import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Typography from "@mui/material/Typography";
import Delete from "@mui/icons-material/Delete";
import ContentCopy from "@mui/icons-material/ContentCopy";
import {
  StyledTableBody,
  UXDataTableWithoutBody,
  StyledTableRow,
} from "../../../components/UXDataTable";
import MethodSelection from "../../../components/MethodSelection";
import { generateUniqueID } from "../../../global";
import { StyledTableCellMedium, StyledTableCellSmall } from "../styles";
import Substance from "../../../api/LIMS/Substance";
import {
  INTERVAL_INITIAL_STATE,
  RETAIN_CONTAINER_INITIAL_STATE,
  getUpdatedIntervalsByIntervalToBeCopied,
} from "../shared";
import { intervalTableCols, procedureRetainContainerTableCols } from "../tableColumns";
import IntervalRowForm, { Mode } from "./Interval/IntervalRowForm";

const ProcedureManagementTables = ({
  foundSelectedTestRow,
  intervals,
  setIntervals,
  isReference,
  uoMs,
  locations,
  containerTypes,
  canEdit,
}) => {
  const [methods, setMethods] = useState([]);
  const [retainContainers, setRetainContainers] = useState([]);
  const [selectedInterval, setSelectedInterval] = useState(null);
  const [selectedChemID, setSelectedChemID] = useState(null);

  const getSubstanceByChemID = async (interval) => {
    if (interval && interval.chemID && interval.chemID !== "") {
      const searchResult = await Substance.getByChemID(interval?.chemID, false, false);
      if (searchResult && searchResult.message === "Success" && searchResult.result) {
        setSelectedChemID(searchResult.result);
      }
    }
  };

  function handleClipboard(interval) {
    const newIntervals = getUpdatedIntervalsByIntervalToBeCopied(intervals, interval);

    setIntervals(newIntervals);
  }

  function handleUpdateIntervalProperty(intervalBeingUpdated, propertyName, newValue) {
    const updatedIntervals = intervals.map((val) => {
      let isSameInterval = val.id === intervalBeingUpdated.id;
      // let isSameInterval = areTwoIntervalsTheSame(val, intervalBeingUpdated);

      if (isSameInterval) {
        return {
          ...val,
          [propertyName]: newValue,
        };
      }
      return val;
    });

    setIntervals(updatedIntervals);
  }

  useEffect(() => {
    if (selectedInterval) {
      handleUpdateIntervalProperty(selectedInterval, "testJSON", JSON.stringify(methods));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [methods]);

  useEffect(() => {
    if (retainContainers.length) {
      if (selectedInterval) {
        handleUpdateIntervalProperty(
          selectedInterval,
          "retainContainerJSON",
          JSON.stringify(retainContainers),
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [retainContainers]);

  useEffect(() => {
    if (selectedInterval) {
      const updatedIntervals = intervals.map((interval) => {
        let isSameInterval = false;

        if (interval.id) {
          isSameInterval = interval.id === selectedInterval.id;
        } else {
          isSameInterval = interval.rowId === selectedInterval.rowId;
        }

        if (isSameInterval) {
          return {
            ...interval,
            chemID: selectedChemID?.chemID,
            intervalSubstance: selectedChemID,
          };
        }
        return interval;
      });

      setIntervals(updatedIntervals);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedChemID]);

  useEffect(() => {
    if (foundSelectedTestRow) {
      setSelectedInterval(null);
      setSelectedChemID(null);
      setMethods([]);
      setRetainContainers([]);
      setIntervals(foundSelectedTestRow?.testIntervals);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [foundSelectedTestRow?.rowId]);

  function findPossibleAlreadyExistingMethod(newMethodWithoutContainer) {
    let found = false;

    if (methods && methods.length > 0) {
      return methods.find((m) => {
        const existVariablesForComparison =
          newMethodWithoutContainer &&
          newMethodWithoutContainer.method &&
          newMethodWithoutContainer.method.name &&
          m &&
          m.method &&
          m.method.name;

        if (existVariablesForComparison) {
          return m.method.name === newMethodWithoutContainer.method.name;
        }
        return false;
      });
    }

    return found;
  }

  function getNewContainerGroup(containerGroupValue) {
    return {
      id: generateUniqueID(),
      containeringGroup: containerGroupValue,
      containerSize: 0,
      containerUoM: "",
      containerType: "Unknown",
      returnLocationName: "DISCARD",
    };
  }

  function canAddNewContainerGroup(newMethodWithoutContainer) {
    let containerGroupAlreadyAdded = true;

    methods.forEach((method) => {
      if (
        method.containerGroups &&
        method.containerGroups.containeringGroup &&
        method.containerGroups.containeringGroup === newMethodWithoutContainer.containerGrouping
      ) {
        containerGroupAlreadyAdded = false;
      }
    });
    return containerGroupAlreadyAdded;
  }

  function getUpdatedMethod(methodAlreadyExists, newMethodWithoutContainer) {
    const methodUpdated = structuredClone(methodAlreadyExists);

    const containerGroupChanged =
      methodAlreadyExists.containerGrouping !== newMethodWithoutContainer.containerGrouping;

    if (containerGroupChanged) {
      if (canAddNewContainerGroup(newMethodWithoutContainer)) {
        methodUpdated.containerGroups = getNewContainerGroup(
          newMethodWithoutContainer.containerGrouping,
        );
      }
    }

    methodUpdated.conditionOffering = newMethodWithoutContainer.conditionOffering;
    methodUpdated.containerGrouping = newMethodWithoutContainer.containerGrouping;
    methodUpdated.estimate = newMethodWithoutContainer.estimate;
    methodUpdated.externalLaboratory = newMethodWithoutContainer.externalLaboratory;
    methodUpdated.method = newMethodWithoutContainer.method;
    methodUpdated.methodFacility = newMethodWithoutContainer.methodFacility;
    methodUpdated.testPriority = newMethodWithoutContainer.testPriority;

    return methodUpdated;
  }

  function handleAddMethods(newMethodsWithoutContainers) {
    const updatedMethodsWithContainer = [];

    if (newMethodsWithoutContainers && newMethodsWithoutContainers.length > 0) {
      newMethodsWithoutContainers.forEach((newMethodWithoutContainer) => {
        const methodAlreadyExists = findPossibleAlreadyExistingMethod(newMethodWithoutContainer);

        if (methodAlreadyExists) {
          const methodUpdated = getUpdatedMethod(methodAlreadyExists, newMethodWithoutContainer);

          updatedMethodsWithContainer.push(methodUpdated);
        } else {
          const newMethodWithoutContainerCopy = structuredClone(newMethodWithoutContainer);

          if (
            newMethodWithoutContainerCopy.containerGrouping &&
            newMethodWithoutContainerCopy.containerGrouping !== ""
          ) {
            newMethodWithoutContainerCopy.containerGroups = getNewContainerGroup(
              newMethodWithoutContainerCopy.containerGrouping,
            );
          }

          updatedMethodsWithContainer.push(newMethodWithoutContainerCopy);
        }
      });
    }

    setMethods(updatedMethodsWithContainer);
  }

  function handleUpdateTestsAndRetainContainers() {
    if (selectedInterval.testJSON) {
      const parsedTests = JSON.parse(selectedInterval.testJSON);
      if (parsedTests && parsedTests.length > 0) {
        setMethods(parsedTests);
      } else {
        setMethods([]);
      }
    } else {
      setMethods([]);
    }
    if (selectedInterval.retainContainerJSON) {
      const parsedRetainContainers = JSON.parse(selectedInterval.retainContainerJSON);
      if (parsedRetainContainers && parsedRetainContainers.length > 0) {
        setRetainContainers(parsedRetainContainers);
      } else {
        setRetainContainers([]);
      }
    } else {
      setRetainContainers([]);
    }
  }

  useEffect(() => {
    if (selectedInterval) {
      handleUpdateTestsAndRetainContainers();
    } else {
      setMethods([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedInterval]);

  function handleDeleteInterval(interval) {
    const filteredIntervals = intervals.filter((i) => !(i.id === interval.id));
    const referenceIntervals = filteredIntervals
      .filter((i) => i.isReference)
      .map((i, index) => {
        return {
          ...i,
          sortOrder: index + 1,
        };
      });
    const candidateIntervals = filteredIntervals
      .filter((i) => !i.isReference)
      .map((i, index) => {
        return {
          ...i,
          sortOrder: index + 1,
        };
      });
    setIntervals([...referenceIntervals, ...candidateIntervals]);
  }

  const uomNameOptions = uoMs ? uoMs.map((unit) => unit?.uoMName) : [];

  function handleUpdateRetainContainerProperty(
    retainContainerBeingUpdated,
    propertyName,
    newValue,
  ) {
    const updatedArray = retainContainers.map((val) => {
      if (val?.id === retainContainerBeingUpdated?.id) {
        return {
          ...val,
          [propertyName]: newValue,
        };
      }
      return val;
    });
    setRetainContainers(updatedArray);
  }

  return (
    <Box display="flex" flexDirection="column" gap={2}>
      <Box>
        <UXDataTableWithoutBody
          tableWidth="100%"
          cols={intervalTableCols}
          tableId="intervalTable"
          rowLength={intervals && intervals.length}
          noDataFoundMessage="No intervals found"
          enableAddIcon={canEdit}
          addToolTipText="Add new Interval"
          addFunction={() => {
            const newInterval = {
              ...INTERVAL_INITIAL_STATE,
              id: generateUniqueID(),
              isReference: isReference,
              sortOrder: intervals && intervals.length ? intervals.length : 1,
            };

            setIntervals((prevState) => [...prevState, newInterval]);
            setSelectedChemID(null);
            setSelectedInterval(newInterval);
          }}>
          <StyledTableBody>
            {intervals &&
              intervals.length &&
              intervals.map((interval) => {
                const isIntervalSelected = selectedInterval
                  ? selectedInterval.id === interval.id
                  : false;

                return interval?.isReference === isReference ? (
                  <StyledTableRow
                    key={interval.id ?? interval.rowId}
                    hover
                    style={{
                      border: isIntervalSelected ? "3px solid #1976D2" : "none",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      if (!isIntervalSelected) {
                        setSelectedChemID(interval.intervalSubstance ?? null);
                        setSelectedInterval(interval);
                        getSubstanceByChemID(interval);
                      }
                    }}>
                    <IntervalRowForm
                      key={interval.id}
                      intervals={intervals}
                      interval={interval}
                      setIntervals={setIntervals}
                      selectedChemID={selectedChemID}
                      setSelectedChemID={setSelectedChemID}
                      disabled={!canEdit}
                      mode={isIntervalSelected ? Mode.Write : Mode.Read}
                      locationOptions={locations}
                    />
                    <StyledTableCellSmall style={{ maxWidth: 10 }}>
                      <Box display="flex" alignItems="center" justifyContent="center" gap={1}>
                        <IconButton
                          color="error"
                          style={{ padding: 0 }}
                          disabled={!canEdit}
                          onClick={() => handleDeleteInterval(interval)}>
                          <Delete fontSize="small" color="inherit" />
                        </IconButton>
                        <IconButton
                          color="info"
                          style={{ padding: 0 }}
                          onClick={() => handleClipboard(interval)}
                          disabled={!canEdit}>
                          <ContentCopy fontSize="small" color="inherit" />
                        </IconButton>
                      </Box>
                    </StyledTableCellSmall>
                  </StyledTableRow>
                ) : null;
              })}
          </StyledTableBody>
        </UXDataTableWithoutBody>
      </Box>
      <Box>
        <MethodSelection
          width="100%"
          selectedMethods={methods}
          setSelectedMethods={handleAddMethods}
          showCompletionDate={false}
          incomingTemplate={null}
          showSearchTemplateName={false}
          isReadOnly={!canEdit}
          showEstimateAndPriority={true}
          maxMethodsAllowed={999}
        />
      </Box>
      <Box display="flex" flexDirection="column" alignitem="flex-start">
        <Typography fontSize={20} color="black" marginBottom={2}>
          Retain Containering
        </Typography>
        <UXDataTableWithoutBody
          tableWidth="100%"
          cols={procedureRetainContainerTableCols}
          tableId="retainContainersTable"
          rowLength={retainContainers.length}
          noDataFoundMessage="No retain containers found"
          blankFirstHeader
          enableAddIcon={canEdit}
          addToolTipText="Add new Retain Container"
          addFunction={() => {
            setRetainContainers((prevState) => [
              ...prevState,
              {
                ...RETAIN_CONTAINER_INITIAL_STATE,
                id: generateUniqueID(),
              },
            ]);
          }}>
          <StyledTableBody>
            {retainContainers &&
              retainContainers.length &&
              retainContainers.map((container) => {
                return (
                  <StyledTableRow key={container.id} hover>
                    <StyledTableCellSmall style={{ maxWidth: 10 }}>
                      <Box display="flex" alignItems="center" justifyContent="center" gap={1}>
                        <IconButton
                          color="error"
                          style={{ padding: 0 }}
                          disabled={!canEdit}
                          onClick={() => {
                            const newRetainContainersList = retainContainers.filter(
                              (item) => item?.id !== container?.id,
                            );
                            setRetainContainers(newRetainContainersList);
                          }}>
                          <Delete fontSize="small" color="inherit" />
                        </IconButton>
                      </Box>
                    </StyledTableCellSmall>
                    <StyledTableCellSmall>
                      <TextField
                        onChange={(e) =>
                          handleUpdateRetainContainerProperty(
                            container,
                            "numberOfContainers",
                            e.target?.value ?? "",
                          )
                        }
                        disabled={!canEdit}
                        value={container?.numberOfContainers}
                        label="# of Containers"
                        size="small"
                        type="number"
                        fullWidth
                      />
                    </StyledTableCellSmall>
                    <StyledTableCellSmall>
                      <TextField
                        onChange={(e) =>
                          handleUpdateRetainContainerProperty(
                            container,
                            "containerSize",
                            e.target?.value ?? "",
                          )
                        }
                        disabled={!canEdit}
                        value={container?.containerSize}
                        label="Container Size"
                        size="small"
                        type="number"
                        fullWidth
                      />
                    </StyledTableCellSmall>
                    <StyledTableCellMedium>
                      <Autocomplete
                        noOptionsText="Loading UoMs..."
                        value={container?.sizeUoM}
                        onChange={(_, value) =>
                          handleUpdateRetainContainerProperty(container, "sizeUoM", value ?? "")
                        }
                        disabled={!canEdit}
                        isOptionEqualToValue={(option, value) => value === option}
                        disablePortal
                        options={uomNameOptions}
                        renderInput={(params) => (
                          <TextField {...params} label="Size UoM" size="small" />
                        )}
                        autoSelect
                        fullWidth
                      />
                    </StyledTableCellMedium>
                    <StyledTableCellSmall>
                      <TextField
                        onChange={(e) =>
                          handleUpdateRetainContainerProperty(
                            container,
                            "retainAmount",
                            e.target?.value ?? "",
                          )
                        }
                        disabled={!canEdit}
                        value={container?.retainAmount}
                        label="Retain Amount"
                        size="small"
                        type="number"
                        fullWidth
                      />
                    </StyledTableCellSmall>
                    <StyledTableCellMedium>
                      <Autocomplete
                        noOptionsText="Loading UoMs..."
                        value={container?.retainUoM}
                        onChange={(_, value) =>
                          handleUpdateRetainContainerProperty(container, "retainUoM", value ?? "")
                        }
                        disabled={!canEdit}
                        isOptionEqualToValue={(option, value) => value === option}
                        disablePortal
                        options={uoMs.map((unit) => unit?.uoMName)}
                        renderInput={(params) => (
                          <TextField {...params} label="Retain UoM" size="small" />
                        )}
                        autoSelect
                        fullWidth
                      />
                    </StyledTableCellMedium>
                    <StyledTableCellMedium>
                      <Autocomplete
                        noOptionsText="Loading Container Types..."
                        value={container?.containerType}
                        onChange={(_, value) =>
                          handleUpdateRetainContainerProperty(
                            container,
                            "containerType",
                            value ?? "",
                          )
                        }
                        disabled={!canEdit}
                        isOptionEqualToValue={(option, value) => value === option}
                        disablePortal
                        options={containerTypes}
                        renderInput={(params) => (
                          <TextField {...params} label="Container Type" size="small" />
                        )}
                        autoSelect
                        fullWidth
                      />
                    </StyledTableCellMedium>
                    <StyledTableCellMedium>
                      <Autocomplete
                        noOptionsText="Loading Locations..."
                        value={container?.storageLocation}
                        onChange={(_, value) =>
                          handleUpdateRetainContainerProperty(
                            container,
                            "storageLocation",
                            value ?? "",
                          )
                        }
                        isOptionEqualToValue={(option, value) => value === option}
                        disablePortal
                        disabled={!canEdit}
                        options={locations}
                        renderInput={(params) => (
                          <TextField {...params} label="Storage Location" size="small" />
                        )}
                        autoSelect
                        fullWidth
                      />
                    </StyledTableCellMedium>
                  </StyledTableRow>
                );
              })}
          </StyledTableBody>
        </UXDataTableWithoutBody>
      </Box>
    </Box>
  );
};

export default ProcedureManagementTables;
