import { duplicateNameExists } from "./validation";
import type { Interval, StationaryTest } from "./types";

type ValidationResponse = {
  isValid: boolean;
  error: string;
};

function validateContainers(containers: any[]): ValidationResponse {
  const validationResponse: ValidationResponse = {
    isValid: true,
    error: "",
  };

  for (let index = 0; index < containers.length; index++) {
    const container = containers[index];

    if (!container.size) {
      validationResponse.isValid = false;
      validationResponse.error = "Container size must be inserted";
      return validationResponse;
    }

    if (!container.currentAmount) {
      validationResponse.isValid = false;
      validationResponse.error = "Container amount must be inserted";
      return validationResponse;
    }

    if (container.currentAmount > container.size) {
      validationResponse.isValid = false;
      validationResponse.error = "Container amount must not be greater than container size";
      return validationResponse;
    }

    if (!container.location) {
      validationResponse.isValid = false;
      validationResponse.error = "Container location must be selected";
      return validationResponse;
    }

    if (!container.containerType) {
      validationResponse.isValid = false;
      validationResponse.error = "Container type must be selected";
      return validationResponse;
    }

    if (!container.containerStatus) {
      validationResponse.isValid = false;
      validationResponse.error = "Container status must be selected";
      return validationResponse;
    }

    if (!container.uom) {
      validationResponse.isValid = false;
      validationResponse.error = "Container status must be selected";
      return validationResponse;
    }
  }

  return validationResponse;
}

export const validateIntervalNames = (intervals: Interval[]): ValidationResponse => {
  const validationResponse: ValidationResponse = {
    isValid: true,
    error: "",
  };
  if (duplicateNameExists(intervals.map((i) => i.intervalName))) {
    validationResponse.isValid = false;
    validationResponse.error = "Interval cannot share the same name";
  }
  return validationResponse;
};

export function validateInterval(interval: Interval): ValidationResponse {
  const validationResponse: ValidationResponse = {
    isValid: true,
    error: "",
  };

  const { intervalName, chemID, initialLocationName } = interval;

  const existIntervalName = intervalName && intervalName !== "";

  if (!existIntervalName) {
    validationResponse.isValid = false;
    validationResponse.error = "Missing Interval Name";
    return validationResponse;
  }

  const existChemId = chemID && chemID !== "";

  if (!existChemId) {
    validationResponse.isValid = false;
    validationResponse.error = "Interval must contain a valid ChemID";
    return validationResponse;
  }

  const existInitialLocationName = initialLocationName && initialLocationName !== "";

  if (!existInitialLocationName) {
    validationResponse.isValid = false;
    validationResponse.error = "Missing Interval initial location";
    return validationResponse;
  }

  return validationResponse;
}

export const validateIntervals = (intervals: Interval[]): ValidationResponse => {
  const validateIntervalNamesResponse = validateIntervalNames(intervals);
  if (!validateIntervalNamesResponse.isValid) {
    return validateIntervalNamesResponse;
  }

  for (let interval of intervals ?? []) {
    const validateIntervalResponse = validateInterval(interval);
    if (!validateIntervalResponse.isValid) {
      return validateIntervalResponse;
    }
  }

  return { isValid: true, error: "" };
};

export function isValidForSampleRegistration(
  activeProcessRequest: StationaryTest | null,
  intervalsToBeUpdated: Interval[] | undefined,
  intervalsToRegister: Interval[] | undefined,
): ValidationResponse {
  const validationResponse: ValidationResponse = {
    isValid: true,
    error: "",
  };

  const existSamplesToRegister = intervalsToRegister && intervalsToRegister.length > 0;

  const existIntervals = intervalsToBeUpdated && intervalsToBeUpdated.length > 0;

  if (!activeProcessRequest || !existSamplesToRegister || !existIntervals) {
    validationResponse.isValid = false;
    validationResponse.error = "You need to select at least one sample to register";
    return validationResponse;
  }

  for (let index = 0; index < intervalsToRegister.length; index++) {
    const intervalToBeRegistered = intervalsToRegister[index];

    const interval = intervalsToBeUpdated.find((r) => {
      return r.intervalName === intervalToBeRegistered.intervalName;
    });

    if (!interval) {
      validationResponse.isValid = false;
      validationResponse.error = "Didn't find interval";
      return validationResponse;
    }

    const validateIntervalNamesResponse = validateIntervalNames(intervalsToRegister);
    if (!validateIntervalNamesResponse.isValid) {
      return validateIntervalNamesResponse;
    }

    const existContaineringMethodSelected =
      interval.containeringMethod && interval.containeringMethod !== "";

    if (!existContaineringMethodSelected) {
      validationResponse.isValid = false;
      validationResponse.error = "You need to select the containering method";
      return validationResponse;
    }

    if (
      !interval.intervalSample ||
      !interval.intervalSample.containers ||
      interval.intervalSample.containers.length === 0
    ) {
      validationResponse.isValid = false;
      validationResponse.error = "You need to send at least one test container";
      return validationResponse;
    }

    const areContainersValid = validateContainers(interval.intervalSample.containers);

    if (!areContainersValid.isValid) {
      validationResponse.isValid = false;
      validationResponse.error = areContainersValid.error;
      return validationResponse;
    }

    const isIntervalValid = validateInterval(interval);

    if (!isIntervalValid.isValid) {
      validationResponse.isValid = false;
      validationResponse.error = isIntervalValid.error;
      return validationResponse;
    }
  }

  if (!validationResponse.isValid) return validationResponse;

  return validationResponse;
}
