import API from '../index'

export default class StockroomItemList {
    constructor(
        ID,
        SiteAssetNumber,
        Description,
        Quantity,
        StockroomCategoryName,
        ImagePath,
        EquipmentPriceUSD,
        StockUnitOfMeasure,
    ) {
        this.ID = ID
        this.SiteAssetNumber = SiteAssetNumber
        this.Description = Description
        this.Quantity = Quantity
        this.StockroomCategoryName = StockroomCategoryName
        this.ImagePath = ImagePath
        this.EquipmentPriceUSD = EquipmentPriceUSD
        this.StockUnitOfMeasure = StockUnitOfMeasure
    }

    static async getAll() {
        const api = await API()
        const { data } = await api.get('/stockroomitemlist')
        return data?.result?.map(item => new StockroomItemList(item))
    }

    static async getItemById(assetNumber) {
        const api = await API()
        const { data } = await api.get(`/stockroomitemlist?siteAssetNumber=${assetNumber}`)
        return new StockroomItemList(data.result)
    }
}