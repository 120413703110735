import API from "..";

export default class WaiverFacility {
  /**
   * The Sample dimension table for M3
   * @param {String} facilityName The MIDAS of the Sample
   */
  constructor({
    facilityName,
    isActive,
    description,
  }) {
    this.facilityName = facilityName;
    this.isActive = isActive;
    this.description = description;
  }

  static async getAll() {
    const api = await API();
    const { data } = await api.get(`/waiverfacility`);

    return data.result.map((d) => new WaiverFacility(d));
  }
}
