import API from "../../api";

export default class ResearchComponent {
  /**
   * The ResearchComponent dimension table for M3
   * @param {int} id The id of the ResearchComponent
   * @param {int} carbonNumber The carbonNumber of the ResearchComponent
   * @param {int} hydrogenNumber The hydrogenNumber for the ResearchComponent
   * @param {int} oxygenNumber The OxygenNumber for the ResearchComponent
   * @param {int} nitrogenNumber The NitrogenNumber for the ResearchComponent
   * @param {double} molecularWeight The MolecularWeight for the ResearchComponent
   * @param {double} specificGravity The SpecificGravity for the ResearchComponent
   */

  constructor({
    id,
    carbonNumber,
    hydrogenNumber,
    oxygenNumber,
    nitrogenNumber,
    specificGravity,
    molecularWeight,
    smilesString
  }) {
    this.id = id;
    this.carbonNumber = carbonNumber;
    this.hydrogenNumber = hydrogenNumber;
    this.oxygenNumber = oxygenNumber;
    this.nitrogenNumber = nitrogenNumber;
    this.specificGravity = specificGravity;
    this.molecularWeight = molecularWeight;
    this.smilesString = smilesString
  }

  static async getAll() {
    const api = await API();
    const { data } = await api.get(`/researchComponent`);
    return data.result.map((d) => new ResearchComponent(d));
  }

  static async get(id) {
    const api = await API();
    const { data } = await api.get(`researchComponent/${id}`);

    if (data.result) {
      return new ResearchComponent(data.result);
    }
    else return null;

  }

  static async add(info) {
    const api = await API();
    const { data } = await api.post(`/researchComponent`, info);
    return new ResearchComponent(data.result);
  }

  static async update(id, info) {
    const api = await API();
    const { data } = await api.put(`/researchComponent/${id}`, info);
    return new ResearchComponent(data.result);
  }

}
