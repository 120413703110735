import API from "..";

export default class Draft {
  /**
   * The ResultComponent dimension table for M3
   * @param {int} id The id of the Draft
   * @param {String} draftOwnerEmail The email of the owner
   * @param {String} draftName The name of the draft
   * @param {String} draftJSON The JSON that defines the draft
   * @param {String} draftType The type of draft
   */

  constructor({
    id,
    draftOwnerEmail,
    draftName,
    draftJSON,
    draftType,
    createdDate,
    lastModifiedDate,
  }) 
    
  {
    this.id = id;
    this.draftOwnerEmail = draftOwnerEmail;
    this.draftName = draftName;
    this.draftJSON = draftJSON;
    this.draftType = draftType;
    this.createdDate = createdDate;
    this.lastModifiedDate = lastModifiedDate;
  }

  static async getAllDrafts() {
    const api = await API();
    const { data } = await api.get(`/draft`);
    return data.result.map((d) => new Draft(d));
  }

  static async get(id) {
    const api = await API();
    const { data } = await api.get(`draft/${id}`);
    if (data.result) {
      return new Draft(data.result);
    }
    else {
      return null;
    }

  }

  static async getByName(name) {
    const api = await API();
    const { data } = await api.get(`draft/getbyname?draftname=${name}`);

    if (data.result) {
      return new Draft(data.result);
    }
    else {
      return null;
    }

  }

  static async getByUserAndType(name, type) {
    const api = await API();
    const { data } = await api.get(`draft/getbyuser?userEmail=${name}&draftType=${type}`);

    if (data.result) {
      return data.result.map((d) => new Draft(d));
    }
    else {
      return null;
    }

  }

  static async add(info) {
    const api = await API();
    const { data } = await api.post(`/draft`, info);
    return (data);
  }

  static async addList(lstDrafts) {
    const api = await API();
    const { data } = await api.post(`/draft/postList`, lstDrafts);
    return (data);
  }

  static async update(info) {
    const api = await API();
    const { data } = await api.put(`/draft`, info);
    return (data);
  }

  static async delete(id) {
    const api = await API();
    const { data } = await api.delete(`/draft/${id}`);
    return (data);
  }
}
