import { TextField } from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";
import { StyledErrorMessage } from "../styles";
import ModalTwoButtons from "../../../components/Modal/ModalTwoButtons";

export default function AddItemById({
  getItemById,
  itemID,
  selectedItems,
  quantity,
  setSelectedItems,
  setIsModalOpen,
  setItemID,
  setQuantity,
  setModalType,
  isModalOpen,
}) {
  const [errorMessage, setErrorMessage] = useState("");

  return (
    <ModalTwoButtons
      title="Add item by ID"
      button1Text="Add"
      button1Action={async () => {
        try {
          setErrorMessage("");
          const fetchedItem = await getItemById(itemID.toUpperCase());
          if (
            fetchedItem &&
            !selectedItems.some((item) => item?.id === fetchedItem?.id)
          ) {
            let foundItem = JSON.parse(JSON.stringify(fetchedItem));
            foundItem = {
              ...foundItem,
              quantity: quantity,
            };
            if (fetchedItem?.quantity < +foundItem?.quantity) {
              setErrorMessage(
                `Quantity desired is above item's stock count of ${fetchedItem?.quantity}`
              );
            } else if (quantity <= 0) {
              setErrorMessage("Quantity value has to be at least 1");
            } else {
              setSelectedItems((prevState) => [...prevState, foundItem]);
              setIsModalOpen(false);
              setItemID("");
              setQuantity(1);
              setModalType("");
            }
          } else {
            setErrorMessage("Item does not exist or already in the list, try another");
          }
        } catch (err) {
          setErrorMessage("Item not found");
        }
      }}
      button2Text="Cancel"
      isButton1Disabled={!itemID.replace(/\s/g, "")}
      button2Action={() => {
        setIsModalOpen(false);
        setErrorMessage("");
        setItemID("");
        setQuantity(1);
        setModalType("");
      }}
      setOpen={setIsModalOpen}
      open={isModalOpen}
    >
      <Box display="flex" flexDirection="column" alignItems="center" gap={2}>
        <TextField
          size="small"
          type="text"
          name="stockroomId"
          label="Stockroom #"
          value={itemID}
          onChange={(event) => setItemID(event.target.value)}
          sx={{ marginTop: "0.5rem" }}
        />
        {errorMessage && (
          <StyledErrorMessage>{errorMessage}</StyledErrorMessage>
        )}
        <TextField
          size="small"
          type="number"
          name="quantity"
          label="Quantity"
          min={1}
          value={quantity}
          onChange={(event) => setQuantity(event.target.value)}
        />
      </Box>
    </ModalTwoButtons>
  );
}
