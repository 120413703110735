import React from "react";
import CustomModal from './index';
import { GlobalButton, GlobalSecondaryButton } from "../../pages/styles";

const ModalTwoButtons = ({ children, title, button1Text, button1Action, isButton1Disabled, button2Text, button2Action, isButton2Disabled, open, setOpen, setClose }) => {

    return (
        <>
            <CustomModal title={title} open={open} setOpen={setOpen} setClose={setClose} >
                {children ? children : <></>}

                <div style={{
                    display: "flex",
                    justifyContent: "center",
                }}>
                    <GlobalButton
                        style={{
                            marginTop: "35px",
                            marginLeft: "6px",
                        }}
                        disabled={isButton1Disabled}
                        variant='contained'
                        onClick={button1Action}
                    >{button1Text}</GlobalButton>

                    <GlobalSecondaryButton
                        style={{
                            marginTop: "35px",
                            marginLeft: "20px",
                        }}
                        variant='contained'
                        disabled={isButton2Disabled}
                        onClick={button2Action}
                    >{button2Text}</GlobalSecondaryButton>
                </div>


            </CustomModal>
        </>
    );
};

export default ModalTwoButtons;
