import { useState, useEffect, useContext } from "react";
import {
  Box,
  Typography,
  IconButton,
  FormControl,
  Select,
  MenuItem,
} from "@mui/material";
import { List } from "@mui/icons-material";
import {
  StyledTableCellSmall,
  StyledTableCellMedium,
} from "./styles";
import { convertDateFormat } from "../../global";
import {
  StyledTableBody,
  UXDataTableWithoutBody,
  StyledTableRow,
} from "../../components/UXDataTable";
import { StockroomPOContext } from "./context/StockroomPOContext";
import SRReceiveStockModal from "./components/SRReceiveStockModal";
import { status } from "./context/StockroomPOUtils";
import StockroomPOApi from "../../api/Stockroom/StockroomPO";
import { toast } from "react-hot-toast";
import DisplayMessage from "./components/DisplayMessage";
import { OrderStatusEnums, StockroomCategoryNameEnums } from "./shared";

const poCols = ["PO #", "Created Date", "Created by", "PO Status"];

const SRReceiveStock = ({ canEdit }) => {
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  const [modalMessageIsOpen, setModalMessageIsOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState({ title: "", message: "" });

  const [isPOItemListOpen, setIsPOItemListOpen] = useState(false);
  const [isPOItemUpdating, setIsPOItemUpdating] = useState(false);
  const [selectedPOItems, setSelectedPOItems] = useState([]);
  const [selectedPO, setSelectedPO] = useState({});

  const { getAllPOs, pos, isPoDataLoading, setIsPoDataLoading } =
    useContext(StockroomPOContext);

  const handlePageChange = (event, page) => {
    setPage(page);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    if (!pos.length) {
      getAllPOs();
    }
  }, [pos, getAllPOs]);

  const handleUpdatePOStatus = async (newStatusName, stockroomPo) => {
    if (validation(newStatusName, stockroomPo)) {
      setIsPoDataLoading(true);

      stockroomPo.statusName = newStatusName;

      StockroomPOApi.managePO(stockroomPo).then(() => {
        getAllPOs();
      }).catch(() => {
        toast.error("Failed while updating status")
      });
    }
  };

  const validation = (newStatusName, stockroomPo) => {
    if (newStatusName === OrderStatusEnums.Complete) {
      let needMidasNumber = stockroomPo.poItems.some((item) =>
        item.equipmentItem?.stockroomCategoryName === StockroomCategoryNameEnums.Chemicals &&
        (!item.receivedContainer?.sample?.sampleName ||
          item.receivedContainer?.containerNumber === null))

      if (needMidasNumber) {
        setModalMessage({
          title: "Error!",
          message:
            "Please provide a valid MIDAS # and Container #",
        });
        setModalMessageIsOpen(true);

        return false;
      }
    }

    return true;
  };

  return (
    <Box display="flex" flexDirection="column">
      <Typography component="h2" fontSize={30} marginBottom={2}>
        Open POs
      </Typography>
      <UXDataTableWithoutBody
        tableWidth="60%"
        blankFirstHeader
        cols={poCols}
        tableId="receiveStockTable"
        rowLength={isPoDataLoading ? 0 : pos.length}
        enablePaging
        rowsPerPage={rowsPerPage}
        page={page}
        handleChangePage={handlePageChange}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        noDataFoundMessage="No POs found"
        isDataLoading={isPoDataLoading}
      >
        <StyledTableBody>
          {pos?.length > 0 &&
            pos
              ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              ?.map((po) => {
                return (
                  <StyledTableRow key={po?.id} hover>
                    <StyledTableCellSmall>
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                      >
                        <IconButton
                          style={{ padding: 0 }}
                          color="primary"
                          onClick={() => {
                            setSelectedPO(po);
                            setSelectedPOItems(structuredClone(po?.poItems));
                            setIsPOItemListOpen(true);
                          }}
                        >
                          <List fontSize="small" color="inherit" />
                        </IconButton>
                      </Box>
                    </StyledTableCellSmall>
                    <StyledTableCellMedium >
                      {po?.id}
                    </StyledTableCellMedium>
                    <StyledTableCellMedium>
                      {convertDateFormat(po?.createdDate)}
                    </StyledTableCellMedium>
                    <StyledTableCellMedium>
                      {po?.createdByEmail}
                    </StyledTableCellMedium>
                    <StyledTableCellMedium>
                      <FormControl fullWidth>
                        <Select
                          size="small"
                          name="poStatus"
                          fullWidth
                          value={po.statusName}
                          onChange={(e) => {
                            if (e && e.target && e.target.value && po) {
                              handleUpdatePOStatus(e.target.value, po);
                            }
                          }}
                          disabled={!canEdit}
                        >
                          <MenuItem value="" disabled>
                            Select an option
                          </MenuItem>
                          {status &&

                            Object.values(status).map((statusName) => {
                              return (
                                <MenuItem
                                  key={statusName}
                                  value={statusName}
                                >
                                  {statusName}
                                </MenuItem>
                              );
                            })}
                        </Select>
                      </FormControl>
                    </StyledTableCellMedium>
                  </StyledTableRow>
                );
              })}
        </StyledTableBody>
      </UXDataTableWithoutBody>
      {isPOItemListOpen && (
        <SRReceiveStockModal
          selectedPO={selectedPO}
          isPOItemUpdating={isPOItemUpdating}
          setIsPOItemListOpen={setIsPOItemListOpen}
          setSelectedPOItems={setSelectedPOItems}
          setSelectedPO={setSelectedPO}
          isPOItemListOpen={isPOItemListOpen}
          selectedPOItems={selectedPOItems}
          setIsPOItemUpdating={setIsPOItemUpdating}
          getAllPOs={getAllPOs}
          canEdit={canEdit}
        />
      )}
      <DisplayMessage
        title={modalMessage.title}
        buttonText="Close"
        buttonAction={() => {
          setModalMessageIsOpen(false);
        }}
        setIsModalOpen={setModalMessageIsOpen}
        isModalOpen={modalMessageIsOpen}
        message={modalMessage.message}
      ></DisplayMessage>
    </Box>
  );
};

export default SRReceiveStock;
