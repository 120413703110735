import { useState } from "react";
import Menu from "@mui/material/Menu";
import MoreOptions from "@mui/icons-material/MoreVert";
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import type { AdditionalMenuAction } from "../types";

type ProcessRequestsAdditionActionsProps = {
  additionalMenuActions: AdditionalMenuAction[];
  row: any;
  visibleIds: number[];
  setVisibleIds(ids: number[]): void;
};

export default function ProcessRequestsAdditionActions({
  additionalMenuActions,
  row,
  visibleIds,
  setVisibleIds,
}: ProcessRequestsAdditionActionsProps) {
  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);
  const alreadyVisible = !!visibleIds.some((vi) => vi === row.id);

  function handleClick(event: any) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  function toggleVisibility(rowId: number) {
    if (alreadyVisible) {
      const filtered = visibleIds.filter((vi) => vi !== rowId);
      setVisibleIds(filtered);
    } else {
      setVisibleIds([...visibleIds, rowId]);
    }
  }

  return (
    <div style={{ display: "flex" }}>
      <IconButton onClick={handleClick}>
        <MoreOptions style={{ color: "black" }} aria-haspopup="true" />
      </IconButton>

      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        {additionalMenuActions?.map((ama: any, i) => (
          <MenuItem
            key={`${ama.label}${i}${row.id}`}
            onClick={() => {
              ama.action(row);
              handleClose();
            }}
          >
            {ama.label}
          </MenuItem>
        ))}
      </Menu>
      <IconButton onClick={() => toggleVisibility(row.id)}>
        {alreadyVisible ? <VisibilityIcon /> : <VisibilityOffIcon />}
      </IconButton>
    </div>
  );
}
