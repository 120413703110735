import API from "..";

export default class TestResult {
    /**
     * The Container dimension table for M3
     * @param TODO
     */
    constructor({
      id,
      testID,
      instrumentID,
      resultDisplayOrder,
      pointTestResult,
      blobTestResult
    }) {
      this.id = id;
      this.testID = testID;
      this.instrumentID = instrumentID;
      this.resultDisplayOrder = resultDisplayOrder;
      this.pointTestResult = pointTestResult;
      this.blobTestResult = blobTestResult;
    }

    static async addTestResults(testResults) {
      const api = await API();
      const { data } = await api.post(`/testResult/createTestResults`, testResults);
      return data.result.map((d) => new TestResult(d));
    }

    static async updateTestResults(testResults) {
      const api = await API();
      const { data } = await api.put(`/testResult/updateTestResults`, testResults);

      if (data && data.result)
        return data?.result.map((d) => new TestResult(d));
      else return []
    }

    static async postAttachs(attachs) {
      const api = await API('multipart/form-data');
      const { data } = await api.post(`/testResult/attachs`, attachs);
      return new TestResult(data.result);
    }
}