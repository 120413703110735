import { useState } from "react";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import ValidatedMidasNumberTextField from "../../../components/ValidatedMidasNumberTextField";
import { useFormik } from "formik";
import { lubricantSampleSchema } from "../shared";
import { toast } from "react-hot-toast";
import type { Container, UoM, Location, Sample } from "../types";
import { CONTAINER_CONFIRMED_STATUS_NAME } from "./NewRequest";

type LubricantSampleModalProps = {
  initialValues: any;
  isLubricantSampleModalOpen: boolean;
  setIsLubricantSampleModalOpen(v: boolean): void;
  uomList: UoM[];
  locationList: Location[];
  addSample(s: Sample): void;
  showViscosity: boolean;
  viscosityGrades: string[];
};

export default function LubricantSampleModal({
  initialValues,
  isLubricantSampleModalOpen,
  setIsLubricantSampleModalOpen,
  uomList,
  locationList,
  addSample,
  showViscosity,
  viscosityGrades,
}: LubricantSampleModalProps) {
  const [isSampleValid, setIsSampleValid] = useState(false);
  const [sample, setSample] = useState<any>(
    initialValues.sampleName && initialValues.sampleName !== ""
      ? {
          sampleName: initialValues.sampleName,
        }
      : null
  );

  const uomOptions = uomList ? uomList.map((uom) => uom.uoMName) : [];
  const locationOptions = locationList
    ? locationList.map((ll) => ll.locationName)
    : [];
  const containerOptions =
    sample && sample.containers && sample.containers.length > 0
      ? sample.containers
          .filter(
            (container: any) =>
              container.containerStatusName === CONTAINER_CONFIRMED_STATUS_NAME
          )
          .map((container: Container) => {
            return {
              containerNumber: container.containerNumber,
              label: `${container.containerNumber}: ${container.currentAmount} ${container.containerSizeUoM}`,
            };
          })
      : [];

  const formik = useFormik({
    initialValues,
    validationSchema: lubricantSampleSchema,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: async (values) => {
      const isValid = sample && sample.sampleName;
      if (isValid) {
        addSample(values);
      } else {
        toast.error("Invalid sample data");
      }
    },
  });

  return (
    <Dialog
      fullWidth={true}
      maxWidth="md"
      open={isLubricantSampleModalOpen}
      onClose={() => setIsLubricantSampleModalOpen(false)}
    >
      <DialogTitle>Lubricant Sample</DialogTitle>
      <DialogContent>
        <Box component="form">
          <Box
            display="grid"
            gridTemplateColumns={showViscosity ? "1fr 1fr 1fr" : "1fr 1fr"}
            gap={2}
            style={{ marginTop: "1rem", marginBottom: "1rem" }}
          >
            <ValidatedMidasNumberTextField
              showLabel
              midasNumberObject={sample}
              setMidasNumberObject={(v: Sample) => {
                setSample(v);
                if (v && v.sampleName) {
                  formik.setFieldValue("sampleName", v.sampleName);
                }
              }}
              hasErrors={isSampleValid}
              setHasErrors={(e: boolean) => setIsSampleValid(e)}
              chemIDToMatch={
                sample?.substance ? sample?.substance?.chemID : null
              }
              setScannedContainer={(value: Container) => {
                if (value && typeof value.containerNumber === "number") {
                  formik.setFieldValue(
                    "containerNumber",
                    value.containerNumber
                  );
                  formik.setFieldValue("containerId", value.id);
                }
              }}
              fieldWidth={null}
              fontSize={null}
              isDisabled={false}
              margin="none"
            />

            <Autocomplete
              id="containerNumber"
              options={containerOptions}
              renderInput={(params) => (
                <TextField {...params} label="Container" size="small" />
              )}
              onChange={(_, v) => {
                formik.setFieldValue("containerNumber", v.containerNumber);
                const correspondentContainer = sample.containers.find(
                  (c: Container) => c.containerNumber === v.containerNumber
                );
                if (correspondentContainer && correspondentContainer.id) {
                  formik.setFieldValue(
                    "containerId",
                    correspondentContainer.id
                  );
                }
              }}
              value={formik.values.containerNumber}
              disabled={
                !sample || !sample.containers || sample.containers.length === 0
              }
              isOptionEqualToValue={(option) =>
                option.containerNumber === formik.values.containerNumber
              }
              getOptionLabel={(option) =>
                option.label ? option.label : String(option)
              }
            />
            {showViscosity && (
              <Autocomplete
                id="viscosityGrade"
                options={viscosityGrades}
                renderInput={(params) => (
                  <TextField {...params} label="Viscosity Grade" size="small" />
                )}
                onChange={(_, v) => {
                  formik.setFieldValue("viscosityGrade", v);
                }}
                value={formik.values.viscosityGrade}
                size="small"
              />
            )}
          </Box>

          <Box
            display="grid"
            gap={2}
            gridTemplateColumns="1fr 1fr 1fr"
            style={{ marginBottom: "1rem" }}
          >
            <TextField
              name="amountUsed"
              id="amountUsed"
              label="Expected Quantity of Material"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.amountUsed}
              size="small"
              type="number"
            />
            <Autocomplete
              id="amountUsedUoMName"
              options={uomOptions}
              renderInput={(params) => (
                <TextField {...params} label="UoM" size="small" />
              )}
              onChange={(_, v) => {
                formik.setFieldValue("amountUsedUoMName", v);
              }}
              value={formik.values.amountUsedUoMName}
              size="small"
            />
            <Autocomplete
              id="usedReturnLocation"
              options={locationOptions}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Used Retain Location"
                  size="small"
                />
              )}
              onChange={(_, v) => {
                formik.setFieldValue("usedReturnLocation", v);
              }}
              value={formik.values.usedReturnLocation}
              size="small"
            />
          </Box>
          <Box display="grid" gap={2} gridTemplateColumns="1fr 1fr 1fr">
            <Autocomplete
              id="unusedReturnLocation"
              options={locationOptions}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Unused Return Location"
                  size="small"
                />
              )}
              onChange={(_, v) => {
                formik.setFieldValue("unusedReturnLocation", v);
              }}
              value={formik.values.unusedReturnLocation}
              size="small"
            />
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          disabled={
            !formik.isValid ||
            !sample ||
            !sample.sampleName ||
            typeof formik.values.containerNumber !== "number"
          }
          onClick={() => formik.submitForm()}
        >
          {initialValues.rowId ? "update" : "add"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
