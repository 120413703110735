import { useState, useRef } from "react";
import InputLabel from "@mui/material/InputLabel";
import { styled } from "@mui/material";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import CustomDataGrid from "../../../components/DataGrid";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

const StyledDivBackGround = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  backgroundColor: "#1976d2",
  padding: "1rem",
  borderTopRightRadius: "1rem",
  borderTopLeftRadius: "1rem",
});

const StyledSpan = styled("span")({
  marginRight: "1rem",
  label: {
    color: "white",
  },
  button: {
    backgroundColor: "white",
    color: "#3190D9",
    fontSize: "13px",
    fontFamily: "EMprint",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "white",
    },
  },
});

const ProcedureManagementDataGrid = ({
  title,
  buttonText,
  buttonAction,
  handleSelectedInfo,
  rows,
  setRows,
  cols,
  modalListShownOptions,
  modalListCompleteData,
  modalListType,
  selectionDataGrid,
  enableSelect,
  enableSelectSingleRow,
  enableDelete,
  sx,
  minWidth,
  canEdit,
  actionOpenModal,
  handleAdd,
  handleDataChange,
  requiredFields,
  customDelete,
  procedureNumberIsAlreadyUsed = false,
}) => {
  const [openModal, setOpenModal] = useState(false);

  const fileInputRef = useRef(null);

  function handleChange(e) {
    const foundItem = modalListCompleteData.find((obj) => {
      if (modalListType === "engineer") {
        return obj?.fullName === e.currentTarget.innerText;
      }

      if (modalListType === "testStand") {
        return obj?.standName === e.currentTarget.innerText;
      }

      if (modalListType === "facility") {
        return obj?.stationaryTestFacilityName === e.currentTarget.innerText;
      }

      return obj.text.toString() === e.currentTarget.innerText;
    });

    handleSelectedInfo(foundItem);
    setOpenModal(false);
  }

  return (
    <>
      <Box>
        <StyledSpan>
          <StyledDivBackGround>
            <InputLabel>{title}</InputLabel>
            <div>
              {buttonText && (
                <Button
                onClick={() => {
                  if (actionOpenModal) {
                    if (modalListType === "container") {
                      buttonAction();
                    } else {
                      setOpenModal(true);
                    }
                  } else {
                    if (modalListType === "file") {
                      fileInputRef?.current?.click();
                    } else {
                      handleAdd();
                    }
                  }
                }}
                  disabled={!requiredFields || procedureNumberIsAlreadyUsed || !canEdit}
                  variant="contained"
                  type="button"
                >
                  {buttonText}
                </Button>
              )}
            </div>
          </StyledDivBackGround>
          <CustomDataGrid
            sx={sx}
            cols={cols}
            rows={rows}
            onRowClick={selectionDataGrid}
            enableSelect={enableSelect}
            enableSelectSingleRow={enableSelectSingleRow}
            setRows={setRows}
            minWidth={minWidth}
            enableDelete={enableDelete}
            canEdit={canEdit}
            handleDataChange={handleDataChange}
            customDelete={customDelete}
            initialRowsPerPage={10}
          />
        </StyledSpan>
      </Box>

      {modalListCompleteData && (
        <Dialog fullWidth={true} maxWidth="sm" open={openModal} onClose={() => setOpenModal(false)}>
          <DialogTitle>Add Engineer</DialogTitle>
          <DialogContent>
            <Box display="grid" gap={2} gridTemplateColumns="1fr" style={{ marginTop: "1rem" }}>
              <Autocomplete
                options={modalListShownOptions}
                renderInput={(params) => <TextField {...params} />}
                onChange={(e) => handleChange(e)}
                sx={{ minWidth: "20rem" }}
              />
            </Box>
          </DialogContent>
        </Dialog>
      )}
    </>
  );
};

export default ProcedureManagementDataGrid;
