import * as yup from "yup";
import { SqcSample, SqcSampleContainerHistory } from "../../../lib/types/sqc";

export type SqcSampleContainerHistoryForm = {
  sqcid?: SqcSampleContainerHistory["sqcid"];
  containerID: SqcSampleContainerHistory["containerID"] | null;
  createdDate: SqcSampleContainerHistory["createdDate"];
  isActiveContainer: SqcSampleContainerHistory["isActiveContainer"];
  deactivatedDate: SqcSampleContainerHistory["deactivatedDate"];
  manufacturer: SqcSampleContainerHistory["manufacturer"];
  lotNumber: SqcSampleContainerHistory["lotNumber"];
};

export type SqcSampleForm = {
  sqcid?: SqcSample["sqcid"];
  isActive: SqcSample["isActive"];
  description: SqcSample["description"];
  siteName?: SqcSample["siteName"];
  containerHistory: SqcSampleContainerHistoryForm[];
};

export const INITIAL_VALUE: SqcSampleForm = {
  isActive: true,
  description: null,
  containerHistory: [],
};

const sqcSampleContainerHistorySchema = yup.object({
  sqcid: yup.string(),
  containerID: yup.number().required(),
  manufacturer: yup.string().max(200).nullable(),
  lotNumber: yup.string().max(100).nullable(),
});

export const sqcSampleSchema = yup.object({
  sqcid: yup.string(),
  isActive: yup.boolean(),
  description: yup.string().nullable(),
  containerHistory: yup.array().of(sqcSampleContainerHistorySchema),
});
