import API from "..";

export default class TSCAReview {
  constructor({
    id,
    shippingRequestID,
    createdByEmail,
    createdDate,
    lastModifiedByEmail,
    lastModifiedDate,
    reviewedDate,
    reviewedByEmail,
    reviewReason,
    tscaStatusName,
    tscaStatus,
    tscaOutcome,
    tscaOutcomeObj,
    comments,
  }) {
    this.id = id;
    this.shippingRequestID = shippingRequestID;
    this.createdByEmail = createdByEmail;
    this.createdDate = createdDate;
    this.lastModifiedByEmail = lastModifiedByEmail;
    this.lastModifiedDate = lastModifiedDate;
    this.reviewedDate = reviewedDate;
    this.reviewedByEmail = reviewedByEmail;
    this.reviewReason = reviewReason;
    this.tscaStatusName = tscaStatusName;
    this.tscaStatus = tscaStatus;
    this.tscaOutcome = tscaOutcome;
    this.tscaOutcomeObj = tscaOutcomeObj;
    this.comments = comments;
  }

  static async getAll() {
    const api = await API();
    const { data } = await api.get(`/tscaReview`);
    if (data.result) {
      return data.result.map((d) => new TSCAReview(d));
    } else {
      return null;
    }
  }
  static async getById(id) {
    const api = await API();
    const { data } = await api.get(`/tscaReview/${id}`);
    if (data.result) {
      return new TSCAReview(data.result);
    } else {
      return null;
    }
  }

  static async createTSCAReview(info) {
    const api = await API();
    const { data } = await api.post("/tscaReview", info);
    return new TSCAReview(data.result);
  }
  static async updateTSCAReview(info) {
    const api = await API();
    const { data } = await api.patch("/tscaReview", info);
    return new TSCAReview(data.result);
  }
}
