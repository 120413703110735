import React, { useEffect } from 'react';
import { styled } from "@mui/material/styles";
import {
  Autocomplete,
  TextField,
  Tooltip,
  RadioGroup,
  FormControlLabel,
  Radio
} from "@mui/material";
import CustomModal from '../Modal/index';
import AddIcon from '@mui/icons-material/AddCircleOutline';
import AddLinkIcon from '@mui/icons-material/AddLink';
import LinkOffIcon from '@mui/icons-material/LinkOff';
import TrashIcon from '@mui/icons-material/Delete';
import { GlobalButton, GlobalSecondaryButton } from "../../pages/styles";
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePickerKeyDownEvent } from '../../global';

const fontSize = 14

const StyledAutocomplete = styled(Autocomplete)({
  '& .MuiAutocomplete-input, & .MuiInputLabel-root': {
    fontSize: fontSize,
  },
});

const Option = styled('li')({
  fontSize: fontSize,
});

function getOperators(type) {
  switch (type) {
    case 'string':
      return (['=', 'like'])

    case 'integer':
      return (['=', 'like', '>', '<', '>=', '<='])

    case 'enum':
      return (['='])

    case 'boolean':
      return (['='])

    case 'date':
      return (['=', '>', '<'])

    default: return (['=', 'like'])
  }
}


const FilterQueryMenu = ({ open, setOpen, applyBtnAction, cancelButtonAction, filteringInfo, appliedFilters, setAppliedFilters }) => {
  const [availableOperators, setAvailableOperators] = React.useState([])
  const [selectedFilters, setSelectedFilters] = React.useState(appliedFilters)

  useEffect(() => {
    setSelectedFilters(appliedFilters)
  }, [appliedFilters, open])

  const addFilterRow = (e) => {
    const newFilters = JSON.parse(JSON.stringify(selectedFilters))
    newFilters.push({ name: null, displayName: null, operator: null, join: '&&', enumValues: [], value: null, startGroup: false, endGroup: false })
    
    setSelectedFilters(newFilters)
  }

  const handleColValueChange = (index, value) => {
    const newFilters = JSON.parse(JSON.stringify(selectedFilters))

    if (value && newFilters[index].name === value.name) {
      return
    }

    newFilters[index].name = !(value === null) ? value.name : null
    newFilters[index].displayName = !(value === null) ? value.displayName : null
    newFilters[index].operator = null
    newFilters[index].value = ''
    newFilters[index].type = value?.type ?? null
    newFilters[index].nullable = value?.nullable ?? false

    const operators = JSON.parse(JSON.stringify(availableOperators))

    operators[index] = getOperators(!(value === null) ? value.type : null)

    if (!(value === null) && value.type === 'enum') {
      newFilters[index].enumValues = value.enumValues
    } else {
      newFilters[index].enumValues = []
    }

    setAvailableOperators(operators)
    setSelectedFilters(newFilters)
  }

  const handleFreeText = (index, value) => {
    const info = filteringInfo.filter(oInfo => oInfo.name === selectedFilters[index].name)
    const newFilters = JSON.parse(JSON.stringify(selectedFilters))

    if (info && info.length > 0 && info[0].type === 'integer') {
      newFilters[index].value = Number.isInteger ? parseInt(value) : value
    } else {
      newFilters[index].value = value
    }

    setSelectedFilters(newFilters)
  }

  const removeFilterRow = (e, index) => {
    let newFilters = JSON.parse(JSON.stringify(selectedFilters))
    const operators = JSON.parse(JSON.stringify(availableOperators))

    // if (selectedFilters[0].endGroup || selectedFilters[0].startGroup) {
    //   newFilters = removeGroupRow(selectedFilters[0], index, false)
    // }

    newFilters.splice(index, 1)
    operators.splice(index, 1)

    setSelectedFilters(newFilters)
    setAvailableOperators(operators)
  }

  const addGroupRow = (row, index) => {
    const filterGroup = selectedFilters.filter((f, fIndex) => f.endGroup && fIndex < index);
    const lastFilterGroup = filterGroup.slice(-1).pop();
    const lastIndexGroup = selectedFilters.findIndex(i => i === lastFilterGroup);

    const newFilters = selectedFilters.map((filter, indexFilter) => {
      if (lastIndexGroup + 1 === indexFilter || (lastIndexGroup === -1 && indexFilter === 0)) {
        return {
          ...filter,
          startGroup: true,
          endGroup: index === 0 ? true : false
        }
      } else if (index === indexFilter) {
        return {
          ...filter,
          endGroup: true
        }
      }
      return filter;
    })

    setSelectedFilters(newFilters)
  }

  const removeGroupRow = (row, index, setFilter = true) => {
    const filterGroup = selectedFilters.filter((f, fIndex) => row.endGroup ? f.startGroup : f.endGroup && fIndex < index);
    const lastFilterGroup = filterGroup.slice(-1).pop();
    const lastIndexGroup = selectedFilters.findIndex(i => i === lastFilterGroup);

    const newFilters = selectedFilters.map((filter, indexFilter) => {
      if (lastIndexGroup === indexFilter) {
        return {
          ...filter,
          startGroup: false,
          endGroup: false
        }
      } else if (index === indexFilter) {
        return {
          ...filter,
          startGroup: false,
          endGroup: false
        }
      }
      return filter;
    })

    if (setFilter) setSelectedFilters(newFilters)

    return newFilters;
  }

  const applyFilters = () => {
    var prettyFilters = []
    var prettyOperators = []

    availableOperators.forEach(element => {
      prettyOperators.push(element)
    })

    selectedFilters.forEach(element => {
      if (!(element.name === null) && !(element.operator === null) && !(element.value === null)) {
        prettyFilters.push(element)
      }

    });

    if (prettyFilters && prettyFilters.length > 0) {
      setAppliedFilters(prettyFilters)
    }

    setAvailableOperators(prettyOperators)
    applyBtnAction()
  }

  const handleOperatorChange = (index, value) => {
    const newFilters = JSON.parse(JSON.stringify(selectedFilters))
    newFilters[index].operator = value

    setSelectedFilters(newFilters)
  }

  const handleEnumSelection = (index, value) => {
    const newFilters = JSON.parse(JSON.stringify(selectedFilters))
    newFilters[index].value = value

    setSelectedFilters(newFilters)
  }

  const handleJoinChange = (index, value) => {
    const newFilters = JSON.parse(JSON.stringify(selectedFilters))
    newFilters[index].join = value

    setSelectedFilters(newFilters)
  }

  const getFieldValue = (oFilter, index) => {
    if (oFilter.enumValues.length < 1) {
      const info = filteringInfo.find(oInfo => oInfo.name === selectedFilters[index].name)

      if (info?.type === 'boolean') {
        return <StyledAutocomplete
          renderOption={(props2, option) => (
            <Option {...props2}>{option}</Option>
          )}
          disablePortal
          options={['true', 'false']}
          getOptionLabel={(option) => option}
          onChange={(e, value) => handleEnumSelection(index, value)}
          value={oFilter.value === '' ? null : oFilter.value}
          isOptionEqualToValue={(option, value) => value === option}
          autoHighlight
          autoSelect
          renderInput={(params) => <TextField {...params} style={{ width: "200px", marginRight: "10px", marginTop: "10px" }} variant="outlined" size="small" label="Select Value" inputProps={{ ...params.inputProps, style: { fontSize: fontSize } }} InputProps={{ ...params.InputProps }} />}
        />
      } else if (info?.type === "date") {
        return (
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DesktopDatePicker
              disabled={oFilter.operator === null}
              size="small"
              inputFormat="MM/dd/yyyy"
              label="Search Date"
              onChange={e => handleFreeText(index, e)}
              value={oFilter.value ?? null}
              renderInput={(params) => <TextField
                onKeyDown={DatePickerKeyDownEvent}
                {...params}
                style={{ width: "200px", marginTop: "10px" }}
                variant="outlined"
                size="small"
                InputLabelProps={{ shrink: true, style: { fontSize: fontSize } }}
                inputProps={{ ...params.inputProps, style: { fontSize: fontSize } }}
                InputProps={{ ...params.InputProps, style: { fontSize: fontSize } }}
              />}
            />
          </LocalizationProvider>
        )
      }
      return (<TextField style={{ width: "200px", marginTop: "10px" }}
        size="small"
        variant="outlined"
        label="Search Value"
        inputProps={{ style: { fontSize: fontSize } }}
        InputLabelProps={{ style: { fontSize: fontSize } }}
        InputProps={{ style: { fontSize: fontSize } }}
        value={oFilter.value}
        onChange={(e) => handleFreeText(index, (e.target.value))}
      />)
    } else {
      return <StyledAutocomplete
        renderOption={(props2, option) => (
          <Option {...props2}>{option}</Option>
        )}
        //disablePortal
        disabled={oFilter.operator === null}
        options={oFilter.enumValues}
        getOptionLabel={(option) => option}
        onChange={(e, value) => handleEnumSelection(index, value)}
        value={oFilter.value === '' ? null : oFilter.value}
        isOptionEqualToValue={(option, value) => value === option}
        autoHighlight
        autoSelect
        renderInput={(params) => <TextField {...params} style={{ width: "200px", marginRight: "10px", marginTop: "10px" }} variant="outlined" size="small" label="Search Value" inputProps={{ ...params.inputProps, style: { fontSize: fontSize } }} InputProps={{ ...params.InputProps }} />}
      />
    }
  }

  let groupNumber = 0;

  return (
    <>
      <CustomModal title={"Filtering Criteria"} open={open} setOpen={setOpen}>
        {selectedFilters && selectedFilters.map((oFilter, index) => {
          const lastFilter = selectedFilters[index - 1];
          const hasGroupAfter = selectedFilters.some((f, fIndex) => f.startGroup && fIndex > index);
          const startGroup = selectedFilters.some((f, fIndex) => f.startGroup && fIndex < index);
          const endGroup = selectedFilters.some((f, fIndex) => f.endGroup && fIndex > index);
          const hasGroup = startGroup && endGroup;

          groupNumber = oFilter.endGroup ? groupNumber + 1 : groupNumber;
          return (
            <>
              <div key={'myDiv' + index} style={{ display: "flex" }}>

                <StyledAutocomplete
                  renderOption={(props2, option) => (
                    <Option {...props2}>{option.displayName}</Option>
                  )}
                  disablePortal
                  options={filteringInfo}
                  getOptionLabel={(option) => option.displayName ? option.displayName : option}
                  onChange={(e, value) => handleColValueChange(index, value)}
                  value={oFilter.displayName}
                  isOptionEqualToValue={(option, value) => value === option.displayName}
                  autoHighlight
                  autoSelect
                  renderInput={(params) => <TextField {...params} style={{ width: "220px", marginRight: "10px", marginTop: "10px" }} variant="outlined" size="small" label="Column Name" inputProps={{ ...params.inputProps, style: { fontSize: fontSize } }} InputProps={{ ...params.InputProps }} />}
                />

                <StyledAutocomplete
                  renderOption={(props2, option) => (
                    <Option {...props2}>{option}</Option>
                  )}
                  disablePortal
                  disabled={availableOperators[index] === undefined}
                  options={availableOperators[index] === undefined ? [] : availableOperators[index]}
                  getOptionLabel={(option) => option}
                  onChange={(e, value) => handleOperatorChange(index, value)}
                  value={oFilter.operator}
                  isOptionEqualToValue={(option, value) => value === option}
                  autoHighlight
                  autoSelect
                  renderInput={(params) => <TextField {...params} style={{ width: "150px", marginRight: "10px", marginTop: "10px" }} variant="outlined" size="small" label="Operator" inputProps={{ ...params.inputProps, style: { fontSize: fontSize } }} InputProps={{ ...params.InputProps }} />}
                />
                {getFieldValue(oFilter, index)}

                {index === selectedFilters.length - 1 &&
                  <Tooltip title={"Add Addtional Filter"} placement="bottom">
                    <AddIcon style={{
                      paddingBottom: "5px",
                      paddingLeft: "15px",
                      fontSize: 40,
                      marginTop: "10px"
                    }}
                      onClick={(e) => addFilterRow(e)}
                    ></AddIcon>
                  </Tooltip>
                }

                {index >= 0 && !lastFilter?.endGroup && !lastFilter?.startGroup && !oFilter?.endGroup && !oFilter?.startGroup && !hasGroup &&
                  <Tooltip title={"Add Group"} placement="bottom">
                    <AddLinkIcon style={{
                      paddingBottom: "5px",
                      paddingLeft: "15px",
                      fontSize: 40,
                      marginTop: "10px"
                    }}
                      onClick={(e) => addGroupRow(oFilter, index)}
                    ></AddLinkIcon>
                  </Tooltip>
                }

                {oFilter?.endGroup && !hasGroupAfter &&
                  <Tooltip title={"Remove Group"} placement="bottom">
                    <LinkOffIcon style={{
                      paddingBottom: "5px",
                      paddingLeft: "15px",
                      fontSize: 40,
                      marginTop: "10px"
                    }}
                      onClick={(e) => removeGroupRow(oFilter, index)}
                    ></LinkOffIcon>
                  </Tooltip>
                }

                {index > 0 && !oFilter.startGroup && !oFilter.endGroup &&
                  <Tooltip title={"Remove Filter"} placement="bottom">
                    <TrashIcon style={{
                      paddingBottom: "5px",
                      paddingLeft: "15px",
                      fontSize: 40,
                      marginTop: "10px"
                    }}
                      onClick={(e) => removeFilterRow(e, index)}
                    ></TrashIcon>
                  </Tooltip>
                }

              </div>
              {oFilter.endGroup && <div style={{ paddingTop: '10px', paddingBottom: '10px', display: 'flex', flexDirection: 'column', gap: '10px' }}>
                <span >Group {groupNumber}</span>
                <hr />
              </div>}
              {selectedFilters[index + 1] && <div key={'myDivRadio' + index} style={{ display: "flex", justifyContent: "center", paddingTop: "5px" }}>
                <RadioGroup
                  row
                  aria-labelledby="join-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                >
                  <FormControlLabel value="&&" checked={oFilter.join === "&&"} control={<Radio />} label="And" onChange={(e) => handleJoinChange(index, e.target.value)} />
                  <FormControlLabel value="||" checked={oFilter.join === "||"} control={<Radio />} label="Or" onChange={(e) => handleJoinChange(index, e.target.value)} />
                  <FormControlLabel value="&& !" checked={oFilter.join === "&& !"} control={<Radio />} label="And Not" onChange={(e) => handleJoinChange(index, e.target.value)} />
                  <FormControlLabel value="|| !" checked={oFilter.join === "|| !"} control={<Radio />} label="Or Not" onChange={(e) => handleJoinChange(index, e.target.value)} />
                </RadioGroup>
              </div>}
            </>
          )
        })}

        <div style={{
          display: "flex",
          justifyContent: "center",
        }}>

          <GlobalButton
            style={{
              marginTop: "35px",
              marginLeft: "6px",
              fontSize: "14px",
              fontFamily: "EMprint",
              textTransform: "none"
            }}
            variant='contained'
            onClick={() => applyFilters()}
          >Apply</GlobalButton>

          <GlobalSecondaryButton
            style={{
              marginTop: "35px",
              marginLeft: "20px",
              fontSize: "14px",
              fontFamily: "EMprint",
              textTransform: "none"
            }}
            variant='contained'
            onClick={cancelButtonAction}
          >Cancel</GlobalSecondaryButton>

        </div>

      </CustomModal>
    </>
  );
}
export default FilterQueryMenu;


