import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import type { TestInformation } from "../../types";
import { REQUEST_INFORMATION_ENUM } from "../../shared";
import Checkbox from "@mui/material/Checkbox";

type GeneralViewProps = {
  testInformation: TestInformation;
};

export default function TestInformationView({
  testInformation,
}: GeneralViewProps) {
  return (
    <div>
      <span
        style={{
          textAlign: "left",
          font: "normal normal 600 20px/24px EMprint",
          letterSpacing: 0,
        }}
      >
        {REQUEST_INFORMATION_ENUM.PROCEDURE_INFORMATION}
      </span>
      <Box
        display="grid"
        gridTemplateColumns="4fr 1fr 2fr"
        gap={2}
        style={{ marginTop: "1rem" }}
      >
        <TextField
          value={testInformation.procedureTestTestDisplayName ?? ""}
          label="Name"
          InputProps={{ readOnly: true }}
          size="small"
          disabled
        />
        <TextField
          value={testInformation.type ?? ""}
          label="Type"
          InputProps={{ readOnly: true }}
          size="small"
          disabled
        />
        <TextField
          value={testInformation.method ?? ""}
          label="Method"
          InputProps={{ readOnly: true }}
          size="small"
          disabled
        />
      </Box>
      <Box display="grid" gridTemplateColumns="1fr" gap={2} mt={3} mb={3}>
        <TextField
          value={testInformation.testDescription ?? ""}
          label="Test Procedure Description"
          InputProps={{ readOnly: true }}
          size="small"
          multiline
          rows={4}
          disabled
        />
      </Box>
      <Box
        display="grid"
        gridTemplateColumns="1fr 1fr"
        gap={2}
        mt={3}
        mb={3}
      >
        <TextField
          value={testInformation.leadEngineerEmail ?? ""}
          label="Engineer"
          InputProps={{ readOnly: true }}
          size="small"
          disabled
        />
        <TextField
          value={testInformation.backupEngineerEmails ?? ""}
          label="Backup Engineer"
          InputProps={{ readOnly: true }}
          size="small"
          disabled
        />
      </Box>
      <Box display="grid" gridTemplateColumns="1fr 1fr 1fr" gap={2}>
        <TextField
          value={testInformation.minimumSampleSize ?? ""}
          label="Minimum Sample Size"
          InputProps={{ readOnly: true }}
          size="small"
          disabled
        />
        <TextField
          value={testInformation.minimumSampleSizeUoMName ?? ""}
          label="UoM"
          InputProps={{ readOnly: true }}
          size="small"
          disabled
        />
        <Box style={{ display: "flex", alignItems: "center" }}>
          <Checkbox
            id="isISO9001"
            name="isISO9001"
            size="small"
            checked={testInformation.isExternal}
            style={{ paddingLeft: 0 }}
            disabled
          />

          <label
            htmlFor="isISO9001"
            style={{ font: "normal normal 400 14px/16px EMprint" }}
          >
            External Test
          </label>
        </Box>
      </Box>
    </div>
  );
}
