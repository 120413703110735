import API from "../../api";

export default class TestConditionName {
    /**
     * The TestConditionName dimension table for M3
     * @param {String} name The full name of the TestConditionName
     * @param {bool} isActive The IsActive for the TestConditionName
     */

    constructor({
        name,
        isActive }) {
        this.name = name;
        this.isActive = isActive;
    }

    static async getAll() {
        const api = await API();
        const { data } = await api.get(`/testConditionName`);
        return data.result.map((d) => new TestConditionName(d));
    }

    static async get(name) {
        const api = await API();
        const { data } = await api.get(`testConditionName/${name}`);
        if (data.result) {
            return new TestConditionName(data.result);
        }
        else {
            return null;
        }
    }

    static async add(info) {
        const api = await API();
        const { data } = await api.post(`/testConditionName`, info);
        return new TestConditionName(data.result);
    }

    static async update(name, info) {
        const api = await API();
        const { data } = await api.put(`/testConditionName/${name}`, info);
        return new TestConditionName(data.result);
    }
}
