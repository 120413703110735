import React from "react";
import {
  TextField,
  styled
} from "@mui/material";
import CustomModal from '../../components/Modal';
import MethodManagementDataGrid from "./MethodManagementDataGrid";
import { GlobalButton, GlobalSecondaryButton } from "../../pages/styles";
import { getMessage } from "../../global";
import ModalSimpleButton from '../../components/Modal/ModalSimpleButton';

const columnsTestCondtions = [
  { field: 'testConditionName', headerName: 'Test Condition Name', type: 'label', size: 'small' },
  { field: 'minimumValue', headerName: 'Minimum Value', type: 'decimal', size: 'small' },
  { field: 'maximumValue', headerName: 'Maximum Value', type: 'decimal', size: 'small' },
  { field: 'discreteValue', headerName: 'Discrete Value', type: 'text', size: 'small' },
  { field: 'unitOfMeasure', headerName: 'Unit Of Measure', type: 'select', size: 'small' }
];

const StyledDivSelectOffering = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '20px'
});

const StyledTextField = styled(TextField)(() => ({
  width: "35rem"
}));


const MethodManagementModalOffering = ({
  open,
  setOpen,
  conditionsList,
  canEdit,
  selectedFacility,
  unitOfMeasureList,
  offering,
  addOffering,
  handleClose
}) => {

  const initialOffering = {
    conditionOfferingName: null,
    methodFacilityID: selectedFacility.id,
    isActive: true,
    testConditionSetupValues: []
  }

  const [selectedInfoTestConditionsList, setSelectedInfoTestConditionsList] = React.useState(null);
  const [rowsTestConditions, setRowsTestConditions] = React.useState([]);
  const [selectedOffering, setSelectedOffering] = React.useState(initialOffering);

  const [modalSimpleButton, setModalSimpleButton] = React.useState(false);
  const [modalSimpleButtonText, setModalSimpleButtonText] = React.useState('');
  const [modalSimpleTitle, setModalSimpleTitle] = React.useState('');
  const [modalSimpleText, setModalSimpleText] = React.useState('');

  React.useEffect(() => {
    if (selectedInfoTestConditionsList !== null) {

      setSelectedInfoTestConditionsList(null);

      if (rowsTestConditions.find(obj => { return obj.id === selectedInfoTestConditionsList.id }) === undefined) {
        setRowsTestConditions([...rowsTestConditions, {
          rowId: selectedInfoTestConditionsList.id,
          testConditionName: selectedInfoTestConditionsList.id,
          methodFacilityID: selectedFacility.testFacilityAbv.id,
          unitOfMeasure: unitOfMeasureList.map(function (item) { return { key: item.uoMName, value: item.uoMName, selected: false } })
        }])
      }
    }
  }, [rowsTestConditions, selectedInfoTestConditionsList, setRowsTestConditions, unitOfMeasureList, selectedFacility]);

  React.useEffect(() => {
    if (offering) {
      setSelectedOffering({
        ...offering,
        testConditionSetupValues: []
      })

      setRowsTestConditions(offering.testConditionSetupValues)
    }
  }, [offering])

  function save() {
    if (selectedOffering.conditionOfferingName === null) {
      setSelectedOffering(() => ({
        ...selectedOffering,
        conditionOfferingName: '',
      }));
      return
    } else if (selectedOffering.conditionOfferingName.length === 0) return

    if (validateRowTestCondtions()) return;
    addOffering({
      ...selectedOffering,
      testConditionSetupValues: rowsTestConditions
    })
    handleClose()
  }

  const validateRowTestCondtions = () => {
    let errorMethodConditions = false;
    if (rowsTestConditions.length === 0) {
      openModalSimpleButton('Alert', `Please at least one Condition must be listed`, 'Ok');
      errorMethodConditions = true;
    }

    rowsTestConditions.forEach(condition => {
      const minimumValue = (condition.minimumValue || condition.minimumValue === 0) && condition.minimumValue !== '' ? parseFloat(condition.minimumValue) : null;
      const maximumValue = (condition.maximumValue || condition.maximumValue === 0) && condition.maximumValue !== '' ? parseFloat(condition.maximumValue) : null;

      if (condition.discreteValue !== null && condition.discreteValue !== undefined && condition.discreteValue !== "" &&
        ((minimumValue !== null && minimumValue !== undefined && minimumValue !== "") ||
          (maximumValue !== null && maximumValue !== undefined && maximumValue !== ""))
      ) {
        openModalSimpleButton('Alert', `Please inform only Discrete Values or Minimum and Maximum Value for test condition ${condition.testConditionName}`, 'Ok');
        errorMethodConditions = true;
      } else if ((maximumValue === undefined || maximumValue === null || maximumValue === "") &&
        (minimumValue === undefined || minimumValue === null || minimumValue === "") &&
        (condition.discreteValue === undefined || condition.discreteValue === null || condition.discreteValue === "")
      ) {
        openModalSimpleButton('Alert', `Please inform Discrete Values or Minimum and Maximum Value for test condition ${condition.testConditionName}`, 'Ok');
        errorMethodConditions = true;
      } else {
        if ((maximumValue === undefined || maximumValue === null || maximumValue === "") && (minimumValue !== null && minimumValue !== undefined && minimumValue !== "")) {
          openModalSimpleButton('Alert', `Please inform Maximum Value for test condition ${condition.testConditionName}`, 'Ok');
          errorMethodConditions = true;
        }
        else if ((minimumValue === undefined || minimumValue === null || minimumValue === "") && (maximumValue !== null && maximumValue !== undefined && maximumValue !== "")) {
          openModalSimpleButton('Alert', `Please inform Minimum Value for test condition ${condition.testConditionName}`, 'Ok');
          errorMethodConditions = true;
        }
        else if (((maximumValue !== undefined && maximumValue !== null) && (minimumValue !== null && minimumValue !== undefined)) && (minimumValue > maximumValue)) {
          openModalSimpleButton('Alert', `Maximum Value must be greater than Minimum Value for test condition ${condition.testConditionName}`, 'Ok');
          errorMethodConditions = true;
        }
      }
    });
    return errorMethodConditions;
  }

  function closeModalSimpleButton() {
    setModalSimpleButton(false);
  }

  function openModalSimpleButton(title, text, buttonText) {
    setModalSimpleButtonText(buttonText)
    setModalSimpleButton(true);
    setModalSimpleTitle(title);
    setModalSimpleText(text);
  }

  return (

    <div>
      <CustomModal title={'Add Conditions Offering'} open={open} setOpen={setOpen} setClose={handleClose} >
        <StyledDivSelectOffering>
          <StyledTextField
            onChange={e => {
              setSelectedOffering(() => ({
                ...selectedOffering,
                conditionOfferingName: e.target.value,
              }));
            }}
            value={selectedOffering.conditionOfferingName}
            label="Offering Name"
            variant="outlined" size="small" margin="none"
            error={selectedOffering.conditionOfferingName !== null && selectedOffering.conditionOfferingName.length === 0 ? true : false}
            helperText={selectedOffering.conditionOfferingName !== null && selectedOffering.conditionOfferingName.length === 0 ? getMessage('REQUIRED_FIELD') : ''}
            inputProps={{ maxLength: 40 }}
          />
        </StyledDivSelectOffering>
        <MethodManagementDataGrid
          key={`dtgrid-test-conditions`}
          sx={{ width: "77rem", height: "20rem" }}
          selectedInfo={selectedInfoTestConditionsList}
          setSelectedInfo={setSelectedInfoTestConditionsList}
          title={'Conditions'}
          buttonText={'Add Condition'}
          rows={rowsTestConditions}
          setRows={setRowsTestConditions}
          cols={columnsTestCondtions}
          modalList={conditionsList}
          enableDelete={canEdit ? true : false}
          canEdit={canEdit}></MethodManagementDataGrid>
        <div style={{
          display: "flex",
          justifyContent: "center",
        }}>
          <GlobalButton
            style={{
              marginTop: "15px",
              marginLeft: "6px",
              // fontSize: "14px",
              // fontFamily: "EMprint",
              // textTransform: "none"
            }}
            variant='contained'
            onClick={save}
          >Save</GlobalButton>

          <GlobalSecondaryButton
            style={{
              marginTop: "15px",
              marginLeft: "20px",
              // fontSize: "14px",
              // fontFamily: "EMprint",
              // textTransform: "none"
            }}
            variant='contained'
            onClick={handleClose}
          >Cancel</GlobalSecondaryButton>


        </div>
      </CustomModal>

      <ModalSimpleButton title={modalSimpleTitle} buttonText={modalSimpleButtonText} buttonAction={closeModalSimpleButton} open={modalSimpleButton} setOpen={setModalSimpleButton}>
        <label>
          {modalSimpleText}
        </label>
      </ModalSimpleButton>
    </div >

  );
};

export default MethodManagementModalOffering;
