import { Box, Card, CardContent, Collapse, IconButton, styled, Typography } from "@mui/material";
import React from "react";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
})(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
    }),
}));

const StyledHeader = styled(Box)(() => ({
    display: 'flex',
    padding: '10px 16px'
}));

const StyledCardContent = styled(CardContent)(() => ({
    display: 'flex',
    gap: 20,
    justifyContent: 'space-between'
}));

const StyledTitle = styled(Typography)(() => ({
    fontSize: '0.875rem'
}));

const StyledDescription = styled(Typography)(() => ({
    fontSize: '0.875rem',
    fontWeight: '600',
    color: 'rgba(0, 0, 0, 0.8)'
}));

const DetailsResults = ({ rowShow, rowsHidden }) => {
    const [expanded, setExpanded] = React.useState(false);

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    const getRow = (columns) => {
        const width = (100 / columns.length)
        return (
            <StyledCardContent>
                {columns.map(column => {
                    return (
                        <Box width={`${width}%`}>
                            <StyledTitle>{column.title}</StyledTitle>
                            <StyledDescription>{column.description}</StyledDescription>
                        </Box>
                    )
                })}
            </StyledCardContent>
        )
    }

    return (
        <Card>
            <StyledHeader>
                <Typography color={'primary'} variant="h5" fontWeight={600}>Details</Typography>
                {rowsHidden && rowsHidden.length > 0 &&
                    <ExpandMore
                        expand={expanded}
                        onClick={handleExpandClick}
                        aria-expanded={expanded}
                        aria-label="show more"
                    >
                        <ExpandMoreIcon />
                    </ExpandMore>
                }
            </StyledHeader>
            {rowShow && getRow(rowShow)}
            <Collapse in={expanded} timeout="auto" unmountOnExit>
                {rowsHidden && rowsHidden.length > 0 && 
                    rowsHidden.map(row => {
                        return getRow(row)
                    })
                }
            </Collapse>
        </Card>
    )
}
export default DetailsResults;