import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  PDFViewer,
  Font,
} from "@react-pdf/renderer";
import Barcode from "../../../assets/LibreBarcode39Text-Regular.ttf";

Font.register({
  family: "Barcode",
  src: Barcode,
});

const styles = StyleSheet.create({
  page: {
    fontSize: "10px",
    display: "flex",
  },
  section: {
    margin: 10,
    padding: 10,
  },

  title: {
    textAlign: "center",
    fontSize: "16px",
  },
  date: {
    textAlign: "center",
    fontSize: "12px",
    marginBottom: "20px",
  },
  barcode: {
    fontFamily: "Barcode",
    fontSize: "30px",
  },
  table: {
    width: "100%",
    marginTop: "20px",
  },
  row: {
    display: "flex",
    flexDirection: "row",
    borderTop: "1px solid gray",
    paddingTop: 8,
    paddingBottom: 8,
  },
  header: {
    borderTop: "none",
  },
  bold: {
    fontWeight: "bold",
  },
  row0: {
    width: "4%",
  },
  row1: {
    width: "12%",
  },
  row2: {
    width: "14%",
  },
  row3: {
    width: "14%",
  },
  row4: {
    width: "14%",
  },
  row5: {
    width: "14%",
  },
  row6: {
    width: "12%",
  },
  row7: {
    width: "14%",
  },
});

export default function RequestReportTemplate({ requests }) {
  return (
    <PDFViewer width={"100%"} height={"100%"}>
      <Document>
        {requests.map((page) => {
          let totalPrice = 0;
          return (
            <Page size="A4" style={styles.page}>
              <View style={styles.section}>
                <View>
                  <View>
                    <Text style={styles.title}>
                      Equipment Stockroom Request
                    </Text>
                    <Text style={styles.date}>
                      {new Date().toLocaleString('en-US')}
                    </Text>
                  </View>

                  <View
                    style={{
                      justifyContent: "space-between",
                      flexDirection: "row",
                      width: "100%",
                    }}
                  >
                    <View>
                      <Text>
                        Request Number: <Text>{page.id}</Text>
                      </Text>
                      <Text>
                        Requested By: <Text>{page.requestedForEmail}</Text>
                      </Text>
                      <Text>
                        Deliver To: <Text>{page.deliveryLocationName}</Text>
                      </Text>
                      <Text>
                        Charge To: <Text>{page.chargeCode}</Text>
                      </Text>
                    </View>
                    <Text style={styles.barcode}>{page.id}</Text>
                  </View>

                  <View style={styles.table}>
                    <View style={[styles.row, styles.bold, styles.header]}>
                      <Text style={styles.row0}>#</Text>
                      <Text style={styles.row1}>Stock #</Text>
                      <Text style={styles.row2}>Shelf</Text>
                      <Text style={styles.row3}>Quantity</Text>
                      <Text style={styles.row4}>Unit Price </Text>
                      <Text style={styles.row5}>Extended Price </Text>
                      <Text style={styles.row6}>Issued</Text>
                      <Text style={styles.row7}>Status</Text>
                    </View>
                    {page.requestItems.map((row, i) => {
                      let extendedPrice =
                        (row.requestedQuantity ?? 0) * row.unitPriceUSD;
                      totalPrice = totalPrice + extendedPrice;
                      return (
                        <View>
                          <View key={i} style={styles.row} wrap={false}>
                            <Text style={styles.row0}>{i}</Text>
                            <Text style={styles.row1}>
                              {row.thisItem.siteAssetNumber}
                            </Text>
                            <Text style={styles.row2}>
                              {row.thisItem.subLocation}
                            </Text>
                            <Text style={styles.row3}>
                              {row.requestedQuantity}
                            </Text>
                            <Text style={styles.row4}>
                              ${row.unitPriceUSD} /{" "}
                              {row.thisItem.stockUnitOfMeasure}
                            </Text>
                            <Text style={styles.row5}>${extendedPrice}</Text>
                            <Text style={styles.row6}>
                              {row.issuedQuantity}
                            </Text>
                            <Text style={styles.row7}>
                              {row.orderStatusName}
                            </Text>
                          </View>
                          <View
                            style={{ display: "flex", flexDirection: "row" }}
                            wrap={false}
                          >
                            <Text style={styles.row1}></Text>
                            <Text>{row.thisItem.description}</Text>
                          </View>
                        </View>
                      );
                    })}
                  </View>
                </View>
              </View>
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                }}
                wrap={false}
              >
                <Text style={styles.row1}></Text>
                <Text style={styles.row2}></Text>
                <Text style={styles.row3}></Text>
                <Text style={styles.row4}> Order Total: </Text>
                <Text style={styles.row5}>${totalPrice} </Text>
                <Text style={styles.row6}></Text>
                <Text style={styles.row7}></Text>
              </View>
            </Page>
          );
        })}
      </Document>
    </PDFViewer>
  );
}
