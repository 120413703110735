import React, {useEffect, useState, useContext} from "react";
import { Link } from "react-router-dom";
import { Box, Divider } from "@mui/material";
import MyTests from "./MyTests";
import { UXDataTable } from "../../components/UXDataTable";
import { GlobalTabCollection, GlobalButton, StyledTab, GlobalSecondaryButton } from "../../pages/styles";
import UserContext from "../../context/UserContext";
import Sample from "../../api/LIMS/Sample";
import Draft from "../../api/LIMS/Draft";
import Template from "../../api/LIMS/Template";
import ModalMessages from "../../components/Modal/ModalSimpleButton";
import { applyFiltersToArray } from "../../global";
import FilterMenu from "../../components/FilterMenu";
import ModalTwoButtons from "../../components/Modal/ModalTwoButtons";

const MyDraftCols = [
  { field: 'draftName', headerName: 'Draft Name', type: 'button-text-draft-tests', path: '/newTest'},
  { field: 'draftType', headerName: 'Draft Type', type: 'label' },
  { field: 'draftOwnerEmail', headerName: 'Draft Owner', type: 'label' },
  { field: 'createdDate', headerName: 'Create Date', type: 'datetime' },
  { field: 'lastModifiedDate', headerName: 'Last Modified', type: 'datetime'}
];

const MyTemplateCols = [
  { field: 'templateName', headerName: 'Template Name', type: 'button-text-template-tests', path: '/newTest'},
  { field: 'templateType', headerName: 'Template Type', type: 'label' },
  { field: 'templateOwnerEmail', headerName: 'Template Owner', type: 'label' },
  { field: 'isPublic', headerName: 'Is Public', type: 'boolean' },
  { field: 'createdDate', headerName: 'Create Date', type: 'datetime' },
  { field: 'lastModifiedDate', headerName: 'Last Modified', type: 'datetime'},
  { field: 'messages', headerName: 'Messages', type: 'list-strings' }
];

const filterOptions = [{name: "sampleName", displayName: "MIDAS #", type: "midasNumber", enumValues:[]}, 
  {name: "description",displayName: "Description", type: "string", enumValues:[]}, 
  {name: "chemID",displayName: "Chem ID", type: "string", enumValues:[]},
  {name: "additionalSampleInformation",displayName: "Additional Info", type: "string", enumValues:[]},
  {name: "sampleOwnerEmail",displayName: "Sample Owner", type: "string", enumValues:[]},
  {name: "createdDate",displayName: "Date Created", type: "date", enumValues:[]}
]

const Tests = ({ ...props }) => {
  const [tabValue, setTabValue] = useState(0);
  const [filteringOpen, setFilteringOpen] = useState(false);
  const [filters, setFilters] = useState([{name:null, displayName:null, operator:null, enumValues:[], value:''}])
  const [filteredSamples, setFilteredSamples] = useState([])

  const currentUser = useContext(UserContext);
  const inputSearchCriteria = currentUser.username

  const [modalMessagesOpen, setModalMessagesOpen] = useState(false);
  const modalMessagesButtonText = 'Ok'
  const [modalMessagesTitle, setModalMessagesTitle] = useState('');
  const [modalMessagesText, setModalMessagesText] = useState('');

  const [mySamples, setMySamples]  = useState(null)
  const [isTestDataLoading, setIsTestDataLoading] = useState(true);

  const [myDrafts, setMyDrafts]  = useState(null)
  const [isDraftDataLoading, setIsDraftDataLoading] = useState(true);

  const [myTemplates, setMyTemplates] = useState(null)
  const [isTemplateDataLoading, setIsTemplateDataLoading] = useState(true);

  const [refreshTrigger, setRefreshTrigger] = useState(false)
  const [dataRefreshTrigger, setDataRefreshTrigger] = useState(false)

  const [modalDeleteItemOpen, setModalDeleteItemOpen] = useState(false);
  const [modalDeleteItemTitle, setModalDeleteItemTitle] = useState("");
  const [modalDeleteItemText, setModalDeleteItemText] = useState("");
  const [modalDeleteItemButton1Text, setModalDeleteItemButton1Text] =
    useState("");
  const [modalDeleteItemButton2Text, setModalDeleteItemButton2Text] =
    useState("");
  const [removeItem, setRemoveItem] = useState();

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const noDataMessage = `No tests found for submitter or requester = ${inputSearchCriteria}`
  const noDataDraftsMessage  = `No test drafts found for owner ${inputSearchCriteria}`
  const noDataTemplatesMessage  = `No test templates found for owner ${inputSearchCriteria}`
  
  function closeModalMessages() {
    setModalMessagesOpen(false);
  }

  function openModalMessages(title, text) {
    setModalMessagesOpen(true);
    setModalMessagesTitle(title);
    setModalMessagesText(text);
  }

  useEffect(() => {
    let cancelPromise = false

    async function getData(perPage, page, allData = []) {
      let data = [];

      if (cancelPromise) return allData
  
      await Sample.myTests(inputSearchCriteria, page, perPage).then((res) => {
        data = res ? res.result : null
      });
    
      if (data !== null) {
        allData = [...allData, ...data]
      }
    
      if (data === null) {
        setIsTestDataLoading(false)
        return allData;
      } else {
        page++;
        setMySamples(allData)
        return getData(perPage, page, allData);
      }
    }

    getData(50, 1)

    return () => {
      cancelPromise = true
    }
  
  }, [inputSearchCriteria, refreshTrigger])

  useEffect(() => {
    let cancelPromise = false

    setIsDraftDataLoading(true)  

    Draft.getByUserAndType(inputSearchCriteria, "methods").then((res) => {
      if (cancelPromise) return
      setMyDrafts(res)
      setIsDraftDataLoading(false)     
    })

  }, [inputSearchCriteria])

  useEffect(() => {
    let cancelPromise = false

    setIsTemplateDataLoading(true)  

    Template.searchTemplates(inputSearchCriteria, '', "methods", 'true').then((res) => {
      if (cancelPromise) return
      setMyTemplates(res)    
      setIsTemplateDataLoading(false)  
    })

    return () => {
      cancelPromise = true
    }
  }, [inputSearchCriteria, dataRefreshTrigger])

  const RemoveDraft = () => {
    if (removeItem && removeItem.id) {
      Draft.delete(removeItem.id).then((res) => {
        closeModalDeleteItem();
        if (res.message === "Success") {
          openModalMessages("Draft Deleted", "Draft successfully deleted!");
          setMyDrafts(null);
        } else {
          openModalMessages(
            "Draft Failed to Delete",
            `${res.message}. Contact support if you feel this is an error.`
          );
        }
      });
    }
  };

  function closeModalDeleteItem() {
    setModalDeleteItemOpen(false);
  }

  function openModalDeleteItem(title, text, button1, button2, item) {
    setModalDeleteItemOpen(true);
    setModalDeleteItemTitle(title);
    setModalDeleteItemText(text);
    setModalDeleteItemButton1Text(button1);
    setModalDeleteItemButton2Text(button2);
    setRemoveItem(item);
  }

  const ConfirmRemoveItem = (item, type) => {
    if (item) {
      openModalDeleteItem(`Delete ${type}`, "Are you sure?", "Yes", "No", item);
    }
  };

  const menuItemsDrafts = [{
    menuType: 'text',
    redirectPath: '',
    text: 'Delete Draft',
    onClickFunction: (item) => ConfirmRemoveItem(item, 'Draft')
  }]

  const RemoveTemplate = (() => {
    closeModalDeleteItem();
    if (removeItem){

      if (inputSearchCriteria !== removeItem.templateOwnerEmail)
      {
        openModalMessages("Template Error", "You cannot delete a template from another user!")
        return
      }

      Template.delete(removeItem.templateOwnerEmail, removeItem.templateName, 'methods').then((res) => {
        if (res.message === 'Success')
        {
          openModalMessages("Template Deleted", "Template successfully deleted!")
          setMyTemplates(null)
        } else {
          openModalMessages('Template Failed to Delete', `${res.message}. Contact support if you feel this is an error.`);
        }
      })
    }
  })

  const UpdateTemplatePublicFlag = ((template) => {
    if (template){

      if (inputSearchCriteria !== template.templateOwnerEmail)
      {
        openModalMessages("Template Error", "You cannot update a template from another user!")
        return
      }

      template.isPublic = !template.isPublic

      Template.update(template).then((res) => {
        if (res.message === 'Success')
        {
          openModalMessages("Template Updated", "Template successfully updated!")
          setMyTemplates(null)
        } else {
          openModalMessages('Template Failed to Update', `${res.message}. Contact support if you feel this is an error.`);
        }
      })
    }
  })

  const menuItemsTemplates = [{
    menuType: 'text',
    redirectPath: '',
    text: 'Delete Template',
    onClickFunction: (item) => ConfirmRemoveItem(item, 'Template')
  },
  {
    menuType: 'text',
    redirectPath: '',
    text: 'Change Public Status',
    onClickFunction: UpdateTemplatePublicFlag
  }]

  useEffect(() => {
    var filteredArray = applyFiltersToArray(filters, mySamples)
    setFilteredSamples(filteredArray)
  }, [filters, mySamples])

  function closeFiltering() {
    setFilteringOpen(false);
  }

  function applyFilters() {
    setFilteringOpen(false);
  }

  const filterClick = (event) => {
    setFilteringOpen(true);
  }

  const clearFiltersClick = (event) => {
    setFilters([{name:null, displayName:null, operator:null, enumValues:[], value:''}])  
  }

  const renderSearchTab = () => {
    switch (tabValue) {
      case 0: {
        return <MyTests mySamples={filteredSamples} isTestDataLoading={isTestDataLoading} noDataMessage={noDataMessage} refreshTrigger={refreshTrigger} setRefreshTrigger={setRefreshTrigger} userEmail={inputSearchCriteria}></MyTests>;
      }
      case 1: {
          return <UXDataTable tableWidth='80%' cols={MyDraftCols} rows={myDrafts === null ? [] : myDrafts.sort((a, b) => b.draftName - a.draftName)} moreOptionsCell={true} enablePaging={true} 
                       noDataMessage={noDataDraftsMessage} menuProps={menuItemsDrafts} defaultRowsPerPage={10} isDataLoading={isDraftDataLoading} tableName={'testDrafts'} enableSorting={true}></UXDataTable>
      }
      case 2: {
        return <UXDataTable tableWidth='90%' cols={MyTemplateCols} rows={myTemplates === null ? [] : myTemplates.sort((a, b) => a.templateName - b.templateName)} moreOptionsCell={true} enablePaging={true} 
                      noDataMessage={noDataTemplatesMessage} menuProps={menuItemsTemplates} defaultRowsPerPage={10} isDataLoading={isTemplateDataLoading} tableName={'testTemplates'}
                      dataRefreshTrigger={dataRefreshTrigger} setDataRefreshTrigger={setDataRefreshTrigger} enableSorting={true}></UXDataTable>
      }
      default: {
        alert(tabValue);
      }
    }
  };

  return (
    <>
      <span className='pageheader'>Tests</span>
      <Divider className='dividerbar' />
      <div>
        <Box sx={{ bgcolor: "#fff", pt:3, pb:1 }} display="flex">
          <GlobalTabCollection style={{margineRight:"1rem"}} value={tabValue} onChange={handleChange} aria-label='ant example'>
            <StyledTab label='My Tests' />
            <StyledTab label='Drafts' />
            <StyledTab label='Templates' />
          </GlobalTabCollection>

              <GlobalButton 
                style={{marginTop:"-.8rem"}}
                component={Link}
                to='/newTest'
                variant='contained'
              >New Test
              </GlobalButton>

              <Box display="flex" alignItems={"center"} marginLeft="auto" marginTop="-.8rem">
                <GlobalButton disabled={tabValue === 1 || tabValue === 2} style={{marginRight:"1rem"}} variant="contained"
                  onClick={() => filterClick()}>Filters</GlobalButton>

                {!(filters[0].name === null) && 
                <GlobalSecondaryButton  variant="contained" 
                  onClick={() => clearFiltersClick()}>Clear Filters</GlobalSecondaryButton>}
              </Box>

          <Box sx={{ p: 1 }} />
        </Box>
        
        {renderSearchTab()}
      </div>

      <ModalTwoButtons
        title={modalDeleteItemTitle}
        button1Text={modalDeleteItemButton1Text}
        button1Action={modalDeleteItemTitle?.includes('Draft') ? RemoveDraft : RemoveTemplate}
        button2Text={modalDeleteItemButton2Text}
        button2Action={closeModalDeleteItem}
        open={modalDeleteItemOpen}
        setOpen={setModalDeleteItemOpen}
      >
        <div style={{ textAlign: "center" }}>
          <label>{modalDeleteItemText}</label>
        </div>
      </ModalTwoButtons>

      {/* Informational Messages */}
      <ModalMessages title={modalMessagesTitle} buttonText={modalMessagesButtonText} buttonAction={closeModalMessages} open={modalMessagesOpen} setOpen={setModalMessagesOpen}>
        <label>
            {modalMessagesText}
        </label>     
      </ModalMessages>

      <FilterMenu 
        open={filteringOpen} 
        setOpen={setFilteringOpen} 
        applyBtnAction={applyFilters} 
        cancelButtonAction={closeFiltering} 
        filteringInfo={filterOptions} 
        appliedFilters={filters} 
        setAppliedFilters={setFilters}/>
    </>
  );
};

export default Tests;