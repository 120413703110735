import API from "..";

export default class CertificationResultComponent {
  /**
   * The Sample dimension table for M3
   * @param {String} certificationResultName The MIDAS of the Sample
   */
  constructor({
    certificationResultName,
    createdByEmail,
    createdDate,
    lastModifiedByEmail,
    lastModifiedDate,
    comments,
    masterResultComponentName,
  }) {
    this.certificationResultName = certificationResultName;
    this.createdByEmail = createdByEmail;
    this.createdDate = createdDate;
    this.lastModifiedByEmail = lastModifiedByEmail;
    this.lastModifiedDate = lastModifiedDate;
    this.comments = comments;
    this.masterResultComponentName = masterResultComponentName;
  }

  static async getAll() {
    const api = await API();
    const { data } = await api.get(`/certificationresultcomponent`);

    return data.result.map((d) => new CertificationResultComponent(d));
  }

  static async create(certificationresultcomponent) {
    const api = await API();
    const { data } = await api.post(`/certificationresultcomponent`, certificationresultcomponent);
    return data;
  }
}
