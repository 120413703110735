import React from "react";
import CustomModal from './index';
//import { Button } from "@mui/material";

const ModalNoButton = ({ children, title, open, setOpen }) => {

    return (
        <>
            <CustomModal title={title} open={open} setOpen={setOpen}>
                {children ? children : <></>}


                <div style={{
                    display: "flex",
                    justifyContent: "center",
                }}>
                </div>

            </CustomModal>
        </>
    );
};

export default ModalNoButton;
