import API from '../index'

export default class STStand {
    constructor(
        StandName,
        CreatedByEmail,
        CreatedDate,
        LastModifiedByEmail,
        LastModifiedDate,
        IsActive,
        StandTypeName,
        ThisStandType,
        ETQID,
    ) {
        this.StandName = StandName
        this.CreatedByEmail = CreatedByEmail
        this.CreatedDate = CreatedDate
        this.LastModifiedByEmail = LastModifiedByEmail
        this.LastModifiedDate = LastModifiedDate
        this.IsActive = IsActive
        this.StandTypeName = StandTypeName
        this.ThisStandType = ThisStandType
        this.ETQID = ETQID
    }

    static async getAll() {
        const api = await API()
        const { data } = await api.get('/ststand')
        return data.result.map(stand => new STStand(stand))
    }

    static async createTestStand(payload) {
        const api = await API();
        const { data } = await api.post('/ststand', payload);
        if (data.result) {
            return new STStand(data.result);
        }
        return data;
    }

    static async updateTestStand(testStand) {
        const api = await API();
        const { data } = await api.put('/ststand', testStand);
        if (data.result) {
            return new STStand(data.result);
        }
        return data;
    }
}