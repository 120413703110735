import { useState, useEffect, useCallback } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import LoadingButton from "@mui/lab/LoadingButton";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";

import MethodSelection from "../../../../components/MethodSelection";
import {
  INITIAL_ERROR_CHECKS_FOR_CONTAINER,
  areTwoIntervalsTheSame,
  getContainersForValidation,
  getRetainContainersForValidation,
} from "../../shared";
import { generateUniqueID } from "../../../../global";
import type { ChemId, Methods, RetainContainer, Interval, GeneralInformation } from "../../types";
import { IntervalTable } from "./IntervalTable";
import { ContainerManagement } from "./ContainerManagement";
import { RetainContaineringTable } from "./RetainContaineringTable";
import Container from "../../../../api/LIMS/Container";
import { toast } from "react-hot-toast";
import { useDetailedStRequest } from "../../context/DetailedStRequestContext";
import { TestContaineringTable } from "./TestContaineringTable";
import { useMsal } from "@azure/msal-react";
import { BuildTestingPayload } from "../../../../global";
import {
  SideInformationProps,
  useSideImportantInformation,
} from "../../context/SideImportantInformationContext";

type IntervalSectionProps = {
  intervals: Interval[];
  setIntervals(newIntervals: Interval[]): void;
  intervalSamplesToRegister: Interval[];
  setIntervalSamplesToRegister(newSamples: Interval[]): void;
  containersReturnLocation: string;
  setContainersReturnLocation(newReturnLocation: string): void;
  generalInformation: GeneralInformation;
};

const IntervalSection = ({
  intervals,
  setIntervals,
  intervalSamplesToRegister,
  setIntervalSamplesToRegister,
  containersReturnLocation,
  setContainersReturnLocation,
  generalInformation,
}: IntervalSectionProps) => {
  const [methods, setMethods] = useState<Methods[]>([]);
  const [retainContainers, setRetainContainers] = useState<RetainContainer[]>([]);
  const [selectedInterval, setSelectedInterval] = useState<Interval | null>(null);
  const [selectedChemID, setSelectedChemID] = useState<ChemId | null>(null);
  const [loading, setLoading] = useState(false);
  const { locations, containerTypes, canManageSampleSchedule, uoms, locationNames } =
    useDetailedStRequest();
  const { accounts } = useMsal();
  const { setSideImportantInformation } = useSideImportantInformation();

  const userEmail = accounts && accounts[0] ? accounts[0].username : null;

  const containeringMethodSelected =
    intervals.find((i) => i.id === selectedInterval?.id)?.containeringMethod ?? "";

  useEffect(() => {
    if (selectedInterval) {
      const newIntervals = intervals.map((interval) => {
        if (areTwoIntervalsTheSame(interval, selectedInterval)) {
          return {
            ...interval,
            testJSON: JSON.stringify(methods),
          };
        }
        return interval;
      });

      setIntervals(newIntervals);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [methods]);

  useEffect(() => {
    if (retainContainers.length && selectedInterval) {
      const newIntervals = intervals.map((interval) => {
        if (areTwoIntervalsTheSame(interval, selectedInterval)) {
          return {
            ...interval,
            retainContainerJSON: JSON.stringify(retainContainers),
          };
        }
        return interval;
      });
      setIntervals(newIntervals);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [retainContainers]);

  useEffect(() => {
    if (selectedInterval && selectedChemID && selectedChemID.chemID) {
      const newList = intervals.map((interval) => {
        if (areTwoIntervalsTheSame(interval, selectedInterval)) {
          const newInterval: Interval = {
            ...interval,
            chemID: selectedChemID.chemID,
            intervalSubstance: selectedChemID,
          };

          return newInterval;
        }
        return interval;
      });

      setIntervals(newList);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedChemID]);

  useEffect(() => {
    if (selectedInterval) {
      if (selectedInterval.testJSON) {
        const parsedTests = JSON.parse(selectedInterval.testJSON);
        if (parsedTests && parsedTests.length > 0) {
          setMethods(parsedTests);
        } else {
          setMethods([]);
        }
      } else {
        setMethods([]);
      }
      if (selectedInterval.retainContainerJSON) {
        const parsedRetainContainers: RetainContainer[] = JSON.parse(
          selectedInterval.retainContainerJSON,
        );
        if (parsedRetainContainers && parsedRetainContainers.length > 0) {
          const retainContainersWithIds = getRetainContainersWithId(parsedRetainContainers);

          setRetainContainers(retainContainersWithIds);
        } else {
          setRetainContainers([]);
        }
      } else {
        setRetainContainers([]);
      }
    } else {
      setMethods([]);
    }
  }, [selectedInterval]);

  // artificially adding IDs to handle React rendering correctly
  function getRetainContainersWithId(parsedRetainContainers: RetainContainer[]) {
    return parsedRetainContainers.map((prc) => {
      if (!prc.id) {
        prc.id = generateUniqueID();
      }
      return prc;
    });
  }

  const isSelectedIntervalLocked =
    selectedInterval &&
    selectedInterval.intervalSampleName &&
    selectedInterval.intervalSampleName !== "";

  const getUpdatedListOfContainers = useCallback(
    (newContainers: any[], areTestContainers: boolean) => {
      const containers: any[] = [];

      if (areTestContainers) {
        newContainers.forEach((nc) => containers.push(nc));

        // maintain the retainContainers
        if (selectedInterval!.intervalSample && selectedInterval!.intervalSample.containers) {
          selectedInterval!.intervalSample.containers.forEach((c: any) => {
            if (c._isRetainContainer) {
              containers.push(c);
            }
          });
        }
      } else {
        // maintain the testContainers
        if (selectedInterval!.intervalSample && selectedInterval!.intervalSample.containers) {
          selectedInterval!.intervalSample.containers.forEach((c: any) => {
            if (!c._isRetainContainer) {
              containers.push(c);
            }
          });
        }

        newContainers.forEach((nc) => containers.push(nc));
      }

      return containers;
    },
    [selectedInterval],
  );

  const updateSampleContainers = useCallback(
    (newContainers: any[], areTestContainers: boolean) => {
      if (selectedInterval && !selectedInterval.intervalSampleName) {
        const newSelectedInterval: Interval = { ...selectedInterval };

        const updatedSample = { ...selectedInterval.intervalSample };

        updatedSample.containers = getUpdatedListOfContainers(newContainers, areTestContainers);

        newSelectedInterval.intervalSample = updatedSample;

        setSelectedInterval(newSelectedInterval);

        const newList = intervals.map((val) => {
          if (areTwoIntervalsTheSame(val, newSelectedInterval)) {
            return {
              ...val,
              intervalSample: updatedSample,
            };
          }
          return val;
        });

        setIntervals(newList);
      } else {
        toast.error("Cannot update container after sample registration");
      }
    },
    [selectedInterval, getUpdatedListOfContainers, intervals, setIntervals],
  );

  async function handleGetTestContainers() {
    if (
      selectedInterval &&
      containeringMethodSelected &&
      containeringMethodSelected !== "" &&
      methods &&
      methods.length > 0
    ) {
      const interval = intervals.find((i) => {
        if (selectedInterval.id && i.id) {
          return selectedInterval.id === i.id;
        }

        return selectedInterval.intervalName !== i.intervalName;
      });

      if (interval) {
        let newTests = [];

        for (const oMethodInfo of methods) {
          //need to pass in the charge code and test requester
          const tests = await BuildTestingPayload(
            oMethodInfo,
            null,
            null,
            generalInformation.billingReference,
            generalInformation.requestedByEmail,
          );

          if (tests === null || typeof tests === "string" || typeof tests[0] === "string") {
            if (Array.isArray(tests)) {
              tests.map((message) => {
                return toast.error(`There was an error building the test payload. ${message}`);
              });
              return;
            } else {
              toast.error(`There was an error building the test payload. ${tests ? tests : ""}`);
              return;
            }
          }

          for (const oTest of tests) {
            newTests.push(oTest);
          }
        }

        const payload: any = {
          containeringMethod: interval.containeringMethod,
          requireParentContainer: false,
          tests: newTests,
        };

        setLoading(true);

        const response = await Container.GetContainersToCreate(payload);

        if (
          response &&
          response.result &&
          response.message === "Success" &&
          response.result.length > 0
        ) {
          const testContainers: any[] = getContainersForValidation(
            locations,
            response.result,
            userEmail,
          );

          const retainContainers = getRetainContainersForValidation(
            locations,
            interval.retainContainerJSON,
            uoms,
            containerTypes,
            userEmail,
          );

          const updatedContainers = [...testContainers, ...retainContainers];

          updateContainerErrorChecksByContainerLength(
            testContainers.length + retainContainers.length,
          );

          const newIntervals = intervals.map((interval) => {
            if (areTwoIntervalsTheSame(interval, selectedInterval)) {
              return {
                ...interval,
                intervalSample: {
                  containers: updatedContainers,
                },
              };
            }
            return interval;
          });

          setIntervals(newIntervals);
          setSelectedInterval({
            ...interval,
            testJSON: JSON.stringify(methods),
            intervalSample: {
              containers: updatedContainers,
            },
          });
        } else {
          toast.error(
            response.message ??
              "An unknown error has occurred. If the problem persists, contact a system administrator.",
          );
        }

        setLoading(false);
      }
    } else {
      toast.error(
        "You need to select a containering method and at least one method to get the default containers",
      );
    }
  }

  const getDefaultContainersDisabled =
    !selectedInterval ||
    !methods ||
    methods.length === 0 ||
    !containeringMethodSelected ||
    containeringMethodSelected === "" ||
    !!(selectedInterval.intervalSampleName && selectedInterval.intervalSampleName !== "");

  const updateContainerErrorChecksByContainerLength = useCallback((length: number) => {
    const errorChecks: any[] = [];

    const errorArraySize = Array(length).fill(0);

    errorArraySize.forEach(() => {
      errorChecks.push(INITIAL_ERROR_CHECKS_FOR_CONTAINER);
    });
  }, []);
  const handleSideInformation = useCallback(
    (newSelected: Interval) => {
      const intervalAlreadyRegistered = !!intervalSamplesToRegister.find((ri) => {
        const registeredIntervalId = ri.intervalName;

        return registeredIntervalId === newSelected.id;
      });
      const intervalDisabledForRegister = !!(
        newSelected.intervalSampleName && newSelected.intervalSampleName !== ""
      );
      let info: SideInformationProps[] = [
        { label: "Interval Name", value: newSelected.intervalName },
        { label: "MIDAS #", value: newSelected.intervalSampleName },
        { label: "Chem ID", value: newSelected.chemID },
        { label: "PSIMS ID", value: newSelected.intervalSample?.psimsid },
        { label: "Initial Location", value: newSelected.initialLocationName },
        { label: "Return Location", value: newSelected.returnLocationName },
        {
          label: "Registered",
          value: intervalAlreadyRegistered || intervalDisabledForRegister ? "Yes" : "No",
        },
      ];

      setSideImportantInformation(info);
    },
    [setSideImportantInformation, intervalSamplesToRegister],
  );
  const handleUpdateSelectedInterval = useCallback(
    (newSelected: Interval) => {
      handleSideInformation(newSelected);
      setSelectedInterval(newSelected);
      if (newSelected.intervalSample && newSelected.intervalSample.containers) {
        updateContainerErrorChecksByContainerLength(newSelected.intervalSample.containers.length);
      }
    },
    [updateContainerErrorChecksByContainerLength, handleSideInformation],
  );

  const testContainersForSample: any[] =
    selectedInterval &&
    selectedInterval.intervalSample &&
    selectedInterval.intervalSample.containers
      ? selectedInterval.intervalSample.containers.filter((c: any) => c.tests && c.tests.length > 0)
      : [];

  const retainContainersForSample: any[] =
    selectedInterval &&
    selectedInterval.intervalSample &&
    selectedInterval.intervalSample.containers
      ? selectedInterval.intervalSample.containers.filter(
          (c: any) => !c.tests || c.tests.length === 0,
        )
      : [];

  return (
    <Box display="flex" flexDirection="column" gap={2}>
      <Box>
        <IntervalTable
          intervalSamplesToRegister={intervalSamplesToRegister}
          intervals={intervals}
          selectedInterval={selectedInterval}
          setIntervalSamplesToRegister={setIntervalSamplesToRegister}
          setIntervals={setIntervals}
          setMethods={setMethods}
          setSelectedInterval={handleUpdateSelectedInterval}
          selectedChemID={selectedChemID}
          setSelectedChemID={setSelectedChemID}
          setContainersReturnLocation={setContainersReturnLocation}
        />
      </Box>
      <Box>
        <MethodSelection
          width="100%"
          selectedMethods={methods}
          setSelectedMethods={setMethods}
          showCompletionDate={false}
          showContainerGrouping={false}
          incomingTemplate={null}
          showSearchTemplateName={false}
          showEstimateAndPriority={true}
          maxMethodsAllowed={999}
          isReadOnly={!!(!canManageSampleSchedule || isSelectedIntervalLocked || !selectedInterval)}
        />
      </Box>
      <Box display="flex" flexDirection="column" alignItems="flex-start" maxWidth={"1300px"}>
        <RetainContaineringTable
          enableAddIcon={
            !!(canManageSampleSchedule && !isSelectedIntervalLocked && selectedInterval)
          }
          retainContainers={retainContainers}
          setRetainContainers={(newContainers: any[]) => {
            if (retainContainersForSample.length === 0) {
              setRetainContainers(newContainers);
            } else {
              updateSampleContainers(newContainers, false);
            }
            if (selectedInterval) {
              const newList = intervals.map((val) => {
                if (areTwoIntervalsTheSame(val, selectedInterval)) {
                  return {
                    ...val,
                    retainContainerJSON: JSON.stringify(newContainers),
                  };
                }
                return val;
              });

              setIntervals(newList);
            }
          }}
          retainContainersForSample={retainContainersForSample}
          selectedInterval={selectedInterval}
          setSelectedInterval={(newSelectedInterval) => {
            if (selectedInterval && !selectedInterval.intervalSampleName) {
              setSelectedInterval(newSelectedInterval);
              const indexToBeReplaced = intervals.findIndex((i) =>
                areTwoIntervalsTheSame(i, newSelectedInterval),
              );

              if (indexToBeReplaced > -1) {
                const updatedIntervals = structuredClone(intervals);

                updatedIntervals[indexToBeReplaced] = newSelectedInterval;
                setIntervals(updatedIntervals);
              }
            } else {
              toast.error("Cannot update container after sample registration");
            }
          }}
        />
      </Box>
      <Box display="flex" flexDirection="column" alignItems="flex-start">
        <ContainerManagement
          disabled={!!(!canManageSampleSchedule || !selectedInterval || isSelectedIntervalLocked)}
          intervals={intervals}
          methods={methods}
          selectedInterval={selectedInterval}
          setIntervals={setIntervals}
          setSelectedInterval={setSelectedInterval}
          containeringMethodSelected={containeringMethodSelected}
        />
      </Box>
      <Box display="flex" flexDirection="column" alignItems="flex-start" maxWidth={"1300px"}>
        <Box display="flex" flexDirection="row" gap="1rem" alignItems="center" marginBottom={2}>
          <Typography fontSize={20} color="black">
            Test Containering
          </Typography>
          <LoadingButton
            variant="outlined"
            onClick={handleGetTestContainers}
            disabled={getDefaultContainersDisabled}
            style={{ textTransform: "initial" }}
            loading={loading}>
            Get Containers
          </LoadingButton>
        </Box>
        <Autocomplete
          value={containersReturnLocation}
          isOptionEqualToValue={(option, value) => value === option}
          disablePortal
          options={locationNames}
          renderInput={(params) => <TextField {...params} label="Return Location" size="small" />}
          autoSelect
          onChange={(_, v) => {
            if (v) {
              setContainersReturnLocation(v);
            }
          }}
          id="containersReturnLocation"
          disabled={!!(!canManageSampleSchedule || isSelectedIntervalLocked || !selectedInterval)}
          style={{ minWidth: "25%", marginBottom: "1rem" }}
        />
        {selectedInterval &&
          selectedInterval.intervalSample &&
          selectedInterval.intervalSample.containers && (
            <TestContaineringTable
              canUpdateFields={canManageSampleSchedule}
              initialSize={testContainersForSample.length}
              selectedInterval={selectedInterval}
              testContainersForSample={testContainersForSample}
              updateSampleContainers={(newContainers: any[]) => {
                updateSampleContainers(newContainers, true);
              }}
            />
          )}
      </Box>
    </Box>
  );
};

export default IntervalSection;
