import API from '../index'

export default class STQueue {
    constructor({
        workGroupName,
        isActive
    }) {
        this.workGroupName = workGroupName
        this.isActive = isActive
    }

    static async getAll() {
        const api = await API()
        const { data } = await api.get('/stqueue')
        return data.result.map(parameter => new STQueue(parameter))
    }

    static async get(name) {
        const api = await API();
        const { data } = await api.get(`stqueue/${name}`);
        if (data.result) {
            return new STQueue(data.result);
        }
        else {
            return null;
        }
    }

    static async add(payload) {
        const api = await API();
        const { data } = await api.post('/stqueue', payload);
        return new STQueue(data.result);
    }

    static async update(payload) {
        const api = await API();
        const { data } = await api.put('/stqueue', payload);
        return new STQueue(data.result);
    }

    static async getAllActiveQueueOptions() {
        const api = await API()
        const { data } = await api.get('/stqueue')
        const queueOptions = [];
        if (data && data.result && data.result.length > 0) {
            data.result.forEach((queue) => {
                if (queue.isActive) {
                    queueOptions.push(queue.workGroupName);
                }
            })
        }
        return queueOptions;
    }
}
