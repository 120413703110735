import React, { useState, useEffect, useContext, useRef } from "react";
import {
  Box,
  Typography,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  Checkbox
} from '@mui/material'
import { StyledTableCellSmall, StyledTableCellMedium, StyledTableCellLarge, StyledGridForm } from './styles'
import { StyledTableBody, UXDataTableWithoutBody, StyledTableRow } from '../../components/UXDataTable'
import ModalTwoButtons from '../../components/Modal/ModalTwoButtons'
import ModalMessages from "../../components/Modal/ModalSimpleButton";
import FilterMenu from "../../components/FilterMenu";
import DisplayMessage from "./components/DisplayMessage";

import { VendorContext } from './context/VendorContext'
import { GlobalButton, GlobalSecondaryButton } from "../../pages/styles";
import { applyFiltersToArray } from "../../global";

const manageVendorCols = ['Vendor Key', 'Name', 'Active', 'Min Order', 'Blanket PO', 'Address 1', 'Address 2', 'City', 'State', 'ZIP', 'Contact', 'Phone', 'Email Address']
const americanStates = ['AL', 'AK', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DE', 'FL', 'GA', 'HI', 'ID', 'IL', 'IN', 'IA', 'KS', 'KY', 'LA', 'ME', 'MD', 'MA', 'MI', 'MIN', 'MS', 'MO', 'MT', 'NE', 'NV', 'NH', 'NJ', 'NM', 'NY', 'NC', 'ND', 'OH', 'OK', 'OR', 'PA', 'RI', 'SC', 'SD', 'TN', 'TX', 'UT', 'VT', 'VA', 'WA', 'WV', 'WI', 'WY']

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 230,
    },
  },
}

const SRManageVendor = ({ canEdit }) => {
  const [vendorKey, setVendorKey] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')
  const [selectedVendor, setSelectedVendor] = useState({})
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isSaving, setIsSaving] = useState(false)
  const { getAllVendors, vendors, isDataLoading, manageVendor } = useContext(VendorContext)
  const vendorInfoFormRef = useRef(null)
  const [modalMessageIsOpen, setModalMessageIsOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState({
    title: "",
    message: "",
  });
  const [filteringOpen, setFilteringOpen] = useState(false);
  const [filters, setFilters] = useState([
    {
      name: null,
      displayName: null,
      operator: null,
      enumValues: [],
      value: "",
    },
  ]);
  const [modalMessagesOpen, setModalMessagesOpen] = useState(false);
  const modalMessagesButtonText = 'Ok'
  const [modalMessagesTitle, setModalMessagesTitle] = useState('');
  const [modalMessagesText, setModalMessagesText] = useState('');

  const filteredVendors = applyFiltersToArray(filters, vendors)
    .sort((a, b) => a?.vendorCode?.localeCompare(b?.vendorCode));

  const alreadyUsedVendorKey = !!(vendors.find(vendor => vendor?.vendorCode === vendorKey))

  const handlePageChange = (event, page) => {
    setPage(page)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  function closeModalMessages() {
    setModalMessagesOpen(false);
  }

  function openModalMessages(title, text) {
    setModalMessagesOpen(true);
    setModalMessagesTitle(title);
    setModalMessagesText(text);
  }


  const filterOptions = [
    { name: "vendorCode", displayName: "Vendor Key", type: "string", enumValues: [] },
    {
      name: "vendorName",
      displayName: "Name",
      type: "string",
      enumValues: [],
    },
    {
      name: "isActive",
      displayName: "Active",
      type: "string",
      enumValues: [],
    },
    {
      name: "minimumOrderQuantity",
      displayName: "Min Order",
      type: "integer",
      enumValues: [],
    },
    {
      name: "blanketPO",
      displayName: "Blanket PO",
      type: "enum",
      enumValues: ["CREDIT CARD", "INVOICE", "MONTHLY INVOICE", "PCARD"],
    },
    {
      name: "addressLine1",
      displayName: "Address 1",
      type: "string",
      enumValues: [],
    },
    {
      name: "addressLine2",
      displayName: "Address 2",
      type: "string",
      enumValues: [],
    },
    {
      name: "city",
      displayName: "City",
      type: "string",
      enumValues: [],
    },
    {
      name: "state",
      displayName: "State",
      type: "enum",
      enumValues: americanStates,
    },
    {
      name: "zipCode",
      displayName: "Zip",
      type: "string",
      enumValues: [],
    },
    {
      name: "contactName",
      displayName: "Contact",
      type: "string",
      enumValues: [],
    },
    {
      name: "phoneNumber",
      displayName: "Phone",
      type: "string",
      enumValues: [],
    },
    {
      name: "vendorEmail",
      displayName: "Email Address",
      type: "string",
      enumValues: [],
    }
  ];

  useEffect(() => {
    if (!vendors.length) {
      getAllVendors()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function closeFiltering() {
    setFilteringOpen(false);
  }

  function applyFilters() {
    setFilteringOpen(false);
  }

  const filterClick = (event) => {
    setFilteringOpen(true);
  };

  const clearFiltersClick = (event) => {
    setFilters([
      {
        name: null,
        displayName: null,
        operator: null,
        enumValues: [],
        value: null,
      },
    ]);
  };

  const renderFilterMenu = () => {
    //switch (tabValue) {
    //case 0: {
    return (
      <FilterMenu
        open={filteringOpen}
        setOpen={setFilteringOpen}
        applyBtnAction={applyFilters}
        cancelButtonAction={closeFiltering}
        filteringInfo={filterOptions}
        appliedFilters={filters}
        setAppliedFilters={setFilters}
      />
    );
    //}
    // case 1: {
    //   return (
    //     <FilterMenu
    //       open={filteringOpen}
    //       setOpen={setFilteringOpen}
    //       applyBtnAction={applyFilters}
    //       cancelButtonAction={closeFiltering}
    //       filteringInfo={filterOptions}
    //       appliedFilters={filters}
    //       setAppliedFilters={setFilters}
    //     />
    //   );
    // }

    // case 2: {
    //   return <></>;
    // }
    // default: {
    //   alert(tabValue);
    // }
    //}
  };

  return (
    <Box display="flex" flexDirection="column">
      <Box display="flex" alignItems="flex-start" justifyContent="space-between" marginBottom={alreadyUsedVendorKey ? 2 : 4}>
        <Box display="flex" alignItems="flex-start" gap={1} width="50%">
          <Box display="flex" flexDirection="column" gap={1}>
            <TextField
              size="small"
              type="text"
              placeholder="New Vendor Key"
              value={vendorKey}
              onChange={(e) => setVendorKey(e.target.value.toUpperCase())}
              error={alreadyUsedVendorKey}
            />
            {alreadyUsedVendorKey && (
              <Typography color="red" fontSize={14}>This Vendor Key is already being used.</Typography>
            )}
          </Box>
          <GlobalButton
            disabled={alreadyUsedVendorKey || !vendorKey || !canEdit}
            variant="contained"
            onClick={(e) => setIsModalOpen(true)}
            style={{ width: 200 }}
          >
            Add Vendor
          </GlobalButton>
        </Box>
        <Box display="flex" alignItems="center" justifyContent="flex-end" gap={2} width="50%">
          <GlobalButton
            style={{ marginRight: "1rem" }}
            variant="contained"
            onClick={() => filterClick()}
          >
            Filters
          </GlobalButton>
          {!(filters[0].name === null) && (
            <GlobalSecondaryButton
              variant="contained"
              onClick={() => clearFiltersClick()}
            >
              Clear Filters
            </GlobalSecondaryButton>
          )}
        </Box>
      </Box>
      <UXDataTableWithoutBody
        tableWidth='100%'
        cols={manageVendorCols}
        tableId="manageVendorTable"
        rowLength={filteredVendors.length}
        enablePaging
        rowsPerPage={rowsPerPage}
        page={page}
        handleChangePage={handlePageChange}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        noDataFoundMessage={'No vendor found'}
        isDataLoading={isDataLoading}
      >
        <StyledTableBody>
          {filteredVendors?.length > 0 && filteredVendors?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)?.map((vendor) => {
            return (
              <StyledTableRow
                key={vendor?.id}
                hover
                onClick={() => {
                  if (canEdit) {
                    setIsModalOpen(true)
                    setSelectedVendor(vendor)
                    setPhoneNumber(vendor.phoneNumber)
                  }
                }}
              >
                <StyledTableCellMedium>{vendor?.vendorCode}</StyledTableCellMedium>
                <StyledTableCellLarge>{vendor?.vendorName?.toUpperCase()}</StyledTableCellLarge>
                <StyledTableCellSmall>{vendor?.isActive ? 'Yes' : 'No'}</StyledTableCellSmall>
                <StyledTableCellSmall>{vendor?.minimumOrderQuantity}</StyledTableCellSmall>
                <StyledTableCellMedium>{vendor?.blanketPO}</StyledTableCellMedium>
                <StyledTableCellLarge>{vendor?.addressLine1?.toUpperCase()}</StyledTableCellLarge>
                <StyledTableCellLarge>{vendor?.addressLine2?.toUpperCase()}</StyledTableCellLarge>
                <StyledTableCellMedium>{vendor?.city?.toUpperCase()}</StyledTableCellMedium>
                <StyledTableCellSmall>{vendor?.state}</StyledTableCellSmall>
                <StyledTableCellMedium>{vendor?.zipCode}</StyledTableCellMedium>
                <StyledTableCellLarge>{vendor?.contactName?.toUpperCase()}</StyledTableCellLarge>
                <StyledTableCellLarge>{vendor?.phoneNumber}</StyledTableCellLarge>
                <StyledTableCellLarge>{vendor?.vendorEmail}</StyledTableCellLarge>
              </StyledTableRow>
            )
          })}
        </StyledTableBody>
      </UXDataTableWithoutBody>
      {isModalOpen && (
        <ModalTwoButtons
          title={`Add Vendor ${selectedVendor.vendorCode ?? vendorKey}`}
          button1Text="Cancel"
          button1Action={() => {
            setIsModalOpen(false)
            setSelectedVendor({})
            setVendorKey('')
            setPhoneNumber('')
          }}
          button2Text="Add/Update"
          button2Action={async () => {
            setIsSaving(true)

            const {
              isActive,
              firstAddress,
              secondAddress,
              blanketPO,
              city,
              state,
              zipCode,
              contactName,
              vendorName,
              minimumOrder,
              email,
            } = vendorInfoFormRef.current

            const payload = {
              id: selectedVendor.id ?? undefined,
              isActive: isActive.checked,
              addressLine1: firstAddress.value,
              addressLine2: secondAddress.value,
              blanketPO: blanketPO.value,
              city: city.value,
              state: state.value,
              zipCode: zipCode.value,
              contactName: contactName.value,
              vendorName: vendorName.value,
              minimumOrderQuantity: minimumOrder.value,
              phoneNumber: phoneNumber,
              vendorCode: selectedVendor?.vendorCode ?? vendorKey,
              vendorEmail: email.value,
            }

            try {
              let message = "";
              if (payload.vendorName === "") {
                message = "Please provide a Vendor Name";
              } else if (payload.blanketPO === "") {
                message = "Please provide a Blanket PO";
              }

              if (payload.minimumOrderQuantity === "" || !/^[0-9\b]+$/.test(payload.minimumOrderQuantity)) {
                message = "Please provide a valid Minimum Order Quantity";
              }

              if (message !== "") {
                setModalMessage({
                  title: "Error!",
                  message: message,
                  function: () => {
                    setModalMessageIsOpen(false)
                  }
                });
                setModalMessageIsOpen(true);
                setIsSaving(false)

                return;
              }

              await manageVendor(payload)
              setIsModalOpen(false)
              setSelectedVendor({})
              setPhoneNumber('')
              setVendorKey('')
              getAllVendors()
              setIsSaving(false)
            } catch (err) {
              openModalMessages("Error Getting Vendors", err)
            }
          }}
          isButton2Disabled={isSaving}
          setOpen={setIsModalOpen}
          open={isModalOpen}
        >
          <StyledGridForm ref={vendorInfoFormRef}>
            <TextField
              size="small"
              type="text"
              name="vendorName"
              label="Name"
              defaultValue={selectedVendor.vendorName}
              inputProps={{
                maxLength: 200,
              }}
            />
            <FormControlLabel
              label="Active"
              control={<Checkbox name="isActive" defaultChecked={selectedVendor.isActive ?? false} />}
            />
            <TextField
              size="small"
              type="text"
              name="minimumOrder"
              label="Minimum Order Quantity"
              defaultValue={selectedVendor.minimumOrderQuantity}
            />
            <FormControl>
              <InputLabel htmlFor="blanketPO">Blanket PO</InputLabel>
              <Select
                size="small"
                name="blanketPO"
                label="Blanket PO"
                defaultValue={selectedVendor.blanketPO ?? ''}
              >
                <MenuItem value="" disabled>Select an option</MenuItem>
                <MenuItem value="CREDIT CARD">Credit Card</MenuItem>
                <MenuItem value="INVOICE">Invoice</MenuItem>
                <MenuItem value="MONTHLY INVOICE">Monthly Invoice</MenuItem>
                <MenuItem value="PCARD">PCard</MenuItem>
              </Select>
            </FormControl>
            <TextField
              size="small"
              type="text"
              name="firstAddress"
              label="Address 1"
              defaultValue={selectedVendor.addressLine1}
              inputProps={{
                maxLength: 200,
              }}
            />
            <TextField
              size="small"
              type="text"
              name="secondAddress"
              label="Address 2"
              defaultValue={selectedVendor.addressLine2}
              inputProps={{
                maxLength: 200,
              }}
            />
            <TextField
              size="small"
              type="text"
              name="city"
              label="City"
              defaultValue={selectedVendor.city}
              inputProps={{
                maxLength: 100,
              }}
            />
            <FormControl>
              <InputLabel htmlFor="state">State</InputLabel>
              <Select
                size="small"
                name="state"
                label="State"
                MenuProps={MenuProps}
                defaultValue={selectedVendor.state ?? ''}
              >
                <MenuItem value="" disabled>Select an option</MenuItem>
                {americanStates.map((state, index) => {
                  return (
                    <MenuItem key={index} value={state}>{state}</MenuItem>
                  )
                })}
              </Select>
            </FormControl>
            <TextField
              size="small"
              type="text"
              name="zipCode"
              label="ZIP"
              defaultValue={selectedVendor.zipCode}
              inputProps={{
                maxLength: 10,
              }}
            />
            <TextField
              size="small"
              type="text"
              name="contactName"
              label="Contact Name"
              defaultValue={selectedVendor.contactName}
              inputProps={{
                maxLength: 100,
              }}
            />
            <TextField
              size="small"
              type="text"
              name="phone"
              label="Phone"
              value={phoneNumber?.replace(/\D/gi, "")?.replace(/^(\d{3})(\d{3})(\d{4})$/gi, "($1) $2-$3")}
              onChange={(e) => setPhoneNumber(e.target.value)}
            />
            <TextField
              size="small"
              type="email"
              name="email"
              label="Email Address"
              defaultValue={selectedVendor.vendorEmail}
              inputProps={{
                maxLength: 100,
              }}
            />
          </StyledGridForm>
        </ModalTwoButtons>
      )
      }
      <DisplayMessage
        title={modalMessage.title}
        buttonText="Close"
        buttonAction={() => modalMessage.function()}
        setIsModalOpen={setModalMessageIsOpen}
        isModalOpen={modalMessageIsOpen}
        message={modalMessage.message}
      />
      {renderFilterMenu()}

      {/* Informational Messages */}
      <ModalMessages title={modalMessagesTitle} buttonText={modalMessagesButtonText} buttonAction={closeModalMessages} open={modalMessagesOpen} setOpen={setModalMessagesOpen}>
        <label>
          {modalMessagesText}
        </label>
      </ModalMessages>
    </Box >
  );
};

export default SRManageVendor;