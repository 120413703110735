import React from "react";
import { Divider } from "@mui/material";

const Bookmarks = ({ ...props }) => {
  return (
    <div>
      <span className='pageheader'>Bookmarks</span>
      <Divider className='dividerbar' />
      <p>
      Coming soon to a browser near you!
      </p>
    </div>
  );
};

export default Bookmarks;
