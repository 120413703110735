import API from "..";

export default class MethodFacilityStatus {
    /**
     * The MethodFacilityStatus dimension table for M3
     * @param {String} statusName The full name of the MethodFacilityStatus
     * @param {bool} isActive The IsActive for the MethodFacilityStatus
     * @param {String} displayColor The IsActive for the MethodFacilityStatus
     * @param {number} displayOrder The IsActive for the MethodFacilityStatus
     */

    constructor({
        statusName,
        isActive,
        displayColor,
        displayOrder,
    }) {
        this.statusName = statusName;
        this.isActive = isActive;
        this.displayColor = displayColor;
        this.displayOrder = displayOrder;
    }

    static async getAll() {
        const api = await API();
        const { data } = await api.get(`/methodFacilityStatus`);
        return data.result.map((d) => new MethodFacilityStatus(d));
    }
}
