import { Text, View } from "@react-pdf/renderer";
import {
    convertDateFormat,
    formatMidasNumber,
} from "../../../../global";
import { styles } from "./PrintStylesheet";

export default function PrintShippingRequest({ workRequest, shippingRequest, dotHazardClass }) {
  return (
    <View>
      <Text style={styles.subtitle}>Shipping Information</Text>
      <View style={styles.tableDivider} />
      <View style={styles.row1}>
        <View style={styles.item25}>
          <Text style={styles.textHeader}>Recipient's Name</Text>
          <Text style={styles.text}>
            {shippingRequest?.shippingAddress?.recipientName}
          </Text>
        </View>
        <View style={styles.item25}>
          <Text style={styles.textHeader}>Phone Number</Text>
          <Text style={styles.text}>
            {shippingRequest?.shippingAddress?.phoneNumber1}
          </Text>
        </View>
        <View style={styles.item25}>
          <Text style={styles.textHeader}>E-mail</Text>
          <Text style={styles.text}>
            {shippingRequest?.shippingAddress?.emailAddress}
          </Text>
        </View>
        <View style={styles.item25}>
          <Text style={styles.textHeader}>Company</Text>
          <Text style={styles.text}>
            {shippingRequest?.shippingAddress?.company}
          </Text>
        </View>
      </View>
      <View style={styles.row1}>
        <View style={styles.item25}>
          <Text style={styles.textHeader}>Department/Floor No</Text>
          <Text style={styles.text}>
            {shippingRequest?.shippingAddress?.department}
          </Text>
        </View>
        <View style={styles.item25}>
          <Text style={styles.textHeader}>Country</Text>
          <Text style={styles.text}>
            {shippingRequest?.shippingAddress?.country}
          </Text>
        </View>
        <View style={styles.item25}>
          <Text style={styles.textHeader}>State</Text>
          <Text style={styles.text}>
            {shippingRequest?.shippingAddress?.state}
          </Text>
        </View>
        <View style={styles.item25}>
          <Text style={styles.textHeader}>Zip Code</Text>
          <Text style={styles.text}>
            {shippingRequest?.shippingAddress?.postalCode}
          </Text>
        </View>
      </View>
      <View style={styles.row1}>
        <View style={styles.item25}>
          <Text style={styles.textHeader}>City</Text>
          <Text style={styles.text}>
            {shippingRequest?.shippingAddress?.city}
          </Text>
        </View>
        <View style={styles.item25}>
          <Text style={styles.textHeader}>Street Address</Text>
          <Text style={styles.text}>
            {shippingRequest?.shippingAddress?.address1}
          </Text>
        </View>
        <View style={styles.item25}>
          <Text style={styles.textHeader}>Ship</Text>
          <Text style={styles.text}>
            {shippingRequest?.isPrepaid
              ? "Prepaid"
              : "Collect"}
          </Text>
        </View>
        <View style={styles.item25}>
          <Text style={styles.textHeader}>Ship Via</Text>
          <Text style={styles.text}>
            {shippingRequest?.carrier?.carrierName}
          </Text>
        </View>
      </View>
      <View style={styles.row1}>
        <View style={styles.item25}>
          <Text style={styles.textHeader}>Reason for Shipment</Text>
          <Text style={styles.text}>
            {shippingRequest?.shippingReasonName}
          </Text>
        </View>
        <View style={styles.item25}>
          <Text style={styles.textHeader}>PO#</Text>
          <Text style={styles.text}>
            {shippingRequest?.referencePO}
          </Text>
        </View>
        <View style={styles.item25}>
          <Text style={styles.textHeader}>Return Authorized by</Text>
          <Text style={styles.text}>-</Text>
        </View>
        <View style={styles.item25}>
          <Text style={styles.textHeader}>Date Required at Destination</Text>
          <Text style={styles.text}>
            {convertDateFormat(
              shippingRequest?.requestedDeliveryDate
            )}
          </Text>
        </View>
      </View>
      <Text style={styles.subtitle}>Sample Safety Information</Text>
      <View style={styles.tableDivider} />
      <View wrap={false} style={styles.table}>
        <View style={styles.tableRow}>
          <Text style={[styles.tableText, styles.item10]}>MIDAS #</Text>
          <Text style={[styles.tableText, styles.item10]}>Container #</Text>
          <Text style={[styles.tableText, styles.item10]}>Quantity</Text>
          <Text style={[styles.tableText, styles.item10]}>Flash Point (F)</Text>
          <Text style={[styles.tableText, styles.item10]}>
            DOT Hazard Class
          </Text>
          <Text style={[styles.tableText, styles.item10]}>Export Value</Text>
        </View>
        {workRequest.workRequestSamples
          ?.filter((item) => !!item.sample)
          .map((item) => {
            const containerID = item.existingContainerID ?? item.newContainerID ?? null;
            const container = item.existingContainer ?? item.newContainer ?? null;
            const shippingItem = shippingRequest?.shippingItems?.find(si => (si.containerID === containerID || containerID === null) && item.sampleName === si.thisContainer?.sampleName)
            const dotHazardClassDescription = dotHazardClass.find(d => d.classNumber.toString() === shippingItem?.dotHazardClass)?.displayName;

           return (<View style={styles.tableRow}>
              <Text style={[styles.tableText, styles.item10]}>
                {item?.sample.sampleName
                  ? formatMidasNumber(item?.sample.sampleName)
                  : "-"}
              </Text>
              <Text style={[styles.tableText, styles.item10]}>
                {item ? container?.containerNumber : "-"}
              </Text>
              <Text style={[styles.tableText, styles.item10]}>
                {item ? `${container?.currentAmount} ${container?.containerSizeUoM}` : ""}
              </Text>

              <Text style={[styles.tableText, styles.item10]}>
                {shippingItem?.flashPointInC === null
                  ? ""
                  : shippingItem?.flashPointInC}
              </Text>
              <Text style={[styles.tableText, styles.item10]}>
                {dotHazardClassDescription === null ? "" : dotHazardClassDescription}
              </Text>
              <Text style={[styles.tableText, styles.item10]}>
                {shippingItem?.exportValue === null ? "" : shippingItem?.exportValue}
              </Text>
            </View>
          )
            
          }
            )}
      </View>
      <View>
        <View wrap={false} style={styles.row1}>
          <View style={styles.item100}>
            <Text style={styles.textHeader}>
              Shipping request for 3rd party lab testing?
            </Text>
            <Text style={styles.text}>
              {shippingRequest?.is3PTesting === null
                ? "No"
                : shippingRequest?.is3PTesting
                ? "Yes"
                : "No"}
            </Text>
          </View>
        </View>
        <View wrap={false} style={styles.row1}>
          <View style={styles.item100}>
            <Text style={styles.textHeader}>
              I acknowledge that the descriptions on this form will be printed
              on MIDAS labels and are deemed acceptable for external use
            </Text>
            <Text style={styles.text}>
              Yes
            </Text>
          </View>
        </View>
        <View wrap={false} style={styles.row1}>
          <View style={styles.item100}>
            <Text style={styles.textHeader}>
              This shipment contains chemicals
            </Text>
            <Text style={styles.text}>
              {shippingRequest?.containsSamples ? "Yes" : "No"}
            </Text>
          </View>
        </View>
      </View>
      <View wrap={false}>
        <Text style={styles.subtitle}>Tracking Details</Text>
        <View style={styles.tableDivider} />
        <View style={styles.table}>
          <View style={styles.tableRow}>
            <Text style={[styles.tableText, styles.item20]}>Tracking Code</Text>
            <Text style={[styles.tableText, styles.item20]}>Shipped Via</Text>
            <Text style={[styles.tableText, styles.item20]}>Created Date</Text>
            <Text style={[styles.tableText, styles.item20]}>Shipped Date</Text>
            <Text style={[styles.tableText, styles.item20]}>
              Delivered Date
            </Text>
          </View>
          {shippingRequest?.shippingTrackings.map((tracking) => (
            <View style={styles.tableRow}>
              <Text style={[styles.tableText, styles.item20]}>
                {tracking.shipmentTrackingNumber}
              </Text>
              <Text style={[styles.tableText, styles.item20]}>
                {tracking.shippingCarrier.carrierName}
              </Text>
              <Text style={[styles.tableText, styles.item20]}>
                {convertDateFormat(tracking.createdDate)}
              </Text>
              <Text style={[styles.tableText, styles.item20]}>
                {tracking.shippedDate}
              </Text>
              <Text style={[styles.tableText, styles.item20]}>
                {tracking.deliveredDate}
              </Text>
            </View>
          ))}
        </View>
      </View>
    </View>
  );
}
