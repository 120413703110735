import API from "../../api";

export default class Method {
  /**
   * The Method dimension table for M3
   * @param {String} name The full name of the Method
   * @param {String} description The Description for the Method
   * @param {String} methodCategoryName The Description for the Method
   * @param {Boolean} isActive The IsActive for the Method
   * @param {String} createdByEmail The CreatedByEmail for the Method
   * @param {Datetime} createdDate The CreatedDate for the Method
   * @param {String} lastModifiedByEmail The LastModifiedByEmail for the Method
   * @param {String} lastModifiedDate The LastModifiedDate for the Method
   * @param {Boolean} isMethodDestructive The IsMethodDestructive for the Method
   * @param {Number} criticalityLevel The criticalityLevel for the Method
   * @param {Boolean} isModifiedMethod The IsModifiedMethod for the Method
   * @param {String} methodFamily The MethodFamily for the Method
   * @param {Boolean} isPackageTest The IsPackageTest for the Method
   * @param {Number} packageTestRequiredAmount The PackageTestRequiredAmount for the Method
   * @param {String} packageTestRequiredAmountUoMName The PackageTestRequiredAmountUoMName for the Method
   * @param {String} packageTestOwnerEmail The PackageTestOwnerEmail for the Method
   * @param {[]} packageTestCriterias List of PackageTestCriterias
   * @param {[]} methodResultComponent List of MethodResultComponent
   * @param {[]} methodFacilities List of MethodResultComponent
   */

  constructor({
    name,
    description,
    methodCategoryName,
    isActive,
    createdByEmail,
    createdDate,
    lastModifiedByEmail,
    lastModifiedDate,
    isMethodDestructive,
    criticalityLevel,
    isModifiedMethod,
    methodFamily,
    isPackageTest,
    packageTestRequiredAmount,
    packageTestRequiredAmountUoMName,
    packageTestOwnerEmail,
    packageTestCriterias,
    methodResultComponents,
    methodFacilities,
  }) {
    this.name = name;
    this.description = description;
    this.methodCategoryName = methodCategoryName;
    this.isActive = isActive;
    this.createdByEmail = createdByEmail;
    this.createdDate = createdDate;
    this.lastModifiedByEmail = lastModifiedByEmail;
    this.lastModifiedDate = lastModifiedDate;
    this.isMethodDestructive = isMethodDestructive;
    this.criticalityLevel = criticalityLevel;
    this.isModifiedMethod = isModifiedMethod;
    this.methodFamily = methodFamily;
    this.isPackageTest = isPackageTest;
    this.packageTestRequiredAmount = packageTestRequiredAmount;
    this.packageTestRequiredAmountUoMName = packageTestRequiredAmountUoMName;
    this.packageTestOwnerEmail = packageTestOwnerEmail;
    this.packageTestCriterias = packageTestCriterias;
    this.methodResultComponents = methodResultComponents;
    this.methodFacilities = methodFacilities;
  }

  static async getAll() {
    const api = await API();
    const { data } = await api.get(`/method`);
    return data.result.map((d) => new Method(d));
  }

  static async get(name) {
    const api = await API();
    const { data } = await api.get(`/method/${name}`);
    if (data.result) {
      return new Method(data.result);
    }
    else {
      return null;
    }
  }

  static async getPackageTests() {
    const api = await API();
    const { data } = await api.get("/method/getPackageTests");
    return data.result.map((d) => new Method(d));
  }

  static async getPackageTestValidationStatus(packageTestName) {
    const api = await API();
    const { data } = await api.get(`/method/getPackageTestValidationStatus?packageTestName=${packageTestName}`);
    return data;
  }

  static async getSqcMethods() {
    const api = await API();
    const { data } = await api.get("/method/getSQCMethods");
    return data.result.map((d) => new Method(d));
  }

  static async add(info) {
    const api = await API();
    const { data } = await api.post(`/method`, info);
    return data;
    //return new Method(data.result);
  }

  static async addUpdate(info) {
    const api = await API();
    const { data } = await api.post(`/method/addUpdate`, info);

    if (data && data.result) return new Method(data.result);
    else return data;
  }

  static async update(name, info) {
    const api = await API();
    const { data } = await api.put(`/method/${name}`, info);
    return data;
    //return new Method(data.result);
  }
}
