import { useState, useEffect } from "react";
import { useFormik } from "formik";
import { useHistory } from "react-router-dom";
import { toast } from "react-hot-toast";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import Checkbox from "@mui/material/Checkbox";
import Tooltip from "@mui/material/Tooltip";
import Autocomplete from "@mui/material/Autocomplete";
import RadioGroup from "@mui/material/RadioGroup";
import TrashIcon from "@mui/icons-material/Delete";
import CircularProgress from "@mui/material/CircularProgress";

import ProcedureManagementDataGrid from "./ProcedureManagementDataGrid";
import { SelectTestModal } from "./SelectTestModal";
import { GlobalButton } from "../../../pages/styles";
import ValidatedMidasNumberTextField from "../../../components/ValidatedMidasNumberTextField";
import EmailAddressTextField from "../../../components/EmailAddressField";
import ModalSimpleButton from "../../../components/Modal/ModalSimpleButton";
import { generateUniqueID, ConvertUOMs } from "../../../global";
import UnitOfMeasureAPI from "../../../api/Admin/UnitOfMeasure";
import STRequest from "../../../api/StationaryTesting/STRequest";
import {
  ADDITIONAL_SAMPLE_NAME_ENUM,
  FUEL_SAMPLE_INITIAL_VALUES,
  LUBRICANT_SAMPLE_INITIAL_VALUES,
  OFICIAL_REGISTERED_ENUM,
  STATIONARY_TESTING_INITIAL_VALUES,
  TEST_CLASSIFICATION_ENUM,
  stationaryTestingSchema,
} from "../shared";
import FileDragAndDrop from "../../../components/FileDragAndDrop";
import {
  StyledTableBody,
  StyledTableCell,
  StyledTableRow,
  UXDataTableWithoutBody,
} from "../../../components/UXDataTable";
import Location from "../../../api/Admin/Location";
import LubricantSampleModal from "./LubricantSampleModal";
import FuelSampleModal from "./FuelSampleModal";
import STViscosityGrade from "../../../api/StationaryTesting/STViscosityGrade";
import { useMsal } from "@azure/msal-react";
import {
  columnsAdditionalFuelSamplesTable,
  columnsAdditionalLubricantSamplesTable,
  columnsAdditionalLubricantSamplesTableWithViscosity,
} from "../tableColumns";
import BillingInfoField from "../../../components/BillingInfoField";

export const CONTAINER_CONFIRMED_STATUS_NAME = "Confirmed";

export default function NewRequest(props) {
  const [sample, setSample] = useState(null);
  const [submittedContainerNumber, setSubmittedContainerNumber] = useState("");
  const [isInvalid, setIsInvalid] = useState(false);
  const [isSelectTestModalOpen, setIsSelectTestModalOpen] = useState(false);
  const [isWarningModalOpen, setIsWarningModalOpen] = useState(false);
  const [isResponseModalOpen, setIsResponseModalOpen] = useState(false);
  const [uomList, setUomList] = useState([]);
  const [isCreatingRequest, setIsCreatingRequest] = useState(false);
  const [files, setFiles] = useState([]);
  const [isLubricantSampleModalOpen, setIsLubricantSampleModalOpen] =
    useState(false);
  const [isFuelSampleModalOpen, setIsFuelSampleModalOpen] = useState(false);
  const [locationsList, setLocationsList] = useState([]);
  const [lubricantSample, setLubricantSample] = useState(
    LUBRICANT_SAMPLE_INITIAL_VALUES
  );
  const [fuelSample, setFuelSample] = useState(FUEL_SAMPLE_INITIAL_VALUES);
  const history = useHistory();
  const [viscosityGrades, setViscosityGrades] = useState([]);
  const [loading, setIsLoading] = useState(false);
  const { accounts } = useMsal();

  const userEmail = accounts && accounts[0] ? accounts[0].username : "";

  function getTitleForUpdateRequest(id, isLocked, cardNumber) {
    if (cardNumber) {
      if (isLocked) {
        return `Card Tracking Number ${cardNumber}`;
      }

      return `Update Request - Card Tracking Number ${cardNumber}`;
    }

    if (isLocked) {
      return `Request # ${id}`;
    }

    return `Update Request - Request # ${id}`;
  }

  const uomOptions = uomList ? uomList.map((uom) => uom.uoMName) : [];

  const locationOptions = locationsList
    ? locationsList.map((ll) => ll.locationName)
    : [];

  const containerOptions = sample
    ? sample.containers
        ?.filter(
          (container) =>
            container.containerStatusName === CONTAINER_CONFIRMED_STATUS_NAME
        )
        ?.map((container) => {
          return {
            containerNumber: container.containerNumber,
            label: `${container.containerNumber}: ${container.currentAmount} ${container.containerSizeUoM}`,
          };
        })
    : [];

  async function getAllUoM() {
    const response = await UnitOfMeasureAPI.getAllActive();
    if (response) {
      setUomList(
        response.filter((u) => u.type === "weight" || u.type === "volume")
      );
    }
  }

  function handleFiles(newFiles) {
    const listFiles = newFiles.map((item) => {
      return {
        file: item,
      };
    });
    setFiles(files.concat(listFiles));
  }

  async function getLocations() {
    const data = await Location.getAll();
    if (data) {
      setLocationsList(data.filter((a) => a.isActive));
    }
  }

  async function getViscosityGrades() {
    const viscosityGrades = await STViscosityGrade.getGrades()
    const filteredGrades = viscosityGrades.filter(x => x.isActive === true).map((grade) => grade?.viscosityGradeName);
    setViscosityGrades(filteredGrades);
  }

  const formik = useFormik({
    initialValues: {
      ...STATIONARY_TESTING_INITIAL_VALUES,
      requestedByEmail: userEmail,
    },
    validationSchema: stationaryTestingSchema,
    onSubmit: async (values) => {
      setIsCreatingRequest(true);

      // Validation for fuels
      if (values.fuelSamples.length > 0) {
        const isRequestFuelsInvalid = values.fuelSamples.some(
          (rf) => !rf.containerId || rf.containerId === ""
        );
        if (isRequestFuelsInvalid) {
          setIsCreatingRequest(false);
          return toast.error("Invalid input for requested fuel");
        }
      }

      // Validation for lubs
      if (values.lubricantSamples.length > 0) {
        const isRequestLubricantsInvalid = values.lubricantSamples.some(
          (rl) => !rl.containerId || rl.containerId === ""
        );
        if (isRequestLubricantsInvalid) {
          setIsCreatingRequest(false);
          return toast.error("Invalid input for requested lubricants");
        }
      }

      // Validation for procedureTests
      if (
        !formik.values.testSelection.procedureTests ||
        formik.values.testSelection.procedureTests.length === 0
      ) {
        setIsCreatingRequest(false);
        return toast.error("Must select a test");
      }

      const requestParameters = formik.values.testSelection.procedureTests.find(
        (pt) =>
          pt.testDisplayName ===
          formik.values.testSelection.procedureTestTestDisplayName
      ).availableParameters;

      // Validation for parameter
      if (!requestParameters) {
        setIsCreatingRequest(false);
        return toast.error("Didn't find parameters in selected test");
      }

      // Validation for Midas Sample
      if (!sample || !sample.sampleName || sample.sampleName === "") {
        setIsCreatingRequest(false);
        return toast.error("Must select a midas sample");
      }

      const testSelection = {
        requestParameters: formik.values.testSelection.requestParameters,
        procedureTestTestDisplayName:
          formik.values.testSelection.procedureTestTestDisplayName,
      };

      const payload = {
        id: values.id,
        submittedSampleName: sample.sampleName,
        requestedByEmail: values.requestedByEmail,
        billingReference: values.billingReference,
        safetyAlert: values.safetyAlert ?? false,
        reportAlias: values.reportAlias !== "" ? values.reportAlias : null,
        accOilCode: values.accOilCode !== "" ? values.accOilCode : null,
        formulationCode:
          values.formulationCode !== "" ? values.formulationCode : null,
        submitterComments:
          values.submitterComments !== "" ? values.submitterComments : null,
        isISO9001: values.isISO9001,
        officialRegistered:
          values.officialRegistered === OFICIAL_REGISTERED_ENUM.Yes
            ? true
            : values.officialRegistered === OFICIAL_REGISTERED_ENUM.No
            ? false
            : null,
        fuelSamples: values.fuelSamples,
        lubricantSamples: values.lubricantSamples,
        testSelection,
        testTypeName: values.testTypeName,
        sampleExpectedUse:
          values.sampleExpectedUse !== "" ? values.sampleExpectedUse : null,
        sampleExpectedUseUoMName:
          values.sampleExpectedUseUoMName !== ""
            ? values.sampleExpectedUseUoMName
            : null,
        sampleUsedReturnLocationName:
          values.sampleUsedReturnLocationName !== ""
            ? values.sampleUsedReturnLocationName
            : null,
        sampleRetainAmount:
          values.sampleRetainAmount !== "" ? +values.sampleRetainAmount : null,
        sampleRetainAmountUoMName:
          values.sampleRetainAmountUoMName !== ""
            ? values.sampleRetainAmountUoMName
            : null,
        sampleUnusedReturnLocationName:
          values.sampleUnusedReturnLocationName !== ""
            ? values.sampleUnusedReturnLocationName
            : null,
        sampleViscosityGrade:
          values.sampleViscosityGrade !== ""
            ? values.sampleViscosityGrade
            : null,
        isCandidate: values.isCandidate,
        testStandName: values.testStandName,
      };

      if (submittedContainerNumber !== "") {
        payload.submittedContainerNumber = +submittedContainerNumber;
      }

      if (payload.id) {
        payload.deletedFileAttachmentNames =
          formik.values.deletedFileAttachmentNames;

        const formData = new FormData();

        files.forEach((file) => {
          if (file.file && !file.file.requestId) {
            formData.append("files", file.file);
          }
        });

        formData.append("stRequestCreateUpdate", JSON.stringify(payload));

        const response = await STRequest.updateRequest(formData);

        if (response && response.id) {
          setIsResponseModalOpen(true);
        } else {
          toast.error("Failed while updating Stationary Test Request");
        }
      } else {
        const formData = new FormData();

        files.forEach((file) => {
          formData.append("files", file.file);
        });

        formData.append("stRequestCreateUpdate", JSON.stringify(payload));

        const response = await STRequest.createRequest(formData);

        if (response) {
          if (response.result && response.result.id) {
            setIsResponseModalOpen(true);
          } else {
            toast.error(response.message ?? "Failed while creating Stationary Test Request");
          }
        }
      }

      setIsCreatingRequest(false);
    },
  });

  function deleteFile(file) {
    if (formik.values.isLocked) {
      return toast.error("Request is locked for changes");
    }
    if (file && file.file && file.file.requestId) {
      formik.setFieldValue("deletedFileAttachmentNames", [
        ...formik.values.deletedFileAttachmentNames,
        file.file.name,
      ]);
    }

    const newFiles = files.filter((f) => f !== file);
    setFiles(newFiles);
  }

  async function initializeRequest() {
    if (
      props &&
      props.location &&
      props.location &&
      props.location.state &&
      props.location.state.id
    ) {
      setIsLoading(true);
      const initialProps = await STRequest.getRequestForUpdateById(
        props.location.state.id
      );
      if (initialProps) {
        if (initialProps.submittedContainerNumber) {
          setSubmittedContainerNumber(initialProps.submittedContainerNumber);
        }
        if (initialProps.submittedSample) {
          setSample(initialProps.submittedSample);
          setSubmittedContainerNumber(
            String(initialProps.submittedContainerNumber)
          );
        }
        const officialRegistered =
          initialProps.officialRegistered === true
            ? OFICIAL_REGISTERED_ENUM.Yes
            : initialProps.officialRegistered === false
            ? OFICIAL_REGISTERED_ENUM.No
            : OFICIAL_REGISTERED_ENUM.NA;
        if (initialProps.fileAttachments) {
          setFiles(initialProps.fileAttachments);
        }
        formik.setValues({ ...initialProps, officialRegistered });
        setIsLoading(false);
      }
    }
  }

  useEffect(() => {
    getAllUoM();
    getLocations();
    getViscosityGrades();
    initializeRequest();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function addOrUpdateAdditionalLubricantSample(newSample) {
    const alreadyAdded =
      formik.values.lubricantSamples.length > 0 &&
      formik.values.lubricantSamples.find(
        (ls) => ls.containerId === newSample.containerId
      );

    if (alreadyAdded) {
      const updatedSamples = formik.values.lubricantSamples.map((ls) => {
        let sampleToAdd = { ...ls };
        const isSameSample = ls.containerId === newSample.containerId;

        if (isSameSample) {
          sampleToAdd = newSample;
        }

        return sampleToAdd;
      });

      formik.setFieldValue("lubricantSamples", updatedSamples);
    } else {
      formik.setFieldValue("lubricantSamples", [
        ...formik.values.lubricantSamples,
        { ...newSample, rowId: generateUniqueID() },
      ]);
    }
    setLubricantSample(LUBRICANT_SAMPLE_INITIAL_VALUES);
    setIsLubricantSampleModalOpen(false);
  }

  function addOrUpdateAdditionalFuelSample(newSample) {
    const alreadyAdded =
      formik.values.fuelSamples.length > 0 &&
      formik.values.fuelSamples.find(
        (ls) => ls.containerId === newSample.containerId
      );

    if (alreadyAdded) {
      const updatedSamples = formik.values.fuelSamples.map((ls) => {
        let sampleToAdd = { ...ls };
        const isSameSample = ls.containerId === newSample.containerId;

        if (isSameSample) {
          sampleToAdd = newSample;
        }

        return sampleToAdd;
      });

      formik.setFieldValue("fuelSamples", updatedSamples);
    } else {
      formik.setFieldValue("fuelSamples", [
        ...formik.values.fuelSamples,
        { ...newSample, rowId: generateUniqueID() },
      ]);
    }
    setFuelSample(FUEL_SAMPLE_INITIAL_VALUES);
    setIsFuelSampleModalOpen(false);
  }

  function handleAddSample(newSample, additionalSampleName) {
    if (additionalSampleName === ADDITIONAL_SAMPLE_NAME_ENUM.Lubricant) {
      addOrUpdateAdditionalLubricantSample(newSample);
    } else {
      addOrUpdateAdditionalFuelSample(newSample);
    }
  }

  function handleDeleteSample(sample, additionalSampleName) {
    if (additionalSampleName === ADDITIONAL_SAMPLE_NAME_ENUM.Lubricant) {
      const filteredLubricantSamples = formik.values.lubricantSamples.filter(
        (ls) => ls.containerId !== sample.containerId
      );

      formik.setFieldValue("lubricantSamples", filteredLubricantSamples);
    } else {
      const filteredFuelSamples = formik.values.fuelSamples.filter(
        (ls) => ls.containerId !== sample.containerId
      );

      formik.setFieldValue("fuelSamples", filteredFuelSamples);
    }
  }

  return (
    <>
      <span className="pageheader">
        {props &&
        props.location &&
        props.location &&
        props.location.state &&
        props.location.state.id
          ? getTitleForUpdateRequest(
              props.location.state.id,
              formik.values.isLocked,
              formik.values.cardNumber
            )
          : "Create Request"}
      </span>
      <Divider className="dividerbar" />
      {loading ? (
        <Box sx={{ display: "flex" }}>
          <CircularProgress />
        </Box>
      ) : (
        <Box style={{ paddingBottom: "2rem" }}>
          <Typography
            style={{ fontSize: 20, marginTop: "1rem", marginBottom: "1rem" }}
          >
            Test Specifications:
          </Typography>

          <Box gap={2} display="grid" gridTemplateColumns="1fr 3fr 1fr">
            <TextField
              value={formik.values.testSelection.testLabel}
              label="Test Name"
              InputProps={{ readOnly: true }}
              size="small"
              disabled
              required
            />

            <TextField
              value={formik.values.testSelection.testDescription ?? ""}
              label="Test Description"
              InputProps={{ readOnly: true }}
              size="small"
              disabled
            />

            <GlobalButton
              variant="contained"
              onClick={() => setIsSelectTestModalOpen(true)}
            >
              Select Test
            </GlobalButton>
          </Box>

          <Box gap={2} display="grid" gridTemplateColumns="3fr 1fr">
            <EmailAddressTextField
              validatedUserEmail={formik.values.requestedByEmail}
              hasErrors={formik.errors?.requestedByEmail ? true : false}
              setValidatedUserEmail={(value) =>
                formik.setFieldValue("requestedByEmail", value)
              }
              setHasErrors={() => {}}
              labelText="Requested For Email"
              placeholderText="Email"
              showPlusMeButton
              isDisabled={formik.values.isLocked}
            />

            <BillingInfoField
              billingValue={formik.values.billingReference}
              handleChange={(e) => {
                formik.setFieldValue("billingReference", e);
              }}
              hasErrors={formik.errors.billingReference}
              isDisabled={formik.values.isLocked}
              margin="normal"
              fieldWidth="100%"
              helperText={null}
              marginLeft={null}
              marginRight={null}
            />
          </Box>

          <Box
            gap={2}
            display="grid"
            gridTemplateColumns="1fr 1fr 1fr"
            style={{ marginTop: "1rem" }}
          >
            <Box display="grid" gridTemplateColumns="1fr 2fr">
              <span style={{ font: "normal normal 500 15px/19px EMprint" }}>
                Test Classification:
              </span>
              <Box>
                <RadioGroup style={{ flexDirection: "row" }}>
                  <FormControlLabel
                    value={TEST_CLASSIFICATION_ENUM.Lubricant}
                    key="lubricantTesting"
                    label="Lubricant Testing"
                    control={
                      <Radio
                        onClick={(e) =>
                          formik.setFieldValue("testTypeName", e.target.value)
                        }
                        checked={
                          TEST_CLASSIFICATION_ENUM.Lubricant ===
                          formik.values.testTypeName
                        }
                      />
                    }
                    disabled={formik.values.isLocked}
                  />
                  <FormControlLabel
                    value={TEST_CLASSIFICATION_ENUM.Fuel}
                    key="fuelTesting"
                    label="Fuel Testing"
                    control={
                      <Radio
                        onClick={(e) =>
                          formik.setFieldValue("testTypeName", e.target.value)
                        }
                        checked={
                          TEST_CLASSIFICATION_ENUM.Fuel ===
                          formik.values.testTypeName
                        }
                      />
                    }
                    disabled={formik.values.isLocked}
                  />
                </RadioGroup>
                <Box style={{ display: "flex", alignItems: "center" }}>
                  <Checkbox
                    id="isISO9001"
                    name="isISO9001"
                    size="small"
                    onChange={() =>
                      formik.setFieldValue(
                        "isISO9001",
                        !formik.values.isISO9001
                      )
                    }
                    checked={formik.values.isISO9001}
                    style={{ paddingLeft: 0 }}
                    disabled={formik.values.isLocked}
                  />

                  <label
                    htmlFor="isISO9001"
                    style={{ font: "normal normal 400 14px/16px EMprint" }}
                  >
                    ISO 9001?
                  </label>
                </Box>
              </Box>
            </Box>

            <Box display="grid" gridTemplateColumns="1fr 2fr">
              <span style={{ font: "normal normal 500 15px/19px EMprint" }}>
                Official Registered?
              </span>
              <RadioGroup style={{ display: "flex", flexDirection: "row" }}>
                <FormControlLabel
                  value={OFICIAL_REGISTERED_ENUM.Yes}
                  label={OFICIAL_REGISTERED_ENUM.Yes}
                  control={
                    <Radio
                      onClick={() =>
                        formik.setFieldValue(
                          "officialRegistered",
                          OFICIAL_REGISTERED_ENUM.Yes
                        )
                      }
                      checked={
                        formik.values.officialRegistered ===
                        OFICIAL_REGISTERED_ENUM.Yes
                      }
                    />
                  }
                  disabled={formik.values.isLocked}
                />
                <FormControlLabel
                  value={OFICIAL_REGISTERED_ENUM.No}
                  label={OFICIAL_REGISTERED_ENUM.No}
                  control={
                    <Radio
                      onClick={() =>
                        formik.setFieldValue(
                          "officialRegistered",
                          OFICIAL_REGISTERED_ENUM.No
                        )
                      }
                      checked={
                        formik.values.officialRegistered ===
                        OFICIAL_REGISTERED_ENUM.No
                      }
                    />
                  }
                  disabled={formik.values.isLocked}
                />
                <FormControlLabel
                  value={OFICIAL_REGISTERED_ENUM.NA}
                  label={OFICIAL_REGISTERED_ENUM.NA}
                  control={
                    <Radio
                      onClick={() =>
                        formik.setFieldValue(
                          "officialRegistered",
                          OFICIAL_REGISTERED_ENUM.NA
                        )
                      }
                      checked={
                        formik.values.officialRegistered ===
                        OFICIAL_REGISTERED_ENUM.NA
                      }
                    />
                  }
                  disabled={formik.values.isLocked}
                />
              </RadioGroup>
            </Box>

            <Box>
              <Checkbox
                id="safetyInformation"
                name="safetyInformation"
                size="small"
                onChange={() =>
                  formik.setFieldValue(
                    "safetyAlert",
                    !formik.values.safetyAlert
                  )
                }
                checked={formik.values.safetyAlert}
                disabled={formik.values.isLocked}
              />
              <label
                htmlFor="safetyInformation"
                style={{ font: "normal normal 400 14px/16px EMprint" }}
              >
                SAFETY ALERT! Testing of material may result in a test that
                falls outside of the scope of normal operation.
              </label>
            </Box>
          </Box>

          <Box
            gap={2}
            display="grid"
            gridTemplateColumns="1fr 1fr 1fr"
            style={{ marginTop: "1rem" }}
          >
            <TextField
              name="reportAlias"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.reportAlias}
              label="Report Alias"
              size="small"
              disabled={formik.values.isLocked}
            />

            <TextField
              name="accOilCode"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.accOilCode}
              label="ACC Oil Code"
              size="small"
              disabled={formik.values.isLocked}
            />

            <TextField
              name="formulationCode"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.formulationCode}
              label="Formulation Stand Code"
              size="small"
              disabled={formik.values.isLocked}
            />
          </Box>

          <Typography
            style={{ fontSize: 20, marginTop: "1rem", marginBottom: "1rem" }}
          >
            Test Sample(s):
          </Typography>

          <Box
            gap={2}
            display="grid"
            gridTemplateColumns={
              formik.values.testSelection.showViscosity
                ? "1fr 1fr 2fr 1fr"
                : "1fr 1fr 3fr"
            }
            style={{ marginTop: 8 }}
          >
            <ValidatedMidasNumberTextField
              showLabel
              midasNumberObject={sample}
              setMidasNumberObject={(e) => setSample(e)}
              hasErrors={isInvalid}
              setHasErrors={(e) => setIsInvalid(e)}
              chemIDToMatch={
                sample && sample.substance && sample.substance.chemID
                  ? sample.substance.chemID
                  : null
              }
              setScannedContainer={(value) => {
                if (value && typeof value.containerNumber !== "undefined") {
                  setSubmittedContainerNumber(String(value.containerNumber));
                }
              }}
              isDisabled={formik.values.isLocked}
            />

            <Autocomplete
              noOptionsText="Loading Containers..."
              value={submittedContainerNumber}
              isOptionEqualToValue={(option) =>
                option.containerNumber === submittedContainerNumber
              }
              onChange={(_, value) => {
                const foundContainer = sample.containers.find(
                  (container) =>
                    container.containerNumber === value.containerNumber
                );

                if (
                  foundContainer &&
                  formik.values.testSelection.procedureTests.length > 0
                ) {
                  const foundUoMObject = uomList.find(
                    (uom) => uom?.uoMName === foundContainer?.containerSizeUoM
                  );
                  const testRecord =
                    formik.values.testSelection.procedureTests.find(
                      (pt) =>
                        pt.testDisplayName ===
                        formik.values.testSelection.procedureTestTestDisplayName
                    );

                  const convertedResult = ConvertUOMs(
                    sample,
                    sample.substance,
                    foundContainer?.currentAmount,
                    foundUoMObject,
                    testRecord?.minimumSampleSizeUoM
                  );

                  if (testRecord?.minimumSampleSize > convertedResult) {
                    setIsWarningModalOpen(true);
                  }
                }
                setSubmittedContainerNumber(
                  value ? value.containerNumber : null
                );
              }}
              disabled={
                !sample ||
                containerOptions.length === 0 ||
                !formik.values.testSelection ||
                !formik.values.testSelection.procedureTests ||
                formik.values.testSelection.procedureTests.length === 0 ||
                formik.values.isLocked
              }
              options={containerOptions}
              renderInput={(params) => (
                <TextField {...params} label="Container" size="small" />
              )}
              autoSelect
              getOptionLabel={(option) =>
                option.label ? option.label : String(option)
              }
            />

            <TextField
              value={!!sample ? sample?.description : ""}
              label="Sample Description"
              InputProps={{ readOnly: true }}
              size="small"
              disabled
            />

            {formik.values.testSelection.showViscosity && (
              <Autocomplete
                name="sampleViscosityGrade"
                id="sampleViscosityGrade"
                options={viscosityGrades}
                renderInput={(params) => (
                  <TextField {...params} label="Viscosity Grade" size="small" />
                )}
                onChange={(_, v) => {
                  formik.setFieldValue("sampleViscosityGrade", v);
                }}
                value={formik.values.sampleViscosityGrade}
                size="small"
                isOptionEqualToValue={(option, value) => option === value}
                disabled={formik.values.isLocked}
              />
            )}
          </Box>

          <Box
            display="grid"
            gap={2}
            gridTemplateColumns="1fr 1fr 1fr 1fr"
            style={{ marginTop: "1rem", marginBottom: "1rem" }}
          >
            <TextField
              name="sampleExpectedUse"
              id="sampleExpectedUse"
              label="Sample Expected Use"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.sampleExpectedUse}
              size="small"
              type="number"
              disabled={formik.values.isLocked}
            />
            <Autocomplete
              name="sampleExpectedUseUoMName"
              id="sampleExpectedUseUoMName"
              options={uomOptions}
              renderInput={(params) => (
                <TextField {...params} label="UoM" size="small" />
              )}
              onChange={(_, v) => {
                formik.setFieldValue("sampleExpectedUseUoMName", v);
              }}
              value={formik.values.sampleExpectedUseUoMName}
              size="small"
              isOptionEqualToValue={(option, value) => option === value}
              disabled={formik.values.isLocked}
            />
            <Autocomplete
              name="sampleUsedReturnLocationName"
              id="sampleUsedReturnLocationName"
              options={locationOptions}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Sample Used Return Location"
                  size="small"
                />
              )}
              onChange={(_, v) => {
                formik.setFieldValue("sampleUsedReturnLocationName", v);
              }}
              value={formik.values.sampleUsedReturnLocationName}
              size="small"
              isOptionEqualToValue={(option, value) => option === value}
              disabled={formik.values.isLocked}
            />
            <Autocomplete
              name="sampleUnusedReturnLocationName"
              id="sampleUnusedReturnLocationName"
              options={locationOptions}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Sample Unused Return Location"
                  size="small"
                />
              )}
              onChange={(_, v) => {
                formik.setFieldValue("sampleUnusedReturnLocationName", v);
              }}
              value={formik.values.sampleUnusedReturnLocationName}
              size="small"
              isOptionEqualToValue={(option, value) => option === value}
              disabled={formik.values.isLocked}
            />
          </Box>

          <ProcedureManagementDataGrid
            title="Additional Lubricant Samples"
            buttonText="Add Lubricant Sample"
            buttonAction={() => {
              if (
                formik.values.testSelection.procedureTestTestDisplayName !==
                  "" &&
                !formik.values.isLocked
              ) {
                setIsLubricantSampleModalOpen(true);
              } else {
                toast.error("You need to select a test first");
              }
            }}
            rows={formik.values.lubricantSamples}
            modalListType="container"
            cols={
              formik.values.testSelection.showViscosity
                ? columnsAdditionalLubricantSamplesTableWithViscosity
                : columnsAdditionalLubricantSamplesTable
            }
            enableDelete
            canEdit={!formik.values.isLocked}
            actionOpenModal
            requiredFields
            selectionDataGrid={(c) => {
              if (!formik.values.isLocked) {
                setLubricantSample(c);
                setIsLubricantSampleModalOpen(true);
              }
            }}
            customDelete={(sample) => {
              if (!formik.values.isLocked) {
                handleDeleteSample(
                  sample,
                  ADDITIONAL_SAMPLE_NAME_ENUM.Lubricant
                );
              }
            }}
          />

          {formik.values.testSelection.testClassificationType === "Engine" && (
            <ProcedureManagementDataGrid
              title="Additional Fuel Samples"
              buttonText="Add Fuel Sample"
              buttonAction={() => {
                if (!formik.values.isLocked) {
                  setIsFuelSampleModalOpen(true);
                }
              }}
              rows={formik.values.fuelSamples}
              modalListType="container"
              cols={columnsAdditionalFuelSamplesTable}
              enableDelete
              canEdit={!formik.values.isLocked}
              actionOpenModal
              requiredFields
              selectionDataGrid={(c) => {
                if (!formik.values.isLocked) {
                  setFuelSample(c);
                  setIsFuelSampleModalOpen(true);
                }
              }}
              customDelete={(sample) => {
                if (!formik.values.isLocked) {
                  handleDeleteSample(sample, ADDITIONAL_SAMPLE_NAME_ENUM.Fuel);
                }
              }}
            />
          )}

          <Typography style={{ fontSize: 20, marginBottom: "1rem" }}>
            Requester Files/Comments:
          </Typography>

          <FileDragAndDrop
            files={files.map((item) => item.file)}
            handleFiles={handleFiles}
            disabled={formik.values.isLocked}
          />

          <UXDataTableWithoutBody
            tableWidth="100%"
            cols={["File Name"]}
            blankFirstHeader={true}
            tableId={`midasFileAttachments`}
            rowLength={files.length}
            enablePaging={false}
            rowsPerPage={files.length}
            page={0}
            handleChangePage={null}
            handleChangeRowsPerPage={null}
            noDataFoundMessage={"No Files Added"}
            enableAddIcon={false}
            addFunction={() => null}
            addToolTipText=""
            isDataLoading={false}
          >
            <StyledTableBody key={"myTablebody"}>
              {files.map((file, rowIndex) => {
                return (
                  <StyledTableRow hover key={`custom-row-${rowIndex}`}>
                    <StyledTableCell
                      component="th"
                      scope="row"
                      style={{ width: "4rem" }}
                    >
                      <Tooltip title="Delete File" placement="right">
                        <TrashIcon onClick={() => deleteFile(file)}></TrashIcon>
                      </Tooltip>
                    </StyledTableCell>
                    <StyledTableCell>
                      {file.file.uri ? (
                        <a href={file.file.uri}>{file.file.name}</a>
                      ) : (
                        <span>{file.file.name}</span>
                      )}
                    </StyledTableCell>
                  </StyledTableRow>
                );
              })}
            </StyledTableBody>
          </UXDataTableWithoutBody>

          <Box
            display="grid"
            gridTemplateColumns="1fr"
            style={{ marginTop: "1rem", marginBottom: "1rem" }}
          >
            <TextField
              onChange={(e) =>
                formik.setFieldValue("submitterComments", e.target.value)
              }
              value={formik.values.submitterComments}
              label="Comments"
              size="small"
              multiline
              rows={4}
              disabled={formik.values.isLocked}
            />
          </Box>

          <Box marginBottom={3}>
            <GlobalButton
              variant="contained"
              type="button"
              onClick={formik.submitForm}
              disabled={isCreatingRequest || formik.values.isLocked}
              fullWidth
            >
              Submit
            </GlobalButton>
          </Box>
        </Box>
      )}

      {isSelectTestModalOpen && (
        <SelectTestModal
          setIsSelectTestModalOpen={setIsSelectTestModalOpen}
          save={(values) => {
            formik.setFieldValue("testSelection", values);
          }}
          initialValues={{ ...formik.values.testSelection }}
          setPredefinedSampleExpectedUseUoMName={(uomName) =>
            formik.setFieldValue("sampleExpectedUseUoMName", uomName)
          }
          setPrefinedSampleExpectedUse={(expectedUse) =>
            formik.setFieldValue("sampleExpectedUse", expectedUse)
          }
          isCandidate={formik.values.isCandidate}
          setIsCandidate={() =>
            formik.setFieldValue("isCandidate", !formik.values.isCandidate)
          }
          testStandName={formik.values.testStandName}
          setTestStandName={(testStandName) =>
            formik.setFieldValue("testStandName", testStandName)
          }
          isLocked={formik.values.isLocked}
        />
      )}

      {isWarningModalOpen && (
        <ModalSimpleButton
          title="Warning!"
          buttonText="Ok"
          buttonAction={() => setIsWarningModalOpen(false)}
          setOpen={setIsWarningModalOpen}
          open={isWarningModalOpen}
        >
          <Typography
            style={{ padding: "0 16px", fontSize: 18, textAlign: "center" }}
          >
            There is not enough material in the selected Container to do the
            test.
          </Typography>
        </ModalSimpleButton>
      )}

      {isResponseModalOpen && (
        <ModalSimpleButton
          title="Success"
          buttonText="Ok"
          buttonAction={() => {
            setIsResponseModalOpen(false);
            history.push("/stationarytesting");
          }}
          setOpen={setIsResponseModalOpen}
          open={isResponseModalOpen}
        >
          <Typography
            style={{ padding: "0 16px", fontSize: 18, textAlign: "center" }}
          >
            Request successfully {formik.values.id ? "updated" : "created"}
          </Typography>
        </ModalSimpleButton>
      )}

      {isLubricantSampleModalOpen && (
        <LubricantSampleModal
          isLubricantSampleModalOpen={isLubricantSampleModalOpen}
          setIsLubricantSampleModalOpen={setIsLubricantSampleModalOpen}
          uomList={uomList}
          locationList={locationsList}
          addSample={(sample) =>
            handleAddSample(sample, ADDITIONAL_SAMPLE_NAME_ENUM.Lubricant)
          }
          initialValues={lubricantSample}
          showViscosity={formik.values.testSelection.showViscosity}
          viscosityGrades={viscosityGrades}
        />
      )}

      {isFuelSampleModalOpen && (
        <FuelSampleModal
          isFuelSampleModalOpen={isFuelSampleModalOpen}
          setIsFuelSampleModalOpen={setIsFuelSampleModalOpen}
          uomList={uomList}
          locationList={locationsList}
          addSample={(sample) =>
            handleAddSample(sample, ADDITIONAL_SAMPLE_NAME_ENUM.Fuel)
          }
          initialValues={fuelSample}
        />
      )}
    </>
  );
}
