import { useEffect, useRef, useState } from "react";
import {
  StyledTableBody,
  StyledTableRow,
  UXDataTableWithoutBody,
} from "../../../components/UXDataTable";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import Autocomplete from "@mui/material/Autocomplete";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import { StyledTableCellLarge, StyledTableCellMedium } from "../styles";
import WarningUnsavedChanges from "./WarningUnsavedChanges";
import DisplayMessage from "./DisplayMessage";
import ValidatedMidasNumberTextField from "../../../components/ValidatedMidasNumberTextField";
import ModalTwoButtons from "../../../components/Modal/ModalTwoButtons";
import LocationField from "../../../components/LocationField";
import ScannerIncreaseInputButton from "./ScannerIncreaseInputButton";
import { GlobalButton, GlobalSecondaryButton } from "../../styles";
import { OrderStatusEnums, StockroomCategoryNameEnums } from "../shared";

const requestListCols = [
  "Stockroom #",
  "Description",
  "Sublocation",
  "Request",
  "Issued",
  "UOM",
  "Inventory",
  "MIDAS #",
  "Container #",
  "Status",
];

export default function DisplayRequestItemList({
  selectedItems,
  setSelectedItems,
  selectedRequest,
  setSelectedRequest,
  createStockRequest,
  setIsModalOpen,
  handleApplyFilters,
  isModalOpen,
  orderStatuses,
  canEdit,
}) {
  const [rowsPerReqListPage, setRowsPerReqListPage] = useState(5);
  const [reqListPage, setReqListPage] = useState(0);
  const [errorArray, setErrorArray] = useState([]);
  const requestBlocked =
    (selectedRequest &&
      (selectedRequest.orderStatusName === OrderStatusEnums.Complete) |
        (selectedRequest.orderStatusName === OrderStatusEnums.Cancelled)) ||
    !canEdit;

  const handleReqListPageChange = (event, page) => {
    setReqListPage(page);
  };

  const [warningSaveModal, setWarningSaveModal] = useState(false);
  const [modalMessageIsOpen, setModalMessageIsOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState({
    title: "",
    message: "",
  });
  const [isRequestItemUpdating, setIsRequestItemUpdating] = useState(false);
  const [finalDestinationModal, setFinalDestinationModal] = useState(false);
  let prevState = useRef(structuredClone([...selectedItems]));

  const handleReqListChangeRowsPerPage = (event) => {
    setRowsPerReqListPage(parseInt(event.target.value, 10));
    setReqListPage(0);
  };

  useEffect(() => {
    if (selectedItems.length && !errorArray.length) {
      setErrorArray(
        selectedItems.map((item) => {
          return {
            itemId: item.id, //id matches the selectedRequest.requestItems
            hasError: true,
          };
        }),
      );
    }
  }, [selectedItems, errorArray]);

  const clearChanges = () => {
    setSelectedItems(structuredClone([...prevState.current]));
    setIsRequestItemUpdating(false);
  };

  const checkSaveRequest = () => {
    // if (!selectedRequest.FinalDestinationName) {
    //   let needsLocation = selectedItems.some(
    //     (item) =>
    //       item.orderStatusName === OrderStatusEnums.Complete && item.thisItem.stockroomCategoryName === StockroomCategoryNameEnums.Chemicals
    //   );

    //   if (needsLocation) {
    //     setFinalDestinationModal(true);
    //     return;
    //   }
    // }

    let needMidasNumber = selectedItems.some(
      (item) =>
        item.orderStatusName === OrderStatusEnums.Complete &&
        item.thisItem.stockroomCategoryName === StockroomCategoryNameEnums.Chemicals &&
        !item.receivedContainer?.sample?.sampleName,
    );

    if (needMidasNumber) {
      setModalMessage({
        title: "Error!",
        message: "Please provide a valid MIDAS #",
        function: () => {
          setModalMessageIsOpen(false);
        },
      });
      setModalMessageIsOpen(true);
      return;
    }

    let needIssuedQty = selectedItems.some(
      (item) =>
        item.orderStatusName === OrderStatusEnums.Complete &&
        (item.issuedQuantity === undefined || item.issuedQuantity === 0),
    );

    if (needIssuedQty) {
      setModalMessage({
        title: "Error!",
        message: "Please provide a Issued Quantity",
        function: () => {
          setModalMessageIsOpen(false);
        },
      });
      setModalMessageIsOpen(true);
      return;
    }

    saveChanges();
  };

  const updateRequestStatus = () => {
    let allStatusFromRequest = [...new Set(selectedItems.map((item) => item.orderStatusName))];
    let usedStatus = orderStatuses.filter((item) =>
      allStatusFromRequest.includes(item.orderStatusName),
    );
    let lowerStatusOrder = Math.min(...usedStatus.map((item) => item.displayOrder));
    return orderStatuses.find((item) => item.displayOrder === lowerStatusOrder).orderStatusName;
  };

  const saveChanges = async () => {
    let payload = { ...selectedRequest };
    payload.orderStatusName = updateRequestStatus();
    payload.requestItems = selectedItems;

    await createStockRequest(payload)
      .then((response) => {
        setIsRequestItemUpdating(false);

        let { status, data } = response;
        let isSuccessful = status >= 200 && status < 300;

        if (isSuccessful) {
          setModalMessage({
            title: "Success!",
            message: "Your order has been updated.",
            function: () => {
              closeWindow();
            },
          });

          prevState.current = structuredClone([...selectedItems]);
        } else {
          let error =
            data.message ??
            "Unable to complete the request. Please contact a system administrator.";
          setModalMessage({
            title: "Error",
            message: error,
          });
        }

        setModalMessageIsOpen(true);
        handleApplyFilters();
      })
      .catch((err) => {
        setModalMessage({
          title: "Error!",
          message:
            "There was an error when trying to cancel all request items. Please try again later.",
        });
        setModalMessageIsOpen(true);
      });
  };

  const closeWindow = () => {
    setIsModalOpen(false);
    setSelectedItems([]);
    setSelectedRequest({});
  };

  const updateOrderStatus = (e, id) => {
    let newList = [...selectedItems];
    let index = newList.findIndex((item) => item.id === id);

    newList[index].orderStatusName = e.target.value;
    setSelectedItems(newList);
    setIsRequestItemUpdating(true);
  };

  const updateIssuedQuantity = (e, id, min, max) => {
    let newList = [...selectedItems];
    let index = newList.findIndex((item) => item.id === id);

    newList[index].issuedQuantity = e.target.value > max ? max : e.target.value;
    setSelectedItems(newList);
    setIsRequestItemUpdating(true);
  };

  const updateSampleName = (value, id) => {
    let newList = [...selectedItems];
    let index = newList.findIndex((item) => item.id === id);
    if (!newList[index].receivedContainer) newList[index].receivedContainer = {};
    newList[index].receivedContainer.sample = value;
    setSelectedItems(newList);

    setIsRequestItemUpdating(true);
  };

  const updateContainerNumber = (containerNumber, containerId, id) => {
    let newList = [...selectedItems];
    let index = newList.findIndex((item) => item.id === id);
    if (!newList[index].receivedContainer) newList[index].receivedContainer = {};
    newList[index].receivedContainer.containerNumber = containerNumber;
    newList[index].receivedContainer.containerId = containerId;
    newList[index].receivedContainerID = containerId;
    setSelectedItems(newList);
    setIsRequestItemUpdating(true);
  };

  return (
    <Dialog fullWidth maxWidth="xl" open={isModalOpen}>
      <DialogTitle alignSelf="center">Request #{selectedRequest.id}</DialogTitle>
      <DialogContent style={{ paddingBottom: 0, paddingTop: "1rem" }}>
        <>
          <UXDataTableWithoutBody
            tableWidth="100%"
            cols={requestListCols}
            tableId="requestListTable"
            rowLength={selectedItems.length}
            enablePaging
            rowsPerPage={rowsPerReqListPage}
            page={reqListPage}
            handleChangePage={handleReqListPageChange}
            handleChangeRowsPerPage={handleReqListChangeRowsPerPage}>
            <StyledTableBody>
              {selectedItems.length &&
                errorArray.length &&
                selectedItems
                  .slice(
                    reqListPage * rowsPerReqListPage,
                    reqListPage * rowsPerReqListPage + rowsPerReqListPage,
                  )
                  .map((item) => {
                    const itemLocked =
                      (item &&
                        (item.orderStatusName === OrderStatusEnums.Complete ||
                          item.orderStatusName === OrderStatusEnums.Cancelled)) | requestBlocked;
                    return (
                      <StyledTableRow key={item?.id} hover>
                        <StyledTableCellMedium>
                          {item?.thisItem?.siteAssetNumber}
                        </StyledTableCellMedium>
                        <StyledTableCellLarge style={{ width: 420 }}>
                          {item?.thisItem?.description}
                        </StyledTableCellLarge>
                        <StyledTableCellMedium>{item?.thisItem?.subLocation}</StyledTableCellMedium>
                        <StyledTableCellMedium>{item?.requestedQuantity}</StyledTableCellMedium>
                        <StyledTableCellMedium>
                          <TextField
                            style={{ width: "100px" }}
                            InputProps={{
                              inputProps: { min: 0, max: item.requestedQuantity },
                            }}
                            size="small"
                            type="number"
                            value={item.issuedQuantity ?? 0}
                            onChange={(e) => {
                              updateIssuedQuantity(e, item.id, 0, item.requestedQuantity);
                            }}
                            disabled={itemLocked}
                          />
                          {!itemLocked && (
                            <ScannerIncreaseInputButton
                              style={{ width: "100%" }}
                              min={0}
                              max={item.requestedQuantity}
                              initialState={item.issuedQuantity ?? 0}
                              callback={(value) => {
                                let newList = [...selectedItems];
                                let index = newList.findIndex((x) => x.id === item.id);

                                newList[index].issuedQuantity = value;
                                setSelectedItems(newList);
                                setIsRequestItemUpdating(true);
                              }}
                            />
                          )}
                        </StyledTableCellMedium>
                        <StyledTableCellMedium>
                          {item?.thisItem?.stockUnitOfMeasure?.toUpperCase()}
                        </StyledTableCellMedium>
                        <StyledTableCellMedium>{item?.thisItem?.quantity}</StyledTableCellMedium>
                        <StyledTableCellMedium>
                          <ValidatedMidasNumberTextField
                            isDisabled={
                              item.thisItem.stockroomCategoryName !==
                                StockroomCategoryNameEnums.Chemicals || itemLocked
                            }
                            margin="none"
                            showLabel={false}
                            fontSize={16}
                            fieldWidth="10rem"
                            midasNumberObject={item.receivedContainer?.sample ?? ""}
                            setMidasNumberObject={(e) => {
                              updateSampleName(e, item.id);
                            }}
                            hasErrors={
                              errorArray.length
                                ? errorArray.find((a) => a.itemId === item.id)?.hasError
                                : true
                            }
                            setHasErrors={(value) => {
                              let index = errorArray.findIndex((a) => a.itemId === item.id);
                              let newItem = [...errorArray];
                              newItem[index].hasError = value;
                              setErrorArray(newItem);
                            }}
                            chemIDToMatch={null}
                            setScannedContainer={(value) => {
                              if (value && typeof value.containerNumber === "number") {
                                updateContainerNumber(value.containerNumber, value.id, item.id);
                              }
                            }}
                          />
                        </StyledTableCellMedium>
                        <StyledTableCellMedium>
                          <Autocomplete
                            disabled={
                              item.thisItem.stockroomCategoryName !==
                                StockroomCategoryNameEnums.Chemicals || itemLocked
                            }
                            style={{ width: "150px" }}
                            noOptionsText="Loading..."
                            value={item.receivedContainer?.containerNumber?.toString() ?? null}
                            onChange={(_, value) => {
                              let containerId = item.receivedContainer?.sample?.containers.find(
                                (x) => x.containerNumber === value,
                              ).id;
                              updateContainerNumber(value, containerId, item.id);
                            }}
                            disablePortal
                            options={
                              item.receivedContainer?.sample?.containers.map(
                                (x) => x.containerNumber,
                              ) ?? []
                            }
                            renderInput={(params) => (
                              <TextField
                                name="containerNumber"
                                {...params}
                                label="Container #"
                                size="small"
                              />
                            )}
                            autoSelect
                          />
                        </StyledTableCellMedium>
                        <StyledTableCellMedium>
                          <FormControl fullWidth>
                            <Select
                              size="small"
                              name="orderStatus"
                              fullWidth
                              value={item.orderStatusName}
                              onChange={(e) => {
                                updateOrderStatus(e, item.id);
                              }}
                              disabled={itemLocked}>
                              <MenuItem value="" disabled>
                                Select an option
                              </MenuItem>
                              {orderStatuses &&
                                orderStatuses.length &&
                                orderStatuses.map((status) => {
                                  return (
                                    <MenuItem
                                      key={status.orderStatusName}
                                      value={status.orderStatusName}>
                                      {status.orderStatusName}
                                    </MenuItem>
                                  );
                                })}
                            </Select>
                          </FormControl>
                        </StyledTableCellMedium>
                      </StyledTableRow>
                    );
                  })}
            </StyledTableBody>
          </UXDataTableWithoutBody>

          {isRequestItemUpdating && (
            <span style={{ color: "red" }}>*There are unsaved changes</span>
          )}
        </>
        <WarningUnsavedChanges
          cancelAllOrderStatus={closeWindow}
          completeAllOrderStatus={saveChanges}
          setErrorMessage={setWarningSaveModal}
          selectedRequest={selectedRequest}
          selectedItems={selectedItems}
          isModalOpen={warningSaveModal}
          setIsModalOpen={setWarningSaveModal}
          createStockRequest={createStockRequest}
          setIsRequestItemUpdating={setIsRequestItemUpdating}
        />
        <DisplayMessage
          title={modalMessage.title}
          buttonText="Close"
          buttonAction={() => modalMessage.function()}
          setIsModalOpen={setModalMessageIsOpen}
          isModalOpen={modalMessageIsOpen}
          message={modalMessage.message}
        />
        <ModalTwoButtons
          title="Final Destination Not Set"
          button1Text="Save Changes"
          button1Action={() => {
            setFinalDestinationModal(false);
            checkSaveRequest();
          }}
          isButton1Disabled={!canEdit}
          button2Text="Cancel"
          button2Action={() => setFinalDestinationModal(false)}
          isButton2Disabled={!canEdit}
          open={finalDestinationModal}
          setOpen={setFinalDestinationModal}>
          <LocationField
            fieldWidth="100%"
            value={selectedRequest.FinalDestinationName}
            handleChange={(value) => {
              let newRequest = { ...selectedRequest };
              newRequest.FinalDestinationName = value;
              setSelectedRequest(newRequest);
            }}
            hasErrors={false}
            label="Final Location"
            disablePortal={false}
            isDisabled={!canEdit}
          />
        </ModalTwoButtons>
      </DialogContent>
      <DialogActions style={{ paddingBottom: "1.5rem", paddingTop: 0, alignSelf: "center" }}>
        <GlobalButton
          style={{
            marginTop: "35px",
            marginLeft: "6px",
          }}
          variant="contained"
          onClick={checkSaveRequest}
          disabled={!canEdit}>
          Save Changes
        </GlobalButton>

        <GlobalSecondaryButton
          style={{
            marginTop: "35px",
            marginLeft: "20px",
          }}
          variant="contained"
          onClick={clearChanges}
          disabled={!canEdit}>
          Clear Changes
        </GlobalSecondaryButton>

        <GlobalSecondaryButton
          style={{
            marginTop: "35px",
            marginLeft: "20px",
          }}
          variant="contained"
          onClick={() => {
            if (isRequestItemUpdating) {
              setWarningSaveModal(true);
            } else {
              closeWindow();
            }
          }}>
          Close
        </GlobalSecondaryButton>
      </DialogActions>
    </Dialog>
  );
}
