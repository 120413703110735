import * as yup from "yup";

export type SqcMethodManagementForm = {
  methodFacilityID?: number;
  methodFacility: {
    availableInstruments: {
      instrumentID: number;
      isActive: boolean;
    }[];
  } | null;
  isActive: boolean;
  isTemperatureDependent: boolean;
  trackedComponents: {
    resultComponentName: string;
    isInformational: boolean;
  }[];
  qcids: {
    sqcSampleID: string;
  }[];
  allowedTemperatures: {
    temperature: number;
  }[];
  limits: {
    id?: number;
    sqcSampleID: string;
    resultComponentName: string;
    isActive: boolean;
    createdDate: string;
    createdByEmail: string;
    lastModifiedByEmail: string | null;
    lastModifiedDate: string | null;
    retiredDate: string | null;
    sigmaValue: number | null;
    meanValue: number | null;
    upperControlLimitValue: number | null;
    lowerControlLimitValue: number | null;
    applicableTemperatureValue: number | null;
    rule1ViolationBehavior: 1 | 2 | 3;
    rule2ViolationBehavior: 1 | 2 | 3;
    rule3ViolationBehavior: 1 | 2 | 3;
    rule4ViolationBehavior: 1 | 2 | 3;
    failureWindowBefore: number | null;
    failureWindowAfter: number | null;
  }[];
  sqcastm: {
    id?: number;
    resultComponentName: string;
    revisionNumber: string | null;
    littleR: number | null;
    bigR: number | null;
    createdByEmail: string;
    createdDate: string;
    isActive: boolean;
    equationForm: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8;
    a: number | null;
    c: number | null;
    x: number | null;
    y: number | null;
    deactivationDate: string | null;
  }[];
};

export const INITIAL_VALUE: SqcMethodManagementForm = {
  methodFacility: {
    availableInstruments: [],
  },
  isActive: true,
  isTemperatureDependent: false,
  trackedComponents: [],
  qcids: [],
  allowedTemperatures: [],
  limits: [],
  sqcastm: [],
};

export const sqcMethodManagementSchema = yup.object({
  limits: yup.array().of(
    yup
      .object({
        sigmaValue: yup.number().nullable(),
        meanValue: yup.number().nullable(),
        upperControlLimitValue: yup.number().nullable(),
        lowerControlLimitValue: yup.number().nullable(),

        rule1ViolationBehavior: yup.number().required(),
        rule2ViolationBehavior: yup.number().required(),
        rule3ViolationBehavior: yup.number().required(),
        rule4ViolationBehavior: yup.number().required(),
      })
      .test(
        "valid-limit-option",
        "A limit option must be selected",
        (values) =>
          (values.meanValue !== null && values.sigmaValue !== null) ||
          (values.upperControlLimitValue !== null &&
            values.lowerControlLimitValue !== null)
      )
  ),
  sqcastm: yup.array().of(
    yup
      .object({
        littleR: yup.number().nullable(),
        bigR: yup.number().nullable(),
        equationForm: yup.number().nullable(),
        a: yup.number().nullable(),
        c: yup.number().nullable(),
        x: yup.number().nullable(),
        y: yup.number().nullable(),
      })
      .test(
        "valid-astm-option",
        "An equation option must be selected",
        (values) => {
          if (!values.equationForm)
            return values.littleR !== null && values.bigR !== null;

          if (values.equationForm === 1) return values.a !== null;

          if (values.equationForm === 2 || values.equationForm === 3)
            return values.a !== null && values.c !== null;

          if (values.equationForm === 4)
            return values.a !== null && values.x !== null;

          if (values.equationForm === 5 || values.equationForm === 8)
            return values.a !== null && values.c !== null && values.x !== null;

          if (values.equationForm === 6)
            return values.a !== null && values.x !== null && values.y !== null;

          if (values.equationForm === 7)
            return (
              values.a !== null &&
              values.c !== null &&
              values.x !== null &&
              values.y !== null
            );
        }
      )
  ),
});
