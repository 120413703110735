import React from "react";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import './index.css'

const PageUnauthorized = ({ ...props }) => {
  return (
    <div className="container">
      <ErrorOutlineIcon sx={{ fontSize: 50}} style={{ fill: "red" }}/>
      <p>You are not authorized to access this page</p>
    </div>
  );
};

export default PageUnauthorized;
