import React from "react";
import Header from "../Header";
import Navbar from "../Navbar";
import Box from "@mui/material/Box";
import "./index.css";

/**
 *
 * The main layout of the application. Uses a ThemeProvider to toggle
 * between a light and dark theme, and a React Unity Page component to set the layout of the page.
 *
 * To learn more about the ThemeProvider in React Unity, see: https://reactunity.azurewebsites.net/#/Theme?id=themeprovider
 *
 * To learn more about the Page and other possible layouts in React Unity, see: https://reactunity.azurewebsites.net/#/Layouts
 *
 */

const Layout = ({ children, ...props }) => {
  return (
    <>
      <Header position='fixed' />
      <Navbar />
      <Box className='main-content'> {children} </Box>
    </>
  );
};

export default Layout;
