import { Box, Table, TableBody, TableCell, TableRow, TextField} from "@mui/material";
import { styled } from "@mui/material/styles";
import { convertToLocalTime, convertDateFormat } from "../../../global";
import React, { useState } from "react";
import { IconButton, Menu, MenuItem, } from "@mui/material";
import MoreOptions from "@mui/icons-material/MoreVert";
import Batch from "../../../api/Formulations/Batch";
import ModalSimpleButton from "../../../components/Modal/ModalSimpleButton";
import ModalTwoButtons from "../../../components/Modal/ModalTwoButtons"


const fontSizeMinor = "13px"

const StyledTableCellMinor5Items = styled(TableCell)({
    border: "none",
    paddingBottom:"0px",
    paddingTop:"0px",
    color:"#00000099",
    fontFamily:"EMprint",
    fontWeight:"400",
    fontSize: fontSizeMinor,
    width:"20%"
});

const StyledTableCellNoBorder = styled(TableCell)({
    border: "none",
    paddingBottom:"0px",
    paddingTop:"0px",
    color:"#000000DE",
    fontFamily:"EMprint",
    fontWeight:"400",
    fontSize: "16px",
    width:"20%"
});

const StyledTableCellNoBorderCondensed = styled(TableCell)({
    border: "none",
    paddingBottom:"15px",
    paddingTop:"0px",
    color:"#000000DE",
    fontFamily:"EMprint",
    fontWeight:"400",
    fontSize: "16px",
    width:"20%"
});

const BlendDetails = ({batchInfo, setBatchInfo, setReloadBatchInfo, hasBlendStatusMaintainer}) => {
    const [anchorEl, setAnchorEl] = useState(null);

    const [modalMessagesOpen, setModalMessagesOpen] = useState(false);
    const modalMessagesButtonText = 'Ok'
    const [modalMessagesTitle, setModalMessagesTitle] = useState('');
    const [modalMessagesText, setModalMessagesText] = useState('');

    const [redirect, setRedirect] = useState(false)

    const [modalCancelBatchOpen, setModalCancelBatchOpen] = useState(false);
    const [modalCancelBatchButton1Text, setModalCancelBatchButton1Text] = useState('');
    const [modalCancelBatchButton2Text, setModalCancelBatchButton2Text] = useState('');
    const [modalCancelBatchTitle, setModalCancelBatchTitle] = useState('');
    const [modalCancelBatchText, setModalCancelBatchText] = useState('');

    const [cancelReason, setCancelReason] = useState(null)

    const openMenu = () => {
        return anchorEl != null
    }

    const handleOpenMoreOptions = (event) => {
        setAnchorEl(event.currentTarget)
    }
    
    const handleCloseMenu = () => {
        setAnchorEl(null)
    }

    function closeModalMessages() {
        if (redirect)
        {
            window.location.href = "/blends"
        } else {
            setModalMessagesOpen(false);
        }
    }
    
    function openModalMessages(title, text) {
        setModalMessagesOpen(true);
        setModalMessagesTitle(title);
        setModalMessagesText(text);
    }

    function closeModalCancelReason() {
        setModalCancelBatchOpen(false);
    }
    
    function openModalCancelReason(title, text, buttonText, button2Text) {
        setModalCancelBatchButton1Text(buttonText)
        setModalCancelBatchButton2Text(button2Text)
        setModalCancelBatchOpen(true);
        setModalCancelBatchTitle(title);
        setModalCancelBatchText(text);
    }

    function SetBatchStatus (batchID, newBatchStatusName)
    {

        if (newBatchStatusName === 'Cancelled' && (cancelReason === null || cancelReason.trim() === ''))
        {
            openModalMessages('Batch Error', "You must provide a cancellation reason!")
            return;
        }

        Batch.updateBatchStatus(batchID, newBatchStatusName, cancelReason === null || cancelReason.trim() === '' ? '' : cancelReason).then((res) => {
            if (res.message === 'Success') {

                if (newBatchStatusName !== 'InProgress')
                {
                    setRedirect(true)
                } else {
                    setRedirect(false)
                }

                openModalMessages('Batch Update Complete', `Batch set to ${newBatchStatusName}!`)
                setReloadBatchInfo(true)
            }
            else
            {
                setRedirect(false)

                if (res.message)
                {
                    openModalMessages('Batch Update Failed', `${res.message}. Contact support if you feel this is an error.`);
                } else {
                    openModalMessages('Batch Update Failed', `Unspecified Error, Contact support if you feel this is an error.`);
                }
            }
        });
    }

    function ConfirmCancel()
    {
        SetBatchStatus(batchInfo.id, 'Cancelled')
    }

    return (
        <div>
            <Box sx={{ boxShadow: 3,
            width: '100%',
            p: 2,
            m: 1,
            borderRadius: 2,
            }}>    
                <Table>
                    <TableBody>
                        <TableRow>
                            <StyledTableCellMinor5Items>Study_Blend/Batch</StyledTableCellMinor5Items>
                            <StyledTableCellMinor5Items>Description</StyledTableCellMinor5Items>
                            <StyledTableCellMinor5Items>Purpose</StyledTableCellMinor5Items>
                            <StyledTableCellMinor5Items>Submitted</StyledTableCellMinor5Items>
                            <StyledTableCellMinor5Items>Owner</StyledTableCellMinor5Items>
                        </TableRow>
                        <TableRow>
                            <StyledTableCellNoBorder>{batchInfo && batchInfo.blendRequest ? `${batchInfo.blendRequest.blendStudy.studyName}_${batchInfo.blendRequest.blendName}/${batchInfo.batchSequenceNumber}` : '-'}</StyledTableCellNoBorder>
                            <StyledTableCellNoBorder>{batchInfo && batchInfo.description ? batchInfo.description : '-'}</StyledTableCellNoBorder>
                            <StyledTableCellNoBorder>{batchInfo && batchInfo.blendPurpose ? batchInfo.blendPurpose : '-'}</StyledTableCellNoBorder>
                            <StyledTableCellNoBorder>{batchInfo ? convertToLocalTime(batchInfo.createdDate) : '-'}</StyledTableCellNoBorder>
                            <StyledTableCellNoBorder>{batchInfo && batchInfo.blendRequest ? batchInfo.blendRequest.blendStudy.ownerEmail : '-'}</StyledTableCellNoBorder>
                        </TableRow>
                    </TableBody>
                </Table>

                <Table style={{marginTop:"2rem"}}>
                    <TableBody>
                        <TableRow>
                            <StyledTableCellMinor5Items>Facility</StyledTableCellMinor5Items>
                            <StyledTableCellMinor5Items>Reference</StyledTableCellMinor5Items>
                            <StyledTableCellMinor5Items>Batch Status</StyledTableCellMinor5Items>
                            <StyledTableCellMinor5Items>ISO Blend</StyledTableCellMinor5Items>
                            <StyledTableCellMinor5Items>Metals Free</StyledTableCellMinor5Items>

                        </TableRow>
                        <TableRow>
                            <StyledTableCellNoBorder>{batchInfo && batchInfo.blendFacilityName ? batchInfo.blendFacilityName : '-'}</StyledTableCellNoBorder>
                            <StyledTableCellNoBorder>{batchInfo && batchInfo.reference ? batchInfo.reference : '-'}</StyledTableCellNoBorder>
                            <StyledTableCellNoBorder>{batchInfo && batchInfo.batchStatusName ? batchInfo.batchStatusName : '-'}</StyledTableCellNoBorder>
                            <StyledTableCellNoBorder>{batchInfo ? batchInfo.isISOBlend ? 'Yes' : 'No' : '-'}</StyledTableCellNoBorder>
                            <StyledTableCellNoBorder>{batchInfo ? batchInfo.isMetalsFree ? 'Yes' : 'No' : '-'}</StyledTableCellNoBorder>
                        </TableRow>
                    </TableBody>
                </Table>

                <Table style={{marginTop:"2rem"}}>
                    <TableBody>
                        <TableRow>
                            <StyledTableCellMinor5Items>Priority</StyledTableCellMinor5Items>
                            <StyledTableCellMinor5Items>Requested Amt.</StyledTableCellMinor5Items>
                            <StyledTableCellMinor5Items>Special Handling</StyledTableCellMinor5Items>
                            <StyledTableCellMinor5Items>Requested Completion</StyledTableCellMinor5Items>
                            <StyledTableCellMinor5Items>Batch State</StyledTableCellMinor5Items>
                        </TableRow>
                        <TableRow>
                            <StyledTableCellNoBorderCondensed>{batchInfo && batchInfo.blendPriorityName  ? batchInfo.blendPriorityName : '-'}</StyledTableCellNoBorderCondensed>
                            <StyledTableCellNoBorderCondensed>{batchInfo && batchInfo.requestedAmount ? `${batchInfo.requestedAmount} ${batchInfo.requestedAmountUoM}` : '-'}</StyledTableCellNoBorderCondensed>
                            <StyledTableCellNoBorderCondensed>{batchInfo && batchInfo.safetyInformation ? batchInfo.safetyInformation : '-'}</StyledTableCellNoBorderCondensed>
                            <StyledTableCellNoBorderCondensed>{batchInfo && batchInfo.requestedCompletionDate ? convertDateFormat(batchInfo.requestedCompletionDate) : '-'}</StyledTableCellNoBorderCondensed>
                            <StyledTableCellNoBorder>
                                {batchInfo.batchStatusName}
                                <IconButton aria-label="more options" 
                                    onClick={(e) => {handleOpenMoreOptions(e)}}
                                >
                                    <MoreOptions color='black'/>
                                </IconButton>
                            </StyledTableCellNoBorder>
                        </TableRow>
                    </TableBody>
                </Table>

                <TextField style={{width: "40%"}} size="small" margin="normal" variant="outlined" label="Batch Comments" InputLabelProps={{shrink: true}}
                    onChange={e => setBatchInfo(() => ({
                        ...batchInfo,
                        batchComments: e.target.value
                    }))}
                    multiline
                    value={batchInfo.batchComments === null ? '' : batchInfo.batchComments}
                    //disabled={HasMetaDataLock()}
                ></TextField>
                
                {/* <TextField style={{marginLeft:"1rem", width: "59%"}}size="small" margin="normal" variant="outlined" label="Comments History" InputLabelProps={{shrink: true}}
                    // onChange={e => setCurrentSample(() => ({
                    //     ...currentSample,
                    //     densityAtSTP: e.target.value
                    // }))}
                    multiline
                    value={batchInfo.batchComments === null ? '' : batchInfo.batchComments}
                    disabled
                ></TextField>        */}
            </Box>

            <Menu
              anchorEl={anchorEl}
              open={openMenu()}
              onClose={(e) => handleCloseMenu(e)}>
                <MenuItem 
                    disabled={batchInfo.batchStatusName === 'Prepared' || batchInfo.batchStatusName === 'InProgress' || batchInfo.batchStatusName === 'Cancelled' || !(hasBlendStatusMaintainer)}
                    onClick={() => {handleCloseMenu(); SetBatchStatus(batchInfo.id, 'InProgress')}}
                >Set Batch InProgress</MenuItem>

                <MenuItem
                    disabled={batchInfo.batchStatusName === 'Prepared' || batchInfo.batchStatusName === 'OnHold' || batchInfo.batchStatusName === 'Cancelled' || !(hasBlendStatusMaintainer)}
                    onClick={() => {handleCloseMenu(); SetBatchStatus(batchInfo.id, 'OnHold')}}
                >Put Batch OnHold</MenuItem>

                <MenuItem
                    disabled={batchInfo.batchStatusName === 'Prepared' || batchInfo.batchStatusName === 'Needs Review' || batchInfo.batchStatusName === 'Cancelled' || !(hasBlendStatusMaintainer)}
                    onClick={() => {handleCloseMenu(); SetBatchStatus(batchInfo.id, 'Needs Review')}}
                >Send Back to Formulator</MenuItem>

                <MenuItem
                    disabled={batchInfo.batchStatusName === 'Prepared' || batchInfo.batchStatusName === 'Cancelled' || !(hasBlendStatusMaintainer)}
                    onClick={() => {handleCloseMenu(); openModalCancelReason("Cancel Reason", "Enter a Batch Cancellation Reason", "Confirm", "Cancel")}}
                >Cancel Batch</MenuItem>
            </Menu>

             {/* Informational Messages */}
            <ModalSimpleButton title={modalMessagesTitle} buttonText={modalMessagesButtonText} buttonAction={closeModalMessages} open={modalMessagesOpen} setOpen={setModalMessagesOpen}>
                <label>
                {modalMessagesText}
                </label>
            </ModalSimpleButton>

            {/* Cancel Batch Reason */}
            <ModalTwoButtons title={modalCancelBatchTitle} button1Text={modalCancelBatchButton1Text} button1Action={ConfirmCancel} button2Text={modalCancelBatchButton2Text} button2Action={closeModalCancelReason} open={modalCancelBatchOpen} setOpen={setModalCancelBatchOpen}>
            <label>
                {modalCancelBatchText}
            </label>

            <div></div>

            <TextField
                size="small"
                label = "Cancel Reason"
                value = {cancelReason === null ? '' : cancelReason}
                onChange = {e => setCancelReason(e.target.value)}
                margin = "normal"
                style={{width:250, marginTop:40}}
                inputProps={{ maxLength: 250 }}
                ></TextField>
            </ModalTwoButtons>
        </div>


    )
};
export default BlendDetails;