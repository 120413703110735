import React, { useContext, useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import logo from "../../assets/midas3logo.png";
import {
  AppBar,
  Autocomplete,
  Badge,
  Box,
  IconButton,
  InputBase,
  Menu,
  MenuItem,
  Toolbar,
} from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import SearchIcon from "@mui/icons-material/Search";
import { styled, alpha } from "@mui/material/styles";
// import { useMsal } from "@azure/msal-react";
import "./index.css";
import UserContext from "../../context/UserContext";
import msalInstance from "../../services/auth/msalInstance";
import { NotificationContext } from "../../context/NotificationContext";

//import { userInfo } from "../../global";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.black, 0.25),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.black, 0.2),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
  verticalAlign: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));

const SearchItem = ({ muiProps, input, suffix }) => (
  <li {...muiProps} value={input}>
    <b>{input}</b>
    <span style={{ marginLeft: "5px" }}>{suffix}</span>
  </li>
);

const Header = (props) => {
  // const { instance } = useMsal();
  // const account = instance.getActiveAccount();
  const currentUser = useContext(UserContext)
  const { unreadNotificationsCount, getNotificationsForUser } = useContext(NotificationContext)
  //turn the account object into a global variable
  // <userInfo.Provider value={account}>
  // </userInfo.Provider>

  const [anchorEl, setAnchorEl] = useState(null);
  const [searchInput, setSearchInput] = useState("");
  //searchOptionLocations will need to line up with order in global search page
  const searchOptionLocations = [
    "in everything",
    "in Samples",
    "in Tests",
    "in Containers",
    "in Requests",
    "in Blends",
    "in Distillations",
    "in Stationary Tests",
  ];
  const history = useHistory();

  const isMenuOpen = Boolean(anchorEl);

  const location = useLocation();

  useEffect(() => {
    getNotificationsForUser()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const menuId = "primary-search-account-menu";

  const handleLogout = () => {

    if (msalInstance !== null && msalInstance !== undefined) {
      var homeAccount = window.localStorage.getItem('HOME_ACCOUNT_ID');

      const currentAccount = msalInstance.getAccountByHomeId(homeAccount);

      let logoutRequest = {
        account: currentAccount,
        postLogoutRedirectUri: "/logout",
      }

      msalInstance.logoutRedirect(logoutRequest)
    }
  }

  const handleProfileDisplay = (e) => {
    setAnchorEl(null);
    history.push({
      pathname: "/profile"
    })
  }

  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleProfileDisplay}>Profile</MenuItem>
      <MenuItem onClick={handleLogout}>Logout</MenuItem>
    </Menu>
  );


  const handleSearchInputChange = (e) => {
    const input = e?.target?.value;
    if (e?.type === "change") {
      setSearchInput(input);
    } else if (e?.type === "click") {
      const searchLocationIndex = e.target.dataset.optionIndex
        ? e.target.dataset.optionIndex
        : e.target.parentElement.dataset.optionIndex;
      history.push({
        pathname: "/search",
        state: {
          searchCriteria: searchInput,
          searchLocation: searchLocationIndex,
        },
      });
    }
  };

  const optionRenderer = (props, option, state) => {
    return (
      <SearchItem
        muiProps={props}
        input={searchInput}
        suffix={option}
        key={option}
        value={searchInput}
      />
    );
  };

  const searchInputRenderer = (params) => {
    return (
      <StyledInputBase
        inputProps={params.inputProps}
        ref={params.InputProps.ref}
        className='searchInput'
        placeholder='Search for Samples, Tests, Containers, Requests, and more'
      />
    );
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      history.push({
        pathname: "/search",
        state: {
          searchCriteria: searchInput,
          searchLocation: 0,
        },
      });
    }
  }

  const handleSearchIconClick= (event) => {
    history.push({
      pathname: "/search",
      state: {
        searchCriteria: searchInput,
        searchLocation: 0,
      },
    });
  }

  const handleMIDASClick = (event) => {
    window.location.href = "/"
    
  }

  const handleHelp = (event) => {
    history.push({
      pathname: "/help"
    })
  }

  useEffect(() => {
    getNotificationsForUser()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [unreadNotificationsCount])

  function GetClassName () {
    if (process.env.REACT_APP_ENV === 'Dev' || process.env.REACT_APP_ENV === 'Test')
    {
      return 'midasHeaderDEV'

    } else if (process.env.REACT_APP_ENV === 'Acc')
    {
      return 'midasHeaderACC'

    } else {
      return 'midasHeader'
    }
  }

  function GetHeaderVerbiage () {
    if (process.env.REACT_APP_ENV === 'Dev')
    {
      return '***DEVELOPMENT***'

    } else if (process.env.REACT_APP_ENV === 'Test')
    {
      return '***TEST***'

    } else if (process.env.REACT_APP_ENV === 'Acc')
    {
      return '***ACCEPTANCE***'

    } else {
      return ''
    }
  }
  
  return (
    <>
      <AppBar className={GetClassName()}>
        <Toolbar>
          <img className='midasLogo' src={logo} alt='MIDAS3 logo' onClick={handleMIDASClick} />
          <div className='search'>
            <Search>
              <div style={{ display: "flex" }}>
                <Autocomplete
                  freeSolo
                  options={searchOptionLocations}
                  getOptionLabel={(e) => e}
                  inputValue={searchInput}
                  renderInput={searchInputRenderer}
                  className='searchInput'
                  onInputChange={handleSearchInputChange}
                  onKeyDown={handleKeyDown}
                  renderOption={optionRenderer}
                  filterOptions={(o) => o}
                  autoHighlight
                  autoSelect
                  //renderInput={(params) => <TextField {...params} variant="outlined" size="small" margin="normal" label="Select Label Stock" error = {errorChecks.substance} InputLabelProps={{shrink: true}} InputProps={{ ...params.InputProps }}/>} 
            
                />
                <SearchIcon className='searchIcon'
                  onClick={() => handleSearchIconClick()}> 
                </SearchIcon>

              </div>
            </Search>
          </div>
          {/* <IconButton color='inherit'>
            <span className='headerFont'>Advanced Search</span>
            <ArrowDropDownIcon />
          </IconButton> */}

          <label style={{fontSize:40}}>{GetHeaderVerbiage()}</label>

          <Box sx={{ flexGrow: 1 }} />
          <div>
            <IconButton
              color='inherit'
              onClick={handleHelp}>
              <Badge color='secondary'>
                <HelpOutlineIcon />
              </Badge>
            </IconButton>
            <IconButton 
              aria-label='show new notifications' 
              color='inherit'
              onClick={() => history.push('/notifications')}
            >
              <Badge badgeContent={unreadNotificationsCount} color='secondary'>
                <NotificationsNoneIcon />
              </Badge>
            </IconButton>
            <IconButton
              aria-label='account of current user'
              aria-controls={menuId}
              aria-haspopup='true'
              onClick={handleProfileMenuOpen}
              color='inherit'
            >
              <AccountCircleIcon /> <span className='headerFont'>{currentUser.name}</span>
              <ArrowDropDownIcon />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
      {renderMenu}
    </>
  );
};

export default Header;
