import AddCircleIcon from "@mui/icons-material/AddCircle";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Radio from "@mui/material/Radio";
import Stack from "@mui/material/Stack";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableFooter from "@mui/material/TableFooter";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { useFormikContext } from "formik";
import { useState } from "react";
import { convertToLocalTime } from "../../../global";
import { SQCMethodLimit } from "../../../lib/types/sqc";
import { useSqcMethodManagement } from "./SqcMethodManagementContext";
import { SqcMethodManagementForm } from "./form";

type SqcLimitRule = {
  name: "1" | "2" | "3" | "4";
  description: string;
  disabled?: boolean;
};

const rules: SqcLimitRule[] = [
  {
    name: "1",
    description: "1 point outside 3 sigma",
    disabled: true,
  },
  {
    name: "2",
    description: "2 out of 3 consecutive points greater than 2 sigma",
  },
  {
    name: "3",
    description: "4 out of 5 consecutive points greater than 1 sigma",
  },
  {
    name: "4",
    description: "7+ consecutive points out of sigma",
  },
];

type RuleRowProps = {
  activeLimitIndex: number;
  rule: SqcLimitRule;
  disabled: boolean;
};

function RuleRow({ rule, activeLimitIndex, disabled }: RuleRowProps) {
  const formik = useFormikContext<SqcMethodManagementForm>();
  const keyName = `rule${rule.name}ViolationBehavior` as const;

  return (
    <>
      <Typography fontSize="0.875rem">
        <strong>Rule {rule.name}:</strong> {rule.description}
      </Typography>

      <Box
        sx={{
          display: "grid",
          gap: "2rem",
          gridTemplateColumns: "repeat(3, 1fr)",
          alignItems: "center",
          justifyItems: "center",
        }}
      >
        <div>
          <Radio
            checked={formik.values.limits[activeLimitIndex][keyName] === 1}
            value={1}
            onChange={(event) =>
              formik.setFieldValue(
                `limits.${activeLimitIndex}.${keyName}`,
                +event.target.value
              )
            }
            disabled={disabled || rule.disabled}
          />
        </div>

        <div>
          <Radio
            checked={formik.values.limits[activeLimitIndex][keyName] === 2}
            value={2}
            onChange={(event) =>
              formik.setFieldValue(
                `limits.${activeLimitIndex}.${keyName}`,
                +event.target.value
              )
            }
            disabled={disabled || rule.disabled}
          />
        </div>

        <div>
          <Radio
            checked={formik.values.limits[activeLimitIndex][keyName] === 3}
            value={3}
            onChange={(event) =>
              formik.setFieldValue(
                `limits.${activeLimitIndex}.${keyName}`,
                +event.target.value
              )
            }
            disabled={disabled || rule.disabled}
          />
        </div>
      </Box>
    </>
  );
}

type SqcMethodManagementLimitsSetsProps = {
  limits: SQCMethodLimit[];
};

export function SqcMethodManagementLimitsSets({
  limits,
}: SqcMethodManagementLimitsSetsProps) {
  const formik = useFormikContext<SqcMethodManagementForm>();
  const { activeLimitIndex, setActiveLimitIndex } = useSqcMethodManagement();
  const [selectedId, setSelectedId] = useState<number>();
  const [option, setOption] = useState<"1" | "2" | null>();

  const [selectedQcid, setSelectedQcid] = useState<string | null>(null);
  const [selectedParameter, setSelectedParameter] = useState<string | null>(
    null
  );
  const [selectedTemperature, setSelectedTemperature] = useState<number | null>(
    null
  );
  const [page, setPage] = useState(0);

  const ROWS_PER_PAGE = 5;

  function getSelectedOption(index: number | null): "1" | "2" | null {
    if (index == null) return null;

    const limit = formik.values.limits[index];

    const optionOneHasValues =
      limit.meanValue !== null || limit.sigmaValue !== null;

    const optionTwoHasValues =
      limit.upperControlLimitValue !== null ||
      limit.lowerControlLimitValue !== null;

    return optionOneHasValues ? "1" : optionTwoHasValues ? "2" : null;
  }

  if (option === undefined) {
    setOption(getSelectedOption(activeLimitIndex));
  }

  const handleChangeOption = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedOption = event.target.value as "1" | "2";

    if (selectedOption === "1") {
      formik.setFieldValue(
        `limits.${activeLimitIndex}.upperControlLimitValue`,
        null
      );
      formik.setFieldValue(
        `limits.${activeLimitIndex}.lowerControlLimitValue`,
        null
      );
    } else {
      formik.setFieldValue(`limits.${activeLimitIndex}.meanValue`, null);
      formik.setFieldValue(`limits.${activeLimitIndex}.sigmaValue`, null);
    }

    setOption(selectedOption);
  };

  const handleCancel = () => {
    formik.setFieldValue("limits", formik.values.limits.slice(0, -1));
    setActiveLimitIndex(null);
  };

  const handleCreate = () => {
    const previousLimit = formik.values.limits.find(
      (l) =>
        l.isActive &&
        l.sqcSampleID === selectedQcid &&
        l.resultComponentName === selectedParameter &&
        (formik.values.isTemperatureDependent
          ? l.applicableTemperatureValue === selectedTemperature
          : true)
    );

    if (previousLimit) {
      previousLimit.isActive = false;
      previousLimit.retiredDate = new Date().toJSON();
      previousLimit.lastModifiedDate = new Date().toJSON();
    }

    const newLimits = formik.values.limits.concat([
      {
        sqcSampleID: selectedQcid!,
        resultComponentName: selectedParameter!,
        isActive: true,
        createdDate: undefined!,
        createdByEmail: undefined!,
        lastModifiedByEmail: undefined!,
        lastModifiedDate: undefined!,
        retiredDate: null,
        sigmaValue: null,
        meanValue: null,
        upperControlLimitValue: null,
        lowerControlLimitValue: null,
        applicableTemperatureValue: formik.values.isTemperatureDependent
          ? selectedTemperature
          : null,
        rule1ViolationBehavior: 3,
        rule2ViolationBehavior: null!,
        rule3ViolationBehavior: null!,
        rule4ViolationBehavior: null!,
        failureWindowBefore: null,
        failureWindowAfter: null,
      },
    ]);

    formik.setFieldValue("limits", newLimits);
    setActiveLimitIndex(newLimits.length - 1);
    setSelectedId(undefined);
    setOption(null);
  };

  const hasValidFilter = Boolean(
    selectedQcid &&
      selectedParameter &&
      (formik.values.isTemperatureDependent ? selectedTemperature : true)
  );

  const filteredLimits = hasValidFilter
    ? limits.filter(
        (l) =>
          l.sqcSampleID === selectedQcid &&
          l.resultComponentName === selectedParameter &&
          (formik.values.isTemperatureDependent
            ? l.applicableTemperatureValue === selectedTemperature
            : true)
      )
    : [];

  return (
    <Stack sx={{ gap: "2rem", paddingBlockStart: "1rem" }}>
      <Stack direction="row" gap="1rem" alignItems="center">
        <Typography
          component="h3"
          sx={{ fontSize: "1.25rem", fontWeight: 600 }}
        >
          Active Limits Set
        </Typography>

        <Button
          variant="text"
          startIcon={<AddCircleIcon />}
          onClick={handleCreate}
          disabled={
            !hasValidFilter || (activeLimitIndex != null && selectedId == null)
          }
        >
          New Limits Set
        </Button>

        {activeLimitIndex != null && selectedId == null && (
          <Button variant="text" onClick={handleCancel}>
            Cancel
          </Button>
        )}
      </Stack>

      <Stack direction="row" sx={{ gap: "0.5rem" }}>
        <Autocomplete
          size="small"
          options={formik.values.qcids.map((q) => q.sqcSampleID)}
          renderInput={(params) => <TextField {...params} label="QCID" />}
          value={selectedQcid}
          onChange={(event, value) => {
            setActiveLimitIndex(null);
            setSelectedId(undefined);
            setSelectedQcid(value);
          }}
          disabled={activeLimitIndex != null && selectedId == null}
          sx={{ width: "16rem" }}
        />

        <Autocomplete
          size="small"
          options={formik.values.trackedComponents
            .filter((t) => !t.isInformational)
            .map((t) => t.resultComponentName)}
          renderInput={(params) => (
            <TextField {...params} label="Analysis Parameter" />
          )}
          value={selectedParameter}
          onChange={(event, value) => {
            setActiveLimitIndex(null);
            setSelectedId(undefined);
            setSelectedParameter(value);
          }}
          disabled={activeLimitIndex != null && selectedId == null}
          sx={{ width: "24rem" }}
        />

        {formik.values.isTemperatureDependent && (
          <Autocomplete
            size="small"
            options={formik.values.allowedTemperatures.map(
              (a) => a.temperature
            )}
            getOptionLabel={(option) => option.toString()}
            renderInput={(params) => (
              <TextField {...params} label="Temperature (°C)" />
            )}
            value={selectedTemperature}
            onChange={(event, value) => {
              setActiveLimitIndex(null);
              setSelectedId(undefined);
              setSelectedTemperature(value);
            }}
            disabled={activeLimitIndex != null && selectedId == null}
            sx={{ width: "12rem" }}
          />
        )}
      </Stack>

      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell sx={{ width: "4rem" }} />

              <TableCell>Status</TableCell>

              <TableCell>QCID</TableCell>

              <TableCell>Analysis Parameter</TableCell>

              {formik.values.isTemperatureDependent && (
                <TableCell>Temperature (°C)</TableCell>
              )}

              <TableCell>Created Date</TableCell>

              <TableCell>Retired Date</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {Array.from(filteredLimits)
              .reverse()
              .slice(page * ROWS_PER_PAGE, page * ROWS_PER_PAGE + ROWS_PER_PAGE)
              .map((l) => (
                <TableRow key={l.id}>
                  <TableCell>
                    <Radio
                      checked={l.id === selectedId}
                      onChange={() => {
                        setSelectedId(l.id);

                        const index = formik.values.limits.findIndex(
                          (ll) => ll.id === l.id
                        );

                        setActiveLimitIndex(index);
                        setOption(getSelectedOption(index));
                      }}
                      disabled={activeLimitIndex != null && selectedId == null}
                    />
                  </TableCell>

                  <TableCell>{l.isActive ? "Active" : "Inactive"}</TableCell>

                  <TableCell>{l.sqcSampleID}</TableCell>

                  <TableCell>{l.resultComponentName}</TableCell>

                  {formik.values.isTemperatureDependent && (
                    <TableCell>{l.applicableTemperatureValue}</TableCell>
                  )}

                  <TableCell>{convertToLocalTime(l.createdDate)}</TableCell>

                  <TableCell>{convertToLocalTime(l.retiredDate)}</TableCell>
                </TableRow>
              ))}

            {filteredLimits.length === 0 && (
              <TableRow>
                <TableCell colSpan={7}>
                  No limits that match the filter are available
                </TableCell>
              </TableRow>
            )}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[]}
                colSpan={7}
                count={filteredLimits.length}
                rowsPerPage={ROWS_PER_PAGE}
                page={page}
                onPageChange={(event, value) => setPage(value)}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>

      {activeLimitIndex !== null && (
        <>
          <Stack sx={{ gap: "1rem" }}>
            <Stack direction="row" alignItems="center" sx={{ gap: "0.5rem" }}>
              <Typography component="h4" sx={{ fontSize: "1.25rem" }}>
                Limits
              </Typography>

              {/* Future enhancement */}
              {/* <Button variant="text" startIcon={<CalculateIcon />}>
            Calculator
          </Button> */}
            </Stack>

            <Stack gap="0.5rem">
              <Stack direction="row" gap="1rem" sx={{ alignItems: "center" }}>
                <Radio
                  checked={option === "1"}
                  value="1"
                  onChange={handleChangeOption}
                  disabled={selectedId != null}
                />

                <TextField
                  type="number"
                  size="small"
                  label="Mean Value"
                  value={formik.values.limits[activeLimitIndex].meanValue ?? ""}
                  onChange={(e) => {
                    formik.setFieldValue(
                      `limits.${activeLimitIndex}.meanValue`,
                      e.target.value !== "" ? +e.target.value : null
                    );
                  }}
                  disabled={option !== "1" || selectedId != null}
                  sx={{ width: "16rem" }}
                />

                <TextField
                  type="number"
                  size="small"
                  label="Sigma Value"
                  value={
                    formik.values.limits[activeLimitIndex].sigmaValue ?? ""
                  }
                  onChange={(e) => {
                    formik.setFieldValue(
                      `limits.${activeLimitIndex}.sigmaValue`,
                      e.target.value !== "" ? +e.target.value : null
                    );
                  }}
                  disabled={option !== "1" || selectedId != null}
                  sx={{ width: "16rem" }}
                />
              </Stack>

              <Stack direction="row" gap="1rem" sx={{ alignItems: "center" }}>
                <Radio
                  checked={option === "2"}
                  value="2"
                  onChange={handleChangeOption}
                  disabled={selectedId != null}
                />

                <TextField
                  type="number"
                  size="small"
                  label="Upper Control Limit"
                  value={
                    formik.values.limits[activeLimitIndex]
                      .upperControlLimitValue ?? ""
                  }
                  onChange={(e) => {
                    formik.setFieldValue(
                      `limits.${activeLimitIndex}.upperControlLimitValue`,
                      e.target.value !== "" ? +e.target.value : null
                    );
                  }}
                  disabled={option !== "2" || selectedId != null}
                  sx={{ width: "16rem" }}
                />

                <TextField
                  type="number"
                  size="small"
                  label="Lower Control Limit"
                  value={
                    formik.values.limits[activeLimitIndex]
                      .lowerControlLimitValue ?? ""
                  }
                  onChange={(e) => {
                    formik.setFieldValue(
                      `limits.${activeLimitIndex}.lowerControlLimitValue`,
                      e.target.value !== "" ? +e.target.value : null
                    );
                  }}
                  disabled={option !== "2" || selectedId != null}
                  sx={{ width: "16rem" }}
                />
              </Stack>
            </Stack>
          </Stack>

          <Box
            sx={{
              display: "grid",
              gridTemplateRows: "repeat(5, 1fr)",
              gridTemplateColumns: "4fr 1fr",
              alignItems: "center",
              maxWidth: "36rem",
            }}
          >
            <Typography component="h4" sx={{ fontSize: "1.25rem" }}>
              Rules
            </Typography>

            <Box
              sx={{
                display: "grid",
                gap: "2rem",
                gridTemplateColumns: "repeat(3, 1fr)",
                alignItems: "center",
                justifyItems: "center",
                fontSize: "0.875rem",
              }}
            >
              <Typography fontSize="0.875rem">Ignore</Typography>
              <Typography fontSize="0.875rem">Warning</Typography>
              <Typography fontSize="0.875rem">Violation</Typography>
            </Box>

            {rules.map((r) => (
              <RuleRow
                key={r.name}
                rule={r}
                activeLimitIndex={activeLimitIndex}
                disabled={selectedId != null}
              />
            ))}
          </Box>

          <Stack sx={{ gap: "0.5rem" }}>
            <Typography component="h4" sx={{ fontSize: "1.25rem" }}>
              Rules Reporting Failure Window
            </Typography>

            <Typography variant="body2" color="#00000099">
              If these values will not be used in MIDAS Results Reporting, leave
              the values empty.
            </Typography>

            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "max-content 1fr",
                gridTemplateRows: "repeat(2, 1fr)",
                gap: "0.5rem 2rem",
                alignItems: "center",
                maxWidth: "36rem",
              }}
            >
              <Typography>Runs Before:</Typography>

              <TextField
                type="number"
                size="small"
                value={
                  formik.values.limits[activeLimitIndex].failureWindowBefore ??
                  ""
                }
                onChange={(e) => {
                  formik.setFieldValue(
                    `limits.${activeLimitIndex}.failureWindowBefore`,
                    e.target.value !== "" ? +e.target.value : null
                  );
                }}
                disabled={selectedId != null}
                sx={{ width: "4rem" }}
              />

              <Typography>Runs After:</Typography>

              <TextField
                type="number"
                size="small"
                value={
                  formik.values.limits[activeLimitIndex].failureWindowAfter ??
                  ""
                }
                onChange={(e) => {
                  formik.setFieldValue(
                    `limits.${activeLimitIndex}.failureWindowAfter`,
                    e.target.value !== "" ? +e.target.value : null
                  );
                }}
                disabled={selectedId != null}
                sx={{ width: "4rem" }}
              />
            </Box>
          </Stack>
        </>
      )}
    </Stack>
  );
}
