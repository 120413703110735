import API from "..";

export default class SpecialHandlingStatus {
  /**
   * The SpecialHandlingStatus dimension table for M3
   * @param TODO
   */
  constructor({
    specialHandlingName,
    isActive
  }) {
    this.specialHandlingName = specialHandlingName;
    this.isActive = isActive;
  }

  static async getAll() {
    const api = await API();
    const { data } = await api.get(`/specialhandlingstatus`);
    return data.result.map((d) => new SpecialHandlingStatus(d));
    
  }
}