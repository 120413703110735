import { createContext, useState } from "react";
import RequestItem from "../../../api/Stockroom/RequestItem";
import { useEffect } from "react";
import ModalSimpleButton from "../../../components/Modal/ModalSimpleButton";
import { Typography } from "@mui/material";

export const RequestItemContext = createContext(null);

export const RequestItemContextProvider = ({ children }) => {
  const [requestItems, setRequestItems] = useState([]);
  const [hasError, setHasError] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    if (hasError) setIsModalOpen(true);
  }, [hasError]);

  const getAllRequestItems = async () => {
    await RequestItem.getAll()
      .then((data) => {
        setRequestItems(data);
      })
      .catch((err) => {
        setHasError(err);
        setIsModalOpen(true);
      });
  };

  const getRequestItem = async (itemID) => {
    await RequestItem.getRequestItemById(itemID)
      .then((data) => {
        setRequestItems(data);
      })
      .catch((err) => {
        setHasError(err);
        setIsModalOpen(true);
      });
  };

  return (
    <RequestItemContext.Provider
      value={{ requestItems, getRequestItem, getAllRequestItems }}
    >
      {children}
      <ModalSimpleButton
        title={"Error"}
        buttonText={"Close"}
        buttonAction={() => {
          setIsModalOpen(false);
          setHasError("");
        }}
        setOpen={() => {
          setIsModalOpen(false);
          setHasError("");
        }}
        open={isModalOpen}
      >
        <Typography
          style={{ padding: "0 16px", fontSize: 18, textAlign: "center" }}
        >
          {hasError}
        </Typography>
      </ModalSimpleButton>
    </RequestItemContext.Provider>
  );
};
