import API from "..";

export default class TSCAStatus {
  constructor({ statusName, isActive, displayColor, displayOrder }) {
    this.statusName = statusName;
    this.isActive = isActive;
    this.displayColor = displayColor;
    this.displayOrder = displayOrder;
  }

  static async getAll() {
    const api = await API();
    const { data } = await api.get(`/tscaStatus`);
    if (data.result) {
      return data.result.map((d) => new TSCAStatus(d));
    } else {
      return null;
    }
  }

  static async createTSCAStatus(info) {
    const api = await API();
    const { data } = await api.post("/tscaStatus", info);
    return new TSCAStatus(data.result);
  }
}
