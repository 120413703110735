import React, { useState, useEffect, useContext, useMemo } from "react";
import { 
  Divider,
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Typography,
  CircularProgress
} from "@mui/material";
import { ListWrapper, WidgetsWrapper, ListItem, InfoText, GroupTitle } from "./styles";
import ModalSimpleButton from '../../components/Modal/ModalSimpleButton'
import UserContext from "../../context/UserContext";
import { PrefContext } from '../../context/PrefContext'
import { RolesAdfs } from '../../global';
import User from "../../api/Admin/User"

const MODAL_CONTENT_INITIAL_STATE = {
  title: '',
  message: '',
}

const UPrefs = () => {
  const [userInfo, setUserInfo] = useState({})
  const [isModalOpen, setIsModalOpen] = useState(false)

  const currentUser = useContext(UserContext)
  const { 
    userPreferences, 
    setUserPreferences, 
    updateUserPreferences,
    modalContent,
    setModalContent,
    isLoading
  } = useContext(PrefContext)
  
  const userRoles = useMemo(() => currentUser?.idTokenClaims?.roles, [currentUser])

  const providerAdfs = window.localStorage.getItem("AUTH_PROVIDER") === "Adfs";

  useEffect(() => {
    if (modalContent?.title !== '') {
      setIsModalOpen(true)
    }
  }, [modalContent.title])

  useEffect(() => {
    const getUserInfo = async () => {
      const response = await User.validateEmailAddress(currentUser?.username)
      const copiedResponse = JSON.parse(JSON.stringify(response))
      setUserInfo(copiedResponse)
    }

    getUserInfo()
  }, [currentUser])

  return (
    <>
      <span className='pageheader'>User Preferences</span>
      <Divider className='dividerbar' />
      <Box display="flex" flexDirection="column" gap={3}>
        <Box display="flex" flexDirection="column" gap={1}>
          <GroupTitle>User Info:</GroupTitle>
          {!(userInfo && typeof userInfo === 'object') ? (<></>) : Object.entries(userInfo).length > 0 ? (
            <>
              <InfoText><strong>Name:</strong> {userInfo?.surname}, {userInfo?.givenName}</InfoText>
              <InfoText><strong>Display Name:</strong> {userInfo?.displayName}</InfoText>
              <InfoText><strong>Email:</strong> {userInfo?.mail}</InfoText>
              <InfoText><strong>Job Title:</strong> {userInfo?.jobTitle}</InfoText>
              <InfoText><strong>Office Location:</strong> {userInfo?.officeLocation ? userInfo?.officeLocation : 'N/A'}</InfoText>
              <InfoText><strong>Business Phones:</strong> {userInfo?.businessPhones ? userInfo?.businessPhones?.join(', ') : 'N/A'}</InfoText>
              <InfoText><strong>Mobile Phones:</strong> {userInfo?.mobilePhones ? userInfo?.mobilePhones?.join(', ') : 'N/A'}</InfoText>
            </>
          ) : (
            <Box display="flex" gap={2} alignItems="center">
              <CircularProgress size={20} />
              <Typography>Loading User Info...</Typography>
            </Box>
          )}
        </Box>

        <Box display="flex" flexDirection="column" gap={1}>
          <GroupTitle>User Roles:</GroupTitle>
          <ListWrapper>
            {providerAdfs && RolesAdfs?.sort().map(role => {
              return (
                <ListItem key={role}>{role}</ListItem>
              )
            })}
            {!providerAdfs && userRoles && userRoles?.length !== 0 && userRoles?.sort().map(role => {
              return (
                <ListItem key={role}>{role}</ListItem>
              )
            })}
          </ListWrapper>
          <Typography fontSize={18}>
            Click{' '}
            <a
              href='https://emprod.service-now.com/itsp?id=sc_cat_item&sys_id=aa73c5761b1170108b18326ecc4bcb97&referrer=popular_items'
              target='_blank'
              rel='noopener noreferrer'
            >
              here
            </a> to join an Active Directory group.
          </Typography>
        </Box>

        <Box display="flex" flexDirection="column" gap={1}>
          <GroupTitle>Notifications:</GroupTitle>
          <FormControlLabel
            label="Receive Email Notifications"
            onChange={(e) => setUserPreferences((prevState) => {
              return {
                ...prevState,
                receiveEmailNotifications: e.target.checked
              }
            })}
            checked={userPreferences?.receiveEmailNotifications}
            control={<Checkbox />}
          />
        </Box>


        <Box display="flex" flexDirection="column" gap={1}>
          <GroupTitle>Widgets:</GroupTitle>
          <WidgetsWrapper>
            <FormControlLabel
              label="Show My Recent Samples widget"
              onChange={(e) => setUserPreferences((prevState) => {
                return {
                  ...prevState,
                  showRecentSamplesWidget: e.target.checked
                }
              })}
              checked={userPreferences?.showRecentSamplesWidget}
              control={<Checkbox />}
            />

            <FormControlLabel
              label="Show My Recent Test Submissions widget"
              onChange={(e) => setUserPreferences((prevState) => {
                return {
                  ...prevState,
                  showRecentTestsWidget: e.target.checked
                }
              })}
              checked={userPreferences?.showRecentTestsWidget}
              control={<Checkbox />}
            />

            <FormControlLabel
              label="Show My Recent Work Requests widget"
              onChange={(e) => setUserPreferences((prevState) => {
                return {
                  ...prevState,
                  showWorkRequestWidget: e.target.checked
                }
              })}
              checked={userPreferences?.showWorkRequestWidget}
              control={<Checkbox />}
            />

            <FormControlLabel
              label="Show My Recent Blend Studies widget"
              onChange={(e) => setUserPreferences((prevState) => {
                return {
                  ...prevState,
                  showRecentBlendsWidget: e.target.checked
                }
              })}
              checked={userPreferences?.showRecentBlendsWidget}
              control={<Checkbox />}
            />
          </WidgetsWrapper>
        </Box>
        
        <Box display="flex" alignItems="center" marginTop={2}>
          <Button
            variant="contained"
            onClick={updateUserPreferences}
            type="button"
            disabled={isLoading}
          >
            Save Changes
          </Button>
        </Box>
      </Box>
      {isModalOpen && (
        <ModalSimpleButton
          title={modalContent?.title}
          buttonText="Close"
          buttonAction={() => {
            setModalContent(MODAL_CONTENT_INITIAL_STATE)
            setIsModalOpen(false)
          }}
          setOpen={setIsModalOpen}
          open={isModalOpen}
        >
          <Typography style={{ padding: '0 16px', fontSize: 18, textAlign: 'center' }}>
            {modalContent?.message}
          </Typography>
        </ModalSimpleButton>
      )}
    </>
  );
};

export default UPrefs;