import React, { useContext, useState } from "react";
import {
  Box,
  TextField
} from '@mui/material'
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { StockroomRequestContext } from "../context/StockroomRequestContext";
import {
  StyledTableBody,
  StyledTableRow,
  UXDataTableWithoutBody,
} from "../../../components/UXDataTable";
import { StyledTableCellSmall } from "../styles";
import { DatePickerKeyDownEvent } from "../../../global";
import BillingInfoField from "../../../components/BillingInfoField";
import { GlobalButton } from "../../styles";


const buildPOCols = [
  "Transaction Date",
  "Transaction By",
  "Stock #",
  "Description",
  "Transaction Type",
  "Quantity",
  "Price",
  "Total Price",
  "Cost Center",
  "WBS #",
  "Work Order"
];

export const ChargesReport = ({ canEdit }) => {
  const [woNumber, setWoNumber] = useState('')
  const [chargeCode, setChargeCode] = useState('')
  const [costCenter, setCostCenter] = useState('')

  const day = new Date().getDate()
  const month = new Date().getMonth()
  const year = new Date().getFullYear()

  const [chargesReport, setChargesReport] = useState([])
  const [dateFrom, setDateFrom] = useState(null)
  const [dateTo, setDateTo] = useState(new Date(year, month, day))
  const { getStockroomReports } = useContext(StockroomRequestContext);
  const [chargesReportRowsPerPage, setChargesReportRowsPerPage] = useState(10);
  const [chargesReportPage, setChargesReportPage] = useState(0);

  const handleCreateReport = async () => {
    const payload = {
      Report: "I",
      Location: "COUNTER",
      ChargeCodeID: chargeCode,
      CostCenter: costCenter,
      WorkorderID: woNumber,
      StartDate: dateFrom,
      EndDate: dateTo
    };

    let report = await getStockroomReports(payload);
    let reportItem = []

    report?.forEach((item) => {
      item.requestItems.forEach((reqItem) => {
        reqItem.workOrderID = item.workOrderID
        reportItem.push(reqItem)
      })
    });

    setChargesReport(reportItem);
  };

  const handleChangeReportRowsPerPage = (event) => {
    setChargesReportRowsPerPage(parseInt(event.target.value, 10));
    setChargesReportPage(0);
  };

  const handleBuildReportPageChange = (event, page) => {
    setChargesReportPage(page);
  };

  return (
    <Box>
      <Box display="flex" gap={4} marginBottom={4}>
        <Box display="flex" alignItems="center" gap={2}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DesktopDatePicker
              disabled={!canEdit}
              label="Search From"
              maxDate={new Date()}
              value={dateFrom}
              onChange={e => setDateFrom(e)}
              renderInput={(params) => <TextField onKeyDown={DatePickerKeyDownEvent} size="small" {...params} />}
            />
            <DesktopDatePicker
            disabled={!canEdit}
              label="To"
              minDate={dateFrom}
              value={dateTo}
              maxDate={new Date()}
              onChange={e => setDateTo(e)}
              renderInput={(params) => <TextField onKeyDown={DatePickerKeyDownEvent} size="small" {...params} />}
            />
          </LocalizationProvider>
          <TextField
            onChange={e => setWoNumber(e.target.value)}
            value={woNumber}
            label="WO #"
            size="small"
            disabled={!canEdit}
          />
          {/* <TextField
            onChange={e => setChargeCode(e.target.value)}
            value={chargeCode}
            label="WBS #"
            size="small"
          /> */}

          <BillingInfoField
            billingValue={chargeCode}
            handleChange={(e) => {setChargeCode(e)}}
            margin="none"
            fieldWidth="30rem"
            marginLeft={null}
            marginRight={null}
            isDisabled={!canEdit}
          />

          <TextField
            onChange={e => setCostCenter(e.target.value)}
            value={costCenter}
            label="Cost Center"
            size="small"
            disabled={!canEdit}
          />
        </Box>

        <Box display="flex" alignItems="center">
          <GlobalButton
            variant="contained"
            onClick={handleCreateReport}
            disabled={!canEdit}
          >
            Create report
          </GlobalButton>
        </Box>
      </Box>
      <Box>
        <UXDataTableWithoutBody
          tableWidth="100%"
          cols={buildPOCols}
          tableId="chargesTable"
          rowLength={chargesReport?.length}
          enablePaging={true}
          rowsPerPage={chargesReportRowsPerPage}
          page={chargesReportPage}
          handleChangePage={handleBuildReportPageChange}
          handleChangeRowsPerPage={handleChangeReportRowsPerPage}
          noDataFoundMessage={"No charges found"}
        >
          <StyledTableBody>
            {chargesReport?.length > 0 &&
              chargesReport.slice(
                chargesReportPage * chargesReportRowsPerPage,
                chargesReportPage * chargesReportRowsPerPage +
                chargesReportRowsPerPage
              )
                .map((item) => {
                  return (
                    <StyledTableRow key={item?.thisItem.id} hover>
                      <StyledTableCellSmall>
                        {item?.thisItem.lastModifiedDate.split("T")[0]}
                      </StyledTableCellSmall>
                      <StyledTableCellSmall>
                        {item?.thisItem.lastModifiedByEmail}
                      </StyledTableCellSmall>
                      <StyledTableCellSmall>
                        {item?.thisItem.siteAssetNumber}
                      </StyledTableCellSmall>
                      <StyledTableCellSmall>{item?.thisItem.description}</StyledTableCellSmall>
                      <StyledTableCellSmall>
                        {item?.orderStatusName}
                      </StyledTableCellSmall>
                      <StyledTableCellSmall>
                        {item?.thisItem.quantity}
                      </StyledTableCellSmall>
                      <StyledTableCellSmall>
                        {item?.thisItem.equipmentPriceUSD}
                      </StyledTableCellSmall>
                      <StyledTableCellSmall>
                        {item?.thisItem.quantity * item?.thisItem.equipmentPriceUSD}
                      </StyledTableCellSmall>
                      <StyledTableCellSmall>{item?.thisItem.costCenter}</StyledTableCellSmall>
                      <StyledTableCellSmall>
                        {item?.thisItem.chargeCode}
                      </StyledTableCellSmall>
                      <StyledTableCellSmall>
                        {item.workOrderID}
                      </StyledTableCellSmall>
                    </StyledTableRow>
                  )
                })}
          </StyledTableBody>
        </UXDataTableWithoutBody>
      </Box>
    </Box>
  )
}