import API from "..";

export default class JapanSDSCode {
  /**
   * The JapanSDSCode dimension table for M3
   * @param TODO
   */
  constructor({
    sdsCodeName,
    description,
    isActive
  }) {
    this.sdsCodeName = sdsCodeName;
    this.description = description;
    this.isActive = isActive;
  }

  static async getAll() {
    const api = await API();
    const { data } = await api.get(`/japansdscode`);
    return data.result.map((d) => new JapanSDSCode(d));
  }
}