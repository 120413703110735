import React from "react";
import {
    InputLabel,
    styled,
    TextField,
    Autocomplete,
    Modal,
    Typography,
    Box
} from "@mui/material";
// import TextField from '@mui/material/TextField';
// import Autocomplete from '@mui/material/Autocomplete';
import CustomDataGrid from '../../components/DataGrid'
import { GlobalButton } from "../styles";

const StyledDiv = styled('div')({
    display: 'flex',
    minHeight: '45vh',
    marginRight: '1rem'
});

const StyledDiVBackGround = styled('div')({
    display: 'flex',
    justifyContent: "space-between",
    backgroundColor: '#1976d2',
    padding: '1rem',
    borderTopRightRadius: '1rem',
    borderTopLeftRadius: '1rem'
});

const StyledSpan = styled('span')({
    marginRight: "1rem",
    label:
    {
        color: 'white'
    },
    button:
    {
        backgroundColor: "white",
        color: "#3190D9",
        fontSize: "13px",
        fontFamily: "EMprint",
        textTransform: "none",
        "&:hover": {
            backgroundColor: 'white',
        },
    }
});

const StyledModalBox = styled(Box)(() => ({
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: 'white',
    borderRadius: '10px',
    border: '1px solid rgba(0, 0, 0, 0.3)',
    boxShadow: '0 3px 7px rgba(0, 0, 0, 0.3)',
    backgroundClip: 'padding-box',
    maxHeight: "100%",
    minHeight: '200px'
}));

const StyledModalHeader = styled('div')({
    margin: '15px',
    display: 'flex',
    justifyContent: 'center'
});

const StyledModalBody = styled('div')({
    margin: '2rem'
});

const MethodManagementDataGrid = ({ title, buttonText, selectedInfo, setSelectedInfo, rows, setRows, cols, modalList, selectionDataGrid, enableSelect, enableDelete, sx, minWidth, canEdit, childInHeader, enableRowDelete }) => {

    const [open, setOpen] = React.useState(false);

    function handleChange(e) {

        let item = modalList.find(obj => { return obj.text.toString().trim() === e.currentTarget.innerText.trim() });

        setSelectedInfo({
            ...selectedInfo,
            name: item.value,
            id: item.id
        });
        setOpen(false);
    }

    return (

        <div>
            <StyledDiv>
                <div>
                    <StyledSpan>
                        <StyledDiVBackGround>
                            <InputLabel>{title}</InputLabel>
                            <div>
                                {childInHeader}
                                {buttonText && <GlobalButton onClick={() => setOpen(true)}
                                    variant='contained'
                                    type="button"
                                >{buttonText}</GlobalButton>
                                }
                            </div>
                        </StyledDiVBackGround>
                        <CustomDataGrid key={`datagrid-${title}`} sx={sx} cols={cols} rows={rows} onRowClick={selectionDataGrid} enableSelect={enableSelect} setRows={setRows} minWidth={minWidth} enableDelete={enableDelete} canEdit={canEdit} enableRowDelete={enableRowDelete}></CustomDataGrid>
                    </StyledSpan>
                </div>
            </StyledDiv>

            {
                modalList && <Modal open={open} onClose={() => setOpen(false)}>
                    <StyledModalBox>
                        <StyledModalHeader id="modal-modal-header" >
                            <Typography variant="h6" component="h6">{buttonText}</Typography>
                        </StyledModalHeader>
                        <StyledModalBody id="modal-modal-body">
                            <Autocomplete
                                disablePortal
                                options={modalList.map((label) => label.text.toString())}
                                sx={{ width: "20rem" }}
                                renderInput={(params) => <TextField {...params} />}
                                onChange={e => {
                                    handleChange(e)
                                }}
                            />
                        </StyledModalBody>
                    </StyledModalBox>
                </Modal>
            }

        </div>

    );
};

export default MethodManagementDataGrid;
