import {
  FormControl,
  FormControlLabel,
  Radio,
  TextField,
  Typography,
  Divider,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useRef, useState } from "react";
import { StyledForm, StyledRadioGroup } from "../styles";
import {GlobalButton, GlobalSecondaryButton} from "../../styles"

export default function FilterRequest({
  inventoryItems,
  setFilteredItems,
  setInventoryPage,
  categories,
}) {
  const [categoryFilter, setCategoryFilter] = useState("All");
  const [isInventoryLoading, setIsInventoryLoading] = useState(false);
  const filterFormRef = useRef(null);

  const searchItemsFilteredByCategory = (category) => {
    setIsInventoryLoading(true);
    const filteredList = inventoryItems.filter((item) => {
      return (
        item?.stockroomCategoryName?.toLowerCase() === category.toLowerCase()
      );
    });
    setFilteredItems(filteredList);
    setIsInventoryLoading(false);
  };

  const handleApplyFilters = (e) => {
    e.preventDefault();
    const { description, description2, stockroom } = filterFormRef.current;
    if (categoryFilter === "All") {
      const filteredList = inventoryItems.filter((item) => {
        return (
          (description.value &&
            item?.description
              ?.toLowerCase()
              .includes(description.value.toLowerCase())) ||
          (description2.value &&
            item?.description
              ?.toLowerCase()
              .includes(description2.value.toLowerCase())) ||
          (stockroom.value &&
            item?.siteAssetNumber
              ?.toLowerCase()
              .includes(stockroom.value.toLowerCase()))
        );
      });
      setFilteredItems(filteredList);
    } else {
      const filteredList = inventoryItems.filter((item) => {
        return (
          (description.value &&
            item?.description
              ?.toLowerCase()
              .includes(description.value.toLowerCase()) &&
            item?.stockroomCategoryName?.toLowerCase() ===
              categoryFilter.toLowerCase()) ||
          (description2.value &&
            item?.description
              ?.toLowerCase()
              .includes(description2.value.toLowerCase()) &&
            item?.stockroomCategoryName?.toLowerCase() ===
              categoryFilter.toLowerCase()) ||
          (stockroom.value &&
            item?.siteAssetNumber
              ?.toLowerCase()
              .includes(stockroom.value.toLowerCase()) &&
            item?.stockroomCategoryName?.toLowerCase() ===
              categoryFilter.toLowerCase())
        );
      });
      setFilteredItems(filteredList);
    }
    setInventoryPage(0);
  };
  return (
    <Box display="flex" gap={1} marginBottom={2}>
      <FormControl>
        <StyledRadioGroup defaultValue={false}>
          {categories &&
            categories.length > 0 &&
            categories.map((category) => {
              return (
                <FormControlLabel
                  key={category.StockroomCategoryName.stockroomCategoryName}
                  label={category.StockroomCategoryName.stockroomCategoryName}
                  control={
                    <Radio
                      onClick={(e) => {
                        if (categoryFilter === e.target.value) {
                          setCategoryFilter("All");
                          setFilteredItems(inventoryItems);
                          setInventoryPage(0);
                        } else {
                          searchItemsFilteredByCategory(e.target.value);
                          setCategoryFilter(e.target.value);
                          setInventoryPage(0);
                        }
                      }}
                    />
                  }
                  disabled={isInventoryLoading}
                  value={category.StockroomCategoryName.stockroomCategoryName}
                  checked={
                    categoryFilter ===
                    category.StockroomCategoryName.stockroomCategoryName
                  }
                />
              );
            })}
          <FormControlLabel
            key="All"
            label="All"
            control={
              <Radio
                onClick={(e) => {
                  if (categoryFilter === e.target.value) {
                    return;
                  } else {
                    setFilteredItems(inventoryItems);
                    setCategoryFilter(e.target.value);
                  }
                }}
              />
            }
            disabled={isInventoryLoading}
            value="All"
            checked={categoryFilter === "All"}
          />
        </StyledRadioGroup>
      </FormControl>
      <Box
        display="flex"
        flexDirection="column"
        gap={2}
        marginLeft={2}
        flexGrow={0.5}
        width={'50%'}
      >
        <Typography>Filters</Typography>
        <StyledForm style={{ gap: 16 }} ref={filterFormRef}>
          <TextField
            size="small"
            type="text"
            name="description"
            label="Description"
          />

          <Divider sx={{ "::before,::after": { top: "unset" } }}>OR</Divider>

          <TextField
            size="small"
            type="text"
            name="description2"
            label="Description (2)"
          />

          <Divider sx={{ "::before,::after": { top: "unset" } }}>OR</Divider>

          <TextField
            size="small"
            type="text"
            name="stockroom"
            label="Stockroom #"
          />
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            gap={1}
          >
            <GlobalButton
              variant="contained"
              fullWidth
              onClick={(e) => handleApplyFilters(e)}
            >
              Apply
            </GlobalButton>
            <GlobalSecondaryButton
              variant="contained"
              fullWidth
              onClick={() => {
                setFilteredItems(inventoryItems);
                setCategoryFilter("All");
                filterFormRef.current.reset();
              }}
            >
              Clear
            </GlobalSecondaryButton>
          </Box>
        </StyledForm>
      </Box>
    </Box>
  );
}
