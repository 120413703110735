import React from "react";
import { Box, Divider } from "@mui/material";
import DesignStudy from "./DesignStudy";
import PrepareBatch from "./PrepareBatch";
import Audit from "./AuditBatch";
import { GlobalTabCollection, StyledTab } from "../../pages/styles";

const Blends = () => {
  const [tabValue, setTabValue] = React.useState(0);
  
  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const renderSearchTab = () => {
    switch (tabValue) {
      case 0: {
        return <DesignStudy/>
      }
      case 1: {
        return <PrepareBatch/>
      }
      case 2: {
        return <Audit/>
      }
      default: {
        alert(tabValue);
      }
    }
  };
  return (
    <Box paddingBottom={4}>
      <span className='pageheader'>Blends</span>
      <Divider className='dividerbar' />
      <div>
        <Box sx={{ bgcolor: "#fff", pt:3, pb:1 }}>
          <GlobalTabCollection value={tabValue} onChange={handleChange} aria-label='ant example'>
            <StyledTab label='Design Study' />
            <StyledTab label='Prepare Batch' />
            <StyledTab label='Audit Batch' />
          </GlobalTabCollection>
        </Box>
        {renderSearchTab()}

      </div>
    </Box>
  );
};

export default Blends;
