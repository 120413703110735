import React from "react";
import {
  Box,
  FormControl,
  TextField,
  styled
} from "@mui/material";
import ModalTwoButtons from '../../../components/Modal/ModalTwoButtons';
import STEngineer from "../../../api/StationaryTesting/STEngineer";

const StyledBox = styled(Box)(() => ({
  display: 'flex',
  marginBottom: "20px",
  gap: "20px",
  justifyContent: "normal!important"
}));

const StyledTextField = styled(TextField)(() => ({
  width: "35rem"
}));

const StyledFormControl = styled(FormControl)(() => ({
  display: 'flex',
  width: "50rem"
}));

const StyledDiv = styled('div')({
  display: 'flex',
  justifyContent: "space-between"
});

const STEngineerForm = ({ infoData, setInfoData, invalidField, handleChangeSTEngineerFilter, canEdit }) => {

  const [modalTwoButtonsOpen, setModalTwoButtonsOpen] = React.useState(false);

  function findEngineer() {
    handleChangeSTEngineerFilter(infoData.engineerEmailAddress);
    closeModalTwoButtonsOpen();
  }

  function closeModalTwoButtonsOpen() {
    setModalTwoButtonsOpen(false);
  }

  function checkExistingEngineer(engineer) {

    if (engineer) {
      STEngineer.get(engineer).then((engineerExists) => {
        if ((engineerExists !== undefined && engineerExists !== null))
          setModalTwoButtonsOpen(true)
      });
    }
  }

  return (

    <div>
      <StyledDiv>
        <div>
          <StyledBox sx={{ flexDirection: 'column' }}>
            <StyledFormControl sx={{ m: 1 }} variant='outlined'>

              <StyledBox sx={{ justifyContent: "space-between" }}>
                <StyledTextField
                  onChange={e => {
                    setInfoData(() => ({
                      ...infoData,
                      engineerEmailAddress: e.target.value
                    }))
                  }}
                  onBlur={e => {
                    checkExistingEngineer(e.target.value)
                  }}
                  value={infoData.engineerEmailAddress}
                  id="engineer-email"
                  label="Engineer Email Address"
                  variant="outlined" size="small" margin="none"
                  error={invalidField.engineerEmailAddress.length === 0 ? false : true}
                  disabled={infoData.id || !canEdit ? true : false}
                  helperText={invalidField.engineerEmailAddress}
                  inputProps={{ maxLength: 50 }}
                />
              </StyledBox>    

               <StyledBox sx={{ justifyContent: "space-between" }}>
                <StyledTextField
                  onChange={e => setInfoData(() => ({
                    ...infoData,
                    fullName: e.target.value
                  }))}
                  id="engineer-name"
                  label="Engineer Full Name"
                  variant="outlined" size="small" margin="none"
                  value={infoData.fullName}
                  inputProps={{ maxLength: 200 }}
                  disabled={!canEdit}
                />

              </StyledBox>  

            </StyledFormControl>
          </StyledBox>

        </div>

      </StyledDiv>

      <ModalTwoButtons title={'Warning'} button1Text={'No'} button1Action={closeModalTwoButtonsOpen} button2Text={'Yes'} button2Action={findEngineer} open={modalTwoButtonsOpen} setOpen={setModalTwoButtonsOpen}>
        <label>
          This ST Engineer already exist, would you like to use it?
        </label>
      </ModalTwoButtons>

    </div>

  );
};

export default STEngineerForm;
