import React, { useState, useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import { Box } from "@mui/material";

import TableMyExperiments from "./CreateDistillationTabs/TableMyExperiments";
import TableAllExperiments from "./CreateDistillationTabs/TableAllExperiments";
import FilterMenu from "../../components/FilterMenu"
import UserContext from "../../context/UserContext";

import DistillationExperiment from '../../api/Distillation/DistillationExperiment'
import DistillationStatus from '../../api/Distillation/DistillationStatus'

import { GlobalButton, GlobalSecondaryButton, GlobalTabCollection, StyledTab } from "../../pages/styles";

const CreateDistillation = () => {
  const [tabValue, setTabValue] = useState(0);
  const [statuses, setStatuses] = useState([])
  const [allExperiments, setAllExperiments] = useState([])
  const [isAllExperimentsLoading, setIsAllExperimentsLoading] = useState(true)
  const [isMyExperimentsLoading, setIsMyExperimentsLoading] = useState(true)
  const [filteringOpen, setFilteringOpen] = useState(false);
  const [filters, setFilters] = useState([{name:null, displayName:null, operator:null, enumValues:[], value:''}])

  const currentUser = useContext(UserContext)

  const filterOptions = [
    {name: "id", displayName: "Experiment ID", type: "integer", enumValues: []}, 
    {name: "mnemonic", displayName: "Mnemonic", type: "string", enumValues: []}, 
    {name: "experimentDescription", displayName: "Experiment Description", type: "string", enumValues: []},
    {name: "requestedByEmail", displayName: "Requester Email", type: "string", enumValues: []},
    {name: "createdDate", displayName: "Requested Date", type: "date", enumValues: []},
    {name: "distillationStatusName", displayName: "Status", type: "enum", enumValues: statuses}
  ]

  const getAllExperiments = async () => {
    setIsAllExperimentsLoading(true)
    setIsMyExperimentsLoading(true)
    setAllExperiments([])
    const data = await DistillationExperiment.getAllExperiments()
    setAllExperiments(data)
    setIsAllExperimentsLoading(false)
    setIsMyExperimentsLoading(false)
  }
  
  const updateExperiment = async (payload) => {
    const result = await DistillationExperiment.updateExperiment(payload)
    getAllExperiments()
    return result;
  }
  
  const getAllDistillationStatuses = async () => {
    const data = await DistillationStatus.getAllDistillationStatuses()
    const formattedData = data.map((status) => status?.distillationStatusName)
    const filteredData = formattedData.filter(status => status?.isActive)
    const activeStatuses = filteredData.map(status => status?.distillationStatusName)
    setStatuses(activeStatuses)
  }

  const handleChange = (event, newValue) => {
    clearFiltersClick()
    setTabValue(newValue);
  };

  const clearFiltersClick = (event) => {
    setFilters([{name:null, displayName:null, operator:null, enumValues:[], value:null}])  
  }

  const renderSearchTab = () => {
    switch (tabValue) {
      case 0: {
        return (
          <TableMyExperiments 
            filters={filters}
            myExperiments={allExperiments.filter((experiment) => {
              return experiment?.requestedByEmail === currentUser.username
            })} 
            isDataLoading={isMyExperimentsLoading} 
            userEmail={currentUser.username}
            updateExperiment={updateExperiment}
          />
        )
      }
      case 1: {
        return (
          <TableAllExperiments 
            filters={filters}
            allExperiments={allExperiments} 
            isDataLoading={isAllExperimentsLoading}
          />
        )
      }
      default: {
        alert(tabValue);
      }
    }
  };

  useEffect(() => {
    getAllExperiments()
    getAllDistillationStatuses()
  }, [])

  return (
       <div>
         <Box sx={{ bgcolor: "#fff", pt:3, pb:1 }}>
          <GlobalTabCollection value={tabValue} onChange={handleChange} aria-label='ant example'>
            <StyledTab label='My Experiments' />
            <StyledTab label='All Experiments' />
            <Box>
              <GlobalButton
                variant="contained" 
                component={Link}
                to='/newExperiment'
              >
                Create New Experiment
              </GlobalButton>
            </Box>
            <Box display="flex" alignItems="center" marginLeft="auto">
              <GlobalButton      
                style={{ marginRight:"1rem" }}
                variant="contained"
                onClick={() => setFilteringOpen(true)}
              >
                Filters
              </GlobalButton>
              {!(filters[0].name === null) && (
                <GlobalSecondaryButton  
                  variant="contained" 
                  onClick={() => clearFiltersClick()}
                >
                  Clear Filters
                </GlobalSecondaryButton>
              )} 
            </Box>
          </GlobalTabCollection>
          <Box sx={{ p: 1 }} />
        </Box>
        {renderSearchTab()}
        <FilterMenu 
          open={filteringOpen} 
          setOpen={setFilteringOpen} 
          applyBtnAction={() => setFilteringOpen(false)} 
          cancelButtonAction={() => setFilteringOpen(false)} 
          filteringInfo={filterOptions} 
          appliedFilters={filters} 
          setAppliedFilters={setFilters}
        />
      </div>
  );
};

export default CreateDistillation;
