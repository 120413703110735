import React, { useState, useEffect } from "react";
import { Typography } from "@mui/material";
import { UXDataTable } from "../../../components/UXDataTable";
import { applyFiltersToArray } from "../../../global";
import ModalSimpleButton from '../../../components/Modal/ModalSimpleButton'

const allExperimentCols = [
  { field: 'id', headerName: 'Experiment ID', type: 'button-distillation', path: '/newExperiment' },
  { field: 'mnemonic', headerName: 'Mnemonic', type: 'label' },
  { field: 'experimentDescription', headerName: 'Experiment Description', type: 'label' },
  { field: 'requestedByEmail', headerName: 'Requester Email', type: 'label' },
  { field: 'createdDate', headerName: 'Requested Date', type: 'datetime' },
  { field: 'distillationStatusName', headerName: 'Status', type: 'label' },
];

const TableAllExperiments = ({ filters, allExperiments, isDataLoading }) => {
  const [filteredExperiments, setFilteredExperiments] = useState([])
  const [isReviewModalOpen, setIsReviewModalOpen] = useState(false)

  const handleSubmitForReview = (experiment) => {
    if (experiment?.distillationStatusName === 'Submitted') {
      setIsReviewModalOpen(true)
      return
    }

    // Write logic for submitting for review
  }

  const menuItems = [
    {
      menuType: 'text',
      redirectPath: '',
      text: 'Submit for Review',
      onClickFunction: handleSubmitForReview,
    },
  ]
  
  useEffect(() => {
    const filteredArray = applyFiltersToArray(filters, allExperiments)
    setFilteredExperiments(filteredArray)
  }, [filters, allExperiments])

  return (
    <div>
      <UXDataTable 
        tableWidth='100%' 
        cols={allExperimentCols} 
        rows={filteredExperiments.sort((a, b) => new Date(b.createdDate) - new Date(a.createdDate))} 
        moreOptionsCell
        enablePaging
        menuProps={menuItems}
        noDataMessage="No Experiments found"
        defaultRowsPerPage={10} 
        isDataLoading={isDataLoading} 
        tableName={'allExperiments'} 
        enableSorting={true}
      />
      {isReviewModalOpen && (
        <ModalSimpleButton
          title="Warning!"
          buttonText="Close"
          buttonAction={() => {
            setIsReviewModalOpen(false)
          }}
          setOpen={setIsReviewModalOpen}
          open={isReviewModalOpen}
        >
          <Typography style={{ padding: '0 16px', fontSize: 18, textAlign: 'center' }}>
            You cannot submit for review an experiment that hasn't been started yet.
          </Typography>
        </ModalSimpleButton>
      )}
    </div>
  );
};

export default TableAllExperiments;