import React, { useState, useEffect, useContext } from "react";
import {
  Grid,
  FormControlLabel,
  Checkbox,
  Box,
} from "@mui/material";
import { RequestRender } from "./RequestRender";
import Requests from "../../../api/Inventory/NewChemicalAuthorizationRequest";
import { GlobalButton, GlobalSecondaryButton } from "../../styles";
import { applyFiltersToArray, hasRole, Roles } from "../../../global";
import FilterMenu from "../../../components/FilterMenu";
import UserContext from "../../../context/UserContext";

const completedStatus = 'Complete';

export const ManagementForm = (props) => {
  const [showCompletedRequests, setshowCompletedRequests] = useState(false);
  const [result, setResult] = useState();
  const [originalResult, setOriginalResult] = useState();
  const [loading, setLoading] = useState(true);
  const [filteringOpen, setFilteringOpen] = useState(false);
  const [filters, setFilters] = useState([{name:null, displayName:null, operator:null, enumValues:[], value:''}])
  const [filteredRequests, setFilteredRequests] = useState([])

  const currentUser = useContext(UserContext);
  const roles = currentUser?.idTokenClaims.roles;
  const canEdit = hasRole(Roles.NcaApprover, roles) || hasRole(Roles.Developer, roles);

  const [reloadData, setReloadData] = useState(false)

  const getRequest = async () => {
    setLoading(true)
    const data = await Requests.getAll()

    if (data) {
      setResult(data.filter(s => s.statusName !== completedStatus && s.statusName !== 'Cancelled'&& s.statusName !== 'Rejected'))
      setOriginalResult(data)
    }
    setLoading(false)
  }

  useEffect(() => {
    getRequest();

  }, [reloadData]);

  const handleCheckBoxfilter = (e) => {
    setshowCompletedRequests(e);
    if (e) {
      setResult(originalResult)

    }
    else {
      setResult(originalResult.filter(s => s.statusName !== completedStatus && s.statusName !== 'Cancelled' && s.statusName !== 'Rejected'));
    }
  }

  useEffect(() => {
    var filteredArray = applyFiltersToArray(filters, result)
    setFilteredRequests(filteredArray)
  }, [filters, result])

  const filterClick = (event) => {
    setFilteringOpen(true);
  }

  const clearFiltersClick = (event) => {
    setFilters([{name:null, displayName:null, operator:null, enumValues:[], value:null}])  
  }

  function closeFiltering() {
    setFilteringOpen(false);
  }

  function applyFilters() {
    setFilteringOpen(false);
  }

  const filterOptions = [
    {name: "requestedForEmail", displayName: "Requested For", type: "string", enumValues:[]},
    {name: "createdDate", displayName: "Date Created", type: "date", enumValues:[]},
    {name: "requiredDate", displayName: "Required Date", type: "date", enumValues:[]},
    {name: "productChemID", displayName: "Product Chem ID", type: "string", enumValues:[]},
    {name: "status", displayName: "Status", type: "string", enumValues:[]},
  ]

  return (
    <>
      <Grid container justifyContent="right" style={{marginTop: '-38px'}}>
        <FormControlLabel
          sx={{ justifyContent: "right" }}
          control={<Checkbox />}
          onChange={(e) => {
            handleCheckBoxfilter(e.target.checked)
          }}

          checked={showCompletedRequests}
          label="Show Completed Requests"
        />
        <Box display="flex" alignItems={"center"} marginLeft="auto" marginTop="-.8rem">
            <GlobalButton style={{marginRight:"1rem"}}variant="contained"
              onClick={() => filterClick()}>Filters</GlobalButton>

            {!(filters[0].name === null) && 
            <GlobalSecondaryButton  variant="contained" 
              onClick={() => clearFiltersClick()}>Clear Filters</GlobalSecondaryButton>}
          </Box>
      </Grid>
      <br />
      <RequestRender canEdit={canEdit} reloadList={getRequest} requests={filteredRequests} showCompletedRequests={showCompletedRequests} loading={loading} setTabValue={props.setTabValue} currentUser={currentUser} setReloadData={setReloadData} reloadData={reloadData}/>
      <FilterMenu 
        open={filteringOpen} 
        setOpen={setFilteringOpen} 
        applyBtnAction={applyFilters} 
        cancelButtonAction={closeFiltering} 
        filteringInfo={filterOptions} 
        appliedFilters={filters} 
        setAppliedFilters={setFilters}
      />
    </>
  );
}

