import API from "../../api";

export default class Location {
  /**
   * The Location dimension table for M3
   * @param {String} locationName The full name of the Location
   * @param {String} siteName The Description for the Location
   * @param {bool} isActive The IsActive for the Location
   * @param {bool} canContainSamples The CanContainSamples for the Location
   * @param {bool} isDeliveryLocation The IsDeliveryLocation for the Location

   */

  constructor({
    locationName,
    siteName,
    isActive,
    canContainSamples, 
    isDeliveryLocation
    }) {
    this.locationName = locationName;
    this.siteName = siteName;
    this.isActive = isActive;
    this.canContainSamples = canContainSamples;
    this.isDeliveryLocation = isDeliveryLocation;
  }

  static async getAll() {
    const api = await API();
    const { data } = await api.get(`/location`);
    return data.result.map((d) => new Location(d));
  }

  static async getAllActive() {
    const api = await API();
    const { data } = await api.get(`/location`);
    if (data && data.result && data.result.length > 0) {
      return data.result.filter((d) => d.isActive);
    }
    return [];
  }

  static async getBySite(siteName) {
    const api = await API();
    const { data } = await api.get(`/location/site`, { params: { siteName } });
    return data.result.map((d) => new Location(d));
  }

  static async get(id) {
    const api = await API();
    const { data } = await api.get(`location/${id}`);
    if (data.result) {
      return new Location(data.result);
    }
    else {
      return null;
    }

  }

  static async add(info) {
    const api = await API();
    const { data } = await api.post(`/location`, info);
    return new Location(data.result);
  }

  static async update(id, info) {
    const api = await API();
    const { data } = await api.put(`/location/${id}`, info);
    return new Location(data.result);
  }
}
