import API from "..";

export default class BatchProductGroup {

  constructor({
    name,
    isActive
}) 
    
  {
    this.name = name;
    this.isActive = isActive;
  }

  static async getAllBatchProductGroups() {
    const api = await API();
    const { data } = await api.get(`/batchProductGroup`);
    if (data.result) {
        return data.result.map((d) => new BatchProductGroup(d));
    }
    else {
        return [];
    }
  }
}
