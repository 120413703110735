import React from "react";
import { styled } from "@mui/material/styles";
import { Box, Tab, Tabs } from "@mui/material";
import TransferContainers from "./TransferContainers";
import LocateContainers from "./LocateContainers";
import DiscardContainers from "./DiscardContainers";
import ReceiveContainer from "./ReceiveContainer";
import ManageContainersData from "./ManageContainerData";

const StyledTestTabs = styled(Tabs)({
  marginTop: "-20px",
  borderBottom: "1px solid #e8e8e8",
});

const StyledTestTab = styled((props) => <Tab disableRipple {...props} />)(({ theme }) => ({
  textTransform: "none",
  minWidth: 100,
  [theme.breakpoints.up("sm")]: {
    minWidth: 0,
  },
  fontWeight: theme.typography.fontWeightRegular,
  marginRight: theme.spacing(1),
  color: "rgba(0, 0, 0, 0.85)",
  fontFamily: ["EMPrint"].join(","),
  "&:hover": {
    color: "#40a9ff",
    opacity: 1,
  },
  "&.Mui-selected": {
    color: "#1890ff",
    fontWeight: theme.typography.fontWeightMedium,
  },
}));

const ManageContainers = ({ ...props }) => {
  const [tabValue, setTabValue] = React.useState(0);
  const [containersTransfer, setContainersTransfer] = React.useState([]);
  const [containersDiscard, setContainersDiscard] = React.useState([]);

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const renderSearchTab = () => {
    switch (tabValue) {
      case 0: {
        return <LocateContainers setTabValue={setTabValue} setContainersTransfer={setContainersTransfer} setContainersDiscard={setContainersDiscard} ></LocateContainers>;
      }
      case 1: {
        return <DiscardContainers containersDiscard={containersDiscard} setContainersDiscard={setContainersDiscard} ></DiscardContainers>;
      }
      case 2: {
        return <ReceiveContainer></ReceiveContainer>;
      }
      case 3: {
        return <TransferContainers containersTransfer={containersTransfer} setContainersTransfer={setContainersTransfer} ></TransferContainers>;
      }
      case 4: {
        return <ManageContainersData></ManageContainersData>;
      }
      default: {
        alert(tabValue);
      }
    }
  };

  return (
    <>
      <div>
        <Box sx={{ bgcolor: "#fff" }}>
          <StyledTestTabs value={tabValue} onChange={handleChange} aria-label='ant example'>
            <StyledTestTab label='Locate Containers'/>
            <StyledTestTab label='Discard Containers'/>
            <StyledTestTab label='Receive Container'/>
            <StyledTestTab label='Transfer Containers'/>
            <StyledTestTab label='Manage Container Data'/>
          </StyledTestTabs>
          <Box sx={{ p: 3 }} />
        </Box>
        {renderSearchTab()}
      </div>
    </>
  );
};

export default ManageContainers;