import React, { useEffect } from "react";
import { styled } from "@mui/material/styles";
import { Box, Button, FormControl, TextField, Autocomplete } from "@mui/material";
import { formatMidasNumber, exportToCsv } from "../../../global";
import { StyledTableRow, StyledTableBody, StyledTableCell, UXDataTableWithoutBody } from "../../../components/UXDataTable";
import ContainerApi from '../../../api/LIMS/Container';
import LocationApi from '../../../api/Admin/Location';
import ModalSimpleButton from "../../../components/Modal/ModalSimpleButton";
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import EmailAddressTextField from "../../../components/EmailAddressField";

const StyledContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '20px'
}));

const StyledFormControl = styled(FormControl)(() => ({
  display: 'flex',
  flexDirection: 'row',
  gap: '20px'
}));

const NewOwnerContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  gap: '20px'
}));

const StyledBox = styled(Box)(() => ({
  display: 'inline-box'
}));

const StyledBoxEmail = styled(Box)(() => ({
  display: 'inline-box',
  marginTop: '-14px'
}));

const StyledAutocomplete = styled(Autocomplete)(() => ({
  width: "20rem"
}));

const StyledButton = styled(Button)(() => ({
  marginTop: '2px',
  widht: '200px',
  textTransform: 'none',
  height: '2.2rem'
}));

const StyledDiv = styled('div')(() => ({
  height: '2.2rem',
  alignItems: 'center',
  display: 'flex'
}));

const StyledDivExport = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'right',
  width: '100%'
}));


const TransferContainers = ({ ...props }) => {
  const [searchOwner, setSearchOwner] = React.useState('');
  const [searchLocation, setSearchLocation] = React.useState('');
  const [searchOwnerInvalid, setSearchOwnerInvalid] = React.useState(false);
  const [searchLocationValid, setSearchLocationValid] = React.useState(true);
  const [listContainer, setListContainer] = React.useState([]);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [page, setPage] = React.useState(0);

  const [modalSimpleButton, setModalSimpleButton] = React.useState(false);
  const [modalSimpleButtonText, setModalSimpleButtonText] = React.useState('');
  const [modalSimpleTitle, setModalSimpleTitle] = React.useState('');
  const [modalSimpleText, setModalSimpleText] = React.useState('');

  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('');

  const [dataIsLoading, setDataIsLoading] = React.useState(false);
  const [listLocation, setListLocation] = React.useState([]);

  const [newOwnerBulk, setNewOwnerBulk] = React.useState('');
  const [newOwnerBulkInvalid, setNewOwnerBulkInvalid] = React.useState(false);
  const [disabledSubmit, setDisabledSubmit] = React.useState(false);

  useEffect(() => {
    LocationApi.getAll().then((resp) => {
      setListLocation(resp)
    })

    const containers = props.containersTransfer;
    if (containers && containers.length > 0) {
      setListContainer(containers.filter(function (item) { return item['containerStatusName'] !== "Discarded" }).map(item => {
        return {
          ...item,
          newOwner: '',
          newOwnerInvalid: false
        }
      }));
      props.setContainersTransfer([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setPage(0)
  }, [listContainer])

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const colHeaders = [
    { id: 'sampleName', label: 'MIDAS Number' },
    { id: 'containerNumber', label: 'Container Number' },
    { id: 'ownerEmail', label: 'Current Owner' },
    { id: 'newEmail', label: 'New Owner' },
    { id: 'locationName', label: 'Location' },
    { id: 'containerStatusName', label: 'Status' },
    { id: 'hasTestsInProgress', label: 'Has Test In Progress' },
  ];

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  function getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const validSearchField = (owner, location) => {
    const validLocation = (location !== '' || owner !== '')
    const valid = !searchOwnerInvalid && validLocation

    setSearchLocationValid(validLocation)

    return valid
  }

  async function handleSearch() {
    if (validSearchField(searchOwner, searchLocation)) {
      setListContainer([])
      setDataIsLoading(true)
      ContainerApi.getByOwnerOrLocation(searchOwner, searchLocation).then((res) => {
        if (res) {
          setListContainer(res.filter(function (item) { return item['containerStatusName'] !== "Discarded" }).map(item => {
            return {
              ...item,
              newOwner: '',
              newOwnerInvalid: false
            }
          }));
        }

        setDataIsLoading(false)
      });
    }
  }

  async function handleSubmit() {
    if (itensToChange.length > 0) {
      setDisabledSubmit(true);
      ContainerApi.changeOwner(itensToChange.map((item) => {
        return {
          id: item.id.toString(),
          newOwner: item.newOwner
        }
      })).then((res) => {
        setDisabledSubmit(false);
        if (res) {
          openModalSimpleButton('Success', 'Transfered with success', 'Ok');
          // handleSearch();
          setSearchOwner();
          setNewOwnerBulk();
          setListContainer([]);
        }
        else
          openModalSimpleButton('Fail', 'Something went wrong, please try after sometime', 'Ok');
      });
    }
  }

  function openModalSimpleButton(title, text, buttonText) {
    setModalSimpleButtonText(buttonText)
    setModalSimpleButton(true);
    setModalSimpleTitle(title);
    setModalSimpleText(text);
  }

  function closeModalSimpleButton() {
    setModalSimpleButton(false);
  }

  function handleChangeNewOwner(id, newOwner) {
    let newList = listContainer.map(container => {
      if (container.id === id) {
        return {
          ...container,
          newOwner: newOwner,
          newOwnerInvalid: newOwner ? false : true
        }
      } else {
        return container;
      }
    });
    setListContainer([...newList]);
  }

  const handleFillClick = (event) => {
    const newOwner = listContainer.map(container => {
      return {
        ...container,
        newOwner: newOwnerBulk,
        newOwnerInvalid: false
      }
    });
    setListContainer([...newOwner]);
  };

  const itensToChange = listContainer.filter(function (item) {
    return item['newOwner'] !== '' && item['newOwnerInvalid'] === false
  });

  const resetEmail = (reset) => {
    setTimeout(() => {
      reset();
    }, 100)
    return <></>;
  }

  return (
    <StyledContainer>
      <StyledFormControl fullWidth>
        <StyledBoxEmail>
          {searchOwner === undefined ? resetEmail(() => setSearchOwner('')) : <EmailAddressTextField
            fontSize={16}
            fieldWidth={"20rem"}
            validatedUserEmail={searchOwner}
            setValidatedUserEmail={value => {
              setSearchOwner(value)
              // validSearchField(value, searchLocation)
            }}
            setHasErrors={(value) => setSearchOwnerInvalid(value)}
            hasErrors={searchOwnerInvalid === null ? false : searchOwnerInvalid}
            isDisabled={false}
            labelText="Owner E-mail"
            placeholderText="Owner E-mail"
            showPlusMeButton={true}
            bypassValidation={true}>
          </EmailAddressTextField>}
        </StyledBoxEmail>
        <StyledDiv>
          <label>AND/OR</label>
        </StyledDiv>
        <StyledBox>
          <StyledAutocomplete
            size="small"
            id="location-select"
            disablePortal
            noOptionsText={"Loading Locations..."}
            options={listLocation.map((location) => location.locationName)}
            getOptionLabel={(option) => option}
            onChange={(e, value) => {
              value = value === null ? "" : value
              setSearchLocation(value)
              validSearchField(searchOwner, value)
            }}
            autoHighlight
            autoSelect
            value={searchLocation === '' ? null : searchLocation}
            renderInput={(params) => <TextField {...params} variant="outlined" size="small" label="Location" error={!searchLocationValid} InputProps={{ ...params.InputProps }} helperText={!searchLocationValid ? 'Required' : ''} />}
          />
        </StyledBox>
        <StyledButton sx={{ width: "8rem" }}
          variant='contained'
          type="submit"
          onClick={handleSearch}
        >Search</StyledButton>
        <StyledDivExport>
          <FileDownloadIcon sx={{ m: 1 }} style={{ cursor: "pointer" }} onClick={e => exportToCsv(listContainer.map(container => {
            return {
              sampleName: formatMidasNumber(container.sampleName),
              containerNumber: container.containerNumber,
              ownerEmail: container.ownerEmail,
              locationName: container.locationName,
              containerStatusName: container.containerStatusName,
            }
          }), "Transfer Containers")} />
        </StyledDivExport>

      </StyledFormControl>
      <NewOwnerContainer>
        <StyledBoxEmail>
          {newOwnerBulk === undefined ? resetEmail(() => setNewOwnerBulk('')) : <EmailAddressTextField
            fontSize={16}
            fieldWidth={"20rem"}
            validatedUserEmail={newOwnerBulk}
            setValidatedUserEmail={value => {
              setNewOwnerBulk(value)
            }}
            setHasErrors={(value) => setNewOwnerBulkInvalid(value)}
            hasErrors={newOwnerBulkInvalid === null ? false : newOwnerBulkInvalid}
            isDisabled={false}
            labelText="Bulk E-mail Transfer"
            placeholderText="Bulk E-mail Transfer"
            showPlusMeButton={true}>
          </EmailAddressTextField>}
        </StyledBoxEmail>
        <StyledButton sx={{ width: "8rem" }}
          variant='contained'
          type="submit"
          onClick={handleFillClick}
          disabled={newOwnerBulkInvalid || newOwnerBulk === '' || listContainer.length < 1}
        >Fill</StyledButton>
      </NewOwnerContainer>
      <div>
        <UXDataTableWithoutBody
          tableWidth='100%'
          cols={colHeaders}
          blankFirstHeader={false}
          tableId="simpleResultTable"
          rowLength={listContainer.length}
          enablePaging={true}
          rowsPerPage={rowsPerPage}
          page={page}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          noDataFoundMessage={'There is no container data available'}
          enableAddIcon={false}
          addFunction={null}
          addToolTipText={null}
          enableSorteable={true}
          order={order}
          orderBy={orderBy}
          handleRequestSort={handleRequestSort}
          isDataLoading={dataIsLoading}
        >

          <StyledTableBody key={"myTablebody"}>
            {stableSort(listContainer, getComparator(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((container, rowIndex) => {
                return (
                  [
                    <StyledTableRow
                      hover
                      key={`custom-row-${rowIndex}`}>

                      <StyledTableCell>{formatMidasNumber(container.sampleName)}</StyledTableCell>
                      <StyledTableCell>{container.containerNumber}</StyledTableCell>
                      <StyledTableCell>{container.ownerEmail}</StyledTableCell>

                      <StyledTableCell >
                        <EmailAddressTextField
                          fontSize={16}
                          fieldWidth={"20rem"}
                          validatedUserEmail={container.newOwner}
                          setValidatedUserEmail={value => {
                            handleChangeNewOwner(container.id, value)
                          }}
                          setHasErrors={(value) => { }}
                          hasErrors={container.newOwnerInvalid === null ? false : container.newOwnerInvalid}
                          isDisabled={false}
                          labelText="New Owner E-mail"
                          placeholderText="New Owner E-mail"
                          showPlusMeButton={true}>
                        </EmailAddressTextField>
                      </StyledTableCell>

                      <StyledTableCell>{container.locationName}</StyledTableCell>
                      <StyledTableCell>{container.containerStatusName}</StyledTableCell>
                      <StyledTableCell>{container.testsInProgress > 0 ? 'Yes' : 'No'}</StyledTableCell>
                    </StyledTableRow>
                  ]
                );
              })}
          </StyledTableBody>
        </UXDataTableWithoutBody>
      </div>
      <NewOwnerContainer paddingBottom="30px">
        <StyledButton sx={{ width: "8rem" }}
          variant='contained'
          type="submit"
          onClick={handleSubmit}
          disabled={itensToChange.length < 1 || disabledSubmit}
        >Change</StyledButton>
      </NewOwnerContainer>
      <ModalSimpleButton title={modalSimpleTitle} buttonText={modalSimpleButtonText} buttonAction={closeModalSimpleButton} open={modalSimpleButton} setOpen={setModalSimpleButton}>
        <label>
          {modalSimpleText}
        </label>
      </ModalSimpleButton>
    </StyledContainer >
  );
};

export default TransferContainers;

