import type { TableColumns } from "../../components/SortableTable/type";
import { convertToLocalTime, formatMidasNumber } from "../../global";
import type { StationaryTest } from "./types";

export const createRequestsCols = [
  {
    id: "cardNumber",
    label: "Card Number",
  },
  {
    id: "testLabel",
    label: "Test Name",
  },
  {
    id: "testTypeName",
    label: "Test Type",
  },
  {
    id: "runNumber",
    label: "Run Number",
  },
  {
    id: "submittedSampleName",
    label: "MIDAS Number",
    transform: (value: string) => (value ? formatMidasNumber(value) : ""),
  },
  {
    id: "submittedContainerNumber",
    label: "Container Number",
  },
  {
    id: "requestedByEmail",
    label: "Requester",
  },
  {
    id: "createdDate",
    label: "Date Created",
    transform: (value: Date) => (value ? convertToLocalTime(value) : null),
  },
  {
    id: "testStartDate",
    label: "Test Started On",
    transform: (value: Date) => (value ? convertToLocalTime(value) : null),
  },
  {
    id: "testEndDate",
    label: "Test Stopped On",
    transform: (value: Date) => (value ? convertToLocalTime(value) : null),
  },
  {
    id: "stationaryTestFacilityName",
    label: "Test Facility",
  },
  {
    id: "testStandName",
    label: "Test Stand",
  },
  { id: "statusName", label: "Status" },
  { id: "workGroupName", label: "Queue" },
];

export const processRequestsCols: TableColumns<StationaryTest> = [
  {
    id: "cardNumber",
    label: "Card Number",
  },
  {
    id: "testLabel",
    label: "Test Name",
  },
  {
    id: "testTypeName",
    label: "Test Type",
  },
  {
    id: "runNumber",
    label: "Run Number",
  },
  {
    id: "submittedSampleName",
    label: "MIDAS Number",
    transform: (value: string) => (value ? formatMidasNumber(value) : ""),
  },
  {
    id: "submittedContainerNumber",
    label: "Container Number",
  },
  {
    id: "requestedByEmail",
    label: "Requester",
  },
  {
    id: "createdDate",
    label: "Date Created",
    transform: (value: Date) => (value ? convertToLocalTime(value) : null),
  },
  {
    id: "testStartDate",
    label: "Test Started On",
    transform: (value: Date) => (value ? convertToLocalTime(value) : null),
  },
  {
    id: "testEndDate",
    label: "Test Stopped On",
    transform: (value: Date) => (value ? convertToLocalTime(value) : null),
  },
  {
    id: "stationaryTestFacilityName",
    label: "Test Facility",
  },
  {
    id: "testStandName",
    label: "Test Stand",
  },
  { id: "statusName", label: "Status" },
  { id: "workGroupName", label: "Queue" },
];

export const columnsAdditionalFuelSamplesTable = [
  {
    field: "sampleName",
    headerName: "Sample Name",
    type: "label",
    size: "small",
  },
  {
    field: "containerNumber",
    headerName: "Container Number",
    type: "label",
    size: "small",
  },
  {
    field: "returnLocationName",
    headerName: "Unused Return Location",
    type: "label",
    size: "small",
  },
  {
    field: "amountUsed",
    headerName: "Sample Expected Use",
    type: "label",
    size: "small",
  },
  {
    field: "amountUsedUoMName",
    headerName: "UoM",
    type: "label",
    size: "small",
  },
];

export const columnsFuelSampleTable = [
  "Sample Name",
  "Container Number",
  "Sample Description",
  "Sample Expected Use",
  "UoM",
  "Sample Used Return Location",
  "Sample Unused Return Location",
];

export const columnsAdditionalLubricantSamplesTable = [
  {
    field: "sampleName",
    headerName: "Sample Name",
    type: "label",
    size: "large",
  },
  {
    field: "containerNumber",
    headerName: "Container Number",
    type: "label",
    size: "small",
  },
  {
    field: "amountUsed",
    headerName: "Sample Expected Use",
    type: "label",
    size: "small",
  },
  {
    field: "amountUsedUoMName",
    headerName: "UoM",
    type: "label",
    size: "small",
  },
  {
    field: "usedReturnLocation",
    headerName: "Used Retain Location",
    type: "label",
    size: "small",
  },
  {
    field: "unusedReturnLocation",
    headerName: "Unused Return Location",
    type: "label",
    size: "small",
  },
];

export const columnsAdditionalLubricantSamplesTableWithViscosity = [
  {
    field: "sampleName",
    headerName: "Sample Name",
    type: "label",
    size: "large",
  },
  {
    field: "containerNumber",
    headerName: "Container Number",
    type: "label",
    size: "small",
  },
  {
    field: "viscosityGrade",
    headerName: "Viscosity Grade",
    type: "label",
    size: "small",
  },
  {
    field: "amountUsed",
    headerName: "Sample Expected Use",
    type: "label",
    size: "small",
  },
  {
    field: "amountUsedUoMName",
    headerName: "UoM",
    type: "label",
    size: "small",
  },
  {
    field: "usedReturnLocation",
    headerName: "Used Retain Location",
    type: "label",
    size: "small",
  },
  {
    field: "unusedReturnLocation",
    headerName: "Unused Return Location",
    type: "label",
    size: "small",
  },
];

export const columnsTestsTable = [
  {
    field: "testDisplayName",
    headerName: "Test Display Name",
    type: "label",
    size: "large",
  },
  { field: "testName", headerName: "Test Name", type: "label", size: "large" },
  { field: "isActive", headerName: "Active", type: "checkbox", size: "small" },
];

export const columnsEngineersTable = [
  {
    field: "engineerEmailAddress",
    headerName: "Email Address",
    type: "label",
    size: "large",
  },
  { field: "fullName", headerName: "Full Name", type: "label", size: "large" },
  {
    field: "isPrimaryEngineer",
    headerName: "Is Lead Engineer?",
    type: "checkbox",
    size: "small",
  },
  {
    field: "receiveNotifications",
    headerName: "Receive Notification",
    type: "checkbox",
    size: "small",
  },
];

export const columnsFacilityTable = [
  {
    field: "stationaryTestFacilityName",
    headerName: "Facility Name",
    type: "label",
    size: "large",
  },
  { field: "isExternal", headerName: "External", type: "label", size: "small" },
  {
    field: "externalCoordinatorEmail",
    headerName: "Coordinator",
    type: "label",
    size: "large",
  },
];

export const columnsTestStandTable = [
  { field: "standName", headerName: "Name", type: "label", size: "large" },
];

export const columnsTestParameterTable = [
  {
    field: "testParameterName",
    headerName: "Parameter",
    type: "label",
    size: "large",
  },
  {
    field: "parameterValue",
    headerName: "Value",
    type: "label",
    size: "large",
  },
];

export const templateIntervalTableCols = [
  "Interval Name",
  "Chem ID",
  "PSIMS ID",
  "Initial Location",
  "Return Location",
];

export const requestIntervalTableCols = [
  "Registered",
  "MIDAS #",
  "Interval Name",
  "Chem ID",
  "PSIMS ID",
  "Initial Location",
  "Return Location",
  "",
];

export const requestIntervalProcessTableCols = [
  "MIDAS #",
  "Chem ID",
  "PSIMS ID",
  "Initial Location",
  "Return Location",
];

export const testContainerTableCols = [
  "Containering Group",
  "Container Size",
  "Container UoM",
  "Container Type",
  "Return Location",
];

export const retainContainerTableCols = [
  "# of Containers",
  "Container Type",
  "Container Size",
  "Retain Amount",
  "Size UoM",
  "Retain UoM",
  "Storage Location",
];

export const procedureRetainContainerTableCols = [
  "# of Containers",
  "Container Size",
  "Size UoM",
  "Retain Amount",
  "Retain UoM",
  "Container Type",
  "Storage Location",
];

export const experimentProcessDetailsCols = ["Result Component Name", "Value", "UoM", ""];

export const intervalTableCols = [
  "Interval Name",
  "Chem ID",
  "PSIMS ID",
  "Initial Location",
  "Return Location",
  " ",
];
