import React, { useState, useEffect, useContext } from "react";
import { styled } from "@mui/material/styles";
import { Box, TextField, TableRow, IconButton, Autocomplete, CircularProgress } from "@mui/material";
import { StyledTableBody, StyledTableCell, UXDataTableWithoutBody, UXDataTableNested } from "../../../components/UXDataTable";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { formatMidasNumber, RoundNumber, RecalculateCurrentAmountOnUoMChange, RecalculateInitialSizeOnUoMChange, CheckContainerCurrentAmount, CheckContainerSize, isNumber, convertToLocalTime, InterpretChangeLogValue, convertDateFormat, hasRole, Roles, DatePickerKeyDownEvent} from "../../../global";
import ValidatedMidasNumberTextField from "../../../components/ValidatedMidasNumberTextField";
import { GlobalButton, GlobalSecondaryButton } from "../../styles";
import UnitOfMeasure from "../../../api/Admin/UnitOfMeasure";
import Location from "../../../api/Admin/Location";
import ContainerType from "../../../api/LIMS/ContainerType";
import ContainerStatus from "../../../api/LIMS/ContainerStatus";
import Sample from "../../../api/LIMS/Sample";
import EmailAddressTextField from "../../../components/EmailAddressField";
import ModalMessages from "../../../components/Modal/ModalSimpleButton"
import UserContext from "../../../context/UserContext";
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

    const containerCols = ["ID", "History", "Status", "Parent Container", "MIDAS #", "Container #", "Type", "Cylinder Serial #", "Size", "Current Amt.", "% Full", "UoM", "Tare Weight", "Tare UoM", "Owner", "Created By", "Created Date", 
                            "Last Modified By", "Modified On", "Default Return Location", "Location", "Sublocation", "Expiration Date", "Controlled Precious Metal", "Next Disposition", "Discard on Disposition", "Comments"]
    const historyCols = ["Property", "Old Value", "New Value", "Changed By", "Changed On"]

    const containerGridFontSize = 12

    const _longTextLength = 250
    const _mediumTextLength = 150
    const _shortTextLength = 75

    const StyledContainer = styled(Box)(() => ({
        display: 'flex',
        gap: '20px',
        marginBottom: "1rem"
    }));

    const StyledDiv = styled('div')(() => ({
        height: '2.2rem',
        alignItems: 'center',
        display: 'flex'
    }));

    const StyledAutocomplete = styled(Autocomplete)({
        '& .MuiAutocomplete-input, & .MuiInputLabel-root': {
          fontSize: containerGridFontSize,
        },
    });
    
    const Option = styled('li')({
        fontSize: containerGridFontSize,
    });

const ManageContainerData = ({ ...props }) => {
    const currentUser = useContext(UserContext)
    const roles = currentUser?.idTokenClaims.roles

    const [midasNumberObject, setMidasNumberObject] = useState(null)
    const [hasMidasErrors, setHasMidasErrors] = useState(null)
    const [mrdNumber, setMRDNumber] = useState(null)
    const [allSamples, setAllSamples] = useState(null)
    const [allContainers, setAllContainers] = useState(null)
    const [containerErrorChecks, setContainerErrorChecks] = useState([])
    const [isDataLoading, setIsDataLoading] = useState(null)

    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [page, setPage] = useState(0);
    const [containerRowOpen, setContainerRowOpen] = useState([]);

    //autocomplete lists
    const [availableUOMs, setAvailableUOMs] = useState([])
    const [availableLocations, setAvailableLocations] = useState([])
    const [containerTypes, setContainerTypes] = useState([])
    const [containerStatuses, setContainerStatuses] = useState([])

    const [modalMessagesOpen, setModalMessagesOpen] = useState(false);
    const modalMessagesButtonText = 'Ok'
    const [modalMessagesTitle, setModalMessagesTitle] = useState('');
    const [modalMessagesText, setModalMessagesText] = useState('');

    const [isSaving, setIsSaving] = useState(false)

    const noDataMessage = `No containers found for MIDAS # ${midasNumberObject === null ? '' : midasNumberObject.sampleName} or MRD # ${mrdNumber === null ? '' : mrdNumber}`
                                        
    useEffect(() => {
        let cancelPromise = false
    
            Location.getAll().then((res) => {
                if (cancelPromise) return
                setAvailableLocations(res.filter(result => result.isActive === true).sort((a, b) => a.locationName.localeCompare(b.locationName)))
            });
    
            UnitOfMeasure.getAll().then((res) => {
                if (cancelPromise) return
                setAvailableUOMs(res.filter(result => (result.metricStandardConversion !== null || result.type === 'concentration') && result.isActive === true).sort((a, b) => a.uoMName.localeCompare(b.uoMName)))
            });
    
            ContainerType.getAll().then((res) => {
                if (cancelPromise) return
                setContainerTypes(res.filter(result => result.isActive === true).sort((a, b) => a.name.localeCompare(b.name)))
            });
    
            ContainerStatus.getAll().then((res) => {
                if (cancelPromise) return
                setContainerStatuses(res.filter(result => result.isActive === true).sort((a, b) => a.name.localeCompare(b.name)))
            });            
        return () => {
            cancelPromise = true
        }
    }, [])

    useEffect(() => {
        if (midasNumberObject !== null)
        {
            setMRDNumber(null)
        }        
    }, [midasNumberObject])

    const isContainerRowOpen = (id) => containerRowOpen.indexOf(id) !== -1;

    function closeModalMessages() {
        setModalMessagesOpen(false);
    }
    
    function openModalMessages(title, text) {
        setModalMessagesOpen(true);
        setModalMessagesTitle(title);
        setModalMessagesText(text);
    }

    const handleContainerRowClick = (id) => {
        const containerRowOpenIndex = containerRowOpen.indexOf(id);
        let newContainerRowOpen = [];
    
        if (containerRowOpenIndex === -1) {
            newContainerRowOpen = newContainerRowOpen.concat(containerRowOpen, id);
        } else if (containerRowOpenIndex === 0) {
            newContainerRowOpen = newContainerRowOpen.concat(containerRowOpen.slice(1));
        } else if (containerRowOpenIndex === containerRowOpen.length - 1) {
            newContainerRowOpen = newContainerRowOpen.concat(containerRowOpen.slice(0, -1));
        } else if (containerRowOpenIndex > 0) {
            newContainerRowOpen = newContainerRowOpen.concat(
            containerRowOpen.slice(0, containerRowOpenIndex),
            containerRowOpen.slice(containerRowOpenIndex + 1)
          );
        }
        setContainerRowOpen(newContainerRowOpen);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const determineStartArray = () => {
        if (allContainers)
        {
            return (allContainers.sort((a, b) => a.sampleName.localeCompare(b.sampleName)).sort((a, b) => a.containerNumber - b.containerNumber).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage))
        } else {
            return []
        }
    }

    function ClearEntries () {
        setMidasNumberObject(null)
        setMRDNumber(null)
        setAllContainers(null)
        setAllSamples(null)
    }

    function SearchHistoryData () {
        let searchString = ''

        if (mrdNumber !== null)
        {
            searchString = mrdNumber;
        }

        if (midasNumberObject !== null)
        {
            searchString = midasNumberObject.sampleName;
        } 

        setIsDataLoading(true)

        let arrayErrors = []
        let tempContainers = []

        Sample.history(searchString).then((res) => {

            setIsDataLoading(false)

            if (res && res.message === 'Success')
            {
                setAllSamples(res.result)

                res.result?.forEach(oSample => {
                    oSample.containers.forEach(oContainer => {
                        arrayErrors.push({ 
                            key: `${oContainer.sampleName}~${oContainer.containerNumber}`,
                            containerStatus: false,
                            containerType: false,
                            size: false,
                            currentAmount: false,
                            uom: false,
                            tareWeight: false,
                            thisTareWeightUoM: false,
                            ownerEmail: false,
                            returnLocation: false,
                            location: false,
                            discardOnDisposition: false,
                        })

                        tempContainers.push(oContainer)
                    })
                })

                setAllContainers(tempContainers)

            } else {
                openModalMessages("Error Retrieving History", `There was an error getting Sample/Container history.  ${res ? res.message : ''}`)
            }

            setContainerErrorChecks(arrayErrors)
        })
    }

    function CheckAutoCompleteField (oContainer, property)
    {
        if (oContainer[property] === null)
        {
            return true
        } else {
            return false
        }
    }

    const handleContainerRowChange = (property, value, oContainer) => {
        const copyContainers = structuredClone(allContainers)

        if (oContainer === null)
        {
            return
        }

        const containerIndex = copyContainers.findIndex(cont => cont.containerNumber === oContainer.containerNumber && cont.sampleName === oContainer.sampleName)

        copyContainers[containerIndex][property] = value

        if (property === 'uom')
        {
          if (value &&   copyContainers[containerIndex].uom)
          {
            const oldUoM = allContainers[containerIndex].uom
    
            const mySample = allSamples.find(samp => samp.sampleName === oContainer.sampleName)
            copyContainers[containerIndex].currentAmount = RecalculateCurrentAmountOnUoMChange(mySample, mySample.substance,  copyContainers[containerIndex].currentAmount,  oldUoM,  copyContainers[containerIndex].uom) 
            copyContainers[containerIndex].size = RecalculateInitialSizeOnUoMChange(mySample, mySample.substance,  copyContainers[containerIndex].size,  oldUoM, copyContainers[containerIndex].uom) 
          }
        }

        if (property === 'discardOnDisposition')
        {
            if (value === null)
            {
                copyContainers[containerIndex][property] = false                
            }
        }

        setAllContainers(copyContainers)
    }

    function updateErrorChecksForContainers (property, value, oContainer)
    {
        const copyContainerErrorChecks = structuredClone(containerErrorChecks)

        if (oContainer === null)
        {
            return
        }

        const index = copyContainerErrorChecks.findIndex(cont => cont.key === `${oContainer.sampleName}~${oContainer.containerNumber}`)

        copyContainerErrorChecks[index][property] = value

        setContainerErrorChecks(copyContainerErrorChecks)  
    }

    function ErrorCheckTareWeightAndUOM (oContainer)
    {
        const copyContainerErrorChecks = structuredClone(containerErrorChecks)

        if (oContainer === null)
        {
            return
        }

        const index = copyContainerErrorChecks.findIndex(cont => cont.key === `${oContainer.sampleName}~${oContainer.containerNumber}`)

        if ((oContainer.tareWeight === null || oContainer.tareWeight === '' ) && oContainer.thisTareWeightUoM === null)
        {
            copyContainerErrorChecks[index].tareWeight = false
            copyContainerErrorChecks[index].thisTareWeightUoM = false 

        } else if ((oContainer.tareWeight !== null || oContainer.tareWeight !== '' ) && oContainer.thisTareWeightUoM === null) {

            copyContainerErrorChecks[index].tareWeight = !isNumber(oContainer.tareWeight)
            copyContainerErrorChecks[index].thisTareWeightUoM = true             

        }  else if ((oContainer.tareWeight === null || oContainer.tareWeight === '' ) && oContainer.thisTareWeightUoM !== null) {

            copyContainerErrorChecks[index].tareWeight = true
            copyContainerErrorChecks[index].thisTareWeightUoM = CheckAutoCompleteField(oContainer, 'thisTareWeightUoM')    

        } else {
            copyContainerErrorChecks[index].tareWeight = !isNumber(oContainer.tareWeight)
            copyContainerErrorChecks[index].thisTareWeightUoM = CheckAutoCompleteField(oContainer, 'thisTareWeightUoM')            
        }
    
        setContainerErrorChecks(copyContainerErrorChecks)  
    }

    function ErrorCheckOnSubmission () {
        let hasErrors = []
    
        containerErrorChecks.forEach((oContainerError, index) => {

            const split = oContainerError.key.split("~")
            const key = `Sample ${formatMidasNumber(split[0])} container ${split[1]}`

            if (oContainerError.containerType === true || oContainerError.containerType === null)
            {
                hasErrors.push(`${key} has an invalid container type`)
            }
    
            if (oContainerError.containerStatus === true || oContainerError.containerStatus === null)
            {
                hasErrors.push(`${key} has an invalid container status`)
            }
    
            if (oContainerError.size === true || oContainerError.size === null)
            {
                hasErrors.push(`${key} has an invalid container size`)
            }
    
            if (oContainerError.currentAmount === true || oContainerError.currentAmount === null)
            {
                hasErrors.push(`${key} has an invalid container current amount`)
            }
    
            if (oContainerError.uom === true || oContainerError.uom === null)
            {
                hasErrors.push(`${key} has an invalid container UoM`)
            }
    
            if (oContainerError.location === true || oContainerError.location === null)
            {
                hasErrors.push(`${key} has an invalid container location`)
            }

            if (oContainerError.returnLocation === true || oContainerError.returnLocation === null)
            {
                hasErrors.push(`${key} has an invalid return container location`)
            }
    
            if (oContainerError.ownerEmail === true || oContainerError.ownerEmail === null)
            {
                hasErrors.push(`${key} has an invalid container owner`)
            }

            if (oContainerError.tareWeight === true || oContainerError.tareWeight === null)
            {
                hasErrors.push(`${key} has an invalid tare weight`)
            }

            if (oContainerError.thisTareWeightUoM === true || oContainerError.thisTareWeightUoM === null)
            {
                hasErrors.push(`${key} has an invalid tare weight UoM`)
            }

            if (oContainerError.discardOnDisposition === true || oContainerError.discardOnDisposition === null)
            {
                hasErrors.push(`${key} has an invalid discard on disposition value`)
            }
        })
    
        //if all checks were passed add it to the collection to register
        if (hasErrors.length === 0) {
            SaveToDatabase()
        } else {
            openModalMessages("Container Errors", hasErrors)
        }
    }

    function SaveToDatabase () {
        setIsSaving(true)

        const formData = new FormData();

        for (let index = 0; index < allSamples.length; index++) {
            const oSample = allSamples[index]
            const containers = allContainers.filter(c => c.sampleName === oSample.sampleName)

            allSamples[index].containers = structuredClone(containers)
        }

        formData.append("samples", JSON.stringify(allSamples))
        //formData.append("samples", JSON.stringify([mySamples]))

        Sample.RegisterSampleAndContainers(formData).then((res) => {

            if (res && res.message === 'Success') {
                openModalMessages('Containers Saved', 'Containers successfully saved!')

                ClearEntries()
            }
            else{
                openModalMessages('Containers Failed to Save', `Save failed ${res ? res.message : ''}`, 'Ok');
            }
    
            setIsSaving(false)
        });
    }

    function RoundAmounts (property, value, oContainer, decimalsToRound)
    {
      if(isNumber(value))
      {
        const copyContainers = structuredClone(allContainers)

        const containerIndex = copyContainers.findIndex(cont => cont.containerNumber === oContainer.containerNumber && cont.sampleName === oContainer.sampleName)
  
        copyContainers[containerIndex][property] = RoundNumber(value, decimalsToRound, true)
        setAllContainers(copyContainers)
      }
    }

    function IsCellDisabled (oContainer) {

        //PM Check
        if (allSamples && allSamples.find(s => s.sampleName === oContainer.sampleName).isRestrictedAccess === true && hasRole(Roles.PMUser, roles) === false && hasRole(Roles.Developer, roles) === false) 
        {
            return true
        }

        //sample owner check
        if (midasNumberObject && midasNumberObject.ownerEmail === currentUser.username)
        {
            return false

        //container owner check
        } else if (oContainer.ownerEmail && oContainer.ownerEmail === currentUser.username)
        {
            return false

        //role check    
        } else if (hasRole(Roles.Developer, roles) || hasRole(Roles.ContainerEditor, roles)) 
        {
            return false
        } else {
            return true
        }
    }

    return (
        <>
        <StyledContainer>
            <ValidatedMidasNumberTextField
                margin={"none"}
                showLabel={true}
                fontSize={16}
                fieldWidth={"20rem"}
                midasNumberObject={midasNumberObject}
                setMidasNumberObject={setMidasNumberObject}
                hasErrors={hasMidasErrors}
                setHasErrors={setHasMidasErrors}
                chemIDToMatch={null}
            ></ValidatedMidasNumberTextField>

            <StyledDiv>
                <label>OR</label>
            </StyledDiv>

            <TextField 
                size="small" 
                margin="none" 
                variant="outlined" 
                label="MRD Number" 
                InputLabelProps={{shrink: true}} 
                style ={{width: "20rem", size:"small"}}
                onChange={(e) => {setMRDNumber(e.target.value); setMidasNumberObject(null)}}
                value={mrdNumber === null ? '' : mrdNumber}>
            </TextField>

            <GlobalButton
                disabled={mrdNumber === null && midasNumberObject === null}
                variant="contained" 
                onClick={() => SearchHistoryData()}
            >Search</GlobalButton>

            <GlobalSecondaryButton 
                variant='contained'
                onClick={(e) => ClearEntries()}
            >Clear</GlobalSecondaryButton>

            <Box display="flex" alignItems={"center"} marginLeft="auto" marginTop="-.3rem">
                {isSaving === true ? (
                <>
                <CircularProgress style={{textTransform: "none"}}></CircularProgress>

                <GlobalButton
                    disabled={true}
                    variant="contained" 
                    onClick={() => ErrorCheckOnSubmission()}
                    >Save Containers</GlobalButton>
                </>
                ) : (
                <GlobalButton
                    disabled={(mrdNumber === null && midasNumberObject === null) || allContainers === null}
                    variant="contained" 
                    onClick={() => ErrorCheckOnSubmission()}
                >Save Containers</GlobalButton>
                )}
            </Box>
        </StyledContainer>

        <UXDataTableWithoutBody 
            tableWidth='100%' 
            cols={containerCols} 
            blankFirstHeader={false}
            tableId="myContainers"
            rowLength={allContainers ? allContainers.length : 0}
            enablePaging={true}
            rowsPerPage={rowsPerPage}
            page={page}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            noDataFoundMessage={noDataMessage}
            enableAddIcon={false}
            addFunction={null}
            addToolTipText={null}  
            isDataLoading={isDataLoading}
        >
            <StyledTableBody> 
                {allContainers && allContainers.length > 0 && determineStartArray().map((oContainer, index) => { 

                    const disableCell = IsCellDisabled(oContainer)
                    const errorSearchKey = `${oContainer.sampleName}~${oContainer.containerNumber}`

                return (
                    <>
                    <TableRow key={`myContainerRow${oContainer.sampleName}~${oContainer.containerNumber}`}>
                        <StyledTableCell>{oContainer.id}</StyledTableCell>

                        <StyledTableCell style={{width:'5rem'}}>
                            <IconButton
                                aria-label='expand row'
                                size='small'
                                onClick={() => handleContainerRowClick(oContainer.id)}>
                                {isContainerRowOpen(oContainer.id) ? (
                                    <KeyboardArrowUpIcon/>
                                ) : (
                                    <KeyboardArrowDownIcon/>
                                )}
                            </IconButton>
                        </StyledTableCell>

                        <StyledTableCell>
                            <StyledAutocomplete
                                disabled={disableCell}
                                renderOption={(props2, option) => (
                                    <Option {...props2}>{option.name}</Option>
                                )}
                                disablePortal
                                noOptionsText={"Loading Statuses..."}
                                options={containerStatuses}
                                getOptionLabel={(option) => option.name}
                                onChange={(e, value) => handleContainerRowChange('containerStatus', value, oContainer)}
                                onBlur={(e) => {updateErrorChecksForContainers('containerStatus', CheckAutoCompleteField(oContainer, 'containerStatus'), oContainer)}}
                                value={oContainer.containerStatus}
                                isOptionEqualToValue={(option, value) => value.name === option.name}
                                autoHighlight
                                autoSelect
                                renderInput={(params) => <TextField {...params} style={{width:_mediumTextLength}} variant="outlined" size="small" error = {containerErrorChecks.find(c => c.key === errorSearchKey).containerStatus}  inputProps={{ ...params.inputProps,  style: { fontSize: containerGridFontSize}}} InputProps={{ ...params.InputProps }}/>} 
                            />
                        </StyledTableCell>

                        <StyledTableCell style={{minWidth: _shortTextLength}}>{oContainer.parentContainer?.containerNumber}</StyledTableCell>
                        <StyledTableCell style={{minWidth: _mediumTextLength}}><b>{formatMidasNumber(oContainer.sampleName)}</b></StyledTableCell>
                        <StyledTableCell style={{minWidth: _shortTextLength}}>{oContainer.containerNumber}</StyledTableCell>

                        <StyledTableCell>                    
                            <StyledAutocomplete
                                disabled={disableCell}    
                                renderOption={(props2, option) => (
                                    <Option {...props2}>{option.name}</Option>
                                )}
                                disablePortal
                                noOptionsText={"Loading Types..."}
                                options={containerTypes}
                                getOptionLabel={(option) => option.name}
                                onChange={(e, value) => handleContainerRowChange('containerType', value, oContainer)}
                                onBlur={(e) => {updateErrorChecksForContainers('containerType', CheckAutoCompleteField(oContainer, 'containerType'), oContainer)}}
                                value={oContainer.containerType}
                                isOptionEqualToValue={(option, value) => value.name === option.name}
                                autoHighlight
                                autoSelect
                                renderInput={(params) => <TextField {...params} style={{width:_mediumTextLength}} variant="outlined" size="small" error = {containerErrorChecks.find(c => c.key === errorSearchKey).containerType}  inputProps={{ ...params.inputProps,  style: { fontSize: containerGridFontSize}}} InputProps={{ ...params.InputProps }}/>} 
                            />
                        </StyledTableCell>

                        <StyledTableCell>
                            <TextField style={{width:_mediumTextLength}}
                                disabled={disableCell}
                                size="small" 
                                margin="dense" 
                                variant="outlined"
                                InputProps={{ style: { fontSize: containerGridFontSize }, maxLength: 40 }}
                                InputLabelProps={{shrink: true}}
                                value = {oContainer.cylinderSerialNumber}
                                onChange={(e) => handleContainerRowChange('cylinderSerialNumber', (e.target.value), oContainer)}
                            />
                        </StyledTableCell>

                        <StyledTableCell>
                            <TextField style={{width:_shortTextLength}}
                                disabled={disableCell}
                                size="small" 
                                margin="dense" 
                                variant="outlined"
                                InputProps={{ style: { fontSize: containerGridFontSize }, maxLength: 20 }}
                                InputLabelProps={{shrink: true}}
                                value = {oContainer.size}
                                error = {containerErrorChecks.find(c => c.key === errorSearchKey).size} 
                                onChange={(e) => handleContainerRowChange('size', (e.target.value), oContainer)}
                                onBlur={e => {updateErrorChecksForContainers('size', CheckContainerSize(oContainer), oContainer); RoundAmounts('size', oContainer.size, oContainer, 2)}}
                            />
                        </StyledTableCell>

                        <StyledTableCell>
                            <TextField style={{width:_shortTextLength}}
                                disabled={disableCell}
                                size="small" 
                                margin="dense" 
                                variant="outlined"
                                InputProps={{ style: { fontSize: containerGridFontSize }, maxLength: 20 }}
                                InputLabelProps={{shrink: true}}
                                value = {oContainer.currentAmount}
                                error = {containerErrorChecks.find(c => c.key === errorSearchKey).currentAmount} 
                                onChange={(e) => handleContainerRowChange('currentAmount', (e.target.value), oContainer)}
                                onBlur={e => {updateErrorChecksForContainers('currentAmount', CheckContainerCurrentAmount(oContainer), oContainer); RoundAmounts('currentAmount', oContainer.currentAmount, oContainer, 2)}}
                            />
                        </StyledTableCell>

                        <StyledTableCell>{oContainer.size > 0 ? ((oContainer.currentAmount / oContainer.size) * 100).toFixed(2) : 0}%</StyledTableCell>

                        <StyledTableCell>
                            <StyledAutocomplete
                                disabled={disableCell}
                                renderOption={(props2, option) => (
                                    <Option {...props2}>{option.uoMName}</Option>
                                )}
                                disablePortal
                                noOptionsText={"Loading UOMs..."}
                                options={availableUOMs.filter(result => (result.type === 'weight' || result.type ==='volume' || result.type === 'unit'))}
                                getOptionLabel={(option) => option.uoMName}
                                onChange={(e, value) => handleContainerRowChange('uom', value, oContainer)}
                                onBlur={(e) => {updateErrorChecksForContainers('uom', CheckAutoCompleteField(oContainer, 'uom'), oContainer)}}
                                value={oContainer.uom}
                                isOptionEqualToValue={(option, value) => value.uoMName === option.uoMName}
                                autoHighlight
                                autoSelect
                                renderInput={(params) => <TextField {...params} style={{width:_shortTextLength}} variant="outlined" size="small" error = {containerErrorChecks.find(c => c.key === errorSearchKey).uom}  inputProps={{ ...params.inputProps,  style: { fontSize: containerGridFontSize}}} InputProps={{ ...params.InputProps }}/>} 
                            />
                        </StyledTableCell>

                        <StyledTableCell>
                            <TextField style={{width:_shortTextLength}}
                                disabled={disableCell}
                                size="small" 
                                margin="dense" 
                                variant="outlined"
                                InputProps={{ style: { fontSize: containerGridFontSize }, maxLength: 18 }}
                                InputLabelProps={{shrink: true}}
                                value = {oContainer.tareWeight ? oContainer.tareWeight : ''}
                                error = {containerErrorChecks.find(c => c.key === errorSearchKey).tareWeight} 
                                onChange={(e) => handleContainerRowChange('tareWeight', (e.target.value), oContainer)}
                                onBlur={(e) => {ErrorCheckTareWeightAndUOM(oContainer); RoundAmounts('tareWeight', oContainer.tareWeight, oContainer, 3)}}
                            />
                        </StyledTableCell>

                        <StyledTableCell>
                            <StyledAutocomplete
                                disabled={disableCell}
                                renderOption={(props2, option) => (
                                    <Option {...props2}>{option.uoMName}</Option>
                                )}
                                disablePortal
                                noOptionsText={"Loading UOMs..."}
                                options={availableUOMs.filter(result => (result.type === 'weight' || result.type ==='volume' || result.type === 'unit'))}
                                getOptionLabel={(option) => option.uoMName}
                                onChange={(e, value) => handleContainerRowChange('thisTareWeightUoM', value, oContainer)}
                                onBlur={(e) => {ErrorCheckTareWeightAndUOM(oContainer)}}
                                value={oContainer.thisTareWeightUoM}
                                isOptionEqualToValue={(option, value) => value.uoMName === option.uoMName}
                                autoHighlight
                                autoSelect
                                renderInput={(params) => <TextField {...params} style={{width:_shortTextLength}} variant="outlined" size="small" error = {containerErrorChecks.find(c => c.key === errorSearchKey).thisTareWeightUoM}  inputProps={{ ...params.inputProps,  style: { fontSize: containerGridFontSize}}} InputProps={{ ...params.InputProps }}/>} 
                            />
                        </StyledTableCell>
                        
                        <StyledTableCell>
                            <EmailAddressTextField
                                fontSize = {12}
                                fieldWidth = {"300px"} 
                                validatedUserEmail = {oContainer.ownerEmail}
                                setValidatedUserEmail = {(e) => handleContainerRowChange("ownerEmail", e, oContainer )}
                                hasErrors = {containerErrorChecks.find(c => c.key === errorSearchKey).ownerEmail === null ? false : containerErrorChecks.find(c => c.key === errorSearchKey).ownerEmail}
                                setHasErrors = {(e) => updateErrorChecksForContainers('ownerEmail', e, oContainer)}
                                isDisabled={disableCell}
                                labelText="Owner Email"
                                placeholderText="Owner Email"
                                showPlusMeButton={true}>
                            </EmailAddressTextField>
                        </StyledTableCell>

                        <StyledTableCell>{oContainer.createdByEmail}</StyledTableCell>
                        <StyledTableCell style={{minWidth: _longTextLength}}>{convertToLocalTime(oContainer.createdDate)}</StyledTableCell>
                        <StyledTableCell>{oContainer.lastModifiedByEmail}</StyledTableCell>
                        <StyledTableCell style={{minWidth: _longTextLength}}>{convertToLocalTime(oContainer.lastModifiedDate)}</StyledTableCell>

                        <StyledTableCell>
                            <StyledAutocomplete
                                disabled={disableCell}
                                renderOption={(props2, option) => (
                                    <Option {...props2}>{option.locationName}</Option>
                                )}                            
                                disablePortal
                                noOptionsText={"Loading Locations..."}
                                options={availableLocations}
                                getOptionLabel={(option) => option.locationName}
                                onChange={(e, value) => handleContainerRowChange('returnLocation', value, oContainer)}
                                onBlur={(e) => {updateErrorChecksForContainers('returnLocation', CheckAutoCompleteField(oContainer, 'returnLocation'), oContainer)}}
                                isOptionEqualToValue={(option, value) => value.locationName === option.locationName}
                                autoHighlight
                                autoSelect
                                value={oContainer.returnLocation}
                                renderInput={(params) => <TextField {...params} style={{width:_mediumTextLength}} variant="outlined" size="small" 
                                    error = {containerErrorChecks.find(c => c.key === errorSearchKey).returnLocation}  inputProps={{ ...params.inputProps,  style: { fontSize: containerGridFontSize } }} InputProps={{ ...params.InputProps }}/>} 
                            />
                        </StyledTableCell>

                        <StyledTableCell>
                            <StyledAutocomplete
                                disabled={disableCell}
                                renderOption={(props2, option) => (
                                    <Option {...props2}>{option.locationName}</Option>
                                )}                            
                                disablePortal
                                noOptionsText={"Loading Locations..."}
                                options={availableLocations}
                                getOptionLabel={(option) => option.locationName}
                                onChange={(e, value) => handleContainerRowChange('location', value, oContainer)}
                                onBlur={(e) => {updateErrorChecksForContainers('location', CheckAutoCompleteField(oContainer, 'location'), oContainer)}}
                                isOptionEqualToValue={(option, value) => value.locationName === option.locationName}
                                autoHighlight
                                autoSelect
                                value={oContainer.location}
                                renderInput={(params) => <TextField {...params} style={{width:_mediumTextLength}} variant="outlined" size="small" 
                                error = {containerErrorChecks.find(c => c.key === errorSearchKey).location}  inputProps={{ ...params.inputProps,  style: { fontSize: containerGridFontSize } }} InputProps={{ ...params.InputProps }}/>} 
                            />
                        </StyledTableCell>

                        <StyledTableCell>
                            <TextField style={{width:_longTextLength}} 
                                disabled={disableCell}
                                multiline
                                size="small" 
                                margin="dense" 
                                variant="outlined"
                                InputProps={{ style: { fontSize: containerGridFontSize }, maxLength: 4000 }}
                                InputLabelProps={{shrink: true}}
                                value = {oContainer.subLocation === null ? '' : oContainer.subLocation}
                                onChange={(e) => handleContainerRowChange('subLocation', (e.target.value), oContainer)}
                            />
                        </StyledTableCell>

                        <StyledTableCell>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DesktopDatePicker
                                    disabled={disableCell}
                                    size="small"
                                    inputFormat="MM/dd/yyyy"
                                    onChange={(e) => handleContainerRowChange('expirationDate', (e), oContainer)}
                                    value={oContainer.expirationDate}
                                    renderInput={(params) => <TextField
                                        onKeyDown={DatePickerKeyDownEvent}
                                        {...params}
                                        style={{ width: "12rem" }}
                                        variant="outlined"
                                        size="small"
                                    />}
                                />
                            </LocalizationProvider>
                        </StyledTableCell>

                        <StyledTableCell>{allSamples && allSamples.find(s => s.sampleName === oContainer.sampleName).isRestrictedAccess.toString()}</StyledTableCell>
                        <StyledTableCell style={{minWidth: _longTextLength}}>{convertDateFormat(oContainer.nextDispositionDate, true)}</StyledTableCell>

                        <StyledTableCell>
                            <StyledAutocomplete
                                disabled={disableCell}
                                renderOption={(props2, option) => (
                                    <Option {...props2}>{option}</Option>
                                )}                            
                                disablePortal
                                options={["True", "False"]}
                                getOptionLabel={(option) => option}
                                onChange={(e, value) => handleContainerRowChange('discardOnDisposition', value, oContainer)}
                                isOptionEqualToValue={(option, value) => value === option}
                                autoHighlight
                                autoSelect
                                value={oContainer.discardOnDisposition}
                                renderInput={(params) => <TextField {...params} style={{width:_mediumTextLength}}  variant="outlined" size="small" 
                                    error = {false}  inputProps={{ ...params.inputProps,  style: { fontSize: containerGridFontSize } }} InputProps={{ ...params.InputProps }}/>} 
                            />
                        </StyledTableCell>
                        <StyledTableCell>{oContainer.comments}</StyledTableCell>
                    </TableRow>

                    {isContainerRowOpen(oContainer.id) && (
                        <UXDataTableNested tableWidth={'100%'} cols={historyCols} blankFirstHeader={false} colSpan={10} isOpenComparator={oContainer.id} isOpenArray={containerRowOpen}>
                            <StyledTableBody>
                            {oContainer.changeLogs.map((oHistory, historyIndex) => ( 
                                <TableRow key={`${oContainer.id}_${historyIndex}`}>
                                    <StyledTableCell>{oHistory.propertyName}</StyledTableCell>
                                    <StyledTableCell>{InterpretChangeLogValue(oHistory.oldValue, 2)}</StyledTableCell>
                                    <StyledTableCell>{InterpretChangeLogValue(oHistory.newValue, 2)}</StyledTableCell>
                                    <StyledTableCell>{oHistory.changeMadeBy}</StyledTableCell>
                                    <StyledTableCell>{convertToLocalTime(oHistory.dateChanged)}</StyledTableCell>
                                </TableRow>                            
                            ))}
                            </StyledTableBody>
                        </UXDataTableNested>
                    )}
                    </>
                )
                })}
            </StyledTableBody>
        </UXDataTableWithoutBody>

        <ModalMessages title={modalMessagesTitle} buttonText={modalMessagesButtonText} buttonAction={closeModalMessages} open={modalMessagesOpen} setOpen={setModalMessagesOpen}>
            {modalMessagesTitle === "Container Errors" ?
            (  modalMessagesText && modalMessagesText.map((text, index) => {
                return (
                    <div style={{display:"flex"}} key={`validationErrors${index}`}>
                        <label>
                        {text}
                        </label>
                    </div>
                )
                })
            )
            :
            ( <label>
                {modalMessagesText}
                </label>
            )}      
        </ModalMessages>
        </>
    )
};

export default ManageContainerData;