import { Document, Page, Text, View, StyleSheet } from "@react-pdf/renderer";
import type { StationaryTest } from "../../types";
import { formatMidasNumber } from "../../../../global";

type TestType = "Lubricant" | "Fuels" | undefined;

const RED = "#800000";

const commonStyles = StyleSheet.create({
  column: {
    paddingLeft: "9px",
    paddingTop: "3px",
    borderLeft: "1px solid black",
    borderRight: "1px solid black",
  },
  boldText: {
    fontWeight: "bold",
    fontFamily: "Helvetica-Bold",
  },
});

const styles = StyleSheet.create({
  page: {
    display: "flex",
    flexDirection: "column",
    padding: "32px 64px",
    fontSize: "9px",
    fontFamily: "Helvetica",
  },
  firstSection: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  fieldName: {
    ...commonStyles.boldText,
  },
  regularSectionTitle: {
    ...commonStyles.boldText,
    textDecoration: "underline",
  },
  emphasizedSectionTitle: {
    ...commonStyles.boldText,
    color: RED,
    textDecoration: "underline",
    textDecorationColor: RED,
  },
  emphasizedText: {
    color: RED,
  },
  section: {
    marginBottom: 16,
    display: "flex",
    flexDirection: "column",
  },
  flexRow: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  table: {
    width: "100%",
  },
  row: {
    display: "flex",
    flexDirection: "row",
    borderTop: "1px solid black",
  },
  lastRow: {
    display: "flex",
    flexDirection: "row",
    borderBottom: "1px solid black",
  },
  borderedBottomView: {
    borderBottom: "1px solid black",
  },
  smallColumn: {
    ...commonStyles.column,
    width: "20%",
  },
  oneQuarterColumn: {
    ...commonStyles.column,
    width: "25%",
  },
  mediumColumn: {
    ...commonStyles.column,
    width: "40%",
  },
  halfColumn: {
    ...commonStyles.column,
    width: "50%",
  },
  bigColumn: {
    ...commonStyles.column,
    width: "60%",
  },
  threeQuarterColumn: {
    ...commonStyles.column,
    width: "75%",
  },
  noLeftOrRightBorder: {
    borderLeft: "none",
    borderRight: "none",
  },
  noBorderLeft: {
    borderLeft: "none",
  },
  noBorderRight: {
    borderRight: "none",
  },
});

type RunCardProps = {
  stationaryTest: Partial<StationaryTest> | null;
};

export default function RunCard({ stationaryTest }: RunCardProps) {
  if (!stationaryTest) {
    return (
      <Document>
        <Page size="A4" />
      </Document>
    );
  }

  const {
    sampleViscosityGrade = "",
    isParticularHazardousSubstance = false,
    sampleUsedReturnLocationName = "",
    sampleUnusedReturnLocationName = "",
  } = { ...stationaryTest.generalInformation };

  const { sampleName, description = "" } = { ...stationaryTest.testInformation?.submittedSample };

  const sampleSize =
    (stationaryTest.generalInformation?.sampleExpectedUse ?? "") +
    " " +
    (stationaryTest.generalInformation?.sampleExpectedUseUoMName ?? "");

  const qtySavedUsedOil = `${stationaryTest.sampleExpectedUse ?? ""} ${
    stationaryTest.sampleExpectedUseUoMName ?? ""
  }`;

  const actualContainerSize = "";

  const testType: TestType =
    (stationaryTest.testSpecification?.testTypeName as TestType) || undefined;

  return (
    <Document>
      <Page size="A4" wrap>
        <View style={styles.page}>
          <Header stationaryTest={stationaryTest} />

          <View style={{ ...styles.flexRow, marginTop: 16 }}>
            <Text style={[styles.emphasizedText, styles.fieldName]}>Test Name: </Text>
            <Text>{stationaryTest.testInformation?.testLabel}</Text>
          </View>
          <View style={styles.flexRow}>
            <Text style={[styles.fieldName]}>Request Status: </Text>
            <Text>{stationaryTest.statusName}</Text>
          </View>

          <TestSpecifications stationaryTest={stationaryTest} />

          {testType === "Lubricant" && (
            <View>
              <LubricantSpecification stationaryTest={stationaryTest} />

              <LubricantSample
                midasNumber={sampleName ? formatMidasNumber(sampleName) : ""}
                sampleDescription={description}
                isParticularHazardousSubstance={isParticularHazardousSubstance}
                sampleViscosityGrade={sampleViscosityGrade}
                sampleSize={sampleSize}
                actualContainerSize={actualContainerSize}
                unusedReturnLocation={sampleUnusedReturnLocationName}
                usedReturnLocation={sampleUsedReturnLocationName}
                qtySavedUsedOil={qtySavedUsedOil}
              />
            </View>
          )}

          {testType === "Fuels" && (
            <View>
              {stationaryTest.lubricantSamples &&
                stationaryTest.lubricantSamples.length > 0 &&
                stationaryTest.lubricantSamples.map((ls, i) => {
                  const {
                    sampleName = "",
                    isParticularHazardousSubstance = false,
                    amountUsed = "",
                    amountUsedUoMName = "",
                    unusedReturnLocation = "",
                    usedReturnLocation = "",
                    retainAmount = "",
                    retainAmountUoMName = "",
                  } = { ...ls };
                  const sampleDescription = ls?.sample?.description ?? "";

                  return (
                    <LubricantSample
                      midasNumber={sampleName}
                      sampleDescription={sampleDescription}
                      isParticularHazardousSubstance={isParticularHazardousSubstance}
                      sampleViscosityGrade={sampleViscosityGrade}
                      sampleSize={amountUsed + " " + amountUsedUoMName}
                      actualContainerSize={actualContainerSize}
                      unusedReturnLocation={unusedReturnLocation}
                      usedReturnLocation={usedReturnLocation}
                      qtySavedUsedOil={retainAmount + " " + retainAmountUoMName}
                    />
                  );
                })}

              <FuelSpecifications stationaryTest={stationaryTest} />

              <FuelUsed stationaryTest={stationaryTest} />
            </View>
          )}

          <TestParameters stationaryTest={stationaryTest} />

          <SubmitterComments stationaryTest={stationaryTest} />

          <AdditionalInstructions stationaryTest={stationaryTest} />

          <AuthorizationValidation stationaryTest={stationaryTest} />
        </View>
      </Page>
    </Document>
  );
}

type SectionProps = {
  stationaryTest: Partial<StationaryTest>;
};

const Header = ({ stationaryTest }: SectionProps) => {
  const {
    type = "",
    requestedByEmail: originalAuthor = "",
    lastModifiedByEmail: lastEditor = "",
    createdDate,
    lastModifiedDate,
  } = { ...stationaryTest.generalInformation, ...stationaryTest.testInformation };

  const localCreatedDateTime = createdDate ? new Date(createdDate).toLocaleDateString() : "n/a";
  const localLastModifiedDateTime = lastModifiedDate
    ? new Date(lastModifiedDate).toLocaleDateString()
    : "n/a";

  return (
    <View fixed>
      <View style={styles.firstSection} fixed>
        <Text style={[styles.fieldName]}>{type} Test Request</Text>
        <Text>
          Original Author: {originalAuthor} - Created: {" " + localCreatedDateTime}
        </Text>
        <Text>
          Last Editor: {lastEditor} - Last Modified: {" " + localLastModifiedDateTime}
        </Text>
      </View>
      <Text style={[styles.emphasizedText, { paddingTop: "5px" }]}>
        Card tracking #: {stationaryTest.cardNumber}
      </Text>
    </View>
  );
};

const TestSpecifications = ({ stationaryTest }: SectionProps) => {
  const {
    runNumber = "",
    billingReference = "",
    isISO9001 = "",
    safetyAlert = false,
    testDisplayName: wiNumber = "",
  } = stationaryTest;
  const { officialRegistered } = { ...stationaryTest?.testSpecification };
  const accAtcRegistered =
    officialRegistered === undefined ? "N/A" : officialRegistered ? "Yes" : "No";

  return (
    <View style={{ ...styles.section, marginTop: 16 }}>
      <Text style={styles.regularSectionTitle}>Test Specifications</Text>
      <View style={styles.table}>
        <View style={[styles.row]}>
          <Text style={[styles.smallColumn, styles.noBorderRight, styles.fieldName]}>
            Run Number:
          </Text>
          <Text style={[{ ...styles.mediumColumn, borderRight: "none" }]}>{runNumber}</Text>
          <Text style={[styles.smallColumn, styles.noBorderRight, styles.fieldName]}>
            WI Number:
          </Text>
          <Text style={styles.smallColumn}>{wiNumber}</Text>
        </View>
        <View style={[styles.row]}>
          <Text
            style={[
              styles.smallColumn,
              styles.noBorderRight,
              styles.emphasizedText,
              styles.fieldName,
            ]}>
            Jade Number:
          </Text>
          <Text style={[styles.smallColumn, styles.noBorderRight]}>{billingReference}</Text>
          <Text style={[{ ...styles.mediumColumn, borderRight: "none" }, styles.fieldName]}>
            Quality System (9001)?:
          </Text>
          <Text style={{ ...styles.smallColumn }}>{isISO9001 ? "Yes" : "No"}</Text>
        </View>
        <View style={[styles.row, styles.lastRow]}>
          <Text style={[styles.smallColumn, styles.noBorderRight, styles.fieldName]}>
            ACC/ATC Registered?:
          </Text>
          <Text style={[styles.smallColumn, styles.noBorderRight]}>{accAtcRegistered}</Text>
          <Text style={[styles.bigColumn, styles.emphasizedText, styles.fieldName]}>
            Testing of this material may produce results which fall outside the scope of normal
            operation?: {safetyAlert ? "Yes" : "No"}
          </Text>
        </View>
      </View>
    </View>
  );
};

const LubricantSpecification = ({ stationaryTest }: SectionProps) => {
  const { reportAlias, accOilCode, formulationCode } = stationaryTest;

  return (
    <View style={styles.section}>
      <Text style={styles.regularSectionTitle}>Lubricant Specifications</Text>
      <View style={styles.table}>
        <View style={[styles.row]}>
          <Text style={[styles.mediumColumn, styles.noBorderRight, styles.fieldName]}>
            Report Alias:
          </Text>
          <Text style={{ ...styles.bigColumn }}>{reportAlias}</Text>
        </View>
        <View style={[styles.row]}>
          <Text
            style={[
              styles.mediumColumn,
              styles.noBorderRight,
              styles.emphasizedText,
              styles.fieldName,
            ]}>
            ACC Oil Code:
          </Text>
          <Text style={{ ...styles.bigColumn }}>{accOilCode}</Text>
        </View>

        <View style={[styles.row, styles.lastRow]}>
          <Text
            style={[
              styles.mediumColumn,
              styles.noBorderRight,
              styles.emphasizedText,
              styles.fieldName,
            ]}>
            Formulation/Stand Code:
          </Text>
          <Text style={{ ...styles.bigColumn }}>{formulationCode}</Text>
        </View>
      </View>
    </View>
  );
};

type LubricantSectionProps = {
  midasNumber: string;
  sampleDescription: string;
  isParticularHazardousSubstance: boolean;
  sampleViscosityGrade: string;
  sampleSize: string;
  actualContainerSize: string;
  unusedReturnLocation: string;
  usedReturnLocation: string;
  qtySavedUsedOil: string;
};

const LubricantSample = ({
  midasNumber,
  sampleDescription,
  isParticularHazardousSubstance,
  sampleViscosityGrade,
  sampleSize,
  actualContainerSize,
  unusedReturnLocation,
  usedReturnLocation,
  qtySavedUsedOil,
}: LubricantSectionProps) => {
  return (
    <View style={styles.section}>
      <Text style={styles.regularSectionTitle}>Lubricant Sample</Text>
      <View style={styles.table}>
        <View style={[styles.row]}>
          <Text
            style={[
              styles.oneQuarterColumn,
              styles.noBorderRight,
              styles.emphasizedText,
              styles.fieldName,
            ]}>
            MIDAS Number:
          </Text>
          <Text style={{ ...styles.threeQuarterColumn }}>{midasNumber}</Text>
        </View>
        <View style={[styles.row]}>
          <Text
            style={[
              styles.oneQuarterColumn,
              styles.noBorderRight,
              styles.emphasizedText,
              styles.fieldName,
            ]}>
            Sample Description:
          </Text>
          <Text style={{ ...styles.threeQuarterColumn }}>{sampleDescription}</Text>
        </View>
        <View style={[styles.row]}>
          <Text
            style={[
              styles.oneQuarterColumn,
              styles.noBorderRight,
              styles.emphasizedText,
              styles.fieldName,
            ]}>
            PHS?:
          </Text>
          <Text style={{ ...styles.oneQuarterColumn }}>
            {isParticularHazardousSubstance ? "Yes" : "No"}
          </Text>
          <Text style={[styles.oneQuarterColumn, styles.noLeftOrRightBorder, styles.fieldName]}>
            Viscosity:
          </Text>
          <Text style={{ ...styles.oneQuarterColumn }}>{sampleViscosityGrade}</Text>
        </View>
        <View style={[styles.row]}>
          <Text style={[styles.oneQuarterColumn, styles.noBorderRight, styles.fieldName]}>
            Sample Size:
          </Text>
          <Text style={{ ...styles.oneQuarterColumn }}>{sampleSize}</Text>
          <Text style={[styles.oneQuarterColumn, styles.noLeftOrRightBorder, styles.fieldName]}>
            Actual Container Size:
          </Text>
          <Text style={{ ...styles.oneQuarterColumn }}>{actualContainerSize}</Text>{" "}
        </View>
        <View style={[styles.row]}>
          <Text
            style={[
              styles.oneQuarterColumn,
              styles.emphasizedText,
              styles.noBorderRight,
              styles.fieldName,
            ]}>
            Unused Disposition:
          </Text>
          <Text style={{ ...styles.oneQuarterColumn }}>{unusedReturnLocation}</Text>
          <Text
            style={[
              styles.oneQuarterColumn,
              styles.noLeftOrRightBorder,
              styles.emphasizedText,
              styles.fieldName,
            ]}>
            Used Disposition:
          </Text>
          <Text style={{ ...styles.oneQuarterColumn }}>{usedReturnLocation}</Text>
        </View>
        <View style={[styles.row, styles.lastRow]}>
          <Text
            style={[
              styles.oneQuarterColumn,
              styles.noBorderRight,
              styles.emphasizedText,
              styles.fieldName,
            ]}>
            Qty Saved Used Oil:
          </Text>
          <Text style={{ ...styles.threeQuarterColumn }}>{qtySavedUsedOil}</Text>
        </View>
      </View>
    </View>
  );
};

const FuelSpecifications = ({ stationaryTest }: SectionProps) => {
  const { fuelType = "", fuelBatch = "", fuelTankLine = "", ionNumber = "" } = stationaryTest;

  return (
    <View style={styles.section} wrap={false}>
      <Text style={styles.regularSectionTitle}>Fuel Specifications</Text>
      <View style={styles.table}>
        <View style={[styles.row]}>
          <Text style={[{ ...styles.oneQuarterColumn, borderRight: "none" }, styles.fieldName]}>
            Fuel Type:
          </Text>
          <Text style={{ ...styles.threeQuarterColumn }}>{fuelType}</Text>
        </View>
        <View style={[styles.row]}>
          <Text style={[{ ...styles.oneQuarterColumn, borderRight: "none" }, styles.fieldName]}>
            Fuel Batch:
          </Text>
          <Text style={{ ...styles.threeQuarterColumn }}>{fuelBatch} </Text>
        </View>

        <View style={[styles.row]}>
          <Text style={[{ ...styles.oneQuarterColumn, borderRight: "none" }, styles.fieldName]}>
            Fuel Tank/Line:
          </Text>
          <Text style={{ ...styles.threeQuarterColumn }}>{fuelTankLine}</Text>
        </View>
        <View style={[styles.row, styles.lastRow]}>
          <Text style={[{ ...styles.oneQuarterColumn, borderRight: "none" }, styles.fieldName]}>
            ION#:
          </Text>
          <Text style={{ ...styles.threeQuarterColumn }}>{ionNumber}</Text>
        </View>
      </View>
    </View>
  );
};
const FuelUsed = ({ stationaryTest }: SectionProps) => {
  const { fuelSamples = [] } = stationaryTest;
  const { isParticularHazardousSubstance = false } = { ...stationaryTest.generalInformation };

  if (fuelSamples === null || fuelSamples.length === 0) return null;

  return (
    <View style={styles.section}>
      <Text style={styles.regularSectionTitle} wrap={false}>
        Fuel Used
      </Text>
      {fuelSamples.map((fs, i) => {
        const { sampleName = "", returnLocationName = "" } = fs;
        const description = fs.sample?.description ?? "";

        return (
          <View style={styles.table} key={sampleName + i}>
            <View style={[styles.row]}>
              <Text style={[{ ...styles.mediumColumn, borderRight: "none" }, styles.fieldName]}>
                MIDAS Number:
              </Text>
              <Text style={{ ...styles.bigColumn }}>{sampleName}</Text>
            </View>
            <View style={[styles.row]}>
              <Text style={[{ ...styles.mediumColumn, borderRight: "none" }, styles.fieldName]}>
                Sample Description:
              </Text>
              <Text style={{ ...styles.bigColumn }}>{description}</Text>
            </View>
            <View style={[styles.row, styles.lastRow]}>
              <Text
                style={[
                  styles.oneQuarterColumn,
                  styles.noBorderRight,
                  styles.emphasizedText,
                  styles.fieldName,
                ]}>
                PHS?:
              </Text>
              <Text style={{ ...styles.oneQuarterColumn }}>
                {isParticularHazardousSubstance ? "Yes" : "No"}
              </Text>
              <Text
                style={[
                  styles.oneQuarterColumn,
                  styles.noLeftOrRightBorder,
                  styles.emphasizedText,
                  styles.fieldName,
                ]}>
                Unused Disposition:
              </Text>
              <Text style={{ ...styles.oneQuarterColumn }}>{returnLocationName}</Text>
            </View>
          </View>
        );
      })}
    </View>
  );
};

const TestParameters = ({ stationaryTest }: SectionProps) => {
  const { requestParameters: originalParameterList = [] } = { ...stationaryTest.testSpecification };
  const parameters = [...originalParameterList];

  let length = parameters.length;
  const isOdd = length % 2 > 0;
  let lastParameter = undefined;

  if (isOdd) {
    lastParameter = parameters.pop();
    length--;
  }

  let midpoint = length / 2;
  const leftColumnParameters = parameters.slice(0, midpoint);
  const rightColumnParameters = parameters.slice(midpoint);

  return (
    <View style={styles.section} wrap={false}>
      <Text style={styles.regularSectionTitle}>Test Parameters</Text>
      {originalParameterList && originalParameterList.length > 0 ? (
        <View style={[styles.table, styles.borderedBottomView]}>
          {leftColumnParameters.map((leftParameter, i) => {
            const rightParameter = rightColumnParameters[i];

            return (
              <View key={leftParameter.testParameterName + i} style={[styles.row]}>
                <Text style={[styles.oneQuarterColumn, styles.noBorderRight, styles.fieldName]}>
                  {leftParameter.testParameterName + ":"}
                </Text>
                <Text style={[styles.oneQuarterColumn]}>{leftParameter.parameterValue}</Text>
                <Text
                  style={[styles.oneQuarterColumn, styles.noLeftOrRightBorder, styles.fieldName]}>
                  {rightParameter.testParameterName + ":"}
                </Text>
                <Text style={[styles.oneQuarterColumn]}>{rightParameter.parameterValue}</Text>
              </View>
            );
          })}
          {lastParameter && (
            <View style={[styles.row]}>
              <Text style={[styles.oneQuarterColumn, styles.noBorderRight, styles.fieldName]}>
                {lastParameter.testParameterName + ":"}
              </Text>
              <Text style={[styles.oneQuarterColumn]}>{lastParameter.parameterValue}</Text>
              <Text style={[styles.halfColumn, styles.noBorderLeft]} />
            </View>
          )}
        </View>
      ) : (
        <Text>None</Text>
      )}
    </View>
  );
};

const SubmitterComments = ({ stationaryTest }: SectionProps) => {
  return (
    <View style={styles.section} wrap={false}>
      <Text style={styles.emphasizedSectionTitle}>Submitter Comments</Text>
      <Text style={styles.emphasizedText}>{stationaryTest.submitterComments}</Text>
    </View>
  );
};

const AdditionalInstructions = ({ stationaryTest }: SectionProps) => {
  return (
    <View style={styles.section} wrap={false}>
      <Text style={styles.emphasizedSectionTitle}>Additional Instructions</Text>
      <Text style={styles.emphasizedText}>
        {stationaryTest.testSpecification?.technicianComments}
      </Text>
    </View>
  );
};

const AuthorizationValidation = ({ stationaryTest }: SectionProps) => {
  const {
    testEngineerEmail = "",
    testStandName = "",
    testEndedByEmail = "",
    testStartDate,
    testStartedByEmail = "",
    eotCellHourmeter = "",
    testEndDate,
    validationStatusName = "",
    validatedByEmail = "",
    validationDate,
    cellCalibrationExpiration,
  } = stationaryTest;
  const { requestedByEmail = "", createdDate } = { ...stationaryTest.generalInformation };

  const localCellCalibrationExpirationDate = cellCalibrationExpiration
    ? new Date(cellCalibrationExpiration).toLocaleDateString()
    : "";
  const localStandPalletCalibrationExpirationDate = "";
  const eotEngineHourmeter = "";

  const localDateRequested = createdDate ? new Date(createdDate).toLocaleDateString() : "";
  const localTestStartDateTime = testStartDate ? new Date(testStartDate).toLocaleString() : "";
  const localTestEndDateTime = testEndDate ? new Date(testEndDate).toLocaleString() : "";
  const localValidationDate = validationDate ? new Date(validationDate).toLocaleDateString() : "";

  return (
    <View style={styles.section} wrap={false}>
      <Text style={styles.emphasizedSectionTitle}>Authorization/Validation</Text>
      <View style={styles.table}>
        <View style={[styles.row]}>
          <Text
            style={[
              styles.oneQuarterColumn,
              styles.noBorderRight,
              styles.fieldName,
              styles.emphasizedText,
            ]}>
            Requester Name:
          </Text>
          <Text style={[styles.oneQuarterColumn, styles.emphasizedText]}>{requestedByEmail}</Text>
          <Text
            style={[
              styles.oneQuarterColumn,
              styles.noLeftOrRightBorder,
              styles.emphasizedText,
              styles.fieldName,
            ]}>
            Date Requested:
          </Text>
          <Text style={[styles.oneQuarterColumn, styles.emphasizedText]}>{localDateRequested}</Text>
        </View>
        <View style={[styles.row, styles.fieldName]}>
          <Text
            style={[
              styles.oneQuarterColumn,
              styles.noBorderRight,
              styles.emphasizedText,
              styles.fieldName,
            ]}>
            Test Engineer:
          </Text>
          <Text style={[styles.oneQuarterColumn, styles.emphasizedText]}>{testEngineerEmail}</Text>
          <Text
            style={[
              styles.oneQuarterColumn,
              styles.noLeftOrRightBorder,
              styles.emphasizedText,
              styles.fieldName,
            ]}>
            Cell/Penthouse Cal. Exp. Date:
          </Text>
          <Text style={[styles.oneQuarterColumn, styles.emphasizedText]}>
            {localCellCalibrationExpirationDate}
          </Text>
        </View>
        <View style={[styles.row]}>
          <Text
            style={[
              styles.oneQuarterColumn,
              styles.noBorderRight,
              styles.emphasizedText,
              styles.fieldName,
            ]}>
            Test Stand Number:
          </Text>
          <Text style={[styles.oneQuarterColumn, styles.emphasizedText]}>{testStandName}</Text>
          <Text
            style={[
              styles.oneQuarterColumn,
              styles.noLeftOrRightBorder,
              styles.emphasizedText,
              styles.fieldName,
            ]}>
            Stand/Pallet Cal. Exp. Date:
          </Text>
          <Text style={[styles.oneQuarterColumn, styles.emphasizedText]}>
            {localStandPalletCalibrationExpirationDate}
          </Text>
        </View>
        <View style={[styles.row]}>
          <Text
            style={[
              styles.oneQuarterColumn,
              styles.noBorderRight,
              styles.emphasizedText,
              styles.fieldName,
            ]}>
            Test Started By:
          </Text>
          <Text style={[styles.oneQuarterColumn, styles.emphasizedText]}>{testStartedByEmail}</Text>
          <Text
            style={[
              styles.oneQuarterColumn,
              styles.noLeftOrRightBorder,
              styles.emphasizedText,
              styles.fieldName,
            ]}>
            Date/Time Test Started:
          </Text>
          <Text style={[styles.oneQuarterColumn, styles.emphasizedText]}>
            {localTestStartDateTime}
          </Text>
        </View>
        <View style={[styles.row]}>
          <Text
            style={[
              styles.oneQuarterColumn,
              styles.noBorderRight,
              styles.emphasizedText,
              styles.fieldName,
            ]}>
            Test Stopped By:
          </Text>
          <Text style={[styles.oneQuarterColumn, styles.emphasizedText]}>{testEndedByEmail}</Text>
          <Text
            style={[
              styles.oneQuarterColumn,
              styles.noLeftOrRightBorder,
              styles.emphasizedText,
              styles.fieldName,
            ]}>
            Date/Time Test Stopped:
          </Text>
          <Text style={[styles.oneQuarterColumn, styles.emphasizedText]}>
            {localTestEndDateTime}
          </Text>
        </View>
        <View style={[styles.row]}>
          <Text
            style={[
              styles.oneQuarterColumn,
              styles.noBorderRight,
              styles.emphasizedText,
              styles.fieldName,
            ]}>
            EOT Engine Hourmeter:
          </Text>
          <Text style={[styles.oneQuarterColumn, styles.emphasizedText]}>{eotEngineHourmeter}</Text>
          <Text
            style={[
              styles.oneQuarterColumn,
              styles.noLeftOrRightBorder,
              styles.emphasizedText,
              styles.fieldName,
            ]}>
            EOT Cell Hourmeter:
          </Text>
          <Text style={[styles.oneQuarterColumn, styles.emphasizedText]}>{eotCellHourmeter}</Text>
        </View>
        <View style={[styles.row]}>
          <Text
            style={[
              styles.oneQuarterColumn,
              styles.noBorderRight,
              styles.emphasizedText,
              styles.fieldName,
            ]}>
            Validation Status:
          </Text>
          <Text style={[styles.oneQuarterColumn, styles.emphasizedText]}>
            {validationStatusName}
          </Text>
          <Text
            style={[
              styles.oneQuarterColumn,
              styles.noLeftOrRightBorder,
              styles.emphasizedText,
              styles.fieldName,
            ]}>
            Validation By:
          </Text>
          <Text style={[styles.oneQuarterColumn, styles.emphasizedText]}>{validatedByEmail}</Text>
        </View>
        <View style={[styles.row, styles.lastRow]}>
          <Text
            style={[
              styles.oneQuarterColumn,
              styles.noBorderRight,
              styles.emphasizedText,
              styles.fieldName,
            ]}>
            Validation Date:
          </Text>
          <Text style={[styles.threeQuarterColumn, styles.emphasizedText]}>
            {localValidationDate}
          </Text>
        </View>
      </View>
    </View>
  );
};
