import API from "..";
export default class MethodFacility {
    /**
     * The Container dimension table for M3
     * @param TODO
     */
    constructor({
      id,
      methodName,
      method,
      testFacilityAbv,
      testFacility,
      containeringGroup,
      legacyTableID,
      requiresEstimates,
      testOwnerEmail,
      testCostUSD,
      requiredSampleSize,
      requiredSampleSizeUoM,
      requiresConditions,
      requiresSeparateContainer,
      messageForSubmitter,
      allowPrioritization,
      targetTurnaroundInDays,
      dropboxLocation,
      isSQCMethod,
      methodStatusName,
      allowUserDefinedConditions,
      testCondtionOfferings, 
      methodFacilityResultComponents
    }) {
      this.id = id;
      this.methodName = methodName;
      this.testFacilityAbv = testFacilityAbv;
      this.testFacility = testFacility;
      this.containeringGroup = containeringGroup;
      this.legacyTableID = legacyTableID;
      this.requiresEstimates = requiresEstimates;
      this.testOwnerEmail = testOwnerEmail;
      this.testCostUSD = testCostUSD;
      this.requiredSampleSize = requiredSampleSize;
      this.requiredSampleSizeUoM = requiredSampleSizeUoM;
      this.requiresConditions = requiresConditions;
      this.requiresSeparateContainer = requiresSeparateContainer;
      this.messageForSubmitter = messageForSubmitter;
      this.allowPrioritization = allowPrioritization;
      this.targetTurnaroundInDays = targetTurnaroundInDays;
      this.dropboxLocation = dropboxLocation;
      this.isSQCMethod = isSQCMethod;
      this.method = method;
      this.methodStatusName = methodStatusName;
      this.allowUserDefinedConditions = allowUserDefinedConditions;
      this.testCondtionOfferings = testCondtionOfferings;
      this.methodFacilityResultComponents = methodFacilityResultComponents;
    }


    static async getFacilitiesByMethodName(methodName) {
      const api = await API();
      const { data } = await api.get(`/methodFacility/?methodName=` + methodName);
      return data.result.map((d) => new MethodFacility(d));
    }

    static async getAll() {
      const api = await API();
      const { data } = await api.get(`/methodFacility`);
      return data.result.map((d) => new MethodFacility(d));
  }

  static async get(id) {
      const api = await API();
      const { data } = await api.get(`methodFacility/${id}`);
      if (data.result) {
          return new MethodFacility(data.result);
      }
      else {
          return null;
      }

  }

  static async add(info) {
      const api = await API();
      const { data } = await api.post(`/methodFacility`, info);
      return new MethodFacility(data.result);
  }

  static async update(id, info) {
      const api = await API();
      const { data } = await api.put(`/methodFacility/${id}`, info);
      return new MethodFacility(data.result);
  }

}