import API from "..";

export default class ContainerType {
  /**
   * The Container dimension table for M3
   * @param TODO
   */
  constructor({
    name,
    isActive,
    defaultContainerSize,
    defaultContainerUoMName, 
    sizeOrder

  }) {
    this.name = name;
    this.isActive = isActive;
    this.defaultContainerSize = defaultContainerSize;
    this.defaultContainerUoMName = defaultContainerUoMName;
    this.sizeOrder = sizeOrder;
  }

  static async getAll() {
    const api = await API();
    const { data } = await api.get(`/containerType`);
    return data.result.map((d) => new ContainerType(d));
  }
}
