import API from "..";

export default class Template {

  constructor({
    templateOwnerEmail,
    templateName,
    templateJSON,
    templateType,
    isPublic,
    createdDate,
    lastModifiedDate,
    messages}) 
    
  {
    this.templateOwnerEmail = templateOwnerEmail;
    this.templateName = templateName;
    this.templateJSON = templateJSON;
    this.templateType = templateType;
    this.isPublic = isPublic;
    this.createdDate = createdDate;
    this.lastModifiedDate = lastModifiedDate;
    this.messages = messages;
  }

  static async getAllTemplates() {
    const api = await API();
    const { data } = await api.get(`/template`);
    return data.result.map((d) => new Template(d));
  }

  static async searchTemplates(userEmail, templateName, templateType, includePublic) {
    const api = await API();
    const { data } = await api.get(`template/search?userEmail=${userEmail}&templateName=${templateName}&templateType=${templateType}&includePublic=${includePublic ? includePublic : 'false'}`);

    if (data.result) {
      return data.result.map((d) => new Template(d));
    }
    else {
      return [];
    }
  }

  static async add(template) {
    const api = await API();
    const { data } = await api.post(`/template`, template);
    return data;
  }

  static async update(template) {
    const api = await API();
    const { data } = await api.put(`/template`, template);
    return data;
  }

  static async delete(userEmail, templateName, templateType) {
    const api = await API();
    const { data } = await api.delete(`/template?userEmail=${userEmail}&templateName=${templateName}&templateType=${templateType}`);
    return data;
  }
}
