import styled from "@mui/material/styles/styled";
import Box from "@mui/material/Box";
import {
  REQUEST_INFORMATION_ENUM,
  REQUEST_INFORMATION_OPTIONS,
} from "../../shared";
import {
  SideInformationProps,
  useSideImportantInformation,
} from "../../context/SideImportantInformationContext";

const Option = styled(Box)({
  padding: "0.4rem",
  borderLeft: "1px solid #cccccd",
});

type DetailedNavigationViewProps = {
  activeOptionName: REQUEST_INFORMATION_ENUM;
  setActiveOptionName(newActiveOptionName: REQUEST_INFORMATION_ENUM): void;
};

export default function DetailedNavigationView({
  activeOptionName,
  setActiveOptionName,
}: DetailedNavigationViewProps) {
  const { sideImportantInformation, setSideImportantInformation } =
    useSideImportantInformation();

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <span
        style={{
          textAlign: "left",
          font: "normal normal 600 10px/13px EMprint",
          letterSpacing: "0.01px",
          color: "#00000099",
        }}
      >
        REQUEST INFORMATION
      </span>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          marginTop: "1rem",
        }}
      >
        {REQUEST_INFORMATION_OPTIONS.map((vo) => (
          <Option
            key={vo.label}
            style={{
              borderLeftWidth: activeOptionName === vo.label ? "4px" : "1px",
            }}
            onClick={() => {
              if (activeOptionName !== vo.label) {
                setActiveOptionName(vo.label);
                setSideImportantInformation([]);
              }
            }}
          >
            <span
              style={{
                color: activeOptionName !== vo.label ? "#3190d9" : undefined,
                textDecoration:
                  activeOptionName !== vo.label ? "underline" : undefined,
                cursor: activeOptionName !== vo.label ? "pointer" : undefined,
              }}
            >
              {vo.label}
            </span>
          </Option>
        ))}
      </div>

      {activeOptionName && !!sideImportantInformation.length && (
        <div style={{ position: "sticky", top: 0, marginTop: "15px" }}>
          <span
            style={{
              textAlign: "left",
              font: "normal normal 600 10px/13px EMprint",
              letterSpacing: "0.01px",
              color: "#00000099",
            }}
          >
            {activeOptionName.toLocaleUpperCase()} IMPORTANT INFORMATION
          </span>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginTop: "1rem",
            }}
          >
            {sideImportantInformation.map((info: SideInformationProps) => (
              <div
                style={{
                  borderLeft: "1px solid #cccccd",
                  padding: "0.2rem  0.4rem",
                }}
              >
                <span
                  style={{
                    font: "normal normal 600 11px/14px EMprint",
                    // color: "#00000099",
                  }}
                >
                  {info.label}: {info.value ?? "-"}
                </span>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}
