import API from "../../api";
export default class Instrument {
  /**
   * The Instrument dimension table for M3
   * @param {int} ID the table ID for the Instrument
   * @param {string} Name The standard name for the Instrument
   * @param {string} Description
   * @param {string} CreatedByEmail
   * @param {DateTime} CreatedDate
   * @param {string} LastModifiedByEmail
   * @param {DateTime} LastModifiedDate
   * @param {bool} IsActive
   * @param {bool} IsConnected Is the Instrument currently connected to a computer
   * @param {string} etqid The name/ID given to the Instrument in ETQ
   * @param {bool} IsLockedForUse Is the Instrument currently in use by a user
   * @param {string} LocationName Validated Location for where the Instrument is connected
   * @param {string} ComPort
   * @param {int?} BaudRate
   * @param {string} Parity
   * @param {byte?} StopBits
   * @param {byte?} DataBits
   * @param {string} Handshake
   * @param {bool} IsDTREnabled
   * @param {bool} IsRTSEnabled
   */
  constructor({
    id,
    name,
    description,
    createdByEmail,
    createdDate,
    lastModifiedByEmail,
    lastModifiedDate,
    isActive,
    isConnected,
    etqid,
    isLockedForUse,
    locationName,
    comPort,
    baudRate,
    parity,
    stopbits,
    dataBits,
    handshake,
    isDTREnabled,
    isRTSEnabled,
  }) {
    this.id = id;
    this.name = name;
    this.description = description;
    this.createdByEmail = createdByEmail;
    this.createdDate = createdDate;
    this.lastModifiedByEmail = lastModifiedByEmail;
    this.lastModifiedDate = lastModifiedDate;
    this.isActive = isActive;
    this.isConnected = isConnected;
    this.etqid = etqid;
    this.isLockedForUse = isLockedForUse;
    this.locationName = locationName;
    this.comPort = comPort;
    this.baudRate = baudRate;
    this.parity = parity;
    this.stopBits = stopbits;
    this.dataBits = dataBits;
    this.handshake = handshake;
    this.isDTREnabled = isDTREnabled;
    this.isRTSEnabled = isRTSEnabled;
  }

  static async getAll() {
    const api = await API();
    const { data } = await api.get(`/instrument`);
    return data.result.map((d) => new Instrument(d));
  }

  static async getByID(id) {
    const api = await API();
    const { data } = await api.get(`/instrument/?id=${id}`);
    return data.result.map((d) => new Instrument(d));
  }

  static async getByName(name) {
    const api = await API();
    const { data } = await api.get(`/instrument/?name=${name}`);
    return data.result.map((d) => new Instrument(d));
  }

  static async getByLocation(locationName) {
    const api = await API();
    const { data } = await api.get(`/instrument/?location=${locationName}`);
    return data.result.map((d) => new Instrument(d));
  }

  static async getByETQ(etqid) {
    const api = await API();
    const { data } = await api.get(`/instrument/?etqId=${etqid}`);
    return data.result.map((d) => new Instrument(d));
  }

  static async add(info) {
    const api = await API();
    const { data } = await api.post(`/instrument`, info);
    return new Instrument(data.result);
  }

  static async update(id, info) {
    const api = await API();
    const { data } = await api.put(`/instrument/${id}`, info);
    return new Instrument(data.result);
  }

  static async delete(id) {
    const api = await API();
    const { data } = await api.delete(`/instrument/${id}`);
    return data;
  }
}
