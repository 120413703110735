import API from '../index'

export default class STStatus {
  constructor({
    statusName,
    isActive,
    displayOrder,
    displayColor
}) {
    this.statusName = statusName
    this.isActive = isActive
    this.displayOrder = displayOrder
    this.displayColor = displayColor
}

  static async getAllStatuses() {
    const api = await API();
    const { data } = await api.get('/ststatus');
    return data.result.map((status) => new STStatus(status));
  }

  static async get(name) {
    const api = await API();
    const { data } = await api.get(`ststatus/${name}`);
    if (data.result) {
      return new STStatus(data.result);
    }
    else {
      return null;
    }
  }

  static async add(payload) {
    const api = await API();
    const { data } = await api.post('/ststatus', payload);
    return new STStatus(data.result);
  }

  static async update(payload) {
    const api = await API();
    const { data } = await api.put('/ststatus', payload);
    return new STStatus(data.result);
  }

  static async getAllActiveStatusNamesExceptCancelled() {
    const api = await API();
    const { data } = await api.get('/ststatus');
    const statusNames = [];
    if (data && data.result && data.result.length > 0) {
      data.result.forEach((status) => {
        if (status.isActive && status.statusName !== "Cancelled") {
          statusNames.push(status.statusName);
        }
      })
    }
    return statusNames;
  }

  static async getAllActiveStatusNamesOrdered() {
    const api = await API();
    const { data } = await api.get('/ststatus');
    if (data && data.result && data.result.length > 0) {
      const activeStatus = data.result.filter((status) => status.isActive);

      const orderedData = activeStatus.sort((a, b) => a?.displayOrder - b?.displayOrder);

      return orderedData.map((s) => s.statusName);
    }
    return [];
  }
}
