export const SERIAL_PORT_CONNECTION_STATE = {
  Disconnected: "disconnected",
  Connecting: "connecting",
  Connected: "connected",
  Open: "open",
} as const;

export type SerialPortDevice = {
  name: string;
  filter: SerialPortFilter;
};

export type SerialPortConnectionState =
  (typeof SERIAL_PORT_CONNECTION_STATE)[keyof typeof SERIAL_PORT_CONNECTION_STATE];

type SerialPortValueMessage = {
  type: "value";
  value: Uint8Array;
};

type SerialPortStateMessage = {
  type: "status";
  state: SerialPortConnectionState;
};

export type SerialPortMessage = {
  timestamp: number;
} & (SerialPortValueMessage | SerialPortStateMessage);

export type SerialPortMessageCallback = (message: SerialPortMessage) => void;
