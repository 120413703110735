import React from "react";
import { Link, useLocation } from "react-router-dom";
import "./index.css";
import { Drawer, Toolbar, List, Divider, ListItem, ListItemText } from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import RoomServiceIcon from "@mui/icons-material/RoomService";
import InventoryIcon from "../../assets/inventoryicon.svg";
import SamplesIcon from "../../assets/samplesicon.svg";
import TestsIcon from "../../assets/chemistry.svg";
//import AnalyticalIcon from "../../assets/analytical.svg";
import BiotechIcon from '@mui/icons-material/Biotech';
import BlendsIcon from "../../assets/blendsicon.svg";
import DistillationsIcon from "../../assets/distillationsicon.svg";
import SQCIcon from "../../assets/sqcicon.svg";
import StationaryTestingIcon from "../../assets/stationarytestingicon.svg";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import UtilitiesIcon from "../../assets/utilitiesicon.svg";
import AccountBalanceOutlinedIcon from "@mui/icons-material/AccountBalance";

/**
 *
 * The main layout of the application. Uses a ThemeProvider to toggle
 * between a light and dark theme, and a React Unity Page component to set the layout of the page.
 *
 * To learn more about the ThemeProvider in React Unity, see: https://reactunity.azurewebsites.net/#/Theme?id=themeprovider
 *
 * To learn more about the Page and other possible layouts in React Unity, see: https://reactunity.azurewebsites.net/#/Layouts
 *
 */

const Navbar = ({ children, ...props }) => {
  const urlPathName = useLocation().pathname;

  const navBarListArray = [
    {
      name: "Home",
      link: "/",
      icon: <HomeIcon />,
      isCustomIcon: false,
      hasDivider: false,
    },
    {
      name: "Inventory",
      link: "/inventory",
      icon: InventoryIcon,
      isCustomIcon: true,
      hasDivider: false,
    },
    {
      name: "Samples",
      link: "/samples",
      icon: SamplesIcon,
      isCustomIcon: true,
      hasDivider: false,
    },
    {
      name: "Tests",
      link: "/tests",
      icon: TestsIcon,
      isCustomIcon: true,
      hasDivider: false,
    },
    {
      name: "Analytical Services",
      link: "/analytical",
      icon: <BiotechIcon fontSize="large"/>,
      isCustomIcon: false,
      hasDivider: false,
    },
    {
      name: "SQC",
      link: "/sqc",
      icon: SQCIcon,
      isCustomIcon: true,
      hasDivider: false,
    },
    {
      name: "Requests",
      link: "/workRequests",
      icon: <RoomServiceIcon />,
      isCustomIcon: false,
      hasDivider: false,
    },
    {
      name: "Blends",
      link: "/blends",
      icon: BlendsIcon,
      isCustomIcon: true,
      hasDivider: false,
    },
    {
      name: "Distillations",
      link: "/distillations",
      icon: DistillationsIcon,
      isCustomIcon: true,
      hasDivider: false,
    },
    {
      name: "Stationary Testing",
      link: "/stationarytesting",
      icon: StationaryTestingIcon,
      isCustomIcon: true,
      hasDivider: false,
    },
    {
      name: "Stockroom",
      link: "/stockroom",
      icon: <AccountBalanceOutlinedIcon fontSize="large"/>,
      isCustomIcon: false,
      hasDivider: false,
    },
    {
      name: "Utilities",
      link: "/utilities",
      icon: UtilitiesIcon,
      isCustomIcon: true,
      hasDivider: false,
    },
    {
      name: "Admin",
      link: "/admin",
      icon: <AdminPanelSettingsIcon />,
      isCustomIcon: false,
      hasDivider: true,
    },
    {
      name: "Bookmarks",
      link: "/bookmarks",
      icon: <BookmarkIcon />,
      isCustomIcon: false,
      hasDivider: false,
    }
  ];

  return (
    <Drawer className='navbar' variant='permanent'>
      <Toolbar />
      <List className='lightText'>
        {navBarListArray.map((navBarListItem, index) => (
          <div key={index}>
            {navBarListItem.hasDivider && <Divider />}
            <Link to={navBarListItem.link} className='lightText'>
              <ListItem className='lightText' button selected={urlPathName === navBarListItem.link}>
                <ListItemText
                  disableTypography
                  primary={
                    navBarListItem.isCustomIcon ? (
                      <img src={navBarListItem.icon} alt='icon' />
                    ) : (
                      navBarListItem.icon
                    )
                  }
                  secondary={<div>{navBarListItem.name}</div>}
                />
              </ListItem>
            </Link>
          </div>
        ))}
      </List>
    </Drawer>
  );
};

export default Navbar;
