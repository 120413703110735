import API from '../index'

export default class STProcedure {
    constructor(
        ProcedureNumber,
        ProcedureName,
        IsActive,
        CreatedByEmail,
        CreatedDate,
        LastModifiedByEmail,
        LastModifiedDate,
        WorkInstructionName,
        Description,
        ClassificationName,
        ProcedureClassification,
        IntervalResultOptionName,
        ResultOption,
        AllowMultipleLubricants,
        RequireFuelSample,
        ShowViscosity,
        AllowMultipleFuelSamples,
        AllowTestPreparationStep,
        AvailableEngineers,
        ProcedureTests
    ) {
        this.ProcedureNumber = ProcedureNumber
        this.ProcedureName = ProcedureName
        this.IsActive = IsActive
        this.CreatedByEmail = CreatedByEmail
        this.CreatedDate = CreatedDate
        this.LastModifiedByEmail = LastModifiedByEmail
        this.LastModifiedDate = LastModifiedDate
        this.WorkInstructionName = WorkInstructionName
        this.Description = Description
        this.ClassificationName = ClassificationName
        this.ProcedureClassification = ProcedureClassification
        this.IntervalResultOptionName = IntervalResultOptionName
        this.ResultOption = ResultOption
        this.AllowMultipleLubricants = AllowMultipleLubricants
        this.RequireFuelSample = RequireFuelSample
        this.ShowViscosity = ShowViscosity
        this.AllowMultipleFuelSamples = AllowMultipleFuelSamples
        this.AllowTestPreparationStep = AllowTestPreparationStep
        this.AvailableEngineers = AvailableEngineers
        this.ProcedureTests = ProcedureTests
    }

    static async getAll() {
        const api = await API()
        const { data } = await api.get('/stprocedure')
        return data.result && data.result.length > 0 ? data.result : [];
    }
    
    static async getProcedureByNumber(procedureNumber) {
        const api = await API()
        const { data } = await api.get(`/stprocedure/${procedureNumber}`)
        return new STProcedure(data.result)
    }

    static async getProcedureByNumberRaw(procedureNumber, useRawParameterValue = false) {
        const api = await API()
        const { data } = await api.get(`/stprocedure/${procedureNumber}`, {
            params: {
                useRawParameterValue,
            },
          })
        return data.result ?? null;
    }

    static async createProcedure(payload) {
        const api = await API();
        const { data } = await api.post('/stprocedure', payload);
        return data ?? null;
    }

    static async updateProcedure(procedure) {
        const api = await API();
        const { data } = await api.put('/stprocedure', procedure);
        return data ?? null;
    }
}
