import React, { useContext, useState } from "react";
import {
  Box,
  TextField} from '@mui/material'
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { StockroomRequestContext } from "../context/StockroomRequestContext";
import {
  StyledTableBody,
  StyledTableRow,
  UXDataTableWithoutBody,
} from "../../../components/UXDataTable";
import { StyledTableCellSmall } from "../styles";
import { DatePickerKeyDownEvent } from "../../../global";
import { GlobalButton } from "../../styles";

const buildPOCols = [
  "Stock #",
  "Description",
  "Action Date",
  "Action Type",
  "Action By",
  "Action"
];

export const StkTrackerReport = ({ canEdit }) => {
  const [stockRoomNumber, setStockRoomNumber] = useState('')

  const day = new Date().getDate()
  const month = new Date().getMonth()
  const year = new Date().getFullYear()

  const [dateFrom, setDateFrom] = useState(null)
  const [dateTo, setDateTo] = useState(new Date(year, month, day))
  const { getStockroomReports } = useContext(StockroomRequestContext);
  const [chargesReportRowsPerPage, setChargesReportRowsPerPage] = useState(10);
  const [chargesReportPage, setChargesReportPage] = useState(0);
  const [chargesReport, setChargesReport] = useState([])

  const handleCreateReport = async () => {
    const payload = {
      Report: "J",
      StockroomNo: stockRoomNumber,
      StartDate: dateFrom,
      EndDate: dateTo
    };

    let report = await getStockroomReports(payload);
    setChargesReport(report);
  };

  const handleChangeReportRowsPerPage = (event) => {
    setChargesReportRowsPerPage(parseInt(event.target.value, 10));
    setChargesReportPage(0);
  };

  const handleBuildReportPageChange = (event, page) => {
    setChargesReportPage(page);
  };

  return (
    <Box>
      <Box display="flex" gap={4} marginBottom={4}>
        <Box display="flex" alignItems="center" gap={2}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DesktopDatePicker
              disabled={!canEdit}
              label="Search From"
              maxDate={new Date()}
              value={dateFrom}
              onChange={e => setDateFrom(e)}
              renderInput={(params) => <TextField onKeyDown={DatePickerKeyDownEvent} size="small" {...params} />}
            />
            <DesktopDatePicker
              disabled={!canEdit}
              label="To"
              value={dateTo}
              minDate={dateFrom}
              maxDate={new Date()}
              onChange={e => setDateTo(e)}
              renderInput={(params) => <TextField onKeyDown={DatePickerKeyDownEvent} size="small" {...params} />}
            />
          </LocalizationProvider>
          <TextField
            disabled={!canEdit}
            onChange={e => setStockRoomNumber(e.target.value)}
            value={stockRoomNumber}
            label="Stockroom #"
            size="small"
          />
        </Box>

        <Box display="flex" alignItems="center">
          <GlobalButton
            disabled={!canEdit}
            variant="contained"
            onClick={handleCreateReport}
          >
            Create report
          </GlobalButton>
        </Box>
      </Box>
      <Box>
        <UXDataTableWithoutBody
          tableWidth="100%"
          cols={buildPOCols}
          tableId="chargesTable"
          rowLength={chargesReport?.length}
          enablePaging={true}
          rowsPerPage={chargesReportRowsPerPage}
          page={chargesReportPage}
          handleChangePage={handleBuildReportPageChange}
          handleChangeRowsPerPage={handleChangeReportRowsPerPage}
          noDataFoundMessage={"No charges found"}
        >
          <StyledTableBody>
            {chargesReport?.length > 0 &&
              chargesReport.slice(
                chargesReportPage * chargesReportRowsPerPage,
                chargesReportPage * chargesReportRowsPerPage +
                chargesReportRowsPerPage
              )
                .map((item) => {
                  return (
                    <StyledTableRow>
                      <StyledTableCellSmall>
                        {item?.stockroomNo}
                      </StyledTableCellSmall>
                      <StyledTableCellSmall>
                        {item?.description}
                      </StyledTableCellSmall>
                      <StyledTableCellSmall>
                        {item?.actionDate.split("T")[0]}
                      </StyledTableCellSmall>
                      <StyledTableCellSmall>{item?.actionType}</StyledTableCellSmall>
                      <StyledTableCellSmall>
                        {item?.actionBy.split("\\")[1]}
                      </StyledTableCellSmall>
                      <StyledTableCellSmall>
                        {item?.actionText}
                      </StyledTableCellSmall>
                    </StyledTableRow>
                  )
                })}
          </StyledTableBody>
        </UXDataTableWithoutBody>
      </Box>
    </Box>
  )
}