import API from "..";

export default class BlendStudyType {

  constructor({
    name,
    isActive,
    description
}) 
    
  {
    this.name = name;
    this.isActive = isActive;
    this.description = description;
  }

  static async getAllBlendStudyTypes() {
    const api = await API();
    const { data } = await api.get(`/blendStudyType`);
    if (data.result) {
        return data.result.map((d) => new BlendStudyType(d));
    }
    else {
        return [];
    }
  }
}
