import API from '../index'

export default class Vendor {
    constructor(
        ID,
        CreatedDate,
        CreatedByEmail,
        LastModifiedDate,
        LastModifiedByEmail,
        IsActive,
        AddressLine1,
        AddressLine2,
        BlanketPO,
        City,
        State,
        ZipCode,
        Country,
        ContactName,
        VendorName,
        MinimumOrderQuantity,
        OrderType,
        PhoneNumber,
        VendorCode,
        VendorEmail,
    ) {
        this.ID = ID
        this.CreatedDate = CreatedDate
        this.CreatedByEmail = CreatedByEmail
        this.LastModifiedDate = LastModifiedDate
        this.LastModifiedByEmail = LastModifiedByEmail
        this.IsActive = IsActive
        this.AddressLine1 = AddressLine1
        this.AddressLine2 = AddressLine2
        this.BlanketPO = BlanketPO
        this.City = City
        this.State = State
        this.ZipCode = ZipCode
        this.Country = Country
        this.ContactName = ContactName
        this.VendorName = VendorName
        this.MinimumOrderQuantity = MinimumOrderQuantity
        this.OrderType = OrderType
        this.PhoneNumber = PhoneNumber
        this.VendorCode = VendorCode
        this.VendorEmail = VendorEmail
    }

    static async getAll() {
        const api = await API()
        const { data } = await api.get('/stockroomvendor')
        return data.result.map(vendor => new Vendor(vendor))
    }

    static async getVendorById(id) {
        const api = await API();
        const { data } = await api.get(`/stockroomvendor?id=${id}`);
        return data.result.map((vendor) => new Vendor(vendor));
    }

    static async manageVendor(info) {
        const api = await API();
        const { data } = await api.post('/stockroomvendor', info);
        return new Vendor(data.result);
    }
}