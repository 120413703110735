export const fieldsConfiguration = [
  {
    type: "Container Registration",
    fields: [
      {
        name: "existingContainerID",
        required: false,
        exists: true,
      },
    ],
  },
  {
    type: "Container Registration w/ Tests",
    fields: [
      {
        name: "existingContainerID",
        required: false,
        exists: true,
      },
    ],
  },
];

export const isFieldRequired = (workRequestType, fieldName) => {
  try {
    const isRequired = fieldsConfiguration
      .find((x) => x.type === workRequestType)
      .fields.find((y) => y.name === fieldName).required;
    return isRequired;
  } catch (err) {
    return true;
  }
};
