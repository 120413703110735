import API from "..";

export default class LotCertificationStatus {
  /**
   * The Sample dimension table for M3
   * @param {String} statusName The MIDAS of the Sample
   */
  constructor({
    statusName,
    isActive,
    displayColor,
    displayOrder,
  }) {
    this.statusName = statusName;
    this.isActive = isActive;
    this.displayColor = displayColor;
    this.displayOrder = displayOrder;
  }

  static async getAll() {
    const api = await API();
    const { data } = await api.get(`/lotcertificationstatus`);

    return data.result.map((d) => new LotCertificationStatus(d));
  }
}
