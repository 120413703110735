import { useState } from "react";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/AddCircleOutline";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { requestIntervalProcessTableCols } from "../../../tableColumns";
import type { Interval, STIntervalProcessResult } from "../../../types";
import { useDetailedStRequest } from "../../../context/DetailedStRequestContext";

type IntervalRowProps = {
  interval: Interval;
  openAddStIntervalProcessResultModal(): void;
  handleEdit(intervalProcessResult: STIntervalProcessResult): void;
  handleDelete(intervalProcessResult: STIntervalProcessResult): void;
};

function IntervalRow({
  interval,
  openAddStIntervalProcessResultModal,
  handleEdit,
  handleDelete,
}: IntervalRowProps) {
  const { canManageIntervalResultDetails } = useDetailedStRequest();
  const [open, setOpen] = useState(false);

  return (
    <>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {interval.intervalName}
        </TableCell>
        <TableCell align="right">{interval.intervalSampleName}</TableCell>
        <TableCell align="right">{interval.chemID}</TableCell>
        <TableCell align="right">{interval.psimsId}</TableCell>
        <TableCell align="right">{interval.initialLocationName}</TableCell>
        <TableCell align="right">{interval.returnLocationName}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Box>
                <Typography variant="h6" gutterBottom component="div">
                  Interval Process Results
                </Typography>
                <IconButton
                  type="button"
                  aria-label="Add ST Interval Process Result"
                  onClick={openAddStIntervalProcessResultModal}
                  style={{
                    cursor: canManageIntervalResultDetails ? "pointer" : "not-allowed",
                  }}
                  disabled={!canManageIntervalResultDetails}
                >
                  <AddIcon
                    style={{ fill: canManageIntervalResultDetails ? "black" : "gray" }}
                  />
                </IconButton>
              </Box>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>Process Component Name</TableCell>
                    <TableCell align="right">Result Value</TableCell>
                    <TableCell align="right">Result UoM Name</TableCell>
                    <TableCell align="right"></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {interval.intervalProcessResults?.map(
                    (intervalProcessResult, i) => (
                      <TableRow
                        key={intervalProcessResult.processComponentName + i}
                      >
                        <TableCell component="th" scope="row">
                          {intervalProcessResult.processComponentName}
                        </TableCell>
                        <TableCell align="right">
                          {intervalProcessResult.resultValue}
                        </TableCell>
                        <TableCell align="right">
                          {intervalProcessResult.resultUoMName}
                        </TableCell>
                        <TableCell align="right">
                          <IconButton
                            type="button"
                            aria-label="Edit ST Process Result Interval"
                            onClick={() => handleEdit(intervalProcessResult)}
                            disabled={!canManageIntervalResultDetails}
                          >
                            <EditIcon />
                          </IconButton>
                          <IconButton
                            type="button"
                            aria-label="Delete ST Process Result Interval"
                            onClick={() => handleDelete(intervalProcessResult)}
                            style={{
                              cursor: "pointer",
                            }}
                            disabled={!canManageIntervalResultDetails}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    )
                  )}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

type STIntervalProcessResultTableProps = {
  intervals: Interval[];
  openAddStIntervalProcessResultModal(intervalBeingEdited: Interval): void;
  handleEdit(
    intervalBeingEdited: Interval,
    intervalProcessResult: STIntervalProcessResult
  ): void;
  handleDelete(
    intervalBeingEdited: Interval,
    intervalProcessResult: STIntervalProcessResult
  ): void;
};

export function STIntervalProcessResultTable({
  intervals,
  openAddStIntervalProcessResultModal,
  handleEdit,
  handleDelete,
}: STIntervalProcessResultTableProps) {
  const { canManageIntervalResultDetails } = useDetailedStRequest();

  return (
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell component="th" scope="row">
              Interval Name
            </TableCell>
            {requestIntervalProcessTableCols.map((col) => (
              <TableCell align="right" key={col}>
                {col}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {intervals.map((interval, i) => (
            <IntervalRow
              key={i + interval.intervalName}
              interval={interval}
              openAddStIntervalProcessResultModal={() => {
                if (canManageIntervalResultDetails) {
                  openAddStIntervalProcessResultModal(interval);
                }
              }
              }
              handleEdit={(e) => {
                if (canManageIntervalResultDetails) {
                  handleEdit(interval, e);
                }
              }}
              handleDelete={(e) => {
                if (canManageIntervalResultDetails) {
                  handleDelete(interval, e);
                }
              }}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
