import React, { useState } from "react";
import { GlobalButton } from "../../../pages/styles";
import ModalTwoButtons from "../../../components/Modal/ModalTwoButtons";
import { TextField } from "@mui/material";

export default function ScannerIncreaseInputButton({
  callback,
  initialState,
  min = 0,
  max = 999,
  style = {},
}) {
  const [modalOpen, setModalOpen] = useState(false);
  const [value, setValue] = useState(initialState);

  const increaseByOneQuantity = () => {
    if (value < max) {
      setValue(value + 1);
    }
  };

  const decreaseByOneQuantity = () => {
    if (value > min) {
      setValue(value - 1);
    }
  };
  return (
    <div style={style}>
      <GlobalButton
        fullWidth
        onClick={() => {
          setModalOpen(true);
        }}
      >
        Scan
      </GlobalButton>

      <ModalTwoButtons
        title={"Scanning Items"}
        button1Text={"Save"}
        button1Action={() => {
          callback(value);
          setModalOpen(false);
        }}
        isButton1Disabled={false}
        button2Text={"Cancel"}
        button2Action={() => {
          callback(initialState);
          setModalOpen(false);
        }}
        open={modalOpen}
        setOpen={setModalOpen}
      >
        <div>Hit enter or scan to increase</div>
        <div>Hit backspace to decrease</div>
        <TextField
          autoFocus
          fullWidth
          InputProps={{
            inputProps: { min: min, max: max },
          }}
          value={value}
          onChange={() => {}}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              increaseByOneQuantity();
            }
            if (e.key === "Backspace") {
              decreaseByOneQuantity();
            }
          }}
        ></TextField>
      </ModalTwoButtons>
    </div>
  );
}
