import React from "react";
import { Box } from "@mui/material";
import STFacility from "./STFacility";
import STMethodClassification from "./STMethodClassification";
import STStandType from "./STStandType";
import STStatus from "./STStatus";
import STType from "./STType";
import STIntervalResult from "./STIntervalResult";
import STParameter from "./STParameter";
import STEngineer from "./STEngineer";
import STQueue from "./STQueue";
import STValidationStatus from "./STValidationStatus";
import { GlobalTabCollection, StyledTab } from "../../styles";



const STMasterData = ({ ...props }) => {
  const [tabValue, setTabValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleChange2 = (event, newValue) => {
    setTabValue(newValue + 5);
  };

  const renderSearchTab = () => {
    switch (tabValue) {
      case 0: {
        return <STFacility></STFacility>
      }
      case 1: {
        return <STMethodClassification></STMethodClassification>;
      }
      case 2: {
        return <STStandType></STStandType>;
      }
      case 3: {
        return <STStatus></STStatus>
      }
      case 4: {
        return <STType></STType>
      }
      case 5: {
        return <STIntervalResult></STIntervalResult>
      }
      case 6: {
        return <STParameter></STParameter>
      }
      case 7: {
        return <STEngineer></STEngineer>
      }
      case 8: {
        return <STQueue></STQueue>
      }
      case 9: {
        return <STValidationStatus></STValidationStatus>
      }
      default: {
        alert(tabValue);
      }
    }
  };

  return (
    <>
      <div>
        <Box sx={{ bgcolor: "#fff" }}>
          <GlobalTabCollection value={tabValue} onChange={handleChange} aria-label='ant example'>
            <StyledTab label='Stationary Test Facility' />
            <StyledTab label='Stationary Test Method Classification' />
            <StyledTab label='Stationary Test Stand Type' />
            <StyledTab label='Stationary Test Status' />
            <StyledTab label='Stationary Test Type' />
          </GlobalTabCollection>
          <br></br>
          <Box sx={{ bgcolor: "#fff", pt:3, pb:1 }}>
            <GlobalTabCollection value={tabValue - 5} onChange={handleChange2} aria-label='ant example'>
              <StyledTab label='Stationary Test Result Interval Option' />
              <StyledTab label='Stationary Test Parameter' />
              <StyledTab label='Stationary Test Engineer' />
              <StyledTab label='Stationary Test Queue' />
              <StyledTab label='Stationary Test Validation Status' />
            </GlobalTabCollection>
          </Box>
          <Box sx={{ p: 3 }} />
        </Box>
        {renderSearchTab()}
      </div>
    </>
  );
};

export default STMasterData;