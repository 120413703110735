import { useState } from "react";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import ValidatedMidasNumberTextField from "../../../components/ValidatedMidasNumberTextField";
import { useFormik } from "formik";
import { fuelSampleSchema } from "../shared";
import { toast } from "react-hot-toast";
import type { UoM, Location, ContainerOption, FuelSamples } from "../types";
import { CONTAINER_CONFIRMED_STATUS_NAME } from "./NewRequest";

type FuelSampleModalProps = {
  initialValues: FuelSamples;
  isFuelSampleModalOpen: boolean;
  setIsFuelSampleModalOpen(n: boolean): void;
  uomList: UoM[];
  locationList: Location[];
  addSample(sample: any): void;
};

export default function FuelSampleModal({
  initialValues,
  isFuelSampleModalOpen,
  setIsFuelSampleModalOpen,
  uomList,
  locationList,
  addSample,
}: FuelSampleModalProps) {
  const [isSampleValid, setIsSampleValid] = useState(false);
  const [sample, setSample] = useState<any>(
    initialValues.sampleName && initialValues.sampleName !== ""
      ? {
          sampleName: initialValues.sampleName,
          containers: [],
        }
      : null
  );

  const uomOptions = uomList ? uomList.map((uom) => uom.uoMName) : [];
  const locationOptions = locationList
    ? locationList.map((ll) => ll.locationName)
    : [];
  const containerOptions: ContainerOption[] =
    sample && sample.containers && sample.containers.length > 0
      ? sample.containers
          .filter(
            (container: any) =>
              container.containerStatusName === CONTAINER_CONFIRMED_STATUS_NAME
          )
          .map((container: any) => {
            return {
              containerNumber: container.containerNumber,
              label: `${container.containerNumber}: ${container.currentAmount} ${container.containerSizeUoM}`,
            };
          })
      : [];

  const formik = useFormik({
    initialValues,
    validationSchema: fuelSampleSchema,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: async (values) => {
      const isValid = sample && sample.sampleName;
      if (isValid) {
        addSample(values);
      } else {
        toast.error("Invalid sample data");
      }
    },
  });

  return (
    <Dialog
      fullWidth={true}
      maxWidth="md"
      open={isFuelSampleModalOpen}
      onClose={() => setIsFuelSampleModalOpen(false)}
    >
      <DialogTitle>Fuel Sample</DialogTitle>
      <DialogContent>
        <Box component="form">
          <Box
            display="grid"
            gridTemplateColumns="1fr 1fr"
            gap={2}
            style={{ marginTop: "1rem", marginBottom: "1rem" }}
          >
            <ValidatedMidasNumberTextField
              showLabel
              midasNumberObject={sample}
              setMidasNumberObject={(v: any) => {
                setSample(v);
                if (v && v.sampleName) {
                  formik.setFieldValue("sampleName", v.sampleName);
                }
              }}
              hasErrors={isSampleValid}
              setHasErrors={(e: any) => setIsSampleValid(e)}
              chemIDToMatch={
                sample?.substance ? sample?.substance?.chemID : null
              }
              setScannedContainer={(value: any) => {
                if (value && typeof value.containerNumber === "number") {
                  formik.setFieldValue(
                    "containerNumber",
                    value.containerNumber
                  );
                  formik.setFieldValue("containerId", value.id);
                }
              }}
              fieldWidth={null}
              fontSize={null}
              isDisabled={false}
              margin="none"
            />

            <Autocomplete
              id="containerNumber"
              options={containerOptions}
              renderInput={(params) => (
                <TextField {...params} label="Container" size="small" />
              )}
              onChange={(_, v) => {
                if (v) {
                  formik.setFieldValue("containerNumber", v.containerNumber);
                  const correspondentContainer = sample.containers.find(
                    (c: any) => c.containerNumber === v.containerNumber
                  );
                  if (correspondentContainer && correspondentContainer.id) {
                    formik.setFieldValue(
                      "containerId",
                      correspondentContainer.id
                    );
                  }
                }
              }}
              value={
                formik.values.containerNumber as unknown as ContainerOption
              }
              disabled={
                !sample || !sample.containers || sample.containers.length === 0
              }
              isOptionEqualToValue={(option: ContainerOption) =>
                option.containerNumber === formik.values.containerNumber
              }
              getOptionLabel={(option) =>
                option.label ? option.label : String(option)
              }
            />
          </Box>

          <Box
            display="grid"
            gap={2}
            gridTemplateColumns="1fr 1fr 1fr"
            style={{ marginBottom: "1rem" }}
          >
            <Autocomplete
              id="returnLocationName"
              options={locationOptions}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Unused Return Location"
                  size="small"
                />
              )}
              onChange={(_, v) => {
                formik.setFieldValue("returnLocationName", v);
              }}
              value={formik.values.returnLocationName}
              size="small"
            />

            <TextField
              name="amountUsed"
              id="amountUsed"
              label="Sample Expected Use"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.amountUsed}
              size="small"
              type="number"
            />
            <Autocomplete
              id="amountUsedUoMName"
              options={uomOptions}
              renderInput={(params) => (
                <TextField {...params} label="UoM" size="small" />
              )}
              onChange={(_, v) => {
                formik.setFieldValue("amountUsedUoMName", v);
              }}
              value={formik.values.amountUsedUoMName}
              size="small"
            />
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          disabled={
            !formik.isValid ||
            !sample ||
            !sample.sampleName ||
            typeof formik.values.containerNumber !== "number"
          }
          onClick={() => formik.submitForm()}
        >
          {initialValues.rowId ? "update" : "add"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
