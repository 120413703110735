import React, { useContext, useState, useEffect } from "react";
import {
  Box,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from '@mui/material'
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { StockroomRequestContext } from "../context/StockroomRequestContext";
import {
  StyledTableBody,
  StyledTableRow,
  UXDataTableWithoutBody,
} from "../../../components/UXDataTable";
import { StyledTableCellSmall } from "../styles";
import { VendorContext } from "../context/VendorContext";
import { DatePickerKeyDownEvent } from "../../../global";
import { GlobalButton} from "../../styles";


const buildPOCols = [
  "PO #",
  "Vendor",
  "Location",
  "Stockroom #",
  "Description",
  "QtyOrdered",
  "BckOrder",
  "POStatus",
  "Recvd",
];

export const POSearchReport = ({ canEdit }) => {
  const [po, setPo] = useState('')
  const [vendor, setVendor] = useState('')

  const day = new Date().getDate()
  const month = new Date().getMonth()
  const year = new Date().getFullYear()

  const [chargesReport, setChargesReport] = useState([])
  const [dateFrom, setDateFrom] = useState(null)
  const [dateTo, setDateTo] = useState(new Date(year, month, day))
  const { getStockroomReports } = useContext(StockroomRequestContext);
  const [chargesReportRowsPerPage, setChargesReportRowsPerPage] = useState(10);
  const [chargesReportPage, setChargesReportPage] = useState(0);
  const { getAllVendors, vendors } = useContext(VendorContext);

  const handleCreateReport = async () => {
    const payload = {
      Report: "M",
      PONo: po,
      Vendor: vendor,
      StartDate: dateFrom,
      EndDate: dateTo
    };

    let report = await getStockroomReports(payload);
    setChargesReport(report);
  };

  useEffect(() => {
    if (!vendors.length) {
      getAllVendors();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangeReportRowsPerPage = (event) => {
    setChargesReportRowsPerPage(parseInt(event.target.value, 10));
    setChargesReportPage(0);
  };

  const handleBuildReportPageChange = (event, page) => {
    setChargesReportPage(page);
  };

  return (
    <Box>
      <Box display="flex" gap={4} marginBottom={4}>
        <Box display="flex" alignItems="center" gap={2}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DesktopDatePicker
              disabled={!canEdit}
              label="Search From"
              maxDate={new Date()}
              value={dateFrom}
              onChange={e => setDateFrom(e)}
              renderInput={(params) => <TextField onKeyDown={DatePickerKeyDownEvent} size="small" {...params} />}
            />
            <DesktopDatePicker
              disabled={!canEdit}
              label="To"
              minDate={dateFrom}
              value={dateTo}
              maxDate={new Date()}
              onChange={e => setDateTo(e)}
              renderInput={(params) => <TextField onKeyDown={DatePickerKeyDownEvent} size="small" {...params} />}
            />
          </LocalizationProvider>
          <TextField
            disabled={!canEdit}
            onChange={e => setPo(e.target.value)}
            value={po}
            label="PO #"
            size="small"
          />
          <FormControl size="small"
            style={{ width: "50%" }}
          >
            <InputLabel htmlFor="vendor">Vendor</InputLabel>
            <Select
              disabled={!canEdit}
              size="small"
              name="vendor"
              label="Vendor"
              onChange={e => setVendor(e.target.value)}
              value={vendor}
            >
              <MenuItem value="-1" >Select an option</MenuItem>
              {vendors.map((state, index) => {
                return (
                  <MenuItem key={index} value={state.id}>{state.vendorName}</MenuItem>
                )
              })}
            </Select>
          </FormControl>
        </Box>

        <Box display="flex" alignItems="center">
          <GlobalButton
            disabled={!canEdit}
            variant="contained"
            onClick={handleCreateReport}
          >
            Create report
          </GlobalButton>
        </Box>
      </Box>
      <Box>
        <UXDataTableWithoutBody
          tableWidth="100%"
          cols={buildPOCols}
          tableId="chargesTable"
          rowLength={chargesReport?.length}
          enablePaging={true}
          rowsPerPage={chargesReportRowsPerPage}
          page={chargesReportPage}
          handleChangePage={handleBuildReportPageChange}
          handleChangeRowsPerPage={handleChangeReportRowsPerPage}
          noDataFoundMessage={"No charges found"}
        >
          <StyledTableBody>
            {chargesReport?.length > 0 &&
              chargesReport.slice(
                chargesReportPage * chargesReportRowsPerPage,
                chargesReportPage * chargesReportRowsPerPage +
                chargesReportRowsPerPage
              )
                .map((item) => {
                  return (
                    <StyledTableRow key={item?.pono} hover>
                      <StyledTableCellSmall>
                        {item?.pOno}
                      </StyledTableCellSmall>
                      <StyledTableCellSmall>
                        {item?.vendor}
                      </StyledTableCellSmall>
                      <StyledTableCellSmall>
                        {item?.location}
                      </StyledTableCellSmall>
                      <StyledTableCellSmall>{item?.stockroomNo}</StyledTableCellSmall>
                      <StyledTableCellSmall>
                        {item?.description}
                      </StyledTableCellSmall>
                      <StyledTableCellSmall>
                        {item?.qtyOrdered}
                      </StyledTableCellSmall>
                      <StyledTableCellSmall>
                        {item?.bckOrder}
                      </StyledTableCellSmall>
                      <StyledTableCellSmall>
                        {item?.poStatus}
                      </StyledTableCellSmall>
                      <StyledTableCellSmall>{item?.recvd}</StyledTableCellSmall>
                    </StyledTableRow>
                  )
                })}
          </StyledTableBody>
        </UXDataTableWithoutBody>
      </Box>
    </Box>
  )
}