import React from "react";
import ModalSimpleButton from "../../../components/Modal/ModalSimpleButton";
import { Typography } from "@mui/material";

export default function DisplayMessage({
  title,
  buttonText,
  setIsModalOpen,
  isModalOpen,
  message,
  setModalType = (type)=>{},
  buttonAction = ()=>{},
}) {

  return (
    <ModalSimpleButton
      title={title}
      buttonText={buttonText}
      buttonAction={() => {
        setIsModalOpen(false);
        buttonAction()
        setModalType("");
      }}
      setOpen={setIsModalOpen}
      open={isModalOpen}
    >
      <Typography
        style={{ padding: "0 16px", fontSize: 18, textAlign: "center" }}
      >
        {message}
      </Typography>
    </ModalSimpleButton>
  );
}
