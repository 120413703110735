import API from '../index'

export default class STRequest {
    constructor(
        ID,
        TestDisplayName,
        TestRecord,
        StatusName,
        RequestStatus,
        StationaryTestFacilityName,
        TestFacility,
        TestTypeName,
        TestType,
        TestStandName,
        TestStand,
        SubmittedSampleName,
        SubmittedSample,
        ValidationStatusName,
        ValidationStatus,
        WorkGroupName,
        QueueGroup,
        SubmittedContainerNumber,
        CreatedByEmail,
        CreatedDate,
        LastModifiedByEmail,
        LastModifiedDate,
        RequestedByEmail,
        CardNumber,
        RunNumber,
        BillingReference,
        SafetyAlert,
        FuelType,
        FuelBatch,
        FuelTankLine,
        IONNumber,
        ReportAlias,
        accOilCode,
        FormulationCode,
        SubmitterComments,
        TechnicianComments,
        TestEngineerEmail,
        TestStartDate,
        TestEndDate,
        TestStartedByEmail,
        TestEndedByEmail,
        CalibrationExpiration,
        CellCalibrationExpiration,
        SupportInformation,
        SequenceNumber,
        ValidatedByEmail,
        ValidationDate,
        ValidationComments,
        RequestFuels,
        RequestLubricants,
        RequestParameters,
        FileAttachments
    ) {
        this.ID = ID
        this.TestDisplayName = TestDisplayName
        this.TestRecord = TestRecord
        this.StatusName = StatusName
        this.RequestStatus = RequestStatus
        this.StationaryTestFacilityName = StationaryTestFacilityName
        this.TestFacility = TestFacility
        this.TestTypeName = TestTypeName
        this.TestType = TestType
        this.TestStandName = TestStandName
        this.TestStand = TestStand
        this.SubmittedSampleName = SubmittedSampleName
        this.SubmittedSample = SubmittedSample
        this.ValidationStatusName = ValidationStatusName
        this.ValidationStatus = ValidationStatus
        this.WorkGroupName = WorkGroupName
        this.QueueGroup = QueueGroup
        this.SubmittedContainerNumber = SubmittedContainerNumber
        this.CreatedByEmail = CreatedByEmail
        this.CreatedDate = CreatedDate
        this.LastModifiedByEmail = LastModifiedByEmail
        this.LastModifiedDate = LastModifiedDate
        this.RequestedByEmail = RequestedByEmail
        this.CardNumber = CardNumber
        this.RunNumber = RunNumber
        this.BillingReference = BillingReference
        this.SafetyAlert = SafetyAlert
        this.FuelType = FuelType
        this.FuelBatch = FuelBatch
        this.FuelTankLine = FuelTankLine
        this.IONNumber = IONNumber
        this.ReportAlias = ReportAlias
        this.accOilCode = accOilCode
        this.FormulationCode = FormulationCode
        this.SubmitterComments = SubmitterComments
        this.TechnicianComments = TechnicianComments
        this.TestEngineerEmail = TestEngineerEmail
        this.TestStartDate = TestStartDate
        this.TestEndDate = TestEndDate
        this.TestStartedByEmail = TestStartedByEmail
        this.TestEndedByEmail = TestEndedByEmail
        this.CalibrationExpiration = CalibrationExpiration
        this.CellCalibrationExpiration = CellCalibrationExpiration
        this.SupportInformation = SupportInformation
        this.SequenceNumber = SequenceNumber
        this.ValidatedByEmail = ValidatedByEmail
        this.ValidationDate = ValidationDate
        this.ValidationComments = ValidationComments
        this.RequestFuels = RequestFuels
        this.RequestLubricants = RequestLubricants
        this.RequestParameters = RequestParameters
        this.FileAttachments = FileAttachments
    }

    static async quickSearch(searchCriteria) {
        const api = await API();
        const { data } = await api.get(`strequest/quickSearch?searchCriteria=${searchCriteria}`);
        return data;
      }

    static async getRequests() {
        const api = await API()
        const { data } = await api.get(`/strequest`)

        if (data.message !== 'Success') {
            return null
        } 

        return data.result;
    }

    static async getUserRequests(requesterEmail) {
        const api = await API()
        const { data } = await api.get(`/strequest?requesterEmail=${requesterEmail}`)
        if (data && data.result && data.result.length > 0) {
          return data.result;
        }
        return [];
    }
 
    static async createRequest(payload) {
        const api = await API();
        const { data } = await api.postForm('/strequest', payload);
        return data;
    }

    static async updateRequest(payload) {
        const api = await API();
        const { data } = await api.putForm('/strequest', payload);
        return data.result;
    }

    static async getRequestForUpdateById(id) {
        const api = await API();
        const { data } = await api.get(`/strequest/update/${id}`);
        return data.result;
    }

    static async getAllForProcessRequests() {
        const api = await API()
        const { data } = await api.get(`/strequest/process-request`)

        if (data.message === 'Success') {
          return data.result;
        }
        return []
    }

    static async getRequestInformationByStRequestId(stRequestId) {
        const api = await API()
        const { data } = await api.get(`/strequest/process-request/information/${stRequestId}`)

        if (data.message !== 'Success') {
            return null
        } 

        return data.result;
    }

    static async getActions(stRequestId) {
        const api = await API()
        const { data } = await api.get(`/strequest/actions/${stRequestId}`)

        if (data.message !== 'Success') {
            return null
        } 

        return data.result;
    }
    
    static async ActionSave(payload) {
        const api = await API();
        const { data } = await api.putForm('/strequest/actions/save', payload);
        return data;
    }

    static async ActionSendBackToRequester(requestId, notificationText) {
        const api = await API();
        const { data } = await api.post(`/strequest/send/requester/${requestId}`, notificationText);
        return data.result;
    }

    static async ActionSendBackToEngineer(requestId, notificationText) {
        const api = await API();
        const { data } = await api.post(`/strequest/send/engineer/${requestId}`, notificationText);
        return data.result;
    }

    static async ActionCancel(requestId, notificationText) {
        const api = await API();
        const { data } = await api.post(`/strequest/action/cancel/${requestId}`, notificationText);
        return data.result;
    }

    static async ActionReopen(requestId, notificationText) {
        const api = await API();
        const { data } = await api.post(`/strequest/action/reopen/${requestId}`, notificationText);
        return data.result;
    }
}
