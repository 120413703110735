import React from "react";
import {
  Box,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

const WorksheetResultEntry = ({ ...props }) => {
  return (
    <div>
      <Box>
        <FormControl sx={{ m: 1, width: "500px" }} variant='outlined'>
          <InputLabel
          // htmlFor='outlined-adornment-password'
          >
          Worksheet Result Entry
          </InputLabel>
          <OutlinedInput
            // id='outlined-adornment-password'
            // type={values.showPassword ? "text" : "password"}
            // value={values.password}
            // onChange={handleChange("password")}
            endAdornment={
              <InputAdornment position='end'>
                <IconButton
                  aria-label='search Worksheet Result Entry'
                  // onClick={handleClickShowPassword}
                  // onMouseDown={handleMouseDownPassword}
                  edge='end'
                >
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            }
            label='Worksheet Result Entry'
          />
        </FormControl>
      </Box>
      <p>
        The code for this page can be found at <code>src/pages/Analytical/WorksheetResultEntry.js</code>
      </p>
    </div>
  );
};

export default WorksheetResultEntry;
