import React, { useState, useEffect, useContext } from "react";
// import {
//   Table,
//   TableBody,
//   TableCell,
//   TableContainer,
//   TableHead,
//   TableRow,
//   styled,
//   tableCellClasses,
// } from "@mui/material";
import UserContext from "../../context/UserContext";
import TestActivity from "../../api/LIMS/TestActivity";
import { UXDataTable } from "../../components/UXDataTable";
// import { formatMidasNumber, convertToLocalTime } from "../../global";

// const StyledTableCell = styled(TableCell)(({ theme }) => ({
//   border: "1px solid #F4F4F4",
//   textAlign: "left",
//   [`&.${tableCellClasses.head}`]: {
//     borderBottomColor: "#D2D2D2",
//     fontWeight: 600,
//     color: "rgba(0, 0, 0, 0.8)",
//   },
//   [`&.${tableCellClasses.body}`]: {
//     fontSize: "small",
//   },
// }));

// const StyledTableRow = styled(TableRow)(({ theme }) => ({
//   "&:nth-of-type(odd)": {
//     backgroundColor: theme.palette.action.hover,
//   },
//   // hide last border
//   "&:last-child td, &:last-child th": {
//     border: 0,
//   },
// }));

const myCols = [
  { field: 'sampleName', headerName: 'MIDAS Number', type: 'label-midasnumber'},
  { field: 'testSequenceNumber', headerName: 'Seq #', type: 'number' },
  { field: 'methodName', headerName: 'Method', type: 'label' },
  { field: 'submittedDate', headerName: 'Date Submitted', type: 'datetime' },
  { field: 'testPriorityName', headerName: 'Priority', type: 'label' },
  { field: 'testStatusName', headerName: 'Test Status', type: 'label' },
];

const RecentTestResultsTable = () => {

  const [testsIsLoading, setTestsIsLoading] = useState(true)
  const [myTestInfo, setMyTestInfo] = useState([])
  const currentUser = useContext(UserContext)
  
  let inputSearchCriteria = currentUser.username
  const noDataMessage = `No tests found for submitter/owner/requester ${inputSearchCriteria}`
  let maxResults = 10

      //get all the locations
  useEffect(() => {

    let cancelPromise = false
    
      if (inputSearchCriteria) {
        TestActivity.getAll(inputSearchCriteria, maxResults).then((res) => {
          if (cancelPromise) return
            setMyTestInfo(res);
            setTestsIsLoading(false)
          })
      } 
    return () => {
      cancelPromise = true
      }
    }, [inputSearchCriteria, maxResults]);

  return (
    <UXDataTable tableWidth='100%' cols={myCols} rows={myTestInfo === null ? [] : myTestInfo.sort((a, b) => new Date(b.submittedDate) - new Date(a.submittedDate))} moreOptionsCell={false} enablePaging={false} 
    noDataMessage={noDataMessage} menuProps={null} defaultRowsPerPage={10} isDataLoading={testsIsLoading} tableName={'recentTestsWidget'} enableSorting={true}></UXDataTable>
  );
};

export default RecentTestResultsTable;
