import API from "..";

export default class SubstanceSubcomponent {
  /**
   * The SubstanceSubcomponent dimension table for M3
   * @param TODO
   */
  constructor({
    chemID,
    subcomponentChemID,
    percentageCompositionUpper,
    percentageCompositionLower,
    componentOrder,
  }) {
    this.chemID = chemID;
    this.subcomponentChemID = subcomponentChemID;
    this.percentageCompositionUpper = percentageCompositionUpper;
    this.percentageCompositionLower = percentageCompositionLower;
    this.componentOrder = componentOrder;
  }

  static async get(chemid = "", subchemid = "") {
    const api = await API();
    const { data } = await api.get(`/substancesubcomponent?chemid=${chemid}&subchemid=${subchemid}`);
    return data.result.map((d) => new SubstanceSubcomponent(d));
  }

  static async create(substancesubcomponent) {
    const api = await API();
    const { data } = await api.post(`/substancesubcomponent`, substancesubcomponent);
    return (data);
  }

  static async delete(substancesubcomponent) {
    const api = await API();
    const { data } = await api.delete(`/substancesubcomponent?chemId=${substancesubcomponent.chemID}&subcomponentChemID=${substancesubcomponent.subcomponentChemID}`);
    return (data);
  }
}