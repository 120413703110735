import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import CloseIcon from "@mui/icons-material/Close";
import Box from "@mui/system/Box";

type CustomTabProps = {
  onCloseTab(): void;
  label: string;
  onClick(_: any): void;
};

function CustomTab({ onCloseTab, label, onClick }: CustomTabProps) {
  return (
    <Tab
      label={
        <Box display="grid" gridTemplateColumns="2fr 1fr">
          <div
            onClick={onClick}
            style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
          >
            <span>{label}</span>
          </div>
          <div onClick={onCloseTab} style={{ display: "flex", marginLeft: "0.5rem" }}>
            <CloseIcon fontSize="small" style={{ color: "black" }} />
          </div>
        </Box>
      }
    />
  );
}

type CustomTabsProps = {
  tabIndex: number;
  handleChange(_: any, index: number): void;
  onCloseTab(index: number): void;
  tabs: any[];
};

export default function CustomTabs({ tabIndex, handleChange, onCloseTab, tabs }: CustomTabsProps) {
  return (
    <Tabs value={tabIndex} onChange={handleChange} variant="scrollable" scrollButtons="auto">
      {tabs.map((tab, i) => (
        <CustomTab
          key={`${tab.label}i`}
          label={tab.label}
          onCloseTab={() => onCloseTab(i)}
          onClick={(e) => handleChange(e, i)}
        />
      ))}
    </Tabs>
  );
}
