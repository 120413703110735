import React, { useContext } from "react";
import {
    Box,
    FormControl,
    styled,
    TextField,
    Autocomplete
} from "@mui/material";
import STParameterComponent from "../../../api/StationaryTesting/STParameter";
import STParameterForm from "./STParameterForm";
import { getMessage, hasRole, Roles } from "../../../global";
import ModalSimpleButton from '../../../components/Modal/ModalSimpleButton';
import UserContext from "../../../context/UserContext";
import { GlobalButton, GlobalSecondaryButton } from "../../styles";

const StyledBox = styled(Box)(() => ({
    display: 'flex',
    marginBottom: "20px"
}));

const StyledFormControl = styled(FormControl)(() => ({
    display: 'flex',
    width: "50rem"
}));

const StyledDiv = styled('div')({
    display: 'flex',
    justifyContent: "space-between"
});

const STParameter = (props) => {

    const initialStateParameter = {
        id: undefined,
        testParameterName: "",
        isActive: false,
    };

    const validationsInitialState =
    {
        testParameterName: "",
    };

    const [isAdd, setIsAdd] = React.useState(false);
    const [infoData, setInfoData] = React.useState(initialStateParameter);
    const [parameterList, setParameterList] = React.useState(null);
    const [invalidField, setInvalidField] = React.useState(validationsInitialState);

    const [modalSimpleButton, setModalSimpleButton] = React.useState(false);
    const [modalSimpleButtonText, setModalSimpleButtonText] = React.useState('');
    const [modalSimpleTitle, setModalSimpleTitle] = React.useState('');
    const [modalSimpleText, setModalSimpleText] = React.useState('');

    const currentUser = useContext(UserContext)
    const roles = currentUser?.idTokenClaims.roles

    const canEdit = hasRole(Roles.Developer, roles) || hasRole(Roles.MethodAdministrator, roles)

    function closeModalSimpleButton() {
        setModalSimpleButton(false);
    }

    function openModalSimpleButton(title, text, buttonText) {
        setModalSimpleButtonText(buttonText)
        setModalSimpleButton(true);
        setModalSimpleTitle(title);
        setModalSimpleText(text);
    }

    function handleSubmit(event) {
        event.preventDefault();

        if (!validateFields()) {
            if (infoData.id !== undefined)
                saveParameter();
            else {

                STParameterComponent.get(infoData.testParameterName).then((parameterExists) => {
                    if ((parameterExists !== undefined && parameterExists !== null))
                        openModalSimpleButton('Fail', 'This stand type name already exist', 'Ok');
                    else saveParameter();
                });
            }
        }
    }

    function saveParameter() {

        let parameter = new STParameterComponent({
            testParameterName: infoData.testParameterName,
            isActive: infoData.isActive
        });

        if (infoData.id === undefined) {
            STParameterComponent.add(parameter).then((res) => {
                if (res) {
                    resetForm();
                    openModalSimpleButton('Success', 'Parameter saved with success', 'Ok');
                    loadParameterFilter();
                }
                else
                    openModalSimpleButton('Fail', 'Something went wrong, please try after sometime', 'Ok');
            });
        } else {
            STParameterComponent.update(parameter).then((res) => {
                if (res) {
                    resetForm();
                    openModalSimpleButton('Success', 'Parameter updated with success', 'Ok');
                    loadParameterFilter();
                }
                else
                    openModalSimpleButton('Fail', 'Something went wrong, please try after sometime', 'Ok');
            });
        }
    }

    function handleChangeIsAdd() {
        setIsAdd(true);
        resetForm();
    }


    function loadParameterFilter() {
        STParameterComponent.getAll().then((res) => {
            if (res.length > 0) {

                let array = [];
                array.push({ id: undefined, testParameterName: "" });
                array.push(...res);

                setParameterList(array);
            }
        });
    }

    function validateFields() {
        let requiredParameterName = infoData.testParameterName === "" ? getMessage('REQUIRED_FIELD') : "";

        setInvalidField({
            ...invalidField,
            testParameterName: requiredParameterName
        });

        return ((requiredParameterName !== ""));
    }

    function resetForm() {

        setInfoData(initialStateParameter);
        setInvalidField(validationsInitialState);
    }

    function handleChangeSTParameterFilter(idSelected) {
        setInfoData(initialStateParameter);

        setInfoData(() => ({
            ...initialStateParameter,
            id: idSelected,
            testParameterName: idSelected
        }));

        setIsAdd(true);

        setInvalidField(validationsInitialState);

        if (idSelected !== "") {
            STParameterComponent.get(idSelected).then((parameter) => {
                if (parameter !== null) {

                    setInfoData(() => ({
                        ...infoData,
                        id: idSelected,
                        testParameterName: parameter.testParameterName,
                        isActive: parameter.isActive,
                    }));
                }
            });
        }
    }

    React.useMemo(() => {
        if (parameterList === null) {
            loadParameterFilter();
        }
    }, [parameterList])

    return (
        <div>
            <form onSubmit={handleSubmit}>
                <StyledDiv>
                    <div>
                        <StyledBox>
                            <StyledFormControl sx={{ m: 1, width: "35rem" }} variant='outlined'>

                                <StyledBox sx={{ justifyContent: "space-between" }}>
                                    <Autocomplete
                                        noOptionsText={"Loading Parameter..."}
                                        value={infoData.testParameterName === '' ? null : infoData.testParameterName}
                                        onChange={(e, value) => {
                                            handleChangeSTParameterFilter(value);
                                        }}
                                        disablePortal
                                        options={parameterList ? parameterList.map((parameter) => parameter.testParameterName) : []}
                                        sx={{ width: "35rem" }}
                                        renderInput={(params) => <TextField {...params} variant="outlined" size="small" margin="none" label="Parameter" />}
                                        autoSelect
                                    />

                                    <GlobalSecondaryButton sx={{ width: "8rem", marginLeft:"1rem" }}
                                        variant='contained'
                                        type="button"
                                        onClick={handleChangeIsAdd}
                                    >Add New</GlobalSecondaryButton>

                                </StyledBox>

                            </StyledFormControl>

                        </StyledBox>

                        {isAdd === true && <STParameterForm
                            infoData={infoData} setInfoData={setInfoData}
                            invalidField={invalidField}
                            handleChangeSTParameterFilter={handleChangeSTParameterFilter}
                            canEdit={canEdit}
                        >
                        </STParameterForm>}

                        {
                            isAdd === true &&
                            <div>
                                <StyledBox sx={{ mb: 1, mt: 2, justifyContent: "space-between" }}>
                                    <GlobalButton sx={{ width: "8rem" }}
                                        variant='contained'
                                        type="submit"
                                        disabled={!canEdit}>Save</GlobalButton>
                                </StyledBox>
                            </div>
                        }

                    </div>

                </StyledDiv>

            </form >

            <ModalSimpleButton title={modalSimpleTitle} buttonText={modalSimpleButtonText} buttonAction={closeModalSimpleButton} open={modalSimpleButton} setOpen={setModalSimpleButton}>
                <label>
                    {modalSimpleText}
                </label>
            </ModalSimpleButton>

        </div >
    )
}

export default STParameter;