import axios from "axios";
import msalInstance from "../services/auth/msalInstance";
import { convertCharacterSpecial } from "../global";

const createAPI = async (contentType) => {
  try {
    if (!contentType) {
      contentType = "application/json;charset-utf-8";
    }

    if (msalInstance !== null && msalInstance !== undefined) {
      let request = {
        scopes: [process.env.REACT_APP_MIDAS_AUTH_SCOPES],
      };

      let adfsrequest = {
        scopes: [process.env.REACT_APP_MIDAS_AUTH_ADFS_SCOPES],
      };

      let usedAccessToken;
      let tokenResponse;

      if (window.localStorage.getItem("AUTH_PROVIDER") === "Adfs") 
      {
        tokenResponse = await msalInstance.acquireTokenSilent(adfsrequest);
        //usedAccessToken = tokenResponse.accessToken;
        usedAccessToken = tokenResponse.idToken;
      } else {
        tokenResponse = await msalInstance.acquireTokenSilent(request);
        usedAccessToken = tokenResponse.accessToken;
      }

      let api = axios.create({
        baseURL: process.env.REACT_APP_MIDAS_API_URL,
        withCredentials: true,
        headers: {
          Accept: "application/json",
          Authorization: `bearer ${usedAccessToken}`,
          "Content-Type": contentType,
        },
        validateStatus: function (status) {
          if (status === 401) {
            console.log(window.location.href);
            console.log(`Status code 401 returned`);
            console.log(`token: ${JSON.stringify(tokenResponse)}`);
            console.log(`id token: ${tokenResponse.idToken}`);
            console.log(`access token: ${tokenResponse.accessToken}`);
          }
          return status;
        },
        paramsSerializer: {
          indexes: null,
        }
      });

      api.interceptors.request.use((config) => {
        if (config.method === "get") {
          if (config.url.includes("/?")) {
            const url = config.url.split("/?");
            let params = new URLSearchParams();
            const urlParams = new URLSearchParams(url[1]);
            urlParams.forEach((value, key) => {
              if (value && value !== "") {
                params.append(key, convertCharacterSpecial(value));
              }
            });

            config.url = url[0];
            config.params = params;
          }
        }
        return config;
      });

      return api;
    }
  } catch (error) {
    if (error.name.includes("Auth")) {
      console.log(`Auth Error Caught: ${error}`);

      return axios.create();
    }

    console.log({ error });
  }
};

export default createAPI;
