import { React, useEffect, useState, useContext } from "react";
import { TextField, Autocomplete } from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import Project from "../../../api/Admin/Project";
import BlendStudyType from "../../../api/Formulations/BlendStudyType";
import { formatCreatedAndModifiedDateInfo, hasRole, Roles} from "../../../global";
import BlendStudy from "../../../api/Formulations/BlendStudy";
import EmailAddressTextField from "../../../components/EmailAddressField";
import { outlinedInputClasses } from "@mui/material/OutlinedInput";
import { inputLabelClasses } from "@mui/material/InputLabel";
import UserContext from "../../../context/UserContext";

const autoCompleteFontSize = 16
const helperLabel = "Required Input"

const StyledShortTextField = styled(TextField)({
    id:"outlined-normal",
    width:"22%",
    marginRight:"15px",
});

const StyledMediumTextField = styled(TextField)({
    id:"outlined-normal",
    width:"50%",
    marginRight:"15px",
});

const StyledAutocomplete = styled(Autocomplete)({
    '& .MuiAutocomplete-input, & .MuiInputLabel-root': {
      fontSize: autoCompleteFontSize,
    },
    width:"22%",
    marginRight:"15px",
});

const StudyInformation = ({ myStudy, setMyStudy, errorChecks, setErrorChecks }) => {
    const currentUser = useContext(UserContext)

    const [availableProjects, setAvailableProjects] = useState([])
    const [availableStudyTypes, setAvailableStudyTypes] = useState([])
    const [studyNameErrorText, setStudyNameErrorText] = useState("Required Input")

    const theme = useTheme();
    const [coloring, setColoring] = useState(theme.palette.primary);

    const roles = currentUser?.idTokenClaims.roles;
    const isDeveloper = hasRole(Roles.Developer, roles);
    const isBlendMaintainer = hasRole(Roles.BlendMaintainer, roles);

    //disable the add batch and blend buttons if you arent the owner
    const userDisabled = myStudy.ownerEmail !== currentUser.username && myStudy.ownerEmail !== '' && myStudy.ownerEmail !== null  && isDeveloper === false && isBlendMaintainer === false
    
    const disableStudyInfo = (myStudy.blendStudyStatusName !== 'Submitted' && myStudy.blendStudyStatusName !=='' && myStudy.blendStudyStatusName !== null) || userDisabled

    //get all the enums on render
    useEffect(() => {
        let cancelPromise = false

        if (availableProjects.length === 0) {
            Project.getAll().then((res) => {
                if (cancelPromise) return

                if (myStudy.project === null) {
                    setMyStudy(() => ({
                        ...myStudy,
                        project: res.find(v => v.name === "PUBLIC")
                    }))
                    setAvailableProjects(res.filter(result => result.isActive === true).sort((a, b) => a.name.localeCompare(b.name)))
                }
            });
        }

        if (availableStudyTypes.length === 0) {
        BlendStudyType.getAllBlendStudyTypes().then((res) => {
            if (cancelPromise) return
            setAvailableStudyTypes(res.filter(result => result.isActive === true).sort((a, b) => a.name.localeCompare(b.name)))
        });
        }
        
        return () => {
            cancelPromise = true
        }
        
    })

    function AutoCompleteChangeAndValidate (property, value)
    {
        const copyMyStudy = structuredClone(myStudy)
        const copyErrors = structuredClone(errorChecks)

        copyMyStudy[property] = value
        copyErrors.blendStudy[property] = value === null ? true : false
    
        setMyStudy(copyMyStudy)
        setErrorChecks(copyErrors)
    }

    const handleKeyPress = (event) => 
    {
      if (event.key === "Enter" || event.key === "Tab") 
      {
        HandleBlendStudyChangeAndValidate(event.target.value);
      }
    };

    function HandleBlendStudyChangeAndValidate (value)
    {
        if (myStudy.studyName !== null && myStudy.studyName.trim() !== '')
        {
            BlendStudy.checkName(value).then((result) => {
                if (result && result.message === "Success" && result.result === true) {
                      
                    setErrorChecks(() => ({
                        ...errorChecks,
                        blendStudy: {...errorChecks.blendStudy, studyName: false}
                    }))

                    setColoring("green")
                } else {
                    setErrorChecks(() => ({
                        ...errorChecks,
                        blendStudy: {...errorChecks.blendStudy, studyName: true}
                    }))

                    setStudyNameErrorText("Study Name Already Used")
                    setColoring(theme.palette.primary)
                  
                }
            });

            // BlendStudy.getBlendStudyByStudyName(value).then((result) => {
            //     if (result) {
            //         setErrorChecks(() => ({
            //             ...errorChecks,
            //             blendStudy: {...errorChecks.blendStudy, studyName: true}
            //         }))

            //         setStudyNameErrorText("Study Name Already Used")
            //         setColoring(theme.palette.primary)
            //     } else {
            //         setErrorChecks(() => ({
            //             ...errorChecks,
            //             blendStudy: {...errorChecks.blendStudy, studyName: false}
            //         }))

            //         setColoring("green")
            //     }
            // });
        } else
        {
            setErrorChecks(() => ({
                ...errorChecks,
                blendStudy: {...errorChecks.blendStudy, studyName: true}
            }))

            setStudyNameErrorText("Required Input")
        }
    }

    function SetOwnerEmail(value)
    {
        const copyStudy = structuredClone(myStudy)

        copyStudy.ownerEmail = value

        setMyStudy(copyStudy)
    }

    function updateErrorForOwnerEmail (value)
    {
        const copyErrors = structuredClone(errorChecks)

        copyErrors.blendStudy.ownerEmail = value

        setErrorChecks(copyErrors)
    }

  return (

    <div style={{marginTop:"50px"}}>

        <div style={{display:"flex"}}>
            <TextField style={{ id:"outlined-small",width:"8%",marginRight:"15px"}} size="small" margin="normal" variant="outlined" label="Study ID" InputLabelProps={{shrink: true}}
                disabled
                value={myStudy.id}
            >
            </TextField>

            <TextField 
                sx={{
                [`& .${outlinedInputClasses.root} .${outlinedInputClasses.notchedOutline}`]:
                {
                    borderColor: coloring,
                },

                [`&:hover .${outlinedInputClasses.root} .${outlinedInputClasses.notchedOutline}`]:
                {
                    borderColor: coloring,
                },
                [`& .${outlinedInputClasses.root}.${outlinedInputClasses.focused} .${outlinedInputClasses.notchedOutline}`]:
                {
                    borderColor: coloring,
                },
                [`& .${outlinedInputClasses.input}`]: {
                color: coloring,
                },

                [`&:hover .${outlinedInputClasses.input}`]: {
                color: coloring,
                },
                [`& .${outlinedInputClasses.root}.${outlinedInputClasses.focused} .${outlinedInputClasses.input}`]:
                {
                    color: coloring,
                },
                [`& .${inputLabelClasses.outlined}`]: {
                color: coloring,
                },

                [`&:hover .${inputLabelClasses.outlined}`]: {
                color: coloring,
                },
                [`& .${inputLabelClasses.outlined}.${inputLabelClasses.focused}`]: {
                color: coloring,
                },
            }}
            style={{ id:"outlined-normal",width:"59%",marginRight:"15px"}} size="small" margin="normal" variant="outlined" label="* Study Name (press enter/tab to validate)" 
                disabled={myStudy.id !== 0}
                inputProps={{ maxLength: 50 }}
                InputLabelProps={{shrink: true}}
                onChange={e => setMyStudy(() => ({
                    ...myStudy,
                    studyName: e.target.value
                }))}
                onKeyDown={handleKeyPress}
                value={myStudy.studyName === null ? '' : myStudy.studyName}
                onBlur={(e) => HandleBlendStudyChangeAndValidate(e.target.value)}
                error={errorChecks.blendStudy.studyName === null ? false : errorChecks.blendStudy.studyName}
                helperText = {errorChecks.blendStudy.studyName ? studyNameErrorText : ''}
              ></TextField>
        </div>

        <div style={{display:"flex"}}>

            <StyledAutocomplete
                disabled={disableStudyInfo || errorChecks.blendStudy.studyName !== false}
                disablePortal
                noOptionsText={"Loading Types..."}
                options={availableStudyTypes}
                getOptionLabel={(option) => option.name}
                onChange={(e, value) => AutoCompleteChangeAndValidate("blendStudyType", value)}
                autoHighlight
                autoSelect
                value={myStudy.blendStudyType}
                isOptionEqualToValue={(option, value) => value.name === option.name}
                renderInput={(params) => <TextField {...params} variant="outlined" size="small" margin="normal" label="* Study Type" error={errorChecks.blendStudy.blendStudyType === null ? false : errorChecks.blendStudy.blendStudyType}
                helperText = {errorChecks.blendStudy.blendStudyType ? helperLabel : ''}
                InputLabelProps={{shrink: true}} InputProps={{ ...params.InputProps }}/>} 
            />

            <StyledAutocomplete
                disabled={disableStudyInfo || errorChecks.blendStudy.studyName !== false}
                disablePortal
                noOptionsText={"Loading Projects..."}
                options={availableProjects}
                getOptionLabel={(option) => option.name}
                onChange={(e, value) => AutoCompleteChangeAndValidate("project", value)}
                autoHighlight
                autoSelect
                value={myStudy.project}
                isOptionEqualToValue={(option, value) => value.name === option.name}
                renderInput={(params) => <TextField {...params} variant="outlined" size="small" margin="normal" label="* Project" error={errorChecks.blendStudy.project === null ? false : errorChecks.blendStudy.project}
                helperText = {errorChecks.blendStudy.project ? helperLabel : ''}
                InputLabelProps={{shrink: true}} InputProps={{ ...params.InputProps }}/>} 
            />

            <StyledShortTextField size="small" margin="normal" variant="outlined" label="PPM Reference" InputLabelProps={{shrink: true}}
                disabled={disableStudyInfo || errorChecks.blendStudy.studyName !== false}
                inputProps={{ maxLength: 20 }}
                onChange={e => setMyStudy(() => ({
                    ...myStudy,
                    ppmReference: e.target.value
                }))}
                value={myStudy.ppmReference === null ? '' : myStudy.ppmReference}
              ></StyledShortTextField>

             <StyledShortTextField size="small" margin="normal" variant="outlined" label="Study Purpose" InputLabelProps={{shrink: true}}
                disabled={disableStudyInfo || errorChecks.blendStudy.studyName !== false}
                 onChange={e => setMyStudy(() => ({
                    ...myStudy,
                    studyPurpose: e.target.value
                  }))}
                  value={myStudy.studyPurpose === null ? '' : myStudy.studyPurpose}
              ></StyledShortTextField>
        </div>

        <div style={{display:"flex"}}>

            <StyledMediumTextField size="small" margin="normal" variant="outlined" label="Notes" InputLabelProps={{shrink: true}}
                disabled={disableStudyInfo || errorChecks.blendStudy.studyName !== false}
                onChange={e => setMyStudy(() => ({
                    ...myStudy,
                    notes: e.target.value
                }))}
                value={myStudy.notes === null ? '' : myStudy.notes}
            ></StyledMediumTextField>
            
            <EmailAddressTextField
                fontSize = {16}
                fieldWidth = {"22%"} 
                validatedUserEmail = {myStudy.ownerEmail}
                setValidatedUserEmail = {(e) => SetOwnerEmail(e)}
                hasErrors={errorChecks.blendStudy.ownerEmail === null ? false : errorChecks.blendStudy.ownerEmail}
                setHasErrors = {(e) => updateErrorForOwnerEmail(e)}
                isDisabled={disableStudyInfo || errorChecks.blendStudy.studyName !== false}
                labelText="Owner Email"
                placeholderText="Owner Email"
                showPlusMeButton={true}
                size="small"
                margin="normal">
            </EmailAddressTextField>
        </div>
        <div style={{display:"flex"}}>
            <StyledMediumTextField size="small" margin="normal" variant="outlined" label="Add Keywords" InputLabelProps={{shrink: true}}
                disabled={disableStudyInfo || errorChecks.blendStudy.studyName !== false}
                onChange={e => setMyStudy(() => ({
                    ...myStudy,
                    keywords: e.target.value
                }))}
                value={myStudy.keywords === null ? '' : myStudy.keywords}
            ></StyledMediumTextField>

        </div>
            {formatCreatedAndModifiedDateInfo(myStudy.createdDate === null ? 'N/A' : myStudy.createdDate, 
                                myStudy.createdByEmail === null ||  myStudy.createdByEmail.trim() === '' ? 'N/A' : myStudy.createdByEmail,
                                myStudy.lastModifiedDate === null ? 'N/A' : myStudy.lastModifiedDate, 
                                myStudy.lastModifiedByEmail === null || myStudy.lastModifiedByEmail.trim() === '' ? 'N/A' : myStudy.lastModifiedByEmail)}
    </div>
  );
};



export default StudyInformation;