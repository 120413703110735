import { AxiosResponse } from "axios";

export class CustomNetworkError extends Error {
  status?: number;
  // eslint-disable-next-line no-useless-constructor
  constructor(message?: string, status?: number) {
    super(message);
    this.status = status;
  }
}

export const checkAPIResponse = (error: AxiosResponse) => {
  const { data, status } = error;
  const isSuccessful = status >= 200 && status < 300;
  if (!isSuccessful) {
    throw new CustomNetworkError(data.message, status);
  }
};
