import API from "../../api";

export default class BillingInfo {
  /**
   * The Billing Info dimension table for M3
   * @param {String} costCode The identifier for the Billing Info
   * @param {String} description The Description for the Billing Info
   * @param {bool} isActive The IsActive for the Billing Info
   */

  constructor({
    costCode,
    description,
    isActive
   }) 
  {
    this.costCode = costCode;
    this.description = description;
    this.isActive = isActive;
  }

  static async getAll() {
    const api = await API();
    const { data } = await api.get(`/billingInfo`);
    return data;
  }
}
