import API from "..";

export default class JapanGHSCode {
  /**
   * The JapanGHSCode dimension table for M3
   * @param TODO
   */
  constructor({
    ghsCodeName,
    description,
    isActive
  }) {
    this.ghsCodeName = ghsCodeName;
    this.description = description;
    this.isActive = isActive;
  }

  static async getAll() {
    const api = await API();
    const { data } = await api.get(`/japanGHSCode`);
    return data.result.map((d) => new JapanGHSCode(d));
  }
}