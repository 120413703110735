import API from "..";

export default class ContainerStatus {
  /**
   * The Container dimension table for M3
   * @param TODO
   */
  constructor({
    name,
    isActive,
  }) {
    this.name = name;
    this.isActive = isActive;
  }

  static async getAll() {
    const api = await API();
    const { data } = await api.get(`/containerStatus`);
    return data.result.map((d) => new ContainerStatus(d));
  }
}
