import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  TextField,
  FormControlLabel,
  Checkbox,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  Divider,
} from "@mui/material";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import EmailAddressTextField from "../../../components/EmailAddressField";
import { StockroomRequestContext } from "../context/StockroomRequestContext";
import { OrderStatusContext } from "../context/OrderStatusContext";
import VendorSearchField from "../../../components/VendorSearchField/VendorSearchField";
import RequestReportTemplate from "./RequestReportTemplate";
import { DatePickerKeyDownEvent } from "../../../global";
import { GlobalButton } from "../../styles";

const _emailOrBadge = { email: "email", badgeId: "badgeId" };

export const RequestsReport = ({ canEdit }) => {
  const [isOptionsExcluded, setIsOptionsExcluded] = useState(false);
  const [requesterEmail, setRequesterEmail] = useState("");
  const [stockRoomNumber, setStockRoomNumber] = useState("");
  const [requesterInvalid, setRequesterInvalid] = useState(false);
  const [emailOrBadge, setEmailOrBadge] = useState(_emailOrBadge.email);
  const [badgeId, setBadgeId] = useState(null);
  const [isValidBadgeId, setIsValidBadgeId] = useState(false);


  const day = new Date().getDate();
  const month = new Date().getMonth();
  const year = new Date().getFullYear();

  const [dateFrom, setDateFrom] = useState(null);
  const [dateTo, setDateTo] = useState(new Date(year, month, day));

  const { requests, getFilteredRequests } = useContext(StockroomRequestContext);
  const { orderStatuses, getAllOrderStatuses } = useContext(OrderStatusContext);

  useEffect(() => {
    if (!orderStatuses.length) {
      getAllOrderStatuses();
    }
  }, [getAllOrderStatuses, orderStatuses]);

  const handleApplyFilters = () => {
    let statuses = isOptionsExcluded
      ? orderStatuses.filter(
          (x) =>
            x.orderStatusName !== "Matching" &&
            x.orderStatusName !== "Returned" &&
            x.orderStatusName !== "Cancelled"
        )
      : orderStatuses;

    const startDateFormatted = `${new Date(dateFrom).getMonth() + 1}/${new Date(
      dateFrom
    ).getDate()}/${new Date(dateFrom).getFullYear()}`;
    const endDateFormatted = `${new Date(dateTo).getMonth() + 1}/${new Date(
      dateTo
    ).getDate()}/${new Date(dateTo).getFullYear()}`;
    getFilteredRequests({
      statuses: statuses.map((x) => x.orderStatusName).join(","),
      startDate: startDateFormatted,
      endDate: endDateFormatted,
      requester:
        emailOrBadge === _emailOrBadge.email ? requesterEmail : badgeId.BadgeID,
    });
  };

  const handleChange = (event) => {
    setEmailOrBadge(event.target.value);
  };



  return (
    <Box display="flex" gap={4} marginBottom={4}>
      <Box display="flex" flexDirection="column" gap={1}>
        <Box display="flex" alignItems="center" gap={2}>
          <FormControl
            style={{
              width: "150px",
            }}
          >
            <InputLabel id="demo-simple-select-label">Input type</InputLabel>
            <Select
              size="small"
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={emailOrBadge}
              label="Input type"
              onChange={handleChange}
              disabled={!canEdit}
            >
              <MenuItem value={_emailOrBadge.email}>Email</MenuItem>
              <MenuItem value={_emailOrBadge.badgeId}>Badge Id</MenuItem>
            </Select>
          </FormControl>
          {emailOrBadge === _emailOrBadge.email ? (
            <EmailAddressTextField
              fontSize={16}
              fieldWidth={"250px"}
              validatedUserEmail={requesterEmail}
              setValidatedUserEmail={setRequesterEmail}
              hasErrors={requesterInvalid}
              setHasErrors={(value) => setRequesterInvalid(value)}
              isDisabled={!canEdit}
              labelText="Requester"
              placeholderText="Email"
              showPlusMeButton={false}
              margin={"none"}
              bypassValidation={true}
            ></EmailAddressTextField>
          ) : (
            <VendorSearchField
              style={{
                width: "250px",
              }}
              value={badgeId}
              setValue={setBadgeId}
              isValid={isValidBadgeId}
              setIsValid={setIsValidBadgeId}
              isDisabled={!canEdit}
            />
          )}

          <TextField
            onChange={(e) => setStockRoomNumber(e.target.value)}
            value={stockRoomNumber}
            label="Stockroom #"
            size="small"
            disabled={!canEdit}
          />

          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DesktopDatePicker
              label="Search From"
              maxDate={new Date()}
              disabled={!canEdit}
              value={dateFrom}
              onChange={(e) => setDateFrom(e)}
              renderInput={(params) => <TextField onKeyDown={DatePickerKeyDownEvent} size="small" {...params} />}
            />

            <DesktopDatePicker
              label="To"
              value={dateTo}
              minDate={dateFrom}
              maxDate={new Date()}
              disabled={!canEdit}
              onChange={(e) => setDateTo(e)}
              renderInput={(params) => <TextField onKeyDown={DatePickerKeyDownEvent} size="small" {...params} />}
            />
          </LocalizationProvider>
          <GlobalButton variant="contained" onClick={handleApplyFilters} disabled={!canEdit}>
            Create report
          </GlobalButton>
        </Box>

        <FormControlLabel
          label="Exclude Matching, Cancelled & Returns"
          control={
            <Checkbox
              checked={isOptionsExcluded}
              onChange={(e) => setIsOptionsExcluded(e.target.checked)}
            />
          }
          disabled={!canEdit}
        />

        <Divider />
        <br />
        <Box height={"800px"}>
          {requests.length > 0 && <RequestReportTemplate requests={requests} />}
          {/* {requests.length > 0 && <RequestReportHtml requests={requests} />} */}
        </Box>
      </Box>
    </Box>
  );
};
