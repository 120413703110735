import API from "..";

export default class SupplierLot {
  /**
   * The Sample dimension table for M3
   * @param {String} lotName The MIDAS of the Sample
   */
  constructor({
    id,
    supplierTradenameID,
    supplierTradename,
    certificationStatusName,
    lotCertificationStatus,
    lotName,
    createdByEmail,
    createdDate,
    lastModifiedByEmail,
    lastModifiedDate,
    shelfLifeStartDate,
    shelfLifeExpirationDate,
    comments,
    supplierLocation,
    waiverReason,
    lastCertifiedByEmail,
    lastCertifiedDate,
    canBeRecertified,
    certificationTestResult
  }) {
    this.id = id;
    this.supplierTradenameID = supplierTradenameID;
    this.supplierTradename = supplierTradename;
    this.certificationStatusName = certificationStatusName;
    this.lotCertificationStatus = lotCertificationStatus;
    this.lotName = lotName;
    this.createdByEmail = createdByEmail;
    this.createdDate = createdDate;
    this.lastModifiedByEmail = lastModifiedByEmail;
    this.lastModifiedDate = lastModifiedDate;
    this.shelfLifeStartDate = shelfLifeStartDate;
    this.shelfLifeExpirationDate = shelfLifeExpirationDate;
    this.comments = comments;
    this.supplierLocation = supplierLocation;
    this.waiverReason = waiverReason;
    this.lastCertifiedByEmail = lastCertifiedByEmail;
    this.lastCertifiedDate = lastCertifiedDate;
    this.canBeRecertified = canBeRecertified;
    this.certificationTestResult = certificationTestResult;
  }

  static async getAll() {
    const api = await API();
    const { data } = await api.get(`/supplierlot`);

    return data.result.map((d) => new SupplierLot(d));
  }

  static async getBySupplierTradenameID(supplierTradenameID) {
    const api = await API();
    const { data } = await api.get(`/supplierlot?supplierTradenameID=${supplierTradenameID}`);

    return data.result.map((d) => new SupplierLot(d));
  }

  static async getByID(id) {
    const api = await API();
    const { data } = await api.get(`/supplierlot/${id}`);
    if (data.result) {
      return new SupplierLot(data.result);
    }
    else {
      return null;
    }
  }

  static async update(supplierlot) {
    const api = await API();
    const { data } = await api.put(`/supplierlot`, supplierlot);
    return data;
  }

  static async create(supplierlot) {
    const api = await API();
    const { data } = await api.post(`/supplierlot`, supplierlot);
    return data;
  }

  static async delete(id) {
    const api = await API();
    const { data } = await api.delete(`/supplierlot/${id}`);
    return data;
  }
}
