import React from "react";
import ModalSimpleButton from "../../../components/Modal/ModalSimpleButton";
import { ImageBox } from "../styles";
import { CircularProgress, Typography } from "@mui/material";
import noImage from "../../../assets/no_image.jpg";

export default function DisplayItemImage({
  itemID,
  setIsModalOpen,
  setItemID,
  setModalType,
  isModalOpen,
  imageLoading,
  itemImage,
  setItemImage,
}) {
  return (
    <ModalSimpleButton
      title={`Item #${itemID}`}
      buttonText="Close"
      buttonAction={() => {
        setIsModalOpen(false);
        setItemID("");
        setModalType("");
        setItemImage("");
      }}
      setOpen={setIsModalOpen}
      open={isModalOpen}
    >
      <ImageBox>
        {!itemImage && !imageLoading ? (
          <img src={noImage} alt={"Not available"} />
        ) : imageLoading ? (
          <Typography style={{ fontSize: 22, fontWeight: "bold" }}>
            Loading
            <CircularProgress size={22} style={{ marginLeft: 10 }} />
          </Typography>
        ) : (
          <img
            style={{ objectFit: "contain" }}
            src={itemImage}
            alt={itemImage && "Item representation"}
          />
        )}
      </ImageBox>
    </ModalSimpleButton>
  );
}
