import React, { useContext } from "react";
import {
    Box,
    FormControl,
    styled,
    TextField,
    Autocomplete
} from "@mui/material";
import STStatusComponent from "../../../api/StationaryTesting/STStatus";
import STStatusForm from "./STStatusForm";
import { getMessage, hasRole, Roles } from "../../../global";
import ModalSimpleButton from '../../../components/Modal/ModalSimpleButton';
import UserContext from "../../../context/UserContext";
import { GlobalButton, GlobalSecondaryButton } from "../../styles";

const StyledBox = styled(Box)(() => ({
    display: 'flex',
    marginBottom: "20px"
}));

const StyledFormControl = styled(FormControl)(() => ({
    display: 'flex',
    width: "50rem"
}));

const StyledDiv = styled('div')({
    display: 'flex',
    justifyContent: "space-between"
});

const STStatus = (props) => {

    const initialStateStatus = {
        id: undefined,
        statusName: "",
        isActive: false,
        displayOrder: "",
        displayColor: "#ffffff"
    };

    const validationsInitialState =
    {
        statusName: "",
    };

    const [isAdd, setIsAdd] = React.useState(false);
    const [infoData, setInfoData] = React.useState(initialStateStatus);
    const [statusList, setStatusList] = React.useState(null);
    const [invalidField, setInvalidField] = React.useState(validationsInitialState);

    const [modalSimpleButton, setModalSimpleButton] = React.useState(false);
    const [modalSimpleButtonText, setModalSimpleButtonText] = React.useState('');
    const [modalSimpleTitle, setModalSimpleTitle] = React.useState('');
    const [modalSimpleText, setModalSimpleText] = React.useState('');

    const currentUser = useContext(UserContext)
    const roles = currentUser?.idTokenClaims.roles

    const canEdit = hasRole(Roles.Developer, roles) || hasRole(Roles.MethodAdministrator, roles)

    function closeModalSimpleButton() {
        setModalSimpleButton(false);
    }

    function openModalSimpleButton(title, text, buttonText) {
        setModalSimpleButtonText(buttonText)
        setModalSimpleButton(true);
        setModalSimpleTitle(title);
        setModalSimpleText(text);
    }

    function handleSubmit(event) {
        event.preventDefault();

        if (!validateFields()) {
            let displayOrderExist = statusList.find(x => (x.displayOrder === infoData.displayOrder && x.statusName !== infoData.statusName))

            if (displayOrderExist === undefined) {
                if (infoData.id !== undefined)
                    saveStatus();
                else {

                    STStatusComponent.get(infoData.statusName).then((statusExists) => {
                        if ((statusExists !== undefined && statusExists !== null))
                            openModalSimpleButton('Fail', 'This status name already exist', 'Ok');
                        else saveStatus();
                    });
                }
            } else {
                openModalSimpleButton('Fail', 'This display order already exist', 'Ok');
            }
        }
    }

    function saveStatus() {
        let status = new STStatusComponent({
            statusName: infoData.statusName,
            isActive: infoData.isActive,
            displayOrder: infoData.displayOrder,
            displayColor: infoData.displayColor
        });

        if (infoData.id === undefined) {
            STStatusComponent.add(status).then((res) => {
                if (res) {
                    resetForm();
                    openModalSimpleButton('Success', 'Status saved with success', 'Ok');
                    loadStatusFilter();
                }
                else
                    openModalSimpleButton('Fail', 'Something went wrong, please try after sometime', 'Ok');
            });
        } else {
            STStatusComponent.update(status).then((res) => {
                if (res) {
                    resetForm();
                    openModalSimpleButton('Success', 'Status updated with success', 'Ok');
                    loadStatusFilter();
                }
                else
                    openModalSimpleButton('Fail', 'Something went wrong, please try after sometime', 'Ok');
            });
        }
    }

    function handleChangeIsAdd() {
        setIsAdd(true);
        resetForm();
    }


    function loadStatusFilter() {
        STStatusComponent.getAllStatuses().then((res) => {
            if (res.length > 0) {

                res.sort(function (a, b) {
                    return (a.displayOrder === null) - (b.displayOrder === null)
                        || a.displayOrder - b.displayOrder
                });

                let array = [];
                array.push({ id: undefined, statusName: "" });
                array.push(...res);

                setStatusList(array);
            }
        });
    }

    function validateFields() {
        let requiredStatusName = infoData.statusName === "" ? getMessage('REQUIRED_FIELD') : "";

        setInvalidField({
            ...invalidField,
            statusName: requiredStatusName
        });

        return ((requiredStatusName !== ""));
    }

    function resetForm() {

        setInfoData(initialStateStatus);
        setInvalidField(validationsInitialState);
    }

    function handleChangeSTStatusFilter(idSelected) {
        setInfoData(initialStateStatus);

        setInfoData(() => ({
            ...initialStateStatus,
            id: idSelected,
            statusName: idSelected
        }));

        setIsAdd(true);

        setInvalidField(validationsInitialState);

        if (idSelected !== "") {
            STStatusComponent.get(idSelected).then((status) => {
                if (status !== null) {

                    setInfoData(() => ({
                        ...infoData,
                        id: idSelected,
                        statusName: status.statusName,
                        displayOrder: parseInt(status.displayOrder),
                        isActive: status.isActive,
                        displayColor: status.displayColor
                    }));
                }
            });
        }
    }

    React.useMemo(() => {
        if (statusList === null) {
            loadStatusFilter();
        }
    }, [statusList])

    return (
        <div>
            <form onSubmit={handleSubmit}>
                <StyledDiv>
                    <div>
                        <StyledBox>
                            <StyledFormControl sx={{ m: 1, width: "35rem" }} variant='outlined'>

                                <StyledBox sx={{ justifyContent: "space-between" }}>
                                    <Autocomplete
                                        noOptionsText={"Loading Status..."}
                                        value={infoData.statusName === '' ? null : infoData.statusName}
                                        onChange={(e, value) => {
                                            handleChangeSTStatusFilter(value);
                                        }}
                                        disablePortal
                                        options={statusList ? statusList.map((status) => status.statusName) : []}
                                        sx={{ width: "35rem" }}
                                        renderInput={(params) => <TextField {...params} variant="outlined" size="small" margin="none" label="Status" />}
                                        autoSelect
                                    />

                                    <GlobalSecondaryButton sx={{ width: "8rem", marginLeft:"1rem" }}
                                        variant='contained'
                                        type="button"
                                        onClick={handleChangeIsAdd}
                                    >Add New</GlobalSecondaryButton>

                                </StyledBox>

                            </StyledFormControl>

                        </StyledBox>

                        {isAdd === true && <STStatusForm
                            infoData={infoData} setInfoData={setInfoData}
                            invalidField={invalidField}
                            handleChangeSTStatusFilter={handleChangeSTStatusFilter}
                            canEdit={canEdit}
                        >
                        </STStatusForm>}

                        {
                            isAdd === true &&
                            <div>
                                <StyledBox sx={{ mb: 1, mt: 2, justifyContent: "space-between" }}>
                                    <GlobalButton sx={{ width: "8rem" }}
                                        variant='contained'
                                        type="submit"
                                        disabled={!canEdit}>Save</GlobalButton>
                                </StyledBox>
                            </div>
                        }

                    </div>

                </StyledDiv>

            </form >

            <ModalSimpleButton title={modalSimpleTitle} buttonText={modalSimpleButtonText} buttonAction={closeModalSimpleButton} open={modalSimpleButton} setOpen={setModalSimpleButton}>
                <label>
                    {modalSimpleText}
                </label>
            </ModalSimpleButton>

        </div >
    )
}

export default STStatus;