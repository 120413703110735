import React, { useState } from "react";
import {
  Box,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import {
  StyledTableBody,
  StyledTableRow,
  UXDataTableWithoutBody,
} from "../../../components/UXDataTable";
import { StyledTableCellSmall } from "../styles";
import { GlobalButton } from "../../styles";
import { exportToCsv } from "../../../global";

const buildPOCols = [
  "Stockroom #",
  "Description",
  "Location",
  "Inventory",
  "Sell Price",
  "Sell Unit",
  "SUinRU",
  "Acct",
  "Total Cost",
];

export const TwoTwoFiveReport = ({ stockItems, canEdit }) => {
  const [targetCostCenter, setTargetCostCenter] = useState("225");
  const [stockroomItemRowsPerPage, setStockroomItemRowsPerPage] = useState(10);
  const [stockroomItemsPage, setStockroomItemsPage] = useState(0);

  const handleBuildPOPageChange = (event, page) => {
    setStockroomItemsPage(page);
  };

  const handleChangeBuildPORowsPerPage = (event) => {
    setStockroomItemRowsPerPage(parseInt(event.target.value, 10));
    setStockroomItemsPage(0);
  };
  let filteredStockroomItems =
    targetCostCenter === "225"
      ? stockItems.filter((item) => item.costCenter === "225")
      : stockItems;
  
  return (
    <Box>
      <Box display="flex" gap={4} marginBottom={4}>
        <Box display="flex" flexDirection={"column"}>
          <Box>Select View</Box>
          <Box display="flex" flexDirection={"row"}>
            <FormControl>
              <FormLabel id="selectView">
                <RadioGroup
                  value={targetCostCenter}
                  onChange={(e, value) => setTargetCostCenter(value)}
                  name="select-view"
                  style={{ display: "flex", flexDirection: "row" }}
                >
                  <FormControlLabel
                    value="225"
                    control={<Radio />}
                    label={"225 Only"}
                  ></FormControlLabel>
                  <FormControlLabel
                    value="225+"
                    control={<Radio />}
                    label={"All Cost Center"}
                  ></FormControlLabel>
                </RadioGroup>
              </FormLabel>
            </FormControl>
          </Box>
        </Box>

        <Box display="flex" alignItems="center">
          <GlobalButton
            style={{ marginRight: "2rem", marginTop: "8%"}}
            variant="contained"
            onClick={(e) =>
              exportToCsv(
                filteredStockroomItems.map((item) => {
                  return {
                    "Stockroom number": item.siteAssetNumber,
                    Description: item.description,
                    Location: item.subLocation,
                    Inventory: item.quantity,
                    "Sell Price": item.equipmentPriceUSD,
                    "Sell Unit": item.stockUnitOfMeasure,
                    SUinRU: item.suinru,
                    Acct: item.costCenter,
                    "Total Cost": (item.equipmentPriceUSD * item.quantity).toFixed(2)
                  };
                }),
                "Inventory Reports"
              )
            }
            disabled={!canEdit}
          >
            Export CSV
          </GlobalButton>
        </Box>
      </Box>
      <UXDataTableWithoutBody
        tableWidth="100%"
        cols={buildPOCols}
        tableId="buildPOTable"
        rowLength={filteredStockroomItems.length}
        enablePaging={true}
        rowsPerPage={stockroomItemRowsPerPage}
        page={stockroomItemsPage}
        handleChangePage={handleBuildPOPageChange}
        handleChangeRowsPerPage={handleChangeBuildPORowsPerPage}
        noDataFoundMessage={"No built POs found"}
      >
        <StyledTableBody>
          {filteredStockroomItems.length > 0 &&
            filteredStockroomItems
              .slice(
                stockroomItemsPage * stockroomItemRowsPerPage,
                stockroomItemsPage * stockroomItemRowsPerPage +
                stockroomItemRowsPerPage
              )
              .map((item) => {
                return (
                  <StyledTableRow key={item?.id} hover>
                    <StyledTableCellSmall>
                      {item.siteAssetNumber}
                    </StyledTableCellSmall>
                    <StyledTableCellSmall>
                      {item.description}
                    </StyledTableCellSmall>
                    <StyledTableCellSmall>
                      {item.subLocation}
                    </StyledTableCellSmall>
                    <StyledTableCellSmall>{item.quantity}</StyledTableCellSmall>
                    <StyledTableCellSmall>
                      {item.equipmentPriceUSD.toFixed(2)}
                    </StyledTableCellSmall>
                    <StyledTableCellSmall>
                      {item.stockUnitOfMeasure}
                    </StyledTableCellSmall>
                    <StyledTableCellSmall>{item.suinru}</StyledTableCellSmall>
                    <StyledTableCellSmall>
                      {item.costCenter}
                    </StyledTableCellSmall>
                    <StyledTableCellSmall>
                      {(item.equipmentPriceUSD * item.quantity).toFixed(2)}
                    </StyledTableCellSmall>
                  </StyledTableRow>
                )
              })}
        </StyledTableBody>
      </UXDataTableWithoutBody>
    </Box>
  );
};
