import {formatMidasNumber, convertToLocalTime} from "../../global";
//import MoreOptions from "@mui/icons-material/MoreVert";
import {useState} from "react";
import { UXDataTableWithoutBody, StyledTableCell, UXDataTableNested, StyledTableBody, StyledTableRow } from "../../components/UXDataTable";
import {IconButton,TableRow} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

const stRequestCols = ['ID', 'Intervals', 'Card Number', 'Test Name', 'Type', 'Run Number', 'MIDAS #', 'Requester', 'Date Created', 'Started On', 'Stopped On', 'Facility', 'Test Stand', 'Status']
const intervalCols = ['Interval Name', "Midas #", 'ChemID', 'Initial Location','Return Location']

const SearchStationaryTests = ({inputSearchCriteria, myStRequests, dataIsLoading}) => {
  const [intervalRowOpen, setIntervalRowOpen] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  const noDataMessage = `No stationary request info found matching the quick search for = ${inputSearchCriteria}`

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleIntervalsRowClick = (stRequestID) => {
    const intervalRowOpenIndex = intervalRowOpen.indexOf(stRequestID);
    let newIntervalRowOpen = [];

    if (intervalRowOpenIndex === -1) {
      newIntervalRowOpen = newIntervalRowOpen.concat(intervalRowOpen, stRequestID);
    } else if (intervalRowOpenIndex === 0) {
      newIntervalRowOpen = newIntervalRowOpen.concat(intervalRowOpen.slice(1));
    } else if (intervalRowOpenIndex === intervalRowOpen.length - 1) {
      newIntervalRowOpen = newIntervalRowOpen.concat(intervalRowOpen.slice(0, -1));
    } else if (intervalRowOpenIndex > 0) {
      newIntervalRowOpen = newIntervalRowOpen.concat(
        intervalRowOpen.slice(0, intervalRowOpenIndex),
        intervalRowOpen.slice(intervalRowOpenIndex + 1)
      );
    }
    setIntervalRowOpen(newIntervalRowOpen);
  };

  const determineStartArray = () => {
    return (myStRequests.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage))
  }

  const isIntervalRowOpen = (stRequestID) => intervalRowOpen.indexOf(stRequestID) !== -1;

  return (
    <>
    <UXDataTableWithoutBody 
      tableWidth='100%' 
      cols={stRequestCols} 
      blankFirstHeader={false}
      tableId="myWRTable"
      rowLength={myStRequests ? myStRequests.length : 0}
      enablePaging={true}
      rowsPerPage={rowsPerPage}
      page={page}
      handleChangePage={handleChangePage}
      handleChangeRowsPerPage={handleChangeRowsPerPage}
      noDataFoundMessage={noDataMessage}
      enableAddIcon={false}
      addFunction={null}
      addToolTipText={null}  
      isDataLoading={dataIsLoading}
      >

        {myStRequests && myStRequests.length > 0 ? (
          determineStartArray().map((stRequest, index) => {
          
            return (
              <StyledTableBody key={"myStRequestsTablebody" + index}> 
                <StyledTableRow>
                  {/* <StyledTableCell component='th' scope='row' style={{width:'2rem'}}>
                    <MoreOptions color='black' />
                  </StyledTableCell> */}

                  <StyledTableCell component='th' scope='row'>{stRequest.id}</StyledTableCell>

                    <StyledTableCell align='right' style={{width:'5rem'}}>

                    {stRequest.requestIntervals.length}

                      <IconButton
                        aria-label='expand row'
                        size='small'
                        onClick={() => handleIntervalsRowClick(stRequest.id)}>
                        {isIntervalRowOpen(stRequest.id) ? (
                          <KeyboardArrowUpIcon/>
                        ) : (
                          <KeyboardArrowDownIcon/>
                        )}
                      </IconButton>
                    </StyledTableCell>
                    <StyledTableCell>{stRequest.cardNumber}</StyledTableCell>
                    <StyledTableCell>{stRequest.testDisplayName}</StyledTableCell>
                    <StyledTableCell>{stRequest.testTypeName}</StyledTableCell>
                    <StyledTableCell>{stRequest.runNumber}</StyledTableCell>
                    <StyledTableCell sx={{minWidth:"8rem"}}>{`${formatMidasNumber(stRequest.submittedSampleName)}/${stRequest.submittedContainerNumber}`}</StyledTableCell>
                    <StyledTableCell>{stRequest.requestedByEmail}</StyledTableCell>
                    <StyledTableCell>{convertToLocalTime(stRequest.createdDate)}</StyledTableCell>
                    <StyledTableCell>{convertToLocalTime(stRequest.testStartDate)}</StyledTableCell>
                    <StyledTableCell>{convertToLocalTime(stRequest.testEndDate)}</StyledTableCell>
                    <StyledTableCell>{stRequest.stationaryTestFacilityName}</StyledTableCell>
                    <StyledTableCell>{stRequest.testStandName}</StyledTableCell>
                    <StyledTableCell>{stRequest.statusName}</StyledTableCell>
                  </StyledTableRow>

                {isIntervalRowOpen(stRequest.id) && (
                  <UXDataTableNested 
                  tableWidth={'100%'} 
                  cols={intervalCols} 
                  blankFirstHeader={false} 
                  colSpan={stRequestCols.length + 1} 
                  isOpenComparator={stRequest.id} 
                  isOpenArray={intervalRowOpen}>

                    {stRequest.requestIntervals.map((intervals) => (
                    <StyledTableBody key={`${stRequest.id}${intervals.id}_"intervalstablebody"`}>
                      <StyledTableRow>
                        <StyledTableCell>{intervals.intervalName}</StyledTableCell>
                        <StyledTableCell>{formatMidasNumber(intervals.sampleName)}</StyledTableCell>
                        <StyledTableCell>{intervals.chemID}</StyledTableCell>
                        <StyledTableCell>{intervals.initialLocationName}</StyledTableCell>
                        <StyledTableCell>{intervals.returnLocationName}</StyledTableCell>
                      </StyledTableRow>
                      
                      </StyledTableBody>
                      ))}
                      </UXDataTableNested>
                    )}
                
                </StyledTableBody>
            );
          })
        ) : (
          <StyledTableBody key={"no ST Requests found"}>
            <TableRow key={"nothing found st"}>
            <StyledTableCell colSpan='14' key={"nothing found cells st"}>
              <p>{noDataMessage}</p>
            </StyledTableCell>
          </TableRow>
          </StyledTableBody>
        )}
    </UXDataTableWithoutBody>
    </>
  );
};

export default SearchStationaryTests;
