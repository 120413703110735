import * as Yup from "yup";
import type { Interval } from "../types";

export const duplicateNameExists = (list: string[], caseSensitive: boolean = false) => {
  const set = caseSensitive ? new Set(list) : new Set(list.map((i) => i.toLowerCase()));
  return set.size !== list.length;
};

export const AddIntervalRowSchema = (intervals: Interval[]) => {
  return Yup.object().shape({
    intervalName: Yup.string()
      .required("Interval name is required")
      .test("Unique", "Interval name must be unique", (value, context) => {
        const id = context.parent.id;

        const updatedIntervalNames = [
          ...intervals
            .filter((interval) => interval.id !== id && interval.intervalName.trim() !== "")
            .map((interval) => interval.intervalName),
          value.toLocaleLowerCase(),
        ];

        const result = duplicateNameExists(updatedIntervalNames);
        return !result;
      }),
    chemID: Yup.string().required("A valid Chem ID is required"),
    psimsId: Yup.string(),
    initialLocationName: Yup.string().required("Initial location is required").min(1),
    returnLocationName: Yup.string(),
  });
};

export const DetailedProcessRequestSchema = (billingCodes: string[]) => {
  return Yup.object().shape({
    billingReference: Yup.string()
      .required("A valid JADE Number is required")
      .oneOf(billingCodes, "A valid JADE Number is required"),
  });
};
