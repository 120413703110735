import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import Autocomplete from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TextField from "@mui/material/TextField";
import { useFormikContext } from "formik";
import { useEffect, useMemo, useState } from "react";
import Instrument from "../../../../../api/Admin/Instrument";
import { SqcMethodManagementForm } from "../../form";
import Skeleton from "@mui/material/Skeleton";

type InstrumentsModalProps = {
  onClose(): void;
};

export function InstrumentsModal({ onClose }: InstrumentsModalProps) {
  const [loading, setLoading] = useState(false);
  const [instruments, setInstruments] = useState<Instrument[]>([]);

  const [selectedInstrument, setSelectedInstrument] =
    useState<Instrument | null>(null);

  useEffect(() => {
    setLoading(true);

    Instrument.getAll()
      .then(setInstruments)
      .finally(() => setLoading(false));
  }, []);

  const formik = useFormikContext<SqcMethodManagementForm>();

  const handleAdd = () => {
    if (!selectedInstrument) return;

    formik.setFieldValue(
      "methodFacility.availableInstruments",
      formik.values.methodFacility?.availableInstruments.concat({
        instrumentID: selectedInstrument.id,
        isActive: selectedInstrument.isActive,
      })
    );

    setSelectedInstrument(null);
  };

  const handleDelete = (index: number) => {
    formik.setFieldValue(
      "methodFacility.availableInstruments",
      formik.values.methodFacility?.availableInstruments.filter(
        (t, i) => i !== index
      )
    );
  };

  const activeInstruments = useMemo(
    () => instruments.filter((i) => i.isActive),
    [instruments]
  );

  return (
    <Dialog open={true} onClose={onClose}>
      <DialogTitle>Instruments</DialogTitle>

      <DialogContent dividers>
        <Stack gap="1rem">
          <Stack direction="row" gap="0.5rem" sx={{ alignItems: "center" }}>
            <Autocomplete
              size="small"
              loading={loading}
              options={activeInstruments}
              getOptionLabel={(option) => option.name}
              getOptionDisabled={(option) =>
                formik.values.methodFacility ? formik.values.methodFacility?.availableInstruments.some(
                  (a) => a.instrumentID === option.id
                ) : false
              }
              renderOption={(props, option) => (
                <li {...props}>{option.name}</li>
              )}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              renderInput={(params) => (
                <TextField {...params} label="Instrument" />
              )}
              value={selectedInstrument}
              onChange={(event, value) => setSelectedInstrument(value)}
              sx={{ width: "16rem" }}
            />

            <Button
              type="button"
              disabled={!selectedInstrument}
              onClick={handleAdd}
            >
              Add
            </Button>
          </Stack>

          <TableContainer>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell sx={{ width: "4rem" }} />
                  <TableCell>Name</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {formik.values.methodFacility?.availableInstruments.map(
                  (a, index) => {
                    const instrument = instruments.find(
                      (i) => i.id === a.instrumentID
                    );

                    return (
                      <TableRow key={a.instrumentID}>
                        <TableCell>
                          <IconButton onClick={() => handleDelete(index)}>
                            <DeleteForeverIcon />
                          </IconButton>
                        </TableCell>
                        <TableCell>
                          {loading ? (
                            <Skeleton
                              variant="text"
                              sx={{ fontSize: "1rem" }}
                            />
                          ) : (
                            instrument?.name
                          )}
                        </TableCell>
                      </TableRow>
                    );
                  }
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Stack>
      </DialogContent>

      <DialogActions>
        <Button type="button" onClick={onClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
