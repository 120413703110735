import { Box, Typography } from '@mui/material'
import { styled } from '@mui/material/styles';

export const WidgetsWrapper = styled(Box)({
  display: 'grid',
  gridTemplateColumns: "1fr 1fr",
  gap: 8,
  width: '35%',
})

export const ListWrapper = styled('ul')({
  display: 'flex',
  flexDirection: 'column',
  gap: 8,
  listStyle: 'none',
})

export const InfoText = styled(Typography)({
  fontSize: 18,
  paddingLeft: 16,
})

export const GroupTitle = styled(Typography)({
  fontSize: 24,
  fontWeight: 'bold',
  color: '#1976d2'
})

export const ListItem = styled('li')({
  paddingLeft: 16,
  position: 'relative',

  '&::before': {
    content: "''",
    position: 'absolute',
    left: 0,
    top: '50%',
    transform: 'translate(0, -50%)',
    width: 5,
    height: 5,
    background: 'black',
    borderRadius: '50%'
  }
})