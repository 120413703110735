import API from '../index'

export default class STType {
    constructor(
        { typeName,
            isActive,
            description }
    ) {
        this.typeName = typeName
        this.isActive = isActive
        this.description = description
    }

    static async getAll() {
        const api = await API()
        const { data } = await api.get('/sttype')
        return data.result.map(type => new STType(type))
    }

    static async get(name) {
        const api = await API();
        const { data } = await api.get(`sttype/${name}`);
        if (data.result) {
            return new STType(data.result);
        }
        else {
            return null;
        }
    }

    static async add(payload) {
        const api = await API();
        const { data } = await api.post('/sttype', payload);
        return new STType(data.result);
    }

    static async update(payload) {
        const api = await API();
        const { data } = await api.put('/sttype', payload);
        return new STType(data.result);
    }

    static async getAllTypeNames() {
        const api = await API()
        const { data } = await api.get('/sttype')
        if (data && data.result) {
            return data.result.map(a => a.typeName)
        }
        return []
    }
}
