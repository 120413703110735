export default class TestConditionInstanceValue {
    /**
     * The Container dimension table for M3
     * @param TODO
     */
    constructor({
      testID,
      testConditionName,
      testConditionValue,
      uomName,
    }) {
      this.testID = testID;
      this.testConditionName = testConditionName;
      this.testConditionValue = testConditionValue;
      this.uomName = uomName;
    }

}