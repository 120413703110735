import React, { useState, useEffect } from "react";
import {Box} from "@mui/material";
import MethodSelection from "../../../components/MethodSelection";

const drawerWidth = 250

const AddMethods = ({ mySampleInfo, setMySampleInfo }) => {

 const [tempSelectedMethods, setTempSelectedMethods] = useState(mySampleInfo.masterMethods)


 useEffect(() => {
      let copyMySampleInfo = JSON.parse(JSON.stringify(mySampleInfo))
      copyMySampleInfo.masterMethods = tempSelectedMethods

      for(var iSample = 0; iSample < copyMySampleInfo.samples.length; iSample++)
      {
            copyMySampleInfo.samples[iSample].methods = tempSelectedMethods

            for (var iMethod = 0; iMethod < copyMySampleInfo.samples[iSample].methods.length; iMethod++)
            {
                  copyMySampleInfo.samples[iSample].methods[iMethod].createFromContainer = null
                  copyMySampleInfo.samples[iSample].methods[iMethod].containerSelectionType = null
            }
      }     
            
      setMySampleInfo(copyMySampleInfo)

// eslint-disable-next-line react-hooks/exhaustive-deps
}, [tempSelectedMethods])
    
return (
       <div>
            <p style={{
               textAlign:"left", 
               color:"#000000DE",
               fontFamily:"EMprint",
               fontWeight:"600",
               fontSize: "16px",
               paddingTop:"2rem",
               paddingLeft:"0rem",
               paddingBottom:"2rem"
            }}
            >Add Methods</p>

            <Box style={{width:`calc(115% - ${drawerWidth}px)`}}>
                  <MethodSelection 
                        width={'90%'} 
                        selectedMethods={tempSelectedMethods} 
                        setSelectedMethods={setTempSelectedMethods} 
                        showCompletionDate={true} 
                        showContainerGrouping={false}
                        incomingTemplate={null}
                        isReadOnly={false}
                        showSearchTemplateName={true}
                        showEstimateAndPriority={true}
                        maxMethodsAllowed={999}
                    >
                  </MethodSelection>

            </Box>
      </div>

 );
};  

export default AddMethods;