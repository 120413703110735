import API from "..";

export default class BlendFacility {

  constructor({
    blendFacilityName,
    isActive,
    siteName,
    preparationThresholdPercent
}) 
    
  {
    this.blendFacilityName = blendFacilityName;
    this.isActive = isActive;
    this.siteName = siteName;
    this.preparationThresholdPercent = preparationThresholdPercent;
  }

  static async getAllFacilities() {
    const api = await API();
    const { data } = await api.get(`/blendFacility`);
    if (data.result) {
        return data.result.map((d) => new BlendFacility(d));
    }
    else {
        return [];
    }
  }
}
