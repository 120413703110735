import { createContext, useState } from 'react'
import Request from '../../../api/Inventory/NewChemicalAuthorizationRequest'

export const NewChemicalAuthorizationRequestContext = createContext(null)

export const NewChemicalAuthorizationRequestContextProvider = ({ children }) => {
    const [requests, setRequests] = useState([])

    const get = async () => {
        const data = await Request.get();
        setRequests(data)
    }

    const createNewChemicalAuthorizationRequest = async (payload) => {
        return await Request.chemicalAuthorizationRequest(payload)
    }
    const UpdateChemicalAuthorizationRequest = async (payload) => {
        return await Request.UpdateChemicalAuthorizationRequest(payload)
    }

    return (
        <NewChemicalAuthorizationRequestContext.Provider value={{ requests, get,createNewChemicalAuthorizationRequest , UpdateChemicalAuthorizationRequest }}>
            {children}
        </NewChemicalAuthorizationRequestContext.Provider>
    )
}