import React, { useState } from "react";
import ModalTwoButtons from "../../../components/Modal/ModalTwoButtons";
import { TextField } from "@mui/material";
import { StyledErrorMessage } from "../styles";

export default function UpdateItemQuantity({
  inventoryItems,
  selectedItem,
  quantity,
  selectedItems,
  setSelectedItems,
  setSelectedItem,
  setQuantity,
  setIsModalOpen,
  setModalType,
  isModalOpen,
}) {
  const [errorMessage, setErrorMessage] = useState("");

  return (
    <ModalTwoButtons
      title="Update item from list"
      button1Text="Update"
      button1Action={() => {
        setErrorMessage("");
        const originalItem = inventoryItems.find(
          (item) => item?.id === selectedItem?.id
        );
        if (originalItem?.quantity < quantity) {
          setErrorMessage(
            `Quantity desired is above item's stock count of ${originalItem?.quantity}`
          );
        } else if (quantity <= 0) {
          setErrorMessage("Quantity value has to be at least 1");
        } else {
          const updatedList = selectedItems.map((item) => {
            if (item?.id === selectedItem?.id) {
              let updatedItem = JSON.parse(JSON.stringify(item));
              updatedItem = {
                ...updatedItem,
                quantity: quantity,
              };
              return updatedItem;
            }
            return item;
          });
          setErrorMessage("");
          setSelectedItems(updatedList);
          setSelectedItem({});
          setQuantity(1);
          setIsModalOpen(false);
          setModalType("");
        }
      }}
      button2Text="Cancel"
      button2Action={() => {
        setSelectedItem({});
        setIsModalOpen(false);
        setQuantity(1);
        setModalType("");
      }}
      setOpen={setIsModalOpen}
      open={isModalOpen}
    >
      <>
        <TextField
          size="small"
          type="number"
          name="quantity"
          label="Quantity"
          min={1}
          value={quantity}
          onChange={(event) => setQuantity(event.target.value)}
          sx={{ marginTop: "0.5rem" }}
        />
        {errorMessage && (
          <StyledErrorMessage>{errorMessage}</StyledErrorMessage>
        )}
      </>
    </ModalTwoButtons>
  );
}
