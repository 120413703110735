import { useState, useContext, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import FilterMenu from "../../components/FilterMenu";
import UserContext from "../../context/UserContext";
import { applyFiltersToArray } from "../../global";
import STRequest from "../../api/StationaryTesting/STRequest";
import STStatus from "../../api/StationaryTesting/STStatus";
import { GlobalButton, GlobalSecondaryButton } from "../styles";
import { FILTER_INITIAL_STATE, ST_STATUS_ENUM, filterOptions } from "./shared";
import { SortableTable } from "../../components/SortableTable/SortableTable";
import CreateRequestsAdditionActions from "./components/CreateRequestsAdditionActions";
import { createRequestsCols } from "./tableColumns";
import type { AdditionalMenuAction } from "./types";
import STQueue from "../../api/StationaryTesting/STQueue";
import STType from "../../api/StationaryTesting/STType";

const CreateRequest = () => {
  const [statuses, setStatuses] = useState([]);
  const [queues, setQueues] = useState<string[]>([]);
  const [types, setTypes] = useState<string[]>([]);
  const [requests, setRequests] = useState([]);
  const [isRequestsLoading, setIsRequestsLoading] = useState(false);
  const [filteringOpen, setFilteringOpen] = useState(false);
  const currentUser = useContext(UserContext);
  const [filters, setFilters] = useState<any>(FILTER_INITIAL_STATE);
  const history = useHistory();

  const getAllRequests = async () => {
    setIsRequestsLoading(true);
    const data = await STRequest.getUserRequests(currentUser.username);
    setRequests(data);
    setIsRequestsLoading(false);
  };

  const getAllStatuses = async () => {
    const data = await STStatus.getAllActiveStatusNamesOrdered();
    setStatuses(data);
  };

  const getAllQueues = async () => {
    const data: string[] = await STQueue.getAllActiveQueueOptions();
    setQueues(data);
  };

  const getAllTypes = async () => {
    const data: string[] = await STType.getAllTypeNames();
    setTypes(data);
  };

  const filteredRequests =
    filters && filters[0] && filters[0].name === ""
      ? requests.filter(
          (r: any) =>
            r.statusName !== ST_STATUS_ENUM.Cancelled &&
            r.statusName !== ST_STATUS_ENUM.Completed
        )
      : applyFiltersToArray(filters, requests);

  useEffect(() => {
    getAllRequests();
    getAllStatuses();
    getAllQueues();
    getAllTypes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const additionalMenuActions: AdditionalMenuAction[] = [
    {
      label: "View Request",
      action: (row) => history.push("/newStationaryRequest", { id: row.id }),
    },
  ];

  return (
    <>
      <Box sx={{ bgcolor: "#fff", pt: 3, pb: 1 }}>
        <Box display="flex" alignItems="center" gap={2}>
          <Typography fontSize={18}>My Experiments Requests</Typography>
          <Box>
            <GlobalButton
              variant="contained"
              // @ts-ignore
              component={Link}
              to="/newStationaryRequest"
            >
              Create New Request
            </GlobalButton>
          </Box>
          <Box display="flex" alignItems="center" marginLeft="auto">
            <GlobalButton
              style={{ marginRight: "1rem" }}
              variant="contained"
              onClick={() => setFilteringOpen(true)}
            >
              Filters
            </GlobalButton>
            {!(filters[0].name === null) && (
              <GlobalSecondaryButton
                variant="contained"
                onClick={() => {
                  setFilters(FILTER_INITIAL_STATE);
                }}
              >
                Clear Filters
              </GlobalSecondaryButton>
            )}
          </Box>
        </Box>
        <Box sx={{ p: 1 }} />
      </Box>
      <SortableTable
        columns={createRequestsCols as any}
        count={filteredRequests ? filteredRequests.length : 0}
        loading={isRequestsLoading}
        rows={filteredRequests}
        rowsPerPage={10}
        initialOrderBy="createdDate"
        children={
          <CreateRequestsAdditionActions
            additionalMenuActions={additionalMenuActions}
            row={null}
          />
        }
        initialAscendingOrder={false}
      />
      <FilterMenu
        open={filteringOpen}
        setOpen={setFilteringOpen}
        applyBtnAction={() => setFilteringOpen(false)}
        cancelButtonAction={() => setFilteringOpen(false)}
        filteringInfo={[
          ...filterOptions,
          {
            name: "statusName",
            displayName: "Status",
            type: "enum",
            enumValues: statuses,
          },
          {
            name: "workGroupName",
            displayName: "Queue",
            type: "enum",
            enumValues: queues,
          },
          {
            name: "testTypeName",
            displayName: "Test Type",
            type: "enum",
            enumValues: types,
          },
        ]}
        appliedFilters={filters}
        setAppliedFilters={setFilters}
        children={undefined}
      />
    </>
  );
};

export default CreateRequest;
