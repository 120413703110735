import { getDeviceDefinition } from "./devices";
import { Subscribable } from "./subscribable";
import {
  SERIAL_PORT_CONNECTION_STATE,
  SerialPortConnectionState,
  SerialPortDevice,
  SerialPortMessage,
  SerialPortMessageCallback,
} from "./types";

export class ManagedSerialPortConnection extends Subscribable<SerialPortMessageCallback> {
  private _serialPort: SerialPort | null = null;
  private _reader: ReadableStreamDefaultReader | null = null;
  private _keepReading = true;
  private _lastUpdateTimestamp: number;
  private _state: SerialPortConnectionState =
    SERIAL_PORT_CONNECTION_STATE.Disconnected;

  constructor() {
    super();
    this._lastUpdateTimestamp = Date.now();
  }

  async connect(filters?: SerialPortFilter[]): Promise<void> {
    try {
      this._updateState(SERIAL_PORT_CONNECTION_STATE.Connecting);

      this._serialPort = await navigator.serial.requestPort({
        filters: filters ?? [],
      });

      this._serialPort.addEventListener("disconnect", () =>
        this._handlePhysicalDisconnect()
      );

      this._updateState(SERIAL_PORT_CONNECTION_STATE.Connected);
    } catch (e) {
      this._updateState(SERIAL_PORT_CONNECTION_STATE.Disconnected);
      throw e;
    }
  }

  async open(options: SerialOptions, readUntilClosed = false): Promise<void> {
    if (!this._serialPort) throw new Error("Serial Port is disconnected");

    await this._serialPort.open(options);
    this._updateState(SERIAL_PORT_CONNECTION_STATE.Open);
    if (readUntilClosed) this.readUntilCanceled();
  }

  async disconnect(forget = false): Promise<void> {
    if (!this._serialPort) throw new Error("Serial Port is disconnected");

    if (this._reader) await this.close();
    if (forget) await this._serialPort.forget();
    this._serialPort = null;
    this._updateState(SERIAL_PORT_CONNECTION_STATE.Disconnected);
  }

  async close(): Promise<void> {
    if (!this._serialPort) throw new Error("Serial Port is disconnected");

    this._keepReading = false;
    await this._reader?.cancel();
    this._reader = null;
    await this._serialPort.close();
    this._updateState(SERIAL_PORT_CONNECTION_STATE.Connected);
  }

  async write(value: Uint8Array): Promise<void> {
    if (!this._serialPort) throw new Error("Serial Port is disconnected");

    const writer = this._serialPort.writable.getWriter();
    await writer?.write(value);
    writer?.releaseLock();
  }

  async readOnce(buffer: ArrayBuffer): Promise<Uint8Array> {
    if (!this._serialPort) throw new Error("Serial Port is disconnected");

    if (this._serialPort.readable.locked) {
      throw new Error("Stream was locked by another consumer");
    }

    this._reader = null;
    const reader = this._serialPort.readable.getReader({ mode: "byob" });

    let offset = 0;
    while (offset < buffer.byteLength) {
      const { value, done } = await reader.read(new Uint8Array(buffer, offset));

      if (done) {
        break;
      }

      buffer = value.buffer;
      offset += value.byteLength;
    }

    const value = new Uint8Array(buffer);
    await reader.cancel();
    reader.releaseLock();

    this._notifySubscribers({
      type: "value",
      value,
      timestamp: Date.now(),
    });

    return value;
  }

  async readUntilCanceled(): Promise<void> {
    if (!this._serialPort) throw new Error("Serial Port is nullish");

    if (this._serialPort.readable.locked) {
      throw new Error("Stream was locked by another consumer");
    }

    this._keepReading = true;
    while (this._serialPort.readable && this._keepReading) {
      this._reader = this._serialPort.readable.getReader();

      try {
        while (true) {
          const { done, value } = await this._reader.read();

          if (done) {
            break;
          }

          this._notifySubscribers({
            type: "value",
            value,
            timestamp: Date.now(),
          });
        }
      } catch (error) {
        throw error;
      } finally {
        this._reader.releaseLock();
      }
    }
  }

  async stopReading(): Promise<void> {
    this._keepReading = false;
    await this._reader?.cancel();
  }

  getDeviceDefinition(): SerialPortDevice | null {
    const serialPortInformation = this.getSerialPortInformation();
    if (!serialPortInformation) return null;
    return getDeviceDefinition(serialPortInformation);
  }

  getSerialPortInformation(): Partial<SerialPortInfo> | null {
    return this._serialPort?.getInfo() ?? null;
  }

  private _handlePhysicalDisconnect(): void {
    this._keepReading = false;
    this._serialPort = null;
    this._reader = null;
    this._updateState(SERIAL_PORT_CONNECTION_STATE.Disconnected);
  }

  private _updateState(state: SerialPortConnectionState): void {
    this._state = state;

    this._notifySubscribers({
      type: "status",
      state,
      timestamp: Date.now(),
    });
  }

  private _notifySubscribers(message: SerialPortMessage): void {
    this._lastUpdateTimestamp = message.timestamp;
    this._listeners.forEach((listener) => {
      listener(message);
    });
  }

  public get state(): SerialPortConnectionState {
    return this._state;
  }

  public get lastUpdateTimestamp(): number {
    return this._lastUpdateTimestamp;
  }
}
