import { Accordion, AccordionDetails, AccordionSummary, Autocomplete, Grid, TextField, Typography } from "@mui/material";
import { styled } from "@mui/system";
import React, { useState, useEffect } from "react";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import JapanFireSafetyCode from "../../../api/LIMS/JapanFireSafetyCode";
import JapanSDSCode from "../../../api/LIMS/JapanSDSCode";
import JapanGHSCode from "../../../api/LIMS/JapanGHSCode";

const StyledDiv = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'start',
  width: '100%',
  gap: '40px'
}));

const StyledTextField = styled(TextField)(() => ({
  width: '100%'
}));

const StyledAccordionDetails = styled(AccordionDetails)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '20px'
}));

const RegionSpecificInformation = ({
  currentSubstance,
  setCurrentSubstance,
  canEdit
}) => {
  const [expanded, setExpanded] = useState(false);
  const [listJapanFireSafetyCode, setListJapanFireSafetyCode] = useState([]);
  const [listJapanSDSCode, setListJapanSDSCode] = useState([]);
  const [listJapanGHSCode, setListJapanGHSCode] = useState([]);
  const [fireSafetyCodeLoading, setFireSafetyCodeLoading] = useState(true);
  const [sdsCodeLoading, setSDSCodeLoading] = useState(true);
  const [ghsCodeLoading, setGHSCodeLoading] = useState(true);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  useEffect(() => {
    JapanFireSafetyCode.getAll().then((resp) => {
      setListJapanFireSafetyCode(resp.filter(obj => obj.isActive === true))
      setFireSafetyCodeLoading(false)
    })

    JapanSDSCode.getAll().then((resp) => {
      setListJapanSDSCode(resp.filter(obj => obj.isActive === true))
      setSDSCodeLoading(false)
    })

    JapanGHSCode.getAll().then((resp) => {
      setListJapanGHSCode(resp.filter(obj => obj.isActive === true))
      setGHSCodeLoading(false)
    })
  }, [])


  return (
    <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel4bh-content"
        id="panel4bh-header"
      >
        <Typography variant="h5" sx={{ width: '33%', flexShrink: 0 }} >
          Region Specific Information
        </Typography>
      </AccordionSummary>
      <StyledAccordionDetails>
        <StyledDiv>
          <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
            <Grid item xs={2} sm={4} md={4} key={"grid-japan-blend-description"}>
              <StyledTextField size="small" id="japan-blend-description" label="Japan Blend Description" variant="outlined"
                onChange={e => setCurrentSubstance(() => ({
                  ...currentSubstance,
                  jBlendDescription: e.target.value
                }))}
                value={currentSubstance.jBlendDescription === null ? '' : currentSubstance.jBlendDescription}
                disabled={!canEdit}
              />
            </Grid>
            <Grid item xs={2} sm={4} md={4} key={"grid-japan-substance-description"}>
              <StyledTextField size="small" id="japan-substance-description" label="Japan Substance Description" variant="outlined"
                onChange={e => setCurrentSubstance(() => ({
                  ...currentSubstance,
                  jDescription: e.target.value
                }))}
                value={currentSubstance.jDescription === null ? '' : currentSubstance.jDescription}
                disabled={!canEdit}
              />
            </Grid>
            <Grid item xs={2} sm={4} md={4} key={"grid-japan-fire-safety"}>
              <Autocomplete
                id="japan-fire-safety-code-select"
                disablePortal
                noOptionsText={fireSafetyCodeLoading ? "Loading Japan Fire Safety Code..." : 'No options'}
                options={listJapanFireSafetyCode.map((fireSafety) => fireSafety.fireSafetyCodeName)}
                getOptionLabel={(option) => option}
                onChange={(e, value) => setCurrentSubstance(() => ({
                  ...currentSubstance,
                  jFireSafetyCode: value
                }))}
                autoHighlight
                autoSelect
                disabled={!canEdit}
                value={currentSubstance.jFireSafetyCode === '' ? null : currentSubstance.jFireSafetyCode}
                renderInput={(params) => <TextField {...params} variant="outlined" size="small" label="Japan Fire Safety Code" error={false} InputProps={{ ...params.InputProps }} />}
              />
            </Grid>
            <Grid item xs={2} sm={4} md={4} key={"grid-japan-toxic-material"}>
              <StyledTextField size="small" id="japan-toxic-material-code" label="Japan Toxic Material Code" variant="outlined"
                onChange={e => setCurrentSubstance(() => ({
                  ...currentSubstance,
                  jToxicMaterialCode: e.target.value
                }))}
                value={currentSubstance.jToxicMaterialCode === null ? '' : currentSubstance.jToxicMaterialCode}
                inputProps={{ maxLength: 16 }}
                disabled={!canEdit}
              />
            </Grid>
            <Grid item xs={2} sm={4} md={4} key={"grid-japan-ghs"}>
              <Autocomplete
                  id="japan-ghs-code-select"
                  disablePortal
                  noOptionsText={ghsCodeLoading ? "Loading Japan GHS Code..." : 'No options'}
                  options={listJapanGHSCode.map((ghsCode) => ghsCode.ghsCodeName)}
                  getOptionLabel={(option) => option}
                  onChange={(e, value) => setCurrentSubstance(() => ({
                    ...currentSubstance,
                    jghsCode: value
                  }))}
                  autoHighlight
                  autoSelect
                  disabled={!canEdit}
                  value={currentSubstance.jghsCode === '' ? null : currentSubstance.jghsCode}
                  renderInput={(params) => <TextField {...params} variant="outlined" size="small" label="Japan GHS Code" error={false} InputProps={{ ...params.InputProps }} />}
                />

              {/* <StyledTextField size="small" id="japan-ghs" label="Japan GHS" variant="outlined"
                onChange={e => setCurrentSubstance(() => ({
                  ...currentSubstance,
                  jghsCode: e.target.value
                }))}
                value={currentSubstance.jghsCode === null ? '' : currentSubstance.jghsCode}
                inputProps={{ maxLength: 16 }}
                disabled={!canEdit}
              /> */}
            </Grid>
            <Grid item xs={2} sm={4} md={4} key={"grid-japan-sds-code"}>
              <Autocomplete
                id="japan-sds-code-select"
                disablePortal
                noOptionsText={sdsCodeLoading ? "Loading Japan SDS Code..." : 'No options'}
                options={listJapanSDSCode.map((sdsCode) => sdsCode.sdsCodeName)}
                getOptionLabel={(option) => option}
                onChange={(e, value) => setCurrentSubstance(() => ({
                  ...currentSubstance,
                  jsdsCode: value
                }))}
                autoHighlight
                autoSelect
                disabled={!canEdit}
                value={currentSubstance.jsdsCode === '' ? null : currentSubstance.jsdsCode}
                renderInput={(params) => <TextField {...params} variant="outlined" size="small" label="Japan SDS Code" error={false} InputProps={{ ...params.InputProps }} />}
              />
            </Grid>
            <Grid item xs={2} sm={4} md={4} key={"grid-japan-shelf-life"}>
              <StyledTextField size="small" id="japan-shelf-life" label="Japan Shelf Life" variant="outlined"
                onChange={e => setCurrentSubstance(() => ({
                  ...currentSubstance,
                  jShelfLife: e.target.value
                }))}
                value={currentSubstance.jShelfLife === null ? '' : currentSubstance.jShelfLife}
                type={`number`}
                disabled={!canEdit}
              />
            </Grid>
          </Grid>
        </StyledDiv>
      </StyledAccordionDetails>
    </Accordion>
  );
};

export default RegionSpecificInformation;