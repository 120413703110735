import React, { useEffect, useState } from 'react';
import { Box, Table, TableCell, TableRow } from "@mui/material";
import { styled } from "@mui/material/styles";
import { convertToLocalTime, downloadFile } from "../../global";
import Test from "../../api/LIMS/Test";
import { Link } from 'react-router-dom';
import ModalSimpleButton from "../Modal/ModalSimpleButton";


const StyledTableCellInfoHeader = styled(TableCell)({
    border: "none",
    paddingBottom:"0px",
    paddingTop:"0px",
    color:"#00000099",
    fontFamily:"EMprint",
    fontWeight:"400",
    fontSize: "12px"
});

const StyledTableCellInfo = styled(TableCell)({
    border: "none",
    paddingBottom:"0px",
    paddingTop:"0px",
    color:"#00000099",
    fontFamily:"EMprint",
    fontWeight:"400",
    fontSize: "14px"
});

const StyledTableCellResultHeader = styled(TableCell)({
    paddingBottom:"0px",
    paddingTop:"0px",
    color:"#00000099",
    fontFamily:"EMprint",
    fontWeight:"400",
    fontSize: "12px"
})

const StyledTableCellResultValue = styled(TableCell)({
    paddingBottom:"0px",
    paddingTop:"0px",
    color:"#00000099",
    fontFamily:"EMprint",
    fontWeight:"400",
    fontSize: "14px"
})


const ResultDisplayBox = ({ testID }) => {
    const [thisTest, setThisTest] = useState(null)

    const [modalMessagesOpen, setModalMessagesOpen] = useState(false)
    const [modalMessagesText, setModalMessagesText] = useState('')
    const [modalMessagesTitle, setModalMessagesTitle] = useState('')
    const [modalMessagesButtonText, setModalMessagesButtonText] = useState('Ok')

    function closeModalMessages() {
        setModalMessagesOpen(false);
        setModalMessagesText('')
        setModalMessagesButtonText('Ok')
        setModalMessagesTitle('')
    }
    
    function openModalMessage(title, message) {
        setModalMessagesOpen(true);
        setModalMessagesTitle(title)
        setModalMessagesText(message)
    }

    useEffect(() => {
        Test.getCompleteByID(testID, true).then((res) => {
            setThisTest(res);
        });
    }, [testID])
    
    return (
        <div>
        {thisTest === null ? <div></div> :
        <div style={{marginTop:"5px"}}>
            <Box sx={{ boxShadow: 3,
            p: 3,
            m: 1,
            borderRadius: 2,
            }}>
                <Table>
                    <TableRow>
                        <StyledTableCellInfoHeader style={{width:'8%'}}>Date Submitted:</StyledTableCellInfoHeader>
                        <StyledTableCellInfo style={{width:'15%'}}>{thisTest.submittedDate !== null ? convertToLocalTime(thisTest.submittedDate) : '-'}</StyledTableCellInfo>
                        <StyledTableCellInfoHeader style={{width:'5%'}}>Conditions:</StyledTableCellInfoHeader>
                        <StyledTableCellInfo style={{width:'10%'}}>{thisTest.testConditionInstanceValue ? thisTest.testConditionInstanceValue.map((tCondition, index) => {
                            return(
                                `${index > 0 ? "~" : ''}${tCondition.testConditionName}: ${tCondition.testConditionValue} ${tCondition.uoMName ? tCondition.uoMName : ''}`
                            )
                        }) : '-'}</StyledTableCellInfo>
                        <StyledTableCellInfoHeader style={{width:'8%'}}>Test Status:</StyledTableCellInfoHeader>
                        <StyledTableCellInfo style={{width:'10%'}}>{thisTest.testStatusName !== null ? thisTest.testStatusName : '-'}</StyledTableCellInfo>
                        <StyledTableCellInfoHeader style={{width:'5%'}}>Priority:</StyledTableCellInfoHeader>
                        <StyledTableCellInfo style={{width:'10%'}}>{thisTest.testPriorityName}</StyledTableCellInfo>
                        <StyledTableCellInfoHeader style={{width:'8%'}}>Estimate:</StyledTableCellInfoHeader>
                        <StyledTableCellInfo style={{width:'10%'}}>{(thisTest.testEstimate!== null && thisTest.testEstimate !== '') ? thisTest.testEstimate : '-'}</StyledTableCellInfo>
                    </TableRow>
                    <TableRow>
                        <StyledTableCellInfoHeader style={{width:'8%'}}>Date Completed:</StyledTableCellInfoHeader>
                        <StyledTableCellInfo style={{width:'15%'}}>{thisTest.reportedDate !== null ? convertToLocalTime(thisTest.reportedDate) : '-'}</StyledTableCellInfo>
                        <StyledTableCellInfoHeader style={{width:'5%'}}>Submitter:</StyledTableCellInfoHeader>
                        <StyledTableCellInfo style={{width:'10%'}}>{thisTest.submitterEmail}</StyledTableCellInfo>
                        <StyledTableCellInfoHeader style={{width:'5%'}}>Requester:</StyledTableCellInfoHeader>
                        <StyledTableCellInfo style={{width:'10%'}}>{thisTest.requesterEmail}</StyledTableCellInfo>
                        <StyledTableCellInfoHeader style={{width:'5%'}}>Analyst:</StyledTableCellInfoHeader>
                        <StyledTableCellInfo style={{width:'10%'}}>{thisTest.analystEmail}</StyledTableCellInfo>
                        <StyledTableCellInfoHeader style={{width:'8%'}}>Test Comments:</StyledTableCellInfoHeader>
                        <StyledTableCellInfo colSpan={2}>{(thisTest.testComment !== null && thisTest.testComment !== '') ? thisTest.testComment : 'None'}</StyledTableCellInfo>
                    </TableRow>
                    <TableRow>
                        <StyledTableCellInfoHeader style={{width:'8%'}}>Date Received:</StyledTableCellInfoHeader>
                        <StyledTableCellInfo style={{width:'15%'}}>{thisTest.receivedDate !== null ? convertToLocalTime(thisTest.receivedDate) : '-'}</StyledTableCellInfo>
                    </TableRow>
                </Table>
                <Table style={{marginTop:"2rem", marginLeft:"2rem", width:'90%'}}>
                        <TableRow style={{border:'bottom'}}>
                            <StyledTableCellResultHeader style={{width:'5%'}}>#</StyledTableCellResultHeader>
                            <StyledTableCellResultHeader style={{width:'20%'}}>Result Description</StyledTableCellResultHeader>
                            <StyledTableCellResultHeader style={{width:'10%'}}>Result Value</StyledTableCellResultHeader>
                            <StyledTableCellResultHeader style={{width:'10%'}}>Unit of Measure</StyledTableCellResultHeader>
                            <StyledTableCellResultHeader>Result Remark</StyledTableCellResultHeader>
                        </TableRow>
                        {thisTest.testResults && thisTest.testResults.map((tResult, index) => {
                            if (tResult.pointTestResult != null && (tResult.pointTestResult.resultValue !== null || tResult.pointTestResult.resultText !== null)) {

                                let displayValue = ''

                                if (tResult.pointTestResult.displayValue)
                                {
                                    displayValue = tResult.pointTestResult.displayValue

                                } else if (tResult.pointTestResult.resultValue !== null && tResult.pointTestResult.resultValue !== '')
                                {
                                    displayValue = tResult.pointTestResult.resultValue

                                } else {
                                    displayValue = tResult.pointTestResult.resultText
                                }
                            return(
                                <TableRow key={`${index}`} style={{border:'bottom'}}>
                                    <StyledTableCellResultValue style={{width:'5%'}}>{tResult.resultDisplayOrder > 0 ? tResult.resultDisplayOrder : '-'}</StyledTableCellResultValue>
                                    <StyledTableCellResultValue style={{width:'20%'}}>{tResult.pointTestResult.resultComponentName}{tResult.pointTestResult.resultConditions && tResult.pointTestResult.resultConditions.map((pCondition) => {
                                        return <p style={{paddingLeft: ".5rem", fontSize: "14px"}}>{pCondition.testConditionName}: {pCondition.testResultConditionValue} {pCondition.uoMName}</p>
                                    })}</StyledTableCellResultValue>
                                    <StyledTableCellResultValue style={{width:'10%'}}>{displayValue}</StyledTableCellResultValue>
                                    {/* <StyledTableCellResultValue style={{width:'10%'}}>{(tResult.pointTestResult.resultValue !== null && tResult.pointTestResult.resultValue !== '') ? tResult.pointTestResult.resultValue : tResult.pointTestResult.resultText}</StyledTableCellResultValue> */}
                                    <StyledTableCellResultValue style={{width:'10%'}}>{tResult.pointTestResult.uoMName}</StyledTableCellResultValue>
                                    <StyledTableCellResultValue>{tResult.pointTestResult.resultComment}</StyledTableCellResultValue>
                                </TableRow>
                            )} else if (tResult.blobTestResult != null && (tResult.blobTestResult.storagePath !== null || tResult.blobTestResult.fileName !== null)) {
                                return(
                                    <TableRow key={`${index}`} style={{border:'bottom'}}>
                                        <StyledTableCellResultValue style={{width:'5%'}}>{tResult.resultDisplayOrder > 0 ? tResult.resultDisplayOrder : '-'}</StyledTableCellResultValue>
                                        <StyledTableCellResultValue style={{width:'20%'}} ><Link onClick={(e) => downloadFile('midasanalyticalfiles', tResult.blobTestResult.fileName, tResult.blobTestResult.storagePath, openModalMessage)}>{tResult.blobTestResult.fileName}</Link></StyledTableCellResultValue>
                                        <StyledTableCellResultValue style={{width:'10%'}}></StyledTableCellResultValue>
                                        <StyledTableCellResultValue style={{width:'10%'}}></StyledTableCellResultValue>
                                        <StyledTableCellResultValue></StyledTableCellResultValue>
                                    </TableRow>
                                )
                            } else { return(null) }}
                        )}
                </Table>
            </Box>
        </div>}

        <ModalSimpleButton title={modalMessagesTitle} buttonText={modalMessagesButtonText} buttonAction={closeModalMessages} open={modalMessagesOpen} setOpen={setModalMessagesOpen}>
            <label>
            {modalMessagesText}
            </label>
        </ModalSimpleButton>
        </div>
    );
}
export default ResultDisplayBox