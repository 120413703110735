import API from '../index'

export default class STEngineer {
    constructor({
        engineerEmailAddress,
        fullName,
    }) {
        this.engineerEmailAddress = engineerEmailAddress
        this.fullName = fullName
    }

    static async getAll() {
        const api = await API()
        const { data } = await api.get('/stengineer')
        return data.result.map(engineer => new STEngineer(engineer))
    }

    static async getAllRaw() {
        const api = await API()
        const { data } = await api.get('/stengineer')

        if (data && data.result) return data.result;

        return [];
    }

    static async get(name) {
        const api = await API();
        const { data } = await api.get(`stengineer/${name}`);
        if (data.result) {
            return new STEngineer(data.result);
        }
        else {
            return null;
        }
    }

    static async add(payload) {
        const api = await API();
        const { data } = await api.post('/stengineer', payload);
        return new STEngineer(data.result);
    }

    static async update(payload) {
        const api = await API();
        const { data } = await api.put('/stengineer', payload);
        return new STEngineer(data.result);
    }
}
