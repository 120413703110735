import API from "..";

export default class ShippingCarrier {

  constructor({
    id,
    carrierName, 
    isActive,
    createdByEmail, 
    createdDate, 
    lastModifiedByEmail, 
    lastModifiedDate
  }) 
    
  {
    this.id = id;
    this.carrierName = carrierName;
    this.isActive = isActive;
    this.createdByEmail = createdByEmail;
    this.createdDate = createdDate;
    this.lastModifiedByEmail = lastModifiedByEmail;
    this.lastModifiedDate = lastModifiedDate;
  }

  static async getAll() {
    const api = await API();
    const { data } = await api.get(`/shippingCarrier`);
    if (data.result) {
        return data.result.map((d) => new ShippingCarrier(d));
    }
    else {
        return [];
    }
  }

  static async getAllActive() {
    const api = await API();
    const { data } = await api.get(`/shippingCarrier`);
    if (data && data.result && data.result.length > 0) {
        return data.result.filter((d) => d.isActive);
    }
    else {
        return [];
    }
  }
}
