import Divider from "@mui/material/Divider";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Typography from "@mui/material/Typography";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import React, { useState } from "react";
import { SqcQcIdManagement } from "./QCID/SqcQcIdManagement";
import { SqcGraphs } from "./SqcGraphs";
import { SqcMethodManagement } from "./MethodManagement/SqcMethodManagement";
import Box from "@mui/material/Box";
import { SqcMethodManagementProvider } from "./MethodManagement/SqcMethodManagementContext";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

// TODO: Move to the root of the application
const theme = createTheme({
  typography: {
    button: {
      fontWeight: 600,
      textTransform: "none",
    },
  },
  components: {
    MuiTable: {
      styleOverrides: {
        root: ({ theme }) => ({
          borderWidth: "1px",
          borderStyle: "solid",
          borderColor: theme.palette.grey[300],
        }),
      },
    },
  },
});

export function Sqc() {
  const [activeTab, setActiveTab] = useState(0);

  const handleChange = (event: React.SyntheticEvent, value: number) => {
    setActiveTab(value);
  };

  return (
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Box sx={{ paddingBlockEnd: "5rem" }}>
          <Typography
            component="h1"
            gutterBottom
            sx={{ fontSize: "2.125rem", fontWeight: 600 }}
          >
            Quality Control
          </Typography>

          <Divider />

          <div>
            <Tabs value={activeTab} onChange={handleChange}>
              <Tab label="Graphs" />
              <Tab label="Method Management" />
              <Tab label="QCID Management" />
            </Tabs>

            <Divider />

            {activeTab === 0 && <SqcGraphs />}

            {activeTab === 1 && (
              <SqcMethodManagementProvider>
                <SqcMethodManagement />
              </SqcMethodManagementProvider>
            )}

            {activeTab === 2 && (
              <SqcQcIdManagement onNavigateToMethod={() => setActiveTab(1)} />
            )}
          </div>
        </Box>
      </LocalizationProvider>
    </ThemeProvider>
  );
}
