import React, { useState, useContext } from "react";
import { styled } from "@mui/material/styles";
import {
  Box,
  FormControl,
  FormControlLabel,
  Autocomplete,
  TextField,
  Checkbox,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { convertToLocalTime, hasRole, Roles } from "../../global";
import UserContext from "../../context/UserContext";
import { GlobalSecondaryButton, GlobalButton } from "../styles";

const StyledTextField = styled(TextField)(() => ({
  width: "35rem",
}));

const StyledAccordionAutoComplete = styled(Autocomplete)(() => ({
  width: "15rem",
  margin: "0.5rem",
}));

const TestDiv = styled("div")(() => ({
  color: "rgba(0, 0, 0, 0.6)",
  fontFamily: "Roboto, Helvetica, Arial, sans-serif",
  fontWeight: "400",
  fontSize: "1rem",
}));

const StyledAccordionTextField = styled(TextField)(() => ({
  width: "15rem",
  margin: "0.5rem",
}));

const StyledAccordion = styled(Accordion)(() => ({
  width: "50rem",
  borderTopLeftRadius: "10px",
  borderTopRightRadius: "10px",
  "&::before": {
    backgroundColor: "transparent",
  },
}));
const StyledAccordionSummary = styled(AccordionSummary)(() => ({
  backgroundColor: "#1976d2",
  borderTopLeftRadius: "10px",
  borderTopRightRadius: "10px",
  maxHeight: "48px",
  minHeight: "48px !important",
}));

const StyledAccordionDetails = styled(AccordionDetails)(() => ({
  display: "flex",
  justifyContent: "flex-start",
  flexWrap: "wrap",
}));

const StyledBox = styled(Box)(() => ({
  display: "flex",
  marginBottom: "20px",
  gap: "20px",
  justifyContent: "normal !important",
}));

const InstrumentManagementForm = ({
  instrumentList,
  saveNewInstrument,
  locationsList,
}) => {
  const initialStateInstrument = {
    id: 0,
    name: "",
    description: "",
    createdByEmail: "",
    createdDate: "",
    lastModifiedByEmail: "",
    lastModifiedDate: "",
    isActive: false,
    isConnected: false,
    etqid: "",
    isLockedForUse: false,
    locationName: "",
    comPort: "",
    baudRate: null,
    parity: null,
    stopBits: null,
    dataBits: null,
    handshake: null,
    isDTREnabled: false,
    isRTSEnabled: false,
  };
  const _baudRate = [110, 300, 600, 1200, 2400, 4800, 9600, 14400, 19200];
  const _parity = ["None", "Odd", "Even", "Mark", "Space"];
  const _dataBits = [0, 1, 2, 3, 4, 5, 6, 7, 8];
  const _stopBits = [
    { label: "None", value: 0 },
    { label: "1", value: 1 },
    { label: "2", value: 2 },
    { label: "1.5", value: 3 },
  ];
  const _handshake = [
    "None",
    "XOnXOff",
    "RequestToSend",
    "RequestToSendXOnXOff",
  ];
  const regex = new RegExp(/^COM\d{1,3}$/);

  const [addNew, setAddNew] = useState(false);
  const [instrumentData, setInstrumentData] = useState(initialStateInstrument);
  const [hasError, setHasError] = useState(false);

  const currentUser = useContext(UserContext);
  const roles = currentUser?.idTokenClaims.roles;

  const canEdit =
    hasRole(Roles.Developer, roles) ||
    hasRole(Roles.InstrumentAdministrator, roles);

  const clearForm = () => {
    setInstrumentData(initialStateInstrument);
  };

  const handleChangeInstrument = (name) => {
    if (!name) return clearForm(initialStateInstrument);
    setInstrumentData(instrumentList.find((x) => x.name === name));
  };

  const verifyAndSaveNewInstrument = () => {
    if (
      !instrumentData.name ||
      !instrumentData.description ||
      !instrumentData.locationName ||
      !instrumentData.etqid ||
      !instrumentData.comPort ||
      !regex.test(instrumentData.comPort) ||
      !instrumentData.parity ||
      !instrumentData.handshake
    ) {
      setHasError(true);
      return;
    }

    setHasError(false);
    instrumentData.createdDate = new Date().toJSON();
    saveNewInstrument(instrumentData).then((response) => {
      setInstrumentData(response);
    });
  };

  return (
    <div>
      <FormControl sx={{ m: 1 }}>
        <StyledBox>
          <Autocomplete
            noOptionsText={"Loading Instruments..."}
            value={instrumentData.name}
            onChange={(e, value) => handleChangeInstrument(value)}
            disablePortal
            options={
              instrumentList
                ? instrumentList.map((instrument) => instrument.name.toString())
                : []
            }
            sx={{ width: "35rem" }}
            renderInput={(params) => (
              <TextField {...params} variant="outlined" size="small" margin="none" label="Instrument" />
            )}
            autoSelect
          />
          {canEdit && (
            <GlobalSecondaryButton
              onClick={() => {
                clearForm();
                setAddNew(true);
              }}
              sx={{ width: "6rem", marginLeft: "1rem" }}
              variant="contained"
            >
              Add New
            </GlobalSecondaryButton>
          )}
        </StyledBox>

        {(addNew || instrumentData.name) && (
          <Box>
            <StyledBox>
              <StyledTextField
                id="outlined-basic"
                label="Instrument Name"
                size="small" margin="none" 
                required
                error={hasError && !instrumentData.name}
                onChange={(e) =>
                  setInstrumentData(() => ({
                    ...instrumentData,
                    name: e.target.value,
                  }))
                }
                value={instrumentData.name}
                disabled={!canEdit}
              />
              <FormControlLabel
                sx={{ marginLeft: 1 }}
                label="Active"
                onChange={(e) =>
                  setInstrumentData(() => ({
                    ...instrumentData,
                    isActive: e.target.checked,
                  }))
                }
                checked={instrumentData.isActive}
                control={<Checkbox />}
                disabled={!canEdit}
              />
              <FormControlLabel
                label="Connected"
                checked={instrumentData.isConnected}
                control={<Checkbox />}
                disabled={!canEdit}
                onChange={(e) =>
                  setInstrumentData(() => ({
                    ...instrumentData,
                    isConnected: e.target.checked,
                  }))
                }
              />
              <FormControlLabel
                label="Currently In Use"
                checked={instrumentData.isLockedForUse}
                control={<Checkbox />}
                disabled={!canEdit}
                onChange={(e) =>
                  setInstrumentData(() => ({
                    ...instrumentData,
                    isLockedForUse: e.target.checked,
                  }))
                }
              />
            </StyledBox>

            <StyledBox>
              <StyledTextField
                id="outlined-basic"
                label="Description"
                variant="outlined" size="small" margin="none" 
                required
                error={hasError && !instrumentData.description}
                value={instrumentData.description}
                onChange={(e) =>
                  setInstrumentData(() => ({
                    ...instrumentData,
                    description: e.target.value,
                  }))
                }
                disabled={!canEdit}
              />
            </StyledBox>

            <StyledBox
              sx={{
                width: "560px",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Autocomplete
                noOptionsText={"Loading Locations..."}
                value={instrumentData.locationName}
                onChange={(e, value) =>
                  setInstrumentData(() => ({
                    ...instrumentData,
                    locationName: value,
                  }))
                }
                disablePortal
                options={locationsList.map((x) => x.locationName)}
                sx={{ width: "17rem" }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    required
                    error={hasError && !instrumentData.locationName}
                    label="Location"
                    variant="outlined" size="small" margin="none" 
                  />
                )}
                autoSelect
                disabled={!canEdit}
              />

              <StyledTextField
                sx={{ width: "17rem" }}
                id="outlined-basic"
                label="EtQID"
                variant="outlined" size="small" margin="none" 
                required
                error={hasError && !instrumentData.etqid}
                value={instrumentData.etqid}
                onChange={(e) =>
                  setInstrumentData(() => ({
                    ...instrumentData,
                    etqid: e.target.value,
                  }))
                }
                disabled={!canEdit}
              />
            </StyledBox>

            <StyledBox
              sx={{
                width: "100%",
                position: "relative",
              }}
            >
              {!instrumentData.createdByEmail ? (
                <TestDiv>
                  Create By <b> unknown </b> on <b> n.d. </b>
                </TestDiv>
              ) : (
                <TestDiv>
                  Create By <b>{instrumentData.createdByEmail}</b> on
                  <b> {convertToLocalTime(instrumentData.createdDate)}</b>
                </TestDiv>
              )}
              {!instrumentData.lastModifiedByEmail ? (
                <TestDiv></TestDiv>
              ) : (
                <TestDiv>
                  Last Updated By <b>{instrumentData.lastModifiedByEmail}</b> on
                  <b> {convertToLocalTime(instrumentData.lastModifiedDate)}</b>
                </TestDiv>
              )}
            </StyledBox>

            <StyledAccordion>
              <StyledAccordionSummary
                expandIcon={<ExpandMoreIcon sx={{ color: "white" }} />}
              >
                <Typography color={"white"}>
                  <span style={{ fontWeight: "400", fontSize: "1rem" }}>
                    Instrument Connection Settings
                  </span>
                </Typography>
              </StyledAccordionSummary>
              <StyledAccordionDetails>
                <StyledAccordionTextField
                  margin="normal"
                  variant="outlined"
                  label="COM Port"
                  helperText={"e.g. COM2"}
                  required
                  error={
                    hasError &&
                    (!instrumentData.comPort ||
                      !regex.test(instrumentData.comPort))
                  }
                  value={instrumentData.comPort}
                  disabled={!canEdit}
                  onChange={(e) =>
                    setInstrumentData(() => ({
                      ...instrumentData,
                      comPort: e.target.value,
                    }))
                  }
                />
                <StyledAccordionAutoComplete
                  value={instrumentData.baudRate}
                  onChange={(e, value) =>
                    setInstrumentData(() => ({
                      ...instrumentData,
                      baudRate: value,
                    }))
                  }
                  disablePortal
                  disabled={!canEdit}
                  options={_baudRate}
                  renderInput={(params) => (
                    <TextField {...params} label="Baud Rate" />
                  )}
                />
                <StyledAccordionAutoComplete
                  value={instrumentData.parity}
                  onChange={(e, value) =>
                    setInstrumentData(() => ({
                      ...instrumentData,
                      parity: value,
                    }))
                  }
                  disabled={!canEdit}
                  options={_parity}
                  disablePortal
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Parity"
                      required
                      error={hasError && !instrumentData.parity}
                    />
                  )}
                />
                <StyledAccordionAutoComplete
                  value={_stopBits.find(
                    (x) => x.value === instrumentData.stopBits
                  )}
                  onChange={(e, value) => {
                    setInstrumentData(() => ({
                      ...instrumentData,
                      stopBits: value.value,
                    }));
                  }}
                  disabled={!canEdit}
                  options={_stopBits}
                  disablePortal
                  renderInput={(params) => (
                    <TextField {...params} label="Stop Bits" />
                  )}
                />
                <StyledAccordionAutoComplete
                  onChange={(e, value) =>
                    setInstrumentData(() => ({
                      ...instrumentData,
                      dataBits: value,
                    }))
                  }
                  options={_dataBits}
                  value={instrumentData.dataBits}
                  id="instrument-databits"
                  disabled={!canEdit}
                  disablePortal
                  renderInput={(params) => (
                    <TextField {...params} label="Data Bits" />
                  )}
                />
                <StyledAccordionAutoComplete
                  value={instrumentData.handshake}
                  onChange={(e, value) =>
                    setInstrumentData(() => ({
                      ...instrumentData,
                      handshake: value,
                    }))
                  }
                  disablePortal
                  options={_handshake}
                  disabled={!canEdit}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      required
                      error={hasError && !instrumentData.handshake}
                      label="Handshake"
                    />
                  )}
                />
                <FormControlLabel
                  sx={{ marginLeft: 1 }}
                  label="DTR Enabled"
                  onChange={(e) =>
                    setInstrumentData(() => ({
                      ...instrumentData,
                      isDTREnabled: e.target.checked,
                    }))
                  }
                  checked={instrumentData.isDTREnabled}
                  control={<Checkbox />}
                  disabled={!canEdit}
                />
                <FormControlLabel
                  sx={{ marginLeft: 1 }}
                  label="RTS Enabled"
                  onChange={(e) =>
                    setInstrumentData(() => ({
                      ...instrumentData,
                      isRTSEnabled: e.target.checked,
                    }))
                  }
                  checked={instrumentData.isRTSEnabled}
                  control={<Checkbox />}
                  disabled={!canEdit}
                />
              </StyledAccordionDetails>
            </StyledAccordion>
            <StyledBox sx={{ mb: 1, mt: 2, justifyContent: "space-between" }}>
              {canEdit && (
                <GlobalButton
                  sx={{ width: "8rem" }}
                  variant="contained"
                  type="submit"
                  onClick={verifyAndSaveNewInstrument}
                >
                  Save
                </GlobalButton>
              )}
            </StyledBox>
          </Box>
        )}
      </FormControl>
    </div>
  );
};

export default InstrumentManagementForm;
