import React from "react";

const DistillationMasterData = ({ ...props }) => {
    return (
      <div>
        <p>
          Coming soon to a browser near you!
        </p>
        <ul>
          <li>Distillation Cut Type</li>
          <li>Distillation Experiment Priority</li>
          <li>Distillation Status</li>
          <li>Distillation Type</li>
        </ul>
      </div>
    );
  };
  
  export default DistillationMasterData;