import LoadingButton from "@mui/lab/LoadingButton";
import Autocomplete from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import Divider from "@mui/material/Divider";
import FormControlLabel from "@mui/material/FormControlLabel";
import Stack from "@mui/material/Stack";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { FormikProvider, useFormik } from "formik";
import { useContext, useEffect, useState } from "react";
import Method from "../../../api/LIMS/Method";
import MethodFacility from "../../../api/LIMS/MethodFacility";
import { SqcApi } from "../../../api/Admin/SQC";
import ModalMessages from "../../../components/Modal/ModalSimpleButton";
import UserContext from "../../../context/UserContext";
import { Roles, convertToLocalTime, hasRole } from "../../../global";
import { SqcMethodSetup } from "../../../lib/types/sqc";
import { InstrumentsModal } from "./Modals/QcIdModal/InstrumentsModal";
import { ParametersModal } from "./Modals/QcIdModal/ParametersModal";
import { QcIdModal } from "./Modals/QcIdModal/QcIdModal";
import { TemperaturesModal } from "./Modals/QcIdModal/TemperaturesModal";
import { SqcMethodManagementAstmSets } from "./SqcMethodManagementAstmSets";
import { useSqcMethodManagement } from "./SqcMethodManagementContext";
import { SqcMethodManagementLimitsSets } from "./SqcMethodManagementLimitsSets";
import {
  INITIAL_VALUE,
  SqcMethodManagementForm,
  sqcMethodManagementSchema,
} from "./form";
import toast from "react-hot-toast";

export function SqcMethodManagement() {
  const [activeTab, setActiveTab] = useState(0);

  const [loadingMethods, setLoadingMethods] = useState(false);
  const [selectedMethod, setSelectedMethod] = useState<Method | null>(null);
  const [selectedMethodFacility, setSelectedMethodFacility] =
    useState<MethodFacility | null>(null);
  const [methods, setMethods] = useState<Method[]>([]);
  const [sqcMethodSetups, setSqcMethodSetups] = useState<SqcMethodSetup[]>([]);
  const [isFormEnabled, setIsFormEnabled] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const [qcidVisible, setQcidVisible] = useState(false);
  const [parametersVisible, setParametersVisible] = useState(false);
  const [temperaturesVisible, setTemperaturesVisible] = useState(false);
  const [instrumentsVisible, setInstrumentsVisible] = useState(false);

  const [modalTitle, setModalTitle] = useState<string | null>(null);
  const [modalMessage, setModalMessage] = useState<string | null>(null);

  const { setActiveLimitIndex, setActiveAstmIndex } = useSqcMethodManagement();

  const openModal = (title: string, message: string) => {
    setModalTitle(title);
    setModalMessage(message);
  };

  const closeModal = () => {
    setModalTitle(null);
    setModalMessage(null);
  };

  const loadSqcMethods = async () => {
    setLoadingMethods(true);

    const methodsPromise = Method.getSqcMethods().then(setMethods);
    const methodSetupsPromise =
      SqcApi.getAllMethodSetups().then((response) => {
        if (response && response.result) {
          setSqcMethodSetups(response.result);
        } else if (response && response.message) {
          toast.error(response.message);
        } else {
          toast.error("Failed while loading sqc methods");
        }
      });

    Promise.allSettled([methodsPromise, methodSetupsPromise]).finally(() =>
      setLoadingMethods(false)
    );
  };

  const updateForm = (methodSetup: SqcMethodSetup) => {
    formik.setValues({
      methodFacilityID: methodSetup.methodFacilityID,
      methodFacility: {
        availableInstruments:
          methodSetup.methodFacility.availableInstruments.map((a) => ({
            instrumentID: a.instrumentID,
            isActive: a.isActive,
          })),
      },
      isActive: methodSetup.isActive,
      isTemperatureDependent: methodSetup.isTemperatureDependent,
      trackedComponents: methodSetup.trackedComponents.map((t) => ({
        resultComponentName: t.resultComponentName,
        isInformational: t.isInformational,
      })),
      qcids: methodSetup.qcids.map((q) => ({ sqcSampleID: q.sqcSampleID })),
      allowedTemperatures: methodSetup.allowedTemperatures.map((a) => ({
        temperature: a.temperature,
      })),
      limits: methodSetup.limits.map((l) => ({
        id: l.id,
        sqcSampleID: l.sqcSampleID,
        resultComponentName: l.resultComponentName,
        isActive: l.isActive,
        createdDate: l.createdDate,
        createdByEmail: l.createdByEmail,
        lastModifiedByEmail: l.lastModifiedByEmail,
        lastModifiedDate: l.lastModifiedDate,
        retiredDate: l.retiredDate,
        sigmaValue: l.sigmaValue,
        meanValue: l.meanValue,
        upperControlLimitValue: l.upperControlLimitValue,
        lowerControlLimitValue: l.lowerControlLimitValue,
        applicableTemperatureValue: l.applicableTemperatureValue,
        rule1ViolationBehavior: l.rule1ViolationBehavior,
        rule2ViolationBehavior: l.rule2ViolationBehavior,
        rule3ViolationBehavior: l.rule3ViolationBehavior,
        rule4ViolationBehavior: l.rule4ViolationBehavior,
        failureWindowBefore: l.failureWindowBefore,
        failureWindowAfter: l.failureWindowAfter,
      })),
      sqcastm: methodSetup.sqcastm.map((s) => ({
        id: s.id,
        resultComponentName: s.resultComponentName,
        revisionNumber: s.revisionNumber,
        littleR: s.littleR,
        bigR: s.bigR,
        createdByEmail: s.createdByEmail,
        createdDate: s.createdDate,
        isActive: s.isActive,
        equationForm: s.equationForm,
        a: s.a,
        c: s.c,
        x: s.x,
        y: s.y,
        deactivationDate: s.deactivationDate,
      })),
    });
  };

  useEffect(() => {
    loadSqcMethods();
  }, []);

  const handleTabChange = (event: React.SyntheticEvent, value: number) => {
    setActiveTab(value);
  };

  const formik = useFormik<SqcMethodManagementForm>({
    initialValues: INITIAL_VALUE,
    onSubmit: async (values) => {
      setIsSaving(true);

      const existingMethodSetup = sqcMethodSetups.find(
        (m) => m.methodFacilityID === selectedMethodFacility!.id
      );

      let updatedMethodSetup: SqcMethodSetup;

      if (existingMethodSetup) {
        updatedMethodSetup = await SqcApi.updateMethodSetup(values);

        if (updatedMethodSetup) {
          openModal("Method Updated Successfully", "Method has been saved!");
        } else {
          openModal(
            "Method Failed to Update",
            "Contact support if you feel this is an error."
          );
          return;
        }
      } else {
        values.methodFacility = null;
        updatedMethodSetup = await SqcApi.createMethodSetup(values);

        if (updatedMethodSetup) {
          openModal("Method Saved Successfully", "Method has been updated!");
        } else {
          openModal(
            "Method Failed to Save",
            "Contact support if you feel this is an error."
          );
          return;
        }
      }

      loadSqcMethods()
        .then(() => {
          setActiveLimitIndex(null);
          setActiveAstmIndex(null);
          updateForm(updatedMethodSetup);
        })
        .finally(() => setIsSaving(false));
    },
    validationSchema: sqcMethodManagementSchema,
    validateOnMount: true,
  });

  const onSelectMethod = (method: Method | null) => {
    setSelectedMethod(method);
    setSelectedMethodFacility(null);
  };

  const onSelectMethodFacility = (methodFacility: MethodFacility | null) => {
    setSelectedMethodFacility(methodFacility);
    setIsFormEnabled(Boolean(methodFacility));

    if (methodFacility) {
      const methodSetup = sqcMethodSetups.find(
        (m) => m.methodFacilityID === methodFacility.id
      );

      if (methodSetup) {
        updateForm(methodSetup);
      } else {
        formik.setFieldValue("methodFacilityID", methodFacility.id);
      }
    }
  };

  const onCancel = () => {
    setSelectedMethod(null);
    setSelectedMethodFacility(null);
    setIsFormEnabled(false);
    setActiveLimitIndex(null);
    setActiveAstmIndex(null);
  };

  const selectedSqcMethodSetup = sqcMethodSetups.find(
    (s) => s.methodFacilityID === selectedMethodFacility?.id
  );

  const currentUser = useContext(UserContext);
  const roles = (currentUser.idTokenClaims as unknown as { roles: string[] })
    .roles;

  const canEdit: boolean =
    hasRole(Roles.Developer, roles) || hasRole(Roles.SQCAdministrator, roles);

  if (!canEdit) {
    return (
      <Typography>
        You don't have permission to edit SQC Method Management. Make sure
        you're assigned the QC.ADMINISTRATOR role.
      </Typography>
    );
  }

  return (
    <FormikProvider value={formik}>
      <Stack sx={{ gap: "2rem" }}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{ paddingBlock: "0.5rem" }}
        >
          <Typography component="h2" sx={{ fontSize: "1.5rem" }}>
            Method Management
          </Typography>

          <Stack direction="row" sx={{ gap: "2rem" }}>

            {isFormEnabled && (
              <Stack direction="row" sx={{ gap: "0.5rem" }}>
                <LoadingButton
                  type="button"
                  variant="contained"
                  onClick={() => formik.handleSubmit()}
                  loading={isSaving}
                  disabled={!formik.isValid}
                >
                  Save Changes
                </LoadingButton>

                <Button type="button" variant="outlined" onClick={onCancel}>
                  Cancel
                </Button>
              </Stack>
            )}
          </Stack>
        </Stack>

        <Stack sx={{ gap: "0.5rem" }}>
          <Stack direction="row" sx={{ gap: "0.5rem" }}>
            <Autocomplete
              loading={loadingMethods}
              options={!loadingMethods ? methods : []}
              getOptionLabel={(option) => option.name}
              renderOption={(props, option) => (
                <li {...props} key={option.name}>
                  {option.name}
                </li>
              )}
              isOptionEqualToValue={(option, value) =>
                option.name === value.name
              }
              renderInput={(params) => <TextField {...params} label="Method" />}
              value={selectedMethod}
              onChange={(event, value) => onSelectMethod(value)}
              disabled={Boolean(isFormEnabled)}
              sx={{ flex: 1 }}
            />

            <Autocomplete
              options={
                !loadingMethods ? selectedMethod?.methodFacilities ?? [] : []
              }
              getOptionLabel={(option) => option.testFacilityAbv}
              renderOption={(props, option) => (
                <li {...props} key={option.id}>
                  {option.testFacilityAbv}
                </li>
              )}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              renderInput={(params) => (
                <TextField {...params} label="Facility" />
              )}
              value={selectedMethodFacility}
              onChange={(event, value) => onSelectMethodFacility(value)}
              disabled={Boolean(isFormEnabled) || !selectedMethod}
              sx={{ flex: 1 }}
            />

            <TextField
              label="Description"
              inputProps={{
                readOnly: true,
              }}
              value={selectedMethod?.description ?? ""}
              sx={{ flex: 4 }}
            />
          </Stack>

          {isFormEnabled && (
            <>
              <>
                {selectedSqcMethodSetup?.lastModifiedByEmail &&
                  selectedSqcMethodSetup?.lastModifiedDate && (
                    <Typography
                      component="span"
                      sx={{ color: "#00000099", fontSize: "0.75rem" }}
                    >
                      Last updated{" "}
                      {convertToLocalTime(
                        selectedSqcMethodSetup.lastModifiedDate
                      )}{" "}
                      by {selectedSqcMethodSetup.lastModifiedByEmail}
                    </Typography>
                  )}
              </>

              <Stack direction="row" sx={{ gap: "0.5rem" }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="isActive"
                      checked={formik.values.isActive}
                      onChange={formik.handleChange}
                    />
                  }
                  label="Active"
                  sx={{ width: "fit-content" }}
                />

                <FormControlLabel
                  control={
                    <Checkbox
                      name="isTemperatureDependent"
                      checked={formik.values.isTemperatureDependent}
                      onChange={formik.handleChange}
                    />
                  }
                  label="Temperature Dependent QC"
                  sx={{ width: "fit-content" }}
                />

                <Button
                  type="button"
                  variant="outlined"
                  onClick={() => setQcidVisible(true)}
                >
                  QCIDs
                </Button>

                <Button
                  type="button"
                  variant="outlined"
                  onClick={() => setParametersVisible(true)}
                >
                  Parameters
                </Button>

                {formik.values.isTemperatureDependent && (
                  <Button
                    type="button"
                    variant="outlined"
                    onClick={() => setTemperaturesVisible(true)}
                  >
                    Temperatures
                  </Button>
                )}

                <Button
                  type="button"
                  variant="outlined"
                  onClick={() => setInstrumentsVisible(true)}
                >
                  Instruments
                </Button>
              </Stack>

              <div>
                <Tabs value={activeTab} onChange={handleTabChange}>
                  <Tab label="Limits Sets" />
                  <Tab label="ASTM Values" />
                </Tabs>

                <Divider />

                <div style={{ display: activeTab === 0 ? "block" : "none" }}>
                  <SqcMethodManagementLimitsSets
                    limits={selectedSqcMethodSetup?.limits ?? []}
                  />
                </div>

                <div style={{ display: activeTab === 1 ? "block" : "none" }}>
                  <SqcMethodManagementAstmSets
                    sets={selectedSqcMethodSetup?.sqcastm ?? []}
                  />
                </div>
              </div>
            </>
          )}
        </Stack>
      </Stack>

      {qcidVisible && <QcIdModal onClose={() => setQcidVisible(false)} />}

      {parametersVisible && (
        <ParametersModal onClose={() => setParametersVisible(false)} />
      )}

      {temperaturesVisible && (
        <TemperaturesModal onClose={() => setTemperaturesVisible(false)} />
      )}

      {instrumentsVisible && (
        <InstrumentsModal onClose={() => setInstrumentsVisible(false)} />
      )}

      {modalMessage && (
        <ModalMessages
          title={modalTitle}
          buttonText="Close"
          buttonAction={() => closeModal()}
          open={!!modalMessage}
          setOpen={(open: boolean) => {
            if (!open) closeModal();
          }}
        >
          <label>{modalMessage}</label>
        </ModalMessages>
      )}
    </FormikProvider>
  );
}
