import { createContext, useState } from 'react';
import LocationApi from "../api/Admin/Location";

export const LocationContext = createContext(null)

export const LocationContextProvider = ({ children }) => {
    const [locations, setLocations] = useState([]);

    const getAllLocations = async () => {
        const data = await LocationApi.getAll()
        setLocations(data)
    }

    return (
        <LocationContext.Provider value={{ locations, getAllLocations }}>
            {children}
        </LocationContext.Provider>
    )
}