import API from '../index'

export default class STFacility {
    constructor({ stationaryTestFacilityName,
        isActive,
        isExternal,
        externalCoordinatorEmail
    }) {
        this.stationaryTestFacilityName = stationaryTestFacilityName
        this.isActive = isActive
        this.isExternal = isExternal
        this.externalCoordinatorEmail = externalCoordinatorEmail
    }

    static async getAll() {
        const api = await API()
        const { data } = await api.get('/stfacility')
        return data.result.map(facility => new STFacility(facility))
    }

    static async getAllActives() {
        const api = await API()
        const { data } = await api.get('/stfacility')
        if (data && data.result && data.result.length > 0) {
            return data.result.filter(r => r.isActive)
        }
        return []
    }

    static async add(payload) {
        const api = await API();
        const { data } = await api.post('/stfacility', payload);
        return new STFacility(data.result);
    }

    static async update(payload) {
        const api = await API();
        const { data } = await api.put('/stfacility', payload);
        return new STFacility(data.result);
    }

    static async get(name) {
        const api = await API();
        const { data } = await api.get(`stfacility/${name}`);
        if (data.result) {
          return new STFacility(data.result);
        }
        else {
          return null;
        }
      }

}
