import { useContext, useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import {
  StyledTableBody,
  StyledTableRow,
  UXDataTableWithoutBody,
} from "../../../components/UXDataTable";
import { StyledTableCellLarge, StyledTableCellMedium, StyledTableCellSmall } from "../styles";
import { FormControl, MenuItem, Select, TextField, Typography } from "@mui/material";
import { OrderStatusContext } from "../context/OrderStatusContext";
import Autocomplete from "@mui/material/Autocomplete";
import { StockroomPOContext } from "../context/StockroomPOContext";
import ModalTwoButtons from "../../../components/Modal/ModalTwoButtons";
import DisplayMessage from "./DisplayMessage";
import { GlobalButton, GlobalSecondaryButton } from "../../styles";
import ValidatedMidasNumberTextField from "../../../components/ValidatedMidasNumberTextField";
import { OrderStatusEnums, StockroomCategoryNameEnums } from "../shared";
import ScannerIncreaseInputButton from "./ScannerIncreaseInputButton";

const poItemCols = [
  "PO Item #",
  "Vendor",
  "Location",
  "Stockroom #",
  "Description",
  "Quantity",
  "BckOrder",
  "POStatus",
  "Received",
  "MIDAS #",
  "Container #",
];

let prevList;

export default function SRReceiveStockModal({
  selectedPO,
  isPOItemUpdating,
  setSelectedPO,
  setIsPOItemListOpen,
  setSelectedPOItems,
  isPOItemListOpen,
  selectedPOItems,
  setIsPOItemUpdating,
  getAllPOs,
  canEdit,
}) {
  const [rowsPerPOItemsPage, setRowsPerPOItemsPage] = useState(5);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [dontCloseStockModal, setDontCloseStockModal] = useState(false);
  const [modalMessageIsOpen, setModalMessageIsOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState({ title: "", message: "" });

  const [poItemsPage, setPOItemsPage] = useState(0);
  const { orderStatuses, getAllOrderStatuses } = useContext(OrderStatusContext);
  const { managePO } = useContext(StockroomPOContext);

  useEffect(() => {
    if (!prevList) prevList = structuredClone(selectedPOItems);
  }, [selectedPOItems]);

  const handlePOItemsPageChange = (event, page) => {
    setPOItemsPage(page);
  };

  const handleChangeRowsPerPOItemsPage = (event) => {
    setRowsPerPOItemsPage(parseInt(event.target.value, 10));
    setPOItemsPage(0);
  };

  const [errorArray, setErrorArray] = useState([]);

  useEffect(() => {
    if (selectedPOItems.length && !errorArray.length) {
      setErrorArray(
        selectedPOItems.map((item) => {
          return {
            itemId: item.id, //id matches the selectedRequest.requestItems
            hasError:
              item.equipmentItem?.stockroomCategoryName === StockroomCategoryNameEnums.Chemicals,
          };
        }),
      );
    }
  }, [selectedPOItems, errorArray]);

  const updateBackorderedQty = (e, id, min, max) => {
    const newList = [...selectedPOItems];
    let index = newList.findIndex((item) => item.id === id);

    newList[index].backorderedQuantity = e.target.value > max ? max : e.target.value;
    setSelectedPOItems(newList);
    setIsPOItemUpdating(true);
  };

  const updatePoStatus = (e, id) => {
    const newList = [...selectedPOItems];
    let index = newList.findIndex((item) => item.id === id);

    newList[index].orderStatusName = e.target.value;
    setSelectedPOItems(newList);
    setIsPOItemUpdating(true);
  };
  const updateReceivedQty = (e, id, min, max) => {
    const newList = [...selectedPOItems];
    let index = newList.findIndex((item) => item.id === id);

    newList[index].receivedQuantity = e.target.value > max ? max : e.target.value;
    setSelectedPOItems(newList);
    setIsPOItemUpdating(true);
  };

  const saveChanges = async () => {
    const payload = {
      ...selectedPO,
      //statusName: getRequestState(),
      poItems: selectedPOItems,
    };

    if (validation(payload)) {
      setDontCloseStockModal(false);
      await managePO(payload)
        .then((response) => {
          setIsPOItemUpdating(false);
          setModalMessage({
            title: "Success!",
            message: "Your order has been updated.",
          });
          prevList = structuredClone(selectedPOItems);
          setModalMessageIsOpen(true);
          getAllPOs();
        })
        .catch((err) => {
          setModalMessage({
            title: "Error!",
            message:
              "There was an error when trying to cancel all request items. Please try again later.",
          });
          setModalMessageIsOpen(true);
        });
    }
  };

  const validation = (payload) => {
    let qtyErrorItemId;
    const qtyError = payload.poItems.some((item) => {
      const invalid =
        item.orderStatusName === OrderStatusEnums.Complete &&
        item.itemQuantity !==
          parseInt(item.backorderedQuantity ?? 0) + parseInt(item.receivedQuantity);

      qtyErrorItemId = invalid ? item.id : "";
      return invalid;
    });

    if (qtyError) {
      setModalMessage({
        title: "Error!",
        message: `Please provide a valid quantity for po item #: ${qtyErrorItemId}`,
      });
      setDontCloseStockModal(true);
      setModalMessageIsOpen(true);

      return false;
    }

    let needMidasNumber = payload.poItems.some(
      (item) =>
        item.equipmentItem?.stockroomCategoryName === StockroomCategoryNameEnums.Chemicals &&
        (!item.receivedContainer?.sample?.sampleName || !item.receivedContainer?.containerNumber),
    );

    if (needMidasNumber) {
      setModalMessage({
        title: "Error!",
        message: "Please provide a valid MIDAS # and Container #",
        function: () => {
          setModalMessageIsOpen(false);
        },
      });
      setDontCloseStockModal(true);
      setModalMessageIsOpen(true);
      return false;
    }

    return true;
  };

  const cancelChanges = () => {
    setSelectedPOItems(structuredClone(prevList));
  };

  // const getRequestState = () => {
  //   // if (
  //   //   !selectedPOItems.some((item) => item.orderStatusName !== status.complete)
  //   // ) {
  //   //   return status.complete;
  //   // }

  //   if (selectedPOItems.some((item) => item.receivedQuantity > 0)) {
  //     return status.inProgress;
  //   }

  //   // if (
  //   //   !selectedPOItems.some((item) => item.orderStatusName !== status.cancelled)
  //   // ) {
  //   //   return status.cancelled;
  //   // }
  //   return status.submitted;
  // };

  useEffect(() => {
    if (!orderStatuses.length) {
      getAllOrderStatuses();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const closeSRReceiveStockModal = () => {
    setIsPOItemListOpen(false);
    setSelectedPOItems([]);
    setSelectedPO({});
    prevList = null;
  };

  const updateSampleName = (value, id) => {
    let newList = [...selectedPOItems];
    let index = newList.findIndex((item) => item.id === id);
    if (!newList[index].receivedContainer) newList[index].receivedContainer = {};
    newList[index].receivedContainer.sample = value;
    setSelectedPOItems(newList);
  };

  const updateContainerNumber = (containerNumber, containerId, id) => {
    let newList = [...selectedPOItems];
    let index = newList.findIndex((item) => item.id === id);
    if (!newList[index].receivedContainer) newList[index].receivedContainer = {};
    newList[index].receivedContainer.containerNumber = containerNumber;
    newList[index].receivedContainer.containerId = containerId;
    newList[index].receivedContainerID = containerId;
    setSelectedPOItems(newList);
  };

  return (
    <>
      <Dialog fullWidth maxWidth="xl" open={isPOItemListOpen}>
        <DialogTitle alignSelf="center">PO #{selectedPO.id}</DialogTitle>
        <DialogContent style={{ paddingBottom: 0, paddingTop: "1rem" }}>
          <UXDataTableWithoutBody
            tableWidth="100%"
            cols={poItemCols}
            tableId="poItemsListTable"
            rowLength={selectedPOItems.length}
            enablePaging
            rowsPerPage={rowsPerPOItemsPage}
            page={poItemsPage}
            handleChangePage={handlePOItemsPageChange}
            handleChangeRowsPerPage={handleChangeRowsPerPOItemsPage}
            noDataFoundMessage={"There are no PO items"}>
            <StyledTableBody>
              {selectedPOItems.length > 0 &&
                selectedPOItems
                  .slice(
                    poItemsPage * rowsPerPOItemsPage,
                    poItemsPage * rowsPerPOItemsPage + rowsPerPOItemsPage,
                  )
                  .map((item) => {
                    return (
                      <StyledTableRow key={item?.id} hover>
                        <StyledTableCellSmall>{item?.id}</StyledTableCellSmall>
                        <StyledTableCellLarge>
                          {item?.equipmentItem?.itemVendor?.vendorName}
                        </StyledTableCellLarge>
                        <StyledTableCellMedium>
                          {item?.equipmentItem?.subLocation}
                        </StyledTableCellMedium>
                        <StyledTableCellMedium>
                          {item?.equipmentItem?.siteAssetNumber}
                        </StyledTableCellMedium>
                        <StyledTableCellLarge style={{ width: 420 }}>
                          {item?.equipmentItem?.description}
                        </StyledTableCellLarge>
                        <StyledTableCellSmall>{item?.itemQuantity}</StyledTableCellSmall>
                        <StyledTableCellMedium>
                          <TextField
                            size="small"
                            type="number"
                            InputProps={{
                              inputProps: {
                                min: 0,
                                max: item.itemQuantity - item.receivedQuantity,
                              },
                            }}
                            style={{ width: "150px" }}
                            value={item.backorderedQuantity ?? 0}
                            onChange={(e) => {
                              updateBackorderedQty(
                                e,
                                item.id,
                                0,
                                item.itemQuantity - item.receivedQuantity,
                              );
                            }}
                            disabled={!canEdit}
                          />
                        </StyledTableCellMedium>
                        <StyledTableCellMedium>
                          <FormControl fullWidth>
                            <Select
                              size="small"
                              name="poStatus"
                              style={{ width: "150px" }}
                              value={item.orderStatusName}
                              onChange={(e) => {
                                updatePoStatus(e, item.id);
                              }}
                              disabled={!canEdit}>
                              <MenuItem value="" disabled>
                                Select an option
                              </MenuItem>
                              {orderStatuses &&
                                orderStatuses.length &&
                                orderStatuses.map((status) => {
                                  return (
                                    <MenuItem
                                      key={status.orderStatusName}
                                      value={status.orderStatusName}>
                                      {status.orderStatusName}
                                    </MenuItem>
                                  );
                                })}
                            </Select>
                          </FormControl>
                        </StyledTableCellMedium>
                        <StyledTableCellMedium>
                          <TextField
                            size="small"
                            type="number"
                            InputProps={{
                              inputProps: {
                                min: item.receivedQuantity,
                                max: item.itemQuantity - item.backorderedQuantity,
                              },
                            }}
                            style={{ width: "150px" }}
                            value={item.receivedQuantity ?? 0}
                            onChange={(e) => {
                              updateReceivedQty(
                                e,
                                item.id,
                                item.receivedQuantity,
                                item.itemQuantity - item.backorderedQuantity,
                              );
                            }}
                            disabled={!canEdit}
                          />
                          {canEdit && (
                            <ScannerIncreaseInputButton
                              style={{ width: "100%" }}
                              min={0}
                              max={item.itemQuantity - item.backorderedQuantity}
                              initialState={item.receivedQuantity ?? 0}
                              callback={(value) => {
                                let newList = [...selectedPOItems];
                                let index = newList.findIndex((x) => x.id === item.id);

                                newList[index].receivedQuantity = value;
                                setSelectedPOItems(newList);
                              }}
                            />
                          )}
                        </StyledTableCellMedium>
                        <StyledTableCellMedium>
                          <ValidatedMidasNumberTextField
                            isDisabled={!canEdit}
                            margin="none"
                            showLabel={false}
                            fontSize={16}
                            fieldWidth="10rem"
                            midasNumberObject={item.receivedContainer?.sample ?? ""}
                            setMidasNumberObject={(e) => {
                              updateSampleName(e, item.id);
                            }}
                            hasErrors={
                              errorArray.length
                                ? errorArray.find((a) => a.itemId === item.id)?.hasError
                                : true
                            }
                            setHasErrors={(value) => {
                              let index = errorArray.findIndex((a) => a.itemId === item.id);
                              let newItem = [...errorArray];
                              if (index > -1) {
                                newItem[index].hasError = value;
                                setErrorArray(newItem);
                              }
                            }}
                            chemIDToMatch={null}
                            setScannedContainer={(value) => {
                              if (value && typeof value.containerNumber === "number") {
                                updateContainerNumber(value.containerNumber, value.id, item.id);
                              }
                            }}
                          />
                        </StyledTableCellMedium>
                        <StyledTableCellMedium>
                          <Autocomplete
                            style={{ width: "150px" }}
                            noOptionsText="Loading..."
                            value={item.receivedContainer?.containerNumber?.toString() ?? null}
                            onChange={(_, value) => {
                              let containerId = item.receivedContainer?.sample?.containers.find(
                                (x) => x.containerNumber === value,
                              ).id;
                              updateContainerNumber(value, containerId, item.id);
                            }}
                            disablePortal
                            options={
                              item.receivedContainer?.sample?.containers.map(
                                (x) => x.containerNumber,
                              ) ?? []
                            }
                            renderInput={(params) => (
                              <TextField
                                name="containerNumber"
                                {...params}
                                label="Container #"
                                size="small"
                              />
                            )}
                            autoSelect
                            disabled={!canEdit}
                          />
                        </StyledTableCellMedium>
                      </StyledTableRow>
                    );
                  })}
            </StyledTableBody>
          </UXDataTableWithoutBody>
          {isPOItemUpdating && <span style={{ color: "red" }}>*There are unsaved changes</span>}
        </DialogContent>
        <DialogActions style={{ paddingBottom: "1.5rem", paddingTop: 0, alignSelf: "center" }}>
          <GlobalButton
            style={{
              marginTop: "35px",
              marginLeft: "6px",
            }}
            variant="contained"
            onClick={saveChanges}
            disabled={!canEdit}>
            Save Changes
          </GlobalButton>

          <GlobalSecondaryButton
            style={{
              marginTop: "35px",
              marginLeft: "20px",
            }}
            variant="contained"
            onClick={cancelChanges}
            disabled={!canEdit}>
            Cancel Changes
          </GlobalSecondaryButton>

          <GlobalSecondaryButton
            style={{
              marginTop: "35px",
              marginLeft: "20px",
            }}
            variant="contained"
            onClick={() => {
              if (isPOItemUpdating) {
                setIsModalOpen(true);
              } else {
                closeSRReceiveStockModal();
              }
            }}>
            Close
          </GlobalSecondaryButton>
        </DialogActions>
      </Dialog>
      {isModalOpen && (
        <ModalTwoButtons
          title="Warning!"
          button1Text="Yes"
          button1Action={() => {
            saveChanges();
          }}
          button2Text="No"
          button2Action={() => {
            cancelChanges();
            closeSRReceiveStockModal();
          }}
          setOpen={setIsModalOpen}
          open={isModalOpen}>
          <Typography style={{ padding: "0 16px", fontSize: 18, textAlign: "center" }}>
            Do you want to save the changes?
          </Typography>
        </ModalTwoButtons>
      )}
      <DisplayMessage
        title={modalMessage.title}
        buttonText="Close"
        buttonAction={() => {
          setModalMessageIsOpen(false);
          if (!dontCloseStockModal) closeSRReceiveStockModal();
        }}
        setIsModalOpen={setModalMessageIsOpen}
        isModalOpen={modalMessageIsOpen}
        message={modalMessage.message}
      />
    </>
  );
}
