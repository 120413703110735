import { createContext, useState } from "react";
import Vendor from "../../../api/Stockroom/Vendor";
import { useEffect } from "react";
import ModalSimpleButton from "../../../components/Modal/ModalSimpleButton";
import { Typography } from "@mui/material";

export const VendorContext = createContext(null);

export const VendorContextProvider = ({ children }) => {
  const [vendors, setVendors] = useState([]);
  const [isDataLoading, setIsDataLoading] = useState(false);

  const [hasError, setHasError] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    if (hasError) setIsModalOpen(true);
  }, [hasError]);

  const getAllVendors = async () => {
    setIsDataLoading(true);
    await Vendor.getAll()
      .then((data) => {
        setIsDataLoading(false);
        const formattedData = data.sort((a, b) => b.vendorName - a.vendorName).map((vendor) => vendor.ID);
        setVendors(formattedData);
      })
      .catch((err) => {
        setHasError(err);
        setIsModalOpen(true);
      });
  };

  const getVendor = async (id) => {
    await Vendor.getVendorById(id)
      .then((data) => {
        setVendors(data);
      })
      .catch((err) => {
        setHasError(err);
        setIsModalOpen(true);
      });
  };

  const manageVendor = async (payload) => {
    await Vendor.manageVendor(payload).catch((err) => {
      setHasError(err);
      setIsModalOpen(true);
    });
  };

  return (
    <VendorContext.Provider
      value={{ vendors, getVendor, getAllVendors, isDataLoading, manageVendor }}
    >
      {children}

      <ModalSimpleButton
        title={"Error"}
        buttonText={"Close"}
        buttonAction={() => {
          setIsModalOpen(false);
          setHasError("");
        }}
        setOpen={() => {
          setIsModalOpen(false);
          setHasError("");
        }}
        open={isModalOpen}
      >
        <Typography
          style={{ padding: "0 16px", fontSize: 18, textAlign: "center" }}
        >
          {hasError}
        </Typography>
      </ModalSimpleButton>
    </VendorContext.Provider>
  );
};
