import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { REQUEST_INFORMATION_ENUM } from "../../shared";
import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
import STRequestInterval from "../../../../api/StationaryTesting/STRequestInterval";
import { generateUniqueID } from "../../../../global";
import type { FormikProps } from "formik";
import type { Interval, StationaryTest, GeneralInformation } from "../../types";
import IntervalSection from "../Interval/IntervalSection";
import { useDetailedStRequest } from "../../context/DetailedStRequestContext";

type SampleScheduleViewProps = {
  formik: FormikProps<Partial<StationaryTest>>;
  generalInformation: GeneralInformation
};

export default function SampleScheduleView({
  formik,
  generalInformation
}: SampleScheduleViewProps) {
  const [intervals, setIntervals] = useState<Interval[]>([]);
  const [loading, setLoading] = useState(false);
  const { stRequestId, canManageSampleSchedule } = useDetailedStRequest();

  async function handleGetIntervals(isReference: boolean) {
    setLoading(true);

    const stRequestIntervals = await STRequestInterval.populateInterval(
      stRequestId,
      isReference
    );

    if (stRequestIntervals && stRequestIntervals.length > 0) {
      // This is only for React
      const intervalsWithId = stRequestIntervals.map((sri: Interval) => {
        return {
          ...sri,
          id: generateUniqueID(),
        };
      });
      setIntervals(intervalsWithId);
      formik.setFieldValue("requestIntervals", intervalsWithId);
      formik.setFieldValue("intervalSamplesToRegister", []);
    }

    setLoading(false);
  }

  useEffect(() => {
    handleGetIntervals(formik.values.isCandidate ? false : true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleUpdateIntervals(updatedIntervals: Interval[]) {
    formik.setFieldValue("requestIntervals", updatedIntervals);
    setIntervals(updatedIntervals);
  }

  const existRegisteredSample = intervals.some(
    (i) => i.intervalSampleName && i.intervalSampleName !== ""
  );

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          marginBottom: "1rem",
        }}
      >
        <span
          style={{
            textAlign: "left",
            font: "normal normal 600 20px/24px EMprint",
            letterSpacing: 0,
            display: "block",
            marginRight: "2rem",
          }}
        >
          {REQUEST_INFORMATION_ENUM.SAMPLE_SCHEDULE}
        </span>
        <Autocomplete
          value={formik.values.sampleScheduleType}
          disablePortal
          options={["Reference", "Candidate"]}
          renderInput={(params) => (
            <TextField {...params} label="Sample Schedule Type" size="small" />
          )}
          autoSelect
          onChange={async (_, v) => {
            if (v && canManageSampleSchedule) {
              await handleGetIntervals(v === "Reference");
              formik.setFieldValue("sampleScheduleType", v);
              formik.setFieldValue("isCandidate", v !== "Reference");
            }
          }}
          id="sampleScheduleType"
          disabled={!canManageSampleSchedule || existRegisteredSample || loading}
          style={{ minWidth: "25%" }}
        />
      </div>
      <Box display="grid" gridTemplateColumns="1fr" gap={2} mt={1}>
        {loading ? (
          <CircularProgress />
        ) : (
          <IntervalSection
            intervals={intervals}
            setIntervals={(ni: any) => handleUpdateIntervals(ni)}
            intervalSamplesToRegister={
              formik.values.intervalSamplesToRegister ?? []
            }
            setIntervalSamplesToRegister={(newIntervalsToRegister) => {
              formik.setFieldValue(
                "intervalSamplesToRegister",
                newIntervalsToRegister
              );
            }}
            containersReturnLocation={formik.values.containerReturnLocation ?? ""}
            setContainersReturnLocation={(v) =>
              formik.setFieldValue("containerReturnLocation", v)
            }
            generalInformation={generalInformation}
          />
        )}
      </Box>
      <Box display="grid" gridTemplateColumns="1fr 1fr" gap={2} mt={3}></Box>
    </div>
  );
}
