import { useState } from 'react'
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { REQUEST_INFORMATION_ENUM } from "../../shared";
import Autocomplete from "@mui/material/Autocomplete";
import {
  StyledTableBody,
  StyledTableCell,
  StyledTableRow,
  UXDataTableWithoutBody,
} from "../../../../components/UXDataTable";
import type {
  AuthorizationValidation,
  CustomFileAttachment,
  StationaryTest,
} from "../../types";
import {
  DesktopDatePicker,
  DesktopDateTimePicker,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import { StyledShortTextField } from "../../../Distillations/CreateDistillationTabs/styles";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import type { FormikProps } from "formik";
import EmailAddressTextField from "../../../../components/EmailAddressField";
import FileDragAndDrop from "../../../../components/FileDragAndDrop";
import Tooltip from "@mui/material/Tooltip";
import TrashIcon from "@mui/icons-material/Delete";
import { toast } from "react-hot-toast";
import { useDetailedStRequest } from "../../context/DetailedStRequestContext";
import { DatePickerKeyDownEvent } from "../../../../global";

const labelStyle = {
  font: "normal normal normal 12px/15px EMprint",
  color: "#021834",
  marginTop: "1rem",
};

type AuthorizationValidationViewProps = {
  authorizationValidation: AuthorizationValidation;
  formik: FormikProps<Partial<StationaryTest>>;
};

export default function AuthorizationValidationView({
  authorizationValidation,
  formik,
}: AuthorizationValidationViewProps) {
  const { canManageAuthorizationValidation } = useDetailedStRequest();
  const [uploadedFiles, setUploadedFiles] = useState(authorizationValidation.fileAttachments ?? []);

  function deleteFile(file: CustomFileAttachment) {
    const fileAlreadyUploaded = file && file.file.requestId && file.file.requestId !== 0;

    if (fileAlreadyUploaded && canManageAuthorizationValidation) {
      if (file && file.file && file.file.requestId) {
        formik.setFieldValue("deletedFileAttachmentNames", [
          ...formik.values.deletedFileAttachmentNames,
          file.file.name,
        ]);
      }

      const newFiles = uploadedFiles?.filter((f) => f !== file);

      setUploadedFiles(newFiles);
    } else {
      if (!fileAlreadyUploaded) {
        const newFiles = formik.values.fileAttachments?.filter((f) => f !== file);
        formik.setFieldValue("fileAttachments", newFiles ?? []);
      } else {
        toast.error("Only users with Validator role can delete files");
      }
    }
  }

  const properAttachments = [...uploadedFiles];

  if (formik.values.fileAttachments) {
    formik.values.fileAttachments.forEach((a) => properAttachments.push(a));
  }

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <span
        style={{
          textAlign: "left",
          font: "normal normal 600 20px/24px EMprint",
          letterSpacing: 0,
        }}
      >
        {REQUEST_INFORMATION_ENUM.AUTHORIZATION}
      </span>
      <Box
        display="grid"
        gridTemplateColumns="1fr 1fr 1fr"
        gap={2}
        style={{ marginTop: "1rem" }}
      >
        <Autocomplete
          value={formik.values.testEngineerEmail}
          isOptionEqualToValue={(option, value) => value === option}
          disablePortal
          options={authorizationValidation.availableTestEngineerEmails}
          renderInput={(params) => (
            <TextField {...params} label="Engineer" size="small" />
          )}
          autoSelect
          onChange={(_, v) => {
            formik.setFieldValue("testEngineerEmail", v);
          }}
          onBlur={formik.handleBlur}
          id="testEngineerEmail"
          disabled={!canManageAuthorizationValidation}
        />
        <Autocomplete
          value={formik.values.testStandName}
          isOptionEqualToValue={(option, value) => value === option}
          disablePortal
          options={authorizationValidation.availableTestStandNames}
          renderInput={(params) => (
            <TextField {...params} label="Test Stand/MAD #" size="small" />
          )}
          autoSelect
          onChange={(_, v) => {
            formik.setFieldValue("testStandName", v);
          }}
          onBlur={formik.handleBlur}
          id="testStandName"
          disabled={!canManageAuthorizationValidation}
        />
        <TextField
          value={formik.values.supportInformation}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          id="supportInformation"
          label="Support Info"
          size="small"
          disabled={!canManageAuthorizationValidation}
        />
      </Box>
      <Box
        display="grid"
        gridTemplateColumns="1fr 1fr 1fr"
        gap={2}
        style={{ marginTop: "1rem" }}
      >
        <EmailAddressTextField
          validatedUserEmail={formik.values.testStartedByEmail}
          hasErrors={formik.errors?.testStartedByEmail ? true : false}
          setValidatedUserEmail={(value: string) =>
            formik.setFieldValue("testStartedByEmail", value)
          }
          setHasErrors={() => {}}
          labelText="Started By"
          placeholderText="Email"
          fieldWidth={undefined}
          fontSize={undefined}
          isDisabled={!canManageAuthorizationValidation}
          margin="none"
          showPlusMeButton={true}
          size={undefined}
        />

        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DesktopDateTimePicker
            value={formik.values.testStartDate}
            onChange={(value) => {
              formik.setFieldValue("testStartDate", value);
            }}
            inputFormat="MM/dd/yyyy hh:mm aa"
            label="Start Date/Time"
            renderInput={(params) => (
              <StyledShortTextField
                onKeyDown={DatePickerKeyDownEvent}
                {...params}
                style={{ width: "100%" }}
                size="small"
                InputProps={{ ...params.InputProps }}
              />
            )}
            disabled={!canManageAuthorizationValidation}
          />
        </LocalizationProvider>
        <TextField
          value={formik.values.sequenceNumber}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          type="number"
          id="sequenceNumber"
          label="Sequence #"
          size="small"
          disabled={!canManageAuthorizationValidation}
        />
      </Box>
      <Box
        display="grid"
        gridTemplateColumns="1fr 1fr 1fr"
        gap={2}
        style={{ marginTop: "1rem" }}
      >
        <EmailAddressTextField
          validatedUserEmail={formik.values.testEndedByEmail}
          hasErrors={formik.errors?.testEndedByEmail ? true : false}
          setValidatedUserEmail={(value: string) =>
            formik.setFieldValue("testEndedByEmail", value)
          }
          setHasErrors={() => {}}
          labelText="Stopped By"
          placeholderText="Email"
          fieldWidth={undefined}
          fontSize={undefined}
          isDisabled={!canManageAuthorizationValidation}
          margin="none"
          showPlusMeButton={true}
          size={undefined}
        />

        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DesktopDateTimePicker
            value={formik.values.testEndDate}
            onChange={(value) => {
              formik.setFieldValue("testEndDate", value);
            }}
            inputFormat="MM/dd/yyyy hh:mm aa"
            label="Stop Date/Time"
            renderInput={(params) => (
              <StyledShortTextField
                onKeyDown={DatePickerKeyDownEvent}
                {...params}
                style={{ width: "100%" }}
                size="small"
                InputProps={{ ...params.InputProps }}
              />
            )}
            disabled={!canManageAuthorizationValidation}
          />
        </LocalizationProvider>
        <TextField
          value={formik.values.eotCellHourmeter}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          id="eotCellHourmeter"
          label="EOT Cell Hourmeter"
          size="small"
          disabled={!canManageAuthorizationValidation}
        />
      </Box>
      <Box
        display="grid"
        gridTemplateColumns="1fr 1fr 1fr"
        gap={2}
        style={{ marginTop: "1rem" }}
      ></Box>
      <Box
        display="grid"
        gridTemplateColumns="1fr 1fr 1fr"
        gap={2}
      >
        <Autocomplete
          value={formik.values.validationStatusName}
          isOptionEqualToValue={(option, value) => value === option}
          disablePortal
          options={authorizationValidation.availableStValidationStatus}
          renderInput={(params) => (
            <TextField {...params} label="Validation Status" size="small" />
          )}
          autoSelect
          onChange={(_, v) => {
            formik.setFieldValue("validationStatusName", v);
          }}
          onBlur={formik.handleBlur}
          id="validationStatusName"
          disabled={!canManageAuthorizationValidation}
        />
        <EmailAddressTextField
          validatedUserEmail={formik.values.validatedByEmail}
          hasErrors={formik.errors?.validatedByEmail ? true : false}
          setValidatedUserEmail={(value: string) =>
            formik.setFieldValue("validatedByEmail", value)
          }
          setHasErrors={() => {}}
          labelText="Validation By"
          placeholderText="Email"
          fieldWidth={undefined}
          fontSize={undefined}
          isDisabled={!canManageAuthorizationValidation}
          margin="none"
          showPlusMeButton={true}
          size={undefined}
        />
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DesktopDatePicker
            value={formik.values.validationDate}
            onChange={(value) => {
              formik.setFieldValue("validationDate", value);
            }}
            inputFormat="MM/dd/yyyy"
            label="Validation Date"
            renderInput={(params) => (
              <StyledShortTextField
                onKeyDown={DatePickerKeyDownEvent}
                {...params}
                style={{ width: "100%" }}
                size="small"
                InputProps={{ ...params.InputProps }}
              />
            )}
            disabled={!canManageAuthorizationValidation}
          />
        </LocalizationProvider>
      </Box>

      <span style={labelStyle}>Validation Files</span>
      <Box display="grid" gridTemplateColumns="1fr" gap={2} mt={1}>
        <UXDataTableWithoutBody
          cols={["", "File Name"]}
          tableWidth="100%"
          blankFirstHeader={false}
          tableId={`midasFileAttachments`}
          rowLength={["files.length"].length}
          enablePaging={false}
          rowsPerPage={["files.length"].length}
          page={0}
          handleChangePage={null}
          handleChangeRowsPerPage={null}
          noDataFoundMessage={"No Files Added"}
          enableAddIcon={false}
          addFunction={() => null}
          addToolTipText=""
          isDataLoading={false}
          enableCheckbox={false}
          handleRequestSort={null}
          handleSelectAllClick={null}
          order={null}
          orderBy={null}
        >
          <StyledTableBody key={"myTablebody"}>
            {properAttachments.map((file, rowIndex) => {
              return (
                <StyledTableRow hover key={`custom-row-${rowIndex}`}>
                  <StyledTableCell
                    component="th"
                    scope="row"
                    style={{ width: "4rem" }}
                  >
                    <Tooltip title="Delete File" placement="right">
                      <TrashIcon onClick={() => deleteFile(file)}></TrashIcon>
                    </Tooltip>
                  </StyledTableCell>
                  <StyledTableCell>
                    {file.file.uri ? (
                      <a href={file.file.uri}>{file.file.name}</a>
                    ) : (
                      <span>{file.file.name}</span>
                    )}
                  </StyledTableCell>
                </StyledTableRow>
              );
            })}
          </StyledTableBody>
        </UXDataTableWithoutBody>
      </Box>
      <Box display="grid" gridTemplateColumns="1fr" gap={2} mt={1}>
        <FileDragAndDrop
          files={formik.values.fileAttachments?.map((item: any) => item.file)}
          handleFiles={(
            newFiles: { name: string; type: string; size: number }[]
          ) => {
            const listFiles = newFiles.map((item: any) => {
              return {
                file: item,
              };
            });
            formik.setFieldValue(
              "fileAttachments",
              formik.values.fileAttachments?.concat(listFiles)
            );
          }}
          disabled={!canManageAuthorizationValidation}
          container={null}
          handleDeleteFile={() => {}}
          handleFileDownload={() => {}}
        />
      </Box>
      <Box display="grid" gridTemplateColumns="2fr 1fr" gap={2} mt={3}>
        <TextField
          value={formik.values.validationComments}
          label="Test Validation Comments"
          size="small"
          multiline
          rows={4}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          id="validationComments"
          disabled={!canManageAuthorizationValidation}
        />
      </Box>
    </div>
  );
}
