import React,  { useContext } from "react";
import { Box } from "@mui/material";
import SiteLocationAdminMenu from "./SiteLocationAdminMenu.jsx";
import ProjectManagement from "./ProjectManagement.jsx";
import UOMManagement from "./UOMManagement.jsx"
import ReleaseManagement from "./ReleaseManagement.jsx"
import { GlobalTabCollection, StyledTab } from "../../pages/styles";
import { hasRole, Roles } from "../../global";
import UserContext from "../../context/UserContext";
import PageUnauthorized from "../PageUnauthorized";

  const GeneralAdminMenu = ({ ...props }) => {
    const [tabValue, setTabValue] = React.useState(0);
    const handleChange = (event, newValue) => {
      setTabValue(newValue);
    };

    const currentUser = useContext(UserContext)
    const roles = currentUser?.idTokenClaims.roles
    const developer = hasRole(Roles.Developer, roles);
  
    const renderSearchTab = () => {
      switch (tabValue) {
        case 0: {
          return <SiteLocationAdminMenu></SiteLocationAdminMenu>
        }
        case 1: {
          return <ProjectManagement></ProjectManagement>
        }
        case 2: {
          return <UOMManagement></UOMManagement>
        }
        case 3: {
          return developer ? <ReleaseManagement/> : <PageUnauthorized/>
        }
        default: {
          alert(tabValue);
        }
      }
    };
  
    return (
      <>
        <div>
          <Box sx={{ bgcolor: "#fff" }}>
            <GlobalTabCollection value={tabValue} onChange={handleChange} aria-label='ant example'>
              <StyledTab label='Site and Location Management' />
              <StyledTab label='Project Management' />
              <StyledTab label='Units of Measure' />
              <StyledTab label='Release Management' />
            </GlobalTabCollection>
            <Box sx={{ p: 3 }} />
          </Box>
          {renderSearchTab()}
        </div>
      </>
    );
  };
  
  export default GeneralAdminMenu;