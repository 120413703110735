import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { useEffect, useState } from "react";
import MethodApi from "../../../api/LIMS/Method";
import { PackageTestManagementForm } from "./PackageTestManagementForm";
import { PackageTestForm, prepareFormForSave } from "./utilities";
import { GlobalButton } from "../../styles";
import ModalSimpleButton from "../../../components/Modal/ModalSimpleButton";

export function PackageTestManagement() {
  const [loading, setLoading] = useState(false);
  const [packageTests, setPackageTests] = useState<any[]>([]);
  const [methods, setMethods] = useState<any[]>([]);
  const [selectedPackageTest, setSelectedPackageTest] =
    useState<MethodApi | null>(null);
  const [packageTestForm, setPackageTestForm] = useState<PackageTestForm>();
  const [modalObj, setModalObj] = useState({
    title: "",
    button: "",
    message: "",
  });
  const [modalOpen, setModalOpen] = useState(false);

  const loadPackageTests = () => {
    setLoading(true);

    const packageTestsPromise = MethodApi.getPackageTests().then((data) =>
      setPackageTests(data)
    );

    const methodsPromise = MethodApi.getAll().then((res) => {
      setMethods(
        res
          .filter((result: any) => result.isActive === true)
          .sort((a: any, b: any) => a.name.localeCompare(b.name))
      );
    });

    Promise.allSettled([packageTestsPromise, methodsPromise]).finally(() =>
      setLoading(false)
    );
  };

  useEffect(() => {
    loadPackageTests();
  }, []);

  const resetForm = () => {
    setPackageTestForm(undefined);
    setSelectedPackageTest(null);
  };

  const handleSave = (values: PackageTestForm) => {
    const flattenedValues = prepareFormForSave(values);

    if (!flattenedValues.name.startsWith("PKG-")) {
      flattenedValues.name = `PKG-${flattenedValues.name}`;
    }

    if (packageTestForm?.name != null && packageTestForm.name.length === 0) {
      MethodApi.add(flattenedValues)
        .then((result) => {
          if (result.message === "Success")
          {
            setModalObj({
              title: "Success!",
              message: "New Package Test created successfully!",
              button: "OK",
            });
            resetForm();
          } else {
            setModalObj({
              title: "Error!",
              message: `New Test Package could not be created. ${result && result.message ? result.message : 'Unknown error, please try again.'}`,
              button: "OK",
            });
          }

          setModalOpen(true);
          loadPackageTests()
        })

          // setModalObj({
          //   title: "Success!",
          //   message: "New Test Package created!",
          //   button: "OK",
          // });
          // setModalOpen(true);
        //   // resetForm();
        // })
        // .catch(() => {
        //   setModalObj({
        //     title: "Error!",
        //     message: "New Test Package could not be created. Please try again.",
        //     button: "OK",
        //   });
        //   setModalOpen(true);
        // })
        // .finally(() => loadPackageTests());
    } else {
      MethodApi.update(flattenedValues.name, flattenedValues)
        .then((result) => {
          if (result.message === "Success")
          {
            setModalObj({
              title: "Success!",
              message: "Test Package updated!",
              button: "OK",
            });
            resetForm();
          } else {
            setModalObj({
              title: "Error!",
              message: `Package Test could not be updated. ${result && result.message ? result.message : 'Unknown error, please try again.'}`,
              button: "OK",
            });
          }

          setModalOpen(true);
          loadPackageTests();
        })

        //   setModalObj({
        //     title: "Success!",
        //     message: "Test Package updated!",
        //     button: "OK",
        //   });
        //   setModalOpen(true);
        //   resetForm();
        // })
        // .catch(() => {
        //   setModalObj({
        //     title: "Error!",
        //     message: "Test Package could not be updated. Please try again.",
        //     button: "OK",
        //   });
        //   setModalOpen(true);
        // })
        // .finally(() => loadPackageTests());
  }};

  const handleCreate = () => {
    setSelectedPackageTest(null);

    setPackageTestForm({
      name: "",
      description: null,
      isActive: true,
      packageTestOwnerEmail: "",
      isPackageTest: true,
      packageTestRequiredAmount: null,
      packageTestRequiredAmountUoMName: null,
      packageTestCriterias: [],
    });
  };

  const handleUpdateExisting = (method: MethodApi | null) => {
    setSelectedPackageTest(method);

    if (!method) {
      setPackageTestForm(undefined);
      return;
    }

    setPackageTestForm({
      name: method.name,
      description: method.description,
      isActive: method.isActive,
      packageTestOwnerEmail: method.packageTestOwnerEmail,
      isPackageTest: true,
      packageTestCriterias: method.packageTestCriterias,
      packageTestRequiredAmount: method.packageTestRequiredAmount,
      packageTestRequiredAmountUoMName: method.packageTestRequiredAmountUoMName,
    });
  };

  return (
    <Box>
      <Typography variant="body1" gutterBottom>
        Select an existing Package Test to update it or create a new one
      </Typography>

      <Stack direction="row" spacing={4} alignItems="center" marginBottom={4}>
        <Autocomplete
          autoSelect
          autoHighlight
          loading={loading}
          options={packageTests}
          getOptionLabel={(option) => option.name}
          renderOption={(props, option) => (
            <li {...props} key={option.name}>
              {option.name}
            </li>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Package Test"
              variant="outlined"
              size="small"
              margin="dense"
            />
          )}
          value={selectedPackageTest}
          onChange={(event, value) => handleUpdateExisting(value)}
          disabled={Boolean(packageTestForm)}
          sx={{ width: "24rem" }}
        />

        <GlobalButton
          variant="contained"
          onClick={handleCreate}
          disabled={Boolean(packageTestForm)}
        >
          Create new Package Test
        </GlobalButton>
      </Stack>

      {packageTestForm && (
        <PackageTestManagementForm
          initialValue={packageTestForm}
          onCancel={resetForm}
          onSave={handleSave}
          methods={methods}
        />
      )}

      <ModalSimpleButton
        title={modalObj.title}
        buttonText={modalObj.button}
        buttonAction={() => {
          setModalOpen(false);
        }}
        open={modalOpen}
        setOpen={setModalOpen}
      >
        {modalObj.message}
      </ModalSimpleButton>
    </Box>
  );
}
