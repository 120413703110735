import { createContext, useEffect, useState } from "react";
import EquipmentStatus from "../../../api/Stockroom/EquipmentStatus";
import ModalSimpleButton from "../../../components/Modal/ModalSimpleButton";
import { Typography } from "@mui/material";

export const EquipmentStatusContext = createContext(null);

export const EquipmentStatusContextProvider = ({ children }) => {
  const [equipmentStatuses, setEquipmentStatuses] = useState([]);
  const [hasError, setHasError] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    if (hasError) setIsModalOpen(true);
  }, [hasError]);

  const getAllEquipmentStatuses = async () => {
    await EquipmentStatus.getAll()
      .then((data) => {
        setEquipmentStatuses(data);
      })
      .catch((err) => {
        setHasError(err);
        setIsModalOpen(true);
      });
  };

  const getEquipmentStatusByName = async (statusName) => {
    await EquipmentStatus.getEquipmentStatusByName(statusName)
      .then((data) => {
        setEquipmentStatuses(data);
      })
      .catch((err) => {
        setHasError(err);
        setIsModalOpen(true);
      });
  };

  return (
    <EquipmentStatusContext.Provider
      value={{
        equipmentStatuses,
        getEquipmentStatusByName,
        getAllEquipmentStatuses,
      }}
    >
      {children}
      <ModalSimpleButton
        title={"Error"}
        buttonText={"Close"}
        buttonAction={() => {
          setIsModalOpen(false);
          setHasError("");
        }}
        setOpen={() => {
          setIsModalOpen(false);
          setHasError("");
        }}
        open={isModalOpen}
      >
        <Typography
          style={{ padding: "0 16px", fontSize: 18, textAlign: "center" }}
        >
          {hasError}
        </Typography>
      </ModalSimpleButton>
    </EquipmentStatusContext.Provider>
  );
};
