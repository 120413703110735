import API from "..";
import {
  SqcStatus,
} from "../../lib/types/sqc";

export class SqcStatusApi {
  static async getAllStatuses(): Promise<SqcStatus[]> {
    const api = await API();

    if (!api) {
      throw new Error("Failed to create an API instance");
    }

    const { data } = await api.get("/sqcstatus");

    return data?.result;
  }
}
