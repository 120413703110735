import React from "react";

const BlendingAdminMenu = ({ ...props }) => {
  return (
    <div>
      <p>
        Coming soon to a browser near you!
      </p>
        <ul>
          <li>Batch Certification Status</li>
          <li>Batch Status</li>
          <li>Blend Facility</li>
          <li>Blend Priority</li>
          <li>Blend Product Group</li>
          <li>Blend Status</li>
          <li>Blend Study Status</li>
          <li>Blend Study Type</li>
        </ul>
    </div>
  );
};

export default BlendingAdminMenu;