import React, { useEffect } from "react";
import "./index.css";
import App from "./app";
import { BrowserRouter } from "react-router-dom";
import { MsalProvider, MsalAuthenticationTemplate } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import msalInstance from "./services/auth/msalInstance";
import { appLoginRequestAzure } from "./services/auth/msalConfigAzureAd";
import { appLoginRequestAdfs } from "./services/auth/msalConfigAdfs";
import Logout from "./pages/External";
import { isAdfs } from "./services/auth/isAdfs";

const AuthProvider = () => {

  const [canRender, setCanRender] = React.useState(false);
  const [authProvider, setAuthProvider] = React.useState(null);
  const [loggedUser, setLoggedUser] = React.useState(null);
  const [appLoginRequest, setAppLoginRequest] = React.useState(null);
  const [newMsalInstance, setnewMsalInstance] = React.useState(null);
  const [intervalId, setIntervalId] = React.useState(0);


  useEffect(() => {
    isAdfs();
  }, []);

  useEffect(() => {

    if (intervalId === 0) {
      const newintervalId = setInterval(() => {
        if (window.localStorage.getItem("AUTH_PROVIDER") != null && window.localStorage.getItem("AUTH_PROVIDER") !== undefined) {
          if (canRender === false) {
            setCanRender(true)
            clearInterval(newintervalId);
            setIntervalId(0);
            return;
          }
        }
      }, 1000);
    }
  }, [canRender, intervalId]);

  useEffect(() => {
    if (canRender === true) {

      if(msalInstance === null)
        window.location.reload()

      setnewMsalInstance(msalInstance);
    }
  }, [canRender])

  useEffect(() => {

    if (newMsalInstance != null) {
      setAuthProvider(window.localStorage.getItem("AUTH_PROVIDER"));
      setLoggedUser(newMsalInstance.getAllAccounts().length > 0 || window.localStorage.getItem("SHOULD_LOGIN"));
      setAppLoginRequest(authProvider === "Adfs" ? appLoginRequestAdfs : appLoginRequestAzure);
    }

  }, [authProvider, newMsalInstance])

  return (
    <>{newMsalInstance === null ? <></> : <>
      {
        loggedUser || window.location.pathname === '/' ? <BrowserRouter>
          <MsalProvider instance={newMsalInstance}>
            <MsalAuthenticationTemplate
              interactionType={InteractionType.Redirect}
              authenticationRequest={appLoginRequest}
            >
              <App />
            </MsalAuthenticationTemplate>
          </MsalProvider>

        </BrowserRouter> :
          <Logout></Logout>
      }
    </>}</>
  )
};

export default AuthProvider;
