import { React, useEffect, useState, useContext } from "react";
import { useLocation, useHistory } from 'react-router-dom'
import {TextField, Autocomplete, Box,Divider, Accordion, Typography, FormControlLabel, Checkbox, CircularProgress} from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import {StyledShortTextField, StyledTextarea, StyledMediumTextField, StyledAutocomplete, StyledAccordionSummary, StyledScrollingAccordionDetails} from "./styles";
import UserContext from "../../../context/UserContext";
import TemplateTables from '../../../components/TemplateTables'
import ModalSimpleButton from '../../../components/Modal/ModalSimpleButton'
import EmailAddressTextField from "../../../components/EmailAddressField";
import BillingInfoField from "../../../components/BillingInfoField";
import DistillationExperimentTemplate from '../../../api/Distillation/DistillationExperimentTemplate'
import DistillationExperimentPriority from '../../../api/Distillation/DistillationExperimentPriority'
import DistillationExperiment from '../../../api/Distillation/DistillationExperiment'
import DistillationType from '../../../api/Distillation/DistillationType'
import Container from "../../../api/LIMS/Container";
import Sample from '../../../api/LIMS/Sample';
import { outlinedInputClasses } from "@mui/material/OutlinedInput";
import { inputLabelClasses } from "@mui/material/InputLabel";
import { styled } from "@mui/material/styles";
import { formatCreatedAndModifiedDateInfo, DatePickerKeyDownEvent, isNumber, ConvertUOMs } from "../../../global";
import { GlobalButton } from "../../../pages/styles";
import UnitOfMeasure from "../../../api/Admin/UnitOfMeasure";

const ValidatedTextField = styled(TextField)({
    [`& .${outlinedInputClasses.root} .${outlinedInputClasses.notchedOutline}`]: {
      borderColor: "green"
    },
    [`&:hover .${outlinedInputClasses.root} .${outlinedInputClasses.notchedOutline}`]: {
      borderColor: "green"
    },
    [`& .${outlinedInputClasses.root}.${outlinedInputClasses.focused} .${outlinedInputClasses.notchedOutline}`]: {
      borderColor: "green"
    },
    [`& .${outlinedInputClasses.input}`]: {
      color: "green"
    },
    [`&:hover .${outlinedInputClasses.input}`]: {
      color: "green"
    },
    [`& .${outlinedInputClasses.root}.${outlinedInputClasses.focused} .${outlinedInputClasses.input}`]: {
      color: "green"
    },
    [`& .${inputLabelClasses.outlined}`]: {
      color: "green"
    },
    [`&:hover .${inputLabelClasses.outlined}`]: {
      color: "green"
    },
    [`& .${inputLabelClasses.outlined}.${inputLabelClasses.focused}`]: {
      color: "green"
    },

    id:"outlined-normal",
    width:"25%",
    marginRight:"15px"
  });

const NewDistillationExperiment = () => {
    const [foundExperiment, setFoundExperiment] = useState(null)
    const [selectedChemID, setSelectedChemID] = useState(null)
    const [requesterEmail, setRequesterEmail] = useState(null)
    const [requesterInvalid, setRequesterInvalid] = useState(false);

    const [experimentFormData, setExperimentFormData] = useState({
        midasContainer: null,
        requestedCompletionDate: null,
        jadeNumber: null,
        comments: '',
        keywords: '',
        mnemonic: '',
        experimentDescription: '',
        isRMCRequest: false,
        initialSampleAmount: 0,
        initialSampleAmountUoM: null
    })
    const [experimentTemplates, setExperimentTemplates] = useState([])
    const [experimentPriorities, setExperimentPriorities] = useState([])
    const [experimentPriority, setExperimentPriority] = useState('')
    const [distillationTypes, setDistillationTypes] = useState([])
    const [distillationType, setDistillationType] = useState('')
    const [availableUOMs, setAvailableUOMs] = useState([])

    const [errorChecks, setErrorChecks] = useState({
        feedMidasContainer: false,
    })
    //const [feedMidasContainer, setFeedMidasContainer] = useState({ sampleName: '', containerNumber: '' })
    const [feedMidasContainer, setFeedMidasContainer] = useState(null)
    const [sample, setSample] = useState(null)
    //    const [sample, setSample] = useState({ description: '', currentAmount: '', containerSizeUoM: '' })

    const [selectedTemplateCut, setSelectedTemplateCut] = useState({})
    // const [isModalOpen, setIsModalOpen] = useState(false)
    // const [modalContent, setModalContent] = useState({ title: '', message: '' })
    const [isLoadingExperiment, setIsLoadingExperiment] = useState(false)
    const [isPreAnalysisAllowed, setIsPreAnalysisAllowed] = useState(true)
    const [isSubmitting, setIsSubmitting] = useState(false)

    const [isAtmosphericAccordionAllowed, setIsAtmosphericAccordionAllowed] = useState(false)
    const [isAtmosphericAccordionExpanded, setIsAtmosphericAccordionExpanded] = useState(false)
    const [atmosphericTemplate, setAtmosphericTemplate] = useState(null)
    const [atmosphericMethods, setAtmosphericMethods] = useState([])
    const [atmosphericTemplateCuts, setAtmosphericTemplateCuts] = useState([])

    const [isVacuumAccordionAllowed, setIsVacuumAccordionAllowed] = useState(false)
    const [isVacuumAccordionExpanded, setIsVacuumAccordionExpanded] = useState(false)
    const [vacuumTemplate, setVacuumTemplate] = useState(null)
    const [vacuumMethods, setVacuumMethods] = useState([])
    const [vacuumTemplateCuts, setVacuumTemplateCuts] = useState([])

    const [isExtractionAccordionAllowed, setIsExtractionAccordionAllowed] = useState(false)
    const [isExtractionAccordionExpanded, setIsExtractionAccordionExpanded] = useState(false)
    const [extractionTemplate, setExtractionTemplate] = useState(null)
    const [extractionMethods, setExtractionMethods] = useState([])
    const [extractionTemplateCuts, setExtractionTemplateCuts] = useState([])

    const [modalMessagesOpen, setModalMessagesOpen] = useState(false);
    const modalMessagesButtonText = 'Ok'
    const [modalMessagesTitle, setModalMessagesTitle] = useState('');
    const [modalMessagesText, setModalMessagesText] = useState('');

    const currentUser = useContext(UserContext)
    const location = useLocation()
    const history = useHistory()

    const canUserChangeExperiment =
        foundExperiment?.requestedByEmail === currentUser.username &&
        foundExperiment?.distillationStatusName === 'Submitted'

    const atmosphericExperimentTemplates = experimentTemplates.filter((experimentTemplate) => {
        return experimentTemplate?.distillationTypeName?.toLowerCase() === 'atmospheric' //&& experimentTemplate?.isActive
    })

    const vacuumExperimentTemplates = experimentTemplates.filter((experimentTemplate) => {
        return experimentTemplate?.distillationTypeName?.toLowerCase() === 'vacuum' //&& experimentTemplate?.isActive
    })

    const extractionExperimentTemplates = experimentTemplates.filter((experimentTemplate) => {
        return experimentTemplate?.distillationTypeName?.toLowerCase() === 'extractions' //&& experimentTemplate?.isActive
    })

    function closeModalMessagesWithReroute(reroute) {
        setModalMessagesOpen(false);

        if (reroute)
        {
            history.push('/distillations')
        }
    }

    function closeModalMessages(reroute) {
        setModalMessagesOpen(false);
    }
    
   function openModalMessages(title, text) {
        setModalMessagesOpen(true);
        setModalMessagesTitle(title);
        setModalMessagesText(text);
    }

    const handleAccordionsEnablement = (experimentType) => {
        if (experimentType === 'Extractions') {
            setIsExtractionAccordionAllowed(true)
            setIsAtmosphericAccordionAllowed(false)
            setIsVacuumAccordionAllowed(false)
        }
        else if (experimentType === 'Atmospheric') {
            setIsExtractionAccordionAllowed(false)
            setIsAtmosphericAccordionAllowed(true)
            setIsVacuumAccordionAllowed(false)
        }
        else if (experimentType === 'Vacuum') {
            setIsExtractionAccordionAllowed(false)
            setIsAtmosphericAccordionAllowed(false)
            setIsVacuumAccordionAllowed(true)
        }
        else if (experimentType === 'Atmospheric/Vacuum') {
            setIsExtractionAccordionAllowed(false)
            setIsAtmosphericAccordionAllowed(true)
            setIsVacuumAccordionAllowed(true)
        }
        else {
            setDistillationType('')
            setIsExtractionAccordionAllowed(false)
            setIsAtmosphericAccordionAllowed(false)
            setIsVacuumAccordionAllowed(false)
        }
    }

    const formatAsTemplateCut = (cut) => {
        return {
            id: cut?.id,
            chemID: cut?.chemID,
            distillationCutTypeName: cut?.distillationCutTypeName,
            requiresRefridgeration: cut?.requiresRefrigeration,
            testingReturnLocationName: cut?.testingReturnLocationName,
            startingTemperatureC: cut?.requestedStartingTemperatureC,
            endTemperatureC: cut?.requestedEndTemperatureC,
            testingTemplateJSON: cut?.testTemplateJSON
        }
    }

    const convertExperimentCutToAtmosphericTemplateCut = (cutArray) => {
        const convertedCutArray = cutArray?.filter(cut => cut.thisDistillationCutType.isAtmospheric === true)
        .map(cut => {
            return formatAsTemplateCut(cut);
        })
        return convertedCutArray
    }

    const convertExperimentCutToVacuumTemplateCut = (cutArray) => {
        const convertedCutArray = cutArray?.filter(cut => cut.thisDistillationCutType.isHighVacuum === true)
        .map(cut => {
            return formatAsTemplateCut(cut);
        })
        return convertedCutArray
    }

    const convertExperimentCutToExtractionTemplateCut = (cutArray) => {
        const convertedCutArray = cutArray?.filter(cut => cut.thisDistillationCutType.isExtraction === true)
        .map(cut => {
            return formatAsTemplateCut(cut);
        })
        return convertedCutArray
    }

    // const requiredFormInformationIsFilled = () => {
    //     return formHasExperimentDescription() &&
    //         formHasValidContainerInfo() &&
    //         formHasRequesterEmail() &&
    //         formHasValidJadeNumber() &&
    //         formHasCommentsIfPriorityIsRush() &&
    //         formHasRequestedCompletionDate() &&
    //         formHasDistillationType() &&
    //         formHasValidExperimentTypes() &&
    //         selectedExperimentTypesHaveValidCuts() &&
    //         formHasValidChargeInfo();
    // }

    const formHasExperimentDescription = () => {
        return typeof experimentFormData?.experimentDescription === 'string' && experimentFormData?.experimentDescription.length > 0;
    }

    const formHasValidContainerInfo = () => {
        return typeof sample?.sampleName === 'string' && sample?.sampleName.length > 0 &&
            //experimentFormData.initialSampleAmount <= feedMidasContainer?.currentAmount &&
            feedMidasContainer?.containerStatusName === 'Confirmed';
    }

    const formHasValidChargeInfo = () => {
        if (isNumber(experimentFormData.initialSampleAmount) && experimentFormData.initialSampleAmountUoM !== null)
        {
            var containerAmount = ConvertUOMs(sample, sample.substance, feedMidasContainer?.currentAmount,  availableUOMs.find(uom =>  uom.uoMName === feedMidasContainer.containerSizeUoM), availableUOMs.find(uom =>  uom.uoMName === experimentFormData.initialSampleAmountUoM))

            if (experimentFormData.initialSampleAmount <= containerAmount)
                {
                    return true
                } else {
                    return false
                }

        } else {
            return false
        }
    }

    const formHasRequesterEmail = () => {
        return typeof requesterEmail === 'string' && requesterEmail.length > 0;
    }

    const formHasValidJadeNumber = () => {
        return typeof experimentFormData?.jadeNumber === 'string' && experimentFormData?.jadeNumber.length > 0;
    }

    const formHasCommentsIfPriorityIsRush = () => {
        if (experimentPriority === 'Rush') {
            return typeof experimentFormData?.comments === 'string' && experimentFormData?.comments.length > 0;
        }
        return true;
    }

    const formHasRequestedCompletionDate = () => {
        return experimentFormData.requestedCompletionDate !== null;
    }

    const formHasDistillationType = () => {
        return typeof distillationType === 'string' && distillationType.length > 0;
    }

    const formHasValidExperimentTypes = () => {
        return isAtmosphericAccordionAllowed || isVacuumAccordionAllowed || isExtractionAccordionAllowed;
    }

    const selectedExperimentTypesHaveValidCuts = () => {
        return selectedAtmosphericCutsAreValid() && selectedVacuumCutsAreValid();
    }

    const selectedAtmosphericCutsAreValid = () => {
        if (isAtmosphericAccordionAllowed) {
            return atmosphericTemplateCuts.findIndex(x => x.distillationCutTypeName === 'Atmospheric Cut') !== -1 &&
                atmosphericTemplateCuts.findIndex(x => x.distillationCutTypeName === 'Atmospheric Resid') !== -1 &&
                atmosphericTemplateCuts.findIndex(x => x.distillationCutTypeName === 'Atmospheric Charge') !== -1;
        }
        return true;
    }

    const selectedVacuumCutsAreValid = () => {
        if (isVacuumAccordionAllowed) {
            return vacuumTemplateCuts.findIndex(x => x.distillationCutTypeName === 'Vacuum Cut') !== -1 &&
                vacuumTemplateCuts.findIndex(x => x.distillationCutTypeName === 'Vacuum Resid') !== -1 &&
                vacuumTemplateCuts.findIndex(x => x.distillationCutTypeName === 'Vacuum Charge') !== -1;
        }
        return true;
    }

    function ErrorCheckOnSave () {
        let hasErrors = []

        if (formHasExperimentDescription() === false)
        {
            hasErrors.push("An experiment description must be selected.")
        }

        if (formHasValidContainerInfo() === false)
        {
            hasErrors.push('The selected container is not valid.')
        }

        if (formHasRequesterEmail() === false)
        {
            hasErrors.push('The experiment must have a valid requester email.')
        }

        if (formHasValidJadeNumber() === false)
        {
            hasErrors.push('The experiment must have a valid Jade number.')
        }

        if (formHasCommentsIfPriorityIsRush() === false)
        {
            hasErrors.push('The experiment must have a comment for this priority.')
        }

        if (formHasRequestedCompletionDate() === false)
        {
            hasErrors.push('The experiment must have a valid requested completion date.')
        }

        if (formHasDistillationType() === false)
        {
            hasErrors.push('The experiment must have a valid distillation type selected.')
        }

        if (formHasValidExperimentTypes() === false)
        {
            hasErrors.push('The experiment must have a cut type cateogry selected.')
        }

        if (selectedExperimentTypesHaveValidCuts() === false)
        {
            hasErrors.push('There must be a Charge, Cut, and Resid cut type on this experiment.')
        }

        if (formHasValidChargeInfo() === false)
        {
            hasErrors.push('The selected container has insufficent volume.')
        }
    
        if (hasErrors.length === 0) {
             return true;     
        } else {
            openModalMessages("Errors Detected", hasErrors, "Ok")
            return false;
        }
    }

    const handleFeedMidasContainer = async () => {
        const splitString = experimentFormData.midasContainer?.split("/")

        if (splitString && splitString.length === 2) {
            try {
                const container = await Container.getContainerBySampleAndNumber(splitString[0], splitString[1])
                const sampleResult = await Sample.get(container?.sampleName);
                setSample(sampleResult.result);
                setFeedMidasContainer(container);
                setErrorChecks(prevState => ({
                    ...prevState,
                    feedMidasContainer: false
                }))
            } catch (e) {
                setErrorChecks(prevState => ({
                    ...prevState,
                    feedMidasContainer: true
                }))
            }
        } else {
            setErrorChecks(prevState => ({
                ...prevState,
                feedMidasContainer: true
            }))
        }
    }

    const handleKeyPressFeedMidasContainer = (event) => {
        if (event.key === 'Enter' || event.key === 'Tab') {
            handleFeedMidasContainer()
        } else {
            setFeedMidasContainer(null)
            setSample(null)
        }
    }

    function handleUpdateExperiment () 
    {
        if (ErrorCheckOnSave() === false)
        {
            return
        }

        setIsSubmitting(true)

        const experimentCuts = [...atmosphericTemplateCuts, ...vacuumTemplateCuts, ...extractionTemplateCuts]
        const formattedExperimentCuts = experimentCuts.map((experimentCut) => {
            return {
                ...experimentCut,
                thisDistillationExperiment: null,
                thisSample: null,
                testingReturnLocation: null,
                thisDistillationCutType: null,
                thisDistillationType: null,
            }
        })

        const payload = {
            ...foundExperiment,
            mnemonic: experimentFormData.mnemonic,
            chargeCode: experimentFormData.chargeCode,
            comments: experimentFormData.comments,
            requestedByEmail: requesterEmail,
            requestedCompletionDate: experimentFormData.requestedCompletionDate,
            status: null,
            //distillationTypeName: distillationType,
            thisDistillationType: null,
            initialContainerID: feedMidasContainer?.id,
            initialContainer: null,
            distillationPriorityName: experimentPriority,
            priority: null,
            experimentCuts: formattedExperimentCuts,
            isRMCRequest: experimentFormData.isRMCRequest,
            keywords: experimentFormData.keywords,
            experimentDescription: experimentFormData.experimentDescription,
            initialSampleAmount: experimentFormData.initialSampleAmount,
            initialSampleAmountUoM: experimentFormData.initialSampleAmountUoM
        }

        DistillationExperiment.updateExperiment(payload).then((res) => {

            setIsSubmitting(false)

            if (res && res.message === 'Success') {
                setExperimentFormData({
                    midasContainer: null,
                    requestedCompletionDate: null,
                    jadeNumber: '',
                    comments: '',
                    keywords: '',
                    mnemonic: '',
                    experimentDescription: '',
                    isRMCRequest: false,
                    initialSampleAmount: 0, 
                    initialSampleAmountUoM: null
                })
                setRequesterEmail(null)
                setFeedMidasContainer(null)
                setSample(null)
                setExperimentPriority('')
                setDistillationType('')
                setVacuumTemplate(null)
                setVacuumTemplateCuts([])
                setVacuumMethods([])
                setAtmosphericTemplate(null)
                setAtmosphericTemplateCuts([])
                setAtmosphericMethods([])
                setExtractionTemplate(null)
                setExtractionTemplateCuts([])
                setExtractionMethods([])
                openModalMessages('Experiment Updated', `Your experiment has been successfullly updated.`);
            } else {
                if (res.message)
                {
                    openModalMessages('Experiment Failed to Update', `${res ? res.message : ''}. Contact support if you feel this is an error.`);
                } else {
                    openModalMessages('Experiment Failed to Update', `Unspecified Error, Contact support if you feel this is an error.`);
                }              
            }
        })

        // const response = await DistillationExperiment.updateExperiment(payload)

        // if (response) {
        //     setExperimentFormData({
        //         midasContainer: null,
        //         requestedCompletionDate: null,
        //         jadeNumber: '',
        //         comments: '',
        //         keywords: '',
        //         mnemonic: '',
        //         experimentDescription: '',
        //         isRMCRequest: false,
        //         initialSampleAmount: 0, 
        //         initialSampleAmountUoM: null
        //     })
        //     setRequesterEmail(null)
        //     setFeedMidasContainer(null)
        //     setSample(null)
        //     setExperimentPriority('')
        //     setDistillationType('')
        //     setVacuumTemplate(null)
        //     setVacuumTemplateCuts([])
        //     setVacuumMethods([])
        //     setAtmosphericTemplate(null)
        //     setAtmosphericTemplateCuts([])
        //     setAtmosphericMethods([])
        //     setExtractionTemplate(null)
        //     setExtractionTemplateCuts([])
        //     setExtractionMethods([])
        //     setIsModalOpen(true)
        //     setModalContent({
        //         title: 'Success!',
        //         message: 'Your experiment has successfully been updated.'
        //     })
        // } else {
        //     setIsModalOpen(true)
        //     setModalContent({
        //         title: 'Error!',
        //         message: 'There was an error when updating this experiment. Please try again later.'
        //     })
        // }
        // setIsSubmitting(false)
    }

    function handleSubmitExperiment()
    {
        if (ErrorCheckOnSave() === false)
        {
            return
        }

        setIsSubmitting(true)

        const newAtmosphericTemplateCuts = atmosphericTemplateCuts.map(cut => {
            return {
                ...cut,
                distillationTypeName: 'Atmospheric'
            }
        })

        const newVacuumTemplateCuts = vacuumTemplateCuts.map(cut => {
            return {
                ...cut,
                distillationTypeName: 'Vacuum'
            }
        })

        const newExtractionTemplateCuts = extractionTemplateCuts.map(cut => {
            return {
                ...cut,
                distillationTypeName: 'Extractions'
            }
        })

        const experimentCuts = [...newAtmosphericTemplateCuts, ...newVacuumTemplateCuts, ...newExtractionTemplateCuts]

        const formattedExperimentCuts = experimentCuts.map((experimentCut) => {
            return {
                id: 0,
                distillationNumber: 0,
                thisDistillationExperiment: null,
                sampleName: null,
                thisSample: null,
                chemID: experimentCut?.chemID,
                requestedStartingTemperatureC: experimentCut?.startingTemperatureC,
                requestedEndTemperatureC: experimentCut?.endTemperatureC,
                actualStartingTemperatureC: null,
                actualEndTemperatureC: null,
                requiresRefrigeration: experimentCut?.requiresRefridgeration,
                testTemplateJSON: experimentCut?.testingTemplateJSON,
                testingReturnLocationName: experimentCut?.testingReturnLocationName,
                testingReturnLocation: null,
                distillationCutTypeName: experimentCut?.distillationCutTypeName,
                thisDistillationCutType: null,
                distillationCutYieldInG: null,
                //distillationTypeName: experimentCut?.distillationTypeName,
                thisDistillationType: null,
                comments: null,
                solventUser: null,
                viscosityGrade: null,
                extractionRunNumber: null,
                treatRatio: null,
                densityYieldPercent: null,
                riYieldPercent: null,
                averageYieldPercent: null,
                carryUnderYieldPercent: null,
                numberExtractionStages: null,
                extractSolventWeightPercent: null,
                raffinateSolventWeightPercent: null,
                solventWaterWeightPercent: null,
                towerBottomTemperatureC: null,
                towerTopTemperatureC: null,
                dewaxedPercent: null,
                raffinatePercent: null,
                pressure: null,
                potLiquidTemperatureC: null,
                distillationCutStatusName: 'InProgress'
            }
        })

        const payload = {
            id: 0,
            mnemonic: experimentFormData.mnemonic,
            chargeCode: experimentFormData.jadeNumber,
            comments: experimentFormData.comments,
            createdByEmail: null,
            createdDate: new Date(),
            lastModifiedByEmail: null,
            lastModifiedDate: null,
            requestedByEmail: requesterEmail,
            requestedCompletionDate: experimentFormData.requestedCompletionDate,
            distillationStatusName: 'Submitted',
            status: null,
            distillationTypeName: distillationType,
            thisDistillationType: null,
            initialContainerID: feedMidasContainer?.id,
            initialContainer: null,
            initialSampleAmount: experimentFormData.initialSampleAmount,
            initialSampleAmountUoM: experimentFormData.initialSampleAmountUoM,
            //initialSampleAmountUoM: feedMidasContainer?.containerSizeUoM,
            distillationPriorityName: typeof experimentPriority === 'string' && experimentPriority.length > 0 ? experimentPriority : 'Normal',
            priority: null,
            experimentCuts: formattedExperimentCuts,
            finalSampleAmount: null,
            finalSampleAmountUoM: null,
            fSampleAmountUoM: null,
            startingAPI: null,
            requestedStartTemperatureC: null,
            requestedEndTemperatureC: null,
            actualStartTemperatureC: null,
            actualEndTemperatureC: null,
            isRMCRequest: experimentFormData.isRMCRequest,
            keywords: experimentFormData.keywords,
            experimentDescription: experimentFormData.experimentDescription,
            experimentStartDate: null,
            experimentCompleteDate: null,
            waterWeight: null,
            atmosphericWettage: null,
            vacuumWettage: null,
            stillNumber: null,
            correctionReason: null
        }

        DistillationExperiment.createExperiment(payload).then((res) => {

            setIsSubmitting(false)

            if (res && res.message === 'Success') {
                setExperimentFormData({
                    midasContainer: null,
                    requestedCompletionDate: null,
                    jadeNumber: '',
                    comments: '',
                    keywords: '',
                    mnemonic: '',
                    experimentDescription: '',
                    isRMCRequest: false,
                    initialSampleAmount: 0, 
                    initialSampleAmountUoM: null
                })
                setRequesterEmail(null)
                setFeedMidasContainer({})
                setSample(null)
                setExperimentPriority('')
                setDistillationType('')
                setVacuumTemplate(null)
                setVacuumTemplateCuts([])
                setVacuumMethods([])
                setAtmosphericTemplate(null)
                setAtmosphericTemplateCuts([])
                setAtmosphericMethods([])
                setExtractionTemplate(null)
                setExtractionTemplateCuts([])
                setExtractionMethods([])
                openModalMessages('Experiment Saved', `Your experiment has been successfullly saved.`);
            } else {
                if (res.message)
                {
                    openModalMessages('Experiment Failed to Save', `${res ? res.message : ''}. Contact support if you feel this is an error.`);
                } else {
                    openModalMessages('Experiment Failed to Save', `Unspecified Error, Contact support if you feel this is an error.`);
                } 
            }
        })
        // const response = await DistillationExperiment.createExperiment(payload)

        // if (response) {
        //         setExperimentFormData({
        //         midasContainer: null,
        //         requestedCompletionDate: null,
        //         jadeNumber: '',
        //         comments: '',
        //         keywords: '',
        //         mnemonic: '',
        //         experimentDescription: '',
        //         isRMCRequest: false,
        //         initialSampleAmount: 0, 
        //         initialSampleAmountUoM: null
        //     })
        //     setRequesterEmail(null)
        //     setFeedMidasContainer({})
        //     setSample(null)
        //     setExperimentPriority('')
        //     setDistillationType('')
        //     setVacuumTemplate(null)
        //     setVacuumTemplateCuts([])
        //     setVacuumMethods([])
        //     setAtmosphericTemplate(null)
        //     setAtmosphericTemplateCuts([])
        //     setAtmosphericMethods([])
        //     setExtractionTemplate(null)
        //     setExtractionTemplateCuts([])
        //     setExtractionMethods([])
        //     setIsModalOpen(true)
        //     setModalContent({
        //         title: 'Success!',
        //         message: 'Your experiment has successfully been created.'
        //     })
        // } else {
        //     setIsModalOpen(true)
        //     setModalContent({
        //         title: 'Error!',
        //         message: 'There was an error when creating this experiment. Please try again later.'
        //     })
        // }
        // setIsSubmitting(false)
    }

    function getExperimentById (experimentId)
    {
        setIsLoadingExperiment(true)
        DistillationExperiment.getExperimentById(experimentId).then((res) => {
            if (res && res.message === 'Success') {
                var fetchedExperiment = res.result

                const formattedExperiment = JSON.parse(JSON.stringify(fetchedExperiment))
                const experimentAtmosphericCuts = convertExperimentCutToAtmosphericTemplateCut(formattedExperiment?.experimentCuts)
                const experimentVacuumCuts = convertExperimentCutToVacuumTemplateCut(formattedExperiment?.experimentCuts)
                const experimentExtractionsCuts = convertExperimentCutToExtractionTemplateCut(formattedExperiment?.experimentCuts)

                if (formattedExperiment?.initialContainerID) {

                    //setSample(formattedExperiment?.initialContainer?.sample)
                    //setFeedMidasContainer(formattedExperiment?.initialContainer)

                    Container.getContainerById(formattedExperiment?.initialContainerID).then((res) =>  {
                        setFeedMidasContainer(res)

                        Sample.get(res.sampleName).then((result) =>  {
                            setSample(result.result)
                        });
                    });
                }

                setFoundExperiment(formattedExperiment)
                setExperimentFormData({
                    midasContainer: foundExperiment?.initialContainerID,
                    requestedCompletionDate: formattedExperiment?.requestedCompletionDate,
                    jadeNumber: formattedExperiment?.chargeCode,
                    comments: formattedExperiment?.comments,
                    keywords: formattedExperiment?.keywords,
                    mnemonic: formattedExperiment?.mnemonic,
                    experimentDescription: formattedExperiment?.experimentDescription,
                    isRMCRequest: formattedExperiment?.isRMCRequest,
                    initialSampleAmount: formattedExperiment?.initialSampleAmount,
                    initialSampleAmountUoM: formattedExperiment?.initialSampleAmountUoM

                })
                setRequesterEmail(formattedExperiment?.requestedByEmail)
                setExperimentPriority(formattedExperiment?.distillationPriorityName)
                setDistillationType(formattedExperiment?.distillationTypeName)
                setAtmosphericTemplateCuts(experimentAtmosphericCuts)
                setVacuumTemplateCuts(experimentVacuumCuts)
                setExtractionTemplateCuts(experimentExtractionsCuts)
                handleAccordionsEnablement(formattedExperiment?.distillationTypeName)
            } else {
                if (res.message)
                {
                    openModalMessages('Error Getting Experiment', `${res ? res.message : ''}. Contact support if you feel this is an error.`);
                } else {
                    openModalMessages('Error Getting Experiment', `Unspecified Error, Contact support if you feel this is an error.`);
                } 
            }

            setIsLoadingExperiment(false)
        })


        // const apiReturn = await DistillationExperiment.getExperimentById(experimentId)
        // var fetchedExperiment = null

        // if (apiReturn && apiReturn.message === "Success") {
        //     fetchedExperiment = apiReturn.result
        // }

        // if (fetchedExperiment) {
        //     const formattedExperiment = JSON.parse(JSON.stringify(fetchedExperiment))
        //     const experimentAtmosphericCuts = convertExperimentCutToAtmosphericTemplateCut(formattedExperiment?.experimentCuts)
        //     const experimentVacuumCuts = convertExperimentCutToVacuumTemplateCut(formattedExperiment?.experimentCuts)
        //     const experimentExtractionsCuts = convertExperimentCutToExtractionTemplateCut(formattedExperiment?.experimentCuts)

        //     if (formattedExperiment?.initialContainerID) {
        //         const experimentContainer = await Container.getContainerById(formattedExperiment?.initialContainerID);
        //         const sampleResult = await Sample.get(experimentContainer?.sampleName);

        //         setSample(sampleResult.result)
        //         setFeedMidasContainer(experimentContainer)
        //     }

        //     setFoundExperiment(formattedExperiment)
        //     setExperimentFormData({
        //         midasContainer: foundExperiment?.initialContainerID,
        //         requestedCompletionDate: formattedExperiment?.requestedCompletionDate,
        //         jadeNumber: formattedExperiment?.chargeCode,
        //         comments: formattedExperiment?.comments,
        //         keywords: formattedExperiment?.keywords,
        //         mnemonic: formattedExperiment?.mnemonic,
        //         experimentDescription: formattedExperiment?.experimentDescription,
        //         isRMCRequest: formattedExperiment?.isRMCRequest,
        //         initialSampleAmount: formattedExperiment?.initialSampleAmount,
        //         initialSampleAmountUoM: formattedExperiment?.initialSampleAmountUoM

        //     })
        //     setRequesterEmail(formattedExperiment?.requestedByEmail)
        //     setExperimentPriority(formattedExperiment?.distillationPriorityName)
        //     setDistillationType(formattedExperiment?.distillationTypeName)
        //     setAtmosphericTemplateCuts(experimentAtmosphericCuts)
        //     setVacuumTemplateCuts(experimentVacuumCuts)
        //     setExtractionTemplateCuts(experimentExtractionsCuts)
        //     handleAccordionsEnablement(formattedExperiment?.distillationTypeName)

        // } else {
        //     setIsModalOpen(true)
        //     setModalContent({
        //         title: 'Error!',
        //         message: 'There was an error when fetching the data for this experiment. Please try again later.'
        //     })
        // }
        // setIsLoadingExperiment(false)
    }

    useEffect(() => {
        if (!isAtmosphericAccordionAllowed) {
            setAtmosphericMethods([])
            setAtmosphericTemplateCuts([])
            setSelectedTemplateCut({})
            setAtmosphericTemplate(null)
        }
    }, [isAtmosphericAccordionAllowed])

    useEffect(() => {
        if (!isVacuumAccordionAllowed) {
            setVacuumMethods([])
            setVacuumTemplateCuts([])
            setSelectedTemplateCut({})
            setVacuumTemplate(null)
        }
    }, [isVacuumAccordionAllowed])

    useEffect(() => {
        if (!isExtractionAccordionAllowed) {
            setExtractionMethods([])
            setExtractionTemplateCuts([])
            setSelectedTemplateCut({})
            setExtractionTemplate(null)
        }
    }, [isExtractionAccordionAllowed])

    useEffect(() => {
        if (location?.state?.incomingExperimentID) {
            getExperimentById(location.state.incomingExperimentID)
        } else {
            setFoundExperiment(null)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location])

    useEffect(() => {
        let cancelPromise = false

        DistillationExperimentPriority.getAllDistillationExperimentPriorities().then((res) => {
            if (cancelPromise) return
            setExperimentPriorities(res.filter(result => result.isActive === true).sort((a, b) => a.distillationPriorityName.localeCompare(b.distillationPriorityName)))
        });

        DistillationType.getAllDistillationTypes().then((res) => {
            if (cancelPromise) return
            setDistillationTypes(res.filter(result => result.isActive === true).sort((a, b) => a.distillationTypeName.localeCompare(b.distillationTypeName)))
        });

        DistillationExperimentTemplate.searchTemplates(currentUser.username, true, false).then((res) => {
            if (cancelPromise) return
            setExperimentTemplates(res)
        });

        UnitOfMeasure.getAll().then((res) => {
            if (cancelPromise) return
            setAvailableUOMs(res.filter(result => (result.metricStandardConversion !== null && (result.type === 'weight' || result.type === 'volume')) && result.isActive === true).sort((a, b) => a.uoMName.localeCompare(b.uoMName)))
        });
                
        return () => {
            cancelPromise = true
          }
    }, [currentUser.username])

    return (
        <>
            <span className='pageheader'>{!!foundExperiment ? `Experiment ${foundExperiment?.id}` : 'New Distillation Experiment'}</span>
            <Divider className='dividerbar' />

            {isLoadingExperiment ? (
                <Box display="flex" alignItems="center" justifyContent="center" fullWidth height="600px">
                    <Typography style={{ fontSize: 22, fontWeight: 'bold' }}>
                        Loading Experiment {location?.state?.incomingExperimentID}...
                        <CircularProgress size={22} style={{ marginLeft: 10 }} />
                    </Typography>
                </Box>
            ) : (
                <>
                    <form style={{ marginTop: 48 }}>
                        <Box display="flex" gap={2}>
                            <TextField
                                style={{ width: "8%" }}
                                label="Experiment ID"
                                size="small"
                                variant="outlined"
                                margin="normal"
                                name="experimentId"
                                InputLabelProps={{ shrink: true }}
                                disabled
                                value={foundExperiment?.id}
                            />

                            <TextField
                                style={{ width: "59%", marginRight: "15px" }}
                                label="Experiment Description *"
                                size="small"
                                variant="outlined"
                                margin="normal"
                                name="experimentDescription"
                                InputLabelProps={{ shrink: true }}
                                disabled={!!foundExperiment && !canUserChangeExperiment}
                                inputProps={{ maxLength: 50 }}
                                onChange={e => setExperimentFormData((prevState) => ({ ...prevState, experimentDescription: e.target.value }))}
                                value={experimentFormData.experimentDescription}
                            />
                        </Box>
                        <Box display="flex" gap={2}>
                        {/* {feedMidasContainer === null || errorChecks.feedMidasContainer ? ( 
                            <StyledShortTextField size="small" margin="normal" variant="outlined" label="MIDAS # / Container #" InputLabelProps={{shrink: true}}
                                onKeyUp={handleKeyPressParentSample}
                                onBlur = {ValidateParentSampleEntry}
                                onChange={e => setParentMidasNumberTextFieldVal(e.target.value)}
                                value={parentMidasNumberTextFieldVal}
                                placeholder="Midas # / Container #"
                                error = {errorChecks.parentMidasNumber}
                                helperText = {errorChecks.parentMidasNumber ? "Invalid parent/container" : ""}
                                disabled={HasMetaDataLock()}
                                >
                            </StyledShortTextField>

                            ):(
                            <ValidatedTextField size="small" margin="normal" variant="outlined" label="Validated MIDAS # / Container #" InputLabelProps={{shrink: true}}
                                onKeyUp={handleKeyPressFeedMidasContainer}
                                error = {errorChecks.feedMidasContainer}
                                helperText = {errorChecks.feedMidasContainer ? "Invalid parent/container" : ""}
                                value={feedMidasContainer?.sampleName ? feedMidasContainer?.sampleName + '/' + feedMidasContainer?.containerNumber : ''}
                                disabled={!!foundExperiment && !canUserChangeExperiment}
                            /> 
                        )} */}
                            {feedMidasContainer ? (
                                <ValidatedTextField
                                    size="small"
                                    margin="normal"
                                    variant="outlined"
                                    label="MIDAS # / Container # *"
                                    InputLabelProps={{ shrink: true }}
                                    disabled={!!foundExperiment && !canUserChangeExperiment}
                                    onKeyUp={handleKeyPressFeedMidasContainer}
                                    error={errorChecks.feedMidasContainer}
                                    helperText={errorChecks.feedMidasContainer ? "Invalid MIDAS Container" : ""}
                                    value={feedMidasContainer?.sampleName ? feedMidasContainer?.sampleName + '/' + feedMidasContainer?.containerNumber : ''}
                                />
                            ) : (
                                <TextField
                                    size="small"
                                    margin="normal"
                                    variant="outlined"
                                    label="MIDAS # / Container # *"
                                    placeholder="Midas # / Container #"
                                    name="midasContainer"
                                    inputProps={{ maxLength: 50 }}
                                    disabled={!!foundExperiment && !canUserChangeExperiment}
                                    InputLabelProps={{ shrink: true }}
                                    error={errorChecks.feedMidasContainer}
                                    onKeyUp={handleKeyPressFeedMidasContainer}
                                    onBlur={handleFeedMidasContainer}
                                    onChange={e => setExperimentFormData((prevState) => ({ ...prevState, midasContainer: e.target.value }))}
                                    value={experimentFormData.midasContainer}
                                    helperText={errorChecks.feedMidasContainer ? "Invalid MIDAS Container" : ""}
                                />
                            )}

                            <TextField
                                style={{ width: '40%' }}
                                size="small"
                                margin="normal"
                                variant="outlined"
                                label="Sample Description"
                                name="sampleDescription"
                                InputLabelProps={{ shrink: true }}
                                InputProps={{ readOnly: true }}
                                value={sample?.description ? sample.description : ''}
                                disabled={true}
                            />

                            <TextField
                                style={{ width: '40%' }}
                                size="small"
                                margin="normal"
                                variant="outlined"
                                label="Container Status"
                                name="containerStatus"
                                InputLabelProps={{ shrink: true }}
                                InputProps={{ readOnly: true }}
                                value={feedMidasContainer?.containerStatusName ? feedMidasContainer.containerStatusName : ''}
                                error={feedMidasContainer?.containerNumber && feedMidasContainer?.containerStatusName !== 'Confirmed'}
                                helperText={feedMidasContainer?.containerNumber && feedMidasContainer?.containerStatusName !== 'Confirmed' ? "Container must be Confirmed" : ""}
                                disabled={true}
                            />
                        </Box>

                        <Box display="flex" gap={2}>
                        <TextField
                                style={{ width: '10%' }}
                                size="small"
                                margin="normal"
                                variant="outlined"
                                label="Current Amount"
                                name="currentAmount"
                                InputLabelProps={{ shrink: true }}
                                InputProps={{ readOnly: true }}
                                value={feedMidasContainer?.currentAmount ? feedMidasContainer?.currentAmount?.toLocaleString() : ''}
                                disabled={true}
                            />

                            <TextField
                                style={{ width: '10%' }}
                                size="small"
                                margin="normal"
                                variant="outlined"
                                label="UoM"
                                name="uom"
                                InputLabelProps={{ shrink: true }}
                                InputProps={{ readOnly: true }}
                                value={feedMidasContainer?.containerSizeUoM ? feedMidasContainer.containerSizeUoM : ''}
                                disabled={true}
                            />

                            <TextField
                                style={{ width: '10%' }}
                                label="Desired Charge Amount *"
                                InputProps={{ inputProps: { min: 0 } }}
                                size="small"
                                variant="outlined"
                                margin="normal"
                                name="desiredChargeAmount"
                                InputLabelProps={{ shrink: true }}
                                disabled={!!foundExperiment && !canUserChangeExperiment}
                                onInput={e => {e.target.value =!!e.target.value && Math.abs(e.target.value) >= 0 ? Math.abs(e.target.value) : null}}
                                onChange={e => setExperimentFormData((prevState) => ({ ...prevState, initialSampleAmount: e.target.value }))}
                                value={experimentFormData.initialSampleAmount}
                                error={Number(experimentFormData?.initialSampleAmount) > ConvertUOMs(null, null, feedMidasContainer?.currentAmount, availableUOMs.find(x => x.uoMName === feedMidasContainer?.containerSizeUoM ? feedMidasContainer?.containerSizeUoM : null), 
                                    experimentFormData.initialSampleAmountUoM ? availableUOMs.find(x => x.uoMName === experimentFormData.initialSampleAmountUoM) : null)}
                                helperText={Number(experimentFormData?.initialSampleAmount) > ConvertUOMs(null, null, feedMidasContainer?.currentAmount, availableUOMs.find(x => x.uoMName === feedMidasContainer?.containerSizeUoM ? feedMidasContainer?.containerSizeUoM : null), 
                                    experimentFormData.initialSampleAmountUoM ? availableUOMs.find(x => x.uoMName === experimentFormData.initialSampleAmountUoM) : null) ? "Amount Unavailable" : ""}
                            />
                            <StyledAutocomplete
                                disablePortal
                                noOptionsText={"Loading UoMs..."}
                                options={availableUOMs.map((option) => option?.uoMName)}
                                onChange={(e, value) => setExperimentFormData((prevState) => ({ ...prevState, initialSampleAmountUoM: value }))}
                                autoHighlight
                                autoSelect
                                disabled={!!foundExperiment && !canUserChangeExperiment}
                                value={experimentFormData.initialSampleAmountUoM}
                                style={{ width: '10%' }}
                                isOptionEqualToValue={(option, value) => value === option}
                                renderInput={(params) => <TextField {...params} variant="outlined" size="small" margin="normal" label="Charge UoM *"
                                    InputLabelProps={{ shrink: true }}
                                    InputProps={{ ...params.InputProps }} />}
                            />
                        </Box>

                        <Box display="flex" marginTop={1} marginBottom={1} alignItems="center">
                            <EmailAddressTextField
                                fontSize={16}
                                fieldWidth={"23%"}
                                validatedUserEmail={requesterEmail}
                                setValidatedUserEmail={setRequesterEmail}
                                hasErrors={requesterInvalid}
                                setHasErrors={(value) => setRequesterInvalid(value)}
                                isDisabled={!!foundExperiment && !canUserChangeExperiment}
                                labelText="Requester Email *"
                                placeholderText="Email"
                                showPlusMeButton={true}>
                            </EmailAddressTextField>
                        </Box>

                        <Box display="flex" gap={2} alignItems="center">
                            <StyledShortTextField
                                label="Mnemonic"
                                size="small"
                                variant="outlined"
                                margin="normal"
                                name="mnemonic"
                                InputLabelProps={{ shrink: true }}
                                disabled={!!foundExperiment && !canUserChangeExperiment}
                                inputProps={{ maxLength: 20 }}
                                onChange={e => setExperimentFormData((prevState) => ({ ...prevState, mnemonic: e.target.value }))}
                                value={experimentFormData.mnemonic}
                            />

                            <FormControlLabel
                                label="RMC Request?"
                                disabled={!!foundExperiment && !canUserChangeExperiment}
                                control={
                                    <Checkbox
                                        name="isRMCRequested"
                                        onChange={e => setExperimentFormData((prevState) => ({ ...prevState, isRMCRequest: e.target.checked }))}
                                        checked={experimentFormData.isRMCRequest}
                                    />
                                }
                            />
                        </Box>

                        <Box display="flex" gap={2} alignItems="center">
                            <BillingInfoField
                                billingValue={experimentFormData.jadeNumber}
                                handleChange={e => {
                                    setExperimentFormData((prevState) => ({ ...prevState, jadeNumber: e }));
                                }}
                                isDisabled={!!foundExperiment && !canUserChangeExperiment}
                                margin="normal"
                                marginLeft="0px"
                                marginRight="0px"
                                fieldWidth="15%"
                                hasErrors={experimentFormData.jadeNumber === null ? false : !experimentFormData.jadeNumber}
                                helperText="JADE is Required"
                                required
                            >
                            </BillingInfoField>

                            <StyledAutocomplete
                                disablePortal
                                noOptionsText={"Loading Priorities..."}
                                options={experimentPriorities.map((option) => option?.distillationPriorityName)}
                                onChange={(_, value) => {
                                    if (value) {
                                        setExperimentPriority(value)
                                    } else {
                                        setExperimentPriority('')
                                    }
                                }}
                                autoHighlight
                                autoSelect
                                disabled={!!foundExperiment && !canUserChangeExperiment}
                                value={experimentPriority}
                                style={{ width: '10%' }}
                                isOptionEqualToValue={(option, value) => value === option}
                                renderInput={(params) => <TextField {...params} variant="outlined" size="small" margin="normal" label="Priority"
                                    InputLabelProps={{ shrink: true }}
                                    InputProps={{ ...params.InputProps }} />}
                            />

                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DesktopDatePicker
                                    label="Req. Completion Date *"
                                    style={{ width: '15%' }}
                                    value={experimentFormData.requestedCompletionDate}
                                    disabled={!!foundExperiment && !canUserChangeExperiment}
                                    minDate={new Date()}
                                    onChange={e => setExperimentFormData((prevState) => ({ ...prevState, requestedCompletionDate: e }))}
                                    renderInput={(params) => <TextField onKeyDown={DatePickerKeyDownEvent} size="small" margin="normal" variant="outlined" {...params} />}
                                />
                            </LocalizationProvider>
                        </Box>
                        <Box display="flex">
                            <StyledTextarea
                                label={experimentPriority === 'Rush' ? 'Comments *' : 'Comments'}
                                multiline
                                size="small"
                                variant="outlined"
                                margin="normal"
                                name="comments"
                                InputLabelProps={{ shrink: true }}
                                disabled={!!foundExperiment && !canUserChangeExperiment}
                                onChange={e => setExperimentFormData((prevState) => ({ ...prevState, comments: e.target.value }))}
                                value={experimentFormData.comments}
                                error={experimentPriority === 'Rush' && experimentFormData?.comments?.trim().length === 0}
                                helperText={experimentPriority === 'Rush' && experimentFormData?.comments?.trim().length === 0 ? 'Required if Priority is Rush' : ''}
                            />
                        </Box>
                        <Box display="flex">
                            <StyledMediumTextField
                                label="Add Keywords"
                                size="small"
                                variant="outlined"
                                margin="normal"
                                name="keywords"
                                InputLabelProps={{ shrink: true }}
                                disabled={!!foundExperiment && !canUserChangeExperiment}
                                onChange={e => setExperimentFormData((prevState) => ({ ...prevState, keywords: e.target.value }))}
                                value={experimentFormData.keywords}
                            />
                        </Box>
                        {formatCreatedAndModifiedDateInfo(foundExperiment?.createdDate ?? 'N/A',
                            foundExperiment?.createdByEmail === null || foundExperiment?.createdByEmail?.trim() === '' ? 'N/A' : foundExperiment?.createdByEmail,
                            foundExperiment?.lastModifiedDate ?? 'N/A',
                            foundExperiment?.lastModifiedByEmail === null || foundExperiment?.lastModifiedByEmail?.trim() === '' ? 'N/A' : foundExperiment?.lastModifiedByEmail)}
                    </form>

                    <Box display="flex" flexDirection="column" gap={3} marginTop={6} marginBottom={6}>
                        <Box display="flex" gap={2} alignItems="flex-start">
                            <Checkbox
                                checked={isPreAnalysisAllowed}
                                disabled={!!foundExperiment && !canUserChangeExperiment}
                                onChange={e => {
                                    setIsPreAnalysisAllowed(e.target.checked)
                                    if (!e.target.checked) {
                                        setDistillationType('')
                                    }
                                }}
                            />

                            <Autocomplete
                                noOptionsText={"No Distillation Types found"}
                                value={distillationType}
                                onChange={(_, value) => {
                                    if (value) {
                                        setDistillationType(value)
                                        handleAccordionsEnablement(value)
                                    }
                                }}
                                disablePortal
                                isOptionEqualToValue={(option, value) => value === option}
                                options={distillationTypes.map(option => option?.distillationTypeName)}
                                renderInput={(params) => <TextField {...params} label="Experiment Type *" size="small" />}
                                autoSelect
                                style={{ width: '16.2%' }}
                                disabled={!isPreAnalysisAllowed || (!!foundExperiment && !canUserChangeExperiment)}
                            />
                        </Box>
                        <Box display="flex" flexDirection="column" gap={3}>
                            <Box display="flex" alignItems="flex-start" gap={2}>
                                <Checkbox
                                    checked={isAtmosphericAccordionAllowed}
                                    onChange={e => setIsAtmosphericAccordionAllowed(e.target.checked)}
                                    disabled={(!!foundExperiment && !canUserChangeExperiment) || distillationType === 'Extractions'}
                                />

                                <Autocomplete
                                    noOptionsText={"No Atmospheric Templates found"}
                                    value={atmosphericTemplate}
                                    onChange={(_, value) => {
                                        if (value) {
                                            setAtmosphericTemplate(value)
                                            setAtmosphericTemplateCuts(value?.templateCuts)
                                            setIsAtmosphericAccordionExpanded(true)
                                        } else {
                                            setAtmosphericTemplate(null)
                                            setAtmosphericMethods([])
                                        }
                                    }}
                                    disabled={(!!foundExperiment && !canUserChangeExperiment) || !isAtmosphericAccordionAllowed}
                                    isOptionEqualToValue={(option, value) => value?.distillationTemplateName === option?.distillationTemplateName}
                                    disablePortal
                                    options={atmosphericExperimentTemplates}
                                    getOptionLabel={(option) => option?.distillationTemplateName}
                                    renderInput={(params) => <TextField {...params} label="Atmospheric Experiment Templates" size="small" />}
                                    autoSelect
                                    style={{ width: '20%' }}
                                />

                                <Accordion
                                    style={{ width: "100%", marginTop: 0 }}
                                    disabled={!isAtmosphericAccordionAllowed}
                                    expanded={isAtmosphericAccordionExpanded}
                                    onChange={() => setIsAtmosphericAccordionExpanded(!isAtmosphericAccordionExpanded)}
                                >
                                    <StyledAccordionSummary
                                        expandIcon={<ExpandMoreIcon style={{ color: 'white' }} />}
                                        aria-controls="panel1-content"
                                    >
                                        <Typography
                                            style={{
                                                color: "white",
                                                fontWeight: '600',
                                                fontSize: "16px",
                                                fontFamily: "EMprint"
                                            }}
                                        >
                                            Atmospheric Cuts
                                        </Typography>
                                    </StyledAccordionSummary>
                                    {isAtmosphericAccordionAllowed && (
                                        <StyledScrollingAccordionDetails>
                                            <TemplateTables
                                                showMethodSearchTemplateName={false}
                                                isReadOnly={!!foundExperiment && !canUserChangeExperiment}
                                                templateName={atmosphericTemplate?.distillationTemplateName}
                                                methods={atmosphericMethods}
                                                setMethods={setAtmosphericMethods}
                                                templateCuts={atmosphericTemplateCuts}
                                                setTemplateCuts={setAtmosphericTemplateCuts}
                                                selectedTemplateCut={selectedTemplateCut}
                                                setSelectedTemplateCut={setSelectedTemplateCut}
                                                selectedChemID={selectedChemID}
                                                setSelectedChemID={setSelectedChemID}
                                                distillationType='Atmospheric'
                                            />
                                        </StyledScrollingAccordionDetails>
                                    )}
                                </Accordion>
                            </Box>
                            <Box display="flex" alignItems="flex-start" gap={2}>
                                <Checkbox
                                    checked={isVacuumAccordionAllowed}
                                    onChange={e => setIsVacuumAccordionAllowed(e.target.checked)}
                                    disabled={(!!foundExperiment && !canUserChangeExperiment) || distillationType === 'Extractions'}
                                />

                                <Autocomplete
                                    noOptionsText={"No Vacuum Templates found"}
                                    value={vacuumTemplate}
                                    onChange={(_, value) => {
                                        if (value) {
                                            setVacuumTemplate(value)
                                            setVacuumTemplateCuts(value?.templateCuts)
                                            setIsVacuumAccordionExpanded(true)
                                        } else {
                                            setVacuumTemplate(null)
                                            setVacuumMethods([])
                                        }
                                    }}
                                    disabled={(!!foundExperiment && !canUserChangeExperiment) || !isVacuumAccordionAllowed}
                                    disablePortal
                                    options={vacuumExperimentTemplates}
                                    isOptionEqualToValue={(option, value) => value?.distillationTemplateName === option?.distillationTemplateName}
                                    getOptionLabel={(option) => option?.distillationTemplateName}
                                    renderInput={(params) => <TextField {...params} label="Vacuum Experiment Templates" size="small" />}
                                    autoSelect
                                    style={{ width: '20%' }}
                                />

                                <Accordion
                                    style={{ width: "100%", marginTop: 0 }}
                                    disabled={!isVacuumAccordionAllowed}
                                    expanded={isVacuumAccordionExpanded}
                                    onChange={() => setIsVacuumAccordionExpanded(!isVacuumAccordionExpanded)}
                                >
                                    <StyledAccordionSummary
                                        expandIcon={<ExpandMoreIcon style={{ color: 'white' }} />}
                                        aria-controls="panel2-content"
                                    >
                                        <Typography
                                            style={{
                                                color: "white",
                                                fontWeight: '600',
                                                fontSize: "16px",
                                                fontFamily: "EMprint"
                                            }}
                                        >
                                            Vacuum Cuts
                                        </Typography>
                                    </StyledAccordionSummary>
                                    {isVacuumAccordionAllowed && (
                                        <StyledScrollingAccordionDetails>
                                            <TemplateTables
                                                showMethodSearchTemplateName={false}
                                                isReadOnly={!!foundExperiment && !canUserChangeExperiment}
                                                templateName={vacuumTemplate?.distillationTemplateName}
                                                methods={vacuumMethods}
                                                setMethods={setVacuumMethods}
                                                templateCuts={vacuumTemplateCuts}
                                                setTemplateCuts={setVacuumTemplateCuts}
                                                selectedTemplateCut={selectedTemplateCut}
                                                setSelectedTemplateCut={setSelectedTemplateCut}
                                                selectedChemID={selectedChemID}
                                                setSelectedChemID={setSelectedChemID}
                                                distillationType='Vacuum'
                                            />
                                        </StyledScrollingAccordionDetails>
                                    )}
                                </Accordion>
                            </Box>
                            <Box display="flex" gap={2} alignItems="flex-start">
                                <Checkbox
                                    checked={isExtractionAccordionAllowed}
                                    onChange={e => setIsExtractionAccordionAllowed(e.target.checked)}
                                    disabled={(!!foundExperiment && !canUserChangeExperiment) || (distillationType !== 'Extractions' && distillationType !== '')}
                                />

                                <Autocomplete
                                    noOptionsText={"No Extraction Templates found"}
                                    value={extractionTemplate}
                                    onChange={(_, value) => {
                                        if (value) {
                                            setExtractionTemplate(value)
                                            setExtractionTemplateCuts(value?.templateCuts)
                                            setIsExtractionAccordionExpanded(true)
                                        } else {
                                            setExtractionTemplate(null)
                                            setExtractionMethods([])
                                        }
                                    }}
                                    disabled={(!!foundExperiment && !canUserChangeExperiment) || !isExtractionAccordionAllowed}
                                    disablePortal
                                    options={extractionExperimentTemplates}
                                    isOptionEqualToValue={(option, value) => value?.distillationTemplateName === option?.distillationTemplateName}
                                    getOptionLabel={(option) => option?.distillationTemplateName}
                                    renderInput={(params) => <TextField {...params} label="Extraction Experiment Templates" size="small" />}
                                    autoSelect
                                    style={{ width: '20%' }}
                                />

                                <Accordion
                                    style={{ width: "100%", marginTop: 0 }}
                                    disabled={!isExtractionAccordionAllowed}
                                    expanded={isExtractionAccordionExpanded}
                                    onChange={() => setIsExtractionAccordionExpanded(!isExtractionAccordionExpanded)}
                                >
                                    <StyledAccordionSummary
                                        expandIcon={<ExpandMoreIcon style={{ color: 'white' }} />}
                                        aria-controls="panel3-content"
                                    >
                                        <Typography
                                            style={{
                                                color: "white",
                                                fontWeight: '600',
                                                fontSize: "16px",
                                                fontFamily: "EMprint"
                                            }}
                                        >
                                            Extractions
                                        </Typography>
                                    </StyledAccordionSummary>
                                    {isExtractionAccordionAllowed && (
                                        <StyledScrollingAccordionDetails>
                                            <TemplateTables
                                                showMethodSearchTemplateName={false}
                                                isReadOnly={!!foundExperiment && !canUserChangeExperiment}
                                                templateName={extractionTemplate?.distillationTemplateName}
                                                methods={extractionMethods}
                                                setMethods={setExtractionMethods}
                                                templateCuts={extractionTemplateCuts}
                                                setTemplateCuts={setExtractionTemplateCuts}
                                                selectedTemplateCut={selectedTemplateCut}
                                                setSelectedTemplateCut={setSelectedTemplateCut}
                                                selectedChemID={selectedChemID}
                                                setSelectedChemID={setSelectedChemID}
                                                distillationType='Extractions'
                                            />
                                        </StyledScrollingAccordionDetails>
                                    )}
                                </Accordion>
                            </Box>
                        </Box>
                    </Box>
                    <Box paddingBottom={6}>
                        <GlobalButton
                            variant="contained"
                            onClick={() => {
                                if (!!foundExperiment && canUserChangeExperiment) {
                                    handleUpdateExperiment()
                                } else {
                                    handleSubmitExperiment()
                                }
                            }}
                            disabled={isSubmitting || (!!foundExperiment && !canUserChangeExperiment)}// || !requiredFormInformationIsFilled()}                        
                        >
                            {!!foundExperiment ? 'Update Experiment' : 'Submit Experiment'}
                        </GlobalButton>
                    </Box>
                </>
            )}
            {/* {isModalOpen && (
                <ModalSimpleButton
                    title={modalContent.title}
                    buttonText="Close"
                    buttonAction={() => {
                        setIsModalOpen(false)

                        if (modalContent?.message?.includes('created')) {
                            history.push('/distillations')
                        }
                    }}
                    setOpen={setIsModalOpen}
                    open={isModalOpen}
                >
                    <Typography style={{ padding: '0 16px', fontSize: 18, textAlign: 'center' }}>
                        {modalContent.message}
                    </Typography>
                </ModalSimpleButton>
            )} */}

             {/* Informational Messages */}
            <ModalSimpleButton title={modalMessagesTitle} buttonText={modalMessagesButtonText} buttonAction={foundExperiment ? closeModalMessages : closeModalMessagesWithReroute} open={modalMessagesOpen} setOpen={setModalMessagesOpen}>
                {Array.isArray(modalMessagesText) ?
                (modalMessagesText && modalMessagesText.map((text, index) => {
                return (
                    <div style={{display:"flex"}} key={`myErrorChecks${index}`}>
                            <label>
                            {text}
                            </label>
                    </div>
                )
                }))
                :
                ( <label>
                {modalMessagesText}
                </label>
                )}      
            </ModalSimpleButton>
        </>
    );
};

export default NewDistillationExperiment;