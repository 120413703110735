import { checkAPIResponse } from "../Helpers";
import API from "../index";

export default class STIntervalResultOption {
  constructor({ optionName, isActive }) {
    this.optionName = optionName;
    this.isActive = isActive;
  }

  static async getAll() {
    const api = await API();
    const response = await api.get("/stintervalresultoption");
    checkAPIResponse(response);
    return response.data.result.map(
      (intervalResultOption) => new STIntervalResultOption(intervalResultOption),
    );
  }

  static async get(name) {
    const api = await API();
    const { data } = await api.get(`stintervalresultoption/${name}`);
    if (data.result) {
      return new STIntervalResultOption(data.result);
    } else {
      return null;
    }
  }

  static async add(payload) {
    const api = await API();
    const { data } = await api.post("/stintervalresultoption", payload);
    return new STIntervalResultOption(data.result);
  }

  static async update(payload) {
    const api = await API();
    const { data } = await api.put("/stintervalresultoption", payload);
    return new STIntervalResultOption(data.result);
  }
}
