import API from "../index";

export default class stockroomContractWorker {
  constructor({
    badgeID,
    firstName,
    lastName,
    contractCompany,
    contactEmailAdress,
  }) {
    this.BadgeID = badgeID;
    this.FirstName = firstName;
    this.LastName = lastName;
    this.ContractCompany = contractCompany;
    this.ContactEmailAdress = contactEmailAdress;
  }

  static async getAll() {
    const api = await API();
    const { data } = await api.get("/stockroomcontractworker");
    return data.result.map(
      (contractWorker) => new stockroomContractWorker(contractWorker)
    );
  }

  static async getContractorWorkerById(id) {
    const api = await API();
    const { data } = await api.get(`/stockroomcontractworker/${id}`);
    return data.result.map(
      (contractWorker) => new stockroomContractWorker(contractWorker)
    );
  }

  static async manageContractorWorker(info) {
    const api = await API();
    const { data } = await api.post("/stockroomcontractworker", info);
    return new stockroomContractWorker(data.result);
  }
}
