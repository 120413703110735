import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { convertToLocalTime } from "../../../../global";
import type { GeneralInformation, StationaryTest } from "../../types";
import { REQUEST_INFORMATION_ENUM } from "../../shared";
import type { FormikProps } from "formik";
import BillingInfoField from "../../../../components/BillingInfoField";
import { useDetailedStRequest } from "../../context/DetailedStRequestContext";

type GeneralViewProps = {
  generalInformation: GeneralInformation;
  formik: FormikProps<Partial<StationaryTest>>;
};

export default function GeneralView({ generalInformation, formik }: GeneralViewProps) {
  const { canManageGeneralAndTesting, statusOptions, queueOptions } = useDetailedStRequest();

  return (
    <div>
      <span
        style={{
          textAlign: "left",
          font: "normal normal 600 20px/24px EMprint",
          letterSpacing: 0,
        }}>
        {REQUEST_INFORMATION_ENUM.GENERAL}
      </span>
      <Box display="grid" gridTemplateColumns="1fr 1fr 1fr" gap={2} style={{ marginTop: "1rem" }}>
        <TextField
          value={generalInformation.requestedByEmail ?? ""}
          label="Requester Email"
          InputProps={{ readOnly: true }}
          size="small"
          disabled
        />
        <TextField
          value={generalInformation.cardNumber ?? ""}
          label="Card Tracking Number"
          InputProps={{ readOnly: true }}
          size="small"
          disabled
        />
        <TextField
          value={generalInformation.runNumber ?? ""}
          label="Run Number"
          InputProps={{ readOnly: true }}
          size="small"
          disabled
        />
      </Box>
      <Box display="grid" gridTemplateColumns="1fr 1fr 1fr" gap={2} mt={3} mb={3}>
        <TextField
          value={
            generalInformation.createdDate
              ? convertToLocalTime(generalInformation.createdDate)
              : null
          }
          label="Date Created"
          InputProps={{ readOnly: true }}
          size="small"
          disabled
        />
        <TextField
          value={generalInformation.lastModifiedByEmail ?? ""}
          label="Last Editor"
          InputProps={{ readOnly: true }}
          size="small"
          disabled
        />
        <TextField
          value={
            generalInformation.lastModifiedDate
              ? convertToLocalTime(generalInformation.lastModifiedDate)
              : null
          }
          label="Modified On"
          InputProps={{ readOnly: true }}
          size="small"
          disabled
        />
      </Box>
      <Box display="grid" gridTemplateColumns="1fr 1fr 1fr" gap={2}>
        <Autocomplete
          value={formik.values.workGroupName}
          isOptionEqualToValue={(option, value) => value === option}
          disablePortal
          options={queueOptions}
          renderInput={(params) => <TextField {...params} label="Queue" size="small" />}
          autoSelect
          onChange={(_, v) => {
            formik.setFieldValue("workGroupName", v);
          }}
          onBlur={formik.handleBlur}
          id="workGroupName"
          disabled={!canManageGeneralAndTesting}
        />
        <Autocomplete
          value={formik.values.statusName}
          isOptionEqualToValue={(option, value) => value === option}
          disablePortal
          options={statusOptions}
          renderInput={(params) => <TextField {...params} label="Status" size="small" />}
          autoSelect
          onChange={(_, v) => {
            formik.setFieldValue("statusName", v);
          }}
          onBlur={formik.handleBlur}
          id="statusName"
          disabled={!canManageGeneralAndTesting}
        />
        <BillingInfoField
          billingValue={formik.values.billingReference}
          handleChange={(e: string) => {
            formik.setFieldValue("billingReference", e);
          }}
          hasErrors={formik.errors.billingReference}
          isDisabled={!canManageGeneralAndTesting}
          margin="none"
          fieldWidth="100%"
          helperText={formik.errors.billingReference}
          marginLeft={null}
          marginRight={null}
          required
        />
      </Box>
    </div>
  );
}
