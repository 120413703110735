import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";
import Autocomplete from "@mui/material/Autocomplete";
import FormControlLabel from "@mui/material/FormControlLabel";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { StyledCheckboxGroup } from "../styles";
import ProcedureManagementDataGrid from "./ProcedureManagementDataGrid";
import ProcedureManagementTables from "./ProcedureManagementTables";
import { convertToLocalTime, generateUniqueID } from "../../../global";

import STEngineerAPI from "../../../api/StationaryTesting/STEngineer";
import STIntervalResultOptionAPI from "../../../api/StationaryTesting/STIntervalResultOption";
import STStandAPI from "../../../api/StationaryTesting/STStand";
import STMethodClassificationAPI from "../../../api/StationaryTesting/STMethodClassification";
import STFacilityAPI from "../../../api/StationaryTesting/STFacility";
import UnitOfMeasureAPI from "../../../api/Admin/UnitOfMeasure";
import LocationApi from "../../../api/Admin/Location";
import ContainerTypeApi from "../../../api/LIMS/ContainerType";
import {
  FACILITY_INITIAL_VALUES,
  TEST_INITIAL_STATE,
  TEST_PARAMETER_INITIAL_VALUES,
  TEST_STAND_INITIAL_VALUES,
} from "../shared";
import TestParameterModal from "./TestParameterModal";
import {
  columnsEngineersTable,
  columnsFacilityTable,
  columnsTestParameterTable,
  columnsTestStandTable,
  columnsTestsTable,
} from "../tableColumns";
import TestStandModal from "./TestStandModal";
import FacilityModal from "./FacilityModal";

const ProcedureManagementForm = ({
  procedureData,
  setProcedureData,
  rowsEngineer,
  setRowsEngineer,
  rowsTest,
  setRowsTest,
  doesProcedureExist,
  testParameters,
  procedureNumberIsAlreadyUsed,
  canManageProcedure,
  canManageSchedule,
}) => {
  const [intervals, setIntervals] = useState([]);
  const [rowsFacility, setRowsFacility] = useState([]);
  const [rowsTestParameter, setRowsTestParameter] = useState([]);
  const [rowsTestStand, setRowsTestStand] = useState([]);

  const [engineers, setEngineers] = useState([]);
  const [facilities, setFacilities] = useState([]);
  const [testStands, setTestStands] = useState([]);
  const [uoMList, setUoMList] = useState([]);
  const [locationList, setLocationList] = useState([]);
  const [containerTypes, setContainerTypes] = useState([]);
  const [intervalResultOptions, setIntervalResultOptions] = useState([]);
  const [resultOptionsLoading, setResultOptionsLoading] = useState(false);
  const [procedureTypes, setProcedureTypes] = useState([]);
  const [showAddTestParameterModal, setShowAddTestParameterModal] = useState(false);
  const [testParameter, setTestParameter] = useState(TEST_PARAMETER_INITIAL_VALUES);
  const [showAddTestStandModal, setShowAddTestStandModal] = useState(false);
  const [testStand, setTestStand] = useState(TEST_STAND_INITIAL_VALUES);
  const [showAddFacilityModal, setShowAddFacilityModal] = useState(false);
  const [facility, setFacility] = useState(FACILITY_INITIAL_VALUES);

  const foundSelectedTestRow = rowsTest.find((row) => row?.rowSelected);

  const filteredTestStandNames =
    testStands && testStands.length > 0
      ? testStands
          .filter((ts) => ts.isActive)
          .filter((testStand) => testStand?.standTypeName === procedureData?.classificationName)
          .map((s) => s.standName)
      : [];

  const filteredFacilityNames =
    facilities && facilities.length > 0 ? facilities.map((f) => f.stationaryTestFacilityName) : [];

  async function getAllUoM() {
    const response = await UnitOfMeasureAPI.getAll();
    setUoMList(response);
  }

  async function getAllLocations() {
    const response = await LocationApi.getAll();
    const filteredData = response.filter((location) => location?.isActive);
    const onlyNameData = filteredData.map((location) => location?.locationName);
    setLocationList(onlyNameData);
  }

  async function getAllContainerTypes() {
    const response = await ContainerTypeApi.getAll();
    const filteredData = response.filter((type) => type?.isActive);
    const onlyNameData = filteredData.map((type) => type?.name);
    setContainerTypes(onlyNameData);
  }

  async function getAllEngineers() {
    const response = await STEngineerAPI.getAllRaw();
    setEngineers(response);
  }

  async function getAllIntervalResultOptions() {
    setResultOptionsLoading(true);
    await STIntervalResultOptionAPI.getAll()
      .then((resultOptions) => {
        const resultOptionNames = resultOptions
          .filter((option) => option.isActive)
          .map((option) => option?.optionName);
        setIntervalResultOptions(resultOptionNames);
      })
      .catch((error) => {
        setIntervalResultOptions([]);
      });
    setResultOptionsLoading(false);
  }

  async function getAllTestStands() {
    const response = await STStandAPI.getAll();
    const formattedData = response.map((type) => type?.StandName);
    setTestStands(formattedData);
  }

  async function getAllTypes() {
    const response = await STMethodClassificationAPI.getAllActiveTypes();
    const classificationNames = response.map((type) => type.classificationName);
    setProcedureTypes(classificationNames);
  }

  async function getAllFacilities() {
    const response = await STFacilityAPI.getAllActives();
    setFacilities(response);
  }

  function handleEngineerSelected(engineerInfo) {
    if (Object.keys(engineerInfo).length > 0) {
      setRowsEngineer((prevState) => {
        return [
          ...prevState,
          {
            ...engineerInfo,
            thisProcedure: null,
            thisEngineer: null,
            procedureNumber: procedureData?.procedureNumber,
            rowId: generateUniqueID(),
            isPrimaryEngineer: false,
            receiveNotifications: false,
          },
        ];
      });
    }
  }

  function convertTestDisplayName(
    procedureNumber,
    derivativeNumber,
    isModified,
    modificationNumber,
  ) {
    let modifiedDeritivateNumber = "";
    let modifiedModificationNumber = "";

    if (derivativeNumber && +derivativeNumber > 0) {
      modifiedDeritivateNumber = `_${derivativeNumber.padStart(2, "0")}`;
    }

    if (modificationNumber && +modificationNumber > 0) {
      modifiedModificationNumber = `${modificationNumber.padStart(2, "0")}`;
    }

    return `${procedureNumber}${modifiedDeritivateNumber}${
      isModified ? "MOD" : ""
    }${modifiedModificationNumber}`;
  }

  function handleChangeProcedureNumber(event) {
    setRowsTest((prevState) => {
      const updatedArrayOfTests = prevState?.map((test) => {
        const newDisplayName = convertTestDisplayName(
          event.target.value,
          test?.derivativeNumber,
          test?.isModified,
          test?.modificationNumber,
        );

        return {
          ...test,
          procedureNumber: event.target.value,
          testDisplayName: newDisplayName,
        };
      });

      return updatedArrayOfTests;
    });

    setProcedureData((prevState) => ({
      ...prevState,
      procedureNumber: event.target.value,
    }));
  }

  useEffect(() => {
    setRowsTest((prevState) => {
      const updatedArray = prevState.map((row) => {
        if (row?.rowId === foundSelectedTestRow?.rowId) {
          return {
            ...foundSelectedTestRow,
            testIntervals: intervals,
            availableFacilities: rowsFacility,
            availableTestStands: rowsTestStand,
            availableParameters: rowsTestParameter,
          };
        }
        return row;
      });

      return updatedArray;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowsTestStand, rowsFacility, rowsTestParameter, intervals]);

  useEffect(() => {
    if (foundSelectedTestRow) {
      setRowsTestStand(foundSelectedTestRow?.availableTestStands);
      setRowsTestParameter(foundSelectedTestRow?.availableParameters);
      setRowsFacility(foundSelectedTestRow?.availableFacilities);
    } else {
      setRowsTestStand([]);
      setRowsTestParameter([]);
      setRowsFacility([]);
      setIntervals([]);
    }
  }, [foundSelectedTestRow]);

  useEffect(() => {
    getAllEngineers();
    getAllIntervalResultOptions();
    getAllTestStands();
    getAllTypes();
    getAllFacilities();
    getAllUoM();
    getAllLocations();
    getAllContainerTypes();
  }, []);

  function handleClickOnTest(clickedTest) {
    const auxiliarTestCopy = { ...clickedTest };

    auxiliarTestCopy.rowSelected = !auxiliarTestCopy.rowSelected;

    const index = rowsTest.findIndex((rt) => rt.rowId === auxiliarTestCopy.rowId);

    const auxiliarRows = [...rowsTest].map((rt) => {
      const testCopy = { ...rt };

      if (rt.rowId !== auxiliarTestCopy.rowId) {
        testCopy.rowSelected = false;
      }

      return testCopy;
    });

    auxiliarRows.splice(index, 1, auxiliarTestCopy);

    setRowsTest(auxiliarRows);
  }

  function handleAddTestParameter(newTestParameter) {
    const alreadyAdded =
      rowsTestParameter.length > 0 &&
      rowsTestParameter.find((rtp) => rtp.testParameterName === newTestParameter.testParameterName);

    if (alreadyAdded) {
      const updatedTestParameters = rowsTestParameter.map((rtp) => {
        let testParameterToAdd = { ...rtp };
        const isSameTestParameter = rtp.testParameterName === newTestParameter.testParameterName;

        if (isSameTestParameter) {
          testParameterToAdd = newTestParameter;
        }

        return testParameterToAdd;
      });

      setRowsTestParameter(updatedTestParameters);
    } else {
      setRowsTestParameter([
        ...rowsTestParameter,
        { ...newTestParameter, rowId: generateUniqueID() },
      ]);
    }
    setShowAddTestParameterModal(false);
    setTestParameter(TEST_PARAMETER_INITIAL_VALUES);
  }

  function handleAddTestStand(newTestStand) {
    const alreadyAdded =
      rowsTestStand.length > 0 &&
      rowsTestStand.find((rtp) => rtp.standName === newTestStand.standName);

    if (alreadyAdded) {
      const updatedTestStands = rowsTestStand.map((rts) => {
        let testStandToAdd = { ...rts };
        const isSameTestStand = rts.standName === newTestStand.standName;

        if (isSameTestStand) {
          testStandToAdd = newTestStand;
        }

        return testStandToAdd;
      });

      setRowsTestStand(updatedTestStands);
    } else {
      setRowsTestStand([...rowsTestStand, { ...newTestStand, rowId: generateUniqueID() }]);
    }
    setShowAddTestStandModal(false);
    setTestStand(TEST_STAND_INITIAL_VALUES);
  }

  function handleAddFacility(newFacility) {
    const alreadyAdded =
      rowsFacility.length > 0 &&
      rowsFacility.find((rf) => rf.facilityName === newFacility.stationaryTestFacilityName);

    if (alreadyAdded) {
      const updatedFacilities = rowsFacility.map((rf) => {
        let facilityToAdd = { ...rf };
        const isSameFacility = rf.facilityName === newFacility.stationaryTestFacilityName;

        if (isSameFacility) {
          facilityToAdd = {
            facilityName: newFacility.stationaryTestFacilityName,
          };
        }

        return facilityToAdd;
      });

      setRowsFacility(updatedFacilities);
    } else {
      setRowsFacility([
        ...rowsFacility,
        {
          ...newFacility,
          rowId: generateUniqueID(),
        },
      ]);
    }
    setShowAddFacilityModal(false);
    setFacility(FACILITY_INITIAL_VALUES);
  }

  const uomList = uoMList
    ? uoMList.filter((u) => u.type === "weight" || u.type === "volume").map((unit) => unit?.uoMName)
    : [];

  function handleAddTest() {
    const newDisplayName = convertTestDisplayName(
      procedureData?.procedureNumber,
      TEST_INITIAL_STATE?.derivativeNumber,
      TEST_INITIAL_STATE?.isModified,
      TEST_INITIAL_STATE?.modificationNumber,
    );

    const oldRowTests =
      rowsTest && rowsTest.length > 0
        ? rowsTest.map((r) => {
            return {
              ...r,
              rowSelected: false,
            };
          })
        : [];

    const newTest = {
      ...TEST_INITIAL_STATE,
      rowId: generateUniqueID(),
      rowSelected: true,
      canEditValue: true,
      procedureNumber: procedureData?.procedureNumber,
      testDisplayName: newDisplayName,
    };

    oldRowTests.push(newTest);

    setRowsTest(oldRowTests);
  }

  return (
    <Box display="flex" flexDirection="column" gap={2}>
      <Box display="flex" gap={2}>
        <TextField
          onChange={(e) => handleChangeProcedureNumber(e)}
          value={procedureData?.procedureNumber}
          label="* Procedure/AL #"
          size="small"
          type="text"
          style={{ width: "10%" }}
          error={procedureNumberIsAlreadyUsed}
          disabled={doesProcedureExist || !canManageProcedure}
          helperText={procedureNumberIsAlreadyUsed ? "Procedure # already exists" : ""}
        />

        <FormControlLabel
          label="Active"
          onChange={(e) =>
            setProcedureData((prevState) => ({
              ...prevState,
              isActive: e.target.checked,
            }))
          }
          disabled={!canManageProcedure}
          checked={procedureData?.isActive}
          control={<Checkbox />}
        />
      </Box>

      <TextField
        onChange={(e) => {
          setProcedureData((prevState) => ({
            ...prevState,
            procedureName: e.target.value,
          }));
        }}
        value={procedureData?.procedureName}
        label="* Name"
        inputProps={{ maxLength: 50 }}
        size="small"
        style={{ width: "25.8%" }}
        disabled={doesProcedureExist || !canManageProcedure}
      />

      <Box display="flex" flexDirection="column" gap={2}>
        <Box display="flex" alignItems="center" gap={2}>
          <Autocomplete
            noOptionsText={"Loading Procedure Types..."}
            value={procedureData?.classificationName}
            onChange={(_, value) => {
              setProcedureData((prevState) => ({
                ...prevState,
                classificationName: value ? value : "",
              }));
            }}
            isOptionEqualToValue={(option, value) => value === option}
            disablePortal
            options={procedureTypes}
            renderInput={(params) => (
              <TextField {...params} label="* Procedure Type" size="small" />
            )}
            autoSelect
            style={{ width: "10%" }}
            disabled={!canManageProcedure}
          />

          <Autocomplete
            noOptionsText={
              resultOptionsLoading
                ? "Loading interval result descriptions..."
                : "No interval result descriptions"
            }
            value={procedureData?.intervalResultOptionName}
            onChange={(_, value) => {
              setProcedureData((prevState) => ({
                ...prevState,
                intervalResultOptionName: value ? value : "",
              }));
            }}
            disabled={!canManageProcedure}
            isOptionEqualToValue={(option, value) => value === option}
            disablePortal
            options={intervalResultOptions}
            renderInput={(params) => (
              <TextField {...params} label="Interval Result Description" size="small" />
            )}
            autoSelect
            style={{ width: "15%" }}
          />
        </Box>
        <TextField
          onChange={(e) => {
            setProcedureData((prevState) => ({
              ...prevState,
              workInstructionName: e.target.value,
            }));
          }}
          value={procedureData?.workInstructionName}
          label="Work Instruction #"
          disabled={!canManageProcedure}
          inputProps={{ maxLength: 50 }}
          size="small"
          style={{ width: "9.8%" }}
        />
      </Box>

      <StyledCheckboxGroup>
        <FormControlLabel
          label="Allow Multiple Lubricant Samples"
          onChange={(e) => {
            setProcedureData((prevState) => ({
              ...prevState,
              allowMultipleLubricants: e.target.checked,
            }));
          }}
          disabled={!canManageProcedure}
          checked={procedureData?.allowMultipleLubricants}
          control={<Checkbox />}
        />

        <FormControlLabel
          label="Allow Multiple Fuels Samples"
          onChange={(e) => {
            setProcedureData((prevState) => ({
              ...prevState,
              allowMultipleFuelSamples: e.target.checked,
            }));
          }}
          disabled={!canManageProcedure}
          checked={procedureData?.allowMultipleFuelSamples}
          control={<Checkbox />}
        />

        <FormControlLabel
          label="Show Viscosity"
          onChange={(e) => {
            setProcedureData((prevState) => ({
              ...prevState,
              showViscosity: e.target.checked,
            }));
          }}
          disabled={!canManageProcedure}
          checked={procedureData?.showViscosity}
          control={<Checkbox />}
        />

        <FormControlLabel
          label="Require Fuel Sample"
          onChange={(e) => {
            setProcedureData((prevState) => ({
              ...prevState,
              requireFuelSample: e.target.checked,
            }));
          }}
          disabled={!canManageProcedure}
          checked={procedureData?.requireFuelSample}
          control={<Checkbox />}
        />

        <FormControlLabel
          label="Allow Test Preparation Step"
          onChange={(e) => {
            setProcedureData((prevState) => ({
              ...prevState,
              allowTestPreparationStep: e.target.checked,
            }));
          }}
          disabled={!canManageProcedure}
          checked={procedureData?.allowTestPreparationStep}
          control={<Checkbox />}
        />
      </StyledCheckboxGroup>

      <Box display="flex" gap={2}>
        {procedureData?.createdByEmail ? (
          <Typography sx={{ position: "relative" }}>
            Created By: <b>{procedureData?.createdByEmail}</b> on
            <b> {convertToLocalTime(procedureData?.createdDate)}</b>
          </Typography>
        ) : (
          <Typography sx={{ position: "relative" }}>
            Created By: <b>unknown</b> on <b>N/A</b>
          </Typography>
        )}

        {procedureData?.lastModifiedByEmail ? (
          <Typography sx={{ position: "relative" }}>
            Last Updated By: <b>{procedureData?.lastModifiedByEmail}</b> on
            <b> {convertToLocalTime(procedureData?.lastModifiedDate)}</b>
          </Typography>
        ) : null}
      </Box>

      <ProcedureManagementDataGrid
        handleSelectedInfo={handleEngineerSelected}
        title="Engineers"
        buttonText="Add Engineer"
        rows={rowsEngineer}
        setRows={setRowsEngineer}
        cols={columnsEngineersTable}
        modalListShownOptions={engineers.map((engineer) => engineer?.fullName)}
        modalListCompleteData={engineers}
        modalListType="engineer"
        enableDelete={canManageProcedure}
        canEdit={canManageProcedure}
        actionOpenModal
        requiredFields={
          procedureData?.procedureNumber &&
          procedureData?.procedureName &&
          procedureData?.classificationName
        }
        procedureNumberIsAlreadyUsed={procedureNumberIsAlreadyUsed}
      />

      <ProcedureManagementDataGrid
        title="Tests"
        buttonText="Add New Test"
        rows={rowsTest}
        setRows={setRowsTest}
        cols={columnsTestsTable}
        enableDelete={false}
        enableSelectSingleRow
        requiredFields={
          procedureData?.procedureNumber &&
          procedureData?.procedureName &&
          procedureData?.classificationName
        }
        actionOpenModal={false}
        handleAdd={handleAddTest}
        procedureNumberIsAlreadyUsed={procedureNumberIsAlreadyUsed}
        canEdit={canManageProcedure}
        selectionDataGrid={handleClickOnTest}
      />

      {foundSelectedTestRow && (
        <Box display="flex" gap={2} flexDirection="column">
          <Accordion style={{ width: "100%" }} defaultExpanded={!!foundSelectedTestRow}>
            <AccordionSummary
              style={{ backgroundColor: "#1976d2" }}
              expandIcon={<ExpandMore style={{ color: "white" }} />}
              aria-controls="panel3-content">
              <Typography
                style={{
                  color: "white",
                  fontWeight: "600",
                  fontSize: "16px",
                  fontFamily: "EMprint",
                }}>
                Test Details
              </Typography>
            </AccordionSummary>
            <AccordionDetails style={{ display: "flex", flexDirection: "column", gap: 16 }}>
              <Box display="flex" alignItems="center" gap={2} marginTop={2}>
                <TextField
                  value={foundSelectedTestRow?.testDisplayName}
                  label="Test Display Name"
                  disabled
                  size="small"
                  style={{ width: "19.5%" }}
                />

                <FormControlLabel
                  label="Active"
                  onChange={(e) => {
                    const updatedArray = rowsTest.map((row) => {
                      if (row?.rowId === foundSelectedTestRow?.rowId) {
                        return {
                          ...foundSelectedTestRow,
                          isActive: e.target.checked,
                        };
                      }
                      return row;
                    });

                    setRowsTest(updatedArray);
                  }}
                  disabled={!canManageProcedure}
                  checked={foundSelectedTestRow?.isActive}
                  control={<Checkbox />}
                />
              </Box>

              <Box display="flex" gap={2} alignItems="center">
                <TextField
                  onChange={(e) => {
                    const updatedArray = rowsTest.map((row) => {
                      if (row?.rowId === foundSelectedTestRow?.rowId) {
                        const formattedValue = e.target.value.trim().replace(/\D+/g, "");

                        const newDisplayName = convertTestDisplayName(
                          foundSelectedTestRow?.procedureNumber,
                          formattedValue,
                          foundSelectedTestRow?.isModified,
                          foundSelectedTestRow?.modificationNumber,
                        );
                        return {
                          ...foundSelectedTestRow,
                          derivativeNumber: formattedValue,
                          testDisplayName: newDisplayName,
                        };
                      }
                      return row;
                    });

                    setRowsTest(updatedArray);
                  }}
                  value={foundSelectedTestRow?.derivativeNumber}
                  label="Derivative #"
                  size="small"
                  type="text"
                  disabled={foundSelectedTestRow?.isOldTest || !canManageProcedure}
                />

                <FormControlLabel
                  label="Modified"
                  onChange={(e) => {
                    const updatedArray = rowsTest.map((row) => {
                      if (row?.rowId === foundSelectedTestRow?.rowId) {
                        const newDisplayName = convertTestDisplayName(
                          foundSelectedTestRow?.procedureNumber,
                          foundSelectedTestRow?.derivativeNumber,
                          e.target.checked,
                          foundSelectedTestRow?.modificationNumber,
                        );
                        return {
                          ...foundSelectedTestRow,
                          isModified: e.target.checked,
                          testDisplayName: newDisplayName,
                        };
                      }
                      return row;
                    });

                    setRowsTest(updatedArray);
                  }}
                  checked={foundSelectedTestRow?.isModified}
                  control={<Checkbox />}
                  disabled={foundSelectedTestRow?.isOldTest || !canManageProcedure}
                />

                <TextField
                  onChange={(e) => {
                    const updatedArray = rowsTest.map((row) => {
                      if (row?.rowId === foundSelectedTestRow?.rowId) {
                        const formattedValue = e.target.value.trim().replace(/\D+/g, "");

                        const newDisplayName = convertTestDisplayName(
                          foundSelectedTestRow?.procedureNumber,
                          foundSelectedTestRow?.derivativeNumber,
                          foundSelectedTestRow?.isModified,
                          formattedValue,
                        );
                        return {
                          ...foundSelectedTestRow,
                          modificationNumber: formattedValue,
                          testDisplayName: newDisplayName,
                        };
                      }
                      return row;
                    });

                    setRowsTest(updatedArray);
                  }}
                  value={foundSelectedTestRow?.modificationNumber}
                  label="Mod #"
                  size="small"
                  type="text"
                  disabled={foundSelectedTestRow?.isOldTest || !canManageProcedure}
                />
              </Box>

              <Box display="flex" alignItems="center" gap={3}>
                <TextField
                  onChange={(e) => {
                    const updatedArray = rowsTest.map((row) => {
                      if (row?.rowId === foundSelectedTestRow?.rowId) {
                        return {
                          ...foundSelectedTestRow,
                          testMethod: e.target.value,
                        };
                      }
                      return row;
                    });

                    setRowsTest(updatedArray);
                  }}
                  disabled={!canManageProcedure}
                  value={foundSelectedTestRow?.testMethod}
                  label="Test Method"
                  size="small"
                  style={{ width: "10%" }}
                />

                <TextField
                  onChange={(e) => {
                    const updatedArray = rowsTest.map((row) => {
                      if (row?.rowId === foundSelectedTestRow?.rowId) {
                        return {
                          ...foundSelectedTestRow,
                          testName: e.target.value,
                        };
                      }
                      return row;
                    });

                    setRowsTest(updatedArray);
                  }}
                  value={foundSelectedTestRow?.testName}
                  label="Test Name"
                  disabled={!canManageProcedure}
                  size="small"
                  style={{ width: "30%" }}
                />
              </Box>

              <TextField
                onChange={(e) => {
                  const updatedArray = rowsTest.map((row) => {
                    if (row?.rowId === foundSelectedTestRow?.rowId) {
                      return {
                        ...foundSelectedTestRow,
                        testDescription: e.target.value,
                      };
                    }
                    return row;
                  });

                  setRowsTest(updatedArray);
                }}
                label="Description"
                multiline
                value={foundSelectedTestRow?.testDescription}
                disabled={!canManageProcedure}
                size="small"
              />

              <Box display="flex" flexDirection="column" gap={2}>
                <TextField
                  onChange={(e) => {
                    const updatedArray = rowsTest.map((row) => {
                      if (row?.rowId === foundSelectedTestRow?.rowId) {
                        return {
                          ...foundSelectedTestRow,
                          userMessage: e.target.value,
                        };
                      }
                      return row;
                    });

                    setRowsTest(updatedArray);
                  }}
                  value={foundSelectedTestRow?.userMessage ?? ""}
                  label="User Message"
                  disabled={!canManageProcedure}
                  size="small"
                />

                <Box display="flex" alignItems="center" gap={2}>
                  <TextField
                    onChange={(e) => {
                      const updatedArray = rowsTest.map((row) => {
                        if (row?.rowId === foundSelectedTestRow?.rowId) {
                          return {
                            ...foundSelectedTestRow,
                            fuelType: e.target.value,
                          };
                        }
                        return row;
                      });

                      setRowsTest(updatedArray);
                    }}
                    value={foundSelectedTestRow?.fuelType ?? ""}
                    label="Fuel Type"
                    disabled={!canManageProcedure}
                    size="small"
                    style={{ width: "12%" }}
                  />

                  <TextField
                    onChange={(e) => {
                      const updatedArray = rowsTest.map((row) => {
                        if (row?.rowId === foundSelectedTestRow?.rowId) {
                          return {
                            ...foundSelectedTestRow,
                            minimumSampleSize: e.target.value,
                          };
                        }
                        return row;
                      });

                      setRowsTest(updatedArray);
                    }}
                    value={foundSelectedTestRow?.minimumSampleSize ?? ""}
                    label="Minimum Sample Size"
                    disabled={!canManageProcedure}
                    size="small"
                    type="number"
                    style={{ width: "15%" }}
                  />

                  <Autocomplete
                    noOptionsText={"Loading Sample Size UoM..."}
                    value={foundSelectedTestRow?.minimumSampleSizeUoMName}
                    onChange={(_, value) => {
                      const updatedArray = rowsTest.map((row) => {
                        if (row?.rowId === foundSelectedTestRow?.rowId) {
                          return {
                            ...foundSelectedTestRow,
                            minimumSampleSizeUoMName: value ? value : "",
                          };
                        }
                        return row;
                      });
                      setRowsTest(updatedArray);
                    }}
                    isOptionEqualToValue={(option, value) => value === option}
                    disablePortal
                    disabled={!canManageProcedure}
                    options={uomList}
                    renderInput={(params) => (
                      <TextField {...params} label="Sample Size UoM" size="small" />
                    )}
                    autoSelect
                    style={{ width: "10%" }}
                  />
                </Box>
              </Box>

              <Box display="grid" gap={2} gridTemplateColumns="1fr 1fr 1fr">
                <ProcedureManagementDataGrid
                  sx={{ height: "20rem" }}
                  title="Test Stand"
                  buttonText="Add Test Stand"
                  rows={rowsTestStand}
                  cols={columnsTestStandTable}
                  enableDelete={canManageProcedure}
                  minWidth={340}
                  canEdit={canManageProcedure}
                  requiredFields
                  handleAdd={() => setShowAddTestStandModal(true)}
                  selectionDataGrid={(c) => {
                    setTestStand(c);
                    setShowAddTestStandModal(true);
                  }}
                  customDelete={(deletedTestStand) => {
                    const filtered = rowsTestStand.filter(
                      (ts) => ts.standName !== deletedTestStand.standName,
                    );
                    setRowsTestStand(filtered);
                  }}
                  setRows={() => {}}
                />

                <ProcedureManagementDataGrid
                  sx={{ width: "35rem", height: "20rem" }}
                  title="Test Parameter"
                  buttonText="Add Test Parameter"
                  rows={rowsTestParameter}
                  cols={columnsTestParameterTable}
                  enableDelete={canManageProcedure}
                  minWidth={400}
                  canEdit={canManageProcedure}
                  requiredFields
                  handleAdd={() => setShowAddTestParameterModal(true)}
                  selectionDataGrid={(c) => {
                    setTestParameter(c);
                    setShowAddTestParameterModal(true);
                  }}
                  customDelete={(deleted) => {
                    const filtered = rowsTestParameter.filter(
                      (ts) => ts.testParameterName !== deleted.testParameterName,
                    );
                    setRowsTestParameter(filtered);
                  }}
                  setRows={() => {}}
                />

                <ProcedureManagementDataGrid
                  sx={{ width: "50rem", height: "20rem" }}
                  title="Facility"
                  buttonText="Add Facility"
                  rows={rowsFacility}
                  cols={columnsFacilityTable}
                  setRows={() => {}}
                  enableDelete={canManageProcedure}
                  canEdit={canManageProcedure}
                  requiredFields
                  handleAdd={() => setShowAddFacilityModal(true)}
                  selectionDataGrid={(c) => {
                    setFacility(c);
                    setShowAddFacilityModal(true);
                  }}
                  customDelete={(deletedFacility) => {
                    const filteredFacilities = rowsFacility.filter(
                      (f) => f.facilityName !== deletedFacility.facilityName,
                    );
                    setRowsFacility(filteredFacilities);
                  }}
                />
              </Box>

              <Accordion style={{ width: "100%" }}>
                <AccordionSummary
                  style={{ backgroundColor: "#1976d2" }}
                  expandIcon={<ExpandMore style={{ color: "white" }} />}
                  aria-controls="panel3-content">
                  <Typography
                    style={{
                      color: "white",
                      fontWeight: "600",
                      fontSize: "16px",
                      fontFamily: "EMprint",
                    }}>
                    Reference Sample Schedule
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <ProcedureManagementTables
                    foundSelectedTestRow={foundSelectedTestRow}
                    intervals={intervals}
                    setIntervals={setIntervals}
                    isReference
                    uoMs={uoMList}
                    locations={locationList}
                    containerTypes={containerTypes}
                    canEdit={canManageSchedule}
                  />
                </AccordionDetails>
              </Accordion>

              <Accordion style={{ width: "100%" }}>
                <AccordionSummary
                  style={{ backgroundColor: "#1976d2" }}
                  expandIcon={<ExpandMore style={{ color: "white" }} />}
                  aria-controls="panel3-content">
                  <Typography
                    style={{
                      color: "white",
                      fontWeight: "600",
                      fontSize: "16px",
                      fontFamily: "EMprint",
                    }}>
                    Candidate Sample Schedule
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <ProcedureManagementTables
                    foundSelectedTestRow={foundSelectedTestRow}
                    intervals={intervals}
                    setIntervals={setIntervals}
                    isReference={false}
                    uoMs={uoMList}
                    locations={locationList}
                    containerTypes={containerTypes}
                    canEdit={canManageSchedule}
                  />
                </AccordionDetails>
              </Accordion>
            </AccordionDetails>
          </Accordion>
        </Box>
      )}
      {showAddTestParameterModal && (
        <TestParameterModal
          add={(newParameter) => {
            handleAddTestParameter(newParameter);
          }}
          initialValues={testParameter}
          isModalOpen={showAddTestParameterModal}
          parameterList={testParameters}
          setModalOpen={setShowAddTestParameterModal}
        />
      )}
      {showAddTestStandModal && (
        <TestStandModal
          add={(newStand) => {
            handleAddTestStand(newStand);
          }}
          initialValues={testStand}
          isModalOpen={showAddTestStandModal}
          standList={filteredTestStandNames}
          setModalOpen={setShowAddTestStandModal}
        />
      )}
      {showAddFacilityModal && (
        <FacilityModal
          add={(newFacility) => {
            handleAddFacility(newFacility);
          }}
          initialValues={facility}
          isModalOpen={showAddFacilityModal}
          standList={filteredFacilityNames}
          setModalOpen={setShowAddFacilityModal}
        />
      )}
    </Box>
  );
};

export default ProcedureManagementForm;
