import API from "../index";

export default class RequestItem {
  constructor(
    ID,
    RequestID,
    StockRoomRequest,
    EquipmentID,
    ThisItem,
    RequestedQuantity,
    IssuedQuantity,
    IssuedByEmail,
    IssuedDate,
    UnitPriceUSD,
    OrderStatusName,
    IgnoreAlert,
    Comments,
    ReceivedContainerID
  ) {
    this.ID = ID;
    this.RequestID = RequestID;
    this.StockRoomRequest = StockRoomRequest;
    this.EquipmentID = EquipmentID;
    this.ThisItem = ThisItem;
    this.RequestedQuantity = RequestedQuantity;
    this.IssuedQuantity = IssuedQuantity;
    this.IssuedByEmail = IssuedByEmail;
    this.IssuedDate = IssuedDate;
    this.UnitPriceUSD = UnitPriceUSD;
    this.OrderStatusName = OrderStatusName;
    this.IgnoreAlert = IgnoreAlert;
    this.Comments = Comments;
    this.ReceivedContainerID = ReceivedContainerID;
  }

  static async getAll() {
    const api = await API();
    const { data } = await api.get("/stockroomrequestitem");
    return data.result.map((item) => new RequestItem(item));
  }

  static async getRequestItemById(itemID) {
    const api = await API();
    const { data } = await api.get(`/stockroomrequestitem?itemID=${itemID}`);
    return data.result.map((item) => new RequestItem(item));
  }
}
