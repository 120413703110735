import {
  StyledTableCell,
  StyledTableRow,
  UXDataTableWithoutBody,
} from "../../../../components/UXDataTable";
import { TableCell, TextField, Autocomplete } from "@mui/material";
import { formatMidasNumber, isFlashPointValid } from "../../../../global";
import { MySafetyInfoCols, sampleGridFontSize } from "../constants.ts";
import { useEffect, useState } from "react";
import DOTHazardClass from "../../../../api/WorkRequest/DOTHazardClass";

export default function WorkRequestShippingSafetyTable({
  myWorkRequest,
  updateData_ShippingItem,
  setShippingRequest,
  shippingRequest,
}) {
  const [safetyTable, setSafetyTable] = useState(
    myWorkRequest.workRequestSamples?.filter((item) => !!item.sample)
  );

  const [dotHazardClass, setDOTHazardClass] = useState([]);
  const [dotHazardClassLoading, setDOTHazardClassLoading] = useState(true);

  const getSampleOnce = (sampleList) => {
    let distinctSampleList = [];
    let onlySampleNames = [];
    sampleList.forEach((x) => {
      if (x.sample && !onlySampleNames.includes(`${x.sample.sampleName}-${x.existingContainerID}`)) {
        distinctSampleList.push(x);
        onlySampleNames.push(`${x.sample.sampleName}-${x.existingContainerID}`);
      }
    });

    return distinctSampleList;
  };
  const updateAllSampleOccurance = (property, value, sampleName, containerID) => {

    let itemsCopy = structuredClone(shippingRequest.shippingItems);
    itemsCopy.forEach((element) => {
      if (element.sampleName === sampleName && element.containerID === containerID) element[property] = value;
    });

    setShippingRequest({
      ...shippingRequest,
      shippingItems: itemsCopy,
    });
  };

  useEffect(() => {
    setSafetyTable(getSampleOnce(myWorkRequest.workRequestSamples));
  }, [myWorkRequest.workRequestSamples]);

  useEffect(() => {
    let cancelPromise = false;

    DOTHazardClass.getAllActive().then((res) => {
      if (cancelPromise) return;
      setDOTHazardClassLoading(false);
      setDOTHazardClass(
        res.sort((a, b) => a.displayName.localeCompare(b.displayName))
      );
    })

    return () => {
      cancelPromise = true;
    };
  }, []);

  return (
    <UXDataTableWithoutBody
      tableWidth="95%"
      cols={MySafetyInfoCols}
      blankFirstHeader={false}
      tableId={`midasSafetyInfoTable`}
      rowLength={safetyTable.length}
      enablePaging={false}
      rowsPerPage={0}
      page={0}
      handleChangePage={null}
      handleChangeRowsPerPage={null}
      noDataFoundMessage={"No MIDAS Numbers Found"}
      enableAddIcon={false}
      addFunction={() => null}
      addToolTipText=""
      isDataLoading={false}
    >
      {safetyTable.map((item, index) => {
        let currentShippingItem = shippingRequest.shippingItems.find(
          (x) => x.sampleName === item.sample.sampleName && x.containerID === item.existingContainerID
        );
        const container = item.sample.containers.find(f => f.id === item.existingContainerID);
        return (
          <StyledTableRow key={`WRSamplesSafetyTable~Row${index}`}>
            <TableCell
              style={{ width: "120px", fontSize: sampleGridFontSize }}
              align="left"
            >
              {item.sample ? formatMidasNumber(item.sample.sampleName) : "-"}
            </TableCell>

            <TableCell
              style={{ width: "151px", fontSize: sampleGridFontSize }}
              align="left"
            >
              {container ? container.containerNumber : ''}
            </TableCell>
            <TableCell
              style={{ width: "100px", fontSize: sampleGridFontSize }}
              align="left"
            >
              {container?.currentAmount ?? ""}
            </TableCell>
            <TableCell
              style={{ width: "100px", fontSize: sampleGridFontSize }}
              align="left"
            >
              {item?.requestedAmountUoM ?? ""}
            </TableCell>
            <TableCell
              style={{ width: "100px", fontSize: sampleGridFontSize }}
              align="left"
            >
              {item?.sample
                ? item.sample?.psimsid
                : item.sample?.psimsid ?? ""}
            </TableCell>
            {/* Blind Code Label Description */}
            <StyledTableCell align="left">
              <TextField
                style={{ width: "100%", marginTop: "5px" }}
                disabled={false}
                size="small"
                margin="dense"
                variant="outlined"
                inputProps={{
                  style: { fontSize: sampleGridFontSize },
                }}
                InputLabelProps={{ shrink: true }}
                value={currentShippingItem?.blindCodeDescription ? currentShippingItem?.blindCodeDescription : item.blindCodeDescription === null ? "" : item.blindCodeDescription}
                onChange={(e) => {
                  updateAllSampleOccurance(
                    "blindCodeDescription",
                    e.target.value,
                    item.sample.sampleName,
                    item.existingContainerID
                  );
                }}
              />
            </StyledTableCell>
            <StyledTableCell align="left" style={{ width: '130px' }}>
              <TextField
                style={{ width: "100%", marginTop: "5px" }}
                disabled={false}
                size="small"
                margin="dense"
                variant="outlined"
                inputProps={{
                  style: { fontSize: sampleGridFontSize },
                }}
                InputLabelProps={{ shrink: true }}
                type="text"
                value={currentShippingItem?.flashPointInC ?? ""}
                onChange={(e) => {
                  const value = e.target.value;
                  if (isFlashPointValid(value)) {
                    updateAllSampleOccurance(
                      "flashPointInC",
                      value,
                      item.sample.sampleName,
                      item.existingContainerID
                    );
                  }
                }}
              />
            </StyledTableCell>
            {/* DOT Hazard Class */}
            <StyledTableCell align="left">
              <Autocomplete
                disablePortal
                noOptionsText={
                  dotHazardClassLoading ? "Loading DOT Hazard Class..." : "No DOT Hazard Class Found"
                }
                options={dotHazardClass}
                onChange={(e, value) => updateAllSampleOccurance(
                    "dotHazardClass",
                    value?.classNumber,
                    item.sample.sampleName,
                    item.existingContainerID
                  )
                }
                getOptionLabel={(option) =>
                  option?.displayName
                    ? option.displayName
                    : dotHazardClass.length > 0
                      ? dotHazardClass.find((f) => `${f.classNumber}` === `${option}`)?.displayName
                      : ""
                }
                autoHighlight
                autoSelect
                value={currentShippingItem?.dotHazardClass ? currentShippingItem?.dotHazardClass : item.dotHazardClass}
                isOptionEqualToValue={(option, value) =>
                  value?.classNumber === option?.classNumber
                }
                style={{ width: "100%" }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    size="small"
                    margin="dense"
                    inputProps={{
                      ...params.inputProps,
                      style: { fontSize: sampleGridFontSize },
                    }}
                    InputLabelProps={{ ...params.InputProps, shrink: true }}
                  />
                )}
              />
            </StyledTableCell>
            <StyledTableCell align="right" style={{ width: '120px' }}>
              <TextField
                style={{ width: "100%", marginTop: "5px" }}
                disabled={false}
                size="small"
                margin="dense"
                variant="outlined"
                inputProps={{
                  style: { fontSize: sampleGridFontSize },
                }}
                InputLabelProps={{ shrink: true }}
                value={currentShippingItem?.exportValue ? currentShippingItem?.exportValue : item.exportValue === null ? "" : item.exportValue}
                onChange={(e) => {
                  updateAllSampleOccurance(
                    "exportValue",
                    e.target.value,
                    item.sample.sampleName,
                    item.existingContainerID
                  );
                }}
              />
            </StyledTableCell>
            <StyledTableCell align="right">
              <TextField
                style={{ width: "100%", marginTop: "5px" }}
                disabled={!!item}
                size="small"
                margin="dense"
                variant="outlined"
                inputProps={{
                  style: { fontSize: sampleGridFontSize },
                }}
                InputLabelProps={{ shrink: true }}
                value={item ? item.sample.description : item.description ?? ""}
                onChange={(e) => {
                  updateData_ShippingItem(
                    "description",
                    e.target.value,
                    item.sample.sampleName,
                    item.existingContainerID
                  );
                }}
              />
            </StyledTableCell>
          </StyledTableRow>
        );
      })}
    </UXDataTableWithoutBody>
  );
}
