import { Button, Tab, Tabs, Autocomplete } from '@mui/material'
import { styled } from '@mui/material/styles';

const fontSize = 10

export const GlobalButton = styled(Button)({
  height: 35,
  textTransform: "none",
});

export const GlobalFixedButton = styled(Button)({
  height: 35,
  textTransform: "none",
  position: 'fixed',
  right: '10px',
  bottom: '10px'
});

export const GlobalSecondaryButton = styled(Button)({
  backgroundColor: "white",
  color: "#0C69B0",
  '&:hover': {
    backgroundColor: 'white',
    color: '#0C69B0'
  },
  '&:disabled': {
    backgroundColor: '#0000001f',
    color: '#00000042',
    border: "none",
  },
  border: "1px solid #0C69B0",
  height: 35,
  textTransform: "none", 
});

export const GlobalTabCollection = styled(Tabs)({
  marginTop: "-20px",
  borderBottom: "1px solid #e8e8e8",
});

export const StyledAutocomplete = styled(Autocomplete)({
  '& .MuiAutocomplete-input, & .MuiInputLabel-root': {
    fontSize: fontSize,
  },
});

export const Option = styled('li')({
  fontSize: fontSize,
});

export const StyledTab = styled((props) => <Tab disableRipple {...props} />)(({ theme }) => ({
  textTransform: "none",
  minWidth: 100,
  [theme.breakpoints.up("sm")]: {
    minWidth: 0,
  },
  fontWeight: theme.typography.fontWeightRegular,
  marginRight: theme.spacing(1),
  color: "rgba(0, 0, 0, 0.85)",
  fontFamily: ["EMPrint"].join(","),
  "&:hover": {
    color: "#40a9ff",
    opacity: 1,
  },
  "&.Mui-selected": {
    color: "#1890ff",
    fontWeight: theme.typography.fontWeightMedium,
  },
}));
