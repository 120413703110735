import React, { useState, useEffect, useContext, createContext } from "react";
import UserContext from "./UserContext";

import ApplicationPreference from "../api/Admin/ApplicationPreference";

export const PrefContext = createContext(null)

const MODAL_CONTENT_INITIAL_STATE = {
    title: '',
    message: '',
}

export const PrefContextProvider = ({ children }) => {
    const [userPreferences, setUserPreferences] = useState({})
    const [isLoading, setIsLoading] = useState(false)
    const [modalContent, setModalContent] = useState(MODAL_CONTENT_INITIAL_STATE)

    const currentUser = useContext(UserContext)

    const updateUserPreferences = async () => {
        setIsLoading(true)
        try {
            await ApplicationPreference.update(currentUser.username, userPreferences)
            setModalContent({ title: 'Success!', message: 'Preferences updated sucessfully!' })
        } catch (err) {
            setModalContent({ title: 'Error!', message: 'There was an error updating your preferences. Please, try again later!'})
        }
        setIsLoading(false)
    }

    useEffect(() => {
        const getUserPreferences = async () => {
            setIsLoading(true)
            const appPref = await ApplicationPreference.getByEmail(currentUser.username);
            const copiedPreferences = JSON.parse(JSON.stringify(appPref))
            setUserPreferences(copiedPreferences)
            setIsLoading(false)
        }

        getUserPreferences()
    }, [currentUser.username])

    return (
        <PrefContext.Provider value={{ 
            userPreferences, 
            setUserPreferences, 
            updateUserPreferences, 
            isLoading,
            modalContent,
            setModalContent
        }}>
            {children}
        </PrefContext.Provider>
    )
}

export default PrefContext
