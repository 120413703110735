import { useState, useContext, useEffect } from 'react'
import {Divider, Box, Checkbox, FormControlLabel, Typography, CircularProgress} from "@mui/material";
import {StyledTableCellSmall, StyledTableClickableCell, StyledTableCellExtraSmall} from './styles'
import { StyledTableBody, StyledTableRow, UXDataTableWithoutBody } from '../../components/UXDataTable'
import ModalSimpleButton from '../../components/Modal/ModalSimpleButton'
import { convertDateFormat } from '../../global'
import { NotificationContext } from '../../context/NotificationContext';
import "./style.css";
import parse from "html-react-parser";
import { GlobalSecondaryButton, GlobalButton } from "../../pages/styles";
import Notification from '../../api/Admin/Notification';

const notificationCols = ['ID', 'Category', 'Header', 'Date']

const Notifications = () => {
    const [showOnlyUnread, setShowOnlyUnread] = useState(false)
    const [isMessageModalOpen, setIsMessageModalOpen] = useState(false)
    const [modalContentID, setModalContentID] = useState(null)
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [page, setPage] = useState(0);
    const {notifications, setNotifications, getNotificationsForUser, isNotificationDataLoading} = useContext(NotificationContext)

    const [isMarkAsReadProcessing, setIsMarkAsReadProcessing] = useState(false)
    const [isDeleteProcessing, setIsDeleteProcessing] = useState(false)

    const [modalMessagesOpen, setModalMessagesOpen] = useState(false);
    const modalMessagesButtonText = 'Ok'
    const [modalMessagesTitle, setModalMessagesTitle] = useState('');
    const [modalMessagesText, setModalMessagesText] = useState('');

    useEffect(() => {

        if (showOnlyUnread === true)
        {
            setNotifications(prevState => {
                const updatedArray = prevState.map(notification => {
                    if (notification.isAcknowledged === true)
                    {
                        return {...notification, isMessageSelected: false}
                    }
                    return notification
                })
    
                return updatedArray
            })

            setPage(0)
        }

    }, [showOnlyUnread, setNotifications])

    function closeModalMessages() {
        setModalMessagesOpen(false);
    }
    
    function openModalMessages(title, text) {
        setModalMessagesOpen(true);
        setModalMessagesTitle(title);
        setModalMessagesText(text);
    }

    const handlePageChange = (event, page) => {
        setPage(page)
    }
    
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    function determineStartArray (arrayNotifications) {
        if (arrayNotifications)
        {
            if (showOnlyUnread)
            {
                return (arrayNotifications.filter(note => note.isAcknowledged === false).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage))
            } else {
                return (arrayNotifications.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage))
            }
           
        } else {
            return []
        }
    }

    const handleHeaderClick = (notification) => {
        updateSingleNotificationAsAcknowledged(notification.id)
        setIsMessageModalOpen(true)
        setModalContentID(notification.id)
    }

    const handleSelectAllMessages = () => {
        const displayedNotifications = determineStartArray(notifications)

        const allNotificationsSelected = notifications.filter(note => note.isMessageSelected).length === notifications.length
        const allDisplayedSelected = displayedNotifications.filter(note => note.isMessageSelected).length === displayedNotifications.length
  
        if (allNotificationsSelected)
        {
            setNotifications(prevState => {
                const updatedArray = prevState.map(notification => {
                    return {...notification, isMessageSelected: false}
                })
    
                return updatedArray
            })

        } else if (allDisplayedSelected)
        {
            setNotifications(prevState => {
                const updatedArray = prevState.map(notification => {
                    if (displayedNotifications.filter(note => note.id === notification.id).length > 0)
                    {
                        return {...notification, isMessageSelected: false}
                    }
                    return notification
                })
    
                return updatedArray
            })

        } else {
            setNotifications(prevState => {
                const updatedArray = prevState.map(notification => {
                    if (displayedNotifications.filter(note => note.id === notification.id).length > 0)
                    {
                        return {...notification, isMessageSelected: true}
                    }
                    return notification
                })
    
                return updatedArray
            })
        }
       
    }

    function markSelectedMessagesAsRead () {
        let selectedMessageIDs = []

        setIsMarkAsReadProcessing(true)

        notifications.filter(note => note.isMessageSelected === true).forEach(oMessage => {
            selectedMessageIDs.push(oMessage.id)
        });

        acknowledgeNotifications(selectedMessageIDs)
    }

    function updateSingleNotificationAsAcknowledged (notificationID) {
        let selectedMessageIDs = [notificationID]
        setIsMarkAsReadProcessing(true)

        acknowledgeNotifications(selectedMessageIDs)
    }

    function acknowledgeNotifications (notificationIDs)
    {
        Notification.acknowledgeNotifications(notificationIDs).then((res) => {
    
            if (res.message === "Success")
            {
                //do nothing for now
            } else {
                openModalMessages("Error Marking as Read", `There was an error marking your notifications as read, please try again.  ${res.message ? res.message : ''} `)
            }

            getNotificationsForUser()

            setIsMarkAsReadProcessing(false)

        })        
    }

    function deleteNotifications () 
    {
        let selectedMessageIDs = []

        setIsDeleteProcessing(true)

        notifications.filter(note => note.isMessageSelected === true).forEach(oMessage => {
            selectedMessageIDs.push(oMessage.id)
        });

        Notification.delete(selectedMessageIDs).then((res) => {
    
            if (res.message === "Success")
            {
                //do nothing for now
            } else {
                openModalMessages("Error Deleting", `There was an error deleting your notifications, please try again.  ${res.message ? res.message : ''} `)
            }

            getNotificationsForUser()
            setIsDeleteProcessing(false)

        })  
    }

    function checkBoxChange (e, id) {

        let copyNotifications = structuredClone(notifications)

        const index = copyNotifications.findIndex(note => note.id === id)

        copyNotifications[index].isMessageSelected = e.target.checked

        setNotifications(copyNotifications)
    }

    return (
        <>
          <span className='pageheader'>User Notifications</span>
          <Divider className='dividerbar' />
          <Box display="flex" flexDirection="column" gap={2}>
            <Box display="flex" flexDirection="column" gap={2}>
                <FormControlLabel
                    label="Show only unread notifications"
                    onChange={() => setShowOnlyUnread(!showOnlyUnread)}
                    checked={showOnlyUnread}
                    control={<Checkbox />}
                />

                <UXDataTableWithoutBody
                    tableWidth='50%' 
                    cols={notificationCols} 
                    tableId="notificationsTable"
                    rowLength={showOnlyUnread ? notifications.filter(note => note.isAcknowledged === false).length : notifications.length}
                    enablePaging
                    enableCheckbox={notifications?.filter(n => n.id < 0).length === 0}
                    handleSelectAllClick={handleSelectAllMessages}
                    selected={notifications.filter(note => note.isMessageSelected === true)}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    handleChangePage={handlePageChange}
                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                    noDataFoundMessage={'No notifications found'}
                    enableAddIcon={false}
                    isDataLoading={isNotificationDataLoading}
                >
                    <StyledTableBody>
                    {notifications && notifications.length && determineStartArray(notifications).map((notification) => {
                        const readWeightValue = notification?.isAcknowledged ? 400 : 700

                        return (
                            <StyledTableRow key={notification?.id} hover>
                                <StyledTableCellExtraSmall>
                                    <Box display="flex" alignItems="center" justifyContent="center">
                                        <Checkbox 
                                            disabled={notification?.id < 0}
                                            onChange={e => checkBoxChange(e, notification.id)}
                                            defaultChecked={false}
                                            checked={!!notification?.isMessageSelected}
                                        />
                                    </Box>
                                </StyledTableCellExtraSmall>
                                <StyledTableCellSmall style={{ fontWeight: readWeightValue }}>{notification?.id}</StyledTableCellSmall>
                                <StyledTableCellSmall style={{ fontWeight: readWeightValue }}>{notification?.notificationType}</StyledTableCellSmall>
                                <StyledTableClickableCell 
                                    style={{ fontWeight: readWeightValue }}
                                    onClick={() => handleHeaderClick(notification)}
                                >
                                    {notification?.notificationSubject}
                                </StyledTableClickableCell>
                                <StyledTableCellSmall style={{ fontWeight: readWeightValue }}>{convertDateFormat(notification?.createdDate)}</StyledTableCellSmall>
                            </StyledTableRow>
                        )
                    })}
                    </StyledTableBody>
                </UXDataTableWithoutBody>
            </Box>
            <Box display="flex" alignItems="center" gap={2}>
                {isMarkAsReadProcessing ? 
                (
                    <>
                    <CircularProgress></CircularProgress>
                    <GlobalButton
                        variant="contained"
                        onClick={markSelectedMessagesAsRead}
                        type="button"
                        disabled={true}
                        >Mark As Read</GlobalButton>
                    </>
                ) : (
                    <GlobalButton
                        variant="contained"
                        onClick={markSelectedMessagesAsRead}
                        type="button"
                        disabled={notifications.filter(note => note.isMessageSelected === true).length < 1 || isDeleteProcessing}
                        >Mark As Read</GlobalButton>
                )}
               
               {isDeleteProcessing ? 
                (
                    <>
                    <CircularProgress></CircularProgress>
                    <GlobalSecondaryButton
                        variant="contained"
                        onClick={deleteNotifications}
                        type="button"
                        disabled={true}
                        >Delete Messages</GlobalSecondaryButton>
                    </>
                ) : (
                    <GlobalSecondaryButton
                        variant="contained"
                        onClick={deleteNotifications}
                        type="button"
                        disabled={notifications.filter(note => note.isMessageSelected === true).length < 1 || isMarkAsReadProcessing}
                        >Delete Messages</GlobalSecondaryButton>
                )}
            </Box>
          </Box>

        {isMessageModalOpen && (
        <ModalSimpleButton
            title={`${notifications.find(note => note.id === modalContentID).notificationSubject}`}
            buttonText="Close"
            buttonAction={() => {
                setIsMessageModalOpen(false)
                setModalContentID(null)
            }}
            setOpen={setIsMessageModalOpen}
            open={isMessageModalOpen}
        >
            <Box display="flex" flexDirection="column" gap={0}>
                <Typography style={{ padding: '0 16px', fontSize: 18, textAlign: 'left' }}>
                    Notification from: {notifications.find(note => note.id === modalContentID).notificationFromEmail}
                    {/* Notification from: {convertDateFormat(modalContent.from)} */}
                </Typography>
                <Typography style={{ padding: '0 16px', fontSize: 18, textAlign: 'left' }}>
                    Category: {notifications.find(note => note.id === modalContentID).notificationType}
                </Typography>
                <Typography style={{ padding: '0 16px', fontSize: 16, textAlign: 'left', marginTop:"1rem" }}>
                    {parse(notifications.find(note => note.id === modalContentID).notificationContent)}
                </Typography>
            </Box>
        </ModalSimpleButton>
        )}

            {/* Informational Messages */}
        <ModalSimpleButton title={modalMessagesTitle} buttonText={modalMessagesButtonText} buttonAction={closeModalMessages} open={modalMessagesOpen} setOpen={setModalMessagesOpen}>
            <label>
                {modalMessagesText}
            </label>
        </ModalSimpleButton>
        </>
      );
}

export default Notifications