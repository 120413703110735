import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  PDFViewer,
} from "@react-pdf/renderer";

const styles = StyleSheet.create({
  page: {
    fontSize: "10px",
    display: "flex",
    padding: "30px",
  },
  title: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: "15px",
    borderTop: "1px solid black",
  },
  vendor: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: "15px",
  },
  shipping: {
    flexDirection: "row",
    marginBottom: "15px",
    borderBottom: "1px solid black",
    borderTop: "1px solid black",
  },
  box: { display: "flex", width: "50%" },
  textPadding: { paddingLeft: "10px" },
  table: {
    width: "100%",
    marginTop: "20px",
  },
  row: {
    display: "flex",
    flexDirection: "row",
    borderTop: "1px solid gray",
    paddingTop: 4,
    paddingBottom: 4,
  },
  rowBorderless: {
    display: "flex",
    flexDirection: "row",
    paddingBottom: 4,
  },
  header: {
    borderTop: "none",
  },
  bold: {
    fontWeight: "bold",
  },

  row1: {
    width: "10%",
  },
  row2: {
    width: "15%",
  },
  row3: {
    width: "50%",
    paddingRight: "10px",
  },
  row4: {
    width: "10%",
  },
  row5: {
    width: "15%",
  },
});

export default function ManagePoTemplate({ reportFile, userInfo }) {
  const groupedVendor = () => {
    let listVendor = [];
    let allVendorIds = [
      ...new Set(reportFile.map(({ itemVendor }) => itemVendor.id)),
    ];
    allVendorIds.forEach((id) => {
      let item = reportFile.filter((item) => item.itemVendor.id === id);

      listVendor.push(item);
    });
    return listVendor;
  };

  return (
    <PDFViewer width={"1000px"} height={"700px"}>
      <Document>
        {reportFile &&
          groupedVendor().map((item) => {
            let totalPrice = 0;
            return (
              <Page size="A4" style={styles.page}>
                {/* TITLE */}
                <View style={styles.title}>
                  <View>
                    <Text style={{ fontSize: "16px", fontWeight: "bold" }}>
                      ExxonMobil Research and Engineering
                    </Text>
                  </View>
                  <View>
                    <Text>Release No: </Text>
                    <Text>Date: {new Date().toLocaleString("en-US")}</Text>
                  </View>
                </View>
                {/* VENDOR AND CONTACT */}
                <View style={styles.vendor}>
                  <View style={styles.box}>
                    <Text>Vendor:</Text>
                    <View>
                      <Text style={styles.textPadding}>
                        {item[0].itemVendor.vendorName}
                      </Text>
                      <Text style={styles.textPadding}>
                        {item[0].itemVendor.addressLine1}
                      </Text>
                      <Text style={styles.textPadding}>
                        {item[0].itemVendor.addressLine2}
                      </Text>
                      <Text style={styles.textPadding}>
                        {item[0].itemVendor.city}, {item[0].itemVendor.state}{" "}
                        {item[0].itemVendor.zipCode}
                      </Text>
                    </View>
                  </View>
                  <View style={styles.box}>
                    <Text>Contact:</Text>
                    <View>
                      <Text style={styles.textPadding}>
                        Name: {item[0].itemVendor.contactName}
                      </Text>
                      <Text style={styles.textPadding}>
                        Phone: {item[0].itemVendor.phoneNumber}
                      </Text>
                      <Text style={styles.textPadding}>
                        Fax: {item[0].itemVendor.contactName}
                      </Text>
                    </View>
                  </View>
                </View>
                {/* SHIP INFORMATION */}
                <View style={styles.shipping}>
                  <View style={styles.box}>
                    <Text>Ship To:</Text>
                    <View>
                      <Text style={styles.textPadding}>
                        {`${userInfo ? userInfo?.givenName : '' } ${userInfo ?  userInfo?.surname : 'StockRoom Dept.'} (${userInfo?.businessPhones ? userInfo?.businessPhones?.join(', ') : 'N/A'})`}
                      </Text>
                      <Text style={styles.textPadding}>
                        ExxonMobil Research and Engineering
                      </Text>
                      <Text style={styles.textPadding}>1545 Route 22 East</Text>
                      <Text style={styles.textPadding}>
                        Annandale, NJ 08801
                      </Text>
                      <Text style={styles.textPadding}></Text>
                    </View>
                  </View>
                  <View style={{ borderLeft: "1px solid black" }}></View>
                  <View style={styles.box}>
                    <Text style={{ padding: "5px" }}>
                      The above Release No. must appear on all invoices,
                      packages, packing lists, shipping papers, correspondence
                      and bills of lading. Include packing list in each package
                      giving contents. If F.O.B shipping point - Ship prepaid
                      and include Freight charges with your invoice for the
                      material.
                    </Text>
                  </View>
                </View>
                {/* LIST ITEMS */}
                <View style={styles.table}>
                  <View style={[styles.row, styles.bold, styles.header]}>
                    <Text style={styles.row1}>Item #</Text>
                    <Text style={styles.row2}>Qty</Text>
                    <Text style={styles.row3}>Description</Text>
                    <Text style={styles.row4}>Unit Price</Text>
                    <Text style={styles.row5}>Extended Price</Text>
                  </View>
                  <View>
                    {item.map((unit, index) => {
                      let currentPrice =
                        unit.reorderQuantity * unit.reorderPriceUSD;
                      totalPrice += currentPrice;
                      return (
                        <View key={index}>
                          <View style={styles.row}>
                            <Text style={styles.row1}>{index}</Text>
                            <Text style={styles.row2}>
                              {unit.reorderQuantity}
                            </Text>
                            <Text style={styles.row3}>{unit.description}</Text>
                            <Text style={styles.row4}>
                              {unit.reorderPriceUSD.toFixed(2)}
                            </Text>
                            <Text style={styles.row5}>
                              {currentPrice.toFixed(2)}
                            </Text>
                          </View>
                          <View style={styles.rowBorderless}>
                            <View style={{ width: "25%" }}></View>
                            <View style={{ width: "40%" }}>
                              <Text>{unit.vendorStockNumber}</Text>
                            </View>
                          </View>
                          <View style={styles.rowBorderless}>
                            <View style={{ width: "25%" }}></View>
                            <View style={{ width: "20%" }}>
                              <Text>{unit.siteAssetNumber}</Text>
                            </View>
                            <View style={{ width: "20%" }}>
                              <Text>{unit.subLocation}</Text>
                            </View>
                          </View>
                        </View>
                      );
                    })}
                  </View>
                  <View
                    style={[
                      styles.rowBorderless,
                      { paddingTop: "10px", borderTop: "1px solid gray" },
                    ]}
                  >
                    <View style={{ width: "25%" }}></View>
                    <View style={{ width: "60%", fontWeight: "bold" }}>
                      <Text>Subtotal This PO/Release for Account: </Text>
                    </View>
                    <View style={{ width: "15%", fontWeight: "bold" }}>
                      <Text>{totalPrice.toFixed(2)}</Text>
                    </View>
                  </View>
                  <View style={[styles.rowBorderless, { paddingTop: "10px" }]}>
                    <View style={{ width: "25%" }}></View>
                    <View style={{ width: "60%", fontWeight: "bold" }}>
                      <Text>Total This PO/Release</Text>
                    </View>
                    <View style={{ width: "15%", fontWeight: "bold" }}>
                      <Text>{totalPrice.toFixed(2)}</Text>
                    </View>
                  </View>
                  <View style={styles.rowBorderless}>
                    <View style={{ width: "25%" }}></View>
                    <View style={{ width: "20%" }}></View>
                    <View style={{ width: "20%" }}>
                      <Text>Pay By: {item[0].itemVendor.blanketPO}</Text>
                    </View>
                  </View>
                </View>
              </Page>
            );
          })}
      </Document>
    </PDFViewer>
  );
}
