import API from "..";

export default class ChemIDCategory {
  /**
   * The ChemIDCategory dimension table for M3
   * @param TODO
   */
  constructor({
    chemIDCategoryName,
    isActive,
    isInternalSource,
    categoryDescription
  }) {
    this.chemIDCategoryName = chemIDCategoryName;
    this.isActive = isActive;
    this.isInternalSource = isInternalSource;
    this.categoryDescription = categoryDescription;
  }

  static async get(catName = "") {
    const api = await API();
    const { data } = await api.get(`/chemidcategory?catName=${catName}`);
    return data.result.map((d) => new ChemIDCategory(d));
  }
}