import React, { useState, useEffect, useContext } from "react";
import UserContext from "../../../context/UserContext";
import {useLocation} from 'react-router-dom';
import { Button, Divider, Drawer, Toolbar,Box, CircularProgress} from "@mui/material";
import StudyInformation from "./StudyInformation";
import BlendsAndBatches from "./BlendsAndBatches";
import StudySummary from "./StudySummary";
import BlendStudy from "../../../api/Formulations/BlendStudy";
import BlendRequest from "../../../api/Formulations/BlendRequest";
import ModalSimpleButton from "../../../components/Modal/ModalSimpleButton";
//import ModalTwoButtons from "../../../components/Modal/ModalTwoButtons";
import ModalNoButton from "../../../components/Modal/ModalNoButton";
import { GlobalButton, GlobalSecondaryButton } from "../../../pages/styles";
//import Draft from "../../../api/LIMS/Draft";
//import Template from "../../../api/LIMS/Template";
//import { isNumber, EvaluateOptionalNumberField, RoundNumber, hasRole, Roles, ConvertUOMs, GetRequiredSampleSize, GetRequiredSampleSizeUoM} from "../../../global";
import { RoundNumber, hasRole, Roles, ConvertUOMs, GetRequiredSampleSize, GetRequiredSampleSizeUoM, isNumber, EvaluateRequiredIntegerField, EvaluateOptionalNumberField} from "../../../global";
import UnitOfMeasure from "../../../api/Admin/UnitOfMeasure";

const drawerWidth = 250
const navFontSize = '14px'

const headerTextStudy = 'Create Blend Study'
const instructionTextStudy = 'To create a new study, add the study information, start your blends and select your batches; then choose your test methods, if required by your study.'

//const headerTextBlends = 'Add Blends, Batches and Tests'
const instructionTextBlends = 'To Prepare: Enter Blend information, select your components, add your batches and optionally add tests.'

const headerTextReview = 'Blend Study Details'
const instructionTextReview = ''

const errorsModalTitle = "Errors Detected"

const defaultBatch = {
  id: 0,
  blendID: 0,
  blendRequest: null,
  blendFacilityName: null,
  blendFacility: null,
  blendPriorityName: null,
  blendPriority: {blendPriorityName: 'Normal', isActive: true},
  priorityReason: null,
  batchComments: null,
  requestedCompletionDate: null,
  gpaqsNumber: null,
  actualAmount: null,
  preparedByEmail: null,
  preparedDate: null,
  lastModifiedByEmail: null,
  lastModifiedDate: null,
  createdByEmail: null,
  createdDate: null,
  destination: 'Internal',
  requestedAmount: null,
  requiresBlindCode: false,
  shippingInfo: null,
  preparedSampleName: null,
  requestedAmountUoM: null,
  unitOfMeasureRequested: null,
  actualAmountUoM: null,
  unitOfMeasureActual: null,
  batchStatusName: "Draft",
  batchStatus: null,
  batchSequenceNumber: 1,
  batchPrecision: 2,
  statusReason: null,
  batchRetainLocationName: null,
  batchRetainLocation: null,
  //  batchRetainLocation: {siteName: "Clinton Technical Center", locationName: "DISCARD", isActive: true, canContainSamples: true},
  batchCertificationStatusName: null,
  batchCertificationStatus: null,
  lastCertifiedByEmail: null,
  lastCertifiedDate: null, 
  shelfLifeStartDate: null, 
  shelfLifeEndDate: null,
  waiverReason: null,
  retainReason: null,
  batchPurpose: null,
  shippingAddressID: null,
  batchAttachments: [],
  analyticalTesting: null,
  analyticalTestingObject: [],
  analyticalTestingOwnerEmail: null,
  batchProductGroupName: null, 
  batchProductGroup: null,
  isISOBlend: false,
  isMetalsFree: false,
  chargeCode: null,
  cmaOilCode: null,
  reference: null,
  safetyInformation: null,
  proMISRCode: null,
  description: null,
  batchAppearanceName: null, 
  freeWater: null,
  appearanceCommments: null

}

const defaultBlendComponent = {
  id: 0,
  blendID: 0,
  blendRequest: null,
  targetAmountUpper: null, //this is a place holder for when we implement upper and lower blending entries
  targetAmountLower: null,
  addOrder: null,
  isBalance: false,
  preferredSampleName: null,
  preferredSample: null,
  secondaryPreferredSampleName: null,
  secondaryPreferredSample: null,
  substanceName: null,
  substance: null,
  componentInstructions: null,
  blendAdditionTemperatureC: null,
  componentUoMName: null,
  componentUoM: {uoMName: 'wt%', type: 'weight', metricStandardUoMName: null, metricStandardConversion: null},
  //componentUoM: null
}

const defaultBlendRequest = {
  id: 0,
  blendStatusName: null,
  blendStatus: null,
  // blendProductGroupName: null,
  // blendProductGroup: null,
  blendStudyID: 0,
  blendStudy: null,
  //description: null,
  blendPurpose: null,
  keywords: null,
  //isISOBlend: false,
  createdByEmail: null,
  createdDate: null,
  lastModifiedByEmail: null,
  lastModifiedDate: null,
  //isMetalsFree: false,
  // chargeCode: null,
  // cmaOilCode: null,
  psimsID: null,
  // reference: null,
  // warning: null,
  blendSubstanceName: null,
  blendName: 'A',
  blendComponents: [defaultBlendComponent],
  batches: [defaultBatch],
  //proMISRCode: null,
  blendComments: null,
  requestUoMName: null,
  requestUoM: {uoMName: 'wt%', type: 'weight', metricStandardUoMName: null, metricStandardConversion: null},
  // blendAttachments: []
}

const defaultBlendStudy = 
 {
  id: 0,
  studyName: null,
  studyPurpose: null, 
  createdDate: null,
  createdByEmail: null,
  lastModifiedDate: null, 
  lastModifiedByEmail: null, 
  projectID: null, 
  project: null, 
  ownerEmail: null, 
  keywords: null, 
  blendStudyStatusName: null,
  blendStudyStatus: null,
  blendStudyTypeName: null, 
  blendStudyType: null, 
  ppmReference: null, 
  notes: null, 
  blendRequests: [defaultBlendRequest]
 };

 //set inital error value to Null if required field
 //set intial error value to false if optional field but has validation
const defaultBatchErrorCheck = 
{
  _blendLetter: 'A',
  _batchNumber: 1,
  requestedAmount: null,
  batchPrecision: false,
  unitOfMeasureRequested: null,
  batchRetainLocation: null,
  //  batchRetainLocation: false,
  destination: false,
  blendPriority: false,
  priorityReason: false,
  blendFacility: null,
  chargeCode: null,
  batchProductGroup: null,
  description: null,
  analyticalTestingOwnerEmail: false
} 

const defaultBlendComponentErrorCheck =
{
  substance: null,
  targetAmountUpper: false, //place holder for when we implement upper and lower limits
  targetAmountLower: null,
  componentUoM: false,
  //  componentUoM: null,
  blendAdditionTemperatureC: false,
  preferredSample: false,
  secondaryPreferredSample: false,
}

const defaultBlendRequestErrorCheck =
{
  //description: null,
  // chargeCode: null,
  // blendProductGroup: null,
  _blendLetter: 'A',
  requestUoM: false,
  blendComponents:[defaultBlendComponentErrorCheck],
  batches: [defaultBatchErrorCheck]
}

const defaultErrorChecksBlendStudy = 
{
  blendStudy:
  {
    studyName: null,
    project: false, 
    ownerEmail: null, 
    blendStudyType: null
  }, 
  blendRequests:[defaultBlendRequestErrorCheck],
};

const BlendStudyManagement = ({ ...props }) => {
  //const history = useHistory()
  const currentUser = useContext(UserContext)
  const roles = currentUser?.idTokenClaims.roles;

  const hasBlendMaintainer = hasRole(Roles.BlendMaintainer, roles) || hasRole(Roles.Developer, roles)

  const location = useLocation()
  const [incomingStudyID, setIncomingStudyID] = useState(location.state && location.state.incomingStudyID ? location.state.incomingStudyID : -1)
  //const isDupeStudy = location.state && location.state.isDupeStudy ? location.state.isDupeStudy : false
  //const [draftObj, setDraftObj] = useState(location.state && location.state.draftObject ? location.state.draftObject : null)
  //const [myTemplateObj, setMyTemplateObj] = useState(location.state && location.state.templateObject ? location.state.templateObject : null)
  //const incomingStudyObj = location.state && location.state.incomingStudyObj ? location.state.incomingStudyObj : null

  const [headerText, setHeaderText] = useState('')
  const [instructionText, setInstructionText] = useState('')

 //used to determine which screen in blend process to render
  const [displayScreenValue, setDisplayScreenValue] = useState(0);
  const [navBarTextColor, setNavBarTextColor] = useState(["#1460A6","#666666", "#666666"])
  
  const [myBlendStudy, setMyBlendStudy] = useState(defaultBlendStudy);
  //const [myBlendStudy, setMyBlendStudy] = useState(incomingStudyObj !== null ? formatIncomingStudyObject(incomingStudyObj) : defaultBlendStudy);

  const [errorChecks, setErrorChecks] = useState(defaultErrorChecksBlendStudy);

  //const [isStudySubmitting, setIsStudySubmitting] = useState(false)
  //const [isSubmitSuccessful, setIsSubmitSuccessful] = useState(false)

  const [isSaveChanges, setIsSaveChanges] = useState(false)
  //const [isSaveChangesSuccessful, setIsSaveChangesSuccessful] = useState(false)

  const [modalMessagesOpen, setModalMessagesOpen] = useState(false);
  const modalMessagesButtonText = 'Ok'
  const [modalMessagesTitle, setModalMessagesTitle] = useState('');
  const [modalMessagesText, setModalMessagesText] = useState('');

  const [modalNoButtonOpen, setModalNoButtonOpen] = useState(false);
  const [modalNoButtonTitle, setModalNoButtonTitle] = useState('');
  const [modalNoButtonText, setModalNoButtonText] = useState('');

  const [blendPageHeader, setBlendPageHeader] = useState('Add Blends, Batches and Tests')
  // const [modalDraftsOpen, setModalDraftsOpen] = useState(false);
  // const [modalDraftsButton1Text, setModalDraftsButton1Text] = useState('');
  // const [modalDraftsButton2Text, setModalDraftsButton2Text] = useState('');
  // const [modalDraftsTitle, setModalDraftsTitle] = useState('');
  // const [modalDraftsText, setModalDraftsText] = useState('');

  // const [modalInventoryCheckOpen, setModalInventoryCheckOpen] = useState(false);
  // const [modalInventoryCheckButton1Text, setModalInventoryCheckButton1Text] = useState('');
  // const [modalInventoryCheckButton2Text, setModalInventoryCheckButton2Text] = useState('');
  // const [modalInventoryCheckTitle, setModalInventoryCheckTitle] = useState('');
  // const [modalInventoryCheckText, setModalInventoryCheckText] = useState('');

  // const [modalTemplatesOpen, setModalTemplatesOpen] = useState(false);
  // const [modalTemplatesButton1Text, setModalTemplatesButton1Text] = useState('');
  // const [modalTemplatesButton2Text, setModalTemplatesButton2Text] = useState('');
  // const [modalTemplatesTitle, setModalTemplatesTitle] = useState('');
  // const [modalTemplatesText, setModalTemplatesText] = useState('');

  //const [draftSaveName, setDraftSaveName] = useState('')
  //const [draftButtonText, setDraftButtonText] = useState(draftObj === null ? 'Save as Draft' : 'Update Draft')
  //const [isDraftSaving, setIsDraftSaving] = useState(false)

  // const [templateSaveName, setTemplateSaveName] = useState(myTemplateObj ? myTemplateObj.templateName : '')
  // const [isPublicChecked, setIsPublicChecked] = useState(false)
  // const [isTemplateSaving, setIsTemplateSaving] = useState(false)
  // const allowTemplateUpdates = myTemplateObj === null || currentUser.username !== myTemplateObj?.templateOwnerEmail ? false : true

  const [availableUOMs, setAvailableUOMs] = useState([])
  const [errorLoadingBlends, setErrorLoadingBlends] = useState(null)

  const [reloadData, setReloadData] = useState(false)

  // function formatIncomingStudyObject (study) {

  //   for(var i = 0; i <  study.blendRequests.length; i++) 
  //   {
  //     for(var x = 0; x <  study.blendRequests[i].batches.length; x++) 
  //     {
  //       //populate the testing object for each blend request
  //       if (study.blendRequests[i].batches[x].analyticalTestingObject === undefined || study.blendRequests[i].batches[x].analyticalTestingObject === null)
  //       {
  //         study.blendRequests[i].batches[x].analyticalTestingObject = study.blendRequests[i].batches[x].analyticalTesting === null || study.blendRequests[i].batches[x].analyticalTesting === '' ? [] : JSON.parse(study.blendRequests[i].batches[x].analyticalTesting)
  //       }
  //     }
  //   }

  //   return study;
  //   // const myReturn = convertDraftAttachmentsToFiles(study);

  //   // return myReturn;
  // }
  
  function closeModalMessages() {
    setModalMessagesOpen(false);

    // if (isSubmitSuccessful === true && isSaveChangesSuccessful === false){
    //   history.push('/blends')
    // }
  }

  function openModalMessages(title, text) {
    setModalMessagesOpen(true);
    setModalMessagesTitle(title);
    setModalMessagesText(text);
  }

  function closeModalNoButton() {
    setModalNoButtonOpen(false);
  }

  function openModalNoButton(title, text) {
    setModalNoButtonOpen(true);
    setModalNoButtonTitle(title);
    setModalNoButtonText(text);
  }

  // function closeModalDrafts() {
  //   setModalDraftsOpen(false);
  // }

  // function openModalDrafts(title, text, buttonText, button2Text) {
  //   setModalDraftsButton1Text(buttonText)
  //   setModalDraftsButton2Text(button2Text)
  //   setModalDraftsOpen(true);
  //   setModalDraftsTitle(title);
  //   setModalDraftsText(text);
  // }

  // function closeModalInventoryCheck() {
  //   setModalInventoryCheckOpen(false);
  // }

  // function openModalInventoryCheck(title, text, buttonText, button2Text) {
  //   setModalInventoryCheckButton1Text(buttonText)
  //   setModalInventoryCheckButton2Text(button2Text)
  //   setModalInventoryCheckOpen(true);
  //   setModalInventoryCheckTitle(title);
  //   setModalInventoryCheckText(text);
  // }

  // function closeModalTemplate() {
  //   setModalTemplatesOpen(false);
  // }

  // function openModalTemplate(title, text, buttonText, button2Text) {
  //   setModalTemplatesButton1Text(buttonText)
  //   setModalTemplatesButton2Text(button2Text)
  //   setModalTemplatesOpen(true);
  //   setModalTemplatesTitle(title);
  //   setModalTemplatesText(text);
  // }

  async function handleNavagation(newValue) {

    let intNewValue = parseInt(newValue.currentTarget.value, 10)

    //study info page
    switch (intNewValue){
      case 0:{

        setDisplayScreenValue(intNewValue)

        return(
        setNavBarTextColor(["#1460A6","#666666", "#666666"])
        );
      }

      //blends and batches page
      case 1:{

      if (!(CheckStudyInfoForErrors()))
      {
        setDisplayScreenValue(intNewValue)

        return(
          setNavBarTextColor(["#1460A6","#1460A6", "#666666"])
          );
      } else {
        return null
      }
      
      }

      //Review Study
      case 2:{

        // if (!(CheckStudyInfoForErrors()) && !(CheckBlendsAndBatchesForErrors()))
        if (!(CheckStudyInfoForErrors()))
        {
          setDisplayScreenValue(intNewValue)

          return(
            setNavBarTextColor(["#1460A6","#1460A6", "#1460A6"])
          );

          // var inventoryAlerts = await CheckComponentInventory()

          // if (inventoryAlerts.length > 0)
          // {
          //   //ask them if they want to continue
          //   openModalInventoryCheck("Confirm Inventory", inventoryAlerts, "Yes", "No")

          //   return null
          // } else {
          //   setDisplayScreenValue(intNewValue)

          //   return(
          //     setNavBarTextColor(["#1460A6","#1460A6", "#1460A6"])
          //   );
          // }  

        } else {
          return null
        }
      }
      
      default: {
        alert(displayScreenValue);
      }
  }
  };

  // function ContinueWithBlend() {
  //   setDisplayScreenValue(2)  
  //   setNavBarTextColor(["#1460A6","#1460A6", "#1460A6"])  
  //   closeModalInventoryCheck()
  // }
  
  function CheckStudyInfoForErrors(){
    let hasErrors = []

    if (errorChecks.blendStudy.studyName === true || errorChecks.blendStudy.studyName === null)
    {
        hasErrors.push("A blend study name must be entered.")
    }

    if (errorChecks.blendStudy.blendStudyType === true || errorChecks.blendStudy.blendStudyType === null)
    {
        hasErrors.push('Blend study type is required.') 

    }
    if (errorChecks.blendStudy.project === true || errorChecks.blendStudy.project === null)
    {
      hasErrors.push("A blend study project must be selected.")
    }

    if (errorChecks.blendStudy.ownerEmail === true || errorChecks.blendStudy.ownerEmail === null)
    {
      hasErrors.push("Blend study owner is required or is invalid.") 
    }

    if (hasErrors.length === 0) {
      return false
    } else {
      openModalMessages(errorsModalTitle, hasErrors, "Ok")
      return true
    }
  }

  // function CheckBlendsAndBatchesForErrors(){
  //   let hasErrors = []

  //   for(var i = 0; i <  myBlendStudy.blendRequests.length; i++) 
  //   {
  //     for(var x = 0; x <  myBlendStudy.blendRequests[i].batches.length; x++) 
  //     {
  //       if ((myBlendStudy.blendRequests[i].batches[x].analyticalTestingObject === null || myBlendStudy.blendRequests[i].batches[x].analyticalTestingObject.length < 1) 
  //       && (myBlendStudy.blendRequests[i].batches[x].batchStatusName === null || myBlendStudy.blendRequests[i].batches[x].batchStatusName === 'Submitted' || myBlendStudy.blendRequests[i].batches[x].batchStatusName === 'Needs Review'))
  //       {
  //         if(myBlendStudy.blendRequests[i].batches[x].batchRetainLocation && myBlendStudy.blendRequests[i].batches[x].batchRetainLocation.locationName === 'DISCARD')
  //         {
  //           hasErrors.push(`A batch has retains set to DISCARD and no analytical testing assigned...meaning no work needs to be done on  Batch ${myBlendStudy.blendRequests[i].batches[x].batchSequenceNumber} on Blend Request ${myBlendStudy.blendRequests[i].blendName}.`)            
  //         }
  //       } else {
  //         const masterUoM = myBlendStudy.blendRequests[i].batches[x].unitOfMeasureRequested
  //         let totalNeededForTesting = GetTotalNeededForTesting(i, x, masterUoM)

  //         if(isNumber(myBlendStudy.blendRequests[i].batches[x].requestedAmount) && myBlendStudy.blendRequests[i].batches[x].unitOfMeasureRequested)
  //         {
  //           if(totalNeededForTesting > Number(myBlendStudy.blendRequests[i].batches[x].requestedAmount))
  //           {
  //             hasErrors.push(`The amount needed for testing (${totalNeededForTesting}${masterUoM.uoMName}) on Blend Request ${myBlendStudy.blendRequests[i].blendName} exceeds the requsted amount ${myBlendStudy.blendRequests[i].batches[x].requestedAmount}${masterUoM.uoMName} on Batch ${myBlendStudy.blendRequests[i].batches[x].batchSequenceNumber}.`)              
  //           }
  //         } 
  //       }
  //     }
  //   }

  //   for(var iRequest = 0; iRequest <  errorChecks.blendRequests.length; iRequest++) 
  //   {
  //     let requestUoM = myBlendStudy.blendRequests[iRequest].requestUoM

  //     if (errorChecks.blendRequests[iRequest].blendProductGroup === true || errorChecks.blendRequests[iRequest].blendProductGroup === null)
  //     {
  //         hasErrors.push(`A blend request product group is required on Blend Request ${myBlendStudy.blendRequests[iRequest].blendName}.`)
  //     }

  //     if (errorChecks.blendRequests[iRequest].requestUoM === true || errorChecks.blendRequests[iRequest].requestUoM === null)
  //     {
  //         hasErrors.push(`A blend request measurment type is required on Blend Request ${myBlendStudy.blendRequests[iRequest].blendName}.`)
  //     }

  //     if (errorChecks.blendRequests[iRequest].description === true || errorChecks.blendRequests[iRequest].description === null)
  //     {
  //         hasErrors.push(`A blend request description is required on Blend Request ${myBlendStudy.blendRequests[iRequest].blendName}.`)
  //     }

  //     if (errorChecks.blendRequests[iRequest].chargeCode === true || errorChecks.blendRequests[iRequest].chargeCode === null)
  //     {
  //         hasErrors.push(`A blend request cost code is required on Blend Request ${myBlendStudy.blendRequests[iRequest].blendName}.`)
  //     }

  //     let targetSum = 0

  //       for(var iComponent = 0; iComponent <  errorChecks.blendRequests[iRequest].blendComponents.length; iComponent++) 
  //       {
  //         let componentUoM = myBlendStudy.blendRequests[iRequest].blendComponents[iComponent].componentUoM

  //         if (myBlendStudy.blendRequests[iRequest].blendStatusName === "Complete" || myBlendStudy.blendRequests[iRequest].blendStudyStatusName === "Cancelled")
  //         {
  //           targetSum = 100;
            
  //         } else {
  //           if ((requestUoM !== null && componentUoM !== null) && requestUoM.uoMName === componentUoM.uoMName)
  //           {
  //             if (isNumber(myBlendStudy.blendRequests[iRequest].blendComponents[iComponent].targetAmountLower))
  //             {
  //               targetSum = targetSum + Number(myBlendStudy.blendRequests[iRequest].blendComponents[iComponent].targetAmountLower).toFixed(3)
  //             }
  //           }
  //         }
        
  //         if (errorChecks.blendRequests[iRequest].blendComponents[iComponent].substance === true || errorChecks.blendRequests[iRequest].blendComponents[iComponent].substance === null)
  //         {
  //           hasErrors.push(`A ChemID is required for all components on Blend Request ${myBlendStudy.blendRequests[iRequest].blendName}.`)
  //         }

  //         if ((errorChecks.blendRequests[iRequest].blendComponents[iComponent].targetAmountLower === true || errorChecks.blendRequests[iRequest].blendComponents[iComponent].targetAmountLower === null))
  //         {
  //           hasErrors.push(`A lower amt. % is missing/invalid on Blend Request ${myBlendStudy.blendRequests[iRequest].blendName}.`)
  //         }

  //         // if ((errorChecks.blendRequests[iRequest].blendComponents[iComponent].targetAmountUpper === true || errorChecks.blendRequests[iRequest].blendComponents[iComponent].targetAmountUpper === null))
  //         // {
  //         //   hasErrors.push(`An upper amt. % is missing/invalid on Blend Request ${myBlendStudy.blendRequests[iRequest].blendName}.`)
  //         // }

  //         if (errorChecks.blendRequests[iRequest].blendComponents[iComponent].blendAdditionTemperatureC === true || errorChecks.blendRequests[iRequest].blendComponents[iComponent].blendAdditionTemperatureC === null)
  //         {
  //           hasErrors.push(`A component addition temp is invalid on Blend Request ${myBlendStudy.blendRequests[iRequest].blendName}.`)
  //         }

  //         if (errorChecks.blendRequests[iRequest].blendComponents[iComponent].preferredSample === true || errorChecks.blendRequests[iRequest].blendComponents[iComponent].preferredSample === null)
  //         {
  //           hasErrors.push(`A component preferred MIDAS # is invalid on Blend Request ${myBlendStudy.blendRequests[iRequest].blendName}.`)
  //         }

  //         if (errorChecks.blendRequests[iRequest].blendComponents[iComponent].secondaryPreferredSample === true || errorChecks.blendRequests[iRequest].blendComponents[iComponent].secondaryPreferredSample === null)
  //         {
  //           hasErrors.push(`A component secondary preferred MIDAS # is invalid on Blend Request ${myBlendStudy.blendRequests[iRequest].blendName}.`)
  //         }

  //         if (errorChecks.blendRequests[iRequest].blendComponents[iComponent].componentUoM === true || errorChecks.blendRequests[iRequest].blendComponents[iComponent].componentUoM === null)
  //         {
  //           hasErrors.push(`A component is missing a unit of measure on Blend Request ${myBlendStudy.blendRequests[iRequest].blendName}.`)
  //         }
  //       }

  //       for(var iBatch = 0; iBatch <  errorChecks.blendRequests[iRequest].batches.length; iBatch++) 
  //       {
  //         if (errorChecks.blendRequests[iRequest].batches[iBatch].requestedAmount === true || errorChecks.blendRequests[iRequest].batches[iBatch].requestedAmount === null)
  //         {
  //           hasErrors.push(`A target amount is required or invalid for Batch ${myBlendStudy.blendRequests[iRequest].batches[iBatch].batchSequenceNumber} on Blend Request ${myBlendStudy.blendRequests[iRequest].blendName}.`)
  //         }
          
  //         if (errorChecks.blendRequests[iRequest].batches[iBatch].batchPrecision === true || errorChecks.blendRequests[iRequest].batches[iBatch].batchPrecision === null)
  //         {
  //           hasErrors.push(`The batch precision is required/invalid for Batch ${myBlendStudy.blendRequests[iRequest].batches[iBatch].batchSequenceNumber} on Blend Request ${myBlendStudy.blendRequests[iRequest].blendName}.`)
  //         } 

  //         if (errorChecks.blendRequests[iRequest].batches[iBatch].unitOfMeasureRequested === true || errorChecks.blendRequests[iRequest].batches[iBatch].unitOfMeasureRequested === null)
  //         {
  //           hasErrors.push(`The batch unit of measure is required for Batch ${myBlendStudy.blendRequests[iRequest].batches[iBatch].batchSequenceNumber} on Blend Request ${myBlendStudy.blendRequests[iRequest].blendName}.`)
  //         } 

  //         if (errorChecks.blendRequests[iRequest].batches[iBatch].batchRetainLocation === true || errorChecks.blendRequests[iRequest].batches[iBatch].batchRetainLocation === null)
  //         {
  //           hasErrors.push(`The batch retain location is required for Batch ${myBlendStudy.blendRequests[iRequest].batches[iBatch].batchSequenceNumber} on Blend Request ${myBlendStudy.blendRequests[iRequest].blendName}.`)
  //         } 

  //         if (errorChecks.blendRequests[iRequest].batches[iBatch].blendPriority === true || errorChecks.blendRequests[iRequest].batches[iBatch].blendPriority === null)
  //         {
  //           hasErrors.push(`The batch priority is required for Batch ${myBlendStudy.blendRequests[iRequest].batches[iBatch].batchSequenceNumber} on Blend Request ${myBlendStudy.blendRequests[iRequest].blendName}.`)
  //         } 

  //         if (errorChecks.blendRequests[iRequest].batches[iBatch].priorityReason === true || errorChecks.blendRequests[iRequest].batches[iBatch].priorityReason === null)
  //         {
  //           hasErrors.push(`The batch priority reason is required for Batch ${myBlendStudy.blendRequests[iRequest].batches[iBatch].batchSequenceNumber} on Blend Request ${myBlendStudy.blendRequests[iRequest].blendName}.`)
  //         } 

  //         if (errorChecks.blendRequests[iRequest].batches[iBatch].destination === true || errorChecks.blendRequests[iRequest].batches[iBatch].destination === null)
  //         {
  //           hasErrors.push(`The batch destination is required for Batch ${myBlendStudy.blendRequests[iRequest].batches[iBatch].batchSequenceNumber} on Blend Request ${myBlendStudy.blendRequests[iRequest].blendName}.`)
  //         } 

  //         if (errorChecks.blendRequests[iRequest].batches[iBatch].blendFacility === true || errorChecks.blendRequests[iRequest].batches[iBatch].blendFacility === null)
  //         {
  //           hasErrors.push(`The batch blend facility is required for Batch ${myBlendStudy.blendRequests[iRequest].batches[iBatch].batchSequenceNumber} on Blend Request ${myBlendStudy.blendRequests[iRequest].blendName}.`)
  //         } 
  //       }

  //     if (targetSum < 100)
  //     {
  //       hasErrors.push(`The target amounts total of ${targetSum}% is less than 100% on Blend Request ${myBlendStudy.blendRequests[iRequest].blendName}.`)
  //     }

  //   }
    
  //   //if all checks were passed add it to the collection to register
  //   if (hasErrors.length === 0) {
  //     return false

  //   } else {
  //     openModalMessages(errorsModalTitle, hasErrors, "Ok")
  //     return true
  //   }
  // }

  // async function CheckComponentInventory() {
  //   return new Promise((value) => {

  //   let hasErrors = []

  //   let arrayBlendRequestsToCheck = []

  //   myBlendStudy.blendRequests.forEach(oBlendRequest => {

  //     if(oBlendRequest.batches.filter(obj => obj.batchStatusName === 'Submitted' || obj.batchStatusName === null || obj.batchStatusName ==='').length > 0)
  //     {
  //       arrayBlendRequestsToCheck.push(structuredClone(oBlendRequest))  
  //     }
  //   });

  //   openModalNoButton("Inventory Check", "Checking component inventory at the site, be patient.")

  //   BlendRequest.CheckBlendComponentSiteVolumes(arrayBlendRequestsToCheck).then((res) => {

  //     closeModalNoButton()

  //     if (res.message === 'Success') 
  //     {
  //       if (res.result !== null && res.result.length > 0)
  //       {
  //         res.result.forEach(oMessage => {
  //           hasErrors.push(oMessage)              
  //         });

  //         value(hasErrors)

  //       } else {
  //         value(hasErrors)
  //       }

  //     } else {
  //       openModalMessages("Error Checking Components", `There was an error checking the container inventory for the components.  ${res.message ? res.message : ''}`)
  //       value(hasErrors)
  //     }
  //   }) 
  // })
  // }

  function GetTotalNeededForTesting (blendRequestIndex, batchIndex, uomObject) {

    if (myBlendStudy.blendRequests[blendRequestIndex].batches[batchIndex].analyticalTestingObject === null || myBlendStudy.blendRequests[blendRequestIndex].batches[batchIndex].analyticalTestingObject.length < 1)
    {
      return 'N/A'

    } else {
      // for(var xBatch = 0; xBatch <  myBlendStudy.blendRequests[blendRequestIndex].batches.length; xBatch++)
      // {
        let totalNeededForTesting = 0

        myBlendStudy.blendRequests[blendRequestIndex].batches[batchIndex].analyticalTestingObject.forEach(oTest => {
          const testUoM = availableUOMs.find(obj => obj.uoMName === GetRequiredSampleSizeUoM(oTest))

          if (testUoM)
          {
            totalNeededForTesting += RoundNumber(ConvertUOMs(null, null, oTest.method.isPackageTest ? oTest.method.packageTestRequiredAmount :  GetRequiredSampleSize(oTest), testUoM, uomObject), 2)
          } else {
            return  `Test ${oTest.methodFacility.methodName}/${oTest.methodFacility.testFacilityAbv} appears to be missing a required sample size UoM`
          }          
        });
        
        return totalNeededForTesting;
      //}
    } 
  }

  function PreparePayloadToSaveChanges()
  {
    let copyStudy = structuredClone(myBlendStudy)

    //only included edit BlendRequests and Blend
    if (copyStudy.id !== 0)
    {
      //only get blend requests that are new, have components new/edited or have batches new/edited
      copyStudy.blendRequests = structuredClone(copyStudy.blendRequests.filter(request => request.id === 0 
                                    || request.hasBeenEdited === true 
                                    || request.blendComponents.filter(component => component.id === 0).length > 0
                                    || request.batches.filter(batch => batch.id === 0 || batch.hasBeenEdited === true).length > 0))

      for(var y = 0; y <  copyStudy.blendRequests.length; y++) 
      {
        copyStudy.blendRequests[y].batches = structuredClone(copyStudy.blendRequests[y].batches.filter(batch => batch.id === 0 || batch.hasBeenEdited === true))
      }
    }

    if (copyStudy.blendRequests.length === 0)
    {
      openModalMessages('Nothing Changed', "No changes found to save!", "Ok")
      return;
    }

    if (copyStudy.projectID === null)
    {
      copyStudy.projectID = 1
    }

    for(var i = 0; i <  copyStudy.blendRequests.length; i++) 
    {
      
      if (copyStudy.blendRequests[i].id === 0)
      {
        copyStudy.blendRequests[i].blendStudyID = 0
      }

      for(var x = 0; x <  copyStudy.blendRequests[i].blendComponents.length; x++) 
      {

        if (copyStudy.blendRequests[i].blendComponents[x].id === 0)
        {
          copyStudy.blendRequests[i].blendComponents[x].blendID = 0
        }

        //set the target upper = lower until ranges are implemented
        copyStudy.blendRequests[i].blendComponents[x].targetAmountUpper = copyStudy.blendRequests[i].blendComponents[x].targetAmountLower
      }

      for(var z = 0; z <  copyStudy.blendRequests[i].batches.length; z++) 
      {
        if (copyStudy.blendRequests[i].batches[z].id === 0)
        {
          copyStudy.blendRequests[i].batches[z].blendID = 0
        }

        copyStudy.blendRequests[i].batches[z].analyticalTesting = copyStudy.blendRequests[i].batches[z].analyticalTestingObject.length === 0 ? null : JSON.stringify(copyStudy.blendRequests[i].batches[z].analyticalTestingObject)
      }
    }

    //check the blend components for the ones being saved to ensure they are complete
    let errors = CheckBlendComponents(copyStudy)

    if (errors.length > 0) 
    {
      openModalMessages(errorsModalTitle, errors, "Ok")
      return;
    };

    const formData = new FormData();

    formData.append("studyJSON", JSON.stringify(copyStudy))

    copyStudy.blendRequests.forEach(oRequest => {
      // oRequest.blendAttachments.forEach(oAttachment => {
      //   if (oAttachment.id === null || oAttachment.id < 1) {
      //     formData.append("filesToUpload", oAttachment.fileObject, oAttachment.fileName)
      //   }
      // })

      oRequest.batches.forEach(oBatch => {
        oBatch.batchAttachments.forEach(batchAttachment => {
          if (batchAttachment.id === null || batchAttachment.id < 1) {
            formData.append("filesToUpload", batchAttachment.fileObject, batchAttachment.fileName)
          }
        })
      })
    })

    SubmitBlendStudy(formData, copyStudy.studyName, false);
  }

  function SubmitBlendStudy (payloadToSubmit, studyName, isSubmit) 
  {
    if (isSubmit === true)
    {
      openModalNoButton("Submitting Batch", "Please wait while the batch is submitted.")

      //setIsStudySubmitting(true)
      //setIsSubmitSuccessful(false)

      setIsSaveChanges(false)
      //setIsSaveChangesSuccessful(false)
    } else {
      setIsSaveChanges(true)
      //setIsSaveChangesSuccessful(false)

      //setIsStudySubmitting(false)
      //setIsSubmitSuccessful(false)
    }


    // let copyStudy = structuredClone(myBlendStudy)

    // //only included edit BlendRequests and Blend
    // if (copyStudy.id === 0)
    // {
    //   copyStudy.id = 0
    // }

    // if (copyStudy.projectID === null || copyStudy.projectID === '')
    // {
    //   copyStudy.projectID = 0
    // }

    // for(var i = 0; i <  copyStudy.blendRequests.length; i++) 
    // {
      
    //   if (copyStudy.blendRequests[i].id === 0)
    //   {
    //     copyStudy.blendRequests[i].id = 0
    //     copyStudy.blendRequests[i].blendStudyID = 0
    //   }

    //   for(var x = 0; x <  copyStudy.blendRequests[i].blendComponents.length; x++) 
    //   {

    //     if (copyStudy.blendRequests[i].blendComponents[x].id === 0)
    //     {
    //       copyStudy.blendRequests[i].blendComponents[x].id = 0
    //       copyStudy.blendRequests[i].blendComponents[x].blendID = 0
    //     }

    //     //set the target upper = lower until ranges are implemented
    //     copyStudy.blendRequests[i].blendComponents[x].targetAmountUpper = copyStudy.blendRequests[i].blendComponents[x].targetAmountLower
    //   }

    //   for(var z = 0; z <  copyStudy.blendRequests[i].batches.length; z++) {
    //     if (copyStudy.blendRequests[i].batches[z].id === 0)
    //     {
    //       copyStudy.blendRequests[i].batches[z].id = 0
    //       copyStudy.blendRequests[i].batches[z].blendID = 0
    //     }

    //     copyStudy.blendRequests[i].batches[z].analyticalTesting = copyStudy.blendRequests[i].batches[z].analyticalTestingObject.length === 0 ? null :
    //     JSON.stringify(copyStudy.blendRequests[i].batches[z].analyticalTestingObject)
    //   }
    // }

    // const formData = new FormData();

    // formData.append("studyJSON", JSON.stringify(copyStudy))

    // copyStudy.blendRequests.forEach(oRequest => {
    //   oRequest.blendAttachments.forEach(oAttachment => {
    //     if (oAttachment.id === null || oAttachment.id < 1) {
    //       formData.append("filesToUpload", oAttachment.fileObject, oAttachment.fileName)
    //     }
    //   })

    //   oRequest.batches.forEach(oBatch => {
    //     oBatch.batchAttachments.forEach(batchAttachment => {
    //       if (batchAttachment.id === null || batchAttachment.id < 1) {
    //         formData.append("filesToUpload", batchAttachment.fileObject, batchAttachment.fileName)
    //       }
    //     })
    //   })
    // })

    BlendStudy.CreateBlendStudy(payloadToSubmit).then((res) => {

      // if (modalNoButtonOpen === true) 
      // {
      //   closeModalNoButton()
      // }

      closeModalNoButton()

      if (res && res.message === 'Success') 
      {

        //delete the draft, if there is one
        // if (draftObj){
        //   Draft.delete(draftObj.id).then((res) => {
        //   })
        // }

        if (isSubmit === true)
        {
          //setIsStudySaving(true)
          setIncomingStudyID(res.result.id)
          //setIsSubmitSuccessful(true)
          openModalMessages('Batch Submitted', `Batch for ${studyName} submitted successfully!`)
          setReloadData(!reloadData)
        } else {
          //setIsSaveChanges(true)
          setIncomingStudyID(res.result.id)
          //setIsSaveChangesSuccessful(true)
          openModalMessages('Blends Saved', `Blend Study ${studyName} information saved successfully!`)
          setReloadData(!reloadData)
        }

        //setIsSaveSuccessful(true)
        //openModalMessages('Registration Complete', `Blend Study ${studyName} registered successfully!`)
      } else {
        openModalMessages('Blend Study Failed to Save', ` ${res ? res.message : 'Unspecified Error'}. Contact support if you feel this is an error.`);          
      }

      if (isSubmit === false)
      {
        setIsSaveChanges(false)
      }

      //setIsStudySaving(false)
    });
  }

  // async function convertDraftAttachmentsToStrings () 
  // {
  //   //only going to do this for file that are new and havent been transmitted to Azure

  //   return {
  //     ...myBlendStudy,
  //     blendRequests: await Promise.all(
  //       myBlendStudy.blendRequests.map(async (oRequest) => {
  //         return {
  //           ...oRequest,
  //           //  blendAttachments:  oRequest.blendAttachments.length > 0 ? JSON.stringify(await Promise.all(
  //           blendAttachments:  oRequest.blendAttachments.length > 0 ? await Promise.all(
  //               oRequest.blendAttachments.filter(x => x.id < 1).map(async (file) => {
  //               return {
  //                 ...file,
  //                 base64String: await convertToBase64_Blends(file.fileObject)
  //                 //fileObject: await convertToBase64_Blends(file.fileObject)
  //               }
  //             })
  //           ) //)
  //           : [], 
  //           batches: await Promise.all(oRequest.batches.map(async (oBatch) => {
  //             return {
  //               ...oBatch, 
  //               //batchAttachments: oBatch.batchAttachments.length > 0 ? JSON.stringify(await Promise.all(
  //               batchAttachments: oBatch.batchAttachments.length > 0 ? await Promise.all(
  //                 oBatch.batchAttachments.filter(x => x.id < 1).map(async (file) => {
  //                   return {
  //                     ...file,
  //                     base64String: await convertToBase64_Blends(file.fileObject)
  //                     //fileObject: await convertToBase64(file.fileObject)
  //                   }
  //                 })
  //               )//)
  //                : []
  //             }
  //           }))
  //         }
  //       })
  //     )
  //   }
  // }

  // function convertDraftAttachmentsToFiles (study)
  // {
  //   return {
  //     ...study,
  //     blendRequests:
  //       study.blendRequests.map(oRequest => {
  //         // const oBlendAttachments = oRequest.blendAttachments && oRequest.blendAttachments.length > 0 ? JSON.parse(oRequest.blendAttachments) : [];
  //         const oBlendAttachments = oRequest.blendAttachments && oRequest.blendAttachments.length > 0 ? oRequest.blendAttachments : [];

  //         return {
  //           ...oRequest,
  //           blendAttachments: oBlendAttachments && oBlendAttachments.length > 0 ? oBlendAttachments.map(file => {
  //               return {
  //                 ...file,
  //                 //fileObject: convertBase64ToFile_Blends(file.fileObject)
  //                 fileObject: convertBase64ToFile_Blends(file.base64String, file.fileName)
  //               }
  //           }) : [],
  //           batches:  oRequest.batches.map(oBatch => {
  //             //const oBatchAttachments = oBatch.batchAttachments && oBatch.batchAttachments.length > 0 ? JSON.parse(oBatch.batchAttachments) : [];
  //             const oBatchAttachments = oBatch.batchAttachments && oBatch.batchAttachments.length > 0 ? oBatch.batchAttachments : [];
  //             return {
  //               ...oBatch,
  //               batchAttachments: oBatchAttachments && oBatchAttachments.length > 0 ? oBatchAttachments.map(file => {
  //                   return {
  //                     ...file,
  //                     //fileObject: convertBase64ToFile_Blends(file.fileObject)
  //                     fileObject: convertBase64ToFile_Blends(file.base64String, file.fileName)
  //                   }
  //               }) : []
  //             }
  //           })
  //         }
  //       })
  //   }
  // }

  // const SaveNewDraft = async (e) => {

  //   if (draftSaveName.trim() === '' && draftObj === null)
  //   {
  //     openModalMessages("Draft Name Error", "You must enter a name to save a new draft!")
  //     return
  //   }

  //   if (isDraftSaving)
  //   { 
  //       return
  //   } else {
  //       setIsDraftSaving(true)
  //   }
 
  //   const preparedDraftStudy = await convertDraftAttachmentsToStrings();

  //   if (draftObj !== null) 
  //   {
  //     const copyDraftObj = structuredClone(draftObj)

  //     copyDraftObj.draftJSON = JSON.stringify(preparedDraftStudy)

  //     Draft.update(copyDraftObj).then((res) => {
  //       if (res.message === 'Success') 
  //       {
  //         openModalMessages("Update Successful", "Draft Successfully Updated!")
  //         closeModalDrafts()
  //       } else {
  //         openModalMessages("Update Failed", `${res.message}. Contact support if you feel this is an error.`)
  //       }
  //       setIsDraftSaving(false)
  //     });

  //   } else {

  //     let newDraft = new Draft({
  //       draftOwnerEmail: null,
  //       draftName: draftSaveName,
  //       draftJSON: JSON.stringify(preparedDraftStudy),
  //       draftType: "blends",
  //       createdDate: null, 
  //       lastModifiedDate: null
  //     })
    
  //     Draft.add(newDraft).then((res) => {
  //       if (res.message === 'Success')
  //       {
  //         openModalMessages("Save Successful", "Draft Successfully Saved!")
  //         closeModalDrafts()
  //         //setDraftButtonText("Update Draft")
  //         setDraftObj(res.result)
  //       } else {
  //         openModalMessages('Draft Save Failed', `${res.message}. Contact support if you feel this is an error.`);
  //       }
  //       setIsDraftSaving(false)
  //     })
  //   }
  // }

  // const SaveTemplate = (e) => {

  //   if (templateSaveName.trim() === '')
  //   {
  //     openModalMessages("Invalid Template Name", "You must enter a template name to continue")
  //     return
  //   }
    
  //   if (isTemplateSaving)
  //   { 
  //       return
  //   } else {
  //       setIsTemplateSaving(true)
  //   }

  //   const copyCurrentStudy = structuredClone(myBlendStudy)

  //   let newTemplate = new Template({
  //     templateName: templateSaveName,
  //     templateJSON: JSON.stringify(copyCurrentStudy),
  //     templateType: "blends",
  //     templateOwnerEmail: currentUser.username,
  //     isPublic: myTemplateObj ? myTemplateObj.isPublic : isPublicChecked,
  //     createdDate: myTemplateObj ? myTemplateObj.createdDate : null,
  //     lastModifiedDate: null
  //   });

  //   Template.add(newTemplate).then((res) => {
  //     if (res.message === 'Success') 
  //     {
  //       openModalMessages("Save Successful", "Template Successfully Saved!")
  //       closeModalTemplate()
  //       setMyTemplateObj(res.result)
  //     } else {
  //       openModalMessages("Update Failed", `${res.message}. Contact support if you feel this is an error.`)
  //     }
  //     setIsTemplateSaving(false)
  //   });
  // };

  // function UpdateTemplate () {
  //   if (templateSaveName.trim() === '')
  //   {
  //     openModalMessages("Invalid Template Name", "You must enter a template name to continue")
  //     return
  //   }
    
  //   if (myTemplateObj !== null && currentUser.username !== myTemplateObj.templateOwnerEmail)
  //   {
  //     openModalMessages("Template Error", "You cannot update a template from another user!")
  //     return
  //   }

  //   if (isTemplateSaving)
  //   { 
  //       return
  //   } else {
  //       setIsTemplateSaving(true)
  //   }

  //   const copyCurrentStudy = structuredClone(myBlendStudy)

  //   let newTemplate = new Template({
  //     templateName: templateSaveName,
  //     templateJSON: JSON.stringify(copyCurrentStudy),
  //     templateType: "blends",
  //     templateOwnerEmail: currentUser.username,
  //     isPublic: myTemplateObj ? myTemplateObj.isPublic : isPublicChecked,
  //     createdDate: myTemplateObj ? myTemplateObj.createdDate : null,
  //     lastModifiedDate: null
  //   });

  //   Template.update(newTemplate).then((res) => {
  //     if (res.message === 'Success') 
  //     {
  //       openModalMessages("Update Successful", "Template Successfully Updated!")
  //       closeModalTemplate()
  //       setMyTemplateObj(res.result)
  //     } else {
  //       openModalMessages("Update Failed", `${res.message}. Contact support if you feel this is an error.`)
  //     }
  //     setIsTemplateSaving(false)
  //   });
  // }

  async function GetBlends (perPage, page, studyID) {
    let cancelPromiseGetBlends = false
    let allData = []

    async function getData(perPage, page, allData) {
      let data = [];

      if (cancelPromiseGetBlends) return allData
  
      await BlendRequest.GetBlendsByStudyID(studyID, page, perPage).then((res) => {
        if (res.message === "Success")
        {
          data = res ? res.result : null
        } else {
          //setModalNoButtonText(`There was an error getting the blend requests for study ${studyID}, ${res && res.message ? res.message :  ""}`)
          setErrorLoadingBlends(`There was an error getting the blend requests for study ${studyID}, ${res && res.message ? res.message :  ""}`)
        }
      });
    
      if (data !== null) {
        allData = [...allData, ...data]
      }
    
      if (data === null || data.length === 0) {
        //setIsTestDataLoading(false)
        return allData;
      } else {
        page++;
        //setMySamples(allData)
        setModalNoButtonText(`Please wait while blend data is loading, this may take longer with larger studies...Loaded ${allData.length} blends`)
        //setLoadingCounterMessage(`Loaded ${allData.length} blends...`)
        return getData(perPage, page, allData);
      }
    }

    var myRequests = await getData(perPage, page, allData)

    return myRequests;
  }

  useEffect(() => {
    if (displayScreenValue === 0)
    {
      setHeaderText(headerTextStudy)
      setInstructionText(instructionTextStudy)
    } else if (displayScreenValue === 1)
    {
      setHeaderText(blendPageHeader)
      setInstructionText(instructionTextBlends)
    } else if (displayScreenValue === 2)
    {
      setHeaderText(headerTextReview)
      setInstructionText(instructionTextReview)
      
    } else {
      setHeaderText(headerTextStudy)
      setInstructionText(instructionTextStudy)
    }
  }, [displayScreenValue, blendPageHeader])

  useEffect(() => {
    if (incomingStudyID > -1)
    {
      let cancelPromise = false
      
      //open loading modal
      openModalNoButton("Loading Blend Data", "Please wait while blend data is loading, this may take longer with larger studies...")

      //BlendStudy.getBlendStudyByID(incomingStudyID).then((res) => {
      BlendStudy.getBlendStudyByID(incomingStudyID, true).then((res) => {

        if (cancelPromise) return

        if (res.message === "Success")
        {      
          setBlendPageHeader(`Add Blends, Batches and Tests to '${res.result.studyName}'`)

          GetBlends(20, 1, res.result.id).then((data) => {

          if (errorLoadingBlends === null)
          {
            closeModalNoButton()
          }

          res.result.blendRequests = data;

          const copyStudy = structuredClone(res.result)
              
          // if (isDupeStudy)
          // { 
          //   copyStudy.id = 0
          //   copyStudy.studyName = null
          //   copyStudy.blendStudyStatusName = null
          //   copyStudy.blendStudyStatus = null
          //   copyStudy.createdDate = null
          //   copyStudy.createdByEmail = null
          //   copyStudy.lastModifiedDate = null
          //   copyStudy.lastModifiedByEmail = null                
          // }

           const newErrorChecks = 
           {
             blendStudy:
             {
              //studyName: isDupeStudy ? null : false,
              studyName: copyStudy.studyName === null || copyStudy.studyName.trim() === '',
              project: copyStudy.project === null, 
              ownerEmail: copyStudy.ownerEmail === null || copyStudy.ownerEmail.trim() === '', 
              blendStudyType: copyStudy.blendStudyType === null
              // studyName: false,
              // project: false, 
              // ownerEmail: false, 
              // blendStudyType: false
             }, 
             blendRequests:[],
           };

          for(var i = 0; i <  copyStudy.blendRequests.length; i++) {
            // if (isDupeStudy)
            // {
            //   copyStudy.blendRequests[i].id = 0
            //   copyStudy.blendRequests[i].blendStudyID = 0
            //   copyStudy.blendRequests[i].blendStatusName = null
            //   copyStudy.blendRequests[i].blendStatus = null
            //   copyStudy.blendRequests[i].blendStudy = null
            //   copyStudy.blendRequests[i].createdByEmail = null
            //   copyStudy.blendRequests[i].createdDate = null
            //   copyStudy.blendRequests[i].lastModifiedDate = null
            //   copyStudy.blendRequests[i].lastModifiedByEmail = null
            //   copyStudy.blendRequests[i].blendSubstanceName = ''
            //   copyStudy.blendRequests[i].blendSubstance = null
            //   //copyStudy.blendRequests[i].blendAttachments = null
            // }

            //populate the testing object for each blend request
            //copyStudy.blendRequests[i].analyticalTestingObject = copyStudy.blendRequests[i].analyticalTesting === null || copyStudy.blendRequests[i].analyticalTesting === '' ? [] : JSON.parse(copyStudy.blendRequests[i].analyticalTesting)

            copyStudy.blendRequests[i].hasBeenEdited = false;

            newErrorChecks.blendRequests.push({
              //description: copyStudy.blendRequests[i].description === '' || copyStudy.blendRequests[i].description === null,
              requestUoM: copyStudy.blendRequests[i].requestUoM === null,
              blendComponents:[],
              batches: [],
              _blendLetter: copyStudy.blendRequests[i].blendName,
              // requestUoM: false,
              // blendComponents:[],
              // batches: []
            })

            for(var x = 0; x <  copyStudy.blendRequests[i].blendComponents.length; x++) {
              // if (isDupeStudy)
              // {
              //   copyStudy.blendRequests[i].blendComponents[x].id = 0
              //   copyStudy.blendRequests[i].blendComponents[x].blendID = 0
              //   copyStudy.blendRequests[i].blendComponents[x].blendRequest = null
              // }
          
              newErrorChecks.blendRequests[i].blendComponents.push({
                _blendLetter: copyStudy.blendRequests[i].blendName,
                substance: copyStudy.blendRequests[i].blendComponents[x].substance === null,
                targetAmountUpper: EvaulateMaxUpperAmountPercentages(copyStudy, i, copyStudy.blendRequests[i].blendComponents[x].targetAmountUpper),
                targetAmountLower: EvaulateMaxUpperAmountPercentages(copyStudy, i, copyStudy.blendRequests[i].blendComponents[x].targetAmountLower),
                blendAdditionTemperatureC: EvaluateOptionalNumberField(copyStudy.blendRequests[i].blendComponents[x].blendAdditionTemperatureC),
                preferredSample: false,
                secondaryPreferredSample: false
                // substance: false,
                // targetAmountUpper: false,
                // targetAmountLower: false,
                // blendAdditionTemperatureC: false,
                // preferredSample: false,
                // secondaryPreferredSample: false
              })
            }

            for(var z = 0; z <  copyStudy.blendRequests[i].batches.length; z++) {
              
              // if (isDupeStudy)
              // {
              //   copyStudy.blendRequests[i].batches[z].id = 0
              //   copyStudy.blendRequests[i].batches[z].blendID = 0
              //   copyStudy.blendRequests[i].batches[z].blendRequest = null
              //   copyStudy.blendRequests[i].batches[z].gpaqsNumber = null
              //   copyStudy.blendRequests[i].batches[z].actualAmount = null
              //   copyStudy.blendRequests[i].batches[z].preparedByEmail = null
              //   copyStudy.blendRequests[i].batches[z].preparedDate = null
              //   copyStudy.blendRequests[i].batches[z].lastModifiedByEmail = null
              //   copyStudy.blendRequests[i].batches[z].lastModifiedDate = null
              //   copyStudy.blendRequests[i].batches[z].createdByEmail = null
              //   copyStudy.blendRequests[i].batches[z].createdDate = null
              //   copyStudy.blendRequests[i].batches[z].shippingInfo = null
              //   copyStudy.blendRequests[i].batches[z].preparedSampleName = null
              //   copyStudy.blendRequests[i].batches[z].preparedSample = null
              //   copyStudy.blendRequests[i].batches[z].actualAmountUoM = null
              //   copyStudy.blendRequests[i].batches[z].unitOfMeasureActual = null
              //   copyStudy.blendRequests[i].batches[z].batchStatusName = 'Draft'
              //   copyStudy.blendRequests[i].batches[z].batchStatus = null
              //   copyStudy.blendRequests[i].batches[z].batchCertificationStatusName = null
              //   copyStudy.blendRequests[i].batches[z].batchCertificationStatus = null
              //   copyStudy.blendRequests[i].batches[z].lastCertifiedDate = null
              //   copyStudy.blendRequests[i].batches[z].lastCertifiedByEmail = null
              //   copyStudy.blendRequests[i].batches[z].shelfLifeStartDate = null
              //   copyStudy.blendRequests[i].batches[z].shelfLifeEndDate = null
              //   copyStudy.blendRequests[i].batches[z].waiverReason = null
              //   copyStudy.blendRequests[i].batches[z].retainReason = null
              //   copyStudy.blendRequests[i].batches[z].batchPurpose = null
              //   copyStudy.blendRequests[i].batches[z].shippingAddressID = null
              //   copyStudy.blendRequests[i].batches[z].requestedCompletionDate = null
              //   copyStudy.blendRequests[i].batches[z].batchComponents = null
              //   copyStudy.blendRequests[i].batches[z].batchAttachments = null
              // }
              
              copyStudy.blendRequests[i].batches[z].hasBeenEdited = false;

              // if (copyStudy.blendRequests[i].batches[z].batchRetainLocation === null) {
              //   copyStudy.blendRequests[i].batches[z].batchRetainLocation = {siteName: "Clinton Technical Center", locationName: "DISCARD", isActive: true, canContainSamples: true}
              // }

              if (copyStudy.blendRequests[i].batches[z].destination === '' || copyStudy.blendRequests[i].batches[z].destination === null) {
                copyStudy.blendRequests[i].batches[z].destination = 'Internal'
              }

              copyStudy.blendRequests[i].batches[z].analyticalTestingObject = copyStudy.blendRequests[i].batches[z].analyticalTesting === null || copyStudy.blendRequests[i].batches[z].analyticalTesting === '' ? [] : JSON.parse(copyStudy.blendRequests[i].batches[z].analyticalTesting)
            
              newErrorChecks.blendRequests[i].batches.push({
                _blendLetter: copyStudy.blendRequests[i].blendName,
                _batchNumber: copyStudy.blendRequests[i].batches[z].batchSequenceNumber,
                requestedAmount: !(isNumber(copyStudy.blendRequests[i].batches[z].requestedAmount)),
                batchPrecision: EvaluateRequiredIntegerField(copyStudy.blendRequests[i].batches[z].batchPrecision),
                unitOfMeasureRequested: copyStudy.blendRequests[i].batches[z].unitOfMeasureRequested === null,
                batchRetainLocation: copyStudy.blendRequests[i].batches[z].batchRetainLocation === null,
                destination: copyStudy.blendRequests[i].batches[z].destination.trim() === '',
                blendPriority: copyStudy.blendRequests[i].batches[z].blendPriority === null,
                priorityReason: copyStudy.blendRequests[i].batches[z].priorityReason === '' && copyStudy.blendRequests[i].batches[z].blendPriority ? copyStudy.blendRequests[i].batches[z].blendPriority.blendPriorityName === 'Normal' ? false : true : false,
              
                blendFacility: copyStudy.blendRequests[i].batches[z].blendFacility === null,
                description: copyStudy.blendRequests[i].batches[z].description === '' || copyStudy.blendRequests[i].batches[z].description === null,
                chargeCode: copyStudy.blendRequests[i].batches[z].chargeCode === '' || copyStudy.blendRequests[i].batches[z].chargeCode === null,
                batchProductGroup: copyStudy.blendRequests[i].batches[z].batchProductGroup === null,

                // requestedAmount: false,
                // batchPrecision: false,
                // requestedAmountUoM: false,
                // batchRetainLocation: false,
                // destination: false,
                // blendPriority: false,
                // priorityReason: false,
                // blendFacility: false
              })

            }

            //need this in case the incoming blend does not have any batches or components on the request
            if (copyStudy.blendRequests[i].batches.length === 0)
            {
              copyStudy.blendRequests[i].batches.push(defaultBatch)
              newErrorChecks.blendRequests[i].batches.push(defaultBatchErrorCheck)
            }

            if (copyStudy.blendRequests[i].blendComponents.length === 0)
            {
              copyStudy.blendRequests[i].blendComponents.push(defaultBlendComponent)
              newErrorChecks.blendRequests[i].blendComponents.push(defaultBlendComponentErrorCheck)

              //need this because when going from M2 to M3 there were closed blend requests without components
              if (copyStudy.blendRequests[i].blendStatusName === "Cancelled" || copyStudy.blendRequests[i].blendStatusName === "Complete")
              {
                newErrorChecks.blendRequests[i].blendComponents[0].substance = false
                newErrorChecks.blendRequests[i].blendComponents[0].targetAmountLower = false
                newErrorChecks.blendRequests[i].blendComponents[0].componentUoM = false
              }
            }
          }

          //need this in case an incoming study doesn't have any blends setup yet
          if (copyStudy.blendRequests.length === 0) 
          {
            copyStudy.blendRequests.push(defaultBlendRequest)
            newErrorChecks.blendRequests.push(defaultBlendRequestErrorCheck)
          }

          setMyBlendStudy(copyStudy)
          setErrorChecks(newErrorChecks)

        })
        } else {
          openModalMessages("Error Loading", `There was an error loading the Blend Study ${incomingStudyID}, please try again.  ${res.message ? res.message : ''} `)
        }
        });
      
      return () => {
        cancelPromise = true
    }
    
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [incomingStudyID, reloadData])

  // useEffect(() => {
  //   if (incomingStudyObj !== null)
  //   {
  //     //setup the error checking
  //     const newErrorChecks = 
  //     {
  //       blendStudy:
  //     {
  //       studyName: incomingStudyObj.studyName === null || incomingStudyObj.studyName.trim() === '',
  //       project: incomingStudyObj.project === null, 
  //       ownerEmail: incomingStudyObj.ownerEmail === null || incomingStudyObj.ownerEmail.trim() === '', 
  //       blendStudyType: incomingStudyObj.blendStudyType === null
  //     }, 
  //       blendRequests:[],
  //     };

  //     for(var i = 0; i <  incomingStudyObj.blendRequests.length; i++) {
  //       const newBlendRequestErrorCheck =
  //       {
  //         // description: incomingStudyObj.blendRequests[i].description === '' || incomingStudyObj.blendRequests[i].description === null,
  //         // chargeCode: incomingStudyObj.blendRequests[i].chargeCode === '' || incomingStudyObj.blendRequests[i].chargeCode === null,
  //         requestUoM: incomingStudyObj.blendRequests[i].requestUoM === null,
  //         //blendProductGroup: incomingStudyObj.blendRequests[i].blendProductGroup === null,
  //         blendComponents:[],
  //         batches: []
  //       }

  //     newErrorChecks.blendRequests.push(newBlendRequestErrorCheck)

  //     for(var x = 0; x <  incomingStudyObj.blendRequests[i].blendComponents.length; x++) {
  //       const newBlendComponentErrorCheck =
  //       {
  //         substance: incomingStudyObj.blendRequests[i].blendComponents[x].substance === null,
  //         targetAmountUpper: EvaulateMaxUpperAmountPercentages(incomingStudyObj, i, incomingStudyObj.blendRequests[i].blendComponents[x].targetAmountUpper),
  //         targetAmountLower: EvaulateMaxUpperAmountPercentages(incomingStudyObj, i, incomingStudyObj.blendRequests[i].blendComponents[x].targetAmountLower),
  //         blendAdditionTemperatureC: EvaluateOptionalNumberField(incomingStudyObj.blendRequests[i].blendComponents[x].blendAdditionTemperatureC),
  //         preferredSample: false,
  //         secondaryPreferredSample: false
  //       }

  //       newErrorChecks.blendRequests[i].blendComponents.push(newBlendComponentErrorCheck)
  //     }

  //     for(var z = 0; z <  incomingStudyObj.blendRequests[i].batches.length; z++) {
  //       const newBatchErrorCheck = 
  //       {
  //         requestedAmount: !(isNumber(incomingStudyObj.blendRequests[i].batches[z].requestedAmount)),
  //         batchPrecision: EvaluateOptionalNumberField(incomingStudyObj.blendRequests[i].batches[z].batchPrecision),
  //         unitOfMeasureRequested: incomingStudyObj.blendRequests[i].batches[z].unitOfMeasureRequested === null,
  //         batchRetainLocation: incomingStudyObj.blendRequests[i].batches[z].batchRetainLocation === null,
  //         destination: incomingStudyObj.blendRequests[i].batches[z].destination.trim() === '',
  //         blendPriority: incomingStudyObj.blendRequests[i].batches[z].blendPriority === null,
  //         priorityReason: incomingStudyObj.blendRequests[i].batches[z].priorityReason === '' && incomingStudyObj.blendRequests[i].batches[z].blendPriority ? incomingStudyObj.blendRequests[i].batches[z].blendPriority.blendPriorityName === 'Normal' ? false : true : false,
         
  //         blendFacility: incomingStudyObj.blendRequests[i].batches[z].blendFacility === null,
  //         description: incomingStudyObj.blendRequests[i].batches[z].description === '' || incomingStudyObj.blendRequests[i].batches[z].description === null,
  //         chargeCode: incomingStudyObj.blendRequests[i].batches[z].chargeCode === '' || incomingStudyObj.blendRequests[i].batches[z].chargeCode === null,
  //         blendProductGroup: incomingStudyObj.blendRequests[i].batches[z].blendProductGroup === null,
  //       } 

  //       newErrorChecks.blendRequests[i].batches.push(newBatchErrorCheck)
  //     }

  //     //need this in case the incoming blend does not have any batches or components on the request
  //     if (incomingStudyObj.blendRequests[i].batches.length === 0)
  //     {
  //       newErrorChecks.blendRequests[i].batches.push(defaultBatchErrorCheck)
  //     }

  //     if (incomingStudyObj.blendRequests[i].blendComponents.length === 0)
  //     {
  //       newErrorChecks.blendRequests[i].blendComponents.push(defaultBlendComponentErrorCheck)
  //     }
  //   }

  //   //need this in case an incoming study doesn't have any blends setup yet
  //   if (incomingStudyObj.blendRequests.length === 0) 
  //   {
  //     newErrorChecks.blendRequest.push(defaultBlendRequestErrorCheck)
  //   }

  //     setErrorChecks(newErrorChecks)
  //   }    
  // }, [incomingStudyObj])

  useEffect(() => {
    let cancelPromise = false

    UnitOfMeasure.getAll().then((res) => {
        if (cancelPromise) return
        setAvailableUOMs(res.filter(result => (result.metricStandardConversion !== null && result.isActive === true)))
    });
    
    return () => {
        cancelPromise = true
    }

  }, [])

 const renderCorrectPage = () => {
  switch (displayScreenValue) {
    case 0: {

      return (
        <StudyInformation
          myStudy={myBlendStudy}
          setMyStudy={setMyBlendStudy}
          errorChecks={errorChecks}
          setErrorChecks={setErrorChecks}
        ></StudyInformation>

      );
    }
    case 1: {

      return (
        <BlendsAndBatches
          myStudy={myBlendStudy}
          setMyStudy={setMyBlendStudy}
          errorCheck={errorChecks}
          setErrorCheck={setErrorChecks}
          defaultBlendRequest={defaultBlendRequest}
          defaultBlendComponent={defaultBlendComponent}
          defaultBlendComponentErrorCheck={defaultBlendComponentErrorCheck}
          defaultBatch={defaultBatch}
          defaultBatchErrorCheck={defaultBatchErrorCheck}
          defaultBlendRequestErrorCheck={defaultBlendRequestErrorCheck}
          GetTotalNeededForTesting={GetTotalNeededForTesting}
          CheckBlendComponents={CheckBlendComponents}
          SubmitBlendStudy={SubmitBlendStudy}
          SumOfBlendTargets={SumOfBlendTargets}
          openModalNoButton={openModalNoButton}
          closeModalNoButton={closeModalNoButton}
          reloadData={reloadData}
          setReloadData={setReloadData}
        ></BlendsAndBatches>
      );
    }
    case 2: {

      return (
        <StudySummary
          myStudy={myBlendStudy}
        ></StudySummary>
      );
    }

    default: {
      alert(displayScreenValue);
    }
  }
  };

  const renderNavagationButton = () => {
    switch (displayScreenValue) {

    case 0: {
        return (
            <GlobalButton
            name="btnBlendAndBatches"
            variant='contained'
            style={{ textTransform: "none",  marginTop:"50px", marginLeft:"15px", marginRight:"10px", width:"90%"}}
            value={1}
            onClick={handleNavagation}
            >Add Blends and Batches</GlobalButton>
        );
      }
      case 1: {
        return (
          isSaveChanges ?
          (<>
              <CircularProgress style={{textTransform: "none",  marginTop:"50px", marginLeft:"15px"}}></CircularProgress>
              <GlobalButton  
                name="btnRegister"
                disabled
                variant='contained'
                style={{ textTransform: "none",  marginTop:"-30px", marginLeft:"10px", marginRight:"10px", width:"60%"}}
              >Save All Changes</GlobalButton>

              <GlobalSecondaryButton
                disabled
                name="btnRegister"
                variant='contained'
                style={{ textTransform: "none",  marginTop:"1rem", marginLeft:"15px", marginRight:"10px", width:"90%"}}
                value={2}
              >Review Study</GlobalSecondaryButton>
            </>
          ) : (
            <>
              <GlobalButton  
                disabled={!(hasBlendMaintainer) && (currentUser.username !== myBlendStudy.ownerEmail)}
                name="btnRegister"
                variant='contained'
                style={{ textTransform: "none",  marginTop:"50px", marginLeft:"15px", marginRight:"10px", width:"90%"}}
                onClick={(e) => PreparePayloadToSaveChanges()}
              >Save All Changes</GlobalButton>

              <GlobalSecondaryButton
                name="btnRegister"
                variant='contained'
                style={{ textTransform: "none",  marginTop:"1rem", marginLeft:"15px", marginRight:"10px", width:"90%"}}
                value={2}
                onClick={handleNavagation}
              >Review Study</GlobalSecondaryButton>
            </>
          )
        );
      }
  
      case 2: {
          return (
            isSaveChanges ?
            (<>
                <CircularProgress style={{textTransform: "none",  marginTop:"50px", marginLeft:"15px"}}></CircularProgress>
                <GlobalButton  
                  name="btnRegister"
                  disabled
                  variant='contained'
                  style={{ textTransform: "none",  marginTop:"-30px", marginLeft:"10px", marginRight:"10px", width:"60%"}}
                >Save All Changes</GlobalButton>
              </>
            ) : (
              <GlobalButton  
                disabled={!(hasBlendMaintainer) && (currentUser.username !== myBlendStudy.ownerEmail)}
                name="btnRegister"
                variant='contained'
                style={{ textTransform: "none",  marginTop:"50px", marginLeft:"15px", marginRight:"10px", width:"90%"}}
                onClick={(e) => PreparePayloadToSaveChanges()}
              >Save All Changes</GlobalButton>
            )
            // isStudySaving ?
            // (<>
            //     <CircularProgress style={{textTransform: "none",  marginTop:"50px", marginLeft:"15px"}}></CircularProgress>
            //     <GlobalButton  
            //       name="btnRegister"
            //       disabled
            //       variant='contained'
            //       style={{ textTransform: "none",  marginTop:"-30px", marginLeft:"10px", marginRight:"10px", width:"60%"}}
            //     >Submit Study</GlobalButton>
            // </>
            // ):(
            //     <GlobalButton  
            //       disabled={!(hasBlendMaintainer) && (currentUser.username !== myBlendStudy.ownerEmail)}
            //       name="btnRegister"
            //       variant='contained'
            //       style={{ textTransform: "none",  marginTop:"50px", marginLeft:"15px", marginRight:"10px", width:"90%"}}
            //       onClick={(e) => SubmitBlendStudy()}
            //     >Submit Study</GlobalButton>
            // )
          )
        }
    
      default: {
        alert(displayScreenValue);
      }
    }
  }

  function CheckBlendComponents (myStudy) 
  {
    let hasErrors = []

    for (var iBlend = 0; iBlend < myStudy.blendRequests.length; iBlend++)
    {
      let blendLetter = myStudy.blendRequests[iBlend].blendName
      let blendErrorObj = errorChecks.blendRequests.find(obj => obj._blendLetter === blendLetter)

      //let requestUoM = myStudy.blendRequests[iBlend].requestUoM

      if (blendErrorObj.requestUoM === true || blendErrorObj.requestUoM === null)
      {
        hasErrors.push(`A blend request measurment type is required on blend ${blendLetter}.`)
      }
  
      //let targetSum = 0

      for(var iComponent = 0; iComponent < blendErrorObj.blendComponents.length; iComponent++) 
      {
        // let componentUoM = myStudy.blendRequests[iBlend].blendComponents[iComponent].componentUoM

        // if (myStudy.blendRequests[iBlend].blendStatusName === "Complete" || myStudy.blendRequests[iBlend].blendStudyStatusName === "Cancelled")
        // {
        //   targetSum = 100;
        
        // } else {
        //   if ((requestUoM !== null && componentUoM !== null) && requestUoM.uoMName === componentUoM.uoMName)
        //   {
        //     if (isNumber(myStudy.blendRequests[iBlend].blendComponents[iComponent].targetAmountLower))
        //     {
        //       targetSum = targetSum + Number(Number(myStudy.blendRequests[iBlend].blendComponents[iComponent].targetAmountLower).toFixed(3)).toFixed(3)
        //     }
        //   }
        // }
    
        if (blendErrorObj.blendComponents[iComponent].substance === true || blendErrorObj.blendComponents[iComponent].substance === null)
        {
          hasErrors.push(`A ChemID is required for all components on blend ${blendLetter}.`)
        }

        if ((blendErrorObj.blendComponents[iComponent].targetAmountLower === true || blendErrorObj.blendComponents[iComponent].targetAmountLower === null))
        {
          hasErrors.push(`A lower amt. % is missing/invalid on blend ${blendLetter}.`)
        }

        if (blendErrorObj.blendComponents[iComponent].blendAdditionTemperatureC === true || blendErrorObj.blendComponents[iComponent].blendAdditionTemperatureC === null)
        {
          hasErrors.push(`A component addition temp is invalid on blend ${blendLetter}.`)
        }

        if (blendErrorObj.blendComponents[iComponent].preferredSample === true || blendErrorObj.blendComponents[iComponent].preferredSample === null)
        {
          hasErrors.push(`A component preferred MIDAS # is invalid on blend ${blendLetter}.`)
        }

        if (blendErrorObj.blendComponents[iComponent].secondaryPreferredSample === true || blendErrorObj.blendComponents[iComponent].secondaryPreferredSample === null)
        {
          hasErrors.push(`A component secondary preferred MIDAS # is invalid on blend ${blendLetter}.`)
        }

        if (blendErrorObj.blendComponents[iComponent].componentUoM === true || blendErrorObj.blendComponents[iComponent].componentUoM === null)
        {
          hasErrors.push(`A component is missing a unit of measure on blend ${blendLetter}.`)
        }
      }

      const target = SumOfBlendTargets(myStudy.blendRequests[iBlend].blendComponents)
      
      if (target < 100 && myStudy.blendRequests[iBlend].blendStatusName !== "Complete" && myStudy.blendRequests[iBlend].blendStatusName !== "Cancelled")
      {
        hasErrors.push(`The component target amounts total of ${target}% is less than 100% on blend ${blendLetter}.`)
      }      
    }

    return hasErrors;
  }

  function EvaulateMaxUpperAmountPercentages (myStudy, blendNumber, value)
  {
    if (value === null || value === '') 
    {
        return false
    } else
    {
      let numberCheck = isNumber(value)
      let totalPercentage = 0
      let requestUoM = myStudy.blendRequests[blendNumber].requestUoM;

      if (numberCheck)
      {
        myStudy.blendRequests[blendNumber].blendComponents.forEach((oComponent) => {     
          
          let componentUoM = oComponent.componentUoM

          if ((requestUoM !== null && componentUoM !== null) && requestUoM.uoMName === componentUoM.uoMName)
          {
            if (isNumber(oComponent.targetAmountUpper))
            {
              totalPercentage = RoundNumber(totalPercentage + RoundNumber(oComponent.targetAmountUpper, 3), 3)
            }
          }
        })

        return(totalPercentage > 100)
      }
      else
      {
        return(numberCheck)
      }
    }

  }

  function SumOfBlendTargets (lstBlendComponents) {

    let sum = 0;

    for(var iComponent = 0; iComponent <  lstBlendComponents.length; iComponent++) 
    {
        if (isNumber(lstBlendComponents[iComponent].targetAmountLower) && lstBlendComponents[iComponent].componentUoM 
        && (lstBlendComponents[iComponent].componentUoM.uoMName === 'wt%' || lstBlendComponents[iComponent].componentUoM.uoMName === 'vol%'))
        {
            sum = sum + Number(lstBlendComponents[iComponent].targetAmountLower)
        }
    }

    return RoundNumber(sum, 2);
  }

    return (
      <div>
        <span className='pageheader'>{headerText}</span>
        <Divider className='dividerbar' />
        
        <p style={{
            color:"#111112",
            fontSize:"15px",
            fontFamily:"EMprint",
        }}>{instructionText}</p>

      <Drawer sx={{ 
          width: 200,
          flexShrink: 0,
           [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' },
          marginTop:"25px",
         }} 
        variant="permanent"
        open
        anchor="right"
      >
        <Box style={{height:"69px"}}>test</Box>
        <Toolbar/>
        <Divider />
  
          <p style={{
               fontFamily:"EMprint",
               fontWeight:"600",
               fontSize:"20px",
               color:"#545459",
               marginBottom:"35px",
               textTransform: 'none',
               textAlign:"left",
               paddingLeft:"20px"
              }}>Study Summary</p>

          <Button style={{
                color:navBarTextColor[0],
                fontFamily:"EMprint",
                fontWeight:"600",
                fontSize: navFontSize,
                textTransform: 'none',
                alignSelf: "flex-start",
                marginTop:"-20px",
                paddingLeft:"20px"
              }}
              value={0}
              onClick={handleNavagation}
              >Study Information</Button>
              <p></p>

          <Button style={{
            color:navBarTextColor[1],
            fontFamily:"EMprint",
            fontWeight:"600",
            fontSize: navFontSize,
            textTransform: 'none',
            alignSelf: "flex-start",
            marginTop:"20px",
            paddingLeft:"20px"
          }}
            value={1}
            onClick={handleNavagation}
          >Add Blends and Batches</Button>

          <Button style={{
            color:navBarTextColor[2],
            fontFamily:"EMprint",
            fontWeight:"600",
            fontSize: navFontSize,
            alignSelf: "flex-start",
            textTransform: 'none',
            marginTop:"20px",
            paddingLeft:"20px"
          }}
            uppercase='false'
            value={2}
            onClick={handleNavagation}
          >Review Blend Study</Button>

        <div style={{bottom:0, position:"absolute", marginBottom:"2rem"}}>
           {renderNavagationButton()}

            {/* <GlobalSecondaryButton
              name="btnSaveDraft"
              variant='contained'
              style={{ marginTop:"15px", marginLeft:"15px", marginRight:"10px", width:"90%"}}
              onClick={(e) => draftButtonText === "Update Draft" ? SaveNewDraft(e) : openModalDrafts("Save Draft", "Enter a name for your new draft.", "Save", "Cancel")}
            >{draftButtonText}</GlobalSecondaryButton> */}

{/*             
            <GlobalSecondaryButton
              disabled={isTemplateSaving}
              name="btnSaveTemplate"
              variant='contained'
              style={{ marginTop:"15px", marginLeft:"15px", marginRight:"10px", width:"90%"}}
              onClick={(e) => openModalTemplate("Save Template", "Enter a name for your new template.", "Save", "Cancel")}
            >{'Save as Template'}</GlobalSecondaryButton>

            <GlobalSecondaryButton
              disabled={isTemplateSaving || allowTemplateUpdates === false}
              name="btnSaveTemplate"
              variant='contained'
              style={{ marginTop:"15px", marginLeft:"15px", marginRight:"10px", width:"90%"}}
              onClick={(e) => UpdateTemplate()}
            >{'Update Template'}</GlobalSecondaryButton>    */}
        </div>
     
  </Drawer>

        <Box style={{width:`calc(112% - ${drawerWidth}px)`}}>
          {renderCorrectPage()}
        </Box>

        {/* Informational Messages */}
        <ModalSimpleButton title={modalMessagesTitle} buttonText={modalMessagesButtonText} buttonAction={closeModalMessages} open={modalMessagesOpen} setOpen={setModalMessagesOpen}>
            {modalMessagesTitle === errorsModalTitle ?
            (modalMessagesText && modalMessagesText.map((text, index) => {
                return (
                    <div style={{display:"flex"}} key={`myBlendingErrorChecks${index}`}>
                        <label>
                        {text}
                        </label>
                    </div>
                )
                })
            )
            :
            ( <label>
                {modalMessagesText}
              </label>
            )}      
        </ModalSimpleButton>

        {/* Informational Messages */}
        <ModalNoButton title={modalNoButtonTitle} open={modalNoButtonOpen} setOpen={setModalNoButtonOpen}>
                <label>
                  {modalNoButtonText}
                </label>
        </ModalNoButton>

        {/* <ModalTwoButtons title={modalDraftsTitle} button1Text={modalDraftsButton1Text} button1Action={SaveNewDraft} button2Text={modalDraftsButton2Text} button2Action={closeModalDrafts} open={modalDraftsOpen} setOpen={setModalDraftsOpen}>
          <label>
            {modalDraftsText}
          </label>

          <div></div>

          <TextField
            size="small"
            label = "Draft Name"
            value = {draftSaveName}
            onChange = {e => setDraftSaveName(e.target.value)}
            margin = "normal"
            style={{width:250, marginTop:40}}
            inputProps={{ maxLength: 250 }}
          ></TextField>
        </ModalTwoButtons> */}

        {/* Saving new template */}
        {/* <ModalTwoButtons title={modalTemplatesTitle} button1Text={modalTemplatesButton1Text} button1Action={SaveTemplate} button2Text={modalTemplatesButton2Text} button2Action={closeModalTemplate} open={modalTemplatesOpen} setOpen={setModalTemplatesOpen}>
          <label>
            {modalTemplatesText}
          </label>

          <div></div>

          <TextField
            size="small"
            label = "Template Name"
            value = {templateSaveName}
            onChange = {e => setTemplateSaveName(e.target.value)}
            margin = "normal"
            style={{width:250, marginTop:40}}
            inputProps={{ maxLength: 250 }}
          ></TextField>

          <div>
            <FormGroup>
                <FormControlLabel control={<Switch defaultChecked />} 
                  checked={isPublicChecked}
                  onChange={e => setIsPublicChecked(e.target.checked)}
                  label="Make Template Public" />
            </FormGroup>
          </div>          
             
        </ModalTwoButtons> */}

        {/* <ModalTwoButtons title={modalInventoryCheckTitle} button1Text={modalInventoryCheckButton1Text} button1Action={ContinueWithBlend} button2Text={modalInventoryCheckButton2Text} button2Action={closeModalInventoryCheck} open={modalInventoryCheckOpen} setOpen={setModalInventoryCheckOpen}>
          {modalInventoryCheckText && modalInventoryCheckText.map((text, index) => {
            return (
              <div style={{display:"flex"}} key={`blendingInventoryChecks${index}`}>
                <label>
                  {text}
                </label>
              </div>
            )
            })
          }   
          <Box style={{marginTop:"2rem"}}>
            <label>{'Would you like to continue?'}</label>
          </Box>
        </ModalTwoButtons> */}
  </div> 
    
      );
    };
    
export default BlendStudyManagement;