import React, { useEffect, useState } from "react";
import InstrumentManagementForm from "./InstrumentManagementForm";
import Instrument from "../../api/Admin/Instrument";
import Location from "../../api/Admin/Location";
import ModalSimpleButton from "../../components/Modal/ModalSimpleButton";
import Typography from "@mui/material/Typography";

const InstrumentManagement = ({ ...props }) => {
  const [instrumentList, setInstrumentList] = useState();
  const [locationsList, setLocationsList] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState({ title: "", message: "" });

  const getInstruments = async () => {
    const data = await Instrument.getAll();
    if (data) {
      setInstrumentList(data);
    }
  };

  const getLocations = async () => {
    const data = await Location.getAll();
    if (data) {
      setLocationsList(data.filter((a) => a.isActive));
    }
  };
  const saveNewInstrument = (instrumentData) =>
    new Promise((resolve, reject) => {
      if (instrumentData.id > 0) {
        (async () => {
          const data = await Instrument.update(
            instrumentData.id,
            instrumentData
          );
          if (data) {
            let newList = [...instrumentList].filter(
              (x) => x.id !== instrumentData.id
            );
            newList.push(data);
            setInstrumentList(newList);
            messageCallback("Success!", "Instrument updated.");
            resolve(data);
          }
        })();
      } else {
        (async () => {
          const data = await Instrument.add(instrumentData);
          if (data) {
            setInstrumentList([...instrumentList, data]);
            messageCallback("Success!", "New instrument created.");
            resolve(data);
          }
        })();
      }
    });

  useEffect(() => {
    getInstruments();
    getLocations();
  }, []);

  const messageCallback = (title, message) => {
    setIsModalOpen(true);
    setModalContent({
      title,
      message,
    });
  };

  return (
    <div>
      <InstrumentManagementForm
        instrumentList={instrumentList}
        saveNewInstrument={saveNewInstrument}
        locationsList={locationsList}
      />
      {isModalOpen && (
        <ModalSimpleButton
          title={modalContent.title}
          buttonText="Close"
          buttonAction={() => {
            setIsModalOpen(false);
            setModalContent({ title: "", message: "" });
          }}
          setOpen={setIsModalOpen}
          open={isModalOpen}
        >
          <Typography
            style={{ padding: "0 16px", fontSize: 18, textAlign: "center" }}
          >
            {modalContent.message}
          </Typography>
        </ModalSimpleButton>
      )}
    </div>
  );
};

export default InstrumentManagement;
