import API from "..";

export default class ExperimentalSubstanceSubtype2 {
  /**
   * The ExperimentalSubstanceSubtype2 dimension table for M3
   * @param TODO
   */
  constructor({
    sT2ID,
    subtype2Name,
    sT1Parent,
    isActive
  }) {
    this.sT2ID = sT2ID;
    this.subtype2Name = subtype2Name;
    this.sT1Parent = sT1Parent;
    this.isActive = isActive;
  }

  static async get(id = -1, name = "", parentID = "") {
    const api = await API();
    const { data } = await api.get(`/experimentalsubstancesubtype2?id=${id}&name=${name}&parentID=${parentID}`);
    return data.result.map((d) => new ExperimentalSubstanceSubtype2(d));
  }
}