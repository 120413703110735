import React, { useState, useEffect } from "react";
import {
  Divider,
  Box,
  TextField,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  FormHelperText,
} from "@mui/material";
import { styled } from "@mui/material/styles";

import SpecialAnalysisDataClassification from "../../api/WorkRequest/SpecialAnalysisDataClassification";
import SpecialAnalysisReportFormat from "../../api/WorkRequest/SpecialAnalysisReportFormat";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { DatePickerKeyDownEvent } from "../../global";


const StyledTitle = styled("p")({
  fontFamily: "EMprint",
  fontWeight: "600",
  fontSize: "16px",
  color: "#545459",
  textTransform: "none",
  textAlign: "left",
  paddingTop: "20px",
});
const StyledSubTitle = styled("p")({
  fontFamily: "EMprint",
  fontWeight: "600",
  fontSize: "1rem",
  color: "#545459",
  textTransform: "none",
  textAlign: "left",
});

export default function SpecialAnalysisForm({
  myWorkRequest,
  specialAnalysisErrorCheck,
  formik,
  readOnly = false,
  isCancelledOrCompleted,
}) {
  const [dataClassification, setDataClassification] = useState([]);
  const [reportFormat, setReportFormat] = useState([]);
  useEffect(() => {
    SpecialAnalysisDataClassification.get()
      .then((dataResult) => {
        setDataClassification(dataResult);
      })

    SpecialAnalysisReportFormat.get()
      .then((reportResult) => {
        setReportFormat(reportResult);
      })
  }, []);

  myWorkRequest.specialRequest = formik.values ?? null;

  return (
    <Box gap={"10px"} display={"flex"} flexDirection={"column"}>
      <Divider />
      <StyledTitle>Analysis Info</StyledTitle>

      <Box display={"flex"} alignItems={"center"} gap={"10px"}>
        <FormControl
          fullWidth
          size="small"
          sx={{ marginTop: "5px" }}
          error={specialAnalysisErrorCheck.dataClassificationName}
        >
          <InputLabel id="dataClassification">* Data Classification</InputLabel>
          <Select
            disabled={readOnly}
            id="dataClassificationName"
            name="dataClassificationName"
            label="* Data Classification"
            value={formik.values?.dataClassificationName}
            onChange={formik.handleChange}
            error={specialAnalysisErrorCheck.dataClassificationName}
          >
            {dataClassification.map((item) => (
              <MenuItem
                key={item.classificationName}
                value={item.classificationName}
              >
                {item.classificationName}
              </MenuItem>
            ))}
          </Select>
          {specialAnalysisErrorCheck.dataClassificationName ? (
            <FormHelperText>Required</FormHelperText>
          ) : (
            <></>
          )}
        </FormControl>
      </Box>
      <Box>
        <Box>
          * Description of material to be analysed: Origin, history, previous
          analyses, correspondences, and all other related background
          information. Attachments are welcome and strongly encouraged to
          enhance out ability to most expeditiously and most appropriately meet
          your needs:
        </Box>
        <TextField
          disabled={readOnly}
          value={formik.values?.requestDescription}
          id="requestDescription"
          onChange={formik.handleChange}
          error={specialAnalysisErrorCheck.requestDescription}
          helperText={
            specialAnalysisErrorCheck.requestDescription ? "Required" : ""
          }
          multiline
          rows={3}
          fullWidth
        ></TextField>
      </Box>
      <Box>
        <Box>* Fundamental reasons for analysis:</Box>
        <TextField
          disabled={readOnly}
          value={formik.values?.requestReason}
          id={"requestReason"}
          onChange={formik.handleChange}
          error={specialAnalysisErrorCheck.requestReason}
          helperText={specialAnalysisErrorCheck.requestReason ? "Required" : ""}
          multiline
          rows={3}
          fullWidth
        ></TextField>
      </Box>
      <Box>
        <Box>* Analysis required, if known, or nature of results expected:</Box>
        <TextField
          disabled={readOnly}
          value={formik.values?.testingRequirements}
          id={"testingRequirements"}
          onChange={formik.handleChange}
          error={specialAnalysisErrorCheck.testingRequirements}
          helperText={
            specialAnalysisErrorCheck.testingRequirements ? "Required" : ""
          }
          multiline
          rows={3}
          fullWidth
        ></TextField>
      </Box>
      <Box>
        <Box>
          <Box>
            <StyledSubTitle>
              * Final report format (initial results will be reported by phone or
              e-mail)
            </StyledSubTitle>

            <FormControl
              fullWidth
              size="small"
              sx={{ marginTop: "5px" }}
              error={specialAnalysisErrorCheck.reportFormatName}
            >
              <InputLabel id="reportFormat">Report Format</InputLabel>
              <Select
                disabled={readOnly}
                id="reportFormatName"
                name="reportFormatName"
                label="* Report Format"
                value={formik.values?.reportFormatName}
                onChange={formik.handleChange}
                error={specialAnalysisErrorCheck.reportFormatName}
              >
                {reportFormat.map((item) => (
                  <MenuItem key={item.formatName} value={item.formatName}>
                    {item.formatName}
                  </MenuItem>
                ))}
              </Select>
              {specialAnalysisErrorCheck.reportFormatName ? (
                <FormHelperText>Required</FormHelperText>
              ) : (
                <></>
              )}
            </FormControl>
          </Box>
        </Box>
      </Box>
      <StyledTitle>Test Info (For Analytical Use Only)</StyledTitle>
      <Box display={"flex"} gap={"10px"}>
        <TextField
          sx={{ width: "20%" }}
          label={"Estimated Man/Hour"}
          disabled={isCancelledOrCompleted}
          id="estimatedTotalHours"
          value={formik.values?.estimatedTotalHours}
          onChange={formik.handleChange}
          size="small"
        />

        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DesktopDatePicker
            sx={{ width: "20%" }}
            disabled={isCancelledOrCompleted}
            size="small"
            inputFormat="MM/dd/yyyy"
            label="Estimated Completion Date"
            onChange={(e) => {
              formik.setFieldValue("estimatedCompletionDate", e, true)
            }}
            minDate={new Date()}
            id={"estimatedCompletionDate"}
            value={formik.values?.estimatedCompletionDate}
            renderInput={(params) => (
              <TextField
                onKeyDown={DatePickerKeyDownEvent}
                {...params}
                variant="outlined"
                size="small"
                InputLabelProps={{ shrink: true }}
                inputProps={{ ...params.inputProps }}
                InputProps={{ ...params.InputProps }}
              />
            )}
          />
        </LocalizationProvider>
      </Box>
    </Box>
  );
}
