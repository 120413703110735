import React from "react";
export const Information = () => (
  <div style = {{paddingBottom:"30px", paddingTop: "30px"}}> 
    <h3 style={{ textAlign: "center" }}>Information:</h3>

    <h4>ExxonMobil Requirements for “New” Chemicals</h4>
    <ul style={{ marginLeft: "30px" }}>
      <li>Global Health Practice for Hazard Materials Communication</li>
      <ul>
        <li style={{ marginLeft: "30px" }}>
          Requirement: Hazard reviews are conducted prior to purchase of new
          chemicals
        </li>
      </ul>
      <li>
        Corporate OIMS Framework & Downstream Guidelines
        <ul>
          <li style={{ marginLeft: "30px" }}>
            Requirement: New chemicals or potentially hazardous substances are
            reviewed prior to their introduction onto the site
          </li>
        </ul>
      </li>
    </ul>
    <h4>EMRE NJ Definitions</h4>
    <ul style={{ marginLeft: "30px" }}>
      <li>A “Chemical” is a material that is:</li>
      <ul>
        <li style={{ marginLeft: "30px" }}>
          Purchased using EM procurement processes
        </li>
        <li style={{ marginLeft: "30px" }}>
          Intended for use in research, testing, utility, maintenance or
          construction applications at the EMRE NJ Sites
        </li>
        <li style={{ marginLeft: "30px" }}>
          Identified in MIDAS by a Chem ID (e.g. CAS #, TRN, RC, Stock # etc.)
        </li>
      </ul>
      <li>
        A new chemical is a chemical that is not found when using the MIDAS
        Right To Know (RTK) New Chemical Search function
      </li>
      <li>
        Exclusions – Below are examples of materials that should not be
        classified as “Chemicals” in the Chemical Management System
        <ul>
          <li style={{ marginLeft: "30px" }}>
            Samples – products, process materials, synthesized
            chemicals…intended for testing and evaluation
          </li>
          <li style={{ marginLeft: "30px" }}>
            Common household/office chemicals (consumer products) used as
            intended by the manufacturer
          </li>
          <li style={{ marginLeft: "30px" }}>
            Materials brought on-site by a Third Party which will be used by the
            Third Party
            <ul>
              <li style={{ marginLeft: "30px" }}>
                Managed by 8.1 Third Party Services System
              </li>
            </ul>
          </li>
        </ul>
      </li>
    </ul>

    <h4>New Requirements for Ordering Chemicals</h4>
    <ul style={{ marginLeft: "30px" }}>
      <li>
        Before placing an order for a chemical, determine if it meets the “New
        Chemical” definition
      </li>
      <ul>
        <li style={{ marginLeft: "30px" }}>
          Use MIDAS Right To Know search function to facilitate this step
        </li>
        <li style={{ marginLeft: "30px" }}>
          If chemical is present and not “new” continue with ordering process
        </li>
      </ul>
    </ul>
    <ul style={{ marginLeft: "30px" }}>
      <li>
        If it is determined to be a “New Chemical,” fill out and submit the New
        Chemical Review Form to OIMS-SHE
      </li>
      <ul>
        <li style={{ marginLeft: "30px" }}>
          OIMS-SHE will review and configure MIDAS to allow entry of the
          material into the inventory
        </li>
        <li style={{ marginLeft: "30px" }}>
          Proceed with ordering the chemical only after OIMS-SHE notification is
          provided (by email)
        </li>
      </ul>
    </ul>
    <ul style={{ marginLeft: "30px" }}>
      <li>
        Don’t forget to conduct appropriate HIRAM/LTO/CSOT/MOC needs with the
        addition of new chemicals as per standard existing procedures
      </li>
    </ul>

    <h4>Engineered Nanomaterial Definition</h4>
    <ul style={{ marginLeft: "30px" }}>
      <li>
        Any engineered nanomaterial utilized for the purpose of research and/or
        generated as a result of research such that:
      </li>
      <ul>
        <li style={{ marginLeft: "30px" }}>
          Includes agglomerated as well as non-agglomerated nanomaterials found
          in liquid suspension(s) or as solid material that has the potential to
          be dispersed or broken down as a result of research activities or
          potential downstream uses in ExxonMobil products or operations
        </li>
        <li style={{ marginLeft: "30px" }}>
          Excludes purchased devices, instrumentation, or equipment where
          nanomaterials
        </li>
      </ul>
    </ul>
  </div>
);
