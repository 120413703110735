import API from '../index'

export default class STRequestInterval {
    constructor(
        IntervalName,
        RequestID,
        ThisRequest,
        IntervalValue,
        TestJSON,
        RetainContainerJSON,
        ChemID,
        IntervalSubstance,
        InitialLocationName,
        InitialLocation,
        ReturnLocationName,
        ReturnLocation,
    ) {
        this.IntervalName = IntervalName
        this.RequestID = RequestID
        this.ThisRequest = ThisRequest
        this.IntervalValue = IntervalValue
        this.TestJSON = TestJSON
        this.RetainContainerJSON = RetainContainerJSON
        this.ChemID = ChemID
        this.IntervalSubstance = IntervalSubstance
        this.InitialLocationName = InitialLocationName
        this.InitialLocation = InitialLocation
        this.ReturnLocationName = ReturnLocationName
        this.ReturnLocation = ReturnLocation
    }
    
    static async populateInterval(requestId, isReference) {
        const api = await API();
        const { data } = await api.post(`/strequestinterval/template/${requestId}`, isReference);
        return data.result;
    }

    static async saveIntervals(requestId, isCandidate, payload) {
        const api = await API();
        const { data } = await api.put(`/strequestinterval/batch/${requestId}/${isCandidate}`, payload);
        return data;
    }

    static async registerSamples(payload) {
        const api = await API();
        const { data } = await api.post('/strequestinterval/register/samples', payload);
        return data;
    }

    static async getAllSTRequestIntervalsForRequest(requestId) {
        const api = await API();
        const { data } = await api.get(`/strequestinterval/requestid/${requestId}`);
        if (data && data.result && data.result.length > 0) return data.result;
        return [];
    }
}
