import API from "..";

export default class ExperimentalSubstanceType {
  /**
   * The ExperimentalSubstanceType dimension table for M3
   * @param TODO
   */
  constructor({
    ecTypeName,
    isActive
  }) {
    this.ecTypeName = ecTypeName;
    this.isActive = isActive;
  }

  static async getAll() {
    const api = await API();
    const { data } = await api.get(`/experimentalsubstancetype`);
    return data.result.map((d) => new ExperimentalSubstanceType(d));
    
  }
}