import API from '../index'

export default class STParameter {
    constructor({
        testParameterName,
        isActive,
    }) {
        this.testParameterName = testParameterName
        this.isActive = isActive
    }

    static async getAll() {
        const api = await API()
        const { data } = await api.get('/stparameter')
        return data.result.map(parameter => new STParameter(parameter))
    }

    static async getAllActive() {
        const api = await API()
        const { data } = await api.get('/stparameter')
        if (data && data.result && data.result.length > 0) {
            return data.result.filter((r) => r.isActive)
        }
        return []
    }

    static async get(name) {
        const api = await API();
        const { data } = await api.get(`stparameter/${name}`);
        if (data.result) {
            return new STParameter(data.result);
        }
        else {
            return null;
        }
    }

    static async add(payload) {
        const api = await API();
        const { data } = await api.post('/stparameter', payload);
        return new STParameter(data.result);
    }

    static async update(payload) {
        const api = await API();
        const { data } = await api.put('/stparameter', payload);
        return new STParameter(data.result);
    }
}
