import API from "..";

export type PackageTestCriteria = {
  id: number;
  packageTestName: string;
  //packageTestMethod: any;
  methodFacilityID: number;
  criteriaMethodFacility: any;
  parentTestCriteriaID: number | null;
  parentTestCriteria: any | null;
  defaultNewSampleChemID: string | null;
  defaultNewSampleSubstance: any | null;
  generatesNewSample: boolean;
  stepDescription: string | null;
  conditionalTrigger: string | null;
  scheduledIntervalHours: number | null;
  testConditionOffering: any;
  testConditionOfferingID: number;
  postTestSampleYield: number | null;
  postTestSampleYieldUoMName: string | null;
  childPackageTestCriteria: PackageTestCriteria[];
};

export class PackageTestCriteriaApi {
  static async getAll(): Promise<PackageTestCriteria[]> {
    const api = await API();

    if (!api) {
      throw new Error("Failed to create an API instance");
    }

    const { data } = await api.get("/packageTestCriteria");

    return data.result;
  }

  static async getByPackageTestName(packageTestName:string){
    const api = await API();

    if (!api) {
      throw new Error("Failed to create an API instance");
    }

    const { data } = await api.get(`/packageTestCriteria/getByPackageTestName?packageName=${packageTestName}`);

    return data.result;
  }
}
