import API from "..";

export default class ShippingAddress {

  constructor({
    etqid,
    recipientName,
    company,
    department,
    address1,
    address2,
    city,
    state,
    country,
    postalCode,
    phoneNumber1,
    emailAddress,
    comments,
    createdByEmail,
    createdDate,
    lastModifiedByEmail,	
    lastModifiedDate
  }) 
    
  {
    this.etqid = etqid;
    this.recipientName = recipientName;
    this.company = company;
    this.department = department;
    this.address1 = address1;
    this.address2 = address2;
    this.city = city;
    this.state = state;
    this.country = country;
    this.postalCode = postalCode;
    this.phoneNumber1 = phoneNumber1;
    this.emailAddress = emailAddress;
    this.comments = comments;
    this.createdByEmail = createdByEmail;
    this.createdDate = createdDate;
    this.lastModifiedByEmail = lastModifiedByEmail;	
    this.lastModifiedDate = lastModifiedDate;
  }

  static async getAll() {
    const api = await API();
    const { data } = await api.get(`/shippingAddress`);
    if (data.result) {
        return data.result.map((d) => new ShippingAddress(d));
    }
    else {
        return [];
    }
  }
}
