import API from "..";

export default class BatchStatus {

  static async deleteBlendAttachment(id) {
    const api = await API();
    const { data } = await api.delete(`/blendRequestAttachment/${id}`);
    return data;
  }

  static async deleteBatchAttachment(id) {
    const api = await API();
    const { data } = await api.delete(`/batchAttachment/${id}`);
    return data;
  }
}
