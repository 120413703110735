export default class BatchSubmission {

  constructor({
    batchToSubmit, 
    containersWithTests 
}) 
    
  {
    this.batchToSubmit = batchToSubmit;
    this.containersWithTests = containersWithTests;

  }
}
