import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { FormikProvider, useFormik, yupToFormErrors } from "formik";
import { useEffect, useState } from "react";
import { PackageTestCriteria } from "../../../api/Admin/PackageTestCriteria";
import UnitOfMeasure from "../../../api/Admin/UnitOfMeasure";
import EmailAddressTextField from "../../../components/EmailAddressField";
import { ConvertUOMs } from "../../../global";
import { GlobalButton, GlobalSecondaryButton } from "../../styles";
import { PackageTestCriterion } from "./PackageTestCriterion";
import {
  PackageTestForm,
  addCriterion,
  buildCriteriaTree,
  flattenTree,
  packageTestSchema,
  removeCriterion,
  updateCriteria,
} from "./utilities";

const validStyle = {
  "& label": {
    color: "green",
  },
  "& .MuiOutlinedInput-input": {
    color: "green",
    "&:focus": {
      color: "black",
    },
    "&:hover": {
      color: "black",
    },
  },
  "& label.Mui-focused": {
    color: "green",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "green",
    },
    "&:hover fieldset": {
      borderColor: "green",
    },
    "&.Mui-focused fieldset": {
      borderColor: "green",
    },
  },
};

type PackageTestManagementFormProps = {
  initialValue: PackageTestForm;
  onCancel(): void;
  onSave(values: PackageTestForm): void;
  methods: any[];
};

export function PackageTestManagementForm({
  initialValue,
  onCancel,
  onSave,
  methods,
}: PackageTestManagementFormProps) {
  const [unitsOfMeasure, setUnitsOfMeasure] = useState<UnitOfMeasure[]>([]);

  useEffect(() => {
    UnitOfMeasure.getAll().then((data) =>
      setUnitsOfMeasure(
        data
          .filter(
            (u: UnitOfMeasure) =>
              u.isActive &&
              (u.type === "weight" || u.type === "volume") &&
              u.metricStandardConversion !== null
          )
          .sort((a: UnitOfMeasure, b: UnitOfMeasure) =>
            a.uoMName.localeCompare(b.uoMName)
          )
      )
    );
  }, []);

  const formik = useFormik({
    initialValues: {
      name: initialValue.name,
      description: initialValue.description,
      isActive: initialValue.isActive,
      packageTestOwnerEmail: initialValue.packageTestOwnerEmail,
      isPackageTest: initialValue.isPackageTest,
      packageTestCriterias: buildCriteriaTree(
        initialValue.packageTestCriterias
      ),
      packageTestRequiredAmount: initialValue.packageTestRequiredAmount,
      packageTestRequiredAmountUoMName:
        initialValue.packageTestRequiredAmountUoMName,
    },
    onSubmit: onSave,
    // validationSchema: packageTestSchema,
    validate: (values) => {
      const additionalErrors: Record<string, string> = {};

      if (unitsOfMeasure.length > 0 && values.packageTestCriterias.length > 0) {
        const targetUom = unitsOfMeasure.find(
          (u) => u.uoMName === values.packageTestRequiredAmountUoMName
        );

        const criteriaAmount = flattenTree(values.packageTestCriterias)
          .filter((p) => p.generatesNewSample)
          .reduce((previousAmount, criterion) => {
            const sourceUom = unitsOfMeasure.find(
              (u) => u.uoMName === criterion.postTestSampleYieldUoMName
            )!;

            const currentAmount = ConvertUOMs(
              null,
              criterion.defaultNewSampleSubstance,
              criterion.postTestSampleYield,
              sourceUom,
              targetUom
            );

            if (typeof currentAmount !== "number") {
              console.warn("Failed to convert UoM for criterion", criterion);
              return previousAmount;
            }

            return +(previousAmount + currentAmount).toFixed(3);
          }, 0);

        if (
          values.packageTestRequiredAmount != null &&
          criteriaAmount > values.packageTestRequiredAmount
        ) {
          additionalErrors[
            "packageTestCriterias"
          ] = `Yield amount in criteria (${criteriaAmount}${targetUom?.uoMName}) exceeds required amount in Package Test (${values.packageTestRequiredAmount}${targetUom?.uoMName})`;
        }
      }

      try {
        packageTestSchema.validateSync(values, { abortEarly: false });
      } catch (e) {
        return {
          ...yupToFormErrors(e),
          ...additionalErrors,
        };
      }

      return additionalErrors;
    },
    validateOnMount: true,
  });

  const handleAddCriterion = (parentCriterion?: PackageTestCriteria) => {
    const newCriterion = {
      id: 0,
      generatesNewSample: false,
      stepDescription: null,
      //stepDescription: "New Criterion",
      childPackageTestCriteria: [] as PackageTestCriteria[],
    } as PackageTestCriteria;

    if (formik.values.name.length !== 0) {
      newCriterion.packageTestName = !formik.values.name.startsWith("PKG-") ? `PKG-${formik.values.name}` : formik.values.name;
    }

    formik.setFieldValue(
      "packageTestCriterias",
      addCriterion(
        formik.values.packageTestCriterias,
        newCriterion,
        parentCriterion
      )
    );
  };

  const handleRemoveCriterion = (packageTestCriterion: PackageTestCriteria) => {
    formik.setFieldValue(
      "packageTestCriterias",
      removeCriterion(formik.values.packageTestCriterias, packageTestCriterion)
    );
  };

  return (
    <FormikProvider value={formik}>
      <form noValidate onSubmit={formik.handleSubmit}>
        <Stack direction="row" alignItems="center" gap={2}>
          <TextField
            name="name"
            label="Name"
            variant="outlined"
            size="small"
            value={formik.values.name ?? ""}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.name && Boolean(formik.errors.name)}
            inputProps={{ maxLength: 50 }}
            disabled={initialValue.name.length > 0}
            margin="dense"
            sx={[
              { width: "24rem" },
              !Boolean(formik.errors.name) && validStyle,
            ]}
            helperText={formik.errors.name || ""}
          />

          <FormControlLabel
            control={
              <Checkbox
                name="isActive"
                checked={formik.values.isActive}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            }
            label="Active"
          />
        </Stack>

        <EmailAddressTextField
          fontSize={16}
          fieldWidth={"25rem"}
          validatedUserEmail={formik.values.packageTestOwnerEmail}
          setValidatedUserEmail={(value: string) =>
            formik.setFieldValue("packageTestOwnerEmail", value)
          }
          setHasErrors={(value: boolean) =>
            formik.setFieldError(
              "packageTestOwnerEmail",
              value ? "invalid" : undefined
            )
          }
          hasErrors={Boolean(formik.errors.packageTestOwnerEmail)}
          isDisabled={false}
          labelText="Owner"
          placeholderText="Owner"
          size="small"
          margin="dense"
          showPlusMeButton={true}
        />

        <TextField
          name="description"
          label="Description"
          variant="outlined"
          size="small"
          value={formik.values.description ?? ""}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={
            formik.touched.description && Boolean(formik.errors.description)
          }
          inputProps={{ maxLength: 200 }}
          margin="normal"
          sx={{ width: "24rem" }}
        />

        <Box sx={{ display: "flex", gap: 2 }}>
          <TextField
            name="packageTestRequiredAmount"
            label="Required Amount"
            variant="outlined"
            size="small"
            value={formik.values.packageTestRequiredAmount ?? ""}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.packageTestRequiredAmount &&
              Boolean(formik.errors.packageTestRequiredAmount)
            }
            inputProps={{ maxLength: 200 }}
            margin="normal"
            sx={{ width: "12rem" }}
          />
          <Autocomplete
            autoHighlight
            autoSelect
            options={unitsOfMeasure ?? []}
            value={
              formik.values.packageTestRequiredAmountUoMName
                ? ({
                    uoMName: formik.values.packageTestRequiredAmountUoMName,
                  } as UnitOfMeasure)
                : null
            }
            isOptionEqualToValue={(option, value) =>
              option.uoMName === value.uoMName
            }
            getOptionLabel={(option) => option.uoMName}
            renderOption={(props, option) => (
              <li {...props} key={option.uoMName}>
                {option.uoMName}
              </li>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                name="packageTestRequiredAmountUoMName"
                label="UoM"
                variant="outlined"
                size="small"
                error={
                  formik.touched.packageTestRequiredAmountUoMName &&
                  Boolean(formik.errors.packageTestRequiredAmountUoMName)
                }
                margin="normal"
              />
            )}
            onChange={(event, option) =>
              formik.setFieldValue(
                "packageTestRequiredAmountUoMName",
                option?.uoMName ?? null
              )
            }
            //onBlur={formik.handleBlur} EWD had to remove this to make the validation work correctly with tab selection
            sx={{ width: "14rem" }}
          />
        </Box>

        <Stack direction="row" alignItems="center" gap={2} marginY={2}>
          <Typography variant="h6">Test Steps</Typography>

          <GlobalButton
            type="button"
            variant="contained"
            onClick={() => handleAddCriterion()}
          >
            Add Root Step
          </GlobalButton>
        </Stack>

        {Boolean(formik.errors.packageTestCriterias) && (
          <Typography color="error" gutterBottom>
            {JSON.stringify(formik.errors.packageTestCriterias)}
          </Typography>
        )}

        <Box component="ul" sx={{ maxWidth: "64rem" }}>
          {formik.values.packageTestCriterias.map((p, index) => (
            <PackageTestCriterion
              key={index}
              packageTestCriterion={p}
              onAdd={(parentCriterion) => handleAddCriterion(parentCriterion)}
              onDelete={(packageTestCriterion) =>
                handleRemoveCriterion(packageTestCriterion)
              }
              onUpdate={(previousValue, newValue) => {
                const newCriteria = updateCriteria(
                  formik.values.packageTestCriterias,
                  previousValue,
                  newValue
                );

                formik.setFieldValue("packageTestCriterias", newCriteria);
              }}
              methods={methods}
              unitsOfMeasure={unitsOfMeasure ?? []}
            />
          ))}
        </Box>

        <Stack direction="row" alignItems="center" gap={1} marginTop={2}>
          <GlobalButton
            type="submit"
            variant="contained"
            disabled={!formik.isValid}
          >
            Save
          </GlobalButton>

          <GlobalSecondaryButton
            type="button"
            variant="outlined"
            onClick={onCancel}
          >
            Cancel
          </GlobalSecondaryButton>
        </Stack>
      </form>
    </FormikProvider>
  );
}
