import API from "..";

export default class batchComponent {

  constructor({
    id, 
    batchID, 
    batch,
    containerID, 
    container,
    substitutionReason,
    actualAmount, 
    orderAdded,
    uoMName,
    uoM,
    blendComponentID,
    blendComponent,
    actualTemperatureC,
    selectedSample,
    selectedSubstance,
    discardContainerUponPrep
}) 
    
  {
    this.id = id;
    this.batchID = batchID;
    this.batch = batch;
    this.containerID = containerID; 
    this.container = container;
    this.substitutionReason = substitutionReason;
    this.actualAmount = actualAmount;
    this.orderAdded = orderAdded;
    this.uoMName = uoMName;
    this.uoM = uoM;
    this.blendComponentID = blendComponentID;
    this.blendComponent = blendComponent;
    this.actualTemperatureC = actualTemperatureC;
    this.selectedSample = selectedSample;
    this.selectedSubstance = selectedSubstance;
    this.discardContainerUponPrep = discardContainerUponPrep;
  }

  static async CreateBatchComponents(batchComponents) {
    const api = await API();
    const { data } = await api.post('/batchComponent', batchComponents);
    return (data);
  }

  static async delete(componentID) {
    const api = await API();
    const { data } = await api.delete(`/batchComponent/${componentID}`);
    return (data);
  }
}
