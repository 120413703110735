import API from "..";

export default class TestActivity {
    /**
     * The Container dimension table for M3
     * @param TODO
     */
     constructor({
      sampleName,
      methodName,
      testFacilityAbv,
      testSequenceNumber,
      testStatusName,
      testPriorityName,
      requesterEmail,
      submitterEmail,
      sampleOwnerEmail,
      submittedDate,
      requestedCompletionDate,
      receivedDate,
      inProgressDate,
      reportedDate,
      testedDate
    }) {
      this.sampleName = sampleName;
      this.methodName = methodName;
      this.testFacilityAbv = testFacilityAbv;
      this.testSequenceNumber = testSequenceNumber;
      this.testStatusName = testStatusName;
      this.testPriorityName = testPriorityName;
      this.requesterEmail = requesterEmail;
      this.submitterEmail = submitterEmail;
      this.sampleOwnerEmail = sampleOwnerEmail;
      this.submittedDate = submittedDate;
      this.requestedCompletionDate = requestedCompletionDate;
      this.receivedDate = receivedDate;
      this.inProgressDate = inProgressDate;
      this.reportedDate = reportedDate;
      this.testedDate = testedDate;
    }
  
    static async getAll(userName, maxResults) {
      const api = await API();
      const { data } = await api.get(`/testActivity/${userName}?maxResults=${maxResults}`);
      if (data.result && data.result.length > 0) {
        return data.result.map((d) => new TestActivity(d));
      } else {
        return null
      }
    }
}