import API from "../../api";
export default class ApplicationPreference {
  /**
   * The Instrument dimension table for M3
   * @param {string} UserEmail
   * @param {bool} SubmitSamplesViaSMG
   * @param {int} DefaultRegistrationTemplateID
   * @param {bool} ReceiveEmailNotifications
   * @param {bool} ShowWorkRequestsWidget
   * @param {bool} ShowRecentSamplesWidget
   * @param {bool} ShowRecentTestsWidget
   * @param {bool} ShowRecentBlendsWidget
   * @param {DateTime} LastLogin
   * @param {string} WorkRequestBusinessUnitName
   * @param {List<Project>} MemberProjects
   */
  constructor({
    userEmail,
    submitSamplesViaSMG,
    defaultRegistrationTemplateID,
    receiveEmailNotifications,
    showWorkRequestWidget,
    showRecentSamplesWidget,
    showRecentTestsWidget,
    showRecentBlendsWidget,
    lastLogin,
    workRequestBusinessUnitName,
    memberProjects
  }) {
    this.userEmail = userEmail;
    this.submitSamplesViaSMG = submitSamplesViaSMG;
    this.defaultRegistrationTemplateID = defaultRegistrationTemplateID;
    this.receiveEmailNotifications = receiveEmailNotifications;
    this.showWorkRequestWidget = showWorkRequestWidget;
    this.showRecentSamplesWidget = showRecentSamplesWidget;
    this.showRecentTestsWidget = showRecentTestsWidget;
    this.showRecentBlendsWidget = showRecentBlendsWidget;
    this.lastLogin = lastLogin;
    this.workRequestBusinessUnitName = workRequestBusinessUnitName;
    this.memberProjects = memberProjects;
  }

  static async getByEmail(userEmail){
    const api = await API();
    const { data } = await api.get(`/applicationPreference/${userEmail}`);
    if (data.result) {
      return new ApplicationPreference(data.result);
    }
    else {
      return new ApplicationPreference();
      //return null;
    }
  }

  static async update(userEmail, info) {
    const api = await API();
    const { data } = await api.put(`/applicationPreference/${userEmail}`, info);
    return new ApplicationPreference(data.result);
  }
}