import React, { useState, useEffect } from "react";
import { Box, Divider, Typography } from "@mui/material";
import SearchSamples from "./SearchSamples";
import SearchTests from "./SearchTests";
import SearchContainers from "./SearchContainers";
import SearchRequests from "./SearchRequests";
import SearchBlends from "./SearchBlends";
import SearchDistillations from "./SearchDistillations";
import SearchStationaryTests from "./SearchStationaryTests";
import Sample from "../../api/LIMS/Sample";
import Container from "../../api/LIMS/Container";
import BlendStudy from "../../api/Formulations/BlendStudy";
import WorkRequest from "../../api/WorkRequest/WorkRequest";
import DistillationExperiment from "../../api/Distillation/DistillationExperiment";
import STRequest from "../../api/StationaryTesting/STRequest";
import { GlobalTabCollection, StyledTab } from "../../pages/styles";
import { GlobalTab } from "./shared";

const GlobalSearch = (props) => {
  const { location } = props;
  const [inputSearchCriteria, setInputSearchCriteria] = useState("");
  const [tabValue, setTabValue] = React.useState(0);

  const [dataIsLoading, setDataIsLoading] = useState(true);
  const [blendDataIsLoading, setBlendDataIsLoading] = useState(true)
  const [wrDataIsLoading, setWRDataIsLoading] = useState(true)
  const [experimentDataIsLoading, setExperimentDataIsLoading] = useState(true)
  const [stRequestDataIsLoading, setStRequestDataIsLoading] = useState(true)

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  useEffect(() => {
    if (location.state) {
      setInputSearchCriteria(location.state.searchCriteria);
      const searchIndex = parseInt(location.state.searchLocation) - 1;
      setTabValue(searchIndex < 0 ? searchIndex + 1 : searchIndex);
    }
  }, [location.state]);

  //call the sample API prior to getting to the sample search screen
  //this API will be used for Samples, containers and tests
  const [sampleSearchArray, setSampleSearchArray] = useState([]);
  const [myContainers, setMyContainers] = useState([]);
  const [myBlends, setMyBlends] = useState([])
  const [myWorkRequests, setMyWorkRequests] = useState([])
  const [myExperiments, setMyExperiments] = useState([])
  const [myStRequests, setMyStRequests] = useState([])

  useEffect(() => {
    let cancelPromise = false

    if (inputSearchCriteria) {
      setDataIsLoading(true)
      setSampleSearchArray([]);

      setBlendDataIsLoading(true)
      setMyBlends([]);

      //let searchCriteriaArray = inputSearchCriteria.replace(/\s+/g, "").split(",");

      if (tabValue === GlobalTab.Samples || tabValue === GlobalTab.Tests) {
        Sample.quickSearch(inputSearchCriteria).then((res) => {
          if (cancelPromise) return

          if (res.message === 'Success') {
            setSampleSearchArray(res.result.sort((a, b) => new Date(b.createdDate) - new Date(a.createdDate)))
          }

          setDataIsLoading(false)
        });
      }

      if (tabValue === GlobalTab.Containers) {
        Container.quickSearch(inputSearchCriteria).then((res) => {
          if (cancelPromise) return

          if (res.message === 'Success') {
            setMyContainers(res.result.sort((a, b) => a.sampleName - b.sampleName).sort((a, b) => new Date(b.createdDate) - new Date(a.createdDate)))
          }

          setDataIsLoading(false)
        });
      }
      if (tabValue === GlobalTab.Blends) {
        BlendStudy.quickSearch(inputSearchCriteria).then((res) => {
          if (cancelPromise) return

          if (res.message === 'Success') {
            setMyBlends(res.result.sort((a, b) => new Date(b.createdDate) - new Date(a.createdDate)))
          }

          setBlendDataIsLoading(false)
        });
      }
      if (tabValue === GlobalTab.Requests) {
        WorkRequest.quickSearch(inputSearchCriteria).then((res) => {
          if (cancelPromise) return

          if (res.message === 'Success') {
            setMyWorkRequests(res.result.sort((a, b) => new Date(b.createdDate) - new Date(a.createdDate)))
          }

          setWRDataIsLoading(false)
        });
      }
      if (tabValue === GlobalTab.Distillations) {
        DistillationExperiment.quickSearch(inputSearchCriteria).then((res) => {
          if (cancelPromise) return

          if (res.message === 'Success') {
            setMyExperiments(res.result.sort((a, b) => new Date(b.createdDate) - new Date(a.createdDate)))
          }

          setExperimentDataIsLoading(false)
        });
      }
      if (tabValue === GlobalTab.StationaryTests) {
        STRequest.quickSearch(inputSearchCriteria).then((res) => {
          if (cancelPromise) return

          if (res.message === 'Success') {
            setMyStRequests(res.result.sort((a, b) => new Date(b.createdDate) - new Date(a.createdDate)))
          }

          setStRequestDataIsLoading(false)
        });
      }
    }

    return () => {
      cancelPromise = true
    }

  }, [inputSearchCriteria, tabValue]);


  const renderSearchTab = () => {
    switch (tabValue) {
      case GlobalTab.Samples: {
        return (
          <SearchSamples
            inputSearchCriteria={inputSearchCriteria}
            sampleSearchArray={sampleSearchArray}
            dataIsLoading={dataIsLoading}
          ></SearchSamples>
        );
      }
      case GlobalTab.Tests: {
        return (
          <SearchTests
            inputSearchCriteria={inputSearchCriteria}
            sampleSearchArray={sampleSearchArray}
            dataIsLoading={dataIsLoading}
          ></SearchTests>
        );
      }
      case GlobalTab.Containers: {
        return (
          <SearchContainers
            inputSearchCriteria={inputSearchCriteria}
            myContainers={myContainers}
            dataIsLoading={dataIsLoading}
          ></SearchContainers>
        );
      }
      case GlobalTab.Requests: {
        return (
          <SearchRequests
            inputSearchCriteria={inputSearchCriteria}
            myWorkRequests={myWorkRequests}
            dataIsLoading={wrDataIsLoading}
          ></SearchRequests>
        )
      }
      case GlobalTab.Blends: {
        return (
          <SearchBlends
            inputSearchCriteria={inputSearchCriteria}
            myBlends={myBlends}
            dataIsLoading={blendDataIsLoading}
          ></SearchBlends>
        )
      }
      case GlobalTab.Distillations: {
        return (
          <SearchDistillations
            inputSearchCriteria={inputSearchCriteria}
            myExperiments={myExperiments}
            dataIsLoading={experimentDataIsLoading}
          ></SearchDistillations>
        )
      }
      case GlobalTab.StationaryTests: {
        return (
          <SearchStationaryTests
            inputSearchCriteria={inputSearchCriteria}
            myStRequests={myStRequests}
            dataIsLoading={stRequestDataIsLoading}
          ></SearchStationaryTests>
        )
      }
      default: {
        alert(tabValue);
      }
    }
  };

  return (
    <div>
      <Typography variant='h5' gutterBottom component='div'>
        {inputSearchCriteria ? 'Results for "' + inputSearchCriteria + '"' : "Search All"}
      </Typography>
      <Divider className='dividerbar' />
      <Box sx={{ bgcolor: "#fff" }}>
        <GlobalTabCollection style={{ marginRight: "1rem" }} scrollButtons="auto" variant="scrollable" value={tabValue} onChange={handleChange} aria-label='ant example'>
          {/* <GlobalTabCollection value={tabValue} onChange={handleChange} aria-label='ant example'> */}
          <StyledTab label='Samples' />
          <StyledTab label='Tests' />
          <StyledTab label='Containers' />
          <StyledTab label='Requests' />
          <StyledTab label='Blends' />
          <StyledTab label='Distillations' />
          <StyledTab label='Stationary Tests' />
        </GlobalTabCollection>
        <Box sx={{ p: 3 }} />
      </Box>
      {renderSearchTab()}
    </div>
  );
};

export default GlobalSearch;
