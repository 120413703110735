import React, { useState } from "react";
import ModalTwoButtons from "../../components/Modal/ModalTwoButtons";
import EmailAddressTextField from "../../components/EmailAddressField";

export default function AssignTechnicianModal({
  workRequestId,
  assignTechnician,
  assignTechnicianModal,
  setAssignTechnicianModal,
}) {
  const [assignTechnicianEmail, setAssignTechnicianEmail] = useState(null);
  const [assignTechnicianError, setAssignTechnicianError] = useState(true);
  return (
    <ModalTwoButtons
      title={"Assign Technician - " + workRequestId}
      button1Text={"Ok"}
      isButton1Disabled={assignTechnicianError}
      button1Action={() => {
        assignTechnician(assignTechnicianEmail);
        setAssignTechnicianModal(false);
      }}
      button2Text={"Cancel"}
      button2Action={() => {
        setAssignTechnicianModal(false);
      }}
      open={assignTechnicianModal}
      setOpen={setAssignTechnicianModal}
    >
      <EmailAddressTextField
        fontSize={16}
        fieldWidth={"350px"}
        validatedUserEmail={assignTechnicianEmail}
        setValidatedUserEmail={(e) => {
          setAssignTechnicianEmail(e);
        }}
        hasErrors={assignTechnicianError}
        setHasErrors={(e) => {
          setAssignTechnicianError(e);
        }}
        isDisabled={false}
        labelText="Technician Email"
        placeholderText="Technician Email"
        showPlusMeButton={true}
        size="small"
        margin="normal"
      ></EmailAddressTextField>
    </ModalTwoButtons>
  );
}
