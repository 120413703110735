import API from "../index";

export default class StockroomPO {
  constructor({
    id,
    createdByEmail,
    createdDate,
    lastModifiedByEmail,
    lastModifiedDate,
    requestedByEmail,
    completedByEmail,
    completeDate,
    comments,
    vendorId,
    vendor,
    statusName,
    poItems,
  }) {
    this.id = id;
    this.createdByEmail = createdByEmail;
    this.createdDate = createdDate;
    this.lastModifiedByEmail = lastModifiedByEmail;
    this.lastModifiedDate = lastModifiedDate;
    this.requestedByEmail = requestedByEmail;
    this.completedByEmail = completedByEmail;
    this.completeDate = completeDate;
    this.comments = comments;
    this.vendorId = vendorId;
    this.vendor = vendor;
    this.statusName = statusName;
    this.poItems = poItems;
  }

  static async getStockroomPOs() {
    const api = await API();
    const { data } = await api.get(`/stockroompo`);
    return data.result.map((po) => new StockroomPO(po));
  }

  static async managePO(info) {
    const api = await API();
    const { data } = await api.post(`/stockroompo`, info);
    return new StockroomPO(data.result);
  }

  static async getPOsForItem(itemID, searchFrom) {
    const api = await API();
    const { data } = await api.get(
      `/stockroompo/getPOsByEquipmentID?equipmentID=${itemID}&minPODate=${searchFrom}`,
    );
    return data.result.map((po) => new StockroomPO(po));
  }
}
