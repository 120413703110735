import {
    TextField, 
    Autocomplete, 
    Box,
    AccordionSummary,
    AccordionDetails,
} from "@mui/material";
import { outlinedInputClasses } from "@mui/material/OutlinedInput";
import { inputLabelClasses } from "@mui/material/InputLabel";
import { styled } from "@mui/material/styles";

const autoCompleteFontSize = 16

export const ValidatedTextField = styled(TextField)({
    [`& .${outlinedInputClasses.root} .${outlinedInputClasses.notchedOutline}`]: {
      borderColor: "green"
    },
    [`&:hover .${outlinedInputClasses.root} .${outlinedInputClasses.notchedOutline}`]: {
      borderColor: "green"
    },
    [`& .${outlinedInputClasses.root}.${outlinedInputClasses.focused} .${outlinedInputClasses.notchedOutline}`]: {
      borderColor: "green"
    },
    [`& .${outlinedInputClasses.input}`]: {
      color: "green"
    },
    [`&:hover .${outlinedInputClasses.input}`]: {
      color: "green"
    },
    [`& .${outlinedInputClasses.root}.${outlinedInputClasses.focused} .${outlinedInputClasses.input}`]: {
      color: "green"
    },
    [`& .${inputLabelClasses.outlined}`]: {
      color: "green"
    },
    [`&:hover .${inputLabelClasses.outlined}`]: {
      color: "green"
    },
    [`& .${inputLabelClasses.outlined}.${inputLabelClasses.focused}`]: {
      color: "green"
    },

    id:"outlined-normal",
});

export const StyledScrollingAccordionDetails = styled(AccordionDetails)({
    overflowX: 'auto',
  
    '&::-webkit-scrollbar': {
      width: '10px',
      height: '10px',
    },
  
    '&::-webkit-scrollbar-track': {
      background: 'white',
    },
  
    '&::-webkit-scrollbar-thumb': {
      background: '#1976d2',
      borderRadius: '4px',
    },
  });

export const StyledShortTextField = styled(TextField)({
    id: "outlined-normal",
    width: "22%",
});

export const StyledTextarea = styled(TextField)({
    width: '50%',
    marginTop: 16,
    marginBottom: 16,
});

export const StyledMediumTextField = styled(TextField)({
    id: "outlined-normal",
    width: "50%",
});

export const StyledAutocomplete = styled(Autocomplete)({
    '& .MuiAutocomplete-input, & .MuiInputLabel-root': {
      fontSize: autoCompleteFontSize,
    },
    width:"22%",
});

export const StyledAccordionSummary = styled(AccordionSummary)({
    backgroundColor: '#0C69B0',

    '& .MuiAccordionSummary-content': {
        alignItems: 'center',
    },
});

export const AutoCompleteBox = styled(Box)({
    display: 'flex',
    padding: 8,
    backgroundColor: 'white',
    width: 400,
    borderRadius: 4,
});