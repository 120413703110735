import { Data, Shape } from "plotly.js";
import { useRef } from "react";
import Plot from "react-plotly.js";

function getSizeInPixels(
  element: Element | null,
  dimension: "width" | "height"
): number {
  return element?.parentElement
    ? +getComputedStyle(element?.parentElement)[dimension].slice(0, -2)
    : 0;
}

type ChartProps = {
  title: string;
  data: Data[];
  shapes?: Partial<Shape>[];
};

export function Chart({ title, data, shapes }: ChartProps) {
  const ref = useRef<Plot>(null);

  const element = (ref.current as any)?.el as HTMLElement | null;
  const width = getSizeInPixels(element, "width");
  const height = getSizeInPixels(element, "height");

  return (
    <Plot
      ref={ref}
      data={data}
      layout={{
        autosize: false,
        width,
        height,
        xaxis: { fixedrange: true },
        yaxis: { fixedrange: true },
        hoverlabel: {
          bgcolor: "white",
          font: {
            size: 16,
          },
        },
        hovermode: "closest",
        shapes,
        title,
      }}
      config={{
        // displayModeBar: false,
        responsive: true,
        displaylogo: false,
        editable: false,
        modeBarButtonsToRemove: ["lasso2d", "select2d"],
        showTips: false,
      }}
    />
  );
}
