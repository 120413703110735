import API from '../index'

export default class DistillationTemplateCut {
    constructor(
        id,
        distillationCutTypeName,
        thisDistillationCutType,
        chemID,
        thisSubstance,
        startingTemperatureC,
        endTemperatureC,
        requiresRefridgeration,
        testingTemplateJSON,
        testingReturnLocationName,
        testingReturnLocation,
        distillationTemplateName,
        distillationTemplate,
    ) {
        this.id = id
        this.distillationCutTypeName = distillationCutTypeName
        this.thisDistillationCutType = thisDistillationCutType
        this.chemID = chemID
        this.thisSubstance = thisSubstance
        this.startingTemperatureC = startingTemperatureC
        this.endTemperatureC = endTemperatureC
        this.requiresRefridgeration = requiresRefridgeration
        this.testingTemplateJSON = testingTemplateJSON
        this.testingReturnLocationName = testingReturnLocationName
        this.testingReturnLocation = testingReturnLocation
        this.distillationTemplateName = distillationTemplateName
        this.distillationTemplate = distillationTemplate
    }

    static async getAllTemplateCuts() {
        const api = await API();
        const { data } = await api.get('/distillationTemplateCut');
        return data.result.map((templateCut) => new DistillationTemplateCut(templateCut));
    }

    static async deleteTemplateCut(cutID) {
        const api = await API();
        const { data } = await api.delete(`/distillationTemplateCut/${cutID}`);
        return data;
    }
}