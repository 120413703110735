import React, { useRef } from "react";
import {
  Box,
  FormControl,
  TextField,
  Checkbox,
  styled,
  Autocomplete,
  Grid,
  Button
} from "@mui/material";

import FormControlLabel from '@mui/material/FormControlLabel';
import MethodManagementDataGrid from "./MethodManagementDataGrid";
import MethodManagementModalOffering from "./MethodManagementModalOffering";
import EmailAddressTextField from "../../components/EmailAddressField";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const StyledBox = styled(Box)(() => ({
  display: 'flex',
  marginBottom: "20px",
  gap: "20px",
  justifyContent: "normal!important"
}));


const StyledTextFieldGrid = styled(TextField)(() => ({
  width: "100%"
}));

const StyledFormControl = styled(FormControl)(() => ({
  display: 'flex',
  width: "70rem"
}));


const StyledGrid = styled(Grid)(() => ({
  marginBottom: "15px",
  width: "80%"
}));


const StyledDatagridDiv = styled('div')({
  display: 'flex',
  minHeight: '20vh',
  justifyContent: 'space-between',
  marginBottom: '1rem',
});

const columnsResultComponentFacility = [
  { field: 'resultComponentName', headerName: 'Result Component Name', type: 'label', size: 'small' },
  { field: 'lowerLimit', headerName: 'Lower Limit', type: 'decimal', size: 'small' },
  { field: 'upperLimit', headerName: 'Upper Limit', type: 'decimal', size: 'small', step: "1" },
  { field: 'significantDigits', headerName: 'Significant Digits', type: 'integer', size: 'small' }
];

const columnsTestCondtionsOffering = [
  { field: 'testFacilityAbv', headerName: 'Facility', type: 'label', size: 'small' },
  { field: 'conditionOfferingName', headerName: 'Condition Offering Name', type: 'label', size: 'small' },
  { field: 'isActive', headerName: 'Is Active', type: 'checkbox', size: 'small' },
];

const MethodManagementFacility = ({
  invalidField,
  selectedFacility,
  unitOfMeasureList,
  conditionsList,
  canEdit,
  methodFacilityList,
  setMethodFacilityList,
  locationList,
  methodFacilityStatusList,
  setSelectedFacility,
  showFacility }) => {

  const [testOwnerInvalid, setTestOwnerInvalid] = React.useState(false);

  const [openConditionOffering, setOpenConditionOffering] = React.useState(false);
  const [offeringToEdit, setOfferingToEdit] = React.useState(null);
  const [expanded, setExpanded] = React.useState('');

  const formFacilityRef = useRef(null);

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  React.useEffect(() => {
    if (showFacility && showFacility === selectedFacility.testFacilityAbv.id) {
      setExpanded(selectedFacility.testFacilityAbv.id)
      setTimeout(() => {
        formFacilityRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
      }, 400)
    }
  }, [showFacility, selectedFacility, setExpanded])

  function addMethodFacility(prop, value) {
    let index = methodFacilityList.findIndex(obj => { return obj.testFacilityAbv === selectedFacility.testFacilityAbv });

    methodFacilityList[index][prop] = value;
    methodFacilityList[index].testFacilityAbv = selectedFacility.testFacilityAbv
    methodFacilityList[index].changed = true;

    setMethodFacilityList([...methodFacilityList]);
    setSelectedFacility(methodFacilityList[index]);

  }

  const addConditionOffering = () => {
    return (
      <>
        <Button onClick={() => setOpenConditionOffering(true)}
          variant='contained'
          type="button"
        >Add Condition Offering</Button>
      </>

    )
  }

  function addCondition(condition) {
    if (condition !== null) {

      if (selectedFacility.testConditionOfferings.find(obj => { return obj.rowId === condition.rowId || obj.id === condition.id }) === undefined) {
        addMethodFacility('testConditionOfferings', [...selectedFacility.testConditionOfferings, {
          ...condition,
          rowId: condition.conditionOfferingName,
          methodFacilityID: selectedFacility.id,
          testFacilityAbv: selectedFacility.testFacilityAbv?.abreviation ? selectedFacility.testFacilityAbv?.abreviation : selectedFacility.testFacilityAbv
        }])
      } else {
        addMethodFacility('testConditionOfferings',[...selectedFacility.testConditionOfferings.filter(obj => obj.rowId !== condition.rowId || obj.id !== condition.id), {
          ...condition,
          rowId: condition.conditionOfferingName,
          testFacilityAbv: selectedFacility.testFacilityAbv?.abreviation ? selectedFacility.testFacilityAbv?.abreviation : selectedFacility.testFacilityAbv
        }])
      }
    }
  }

  function addMethodFacilityResultComponentList(result) {
    if (result !== null) {

      if (selectedFacility.methodFacilityResultComponents.find(obj => { return obj.id === result.id }) === undefined) {
        addMethodFacility('methodFacilityResultComponents', [...selectedFacility.methodFacilityResultComponents, {
          rowId: result.id,
          resultComponentName: result.id,
          methodFacilityID: selectedFacility.testFacilityAbv.id
        }])
      } else {
        addMethodFacility('methodFacilityResultComponents',[
          ...result,
        ])
      }
    }
  }

  function onCloseModalOffering() {
    setOfferingToEdit(null)
    setOpenConditionOffering(false)
  }

  function clickRowOffering(value) {
    setOfferingToEdit(value)
    setOpenConditionOffering(true)
  }

  return (

    <>
      <Accordion expanded={expanded === selectedFacility.testFacilityAbv.rowId} onChange={handleChange(selectedFacility.testFacilityAbv.rowId)} ref={formFacilityRef}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id={`${selectedFacility.testFacilityAbv.rowId}-header`}
        >
          <Typography>{selectedFacility.testFacilityAbv.rowId}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <StyledGrid container spacing={2} 
            key={`${selectedFacility.testFacilityAbv.rowId}1`} 
            fullWidth>
            <Grid item xs={2}>
              <StyledTextFieldGrid sx={{ mr: 1 }}
                key={`${selectedFacility.testFacilityAbv.rowId}-text-1`}
                onChange={e => addMethodFacility('containeringGroup', e.target.value)}
                id="method-management-containering-group"
                label="Containering Group"
                variant="outlined" size="small" margin="none" 
                value={selectedFacility.containeringGroup ? selectedFacility.containeringGroup : ''}
                inputProps={{ maxLength: 50 }}
                disabled={!canEdit}
              />
            </Grid>
            <Grid item xs={2}>
              <StyledTextFieldGrid sx={{ mr: 1 }}
                key={`${selectedFacility.testFacilityAbv.rowId}-legacy-id`}
                onChange={e => addMethodFacility('legacyTableID', e.target.value)}
                id="method-management-legacy-id"
                label="Legacy Table Id"
                variant="outlined" size="small" margin="none" 
                value={selectedFacility.legacyTableID ? selectedFacility.legacyTableID : ''}
                disabled={!canEdit} />
            </Grid>
            <Grid item xs={4}>
              <StyledFormControl sx={{ width: "100%" }} fullWidth
                //  key={`${selectedFacility.testFacilityAbv.rowId}-form-2`}
              >
                <Autocomplete
                  noOptionsText={"Loading Status..."}
                  key={`${selectedFacility.testFacilityAbv.rowId}-auto-2`}
                  value={selectedFacility.methodStatusName === '' ? null : selectedFacility.methodStatusName}
                  onChange={(e, value) => addMethodFacility('methodStatusName', value)}
                  disablePortal
                  options={methodFacilityStatusList.map((label) => label.statusName.toString())}
                  sx={{ width: "100%" }}
                  renderInput={(params) => <TextField {...params} variant="outlined" size="small" margin="none" label="Status" />}
                  autoSelect
                  disabled={!canEdit}
                />

              </StyledFormControl>
            </Grid>
          </StyledGrid>
          <StyledGrid container spacing={2} fullWidth 
            key={`${selectedFacility.testFacilityAbv.rowId}-2`}
          >
            <Grid item xs={4}>
              <StyledTextFieldGrid sx={{ mr: 1 }}
                key={`${selectedFacility.testFacilityAbv.rowId}-method-management-message-submitter`}
                onChange={e => addMethodFacility('messageForSubmitter', e.target.value)}
                id="method-management-message-submitter"
                label="Message Submitter"
                variant="outlined" size="small" margin="none" 
                value={selectedFacility.messageForSubmitter ? selectedFacility.messageForSubmitter : ''}
                disabled={!canEdit} />
            </Grid>
            <Grid item xs={4}>
              <StyledFormControl sx={{ width: "100%" }} fullWidth 
                key={`${selectedFacility.testFacilityAbv.rowId}-form-2`}
              >
                <Autocomplete
                  noOptionsText={"Loading Locations..."}
                  key={`${selectedFacility.testFacilityAbv.rowId}-auto-2`}
                  value={selectedFacility.dropboxLocation === '' ? null : selectedFacility.dropboxLocation}
                  onChange={(e, value) => addMethodFacility('dropboxLocation', value)}
                  disablePortal
                  options={locationList.map((label) => label.locationName.toString())}
                  sx={{ width: "100%" }}
                  renderInput={(params) => <TextField {...params} variant="outlined" size="small" margin="none" label="Dropbox Location" />}
                  autoSelect
                  disabled={!canEdit}
                />

              </StyledFormControl>
            </Grid>
          </StyledGrid>
          <StyledGrid container spacing={2} fullWidth error={invalidField.testOwnerEmail ? true : false} 
            key={`${selectedFacility.testFacilityAbv.rowId}-3`}
            >
            <Grid item xs={4}>
              <EmailAddressTextField
                fontSize={16}
                key={`${selectedFacility.testFacilityAbv.rowId}-method-management-test-owner`}
                fieldWidth={"20rem"}
                validatedUserEmail={selectedFacility.testOwnerEmail}
                setValidatedUserEmail={value => {
                  addMethodFacility('testOwnerEmail', value)
                }}
                setHasErrors={(value) => setTestOwnerInvalid(value)}
                hasErrors={(invalidField.testOwnerEmail.length > 0 && (selectedFacility.testOwnerEmail === "" || selectedFacility.testOwnerEmail === null)) ? true : testOwnerInvalid === null ? false : testOwnerInvalid}
                isDisabled={!canEdit}
                labelText="Test Owner"
                placeholderText="Test Owner"
                size={"small"}
                margin={"none"}
                showPlusMeButton={true}>
              </EmailAddressTextField>
            </Grid>
            <Grid item xs={4}>
              <StyledTextFieldGrid sx={{ mr: 1 }}
                key={`${selectedFacility.testFacilityAbv.rowId}-method-management-test-cost`}
                onChange={e => addMethodFacility('testCostUSD', e.target.value)}
                id="method-management-test-cost"
                label="Test Cost"
                variant="outlined" size="small" margin="none" 
                value={selectedFacility.testCostUSD ? selectedFacility.testCostUSD : ''}
                disabled={!canEdit} />
            </Grid>
            <Grid item xs={4}>
              <StyledTextFieldGrid sx={{ mr: 1 }}
                key={`${selectedFacility.testFacilityAbv.rowId}-method-management-target-turn-around`}
                onChange={e => addMethodFacility('targetTurnaroundInDays', e.target.value)}
                id="method-management-target-turn-around"
                label="Expected Turn Around (days)"
                variant="outlined" size="small" margin="none" 
                value={selectedFacility.targetTurnaroundInDays ? selectedFacility.targetTurnaroundInDays : ''}
                disabled={!canEdit} />
            </Grid>
          </StyledGrid>
          <StyledGrid container spacing={2} fullWidth error={invalidField.requiredSampleSize ? true : false} 
            key={`${selectedFacility.testFacilityAbv.rowId}-4`}
            >
            <Grid item xs={4}>
              <StyledTextFieldGrid sx={{ width: "100%", mr: 1 }}
                key={`${selectedFacility.testFacilityAbv.rowId}-style-text-4`}
                onChange={e => addMethodFacility('requiredSampleSize', e.target.value)}
                id="method-management-required-sample-amount"
                label="Required Sample Amount"
                variant="outlined" size="small" margin="none" 
                type="number"
                value={selectedFacility.requiredSampleSize ? selectedFacility.requiredSampleSize : ''}
                error={invalidField.requiredSampleSize.length > 0 && (selectedFacility.requiredSampleSize === "" || selectedFacility.requiredSampleSize === null) ? true : false}
                helperText={invalidField.requiredSampleSize && (selectedFacility.requiredSampleSize === "" || selectedFacility.requiredSampleSize === null) ? invalidField.requiredSampleSize : ""}
                disabled={!canEdit} />
            </Grid>
            <Grid item xs={4}>
              {<StyledFormControl sx={{ width: "100%" }} fullWidth error={invalidField.requiredSampleSizeUoM ? true : false} 
                key={`${selectedFacility.testFacilityAbv.rowId}-style-form-4`}
              >

                {
                  unitOfMeasureList &&
                  <Autocomplete
                    noOptionsText={"Loading UoMs..."}
                    key={`${selectedFacility.testFacilityAbv.rowId}-auto-4`}
                    value={selectedFacility.requiredSampleSizeUoM === '' ? null : selectedFacility.requiredSampleSizeUoM}
                    onChange={(e, value) => addMethodFacility('requiredSampleSizeUoM', value)}
                    disablePortal
                    options={unitOfMeasureList.map((uom) => uom.uoMName.toString())}
                    sx={{ width: "100%" }}
                    renderInput={(params) => <TextField {...params} variant="outlined" size="small" margin="none" label="Required Samples UoM"
                      error={invalidField.requiredSampleSizeUoM.length > 0 && (selectedFacility.requiredSampleSizeUoM === "" || selectedFacility.requiredSampleSizeUoM === null) ? true : false}
                      helperText={invalidField.requiredSampleSizeUoM && (selectedFacility.requiredSampleSizeUoM === "" || selectedFacility.requiredSampleSizeUoM === null) ? invalidField.requiredSampleSizeUoM : invalidField.requiredSampleSizeUoM} />}
                    autoSelect
                    disabled={!canEdit}
                  />
                }

              </StyledFormControl>}
            </Grid>
          </StyledGrid>


          <StyledFormControl sx={{ width: "50rem" }} variant='outlined' fullWidth 
          key={`${selectedFacility.testFacilityAbv.rowId}-5`}
          >

            <StyledBox sx={{ justifyContent: "space-between" }} 
              key={`${selectedFacility.testFacilityAbv.rowId}-style-box-5`}
            >

              <FormControlLabel
                key={`${selectedFacility.testFacilityAbv.rowId}-requiresEstimates`}
                label="Requires Estimates"
                onChange={e => addMethodFacility('requiresEstimates', e.target.checked)}
                checked={selectedFacility.requiresEstimates}
                control={<Checkbox />}
                disabled={!canEdit}
              />

              <FormControlLabel
                key={`${selectedFacility.testFacilityAbv.rowId}-requiresConditions`}
                label="Requires Conditions"
                onChange={e => addMethodFacility('requiresConditions', e.target.checked)}
                checked={selectedFacility.requiresConditions}
                control={<Checkbox />}
                disabled={!canEdit}
              />

              <FormControlLabel
                key={`${selectedFacility.testFacilityAbv.rowId}-requiresSeparateContainer`}
                label="Requires Separate Container"
                onChange={e => addMethodFacility('requiresSeparateContainer', e.target.checked)}
                checked={selectedFacility.requiresSeparateContainer}
                control={<Checkbox />}
                disabled={!canEdit}
              />

              <FormControlLabel
                key={`${selectedFacility.testFacilityAbv.rowId}-allowPrioritization`}
                label="Allow Priorization"
                onChange={e => addMethodFacility('allowPrioritization', e.target.checked)}
                checked={selectedFacility.allowPrioritization}
                control={<Checkbox />}
                disabled={!canEdit}
              />

              <FormControlLabel
                key={`${selectedFacility.testFacilityAbv.rowId}-isSQCMethod`}
                label="Has SQC"
                onChange={e => addMethodFacility('isSQCMethod', e.target.checked)}
                checked={selectedFacility.isSQCMethod}
                control={<Checkbox />}
                disabled={!canEdit}
              />

            </StyledBox>

          </StyledFormControl>
          {
            selectedFacility.requiresConditions === true && <StyledDatagridDiv>


              <MethodManagementDataGrid
                key={`${selectedFacility.testFacilityAbv.rowId}-dtgrid-test-conditions-offering`}
                sx={{ width: "77rem", height: "20rem" }}
                // selectedInfo={selectedInfoTestConditionsList}
                setSelectedInfo={addCondition}
                title={'Conditions Offering'}
                rows={selectedFacility.testConditionOfferings}
                setRows={(condition) => addMethodFacility('testConditionOfferings',[
                  ...condition,
                ])}
                cols={columnsTestCondtionsOffering}
                enableDelete={canEdit ? true : false}
                enableRowDelete={(row) => row.id && row.id > 0 ? false : true}
                canEdit={canEdit}
                childInHeader={addConditionOffering()}
                selectionDataGrid={(value) => clickRowOffering(value)}
              ></MethodManagementDataGrid>

            </StyledDatagridDiv>
          }
          <MethodManagementDataGrid key="dt-grid-resultComponent"
            sx={{ width: "77rem", height: "20rem" }}
            // selectedInfo={selectedInfoMethodFacilityResultComponentList}
            setSelectedInfo={addMethodFacilityResultComponentList}
            title={'Result Component'}
            rows={selectedFacility.methodFacilityResultComponents ? selectedFacility.methodFacilityResultComponents : []}
            setRows={(result) => addMethodFacility('methodFacilityResultComponents',[
              ...result,
            ])}
            cols={columnsResultComponentFacility}
            enableSelect={false}
            canEdit={canEdit}>
          </MethodManagementDataGrid>
        </AccordionDetails>
      </Accordion>
      {
        openConditionOffering && <MethodManagementModalOffering
          open={openConditionOffering}
          setOpen={setOpenConditionOffering}
          handleClose={onCloseModalOffering}
          conditionsList={conditionsList}
          canEdit={canEdit}
          selectedFacility={selectedFacility}
          unitOfMeasureList={unitOfMeasureList}
          offering={offeringToEdit}
          addOffering={addCondition}
        ></MethodManagementModalOffering>
      }

    </ >

  );
};

export default MethodManagementFacility;
