import Tooltip from "@mui/material/Tooltip";
import Checkbox from "@mui/material/Checkbox";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import ContentCopy from "@mui/icons-material/ContentCopy";

import {
  StyledTableBody,
  StyledTableRow,
  UXDataTableWithoutBody,
} from "../../../../components/UXDataTable";
import { formatMidasNumber, generateUniqueID } from "../../../../global";
import {
  INTERVAL_INITIAL_STATE,
  areTwoIntervalsTheSame,
  getUpdatedIntervalsByIntervalToBeCopied,
} from "../../shared";
import { StyledDelete, StyledTableCellMedium, StyledTableCellSmall } from "../../styles";
import { requestIntervalTableCols } from "../../tableColumns";
import type { ChemId, Interval, Methods } from "../../types";
import Substance from "../../../../api/LIMS/Substance";
import { useDetailedStRequest } from "../../context/DetailedStRequestContext";
import IntervalRowForm, { Mode } from "./IntervalRowForm";

type IntervalTableProps = {
  intervals: Interval[];
  selectedInterval: Interval | null;
  setIntervals(newIntervals: Interval[]): void;
  setSelectedInterval(newSelectedInterval: Interval): void;
  setMethods(newMethods: Methods[]): void;
  intervalSamplesToRegister: Interval[];
  setIntervalSamplesToRegister(newSamples: Interval[]): void;
  selectedChemID: ChemId | null;
  setSelectedChemID(chemId: ChemId | null): void;
  setContainersReturnLocation(n: string): void;
};

export function IntervalTable({
  intervals,
  setIntervals,
  setMethods,
  selectedInterval,
  setSelectedInterval,
  intervalSamplesToRegister,
  setIntervalSamplesToRegister,
  selectedChemID,
  setSelectedChemID,
  setContainersReturnLocation,
}: IntervalTableProps) {
  const { canManageSampleSchedule, stRequestId, locationNames } = useDetailedStRequest();

  function handleAddIntervalToSampleRegistration(
    interval: Interval,
    intervalAlreadyAdded: boolean,
  ) {
    const intervalId = interval.id;

    if (intervalAlreadyAdded) {
      const filtered = intervalSamplesToRegister.filter(
        (registeredInterval) => registeredInterval.id !== intervalId,
      );
      setIntervalSamplesToRegister(filtered);
    } else {
      setIntervalSamplesToRegister([...intervalSamplesToRegister, interval]);
    }
  }

  async function getSubstanceByChemID(interval: Interval) {
    if (interval && interval.chemID && interval.chemID !== "") {
      const searchResult = await Substance.getByChemID(interval.chemID, false, false);

      if (searchResult && searchResult.message === "Success") {
        setSelectedChemID(searchResult.result);
      }
    }
  }

  function handleClipboard(interval: Interval) {
    const newIntervals = getUpdatedIntervalsByIntervalToBeCopied(intervals, interval);

    setIntervals(newIntervals);
  }

  function handleDeleteInterval(interval: Interval) {
    const filteredIntervals = intervals.filter((item) => !areTwoIntervalsTheSame(item, interval));
    setIntervals(filteredIntervals);
  }

  const handleRowClicked = (interval: Interval, isIntervalSelected: boolean) => {
    if (!isIntervalSelected) {
      setSelectedChemID(null);
      setSelectedInterval(interval);
      getSubstanceByChemID(interval);

      const intervalRegistered = interval.intervalSampleName && interval.intervalSampleName !== "";

      if (
        intervalRegistered &&
        interval.intervalSample &&
        interval.intervalSample.containers &&
        interval.intervalSample.containers.length > 0
      ) {
        const containerReturnLocation = interval.intervalSample.containers[0].returnLocationName;

        setContainersReturnLocation(containerReturnLocation);
      } else {
        setContainersReturnLocation("DISCARD");
      }
    }
  };

  return (
    <UXDataTableWithoutBody
      tableWidth="100%"
      cols={requestIntervalTableCols}
      tableId="intervalTable"
      rowLength={intervals.length}
      noDataFoundMessage="No intervals found"
      blankFirstHeader={false}
      enableAddIcon={canManageSampleSchedule}
      addToolTipText="Add new Interval"
      addFunction={() => {
        const newInterval: Interval = {
          ...INTERVAL_INITIAL_STATE,
          requestID: stRequestId,
          id: generateUniqueID(),
        };

        setIntervals([...intervals, newInterval]);
        setMethods([]);
        setSelectedInterval(newInterval);
      }}
      enableCheckbox={false}
      enablePaging={false}
      handleChangePage={() => {}}
      handleChangeRowsPerPage={() => {}}
      handleRequestSort={() => {}}
      handleSelectAllClick={() => {}}
      order={null}
      orderBy={null}
      page={null}
      rowsPerPage={null}>
      <StyledTableBody>
        {intervals.length &&
          intervals.map((interval, i) => {
            const intervalId = interval.id;

            const isIntervalSelected = selectedInterval
              ? selectedInterval.id === intervalId
              : false;

            const intervalDisabledForRegister = !!(
              interval.intervalSampleName && interval.intervalSampleName !== ""
            );

            const intervalAlreadyRegistered = !!intervalSamplesToRegister.find(
              (regsiterInterval) => regsiterInterval.id === intervalId,
            );

            return (
              <StyledTableRow
                key={interval.id}
                hover
                style={{
                  border: isIntervalSelected ? "3px solid #1976D2" : undefined,
                  cursor: "pointer",
                }}
                onClick={() => handleRowClicked(interval, isIntervalSelected)}>
                <StyledTableCellSmall>
                  <Tooltip title="Register Sample" placement="right">
                    <div>
                      <Checkbox
                        size="small"
                        checked={intervalDisabledForRegister || intervalAlreadyRegistered}
                        disabled={
                          intervalDisabledForRegister ||
                          !interval.intervalName ||
                          interval.intervalName === "" ||
                          !canManageSampleSchedule
                        }
                        onClick={() => {
                          if (interval.intervalName && interval.intervalName !== "") {
                            handleAddIntervalToSampleRegistration(
                              interval,
                              intervalAlreadyRegistered,
                            );
                          }
                        }}
                      />
                    </div>
                  </Tooltip>
                </StyledTableCellSmall>
                <StyledTableCellMedium style={{ border: "none" }}>
                  {interval?.intervalSampleName
                    ? formatMidasNumber(interval.intervalSampleName)
                    : ""}
                </StyledTableCellMedium>
                <IntervalRowForm
                  key={i}
                  intervals={intervals}
                  interval={interval}
                  setIntervals={setIntervals}
                  selectedChemID={selectedChemID}
                  setSelectedChemID={setSelectedChemID}
                  disabled={intervalDisabledForRegister && !canManageSampleSchedule}
                  mode={isIntervalSelected ? Mode.Write : Mode.Read}
                  locationOptions={locationNames}
                />
                <StyledTableCellSmall>
                  <Box display="flex" alignItems="center" justifyContent="center" gap={1}>
                    <Tooltip title={`Delete interval ${interval.intervalName}`} placement="right">
                      <div>
                        <IconButton
                          style={{ padding: 0 }}
                          disabled={!canManageSampleSchedule || intervalDisabledForRegister}
                          onClick={() => {
                            if (canManageSampleSchedule && !intervalDisabledForRegister) {
                              handleDeleteInterval(interval);
                            }
                          }}>
                          <StyledDelete fontSize="small" />
                        </IconButton>
                      </div>
                    </Tooltip>
                    <Tooltip
                      title={`Copy interval ${interval.intervalName} above`}
                      placement="right">
                      <div>
                        <IconButton
                          color="info"
                          style={{ padding: 0 }}
                          onClick={() => handleClipboard(interval)}
                          disabled={!canManageSampleSchedule}>
                          <ContentCopy fontSize="small" color="inherit" />
                        </IconButton>
                      </div>
                    </Tooltip>
                  </Box>
                </StyledTableCellSmall>
              </StyledTableRow>
            );
          })}
      </StyledTableBody>
    </UXDataTableWithoutBody>
  );
}
