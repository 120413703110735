import React from "react";
import {
  Box,
  FormControl,
  InputLabel,
  TextField,
  Checkbox,
  //Select,
  //MenuItem,
  styled
} from "@mui/material";
import FormControlLabel from '@mui/material/FormControlLabel';
import ModalTwoButtons from '../../components/Modal/ModalTwoButtons';
import ResultComponentApi from "../../api/Admin/ResultComponent";
import { convertToLocalTime } from "../../global";

const StyledBox = styled(Box)(() => ({
  display: 'flex',
  marginBottom: "20px",
  gap: "20px",
  justifyContent: "normal!important"
}));

const StyledTextField = styled(TextField)(() => ({
  width: "35rem"
}));

const StyledFormControl = styled(FormControl)(() => ({
  display: 'flex',
  width: "50rem"
}));

// const StyledSelect = styled(Select)(() => ({
//   width: "35rem"
// }));

const StyledDiv = styled('div')({
  display: 'flex',
  justifyContent: "space-between"
});

const StyledInputLabel = styled(InputLabel)(() => ({
  wordBreak: 'break-all',
  wordWrap: 'break-word',
  whiteSpace: 'normal;'
}));

const ResultComponentForm = ({ infoData, setInfoData, invalidField, handleChangeResultComponentFilter, canEdit}) => {

  const [modalTwoButtonsOpen, setModalTwoButtonsOpen] = React.useState(false);

  function findResultComponent() {
    handleChangeResultComponentFilter(infoData.name);
    closeModalTwoButtonsOpen();
  }

  function closeModalTwoButtonsOpen() {
    setModalTwoButtonsOpen(false);
  }

  function checkExistingResultComponent(resultComponent) {

    if (resultComponent) {
      ResultComponentApi.get(resultComponent).then((resultComponentExists) => {
        if ((resultComponentExists !== undefined && resultComponentExists !== null))
          setModalTwoButtonsOpen(true)
      });
    }
  }

  return (

    <div>

      <StyledDiv>

        <div>

          <StyledBox sx={{ flexDirection: 'column' }}>
            <StyledFormControl sx={{ m: 1 }} variant='outlined'>

              <StyledBox sx={{ justifyContent: "space-between" }}>
                <StyledTextField
                  onChange={e => {
                    setInfoData(() => ({
                      ...infoData,
                      name: e.target.value
                    }))
                  }}
                  onBlur={e => {
                    checkExistingResultComponent(e.target.value)
                  }}
                  value={infoData.name}
                  id="result-component-name"
                  label="Result Component name"
                  variant="outlined" size="small" margin="none" 
                  error={invalidField.name.length === 0 ? false : true}
                  disabled={infoData.id || !canEdit ? true : false}
                  helperText={invalidField.name} 
                  inputProps={{ maxLength: 85 }} 
                  />

                <FormControlLabel
                  label="Active"
                  onChange={e => setInfoData(() => ({
                    ...infoData,
                    status: e.target.checked
                  }))}
                  checked={infoData.status}
                  disabled={!canEdit}
                  control={<Checkbox />}
                />
              </StyledBox>

              <StyledBox sx={{ justifyContent: "space-between" }}>
                <StyledTextField
                  onChange={e => setInfoData(() => ({
                    ...infoData,
                    description: e.target.value
                  }))}
                  id="result-component-description"
                  label="Description"
                  variant="outlined" size="small" margin="none" 
                  value={infoData.description} 
                  inputProps={{ maxLength: 200 }} 
                  disabled={!canEdit}
                  />

              </StyledBox>
              <StyledBox sx={{ justifyContent: "space-between" }}>
                <StyledTextField
                  onChange={e => setInfoData(() => ({
                    ...infoData,
                    historicalIDNUM: e.target.value
                  }))}
                  id="result-component-idnum"
                  label="Historical IdNUM"
                  variant="outlined" size="small" margin="none" 
                  value={infoData.historicalIDNUM} 
                  inputProps={{ maxLength: 200 }} 
                  disabled={!canEdit}
                  />

              </StyledBox>

              {/* <StyledFormControl fullWidth error={invalidField.GRDB}>
                <InputLabel id="result-component-grdb">GRDB Chemical/Physical Property</InputLabel>
                <StyledSelect
                  labelId="result-component-grdb"
                  id="result-component-grdb-select"
                  onChange={e => {
                    setInfoData(() => ({
                      ...infoData,
                      GRDB: e.target.value
                    }))
                  }}
                  value={GRDBList && infoData.GRDB ? infoData.GRDB : ''}
                  label="GRDB Chemical/Physical Property"
                  disabled={!canEdit}
                >
                  {
                    GRDBList &&
                      GRDBList.map((GRDB) => (
                        <MenuItem value={GRDB.id} key={GRDB.id}>{GRDB.name}</MenuItem>
                      ))
                  }
                </StyledSelect>

              </StyledFormControl> */}
            </StyledFormControl>
          </StyledBox>

          {infoData.historicalIDNUM === 0 ? <div></div> :

            <StyledFormControl sx={{ m: 1, width: "100%" }} variant='outlined' fullWidth>
              <StyledBox sx={{ justifyContent: "space-between" }}>

                {!infoData.createdByName ?

                  <StyledInputLabel sx={{ position: "relative" }}>Create By <b> unknown </b> on <b> n.d. </b></StyledInputLabel>

                  : <StyledInputLabel sx={{ position: "relative" }}>Create By <b>{infoData.createdByName}</b> on
                    <b> {convertToLocalTime(infoData.createdByDate)}</b>
                  </StyledInputLabel>}

                {!infoData.createdByName ? <div></div> :

                  !infoData.lastUpdatedName ?
                    <div></div>

                    : <StyledInputLabel sx={{ position: "relative" }}>Last Updated By <b>{infoData.lastUpdatedName}</b> on
                      <b> {convertToLocalTime(infoData.lastUpdatedDate)}</b>

                    </StyledInputLabel>}

              </StyledBox>
            </StyledFormControl>
          }

        </div>

      </StyledDiv>

      <ModalTwoButtons title={'Warning'} button1Text={'No'} button1Action={closeModalTwoButtonsOpen} button2Text={'Yes'} button2Action={findResultComponent} open={modalTwoButtonsOpen} setOpen={setModalTwoButtonsOpen}>
        <label>
          This Result Component already exist, would you like to use it?
        </label>
      </ModalTwoButtons>

    </div>

  );
};

export default ResultComponentForm;
