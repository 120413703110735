import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { useContext, useEffect, useState } from "react";

import { Document, Page, Text } from "@react-pdf/renderer";

import TrashIcon from "@mui/icons-material/Delete";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  MenuItem,
  Select,
  Switch,
  TextField,
  Typography,
  Tooltip,
} from "@mui/material";
import { GlobalButton } from "../../styles";

import PrintLabel from "../../../api/LIMS/PrintLabel";
import {
  StyledTableBody,
  StyledTableCell,
  StyledTableRow,
  UXDataTableWithoutBody,
} from "../../../components/UXDataTable";

import { Roles, convertToLocalTime, hasRole, DatePickerKeyDownEvent } from "../../../global";

import { usePDF } from "@react-pdf/renderer";
import ShippingReason from "../../../api/WorkRequest/ShippingReason";
import TSCAOutcome from "../../../api/WorkRequest/TSCAOutcome";
import TSCAReview from "../../../api/WorkRequest/TSCAReview";
import TSCAStatus from "../../../api/WorkRequest/TSCAStatus";
import ModalSimpleButton from "../../../components/Modal/ModalSimpleButton";
import PrintInformation from "../../../components/PrintInformation";
import UserContext from "../../../context/UserContext";
import {
  Description,
  DescriptionLink,
  StyledAutocomplete,
  StyledFormGroup,
  StyledShippingInfo,
  StyledShippingInfoDescription,
  StyledShippingInfoTitle,
  StyledShortTextField,
  StyledTextField,
  SubTitle,
  Title,
} from "../NewWorkRequests/constants";

import PrintShippingRequest from "./components/PrintShippingRequest";
import WorkRequestShippingSafetyTable from "./components/WorkRequestShippingSafetyTable";
import { styles } from "./components/PrintStylesheet";

export default function ShippingForm({
  myWorkRequest,
  shippingRequest,
  expanded,
  handleChange,
  setShippingRequest,
  shippingCarriersLoading,
  shippingCarriers,
  isShippingRole,
  ownerCanEdit,
  updateData_ShippingItem,
  dotHazardClassLoading,
  dotHazardClass,
  isCancelledOrCompleted,
}) {
  const [printLabelInfo, setPrintLabelInfo] = useState(null);
  const [printInfoOpen, setPrintInfoOpen] = useState(false);
  const [shippedVia, setShippedVia] = useState(null);
  const [trackingCode, setTrackingCode] = useState("");
  const [shippingReasons, setShippingReasons] = useState([]);
  const [tscaOutcome, setTscaOutcome] = useState([]);
  const [tscaStatus, setTscaStatus] = useState([]);
  const [modalObj, setModalObj] = useState({
    isOpen: false,
    title: "",
    message: "",
  });
  
  const [instance] = usePDF({
    document: (
      <Document>
        <Page size="A4" style={styles.page}>
          <Text style={styles.title}>Request Details - {myWorkRequest.id}</Text>
          <PrintShippingRequest workRequest={myWorkRequest} shippingRequest={shippingRequest} dotHazardClass={dotHazardClass}
           />
        </Page>
      </Document>
    ),
  });

  const currentUser = useContext(UserContext);
  const userRoles = currentUser?.idTokenClaims.roles;
  const isDeveloperRole = hasRole(Roles.Developer, userRoles);

  const isTscaApprover =
    hasRole(Roles.TSCAApprover, userRoles) || isDeveloperRole;

  useEffect(() => {
    ShippingReason.getAll().then((res) => {
      setShippingReasons(
        res
          .filter((result) => result.isActive === true)
          .sort((a, b) =>
            a.shippingReasonName.localeCompare(b.shippingReasonName)
          )
      );

      TSCAOutcome.getAll().then((res) => {
        setTscaOutcome(
          res
            .filter((result) => result.isActive === true)
            .sort((a, b) => a.outcomeName.localeCompare(b.outcomeName))
        );
      });
      TSCAStatus.getAll().then((res) => {
        setTscaStatus(
          res
            .filter((result) => result.isActive === true)
            .sort((a, b) => a.statusName.localeCompare(b.statusName))
        );
      });
      // TSCAReview
    });
  }, []);

  function updateShippingAddress(property, value) {
    const newShippingAddress = shippingRequest?.shippingAddress;

    newShippingAddress[property] = value;

    setShippingRequest({
      ...shippingRequest,
      shippingAddress: newShippingAddress,
    });
  }

  function addTracking() {
    const carrier = shippingCarriers.find((s) => s.carrierName === shippedVia);

    if (carrier) {
      setShippingRequest(() => ({
        ...shippingRequest,
        shippingTrackings: [
          ...shippingRequest?.shippingTrackings,
          {
            shippingRequestID: shippingRequest?.id,
            shippingCarrierID: carrier.id,
            shipmentTrackingNumber: trackingCode,
          },
        ],
      }));

      setShippedVia(null);
      setTrackingCode("");
    }
  }

  function deleteTracking(tracking) {
    const trackings = shippingRequest?.shippingTrackings.filter(
      (t) => t !== tracking
    );
    setShippingRequest(() => ({
      ...shippingRequest,
      shippingTrackings: trackings,
    }));
  }

  function OpenPrintingOptions(sampleList, onlyWithBlindCode) {
    let arrayPrintInfo = [];

    sampleList.forEach((dataTable) => {
      const containerID = dataTable.existingContainerID ?? dataTable.newContainerID;
      const shippingItem = shippingRequest.shippingItems.find(s => s.containerID === containerID);
      const containerNumbers = dataTable.existingContainer?.containerNumber || dataTable.existingContainer?.containerNumber === 0 ?  [`${dataTable.existingContainer?.containerNumber}`] : 
        dataTable.newContainer?.containerNumber || dataTable.newContainer?.containerNumber === 0 ? [`${dataTable.newContainer?.containerNumber}`] : [];

      let newPrintInfo = new PrintLabel({
        sampleName: dataTable.sample.sampleName,
        containerNumbers: containerNumbers,
        includeAdditionalSampleInformation: false,
        chemIDOnly: false,
        printLabelSizeType: null,
        isBlindCoded: onlyWithBlindCode,
        blindDescription: shippingItem?.blindCodeDescription ? shippingItem?.blindCodeDescription : null,
        blindSampleName: null,
        isShelfLabel: false,
        shelfLabelText: null,
        includeShelfBarcode: false
      });

      if ((onlyWithBlindCode && shippingItem?.blindCodeDescription && shippingItem?.blindCodeDescription !== '') || !onlyWithBlindCode) 
        arrayPrintInfo.push(newPrintInfo);
    });

    setPrintLabelInfo(arrayPrintInfo);
    setPrintInfoOpen(true);
  }
  
  function closePrintInformation() {
    setPrintInfoOpen(false);
  }

  const handlePrintShippingForm = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (!instance.loading && !instance.error && instance.url) {
      const link = document.createElement("a");
      link.href = instance.url;
      link.target = "_blank";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  return (
    <>
      <Accordion
        expanded={expanded === "panel2"}
        onChange={handleChange("panel2")}
      >
        <AccordionSummary
          style={{ justifyContent: "space-between" }}
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2bh-content"
          id="panel2bh-header"
        >
          <Title style={{ display: "flex", flex: 1 }}>Shipping Form</Title>
          <GlobalButton
            variant="outlined"
            sx={{ marginRight: "20px" }}
            onClick={handlePrintShippingForm}
          >
            Print Shipping Form
          </GlobalButton>
        </AccordionSummary>
        <AccordionDetails>
          <SubTitle>Ship Details</SubTitle>
          <Grid container spacing={2}>
            <Grid item md={1.5}>
              <StyledShippingInfo>
                <StyledShippingInfoTitle>
                  Recipient's Name
                </StyledShippingInfoTitle>

                <TextField
                  size="small"
                  disabled={!isShippingRole && !ownerCanEdit}
                  onChange={(e) => {
                    updateShippingAddress("recipientName", e.target.value);
                  }}
                  value={shippingRequest?.shippingAddress?.recipientName}
                />
              </StyledShippingInfo>
            </Grid>
            <Grid item md={1.5}>
              <StyledShippingInfo>
                <StyledShippingInfoTitle>Phone Number</StyledShippingInfoTitle>

                <TextField
                  size="small"
                  disabled={!isShippingRole && !ownerCanEdit}
                  onChange={(e) => {
                    updateShippingAddress("phoneNumber1", e.target.value);
                  }}
                  value={shippingRequest?.shippingAddress?.phoneNumber1}
                />
              </StyledShippingInfo>
            </Grid>
            <Grid item md={1.5}>
              <StyledShippingInfo>
                <StyledShippingInfoTitle>E-mail</StyledShippingInfoTitle>

                <TextField
                  size="small"
                  disabled={!isShippingRole && !ownerCanEdit}
                  value={shippingRequest?.shippingAddress?.emailAddress}
                  onChange={(e) => {
                    updateShippingAddress("emailAddress", e.target.value);
                  }}
                />
              </StyledShippingInfo>
            </Grid>
            <Grid item md={1.5}>
              <StyledShippingInfo>
                <StyledShippingInfoTitle>Company</StyledShippingInfoTitle>

                <TextField
                  size="small"
                  disabled={!isShippingRole && !ownerCanEdit}
                  value={shippingRequest?.shippingAddress?.company}
                  onChange={(e) => {
                    updateShippingAddress("company", e.target.value);
                  }}
                />
              </StyledShippingInfo>
            </Grid>
            <Grid item md={1.5}>
              <StyledShippingInfo>
                <StyledShippingInfoTitle>
                  Department/Floor No
                </StyledShippingInfoTitle>

                <TextField
                  size="small"
                  disabled={!isShippingRole && !ownerCanEdit}
                  value={shippingRequest?.shippingAddress?.department}
                  onChange={(e) => {
                    updateShippingAddress("department", e.target.value);
                  }}
                />
              </StyledShippingInfo>
            </Grid>
            <Grid item md={1.5}>
              <StyledShippingInfo>
                <StyledShippingInfoTitle>Country</StyledShippingInfoTitle>

                <TextField
                  size="small"
                  disabled={!isShippingRole && !ownerCanEdit}
                  value={shippingRequest?.shippingAddress?.country}
                  onChange={(e) => {
                    updateShippingAddress("country", e.target.value);
                  }}
                />
              </StyledShippingInfo>
            </Grid>
            <Grid item md={1.5}>
              <StyledShippingInfo>
                <StyledShippingInfoTitle>State</StyledShippingInfoTitle>

                <TextField
                  size="small"
                  disabled={!isShippingRole && !ownerCanEdit}
                  value={shippingRequest?.shippingAddress?.state}
                  onChange={(e) => {
                    updateShippingAddress("state", e.target.value);
                  }}
                />
              </StyledShippingInfo>
            </Grid>
            <Grid item md={1.5}>
              <StyledShippingInfo>
                <StyledShippingInfoTitle>Zip Code</StyledShippingInfoTitle>

                <TextField
                  size="small"
                  disabled={!isShippingRole && !ownerCanEdit}
                  value={shippingRequest?.shippingAddress?.postalCode}
                  onChange={(e) => {
                    updateShippingAddress("postalCode", e.target.value);
                  }}
                />
              </StyledShippingInfo>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item md={1.5}>
              <StyledShippingInfo>
                <StyledShippingInfoTitle>City</StyledShippingInfoTitle>

                <TextField
                  size="small"
                  disabled={!isShippingRole && !ownerCanEdit}
                  value={shippingRequest?.shippingAddress?.city}
                  onChange={(e) => {
                    updateShippingAddress("city", e.target.value);
                  }}
                />
              </StyledShippingInfo>
            </Grid>
            <Grid item md={1.5}>
              <StyledShippingInfo>
                <StyledShippingInfoTitle>
                  Street Address
                </StyledShippingInfoTitle>

                <TextField
                  size="small"
                  disabled={!isShippingRole && !ownerCanEdit}
                  value={shippingRequest?.shippingAddress?.address1}
                  onChange={(e) => {
                    updateShippingAddress("address1", e.target.value);
                  }}
                />
              </StyledShippingInfo>
            </Grid>
            <Grid item md={1.5}>
              <StyledShippingInfo>
                <StyledShippingInfoTitle>Ship</StyledShippingInfoTitle>

                <Select
                  disabled={!isShippingRole && !ownerCanEdit}
                  value={shippingRequest?.isPrepaid}
                  size="small"
                  fullWidth
                  onChange={(e) => {
                    setShippingRequest({
                      ...shippingRequest,
                      isPrepaid: e.target.value,
                    });
                  }}
                >
                  <MenuItem value={true}>Prepaid</MenuItem>
                  <MenuItem value={false}>Collect</MenuItem>
                </Select>
              </StyledShippingInfo>
            </Grid>
            <Grid item md={1.5}>
              <StyledShippingInfo>
                <StyledShippingInfoTitle>Ship Via</StyledShippingInfoTitle>

                <Select
                  disabled={!isShippingRole && !ownerCanEdit}
                  value={shippingRequest?.carrier?.carrierName}
                  size="small"
                  fullWidth
                  onChange={(e) => {
                    let newCarrier = shippingRequest?.carrier;
                    newCarrier.carrierName = e.target.value;
                    const carrier = shippingCarriers?.find(
                      (sc) => sc.carrierName === e.target.value
                    );
                    if (carrier) {
                      setShippingRequest({
                        ...shippingRequest,
                        carrier: newCarrier,
                        shippingCarrierID: carrier.id,
                      });
                    }
                  }}
                >
                  {shippingCarriers.map((x) => (
                    <MenuItem value={x.carrierName}>{x.carrierName}</MenuItem>
                  ))}
                </Select>
              </StyledShippingInfo>
            </Grid>
            <Grid item md={1.5}>
              <StyledShippingInfo>
                <StyledShippingInfoTitle>
                  Reason for Shipment
                </StyledShippingInfoTitle>

                <Select
                  disabled={!isShippingRole && !ownerCanEdit}
                  value={shippingRequest.shippingReasonName}
                  size="small"
                  fullWidth
                  onChange={(e) => {
                    setShippingRequest({
                      ...shippingRequest,
                      shippingReasonName: e.target.value,
                    });
                  }}
                >
                  {shippingReasons?.map((x) => (
                    <MenuItem value={x.shippingReasonName}>
                      {x.shippingReasonName}
                    </MenuItem>
                  ))}
                </Select>
              </StyledShippingInfo>
            </Grid>
            <Grid item md={1.5}>
              <StyledShippingInfo>
                <StyledShippingInfoTitle>PO#</StyledShippingInfoTitle>

                <TextField
                  size="small"
                  disabled={!isShippingRole && !ownerCanEdit}
                  value={shippingRequest?.referencePO}
                  onChange={(e) => {
                    setShippingRequest({
                      ...shippingRequest,
                      referencePO: e?.target?.value,
                    });
                  }}
                />
              </StyledShippingInfo>
            </Grid>
            <Grid item md={1.5}>
              <StyledShippingInfo>
                <StyledShippingInfoTitle>
                  Return Authorized by
                </StyledShippingInfoTitle>
                <StyledShippingInfoDescription>-</StyledShippingInfoDescription>
              </StyledShippingInfo>
            </Grid>
            <Grid item md={1.5}>
              <StyledShippingInfo>
                <StyledShippingInfoTitle>
                  Date Required at Destination
                </StyledShippingInfoTitle>

                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DesktopDatePicker
                    disabled={!isShippingRole && !ownerCanEdit}
                    size="small"
                    inputFormat="MM/dd/yyyy"
                    onChange={(e) =>
                      setShippingRequest({
                        ...shippingRequest,
                        requestedDeliveryDate: e,
                      })
                    }
                    minDate={new Date()}
                    value={shippingRequest?.requestedDeliveryDate}
                    renderInput={(params) => (
                      <StyledShortTextField
                        onKeyDown={DatePickerKeyDownEvent}
                        style={{ width: "100%" }}
                        {...params}
                        variant="outlined"
                        size="small"
                        InputLabelProps={{ shrink: true }}
                        inputProps={{ ...params.inputProps }}
                        InputProps={{ ...params.InputProps }}
                      />
                    )}
                  />
                </LocalizationProvider>
              </StyledShippingInfo>
            </Grid>
          </Grid>
          <SubTitle>ETQ Info</SubTitle>
          <Grid container spacing={2}>
            <Grid item md={12}>
              <StyledFormGroup>
                <Description>
                  Shipping request for 3rd party lab testing{" "}
                </Description>
                <Typography>No</Typography>
                <Switch
                  onChange={(e) =>
                    setShippingRequest(() => ({
                      ...shippingRequest,
                      is3PTesting: e.target.checked,
                    }))
                  }
                  checked={
                    shippingRequest?.is3PTesting === null
                      ? false
                      : shippingRequest?.is3PTesting
                  }
                  inputProps={{ "aria-label": "controlled" }}
                  disabled={!isShippingRole && !ownerCanEdit}
                />
                <Typography>Yes</Typography>
              </StyledFormGroup>
            </Grid>
          </Grid>
          <SubTitle>MIDAS Sample Info</SubTitle>
          <Grid container spacing={2}>
            <WorkRequestShippingSafetyTable
              myWorkRequest={myWorkRequest}
              updateData_ShippingItem={updateData_ShippingItem}
              shippingRequest={shippingRequest}
              setShippingRequest={setShippingRequest}
              ownerCanEdit={ownerCanEdit}
              dotHazardClass={dotHazardClass}
              dotHazardClassLoading={dotHazardClassLoading}
              isCancelledOrCompleted={isCancelledOrCompleted}
            />
          </Grid>

          <SubTitle>TSCA Review</SubTitle>
          {shippingRequest?.tscaReview ? (
            <Grid container spacing={2}>
              <Grid item md={4}>
                <StyledShippingInfo>
                  <StyledShippingInfoTitle>Outcome</StyledShippingInfoTitle>

                  <Select
                    disabled={!isTscaApprover}
                    fullWidth
                    size="small"
                    value={shippingRequest?.tscaReview?.tscaOutcomeName ?? null}
                    onChange={(e) => {
                      setShippingRequest((prev) => ({
                        ...prev,
                        tscaReview: {
                          ...prev.tscaReview,
                          tscaOutcomeName: e.target.value,
                        },
                      }));
                    }}
                  >
                    {tscaOutcome.map((x) => (
                      <MenuItem value={x.outcomeName}>{x.outcomeName}</MenuItem>
                    ))}
                  </Select>
                </StyledShippingInfo>
              </Grid>
              <Grid item md={1.5}>
                <StyledShippingInfo>
                  <StyledShippingInfoTitle>Status</StyledShippingInfoTitle>
                  <Select
                    disabled={!isTscaApprover}
                    size="small"
                    fullWidth
                    value={shippingRequest?.tscaReview?.tscaStatusName ?? null}
                    onChange={(e) => {
                      setShippingRequest((prev) => ({
                        ...prev,
                        tscaReview: {
                          ...prev.tscaReview,
                          tscaStatusName: e.target.value,
                        },
                      }));
                    }}
                  >
                    {tscaStatus.map((x) => (
                      <MenuItem value={x.statusName}>{x.statusName}</MenuItem>
                    ))}
                  </Select>
                </StyledShippingInfo>
              </Grid>
              <Grid item md={3}>
                <StyledShippingInfo>
                  <StyledShippingInfoTitle>Reviewed By</StyledShippingInfoTitle>
                  <StyledShippingInfoDescription>
                    {shippingRequest?.tscaReview?.reviewedByEmail}
                  </StyledShippingInfoDescription>
                </StyledShippingInfo>
              </Grid>
              <Grid item md={1.5}>
                <StyledShippingInfoTitle></StyledShippingInfoTitle>
                <GlobalButton
                  disabled={!isTscaApprover}
                  variant="contained"
                  onClick={() => {
                    TSCAReview.updateTSCAReview(shippingRequest.tscaReview)
                      .then((res) => {
                        setShippingRequest((prev) => ({
                          ...prev,
                          tscaReview: res,
                        }));
                        setModalObj({
                          isOpen: true,
                          title: "TSCA Update",
                          message: "Your TSCA has been updated.",
                        });
                      })
                      .catch((err) => {
                        setModalObj({
                          isOpen: true,
                          title: "TSCA Update Error",
                          message: "Something went wrong.",
                        });
                      });
                  }}
                >
                  Save
                </GlobalButton>
              </Grid>
            </Grid>
          ) : (
            <Grid item md={12}>
              <Description>No TSCA Required</Description>
            </Grid>
          )}

          <SubTitle>Terms</SubTitle>
          <Grid container spacing={2}>
            <Grid item md={12}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={true}
                      disabled={true}
                    ></Checkbox>
                  }
                  label="I acknowledge that the descriptions on this form will be printed on MIDAS labels and are deemed acceptable for external use"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={shippingRequest?.containsSamples}
                      onChange={(e) =>
                        setShippingRequest(() => ({
                          ...shippingRequest,
                          containsSamples: e.target.checked,
                        }))
                      }
                      disabled={!isShippingRole && !ownerCanEdit}
                    ></Checkbox>
                  }
                  label="This shipment contains chemicals"
                />
              </FormGroup>
            </Grid>
          </Grid>
          <SubTitle>Labels Instructions</SubTitle>
          <Grid container spacing={2}>
            <Grid item md={12}>
              <Description>
              Press here to print blind-coded labels.{" "}
                {shippingRequest.shippingItems.some(s => s?.blindCodeDescription && s?.blindCodeDescription !== '') ? (<DescriptionLink
                  onClick={() => {
                    OpenPrintingOptions(myWorkRequest?.workRequestSamples, true);
                  }}
                >
                  Print
                </DescriptionLink>) : (
                  <Tooltip title="No items with Blind Code Label Description" placement="right">
                    <DescriptionLink style={{color: '#999'}}>Print</DescriptionLink>
                  </Tooltip>
                )}
              </Description>
            </Grid>
            <Grid item md={12}>
              <Description>
              Press here to print GHS Labels.{" "}
                <DescriptionLink
                  onClick={() => {
                    OpenPrintingOptions(myWorkRequest?.workRequestSamples, false);
                  }}
                >Print</DescriptionLink>
              </Description>
            </Grid>
          </Grid>
          <SubTitle>Tracking Detail</SubTitle>
          <Grid container spacing={2}>
            <Grid item md={3}>
              <StyledTextField
                size="small"
                margin="normal"
                variant="outlined"
                label="Tracking Code"
                InputLabelProps={{ shrink: true }}
                inputProps={{ maxLength: 60 }}
                onChange={(e) => setTrackingCode(e.target.value)}
                value={trackingCode}
                disabled={!isShippingRole && !ownerCanEdit}
              ></StyledTextField>
            </Grid>
            <Grid item md={3}>
              <StyledAutocomplete
                style={{ width: "100%" }}
                disablePortal
                disabled={!isShippingRole && !ownerCanEdit}
                noOptionsText={
                  shippingCarriersLoading
                    ? "Loading Carriers..."
                    : "No Carriers Found"
                }
                options={shippingCarriers}
                getOptionLabel={(option) =>
                  option?.carrierName
                    ? option.carrierName
                    : shippingCarriers.length > 0
                    ? shippingCarriers.find((f) => f.carrierName === option)
                        ?.carrierName
                    : ``
                }
                autoHighlight
                autoSelect
                onChange={(e, value) => setShippedVia(value?.carrierName)}
                value={shippedVia}
                isOptionEqualToValue={(option, value) =>
                  value?.carrierName === option?.carrierName
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    size="small"
                    margin="normal"
                    label="* Ship Via"
                    InputLabelProps={{ shrink: true }}
                    InputProps={{ ...params.InputProps }}
                  />
                )}
              />
            </Grid>
            <Grid
              item
              xs={4}
              md={4}
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: "10px",
              }}
            >
              <GlobalButton
                name="addCode"
                variant="contained"
                onClick={addTracking}
                disabled={shippedVia === null || trackingCode === ""}
              >
                Add Code
              </GlobalButton>
            </Grid>
          </Grid>
          <UXDataTableWithoutBody
            tableWidth="50%"
            cols={[
              "Tracking Code",
              "Shipped Via",
              "Created Date",
              "Shipped Date",
              "Delivered Date",
            ]}
            blankFirstHeader={true}
            tableId={`midasShippingTracking`}
            rowLength={shippingRequest?.shippingTrackings?.length}
            enablePaging={false}
            rowsPerPage={shippingRequest?.shippingTrackings?.length}
            page={0}
            handleChangePage={null}
            handleChangeRowsPerPage={null}
            noDataFoundMessage={"No Tracking Added"}
            enableAddIcon={false}
            addFunction={() => null}
            addToolTipText=""
            isDataLoading={false}
          >
            <StyledTableBody key={"myTablebody"}>
              {shippingRequest?.shippingTrackings?.map((tracking, rowIndex) => {
                return (
                  <StyledTableRow
                    hover
                    key={`tracking-custom-row-${rowIndex}`}
                    style={{ width: "10px" }}
                  >
                    <StyledTableCell component="th" scope="row">
                      <IconButton
                        disabled={tracking.id ? true : false}
                        aria-label="deleteTracking"
                        component="span"
                        onClick={() => deleteTracking(tracking)}
                      >
                        <TrashIcon />
                      </IconButton>
                    </StyledTableCell>
                    <StyledTableCell>
                      {tracking.shipmentTrackingNumber}
                    </StyledTableCell>
                    <StyledTableCell>
                      {
                        shippingCarriers.find(
                          (f) => f.id === tracking.shippingCarrierID
                        )?.carrierName
                      }
                    </StyledTableCell>
                    <StyledTableCell>
                      {convertToLocalTime(tracking.createdDate)}
                    </StyledTableCell>
                    <StyledTableCell>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DesktopDatePicker
                          disabled={!isShippingRole && !ownerCanEdit}
                          size="small"
                          inputFormat="MM/dd/yyyy"
                          onChange={(e) => {
                            let newTrackings = [
                              ...shippingRequest.shippingTrackings,
                            ];
                            newTrackings[rowIndex].shippedDate = e;

                            setShippingRequest({
                              ...shippingRequest,
                              shippingTrackings: newTrackings,
                            });
                          }}
                          // minDate={new Date()}
                          value={tracking.shippedDate}
                          renderInput={(params) => (
                            <StyledTextField
                              onKeyDown={DatePickerKeyDownEvent}
                              {...params}
                              variant="outlined"
                              size="small"
                              InputLabelProps={{ shrink: true }}
                              inputProps={{ ...params.inputProps }}
                              InputProps={{ ...params.InputProps }}
                            />
                          )}
                        />
                      </LocalizationProvider>
                    </StyledTableCell>
                    <StyledTableCell>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DesktopDatePicker
                          disabled={!isShippingRole && !ownerCanEdit}
                          size="small"
                          inputFormat="MM/dd/yyyy"
                          onChange={(e) => {
                            let newTrackings = [
                              ...shippingRequest.shippingTrackings,
                            ];
                            newTrackings[rowIndex].deliveredDate = e;

                            setShippingRequest({
                              ...shippingRequest,
                              shippingTrackings: newTrackings,
                            });
                          }}
                          // minDate={new Date()}
                          value={tracking.deliveredDate}
                          renderInput={(params) => (
                            <StyledTextField
                              onKeyDown={DatePickerKeyDownEvent}
                              {...params}
                              variant="outlined"
                              size="small"
                              InputLabelProps={{ shrink: true }}
                              inputProps={{ ...params.inputProps }}
                              InputProps={{ ...params.InputProps }}
                            />
                          )}
                        />
                      </LocalizationProvider>
                    </StyledTableCell>
                  </StyledTableRow>
                );
              })}
            </StyledTableBody>
          </UXDataTableWithoutBody>
          <PrintInformation
            open={printInfoOpen}
            setOpen={setPrintInfoOpen}
            button2Action={closePrintInformation}
            button2Text={"Close"}
            printLabelInfo={printLabelInfo}
            isShelfLabel={false}
            useFieldsBlindCode={false}
          ></PrintInformation>
        </AccordionDetails>
      </Accordion>
      <ModalSimpleButton
        title={modalObj.title}
        buttonText={"Ok"}
        buttonAction={() => {
          setModalObj((prev) => ({ ...prev, isOpen: false }));
        }}
        open={modalObj.isOpen}
        setOpen={(value) => {
          setModalObj((prev) => ({ ...prev, isOpen: value }));
        }}
      >
        <p>{modalObj.message}</p>
      </ModalSimpleButton>
    </>
  );
}
