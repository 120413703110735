import API from "../index";

export default class OrderStatus {
  constructor({ orderStatusName, isActive, displayColor, displayOrder }) {
    this.orderStatusName = orderStatusName;
    this.isActive = isActive;
    this.displayColor = displayColor;
    this.displayOrder = displayOrder;
  }

  static async getAll() {
    const api = await API();
    const { data } = await api.get("/orderstatus");
    return data.result.map((orderStatus) => new OrderStatus(orderStatus));
  }

  static async getOrderStatusByName(orderStatusName) {
    const api = await API();
    const { data } = await api.get(
      `/orderstatus?orderStatusName=${orderStatusName}`
    );
    return data.result.map((orderStatus) => new OrderStatus(orderStatus));
  }
}
