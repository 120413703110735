import React, { useContext } from "react";
import {
  Box,
  FormControl,
  styled,
  Autocomplete,
  TextField
} from "@mui/material";
import ResultComponentForm from "./ResultComponentForm";
import ResultComponent from "../../api/Admin/ResultComponent";
import ResearchComponent from "../../api/Admin/ResearchComponent";
import { getMessage, hasRole, Roles } from "../../global";
import ModalSimpleButton from '../../components/Modal/ModalSimpleButton';
import UserContext from "../../context/UserContext";
import { GlobalSecondaryButton, GlobalButton } from "../styles";

const StyledBox = styled(Box)(() => ({
  display: 'flex',
  marginBottom: "20px"
}));

const StyledFormControl = styled(FormControl)(() => ({
  display: 'flex',
  width: "50rem"
}));

const StyledDiv = styled('div')({
  display: 'flex',
  justifyContent: "space-between"
});

const ResultComponents = ({ ...props }) => {

  const initialState = {
    id: 0,
    GRDB: "",
    researchComponentID: 0,
    name: "",
    status: true,
    description: "",
    historicalIDNUM: 0,
    createdByName: "",
    lastUpdatedName: "",
    createdByDate: "",
    lastUpdatedDate: "",
    carbonNumber: "",
    oxygenNumber: "",
    molecularWeight: "",
    specificGravity: "",
    smilesString: "",
    hydrogenNumber: "",
    nitrogenNumber: ""
  };

  const validationsInitialState =
  {
    name: ""
  };

  const [isAdd, setIsAdd] = React.useState(false);
  const [infoData, setInfoData] = React.useState(initialState);
  const [invalidField, setInvalidField] = React.useState(validationsInitialState);

  const [resultComponentList, setResultComponentList] = React.useState(null);

  //const [GRDBList, setGRDBList] = React.useState(null);

  const [modalSimpleButton, setModalSimpleButton] = React.useState(false);
  const [modalSimpleButtonText, setModalSimpleButtonText] = React.useState('');
  const [modalSimpleTitle, setModalSimpleTitle] = React.useState('');
  const [modalSimpleText, setModalSimpleText] = React.useState('');

  const currentUser = useContext(UserContext)
  const roles = currentUser?.idTokenClaims.roles

  const canEdit = hasRole(Roles.Developer, roles) || hasRole(Roles.ResultComponentAdministrator, roles)

  function resetForm() {
    setInfoData(initialState);
    setInvalidField(validationsInitialState);
  }

  function handleChangeResultComponentFilter(idSelected) {
    if (idSelected) {
      resetForm();

      setInfoData(() => ({
        ...initialState,
        id: idSelected,
        name: idSelected
      }));

      setIsAdd(false);

      ResultComponent.get(idSelected).then((resultComponent) => {
        if (resultComponent) {

          if (resultComponent.researchComponentID) {

            ResearchComponent.get(resultComponent.researchComponentID).then((researchComponent) => {

              if (researchComponent) {
                setInfoData(() => ({
                  id: resultComponent.name,
                  GRDB: resultComponent.GRDBProperty,
                  researchComponentID: resultComponent.researchComponentID,
                  name: resultComponent.name,
                  status: resultComponent.isActive,
                  description: resultComponent.description,
                  historicalIDNUM: resultComponent.legacyIDNUM,
                  createdByName: resultComponent.createdByEmail,
                  lastUpdatedName: resultComponent.lastModifiedByEmail,
                  createdByDate: resultComponent.createdDate,
                  lastUpdatedDate: resultComponent.lastModifiedDate,
                  carbonNumber: researchComponent.carbonNumber,
                  oxygenNumber: researchComponent.oxygenNumber,
                  molecularWeight: researchComponent.molecularWeight,
                  specificGravity: researchComponent.specificGravity,
                  hydrogenNumber: researchComponent.hydrogenNumber,
                  smilesString: researchComponent.smilesString,
                  nitrogenNumber: researchComponent.nitrogenNumber,
                }));
              }
            })
          }
          else {
            setInfoData(() => ({
              id: resultComponent.name,
              GRDB: resultComponent.GRDBProperty,
              name: resultComponent.name,
              status: resultComponent.isActive,
              description: resultComponent.description,
              historicalIDNUM: resultComponent.legacyIDNUM,
              createdByName: resultComponent.createdByEmail,
              lastUpdatedName: resultComponent.lastModifiedByEmail,
              createdByDate: resultComponent.createdDate,
              lastUpdatedDate: resultComponent.lastModifiedDate,
              carbonNumber: "",
              oxygenNumber: "",
              molecularWeight: "",
              specificGravity: "",
              smilesString: "",
              hydrogenNumber: "",
              nitrogenNumber: ""
            }));
          }
        }
      });
    } else {
      resetForm();
    }
  }

  function handleChangeIsAdd() {
    setIsAdd(true);
    resetForm();
  }

  function loadResultComponentFilter() {

    ResultComponent.getAll().then((res) => {
      if (res.length > 0) {

        let array = [];
        array.push({ id: 0, name: "" });
        array.push(...res);

        setResultComponentList(array);
      }
    });

    // ResultComponent.getGRDB().then((res) => {
    //   if (res && res.message === 'Success') 
    //   {
    //     setGRDBList(res.data);
    //   } else {
    //     openModalSimpleButton('GRDB Load Fail', ` ${res ? res.message : 'Unspecified Error'}. Contact support if you feel this is an error.`, 'OK');  
    //     setGRDBList(null)        
    //   } 
     
    // });
  }

  function handleSubmit(event) {
    event.preventDefault();
    if (validateFields()) {
      if (invalidField.name.length === 0) {

        if (infoData.id === 0) {
          ResultComponent.get(infoData.name).then((res) => {
            if (res === null || res === undefined || res.legacyIDNUM === null || res.legacyIDNUM === undefined) {
              addUpdateResearch();
            }
            else {
              openModalSimpleButton('Success', 'This result component name already exist', 'Ok');
            }
          });
        }
        else {
          addUpdateResearch();
        }
      }
    }
  }


  function addUpdateResearch() {

    let researchComp = new ResearchComponent({
      id: infoData.researchComponentID,
      carbonNumber: infoData.carbonNumber,
      hydrogenNumber: infoData.hydrogenNumber,
      oxygenNumber: infoData.oxygenNumber,
      nitrogenNumber: infoData.nitrogenNumber,
      specificGravity: infoData.specificGravity,
      molecularWeight: infoData.molecularWeight,
      smilesString: infoData.smilesString
    });

    if (Object.entries(researchComp).filter(([k, v], i) => !!v).length === 0) {
      addUpdateResultComponent();
    }
    else {
      if (infoData.researchComponentID === 0 || infoData.researchComponentID === null || infoData.researchComponentID === undefined) {
        ResearchComponent.add(researchComp).then((res) => {
          if (res.id !== 0) {

            setInfoData(() => ({
              ...infoData,
              researchComponentID: res.id
            }));
            addUpdateResultComponent(res.id);
          }
        });
      }
      else {
        ResearchComponent.update(infoData.researchComponentID, researchComp).then((res) => {
          if (res.id !== 0) {
            setInfoData(() => ({
              ...infoData,
              researchComponentID: res.id
            }));

            addUpdateResultComponent(res.id);
          }
        });
      }
    }
  }

  function addUpdateResultComponent(researchComponentID) {

    let resultComp = new ResultComponent({
      name: infoData.name,
      legacyIDNUM: infoData.historicalIDNUM,
      description: infoData.description,
      isActive: infoData.status,
      createdByEmail: infoData.createdByName,
      createdDate: infoData.createdByDate,
      lastModifiedByEmail: infoData.lastUpdatedName,
      lastModifiedDate: infoData.lastUpdatedDate,
      researchComponentID: researchComponentID,
      GRDBProperty: infoData.GRDB,
    });

    ResultComponent.add(resultComp).then((res) => {
      if (res) {
        resetForm();
        openModalSimpleButton('Success', 'Result Component saved successfully!', 'Ok');
        loadResultComponentFilter();
      }
      else
        openModalSimpleButton('Fail', 'Something went wrong, please try again later.', 'Ok');
    });
  }

  function validateFields() {
    setInvalidField({
      ...invalidField,
      name: infoData.name === "" ? getMessage('REQUIRED_FIELD') : ""
    });

    return !(infoData.name === "");
  }

  function closeModalSimpleButton() {
    setModalSimpleButton(false);
  }

  function openModalSimpleButton(title, text, buttonText) {
    setModalSimpleButtonText(buttonText)
    setModalSimpleButton(true);
    setModalSimpleTitle(title);
    setModalSimpleText(text);
  }

  React.useEffect(() => {
    if (resultComponentList === null) {
      loadResultComponentFilter();
    }
  }, [resultComponentList]);

  return (

    <div>
      <form onSubmit={handleSubmit}>
        <StyledDiv>
          <div>
            <StyledBox>
              <StyledFormControl sx={{ m: 1, width: "44rem" }} variant='outlined'>

                <StyledBox sx={{ justifyContent: "space-between" }}>
                  <Autocomplete
                    noOptionsText={"Loading Result Components..."}
                    value={infoData.name}
                    onChange={(e, value) => { handleChangeResultComponentFilter(value) }}
                    disablePortal
                    options={resultComponentList ? resultComponentList.map((component) => component.name.toString()) : []}
                    sx={{ width: "35rem" }}
                    renderInput={(params) => <TextField {...params} variant="outlined" size="small" margin="none" label="Result Component" />}
                    autoSelect
                  />

                  { canEdit && <GlobalSecondaryButton sx={{width:"8rem", marginLeft:"1rem" }}
                      variant='contained'
                      type="button"
                      onClick={handleChangeIsAdd}
                    >Add New</GlobalSecondaryButton>
                  }

                </StyledBox>

              </StyledFormControl>

            </StyledBox>

            {infoData.id === 0 && isAdd === false ? <div></div> : <ResultComponentForm
              infoData={infoData} setInfoData={setInfoData}
              invalidField={invalidField}
              handleChangeResultComponentFilter={handleChangeResultComponentFilter}
              //GRDBList={GRDBList}
              canEdit={canEdit}>
            </ResultComponentForm>}

          </div>

        </StyledDiv>

        <div>
          {
            (infoData.id === 0 && isAdd === false) || !canEdit ? <div></div> :
              <div>
                <StyledBox sx={{ mb: 1, mt: 2, justifyContent: "space-between" }}>
                  <GlobalButton sx={{ width: "8rem" }}
                    variant='contained'
                    type="submit"
                  >Save</GlobalButton>
                </StyledBox>
              </div>
          }
        </div>

      </form >

      <ModalSimpleButton title={modalSimpleTitle} buttonText={modalSimpleButtonText} buttonAction={closeModalSimpleButton} open={modalSimpleButton} setOpen={setModalSimpleButton}>
        <label>
          {modalSimpleText}
        </label>
      </ModalSimpleButton>

    </div >

  );
};

export default ResultComponents;
