import { convertToLocalTime, formatMidasNumber, convertDateFormat } from "../../global";
import {useState} from "react";
import {TableBody} from "@mui/material";
import { UXDataTableWithoutBody, StyledTableCell, StyledTableBody, StyledTableRow } from "../../components/UXDataTable";

const containerCols = ['MIDAS #', 'Container', 'Status', 'Description','ChemID', 'Size', '% Full', 'Location', 'Sub Location','Last Modified By','Owner','Created On', "Exp. Date"]

const SearchContainers = ({inputSearchCriteria, myContainers, dataIsLoading}) => {
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  const noDataMessage = `No containers found with midas number, chem id or sample owner = ${inputSearchCriteria}`

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  
  const determineStartArray = () => {
    return (myContainers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage))
  }

  return (
    <>
      <UXDataTableWithoutBody 
        tableWidth='100%' 
        cols={containerCols} 
        blankFirstHeader={false}
        tableId="mySamplesSearch"
        rowLength={myContainers ? myContainers.length : 0}
        enablePaging={true}
        rowsPerPage={rowsPerPage}
        page={page}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        noDataFoundMessage={noDataMessage}
        enableAddIcon={false}
        addFunction={null}
        addToolTipText={null}  
        isDataLoading={dataIsLoading}
      >
        {myContainers && myContainers.length > 0 ? (
          determineStartArray().map((oContainer, index) => {
            return (
              <StyledTableBody key={`${oContainer.sampleName}${index}-${index}`}>
                <StyledTableRow>
                  <StyledTableCell sx={{minWidth:"8rem"}}>
                    <b>{formatMidasNumber(oContainer.sampleName)}</b>
                  </StyledTableCell>
                  <StyledTableCell>{oContainer.containerNumber}</StyledTableCell>
                  <StyledTableCell>{oContainer.containerStatusName}</StyledTableCell>
                  <StyledTableCell>{oContainer.sample.description}</StyledTableCell>
                  <StyledTableCell>{oContainer.sample.chemID}</StyledTableCell>

                  <StyledTableCell>
                    {oContainer.size} {oContainer.containerSizeUoM}
                  </StyledTableCell>
                  <StyledTableCell>
                    {oContainer.size > 0 ? ((oContainer.currentAmount / oContainer.size) * 100).toFixed(2): 0}%
                  </StyledTableCell>
                  <StyledTableCell>{oContainer.locationName}</StyledTableCell>
                  <StyledTableCell>{oContainer.subLocation}</StyledTableCell>
                  <StyledTableCell>
                    {oContainer.lastModifiedByEmail === null ? oContainer.createdByEmail : oContainer.lastModifiedByEmail}
                  </StyledTableCell>
                  <StyledTableCell>{oContainer.ownerEmail}</StyledTableCell>
                  <StyledTableCell>
                    {convertToLocalTime(oContainer.createdDate)}
                  </StyledTableCell>

                  <StyledTableCell>
                    {oContainer.expirationDate === null ? 'N/A' : convertDateFormat(oContainer.expirationDate)}
                  </StyledTableCell>

                  
                </StyledTableRow>
              </StyledTableBody>
            );
          })
        ) : (
        <TableBody>
          <StyledTableRow>
            <StyledTableCell colSpan='12'>
              <p>{noDataMessage}</p>
            </StyledTableCell>
          </StyledTableRow>
        </TableBody>
        )}
      </UXDataTableWithoutBody>
    </>
  );
};

export default SearchContainers;
