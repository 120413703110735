import API from "..";
export default class PiscesBacklog {
    /**
     * The Container dimension table for M3
     * @param TODO
     */
    constructor({
      methodName,
      testFacilityAbv,
      conditionOfferingName,
      methodDescription,
      numberRequest,
      numberActivated,
      numberCompleted,
      numberPending,
      numberInProgress,
      currentBacklog,

    }) {
      this.methodName = methodName;
      this.testFacilityAbv = testFacilityAbv;
      this.conditionOfferingName = conditionOfferingName;
      this.methodDescription = methodDescription;
      this.numberRequest = numberRequest;
      this.numberActivated = numberActivated;
      this.numberCompleted = numberCompleted;
      this.numberPending = numberPending;
      this.numberInProgress = numberInProgress;
      this.currentBacklog = currentBacklog;
    }


    static async get(startDate, endDate, testFacilities, page, resultsPerPage) {
      const queryString = testFacilities.join('&testFacilities=');
      const api = await API();
      const { data } = await api.get(`/piscesreporting/backlog?startDate=${startDate}&endDate=${endDate}&page=${page}&resultsPerPage=${resultsPerPage}${queryString ? '&testFacilities=' + queryString : ''}`);
      return data.result.map((d) => new PiscesBacklog(d));
    }

}