import API from "..";
export default class PiscesTest {
    /**
     * The Container dimension table for M3
     * @param TODO
     */
    constructor({
      methodName,
      testFacilityAbv,
      conditionOfferingName,
      methodDescription,
      sampleName,
      submittedDate,
      receivedDate,
      reportedDate,
      status,
      requesterEmail,
      submitterEmail,
      testEstimate,
      priority,
      testGroup,
      billingInfo,
      inProgressDate,
      analystEmail,
    }) {
      this.methodName = methodName;
      this.testFacilityAbv = testFacilityAbv;
      this.conditionOfferingName = conditionOfferingName;
      this.methodDescription = methodDescription;
      this.sampleName = sampleName;
      this.submittedDate = submittedDate;
      this.receivedDate = receivedDate;
      this.reportedDate = reportedDate;
      this.status = status;
      this.requesterEmail = requesterEmail;
      this.submitterEmail = submitterEmail;
      this.testEstimate = testEstimate;
      this.priority = priority;
      this.testGroup = testGroup;
      this.billingInfo = billingInfo;
      this.inProgressDate = inProgressDate;
      this.analystEmail = analystEmail;
    }


    static async get(startDate, endDate, testFacilities, page, resultsPerPage) {
      const queryString = testFacilities.join('&testFacilities=');
      const api = await API();
      const { data } = await api.get(`/piscesreporting/test?startDate=${startDate}&endDate=${endDate}&page=${page}&resultsPerPage=${resultsPerPage}${queryString ? '&testFacilities=' + queryString : ''}`);
      return data.result.map((d) => new PiscesTest(d));
    }

}