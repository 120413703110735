import { createContext, useState } from "react";
import StockroomItemList from "../../../api/Stockroom/StockroomItemList";
import { useEffect } from "react";
import ModalSimpleButton from "../../../components/Modal/ModalSimpleButton";
import { Typography } from "@mui/material";

export const StockroomItemListContext = createContext(null);

export const StockroomItemListContextProvider = ({ children }) => {
  const [inventoryItems, setInventoryItems] = useState([]);
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [hasError, setHasError] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    if (hasError) setIsModalOpen(true);
  }, [hasError]);

  const getAllInventoryItems = async () => {
    setIsDataLoading(true);
    await StockroomItemList.getAll()
      .then((data) => {
        setIsDataLoading(false);
        const formattedData = data.map((item) => item?.ID);
        setInventoryItems(formattedData);
      })
      .catch((err) => {
        setHasError(err);
        setIsModalOpen(true);
      });
  };

  const getItemById = async (assetNumber) => {
    return await StockroomItemList.getItemById(assetNumber)
      .then((data) => {
        const formattedData = data.ID;
        return formattedData;
      })
      .catch((err) => {
        setHasError(err);
        setIsModalOpen(true);
      });
  };

  return (
    <StockroomItemListContext.Provider
      value={{
        inventoryItems,
        getAllInventoryItems,
        getItemById,
        isDataLoading,
      }}
    >
      {children}

      <ModalSimpleButton
        title={"Error"}
        buttonText={"Close"}
        buttonAction={() => {
          setIsModalOpen(false);
          setHasError("");
        }}
        setOpen={() => {
          setIsModalOpen(false);
          setHasError("");
        }}
        open={isModalOpen}
      >
        <Typography
          style={{ padding: "0 16px", fontSize: 18, textAlign: "center" }}
        >
          {hasError}
        </Typography>
      </ModalSimpleButton>
    </StockroomItemListContext.Provider>
  );
};
