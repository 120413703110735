import React, { useState, useEffect } from "react";
import DistillationExperiment from '../../api/Distillation/DistillationExperiment';
import DistillationStatus from '../../api/Distillation/DistillationStatus';
import { GlobalButton, GlobalSecondaryButton, GlobalTabCollection, StyledTab } from "../../pages/styles";
import { Box} from "@mui/material";
import TableDistillationsBacklog from "./TableDistillationBacklog";
import FilterMenu from "../../components/FilterMenu"
import TableCompletedExperiments from "./TableAllCompletedExperiments";

const ManageDistillations = () => {
  const [tabValue, setTabValue] = useState(0);
  const [filteringOpen, setFilteringOpen] = useState(false);
  const [filters, setFilters] = useState([{name:null, displayName:null, operator:null, enumValues:[], value:''}])

  const [myExperiments, setMyExperiments] = useState([])
  const [myCompletedExperiments, setMyCompletedExperiments] = useState([])

  const [distillationStatuses, setDistillationStatuses] = useState([])
  const [isExperimentsLoading, setIsExperimentsLoading] = useState(true)
  const [isCompletedLoading, setIsCompletedLoading] = useState(true)

  const filterOptions = [{name: "id", displayName: "Distillation #", type: "string", enumValues:[]}, 
  {name: "initialContainer.sampleName",displayName: "Feed MIDAS #", type: "midasNumber", enumValues:[]},  //this is initialContainer.samplename
  {name: "requestedByEmail",displayName: "Requester", type: "string", enumValues:[]},
  {name: "createdDate",displayName: "Request Date", type: "date", enumValues: []},
  {name: "mnemonic",displayName: "Mnemonic", type: "string", enumValues:[]},
  {name: "comments",displayName: "Comments", type: "string", enumValues:[]},
  {name: "experimentStartDate",displayName: "Start Date", type: "date", enumValues: []},
  {name: "createdDate", displayName: "Submitted On", type: "date", enumValues:[]},
  {name: "requestedCompletionDate",displayName: "Req. Completion Date", type: "string", enumValues:[]},
  {name: "distillationStatusName",displayName: "Status", type: "enum", enumValues: distillationStatuses}
  ]

  //get all the API info
  useEffect(() => {
    let cancelPromise = false

    DistillationExperiment.getOpenExperiments().then((res) => {
      setIsExperimentsLoading(false)
      if (cancelPromise) return

      if(res.message === "Success")
      {
        setMyExperiments(res.result.sort((a, b) => b.distillationPriorityName - a.distillationPriorityName || new Date(a.createdDate) - new Date(b.createdDate)))
      } else {
        setMyExperiments([])
      }
    })

    DistillationExperiment.getCompletedExperiments().then((res) => {
      setIsCompletedLoading(false)
      if (cancelPromise) return

      if(res.message === "Success")
      {
        setMyCompletedExperiments(res.result.sort((a, b) => new Date(b.createdDate) - new Date(a.createdDate)))
      } else {
        setMyCompletedExperiments([])
      }
    })
    
    DistillationStatus.getAllDistillationStatuses().then((res) => {
      if (cancelPromise) return
      setDistillationStatuses(res.filter(result => result.isActive === true).map((item) => (item.distillationStatusName)).sort())
    })

    return () => {
      cancelPromise = true
    }
  }, []) 

  const handleChange = (event, newValue) => {
    clearFiltersClick()
    setTabValue(newValue);
  };

  function closeFiltering() {
    setFilteringOpen(false);
  }

  function applyFilters() {
    setFilteringOpen(false);
  }

  const filterClick = (event) => {
    setFilteringOpen(true);
  }

  const clearFiltersClick = (event) => {
    setFilters([{name:null, displayName:null, operator:null, enumValues:[], value:''}])  
  }

  const renderSearchTab = () => {
    switch (tabValue) {
      case 0: {
        return <TableDistillationsBacklog
                  filters={filters} 
                  myExperiments={myExperiments} 
                  isDataLoading={isExperimentsLoading}
                />
      }

      case 1: {
        return <TableCompletedExperiments
                  filters={filters} 
                  myExperiments={myCompletedExperiments} 
                  isDataLoading={isCompletedLoading}
                />
      }
      default: {
        alert(tabValue);
      }
    }
  };

  const renderFilterMenu = () => {
    switch (tabValue) {
      case 0: {
        return  <FilterMenu 
                  open={filteringOpen} 
                  setOpen={setFilteringOpen} 
                  applyBtnAction={applyFilters} 
                  cancelButtonAction={closeFiltering} 
                  filteringInfo={filterOptions} 
                  appliedFilters={filters} 
                  setAppliedFilters={setFilters}/>
      }

      case 1: {
        return  <FilterMenu 
                  open={filteringOpen} 
                  setOpen={setFilteringOpen} 
                  applyBtnAction={applyFilters} 
                  cancelButtonAction={closeFiltering} 
                  filteringInfo={filterOptions} 
                  appliedFilters={filters} 
                  setAppliedFilters={setFilters}/>
      }

      default: {
        alert(tabValue);
      }
    }
  };
  return (
    <div>
      <Box sx={{ bgcolor: "#fff", pt:3, pb:1 }} display="flex">
       <GlobalTabCollection style={{marginRight:"1rem"}} scrollButtons="auto" variant="scrollable" value={tabValue} onChange={handleChange} aria-label='ant example'>
         <StyledTab label='Open Experiments'/>
         <StyledTab label='Completed Experiments'/>
       </GlobalTabCollection>

           <Box display="flex" alignItems={"center"} marginLeft="auto" marginTop="-.8rem">
               <GlobalButton style={{marginRight:"1rem"}}variant="contained"
                 onClick={() => filterClick()}>Filters</GlobalButton>

               {!(filters[0].name === null) && 
               <GlobalSecondaryButton  variant="contained" 
                 onClick={() => clearFiltersClick()}>Clear Filters</GlobalSecondaryButton>}
           </Box>
           
       <Box sx={{ p: 1 }} />
      </Box>
      {renderFilterMenu()}
      {renderSearchTab()}


    </div>
);
};

export default ManageDistillations;