import API from "..";

export default class DOTHazardClass {

  constructor({
    classNumber,
    classDescription,
    isActive,
    displayColor,
    displayName,
  }) 
    
  {
    this.classNumber = classNumber;
    this.classDescription = classDescription;
    this.isActive = isActive;
    this.displayColor = displayColor;
    this.displayName = displayName;
  }  

  static async getAll() {
    const api = await API();
    const { data } = await api.get(`/dotHazardClass`);
    if (data.result) {
        return data.result.map((d) => new DOTHazardClass(d));
    }
    else {
        return [];
    }
  }

  static async getAllActive() {
    const api = await API();
    const { data } = await api.get(`/dotHazardClass`);
    if (data && data.result && data.result.length > 0) {
        return data.result.filter(r => r.isActive);
    }
    else {
        return [];
    }
  }
}
