import API from "..";
export default class TestPriority {
    /**
     * The Container dimension table for M3
     * @param TODO
     */
    constructor({
      testPriorityName,
      isActive,
      priorityLevel
    }) {
      this.testPriorityName = testPriorityName;
      this.isActive = isActive;
      this.priorityLevel = priorityLevel;
    }


    static async getAllTestPriorities() {
      const api = await API();
      const { data } = await api.get('/testPriority');
      return data.result.map((d) => new TestPriority(d));
    }

}