import React, { useState, useEffect, useMemo } from "react";
import { 
  Box,
  FormControlLabel,
  Checkbox,
  Typography
} from "@mui/material";
import { GlobalButton, GlobalSecondaryButton } from "../../styles";
import ValidatedMidasNumberTextField from  "../../../components/ValidatedMidasNumberTextField";
import PrintInformation from "../../../components/PrintInformation";

export const ReprintLabel = () => {
  const [sample, setSample] = useState(null)
  const [containers, setContainers] = useState([])
  const [isInvalid, setIsInvalid] = useState(false)
  const [isPrintModalOpen, setIsPrintModalOpen] = useState(false)
  const [printLabelInfo, setPrintLabelInfo] = useState([])
  const [printShelfLabel, setPrintShelfLabel] = useState(false)

  const selectedContainers = useMemo(() => containers?.filter(container => container?.isChecked), [containers])

  useEffect(() => {
    if (sample?.sampleName) {
      const containerOptions = sample?.containers?.filter(c => c.containerStatusName === 'Confirmed').map(container => {
        return {
          id: container?.containerNumber,
          isChecked: false,
          containerNumber: container?.containerNumber?.toString()
        }
      })

      setContainers(containerOptions)
    } else {
      setContainers([])
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sample?.sampleName])

  return (
    <>
      <Box display="flex" flexDirection="column" gap={2}>
        <Box display="flex" flexDirection="column" gap={2}>
            <ValidatedMidasNumberTextField
                showLabel
                fieldWidth="10%"
                midasNumberObject={sample}
                setMidasNumberObject={(e) => setSample(e)}
                hasErrors={isInvalid}
                setHasErrors={(e) => setIsInvalid(e)}
                chemIDToMatch={sample?.substance ? sample?.substance?.chemID : null}
                autoSearch={false}
            />
        
            <Box display="flex" flexDirection="column" gap={1}>
                <Typography fontWeight="bold" fontSize={22} color="#1976d2">Sample Containers:</Typography>

                <Box display="flex" flexDirection="column" paddingLeft={3}>
                    <FormControlLabel
                        checked={containers?.length > 0 && selectedContainers?.length === containers?.length}
                        control={<Checkbox />}
                        disabled={containers?.length === 0}
                        label="Select all containers" 
                        onChange={(e) => {
                            setContainers(prevState => {
                                const updatedArray = prevState.map(item => {
                                    return {
                                        ...item,
                                        isChecked: e.target.checked
                                    }  
                                })

                                return updatedArray
                            })
                        }} 
                    />
                    {containers?.length > 0 ? (
                      <>
                        <Box display="grid" gap={1} gridTemplateColumns="1fr 1fr 1fr 1fr 1fr" width="30%">
                            {containers?.map(container => {
                                return (
                                    <FormControlLabel
                                        key={container?.id}
                                        checked={container?.isChecked} 
                                        control={<Checkbox />} 
                                        label={container?.containerNumber} 
                                        onChange={(e) => {
                                            setContainers(prevState => {
                                                const updatedArray = prevState.map(item => {
                                                    if (item?.id === container?.id) {
                                                        return {
                                                            ...container,
                                                            isChecked: e.target.checked
                                                        }
                                                    }
                                                    return item
                                                })

                                                return updatedArray
                                            })
                                        }} 
                                    />
                                )
                            })}
                        </Box>
                      </>
                    ) : (
                        <Typography>There are no containers for this Sample</Typography>
                    )}
                </Box>
            </Box>
        </Box>  

      <div style={{display:"flex"}}>
        <GlobalButton
          style={{ width: '5%' }}
          variant='contained'
          disabled={selectedContainers?.length === 0 || !sample}
          onClick={() => {
            const adaptedContainers = selectedContainers?.map(container => container?.containerNumber)

            setPrintLabelInfo(
              [{
                sampleName: sample?.sampleName,
                containerNumbers: adaptedContainers,
                includeAdditionalSampleInformation: false,
                chemIDOnly: false,
                printLabelSizeType: null,
                isBlindCoded: false,
                blindDescription: null,
                blindSampleName: null,
                isShelfLabel: false,
                shelfLabelText: null,
                includeShelfBarcode: false
              }]
            )
            setPrintShelfLabel(false)
            setIsPrintModalOpen(true)
          }}
        >
          Print
        </GlobalButton>

        <GlobalSecondaryButton
          style={{ width: '8%', marginLeft:"1rem" }}
          variant='contained'
          onClick={() => {
            setPrintLabelInfo(
              [{
                sampleName: null,
                containerNumbers: null,
                includeAdditionalSampleInformation: false,
                chemIDOnly: false,
                printLabelSizeType: null,
                isBlindCoded: false,
                blindDescription: null,
                blindSampleName: null,
                isShelfLabel: true,
                shelfLabelText: null,
                includeShelfBarcode: false
              }]
            )
            setPrintShelfLabel(true)
            setIsPrintModalOpen(true)
          }}
        >
          Print Shelf Label
        </GlobalSecondaryButton>
      </div>

      </Box>
      <PrintInformation 
        open={isPrintModalOpen} 
        setOpen={setIsPrintModalOpen} 
        button2Action={() => setIsPrintModalOpen(false)} 
        button2Text="Close"
        printLabelInfo={printLabelInfo}
        isShelfLabel={printShelfLabel}
        > 
      </PrintInformation>
    </>
  );
};
