import React from "react";
import { Box, Divider } from "@mui/material";
import StockroomManagement from "./StockroomManagement";
import StockroomOrder from "./StockroomOrder";
import { GlobalTabCollection, StyledTab } from "../../pages/styles";


export const StockroomContent = ({ ...props }) => {
  const [tabValue, setTabValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const renderSearchTab = () => {
    switch (tabValue) {
      case 0: {
        return <StockroomOrder />;
      }
      case 1: {
        return <StockroomManagement />;
      }
      default: {
        alert(tabValue);
      }
    }
  };
  return (
    <Box paddingBottom={4}>
      <span className="pageheader">Stockroom</span>
      <Divider className="dividerbar" />
      <div>
        <Box sx={{ bgcolor: "#fff", pt:3, pb:1 }}>
          <GlobalTabCollection
            value={tabValue}
            onChange={handleChange}
            aria-label="ant example"
          >
            <StyledTab label="Orders" />
            <StyledTab label="Stockroom Management" />
          </GlobalTabCollection>
          <Box sx={{ p: 3 }} />
        </Box>
        {renderSearchTab()}
      </div>
    </Box>
  );
};
