import React, {useContext} from "react";
import {Box, FormControl, styled, TextField, Autocomplete, CircularProgress} from "@mui/material";
import MethodApi from "../../api/LIMS/Method";
import UnitOfMeasureApi from "../../api/Admin/UnitOfMeasure";
import MethodManagementForm from "./MethodManagementForm";
import { getMessage, hasRole, Roles } from "../../global";
import ModalSimpleButton from '../../components/Modal/ModalSimpleButton';
import UserContext from "../../context/UserContext";
import { GlobalButton, GlobalSecondaryButton } from "../../pages/styles";

const StyledBox = styled(Box)(() => ({
    display: 'flex',
    marginBottom: "20px"
}));

const StyledFormControl = styled(FormControl)(() => ({
    display: 'flex',
    width: "50rem"
}));

const StyledDiv = styled('div')({
    display: 'flex',
    justifyContent: "space-between"
});

const MethodManagement = (props) => {

    const initialStateMethod = {
        id: undefined,
        name: "",
        description: "",
        methodCategoryName: "",
        isActive: false,
        createdByEmail: "",
        createdDate: "",
        lastModifiedByEmail: "",
        lastModifiedDate: "",
        isMethodDestructive: false,
        criticalityLevel: "",
    };

    const validationsInitialState =
    {
        name: "",
        testOwnerEmail: "",
        requiredSampleSizeUoM: "",
        requiredSampleSize: ""
    };

    function initialStateMethodFacility() {
        return {
            Id: 0,
            testFacilityAbv: '',
            containeringGroup: null,
            legacyTableID: 0,
            requiresEstimates: false,
            testOwnerEmail: '',
            testCostUSD: null,
            requiredSampleSize: '',
            requiredSampleSizeUoM: '',
            requiresConditions: false,
            testConditionSetup: '',
            requiresSeparateContainer: false,
            messageForSubmitter: '',
            allowPrioritization: false,
            targetTurnaroundInDays: null,
            dropboxLocation: '',
            isSQCMethod: false,
            allowUserDefinedConditions: false,
            testConditionOfferings: [],
            methodFacilityResultComponents: []
        }
    }

    const [isAdd, setIsAdd] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(false);
    const [infoData, setInfoData] = React.useState(initialStateMethod);
    const [methodManagementList, setMethodManagementList] = React.useState(null);
    const [invalidField, setInvalidField] = React.useState(validationsInitialState);
    const [methodFacilityList, setMethodFacilityList] = React.useState([]);

    const [selectedFacility, setSelectedFacility] = React.useState(initialStateMethodFacility());
    const [showFacility, setShowFacility] = React.useState('');
    const [rowsFacility, setRowsFacility] = React.useState([]);
    const [rowsResultComponent, setRowsResultComponent] = React.useState([]);

    const [unitOfMeasureList, setUnitOfMeasureList] = React.useState([]);

    const [modalSimpleButton, setModalSimpleButton] = React.useState(false);
    const [modalSimpleButtonText, setModalSimpleButtonText] = React.useState('');
    const [modalSimpleTitle, setModalSimpleTitle] = React.useState('');
    const [modalSimpleText, setModalSimpleText] = React.useState('');

    const currentUser = useContext(UserContext)
    const roles = currentUser?.idTokenClaims.roles

    const canEdit = hasRole(Roles.Developer, roles) || hasRole(Roles.MethodAdministrator, roles)

    function closeModalSimpleButton() {
        setModalSimpleButton(false);
    }

    function openModalSimpleButton(title, text, buttonText) {
        setModalSimpleButtonText(buttonText)
        setModalSimpleButton(true);
        setModalSimpleTitle(title);
        setModalSimpleText(text);
    }

    function saveMethod() {
        setIsLoading(true);
        let methodResultComponentObj = rowsResultComponent.map((item) => ({
            resultComponentName: item.resultComponentName,
            upperLimit: item.upperLimit,
            lowerLimit: item.lowerLimit,
            isOptionalResult: item.isOptionalResult,
            significantDigits: item.significantDigits,
            methodName: infoData.name,
            uoMName: item.uoMName.filter(function (item) {
                return item['selected'] === true
            })[0]?.value
        }));

        let methodFacilityObj = methodFacilityList.map((item) => ({
            id: item.id === undefined ? 0 : item.id,
            isActive: item.isActive,
            methodName: infoData.name,
            testFacilityAbv: item.testFacilityAbv.abreviation,
            containeringGroup: item.containeringGroup,
            legacyTableID: item.legacyTableID,
            requiresEstimates: item.requiresEstimates,
            testOwnerEmail: item.testOwnerEmail,
            testCostUSD: item.testCostUSD,
            requiredSampleSize: item.requiredSampleSize,
            requiredSampleSizeUoM: item.requiredSampleSizeUoM,
            requiresConditions: item.requiresConditions,
            requiresSeparateContainer: item.requiresSeparateContainer,
            messageForSubmitter: item.messageForSubmitter,
            allowPrioritization: item.allowPrioritization,
            targetTurnaroundInDays: item.targetTurnaroundInDays,
            dropboxLocation: item.dropboxLocation,
            isSQCMethod: item.isSQCMethod,
            allowUserDefinedConditions: item.allowUserDefinedConditions,
            methodStatusName: item.methodStatusName,
            testConditionOfferings: item.testConditionOfferings === undefined ? [] : item.testConditionOfferings.map((offering) => ({
                ...offering,
                testConditionSetupValues: offering.testConditionSetupValues.map((condition) => ({
                    testConditionOfferingID: offering.id ? offering.id : 0,
                    testConditionName: condition.testConditionName,
                    maximumValue: condition.maximumValue,
                    minimumValue: condition.minimumValue,
                    discreteValue: condition.discreteValue,
                    unitOfMeasure: condition.unitOfMeasure.filter(function (item) {
                        return item['selected'] === true
                    })[0]?.value
                }))
            })),
            methodFacilityResultComponents: item.methodFacilityResultComponents === undefined ? [] : item.methodFacilityResultComponents.map((mfrc) => ({
                resultComponentName: mfrc.resultComponentName,
                methodName: infoData.name,
                methodFacilityID: mfrc.methodFacilityID,
                upperLimit: mfrc.upperLimit,
                lowerLimit: mfrc.lowerLimit,
                significantDigits: mfrc.significantDigits,
            }))
        }));

        let methodObj = {
            name: infoData.name,
            description: infoData.description,
            methodCategoryName: infoData.methodCategoryName,
            isActive: infoData.isActive,
            createdByEmail: infoData.createdByEmail,
            createdDate: infoData.createdDate,
            lastModifiedByEmail: infoData.lastModifiedByEmail,
            lastModifiedDate: infoData.lastModifiedDate,
            isMethodDestructive: infoData.isMethodDestructive,
            criticalityLevel: infoData.criticalityLevel,
            methodResultComponents: methodResultComponentObj,
            methodFacilities: methodFacilityObj
        };

        MethodApi.addUpdate(methodObj).then((res) => {
            if (res && !res.message) {
                resetForm();
                setIsAdd(false);
                openModalSimpleButton('Success', 'Method Saved with success', 'Ok');
                loadMethodManagementFilter();
            }
            else
                openModalSimpleButton('Fail', 'Something went wrong, please try after sometime', 'Ok');

            setIsLoading(false);
        });      
    }

    function handleSubmit(event) {
        event.preventDefault();

        if (!validateFields()) {

            if (infoData.id !== undefined) 
                saveMethod();
            else {

                MethodApi.get(infoData.name).then((methodExists) => {
                    if ((methodExists !== undefined && methodExists !== null))
                        openModalSimpleButton('Fail', 'This method already exist', 'Ok');
                    else saveMethod();
                });
            }
        }
    }

    function handleChangeIsAdd() {
        setIsAdd(true);
        resetForm();
    }

    function afterValidateFields(methFac) {
        const facilities = rowsFacility.map((row) => {
            if (row.rowId === methFac?.testFacilityAbv?.rowId) {
                return {
                    ...row,
                    rowSelected: true
                }
            } else {
                return {
                    ...row,
                    rowSelected: false
                }
            }
        })

        setRowsFacility([...facilities]);
        setSelectedFacility(methFac)
        handleShowFacility(methFac?.testFacilityAbv?.rowId);
    }

    function validateFields() {
        const methodFacilityListChanged = methodFacilityList.filter(f => f.changed === true);

        let requiredTestOwner = "";
        let requiredSampleSizeUoM = "";
        let requiredSampleSize = "";
        let requiredMethodName = infoData.name === "" ? getMessage('REQUIRED_FIELD') : "";

        let errorMethodConditions = false;

        methodFacilityListChanged.forEach(methFac => {
            if (!((requiredMethodName !== "") || (requiredTestOwner !== "") || (requiredSampleSizeUoM !== "" || errorMethodConditions === true))) {
                if (requiredTestOwner === "") {
                    requiredTestOwner = methFac.testOwnerEmail === "" || methFac.testOwnerEmail === null ? getMessage('REQUIRED_FIELD') : "";
                    afterValidateFields(methFac)
                }  

                if (requiredSampleSizeUoM === "") {
                    requiredSampleSizeUoM = methFac.requiredSampleSizeUoM === "" || methFac.requiredSampleSizeUoM === null ? getMessage('REQUIRED_FIELD') : "";
                    afterValidateFields(methFac)
                }

                if (requiredSampleSize === "") {
                    requiredSampleSize = methFac.requiredSampleSize === "" || methFac.requiredSampleSize === null ? getMessage('REQUIRED_FIELD') : "";
                    afterValidateFields(methFac)
                }

                if (methFac.requiresConditions && (!methFac.testConditionOfferings || methFac.testConditionOfferings.length < 1)) {
                    openModalSimpleButton('Alert', `Please at least one Condition must be listed`, 'Ok');
                    errorMethodConditions = true;
                }

            }
        });

        setInvalidField({
            ...invalidField,
            name: requiredMethodName,
            testOwnerEmail: requiredTestOwner,
            requiredSampleSizeUoM: requiredSampleSizeUoM,
            requiredSampleSize: requiredSampleSize
        });


        return ((requiredMethodName !== "") || (requiredTestOwner !== "") || (requiredSampleSizeUoM !== "" || requiredSampleSize !== "" || errorMethodConditions === true));
    }

    function resetForm() {

        setInfoData(initialStateMethod);
        setInvalidField(validationsInitialState);
        setMethodFacilityList([]);
        setSelectedFacility(initialStateMethodFacility());
        setRowsFacility([]);
        setRowsResultComponent([]);
    }

    function handleShowFacility(id) {
        setShowFacility(id);
        setTimeout(() => {
            setShowFacility('');
        }, 1000)
    }

    function handleChangeMethodManagementFilter(idSelected) {


        setInfoData(initialStateMethod);

        setInfoData(() => ({
            ...initialStateMethod,
            id: idSelected,
            name: idSelected
        }));

        setIsAdd(true);

        setInvalidField(validationsInitialState);
        setMethodFacilityList([]);
        setSelectedFacility(initialStateMethodFacility());
        setRowsFacility([]);
        setRowsResultComponent([]);

        if (idSelected !== "") {
            MethodApi.get(idSelected).then((method) => {
                if (method !== null) {

                    setInfoData(() => ({
                        ...infoData,
                        id: idSelected,
                        name: method.name,
                        description: method.description,
                        methodCategoryName: method.methodCategoryName,
                        isActive: method.isActive,
                        createdByEmail: method.createdByEmail,
                        createdDate: method.createdDate,
                        lastModifiedByEmail: method.lastModifiedByEmail,
                        lastModifiedDate: method.lastModifiedDate,
                        isMethodDestructive: method.isMethodDestructive,
                        criticalityLevel: method.criticalityLevel,
                    }));

                    let facilities = method.methodFacilities.map((item) => ({
                        rowId: item.testFacilityAbv,
                        id: item.testFacilityAbv,
                        abreviation: item.testFacilityAbv,
                        selected: false
                    }));

                    setRowsFacility([...facilities]);

                    let methodFacilityList = method.methodFacilities.map((item) => ({
                        id: item.id === undefined ? 0 : item.id,
                        methodName: method.name,
                        testFacilityAbv: {
                            rowId: item.testFacilityAbv,
                            id: item.testFacilityAbv,
                            abreviation: item.testFacilityAbv,
                            selected: false
                        },
                        isActive: item.isActive,
                        containeringGroup: item.containeringGroup,
                        legacyTableID: item.legacyTableID,
                        requiresEstimates: item.requiresEstimates,
                        testOwnerEmail: item.testOwnerEmail,
                        testCostUSD: item.testCostUSD,
                        requiredSampleSize: item.requiredSampleSize,
                        requiredSampleSizeUoM: item.requiredSampleSizeUoM,
                        requiresConditions: item.requiresConditions,
                        requiresSeparateContainer: item.requiresSeparateContainer,
                        messageForSubmitter: item.messageForSubmitter,
                        allowPrioritization: item.allowPrioritization,
                        targetTurnaroundInDays: item.targetTurnaroundInDays,
                        dropboxLocation: item.dropboxLocation,
                        isSQCMethod: item.isSQCMethod,
                        allowUserDefinedConditions: item.allowUserDefinedConditions,
                        methodStatusName: item.methodStatusName,
                        testConditionOfferings: item.testConditionOfferings.map((offering) => ({
                            ...offering,
                            testConditionSetupValues: offering.testConditionSetupValues.map((condition) => ({
                                ...condition,
                                unitOfMeasure: unitOfMeasureList.map((item) => ({
                                    key: item.uoMName, value: item.uoMName, selected: condition.unitOfMeasure === item.uoMName
                                }))
                            })),
                            testFacilityAbv: item.testFacilityAbv
                        })),
                        methodFacilityResultComponents: method.methodResultComponents.map((mrc) => {
                            const methodFacilityID = item.id === undefined ? 0 : item.id;
                            const mfrc = item.methodFacilityResultComponents.find(m => m.methodName === method.name && m.resultComponentName === mrc.resultComponentName && m.methodFacilityID === methodFacilityID);
                            return {
                                resultComponentName: mrc.resultComponentName,
                                methodName: method.name,
                                methodFacilityID: methodFacilityID,
                                upperLimit: mfrc?.upperLimit || mfrc?.upperLimit === 0 ? mfrc?.upperLimit : mrc.upperLimit,
                                lowerLimit: mfrc?.lowerLimit || mfrc?.lowerLimit === 0 ? mfrc?.lowerLimit : mrc.lowerLimit,
                                significantDigits: mfrc?.significantDigits ? mfrc?.significantDigits : mrc.significantDigits,
                            }
                        })
                        
                    }));

                    let methodResultComponents = method.methodResultComponents.map((item) => ({
                        resultComponentName: item.resultComponentName,
                        upperLimit: item.upperLimit,
                        lowerLimit: item.lowerLimit,
                        isOptionalResult: item.isOptionalResult,
                        significantDigits: item.significantDigits,
                        methodName: infoData.name,
                        uoMName: unitOfMeasureList.map((unit) => ({
                            key: unit.uoMName, value: unit.uoMName, selected: item.uoMName === unit.uoMName
                        }))
                    }));

                    if (methodFacilityList.length > 0)
                        setMethodFacilityList([...methodFacilityList]);

                    if (methodResultComponents.length > 0)
                        setRowsResultComponent([...methodResultComponents])

                }
            });
        }
    }

    function loadMethodManagementFilter() {
        MethodApi.getAll().then((res) => {
            if (res.length > 0) {
                let array = [];
                array.push({ id: undefined, name: "" });
                array.push(...res);

                setMethodManagementList(array.filter(obj => obj.isPackageTest === false));
            }
        });
    }

    React.useMemo(() => {
        if (methodManagementList === null) { 
            loadMethodManagementFilter();
        }
    }, [methodManagementList])

    React.useEffect(() => {
        let unmounted = false;

        if (unitOfMeasureList.length === 0) {
            UnitOfMeasureApi.getAll().then((res) => {
                if (!unmounted && res.length > 0) {
                    setUnitOfMeasureList(res.filter(f => f.isActive === true).sort((a, b) => a.uoMName.localeCompare(b.uoMName)));
                }
            });
        }

        return () => { unmounted = true };
    }, [unitOfMeasureList]);

    return (

        <div style={{scrollBehavior: 'smooth'}}>
            <form onSubmit={handleSubmit}>
                <StyledDiv>
                    <div>
                        <StyledBox>
                            <StyledFormControl sx={{width: "35rem" }} variant='outlined'>

                                <StyledBox sx={{ justifyContent: "space-between" }}>
                                    <Autocomplete
                                        noOptionsText={"Loading Methods..."}
                                        value={infoData.name === '' ? null : infoData.name}
                                        onChange={(e, value) => {
                                            handleChangeMethodManagementFilter(value);
                                        }}
                                        disablePortal
                                        options={methodManagementList ? methodManagementList.map((method) => method.name.toString()) : []}
                                        sx={{ width: "35rem" }}
                                        renderInput={(params) => <TextField {...params} variant="outlined" size="small" margin="none" label="Method Name" />}
                                        autoSelect
                                    /> 

                                    <GlobalSecondaryButton sx={{ width: "8rem" , marginLeft: "1rem"}}
                                        variant='contained'
                                        type="button"
                                        onClick={handleChangeIsAdd}
                                    >Add New</GlobalSecondaryButton>

                                </StyledBox>

                            </StyledFormControl>

                        </StyledBox>

                        {isAdd === true && <MethodManagementForm
                            infoData={infoData} setInfoData={setInfoData} methodFacilityList={methodFacilityList} setMethodFacilityList={setMethodFacilityList}
                            invalidField={invalidField} selectedFacility={selectedFacility} setSelectedFacility={setSelectedFacility}
                            rowsFacility={rowsFacility} setRowsFacility={setRowsFacility} rowsResultComponent={rowsResultComponent} setRowsResultComponent={setRowsResultComponent}
                            validateFields={validateFields}
                            unitOfMeasureList={unitOfMeasureList}
                            setUnitOfMeasureList={setUnitOfMeasureList}
                            handleChangeMethodManagementFilter={handleChangeMethodManagementFilter}
                            canEdit={canEdit}
                            showFacility={showFacility}
                            setShowFacility={handleShowFacility}
                        >
                        </MethodManagementForm>}

                        {
                            isAdd === true &&
                                <div>
                                    <StyledBox style={{marginTop: 20}}>
                                        <GlobalButton sx={{ width: "8rem" }}
                                            variant='contained'
                                            type="submit"
                                            disabled={!canEdit || isLoading}>Save Method</GlobalButton>
                                        {isLoading && <CircularProgress style={{textTransform: "none", marginLeft:"15px"}}></CircularProgress>}
                                    </StyledBox>
                                </div>
                        }

                    </div>

                </StyledDiv>

            </form >

            <ModalSimpleButton title={modalSimpleTitle} buttonText={modalSimpleButtonText} buttonAction={closeModalSimpleButton} open={modalSimpleButton} setOpen={setModalSimpleButton}>
                <label>
                    {modalSimpleText}
                </label>
            </ModalSimpleButton>

        </div >
    )
}

export default MethodManagement;