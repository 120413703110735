import React from "react";

const AnalyticalMasterData = ({ ...props }) => {
    return (
      <div>
        <p>
          Coming soon to a browser near you!
        </p>

          <li>Method Category</li>
          <li>Test Facility</li>
          <li>Test Priority</li>
          <li>Test Status</li>
      </div>
    );
  };
  
  export default AnalyticalMasterData;