import React, { useState, useContext, useEffect, useRef, useMemo } from "react";
import {
  Box,
  Typography,
  FormControl,
  FormControlLabel,
  Checkbox,
  TextField,
  InputLabel,
  Select,
  MenuItem,
  Autocomplete,
  IconButton,
  CircularProgress,
} from "@mui/material";
import { Edit } from "@mui/icons-material";
import {
  StyledInput,
  SmallImageBox,
  StyledTableCellSmall,
  StyledTableCellMedium,
  StyledTableCellLarge,
} from "./styles";
import {
  StyledTableBody,
  UXDataTableWithoutBody,
  StyledTableRow,
} from "../../components/UXDataTable";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import ModalTwoButtons from "../../components/Modal/ModalTwoButtons";
import ModalSimpleButton from "../../components/Modal/ModalSimpleButton";
import { convertDateFormat, DatePickerKeyDownEvent } from "../../global";

import { CategoryContext } from "./context/CategoryContext";
import { InventoryItemContext } from "./context/InventoryItemContext";
import { VendorContext } from "./context/VendorContext";
import { StockroomPOContext } from "./context/StockroomPOContext";
import { StockroomRequestContext } from "./context/StockroomRequestContext";

import UnitOfMeasureAPI from "../../api/Admin/UnitOfMeasure";
import LocationApi from "../../api/Admin/Location";
import noImage from "../../assets/no_image.jpg";
import SampleAttachment from "../../api/LIMS/SampleAttachment";
import { GlobalButton, GlobalSecondaryButton } from "../styles";
import FilterRequest from "./components/FilterRequest";
import { GlobalTabCollection, StyledTab } from "../../pages/styles";

const stockroomCols = [
  "Stockroom #",
  "Description",
  "Active",
  "Location",
  "Sublocation",
  "Inventory",
  "RO Point",
  "RO Quantity",
  "RO Type",
  "SigReq",
  "RO Price",
  "RO Unit",
  "Sell Price",
  "Sell Unit",
  "SUinRU",
  "Acct",
  "Vendor Code",
  "Vendor Name",
  "Vendor Product",
  "Deliver",
];
const poForItemCols = [
  "PO Req #",
  "Status",
  "Order Date",
  "Received Date",
  "Order Quantity",
  "Received Quantity",
  "BO Quantity",
  "RO Price",
];
const requestsForItemCols = [
  "Req #",
  "Requested Date",
  "Requested By",
  "Issued Date",
  "Issued By",
  "Requested",
  "Issued",
  "Status",
];

const STOCK_ITEM_INITIAL_STATE = {
  alertQuantity: 0,
  capitalizedDate: null,
  chargeCode: "0",
  comments: null,
  companyCode: null,
  connection: null,
  contactEmail: "0",
  costCenter: "",
  createdByEmail: null,
  createdDate: undefined,
  deactivationDate: null,
  description: "",
  deviceType: null,
  elastomerType: null,
  equipmentPriceUSD: 0,
  equipmentStatusName: "Discarded",
  equipmentTypeName: "SI",
  imagePath: null,
  inletGaugeRange: null,
  inspectionFrequency: null,
  inventoryNote: null,
  isActive: false,
  isAutomaticReorder: false,
  isCapitalAsset: false,
  isDeliverable: false,
  isRecyclable: false,
  isSignatureRequired: false,
  itemVendor: null,
  lastModifiedByEmail: null,
  lastModifiedDate: null,
  locationName: "",
  manufacturer: null,
  maxInletPressure: null,
  mixture: null,
  modelNumber: null,
  originalAssetNumber: null,
  outletGaugeRange: null,
  outletPressure: null,
  ownerEmail: null,
  profitCenter: null,
  quantity: 0,
  reorderPoint: 0,
  reorderPriceUSD: 0,
  reorderQuantity: 0,
  reorderUoM: "g",
  replacementDate: null,
  restrictiveOrifice: null,
  sampleName: null,
  sapAssetNumber: null,
  sapAssetNumberSplit: null,
  serialNumber: null,
  service: null,
  setPressure: null,
  sheetNumber: null,
  siteAssetNumber: null,
  siteName: null,
  stockUnitOfMeasure: "g",
  stockroomCategoryName: "",
  storageAvailability: null,
  subLocation: null,
  suinru: 0,
  tagName: null,
  vendorID: 0,
  vendorStockNumber: "",
  weight: null,
  weightUoM: null,
};

const SRManageStock = ({ canEdit }) => {
  const [locationName, setLocationName] = useState("");
  const [uomList, setUoMList] = useState([]);
  const [roUnitName, setROUnitName] = useState("");
  const [roTypeList] = useState(["Automatic", "Non-Automatic"]);
  const [roType, setROType] = useState("");
  const [equipStatusName, setEquipStatusName] = useState("");
  const [equipStatusNameList] = useState(["Yes", "No"]);
  const [stockUnitName, setStockUnitName] = useState("");
  const [tabValue, setTabValue] = useState(0);
  const [selectedItem, setSelectedItem] = useState(STOCK_ITEM_INITIAL_STATE);
  const [selectedVendor, setSelectedVendor] = useState({});
  const [formVendorValue, setFormVendorValue] = useState("");
  const [formVendorName, setFormVendorName] = useState("");
  const [filteredStock, setFilteredStock] = useState([]);
  const [categoryPrefix, setCategoryPrefix] = useState("Chemicals");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [poRowsPerPage, setPORowsPerPage] = useState(10);
  const [poPage, setPOPage] = useState(0);
  const [requestsRowsPerPage, setRequestsRowsPerPage] = useState(10);
  const [requestsPage, setRequestsPage] = useState(0);
  const [stockroomNumber, setStockroomNumber] = useState("");
  const [isItemActive, setIsItemActive] = useState(true);
  const [isItemInactive, setIsItemInactive] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isMessageModalOpen, setIsMessageModalOpen] = useState(false);
  const [image, setImage] = useState(null);
  const [imageLoading, setImageLoading] = useState(false);
  const [messageContent, setMessageContent] = useState({
    title: "",
    message: "",
  });

  const { categories, getAllCategories } = useContext(CategoryContext);
  const { stockItems, getAllStockItems, manageStockItem, isInventoryDataLoading } =
    useContext(InventoryItemContext);
  const { getAllVendors, vendors } = useContext(VendorContext);
  const { getPOsForItem, posForItem, isPoDataLoading, refreshPosForItem } =
    useContext(StockroomPOContext);
  const { getRequestsForItem, requestsForItem, isDataLoading, refreshRequestsForItem } =
    useContext(StockroomRequestContext);

  const runWhenStart = useRef(true);

  useEffect(() => {
    if (runWhenStart.current) {
      getAllStockItems();
      runWhenStart.current = false;
    }
  }, [runWhenStart, getAllStockItems]);

  const filteredCategories = useMemo(() => {
    return categories?.filter((category) => {
      return category.StockroomCategoryName.stockroomCategoryName !== "No Charge";
    });
  }, [categories]);

  const day = new Date().getDate();
  const month = new Date().getMonth();
  const year = new Date().getFullYear();

  const [dateFrom, setDateFrom] = useState(null);
  const [dateTo, setDateTo] = useState(new Date(year, month, day));

  async function getAllUoM() {
    const response = await UnitOfMeasureAPI.getAll();
    setUoMList(response);
  }

  const handlePageChange = (event, page) => {
    setPage(page);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handlePOPageChange = (event, pOPage) => {
    setPOPage(pOPage);
  };

  const handleChangePORowsPerPage = (event) => {
    setPORowsPerPage(parseInt(event.target.value, 10));
    setPOPage(0);
  };

  const handleRequestsPageChange = (event, requestPage) => {
    setRequestsPage(requestPage);
  };

  const handleChangeRequestsRowsPerPage = (event) => {
    setRequestsRowsPerPage(parseInt(event.target.value, 10));
    setRequestsPage(0);
  };

  const handleCategoryPrefix = (category) => {
    switch (category) {
      case "Chemicals":
        return "CH";
      case "Elec Parts":
        return "EP";
      case "Electrical":
        return "EL";
      case "Engine":
        return "EN";
      case "Fittings":
        return "FI";
      case "Gauges":
        return "GA";
      case "Indstrl Test":
        return "IT";
      case "Insulation":
        return "IN";
      case "Miscellaneous":
        return "MI";
      case "Pumps":
        return "PU";
      case "Safety":
        return "SA";
      case "Struct Shapes":
        return "SS";
      case "Tools":
        return "TO";
      case "Tubing":
        return "TB";
      case "Unistrut":
        return "UN";
      case "Valves":
        return "VA";
      case "No Charge":
      default:
        break;
    }
  };

  const isStockroomNumberUsed = useMemo(() => {
    return !!stockItems.find(
      (item) =>
        item?.siteAssetNumber ===
        `${handleCategoryPrefix(categoryPrefix)}${stockroomNumber.toUpperCase()}`,
    );
  }, [stockroomNumber, categoryPrefix, stockItems]);

  const renderTabTable = () => {
    switch (tabValue) {
      case 0: {
        return (
          <UXDataTableWithoutBody
            tableWidth="100%"
            cols={poForItemCols}
            tableId="poForItemTable"
            rowLength={posForItem.length}
            enablePaging
            rowsPerPage={poRowsPerPage}
            page={poPage}
            handleChangePage={handlePOPageChange}
            handleChangeRowsPerPage={handleChangePORowsPerPage}
            noDataFoundMessage={"No PO for item found"}
            isDataLoading={isPoDataLoading}>
            <StyledTableBody>
              {posForItem.length > 0 &&
                posForItem
                  .slice(poPage * poRowsPerPage, poPage * poRowsPerPage + poRowsPerPage)
                  .map((po) => {
                    const foundItem = po?.poItems?.find(
                      (item) => item.equipmentID === selectedItem.id,
                    );

                    return (
                      <StyledTableRow key={po?.id} hover>
                        <StyledTableCellMedium>{po?.id}</StyledTableCellMedium>
                        <StyledTableCellSmall>
                          {foundItem && foundItem.orderStatusName}
                        </StyledTableCellSmall>
                        <StyledTableCellSmall>
                          {convertDateFormat(po?.createdDate)}
                        </StyledTableCellSmall>
                        <StyledTableCellMedium>
                          {foundItem && convertDateFormat(foundItem.receivedDate)}
                        </StyledTableCellMedium>
                        <StyledTableCellSmall>
                          {foundItem && (foundItem.itemQuantity ?? 0)}
                        </StyledTableCellSmall>
                        <StyledTableCellSmall>
                          {foundItem && (foundItem.receivedQuantity ?? 0)}
                        </StyledTableCellSmall>
                        <StyledTableCellSmall>
                          {foundItem && (foundItem.backorderedQuantity ?? 0)}
                        </StyledTableCellSmall>
                        <StyledTableCellMedium>
                          ${foundItem && foundItem.unitPriceUSD.toFixed(2)}
                        </StyledTableCellMedium>
                      </StyledTableRow>
                    );
                  })}
            </StyledTableBody>
          </UXDataTableWithoutBody>
        );
      }
      case 1: {
        return (
          <UXDataTableWithoutBody
            tableWidth="100%"
            cols={requestsForItemCols}
            tableId="requestsForItemTable"
            rowLength={requestsForItem.length}
            enablePaging
            rowsPerPage={requestsRowsPerPage}
            page={requestsPage}
            handleChangePage={handleRequestsPageChange}
            handleChangeRowsPerPage={handleChangeRequestsRowsPerPage}
            noDataFoundMessage={"No requests for item found"}
            isDataLoading={isDataLoading}>
            <StyledTableBody>
              {requestsForItem.length > 0 &&
                requestsForItem
                  .slice(
                    requestsPage * requestsRowsPerPage,
                    requestsPage * requestsRowsPerPage + requestsRowsPerPage,
                  )
                  .map((request) => {
                    const foundItem = request?.requestItems?.find(
                      (item) => item.equipmentID === selectedItem.id,
                    );

                    return (
                      <StyledTableRow key={request?.id} hover>
                        <StyledTableCellSmall>{request?.id}</StyledTableCellSmall>
                        <StyledTableCellMedium>
                          {convertDateFormat(request?.createdDate)}
                        </StyledTableCellMedium>
                        <StyledTableCellLarge>{request?.createdByEmail}</StyledTableCellLarge>
                        <StyledTableCellSmall>
                          {foundItem && convertDateFormat(foundItem.issuedDate)}
                        </StyledTableCellSmall>
                        <StyledTableCellLarge>
                          {foundItem && (foundItem.issuedByEmail ?? "N/A")}
                        </StyledTableCellLarge>
                        <StyledTableCellSmall>
                          {foundItem && foundItem.requestedQuantity}
                        </StyledTableCellSmall>
                        <StyledTableCellSmall>
                          {foundItem && foundItem.issuedQuantity}
                        </StyledTableCellSmall>
                        <StyledTableCellSmall>
                          {foundItem && foundItem.orderStatusName}
                        </StyledTableCellSmall>
                      </StyledTableRow>
                    );
                  })}
            </StyledTableBody>
          </UXDataTableWithoutBody>
        );
      }
      default: {
        alert(tabValue);
      }
    }
  };

  const searchItemsFilteredByCategory = (category) => {
    if (category === "All") {
      if (isItemActive && isItemInactive) {
        setFilteredStock(stockItems);
      } else if (isItemActive && !isItemInactive) {
        const filteredList = stockItems.filter((item) => item.equipmentStatusName === "Confirmed");
        setFilteredStock(filteredList);
      } else {
        const filteredList = stockItems.filter((item) => item.equipmentStatusName === "Discarded");
        setFilteredStock(filteredList);
      }
    } else {
      if (isItemActive && isItemInactive) {
        const filteredList = stockItems.filter((item) => {
          return item?.stockroomCategoryName?.toLowerCase() === category.toLowerCase();
        });
        setFilteredStock(filteredList);
      } else if (isItemActive && !isItemInactive) {
        const filteredList = stockItems.filter((item) => {
          return (
            item?.equipmentStatusName === "Confirmed" &&
            item?.stockroomCategoryName?.toLowerCase() === category.toLowerCase()
          );
        });
        setFilteredStock(filteredList);
      } else if (!isItemActive && isItemInactive) {
        const filteredList = stockItems.filter((item) => {
          return (
            item.equipmentStatusName === "Discarded" &&
            item?.stockroomCategoryName?.toLowerCase() === category.toLowerCase()
          );
        });
        setFilteredStock(filteredList);
      } else {
        setFilteredStock([]);
      }
    }
    setPage(0);
  };

  // const handleApplyFilters = () => {
  //   const description = description1Ref.current.value;
  //   const description2 = description2Ref.current.value;
  //   const stockroomNumber = stockroomNumberRef.current.value;

  //   if (!description && !description2 && !stockroomNumber) {
  //     return;
  //   }

  //   if (categoryFilter === "All") {
  //     const filteredList = filteredStock.filter((item) => {
  //       return (
  //         (description &&
  //           item?.description
  //             ?.toLowerCase()
  //             .includes(description.toLowerCase())) ||
  //         (description2 &&
  //           item?.description
  //             ?.toLowerCase()
  //             .includes(description2.toLowerCase())) ||
  //         (stockroomNumber &&
  //           item?.siteAssetNumber
  //             ?.toLowerCase()
  //             .includes(stockroomNumber.toLowerCase()))
  //       );
  //     });
  //     setFilteredStock(filteredList);
  //   } else {
  //     const filteredList = filteredStock.filter((item) => {
  //       return (
  //         (description &&
  //           item?.description
  //             ?.toLowerCase()
  //             .includes(description.toLowerCase()) &&
  //           item?.stockroomCategoryName?.toLowerCase() ===
  //           categoryFilter.toLowerCase()) ||
  //         (description2 &&
  //           item?.description
  //             ?.toLowerCase()
  //             .includes(description2.toLowerCase()) &&
  //           item?.stockroomCategoryName?.toLowerCase() ===
  //           categoryFilter.toLowerCase()) ||
  //         (stockroomNumber &&
  //           item?.siteAssetNumber
  //             ?.toLowerCase()
  //             .includes(stockroomNumber.toLowerCase()) &&
  //           item?.stockroomCategoryName?.toLowerCase() ===
  //           categoryFilter.toLowerCase())
  //       );
  //     });
  //     setFilteredStock(filteredList);
  //   }
  //   setPage(0);
  //   description1Ref.current.value = "";
  //   description2Ref.current.value = "";
  //   stockroomNumberRef.current.value = "";
  // };

  useEffect(() => {
    if (!categories.length) {
      getAllCategories();
    }
    if (!stockItems.length) {
      getAllStockItems();
    }
    if (!vendors.length) {
      getAllVendors();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    LocationApi.getAll().then((res) => {
      if (res.length > 0) {
        // setLocationList(res);
      }
    });
    getAllUoM();
  }, []);

  useEffect(() => {
    searchItemsFilteredByCategory("Chemicals");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stockItems]);

  useEffect(() => {
    searchItemsFilteredByCategory(categoryPrefix);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isItemActive, isItemInactive]);

  const convertImagePath = async (imagePath) => {
    setImageLoading(true);
    if (imagePath) {
      const containerName = imagePath.split("/")[imagePath.split("/").length - 2];
      const fileName = imagePath.split("/")[imagePath.split("/").length - 1];
      const response = await SampleAttachment.getFileAttachment(containerName, fileName);
      if (response) {
        setImage(`data:image/png;base64,${response}`);
        setImageLoading(false);
      } else {
        setImage(null);
        setImageLoading(false);
      }
    } else {
      setImage(null);
      setImageLoading(false);
    }
  };

  return (
    <Box>
      <Box display="flex" flexDirection="column">
        <Typography component="h2" fontSize={30} marginBottom={2}>
          Stockroom Inventory
        </Typography>
        <Box display="flex" alignItems="center" gap={2} marginBottom={2}>
          <FormControlLabel
            label="Active"
            control={
              <Checkbox defaultChecked onChange={(e) => setIsItemActive(e.target.checked)} />
            }
          />
          <FormControlLabel
            label="Inactive"
            control={<Checkbox onChange={(e) => setIsItemInactive(e.target.checked)} />}
          />

          <Box display="flex" gap={2} marginLeft={"20%"}>
            <Autocomplete
              noOptionsText={"Loading Categories..."}
              value={categoryPrefix}
              onChange={(_, value) => setCategoryPrefix(value)}
              disablePortal
              options={filteredCategories.map(
                (category) => category.StockroomCategoryName.stockroomCategoryName,
              )}
              renderInput={(params) => (
                <TextField {...params} label="Category" size="small" style={{ width: 200 }} />
              )}
              autoSelect
            />
            <StyledInput
              style={{ width: "30%" }}
              size="small"
              type="text"
              name="stockroom"
              label="Stockroom #"
              inputProps={{ maxLength: 7 }}
              value={`${handleCategoryPrefix(categoryPrefix)}${stockroomNumber.toUpperCase()}`}
              onChange={(event) =>
                setStockroomNumber(event.target.value.replace(/[a-z]|\s|\W|_/gi, ""))
              }
              error={isStockroomNumberUsed}
              {...(isStockroomNumberUsed && {
                helperText: "Stockroom # already exists.",
              })}
            />
            <GlobalSecondaryButton
              variant="contained"
              disabled={!stockroomNumber || isStockroomNumberUsed || !canEdit}
              style={{ height: 40 }}
              onClick={() => {
                if (
                  `${handleCategoryPrefix(categoryPrefix)}${stockroomNumber.toUpperCase()}`.length <
                  6
                ) {
                  setIsMessageModalOpen(true);
                  setMessageContent({
                    title: "Error",
                    message: "Stockroom # must be 6 or 7 characters.",
                  });
                } else {
                  setSelectedItem(STOCK_ITEM_INITIAL_STATE);
                  setStockUnitName("g");
                  setROUnitName("g");
                  setIsModalOpen(true);
                }
              }}>
              Add new
            </GlobalSecondaryButton>
          </Box>
        </Box>
        <Box display="flex" gap={2} marginBottom={2}>
          <Box display="flex" flexDirection="column" gap={2} width={"100%"}>
            <FilterRequest
              inventoryItems={stockItems}
              setFilteredItems={setFilteredStock}
              setInventoryPage={setPage}
              categories={categories}
            />
          </Box>
          {selectedItem?.id && (
            <Box display="flex" flexDirection="column" gap={2} alignItems="flex-end" flexGrow={0.4}>
              <SmallImageBox>
                {imageLoading ? (
                  <Typography style={{ fontSize: 22, fontWeight: "bold" }}>
                    Loading
                    <CircularProgress size={22} style={{ marginLeft: 10 }} />
                  </Typography>
                ) : image ? (
                  <img
                    style={{ objectFit: "contain" }}
                    src={image}
                    alt={image && "Item representation"}
                  />
                ) : (
                  <img src={noImage} alt={"Not available"} />
                )}
              </SmallImageBox>
              <GlobalSecondaryButton disabled={!canEdit} variant="contained">
                Update image
              </GlobalSecondaryButton>
            </Box>
          )}
        </Box>
        <UXDataTableWithoutBody
          tableWidth="100%"
          cols={stockroomCols}
          tableId="stockTable"
          blankFirstHeader
          rowLength={filteredStock.length}
          enablePaging
          rowsPerPage={rowsPerPage}
          page={page}
          handleChangePage={handlePageChange}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          noDataFoundMessage={"No items found"}
          isDataLoading={isInventoryDataLoading}>
          <StyledTableBody>
            {filteredStock.length > 0 &&
              filteredStock
                .sort((a, b) => a?.siteAssetNumber?.localeCompare(b?.siteAssetNumber))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((item) => {
                  return (
                    <StyledTableRow
                      key={item?.id}
                      hover
                      style={{
                        border: selectedItem?.id === item?.id && "3px solid #1976D2",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        if (selectedItem && selectedItem.id === item?.id) {
                          setImage(null);
                        } else {
                          setSelectedItem(item);
                          convertImagePath(item.imagePath);
                        }
                      }}>
                      <StyledTableCellSmall>
                        <Box display="flex" alignItems="center" gap={1} justifyContent="center">
                          <IconButton
                            style={{ padding: 0 }}
                            onClick={() => {
                              setSelectedItem(item);
                              setStockUnitName(item?.stockUnitOfMeasure);
                              setROUnitName(item?.reorderUoM);
                              setSelectedVendor(item?.itemVendor);
                              setFormVendorName(item?.itemVendor?.vendorName);
                              setFormVendorValue(item?.itemVendor?.vendorCode);
                              setLocationName(item?.locationName);
                              setIsModalOpen(true);
                              setEquipStatusName(
                                item?.equipmentStatusName === "Confirmed" ? "Yes" : "No",
                              );
                            }}
                            disabled={!canEdit}>
                            <Edit fontSize="small" />
                          </IconButton>
                        </Box>
                      </StyledTableCellSmall>
                      <StyledTableCellMedium>{item?.siteAssetNumber}</StyledTableCellMedium>
                      <StyledTableCellLarge>{item?.description}</StyledTableCellLarge>
                      <StyledTableCellSmall>
                        {item?.equipmentStatusName === "Confirmed" ? "Yes" : "No"}
                      </StyledTableCellSmall>
                      <StyledTableCellMedium>{item?.locationName}</StyledTableCellMedium>
                      <StyledTableCellMedium>{item?.subLocation}</StyledTableCellMedium>
                      <StyledTableCellSmall>{item?.quantity}</StyledTableCellSmall>
                      <StyledTableCellSmall>{item?.reorderPoint}</StyledTableCellSmall>
                      <StyledTableCellSmall>{item?.reorderQuantity}</StyledTableCellSmall>
                      <StyledTableCellMedium>
                        {item?.isAutomaticReorder ? "Automatic" : "Non-automatic"}
                      </StyledTableCellMedium>
                      <StyledTableCellSmall>
                        {item?.isSignatureRequired ? "Yes" : "No"}
                      </StyledTableCellSmall>
                      <StyledTableCellMedium>
                        ${item?.reorderPriceUSD?.toFixed(2)}
                      </StyledTableCellMedium>
                      <StyledTableCellSmall>{item?.reorderUoM?.toUpperCase()}</StyledTableCellSmall>
                      <StyledTableCellMedium>
                        ${item?.equipmentPriceUSD?.toFixed(2)}
                      </StyledTableCellMedium>
                      <StyledTableCellSmall>
                        {item?.stockUnitOfMeasure?.toUpperCase()}
                      </StyledTableCellSmall>
                      <StyledTableCellSmall>{item?.suinru}</StyledTableCellSmall>
                      <StyledTableCellSmall>{item?.costCenter}</StyledTableCellSmall>
                      <StyledTableCellMedium>{item?.itemVendor?.vendorCode}</StyledTableCellMedium>
                      <StyledTableCellMedium>{item?.itemVendor?.vendorName}</StyledTableCellMedium>
                      <StyledTableCellLarge>{item?.vendorStockNumber}</StyledTableCellLarge>
                      <StyledTableCellSmall>
                        {item?.isDeliverable ? "Yes" : "No"}
                      </StyledTableCellSmall>
                    </StyledTableRow>
                  );
                })}
          </StyledTableBody>
        </UXDataTableWithoutBody>
      </Box>
      {selectedItem?.id && (
        <Box marginTop={5}>
          <Box sx={{ bgcolor: "#fff", pt: 3, pb: 1 }}>
            <GlobalTabCollection value={tabValue} onChange={(_, newValue) => setTabValue(newValue)}>
              <StyledTab label="PO's for Item" />
              <StyledTab label="Requests for Item" />
            </GlobalTabCollection>
            <Box sx={{ p: 3 }} />
          </Box>
          <Box display="flex" flexDirection="column" gap={2}>
            <Box display="flex" alignItems="center" gap={2}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DesktopDatePicker
                  label="From"
                  value={dateFrom}
                  maxDate={new Date()}
                  onChange={(e) => setDateFrom(e)}
                  renderInput={(params) => (
                    <TextField onKeyDown={DatePickerKeyDownEvent} size="small" {...params} />
                  )}
                />
                <DesktopDatePicker
                  label="To"
                  value={dateTo}
                  maxDate={new Date()}
                  onChange={(e) => setDateTo(e)}
                  renderInput={(params) => (
                    <TextField onKeyDown={DatePickerKeyDownEvent} size="small" {...params} />
                  )}
                />
              </LocalizationProvider>
              <GlobalButton
                variant="contained"
                type="button"
                disabled={!selectedItem?.id && dateFrom === null}
                onClick={() => {
                  if (tabValue === 0) {
                    getPOsForItem(
                      selectedItem?.id,
                      `${new Date(dateFrom).getMonth() + 1}/${new Date(
                        dateFrom,
                      ).getDate()}/${new Date(dateFrom).getFullYear()}`,
                    );
                  } else {
                    getRequestsForItem(
                      selectedItem?.id,
                      `${new Date(dateFrom).getMonth() + 1}/${new Date(
                        dateFrom,
                      ).getDate()}/${new Date(dateFrom).getFullYear()}`,
                    );
                  }
                }}>
                Search
              </GlobalButton>
              <GlobalSecondaryButton
                variant="contained"
                type="button"
                disabled={posForItem.length === 0 && requestsForItem.length === 0}
                onClick={() => {
                  if (posForItem) {
                    refreshPosForItem();
                  }
                  if (requestsForItem) {
                    refreshRequestsForItem();
                  }
                }}>
                Clear List
              </GlobalSecondaryButton>
            </Box>
            {renderTabTable()}
          </Box>
        </Box>
      )}
      {isModalOpen && (
        <ModalTwoButtons
          title={`Stockroom # ${
            selectedItem.siteAssetNumber !== ""
              ? selectedItem.siteAssetNumber
              : `${handleCategoryPrefix(categoryPrefix)}${stockroomNumber.toUpperCase()}`
          }`}
          isButton1Disabled={!selectedItem.description}
          button1Text="Add/Update"
          button1Action={async () => {
            const payload = {
              ...selectedItem,
              //itemVendor: selectedVendor ?? null,
              vendorID: selectedVendor.id,
              locationName: locationName,
              reorderUoM: roUnitName,
              isAutomaticReorder: roType === "Automatic",
              siteAssetNumber:
                selectedItem.siteAssetNumber ??
                `${handleCategoryPrefix(categoryPrefix)}${stockroomNumber.toUpperCase()}`,
              stockUnitOfMeasure: stockUnitName,
              stockroomCategoryName: selectedItem?.stockroomCategoryName ?? categoryPrefix,
              equipmentStatusName: equipStatusName === "Yes" ? "Confirmed" : "Discarded",
            };

            await manageStockItem(payload)
              .then((data) => {
                setIsMessageModalOpen(true);
                setMessageContent({
                  title: "Success",
                  message: "Stockroom inventory item created/updated successfully.",
                });
                setStockroomNumber("");
                setIsModalOpen(false);
                setSelectedItem(STOCK_ITEM_INITIAL_STATE);
                setSelectedVendor({});
                setFormVendorValue("");
                setFormVendorName("");
                setLocationName("");
                setROUnitName("g");
                setStockUnitName("g");
                getAllStockItems();
              })
              .catch((err) => {
                const { status } = err;
                let errorMessage =
                  err.message ??
                  "The application has encountered an unknown error and cannot complete the action. Please contact a system administrator for help.";

                if (status === 403) {
                  errorMessage =
                    "You are lacking one or more roles and are unauthorized to perform this action.";
                }

                setMessageContent({
                  title: "Error",
                  message: errorMessage,
                });
                setIsMessageModalOpen(true);
              });
          }}
          button2Text="Cancel"
          button2Action={() => {
            setIsModalOpen(false);
            setStockroomNumber("");
            setSelectedItem(STOCK_ITEM_INITIAL_STATE);
            setSelectedVendor({});
            setFormVendorValue("");
            setFormVendorName("");
            setLocationName("");
            setROUnitName("g");
            setStockUnitName("g");
          }}
          setOpen={setIsModalOpen}
          open={isModalOpen}>
          <Box display="grid" gridTemplateColumns="repeat(3, 1fr)" gap={2} paddingTop={2}>
            <TextField
              size="small"
              type="text"
              name="description"
              label="Description"
              value={selectedItem.description}
              onChange={(e) => {
                setSelectedItem((prevState) => {
                  return { ...prevState, description: e.target.value };
                });
              }}
            />

            <Autocomplete
              noOptionsText={"Loading Equipment Status Name..."}
              value={equipStatusName}
              onChange={(_, value) => setEquipStatusName(value)}
              disablePortal
              options={equipStatusNameList}
              renderInput={(params) => (
                <TextField name="equipmentStatusName" {...params} label="Active" size="small" />
              )}
              autoSelect
            />

            <TextField
              size="small"
              type="text"
              name="location"
              label="Location"
              value={selectedItem.locationName}
              onChange={(e) => {
                setSelectedItem((prevState) => {
                  return { ...prevState, locationName: e.target.value };
                });
              }}
            />

            <TextField
              size="small"
              type="text"
              name="sublocation"
              label="Sublocation"
              value={selectedItem.subLocation}
              onChange={(e) => {
                setSelectedItem((prevState) => {
                  return { ...prevState, subLocation: e.target.value };
                });
              }}
            />

            <TextField
              size="small"
              type="number"
              name="inventory"
              label="Inventory"
              value={selectedItem.quantity}
              onChange={(e) => {
                setSelectedItem((prevState) => {
                  if (e.target.value < 0) {
                    return prevState;
                  }
                  const formattedValue = e.target.value.toString().replace(/e/gi, "");
                  return { ...prevState, quantity: +formattedValue };
                });
              }}
            />

            <TextField
              size="small"
              type="number"
              name="roPoint"
              label="RO Point"
              value={selectedItem.reorderPoint}
              onChange={(e) => {
                setSelectedItem((prevState) => {
                  if (e.target.value < 0) {
                    return prevState;
                  }
                  const formattedValue = e.target.value.toString().replace(/e/gi, "");
                  return { ...prevState, reorderPoint: +formattedValue };
                });
              }}
            />

            <TextField
              size="small"
              type="number"
              name="roQuantity"
              label="RO Quantity"
              value={selectedItem.reorderQuantity}
              onChange={(e) => {
                setSelectedItem((prevState) => {
                  if (e.target.value < 0) {
                    return prevState;
                  }
                  const formattedValue = e.target.value.toString().replace(/e/gi, "");
                  return { ...prevState, reorderQuantity: +formattedValue };
                });
              }}
            />

            <Autocomplete
              noOptionsText={"Loading RO Type..."}
              value={roType}
              onChange={(_, value) => setROType(value)}
              disablePortal
              options={roTypeList}
              renderInput={(params) => (
                <TextField name="roType" {...params} label="RO Type" size="small" />
              )}
              autoSelect
            />

            <TextField
              size="small"
              type="text"
              name="roPrice"
              label="RO Price"
              value={selectedItem.reorderPriceUSD}
              onChange={(e) => {
                setSelectedItem((prevState) => {
                  return { ...prevState, reorderPriceUSD: e.target.value };
                });
              }}
            />

            <Autocomplete
              noOptionsText={"Loading UoMs..."}
              value={roUnitName}
              onChange={(_, value) => setROUnitName(value)}
              disablePortal
              options={uomList.map((unit) => unit?.uoMName)}
              renderInput={(params) => (
                <TextField name="roUnit" {...params} label="RO Unit" size="small" />
              )}
              autoSelect
            />

            <TextField
              size="small"
              type="text"
              name="sellPrice"
              label="Sell Price"
              value={selectedItem.equipmentPriceUSD}
              onChange={(e) => {
                setSelectedItem((prevState) => {
                  return { ...prevState, equipmentPriceUSD: e.target.value };
                });
              }}
            />

            <Autocomplete
              noOptionsText={"Loading UoMs..."}
              value={stockUnitName}
              onChange={(_, value) => setStockUnitName(value)}
              disablePortal
              options={uomList.map((unit) => unit?.uoMName)}
              renderInput={(params) => (
                <TextField name="sellUnit" {...params} label="Sell Unit" size="small" />
              )}
              autoSelect
            />

            <TextField
              size="small"
              type="text"
              name="suInRu"
              label="SUinRU"
              value={selectedItem.suinru}
              onChange={(e) => {
                setSelectedItem((prevState) => {
                  return { ...prevState, suinru: e.target.value };
                });
              }}
              // onChange={(e) => {
              //   setSelectedItem((prevState) => {
              //     if (e.target.value < 0) {
              //       return prevState;
              //     }
              //     const formattedValue = e.target.value
              //       .toString()
              //       .replace(/e/gi, "");
              //     return { ...prevState, suinru: +formattedValue };
              //   });
              // }}
            />

            <FormControl>
              <InputLabel htmlFor="acct">Acct</InputLabel>
              <Select
                size="small"
                name="acct"
                label="Acct"
                value={selectedItem.costCenter}
                onChange={(e) => {
                  setSelectedItem((prevState) => {
                    return { ...prevState, costCenter: e.target.value };
                  });
                }}>
                <MenuItem value="" disabled>
                  Select an option
                </MenuItem>
                <MenuItem value="225">225</MenuItem>
                <MenuItem value="EXP">EXP</MenuItem>
                <MenuItem value="NCT">NCT</MenuItem>
              </Select>
            </FormControl>

            <Autocomplete
              noOptionsText={"Loading Vendors' Codes..."}
              value={selectedVendor?.vendorCode ?? ""}
              onChange={(_, value) => {
                if (value) {
                  const foundVendor = vendors.find((vendor) => vendor.vendorCode === value);
                  setSelectedVendor(foundVendor);
                  //setFormVendorName(foundVendor?.vendorName);
                } else {
                  setSelectedVendor({});
                  //setFormVendorName("");
                }
              }}
              inputValue={formVendorValue}
              onInputChange={(_, value) => setFormVendorValue(value)}
              disablePortal
              options={vendors.map((vendor) => vendor?.vendorCode ?? "")}
              renderInput={(params) => (
                <TextField name="vendorCode" {...params} label="Vendor Code" size="small" />
              )}
              autoSelect
            />

            <Autocomplete
              noOptionsText={"Loading Vendor Name..."}
              value={formVendorName}
              //onChange={(_, value) => setFormVendorName(value)}
              onChange={(_, value) => {
                if (value) {
                  const foundVendor = vendors.find((vendor) => vendor.vendorName === value);
                  setFormVendorName(foundVendor.vendorName);
                } else {
                  setFormVendorName("");
                }
              }}
              disablePortal
              options={vendors.map((vendor) => vendor?.vendorName ?? "")}
              renderInput={(params) => (
                <TextField {...params} label="Vendor Name" size="small" style={{ width: 200 }} />
              )}
              autoSelect
            />

            <TextField
              size="small"
              type="text"
              name="vendorProduct"
              label="Vendor Product"
              value={selectedItem.vendorStockNumber}
              onChange={(e) => {
                setSelectedItem((prevState) => {
                  return { ...prevState, vendorStockNumber: e.target.value };
                });
              }}
            />

            <FormControlLabel
              label="Is Deliverable"
              control={
                <Checkbox
                  name="deliver"
                  checked={selectedItem.isDeliverable}
                  onChange={(e) => {
                    setSelectedItem((prevState) => {
                      return { ...prevState, isDeliverable: e.target.checked };
                    });
                  }}
                />
              }
            />
          </Box>
        </ModalTwoButtons>
      )}
      {isMessageModalOpen && (
        <ModalSimpleButton
          title={messageContent.title}
          buttonText="Ok"
          buttonAction={() => setIsMessageModalOpen(false)}
          setOpen={setIsMessageModalOpen}
          open={isMessageModalOpen}>
          <Typography style={{ textAlign: "center" }}>{messageContent.message}</Typography>
        </ModalSimpleButton>
      )}
    </Box>
  );
};

export default SRManageStock;
