import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from '@mui/material/FormControl';
import { CONTAINERING_METHOD_ENUM, areTwoIntervalsTheSame } from "../../shared";
import type { Interval, Methods } from "../../types";
import HelpIcon from '@mui/icons-material/Help';
import { useState } from "react";
import ModalMessages from "../../../../components/Modal/ModalSimpleButton";
import { globalMessages } from "../../../../global";


type ContainerManagementProps = {
  selectedInterval: Interval | null;
  intervals: Interval[];
  setIntervals(newIntervals: Interval[]): void;
  setSelectedInterval(newInterval: Interval): void;
  disabled: boolean;
  methods: Methods[];
  containeringMethodSelected: string;
};

export function ContainerManagement({
  selectedInterval,
  setIntervals,
  setSelectedInterval,
  disabled,
  intervals,
  methods,
  containeringMethodSelected,
}: ContainerManagementProps) {

  const [modalMessagesOpen, setModalMessagesOpen] = useState(false);
  const modalMessagesButtonText = 'Ok'
  const [modalMessagesTitle, setModalMessagesTitle] = useState('');
  const [modalMessagesText, setModalMessagesText] = useState('');

  function closeModalMessages() {
    setModalMessagesOpen(false);
  }

  function openModalMessages(title: string, text: string) {
    setModalMessagesOpen(true);
    setModalMessagesTitle(title);
    setModalMessagesText(text);
  }

  return (
    <Box display="flex" flexDirection="column" alignItems="flex-start">
      <Typography fontSize={20} color="black" marginBottom={2}>
        Container Management
      </Typography>
      <FormControl>
        <RadioGroup style={{ flexDirection: "row" }}>
          <FormControlLabel
            value={CONTAINERING_METHOD_ENUM.NON_SPLIT}
            key={CONTAINERING_METHOD_ENUM.NON_SPLIT}
            label="Make NonSplit"
            control={
              <Radio
                checked={containeringMethodSelected === CONTAINERING_METHOD_ENUM.NON_SPLIT}
                onChange={() => {
                  if (selectedInterval) {
                    const newList = intervals.map((val) => {
                      if (areTwoIntervalsTheSame(val, selectedInterval)) {
                        return {
                          ...val,
                          containeringMethod: CONTAINERING_METHOD_ENUM.NON_SPLIT,
                          testJSON: JSON.stringify(methods),
                        };
                      }
                      return val;
                    });

                    const newSelected = intervals.find((val) =>
                      areTwoIntervalsTheSame(val, selectedInterval)
                    );

                    setIntervals(newList);
                    if (newSelected) setSelectedInterval(newSelected);
                  }
                }}
              />
            }
            disabled={disabled}
          />
           <HelpIcon style={{marginTop:".5rem", marginRight:"1rem", color:"blue"}} onClick={(e) => openModalMessages("Container Info", globalMessages.NonSplitMsg)}></HelpIcon>
          <FormControlLabel
            value={CONTAINERING_METHOD_ENUM.MIDAS}
            key={CONTAINERING_METHOD_ENUM.MIDAS}
            label="MIDAS Recommendation"
            control={
              <Radio
                checked={containeringMethodSelected === CONTAINERING_METHOD_ENUM.MIDAS}
                onChange={() => {
                  if (selectedInterval) {
                    const newList = intervals.map((val) => {
                      if (areTwoIntervalsTheSame(val, selectedInterval)) {
                        return {
                          ...val,
                          containeringMethod: CONTAINERING_METHOD_ENUM.MIDAS,
                          testJSON: JSON.stringify(methods),
                        };
                      }
                      return val;
                    });

                    setIntervals(newList);
                    const newSelected = intervals.find((val) =>
                      areTwoIntervalsTheSame(val, selectedInterval)
                    );

                    if (newSelected) setSelectedInterval(newSelected);
                  }
                }}
              />
            }
            disabled={disabled}
          />
          <HelpIcon style={{marginTop:".5rem", color:"blue"}} onClick={(e) => openModalMessages("Container Info", globalMessages.MidasMsg)}></HelpIcon>
        </RadioGroup>
      </FormControl>

      <ModalMessages title={modalMessagesTitle} buttonText={modalMessagesButtonText} buttonAction={closeModalMessages} open={modalMessagesOpen} setOpen={setModalMessagesOpen}>
        <label>
          {modalMessagesText}
        </label>
      </ModalMessages>
    </Box>
  );
}
