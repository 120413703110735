import { useState } from "react";
import Menu from "@mui/material/Menu";
import MoreOptions from "@mui/icons-material/MoreVert";
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import type { AdditionalMenuAction } from "../types";

type CreateRequestsAdditionActionsProps = {
  additionalMenuActions: AdditionalMenuAction[];
  row: any;
}

export default function CreateRequestsAdditionActions({
  additionalMenuActions,
  row,
}: CreateRequestsAdditionActionsProps) {
  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);

  function handleClick(event: any) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  return (
    <div style={{ display: "flex" }}>
      <IconButton onClick={handleClick}>
        <MoreOptions aria-haspopup="true" />
      </IconButton>

      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        {additionalMenuActions?.map((ama, i) => (
          <MenuItem
            key={`${ama.label}${i}${row.id}`}
            onClick={() => {
              ama.action(row);
              handleClose();
            }}
          >
            {ama.label}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}
