import { createContext, useState } from "react";
import Image from "../../../api/Stockroom/Image";
import { useEffect } from "react";
import ModalSimpleButton from "../../../components/Modal/ModalSimpleButton";
import { Typography } from "@mui/material";

export const ImageContext = createContext(null);

export const ImageContextProvider = ({ children }) => {
  const [images, setImages] = useState([]);
  const [hasError, setHasError] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    if (hasError) setIsModalOpen(true);
  }, [hasError]);

  const getAllImages = async () => {
    await Image.getAll()
      .then((data) => {
        setImages(data);
      })
      .catch((err) => {
        setHasError(err);
        setIsModalOpen(true);
      });
  };

  const getImageById = async (imageId) => {
    await Image.getImageById(imageId)
      .then((data) => {
        return data;
      })
      .catch((err) => {
        setHasError(err);
        setIsModalOpen(true);
      });
  };

  return (
    <ImageContext.Provider value={{ images, getImageById, getAllImages }}>
      {children}
      <ModalSimpleButton
        title={"Error"}
        buttonText={"Close"}
        buttonAction={() => {
          setIsModalOpen(false);
          setHasError("");
        }}
        setOpen={() => {
          setIsModalOpen(false);
          setHasError("");
        }}
        open={isModalOpen}
      >
        <Typography
          style={{ padding: "0 16px", fontSize: 18, textAlign: "center" }}
        >
          {hasError}
        </Typography>
      </ModalSimpleButton>
    </ImageContext.Provider>
  );
};
