import { useState, useContext, useEffect } from "react";
import { Box} from "@mui/material";
import TableMyStudies from "./TableMyStudies";
import TableAllStudies from "./TableAllStudies";
import TableBatchQueue from "./TableBatchQueue";
import FilterMenu from "../../components/FilterMenu"
import BlendStudy from "../../api/Formulations/BlendStudy";
import UserContext from "../../context/UserContext";
import BatchQueue from "../../api/Formulations/BatchQueue";
import BlendStatus from "../../api/Formulations/BlendStatus";
import BatchStatus from "../../api/Formulations/BatchStatus";
import BlendPriority from "../../api/Formulations/BlendPriority";
import UnitOfMeasure from "../../api/Admin/UnitOfMeasure";
//import Draft from "../../api/LIMS/Draft"
//import Template from "../../api/LIMS/Template"
//import { UXDataTable } from "../../components/UXDataTable";
import { Link } from "react-router-dom";
import { GlobalButton, GlobalSecondaryButton, GlobalTabCollection, StyledTab } from "../../pages/styles";
//import ModalMessages from "../../components/Modal/ModalSimpleButton";
import BlendFacility from "../../api/Formulations/BlendFacility";
//import ModalTwoButtons from "../../components/Modal/ModalTwoButtons";

// const MyDraftCols = [
//   { field: 'draftName', headerName: 'Draft Name', type: 'button-text-draft-blends', path: '/blendstudy'},
//   { field: 'draftType', headerName: 'Draft Type', type: 'label' },
//   { field: 'draftOwnerEmail', headerName: 'Draft Owner', type: 'label' },
//   { field: 'createdDate', headerName: 'Create Date', type: 'datetime' },
//   { field: 'lastModifiedDate', headerName: 'Last Modified', type: 'datetime'}
// ];

// const MyTemplateCols = [
//   { field: 'templateName', headerName: 'Template Name', type: 'button-text-template-blends', path: '/blendstudy'},
//   { field: 'templateType', headerName: 'Template Type', type: 'label' },
//   { field: 'templateOwnerEmail', headerName: 'Template Owner', type: 'label' },
//   { field: 'isPublic', headerName: 'Is Public', type: 'boolean' },
//   { field: 'createdDate', headerName: 'Create Date', type: 'datetime' },
//   { field: 'lastModifiedDate', headerName: 'Last Modified', type: 'datetime'},
//   { field: 'messages', headerName: 'Messages', type: 'list-strings' }
// ];

const DesignStudy = ({ ...props }) => {
  const [tabValue, setTabValue] = useState(0);
  const [filteringOpen, setFilteringOpen] = useState(false);
  const [filters, setFilters] = useState([{name:null, displayName:null, operator:null, enumValues:[], value:''}])

  const [allStudies, setAllStudies] = useState([])
  const [isDataLoading, setIsDataLoading] = useState(true)
  //const [draftsIsLoading, setDraftsIsLoading] = useState(true)
  //const [templatesIsLoading, setTemplatesIsLoading] = useState(true)
  
  const [myStudies, setMyStudies] = useState([])
  const [isMyStudiesLoading, setIsMyStudiesLoading] = useState(true)
  const currentUser = useContext(UserContext) 
  let inputSearchCriteria = currentUser.username

  const [allBatches, setAllBatches] = useState([])
  const [isBatchesLoading, setBatchesLoading] = useState(true)

  const [blendStudyStatuses, setBlendStudyStatuses] = useState([])
  const [blendStudyPriorities, setBlendStudyPriorities] = useState([])
  const [batchStatuses, setBatchStatuses] = useState([])
  const [unitOfMeasures, setUnitOfMeasures] = useState([])
  const [blendFacilities, setBlendFacilities] = useState([])

  //const [myDrafts, setMyDrafts] = useState(null)
  //const [myTemplates, setMyTemplates] = useState(null)

  // const [modalMessagesOpen, setModalMessagesOpen] = useState(false);
  // const modalMessagesButtonText = 'Ok'
  // const [modalMessagesTitle, setModalMessagesTitle] = useState('');
  // const [modalMessagesText, setModalMessagesText] = useState('');

  // const [modalDeleteItemOpen, setModalDeleteItemOpen] = useState(false);
  // const [modalDeleteItemTitle, setModalDeleteItemTitle] = useState("");
  // const [modalDeleteItemText, setModalDeleteItemText] = useState("");
  // const [modalDeleteItemButton1Text, setModalDeleteItemButton1Text] = useState("");
  // const [modalDeleteItemButton2Text, setModalDeleteItemButton2Text] = useState("");
  // const [removeItem, setRemoveItem] = useState();

  const [refreshData, setRefreshData] = useState(false);

  // function closeModalMessages() {
  //   setModalMessagesOpen(false);
  // }

  // function openModalMessages(title, text) {
  //   setModalMessagesOpen(true);
  //   setModalMessagesTitle(title);
  //   setModalMessagesText(text);
  // }

  const filterOptions = [{name: "id", displayName: "Study ID", type: "integer", enumValues:[]}, 
    {name: "studyName",displayName: "Study Name", type: "string", enumValues:[]}, 
    {name: "studyPurpose",displayName: "Description", type: "string", enumValues:[]},
    {name: "ownerEmail",displayName: "Owner", type: "string", enumValues:[]},
    {name: "createdDate",displayName: "Date Created", type: "date", enumValues:[]},
    {name: "lastModifiedDate",displayName: "Last Modified", type: "date", enumValues:[]},
    {name: "blendStudyStatusName",displayName: "Status", type: "enum", enumValues: blendStudyStatuses}
  ]

  const batchFilterOptions = [{name: "studyName", displayName: "Study Name", type: "string", enumValues:[]}, 
    {name: "blendName",displayName: "Blend Name", type: "string", enumValues:[]}, 
    {name: "batchSequenceNumber",displayName: "Batch #", type: "integer", enumValues:[]},
    {name: "blendPriorityName",displayName: "Priority", type: "enum", enumValues: blendStudyPriorities},
    {name: "requestedCompletionDate",displayName: "Required Date", type: "date", enumValues:[]},
    {name: "requestedAmount",displayName: "Requested Amount", type: "integer", enumValues:[]},
    {name: "requestedAmountUoM",displayName: "UoM", type: "enum", enumValues: unitOfMeasures},
    {name: "ownerEmail",displayName: "Study Owner", type: "string", enumValues:[]},
    {name: "preparedByEmail",displayName: "Assigned Blender", type: "string", enumValues:[]},
    {name: "batchStatusName",displayName: "Status", type: "enum", enumValues: batchStatuses},
    {name: "age", displayName: "Age", type: "integer", enumValues:[]},
    {name: "blendFacilityName", displayName: "Facility", type: "enum", enumValues: blendFacilities}
  ]

  //get all the API info
  useEffect(() => {
    let cancelPromise = false
      
      BlendFacility.getAllFacilities().then((res) => {
        if (cancelPromise) return
          setBlendFacilities(res.filter(result => result.isActive === true).map((item) => (item.blendFacilityName)).sort())
      })

      BlendStatus.getAllBlendStatuses().then((res) => {
        if (cancelPromise) return
          setBlendStudyStatuses(res.filter(result => result.isActive === true).map((item) => (item.blendStatusName)).sort())
      });

      BatchStatus.getAllBatchStatuses().then((res) => {
        if (cancelPromise) return
          setBatchStatuses(res.filter(result => result.isActive === true).map((item) => (item.batchStatusName)).sort())
      });

      BlendPriority.getAllBlendPriorities().then((res) => {
        if (cancelPromise) return
          setBlendStudyPriorities(res.filter(result => result.isActive === true).map((item) => (item.blendPriorityName)).sort())
      });

      UnitOfMeasure.getAll().then((res) => {
        if (cancelPromise) return
          setUnitOfMeasures(res.filter(result => (result.type === 'weight' || result.type ==='volume') && result.metricStandardConversion !== null && result.isActive === true).sort((a, b) => a.uoMName.localeCompare(b.uoMName)))
      });

      BatchQueue.getBatchActiveQueue().then((res) => {
        if (cancelPromise) return
          setAllBatches(res)
          setBatchesLoading(false)
      });


      return () => {
        cancelPromise = true
      }
  }, [])  

  //get all the studies for owner
  useEffect(() => {
    let cancelPromise = false
      BlendStudy.getMyBlendStudies(inputSearchCriteria).then((res) => {
        if (cancelPromise) return
        setMyStudies(res)
        setIsMyStudiesLoading(false)
      });

      
      BlendStudy.getAllBlendStudies().then((res) => {
        if (cancelPromise) return
          setAllStudies(res)
          setIsDataLoading(false)
      });

    return () => {
      cancelPromise = true
    }
  }, [inputSearchCriteria, refreshData])

    //get all the studies for owner
  // useEffect(() => {
  //   let cancelPromise = false
         
  //   // if (myDrafts === null)
  //   // {
  //   //   setDraftsIsLoading(true)  
    
  //   //   Draft.getByUserAndType(inputSearchCriteria, "blends").then((res) => {
  //   //     if (cancelPromise) return
  //   //       setMyDrafts(res)
  //   //       setDraftsIsLoading(false)       
  //   //   })
  //   // }
    
  //   if (myTemplates === null)
  //   {
  //     setTemplatesIsLoading(true)  
    
  //     Template.searchTemplates(inputSearchCriteria, '', "blends", 'true').then((res) => {
  //       if (cancelPromise) return
  //       setMyTemplates(res)    
  //       setTemplatesIsLoading(false)  
  //     })
  //   }
          
  //   return () => {
  //     cancelPromise = true
  //   }
  // }, [inputSearchCriteria, myTemplates])

  // const RemoveDraft = () => {
  //   if (removeItem && removeItem.id) {
  //     Draft.delete(removeItem.id).then((res) => {
  //       closeModalDeleteItem();
  //       if (res.message === "Success") 
  //       {
  //         openModalMessages("Draft Deleted", "Draft successfully deleted!");
  //         setMyDrafts(null);
  //       } else {
  //         openModalMessages("Draft Failed to Delete",`${res.message}. Contact support if you feel this is an error.`);
  //       }
  //     });
  //   }
  // };

  // const RemoveTemplate = (() => {
  //   closeModalDeleteItem();
  //   if (removeItem){

  //     if (inputSearchCriteria !== removeItem.templateOwnerEmail)
  //     {
  //       openModalMessages("Template Error", "You cannot delete a template from another user!")
  //       return
  //     }

  //     Template.delete(removeItem.templateOwnerEmail, removeItem.templateName, 'blends').then((res) => {
  //       if (res.message === 'Success')
  //       {
  //         openModalMessages("Template Deleted", "Template successfully deleted!")
  //         setMyTemplates(null)
  //       } else {
  //         openModalMessages('Template Failed to Delete', `${res.message}. Contact support if you feel this is an error.`);
  //       }
  //     })
  //   }
  // })

  // const UpdateTemplatePublicFlag = ((template) => {
  //   if (template){

  //     if (inputSearchCriteria !== template.templateOwnerEmail)
  //     {
  //       openModalMessages("Template Error", "You cannot update a template from another user!")
  //       return
  //     }

  //     template.isPublic = !template.isPublic

  //     Template.update(template).then((res) => {
  //       if (res.message === 'Success')
  //       {
  //         openModalMessages("Template Updated", "Template successfully updated!")
  //         setMyTemplates(null)
  //       } else {
  //         openModalMessages('Template Failed to Update', `${res.message}. Contact support if you feel this is an error.`);
  //       }
  //     })
  //   }
  // })

  // function closeModalDeleteItem() {
  //   setModalDeleteItemOpen(false);
  // }

  // function openModalDeleteItem(title, text, button1, button2, item) {
  //   setModalDeleteItemOpen(true);
  //   setModalDeleteItemTitle(title);
  //   setModalDeleteItemText(text);
  //   setModalDeleteItemButton1Text(button1);
  //   setModalDeleteItemButton2Text(button2);
  //   setRemoveItem(item);
  // }

  // const ConfirmRemoveItem = (item, type) => {
  //   if (item) {
  //     openModalDeleteItem(`Delete ${type}`, "Are you sure?", "Yes", "No", item);
  //   }
  // };

  // const menuItemsDrafts = [{
  //   menuType: 'text',
  //   redirectPath: '',
  //   text: 'Delete Draft',
  //   onClickFunction: (item) => ConfirmRemoveItem(item, 'Draft')
  // }]

  // const menuItemsTemplates = [{
  //   menuType: 'text',
  //   redirectPath: '',
  //   text: 'Delete Template',
  //   onClickFunction: (item) => ConfirmRemoveItem(item, 'Template')
  // },
  // {
  //   menuType: 'text',
  //   redirectPath: '',
  //   text: 'Change Public Status',
  //   onClickFunction: UpdateTemplatePublicFlag
  // }]

  const handleChange = (event, newValue) => {
    clearFiltersClick()
    setTabValue(newValue);
  };

  function closeFiltering() {
    setFilteringOpen(false);
  }

  function applyFilters() {
    setFilteringOpen(false);
  }

  const filterClick = (event) => {
    setFilteringOpen(true);
  }

  const clearFiltersClick = (event) => {
    setFilters([{name:null, displayName:null, operator:null, enumValues:[], value:''}])  
  }

  const renderSearchTab = () => {
    switch (tabValue) {
      case 0: {
        return <TableMyStudies 
                  filters={filters} 
                  myStudies={myStudies} 
                  isDataLoading={isMyStudiesLoading} 
                  userEmail={inputSearchCriteria}
                  refreshData={refreshData}
                  setRefreshData={setRefreshData}/>
      }
      case 1: {
        return <TableAllStudies 
                  filters={filters} 
                  allStudies={allStudies} 
                  isDataLoading={isDataLoading}
                  refreshData={refreshData}
                  setRefreshData={setRefreshData}/>
      }
      case 2: {
        return <TableBatchQueue 
                  filters={filters} 
                  allBatches={allBatches} 
                  isDataLoading={isBatchesLoading}/>
      }
      // case 3: {
      //   return  <UXDataTable 
      //             tableWidth='80%' 
      //             cols={MyDraftCols} 
      //             rows={myDrafts === null ? [] : myDrafts.sort((a, b) => b.draftName - a.draftName)} 
      //             moreOptionsCell={true}
      //             enablePaging={true} 
      //             noDataMessage={`No blend drafts found for ${inputSearchCriteria}`}
      //             menuProps={menuItemsDrafts} 
      //             defaultRowsPerPage={10} 
      //             isDataLoading={draftsIsLoading} 
      //             tableName={'blendDrafts'}
      //             enableSorting={true}>
      //           </UXDataTable>
      // }
      // case 3: {
      //   return  <UXDataTable 
      //             tableWidth='80%' 
      //             cols={MyTemplateCols} 
      //             rows={myTemplates === null ? [] : myTemplates.sort((a, b) => a.templateName - b.templateName)} 
      //             moreOptionsCell={true} 
      //             enablePaging={true} 
      //             noDataMessage={`No blend templates found for ${inputSearchCriteria}`}
      //             menuProps={menuItemsTemplates} 
      //             defaultRowsPerPage={10} 
      //             isDataLoading={templatesIsLoading} 
      //             tableName={'blendTemplates'}
      //             enableSorting={true}>
      //           </UXDataTable>
      // }
      default: {
        alert(tabValue);
      }
    }
  };

  const renderFilterMenu = () => {
    switch (tabValue) {
      case 0: {
        return  <FilterMenu 
                  open={filteringOpen} 
                  setOpen={setFilteringOpen} 
                  applyBtnAction={applyFilters} 
                  cancelButtonAction={closeFiltering} 
                  filteringInfo={filterOptions} 
                  appliedFilters={filters} 
                  setAppliedFilters={setFilters}/>
      }
      case 1: {
        return  <FilterMenu 
                  open={filteringOpen} 
                  setOpen={setFilteringOpen} 
                  applyBtnAction={applyFilters} 
                  cancelButtonAction={closeFiltering} 
                  filteringInfo={filterOptions} 
                  appliedFilters={filters} 
                  setAppliedFilters={setFilters}/>
      }
      case 2: {
        return  <FilterMenu 
                  open={filteringOpen} 
                  setOpen={setFilteringOpen} 
                  applyBtnAction={applyFilters} 
                  cancelButtonAction={closeFiltering} 
                  filteringInfo={batchFilterOptions} 
                  appliedFilters={filters} 
                  setAppliedFilters={setFilters}/>
      }
      // case 3: {
      //   return <></>
      // }
      // case 4: {
      //   return <></>
      // }
      default: {
        alert(tabValue);
      }
    }
  };
  
  return (
       <div>
         <Box sx={{ bgcolor: "#fff", pt:3, pb:1 }} display="flex">
          <GlobalTabCollection style={{marginRight:"1rem"}} scrollButtons="auto" variant="scrollable" value={tabValue} onChange={handleChange} aria-label='ant example'>
            <StyledTab label='My Studies' />
            <StyledTab label='All Studies' />
            <StyledTab label='Batch Queue' /> 
            {/* <StyledTab label='Drafts' />  */}
            {/* <StyledTab label='Templates' />  */}
          </GlobalTabCollection>

            <GlobalButton 
            style={{marginTop:"-.8rem"}}
            variant="contained" 
            component={Link}
            to='/blendstudy'>
              Create Blend Study
          </GlobalButton>

          <Box display="flex" alignItems={"center"} marginLeft="auto" marginTop="-.8rem">
              <GlobalButton disabled={tabValue === 3 || tabValue === 4} style={{marginRight:"1rem"}} variant="contained"
                onClick={() => filterClick()}>Filters</GlobalButton>

              {!(filters[0].name === null) && 
              <GlobalSecondaryButton  variant="contained" 
                onClick={() => clearFiltersClick()}>Clear Filters</GlobalSecondaryButton>}
          </Box>
              
          <Box sx={{ p: 1 }} />
         </Box>
        {renderSearchTab()}
        {renderFilterMenu()}

        {/* <ModalTwoButtons
          title={modalDeleteItemTitle}
          button1Text={modalDeleteItemButton1Text}
          // button1Action={modalDeleteItemTitle?.includes('Draft') ? RemoveDraft : RemoveTemplate}
          button1Action={RemoveTemplate}
          button2Text={modalDeleteItemButton2Text}
          button2Action={closeModalDeleteItem}
          open={modalDeleteItemOpen}
          setOpen={setModalDeleteItemOpen}
        >
        <div style={{ textAlign: "center" }}>
          <label>{modalDeleteItemText}</label>
        </div>
      </ModalTwoButtons> */}

        {/* Informational Messages */}
        {/* <ModalMessages title={modalMessagesTitle} buttonText={modalMessagesButtonText} buttonAction={closeModalMessages} open={modalMessagesOpen} setOpen={setModalMessagesOpen}>
          <label>
              {modalMessagesText}
          </label>     
        </ModalMessages> */}
       </div>
  );
};

export default DesignStudy;
