import React, { useContext } from "react";
import { Box, Divider } from "@mui/material";
import SimpleResultEntry from "./SimpleResultEntry";
import ViewBacklogs from "./ViewBacklogs";
import WorksheetResultEntry from "./WorksheetResultEntry";
import ReviewCorrectResults from "./ReviewCorrectResults";
import { hasRole, Roles } from "../../global";
import UserContext from "../../context/UserContext";
import PageUnauthorized from "../PageUnauthorized";
import { GlobalTabCollection, StyledTab } from "../../pages/styles";

const Analytical = ({ ...props }) => {
  const [tabValue, setTabValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const currentUser = useContext(UserContext)
  const roles = currentUser?.idTokenClaims.roles
  const developer = hasRole(Roles.Developer, roles);
  const analyst = hasRole(Roles.Analyst, roles);
  const testReviewer = hasRole(Roles.TestReviewer, roles);

  const renderSearchTab = () => {
    switch (tabValue) {
      case 0: {
        return <ViewBacklogs></ViewBacklogs>
      }
      case 1: {
        if (!developer && !testReviewer && tabValue !== 0)
          return <PageUnauthorized/>

        return <ReviewCorrectResults></ReviewCorrectResults>;
      }
      case 2: {
        if (!developer && !analyst && tabValue !== 0)
          return <PageUnauthorized/>

        return <SimpleResultEntry></SimpleResultEntry>;
      }
      case 3: {
        if (!developer && !analyst && tabValue !== 0)
          return <PageUnauthorized/>
        return <WorksheetResultEntry></WorksheetResultEntry>;
      }
      default: {
        alert(tabValue);
      }
    }
  };

  return (
    <>
      <span className='pageheader'>Analytical</span>
      <Divider className='dividerbar' />
      <div>
        <Box sx={{ bgcolor: "#fff", pt:3, pb:1 }}>
          <GlobalTabCollection value={tabValue} onChange={handleChange} aria-label='ant example'>
            <StyledTab label='View Backlogs' />
            <StyledTab label='Review and Correct Results' />
            <StyledTab label='Simple Result Entry' />
            <StyledTab label='Worksheet Result Entry' />
          </GlobalTabCollection>
          <Box sx={{ p: 3 }} />
        </Box>
        {renderSearchTab()}
      </div>
    </>
  );
};

export default Analytical;
