import React, { useState, useEffect, useRef } from "react";
import {Box,IconButton, Typography, TextField, Autocomplete} from '@mui/material'
import { Delete, Edit } from "@mui/icons-material";
import { StyledTableBody, StyledTableRow, UXDataTableWithoutBody } from '../../components/UXDataTable'
import MethodSelection from '../../components/MethodSelection'
import { ChemIDSearchField } from '../../components/ChemIDSearch/index'
import ModalTwoButtons from '../../components/Modal/ModalTwoButtons'
import ModalMessages from '../../components/Modal/ModalSimpleButton'
import {StyledTableCellLarge, StyledTableCellMedium, StyledTableCellSmall, StyledErrorMessage, StyledForm} from '../../pages/Distillations/styles'
import { convertCelsiusToFahrenheit, convertFahrenheitToCelsius, generateUniqueID, ConvertUOMs, RoundNumber, OrderTemplateCutList, GetRequiredSampleSize, GetRequiredSampleSizeUoM } from '../../global'
import DistillationCutType from '../../api/Distillation/DistillationCutType'
import DistillationTemplateCut from '../../api/Distillation/DistillationTemplateCut'
import LocationApi from "../../api/Admin/Location";
import Substance from '../../api/LIMS/Substance'
import UnitOfMeasure from "../../api/Admin/UnitOfMeasure";


const templateCutsCols = ['Cut Type', 'Chem ID', 'Start Temp C/F', 'End Temp C/F', 'Keep Refrigerated', 'Return Location']
const splitCols = ['Split', 'Container Size', 'Container UoM']

const TemplateTables = ({
    showMethodSearchTemplateName,
    isReadOnly = false,
    templateName,
    methods,
    setMethods,
    templateCuts,
    setTemplateCuts,
    selectedTemplateCut,
    setSelectedTemplateCut,
    selectedChemID,
    setSelectedChemID,
    distillationType
}) => {
    const [containerGroups, setContainerGroups] = useState([])
    const [cutTypesList, setCutTypesList] = useState([])
    const [cutTypeValue, setCutTypeValue] = useState('')
    const [locationList, setLocationList] = useState([])
    const [returnLocationValue, setReturnLocationValue] = useState('DISCARD')
    const [hasChemIDErrors, setHasChemIDErrors] = useState(false)
    const [startTemperature, setStartTemperature] = useState({
        fahrenheit: '',
        celsius: '',
    })
    const [endTemperature, setEndTemperature] = useState({
        fahrenheit: '',
        celsius: '',
    })
    const [isTemplateCutModalOpen, setIsTemplateCutModalOpen] = useState(false)
    const [isAdding, setIsAdding] = useState(false)
    const [availableUOMs, setAvailableUOMs] = useState([])

    const [modalDeleteItemOpen, setModalDeleteItemOpen] = useState(false);
    const [modalDeleteItemButton1Text, setModalDeleteItemButton1Text] = useState('');
    const [modalDeleteItemButton2Text, setModalDeleteItemButton2Text] = useState('');
    const [modalDeleteItemTitle, setModalDeleteItemTitle] = useState('');
    const [modalDeleteItemText, setModalDeleteItemText] = useState('');
    
    const [modalMessagesOpen, setModalMessagesOpen] = useState(false);
    const modalMessagesButtonText = 'Ok'
    const [modalMessagesTitle, setModalMessagesTitle] = useState('');
    const [modalMessagesText, setModalMessagesText] = useState('');

    const [selectedCut, setSelectedCut] = useState(null);

    const templateFormRef = useRef(null)

    function closeModalMessages() {
        setModalMessagesOpen(false);
    }

    function openModalMessages(title, text) {
        setModalMessagesOpen(true);
        setModalMessagesTitle(title);
        setModalMessagesText(text);
    }

    const filteredCutTypesList = cutTypesList.filter(cutType => {
        if (distillationType === 'Extractions') {
            return (
                cutType?.isExtraction
            )
        }
        else if (distillationType === 'Atmospheric') {
            return (
                cutType?.isAtmospheric
            )
        }
        else if (distillationType === 'Vacuum') {
            return (
                cutType?.isHighVacuum
            )
        } else {
            return cutType
        }
    })

    const isCutNameAStartingCut = (cutTypeValue) => {
        const index = cutTypesList.findIndex(x => x.distillationCutTypeName === cutTypeValue);
        return index !== -1 ? cutTypesList[index].isStartingCut : false;
    }

    const getPreviousAndNextCut = () => {
        let previous = null
        let next = null

        if (!isAdding) {
            const selectedCutIndex = templateCuts?.findIndex(cut => cut?.id === selectedTemplateCut?.id)

            if (selectedCutIndex > 0) {
                if (templateCuts[selectedCutIndex - 1]?.distillationCutTypeName?.toLowerCase()?.includes('cut')) {
                    previous = templateCuts[selectedCutIndex - 1]
                }

                if (templateCuts[selectedCutIndex + 1]?.distillationCutTypeName?.toLowerCase()?.includes('cut')) {
                    next = templateCuts[selectedCutIndex + 1]
                }
            }

            if (selectedCutIndex === 0) {
                if (templateCuts[selectedCutIndex + 1]?.distillationCutTypeName?.toLowerCase()?.includes('cut')) {
                    next = templateCuts[selectedCutIndex + 1]
                }
            }
        } else {
            const filteredCuts = templateCuts.filter(cut => {
                return cut?.distillationCutTypeName === cutTypeValue && cutTypeValue?.toLowerCase()?.includes('cut')
            })

            if (filteredCuts.length > 0) {
                previous = filteredCuts[filteredCuts.length - 1]
            }
        }

        return [previous, next]
    }

    const [previousCut, nextCut] = getPreviousAndNextCut()

    const isStartingTemperatureHigherThanEnd =
        startTemperature.celsius !== '' &&
        endTemperature.celsius !== '' &&
        endTemperature.celsius !== '+' &&
        !isCutNameAStartingCut(cutTypeValue) &&
        +startTemperature.celsius > +endTemperature.celsius

    const isThereStartingGap =
        previousCut &&
        startTemperature.celsius !== '' &&
        startTemperature.celsius !== '+' &&
        !isCutNameAStartingCut(cutTypeValue) &&
        previousCut?.endTemperatureC !== startTemperature.celsius

    const isThereEndGap =
        nextCut &&
        endTemperature.celsius !== '' &&
        endTemperature.celsius !== '+' &&
        !isCutNameAStartingCut(cutTypeValue) &&
        nextCut?.startingTemperatureC !== endTemperature.celsius

    const isStartingCutUnique = (templateCuts, cutTypeValue) => {
        if (templateCuts.findIndex(x => x.distillationCutTypeName === cutTypeValue) !== -1 &&
            filteredCutTypesList.find(x => x.distillationCutTypeName === cutTypeValue)?.isStartingCut) {
            return false;
        }
        else {
            return true;
        }
    }

    const isEndingCutUnique = (templateCuts, cutTypeValue) => {
        if (templateCuts.findIndex(x => x.distillationCutTypeName === cutTypeValue) !== -1 &&
            filteredCutTypesList.find(x => x.distillationCutTypeName === cutTypeValue)?.isEndingCut) {
            return false;
        }
        else {
            return true;
        }
    }

    const getSubstanceByChemID = async (templateCut) => {
        const searchResult = await Substance.getByChemID(templateCut?.chemID, false, false)
        const foundSubstance = searchResult && searchResult.message === 'Success' ? searchResult.result : null
        setSelectedChemID(foundSubstance)
    }

    const handleTemplateCutDeleteIcon = (templateCut) => {
        openModalDeleteItem("Confirm Cut Deletion", "Are you sure you want to delete this template cut?  This action cannot be reversed.", "Yes", "No")
        setSelectedCut(templateCut)
        // const newTemplateCutList = templateCuts.filter(item => item?.id !== templateCut?.id)
        // handleTemplateCutsList(newTemplateCutList)
    }

    const handleTemplateCutsList = (cutList) => {
        const listArray = OrderTemplateCutList(cutList, filteredCutTypesList)
        setTemplateCuts(listArray)
    }

    const handleTemplateCutEditIcon = (templateCut) => {
        getSubstanceByChemID(templateCut)
        setIsAdding(false)
        setSelectedTemplateCut(templateCut)
        setStartTemperature({
            fahrenheit: convertCelsiusToFahrenheit(templateCut?.startingTemperatureC),
            celsius: templateCut?.startingTemperatureC,
        })
        setEndTemperature({
            fahrenheit: convertCelsiusToFahrenheit(templateCut?.endTemperatureC),
            celsius: templateCut?.endTemperatureC,
        })
        setCutTypeValue(templateCut?.distillationCutTypeName)
        setReturnLocationValue(templateCut?.testingReturnLocationName ?? 'DISCARD')
        setIsTemplateCutModalOpen(true)
    }

    const generateContainerGroupList = () => {
        const obj = []

        methods.forEach((method) => {
            if (obj[method?.containerGrouping]) {
                obj[method?.containerGrouping] = [...obj[method?.containerGrouping], method]
            } else if (method?.containerGrouping !== '' && method?.containerGrouping !== null) {
                obj[method?.containerGrouping] = [method]
            } else {
                return
            }
        })

        const arrayOfContainerGroups = Object.entries(obj).reduce((acc, cur) => {
            const [key, value] = cur

            const masterUoM = availableUOMs.find(uom => uom.uoMName === GetRequiredSampleSizeUoM(value[0]))

            const containerSizeValue = value.reduce((valueAcc, valueCur) => {
                const currentUoM = availableUOMs.find(uom => uom.uoMName === GetRequiredSampleSizeUoM(valueCur))

                valueAcc += ConvertUOMs(null, null, GetRequiredSampleSize(valueCur), currentUoM, masterUoM)
                return RoundNumber(valueAcc, 2)
            }, 0)

            const obj = {
                id: generateUniqueID(),
                split: key,
                containerSize: containerSizeValue,
                containerUoMName: GetRequiredSampleSizeUoM(value[0])
            }
            acc.push(obj)
            return acc
        }, [])

        return arrayOfContainerGroups
    }

    function closeModalDeleteItem() {
        setModalDeleteItemOpen(false);
    }
    
    function openModalDeleteItem(title, text, buttonText, button2Text) {
        setModalDeleteItemButton1Text(buttonText)
        setModalDeleteItemButton2Text(button2Text)
        setModalDeleteItemOpen(true);
        setModalDeleteItemTitle(title);
        setModalDeleteItemText(text);
    }

    function deleteItem ()
    {
        closeModalDeleteItem()

        DistillationTemplateCut.deleteTemplateCut(selectedCut.id).then((res) => {
            if (res && res.message === 'Success') {
                const newTemplateCutList = templateCuts.filter(item => item?.id !== selectedCut.id)
                handleTemplateCutsList(newTemplateCutList)

                openModalMessages("Template Cut Deleted", `Template cut successfully deleted.`) 
            } else {
                openModalMessages("Error Deleting Template Cut", `${res ? res.message : 'Error removing template cut'}.  Please try again or contact support.`)
                return
            }

            setSelectedCut(null)
        })
        // const newTemplateCutList = templateCuts.filter(item => item?.id !== templateCut?.id)
        // handleTemplateCutsList(newTemplateCutList)
    }

    function cancelDeleteItem ()
    {
        setSelectedCut(null)
        closeModalDeleteItem()
    }

    useEffect(() => {
        if (selectedTemplateCut && !!(templateCuts.find(cut => cut.id === selectedTemplateCut.id))) {
            if (selectedTemplateCut?.testingTemplateJSON) {
                const parsedValue = JSON.parse(selectedTemplateCut.testingTemplateJSON)
                setMethods(parsedValue.methods ? parsedValue.methods : parsedValue)
                //setMethods(parsedValue.methods)
                setContainerGroups(parsedValue.containerGroups)
            } else {
                setMethods([])
                setContainerGroups([])
            }
        } else {
            setMethods([])
            setContainerGroups([])
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedTemplateCut])

    useEffect(() => {
        if (methods?.length) {
            const generatedContainerList = generateContainerGroupList()
            const newList = templateCuts.map(cut => {
                if (cut?.id === selectedTemplateCut?.id) {
                    let updatedCut = {
                        ...selectedTemplateCut,
                        testingTemplateJSON: JSON.stringify({ methods: methods, containerGroups: generatedContainerList })
                    }
                    return updatedCut
                }
                return cut
            })
            handleTemplateCutsList(newList)
            setContainerGroups(generatedContainerList)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [methods])

    useEffect(() => {
        if (cutTypeValue) {
            setCutTypeValue('')
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [distillationType])

    useEffect(() => {
        LocationApi.getAll().then((res) =>  {
            setLocationList(res.filter(obj => obj.isActive === true));
        })

        UnitOfMeasure.getAll().then((res) => {
            setAvailableUOMs(res.filter(result => (result.metricStandardConversion !== null || (result.type === 'weight' || result.type === 'volume')) && result.isActive === true).sort((a, b) => a.uoMName.localeCompare(b.uoMName)))
        });

        DistillationCutType.getAllCutTypes().then((res) => {
            setCutTypesList(res.filter(cutType => cutType.isActive === true))            
        })
    }, [])

    return (
        <>
            <Box display="flex" flexDirection="column">
                <UXDataTableWithoutBody
                    tableWidth='100%'
                    cols={templateCutsCols}
                    blankFirstHeader
                    tableId="templateCutsTable"
                    rowLength={templateCuts?.length}
                    noDataFoundMessage={'No template cuts found'}
                    enableAddIcon={!isReadOnly}
                    addToolTipText="Add new Template Cut"
                    addFunction={() => {
                        setIsTemplateCutModalOpen(true)
                        setIsAdding(true)
                    }}
                >
                    <StyledTableBody>
                        {templateCuts && templateCuts.length && templateCuts.map((templateCut) => {
                            return templateCut.distillationCutTypeName && (
                                <StyledTableRow
                                    key={templateCut.id}
                                    hover
                                    style={{ border: selectedTemplateCut?.id === templateCut?.id && '3px solid #1976D2', cursor: 'pointer' }}
                                    onClick={() => {
                                        if (selectedTemplateCut && selectedTemplateCut.id === templateCut?.id) {
                                            setSelectedTemplateCut(null)
                                        } else {
                                            setSelectedTemplateCut(templateCut)
                                        }
                                    }}
                                >
                                    <StyledTableCellSmall>
                                        <Box display="flex" alignItems="center" justifyContent="center" gap={1}>
                                            <IconButton
                                                disabled={isReadOnly}
                                                color="error"
                                                style={{ padding: 0 }}
                                            >
                                                <Delete
                                                    fontSize="small"
                                                    color="inherit"
                                                    onClick={() => handleTemplateCutDeleteIcon(templateCut)}
                                                />
                                            </IconButton>
                                            <IconButton
                                                disabled={isReadOnly}
                                                color="info"
                                                style={{ padding: 0 }}
                                            >
                                                <Edit
                                                    fontSize="small"
                                                    color="inherit"
                                                    onClick={() => handleTemplateCutEditIcon(templateCut)}
                                                />
                                            </IconButton>
                                        </Box>
                                    </StyledTableCellSmall>
                                    <StyledTableCellMedium>
                                        {templateCut?.distillationCutTypeName}
                                    </StyledTableCellMedium>
                                    <StyledTableCellMedium>
                                        {templateCut?.isStartingCut ? ''
                                            : templateCut?.chemID}
                                    </StyledTableCellMedium>
                                    <StyledTableCellSmall>
                                        {templateCut?.isStartingCut ? ''
                                            : <>{templateCut?.startingTemperatureC} / {convertCelsiusToFahrenheit(templateCut?.startingTemperatureC)}</>}
                                    </StyledTableCellSmall>
                                    <StyledTableCellSmall>
                                        {templateCut?.isStartingCut ? ''
                                            : <>{templateCut?.endTemperatureC} / {convertCelsiusToFahrenheit(templateCut?.endTemperatureC)}</>}
                                    </StyledTableCellSmall>
                                    <StyledTableCellSmall>
                                        {templateCut?.isStartingCut ? ''
                                            : templateCut?.requiresRefridgeration ? 'Yes' : 'No'}
                                    </StyledTableCellSmall>
                                    <StyledTableCellLarge>
                                        {templateCut?.isStartingCut ? ''
                                            : templateCut?.testingReturnLocationName}
                                    </StyledTableCellLarge>
                                </StyledTableRow>
                            )
                        })}
                    </StyledTableBody>
                </UXDataTableWithoutBody>
            </Box>
            <Box marginTop={4}>
                <MethodSelection
                    width={'100%'}
                    selectedMethods={methods ? methods : []}
                    setSelectedMethods={setMethods}
                    showCompletionDate={false}
                    showContainerGrouping
                    incomingTemplate={null}
                    isReadOnly={isReadOnly}
                    showSearchTemplateName={showMethodSearchTemplateName}
                    showEstimateAndPriority={true}
                    maxMethodsAllowed={999}
                />
            </Box>
            <Box width="30%" marginTop={4}>
                <UXDataTableWithoutBody
                    tableWidth='100%'
                    cols={splitCols}
                    tableId="containerTable"
                    rowLength={containerGroups?.length}
                    noDataFoundMessage={'No containers found'}
                >
                    <StyledTableBody>
                        {methods?.length > 0 && containerGroups && containerGroups?.length && containerGroups?.map(container => {
                            return (
                                <StyledTableRow key={container?.id}>
                                    <StyledTableCellSmall>
                                        {container?.split}
                                    </StyledTableCellSmall>
                                    <StyledTableCellSmall>
                                        {container?.containerSize}
                                    </StyledTableCellSmall>
                                    <StyledTableCellSmall>
                                        {container?.containerUoMName}
                                    </StyledTableCellSmall>
                                </StyledTableRow>
                            )
                        })}
                    </StyledTableBody>
                </UXDataTableWithoutBody>
            </Box>
            {isTemplateCutModalOpen && (
                <ModalTwoButtons
                    title="Template Cut"
                    button1Text="Add/Update"
                    button1Action={() => {
                        if (isAdding) {
                            let newTemplateCut = {
                                id: `test-${generateUniqueID()}`,
                                chemID: selectedChemID?.chemID,
                                distillationCutTypeName: cutTypeValue,
                                requiresRefridgeration: false, //remove after DB change
                                testingReturnLocationName: returnLocationValue === '' ? null : returnLocationValue,
                                startingTemperatureC: startTemperature.celsius,
                                endTemperatureC: endTemperature.celsius,
                                testingTemplateJSON: null,
                                distillationTemplateName: templateName
                            }
                            const newList = [...templateCuts, newTemplateCut]
                            handleTemplateCutsList(newList)
                        } else {
                            const newList = templateCuts.map(cut => {
                                if (cut?.id === selectedTemplateCut?.id) {
                                    let updatedCut = {
                                        ...selectedTemplateCut,
                                        chemID: selectedChemID?.chemID ?? selectedTemplateCut?.chemID,
                                        distillationCutTypeName: cutTypeValue,
                                        requiresRefridgeration: false, //remove after DB change
                                        testingReturnLocationName: returnLocationValue === '' ? null : returnLocationValue,
                                        startingTemperatureC: startTemperature.celsius,
                                        endTemperatureC: endTemperature.celsius,
                                    }
                                    return updatedCut
                                }
                                return cut
                            })
                            handleTemplateCutsList(newList)
                            setSelectedTemplateCut(null)
                        }
                        setIsTemplateCutModalOpen(false)
                        setSelectedChemID(null)
                        setStartTemperature({
                            fahrenheit: '',
                            celsius: '',
                        })
                        setEndTemperature({
                            fahrenheit: '',
                            celsius: '',
                        })
                        setCutTypeValue('')
                        setReturnLocationValue('DISCARD')
                    }}
                    isButton1Disabled={
                        (!isCutNameAStartingCut(cutTypeValue) &&
                            (!selectedChemID ||
                                cutTypeValue === '' ||
                                returnLocationValue === '' ||
                                startTemperature.celsius === '' ||
                                endTemperature.celsius === '' ||
                                isStartingTemperatureHigherThanEnd ||
                                isThereStartingGap ||
                                isThereEndGap)) ||
                        !isStartingCutUnique(templateCuts, cutTypeValue) ||
                        (!isEndingCutUnique(templateCuts, cutTypeValue) && selectedTemplateCut === null)

                    }
                    button2Text="Cancel"
                    button2Action={() => {
                        setIsTemplateCutModalOpen(false)
                        setIsAdding(false)
                        setSelectedChemID(null)
                        setSelectedTemplateCut(null)
                        setStartTemperature({
                            fahrenheit: '',
                            celsius: '',
                        })
                        setEndTemperature({
                            fahrenheit: '',
                            celsius: '',
                        })
                        setCutTypeValue('')
                        setReturnLocationValue('DISCARD')
                    }}
                    setOpen={setIsTemplateCutModalOpen}
                    open={isTemplateCutModalOpen}
                >
                    <StyledForm ref={templateFormRef}>
                    <div style={{maringTop:"1rem"}}>
                        <Autocomplete
                            noOptionsText={"Loading Cut Types..."}
                            value={cutTypeValue}
                            onChange={(_, value) => {
                                if (value) {
                                    setCutTypeValue(value)
                                } else {
                                    setCutTypeValue('')
                                }
                            }}
                            disablePortal
                            options={filteredCutTypesList.map(cutType => cutType?.distillationCutTypeName)}
                            isOptionEqualToValue={(option, value) => option === value}
                            renderInput={(params) => <TextField {...params} label="Cut Type *" size="small" margin="normal"/>}
                            autoSelect
                            fullWidth
                        />
                        <Box display="flex" flexDirection="column" width="100%">
                            <ChemIDSearchField
                                fontSize={14}
                                fieldWidth="100%"
                                selectedChemIDObject={selectedChemID}
                                setSelectedChemIDObject={(e) => setSelectedChemID(e)}
                                hasErrors={hasChemIDErrors}
                                setHasErrors={setHasChemIDErrors}
                                allowInactiveChemIDs={false}
                                returnSubstanceStrucureData={false}
                                margin="normal"
                            />
                        </Box>
                        <Autocomplete
                            noOptionsText={"Loading Return Locations..."}
                            value={returnLocationValue}
                            onChange={(_, value) => {
                                if (value) {
                                    setReturnLocationValue(value)
                                } else {
                                    setReturnLocationValue('')
                                }
                            }}
                            disablePortal
                            options={locationList.map((location) => location?.locationName)}
                            isOptionEqualToValue={(option, value) => value === option}
                            renderInput={(params) => <TextField {...params} label="Return Location *" size="small" margin="normal" />}
                            autoSelect
                            fullWidth
                        />
                        <Box display="flex" flexDirection="column" gap={2}>
                            <Typography>Start Temperature *</Typography>
                            {previousCut && <Typography>Previous cut End temp C/F: {previousCut?.endTemperatureC} / {convertCelsiusToFahrenheit(previousCut?.endTemperatureC)}</Typography>}
                            <Box display="flex" alignItems="center" justifyContent="center" gap={1}>
                                <TextField
                                    size="small"
                                    type="text"
                                    name="startTempC"
                                    label="Celsius"
                                    value={startTemperature.celsius}
                                    onChange={(e) => setStartTemperature({ fahrenheit: convertCelsiusToFahrenheit(e.target.value), celsius: e.target.value })}
                                    inputProps={{
                                        maxLength: 5,
                                    }}
                                />
                                <Typography>=</Typography>
                                <TextField
                                    size="small"
                                    type="text"
                                    name="startTempF"
                                    label="Fahrenheit"
                                    value={startTemperature.fahrenheit}
                                    onChange={(e) => setStartTemperature({ fahrenheit: e.target.value, celsius: convertFahrenheitToCelsius(e.target.value) })}
                                    inputProps={{
                                        maxLength: 5,
                                    }}
                                />
                            </Box>
                            {isStartingTemperatureHigherThanEnd && <StyledErrorMessage>Starting temperature cannot be higher than End temperature.</StyledErrorMessage>}
                            {isThereStartingGap && <StyledErrorMessage>Starting temperature cannot be lower or higher than previous cut's End temperature.</StyledErrorMessage>}
                        </Box>
                        <Box display="flex" flexDirection="column" gap={2}>
                            <Typography>End Temperature *</Typography>
                            {nextCut && <Typography>Next cut Starting temp C/F: {nextCut?.startingTemperatureC} / {convertCelsiusToFahrenheit(nextCut?.startingTemperatureC)}</Typography>}
                            <Box display="flex" alignItems="center" justifyContent="center" gap={1}>
                                <TextField
                                    size="small"
                                    type="text"
                                    name="endTempC"
                                    label="Celsius"
                                    value={endTemperature.celsius}
                                    onChange={(e) => setEndTemperature({ fahrenheit: convertCelsiusToFahrenheit(e.target.value), celsius: e.target.value })}
                                    inputProps={{
                                        maxLength: 5,
                                    }}
                                />
                                <Typography>=</Typography>
                                <TextField
                                    size="small"
                                    type="text"
                                    name="endTempF"
                                    label="Fahrenheit"
                                    value={endTemperature.fahrenheit}
                                    onChange={(e) => setEndTemperature({ fahrenheit: e.target.value, celsius: convertFahrenheitToCelsius(e.target.value) })}
                                    inputProps={{
                                        maxLength: 5,
                                    }}
                                />
                            </Box>
                            {isStartingTemperatureHigherThanEnd && <StyledErrorMessage>End temperature cannot be lower than Starting temperature.</StyledErrorMessage>}
                            {isThereEndGap && <StyledErrorMessage>End temperature cannot be lower or higher than next cut's Starting temperature.</StyledErrorMessage>}
                        </Box>
                        <Box display="flex" flexDirection="column" gap={2}>
                            {!isStartingCutUnique(templateCuts, cutTypeValue) && selectedTemplateCut === null ?
                                <StyledErrorMessage>A Starting Cut is already added and there can only be 1.</StyledErrorMessage> :
                                ''
                            }
                            {!isEndingCutUnique(templateCuts, cutTypeValue) && selectedTemplateCut === null ?
                                <StyledErrorMessage>An Ending Cut is already added and there can only be 1.</StyledErrorMessage> :
                                ''
                            }
                        </Box>
                    </div>
                    </StyledForm>

                </ModalTwoButtons>
            )}

            
                <ModalTwoButtons title={modalDeleteItemTitle} button1Text={modalDeleteItemButton1Text} button1Action={deleteItem} button2Text={modalDeleteItemButton2Text} button2Action={cancelDeleteItem} open={modalDeleteItemOpen} setOpen={setModalDeleteItemOpen}>
                    <label>
                        {modalDeleteItemText}
                    </label>

                    <div></div>
                </ModalTwoButtons>

                
            {/* Informational Messages */}
            <ModalMessages title={modalMessagesTitle} buttonText={modalMessagesButtonText} buttonAction={closeModalMessages} open={modalMessagesOpen} setOpen={setModalMessagesOpen}>
                <label>
                    {modalMessagesText}
                </label>
            </ModalMessages>
        </>
    )
}

export default TemplateTables