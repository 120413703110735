import API from "..";
export default class PrintLabel {
  
  constructor({
    sampleName,
    containerNumbers,
    includeAdditionalSampleInformation, 
    chemIDOnly,    
    printLabelSizeType,
    isBlindCoded,
    blindDescription, 
    blindSampleName, 
    isShelfLabel, 
    shelfLabelText, 
    includeShelfBarcode

  }) {
    this.sampleName = sampleName;
    this.containerNumbers = containerNumbers;
    this.includeAdditionalSampleInformation = includeAdditionalSampleInformation;
    this.chemIDOnly = chemIDOnly;
    this.printLabelSizeType = printLabelSizeType;
    this.isBlindCoded = isBlindCoded;
    this.blindDescription = blindDescription;
    this.blindSampleName = blindSampleName;
    this.isShelfLabel = isShelfLabel;
    this.shelfLabelText = shelfLabelText;
    this.includeShelfBarcode = includeShelfBarcode
  }

  static async getZPL(printLabelDetails) {
    const api = await API();
    const { data } = await api.post(`/printlabel`, printLabelDetails);
    return data;
  }

}