import React from "react";
import { Divider } from "@mui/material";

const SMGCart = ({ ...props }) => {
  return (
    <div>
      <span className='pageheader'>SMG Postman - Site Logistics</span>
      <Divider className='dividerbar' />
      <p>
        <h1>This page is currently under construction</h1>
        This will be the MIDAS 3 implementation of the SMG Postman application (Cart App)
      </p>
    </div>
  );
};

export default SMGCart;