import React from "react";
import { Box } from "@mui/material";
import ResultComponents from "./ResultComponents";
import InstrumentManagement from "./InstrumentManagement";
import MethodManagement from "./MethodManagement";
import AnalyticalMasterData from "./AnalyticalMasterData";
import { PackageTestManagement } from "./PackageTest/PackageTestManagement";
import { GlobalTabCollection, StyledTab } from "../../pages/styles";


const AnalyticalAdminMenu = ({ ...props }) => {
  const [tabValue, setTabValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const renderSearchTab = () => {
    switch (tabValue) {
      case 0: {
        return <MethodManagement></MethodManagement>
      }
      case 1: {
        return <PackageTestManagement />
      }
      case 2: {
        return <ResultComponents></ResultComponents>;
      }
      case 3: {
        return <InstrumentManagement></InstrumentManagement>;
      }
      case 4: {
        return <AnalyticalMasterData></AnalyticalMasterData>
      }
      default: {
        alert(tabValue);
      }
    }
  };

  return (
    <>
      <div>
        <Box sx={{ bgcolor: "#fff"}}>
          <GlobalTabCollection value={tabValue} onChange={handleChange} aria-label='ant example'>
            <StyledTab label='Method Management' />
            <StyledTab label='Package Test Management' />
            <StyledTab label='Result Components' />
            <StyledTab label='Instrument Management' />
            <StyledTab label='Analytical Master Data' />
          </GlobalTabCollection>
          <Box sx={{ p: 3 }} />
        </Box>
        {renderSearchTab()}
      </div>
    </>
  );
};

export default AnalyticalAdminMenu;
