import API from '../index'

export default class STValidationStatus {
    constructor({
        statusName,
        isActive,
        displayOrder,
        displayColor
    }) {
        this.statusName = statusName
        this.isActive = isActive
        this.displayOrder = displayOrder
        this.displayColor = displayColor
    }

    static async getAll() {
        const api = await API();
        const { data } = await api.get('/stvalidationstatus');
        return data.result.map((status) => new STValidationStatus(status));
    }

    static async get(name) {
        const api = await API();
        const { data } = await api.get(`stvalidationstatus/${name}`);
        if (data.result) {
            return new STValidationStatus(data.result);
        }
        else {
            return null;
        }
    }

    static async add(payload) {
        const api = await API();
        const { data } = await api.post('/stvalidationstatus', payload);
        return new STValidationStatus(data.result);
    }

    static async update(payload) {
        const api = await API();
        const { data } = await api.put('/stvalidationstatus', payload);
        return new STValidationStatus(data.result);
    }
}