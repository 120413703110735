import { useState, useEffect, useContext } from "react";
import Box from "@mui/material/Box";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Typography from "@mui/material/Typography";
import { StyledButton } from "./styles";
import ModalSimpleButton from "../../components/Modal/ModalSimpleButton";
import UserContext from "../../context/UserContext";
import { Roles, hasRole } from "../../global";

import STStandApi from "../../api/StationaryTesting/STStand";
import STStandTypeApi from "../../api/StationaryTesting/STStandType";

const TestStandManagement = () => {
  const [showTestStandInfo, setShowTestStandInfo] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isMessageModalOpen, setIsMessageModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState({ title: "", message: "" });
  const [isTestStandCreating, setIsTestStandCreating] = useState(false);
  const [isTestStandUpdating, setIsTestStandUpdating] = useState(false);
  const [testStandInfo, setTestStandInfo] = useState<any>({});
  const [standTypes, setStandTypes] = useState([]);
  const [testStands, setTestStands] = useState([]);
  const [testStandName, setTestStandName] = useState("");

  const currentUser = useContext(UserContext);
  // @ts-ignore
  const roles = currentUser?.idTokenClaims?.roles;

  const canManageTestStand =
    hasRole(Roles.Developer, roles) ||
    hasRole(Roles.STStandAdministrator, roles);

  async function getAllTestStands() {
    const response = await STStandApi.getAll();
    const formattedData = response.map((type: any) => type?.StandName);
    setTestStands(formattedData);
  }

  async function getAllTestStandTypes() {
    const response = await STStandTypeApi.getAllActives();
    const formattedData = response.map((type: any) => type?.standTypeName);
    setStandTypes(formattedData);
  }

  async function createTestStand() {
    setIsTestStandCreating(true);

    const payload = {
      // @ts-ignore
      standName: testStandInfo?.standName,
      createdByEmail: null,
      createdDate: new Date(),
      lastModifiedByEmail: null,
      lastModifiedDate: null,
      // @ts-ignore
      isActive: testStandInfo?.isActive,
      // @ts-ignore
      standTypeName: testStandInfo?.standTypeName,
      thisStandType: null,
      // @ts-ignore
      etqid: testStandInfo?.etqid,
    };

    const newTestStand = await STStandApi.createTestStand(payload);

    if (newTestStand.message) {
      setIsMessageModalOpen(true);
      setModalContent({
        title: "Error!",
        message: newTestStand.message,
      });
    } else {
      setShowTestStandInfo(false);
      setTestStandInfo({});
      setIsMessageModalOpen(true);
      setModalContent({ title: "Success!", message: "Test Stand created successfully!" });
      getAllTestStands();
    }
    
    setIsTestStandCreating(false);
  }

  async function updateTestStand() {
    setIsTestStandUpdating(true);

    const updatedTestStand = await STStandApi.updateTestStand(testStandInfo);

    if (updatedTestStand.message) {
      setIsMessageModalOpen(true);
      setModalContent({
      title: "Error!",
      message: updatedTestStand.message,
      });
    } else {
      setShowTestStandInfo(false);
      setTestStandInfo({});
      setTestStandName("");
      setIsMessageModalOpen(true);
      setModalContent({ title: "Success!", message: "Test Stand updated successfully!" });
      getAllTestStands();
    }
    setIsTestStandUpdating(false);
  }

  useEffect(() => {
    getAllTestStands();
    getAllTestStandTypes();
  }, []);

  return (
    <>
      <Box display="flex" flexDirection="column" gap={2}>
        <Box display="flex" flexDirection="column" gap={3}>
          <Box display="flex" gap={2}>
            <Autocomplete
              noOptionsText="Loading Test Stands..."
              value={testStandName}
              onChange={(e, value) => {
                if (value) {
                  const foundTestStand = testStands.find(
                    (stand: any) => stand?.standName === value
                  );
                  if (foundTestStand) {
                    setTestStandInfo(foundTestStand);
                    setTestStandName(value);
                    setIsEditing(true);
                    setShowTestStandInfo(true);
                  }
                } else {
                  setTestStandInfo({});
                  setTestStandName("");
                  setIsEditing(false);
                  setShowTestStandInfo(false);
                }
              }}
              disablePortal
              options={testStands.map((stand: any) => stand?.standName)}
              sx={{ width: "35rem" }}
              renderInput={(params) => <TextField {...params} label="Select Test Stand" />}
              autoSelect
              size="small"
            />
            <StyledButton
              variant="contained"
              type="button"
              onClick={() => {
                setShowTestStandInfo(true);
                setIsEditing(false);
                setTestStandName("");
              }}
              disabled={showTestStandInfo || !canManageTestStand}
            >
              Add New Test Stand
            </StyledButton>
          </Box>
          {showTestStandInfo ? (
            <Box display="flex" flexDirection="column" gap={2}>
              <TextField
                onChange={(e) => {
                  setTestStandInfo(() => ({
                    ...testStandInfo,
                    standName: e.target.value,
                  }));
                }}
                value={testStandInfo?.standName ? testStandInfo.standName : ""}
                disabled={isEditing || !canManageTestStand}
                label="Test Stand Name"
                size="small"
                style={{ width: "20%" }}
              />
              <Box display="flex" alignItems="center" gap={2}>
                <Autocomplete
                  noOptionsText="Loading Stand Types..."
                  value={testStandInfo?.standTypeName}
                  onChange={(_, value) => {
                    if (value) {
                      setTestStandInfo(() => ({
                        ...testStandInfo,
                        standTypeName: value,
                      }));
                    } else {
                      setTestStandInfo(() => ({
                        ...testStandInfo,
                        standTypeName: "",
                      }));
                    }
                  }}
                  disabled={!canManageTestStand}
                  isOptionEqualToValue={(option, value) => value === option}
                  disablePortal
                  options={standTypes}
                  renderInput={(params) => (
                    <TextField {...params} label="Test Stand Type" size="small" />
                  )}
                  autoSelect
                  style={{ width: "12%" }}
                />

                <FormControlLabel
                  label="Active"
                  checked={testStandInfo?.isActive}
                  disabled={!canManageTestStand}
                  onChange={(e) => {
                    setTestStandInfo(() => ({
                      ...testStandInfo,
                      // @ts-ignore
                      isActive: e.target.checked,
                    }));
                  }}
                  control={<Checkbox />}
                />
              </Box>
              <TextField
                onChange={(e) => {
                  setTestStandInfo(() => ({
                    ...testStandInfo,
                    etqid: e.target.value,
                  }));
                }}
                disabled={!canManageTestStand}
                value={testStandInfo?.etqid ? testStandInfo.etqid : ""}
                label="ETQ ID"
                size="small"
                style={{ width: "20%" }}
              />
            </Box>
          ) : null}
        </Box>
        <Box display="flex" gap={2} alignItems="center" marginTop={2}>
          <StyledButton
            variant="contained"
            disabled={
              !showTestStandInfo || !isEditing || isTestStandUpdating || !canManageTestStand
            }
            onClick={updateTestStand}
          >
            Save Test Stand
          </StyledButton>
          <StyledButton
            variant="contained"
            onClick={() => {
              setShowTestStandInfo(false);
              createTestStand();
            }}
            disabled={!showTestStandInfo || isEditing || isTestStandCreating || !canManageTestStand}
          >
            Create Test Stand
          </StyledButton>
        </Box>
      </Box>
      {isMessageModalOpen && (
        <ModalSimpleButton
          title={modalContent.title}
          buttonText="Close"
          buttonAction={() => {
            setIsMessageModalOpen(false);
            setModalContent({ title: "", message: "" });
          }}
          setOpen={setIsMessageModalOpen}
          open={isMessageModalOpen}
        >
          <Typography style={{ padding: "0 16px", fontSize: 18, textAlign: "center" }}>
            {modalContent.message}
          </Typography>
        </ModalSimpleButton>
      )}
    </>
  );
};

export default TestStandManagement;
