import { useState } from "react";

import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/AddCircleOutline";

import { EditStRequestProcessResult } from "./EditStRequestProcessResult";
import { STRequestProcessResultTable } from "./STRequestProcessResultTable";
import type { STRequestProcessResult } from "../../../types";
import { useDetailedStRequest } from "../../../context/DetailedStRequestContext";

type STRequestProcessResultSectionProps = {
  initialSTRequestProcessResults: STRequestProcessResult[];
  saveSTRequestProcessResult(values: STRequestProcessResult[]): void;
};

export default function STRequestProcessResultSection({
  initialSTRequestProcessResults,
  saveSTRequestProcessResult,
}: STRequestProcessResultSectionProps) {
  const { canManageProcessResultDetails, canManageIntervalResultDetails } = useDetailedStRequest();
  const [
    editSTRequestProcessResultModalOpen,
    setEditSTRequestProcessResultModalOpen,
  ] = useState(false);
  const [stRequestProcessResults, setStRequestProcessResults] = useState<
    STRequestProcessResult[]
  >(initialSTRequestProcessResults ?? []);
  const [
    stRequestProcessResultBeingEdited,
    setStRequestProcessResultBeingEdited,
  ] = useState<STRequestProcessResult | null>(null);

  function handleAddSTRequestProcessResult() {
    if (canManageIntervalResultDetails) {
      setStRequestProcessResultBeingEdited(null);
      setEditSTRequestProcessResultModalOpen(true);
    }
  }

  function handleDeleteSTRequestProcessResult(v: STRequestProcessResult) {
    const filtered = stRequestProcessResults.filter((i) => !(i.id === v.id));

    setStRequestProcessResults(filtered);
    saveSTRequestProcessResult(filtered);
  }

  function handleEditSTRequestProcessResult(v: STRequestProcessResult) {
    setStRequestProcessResultBeingEdited(v);
    setEditSTRequestProcessResultModalOpen(true);
  }

  function handleSaveSTRequestProcessResult(
    newValue: STRequestProcessResult,
    previousStRequestProcessResult: STRequestProcessResult | null
  ) {
    let newValues: STRequestProcessResult[] = [];

    if (previousStRequestProcessResult !== null) {
      stRequestProcessResults.forEach((s) => {
        const newStRequestProcessResult = {
          ...s,
        };

        if (
          s.id ===
          previousStRequestProcessResult.id
        ) {
          newStRequestProcessResult.resultValue = newValue.resultValue;
          newStRequestProcessResult.resultUoMName = newValue.resultUoMName;
        }

        newValues.push(newStRequestProcessResult);
      });
    } else {
      newValues = [...stRequestProcessResults, { ...newValue }];
    }

    setStRequestProcessResults(newValues);
    setStRequestProcessResultBeingEdited(null);
    saveSTRequestProcessResult(newValues);
  }

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "start",
        }}
      >
        <span
          style={{
            textAlign: "left",
            font: "normal normal 600 20px/24px EMprint",
            letterSpacing: 0,
          }}
        >
          ST Request Process Result
        </span>
        <IconButton
          type="button"
          aria-label="Add ST Request Process Result"
          onClick={handleAddSTRequestProcessResult}
          disabled={!canManageProcessResultDetails}
        >
          <AddIcon style={{ fill: canManageProcessResultDetails ? "black" : "gray" }} />
        </IconButton>
      </div>
      <STRequestProcessResultTable
        handleDelete={handleDeleteSTRequestProcessResult}
        handleEdit={handleEditSTRequestProcessResult}
        stRequestProcessResults={stRequestProcessResults}
      />
      <EditStRequestProcessResult
        close={() => setEditSTRequestProcessResultModalOpen(false)}
        open={editSTRequestProcessResultModalOpen}
        initialValues={stRequestProcessResultBeingEdited}
        onSave={handleSaveSTRequestProcessResult}
      />
    </>
  );
}
