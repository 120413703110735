import { Accordion, AccordionDetails, AccordionSummary, Autocomplete, Box, Button, Grid, IconButton, Table, TableContainer, TableHead, TableRow, TableCell, TableBody, TextField, Typography } from "@mui/material";
import { styled } from "@mui/system";
import React from "react";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SubstanceStructure from "../../../api/LIMS/SubstanceStructure";
import SubstanceStructureLookup from "../../../api/LIMS/SubstanceStructureLookup";
import SubstanceSubcomponent from "../../../api/LIMS/SubstanceSubcomponent";
import ModalTwoButtons from '../../../components/Modal/ModalTwoButtons';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { useEffect } from "react";
import { ChemIDSearchField } from '../../../components/ChemIDSearch/index';
import { isFlashPointValid } from '../../../global';

const StyledDiv = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'start',
  width: '60%',
  gap: '20px',
  flexDirection: 'column'
}));

const StyledDivInputs = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'start',
  minWidth: '450px',
  gap: '20px',
  flexDirection: 'column'
}));

const StyledButton = styled(Button)(() => ({
  textTransform: 'none',
  height: '2.4rem',
}));

const StyledBoxInputs = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
  gap: '20px'
}));

const StyledBoxTables = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
  gap: '20px'
}));

const StyledAccordionDetails = styled(AccordionDetails)(() => ({
  display: 'flex',
  flexDirection: 'row',
  gap: '20px'
}));

const StyledDivButtons = styled('div')(() => ({
  display: 'flex',
  width: '15rem',
  gap: '20px',
  flexDirection: 'column'
}));

const StyledTextField = styled(TextField)(() => ({
  width: '100%'
}));

const ChemicalAndStructure = ({
  currentSubstance,
  setCurrentSubstance,
  listOfStructuresLookup,
  setListOfStructuresLookup,
  listSubcomponent,
  setListSubcomponent,
  isNewSubstance,
  openModalSimpleButton,
  reloadListStructureLookup,
  reloadListSubcomponent,
  canEdit,
  canEditSubstanceStructures,
  onChangeListSubcomponent
}) => {

  let substanceStructureInitial = new SubstanceStructure({
    smilesString: '',
    chemicalFormula: '',
    molecularWeight: '',
    displayName: '',
    id: '',
  })

  let substanceStructureLookupInitial = new SubstanceStructureLookup({
    chemID: '',
    referenceSource: '',
    structureID: '',
    lowerPercentage: 0,
    upperPercentage: null,
  })

  let substanceSubcomponentInitial = new SubstanceSubcomponent({
    chemID: '',
    subcomponentChemID: null,
    percentageCompositionUpper: 0,
    percentageCompositionLower: 0,
    componentOrder: null,
  })

  let substanceSubcomponentInvalidInitial = {
    componentOrder: false,
  }

  const [expanded, setExpanded] = React.useState(false);
  const [listOfStructures, setListOfStructures] = React.useState([]);

  const [modalStructureOpen, setModalStructureOpen] = React.useState(false);
  const [modalStructureLookupOpen, setModalStructureLookupOpen] = React.useState(false);
  const [modalSubcomponentOpen, setModalSubcomponentOpen] = React.useState(false);

  const [substanceStructure, setSubstanceStructure] = React.useState(substanceStructureInitial);
  const [substanceStructureLookup, setSubstanceStructureLookup] = React.useState(substanceStructureLookupInitial);
  const [substanceSubcomponent, setSubstanceSubcomponent] = React.useState(substanceSubcomponentInitial);
  const [substanceSubcomponentInvalid, setSubstanceSubcomponentInvalid] = React.useState(substanceSubcomponentInvalidInitial);
  const [editStructureLookup, setEditStructureLookup] = React.useState(false);
  const [editSubcomponent, setEditSubcomponent] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [searchSmilesString, setSearchSmilesString] = React.useState('');
  const [searchChemicalFormula, setSearchChemicalFormula] = React.useState('');
  const [hasChemIDErrors, setHasChemIDErrors] = React.useState(false);

  useEffect(() => {
    if (searchSmilesString.length > 2) {
      setIsLoading(true)
      const delayDebounceFn = setTimeout(async () => {
        await findStructure(searchSmilesString)
        setIsLoading(false)

      }, 2000)

      return () => clearTimeout(delayDebounceFn)
    }
  }, [searchSmilesString])

  useEffect(() => {
    if (searchChemicalFormula.length > 2) {
      setIsLoading(true)
      const delayDebounceFn = setTimeout(async () => {
        await findStructure("", searchChemicalFormula)
        setIsLoading(false)

      }, 2000)

      return () => clearTimeout(delayDebounceFn)
    }
  }, [searchChemicalFormula])

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  function openModalStructureOpen() {
    setSubstanceStructure(substanceStructureInitial)
    setModalStructureOpen(true);
  }

  async function findStructure(smilesString = "", chemicalFormula = "") {
    if (smilesString !== "" || chemicalFormula !== "") {
     await SubstanceStructure.find(smilesString, chemicalFormula).then((resp) => {
        setListOfStructures(resp.map(item => {
          return {
            ...item,
            value: item.id,
            name: item.smilesString,
            nameChemical: item.chemicalFormula !== null && item.chemicalFormula !== '' ? `${item.chemicalFormula} - SMILES String (${item.smilesString})` : ""
          }
        }))
      })
    }
  }

  function openModalStructureLookupOpen(substanceStructureLookup) {
    setSubstanceStructureLookup(substanceStructureLookup ? substanceStructureLookup : substanceStructureLookupInitial)
    setModalStructureLookupOpen(true);

    if (substanceStructureLookup && substanceStructureLookup?.structureID) {
      setListOfStructures([
        {
          ...substanceStructureLookup.substanceStructure,
          value: substanceStructureLookup.substanceStructure.id,
          name: substanceStructureLookup.substanceStructure.smilesString,
          nameChemical: substanceStructureLookup.substanceStructure.chemicalFormula !== null && substanceStructureLookup.substanceStructure.chemicalFormula !== '' ? `${substanceStructureLookup.substanceStructure.chemicalFormula} - SMILES String (${substanceStructureLookup.substanceStructure.smilesString})` : ""
        }
      ])
    } else {
      setListOfStructures([])
    }
  }

  function openModalSubcomponentOpen(substanceSubcomponent) {
    const componentOrder = listSubcomponent[0] ? parseInt(listSubcomponent[0].componentOrder) + 1 : 1;
    setSubstanceSubcomponent(substanceSubcomponent ? substanceSubcomponent : {
      ...substanceSubcomponentInitial,
      componentOrder: componentOrder
    })
    setModalSubcomponentOpen(true);
  }

  function closeModalStructureOpen() {
    setModalStructureOpen(false);
  }

  function closeModalStructureLookupOpen() {
    setModalStructureLookupOpen(false);
    setEditStructureLookup(false)
  }

  function closeModalSubcomponentOpen() {
    setModalSubcomponentOpen(false);
    setEditSubcomponent(false);
    setHasChemIDErrors(false);
    setSubstanceSubcomponentInvalid(substanceSubcomponentInvalidInitial);
  }

  async function createStructure(structure) {
    // await SubstanceStructure.create(structure).then(async (res) => {
    //   if (res.errors) {
    //     openModalSimpleButton('Fail', 'Something went wrong, please try after sometime', 'Ok');
    //   } else {
    //     closeModalStructureOpen()
    //   }
    // })
  }

  async function addStructureLookup(structureLookup) {
    let list = listOfStructuresLookup;
    let itemEdited = list.find(s => s.referenceSource === structureLookup.referenceSource)
    if (!itemEdited)
      list.push(structureLookup)
    else {
      list = list.map(item => {
        if (itemEdited.referenceSource === item.referenceSource) {
          return {
            ...itemEdited,
            ...structureLookup
          }
        } else {
          return item
        }
      })
    }
    setListOfStructuresLookup(list)
    closeModalStructureLookupOpen()
  }

  const validateSubcomponentFields = (subcomponent) => {
    const subcomponentChemID = subcomponent?.subcomponentChemID?.chemID ? subcomponent?.subcomponentChemID?.chemID : subcomponent.subcomponentChemID;
    const componentOrderInvalid = listSubcomponent.some(s => s.componentOrder === substanceSubcomponent.componentOrder && s.subcomponentChemID !== subcomponentChemID);
    setSubstanceSubcomponentInvalid({
      componentOrder: componentOrderInvalid
    })
    setHasChemIDErrors(!subcomponentChemID);
    return componentOrderInvalid || !subcomponentChemID;
  }

  async function addSubcomponent(subcomponent) {
    if (validateSubcomponentFields(subcomponent)) return
    subcomponent.subcomponentChemID = subcomponent?.subcomponentChemID?.chemID ? subcomponent?.subcomponentChemID?.chemID : subcomponent.subcomponentChemID;
    let list = listSubcomponent;
    let itemEdited = list.find(s => s.subcomponentChemID === subcomponent?.subcomponentChemID)
    if (!itemEdited)
      list.push(subcomponent)
    else {
      list = list.map(item => {
        if (itemEdited.subcomponentChemID === item.subcomponentChemID) {
          return {
            ...itemEdited,
            ...subcomponent
          }
        } else {
          return item
        }
      })
    }

    setListSubcomponent(list.sort((a, b) => b.componentOrder - a.componentOrder))
    closeModalSubcomponentOpen()
    onChangeListSubcomponent(list)
    
  }

  async function deleteStructureLookup(structureLookup) {
    if (!isNewSubstance) {
      await SubstanceStructureLookup.delete(structureLookup).then(async (res) => {
        if (res.errors) {
          openModalSimpleButton('Fail', 'Something went wrong, please try after sometime', 'Ok');
        } else {
          openModalSimpleButton('Success', 'Substance Structure Lookup Deleted with success', 'Ok');
          reloadListStructureLookup()
        }
      })
    } else {
      let list = listOfStructuresLookup.filter(s => s !== structureLookup);
      setListOfStructuresLookup(list)
    }
  }

  async function deleteSubcomponent(subcomponent) {
    if (!isNewSubstance) {
      await SubstanceSubcomponent.delete(subcomponent).then(async (res) => {
        if (res.errors) {
          openModalSimpleButton('Fail', 'Something went wrong, please try after sometime', 'Ok');
        } else {
          openModalSimpleButton('Success', 'Substance Subcomponent Deleted with success', 'Ok');
          reloadListSubcomponent(true)
        }
      })
    } else {
      let list = listSubcomponent.filter(s => s !== subcomponent);
      onChangeListSubcomponent(list);
      setListSubcomponent(list)
    }
  }

  return (
    <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel3bh-content"
        id="panel3bh-header"
      >
        <Typography variant="h5" sx={{ width: '33%', flexShrink: 0 }} >
          Chemical And Structure
        </Typography>
      </AccordionSummary>
      <StyledAccordionDetails>
        <StyledDivInputs>
          <StyledBoxInputs>
            <TextField size="small" id="density-at-stp" label="Density at STP (g/ml)" variant="outlined"
              onChange={e => setCurrentSubstance(() => ({
                ...currentSubstance,
                densityAtSTPgml: e.target.value < 0 ? 0 : e.target.value
              }))}
              value={currentSubstance.densityAtSTPgml === null ? '' : currentSubstance.densityAtSTPgml}
              type="number"
              InputProps={{ inputProps: { min: 0 } }}
              disabled={!canEdit}
            />
            <TextField size="small" id="boiling-point-c" label="Boiling Point (C)" variant="outlined"
              onChange={e => setCurrentSubstance(() => ({
                ...currentSubstance,
                boilingPointC: e.target.value
              }))}
              value={currentSubstance.boilingPointC === null ? '' : currentSubstance.boilingPointC}
              type="number"
              disabled={!canEdit}
            />
          </StyledBoxInputs>
          <StyledBoxInputs>
            <TextField size="small" id="flash-point-c" label="Flash Point (C)" variant="outlined"
              onChange={e => {
                const value = e.target.value;

                if (isFlashPointValid(value)) {
                  setCurrentSubstance(() => ({
                    ...currentSubstance,
                    flashPointC: value
                  }))
                }
              }}
              value={currentSubstance.flashPointC === null ? '' : currentSubstance.flashPointC}
              type="text"
              disabled={!canEdit}
            />
            <TextField size="small" id="flash-point-method" label="Flash Point Method" variant="outlined"
              onChange={e => setCurrentSubstance(() => ({
                ...currentSubstance,
                flashPointMethod: e.target.value
              }))}
              value={currentSubstance.flashPointMethod === null ? '' : currentSubstance.flashPointMethod}
              inputProps={{ maxLength: 20 }}
              disabled={!canEdit}
            />
          </StyledBoxInputs>
        </StyledDivInputs>
        <StyledDiv>
          <StyledBoxTables>
            <Box style={{ width: 'calc(100% - 15rem)' }}>
              <Typography variant="h6" gutterBottom component="div">
                List of Structures
              </Typography>
              <TableContainer sx={{ maxHeight: 160 }}>
                <Table stickyHeader size="small" aria-label="purchases">
                  <TableHead>
                    <TableRow>
                      <TableCell>SMILES String</TableCell>
                      <TableCell>Chemical Formula</TableCell>
                      <TableCell>Lower Percentage</TableCell>
                      <TableCell>Upper Percentage</TableCell>
                      <TableCell style={{ width: 120 }} align="center">Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {listOfStructuresLookup.length > 0 ? listOfStructuresLookup.map((structure, index) => (
                      <TableRow key={index}>
                        <TableCell component="th" scope="row">
                          {structure.substanceStructure?.smilesString}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {structure.substanceStructure?.chemicalFormula}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {structure.lowerPercentage}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {structure.upperPercentage}
                        </TableCell>
                        <TableCell align="right" >
                          <IconButton disabled={!canEdit} aria-label="edit" onClick={() => {
                            openModalStructureLookupOpen(structure)
                            setEditStructureLookup(true)
                          }}>
                            <EditIcon />
                          </IconButton>
                          <IconButton disabled={!canEdit} aria-label="delete" onClick={() => deleteStructureLookup(structure)}>
                            <DeleteIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    )) : (<TableRow>
                      <TableCell component="th" scope="row" colSpan={5} align="center">
                        No Structures Found
                      </TableCell>
                    </TableRow>)}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
            <StyledDivButtons>
              <StyledButton sx={{ width: "100%" }}
                variant='contained'
                type="submit"
                onClick={() => openModalStructureLookupOpen()}
                disabled={!canEdit}
              >Add Structure</StyledButton>
              <StyledButton sx={{ width: "100%" }}
                variant='contained'
                type="submit"
                onClick={openModalStructureOpen}
                disabled={!canEditSubstanceStructures}
              >Create New Structure</StyledButton>
            </StyledDivButtons>
          </StyledBoxTables>
          <StyledBoxTables>
            <Box style={{ width: 'calc(100% - 15rem)' }}>
              <Typography variant="h6" gutterBottom component="div">
                List of Subcomponents
              </Typography>
              <TableContainer sx={{ maxHeight: 160 }}>
                <Table stickyHeader size="small" aria-label="purchases">
                  <TableHead>
                    <TableRow>
                      <TableCell>Chem ID</TableCell>
                      <TableCell>Lower Percentage</TableCell>
                      <TableCell>Upper Percentage</TableCell>
                      <TableCell>Order</TableCell>
                      <TableCell style={{ width: 120 }} align="center">Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {listSubcomponent.length > 0 ? listSubcomponent.map((subcomponent, index) => (
                      <TableRow key={index}>
                        <TableCell component="th" scope="row">
                          {subcomponent.subcomponentChemID}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {subcomponent.percentageCompositionLower}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {subcomponent.percentageCompositionUpper}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {subcomponent.componentOrder}
                        </TableCell>
                        <TableCell align="right" >
                          <IconButton disabled={isNewSubstance ? false : (!isNewSubstance || !canEdit)} aria-label="edit" onClick={() => {
                            openModalSubcomponentOpen(subcomponent)
                            setEditSubcomponent(true)
                          }}>
                            <EditIcon />
                          </IconButton>
                          <IconButton disabled={isNewSubstance ? false : (!isNewSubstance || !canEdit)} aria-label="delete" onClick={() => deleteSubcomponent(subcomponent)}>
                            <DeleteIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    )) : (
                      <TableRow>
                        <TableCell component="th" scope="row" colSpan={5} align="center">
                          No Subcomponent Found
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
            <StyledButton sx={{ width: "15rem" }}
              variant='contained'
              type="submit"
              onClick={() => openModalSubcomponentOpen()}
              disabled={isNewSubstance ? false : (!isNewSubstance || !canEdit)}
            >Add Subcomponents</StyledButton>
          </StyledBoxTables>
        </StyledDiv>
      </StyledAccordionDetails>
      <ModalTwoButtons title={'Structure'} button1Text={'Create'} button1Action={() => createStructure(substanceStructure)} button2Text={'Cancel'} button2Action={closeModalStructureOpen} open={modalStructureOpen} setOpen={setModalStructureOpen} setClose={closeModalStructureOpen}>
        {/* <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }} maxWidth={'500px'}>
          <Grid item xs={2} sm={4} md={6} key={"smilesString"}>
            <StyledTextField
              onChange={e => setSubstanceStructure({
                ...substanceStructure,
                smilesString: e.target.value
              })}
              label="Smiles String"
              size="small"
              value={substanceStructure.smilesString}
              inputProps={{ maxLength: 200 }} 
              />
          </Grid>
          <Grid item xs={2} sm={4} md={6} key={"chemicalFormula"}>
            <StyledTextField
              onChange={e => setSubstanceStructure({
                ...substanceStructure,
                chemicalFormula: e.target.value
              })}
              label="Chemical Formula"
              size="small"
              value={substanceStructure.chemicalFormula} 
              inputProps={{ maxLength: 100 }}
              />
          </Grid>
          <Grid item xs={2} sm={4} md={6} key={"molecularWeight"}>
            <StyledTextField
              onChange={e => setSubstanceStructure({
                ...substanceStructure,
                molecularWeight: e.target.value
              })}
              label="Molecular Weight"
              size="small"
              value={substanceStructure.molecularWeight} />
          </Grid>
          <Grid item xs={2} sm={4} md={6} key={"displayName"}>
            <StyledTextField
              onChange={e => setSubstanceStructure({
                ...substanceStructure,
                displayName: e.target.value
              })}
              label="Display Name"
              size="small"
              value={substanceStructure.displayName}
              inputProps={{ maxLength: 200 }}
              />
          </Grid>
        </Grid> */}
        <div>Substance Structure in development</div>
      </ModalTwoButtons>
      <ModalTwoButtons title={'Structure Lookup'} button1Text={'Add'} button1Action={() => addStructureLookup(substanceStructureLookup)} button2Text={'Cancel'} button2Action={closeModalStructureLookupOpen}  open={modalStructureLookupOpen} setOpen={setModalStructureLookupOpen} setClose={closeModalStructureLookupOpen}>
        <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }} maxWidth={'500px'}>
          <Grid item xs={2} sm={4} md={6} key={"smilesString"}>
            <Autocomplete
              id="smilesString"
              disablePortal
              noOptionsText={isLoading ? "Loading SMILES String..." : "No SMILES String Found"}
              options={listOfStructures}
              getOptionLabel={(option) => option?.name ? option.name : listOfStructures.find(f => f.value === option)?.name}
              onChange={(e, value) => {
                setSubstanceStructureLookup({
                  ...substanceStructureLookup,
                  structureID: value?.value ? value?.value : null,
                  substanceStructure: listOfStructures.find(f => f.value === value?.value)
                })
              }}
              autoHighlight
              autoSelect
              disabled={editStructureLookup}
              value={substanceStructureLookup.structureID === '' ? null : substanceStructureLookup.structureID}
              renderInput={(params) => <StyledTextField {...params}  onChange={(e) => setSearchSmilesString(e.target.value)} variant="outlined" size="small" label="SMILES String" error={false} InputProps={{ ...params.InputProps }} />}
            />
          </Grid>
          <Grid item xs={2} sm={4} md={6} key={"chemicalFormula"}>
            <Autocomplete
              id="chemicalFormula"
              disablePortal
              noOptionsText={isLoading ? "Loading Chemical Formula..." : "No Chemical Formula Found"}
              options={listOfStructures}
              getOptionLabel={(option) => option?.name ? option.name : listOfStructures.find(f => f.value === option)?.nameChemical}
              onChange={(e, value) => {
                setSubstanceStructureLookup({
                  ...substanceStructureLookup,
                  structureID: value?.value ? value?.value : null,
                  substanceStructure: listOfStructures.find(f => f.value === value?.value)
                })
              }}
              autoHighlight
              autoSelect
              disabled={editStructureLookup}
              value={substanceStructureLookup.structureID === '' ? null : substanceStructureLookup.structureID}
              renderInput={(params) => <StyledTextField {...params} onChange={(e) => setSearchChemicalFormula(e.target.value)} variant="outlined" size="small" label="Chemical Formula" error={false} InputProps={{ ...params.InputProps }} />}
            />
          </Grid>
          <Grid item xs={4} sm={8} md={12} key={"referenceSource"}>
            <StyledTextField
              onChange={e => setSubstanceStructureLookup({
                ...substanceStructureLookup,
                referenceSource: e.target.value
              })}
              label="Reference Source"
              size="small"
              disabled={editStructureLookup}
              value={substanceStructureLookup.referenceSource}
              inputProps={{ maxLength: 200 }}
              />
          </Grid>
          <Grid item xs={2} sm={4} md={6} key={"lowerPercentage"}>
            <StyledTextField
              onChange={e => setSubstanceStructureLookup({
                ...substanceStructureLookup,
                lowerPercentage: e.target.value > 100 ? 100 : e.target.value < 0 ? 0 : e.target.value,
              })}
              onBlur={e => setSubstanceStructureLookup({
                ...substanceStructureLookup,
                upperPercentage: substanceStructureLookup.upperPercentage !== null && e.target.value > substanceStructureLookup.upperPercentage ? e.target.value : substanceStructureLookup.upperPercentage
              })}
              label="Lower Percentage"
              size="small"
              type="number"
              required
              value={substanceStructureLookup.lowerPercentage === null ? '' : substanceStructureLookup.lowerPercentage} />
          </Grid>
          <Grid item xs={2} sm={4} md={6} key={"upperPercentage"}>
            <StyledTextField
              onChange={e => setSubstanceStructureLookup({
                ...substanceStructureLookup,
                upperPercentage: e.target.value > 100 ? 100 : e.target.value < 0 ? 0 : e.target.value
              })}
              onBlur={e => setSubstanceStructureLookup({
                ...substanceStructureLookup,
                lowerPercentage: e.target.value < substanceStructureLookup.lowerPercentage ? e.target.value : substanceStructureLookup.lowerPercentage
              })}
              label="Upper Percentage"
              size="small"
              type="number"
              value={substanceStructureLookup.upperPercentage === null ? '' : substanceStructureLookup.upperPercentage} />
          </Grid>
        </Grid>
      </ModalTwoButtons>
      <ModalTwoButtons title={'Subcomponent'} button1Text={'Add'} button1Action={() => addSubcomponent(substanceSubcomponent)} button2Text={'Cancel'} button2Action={closeModalSubcomponentOpen} open={modalSubcomponentOpen} setOpen={setModalSubcomponentOpen} setClose={closeModalSubcomponentOpen}>
        <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }} maxWidth={'500px'} style={{marginTop:".1rem"}}>
          <Grid item xs={2} sm={4} md={6} key={"subcomponentChemID"}>
              { editSubcomponent ?  <StyledTextField
                label="Subcomponent Chem ID"
                size="small"
                disabled={true}
                value={substanceSubcomponent.subcomponentChemID} 
                inputProps={{ maxLength: 50 }}
              /> : <ChemIDSearchField
                fontSize={16}
                fieldWidth="100%"
                selectedChemIDObject={substanceSubcomponent.subcomponentChemID}
                setSelectedChemIDObject={(e) => setSubstanceSubcomponent({
                  ...substanceSubcomponent,
                  subcomponentChemID: e
                })}
                hasErrors={hasChemIDErrors}
                setHasErrors={setHasChemIDErrors}
                margin={'none'}
                allowInactiveChemIDs={true}
                returnSubstanceStrucureData={true}
              />}
          </Grid>
          <Grid item xs={2} sm={4} md={6} key={"componentOrder"}>
            <StyledTextField
              onChange={e => setSubstanceSubcomponent({
                ...substanceSubcomponent,
                componentOrder: parseInt(e.target.value)
              })}
              label="Component Order"
              size="small"
              type="number"
              value={substanceSubcomponent.componentOrder === null ? '' : substanceSubcomponent.componentOrder} 
              error={substanceSubcomponentInvalid.componentOrder}
              helperText={substanceSubcomponentInvalid.componentOrder ? 'Order already created' : ''}
            />
          </Grid>
          <Grid item xs={2} sm={4} md={6} key={"percentageCompositionLower"}>
            <StyledTextField
              onChange={e => setSubstanceSubcomponent({
                ...substanceSubcomponent,
                percentageCompositionLower: e.target.value > 100 ? 100 : e.target.value < 0 ? 0 : e.target.value,
              })}
              onBlur={e => setSubstanceSubcomponent({
                ...substanceSubcomponent,
                percentageCompositionUpper: substanceSubcomponent.percentageCompositionUpper !== null && e.target.value > substanceSubcomponent.percentageCompositionUpper ? e.target.value : substanceSubcomponent.percentageCompositionUpper
              })}
              label="Percentage Composition Lower"
              size="small"
              type="number"
              value={substanceSubcomponent.percentageCompositionLower === null ? '' : substanceSubcomponent.percentageCompositionLower} />
          </Grid>
          <Grid item xs={2} sm={4} md={6} key={"percentageCompositionUpper"}>
            <StyledTextField
              onChange={e => setSubstanceSubcomponent({
                ...substanceSubcomponent,
                percentageCompositionUpper: e.target.value > 100 ? 100 : e.target.value < 0 ? 0 : e.target.value
              })}
              onBlur={e => setSubstanceSubcomponent({
                ...substanceSubcomponent,
                percentageCompositionLower: e.target.value < substanceSubcomponent.percentageCompositionLower ? e.target.value : substanceSubcomponent.percentageCompositionLower
              })}
              label="Percentage Composition Upper"
              size="small"
              type="number"
              value={substanceSubcomponent.percentageCompositionUpper === null ? '' : substanceSubcomponent.percentageCompositionUpper} />
          </Grid>
        </Grid>
      </ModalTwoButtons>
    </Accordion>
  );
};

export default ChemicalAndStructure;