import React from "react";
import { styled } from "@mui/material/styles";
import {
  Button
} from "@mui/material";

const Logout = ({ ...props }) => {

  const handleLogin = () => {
    window.localStorage.setItem("SHOULD_LOGIN", true);
    window.location.href = "/";
  }

  const StyledContainer = styled('div')({
    display: 'flex',
    justifyContent: "center",
    height: '115vh',
    alignItems: 'center',
    background: 'transparent linear-gradient(93deg, #3a397b 0%, #0c69b0 100%) 0% 0% no-repeat padding-box'
  });

  const StyledBox = styled('div')({
    display: 'flex',
    justifyContent: "center",
    flexWrap: 'wrap',
    width: '30rem',
    border: '2px solid white',
    padding: '3rem',
    borderRadius: '2rem'
  });

  const StyledButton = styled(Button)(() => ({
    backgroundColor: "white",
    color: "#3190D9",
    fontSize: "13px",
    fontFamily: "EMprint",
    textTransform: "none",
    "&:hover": {
      backgroundColor: 'white'
    },
    width: '18rem',
  }));

  const StyledTitle = styled('h1')(() => ({
    color: "#0c69b0",
    fontSize: "2.5rem",
    fontFamily: "EMprint",
    textTransform: "none",
    textAlign: 'center',
    textShadow: '-2px -2px 0 #fff, 2px -2px 0 #fff, -2px 2px 0 #fff, 2px 2px 0 #fff;'
  }));

  return (
    <StyledContainer>
      <StyledBox>
        <StyledTitle>You are not logged in. Please login to continue on MIDAS 3</StyledTitle>
        {/* <StyledTitle>You are not logged in. Please login to continue on MIDAS 3</StyledTitle> */}
        <StyledButton type="button" onClick={handleLogin}>Login</StyledButton>
      </StyledBox>
    </StyledContainer>
  );
};

export default Logout;