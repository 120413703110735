import API from "../index";

export default class STMethodClassification {
  constructor({ classificationName, isActive }) {
    this.classificationName = classificationName;
    this.isActive = isActive;
  }

  static async getAllActiveTypes() {
    const api = await API();
    const { data } = await api.get("/stmethodclassification");

    if (data && data.result) {
      return data.result.filter((r) => r.isActive);
    }

    return [];
  }

  static async getAll() {
    const api = await API()
    const { data } = await api.get('/stmethodclassification')
    return data.result.map(method => new STMethodClassification(method))
  }

  static async add(payload) {
    const api = await API();
    const { data } = await api.post('/stmethodclassification', payload);
    return new STMethodClassification(data.result);
  }

  static async update(payload) {
    const api = await API();
    const { data } = await api.put('/stmethodclassification', payload);
    return new STMethodClassification(data.result);
  }

  static async get(name) {
    const api = await API();
    const { data } = await api.get(`stmethodclassification/${name}`);
    if (data.result) {
      return new STMethodClassification(data.result);
    }
    else {
      return null;
    }
  }

  static async getAllActiveForStRequestCreation() {
    const api = await API();
    const { data } = await api.get("/stmethodclassification/strequestcreation");

    return data && data.result ? data.result : [];
  }
}
