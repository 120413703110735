import API from '../index'

export default class EquipmentType {
    constructor(
        EquipmentTypeName,
        Description,
        IsActive,
    ) {
        this.EquipmentTypeName = EquipmentTypeName
        this.Description = Description
        this.IsActive = IsActive
    }

    static async getAll() {
        const api = await API()
        const { data } = await api.get('/equipmenttype')
        return data.result.map(equipmentType => new EquipmentType(equipmentType))
    }

    static async getEquipmentTypeByName(equipmentTypeName) {
        const api = await API();
        const { data } = await api.get(`/equipmenttype?equipmentTypeName=${equipmentTypeName}`);
        return data.result.map((equipmentType) => new EquipmentType(equipmentType));      
    }
}