import API from '../index'

export default class DistillationType {
    constructor(
        distillationTypeName,
        isActive,
    ) {
        this.distillationTypeName = distillationTypeName
        this.isActive = isActive
    }

    static async getAllDistillationTypes() {
        const api = await API();
        const { data } = await api.get('/distillationtype');
        return data.result;
    }
}