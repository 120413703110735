import { useContext, useEffect, useState } from "react";
import CustomTabs from "./CustomTabs";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import styled from "@mui/material/styles/styled";
import CircularProgress from "@mui/material/CircularProgress";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Tooltip from "@mui/material/Tooltip";
import DetailedNavigationView from "./DetailedNavigationView";
import type {
  InformationForProcessRequest,
  Interval,
  IntervalSample,
  StationaryTest,
} from "../../types";
import DetailedActiveRequest from "./DetailedActiveRequest";
import {
  PROCESS_REQUESTS_INITIAL_VALUES,
  REQUEST_INFORMATION_ENUM,
  getFormikInitialValuesForProcessRequestByApiResponse,
  getPayloadForRegisterIntervals,
  getPayloadForSaveAction,
} from "../../shared";
import STRequest from "../../../../api/StationaryTesting/STRequest";
import { useFormik } from "formik";
import { toast } from "react-hot-toast";
import STRequestInterval from "../../../../api/StationaryTesting/STRequestInterval";
import ModalTwoButtons from "../../../../components/Modal/ModalTwoButtons";
import { usePDF } from "@react-pdf/renderer";
import RunCard from "../PrintableComponents/RunCard";
import { isValidForSampleRegistration, validateIntervals } from "../../validators";
import PrintLabel from "../../../../api/LIMS/PrintLabel";
import PrintInformation from "../../../../components/PrintInformation";
import { DetailedStRequestProvider } from "../../context/DetailedStRequestContext";
import UserContext from "../../../../context/UserContext";
import { Roles, hasRole } from "../../../../global";
import { useSideImportantInformation } from "../../context/SideImportantInformationContext";
import STRequestIntervalApi from "../../../../api/StationaryTesting/STRequestInterval";
import STRequestProcessResultApi from "../../../../api/StationaryTesting/STRequestProcessResult";
import { DetailedProcessRequestSchema } from "../../validation";
import BillingInfo from "../../../../api/Admin/BillingInfo";

const ContainerBox = styled(Box)({
  position: "fixed",
  backgroundColor: "white",
  zIndex: 99,
  display: "flex",
  flexDirection: "column",
  width: "-webkit-fill-available",
  height: "-webkit-fill-available",
  paddingBottom: "2rem",
  overflow: "scroll",
  "&::-webkit-scrollbar": {
    width: 0,
  },
  borderTop: "solid 1px #e3e3e3",
});

enum ActionEnums {
  SaveUpdates = "Save Updates",
  BackToRequester = "Send Request Back To Requester",
  BackToEngineer = "Send Request Back To Engineer",
  Cancel = "Cancel Request",
  Reopen = "Reopen Request",
  PrintRunCard = "Print Run Card",
  RegisterSample = "Register Sample(s)",
}

type DetailedProcessRequestProps = {
  visibleProcessRequests: StationaryTest[];
  onCloseTab(closingIndex: number): void;
};

export default function DetailedProcessRequest({
  visibleProcessRequests = [],
  onCloseTab,
}: DetailedProcessRequestProps) {
  const [tabIndex, setTabIndex] = useState(0);
  const [activeOptionName, setActiveOptionName] = useState<REQUEST_INFORMATION_ENUM>(
    REQUEST_INFORMATION_ENUM.GENERAL,
  );
  const [loading, setLoading] = useState(true);
  const [informationForProcessRequest, setInformationForProcessRequest] =
    useState<InformationForProcessRequest | null>(null);
  const [actions, setActions] = useState<string[]>([""]);
  const [selectedAction, setSelectedAction] = useState<string>("");
  const [modalOpen, setModalOpen] = useState(false);
  const [notificationText, setNotificationText] = useState("");
  const [expanded, setExpanded] = useState(false);
  const [printInfoOpen, setPrintInfoOpen] = useState<boolean>(false);
  const [printLabelInfo, setPrintLabelInfo] = useState<PrintLabel[] | null>(null);
  const { setSideImportantInformation } = useSideImportantInformation();
  const [availableBillingCodes, setAvailableBillingCodes] = useState<string[]>([]);

  const tabLabels = visibleProcessRequests.map((vpr) => {
    return {
      label: vpr.cardNumber ?? vpr.id,
    };
  });

  const activeProcessRequest =
    visibleProcessRequests.length > 0 ? visibleProcessRequests[tabIndex] : null;

  const formik = useFormik({
    initialValues: PROCESS_REQUESTS_INITIAL_VALUES,
    validationSchema: DetailedProcessRequestSchema(availableBillingCodes),
    validateOnMount: true,
    initialTouched: { billingReference: true },
    onSubmit: () => {},
  });

  function fetchDataForForm() {
    getRequestInformation(activeProcessRequest);
    getActions(activeProcessRequest);
  }

  useEffect(() => {
    BillingInfo.getAll().then((response) => {
      if (response.message === "Success") {
        const billingInfo: BillingInfo[] = response.result;
        setAvailableBillingCodes(billingInfo.map((info) => info.costCode));
      }
    });
  }, []);

  useEffect(() => {
    fetchDataForForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeProcessRequest]);

  const [instance, update] = usePDF({
    document: <RunCard stationaryTest={formik.values}></RunCard>,
  });

  useEffect(() => {
    // @ts-ignore
    update(<RunCard stationaryTest={formik.values} />);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values]);

  function handleChange(_: any, newValue: number) {
    const isNewValueValid = newValue < visibleProcessRequests.length;
    if (isNewValueValid) {
      setTabIndex(newValue);
      setSideImportantInformation([]);
    }
  }

  function customClose(closingIndex: number) {
    onCloseTab(closingIndex);
    if (tabIndex === closingIndex) {
      if (visibleProcessRequests.length > 0) {
        setTabIndex(0);
      }
    }
  }

  async function getRequestInformation(activeProcessRequest: StationaryTest | null) {
    if (activeProcessRequest && activeProcessRequest.id) {
      setInformationForProcessRequest(null);
      setLoading(true);
      const response = await STRequest.getRequestInformationByStRequestId(activeProcessRequest.id);
      setInformationForProcessRequest(response);

      const stRequestProcessResults = await STRequestProcessResultApi.getByStRequestId(
        activeProcessRequest.id,
      );

      const intervalsWithIntervalProcessResults =
        await STRequestIntervalApi.getAllSTRequestIntervalsForRequest(activeProcessRequest.id);

      const formikValues = getFormikInitialValuesForProcessRequestByApiResponse(
        response,
        stRequestProcessResults,
        intervalsWithIntervalProcessResults,
      );

      formik.setValues(formikValues as any);

      setLoading(false);
    }
  }

  async function getActions(activeProcessRequest: StationaryTest | null) {
    if (activeProcessRequest && activeProcessRequest.id) {
      setLoading(true);
      const response = await STRequest.getActions(activeProcessRequest.id);
      setActions(["", ...response]);
      setLoading(false);
    }
  }

  function downloadRunCard() {
    if (!instance.loading && !instance.error && instance.url) {
      const link = document.createElement("a");
      link.href = instance.url;
      link.target = "_blank";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }

  function validateIntervalPayload(requestIntervals: Interval[]) {
    const { isValid, error } = validateIntervals(requestIntervals);

    if (!isValid) {
      toast.error(error);
    }

    return isValid;
  }

  function getIntervalWithContainer(requestInterval: Interval) {
    if (
      requestInterval.intervalSample &&
      requestInterval.intervalSample.containers &&
      requestInterval.intervalSample.containers.length > 0
    ) {
      const containers: any[] = [];

      requestInterval.intervalSample.containers.forEach((c: any) => {
        containers.push({
          ...c,
          returnLocationName: formik.values.containerReturnLocation ?? c.returnLocationName,
        });
      });

      requestInterval.intervalSample.containers = containers;
    }

    return requestInterval;
  }

  async function handleSaveUpdates() {
    setLoading(true);
    const payloadToRequest = getPayloadForSaveAction(formik.values);
    let response = null;

    if (activeOptionName === REQUEST_INFORMATION_ENUM.SAMPLE_SCHEDULE) {
      const validIntervals = validateIntervalPayload(payloadToRequest.requestIntervals!);

      if (validIntervals) {
        response = await STRequestInterval.saveIntervals(
          activeProcessRequest!.id,
          payloadToRequest.sampleScheduleType,
          payloadToRequest.requestIntervals,
        );
      } else {
        setLoading(false);
        return;
      }
    } else {
      const formData = new FormData();

      formik.values.fileAttachments?.forEach((file) => {
        if (file.file && !file.file.requestId) {
          formData.append("files", file.file as any);
        }
      });

      formData.append("stRequestCreateUpdate", JSON.stringify(payloadToRequest));

      response = await STRequest.ActionSave(formData);
    }

    if (response && response.result) {
      toast.success("Request successfully updated");
      fetchDataForForm();
    } else if (response && response.message) {
      toast.error(response.message);
    } else {
      toast.error("Failed to update Request");
    }
    setLoading(false);
  }

  async function handleRegisterSamples() {
    const validationResponse = isValidForSampleRegistration(
      activeProcessRequest,
      formik.values.requestIntervals,
      formik.values.intervalSamplesToRegister,
    );

    if (!validationResponse.isValid) {
      return toast.error(validationResponse.error);
    }

    setLoading(true);

    const intervalSamplesToRegisterWithContainers: Interval[] = formik.values
      .intervalSamplesToRegister
      ? formik.values.intervalSamplesToRegister.map(getIntervalWithContainer)
      : [];

    let relevantRequestIntervalsWithContainers: Interval[] = formik.values.requestIntervals
      ? formik.values.requestIntervals
          .map(getIntervalWithContainer)
          .filter(
            (interval) =>
              intervalSamplesToRegisterWithContainers.find(
                (registerInterval) => registerInterval.intervalName === interval.intervalName,
              ) !== undefined,
          )
      : [];

    const payloadToRequest = getPayloadForRegisterIntervals(
      activeProcessRequest!,
      intervalSamplesToRegisterWithContainers,
      relevantRequestIntervalsWithContainers,
    );

    const response = await STRequestInterval.registerSamples(payloadToRequest);

    if (response && response.result && response.result.length > 0) {
      const filteredSamples = response.result.filter((ri: any) => {
        const found = formik.values.intervalSamplesToRegister?.some((isr) => {
          return isr.intervalName === ri.intervalName;
        });

        return found;
      });

      const registeredSamples = filteredSamples.map(
        (requestInterval: any) => requestInterval.intervalSample,
      );

      openPrintInfo(registeredSamples);

      formik.setFieldValue("intervalSamplesToRegister", []);
      formik.setFieldValue("requestIntervals", response); //requestIntervals);
    } else {
      if (response && response.message) {
        toast.error(response.message);
      } else {
        toast.error("Failed while registering sample(s)");
      }
    }

    setLoading(false);
  }

  async function handleAction() {
    switch (selectedAction) {
      case ActionEnums.SaveUpdates:
        handleSaveUpdates();
        break;
      case ActionEnums.BackToRequester:
      case ActionEnums.BackToEngineer:
      case ActionEnums.Cancel:
      case ActionEnums.Reopen:
        setModalOpen(true);
        break;
      case ActionEnums.PrintRunCard:
        downloadRunCard();
        break;
      case ActionEnums.RegisterSample:
        handleRegisterSamples();
        break;
      default:
        break;
    }
  }

  async function handleSendNotification() {
    setLoading(true);
    let response;

    if (selectedAction === ActionEnums.BackToRequester) {
      response = await STRequest.ActionSendBackToRequester(
        activeProcessRequest?.id,
        notificationText,
      );
    } else if (selectedAction === ActionEnums.BackToEngineer) {
      response = await STRequest.ActionSendBackToEngineer(
        activeProcessRequest?.id,
        notificationText,
      );
    } else if (selectedAction === ActionEnums.Cancel) {
      response = await STRequest.ActionCancel(activeProcessRequest?.id, notificationText);
    } else if (selectedAction === ActionEnums.Reopen) {
      response = await STRequest.ActionReopen(activeProcessRequest?.id, notificationText);
    }

    if (response) {
      toast.success("Notification sent");
      setModalOpen(false);
      setNotificationText("");
      setSelectedAction("");
      await getActions(activeProcessRequest);
      await getRequestInformation(activeProcessRequest);
    } else {
      toast.error("Failed while sending notification");
    }
    setLoading(false);
  }

  function openPrintInfo(registeredSamples: IntervalSample[]) {
    const arrayPrintInfo: PrintLabel[] = [];
    const containerNumbers: number[] = [];

    registeredSamples.forEach((sample) => {
      sample.containers?.forEach((c) => {
        containerNumbers.push(c.containerNumber);
      });

      const newPrintInfo = new PrintLabel({
        sampleName: sample.sampleName,
        containerNumbers: containerNumbers,
        includeAdditionalSampleInformation: false,
        chemIDOnly: false,
        printLabelSizeType: null,
        isBlindCoded: false,
        blindDescription: null,
        blindSampleName: null,
        isShelfLabel: false,
        shelfLabelText: null,
        includeShelfBarcode: false,
      });

      arrayPrintInfo.push(newPrintInfo);
    });

    setPrintLabelInfo(arrayPrintInfo);
    setPrintInfoOpen(true);
  }

  const currentUser = useContext(UserContext);
  // @ts-ignore
  const roles = currentUser?.idTokenClaims?.roles;

  const canManageSampleSchedule =
    hasRole(Roles.Developer, roles) || hasRole(Roles.STScheduleAdministrator, roles);

  const canManageAuthorizationValidation =
    hasRole(Roles.Developer, roles) || hasRole(Roles.STValidator, roles);

  const canManageProcessResultDetails =
    hasRole(Roles.Developer, roles) || hasRole(Roles.STProcessResult, roles);

  const canManageIntervalResultDetails =
    hasRole(Roles.Developer, roles) || hasRole(Roles.STIntervalResult, roles);

  const canManageFuelSamples =
    hasRole(Roles.Developer, roles) || hasRole(Roles.STSupplementalSample, roles);

  const canManageGeneralAndTesting =
    hasRole(Roles.Developer, roles) || hasRole(Roles.STExperimentProcessor, roles);

  if (!activeProcessRequest) {
    return <></>;
  }

  return (
    <DetailedStRequestProvider
      stRequestId={activeProcessRequest.id}
      actions={actions}
      canManageSampleSchedule={canManageSampleSchedule}
      canManageAuthorizationValidation={canManageAuthorizationValidation}
      canManageIntervalResultDetails={canManageIntervalResultDetails}
      canManageProcessResultDetails={canManageProcessResultDetails}
      canManageFuelSamples={canManageFuelSamples}
      canManageGeneralAndTesting={canManageGeneralAndTesting}>
      <ContainerBox style={{ top: expanded ? "10%" : "50%", transition: "top 0.25s ease" }}>
        <PrintInformation
          open={printInfoOpen}
          setOpen={setPrintInfoOpen}
          button2Action={() => {
            setPrintLabelInfo(null);
            setPrintInfoOpen(false);
          }}
          button2Text="Close"
          printLabelInfo={printLabelInfo}
          isShelfLabel={false}
        />
        <CustomTabs
          handleChange={handleChange}
          onCloseTab={customClose}
          tabIndex={tabIndex}
          tabs={tabLabels}
        />
        {activeProcessRequest && (
          <div
            style={{
              display: "flex",
              marginTop: "2rem",
              flexDirection: "column",
              paddingRight: "8rem",
            }}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                flex: 1,
                alignItems: "center",
                justifyContent: "space-between",
              }}>
              <span
                style={{
                  textAlign: "left",
                  font: "normal normal normal 34px/16px EMprint",
                  letterSpacing: "0.01px",
                  color: "#111112",
                }}>
                {activeProcessRequest.cardNumber}
              </span>
              <Box display="grid" gridTemplateColumns="4fr 1fr 1fr" gap={2}>
                <Autocomplete
                  options={actions}
                  value={selectedAction}
                  renderInput={(params) => <TextField {...params} label="Action" size="small" />}
                  onChange={(_, selected) => {
                    if (selected) {
                      setSelectedAction(selected);
                    }
                  }}
                />
                <Button
                  variant="contained"
                  style={{ textTransform: "initial" }}
                  onClick={handleAction}
                  disabled={loading || !selectedAction}>
                  Go
                </Button>
                <Tooltip title={expanded ? "Expand Less" : "Expand More"}>
                  <div
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setExpanded(!expanded);
                    }}>
                    {expanded ? <ExpandMoreIcon /> : <ExpandLessIcon />}
                  </div>
                </Tooltip>
              </Box>
            </div>

            <Box
              display="grid"
              gridTemplateColumns="8fr .5fr 1.5fr"
              style={{ marginTop: "1.5rem" }}>
              {loading || !informationForProcessRequest ? (
                <Box sx={{ display: "flex" }}>
                  <CircularProgress />
                </Box>
              ) : (
                <DetailedActiveRequest
                  activeOptionName={activeOptionName}
                  informationForProcessRequest={informationForProcessRequest}
                  formik={formik}
                />
              )}

              <Box />

              <DetailedNavigationView
                activeOptionName={activeOptionName}
                setActiveOptionName={setActiveOptionName}
              />
            </Box>
          </div>
        )}
        {modalOpen && !loading && (
          <ModalTwoButtons
            title="Reason for Action"
            button1Text="Confirm"
            button1Action={handleSendNotification}
            button2Text="Cancel"
            button2Action={() => setModalOpen(false)}
            open={modalOpen}
            setOpen={() => setModalOpen(true)}
            isButton1Disabled={false}
            isButton2Disabled={false}
            setClose={() => setModalOpen(false)}>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <label>This reason will be sent as a notification</label>
              <TextField
                size="small"
                label="Reason"
                value={notificationText}
                onChange={(e) => setNotificationText(e.target.value)}
                margin="normal"></TextField>
            </div>
          </ModalTwoButtons>
        )}
      </ContainerBox>
    </DetailedStRequestProvider>
  );
}
