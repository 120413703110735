import React from "react";
import ModalTwoButtons from "../../../components/Modal/ModalTwoButtons";
import { Typography } from "@mui/material";

export default function WarningUnsavedChanges({
  cancelAllOrderStatus,
  completeAllOrderStatus,
  setErrorMessage,
  selectedRequest,
  selectedItems,
  isModalOpen,
  setIsModalOpen,
  createStockRequest,
  setIsRequestItemUpdating,
}) {
  return (
    <ModalTwoButtons
      title="Warning!"
      button1Text="Yes"
      button1Action={async () => {
        const payload = {
          ...selectedRequest,
          requestItems: selectedItems,
        };
        const response = await createStockRequest(payload);

        if (response) {
          setIsModalOpen(false);
          setIsRequestItemUpdating(false);
          completeAllOrderStatus();
        } else {
          setErrorMessage(
            "There was an error when trying to save your changes. Please try again later."
          );
        }
      }}
      button2Text="No"
      button2Action={() => {
        setIsModalOpen(false);
        setIsRequestItemUpdating(false);
        cancelAllOrderStatus();
      }}
      setOpen={setIsModalOpen}
      open={isModalOpen}
    >
      <Typography
        style={{ padding: "0 16px", fontSize: 18, textAlign: "center" }}
      >
        Do you want to save the changes?
      </Typography>
    </ModalTwoButtons>
  );
}
