import React, { useEffect, useState } from "react";
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import {
  StyledForm,
  StyledTableCellMedium,
} from "./styles";
import {
  StyledTableBody,
  UXDataTableNested,
  UXDataTableWithoutBody,
  StyledTableRow,
  StyledTableCell,
} from "../../components/UXDataTable";
import { convertDateFormat } from "../../global";
import Request from "../../api/Stockroom/Request";
import Return from "../../api/Stockroom/Return";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import ModalSimpleButton from "../../components/Modal/ModalSimpleButton";
import VendorSearchField from "../../components/VendorSearchField/VendorSearchField";
import EmailAddressTextField from "../../components/EmailAddressField";
import { GlobalButton } from "../styles";

const _emailOrBadge = { email: "email", badgeId: "badgeId" };

const SRReturns = ({ canEdit }) => {
  const [emailOrBadge, setEmailOrBadge] = useState(_emailOrBadge.email);
  const [badgeId, setBadgeId] = useState(null);
  const [isValidBadgeId, setIsValidBadgeId] = useState(false);

  const [stockRoomList, setStockRoomList] = useState([]);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [page, setPage] = React.useState(0);
  const [stockRoomRowOpen, setStockRoomRowOpen] = React.useState([]);
  const [isDataLoading, setIsDataLoading] = React.useState(false);

  const [requester, setRequester] = React.useState(null);
  const [requestId, setRequestId] = React.useState();
  const [siteAssetNumber, setSiteAssetNumber] = React.useState("");
  const [searchRequesterValid, setSearchRequesterValid] = React.useState(true);
  const [searchRequesterIdValid, setSearchRequesterIdValid] =
    React.useState(true);
  const [searchSiteAssetNumberValid, setSearchSiteAssetNumberValid] =
    React.useState(true);
  const [isReturnModalOpen, setIsReturnModalOpen] = React.useState(false);
  const [messageReturnItem, setMessageReturnItem] = React.useState("");

  const [containerArray, setContainerArray] = useState([]); //{id, sampleName, containerNumber, containerId, sampleNameHasError, containerListBySample}

  useEffect(() => {
    if (stockRoomList.length > 0 && containerArray.length === 0) {
      setContainerArray(
        stockRoomList.map((item) => {
          return {
            id: item.id, //id matches the selectedRequest.requestItems
            sampleName: 0,
            containerNumber: null,
            containerId: 0,
            sampleNameHasError: true,
            containerListBySample: [],
          };
        })
      );
    }
  }, [stockRoomList, containerArray]);

  const returnsCols = [
    "Req #",
    "Requested",
    "Requested By",
    "Status",
    "Request Items",
  ];

  const returnsListCols = [
    "Stockroom #",
    "Description",
    "Request",
    "Issued",
    "UOM",
    "Inventory",
    "Status",
    "Midas #",
    "Container #",
    "Return Quantity",
    "",
  ];

  const COMPLETE = "Complete";

  // const determineStartArray = () => {
  //   if (stockRoomList && stockRoomList.length > 0) {
  //     return stockRoomList.slice(
  //       page * rowsPerPage,
  //       page * rowsPerPage + rowsPerPage
  //     );
  //   } else {
  //     return stockRoomList;
  //   }
  // };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleStockRoomRowClick = async (stockRoom) => {
    const id = stockRoom.id;
    const stockRoomRowOpenIndex = stockRoomRowOpen.indexOf(id);
    let newStockRoomRowOpen = [];

    if (stockRoomRowOpenIndex === -1) {
      newStockRoomRowOpen = newStockRoomRowOpen.concat(stockRoomRowOpen, id);
    } else if (stockRoomRowOpenIndex === 0) {
      newStockRoomRowOpen = newStockRoomRowOpen.concat(
        stockRoomRowOpen.slice(1)
      );
    } else if (stockRoomRowOpenIndex === stockRoomRowOpen.length - 1) {
      newStockRoomRowOpen = newStockRoomRowOpen.concat(
        stockRoomRowOpen.slice(0, -1)
      );
    } else if (stockRoomRowOpenIndex > 0) {
      newStockRoomRowOpen = newStockRoomRowOpen.concat(
        stockRoomRowOpen.slice(0, stockRoomRowOpenIndex),
        stockRoomRowOpen.slice(stockRoomRowOpenIndex + 1)
      );
    }
    setStockRoomRowOpen(newStockRoomRowOpen);
  };

  const isStockRoomRowOpen = (stockRoomId) => {
    if (stockRoomRowOpen) {
      if (stockRoomRowOpen.length > 0)
        return stockRoomRowOpen.indexOf(stockRoomId) !== -1;
    }

    return false;
  };

  const validSearchField = (requestId, siteAssetNumber) => {
    let requesterEmailOrBadge =
      emailOrBadge === _emailOrBadge.email ? requester : badgeId.BadgeID;
    const validRequestId = requestId && requestId > 0;

    const valid =
      (requesterEmailOrBadge && requesterEmailOrBadge !== "") ||
      searchRequesterValid ||
      validRequestId ||
      siteAssetNumber !== "";

    setSearchRequesterIdValid(valid);
    setSearchSiteAssetNumberValid(valid);

    return valid;
  };

  const handleChange = (event) => {
    setEmailOrBadge(event.target.value);
  };

  const handleFind = async () => {
    if (validSearchField(requestId, siteAssetNumber)) {
      setIsDataLoading(true);
      let requesterEmailOrBadge =
        emailOrBadge === _emailOrBadge.email ? requester : badgeId.BadgeID;
      const data = await Request.getFilteredRequestReturns(
        requesterEmailOrBadge,
        requestId,
        siteAssetNumber
      );
      setIsDataLoading(false);
      setStockRoomList(data);
    }
  };

  const handleReturnItem = async (
    requestId,
    requestItemId,
    returnedQuantity
  ) => {
    if (returnedQuantity > 0) {
      setIsDataLoading(true);
      await Return.createReturn({
        requestId,
        requestItemId,
        returnedQuantity,
      })
        .then((data) => {
          setMessageReturnItem("Quantity Successfully Updated!");
          setIsReturnModalOpen(true);
          setIsDataLoading(false);
          handleFind();
        })
        .catch((err) => {
          setMessageReturnItem(
            "Something went wrong, please try after sometime"
          );
        });
    }
  };

  function handleChangeSubTable(items, stockRoomId) {
    const newList = stockRoomList.map((stockRoom) => {
      if (stockRoom.id === stockRoomId) {
        stockRoom.requestItems = items;
      }
      return stockRoom;
    });

    setStockRoomList(() => [...newList]);
  }

  const stockRoomListFiltered =
    stockRoomList.length > 0
      ? stockRoomList
          .filter((s) => s.requestItems.some((ri) => ri.orderStatusName === COMPLETE))
          .map((st) => {
            st.requestItems = st.requestItems.filter((ri) => ri.orderStatusName === COMPLETE);

            return st;
          })
      : [];

  return (
    <Box>
      <Box>
        <StyledForm>
          <Typography component="h2" fontSize={30} marginBottom={2}>
            Find Original Order
          </Typography>
          <Box
            display="flex"
            alignItems="start"
            gap={2}
            marginBottom={3}
            justifyContent={"space-between"}
          >
            <FormControl
              style={{
                width: "50%",
              }}
            >
              <InputLabel id="demo-simple-select-label">Input type</InputLabel>
              <Select
                size="small"
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={emailOrBadge}
                label="Input type"
                onChange={handleChange}
              >
                <MenuItem value={_emailOrBadge.email}>Email</MenuItem>
                <MenuItem value={_emailOrBadge.badgeId}>Badge Id</MenuItem>
              </Select>
            </FormControl>
            {emailOrBadge === _emailOrBadge.email ? (
              <EmailAddressTextField
                fontSize={16}
                fieldWidth={"90rem"}
                validatedUserEmail={requester}
                setValidatedUserEmail={setRequester}
                hasErrors={!searchRequesterValid}
                setHasErrors={(value) => setSearchRequesterValid(!value)}
                isDisabled={false}
                labelText="Requester"
                placeholderText="Email"
                showPlusMeButton={false}
                margin={"none"}
              ></EmailAddressTextField>
            ) : (
              <VendorSearchField
                style={{
                  width: "100%",
                }}
                value={badgeId}
                setValue={setBadgeId}
                isValid={isValidBadgeId}
                setIsValid={setIsValidBadgeId}
                disabled={false}
              />
            )}

            <TextField
              style={{
                width: "100%",
              }}
              size="small"
              type="text"
              name="requestid"
              label="Request ID"
              value={requestId ?? null}
              onChange={(e) => {
                var numberRegex = /^\d+$/;
                if (numberRegex.test(e.target.value) || e.target.value === "") {
                  let value = e.target.value ?? null;
                  setRequestId(value);
                  validSearchField(value, siteAssetNumber);
                }
              }}
              error={!searchRequesterIdValid}
              helperText={!searchRequesterIdValid ? "Required" : ""}
            />
            <TextField
              style={{
                width: "100%",
              }}
              size="small"
              type="text"
              name="stockroom"
              label="Stockroom #"
              onChange={(e) => {
                setSiteAssetNumber(e.target.value);
                validSearchField(requestId, e.target.value);
              }}
              error={!searchSiteAssetNumberValid}
              helperText={!searchSiteAssetNumberValid ? "Required" : ""}
            />
            <Box display="flex" alignItems="center">
              <GlobalButton variant="contained" onClick={handleFind}>
                Find
              </GlobalButton>
            </Box>
          </Box>
        </StyledForm>
      </Box>

      <Box display="flex" flexDirection="column" gap={3}>
        <UXDataTableWithoutBody
          tableWidth="100%"
          cols={returnsCols}
          blankFirstHeader={false}
          tableId="returnsTable"
          rowLength={stockRoomListFiltered.length}
          enablePaging={true}
          rowsPerPage={rowsPerPage}
          page={page}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          noDataFoundMessage={"No returns found"}
          enableAddIcon={false}
          addFunction={null}
          addToolTipText={null}
          isDataLoading={isDataLoading}
        >
          {stockRoomListFiltered && (
            <StyledTableBody key={"returnsTablebody"}>
              {stockRoomListFiltered
                .map((stockRoom, rowIndex) => {
                  let listStockRoomItem = stockRoom?.requestItems;
                  let countStockRoomItemResult = listStockRoomItem.length;
                  return (
                    <React.Fragment key={rowIndex}>
                      <StyledTableRow hover key={`custom-row-${rowIndex}`}>
                        <StyledTableCellMedium>
                          {stockRoom?.id}
                        </StyledTableCellMedium>
                        <StyledTableCellMedium>
                          {convertDateFormat(stockRoom?.createdDate)}
                        </StyledTableCellMedium>
                        <StyledTableCellMedium>
                          {stockRoom?.createdByEmail}
                        </StyledTableCellMedium>
                        <StyledTableCellMedium>
                          {stockRoom?.orderStatusName}
                        </StyledTableCellMedium>
                        <StyledTableCellMedium>
                          {countStockRoomItemResult}
                          {countStockRoomItemResult > 0 && (
                            <IconButton
                              aria-label="expand row"
                              size="small"
                              onClick={() => handleStockRoomRowClick(stockRoom)}
                            >
                              {isStockRoomRowOpen(stockRoom.id) ? (
                                <KeyboardArrowUpIcon />
                              ) : (
                                <KeyboardArrowDownIcon />
                              )}
                            </IconButton>
                          )}
                        </StyledTableCellMedium>
                      </StyledTableRow>

                      <UXDataTableNested
                        key={`data-table-nested-${rowIndex}`}
                        tableWidth={"100%"}
                        cols={returnsListCols}
                        blankFirstHeader={false}
                        colSpan={10}
                        isOpenComparator={stockRoom.id}
                        isOpenArray={stockRoomRowOpen}
                        // beforeChild={mountBeforeChild(test)}
                      >
                        <StyledTableBody key={`sub-table-body-${rowIndex}`}>
                          {listStockRoomItem &&
                            listStockRoomItem.length > 0 &&
                            listStockRoomItem.map((stockRoomItem, rowIndex) => {
                              return (
                                <StyledTableRow key={rowIndex + "sub"}>
                                  <StyledTableCell>
                                    {stockRoomItem?.thisItem?.siteAssetNumber}
                                  </StyledTableCell>
                                  <StyledTableCell>
                                    {stockRoomItem?.thisItem?.description}
                                  </StyledTableCell>
                                  <StyledTableCell>
                                    {stockRoomItem?.requestedQuantity}
                                  </StyledTableCell>
                                  <StyledTableCell>
                                    {stockRoomItem?.issuedQuantity}
                                  </StyledTableCell>
                                  <StyledTableCell>
                                    {stockRoomItem?.thisItem?.stockUnitOfMeasure?.toUpperCase()}
                                  </StyledTableCell>
                                  <StyledTableCell>
                                    {stockRoomItem?.thisItem?.quantity}
                                  </StyledTableCell>
                                  <StyledTableCell>
                                    {stockRoomItem?.orderStatusName}
                                  </StyledTableCell>
                                  <StyledTableCell>
                                    {
                                      stockRoomItem.receivedContainer
                                        ?.sampleName
                                    }
                                  </StyledTableCell>
                                  <StyledTableCell>
                                    {
                                      stockRoomItem.receivedContainer
                                        ?.containerNumber
                                    }
                                  </StyledTableCell>
                                  <StyledTableCell>
                                    <TextField
                                      sx={{ width: "100%", mr: 1 }}
                                      size="small"
                                      type="number"
                                      name="returnedQuantity"
                                      disabled={!canEdit}
                                      InputProps={{
                                        inputProps: {
                                          min: 0,
                                          max:
                                            stockRoomItem.issuedQuantity ?? 0,
                                        },
                                      }}
                                      value={
                                        stockRoomItem.returnedQuantity
                                          ? stockRoomItem.returnedQuantity
                                          : null
                                      }
                                      onChange={(e) => {
                                        stockRoomItem.returnedQuantity =
                                          e.target.value >
                                          stockRoomItem.issuedQuantity
                                            ? stockRoomItem.issuedQuantity
                                            : e.target.value;

                                        handleChangeSubTable(
                                          listStockRoomItem,
                                          stockRoom.id
                                        );
                                      }}
                                    />
                                  </StyledTableCell>
                                  <StyledTableCell>
                                    <GlobalButton
                                      variant="contained"
                                      disabled={!stockRoomItem.issuedQuantity || stockRoomItem.issuedQuantity === 0 || !canEdit}
                                      onClick={() =>
                                        handleReturnItem(
                                          stockRoom.id,
                                          stockRoomItem.id,
                                          stockRoomItem.returnedQuantity
                                        )
                                      }
                                    >
                                      Return
                                    </GlobalButton>
                                  </StyledTableCell>
                                </StyledTableRow>
                              );
                            })}
                        </StyledTableBody>
                      </UXDataTableNested>
                    </React.Fragment>
                  );
                })}
            </StyledTableBody>
          )}
        </UXDataTableWithoutBody>
      </Box>
      {isReturnModalOpen && (
        <ModalSimpleButton
          title="Return Item"
          buttonText="Ok"
          buttonAction={() => {
            setIsReturnModalOpen(false);
          }}
          setOpen={setIsReturnModalOpen}
          open={isReturnModalOpen}
        >
          <Typography
            style={{ padding: "0 16px", fontSize: 18, textAlign: "center" }}
          >
            {messageReturnItem}
          </Typography>
        </ModalSimpleButton>
      )}
    </Box>
  );
};
export default SRReturns;
