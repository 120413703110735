import React from "react";
import {
  Box,
  FormControl,
  TextField,
  Checkbox,
  styled
} from "@mui/material";
import FormControlLabel from '@mui/material/FormControlLabel';
import ModalTwoButtons from '../../../components/Modal/ModalTwoButtons';
import STQueue from "../../../api/StationaryTesting/STQueue";

const StyledBox = styled(Box)(() => ({
  display: 'flex',
  marginBottom: "20px",
  gap: "20px",
  justifyContent: "normal!important"
}));

const StyledTextField = styled(TextField)(() => ({
  width: "35rem"
}));

const StyledFormControl = styled(FormControl)(() => ({
  display: 'flex',
  width: "50rem"
}));

const StyledDiv = styled('div')({
  display: 'flex',
  justifyContent: "space-between"
});

const STQueueForm = ({ infoData, setInfoData, invalidField, handleChangeSTQueueFilter, canEdit }) => {

  const [modalTwoButtonsOpen, setModalTwoButtonsOpen] = React.useState(false);

  function findQueue() {
    handleChangeSTQueueFilter(infoData.workGroupName);
    closeModalTwoButtonsOpen();
  }

  function closeModalTwoButtonsOpen() {
    setModalTwoButtonsOpen(false);
  }

  function checkExistingQueue(queue) {

    if (queue) {
      STQueue.get(queue).then((queueExists) => {
        if ((queueExists !== undefined && queueExists !== null))
          setModalTwoButtonsOpen(true)
      });
    }
  }

  return (

    <div>
      <StyledDiv>
        <div>
          <StyledBox sx={{ flexDirection: 'column' }}>
            <StyledFormControl sx={{ m: 1 }} variant='outlined'>

              <StyledBox sx={{ justifyContent: "space-between" }}>
                <StyledTextField
                  onChange={e => {
                    setInfoData(() => ({
                      ...infoData,
                      workGroupName: e.target.value
                    }))
                  }}
                  onBlur={e => {
                    checkExistingQueue(e.target.value)
                  }}
                  value={infoData.workGroupName}
                  id="queue-name"
                  label="Work Group Name"
                  variant="outlined" size="small" margin="none"
                  error={invalidField.workGroupName.length === 0 ? false : true}
                  disabled={infoData.id || !canEdit ? true : false}
                  helperText={invalidField.workGroupName}
                  inputProps={{ maxLength: 50 }}
                />

                <FormControlLabel
                  label="Active"
                  onChange={e => setInfoData(() => ({
                    ...infoData,
                    isActive: e.target.checked
                  }))}
                  checked={infoData.isActive}
                  disabled={!canEdit}
                  control={<Checkbox />}
                />               
              </StyledBox>      

            </StyledFormControl>
          </StyledBox>

        </div>

      </StyledDiv>

      <ModalTwoButtons title={'Warning'} button1Text={'No'} button1Action={closeModalTwoButtonsOpen} button2Text={'Yes'} button2Action={findQueue} open={modalTwoButtonsOpen} setOpen={setModalTwoButtonsOpen}>
        <label>
          This Work Group already exist, would you like to use it?
        </label>
      </ModalTwoButtons>

    </div>

  );
};

export default STQueueForm;
