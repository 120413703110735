import API from "../../api";

export default class MethodResultComponent {
    /**
     * The MethodResultComponent dimension table for M3
     * @param {Number} resultComponentName The ResultComponentName of the MethodResultComponent
     * @param {Any} resultComponent The ResultComponent of the MethodResultComponent
     * @param {Number} upperLimit The upperLimit of the MethodResultComponent
     * @param {Number} lowerLimit The lowerLimit of the MethodResultComponent
     * @param {Boolean} isOptionalResult The isOptionalResult of the MethodResultComponent
     * @param {Number} significantDigits The significantDigits of the MethodResultComponent
     * @param {String} methodName The methodName of the MethodResultComponent
     * @param {String} uoMName The uoMName of the MethodResultComponent
     */

    constructor({
        resultComponentName,
        resultComponent,
        upperLimit,
        lowerLimit,
        isOptionalResult,
        significantDigits,
        methodName,
        uoMName
    }) {
        this.resultComponentName = resultComponentName;
        this.resultComponent = resultComponent;
        this.upperLimit = upperLimit;
        this.lowerLimit = lowerLimit;
        this.isOptionalResult = isOptionalResult;
        this.significantDigits = significantDigits;
        this.methodName = methodName;
        this.uoMName = uoMName;
    }

    static async getAll() {
        const api = await API();
        const { data } = await api.get(`/methodResultComponent`);
        return data.result.map((d) => new MethodResultComponent(d));
    }

    static async get(resultComponentID, methodName) {
        const api = await API();
        const { data } = await api.get(`methodResultComponent/getById?resultComponentID=${resultComponentID}&methodName=${methodName}`);
        if (data.result) {
            return new MethodResultComponent(data.result);
        }
        else {
            return null;
        }

    }

    static async add(info) {
        const api = await API();
        const { data } = await api.post(`/methodResultComponent`, info);
        return new MethodResultComponent(data.result);
    }

    static async update(resultComponentID, methodName, info) {
        const api = await API();
        const { data } = await api.put(`/methodResultComponent?resultComponentID=${resultComponentID}&methodName=${methodName}`, info);
        return new MethodResultComponent(data.result);
    }

    static async getByNames(methodNames) {
        const names = methodNames.map((name) => `${name}`).join('&methodName=')
        const api = await API();
        const { data } = await api.get(`/methodResultComponent/getByNames?methodName=${names}`);
        return data.result.map((d) => new MethodResultComponent(d));
    }
}
