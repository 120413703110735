import {
  StyledTableCell,
  StyledTableRow,
  UXDataTableWithoutBody,
} from "../../../../components/UXDataTable";
import {
  MySafetyInfoCols,
  sampleGridFontSize,
} from "../../NewWorkRequests/constants";
import { TableCell, TextField, Autocomplete } from "@mui/material";
import { formatMidasNumber, isFlashPointValid } from "../../../../global";

export default function WorkRequestShippingSafetyTable({
  myWorkRequest,
  updateData_ShippingItem,
  setShippingRequest,
  shippingRequest,
  ownerCanEdit,
  dotHazardClassLoading,
  dotHazardClass,
  isCancelledOrCompleted,
}) {
  
  const updateAllSampleOccurance = (property, value, sampleName, containerID) => {
    let itemsCopy = structuredClone(shippingRequest.shippingItems);
    itemsCopy.forEach((element) => {
      if (element.sampleName === sampleName && element.containerID === containerID) element[property] = value;
    });

    setShippingRequest({
      ...shippingRequest,
      shippingItems: itemsCopy,
    });
  };
  let sampleNameSet = [
    ...new Set(
      shippingRequest.shippingItems
        .filter((x) => x.sampleName !== null)
        .map((x) => `${x.sampleName}-${x.containerID}`)
    ),
  ];

  return (
    <UXDataTableWithoutBody
      tableWidth="95%"
      cols={MySafetyInfoCols}
      blankFirstHeader={false}
      tableId={`midasSafetyInfoTable`}
      rowLength={sampleNameSet.length}
      enablePaging={false}
      rowsPerPage={0}
      page={0}
      handleChangePage={null}
      handleChangeRowsPerPage={null}
      noDataFoundMessage={"No MIDAS Numbers Found"}
      enableAddIcon={false}
      addFunction={() => null}
      addToolTipText=""
      isDataLoading={false}
    >
      {sampleNameSet.map((sampleName, index) => {
        const item = shippingRequest.shippingItems.find(
          (x) => `${x.sampleName}-${x.containerID}` === sampleName
        ) ?? shippingRequest.shippingItems[index];
        return (
          <StyledTableRow key={`WRSamplesSafetyTable~Row${index}`}>
            <TableCell
              style={{ width: "120px", fontSize: sampleGridFontSize }}
              align="left"
            >
              {item.sampleName ? formatMidasNumber(item.sampleName) : "-"}
            </TableCell>

            <TableCell
              style={{ width: "151px", fontSize: sampleGridFontSize }}
              align="left"
            >
              {item.thisContainer ? item.thisContainer.containerNumber : ''}
            </TableCell>
            <TableCell
              style={{ width: "100px", fontSize: sampleGridFontSize }}
              align="left"
            >
              {item.thisContainer?.currentAmount ?? ""}
            </TableCell>
            <TableCell
              style={{ width: "100px", fontSize: sampleGridFontSize }}
              align="left"
            >
              {item?.thisContainer?.containerSizeUoM ?? ""}
            </TableCell>
            <TableCell
              style={{ width: "100px", fontSize: sampleGridFontSize }}
              align="left"
            >
              {item.thisContainer?.sample
                ? item.thisContainer?.sample?.psimsid
                : item.psimsid ?? ""}
            </TableCell>
            {/* Blind Code Label Description */}
            <StyledTableCell align="left">
              <TextField
                style={{ width: "100%", marginTop: "5px" }}
                disabled={false}
                size="small"
                margin="dense"
                variant="outlined"
                inputProps={{
                  style: { fontSize: sampleGridFontSize },
                }}
                InputLabelProps={{ shrink: true }}
                value={item.blindCodeDescription === null ? "" : item.blindCodeDescription}
                onChange={(e) => {
                  updateAllSampleOccurance(
                    "blindCodeDescription",
                    e.target.value,
                    item.sampleName,
                    item.containerID
                  );
                }}
              />
            </StyledTableCell>
            <StyledTableCell align="left" style={{ width: '130px' }}>
              <TextField
                style={{ width: "100%", marginTop: "5px" }}
                size="small"
                margin="dense"
                variant="outlined"
                inputProps={{
                  style: { fontSize: sampleGridFontSize },
                }}
                InputLabelProps={{ shrink: true }}
                type="text"
                value={item?.flashPointInC ?? ""}
                disabled={!ownerCanEdit}
                onChange={(e) => {
                  const value = e.target.value;
                  if (isFlashPointValid(value)) {
                    updateAllSampleOccurance(
                      "flashPointInC",
                      value,
                      item.sampleName,
                      item.containerID
                    );
                  }
                }}
              />
            </StyledTableCell>
            {/* DOT Hazard */}
            <StyledTableCell align="left">
              <Autocomplete
                disablePortal
                noOptionsText={
                  dotHazardClassLoading ? "Loading DOT Hazard Class..." : "No DOT Hazard Class Found"
                }
                options={dotHazardClass}
                onChange={(e, value) => updateAllSampleOccurance(
                    "dotHazardClass",
                    value?.classNumber,
                    item.sampleName,
                    item.containerID
                  )
                }
                getOptionLabel={(option) =>
                  option?.displayName
                    ? option.displayName
                    : dotHazardClass.length > 0
                      ? dotHazardClass.find((f) => `${f.classNumber}` === `${option}`)?.displayName
                      : ""
                }
                autoHighlight
                autoSelect
                value={item.dotHazardClass}
                isOptionEqualToValue={(option, value) =>
                  value?.classNumber === option?.classNumber
                }
                style={{ width: "100%" }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    size="small"
                    margin="dense"
                    inputProps={{
                      ...params.inputProps,
                      style: { fontSize: sampleGridFontSize },
                    }}
                    InputLabelProps={{ ...params.InputProps, shrink: true }}
                  />
                )}
                disabled={isCancelledOrCompleted}
              />
            </StyledTableCell>
            <StyledTableCell align="right" style={{ width: '120px' }}>
              <TextField
                style={{ width: "100%", marginTop: "5px", }}
                disabled={!ownerCanEdit}

                size="small"
                margin="dense"
                variant="outlined"
                inputProps={{
                  style: { fontSize: sampleGridFontSize },
                }}
                InputLabelProps={{ shrink: true }}
                value={item.exportValue ?? ""}
                onChange={(e) => {
                  updateAllSampleOccurance(
                    "exportValue",
                    e.target.value,
                    item.sampleName,
                    item.containerID
                  );
                }}
              />
            </StyledTableCell>
            <StyledTableCell align="right">
              <TextField
                style={{ width: "100%", marginTop: "5px" }}
                disabled={!!item || !ownerCanEdit}
                size="small"
                margin="dense"
                variant="outlined"
                inputProps={{
                  style: { fontSize: sampleGridFontSize },
                }}
                InputLabelProps={{ shrink: true }}
                value={item.description ?? ""}
                onChange={(e) => {
                  updateAllSampleOccurance(
                    "description",
                    e.target.value,
                    item.sampleName,
                    item.containerID
                  );
                }}
              />
            </StyledTableCell>
          </StyledTableRow>
        );
      })}
    </UXDataTableWithoutBody>
  );
}
