import React from "react";
import { StockroomContent } from "./StockroomContent";

import { InventoryItemContextProvider } from './context/InventoryItemContext'
import { StockroomItemListContextProvider } from './context/StockroomItemListContext'
import { CategoryContextProvider } from './context/CategoryContext'
import { ImageContextProvider } from './context/ImageContext'
import { StockroomPOContextProvider } from './context/StockroomPOContext'
import { StockroomPOItemContextProvider } from './context/StockroomPOItemContext'
import { StockroomRequestContextProvider } from './context/StockroomRequestContext'
import { RequestItemContextProvider } from './context/RequestItemContext'
import { VendorContextProvider } from './context/VendorContext'
import { OrderStatusContextProvider } from './context/OrderStatusContext'
import { EquipmentStatusContextProvider } from './context/EquipmentStatusContext'
import { EquipmentTypeContextProvider } from './context/EquipmentTypeContext'

const Stockroom = () => {
  return (
    <StockroomItemListContextProvider>
      <InventoryItemContextProvider>
        <VendorContextProvider>
          <StockroomRequestContextProvider>
            <OrderStatusContextProvider>
              <EquipmentStatusContextProvider>
                <EquipmentTypeContextProvider>
                  <RequestItemContextProvider>
                    <CategoryContextProvider>
                      <StockroomPOContextProvider>
                        <StockroomPOItemContextProvider>
                          <ImageContextProvider>
                            <StockroomContent />
                          </ImageContextProvider>
                        </StockroomPOItemContextProvider>
                      </StockroomPOContextProvider>
                    </CategoryContextProvider>
                  </RequestItemContextProvider>
                </EquipmentTypeContextProvider>
              </EquipmentStatusContextProvider>
            </OrderStatusContextProvider>
          </StockroomRequestContextProvider>
        </VendorContextProvider>
      </InventoryItemContextProvider>
    </StockroomItemListContextProvider>
  );
};

export default Stockroom;