import React, { useContext } from "react";
import { Grid, Divider, List, ListItem, ListItemText, Typography } from "@mui/material";
import "./index.css";
//import HomeIcon from "@mui/icons-material/Home";
import WorkRequestsTable from "./WorkRequestsTable";
import MySamplesTable from "./MySamplesTable";
import RecentTestResultsTable from "./RecentTestResultsTable";
import RecentlyUsedBlendStudiesTable from "./RecentlyUsedBlendStudiesTable";
//import TempWelcomeBanner from "./TempWelcomeBanner";

import PrefContext from "../../context/PrefContext";

function SetBannerImage() {
  let todayDate = new Date();
  let day = todayDate.getDate();
  let month = todayDate.getMonth();
  let rnd = Math.random();

  if (month === 9 && day === 31) {
    return "homebannerHalloween";
  } else if (month === 0 && day === 1) {
    return "homebannerNewYearsDay";
  } else if (month === 10 && day === 24) {
    return "homebannerThanksgiving";
  } else if (month === 2 && day === 14) {
    return "homebannerPiDay";
  } else if (month === 11 && day >= 20 && day <= 30) {
    return "homebannerChristmas";
  } else if (month === 2 && day === 17) {
    return "homebannerStPatricksDay";
  } else if (month === 11 && Math.random() > 0.8) {
    return "homebannerDecember";
  } else if (month === 0 && Math.random() > 0.8) {
    return "homebannerWinter1";
  } else if (month === 2 && Math.random() > 0.8) {
    return "homebannerEarlySpring";
  } else if (month === 10 && Math.random() > 0.8) {
    return "homebannerNovember";
  } else if (rnd < 0.05) {
    return "homebannerBabyG";
  } else if (rnd >= 0.05 && rnd < 0.1) {
    return "homebannerPunchCard";
  } else {
    return "homebanner";
  }
}

const Home = () => {
  const { userPreferences } = useContext(PrefContext);

  const renderWidgets = () => {
    let widgetArray = [];

    const returnCorrectComponent = (keyName) => {
      switch (keyName) {
        case "showRecentSamplesWidget":
          return (
            <Grid item xs={6}>
              <Typography className="homeTableHeader" variant="h6" component="div">
                My Recent Samples
              </Typography>
              <MySamplesTable />
            </Grid>
          );
        case "showRecentTestsWidget":
          return (
            <Grid item xs={6}>
              <Typography className="homeTableHeader" variant="h6" component="div">
                My Recent Test Submissions
              </Typography>
              <RecentTestResultsTable />
            </Grid>
          );
        case "showWorkRequestWidget":
          return (
            <Grid item xs={6}>
              <Typography className="homeTableHeader" variant="h6" component="div">
                My Recent Work Requests
              </Typography>
              <WorkRequestsTable />
            </Grid>
          );
        case "showRecentBlendsWidget":
          return (
            <Grid item xs={6}>
              <Typography className="homeTableHeader" variant="h6" component="div">
                My Recent Blend Studies
              </Typography>
              <RecentlyUsedBlendStudiesTable />
            </Grid>
          );
        default:
          break;
      }
    };

    Object.entries(userPreferences).forEach(([key, value]) => {
      if (key?.includes("Widget") && value) {
        const component = returnCorrectComponent(key);
        widgetArray.push(component);
      }
    });

    return (
      <Grid container spacing={3} className="homeTables">
        {widgetArray.map((widget, index) => {
          return <React.Fragment key={index}>{widget}</React.Fragment>;
        })}
      </Grid>
    );
  };

  return (
    <>
      <div>
        <span className="pageheader">Home</span>

        <Divider className="dividerbar" />

        <div>
          {"Welcome to MIDAS 3!  "}
          <a
            className="homepagehyperlinks"
            href="http://goto/MIDAStraining"
            target="_blank"
            rel="noopener noreferrer">
            Click here to view our tutorials.
          </a>

          {"  Have feedback?  "}
          <a
            className="homepagehyperlinks"
            href="https://emprod.service-now.com/itsp?id=sc_cat_item&sys_id=568fa9801b2778901cf976ae034bcbf3"
            target="_blank"
            rel="noopener noreferrer">
            Submit a ticket!
          </a>
        </div>
        <SpocListLink />
        {/* <TempWelcomeBanner></TempWelcomeBanner> */}
        <div className={SetBannerImage()}>
          <Grid container spacing={2} style={{ height: "13rem" }}>
            <Grid item xs={2} className="bannerlist">
              <div>
                <p className="bannerheader">Recent Items</p>
                <List dense={true}>
                  <ListItem>
                    <ListItemText className="listcontents" primary="Coming Soon!" />
                  </ListItem>

                  {/* {[0, 1, 2, 3, 4].map((keyNum) => (
                    <ListItem key={keyNum}>
                      <ListItemIcon>
                        <HomeIcon />
                      </ListItemIcon>
                      <ListItemText className='listcontents' primary='Single-line item' />
                    </ListItem>
                  ))} */}
                </List>
              </div>
            </Grid>
            <Grid item xs={2}>
              <div className="bannerlist">
                <p className="bannerheader">Top Bookmarks</p>
                <List dense={true}>
                  <ListItem>
                    <ListItemText className="listcontents" primary="Coming Soon!" />
                  </ListItem>
                  {/* {[0, 1, 2, 3, 4].map((keyNum) => (
                    <ListItem key={keyNum}>
                      <ListItemIcon>
                        <HomeIcon />
                      </ListItemIcon>
                      <ListItemText className='listcontents' primary='Single-line item' />
                    </ListItem>
                  ))} */}
                </List>
              </div>
            </Grid>
          </Grid>
        </div>

        {renderWidgets()}
      </div>
    </>
  );
};

const SpocListLink = () => {
  var link = (
    <a
      className="homepagehyperlinks"
      href="https://ishareteam7.na.xom.com/sites/Midas3Training/Training%20Documents/MIDAS%203%20SPOC%20Network.xlsx"
      target="_blank"
      rel="noopener noreferrer">
      SPOC List
    </a>
  );
  return <div>Please test with your SPOC first before submitting a ticket. {link}.</div>;
};

export default Home;
