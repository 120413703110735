const isAdfs = () => 
{
    let result = window.location.href.includes(process.env.REACT_APP_MIDAS_ADFS_FE_URL) || window.location.href.includes(process.env.REACT_APP_MIDAS_ADFS_FE_PROD_URL);
    if (window.localStorage.getItem("AUTH_PROVIDER") != null && window.localStorage.getItem("AUTH_PROVIDER") !== undefined)
    {
        if(result) {
            window.localStorage.setItem("AUTH_PROVIDER", "Adfs");
        }
        else {
            window.localStorage.setItem("AUTH_PROVIDER", "AzureAd");
        }
    }
    return result;
}

export { isAdfs };
 
