import React from "react";
import { Box, Divider} from "@mui/material";
import GeneralAdminMenu from "./GeneralAdminMenu";
import AnalyticalAdminMenu from "./AnalyticalAdminMenu";
import BlendingAdminMenu from "./BlendingAdminMenu";
import InventoryMasterDataManagement from "./InventoryMasterDataManagement";
import WorkRequestMasterData from "./WorkRequestMasterData";
import STMasterData from "./StationaryTesting/STMasterData";
import DistillationMasterData from "./DistillationMasterData";
import StockroomMasterData from "./StockroomMasterData";
import { GlobalTabCollection, StyledTab } from "../styles";

// import { hasRole, Roles } from "../../global";
// import UserContext from "../../context/UserContext";

const Admin = ({ ...props }) => {
  
// const currentUser = useContext(UserContext)
// const userRoles = currentUser?.idTokenClaims.roles;

// const canAccessGeneral = hasRole(Roles.MasterDataAdmin, userRoles) || hasRole(Roles.Developer, userRoles);
// const canAccessChemical = hasRole(Roles.MasterDataChemical, userRoles) || hasRole(Roles.Developer, userRoles);
// const canAccessAnalytical = hasRole(Roles.MasterDataAnalytical, userRoles) || hasRole(Roles.Developer, userRoles) || hasRole(Roles.ResultComponentAdministrator, userRoles) || hasRole(Roles.InstrumentAdministrator, userRoles);
// const canAccessBlending = hasRole(Roles.MasterDataFormulations, userRoles) || hasRole(Roles.Developer, userRoles);
// const canAccessWorkRequest = hasRole(Roles.MasterDataWorkRequest, userRoles) || hasRole(Roles.Developer, userRoles);
// const canAccessST = hasRole(Roles.MasterDataStationaryTesting, userRoles) || hasRole(Roles.Developer, userRoles);
// const canAccessDistillation = hasRole(Roles.MasterDataDistillation, userRoles) || hasRole(Roles.Developer, userRoles);
// const canAccessStockroom = hasRole(Roles.MasterDataStockroom, userRoles) || hasRole(Roles.Developer, userRoles);

const canAccessGeneral = true;
const canAccessChemical = true;
const canAccessAnalytical = true;
const canAccessBlending = true;
const canAccessWorkRequest = true;
const canAccessST = true;
const canAccessDistillation = true;
const canAccessStockroom = true;


  const [tabValue, setTabValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const renderSearchTab = () => {
    switch (tabValue) {
      case 0: {
        return <GeneralAdminMenu></GeneralAdminMenu>
      }
      case 1: {
        return <InventoryMasterDataManagement></InventoryMasterDataManagement>
      }
      case 2: {
        return <AnalyticalAdminMenu></AnalyticalAdminMenu>;
      }
      case 3: {
        return <BlendingAdminMenu></BlendingAdminMenu>;
      }
      case 4: {
        return <WorkRequestMasterData></WorkRequestMasterData>
      }
      case 5: {
        return <STMasterData></STMasterData>
      }
      case 6: {
        return <DistillationMasterData></DistillationMasterData>
      }
      case 7: {
        return <StockroomMasterData></StockroomMasterData>
      }
      default: {
        alert(tabValue);
      }
    }
  };

  return (
    <Box paddingBottom={4}>
      <span className='pageheader'>Site Administration</span>
      <Divider className='dividerbar' />
      <div>
        <Box sx={{ bgcolor: "#fff", pt:3, pb:1 }}>
          <GlobalTabCollection value={tabValue} onChange={handleChange} aria-label='ant example'>
            <StyledTab disabled={!canAccessGeneral} label='General Site Master Data' />
            <StyledTab disabled={!canAccessChemical} label='Chemical Inventory/Safety Master Data' />
            <StyledTab disabled={!canAccessAnalytical} label='Analytical Master Data' />
            <StyledTab disabled={!canAccessBlending} label='Blending Master Data' />
            <StyledTab disabled={!canAccessWorkRequest} label='Work Request Master Data' />
            <StyledTab disabled={!canAccessST} label='Stationary Testing Master Data'/>
            <StyledTab disabled={!canAccessDistillation} label='Distillations Master Data'/>
            <StyledTab disabled={!canAccessStockroom} label='Stockroom Master Data'/>
          </GlobalTabCollection>
          <Box sx={{ p: 3 }} />
        </Box>
        {renderSearchTab()}
      </div>
    </Box>
  );
};

export default Admin;
