import React from "react";
import CustomModal from './index';
import { GlobalButton } from "../../pages/styles";

const ModalSimpleButton = ({ children, title, buttonText, buttonAction, open, setOpen }) => {

    return (
        <>
            <CustomModal title={title} open={open} setOpen={setOpen}>
                {children ? children : <></>}


                <div style={{
                    display: "flex",
                    justifyContent: "center",
                }}>
                    <GlobalButton
                        style={{
                            marginTop: "35px",
                            marginLeft: "6px",
                        }}
                        variant='contained'
                        onClick={buttonAction}
                    >{buttonText}</GlobalButton>

                </div>

            </CustomModal>
        </>
    );
};

export default ModalSimpleButton;
