import { TextField, Box, Typography, CircularProgress, Autocomplete, FormGroup, FormControlLabel, Switch, Menu, MenuItem} from "@mui/material";
import { EvaluateOptionalNumberField, formatMidasNumber, EvaluateOptionalIntegerField, EvaluateRequiredNumberField, OrderTemplateCutList} from "../../../global";
import { GlobalButton,  GlobalTabCollection, StyledTab } from "../../styles";
import MethodSelection from "../../../components/MethodSelection";
import { useState } from "react";
import ContainerTable from "../../../components/ContainerTable";
import MoreOptions from "@mui/icons-material/MoreVert";
import ModalTwoButtons from "../../../components/Modal/ModalTwoButtons";
import SettingsIcon from '@mui/icons-material/Settings';


const wholeNumberMsg = "Only Whole Numbers"
const numberMsg = "Only Numbers"

const ExtractionDistillation = ({myDistillationExp, cutErrors, handleCutRowChange, updateCutErrors, handleExperimentChange, 
     experimentErrors, updateExperimentErrors, experimentHasStarted, StartExperiment, experimentIsStarting, allLocations, ValidateInitialAmount, actualAmountMessage,
     testingContainers, setTestingContainers, testingContainerErrors, setTestingContainerErrors, experimentResultsEntered, fnSaveResults, resultsAreSaving, 
     fnSubmitTesting, testsAreSaving, experimentIsClosed, containerTypes, updateCutStatus, cutTypes}) => {
    
     const [switchShowTests, setSwitchShowTests] = useState(false)
     
     const [tabValueCuts, setTabValueCuts] = useState(myDistillationExp.experimentCuts.find(d => d.id > 0) && cutTypes ? OrderTemplateCutList(myDistillationExp.experimentCuts, cutTypes).find(d => d.id > 0).id : null)
     
     const lockResults = experimentResultsEntered || !experimentHasStarted
     const allowContainering = (experimentResultsEntered === true && experimentHasStarted === true && experimentIsClosed === false)

     const [anchorEl, setAnchorEl] = useState(null)
     const [workingCutID, setWorkingCutID] = useState(null)

     const [modalCancelCutOpen, setModalCancelCutOpen] = useState(false);
     const [modalCancelCutTitle, setModalCancelCutTitle] = useState("");
     const [modalCancelCutText, setModalCancelCutText] = useState("");
     const [modalCancelCutButton1Text, setModalCancelCutButton1Text] = useState("");
     const [modalCancelCutButton2Text, setModalCancelCutButton2Text] = useState("");

     const openMenu = () => {
          return anchorEl != null
     }

     const handleOpenMoreOptions = (event) => {
          setAnchorEl(event.currentTarget)
     }
      
     const handleCloseMenu = () => {
          setAnchorEl(null)
     }

     const ConfirmRemoveItem = () => {
          if (workingCutID) {
            openModalCancelCut(`Cancel Cut `, "Are you sure you want to cancel the cut?", "Yes", "No");
          }
     };

     function closeModalCancelCut() {
          setModalCancelCutOpen(false);
     }
      
     function openModalCancelCut(title, text, button1, button2, Cut) {
          setModalCancelCutOpen(true);
          setModalCancelCutTitle(title);
          setModalCancelCutText(text);
          setModalCancelCutButton1Text(button1);
          setModalCancelCutButton2Text(button2);     
     }

     const handleTabChangeCuts = (event, newValue) => {
          setTabValueCuts(newValue)
     };

     function PopulateMIDASNumber(cut) {
          return (cut.thisContainer ? `${formatMidasNumber(cut.thisContainer?.sampleName)}/${cut.thisContainer?.containerNumber}` : '')
     }

     const GetLabelDisplay = () => {
          if (lockResults) {
               if (myDistillationExp?.experimentCuts?.find(obj => obj.id === tabValueCuts).testTemplateJSON ? JSON.parse(myDistillationExp?.experimentCuts?.find(obj => obj.id === tabValueCuts).testTemplateJSON).methods : [] > 0)
               {
                    return "Show Tests"
               } else {
                    return "No Methods"
               }
     
          } else {
               return ("Enter and submit results to generate containering")
          }
     }

     const updateContainers = (newContainer) =>  {
          const index = testingContainers.findIndex(obj => obj.cutID === tabValueCuts)
          const copyContainerInfo = structuredClone(testingContainers)
     
          if (index >= 0)
          {
               copyContainerInfo[index].containers = structuredClone(newContainer)
               setTestingContainers(copyContainerInfo)
          }
               
     }
     
     const updateContainersErrors = (newErrors) =>  {
          const index = testingContainerErrors.findIndex(obj => obj.cutID === tabValueCuts)
          const copyContainerErrorInfo = structuredClone(testingContainerErrors)
     
          if (index >= 0)
          {
               copyContainerErrorInfo[index].errors = structuredClone(newErrors)
               setTestingContainerErrors(copyContainerErrorInfo)
          }
     }

     function ChangeStatus () {
          updateCutStatus(workingCutID)
          closeModalCancelCut()
          setWorkingCutID(null)
     }

     function IsStartingCut ()
     {
          return myDistillationExp.experimentCuts.find(cut => cut.id === tabValueCuts).thisDistillationCutType.isStartingCut === true 
     }

     function IsEndingCut ()
     {
          return myDistillationExp.experimentCuts.find(cut => cut.id === tabValueCuts).thisDistillationCutType.isEndingCut === true 
     }

     function IsCutFailed () 
     {
          return myDistillationExp.experimentCuts.find(cut => cut.id === tabValueCuts).distillationCutStatusName === 'Failed'       
     }

return (
     <div>

     <div style={{marginTop:"2rem"}}>
          {experimentIsStarting ?
          (
          <>
               <CircularProgress style={{textTransform: "none"}}></CircularProgress>
               <GlobalButton  
                    disabled
                    name="btnStartExp"
                    variant='contained'
                    style={{ textTransform: "none",  marginBottom:"25px", marginLeft:"10px"}}
               >{"Start Experiment"}</GlobalButton>
          </>
          ):(
          <GlobalButton  
               disabled={experimentHasStarted}
               name="btnStartExp"
               variant='contained'
               style={{ textTransform: "none", marginBottom:"25px"}}
               onClick={(e) => StartExperiment()}
          >{"Start Experiment"}</GlobalButton>
          )
          }

          {/* <GlobalSecondaryButton  
               disabled
               name="btnSaveTemplate"
               variant='contained'
               style={{ textTransform: "none", marginBottom:"25px", marginLeft:"25px"}}
               //onClick={(e) => templateButtonText === templateButtonUpdateText ? SaveTemplate(e) : openModalTemplate(templateModalTitle, "Enter a name for your new template", templateButton1Text, buttonCancelText)}
          >{"Print Run Sheet"}</GlobalSecondaryButton> */}
     </div>

     <Box display="flex" gap={3} alignItems="center" marginTop={"2rem"} width="50%">
          <Typography fontSize={16}>
               Requested Initial Amount: {myDistillationExp.initialSampleAmount?.toLocaleString()} {myDistillationExp.initialSampleAmountUoM}
          </Typography>


          <TextField size="small" margin="normal" variant="outlined" label="Actual Initial Amount (g)" InputLabelProps={{shrink: true}}
               disabled = {experimentHasStarted}   
               onChange={(e) => handleExperimentChange('actualInitialSampleAmount', e.target.value)}
               value={myDistillationExp.actualInitialSampleAmount === null ? '' : myDistillationExp.actualInitialSampleAmount.toLocaleString()}
               error={experimentErrors.actualInitialSampleAmount}
               onBlur={e => {updateExperimentErrors('actualInitialSampleAmount', ValidateInitialAmount())}}
               helperText={experimentErrors.actualInitialSampleAmount ? actualAmountMessage : ""}
          ></TextField> 
          
          
          <Autocomplete
               disabled={experimentHasStarted}                                
               disablePortal
               noOptionsText={"Loading Locations..."}
               options={allLocations ? allLocations : []}
               getOptionLabel={(option) => option.locationName}
               onChange={(e, value) => handleExperimentChange('experimentLocation', value)}
               isOptionEqualToValue={(option, value) => value.locationName === option.locationName}
               autoHighlight
               autoSelect
               value={myDistillationExp.experimentLocation}
               renderInput={(params) => <TextField {...params} style={{width:"200px"}} variant="outlined" size="small" margin="normal" label="Experiment Location"
               error = {experimentErrors.experimentLocation} InputLabelProps={{shrink: true}} inputProps={{ ...params.inputProps,  style: { fontSize: 16 } }} InputProps={{ ...params.InputProps }}/>}        
          />            
     </Box>

     <div style={{display:"flex", marginTop:"5px"}}>
          <p style={{
          fontFamily:"EMprint",
          fontWeight:"600",
          fontSize:"18px",
          color:"#545459",
          textTransform: 'none',
          textAlign:"left",
          paddingTop:"20px",
          marginBottom:"1rem"
          }}>Extraction Cuts</p>
     </div>

     <div style={{marginTop:"1.5rem"}}>
          <GlobalTabCollection style={{width: myDistillationExp?.experimentCuts?.length > 7 ? "100%" :  `${myDistillationExp?.experimentCuts?.length * 14}%`}} 
               value={tabValueCuts} onChange={handleTabChangeCuts} variant={"scrollable"} scrollButtons="auto" aria-label='ant example'>
               {cutTypes && OrderTemplateCutList(myDistillationExp?.experimentCuts, cutTypes).map((oCut, index) => {
                    return(
                         <StyledTab 
                         key={index}
                         value={oCut.id}
                         label={`${oCut.distillationCutTypeName} ${oCut.id}`} 
                         />
                    )
               })}
               
          </GlobalTabCollection>

          <Box display="flex" gap={3} alignItems="center" marginTop={"1rem"} width="100%">

               {/* {(!IsStartingCut() && !IsEndingCut() && myDistillationExp.experimentCuts.find(cut => cut.id === tabValueCuts).distillationCutStatusName === 'InProgress') && */}
                    <SettingsIcon aria-label="more options" 
                         disabled = {(IsStartingCut() || IsEndingCut() || myDistillationExp.experimentCuts.find(cut => cut.id === tabValueCuts).distillationCutStatusName !== 'InProgress')}
                         onClick={(e) => {handleOpenMoreOptions(e); setWorkingCutID(tabValueCuts)}}
                         >
                         <MoreOptions color='black'/>
                    </SettingsIcon>
               {/* } */}
               <TextField size="small" margin="normal" variant="outlined" label="Cut Status" InputLabelProps={{shrink: true}}
                    disabled={true}
                    value={myDistillationExp.experimentCuts.find(cut => cut.id === tabValueCuts) ? myDistillationExp.experimentCuts.find(cut => cut.id === tabValueCuts).distillationCutStatusName : ''}
               ></TextField> 

               <TextField size="small" margin="normal" variant="outlined" label="MIDAS #" InputLabelProps={{shrink: true}}
                    disabled={true}
                    value={myDistillationExp.experimentCuts.find(cut => cut.id === tabValueCuts) ? PopulateMIDASNumber(myDistillationExp.experimentCuts.find(cut => cut.id === tabValueCuts)) : ''}
               ></TextField> 

               <Autocomplete
                    disabled = {lockResults || IsCutFailed() || IsStartingCut()}
                    noOptionsText={"Loading..."}
                    options={containerTypes}
                    getOptionLabel={(option) => option.name}
                    onChange={(e, value) => handleCutRowChange('containerType', value, tabValueCuts)}
                    autoHighlight
                    autoSelect
                    value={myDistillationExp.experimentCuts.find(cut => cut.id === tabValueCuts).thisContainer ? myDistillationExp.experimentCuts.find(cut => cut.id === tabValueCuts).thisContainer.containerType : null}
                    isOptionEqualToValue={(option, value) => value.name === option.name}
                    style={{width:"12%"}}
                    renderInput={(params) => <TextField {...params} margin="normal" variant="outlined" size="small" label="Container Type" inputProps={{ ...params.inputProps}} InputProps={{ ...params.InputProps }}/>} 
               />

               {!IsStartingCut() && !IsCutFailed() ? (
                    <TextField size="small" margin="normal" variant="outlined" label="Net Wt (g)*" InputLabelProps={{shrink: true}}
                         disabled={lockResults}
                         value = {myDistillationExp.experimentCuts.find(cut => cut.id === tabValueCuts).distillationCutYieldInG ? myDistillationExp.experimentCuts.find(cut => cut.id === tabValueCuts).distillationCutYieldInG.toLocaleString() : ''}
                         error = {cutErrors[cutErrors.findIndex((cut => cut.id === tabValueCuts))].distillationCutYieldInG} 
                         onChange={(e) => handleCutRowChange('distillationCutYieldInG', (e.target.value), tabValueCuts)}
                         onBlur={e => {updateCutErrors('distillationCutYieldInG', EvaluateRequiredNumberField(myDistillationExp.experimentCuts.find(cut => cut.id === tabValueCuts).distillationCutYieldInG),tabValueCuts)}}
                         helperText={cutErrors[cutErrors.findIndex(cut => cut.id === tabValueCuts)].distillationCutYieldInG ? numberMsg : ""}
                         />
                    ) : (
                    <TextField size="small" margin="normal" variant="outlined" label="Net Wt (g)*" InputLabelProps={{shrink: true}}
                         disabled = {true}
                         value={myDistillationExp.experimentCuts.find(cut => cut.id === tabValueCuts).distillationCutYieldInG === null ? '' : myDistillationExp.experimentCuts.find(cut => cut.id === tabValueCuts).distillationCutYieldInG.toLocaleString()}
                    ></TextField> 
                    )
               }

               <TextField size="small" margin="normal" variant="outlined" label="Solvent Used" InputLabelProps={{shrink: true}}
                    disabled = {lockResults || IsStartingCut() || IsCutFailed()}
                    value={myDistillationExp.experimentCuts.find(cut => cut.id === tabValueCuts) ? myDistillationExp.experimentCuts.find(cut => cut.id === tabValueCuts).solventUsed : ''}
                    onChange={(e) => handleCutRowChange('solventUsed', (e.target.value), tabValueCuts)}
               ></TextField> 
          </Box>

          <Box display="flex" gap={3} alignItems="center" marginTop={"1rem"} width="100%">
               <TextField size="small" margin="normal" variant="outlined" label="Visc Grade" InputLabelProps={{shrink: true}}
                    disabled = {lockResults || IsStartingCut() || IsCutFailed()}
                    value={myDistillationExp.experimentCuts.find(cut => cut.id === tabValueCuts) ? myDistillationExp.experimentCuts.find(cut => cut.id === tabValueCuts).viscosityGrade : ''}
                    onChange={(e) => handleCutRowChange('viscosityGrade', (e.target.value), tabValueCuts)}
               ></TextField> 

               <TextField size="small" margin="normal" variant="outlined" label="Treat Ratio" InputLabelProps={{shrink: true}}
                    disabled = {lockResults || IsStartingCut() || IsCutFailed()}
                    value={myDistillationExp.experimentCuts.find(cut => cut.id === tabValueCuts) ? myDistillationExp.experimentCuts.find(cut => cut.id === tabValueCuts).treatRatio : ''}
                    onChange={(e) => handleCutRowChange('treatRatio', (e.target.value), tabValueCuts)}
               ></TextField> 

               <TextField size="small" margin="normal" variant="outlined" label="Density Yield (%)" InputLabelProps={{shrink: true}}
                    disabled = {lockResults || IsStartingCut() || IsCutFailed()}
                    value={myDistillationExp.experimentCuts.find(cut => cut.id === tabValueCuts) ? myDistillationExp.experimentCuts.find(cut => cut.id === tabValueCuts).densityYieldPercent : ''}
                    onChange={(e) => handleCutRowChange('densityYieldPercent', (e.target.value), tabValueCuts)}
                    error = {cutErrors[cutErrors.findIndex((cut => cut.id === tabValueCuts))].densityYieldPercent}  
                    onBlur={e => {updateCutErrors('densityYieldPercent', EvaluateOptionalNumberField(myDistillationExp.experimentCuts.find(cut => cut.id === tabValueCuts).densityYieldPercent), tabValueCuts)}}
                    helperText={cutErrors[cutErrors.findIndex(cut => cut.id === tabValueCuts)].densityYieldPercent ? numberMsg : ""}
               ></TextField> 

               <TextField size="small" margin="normal" variant="outlined" label="RI Yield (%)" InputLabelProps={{shrink: true}}
                    disabled = {lockResults || IsStartingCut() || IsCutFailed()}
                    value={myDistillationExp.experimentCuts.find(cut => cut.id === tabValueCuts) ? myDistillationExp.experimentCuts.find(cut => cut.id === tabValueCuts).riYieldPercent : ''}
                    onChange={(e) => handleCutRowChange('riYieldPercent', (e.target.value), tabValueCuts)}
                    error = {cutErrors[cutErrors.findIndex((cut => cut.id === tabValueCuts))].riYieldPercent}  
                    onBlur={e => {updateCutErrors('riYieldPercent', EvaluateOptionalNumberField(myDistillationExp.experimentCuts.find(cut => cut.id === tabValueCuts).riYieldPercent), tabValueCuts)}}
                    helperText={cutErrors[cutErrors.findIndex(cut => cut.id === tabValueCuts)].riYieldPercent ? numberMsg : ""}
               ></TextField> 

               <TextField size="small" margin="normal" variant="outlined" label="Avg. Yield (%)" InputLabelProps={{shrink: true}}
                    disabled = {lockResults || IsStartingCut() || IsCutFailed()}
                    value={myDistillationExp.experimentCuts.find(cut => cut.id === tabValueCuts) ? myDistillationExp.experimentCuts.find(cut => cut.id === tabValueCuts).averageYieldPercent : ''}
                    onChange={(e) => handleCutRowChange('averageYieldPercent', (e.target.value), tabValueCuts)}
                    error = {cutErrors[cutErrors.findIndex((cut => cut.id === tabValueCuts))].averageYieldPercent}  
                    onBlur={e => {updateCutErrors('averageYieldPercent', EvaluateOptionalNumberField(myDistillationExp.experimentCuts.find(cut => cut.id === tabValueCuts).averageYieldPercent), tabValueCuts)}}
                    helperText={cutErrors[cutErrors.findIndex(cut => cut.id === tabValueCuts)].averageYieldPercent ? numberMsg : ""}
               ></TextField> 
          </Box>

          
          <Box display="flex" gap={3} alignItems="center" marginTop={"1rem"} width="100%">
               <TextField size="small" margin="normal" variant="outlined" label="Carry Under Yield (%)" InputLabelProps={{shrink: true}}
                    disabled = {lockResults || IsStartingCut() || IsCutFailed()}
                    value={myDistillationExp.experimentCuts.find(cut => cut.id === tabValueCuts) ? myDistillationExp.experimentCuts.find(cut => cut.id === tabValueCuts).carryUnderYieldPercent : ''}
                    onChange={(e) => handleCutRowChange('carryUnderYieldPercent', (e.target.value), tabValueCuts)}
                    error = {cutErrors[cutErrors.findIndex((cut => cut.id === tabValueCuts))].carryUnderYieldPercent}  
                    onBlur={e => {updateCutErrors('carryUnderYieldPercent', EvaluateOptionalNumberField(myDistillationExp.experimentCuts.find(cut => cut.id === tabValueCuts).carryUnderYieldPercent), tabValueCuts)}}
                    helperText={cutErrors[cutErrors.findIndex(cut => cut.id === tabValueCuts)].carryUnderYieldPercent ? numberMsg : ""}
               ></TextField> 

               <TextField size="small" margin="normal" variant="outlined" label="# of Stages" InputLabelProps={{shrink: true}}
                    disabled = {lockResults || IsStartingCut() || IsCutFailed()}
                    value={myDistillationExp.experimentCuts.find(cut => cut.id === tabValueCuts) ? myDistillationExp.experimentCuts.find(cut => cut.id === tabValueCuts).numberExtractionStages : ''}
                    onChange={(e) => handleCutRowChange('numberExtractionStages', (e.target.value), tabValueCuts)}
                    error = {cutErrors[cutErrors.findIndex((cut => cut.id === tabValueCuts))].numberExtractionStages}  
                    onBlur={e => {updateCutErrors('numberExtractionStages', EvaluateOptionalIntegerField(myDistillationExp.experimentCuts.find(cut => cut.id === tabValueCuts).numberExtractionStages), tabValueCuts)}}
                    helperText={cutErrors[cutErrors.findIndex(cut => cut.id === tabValueCuts)].numberExtractionStages ? wholeNumberMsg : ""}
               ></TextField> 

               <TextField size="small" margin="normal" variant="outlined" label="Solvent Weight (%)" InputLabelProps={{shrink: true}}
                    disabled = {lockResults || IsStartingCut() || IsCutFailed()}
                    value={myDistillationExp.experimentCuts.find(cut => cut.id === tabValueCuts) ? myDistillationExp.experimentCuts.find(cut => cut.id === tabValueCuts).extractSolventWeightPercent : ''}
                    onChange={(e) => handleCutRowChange('extractSolventWeightPercent', (e.target.value), tabValueCuts)}
                    error = {cutErrors[cutErrors.findIndex((cut => cut.id === tabValueCuts))].extractSolventWeightPercent}  
                    onBlur={e => {updateCutErrors('extractSolventWeightPercent', EvaluateOptionalNumberField(myDistillationExp.experimentCuts.find(cut => cut.id === tabValueCuts).extractSolventWeightPercent), tabValueCuts)}}
                    helperText={cutErrors[cutErrors.findIndex(cut => cut.id === tabValueCuts)].extractSolventWeightPercent ? numberMsg : ""}
               ></TextField> 

               <TextField size="small" margin="normal" variant="outlined" label="Raffinate Solvent Weight (%)" InputLabelProps={{shrink: true}}
                    disabled = {lockResults || IsStartingCut() || IsCutFailed()}
                    value={myDistillationExp.experimentCuts.find(cut => cut.id === tabValueCuts) ? myDistillationExp.experimentCuts.find(cut => cut.id === tabValueCuts).raffinateSolventWeightPercent : ''}
                    onChange={(e) => handleCutRowChange('raffinateSolventWeightPercent', (e.target.value), tabValueCuts)}
                    error = {cutErrors[cutErrors.findIndex((cut => cut.id === tabValueCuts))].raffinateSolventWeightPercent}  
                    onBlur={e => {updateCutErrors('raffinateSolventWeightPercent', EvaluateOptionalNumberField(myDistillationExp.experimentCuts.find(cut => cut.id === tabValueCuts).raffinateSolventWeightPercent), tabValueCuts)}}
                    helperText={cutErrors[cutErrors.findIndex(cut => cut.id === tabValueCuts)].raffinateSolventWeightPercent ? numberMsg : ""}
               ></TextField> 

               <TextField size="small" margin="normal" variant="outlined" label="Solvent Water Weight (%)" InputLabelProps={{shrink: true}}
                    disabled = {lockResults || IsStartingCut() || IsCutFailed()}
                    value={myDistillationExp.experimentCuts.find(cut => cut.id === tabValueCuts) ? myDistillationExp.experimentCuts.find(cut => cut.id === tabValueCuts).solventWaterWeightPercent : ''}
                    onChange={(e) => handleCutRowChange('solventWaterWeightPercent', (e.target.value), tabValueCuts)}
                    error = {cutErrors[cutErrors.findIndex((cut => cut.id === tabValueCuts))].solventWaterWeightPercent}  
                    onBlur={e => {updateCutErrors('solventWaterWeightPercent', EvaluateOptionalNumberField(myDistillationExp.experimentCuts.find(cut => cut.id === tabValueCuts).solventWaterWeightPercent), tabValueCuts)}}
                    helperText={cutErrors[cutErrors.findIndex(cut => cut.id === tabValueCuts)].solventWaterWeightPercent ? numberMsg : ""}
               ></TextField> 
          </Box>

          <Box display="flex" gap={3} alignItems="center" marginTop={"1rem"} width="100%">
               <TextField size="small" margin="normal" variant="outlined" label="Tower Bottom Temp (C)" InputLabelProps={{shrink: true}}
                    disabled = {lockResults || IsStartingCut() || IsCutFailed()}
                    value={myDistillationExp.experimentCuts.find(cut => cut.id === tabValueCuts) ? myDistillationExp.experimentCuts.find(cut => cut.id === tabValueCuts).towerBottomTemperatureC : ''}
                    onChange={(e) => handleCutRowChange('towerBottomTemperatureC', (e.target.value), tabValueCuts)}
                    error = {cutErrors[cutErrors.findIndex((cut => cut.id === tabValueCuts))].towerBottomTemperatureC}  
                    onBlur={e => {updateCutErrors('towerBottomTemperatureC', EvaluateOptionalNumberField(myDistillationExp.experimentCuts.find(cut => cut.id === tabValueCuts).towerBottomTemperatureC), tabValueCuts)}}
                    helperText={cutErrors[cutErrors.findIndex(cut => cut.id === tabValueCuts)].towerBottomTemperatureC ? numberMsg : ""}
               ></TextField> 

               <TextField size="small" margin="normal" variant="outlined" label="Tower Top Temp (C)" InputLabelProps={{shrink: true}}
                    disabled = {lockResults || IsStartingCut() || IsCutFailed()}
                    value={myDistillationExp.experimentCuts.find(cut => cut.id === tabValueCuts) ? myDistillationExp.experimentCuts.find(cut => cut.id === tabValueCuts).towerTopTemperatureC : ''}
                    onChange={(e) => handleCutRowChange('towerTopTemperatureC', (e.target.value), tabValueCuts)}
                    error = {cutErrors[cutErrors.findIndex((cut => cut.id === tabValueCuts))].towerTopTemperatureC}  
                    onBlur={e => {updateCutErrors('towerTopTemperatureC', EvaluateOptionalNumberField(myDistillationExp.experimentCuts.find(cut => cut.id === tabValueCuts).towerTopTemperatureC), tabValueCuts)}}
                    helperText={cutErrors[cutErrors.findIndex(cut => cut.id === tabValueCuts)].towerTopTemperatureC ? numberMsg : ""}
               ></TextField> 

               <TextField size="small" margin="normal" variant="outlined" label="Dewaxed (%)" InputLabelProps={{shrink: true}}
                    disabled = {lockResults || IsStartingCut() || IsCutFailed()}
                    value={myDistillationExp.experimentCuts.find(cut => cut.id === tabValueCuts) ? myDistillationExp.experimentCuts.find(cut => cut.id === tabValueCuts).dewaxedPercent : ''}
                    onChange={(e) => handleCutRowChange('dewaxedPercent', (e.target.value), tabValueCuts)}
                    error = {cutErrors[cutErrors.findIndex((cut => cut.id === tabValueCuts))].dewaxedPercent}  
                    onBlur={e => {updateCutErrors('dewaxedPercent', EvaluateOptionalNumberField(myDistillationExp.experimentCuts.find(cut => cut.id === tabValueCuts).dewaxedPercent), tabValueCuts)}}
                    helperText={cutErrors[cutErrors.findIndex(cut => cut.id === tabValueCuts)].dewaxedPercent ? numberMsg : ""}
               ></TextField> 

               <TextField size="small" margin="normal" variant="outlined" label="Raffinate (%)" InputLabelProps={{shrink: true}}
                    disabled = {lockResults || IsStartingCut() || IsCutFailed()}
                    value={myDistillationExp.experimentCuts.find(cut => cut.id === tabValueCuts) ? myDistillationExp.experimentCuts.find(cut => cut.id === tabValueCuts).raffinatePercent : ''}
                    onChange={(e) => handleCutRowChange('raffinatePercent', (e.target.value), tabValueCuts)}
                    error = {cutErrors[cutErrors.findIndex((cut => cut.id === tabValueCuts))].raffinatePercent}  
                    onBlur={e => {updateCutErrors('raffinatePercent', EvaluateOptionalNumberField(myDistillationExp.experimentCuts.find(cut => cut.id === tabValueCuts).raffinatePercent), tabValueCuts)}}
                    helperText={cutErrors[cutErrors.findIndex(cut => cut.id === tabValueCuts)].raffinatePercent ? numberMsg : ""}
               ></TextField>                
          </Box>

          <Box display="flex" gap={3} alignItems="center" marginTop={"1rem"} width="100%">
               <TextField size="small" margin="normal" variant="outlined" label="Unit Name*" InputLabelProps={{shrink: true}}
                    disabled={lockResults}
                    inputProps={{ maxLength: 20 }}
                    onChange={(e) => handleExperimentChange('extractionUnit', e.target.value)}
                    value={myDistillationExp.extractionUnit === null ? '' : myDistillationExp.extractionUnit}
                    error={experimentErrors.extractionUnit}
                    onBlur={e => {updateExperimentErrors('extractionUnit', myDistillationExp.extractionUnit === null || myDistillationExp.extractionUnit === '')}}
                    helperText={experimentErrors.extractionUnit ? "Required" : ""}
               ></TextField> 

               <TextField style={{width:"45rem"}} multiline  size="small" margin="normal" variant="outlined" label="Comments" InputLabelProps={{shrink: true}}
                    disabled = {lockResults}
                    onChange={(e) => handleExperimentChange('comments', e.target.value)}
                    value={myDistillationExp.comments === null ? '' : myDistillationExp.comments}
               ></TextField>
          </Box>
     </div>

         

     {resultsAreSaving ?
          ( 
               <div style={{marginTop:"2rem"}}>
                    <CircularProgress style={{textTransform: "none"}}></CircularProgress>
                    <GlobalButton
                         disabled
                         name="btnSubmit"
                         variant='contained'
                         style={{marginBottom:"3rem", marginLeft:"10px"}}
                    >
                         {'Submit Results'}
                    </GlobalButton>
               </div>
          ) : (
               <div style={{marginTop:"2rem"}}>
                    <GlobalButton
                         disabled={lockResults}
                         name="btnSubmit"
                         variant='contained'
                         style={{marginBottom:"3rem"}}
                         onClick={(e) => fnSaveResults()}
                    >
                         {'Submit Results'}
                    </GlobalButton>
               </div>          
          )
     }

     {experimentIsClosed === false &&
     <>
          {allowContainering && !IsStartingCut() && !IsCutFailed() &&

          
               <div style={{marginTop:"1rem"}}>

               <div>
                    <p style={{
                    fontFamily:"EMprint",
                    fontWeight:"600",
                    fontSize:"16px",
                    color:"#545459",
                    textTransform: 'none',
                    textAlign:"left",
                    paddingTop:"10px"
                    }}>{`Testing/Containering`}</p>

               </div>

                    <FormGroup style={{marginLeft: "15px"}}>
                         <FormControlLabel control={<Switch />} 
                              checked={switchShowTests}
                              onChange={e => setSwitchShowTests(e.target.checked)}
                              label = {GetLabelDisplay()}
                              disabled = {allowContainering === false}
                         />
                    </FormGroup>

               {switchShowTests &&
                    <MethodSelection 
                         width={'100%'} 
                         selectedMethods={myDistillationExp?.experimentCuts?.find(obj => obj.id === tabValueCuts).testTemplateJSON ? JSON.parse(myDistillationExp?.experimentCuts?.find(obj => obj.id === tabValueCuts).testTemplateJSON).methods : []} 
                         setSelectedMethods={null} 
                         showCompletionDate={true} 
                         showContainerGrouping={false}
                         incomingTemplate={null}
                         isReadOnly={true}
                         showSearchTemplateName={false}
                         showEstimateAndPriority={true}
                         maxMethodsAllowed={999}
                    >
                    </MethodSelection>
               }

                    <div style={{marginTop: "1rem"}}>
                         {myDistillationExp.experimentCuts?.find(obj => obj.id === tabValueCuts).testTemplateJSON !== null && testingContainers.find(obj => obj.cutID === tabValueCuts) === undefined &&
                              <div style={{display:"flex"}}>
                                   <p style={{
                                        fontFamily:"EMprint",
                                        fontWeight:"600",
                                        fontSize:"16px",
                                        color:"#545459",
                                        textTransform: 'none',
                                        textAlign:"left",
                                        paddingTop:"10px"
                                        }}>{'Generating Testing Containers'}</p>
                                   <CircularProgress style={{textTransform: "none"}}></CircularProgress>
                              </div>
                         }

                    <ContainerTable
                         width = {'100%'}
                         showParentContainer = {false}
                         showTests = {true}
                         containers={testingContainers.find(obj => obj.cutID === tabValueCuts) ? testingContainers.find(obj => obj.cutID === tabValueCuts).containers : []} 
                         setContainers = {updateContainers}
                         containerErrorChecks={testingContainerErrors.find(obj => obj.cutID === tabValueCuts) ? testingContainerErrors.find(obj => obj.cutID === tabValueCuts).errors : []} 
                         setContainerErrorChecks = {updateContainersErrors}
                         enableAdditions = {false}
                         enableDeletions = {false} 
                         enableCopy = {false}
                         availableParentContainers = {null}
                         sampleObject = {myDistillationExp.experimentCuts.find(cut => cut.id === tabValueCuts) ? myDistillationExp.experimentCuts.find(cut => cut.id === tabValueCuts).thisContainer.sample : null}
                         substanceObject = {myDistillationExp.experimentCuts.find(cut => cut.id === tabValueCuts) ? myDistillationExp.experimentCuts.find(cut => cut.id === tabValueCuts).thisContainer.sample.substance : null}
                         lockContainerStatus={true}
                         newContainerDefaultOwner = {null}
                    >
                    </ContainerTable>
                    </div> 
               </div>   
          } 
    
          {testsAreSaving ?
               ( 
                    <div style={{marginTop:"2rem"}}>
                         <CircularProgress style={{textTransform: "none"}}></CircularProgress>
                         <GlobalButton
                              disabled
                              name="btnSubmit"
                              variant='contained'
                              style={{marginBottom:"3rem"}}
                         >
                              {'Submit Testing'}
                         </GlobalButton>
                    </div>
               ) : (
                    <div style={{marginTop:"2rem"}}>
                         <GlobalButton
                              disabled={!(allowContainering)}
                              name="btnSubmit"
                              variant='contained'
                              style={{marginBottom:"3rem"}}
                              onClick={(e) => fnSubmitTesting()}
                         >
                              {'Submit Testing'}
                         </GlobalButton>
                    </div>       
               )
          }

          <Menu
              anchorEl={anchorEl}
              open={openMenu()}
              onClose={(e) => handleCloseMenu(e)}>
              <MenuItem 
                onClick={() => {handleCloseMenu(); ConfirmRemoveItem()}}
              >Cancel Cut</MenuItem>
          </Menu>

          <ModalTwoButtons
               title={modalCancelCutTitle}
               button1Text={modalCancelCutButton1Text}
               button1Action={ChangeStatus}
               button2Text={modalCancelCutButton2Text}
               button2Action={closeModalCancelCut}
               open={modalCancelCutOpen}
               setOpen={setModalCancelCutOpen}
          >
          <div style={{ textAlign: "center" }}>
               <label>{modalCancelCutText}</label>
          </div>
          </ModalTwoButtons>
     </>
     }
     </div>
);   
};

  export default ExtractionDistillation;