import API from "..";

export default class BlendStudy {

  constructor({
    id,
    studyName,
    studyPurpose,
    createdDate,
    createdByEmail,
    lastModifiedDate,
    lastModifiedByEmail,
    projectID,
    project,
    ownerEmail,
    keywords,
    blendStudyStatusName,
    blendStudyStatus,
    blendStudyTypeName,
    blendStudyType,
    ppmReference,
    notes,
    blendRequests
}) 
    
  {
    this.id = id;
    this.studyName = studyName;
    this.studyPurpose = studyPurpose;
    this.createdDate = createdDate;
    this.createdByEmail = createdByEmail;
    this.lastModifiedDate = lastModifiedDate;
    this.lastModifiedByEmail = lastModifiedByEmail;
    this.projectID = projectID;
    this.project = project;
    this.ownerEmail = ownerEmail;
    this.keywords = keywords;
    this.blendStudyStatusName = blendStudyStatusName;
    this.blendStudyStatus = blendStudyStatus;
    this.blendStudyTypeName = blendStudyTypeName;
    this.blendStudyType = blendStudyType;
    this.ppmReference = ppmReference;
    this.notes = notes;
    this.blendRequests = blendRequests;
  }

  static async getAllBlendStudies() {
    const api = await API();
    const { data } = await api.get(`/blendStudy`);
    if (data.result) {
        return data.result.map((d) => new BlendStudy(d));
    }
    else {
        return [];
    }
  }

  static async quickSearch(searchCriteria) {
    const api = await API();
    const { data } = await api.get(`/blendStudy/search?searchCriteria=${searchCriteria}`);
    return data
  }

  static async getBlendStudyByID(blendStudyID, getGenericStudy) {
    const api = await API();
    const { data } = await api.get(`/blendStudy/${blendStudyID}?getGenericStudy=${getGenericStudy}`);
    return data
  }

  // static async GetBasicStudyByID(blendStudyID) {
  //   const api = await API();
  //   const { data } = await api.get(`/blendStudy/getBasicStudy/${blendStudyID}`);
  //   return data
  // }

  static async getBlendStudyByStudyName(studyName) {
    const api = await API();
    let tempStudyName = studyName.replace(/ /g, '%20')
    const { data } = await api.get(`/blendStudy/getByName?studyName=${tempStudyName}`);
    if (data.result) {
      return new BlendStudy(data.result);
    }
    else {
      return null;
    }
  }

  static async checkName(studyName) {
    const api = await API();
    let tempStudyName = studyName.replace(/ /g, '%20')
    const { data } = await api.get(`/blendStudy/checkName?studyName=${tempStudyName}`);
    return data;
    // if (data.result) {
    //   return new BlendStudy(data.result);
    // }
    // else {
    //   return null;
    // }
  }

  static async getMyBlendStudies(ownerEmail) {
    const api = await API();
    const { data } = await api.get(`/blendStudy/getByOwner?ownerEmail=${ownerEmail}`);
    if (data.result) {
        return data.result.map((d) => new BlendStudy(d));
    }
    else {
      return [];
    }
  }

  static async updateBlendStudyOwner(studyID, userName) {
    const api = await API();
    const { data } = await api.put(`/blendStudy/updateBlendStudyOwner?studyID=${studyID}&userName=${userName}`);
    return data;
  }

  static async CreateBlendStudy(oBlendStudy) {
    const api = await API('multipart/form-data');
    const { data } = await api.post('/blendStudy', oBlendStudy);
    return (data);
  }

  // static async CancelBlendStudy(blendStudyID) {
  //   const api = await API();
  //   const { data } = await api.post(`/blendStudy/cancelStudy?blendStudyID=${blendStudyID}`);
  //   return (data);
  // }
}
