import API from "..";

export default class SupplierLotFileAttachment {
  
  constructor({
    id,
    supplierLotID,
    thisSupplierLot,
    fileName,
    storagePath,
    isCOA
  }) 
    
  {
    this.id = id;
    this.supplierLotID = supplierLotID;
    this.thisSupplierLot = thisSupplierLot;
    this.fileName = fileName;
    this.storagePath = storagePath;
    this.isCOA = isCOA;
  }

  static async getAll(supplierLotId) {
    const api = await API();
    const { data } = await api.get(`/supplierlotfileattachment/?supplierLotId=${supplierLotId}`);
    if (data.result) {
        return data.result.map((d) => new SupplierLotFileAttachment(d));
    }
    else {
        return [];
    }
  }

  static async postAttachs(info) {
    const api = await API('multipart/form-data');
    const { data } = await api.post('/supplierlotfileattachment', info);
    return new SupplierLotFileAttachment(data.result);
  }

  static async updateSupplierLot(info) {
    const api = await API();
    const { data } = await api.put('/supplierlotfileattachment', info);
    return data;
  }
}
