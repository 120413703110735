import API from "../../api";

export default class TestFacility {
  /**
   * The TestFacility dimension table for M3
   * @param {String} testFacilityName The full name of the TestFacility
   * @param {String} abreviation The Abreviation for the TestFacility
   * @param {bool} isActive The IsActive for the TestFacility
   * @param {bool} isExternalTestFacility The IsExternalTestFacility for the TestFacility
   */

  constructor({
    testFacilityName,
    abreviation,
    isActive,
    isExternalTestFacility }) {
    this.testFacilityName = testFacilityName;
    this.isExternalTestFacility = isExternalTestFacility;
    this.abreviation = abreviation;
    this.isActive = isActive;
  }

  static async getAll() {
    const api = await API();
    const { data } = await api.get(`/testFacility`);
    return data.result.map((d) => new TestFacility(d));
  }

  static async get(id) {
    const api = await API();
    const { data } = await api.get(`testFacility/${id}`);
    if (data.result) {
      return new TestFacility(data.result);
    }
    else {
      return null;
    }

  }

  static async add(info) {
    const api = await API();
    const { data } = await api.post(`/testFacility`, info);
    return new TestFacility(data.result);
  }

  static async update(id, info) {
    const api = await API();
    const { data } = await api.put(`/testFacility/${id}`, info);
    return new TestFacility(data.result);
  }
}
