import React, { useState, useEffect, useContext } from "react";
import UserContext from "../../context/UserContext";
import Sample from "../../api/LIMS/Sample";
import { UXDataTable } from "../../components/UXDataTable";

// const myCols = [
//   { field: 'sampleName', headerName: 'MIDAS Number', type: 'label-midasnumber'},
//   { field: 'description', headerName: 'Description', type: 'label' },
//   { field: 'chemID', headerName: 'Chem ID', type: 'label' },
//   { field: 'createdDate', headerName: 'Date Created', type: 'datetime' },
// ];

const myCols = [
  { field: 'sampleName', headerName: 'MIDAS Number', type: 'button-midasnumber', path: '/newSample'},
  { field: 'description', headerName: 'Description', type: 'label' },
  { field: 'chemID', headerName: 'Chem ID', type: 'label' },
  { field: 'createdDate', headerName: 'Date Created', type: 'datetime' },
];



const MySamplesTable = () => {

  const [samplesIsLoading, setSamplesIsLoading] = useState(true)
  const [mySampleInfo, setMySampleInfo] = useState([])
  const currentUser = useContext(UserContext)
  
  let inputSearchCriteria = currentUser.username
  const noDataMessage = `No samples found for owner ${inputSearchCriteria}`

  //get all the samples
  useEffect(() => {
    let cancelPromise = false
      
    Sample.mySamples(inputSearchCriteria, 1, 10).then((res) => {
      if (cancelPromise) return

      if (res && res.message === 'Success')
      {
        setMySampleInfo(res.result)
      } else {
        setMySampleInfo(null)
      }

      setSamplesIsLoading(false)
    });
      
    return () => {
      cancelPromise = true
    }
  }, [inputSearchCriteria])

  
  return (
    <UXDataTable tableWidth='100%' cols={myCols} rows={mySampleInfo === null ? [] : mySampleInfo.sort((a, b) => new Date(b.createdDate) - new Date(a.createdDate))} moreOptionsCell={false} enablePaging={false} 
      noDataMessage={noDataMessage} menuProps={null} defaultRowsPerPage={10} isDataLoading={samplesIsLoading} tableName={'mySamplesWidget'} enableSorting={true}></UXDataTable>
  );
};

export default MySamplesTable;
