import React, { useContext, useRef } from "react";
import { SHEReviewManagement } from "./SHEReviewManagement";
import { MenuItem, Menu } from "@mui/material";
import { NewChemicalAuthorizationRequestContext } from "../Context/NewChemicalAuthorizationRequestContext";
import { styled } from "@mui/material/styles";

import {
  StyledTableBody,
  UXDataTableWithoutBody,
  StyledTableRow,
  StyledTableCell,
} from "../../../components/UXDataTable";
import { NewChemicalAuthorizationRequestContextProvider } from "../Context/NewChemicalAuthorizationRequestContext";
import MoreOptions from "@mui/icons-material/MoreVert";
import { convertDateFormat } from "../../../global";
import ModalSimpleButton from "../../../components/Modal/ModalSimpleButton";

const StyledTableCellClickable = styled(StyledTableCell)(() => ({
  cursor: 'pointer'
}));

const RequestCols = [
  "",
  "Requested For",
  "Date Created ",
  "Required Date",
  "Approved Date",
  "Product Chem Id",
  "Status",
];

const completedStatus = 'Complete';

export function RequestRender(props) {
  const { showCompletedRequests, requests, loading, canEdit, currentUser, setReloadData, reloadData } =
    props;
  const filteredEvents = [];
  requests && requests.forEach((row) => {
    filteredEvents.push(row);
  });

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [moreOptionsSelected, setMoreOptionsSelected] = React.useState(null);

  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [page, setPage] = React.useState(0);
  const handlePageChange = (event, page) => {
    setPage(page);
  };
  const [modalMessagesOpen, setModalMessagesOpen] = React.useState(false)
  const [modalMessagesText, setModalMessagesText] = React.useState('')
  const [modalMessagesTitle, setModalMessagesTitle] = React.useState('')
  const [modalMessagesButtonText, setModalMessagesButtonText] = React.useState('Ok')

  const formSHEReviewRef = useRef(null)

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const [selectedVendor, setSelectedVendor] = React.useState();
  const determineStartArray = () => {
    return (filteredEvents.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage))
  }

  function handleOpenMoreOptions(e, workRequest) {
    setMoreOptionsSelected(workRequest)

    setAnchorEl(e.currentTarget)
  }

  function handleCloseMoreOptions() {
    setMoreOptionsSelected(null)

    setAnchorEl(null)
  }


  const { UpdateChemicalAuthorizationRequest } = useContext(
    NewChemicalAuthorizationRequestContext
  );

  React.useEffect(()=> {
    if (selectedVendor !== null && formSHEReviewRef?.current !== null) {
      formSHEReviewRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' })
    }
  }, [selectedVendor])

  React.useEffect(()=> {
    setSelectedVendor()
  }, [showCompletedRequests])

  function reloadList(){
    props.reloadList()
    setSelectedVendor()
  }

  const updateRequestStatus = async (submitInputsValue) => {
    handleCloseMoreOptions(null)
    
    const payload = {
      id: submitInputsValue.id,
      createdByEmail: submitInputsValue.createdByEmail,
      createdDate: submitInputsValue.createdDate,
      lastModifiedByEmail: submitInputsValue.lastModifiedByEmail,
      lastModifiedDate: submitInputsValue.lastModifiedDate,
      requestedForEmail: submitInputsValue.requestedForEmail,
      requiredDate: submitInputsValue.requiredDate,
      employeeSection: submitInputsValue.employeeSection,
      productName: submitInputsValue.productName,
      productChemID: submitInputsValue.productChemID,
      isEngineeredNanomaterial: submitInputsValue.isEngineeredNanomaterial,
      estimatedAnnualPurchaseAmount: submitInputsValue.estimatedAnnualPurchaseAmount,
      estimatedAnnualPurchaseAmountUoMName: submitInputsValue.estimatedAnnualPurchaseAmountUoMName,
      intendedUse: submitInputsValue.intendedUse,
      knownSafetyRequirements: submitInputsValue.knownSafetyRequirements,
      isNewToSite: submitInputsValue.isNewToSite,
      reviewerNotes: submitInputsValue.reviewerNotes,
      ChemicalIdentification: submitInputsValue.ChemicalIdentification,
      nfpah: submitInputsValue.nfpah,
      nfpaf: submitInputsValue.nfpaf,
      nfpar: submitInputsValue.nfpar,
      hmish: submitInputsValue.hmish,
      hmisf: submitInputsValue.hmisf,
      hmisr: submitInputsValue.hmisr,
      isChronic: submitInputsValue.isChronic,
      isPHSCat1: submitInputsValue.isPHSCat1,
      isPHSCat2: submitInputsValue.isPHSCat2,
      isPHSCat3: submitInputsValue.isPHSCat3,
      isPeroxidizable: submitInputsValue.isPeroxidizable,
      reviewedByEmail: submitInputsValue.reviewedByEmail,
      approvedDate: submitInputsValue.approvedDate,
      midasChemID: submitInputsValue.midasChemID,
      statusName: 'Cancelled',
    };
    try {
      await UpdateChemicalAuthorizationRequest(payload);
      setModalMessagesOpen(true);
      setModalMessagesText('Form cancelled succesfully')
      setReloadData(!(reloadData))
    } catch (err) {
      const msg = err && err.message ? err.message : 'form cancel failed';
      setModalMessagesOpen(true);
      setModalMessagesText(msg)
    }
  };

  function closeModalMessages() {
    setModalMessagesOpen(false);
    setModalMessagesText('')
    setModalMessagesButtonText('Ok')
    setModalMessagesTitle('')
  }

  return (
    <NewChemicalAuthorizationRequestContextProvider>
      <UXDataTableWithoutBody
        tableWidth="100%"
        cols={RequestCols}
        tableId="manageVendorTable"
        // rowLength={10}
        rowLength={filteredEvents.length}
        enablePaging
        rowsPerPage={rowsPerPage}
        page={page}
        handleChangePage={handlePageChange}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        noDataFoundMessage={"No data found"}
        isDataLoading={loading}
      >
        <StyledTableBody>
          {determineStartArray().map((vendor, rowIndex) => {
            return (
              <StyledTableRow
                hover
              >

                <StyledTableCell
                  component="th" scope="row" style={{ width: "10px" }}>
                  <MoreOptions onClick={(e) => handleOpenMoreOptions(e, vendor)} color='black' />

                </StyledTableCell>
                <StyledTableCellClickable onClick={() => {setSelectedVendor(vendor)}}>{vendor?.requestedForEmail}</StyledTableCellClickable>
                <StyledTableCellClickable onClick={() => {setSelectedVendor(vendor)}}>{convertDateFormat(vendor?.createdDate)}</StyledTableCellClickable>
                <StyledTableCellClickable onClick={() => {setSelectedVendor(vendor)}}>{convertDateFormat(vendor?.requiredDate)}</StyledTableCellClickable>
                <StyledTableCellClickable onClick={() => {setSelectedVendor(vendor)}}>{convertDateFormat(vendor?.approvedDate)}</StyledTableCellClickable>
                <StyledTableCellClickable onClick={() => {setSelectedVendor(vendor)}}>{vendor?.productChemID}</StyledTableCellClickable>
                {/* If showCompletedRequests is true we can show all the status, if it is not true show status except completed, cancelled and rejected */}
                {showCompletedRequests ? <StyledTableCellClickable onClick={() => {setSelectedVendor(vendor)}}>{showCompletedRequests && vendor?.statusName}</StyledTableCellClickable>
                  :

                  // if it is false & have other status like completedStatus Rejected and Cancelled we are showing empty otherwise we are showing what all the status coming from DB.
                  <StyledTableCell >{!showCompletedRequests && (vendor?.statusName === completedStatus || vendor?.statusName === 'Cancelled' || vendor?.statusName === 'Rejected') ? '' : vendor?.statusName}</StyledTableCell>}
              </StyledTableRow>
            );
          })}
        </StyledTableBody>
      </UXDataTableWithoutBody>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={anchorEl && moreOptionsSelected ? true : false}
        onClose={() => handleCloseMoreOptions(null)}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={() => { updateRequestStatus(moreOptionsSelected) }} disabled={!canEdit}>Cancel</MenuItem>
      </Menu>

      <ModalSimpleButton title={modalMessagesTitle} buttonText={modalMessagesButtonText} buttonAction={closeModalMessages} open={modalMessagesOpen} setOpen={setModalMessagesOpen}>
        <label>
          {modalMessagesText}
        </label>
      </ModalSimpleButton>
      
      {selectedVendor && <SHEReviewManagement canEdit={canEdit} reloadList={reloadList} formSHEReviewRef={formSHEReviewRef} selectedVendor={selectedVendor} showCompletedRequests={showCompletedRequests} setTabValue={props.setTabValue} currentUser={currentUser}/>}
    </NewChemicalAuthorizationRequestContextProvider>
  );
}
