import API from "..";

export default class ExperimentalSubstanceSubtype1 {
  /**
   * The ExperimentalSubstanceSubtype1 dimension table for M3
   * @param TODO
   */
   constructor({
    sT1ID,
    subtype1Name,
    ecTypeParent,
    isActive
  }) {
    this.sT1ID = sT1ID;
    this.subtype1Name = subtype1Name;
    this.ecTypeParent = ecTypeParent;
    this.isActive = isActive;
  }

  static async get(id = -1, name = "", parentName = "") {
    const api = await API();
    const { data } = await api.get(`/experimentalsubstancesubtype1?id=${id}&name=${name}&parentName=${parentName}`);
    return data.result.map((d) => new ExperimentalSubstanceSubtype1(d));
    
  }
}