import { useState, useContext, useEffect } from "react";
import {
  Box,
  FormControlLabel,
  Checkbox,
  TextField,
  Typography,
  IconButton,
  FormControl,
} from "@mui/material";
import CircularProgress from '@mui/material/CircularProgress';
import { WarningAmber, List } from "@mui/icons-material";
import {
  StyledTableCellMedium,
  StyledTableCellSmall,
  StyledWarningBox,
  StyledRowForm,
} from "./styles";
import { StockroomRequestContext } from "./context/StockroomRequestContext";
import { OrderStatusContext } from "./context/OrderStatusContext";
import {
  UXDataTableWithoutBody,
  StyledTableBody,
  StyledTableRow,
} from "../../components/UXDataTable";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { convertDateFormat, DatePickerKeyDownEvent } from "../../global";
import DisplayRequestItemList from "./components/DisplayRequestItemList";
import DisplayMessage from "./components/DisplayMessage";
import LocationField from "../../components/LocationField";
import { GlobalButton, GlobalSecondaryButton } from "../styles";
import StockroomApi from "../../api/Stockroom/Request";
import { toast } from "react-hot-toast";

const requestCols = [
  "Req #",
  "Requested",
  "Requested By",
  "Final Location",
  "Status",
];

const SRRequests = ({ canEdit }) => {
  const [statuses, setStatuses] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDisplayItemListModalOpen, setIsDisplayItemListModalOpen] =
    useState(false);
  const [modalType, setModalType] = useState("");

  const [selectedItems, setSelectedItems] = useState([]);
  const [selectedRequest, setSelectedRequest] = useState({});
  
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(false);
  
  const {
    requests,
    getFilteredRequests,
    isDataLoading,
    refreshRequests,
    createStockRequest,
  } = useContext(StockroomRequestContext);

  const { orderStatuses, getAllOrderStatuses } = useContext(OrderStatusContext);

  const day = new Date().getDate();
  const month = new Date().getMonth();
  const year = new Date().getFullYear();

  const [dateFrom, setDateFrom] = useState(null);
  const [dateTo, setDateTo] = useState(new Date(year, month, day));

  const handleApplyFilters = () => {
    const startDateFormatted = `${new Date(dateFrom).getMonth() + 1}/${new Date(
      dateFrom
    ).getDate()}/${new Date(dateFrom).getFullYear()}`;
    const endDateFormatted = `${new Date(dateTo).getMonth() + 1}/${new Date(
      dateTo
    ).getDate()}/${new Date(dateTo).getFullYear()}`;
    refreshRequests();
    getFilteredRequests({
      statuses: statuses.join(","),
      startDate: startDateFormatted,
      endDate: endDateFormatted,
    });
    setPage(0);
  };

  const handlePageChange = (event, page) => {
    setPage(page);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleStatuses = (event) => {
    if (statuses.includes(event.target.value)) {
      const filteredStatuses = statuses.filter(
        (status) => status !== event.target.value
      );
      setStatuses(filteredStatuses);
    } else {
      setStatuses((prevState) => [...prevState, event.target.value]);
    }
  };

  useEffect(() => {
    if (!orderStatuses.length) {
      getAllOrderStatuses();
    }
  }, [orderStatuses, getAllOrderStatuses]);

  const handleSelectModal = () => {
    switch (modalType) {
      case "successMessage":
        return (
          <DisplayMessage
            title="Success!"
            buttonText="Close"
            buttonAction={() => {
              setIsModalOpen(false);
              setModalType("");
            }}
            setOpen={setIsModalOpen}
            open={isModalOpen}
            message={"Your changes have successfully been saved."}
          ></DisplayMessage>
        );
      case "failureMessage":
        return (
          <DisplayMessage
            title="Error!"
            buttonText="Close"
            buttonAction={() => {
              setIsModalOpen(false);
              setModalType("");
            }}
            setOpen={setIsModalOpen}
            open={isModalOpen}
            message={
              " There was an error when saving your changes. Please try again later."
            }
          ></DisplayMessage>
        );
      default:
        return null;
    }
  };

  const updateFinalDestinationName = async (value, id) => {
    const stockRoomRequest = requests.find((item) => item.id === id);

    if (stockRoomRequest) {
      stockRoomRequest.finalDestinationName = value;

      setLoading(true);

      StockroomApi.createRequest(stockRoomRequest)
        .then(() => {
          setLoading(false);
          setSelectedRequest(stockRoomRequest);
        })
        .catch(() => {
          toast.error("Failed while calling API");
        });
    }
  };

  function getOrderStatusLabelByName(name) {
    switch (name) {
      case "InProgress":
        return "In Progress";
      case "OnHold":
        return "On Hold";
      default:
        return name;
    }
  }

  return (
    <Box>
      <StyledRowForm>
        <Box display="grid" gridTemplateColumns="1fr 1fr">
          {
            orderStatuses && orderStatuses.length > 0 && orderStatuses.map((os) => (
              <FormControlLabel
                key={os.orderStatusName}
                label={getOrderStatusLabelByName(os.orderStatusName)}
                control={<Checkbox />}
                value={os.orderStatusName}
                onChange={(e) => handleStatuses(e)}
              />
            ))
          }
        </Box>
        <Box display="flex" gap={2}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DesktopDatePicker
              label="Search From"
              maxDate={new Date()}
              value={dateFrom}
              onChange={(e) => setDateFrom(e)}
              renderInput={(params) => <TextField onKeyDown={DatePickerKeyDownEvent} size="small" {...params} />}
            />
            <DesktopDatePicker
              label="To"
              value={dateTo}
              minDate={dateFrom}
              maxDate={new Date()}
              onChange={(e) => setDateTo(e)}
              renderInput={(params) => <TextField onKeyDown={DatePickerKeyDownEvent} size="small" {...params} />}
            />
          </LocalizationProvider>
        </Box>
        <Box display="flex" alignItems="center">
          <GlobalSecondaryButton 
              disabled={
                statuses.length === 0 ||
                !dateFrom ||
                new Date(dateFrom).getTime() > new Date(dateTo).getTime() ||
                isNaN(dateFrom) ||
                isNaN(dateTo)
              }
              onClick={handleApplyFilters}>
            Search
          </GlobalSecondaryButton>
        </Box>
      </StyledRowForm>
      <StyledWarningBox>
        <WarningAmber fontSize="medium" />
        <Typography>
          Warning: Fetching requests might take some time depending on the date
          range.
        </Typography>
      </StyledWarningBox>
      {loading ? (
        <Box>
          <CircularProgress size={16}/>
        </Box>
      ) : (
        <UXDataTableWithoutBody
          tableWidth="80%"
          cols={requestCols}
          tableId="requestsTable"
          blankFirstHeader
          rowLength={requests.length}
          enablePaging
          rowsPerPage={rowsPerPage}
          page={page}
          handleChangePage={handlePageChange}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          noDataFoundMessage="No requests found"
          isDataLoading={isDataLoading}
        >
          <StyledTableBody>
              {requests.length > 0 &&
                requests
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((item) => {
                    return (
                      <StyledTableRow key={item?.id} hover>
                        <StyledTableCellSmall>
                          <Box 
                            display="flex"
                            alignItems="center"
                            gap={1}
                            justifyContent="center"
                            >
                            <IconButton
                              style={{ padding: 0 }}
                              color="primary"
                              onClick={() => {
                                setSelectedRequest(item);
                                setSelectedItems(structuredClone(item?.requestItems));
                                setIsDisplayItemListModalOpen(true);
                              }}
                            >
                              <List fontSize="small" color="inherit" />
                            </IconButton>
                          </Box>
                        </StyledTableCellSmall>
                        <StyledTableCellMedium>{item?.id}</StyledTableCellMedium>
                        <StyledTableCellMedium>
                          {convertDateFormat(item?.createdDate)}
                        </StyledTableCellMedium>
                        <StyledTableCellMedium>
                          {item?.requestedForEmail}
                          </StyledTableCellMedium>
                        <StyledTableCellMedium>
                          <FormControl fullWidth>
                            <LocationField
                                fieldWidth="150px"
                                value={item.finalDestinationName}
                                handleChange={(value) => {
                                  updateFinalDestinationName(value, item.id);
                                }}
                                hasErrors={false}
                                label="Final Location"
                                disablePortal={false}
                                isDisabled={!canEdit}
                              />
                          </FormControl>
                        </StyledTableCellMedium>
    
                        <StyledTableCellMedium>
                          {item?.orderStatusName}
                          </StyledTableCellMedium>
                      </StyledTableRow>
                    );
                  })}
            </StyledTableBody>
        </UXDataTableWithoutBody>
      )}
      <GlobalButton disabled>Update inventory from scanner</GlobalButton>
      {isModalOpen && handleSelectModal()}
      {selectedItems.length > 0 && (
        <DisplayRequestItemList
          selectedItems={selectedItems}
          selectedRequest={selectedRequest}
          setSelectedItems={setSelectedItems}
          setSelectedRequest={setSelectedRequest}
          createStockRequest={createStockRequest}
          handleApplyFilters={handleApplyFilters}
          isModalOpen={isDisplayItemListModalOpen}
          setIsModalOpen={setIsDisplayItemListModalOpen}
          orderStatuses={orderStatuses}
          canEdit={canEdit}
        />
      )}
    </Box>
  );
};

export default SRRequests;
