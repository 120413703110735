import React, { useState, useEffect, useContext } from "react";
import UserContext from "../../context/UserContext";
import { Link } from "react-router-dom";
import { Box, Divider } from "@mui/material";
import { UXDataTable } from "../../components/UXDataTable";
import Sample from "../../api/LIMS/Sample";
import Draft from "../../api/LIMS/Draft"
import Template from "../../api/LIMS/Template"
import { GlobalTabCollection, GlobalButton, StyledTab, GlobalSecondaryButton } from "../../pages/styles";
import ModalMessages from "../../components/Modal/ModalSimpleButton";
import { applyFiltersToArray } from "../../global";
import FilterMenu from "../../components/FilterMenu";
import ModalTwoButtons from "../../components/Modal/ModalTwoButtons";

const MySamplesCols = [
  { field: 'sampleName', headerName: 'MIDAS #', type: 'button-midasnumber', path: '/newSample'},
  { field: 'description', headerName: 'Description', type: 'label' }, 
  { field: 'chemID', headerName: 'Chem ID', type: 'label' },
  { field: 'createdDate', headerName: 'Date Created', type: 'datetime' },
];

const MyDraftCols = [
  { field: 'draftName', headerName: 'Draft Name', type: 'button-text-draft', path: '/newSample'},
  { field: 'draftType', headerName: 'Draft Type', type: 'label' },
  { field: 'draftOwnerEmail', headerName: 'Draft Owner', type: 'label' },
  { field: 'createdDate', headerName: 'Create Date', type: 'datetime' },
  { field: 'lastModifiedDate', headerName: 'Last Modified', type: 'datetime'}
];

const MyTemplateCols = [
  { field: 'templateName', headerName: 'Template Name', type: 'button-text-template', path: '/newSample'},
  { field: 'templateType', headerName: 'Template Type', type: 'label' },
  { field: 'templateOwnerEmail', headerName: 'Template Owner', type: 'label' },
  { field: 'isPublic', headerName: 'Is Public', type: 'boolean' },
  { field: 'createdDate', headerName: 'Create Date', type: 'datetime' },
  { field: 'lastModifiedDate', headerName: 'Last Modified', type: 'datetime'},
  { field: 'messages', headerName: 'Messages', type: 'list-strings' }
];

const filterOptions = [{name: "sampleName", displayName: "MIDAS #", type: "midasNumber", enumValues:[]}, 
  {name: "description",displayName: "Description", type: "string", enumValues:[]}, 
  {name: "chemID",displayName: "Chem ID", type: "string", enumValues:[]},
  {name: "createdDate",displayName: "Date Created", type: "date", enumValues:[]}
]

const Samples = () => {
  const [tabValue, setTabValue] = useState(0);
  const [filteringOpen, setFilteringOpen] = useState(false);
  const [filters, setFilters] = useState([{name:null, displayName:null, operator:null, enumValues:[], value:''}])

  const [samplesIsLoading, setSamplesIsLoading] = useState(true)
  const [draftsIsLoading, setDraftsIsLoading] = useState(true)
  const [templatesIsLoading, setTemplatesIsLoading] = useState(true)

  const [modalMessagesOpen, setModalMessagesOpen] = useState(false);
  const modalMessagesButtonText = 'Ok'
  const [modalMessagesTitle, setModalMessagesTitle] = useState('');
  const [modalMessagesText, setModalMessagesText] = useState('');

  const [filteredSamples, setFilteredSamples] = useState([])

  const [modalDeleteItemOpen, setModalDeleteItemOpen] = useState(false);
  const [modalDeleteItemTitle, setModalDeleteItemTitle] = useState("");
  const [modalDeleteItemText, setModalDeleteItemText] = useState("");
  const [modalDeleteItemButton1Text, setModalDeleteItemButton1Text] = useState("");
  const [modalDeleteItemButton2Text, setModalDeleteItemButton2Text] = useState("");
  const [removeItem, setRemoveItem] = useState();

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const [mySampleInfo, setMySampleInfo] = useState([])
  const [myDrafts, setMyDrafts] = useState(null)
  const [myTemplates, setMyTemplates] = useState(null)

  const currentUser = useContext(UserContext)

  function closeModalMessages() {
    setModalMessagesOpen(false);
  }

  function openModalMessages(title, text) {
    setModalMessagesOpen(true);
    setModalMessagesTitle(title);
    setModalMessagesText(text);
  }

  const menuItemsSamples = [{
    menuType: 'link-newSample',
    redirectPath: '/newSample',
    text: 'Register Sample from Copy',
    onClickFunction: null
  },
  {
    menuType: 'link-requestTest',
    redirectPath: '/newTest',
    text: 'Request a Test',
    onClickFunction: null
  }]

  function closeModalDeleteItem() {
    setModalDeleteItemOpen(false);
  }

  function openModalDeleteItem(title, text, button1, button2, item) {
    setModalDeleteItemOpen(true);
    setModalDeleteItemTitle(title);
    setModalDeleteItemText(text);
    setModalDeleteItemButton1Text(button1);
    setModalDeleteItemButton2Text(button2);
    setRemoveItem(item);
  }

  const ConfirmRemoveItem = (item, type) => {
    if (item) {
      openModalDeleteItem(`Delete ${type}`, "Are you sure?", "Yes", "No", item);
    }
  };

  const RemoveDraft = () => {
    if (removeItem && removeItem.id) {
      Draft.delete(removeItem.id).then((res) => {
        closeModalDeleteItem();
        if (res.message === "Success") {
          openModalMessages("Draft Deleted", "Draft successfully deleted!");
          setMyDrafts(null);
        } else {
          openModalMessages(
            "Draft Failed to Delete",
            `${res.message}. Contact support if you feel this is an error.`
          );
        }
      });
    }
  };

  const RemoveTemplate = (() => {
    closeModalDeleteItem();
    if (removeItem){

      if (inputSearchCriteria !== removeItem.templateOwnerEmail)
      {
        openModalMessages("Template Error", "You cannot delete a template from another user!")
        return
      }

      Template.delete(removeItem.templateOwnerEmail, removeItem.templateName, 'sample').then((res) => {
        if (res.message === 'Success')
        {
          openModalMessages("Template Deleted", "Template successfully deleted!")
          setMyTemplates(null)
        } else {
          openModalMessages('Template Failed to Delete', `${res.message}. Contact support if you feel this is an error.`);
        }
      })
    }
  })

  const UpdateTemplatePublicFlag = ((template) => {
    if (template){

      if (inputSearchCriteria !== template.templateOwnerEmail)
      {
        openModalMessages("Template Error", "You cannot update a template from another user!")
        return
      }

      template.isPublic = !template.isPublic

      Template.update(template).then((res) => {
        if (res.message === 'Success')
        {
          openModalMessages("Template Updated", "Template successfully updated!")
          setMyTemplates(null)
        } else {
          openModalMessages('Template Failed to Update', `${res.message}. Contact support if you feel this is an error.`);
        }
      })
    }
  })

  const menuItemsDrafts = [{
    menuType: 'text',
    redirectPath: '',
    text: 'Delete Draft',
    onClickFunction: (item) => ConfirmRemoveItem(item, 'Draft')
  }]

  const menuItemsTemplates = [{
    menuType: 'text',
    redirectPath: '',
    text: 'Delete Template',
    onClickFunction: (item) => ConfirmRemoveItem(item, 'Template')
  },
  {
    menuType: 'text',
    redirectPath: '',
    text: 'Change Public Status',
    onClickFunction: UpdateTemplatePublicFlag
  }]
  
  const inputSearchCriteria = currentUser.username

  const noDataMessage = `No samples found for owner ${inputSearchCriteria}`
  const noDataDraftsMessage  = `No sample drafts found for owner ${inputSearchCriteria}`
  const noDataTemplatesMessage  = `No sample templates found for owner ${inputSearchCriteria}`

  useEffect(() => {
    let cancelPromise = false

    async function getData(perPage, page, allData = []) {
      let data = [];

      if (cancelPromise) return allData
  
      await Sample.mySamples(inputSearchCriteria, page, perPage).then((res) => {
        data = res ? res.result : null
      });
    
      if (data !== null) {
        allData = [...allData, ...data]
      }
    
      if (data === null || data.length === 0) {
        setSamplesIsLoading(false)
        return allData;
      } else {
        page++;
        setMySampleInfo(allData)
        return getData(perPage, page, allData);
      }
    }

    getData(200, 1)

    return () => {
      cancelPromise = true
    }
  
  }, [inputSearchCriteria])

  //get all the samples, templates and drafts
  useEffect(() => {
    let cancelPromise = false

    if (myDrafts === null)
    {
      setDraftsIsLoading(true)  

      Draft.getByUserAndType(inputSearchCriteria, "sample").then((res) => {
        if (cancelPromise) return
          setMyDrafts(res)
          setDraftsIsLoading(false)       
      })
    }

    if (myTemplates === null)
    {
      setTemplatesIsLoading(true)  

      Template.searchTemplates(inputSearchCriteria, '', "sample", 'true').then((res) => {
        if (cancelPromise) return
        setMyTemplates(res)    
        setTemplatesIsLoading(false)  
      })
    }

    return () => {
      cancelPromise = true
    }
  }, [inputSearchCriteria, myTemplates, myDrafts])

  useEffect(() => {
    var filteredArray = applyFiltersToArray(filters, mySampleInfo)
    setFilteredSamples(filteredArray)
  }, [filters, mySampleInfo])

  function closeFiltering() {
    setFilteringOpen(false);
  }

  function applyFilters() {
    setFilteringOpen(false);
  }

  const filterClick = (event) => {
    setFilteringOpen(true);
  }

  const clearFiltersClick = (event) => {
    setFilters([{name:null, displayName:null, operator:null, enumValues:[], value:''}])  
  }


  return (
    <>
      <span className='pageheader'>Samples</span>
      <Divider className='dividerbar' />
      <div>
        <Box sx={{ bgcolor: "#fff", pt:3, pb:1 }} display="flex">
          <GlobalTabCollection style={{marginRight:"1rem"}} value={tabValue} onChange={handleChange} aria-label='ant example'>
            <StyledTab label='My Samples' />
            <StyledTab label='Drafts' />
            <StyledTab label='Templates' />
          </GlobalTabCollection>

          <GlobalButton 
            style={{marginTop:"-.8rem"}}
            component={Link}
            to='/newSample'
            variant='contained'
          >Register Sample
          </GlobalButton>

          <Box display="flex" alignItems={"center"} marginLeft="auto" marginTop="-.8rem">
            <GlobalButton disabled={tabValue === 1 || tabValue === 2} style={{marginRight:"1rem"}} variant="contained"
              onClick={() => filterClick()}>Filters</GlobalButton>

            {!(filters[0].name === null) && 
            <GlobalSecondaryButton  variant="contained" 
              onClick={() => clearFiltersClick()}>Clear Filters</GlobalSecondaryButton>}
          </Box>

          <Box sx={{ p: 1 }} />
        </Box>

      {tabValue === 0 &&
       <UXDataTable tableWidth='100%' cols={MySamplesCols} rows={filteredSamples === null ? [] : filteredSamples} moreOptionsCell={true} enablePaging={true} 
                    noDataMessage={noDataMessage} menuProps={menuItemsSamples} defaultRowsPerPage={10} isDataLoading={samplesIsLoading} tableName={'sampleInfo'} enableSorting={true}></UXDataTable>
      }

      {tabValue === 1 &&
       <UXDataTable tableWidth='70%' cols={MyDraftCols} rows={myDrafts === null ? [] : myDrafts.sort((a, b) => b.draftName - a.draftName)} moreOptionsCell={true} enablePaging={true} 
                    noDataMessage={noDataDraftsMessage} menuProps={menuItemsDrafts} defaultRowsPerPage={10} isDataLoading={draftsIsLoading} tableName={'sampleDrafts'} enableSorting={true}></UXDataTable>
      }

      {tabValue === 2 &&
       <UXDataTable tableWidth='90%' cols={MyTemplateCols} rows={myTemplates === null ? [] : myTemplates.sort((a, b) => a.templateName - b.templateName)} moreOptionsCell={true} enablePaging={true} 
                    noDataMessage={noDataTemplatesMessage} menuProps={menuItemsTemplates} defaultRowsPerPage={10} isDataLoading={templatesIsLoading} tableName={'sampleTemplates'} enableSorting={true}></UXDataTable>
      }

      </div>

        {/* Informational Messages */}
        <ModalMessages title={modalMessagesTitle} buttonText={modalMessagesButtonText} buttonAction={closeModalMessages} open={modalMessagesOpen} setOpen={setModalMessagesOpen}>
          <label>
              {modalMessagesText}
          </label>     
        </ModalMessages>

        <ModalTwoButtons
        title={modalDeleteItemTitle}
        button1Text={modalDeleteItemButton1Text}
        button1Action={modalDeleteItemTitle?.includes('Draft') ? RemoveDraft : RemoveTemplate}
        button2Text={modalDeleteItemButton2Text}
        button2Action={closeModalDeleteItem}
        open={modalDeleteItemOpen}
        setOpen={setModalDeleteItemOpen}
      >
        <div style={{ textAlign: "center" }}>
          <label>{modalDeleteItemText}</label>
        </div>
      </ModalTwoButtons>

        <FilterMenu 
          open={filteringOpen} 
          setOpen={setFilteringOpen} 
          applyBtnAction={applyFilters} 
          cancelButtonAction={closeFiltering} 
          filteringInfo={filterOptions} 
          appliedFilters={filters} 
          setAppliedFilters={setFilters}/>
    </>
  );
};

export default Samples;