"use client";

import React, {
  useContext,
  useRef,
  useState,
  useSyncExternalStore,
} from "react";
import { ManagedSerialPortConnection } from "../lib/serial/ManagedSerialPortConnection";

type SerialContextProps = {
  connection: ManagedSerialPortConnection;
  isSupported: boolean;
};

const SerialContext = React.createContext<SerialContextProps | null>(null);

type SerialProviderProps = {
  children: React.ReactNode;
};

export function SerialProvider({ children }: SerialProviderProps) {
  const [connection] = useState(() => new ManagedSerialPortConnection());

  const connectionIdentity = useRef({
    connection,
    lastUpdateTimestamp: connection.lastUpdateTimestamp,
  });

  useSyncExternalStore(connection.subscribe.bind(connection), () => {
    if (
      connection.lastUpdateTimestamp !==
      connectionIdentity.current.lastUpdateTimestamp
    ) {
      connectionIdentity.current.lastUpdateTimestamp =
        connection.lastUpdateTimestamp;
      connectionIdentity.current = { ...connectionIdentity.current };
    }

    return connectionIdentity.current;
  });

  return (
    <SerialContext.Provider
      value={{
        connection: connection,
        isSupported: "serial" in navigator,
      }}
    >
      {children}
    </SerialContext.Provider>
  );
}

export function useSerial() {
  const context = useContext(SerialContext);

  if (!context) {
    throw new Error(
      `${useSerial.name} must be invoked inside a ${SerialProvider.name} context`
    );
  }

  return context;
}
