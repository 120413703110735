import React, { useEffect } from 'react';
import { styled } from "@mui/material/styles";
import {Autocomplete, TextField, Tooltip} from "@mui/material";
import CustomModal from '../Modal/index';
import AddIcon from '@mui/icons-material/AddCircleOutline';
import TrashIcon from '@mui/icons-material/Delete';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { GlobalButton, GlobalSecondaryButton } from '../../pages/styles';
import { convertMIDASNumberToDatabaseFormat, DatePickerKeyDownEvent } from '../../global';

const fontSize = 14

const StyledAutocomplete = styled(Autocomplete)({
  '& .MuiAutocomplete-input, & .MuiInputLabel-root': {
    fontSize: fontSize,
  },
});

const Option = styled('li')({
  fontSize: fontSize,
});

function getOperators(type) {

  switch (type) {

    case 'midasNumber':
      return (['=', 'like'])

    case 'string':
      return (['=', 'like'])

    case 'integer':
      return (['=', 'like', '>', '<'])

    case 'date':
      return (['=', '>', '<'])

    case 'enum':
      return (['='])

    default: return (['=', 'like'])
  }
}

//const FilterMenu = ({ cols, setFilters, open, setOpen, applyBtnAction, cancelButtonAction, setClose}) => {
const FilterMenu = ({ children, open, setOpen, applyBtnAction, cancelButtonAction, filteringInfo, appliedFilters, setAppliedFilters }) => {

  //filteringInfo
  //.displayName = drop down display
  //.name = filtering parameter
  //.type = column type (used for operator selection)
  //.enumValues = values for an enum list

  //appliedFilters
  //.displayName = drop down display
  //.name = selected col from list
  //.operator = selected operator
  //.value = filter value
  //.enumValues = values for an enum list

  const [availableOperators, setAvailableOperators] = React.useState([])
  const [selectedFilters, setSelectedFilters] = React.useState(appliedFilters)

  useEffect(() => {
    setSelectedFilters(appliedFilters)
  }, [appliedFilters, open])

  const handleColValueChange = (index, value) => {
    const newFilters = JSON.parse(JSON.stringify(selectedFilters))

    if (value && newFilters[index].name === value.name) {
      return
    }

    newFilters[index].name = !(value === null) ? value.name : null
    newFilters[index].displayName = !(value === null) ? value.displayName : null
    newFilters[index].operator = null
    newFilters[index].value = ''

    const operators = JSON.parse(JSON.stringify(availableOperators))

    operators[index] = getOperators(!(value === null) ? value.type : null)

    if (!(value === null) && value.type === 'enum') {
      newFilters[index].enumValues = value.enumValues
      newFilters[index].multiple = value.multiple
    } else {
      newFilters[index].enumValues = []
    }

    setAvailableOperators(operators)
    setSelectedFilters(newFilters)
  }

  const handleOperatorChange = (index, value) => {
    const newFilters = JSON.parse(JSON.stringify(selectedFilters))
    newFilters[index].operator = value

    setSelectedFilters(newFilters)
  }

  const handleEnumSelection = (index, value) => {
    const newFilters = JSON.parse(JSON.stringify(selectedFilters))
    newFilters[index].value = value

    setSelectedFilters(newFilters)
  }

  const handleFreeText = (index, value) => {
    const info = filteringInfo.filter(oInfo => oInfo.name === selectedFilters[index].name)
    const newFilters = JSON.parse(JSON.stringify(selectedFilters))

    if (info && info.length > 0 && info[0].type === 'integer') {
      newFilters[index].value = value.isInteger ? parseInt(value) : value
      //newFilters[index].value = Number.isInteger ? parseInt(value) : value
    // } else if (info && info.length > 0 && info[0].type === 'midasNumber') 
    // {
    //   newFilters[index].value = convertMIDASNumberToDatabaseFormat(value)
    }  else {
      newFilters[index].value = value
    }

    setSelectedFilters(newFilters)
  }

  const addFilterRow = (e) => {
    const newFilters = JSON.parse(JSON.stringify(selectedFilters))
    newFilters.push({ name: null, displayName: null, operator: null, enumValues: [], value: null })

    setSelectedFilters(newFilters)
  }

  const removeFilterRow = (e, index) => {
    const newFilters = JSON.parse(JSON.stringify(selectedFilters))
    const operators = JSON.parse(JSON.stringify(availableOperators))

    newFilters.splice(index, 1)
    operators.splice(index, 1)

    setSelectedFilters(newFilters)
    setAvailableOperators(operators)
  }

  const applyFilters = () => {
    var prettyFilters = []
    var prettyOperators = []

    availableOperators.forEach(element => {
      prettyOperators.push(element)
    })

    selectedFilters.forEach(element => {
      if (!(element.name === null) && !(element.operator === null) && !(element.value === null)) {
        prettyFilters.push(element)
      }

    });

    if (prettyFilters && prettyFilters.length > 0) {
      setAppliedFilters(prettyFilters)
    }

    setAvailableOperators(prettyOperators)
    applyBtnAction()
  }

  function ConvertMidasNumbers (rowIndex) 
  {
    const info = filteringInfo.find(oInfo => oInfo.name === selectedFilters[rowIndex].name)
    const newFilters = JSON.parse(JSON.stringify(selectedFilters))

    if (info.type === 'midasNumber')
    {
      newFilters[rowIndex].value = convertMIDASNumberToDatabaseFormat(newFilters[rowIndex].value)
    }
    // for (let index = 0; index < newFilters.length; index++) 
    // {
    //   newFilters[index].value = convertMIDASNumberToDatabaseFormat(newFilters[index].value)
    //   // if (newFilters[index].type === 'midasNumber' && newFilters[index].operator === '=')
    //   // {
        
    //   // }
    // }
    setSelectedFilters(newFilters);
  }

  return (
    <>
      <CustomModal title={"Filtering Criteria"} open={open} setOpen={setOpen}>
        {children ? children : <></>}

        {selectedFilters && selectedFilters.map((oFilter, index) => {
          const info = filteringInfo.find(oInfo => oInfo.name === oFilter.name)
          return (

            <div key={'myDiv' + index} style={{ display: "flex" }}>
              <StyledAutocomplete
                renderOption={(props2, option) => (
                  <Option {...props2}>{option.displayName}</Option>
                )}
                //disablePortal
                options={filteringInfo}
                getOptionLabel={(option) => option.displayName ? option.displayName : option}
                onChange={(e, value) => handleColValueChange(index, value)}
                value={oFilter.displayName}
                isOptionEqualToValue={(option, value) => value === option.displayName}
                autoHighlight
                autoSelect
                renderInput={(params) => <TextField {...params} style={{ width: "220px", marginRight: "10px", marginTop: "10px" }} variant="outlined" size="small" label="Column Name" inputProps={{ ...params.inputProps, style: { fontSize: fontSize } }} InputProps={{ ...params.InputProps }} />}
              />

              <StyledAutocomplete
                renderOption={(props2, option) => (
                  <Option {...props2}>{option}</Option>
                )}
                //disablePortal
                disabled={availableOperators[index] === undefined || oFilter.name === null}
                options={availableOperators[index] === undefined ? [] : availableOperators[index]}
                getOptionLabel={(option) => option}
                onChange={(e, value) => handleOperatorChange(index, value)}
                value={oFilter.operator}
                isOptionEqualToValue={(option, value) => value === option}
                autoHighlight
                autoSelect
                renderInput={(params) => <TextField {...params} style={{ width: "150px", marginRight: "10px", marginTop: "10px" }} variant="outlined" size="small" label="Operator" inputProps={{ ...params.inputProps, style: { fontSize: fontSize } }} InputProps={{ ...params.InputProps }} />}
              />

              {oFilter.enumValues.length < 1 ?
                info?.type === "date" ?
                  (<LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DesktopDatePicker
                      disabled={oFilter.operator === null}
                      size="small"
                      inputFormat="MM/dd/yyyy"
                      label="Search Date"
                      onChange={e => handleFreeText(index, e)}
                      value={oFilter.value}
                      renderInput={(params) => <TextField 
                        onKeyDown={DatePickerKeyDownEvent}
                        {...params} 
                        style={{ width: "200px", marginTop: "10px" }} 
                        variant="outlined" 
                        size="small" 
                        InputLabelProps={{ shrink: true, style: { fontSize: fontSize } }} 
                        inputProps={{ ...params.inputProps, style: { fontSize: fontSize } }} 
                        InputProps={{ ...params.InputProps, style: { fontSize: fontSize } }} 
                      />}
                    />
                  </LocalizationProvider>
                  ) : (<TextField
                    disabled={oFilter.operator === null}
                    style={{ width: "200px", marginTop: "10px" }}
                    size="small"
                    variant="outlined"
                    label="Search Value"
                    inputProps={{ style: { fontSize: fontSize } }}
                    InputLabelProps={{ style: { fontSize: fontSize } }}
                    InputProps={{ style: { fontSize: fontSize } }}
                    value={oFilter.value}
                    onChange={(e) => handleFreeText(index, (e.target.value))}
                    onBlur={() => ConvertMidasNumbers(index)}
                  />
                  ) : (
                  <StyledAutocomplete
                    renderOption={(props2, option) => (
                      <Option {...props2}>{option}</Option>
                    )}
                    disabled={oFilter.operator === null}
                    options={oFilter.enumValues}
                    getOptionLabel={(option) => option}
                    onChange={(e, value) => handleEnumSelection(index, value)}
                    value={oFilter.multiple && (oFilter.value === '' || !oFilter.value) ? [] : oFilter.value === '' ? null : oFilter.value}
                    isOptionEqualToValue={(option, value) => value === option}
                    autoHighlight
                    autoSelect
                    multiple={oFilter.multiple ? true : false}
                    renderInput={(params) => <TextField {...params} style={{ width: "200px", marginRight: "10px", marginTop: "10px" }} variant="outlined" size="small" label="Search Value" inputProps={{ ...params.inputProps, style: { fontSize: fontSize } }} InputProps={{ ...params.InputProps }} />}
                  />
                )}

              {index === selectedFilters.length - 1 &&
                <Tooltip title={"Add Addtional Filter"} placement="bottom">
                  <AddIcon style={{
                    paddingBottom: "5px",
                    paddingLeft: "15px",
                    fontSize: 40,
                    marginTop: "10px"
                  }}
                    onClick={(e) => addFilterRow(e)}
                  ></AddIcon>
                </Tooltip>
              }

              {index > 0 &&
                <Tooltip title={"Remove Filter"} placement="bottom">
                  <TrashIcon style={{
                    paddingBottom: "5px",
                    paddingLeft: "15px",
                    fontSize: 40,
                    marginTop: "10px"
                  }}
                    onClick={(e) => removeFilterRow(e, index)}
                  ></TrashIcon>
                </Tooltip>
              }

            </div>
          )
        })}

        <div style={{
          display: "flex",
          justifyContent: "center",
          //marginTop:"5rem"
        }}>
          <GlobalButton
            style={{
              marginTop: "35px",
              marginLeft: "6px",
              fontSize: "14px",
              fontFamily: "EMprint",
              textTransform: "none"
            }}
            variant='contained'
            onClick={() => applyFilters()}
          >Apply Filters</GlobalButton>

          <GlobalSecondaryButton
            style={{
              marginTop: "35px",
              marginLeft: "20px",
              fontSize: "14px",
              fontFamily: "EMprint",
              textTransform: "none"
            }}
            variant='contained'
            onClick={cancelButtonAction}
          >Cancel</GlobalSecondaryButton>

        </div>

      </CustomModal>
    </>
  );
}
export default FilterMenu;


