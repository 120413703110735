import LoadingButton from "@mui/lab/LoadingButton";
import Autocomplete from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Stack from "@mui/material/Stack";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { FormikProvider, useFormik } from "formik";
import { useContext, useEffect, useState } from "react";
import { SqcApi } from "../../../api/Admin/SQC";
import ModalMessages from "../../../components/Modal/ModalSimpleButton";
import UserContext from "../../../context/UserContext";
import { Roles, convertToLocalTime, hasRole } from "../../../global";
import { SqcSample } from "../../../lib/types/sqc";
import { SqcIdSitePicker } from "./SqcIdSitePicker";
import { SqcQcIdSampleRow } from "./SqcQcIdSampleRow";
import { INITIAL_VALUE, SqcSampleForm, sqcSampleSchema } from "./form";

type SqcQcIdManagementProps = {
  onNavigateToMethod(): void;
};

export function SqcQcIdManagement({
  onNavigateToMethod,
}: SqcQcIdManagementProps) {
  const [loadingSamples, setLoadingSamples] = useState(false);
  const [selectedSample, setSelectedSample] = useState<SqcSample | null>(null);
  const [samples, setSamples] = useState<SqcSample[]>([]);
  const [isFormEnabled, setIsFormEnabled] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [modalTitle, setModalTitle] = useState<string | null>(null);
  const [modalMessage, setModalMessage] = useState<string | null>(null);
  const [isCreatingNew, setIsCreatingNew] = useState(false);

  const openModal = (title: string, message: string) => {
    setModalTitle(title);
    setModalMessage(message);
  };

  const closeModal = () => {
    setModalTitle(null);
    setModalMessage(null);
  };

  const loadSamples = () => {
    setLoadingSamples(true);

    return SqcApi.getAllSamples()
      .then(setSamples)
      .finally(() => setLoadingSamples(false));
  };

  const updateForm = (sample: SqcSample) => {
    formik.setValues({
      sqcid: sample.sqcid,
      isActive: sample.isActive,
      description: sample.description,
      siteName: sample.siteName,
      containerHistory: Array.from(sample.containerHistory)
        .reverse()
        .map((c) => ({
          sqcid: c.sqcid,
          containerID: c.containerID,
          createdDate: c.createdDate,
          isActiveContainer: c.isActiveContainer,
          deactivatedDate: c.deactivatedDate,
          manufacturer: c.manufacturer,
          lotNumber: c.lotNumber,
        })),
    });
  };

  useEffect(() => {
    loadSamples();
  }, []);

  const formik = useFormik<SqcSampleForm>({
    initialValues: INITIAL_VALUE,
    onSubmit: async (values) => {
      setIsSaving(true);

      let updatedSample: SqcSample;
      if (selectedSample) {
        updatedSample = await SqcApi.updateSample(selectedSample.sqcid, values);

        if (updatedSample) {
          openModal("Sample Updated Successfully", "Sample has been saved!");
        } else {
          openModal(
            "Sample Failed to Update",
            "Contact support if you feel this is an error."
          );
          return;
        }
      } else {
        setSelectedSample(null);
        updatedSample = await SqcApi.createSample(values);

        if (updatedSample) {
          openModal("Sample Saved Successfully", "Sample has been saved!");
        } else {
          openModal(
            "Sample Failed to Save",
            "Contact support if you feel this is an error."
          );
          return;
        }
      }

      loadSamples()
        .then(() => {
          setSelectedSample(updatedSample);
          updateForm(updatedSample);
        })
        .finally(() => {
          setIsCreatingNew(false);
          setIsSaving(false)
        });
    },
    validationSchema: sqcSampleSchema,
  });

  const onCreate = () => {
    if (isFormEnabled) {
      const discard = window.confirm(
        "Unsaved changes will be lost. Are you sure?"
      );

      if (!discard) return;
    }

    setIsCreatingNew(true);
    formik.resetForm({ values: INITIAL_VALUE });
    setSelectedSample(null);
    setIsFormEnabled(true);
  };

  const onSelectSample = (sample: SqcSample | null) => {
    setSelectedSample(sample);
    setIsFormEnabled(Boolean(sample));

    if (sample) {
      updateForm(sample);
    }
  };

  const onCancel = () => {
    setSelectedSample(null);
    setIsFormEnabled(false);
    setIsCreatingNew(false);
  };

  const onAddMidasContainer = () => {
    let containerHistory = structuredClone(formik.values.containerHistory);
    const previousSample = containerHistory.at(0);

    const dateNow = new Date().toJSON();

    if (previousSample) {
      previousSample.isActiveContainer = false;
      previousSample.deactivatedDate = dateNow;

      containerHistory = containerHistory.slice(1);
      containerHistory.unshift(previousSample);
    }

    const newSample = {
      containerID: null,
      createdDate: dateNow,
      isActiveContainer: true,
      deactivatedDate: null,
      manufacturer: null,
      lotNumber: null,
    };

    containerHistory.unshift(newSample);
    formik.setFieldValue("containerHistory", containerHistory);
  };

  const lastContainer = formik.values.containerHistory.at(0);
  const addContainerDisabled = lastContainer && !lastContainer.sqcid;

  const currentUser = useContext(UserContext);
  const roles = (currentUser.idTokenClaims as unknown as { roles: string[] })
    .roles;

  const canEdit: boolean =
    hasRole(Roles.Developer, roles) || hasRole(Roles.SQCAdministrator, roles);

  if (!canEdit) {
    return (
      <Typography>
        You don't have permission to edit QCID Management. Make sure you're
        assigned the QC.ADMINISTRATOR role.
      </Typography>
    );
  }

  return (
    <>
      <FormikProvider value={formik}>
        <Stack sx={{ gap: "2rem" }}>
          <Stack sx={{ paddingBlock: "0.5rem", gap: "0.5rem" }}>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography component="h2" sx={{ fontSize: "1.5rem" }}>
                QCID Management
              </Typography>

              <Stack direction="row" sx={{ gap: "2rem" }}>
                <Button type="button" variant="outlined" onClick={onCreate}>
                  Create New QCID
                </Button>

                {isFormEnabled && (
                  <Stack direction="row" sx={{ gap: "0.5rem" }}>
                    <LoadingButton
                      type="button"
                      variant="contained"
                      onClick={() => formik.handleSubmit()}
                      loading={isSaving}
                      disabled={!formik.isValid}
                    >
                      Save Changes
                    </LoadingButton>

                    <Button type="button" variant="outlined" onClick={onCancel}>
                      Cancel
                    </Button>
                  </Stack>
                )}
              </Stack>
            </Stack>

            <Typography
              component="span"
              sx={{ color: "#00000099", fontSize: "0.875rem" }}
            >
              QCIDs are method independent. To assign a QCID to a method, go to{" "}
              <Typography
                component="a"
                onClick={onNavigateToMethod}
                sx={{
                  color: "#2175B6",
                  fontSize: "inherit",
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
              >
                Method Management
              </Typography>
              .
            </Typography>
          </Stack>

          <Stack sx={{ gap: "0.5rem" }}>
            <Autocomplete
              loading={loadingSamples}
              options={samples}
              getOptionLabel={(option) => option.sqcid}
              renderOption={(props, option) => (
                <li {...props} key={option.sqcid}>
                  {option.sqcid}
                </li>
              )}
              isOptionEqualToValue={(option, value) =>
                option.sqcid === value.sqcid
              }
              renderInput={(params) => <TextField {...params} label="QCID" />}
              value={selectedSample}
              onChange={(event, value) => onSelectSample(value)}
              disabled={Boolean(isCreatingNew)}
              sx={{ width: "16rem" }}
            />

            {isFormEnabled && (
              <>
                <SqcIdSitePicker
                  disabled={Boolean(formik.values.sqcid)}
                  value={formik.values.siteName}
                  onChange={(site) =>
                    formik.setFieldValue("siteName", site.siteName)
                  }
                />

                <FormControlLabel
                  control={
                    <Checkbox
                      name="isActive"
                      checked={formik.values.isActive}
                      onChange={formik.handleChange}
                    />
                  }
                  label="Active"
                  sx={{ width: "fit-content" }}
                />

                <TextField
                  name="description"
                  label="Description"
                  multiline
                  rows={4}
                  value={formik.values.description ?? ""}
                  onChange={formik.handleChange}
                  sx={{ width: "32rem" }}
                />
              </>
            )}
          </Stack>

          {isFormEnabled && (
            <>
              <Stack sx={{ gap: "0.5rem" }}>
                <Button
                  type="button"
                  variant="outlined"
                  onClick={onAddMidasContainer}
                  disabled={addContainerDisabled}
                  sx={{ alignSelf: "start" }}
                >
                  Add MIDAS Container
                </Button>

                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell sx={{ padding: 0, width: "4rem" }} />
                        <TableCell sx={{ width: "20rem" }}>
                          MIDAS Sample Container
                        </TableCell>
                        <TableCell>Description</TableCell>
                        <TableCell>Manufacturer</TableCell>
                        <TableCell sx={{ width: "12rem" }}>
                          Lot Number
                        </TableCell>
                        <TableCell>Activation Date</TableCell>
                        <TableCell>Deactivation Date</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {formik.values.containerHistory.map((ch, index) => (
                        <SqcQcIdSampleRow
                          key={ch.createdDate}
                          index={index}
                          containerId={ch.containerID}
                          removable={!ch.sqcid}
                          disabled={Boolean(ch.sqcid)}
                        />
                      ))}

                      {formik.values.containerHistory.length === 0 && (
                        <TableRow>
                          <TableCell colSpan={7}>No records</TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Stack>

              {selectedSample?.lastModifiedByEmail &&
                selectedSample?.lastModifiedDate && (
                  <Typography
                    component="span"
                    sx={{ color: "#00000099", fontSize: "0.75rem" }}
                  >
                    Last updated{" "}
                    {convertToLocalTime(selectedSample.lastModifiedDate)} by{" "}
                    {selectedSample.lastModifiedByEmail}
                  </Typography>
                )}
            </>
          )}
        </Stack>
      </FormikProvider>

      {modalMessage && (
        <ModalMessages
          title={modalTitle}
          buttonText="Close"
          buttonAction={() => closeModal()}
          open={!!modalMessage}
          setOpen={(open: boolean) => {
            if (!open) closeModal();
          }}
        >
          <label>{modalMessage}</label>
        </ModalMessages>
      )}
    </>
  );
}
