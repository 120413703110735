import API from "..";
export default class PiscesWorkRequest {
    /**
     * The Container dimension table for M3
     * @param TODO
     */
    constructor({
      facility,
      category,
      priority,
      priorityReason,
      requester,
      submittedDate,
      requestedCompletionDate,
      lastAcceptableCompletionDate,
      assignedDate,
      inProgressDate,
      completedDate,
      description,
      assignedTo,
      completedBy,
      workRequestID,
      billingInfo,

    }) {
      this.facility = facility;
      this.category = category;
      this.priority = priority;
      this.priorityReason = priorityReason;
      this.requester = requester;
      this.submittedDate = submittedDate;
      this.requestedCompletionDate = requestedCompletionDate;
      this.lastAcceptableCompletionDate = lastAcceptableCompletionDate;
      this.assignedDate = assignedDate;
      this.inProgressDate = inProgressDate;
      this.completedDate = completedDate;
      this.description = description;
      this.assignedTo = assignedTo;
      this.completedBy = completedBy;
      this.workRequestID = workRequestID;
      this.billingInfo = billingInfo;
    }


    static async get(startDate, endDate, workRequestFacilities, page, resultsPerPage) {
      const queryString = workRequestFacilities.join('&workRequestFacilities=');
      const api = await API();
      const { data } = await api.get(`/piscesreporting/workrequest?startDate=${startDate}&endDate=${endDate}&page=${page}&resultsPerPage=${resultsPerPage}${queryString ? '&workRequestFacilities=' + queryString : ''}`);
      return data.result.map((d) => new PiscesWorkRequest(d));
    }

}