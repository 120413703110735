import API from '../index'

export default class DistillationStatus {
    constructor(
        distillationStatusName,
        isActive,
        displayOrder,
        displayColor
    ) {
        this.distillationStatusName = distillationStatusName
        this.isActive = isActive
        this.displayOrder = displayOrder
        this.displayColor = displayColor
    }

    static async getAllDistillationStatuses() {
        const api = await API();
        const { data } = await api.get('/distillationstatus');
        return data.result.map((distillationStatus) => new DistillationStatus(distillationStatus));
    }
}