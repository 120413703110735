import { useState } from 'react';
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import Autocomplete from "@mui/material/Autocomplete";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import {
  StyledTableBody,
  UXDataTableWithoutBody,
  StyledTableRow,
} from "../../../../components/UXDataTable";
import { StyledDelete, StyledTableCellMedium, StyledTableCellSmall } from "../../styles";
import { retainContainerTableCols } from "../../tableColumns";
import type { RetainContainer } from "../../types";
import { INITIAL_ERROR_CHECKS_FOR_CONTAINER, RETAIN_CONTAINER_INITIAL_STATE } from "../../shared";
import { generateUniqueID } from "../../../../global";
import ContainerTable from "../../../../components/ContainerTable";
import { useDetailedStRequest } from "../../context/DetailedStRequestContext";

type RetainSampleContaineringProps = {
  selectedInterval: any | undefined;
  retainContainersForSample: any[];
  initialSize: number;
  setSelectedInterval(newSelectedInterval: any): void;
};

function RetainSampleContainering({
  selectedInterval,
  retainContainersForSample,
  initialSize,
  setSelectedInterval,
}: RetainSampleContaineringProps) {
  const [retainContainerErrorChecks, setRetainContainerErrorChecks] = useState<any[]>(() => {
    const initialErrors: any[] = [];

    Array.apply(null, Array(initialSize)).forEach(() => {
      initialErrors.push(structuredClone(INITIAL_ERROR_CHECKS_FOR_CONTAINER));
    });

    return initialErrors;
  });

  function getInitialErrors(initialSize: number) {
    const initialErrors: any[] = [];

    Array.apply(null, Array(initialSize)).forEach(() => {
      initialErrors.push(structuredClone(INITIAL_ERROR_CHECKS_FOR_CONTAINER));
    });

    return initialErrors;
  }

  function handleUpdateRetainContainers(newContainers: any) {
    const containers = (selectedInterval && selectedInterval.intervalSample && selectedInterval.intervalSample.containers) 
      ? selectedInterval.intervalSample.containers.filter((c: any) => c.tests && c.tests.length > 0) 
      : [];

    const newSelectedIntervalRetainContainers = structuredClone(selectedInterval);

    newSelectedIntervalRetainContainers.intervalSample.containers = [...containers, ...newContainers];

    setSelectedInterval(newSelectedIntervalRetainContainers);
  }

  return (
    <>
      {selectedInterval &&
        selectedInterval.intervalSample &&
        selectedInterval.intervalSample.containers && retainContainerErrorChecks && retainContainersForSample && (
          <ContainerTable
            width="100%"
            showParentContainer={false}
            showTests={false}
            containers={retainContainersForSample}
            setContainers={handleUpdateRetainContainers}
            containerErrorChecks={
              retainContainerErrorChecks.length < retainContainersForSample.length
                ? getInitialErrors(retainContainersForSample.length)
                : retainContainerErrorChecks
            }
            setContainerErrorChecks={setRetainContainerErrorChecks}
            enableAdditions={false}
            enableDeletions={false}
            enableCopy={false}
            availableParentContainers={null}
            sampleObject={selectedInterval.intervalSample}
            substanceObject={selectedInterval.intervalSample.substance ?? null}
            lockContainerStatus={false}
            newContainerDefaultOwner = {null}
          />
        )}
    </>
  );
}

type RetainContaineringTableProps = {
  retainContainers: RetainContainer[];
  setRetainContainers(newRetainContainers: RetainContainer[]): void;
  enableAddIcon: boolean;
  retainContainersForSample: any[];
  selectedInterval: any | undefined;
  setSelectedInterval(newSelectedInterval: any): void;
};

export function RetainContaineringTable({
  enableAddIcon,
  retainContainers,
  setRetainContainers,
  retainContainersForSample,
  selectedInterval,
  setSelectedInterval,
}: RetainContaineringTableProps) {
  const { containerTypes, uoms, locationNames, canUpdateFields } = useDetailedStRequest();

  function getUniqueIdentifierForRetainContainer(retainContainer: RetainContainer) {
    return retainContainer.id;
  }

  const containerTypeNames =
    containerTypes && containerTypes.length > 0 ? containerTypes.map((ct) => ct?.name) : [];

  return (
    <>
      <Typography fontSize={20} color="black" marginBottom={2}>
        Retain Containering
      </Typography>
      {retainContainersForSample.length > 0 && (
        <RetainSampleContainering
          initialSize={retainContainersForSample.length}
          retainContainersForSample={retainContainersForSample}
          selectedInterval={selectedInterval}
          setSelectedInterval={setSelectedInterval}
        />
      )}
      {retainContainersForSample.length === 0 && (
        <UXDataTableWithoutBody
          tableWidth="100%"
          cols={retainContainerTableCols}
          tableId="retainContainersTable"
          rowLength={retainContainers.length}
          noDataFoundMessage="No retain containers found"
          blankFirstHeader
          enableAddIcon={enableAddIcon}
          addToolTipText="Add new Retain Container"
          addFunction={() => {
            const newContainer = {
              ...RETAIN_CONTAINER_INITIAL_STATE,
              id: generateUniqueID(),
            };

            setRetainContainers([...retainContainers, newContainer]);
          }}
          enablePaging={false}
          handleChangePage={() => {}}
          handleChangeRowsPerPage={() => {}}
          handleRequestSort={() => {}}
          handleSelectAllClick={() => {}}
          order={null}
          orderBy={null}
          page={null}
          rowsPerPage={null}
          enableCheckbox={false}
        >
          <StyledTableBody>
            {retainContainers &&
              retainContainers.length &&
              retainContainers.map((container) => {
                return (
                  <StyledTableRow key={getUniqueIdentifierForRetainContainer(container)} hover>
                    <StyledTableCellSmall style={{ maxWidth: 10 }}>
                      <Box display="flex" alignItems="center" justifyContent="center" gap={1}>
                        <IconButton
                          style={{ padding: 0 }}
                          disabled={!canUpdateFields}
                          onClick={() => {
                            const newRetainContainersList = retainContainers.filter(
                              (item) =>
                                getUniqueIdentifierForRetainContainer(item) !==
                                getUniqueIdentifierForRetainContainer(container)
                            );
                            setRetainContainers(newRetainContainersList);
                          }}
                        >
                          <StyledDelete fontSize="small" />
                        </IconButton>
                      </Box>
                    </StyledTableCellSmall>
                    <StyledTableCellSmall>
                      <TextField
                        onChange={(e) => {
                          const updatedArray = retainContainers.map((val) => {
                            if (
                              getUniqueIdentifierForRetainContainer(val) ===
                              getUniqueIdentifierForRetainContainer(container)
                            ) {
                              return {
                                ...val,
                                numberOfContainers: e.target.value,
                              };
                            }
                            return val;
                          });
                          setRetainContainers(updatedArray);
                        }}
                        disabled={!canUpdateFields}
                        value={container?.numberOfContainers}
                        size="small"
                        type="number"
                        fullWidth
                      />
                    </StyledTableCellSmall>
                    <StyledTableCellMedium>
                      <Autocomplete
                        noOptionsText="Loading Container Types..."
                        value={container?.containerType}
                        onChange={(_, value) => {
                          const updatedArray = retainContainers.map((val) => {
                            if (
                              getUniqueIdentifierForRetainContainer(val) ===
                              getUniqueIdentifierForRetainContainer(container)
                            ) {
                              return {
                                ...val,
                                containerType: value ? value : "",
                              };
                            }
                            return val;
                          });
                          setRetainContainers(updatedArray);
                        }}
                        disabled={!canUpdateFields}
                        isOptionEqualToValue={(option, value) => value === option}
                        disablePortal
                        options={containerTypeNames}
                        renderInput={(params) => <TextField {...params} size="small" />}
                        autoSelect
                        fullWidth
                      />
                    </StyledTableCellMedium>
                    <StyledTableCellSmall>
                      <TextField
                        onChange={(e) => {
                          const updatedArray = retainContainers.map((val) => {
                            if (
                              getUniqueIdentifierForRetainContainer(val) ===
                              getUniqueIdentifierForRetainContainer(container)
                            ) {
                              return {
                                ...val,
                                containerSize: e.target.value,
                              };
                            }
                            return val;
                          });
                          setRetainContainers(updatedArray);
                        }}
                        disabled={!canUpdateFields}
                        value={container?.containerSize}
                        size="small"
                        type="number"
                        fullWidth
                      />
                    </StyledTableCellSmall>
                    <StyledTableCellSmall>
                      <TextField
                        onChange={(e) => {
                          const updatedArray = retainContainers.map((val) => {
                            if (
                              getUniqueIdentifierForRetainContainer(val) ===
                              getUniqueIdentifierForRetainContainer(container)
                            ) {
                              return {
                                ...val,
                                retainAmount: e.target.value,
                              };
                            }
                            return val;
                          });
                          setRetainContainers(updatedArray);
                        }}
                        disabled={!canUpdateFields}
                        value={container?.retainAmount}
                        size="small"
                        type="number"
                        fullWidth
                      />
                    </StyledTableCellSmall>
                    <StyledTableCellMedium>
                      <Autocomplete
                        noOptionsText="Loading UoMs..."
                        value={container?.sizeUoM}
                        onChange={(_, value) => {
                          const updatedArray = retainContainers.map((val) => {
                            if (
                              getUniqueIdentifierForRetainContainer(val) ===
                              getUniqueIdentifierForRetainContainer(container)
                            ) {
                              return {
                                ...val,
                                sizeUoM: value ? value : "",
                              };
                            }
                            return val;
                          });
                          setRetainContainers(updatedArray);
                        }}
                        disabled={!canUpdateFields}
                        isOptionEqualToValue={(option, value) => value === option}
                        disablePortal
                        options={uoms.map((unit) => unit?.uoMName)}
                        renderInput={(params) => <TextField {...params} size="small" />}
                        autoSelect
                        fullWidth
                      />
                    </StyledTableCellMedium>
                    <StyledTableCellMedium>
                      <Autocomplete
                        noOptionsText="Loading UoMs..."
                        value={container?.retainUoM}
                        onChange={(_, value) => {
                          const updatedArray = retainContainers.map((val) => {
                            if (
                              getUniqueIdentifierForRetainContainer(val) ===
                              getUniqueIdentifierForRetainContainer(container)
                            ) {
                              return {
                                ...val,
                                retainUoM: value ? value : "",
                              };
                            }
                            return val;
                          });
                          setRetainContainers(updatedArray);
                        }}
                        disabled={!canUpdateFields}
                        isOptionEqualToValue={(option, value) => value === option}
                        disablePortal
                        options={uoms.map((unit) => unit?.uoMName)}
                        renderInput={(params) => <TextField {...params} size="small" />}
                        autoSelect
                        fullWidth
                      />
                    </StyledTableCellMedium>
                    <StyledTableCellMedium>
                      <Autocomplete
                        noOptionsText="Loading Locations..."
                        value={container?.storageLocation}
                        onChange={(_, value) => {
                          const updatedArray = retainContainers.map((val) => {
                            if (
                              getUniqueIdentifierForRetainContainer(val) ===
                              getUniqueIdentifierForRetainContainer(container)
                            ) {
                              return {
                                ...val,
                                storageLocation: value ? value : "",
                              };
                            }
                            return val;
                          });
                          setRetainContainers(updatedArray);
                        }}
                        isOptionEqualToValue={(option, value) => value === option}
                        disablePortal
                        disabled={!canUpdateFields}
                        options={locationNames}
                        renderInput={(params) => <TextField {...params} size="small" />}
                        autoSelect
                        fullWidth
                      />
                    </StyledTableCellMedium>
                  </StyledTableRow>
                );
              })}
          </StyledTableBody>
        </UXDataTableWithoutBody>
      )}
    </>
  );
}
