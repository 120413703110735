import { convertToLocalTime, numberOfTestsOnSample, formatMidasNumber, displayHFRValue } from "../../global";
import MoreOptions from "@mui/icons-material/MoreVert";
import { useState } from "react";
import { UXDataTableWithoutBody, StyledTableCell, UXDataTableNested, StyledTableBody } from "../../components/UXDataTable";
import {IconButton, TableRow, Box, MenuItem, Menu, TextField} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import ResultDisplayBox from "../../components/ResultDisplay";
import TestAPI from "../../api/LIMS/Test"
import ModalTwoButtons from "../../components/Modal/ModalTwoButtons";
import ModalSimpleButton from "../../components/Modal/ModalSimpleButton";

const myTestsCols = ['MIDAS Number', 'Tests', 'Sample Description', 'Additional Info','Chem ID','Parent Sample', 'HFR', 'Sample Owner', 'Access Group']
const myMethodCols = ['Container #', 'TSN', 'Method', 'Method Description', 'Results','Facility','Status', 'Date Completed']

const errorsModalTitle = "Errors Detected"

const MyTests = ({mySamples, isTestDataLoading, noDataMessage, refreshTrigger, setRefreshTrigger, userEmail}) => {
  const [testRowOpen, setTestRowOpen] = useState([]);
  const [resultRowOpen, setResultRowOpen] = useState([]);
  const [moreOptionsSelected, setMoreOptionsSelected] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  const [modalCancelTestOpen, setModalCancelTestOpen] = useState(false);
  const [modalCancelTestButton1Text, setModalCancelTestButton1Text] = useState('');
  const [modalCancelTestButton2Text, setModalCancelTestButton2Text] = useState('');
  const [modalCancelTestTitle, setModalCancelTestTitle] = useState('');
  const [modalCancelTestText, setModalCancelTestText] = useState('');

  const [modalReviewOpen, setModalReviewOpen] = useState(false);
  const [modalReviewButton1Text, setModalReviewButton1Text] = useState('');
  const [modalReviewButton2Text, setModalReviewButton2Text] = useState('');
  const [modalReviewTitle, setModalReviewTitle] = useState('');
  const [modalReviewText, setModalReviewText] = useState('');

  const [modalMessagesOpen, setModalMessagesOpen] = useState(false);
  const modalMessagesButtonText = 'Ok'
  const [modalMessagesTitle, setModalMessagesTitle] = useState('');
  const [modalMessagesText, setModalMessagesText] = useState('');

  const [reviewReason, setReviewReason] = useState(null)
  const [cancelReason, setCancelReason] = useState(null)
  const [cancelID, setCancelID] = useState(null)

  const handleTestRowClick = (sampleName) => {
    const testRowOpenIndex = testRowOpen.indexOf(sampleName);
    let newTestRowOpen = [];

    if (testRowOpenIndex === -1) {
      newTestRowOpen = newTestRowOpen.concat(testRowOpen, sampleName);
    } else if (testRowOpenIndex === 0) {
      newTestRowOpen = newTestRowOpen.concat(testRowOpen.slice(1));
    } else if (testRowOpenIndex === testRowOpen.length - 1) {
      newTestRowOpen = newTestRowOpen.concat(testRowOpen.slice(0, -1));
    } else if (testRowOpenIndex > 0) {
      newTestRowOpen = newTestRowOpen.concat(
        testRowOpen.slice(0, testRowOpenIndex),
        testRowOpen.slice(testRowOpenIndex + 1)
      );
    }
    setTestRowOpen(newTestRowOpen);
  };

  const handleResultRowClick = (sampleName, sequenceNumber) => {
    const resultRowOpenIndex = resultRowOpen.indexOf(sampleName + "~" + sequenceNumber);
    let newResultRowOpen = [];

    if (resultRowOpenIndex === -1) {
      newResultRowOpen = newResultRowOpen.concat(resultRowOpen, sampleName + "~" + sequenceNumber);
    } else if (resultRowOpenIndex === 0) {
      newResultRowOpen = newResultRowOpen.concat(resultRowOpen.slice(1));
    } else if (resultRowOpenIndex === resultRowOpen.length - 1) {
      newResultRowOpen = newResultRowOpen.concat(resultRowOpen.slice(0, -1));
    } else if (resultRowOpenIndex > 0) {
      newResultRowOpen = newResultRowOpen.concat(
        resultRowOpen.slice(0, resultRowOpenIndex),
        resultRowOpen.slice(resultRowOpenIndex + 1)
      );
    }
    setResultRowOpen(newResultRowOpen);
  };

  function handleOpenMoreOptions(e, test) {
    setMoreOptionsSelected(test)
    setAnchorEl(e.currentTarget)
  }

  function handleCloseMoreOptions() {
    setMoreOptionsSelected(null)
    setAnchorEl(null)
  }

  function closeModalCancelReason() {
    setModalCancelTestOpen(false);
  }

  function openModalCancelReason(title, text, buttonText, button2Text) {
    setModalCancelTestButton1Text(buttonText)
    setModalCancelTestButton2Text(button2Text)
    setModalCancelTestOpen(true);
    setModalCancelTestTitle(title);
    setModalCancelTestText(text);
  }

  function closeModalReviewReason() {
    setModalReviewOpen(false);
  }

  function openModalReviewReason(title, text, buttonText, button2Text) {
    setModalReviewButton1Text(buttonText)
    setModalReviewButton2Text(button2Text)
    setModalReviewOpen(true);
    setModalReviewTitle(title);
    setModalReviewText(text);
  }

  function closeModalMessages() {
    setModalMessagesOpen(false);
  }

  function openModalMessages(title, text) {
    setModalMessagesOpen(true);
    setModalMessagesTitle(title);
    setModalMessagesText(text);
  }

  async function reviewTest() {
    if (reviewReason === null || reviewReason.trim() === '')
    {
      openModalMessages(errorsModalTitle, "You must provide a review reason!")
      return;
    }

    if (cancelID === null || cancelID <= 0)
    {
      openModalMessages(errorsModalTitle, "There was an error getting the test ID!, contact support.")
      return;
    }

    await TestAPI.submitTestForReview(cancelID, reviewReason, userEmail).then(async (res) => {
      if (res && res.message === 'Success') {
        openModalMessages('Success', 'Your test was successfully placed UnderReview!')
        setRefreshTrigger(!refreshTrigger)
      } else {
        openModalMessages(errorsModalTitle, `Your test could not be placed UnderReview!  ${res ? res.message : ''}`)
      }
    }); 

    closeModalReviewReason()
    handleCloseMoreOptions(null)
    setReviewReason(null)
    setCancelID(null)
  }

  async function cancelTest() {
    if (cancelReason === null || cancelReason.trim() === '')
    {
      openModalMessages(errorsModalTitle, "You must provide a cancellation reason!")
      return;
    }

    if (cancelID === null || cancelID <= 0)
    {
      openModalMessages(errorsModalTitle, "There was an error getting the test ID!, contact support.")
      return;
    }

    let arrayCancelIDs = [cancelID]

    await TestAPI.cancelTests(arrayCancelIDs, cancelReason).then(async (res) => {
      if (res && res.message === 'Success') {
        openModalMessages('Success', 'Your test was successfully cancelled!')
        setRefreshTrigger(!refreshTrigger)
      } else {
        openModalMessages(errorsModalTitle, `Your test could not be cancelled!  ${res ? res.message : ''}`)
      }
    }); 

    closeModalCancelReason()
    handleCloseMoreOptions(null)
    setCancelReason(null)
    setCancelID(null)
  }

  const determineStartArray = () => {
    return (mySamples.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage))
  }

  const determineTestOrdering = (containers) => {

    let orderedTests = []

    containers.forEach(oContainer => {
      oContainer.tests.forEach(oTest => {
        orderedTests.push(oTest);
        })
    });

    return (orderedTests.sort((a, b) => a.testSequenceNumber - b.testSequenceNumber))
  }
  
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const isTestRowOpen = (sampleName) => testRowOpen.indexOf(sampleName) !== -1;
  const isResultRowOpen = (sampleName, sequenceNumber) => resultRowOpen.indexOf(sampleName + "~" + sequenceNumber) !== -1;

  return (
  <div>
  <Box>
    <UXDataTableWithoutBody 
      tableWidth='100%' 
      cols={myTestsCols} 
      blankFirstHeader={false}
      tableId="myTestsTable"
      rowLength={mySamples ? mySamples.length : 0}
      enablePaging={true}
      rowsPerPage={rowsPerPage}
      page={page}
      handleChangePage={handleChangePage}
      handleChangeRowsPerPage={handleChangeRowsPerPage}
      noDataFoundMessage={noDataMessage}
      enableAddIcon={false}
      addFunction={null}
      addToolTipText={null}  
      isDataLoading={isTestDataLoading}
    >

      {mySamples && mySamples.length > 0 ? (
        determineStartArray().map((sampleRow, index) => {
        
          return (
            <StyledTableBody key={"myTestsTablebody" + index}> 
              <TableRow>
                {/* <StyledTableCell component='th' scope='row' style={{width:'2rem'}}>
                  <MoreOptions color='black' />
                </StyledTableCell> */}

                <StyledTableCell component='th' scope='row'>
                  <b>
                    {formatMidasNumber(sampleRow.sampleName)}
                  </b>
                </StyledTableCell>

                <StyledTableCell align='right' style={{width:'5rem'}}>

                  {numberOfTestsOnSample(sampleRow.containers)}

                    <IconButton
                      aria-label='expand row'
                      size='small'
                      onClick={() => handleTestRowClick(sampleRow.sampleName)}>
                      {isTestRowOpen(sampleRow.sampleName) ? (
                        <KeyboardArrowUpIcon/>
                      ) : (
                        <KeyboardArrowDownIcon/>
                      )}
                    </IconButton>
                  </StyledTableCell>
                  
                  <StyledTableCell align='right'>{sampleRow.description}</StyledTableCell>
                  <StyledTableCell align='right'>{sampleRow.additionalSampleInformation}</StyledTableCell>
                  <StyledTableCell align='right'>{sampleRow.chemID}</StyledTableCell>
                  <StyledTableCell align='right'>{sampleRow.parentSampleContainer ? sampleRow.parentSampleContainer.map((sContainer) => {
                        return(
                            `${sContainer.sampleName}`
                        )
                    }) : ''}</StyledTableCell>
                  {sampleRow.substance ? (
                    <StyledTableCell align='right'>
                      {displayHFRValue(sampleRow.substance)}
                    </StyledTableCell>
                  ) : (<StyledTableCell align='right'>- -</StyledTableCell>)
                  }
                
                  <StyledTableCell align='right'>{sampleRow.sampleOwnerEmail}</StyledTableCell>
                  <StyledTableCell align='right'>{sampleRow.project.name}</StyledTableCell>
              </TableRow>

              {isTestRowOpen(sampleRow.sampleName) && (
                <UXDataTableNested tableWidth={'100%'} cols={myMethodCols} blankFirstHeader={true} colSpan={myTestsCols.length + 1} isOpenComparator={sampleRow.sampleName} isOpenArray={testRowOpen}>
                  {determineTestOrdering(sampleRow.containers).map((testRow) => ( 
                  <StyledTableBody key={sampleRow.sampleName + testRow.testSequenceNumber + "testtablebody"}>
                    <TableRow>
                      <StyledTableCell component='th' scope='row' style={{width:'2rem'}}>
                        <IconButton aria-label="more options" onClick={(e) => handleOpenMoreOptions(e, testRow)}><MoreOptions color='black'/></IconButton>
                      </StyledTableCell>
                      <StyledTableCell>{sampleRow.containers.find(element => element.id === testRow.containerID).containerNumber}</StyledTableCell>
                      <StyledTableCell style={{width:'2rem'}}>{testRow.testSequenceNumber}</StyledTableCell>
                      <StyledTableCell>{testRow.methodFacility.methodName}</StyledTableCell>
                      <StyledTableCell>{testRow.methodFacility.method.description}</StyledTableCell>
                      <StyledTableCell style={{width:'4rem'}}>{testRow.testStatusName === "Corrected" ? "0" : testRow.testResults.length}

                        <IconButton
                          aria-label='expand row'
                          size='small'
                          onClick={() => handleResultRowClick(sampleRow.sampleName, testRow.testSequenceNumber)}>
                          {isResultRowOpen(sampleRow.sampleName, testRow.testSequenceNumber) ? (
                            <KeyboardArrowUpIcon/>
                          ) : (
                            <KeyboardArrowDownIcon/>
                          )}
                        </IconButton>
                      </StyledTableCell>
                      <StyledTableCell>{testRow.methodFacility.testFacilityAbv}</StyledTableCell>
                      <StyledTableCell>{testRow.testStatusName}</StyledTableCell>
                      <StyledTableCell>{convertToLocalTime(testRow.reportedDate)}</StyledTableCell>
                    </TableRow>
                    <TableRow style={{paddingBottom:"0px",paddingTop:"0px", marginTop:"0px", marginBottom:"0px"}}>
                      <StyledTableCell colSpan={8} style={{paddingBottom:"0px",paddingTop:"0px", marginTop:"0px", marginBottom:"0px"}}>{isResultRowOpen(sampleRow.sampleName, testRow.testSequenceNumber) ? <ResultDisplayBox testID={testRow.id}></ResultDisplayBox> : null}</StyledTableCell>
                    </TableRow>
                    
                    </StyledTableBody>
                    ))}
              </UXDataTableNested>
              )}
              
            </StyledTableBody>
          )
        })
      ) : (
        <StyledTableBody key={"no tests found"}>
          <TableRow key={"nothing found"}>
          <StyledTableCell colSpan='14' key={"nothing found cells"}>
            <p>{noDataMessage}</p>
          </StyledTableCell>
        </TableRow>
        </StyledTableBody>
      )}
  </UXDataTableWithoutBody>
    <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={anchorEl && moreOptionsSelected ? true : false}
        onClose={() => handleCloseMoreOptions(null)}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}>
      <MenuItem onClick={() => {setCancelID(moreOptionsSelected.id); openModalReviewReason("Review Reason", "Enter a Reason to Review the Test", "Confirm", "Cancel")}}>Submit for Review</MenuItem>
      <MenuItem onClick={() => {setCancelID(moreOptionsSelected.id); openModalCancelReason("Cancel Reason", "Enter a Cancellation Reason", "Confirm", "Cancel")}}>Cancel Test</MenuItem>
    </Menu>
  </Box>

   {/* Cancel Test Reason */}
    <ModalTwoButtons title={modalCancelTestTitle} button1Text={modalCancelTestButton1Text} button1Action={cancelTest} button2Text={modalCancelTestButton2Text} button2Action={closeModalCancelReason} open={modalCancelTestOpen} setOpen={setModalCancelTestOpen}>
      <label>
          {modalCancelTestText}
      </label>

      <div></div>

      <TextField
          size="small"
          label = "Cancel Reason"
          value = {cancelReason === null ? '' : cancelReason}
          onChange = {e => setCancelReason(e.target.value)}
          margin = "normal"
          style={{width:250, marginTop:40}}
          inputProps={{ maxLength: 250 }}
        ></TextField>
    </ModalTwoButtons>

    {/* Review Test Reason */}
    <ModalTwoButtons title={modalReviewTitle} button1Text={modalReviewButton1Text} button1Action={reviewTest} button2Text={modalReviewButton2Text} button2Action={closeModalReviewReason} open={modalReviewOpen} setOpen={setModalReviewOpen}>
      <label>
          {modalReviewText}
      </label>

      <div></div>

      <TextField
        size="small"
        label = "Review Reason"
        value = {reviewReason === null ? '' : reviewReason}
        onChange = {e => setReviewReason(e.target.value)}
        margin = "normal"
        style={{width:250, marginTop:40}}
        inputProps={{ maxLength: 250 }}
      ></TextField>
    </ModalTwoButtons>

     {/* Informational Messages */}
     <ModalSimpleButton title={modalMessagesTitle} buttonText={modalMessagesButtonText} buttonAction={closeModalMessages} open={modalMessagesOpen} setOpen={setModalMessagesOpen}>
        <label>
          {modalMessagesText}
        </label>   
      </ModalSimpleButton>
  </div>
  );
};

export default MyTests;
