import API from "..";

export default class CertificationMethodModel {
  /**
   * The Sample dimension table for M3
   * @param {String} methodName The MIDAS of the Sample
   */
  constructor({
    id,
    methodName,
    description,
    result,
    units,
    comments,
    minimumNumericValue,
    minimumTextValue,
    maximumNumericValue,
    maximumTextValue,
    typicalNumericValue,
    typicalTextValue,
    certificationResultName,
  }) {
    this.id = id;
    this.methodName = methodName;
    this.description = description;
    this.result = result;
    this.units = units;
    this.comments = comments;
    this.minimumNumericValue = minimumNumericValue;
    this.minimumTextValue = minimumTextValue;
    this.maximumNumericValue = maximumNumericValue
    this.maximumTextValue = maximumTextValue;
    this.typicalNumericValue = typicalNumericValue;
    this.typicalTextValue = typicalTextValue;
    this.certificationResultName = certificationResultName;
  }

  static async getBySupplierTradenameIDWithSupplierLotID(supplierTradenameID, supplierLotID) {
    const api = await API();
    const { data } = await api.get(`/certificationmethod?supplierTradenameID=${supplierTradenameID}&supplierLotID=${supplierLotID}`);

    return data.result.map((d) => new CertificationMethodModel(d));
  }
}
