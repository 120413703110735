import API from "..";

export default class Supplier {
  /**
   * The Sample dimension table for M3
   * @param {String} supplierName The MIDAS of the Sample
   */
  constructor({
    supplierName,
    description,
    supplierLocation,
    certificationDate,
    certifiedByEmail,
    createdByEmail,
    createdDate,
    lastModifiedByEmail,
    lastModifiedDate,
    tradenames,
  }) {
    this.supplierName = supplierName;
    this.description = description;
    this.supplierLocation = supplierLocation;
    this.certificationDate = certificationDate;
    this.certifiedByEmail = certifiedByEmail;
    this.createdByEmail = createdByEmail;
    this.createdDate = createdDate;
    this.lastModifiedByEmail = lastModifiedByEmail;
    this.lastModifiedDate = lastModifiedDate;
    this.tradenames = tradenames;
  }

  static async getAll() {
    const api = await API();
    const { data } = await api.get(`/supplier`);

    return data.result.map((d) => new Supplier(d));
  }

  static async getBySupplierName(supplierName) {
    const api = await API();
    const { data } = await api.get(`/supplier?supplierName=${supplierName}`);

    if (data.result)
      return new Supplier(data.result);
    else
      return null;

  }

  static async update(id, supplier) {
    const api = await API();
    const { data } = await api.put(`/supplier`, supplier);
    return data;
  }

  static async create(supplier) {
    const api = await API();
    const { data } = await api.post(`/supplier`, supplier);
    return data;
  }

  static async delete(supplierName) {
    const api = await API();
    const { data } = await api.delete(`/supplier/${supplierName}`);
    return data;
  }
}
