import API from '../index'

export default class STStandType {
    constructor({
        standTypeName,
        isActive,
    }) {
        this.standTypeName = standTypeName
        this.isActive = isActive
    }

    static async getAll() {
        const api = await API()
        const { data } = await api.get('/ststandtype')
        return data.result.map(standType => new STStandType(standType))
    }

    static async getAllActives() {
        const api = await API();
        const { data } = await api.get('/ststandtype');
        if (data && data.result && data.result.length > 0) {
            return data.result.filter((r) => r.isActive);
        }
        return [];
    }

    static async get(name) {
        const api = await API();
        const { data } = await api.get(`ststandtype/${name}`);
        if (data.result) {
            return new STStandType(data.result);
        }
        else {
            return null;
        }
    }

    static async add(payload) {
        const api = await API();
        const { data } = await api.post('/ststandtype', payload);
        return new STStandType(data.result);
    }

    static async update(payload) {
        const api = await API();
        const { data } = await api.put('/ststandtype', payload);
        return new STStandType(data.result);
    }
}
