import React from "react";
import { printZpl } from "../../global";
import { GlobalButton } from "../../pages/styles";
//import { styled } from "@mui/material/styles";
//import User from "../../api/Admin/User";
//import UserContext from "../../context/UserContext";
import ResultDisplayBox from "../../components/ResultDisplay";

/* const StyledDiv = styled('div')({
  display:"flex", 
  marginTop:"5px"
}); */

// eslint-disable-next-line no-template-curly-in-string
const zplString = "^XA^LH0,0^BY2,3,10^FO25,35^A0N,60,60^FD2200001000-0^FS^FO25,155^A0N,40,40^FDWATER^FS^FO0,100^B3N,N,40,N,N^FD2200001000000^FS^FO25,205^A0N,24,24^FDChemID: 3074.0^FS^FO25,230^A0N,24,24^FDPHS: None^FS^FO300,230^A0N,24,24^FDReturn: Discard^FS^FO25,255^A0N,24,24^FDPeroxidizable: N^FS^FO210,255^A0N,24,24^FD3/22/2023^FS^FO350,255^A0N,24,24^FDHFR: 110^FS^XZ"

const Sandbox = () => {
  //const currentUser = useContext(UserContext)
  // const newUser = User.get(currentUser.username);

  function PrintLabel (ZPL) {
    printZpl(ZPL)
  }
  
  return (
    <div>
       <GlobalButton 
          variant='contained'
          style={{ marginTop:"20px", marginRight:"20px" }}
          onClick={(e) => PrintLabel(zplString)}
        >{'Test ZPL Print'}
        </GlobalButton>
      {/* <div>
        <span className='pageheader'>User Preferences</span>
        <Divider className='dividerbar' />
        <StyledDiv>
          <p style={{
          fontFamily:"EMprint",
          fontWeight:"600",
          fontSize:"16px",
          color:"#545459",
          textTransform: 'none',
          textAlign:"left",
          paddingTop:"20px"
          }}>Identity Information</p>
        </StyledDiv>
          Name (context): {currentUser.name}<br/>
          Name (Graph): {newUser.displayName}<br/>
          Email (context): {currentUser.username}<br/>
          Email (Graph): {newUser.mail}<br/>
          User Principal Name (Graph): {newUser.userPrincipalName}<br/>
      </div> */}
      <div>
          <ResultDisplayBox testID={13886222}></ResultDisplayBox>
      </div>
    </div>
  );
};

export default Sandbox;