import API from "..";

export default class SupplierTradename {
  /**
   * The Sample dimension table for M3
   * @param {String} supplierName The MIDAS of the Sample
   */
  constructor({
    id,
    supplierName,
    supplier,
    tradenameID,
    tradename,
    lots,
  }) {
    this.id = id;
    this.supplierName = supplierName;
    this.supplier = supplier;
    this.tradenameID = tradenameID;
    this.tradename = tradename;
    this.lots = lots;
  }

  static async getAll() {
    const api = await API();
    const { data } = await api.get(`/suppliertradename`);

    return data.result.map((d) => new SupplierTradename(d));
  }

  static async getByChemID(chemID) {
    const api = await API();
    const { data } = await api.get(`/suppliertradename?chemID=${chemID}`);

    return data.result.map((d) => new SupplierTradename(d));
  }

  static async update(suppliertradename) {
    const api = await API();
    const { data } = await api.put(`/suppliertradename`, suppliertradename);
    return data;
  }

  static async create(suppliertradename) {
    const api = await API();
    const { data } = await api.post(`/suppliertradename`, suppliertradename);
    return data;
  }

  static async delete(id) {
    const api = await API();
    const { data } = await api.delete(`/suppliertradename/${id}`);
    return data;
  }
}
