import { SerialPortDevice } from "./types";

export const SERIAL_DEVICES = {
  ArduinoMega2560Rev3: {
    name: "Arduino Mega 2560 Revision 3",
    filter: {
      usbVendorId: 0x2341,
      usbProductId: 0x42,
    },
  } as SerialPortDevice,
} as const;

export function getDeviceDefinition(
  serialPortInformation: Partial<SerialPortInfo>
): SerialPortDevice | null {
  return (
    Object.values(SERIAL_DEVICES).find(
      (serialDevice) =>
        serialDevice.filter.usbVendorId === serialPortInformation.usbVendorId &&
        serialDevice.filter.usbProductId === serialPortInformation.usbProductId
    ) ?? null
  );
}
