import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import EditIcon from "@mui/icons-material/Edit";
import SubdirectoryArrowRightIcon from "@mui/icons-material/SubdirectoryArrowRight";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import { useState } from "react";
import { PackageTestCriteria } from "../../../api/Admin/PackageTestCriteria";
import UnitOfMeasure from "../../../api/Admin/UnitOfMeasure";
import { PackageTestCriteriaForm } from "./PackageTestCriteriaForm";

type PackageTestCriterionProps = {
  packageTestCriterion: PackageTestCriteria;
  onAdd(parentCriterion: PackageTestCriteria): void;
  onDelete(criterion: PackageTestCriteria): void;
  onUpdate(
    previousValue: PackageTestCriteria,
    newValue: PackageTestCriteria
  ): void;
  methods: any[];
  unitsOfMeasure: UnitOfMeasure[];
};

export function PackageTestCriterion(props: PackageTestCriterionProps) {
  return <DeepPackageTestCriterion depth={0} {...props} />;
}

type DeepPackageTestCriterionProps = PackageTestCriterionProps & {
  depth: number;
};

function DeepPackageTestCriterion({
  packageTestCriterion,
  depth = 0,
  onAdd,
  onDelete,
  onUpdate,
  methods,
  unitsOfMeasure,
}: DeepPackageTestCriterionProps) {
  const [isUpdating, setIsUpdating] = useState(false);

  return (
    <>
      <Box
        component="li"
        sx={{
          display: "flex",
          alignItems: "center",
          border: "1px solid black",
          listStyleType: "none",
          paddingLeft: "1rem",
          marginLeft: `${depth * 2}rem`,
          marginBottom: "4px",
        }}
      >
        {packageTestCriterion.stepDescription}

        <IconButton
          type="button"
          onClick={() => setIsUpdating(true)}
          sx={{ marginLeft: "auto" }}
        >
          <EditIcon />
        </IconButton>

        <IconButton type="button" onClick={() => onAdd(packageTestCriterion)}>
          <SubdirectoryArrowRightIcon />
        </IconButton>

        <IconButton
          type="button"
          onClick={() => onDelete(packageTestCriterion)}
        >
          <DeleteForeverIcon />
        </IconButton>
      </Box>

      {packageTestCriterion.childPackageTestCriteria.map((c, i) => (
        <DeepPackageTestCriterion
          key={i}
          packageTestCriterion={c}
          depth={depth + 1}
          onAdd={onAdd}
          onDelete={onDelete}
          onUpdate={onUpdate}
          methods={methods}
          unitsOfMeasure={unitsOfMeasure}
        />
      ))}

      {isUpdating && (
        <PackageTestCriteriaForm
          initialValue={packageTestCriterion}
          onClose={() => setIsUpdating(false)}
          onUpdate={(newValue) => onUpdate(packageTestCriterion, newValue)}
          methods={methods}
          unitsOfMeasure={unitsOfMeasure}
        />
      )}
    </>
  );
}
