import API from "..";

export default class NewChemicalAuthorizationRequest {
  constructor({
    id,
    createdByEmail,
    createdDate,
    lastModifiedByEmail,
    lastModifiedDate,
    requestedForEmail,
    requiredDate,
    employeeSection,
    productName,
    productChemID,
    isEngineeredNanomaterial,
    estimatedAnnualPurchaseAmount,
    estimatedAnnualPurchaseAmountUoMName,
    intendedUse,
    knownSafetyRequirements,
    isNewToSite,
    chemicalIdentification,
    reviewerNotes,
    nfpah,
    nfpaf,
    nfpar,
    hmish,
    hmisf,
    hmisr,
    isChronic,
    isPHSCat1,
    isPHSCat2,
    isPHSCat3,
    isPeroxidizable,
    reviewedByEmail,
    approvedDate,
    midasChemID,
    statusName,
    requestComments,
    fileAttachments,
  }) {
    this.id = id;
    this.createdByEmail = createdByEmail;
    this.createdDate = createdDate;
    this.lastModifiedByEmail = lastModifiedByEmail;
    this.lastModifiedDate = lastModifiedDate;
    this.requestedForEmail = requestedForEmail;
    this.requiredDate = requiredDate;
    this.employeeSection = employeeSection;
    this.productName = productName;
    this.productChemID = productChemID;
    this.isEngineeredNanomaterial = isEngineeredNanomaterial;
    this.estimatedAnnualPurchaseAmount = estimatedAnnualPurchaseAmount;
    this.estimatedAnnualPurchaseAmountUoMName = estimatedAnnualPurchaseAmountUoMName;
    this.intendedUse = intendedUse;
    this.knownSafetyRequirements = knownSafetyRequirements;
    this.isNewToSite = isNewToSite;
    this.chemicalIdentification = chemicalIdentification;
    this.reviewerNotes = reviewerNotes;
    this.nfpah = nfpah;
    this.nfpaf = nfpaf;
    this.nfpar = nfpar;
    this.hmish = hmish;
    this.hmisf = hmisf;
    this.hmisr = hmisr;
    this.isChronic = isChronic;
    this.isPHSCat1 = isPHSCat1;
    this.isPHSCat2 = isPHSCat2;
    this.isPHSCat3 = isPHSCat3;
    this.isPeroxidizable = isPeroxidizable;
    this.reviewedByEmail = reviewedByEmail;
    this.approvedDate = approvedDate;
    this.midasChemID = midasChemID;
    this.statusName = statusName;
    this.requestComments = requestComments;
    this.fileAttachments = fileAttachments;
  }

  static async get() {
    const api = await API();
    const { data } = await api.get(`/chemicalauthorizationrequest`);
    return data;
  }
  static async getAll() {
    const api = await API()
    const { data } = await api.get('/chemicalauthorizationrequest')
    return data?.result?.map(item => new NewChemicalAuthorizationRequest(item))
  }

  static async chemicalAuthorizationRequest(requestObject) {
    const api = await API();
    const { data } = await api.post('/chemicalauthorizationrequest', requestObject);
    return data;

    //return new NewChemicalAuthorizationRequest(data.result);
  }

  static async UpdateChemicalAuthorizationRequest(requestObject) {
    const api = await API();
    const { data } = await api.put('/chemicalauthorizationrequest', requestObject);
    return data;

    // if (data && data.result) {
    //   return new NewChemicalAuthorizationRequest(data.result);
    // } else {
    //   throw data;
    // }
  }
}
