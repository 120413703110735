import API from "..";

export default class TSCAOutcome {
  constructor({ outcomeName, isActive }) {
    this.outcomeName = outcomeName;
    this.isActive = isActive;
  }

  static async getAll() {
    const api = await API();
    const { data } = await api.get(`/tscaOutcome`);
    if (data.result) {
      return data.result.map((d) => new TSCAOutcome(d));
    } else {
      return null;
    }
  }

  static async createTSCAOutcome(info) {
    const api = await API();
    const { data } = await api.post("/tscaOutcome", info);
    return new TSCAOutcome(data.result);
  }
}
