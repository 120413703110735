import React from "react";

const StockroomMasterData = ({ ...props }) => {
    return (
      <div>
        <p>
          Coming soon to a browser near you!
          
        </p>
        <ul>
          <li>Equipment Status</li>
          <li>Equipment Type</li>
          <li>Order Status</li>
          <li>Stockroom Category</li>
        </ul>
      </div>
    );
  };
  
  export default StockroomMasterData;