import { REQUEST_INFORMATION_ENUM } from "../../shared";
import type { InformationForProcessRequest, StationaryTest } from "../../types";
import AuthorizationValidationView from "./AuthorizationValidationView";
import ExperimentProcessDetailsView from "./ExperimentProcessDetailsView";
import GeneralView from "./GeneralView";
import SampleScheduleView from "./SampleScheduleView";
import StationaryTestFuelSampleView from "./StationaryTestFuelSampleView";
import StationaryTestLubeSampleView from "./StationaryTestLubeSampleView";
import StationaryTestSampleView from "./StationaryTestSampleView";
import TestInformationView from "./TestInformationView";
import TestSpecificationView from "./TestSpecificationView";
import { FormikProps } from "formik";

type DetailedActiveRequestProps = {
  activeOptionName: REQUEST_INFORMATION_ENUM;
  informationForProcessRequest: InformationForProcessRequest;
  formik: FormikProps<Partial<StationaryTest>>;
};

export default function DetailedActiveRequest({
  activeOptionName,
  informationForProcessRequest,
  formik,
}: DetailedActiveRequestProps) {
  return (
    <div style={{ marginBottom: "2rem" }}>
      {activeOptionName === REQUEST_INFORMATION_ENUM.GENERAL && (
        <GeneralView generalInformation={informationForProcessRequest.generalInformation} formik={formik} />
      )}
      {activeOptionName === REQUEST_INFORMATION_ENUM.PROCEDURE_INFORMATION && (
        <TestInformationView testInformation={informationForProcessRequest.testInformation} />
      )}
      {activeOptionName === REQUEST_INFORMATION_ENUM.TEST_SPECIFICATIONS && (
        <TestSpecificationView
          testSpecification={informationForProcessRequest.testSpecification}
          formik={formik}
        />
      )}
      {activeOptionName === REQUEST_INFORMATION_ENUM.AUTHORIZATION && (
        <AuthorizationValidationView
          authorizationValidation={informationForProcessRequest.authorizationValidation}
          formik={formik}
        />
      )}
      {activeOptionName === REQUEST_INFORMATION_ENUM.STATIONARY_TEST_SAMPLE && (
        <StationaryTestSampleView
          generalInformation={formik.values.generalInformation}
          testInformation={formik.values.testInformation}
        />
      )}
      {activeOptionName === REQUEST_INFORMATION_ENUM.STATIONARY_TEST_LUBE_SAMPLES && (
        <StationaryTestLubeSampleView
          lubricantSamples={informationForProcessRequest.lubricantSamples}
        />
      )}
      {activeOptionName === REQUEST_INFORMATION_ENUM.STATIONARY_TEST_FUEL_SAMPLES && (
        <StationaryTestFuelSampleView
          fuelSamples={informationForProcessRequest.fuelSamples}
          formik={formik}
        />
      )}
      {activeOptionName === REQUEST_INFORMATION_ENUM.SAMPLE_SCHEDULE && (
        <SampleScheduleView formik={formik} generalInformation={informationForProcessRequest.generalInformation} />
      )}
      {activeOptionName === REQUEST_INFORMATION_ENUM.EXPERIMENT_PROCESS_DETAILS && (
        <ExperimentProcessDetailsView
          saveSTRequestProcessResult={(stRequestProcessResults) => 
            formik.setFieldValue("stRequestProcessResults", stRequestProcessResults)
          }
          saveSTIntervalProcessResult={(stIntervalsWithIntervalProcessResults) => 
            formik.setFieldValue("stIntervalsWithIntervalProcessResults", stIntervalsWithIntervalProcessResults)
          }
        />
      )}
    </div>
  );
}
