import API from "..";

export default class BlendPriority {

  constructor({
    blendPriorityName,
    isActive
}) 
    
  {
    this.blendPriorityName = blendPriorityName;
    this.isActive = isActive;
  }

  static async getAllBlendPriorities() {
    const api = await API();
    const { data } = await api.get(`/blendPriority`);
    if (data.result) {
        return data.result.map((d) => new BlendPriority(d));
    }
    else {
        return [];
    }
  }
}
