import React from "react";
import {
  Box,
  FormControl,
  TextField,
  Checkbox,
  styled
} from "@mui/material";
import FormControlLabel from '@mui/material/FormControlLabel';
import ModalTwoButtons from '../../../components/Modal/ModalTwoButtons';
import STStatus from "../../../api/StationaryTesting/STStatus";
import { ChromePicker } from 'react-color';
import Typography from '@mui/material/Typography';

const StyledBox = styled(Box)(() => ({
  display: 'flex',
  marginBottom: "20px",
  gap: "20px",
  justifyContent: "normal!important"
}));

const StyledTextField = styled(TextField)(() => ({
  width: "35rem"
}));

const StyledFormControl = styled(FormControl)(() => ({
  display: 'flex',
  width: "50rem"
}));

const StyledDiv = styled('div')({
  display: 'flex',
  justifyContent: "space-between"
});

const STStatusForm = ({ infoData, setInfoData, invalidField, handleChangeSTStatusFilter, canEdit }) => {

  const [modalTwoButtonsOpen, setModalTwoButtonsOpen] = React.useState(false);
  const [color, setColor] = React.useState('#fff')
  const [showColorPicker, setShowColorPicker] = React.useState(false)

  function findStatus() {
    handleChangeSTStatusFilter(infoData.statusName);
    closeModalTwoButtonsOpen();
  }

  function closeModalTwoButtonsOpen() {
    setModalTwoButtonsOpen(false);
  }

  function checkExistingStatus(status) {

    if (status) {
      STStatus.get(status).then((statusExists) => {
        if ((statusExists !== undefined && statusExists !== null))
          setModalTwoButtonsOpen(true)
      });
    }
  }

  function handleChange(e) {
    setInfoData(() => ({
      ...infoData,
      displayColor: e.hex
    }));

    setColor(e.hex)
    // setShowColorPicker(() => !(e.hex !== infoData.displayColor))
  }

  function handleClick(e) {
    e.preventDefault();

    setShowColorPicker(showColorPicker => !showColorPicker)
  }

  return (

    <div>
      <StyledDiv>
        <div>
          <StyledBox sx={{ flexDirection: 'column' }}>
            <StyledFormControl sx={{ m: 1 }} variant='outlined'>

              <StyledBox sx={{ justifyContent: "space-between" }}>
                <StyledTextField
                  onChange={e => {
                    setInfoData(() => ({
                      ...infoData,
                      statusName: e.target.value
                    }))
                  }}
                  onBlur={e => {
                    checkExistingStatus(e.target.value)
                  }}
                  value={infoData.statusName}
                  id="status-name"
                  label="Status Name"
                  variant="outlined" size="small" margin="none"
                  error={invalidField.statusName.length === 0 ? false : true}
                  disabled={infoData.id || !canEdit ? true : false}
                  helperText={invalidField.statusName}
                  inputProps={{ maxLength: 50 }}
                />

                <FormControlLabel
                  label="Active"
                  onChange={e => setInfoData(() => ({
                    ...infoData,
                    isActive: e.target.checked
                  }))}
                  checked={infoData.isActive}
                  disabled={!canEdit}
                  control={<Checkbox />}
                />
              </StyledBox>

              <StyledBox sx={{ justifyContent: "space-between" }}>
                <StyledTextField
                  onChange={e => {
                    setInfoData(() => ({
                      ...infoData,
                      displayOrder: parseInt(e.target.value)
                    }))
                  }}
                  value={infoData.displayOrder}
                  id="display-order"
                  label="Display Order"
                  type="number"
                  variant="outlined" size="small" margin="none"
                  inputProps={{ maxLength: 50 }}
                />
              </StyledBox>

              <StyledBox>
                <button href="#" onClick={e => handleClick(e)}>
                  {showColorPicker ? 'Close Color Picker' : 'Open Color Picker'}
                </button>
              </StyledBox>

              <StyledBox>
                {showColorPicker && (
                  <ChromePicker
                    color={color}
                    onChange={e => {
                      handleChange(e)
                    }}
                  />)}

                <Typography>Display Color:   <b>{infoData.displayColor}</b></Typography>
              </StyledBox>

            </StyledFormControl>
          </StyledBox>

        </div>

      </StyledDiv>

      <ModalTwoButtons title={'Warning'} button1Text={'No'} button1Action={closeModalTwoButtonsOpen} button2Text={'Yes'} button2Action={findStatus} open={modalTwoButtonsOpen} setOpen={setModalTwoButtonsOpen}>
        <label>
          This Status already exist, would you like to use it?
        </label>
      </ModalTwoButtons>

    </div>

  );
};

export default STStatusForm;
