export default class StkReport {
  constructor({
    report,
    stockroomNo,
    description,
    actionDate,
    actionType,
    actionBy,
    actionText
  }) {
    this.report = report;
    this.stockroomNo = stockroomNo;
    this.description = description;
    this.actionDate = actionDate;
    this.actionType = actionType;
    this.actionBy = actionBy;
    this.actionText = actionText;
  }
}
