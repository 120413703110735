import React from "react";
import {
    styled,
} from "@mui/material";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';

const StyledModalBox = styled(Box)(() => ({
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: 'white',
    borderRadius: '10px',
    border: '1px solid rgba(0, 0, 0, 0.3)',
    boxShadow: '0 3px 7px rgba(0, 0, 0, 0.3)',
    backgroundClip: 'padding-box',
    maxHeight: "100%",
}));

const StyledModalHeader = styled('div')({
    margin: '15px',
    display: 'flex',
    justifyContent: 'center'
});

const StyledModalBody = styled('div')({
    margin: '2rem',
    overflow: 'auto',
    maxHeight: '80vh'
});

const CustomModal = ({ children, title, open, setOpen, setClose}) => {

    //const handleClose = () => setClose ? setClose() : setOpen(false);

    return (
        <>
            <Modal style={{maxWidth:"100%", maxHeight:"100%", overflow:"auto",
                //position: 'absolute',
            //  top: '50%',
            //  left: '50%',
                //transform: 'translate(-50%, -50%)',
            //  backgroundColor: 'white',
            //  borderRadius: '10px',
            //  border: '1px solid rgba(0, 0, 0, 0.3)',
            //  boxShadow: '0 3px 7px rgba(0, 0, 0, 0.3)',
            //  backgroundClip: 'padding-box'
            }}

                open={open}
                //onClose={handleClose}
                aria-labelledby="modal-modal-header"
                aria-describedby="modal-modal-body"
            >
                <StyledModalBox >
                    <StyledModalHeader id="modal-modal-header" >
                        <Typography variant="h6" component="h6">{title}</Typography>
                    </StyledModalHeader>
                    <StyledModalBody id="modal-modal-body">

                        {children}

                    </StyledModalBody>
                </StyledModalBox>
            </Modal>
        </>
    );
};

export default CustomModal;
