import { useState } from 'react'
import { TextField, RadioGroup, Radio, FormControlLabel, 
  FormControl, FormLabel, Button, Typography, Box } from '@mui/material';
import ModalTwoButtons from '../../components/Modal/ModalTwoButtons'
import ModalMessages from '../../components/Modal/ModalSimpleButton'  
import Notification from '../../api/Admin/Notification';

const ReleaseManagement = () => {
  const [version, setVersion] = useState('');
  const [onlyMe, setOnlyMe] = useState(true);
  const [isProcessing, setIsProcessing] = useState(false);
  const [versionError, setVersionError] = useState(false);

  const [modalMessagesOpen, setModalMessagesOpen] = useState(false);
  const [modalMessagesTitle, setModalMessagesTitle] = useState('');
  const [modalMessagesText, setModalMessagesText] = useState('');

  const [confirmSendOpen, setConfirmSendOpen] = useState(false);


  function openModalMessages(title, text) {
    setModalMessagesOpen(true);
    setModalMessagesTitle(title);
    setModalMessagesText(text);
  }

  function closeModalMessages() {
    setModalMessagesOpen(false);
  }

  function confirmSendNo() {
    setConfirmSendOpen(false);
  }

  async function confirmSendYes() {
    setConfirmSendOpen(false);
    await processSendNotification();
  }

  const handleVersionChange = (e) => {
    setVersion(e.target.value);
    if (e.target.value) {
      setVersionError(false);
    }
  }

  const handleSend = async () => {
    if (!version) {
      setVersionError(true);
      return;
    }

    if (onlyMe){ 
      await processSendNotification();
    } else {
      setConfirmSendOpen(true);
    }
  }

  async function processSendNotification() {
    setIsProcessing(true);

    await Notification.sendReleaseNotifications(version, onlyMe).then((res) => {
      if (res.message === "Success") {
        openModalMessages(`Release notifications sent successfully`, `Release notifications sent successfully to ${res.result} user(s)`);
      } else {
        openModalMessages(`Error sending notifications`, `Error sending release notifications: ${res.message}`);
      }
    });

    setIsProcessing(false);
  }

  return (
    <>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, maxWidth: 300 }}>
        <Typography fontWeight="bold" fontSize={22} color="#1976d2">Send Release Notifications</Typography>
        <TextField
          label="Enter version number"
          variant="outlined"
          value={version}
          onChange={handleVersionChange}
          error={versionError}
          fullWidth
          required
        />
        <FormControl component="fieldset">
          <FormLabel component="legend">Send To</FormLabel>
          <RadioGroup
            aria-label="send-to"
            name="sendTo"
            value={onlyMe ? "onlyMe" : "allActiveUsers"}
            onChange={(e) => setOnlyMe(e.target.value === "onlyMe")}
          >
            <FormControlLabel value="onlyMe" control={<Radio />} label="Only Me" />
            <FormControlLabel value="allActiveUsers" control={<Radio />} label="All Active Users" />
          </RadioGroup>
        </FormControl>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSend}
          disabled={isProcessing}
        >
          {isProcessing ? "Sending..." : "Send"}
        </Button>
      </Box>
      <ModalMessages title={modalMessagesTitle} buttonText="Ok" buttonAction={closeModalMessages} 
        open={modalMessagesOpen} setOpen={setModalMessagesOpen}>
        <label>
            {modalMessagesText}
        </label>
      </ModalMessages>
      <ModalTwoButtons title="Confirm send notification" button1Text="Yes" button1Action={confirmSendYes} 
        button2Text="No" button2Action={confirmSendNo} open={confirmSendOpen} setOpen={setConfirmSendOpen}>
        <label>
            Are you sure you want to send release notification for version {version} to all active users?
        </label>
      </ModalTwoButtons>
    </>
  );
};

export default ReleaseManagement;