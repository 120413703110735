import API from "..";

export default class ExperimentalSubstanceSubtype3 {
  /**
   * The ExperimentalSubstanceSubtype3 dimension table for M3
   * @param TODO
   */
   constructor({
    sT3ID,
    subtype3Name,
    sT2Parent,
    isActive
  }) {
    this.sT3ID = sT3ID;
    this.subtype3Name = subtype3Name;
    this.sT2Parent = sT2Parent;
    this.isActive = isActive;
  }

  static async get(id = -1, name = "") {
    const api = await API();
    const { data } = await api.get(`/experimentalsubstancesubtype3?id=${id}&name=${name}`);
    return data.result.map((d) => new ExperimentalSubstanceSubtype3(d));
    
  }
}