import React from "react";

const WorkRequestMasterData = ({ ...props }) => {
    return (
      <div>
        <p>
          Coming soon to a browser near you!
        </p>
        <ul>
          <li>Shipping Address</li>
          <li>Shipping Carrier</li>
          <li>Shipping Reason</li>
          <li>TSCA Status</li>
          <li>Work Request Facility</li>
          <li>Work Request Priority</li>
          <li>Work Request Status</li>
          <li>Work Request Type</li>
        </ul>
      </div>
    );
  };
  
  export default WorkRequestMasterData;