import React, { useState } from "react";
import { StyledErrorMessage } from "../styles";
import ModalTwoButtons from "../../../components/Modal/ModalTwoButtons";
import { TextField } from "@mui/material";

export default function AddItem({
  selectedItems,
  selectedItem,
  quantity,
  setSelectedItems,
  setIsModalOpen,
  setSelectedItem,
  setQuantity,
  setModalType,
  isModalOpen,
}) {
  const [errorMessage, setErrorMessage] = useState("");

  return (
    <ModalTwoButtons
      title="Add item to list"
      button1Text="Add"
      button1Action={() => {
        setErrorMessage("");
        if (!selectedItems.some((item) => item?.id === selectedItem.id)) {
          if (selectedItem.quantity < quantity) {
            setErrorMessage(
              `Quantity desired is above item's stock count of ${selectedItem.quantity}`
            );
          } else if (quantity <= 0) {
            setErrorMessage("Quantity value has to be at least 1");
          } else {
            let newItem = JSON.parse(JSON.stringify(selectedItem));
            newItem = {
              ...newItem,
              quantity: quantity,
            };
            setSelectedItems((prevState) => [...prevState, newItem]);
            setIsModalOpen(false);
            setSelectedItem({});
            setQuantity(1);
            setErrorMessage("");
            setModalType("");
          }
        } else {
          setErrorMessage("Item already in the list, try another");
        }
      }}
      button2Text="Close"
      button2Action={() => {
        setSelectedItem({});
        setIsModalOpen(false);
        setQuantity(1);
        setModalType("");
      }}
      setOpen={setIsModalOpen}
      open={isModalOpen}
    >
      <>
        <TextField
          size="small"
          type="number"
          name="quantity"
          label="Quantity"
          min={1}
          value={quantity}
          onChange={(event) => setQuantity(event.target.value)}
          sx={{ marginTop: "0.5rem" }}
        />
        {errorMessage && (
          <StyledErrorMessage>{errorMessage}</StyledErrorMessage>
        )}
      </>
    </ModalTwoButtons>
  );
}
