import { createContext, useState } from "react";
import StockroomPO from "../../../api/Stockroom/StockroomPO";
import ModalSimpleButton from "../../../components/Modal/ModalSimpleButton";
import { Typography } from "@mui/material";
import { useEffect } from "react";

export const StockroomPOContext = createContext(null);

export const StockroomPOContextProvider = ({ children }) => {
  const [pos, setPOs] = useState([]);
  const [posForItem, setPOsForItem] = useState([]);
  const [isPoDataLoading, setIsPoDataLoading] = useState(false);

  const [hasError, setHasError] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    if (hasError) setIsModalOpen(true);
  }, [hasError]);

  const getAllPOs = async () => {
    setIsPoDataLoading(true);
    await StockroomPO.getStockroomPOs()
      .then((data) => {
        setIsPoDataLoading(false);
        // const formattedData = data.map((po) => po.ID);
        const filteredList = data.filter(
          (po) =>
            po?.statusName?.toLowerCase() !== "complete" &&
            po?.statusName?.toLowerCase() !== "cancelled",
        );
        setPOs(filteredList);
      })
      .catch((err) => {
        setHasError(err);
        setIsModalOpen(true);
      });
  };

  const managePO = async (payload) => {
    return await StockroomPO.managePO(payload);
  };

  const getPOsForItem = async (itemId, searchFrom) => {
    setIsPoDataLoading(true);
    await StockroomPO.getPOsForItem(itemId, searchFrom)
      .then((data) => {
        setIsPoDataLoading(false);

        setPOsForItem(data);
      })
      .catch((err) => {
        setHasError(err);
        setIsModalOpen(true);
      });
  };

  const refreshPosForItem = () => {
    setPOsForItem([]);
  };

  return (
    <StockroomPOContext.Provider
      value={{
        pos,
        getAllPOs,
        isPoDataLoading,
        managePO,
        getPOsForItem,
        posForItem,
        refreshPosForItem,
        setIsPoDataLoading,
      }}>
      {children}

      <ModalSimpleButton
        title={"Error"}
        buttonText={"Close"}
        buttonAction={() => {
          setIsModalOpen(false);
          setHasError("");
        }}
        setOpen={() => {
          setIsModalOpen(false);
          setHasError("");
        }}
        open={isModalOpen}>
        <Typography style={{ padding: "0 16px", fontSize: 18, textAlign: "center" }}>
          {hasError}
        </Typography>
      </ModalSimpleButton>
    </StockroomPOContext.Provider>
  );
};
