import { Autocomplete, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";

import StockroomContractWorker from "../../api/Stockroom/StockroomContractWorker";
import { Box } from "@mui/system";
import { outlinedInputClasses } from "@mui/material/OutlinedInput";
import { inputLabelClasses } from "@mui/material/InputLabel";

export default function VendorSearchField({
  style,
  value,
  setValue,
  setIsValid,
  isValid,
  isDisabled = false,
}) {
  const [contractWorkers, setContractWorkers] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [touched, setTouched] = useState(false);

  useEffect(() => {
    StockroomContractWorker.getAll()
      .then((data) => {
        setContractWorkers(data);
      })
      .catch((err) => {});
  }, []);
  useEffect(() => {
    if (value === null) setInputValue("");
  }, [value]);

  const validateBadgeId = (value) => {
    let badgeId = contractWorkers.find((item) => item.BadgeID.toString() === value);
    setInputValue(value);
    setTouched(true);
    if (badgeId) {
      setIsValid(true);
    } else {
      setIsValid(false);
    };
  };

  return (
    <Autocomplete
      freeSolo={true}
      disabled={contractWorkers.length === 0 && isDisabled}
      value={value}
      inputValue={inputValue}
      onInputChange={(event, newInputValue) => {
        validateBadgeId(newInputValue);
      }}
      onChange={(event, newValue) => {
        setValue(newValue);
        setIsValid(true);
      }}
      onBlur={() => setTouched(true)}
      style={style}
      size="small"
      options={contractWorkers}
      renderInput={(params) => (
        <TextField
          {...params}
          InputLabelProps={{ shrink: true }}
          label={
            touched
              ? isValid
                ? "Valid Badge ID"
                : "Invalid Badge ID"
              : "Badge ID"
          }
          placeholder="Search Badge ID"
        />
      )}
      getOptionLabel={(option) => option.BadgeID.toString()}
      renderOption={(props, option) => (
        <Box
          component="li"
          sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
          {...props}
        >
          {option.BadgeID} - {option.LastName}, {option.FirstName}
        </Box>
      )}
      sx={{
        width: 300,
        [`& .${outlinedInputClasses.root} .${outlinedInputClasses.notchedOutline}`]:
          {
            ...(touched && { borderColor: isValid ? "green" : "red" }),
          },

        [`&:hover .${outlinedInputClasses.root} .${outlinedInputClasses.notchedOutline}`]:
          {
            ...(touched && { borderColor: isValid ? "green" : "red" }),
          },
        [`& .${outlinedInputClasses.root}.${outlinedInputClasses.focused} .${outlinedInputClasses.notchedOutline}`]:
          {
            ...(touched && { borderColor: isValid ? "green" : "red" }),
          },
        [`& .${outlinedInputClasses.input}`]: {
          ...(touched && { color: isValid ? "green" : "red" }),
        },

        [`&:hover .${outlinedInputClasses.input}`]: {
          ...(touched && { color: isValid ? "green" : "red" }),
        },
        [`& .${outlinedInputClasses.root}.${outlinedInputClasses.focused} .${outlinedInputClasses.input}`]:
          {
            ...(touched && { color: isValid ? "green" : "red" }),
          },
        [`& .${inputLabelClasses.outlined}`]: {
          ...(touched && { color: isValid ? "green" : "red" }),
        },

        [`&:hover .${inputLabelClasses.outlined}`]: {
          ...(touched && { color: isValid ? "green" : "red" }),
        },
        [`& .${inputLabelClasses.outlined}.${inputLabelClasses.focused}`]: {
          ...(touched && { color: isValid ? "green" : "red" }),
        },
      }}
    ></Autocomplete>
  );
}
