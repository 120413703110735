import React, {
  useEffect,
  useState,
  useContext,
  useMemo,
  useRef,
} from "react";
import {
  Box,
  Typography,
  TextField,
  Autocomplete,
  IconButton,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
} from "@mui/material";
import {
  StyledTableCellMedium,
  StyledTableCellSmall,
  StyledTableCellLarge
} from "./styles";
import {
  StyledTableBody,
  StyledTableRow,
  UXDataTableWithoutBody,
} from "../../components/UXDataTable";
import ModalSimpleButton from "../../components/Modal/ModalSimpleButton";
// import EmailSearchInput from "../../components/EmailInput";
import EmailAddressTextField from "../../components/EmailAddressField";
import {
  AddCircleOutline,
  Delete,
  CameraAltOutlined,
} from "@mui/icons-material";
import { applyFiltersToArray } from "../../global";
import FilterMenu from "../../components/FilterMenu";
import { StockroomItemListContext } from "./context/StockroomItemListContext";
import { CategoryContext } from "./context/CategoryContext";
import { StockroomRequestContext } from "./context/StockroomRequestContext";
import SampleAttachment from "../../api/LIMS/SampleAttachment";
import LocationApi from "../../api/Admin/Location";
import DisplayItemImage from "./components/DisplayItemImage";
import AddItem from "./components/AddItem";
import DeleteItem from "./components/DeleteItem";
import UpdateItemQuantity from "./components/UpdateItemQuantity";
import UpdateItemComment from "./components/UpdateItemComment";
import AddItemById from "./components/AddItemById";
import DisplayMessage from "./components/DisplayMessage";
import FilterRequest from "./components/FilterRequest";
import VendorInputField from "../../components/VendorInputField/VendorInputField";
import { GlobalSecondaryButton, GlobalButton } from "../styles";
import BillingInfoField from "../../components/BillingInfoField";

const itemsSelectedCols = [
  "Stockroom #",
  "Description",
  "Request",
  "UoM",
  "Price",
];
const inventoryCols = [
  "Stockroom #",
  "Description",
  "Inventory",
  "UoM",
  "Price",
];

const FILTER_INITIAL_STATE = [
  {
    name: null,
    displayName: null,
    operator: null,
    enumValues: [],
    value: "",
  },
];

const SUBMIT_FORM_INITIAL_STATE = {
  deliveryLocation: "COUNTER",
  workOrder: "",
  costCenter: "",
  wbs: "",
};
const _emailOrBadge = { email: "email", badgeId: "badgeId" };

const StockroomOrder = () => {
  const [emailOrBadge, setEmailOrBadge] = useState(_emailOrBadge.email);
  const [badgeId, setBadgeId] = useState(null);
  const [isValidBadgeId, setIsValidBadgeId] = useState(false);

  const [itemID, setItemID] = useState("");
  const [isSubmittingRequest, setIsSubmittingRequest] = useState(false);
  const [imageLoading, setImageLoading] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectedItem, setSelectedItem] = useState({});
  const [locationList, setLocationList] = useState([]);
  const [itemImage, setItemImage] = useState("");
  const [quantity, setQuantity] = useState(1);
  const [comment, setComment] = useState("");
  const [requesterEmail, setRequesterEmail] = useState(null);
  const [submitInputsValue, setSubmitInputsValue] = useState(
    SUBMIT_FORM_INITIAL_STATE
  );
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalType, setModalType] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [inventoryRowsPerPage, setInventoryRowsPerPage] = useState(10);
  const [inventoryPage, setInventoryPage] = useState(0);
  const [filteredItems, setFilteredItems] = useState([]);
  const [filteringOpen, setFilteringOpen] = useState(false);
  const [filters, setFilters] = useState(FILTER_INITIAL_STATE);

  const [requesterInvalid, setRequesterInvalid] = useState(false);

  const [modalErrorMessage, setModalErrorMessage] = useState({
    title: "",
    message: "",
    isOpen: false,
  });

  const runWhenStart = useRef(true);

  const { inventoryItems, getAllInventoryItems, getItemById, isDataLoading } =
    useContext(StockroomItemListContext);
  const { categories, getAllCategories } = useContext(CategoryContext);
  const { createStockRequest } = useContext(StockroomRequestContext);

  const isCartOnlyChemicals = selectedItems.every(
    (item) => item?.stockroomCategoryName?.toLowerCase() === "chemicals"
  );
  const totalOrder = useMemo(() => {
    if (selectedItems) {
      return selectedItems.reduce((acc, cur) => {
        const total = cur?.equipmentPriceUSD * cur?.quantity;
        return (acc += total);
      }, 0);
    } else {
      return 0;
    }
  }, [selectedItems]);

  const isValidEmailOrBadgeID = () => {
    if (emailOrBadge === _emailOrBadge.email) return requesterInvalid;
    else return !isValidBadgeId;
  };

  const filterOptions = [
    {
      name: "siteAssetNumber",
      displayName: "Stockroom #",
      type: "string",
      enumValues: [],
    },
    {
      name: "description",
      displayName: "Description",
      type: "string",
      enumValues: [],
    },
    {
      name: "stockroomCategoryName",
      displayName: "Categories",
      type: "enum",
      enumValues: categories?.map(
        (category) => category.StockroomCategoryName.stockroomCategoryName
      ),
    },
  ];

  const convertImagePath = async (imagePath) => {
    setImageLoading(true);
    if (imagePath) {
      const containerName =
        imagePath.split("/")[imagePath.split("/").length - 2];
      const fileName = imagePath.split("/")[imagePath.split("/").length - 1];
      const response = await SampleAttachment.getFileAttachment(
        containerName,
        fileName
      );
      if (response) {
        setItemImage(`data:image/png;base64,${response}`);
        setImageLoading(false);
      } else {
        setItemImage(null);
        setImageLoading(false);
      }
    } else {
      setItemImage(null);
      setImageLoading(false);
    }
  };

  const handlePageChange = (event, page) => {
    setPage(page);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleInventoryPageChange = (event, inventoryPage) => {
    setInventoryPage(inventoryPage);
  };

  const handleChangeInventoryRowsPerPage = (event) => {
    setInventoryRowsPerPage(parseInt(event.target.value, 10));
    setInventoryPage(0);
  };

  const handleSelectModal = () => {
    switch (modalType) {
      case "displayItemImage":
        return (
          <DisplayItemImage
            itemID={itemID}
            setIsModalOpen={setIsModalOpen}
            setItemID={setItemID}
            setModalType={setModalType}
            isModalOpen={isModalOpen}
            imageLoading={imageLoading}
            itemImage={itemImage}
            setItemImage={setItemImage}
          />
        );
      case "addItem":
        return (
          <AddItem
            selectedItems={selectedItems}
            selectedItem={selectedItem}
            quantity={quantity}
            setSelectedItems={setSelectedItems}
            setIsModalOpen={setIsModalOpen}
            setSelectedItem={setSelectedItem}
            setQuantity={setQuantity}
            setModalType={setModalType}
            isModalOpen={isModalOpen}
          />
        );
      case "deleteItem":
        return (
          <DeleteItem
            selectedItems={selectedItems}
            selectedItem={selectedItem}
            setSelectedItems={setSelectedItems}
            setIsModalOpen={setIsModalOpen}
            setSelectedItem={setSelectedItem}
            setModalType={setModalType}
            setQuantity={setQuantity}
            isModalOpen={isModalOpen}
          />
        );
      case "updateItemQuantity":
        return (
          <UpdateItemQuantity
            inventoryItems={inventoryItems}
            selectedItem={selectedItem}
            quantity={quantity}
            selectedItems={selectedItems}
            setSelectedItems={setSelectedItems}
            setSelectedItem={setSelectedItem}
            setQuantity={setQuantity}
            setIsModalOpen={setIsModalOpen}
            setModalType={setModalType}
            isModalOpen={isModalOpen}
          />
        );
      case "updateItemComment":
        return (
          <UpdateItemComment
            selectedItems={selectedItems}
            selectedItem={selectedItem}
            comment={comment}
            setSelectedItems={setSelectedItems}
            setSelectedItem={setSelectedItem}
            setIsModalOpen={setIsModalOpen}
            setComment={setComment}
            setModalType={setModalType}
            isModalOpen={isModalOpen}
          />
        );
      case "addItemById":
        return (
          <AddItemById
            getItemById={getItemById}
            itemID={itemID}
            selectedItems={selectedItems}
            quantity={quantity}
            setSelectedItems={setSelectedItems}
            setIsModalOpen={setIsModalOpen}
            setItemID={setItemID}
            setQuantity={setQuantity}
            setModalType={setModalType}
            isModalOpen={isModalOpen}
          />
        );
      case "displaySuccessMessage":
        return (
          <DisplayMessage
            setIsModalOpen={setIsModalOpen}
            setModalType={setModalType}
            isModalOpen={isModalOpen}
            title={"Success!"}
            buttonText={"Close"}
            message={"Your order has successfully been submitted."}
          />
        );
      case "displayErrorMessage":
        return (
          <DisplayMessage
            setIsModalOpen={setIsModalOpen}
            setModalType={setModalType}
            isModalOpen={isModalOpen}
            title={"Error!"}
            buttonText={"Close"}
            message={
              "There was an error when creating your order, please try again later."
            }
          />
        );
      case "displayWarningSubmitMessage":
        return (
          <DisplayMessage
            setIsModalOpen={setIsModalOpen}
            setModalType={setModalType}
            isModalOpen={isModalOpen}
            title={"Error when submitting"}
            buttonText={"Close"}
            message={
              "You cannot have an empty list of items when submitting your order."
            }
          />
        );
      case "displayWarningChemicalMessage":
        return (
          <DisplayMessage
            setIsModalOpen={setIsModalOpen}
            setModalType={setModalType}
            isModalOpen={isModalOpen}
            title={"Error when submitting"}
            buttonText={"Close"}
            message={
              "Items that are not chemicals cannot be delivered to a location different than COUNTER."
            }
          />
        );
      default:
        return null;
    }
  };

  const createRequest = async () => {
    const { deliveryLocation, workOrder, costCenter, wbs } = submitInputsValue;

    let requestItens = [];

    selectedItems.forEach((item) => {
      if (item.stockroomCategoryName === "Chemicals" && item.quantity > 1) {
        for (let index = 0; index < item.quantity; index++) {
          requestItens.push({
            stockRoomRequest: null,
            thisItem: null,
            issuedQuantity: null,
            issuedByEmail: null,
            issuedDate: null,
            equipmentID: item.id,
            requestedQuantity: 1,
            unitPriceUSD: item.equipmentPriceUSD,
            orderStatusName: "Pending",
            ignoreAlert: true,
            comments: item.comments,
          });
        }
      } else {
        requestItens.push({
          stockRoomRequest: null,
          thisItem: null,
          issuedQuantity: null,
          issuedByEmail: null,
          issuedDate: null,
          equipmentID: item.id,
          requestedQuantity: +item.quantity,
          unitPriceUSD: item.equipmentPriceUSD,
          orderStatusName: "Pending",
          ignoreAlert: true,
          comments: item.comments,
        });
      }
    });

    const payload = {
      requestedForEmail:
        emailOrBadge === _emailOrBadge.email ? requesterEmail : badgeId.BadgeID,
      costCenter: costCenter,
      deliveryLocationName: deliveryLocation,
      workOrderID: +workOrder,
      isSignatureRequired: false,
      requestItems: requestItens,
      createdByEmail: null,
      createdDate: null,
      lastModifiedByEmail: null,
      lastModifiedDate: null,
      chargeCode: wbs,
      comments: null,
      completedDate: null,
      emergeRequest: null,
      orderStatusName: "Pending",
      workOrderName: null,
    };

    setIsSubmittingRequest(true);
    await createStockRequest(payload)
      .then((response) => {
        OpenModalByType("displaySuccessMessage");
        setSelectedItems([]);
        setSubmitInputsValue(SUBMIT_FORM_INITIAL_STATE);
        setRequesterEmail(null);
        setRequesterInvalid(false);
      })
      .catch((err) => OpenModalByType("displayErrorMessage"))
      .finally(() => setIsSubmittingRequest(false));
  };

  useEffect(() => {
    if (runWhenStart.current) {
      getAllInventoryItems();
      runWhenStart.current = false;
    }
  }, [runWhenStart, getAllInventoryItems]);

  useEffect(() => {
    LocationApi.getBySite("Clinton Technical Center")
      .then((res) => {
        if (res.length > 0) {
          setLocationList(res.filter(l => l.isActive));
        }
      })
      .catch((message) => {
        setModalErrorMessage({
          title: "Error",
          message: message,
          isOpen: true,
        });
        setLocationList([]);
      });
  }, []);

  useEffect(() => {
    const filteredArray = applyFiltersToArray(filters, inventoryItems);
    setFilteredItems(filteredArray);
  }, [filters, inventoryItems]);

  useEffect(() => {
    if (!inventoryItems.length && !categories.length) {
      getAllCategories();
      getAllInventoryItems();
    } else {
      setFilteredItems(inventoryItems);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inventoryItems]);

  const OpenModalByType = (modalType) => {
    setIsModalOpen(true);
    setModalType(modalType);
  };
  const handleChange = (event) => {
    setEmailOrBadge(event.target.value);
  };

  return (
    <Box>
      <Box display="flex" flexDirection="column">
        <Typography component="h2" fontSize={30} marginBottom={2}>
          Stockroom Request
        </Typography>

        <Box
          display="flex"
          alignItems="flex-start"
          gap={2}
          flexDirection="column"
          alignContent="center"
        >
          <Box
            display="flex"
            flexDirection="row"
            alignItems={
              emailOrBadge === _emailOrBadge.email ? "baseline" : "flex-end"
            }
            gap={2}
            width={"60%"}
          >
            <FormControl
              style={{
                width: "30%",
              }}
            >
              <InputLabel id="demo-simple-select-label">Input type</InputLabel>
              <Select
                size="small"
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={emailOrBadge}
                label="Input type"
                onChange={handleChange}
              >
                <MenuItem value={_emailOrBadge.email}>Email</MenuItem>
                <MenuItem value={_emailOrBadge.badgeId}>Badge Id</MenuItem>
              </Select>
            </FormControl>
            {emailOrBadge === _emailOrBadge.email ? (
              <EmailAddressTextField
                fontSize={16}
                fieldWidth={"30%"}
                validatedUserEmail={requesterEmail}
                setValidatedUserEmail={setRequesterEmail}
                hasErrors={requesterInvalid}
                setHasErrors={(value) => setRequesterInvalid(value)}
                isDisabled={false}
                labelText="Requester"
                placeholderText="Email"
                showPlusMeButton={false}
                margin="none"
              />
            ) : (
              <VendorInputField
                style={{
                  width: "30%",
                  marginTop: "10px",
                }}
                value={badgeId}
                setValue={setBadgeId}
                isValid={isValidBadgeId}
                setIsValid={setIsValidBadgeId}
                isDisabled={false}
              />
            )}
          </Box>
          <Box display="flex" flexDirection="row" width={"60%"}>
            <Autocomplete
              style={{ width: "30%" }}
              noOptionsText={"Loading Locations..."}
              value={submitInputsValue.deliveryLocation}
              onChange={(_, value) =>
                setSubmitInputsValue((prevState) => {
                  return { ...prevState, deliveryLocation: value };
                })
              }
              disablePortal
              options={locationList.map((label) =>
                label.locationName.toString()
              )}
              renderInput={(params) => (
                <TextField
                  name="deliverto"
                  {...params}
                  label="Deliver to"
                  size="small"
                />
              )}
              autoSelect
            />
          </Box>
          <Box
            display="grid"
            gridTemplateColumns="1fr 1fr"
            gap={2}
            // maxWidth="100%"
          >
            <Typography>
              Charge info (You must provide at least a{" "}
              <strong>Work Order #</strong>, <strong>Cost Center</strong> or a{" "}
              <strong>Cost Code</strong> to proceed):
            </Typography>
          </Box>
          <Box display="flex" gap={2} alignItems="center" maxWidth="80%">
            <TextField
              size="small"
              type="text"
              name="wo"
              label="WO #"
              value={submitInputsValue.workOrder}
              onChange={(e) =>
                setSubmitInputsValue((prevState) => {
                  return { ...prevState, workOrder: e.target.value };
                })
              }
            />
            <TextField
              size="small"
              type="text"
              name="costCenter"
              label="Cost Center"
              value={submitInputsValue.costCenter}
              onChange={(e) =>
                setSubmitInputsValue((prevState) => {
                  return {
                    ...prevState,
                    costCenter: e.target.value.toUpperCase(),
                  };
                })
              }
            />
            <BillingInfoField
              billingValue={submitInputsValue.wbs}
              handleChange={(e) => {
                setSubmitInputsValue((prevState) => {
                  return { ...prevState, wbs: e };
                });
              }}
              margin="none"
              fieldWidth="32rem"
              marginLeft={null}
              marginRight={null}
            />
            <GlobalButton
              variant="contained"
              type="button"
              disabled={
                !selectedItems.length ||
                isSubmittingRequest ||
                isValidEmailOrBadgeID() ||
                !submitInputsValue.deliveryLocation ||
                !(
                  submitInputsValue.workOrder ||
                  submitInputsValue.costCenter ||
                  submitInputsValue.wbs
                )
              }
              onClick={() => {
                if (selectedItems.length === 0) {
                  OpenModalByType("displayWarningSubmitMessage");
                } else {
                  if (
                    !isCartOnlyChemicals &&
                    submitInputsValue.deliveryLocation?.toLowerCase() !==
                      "counter"
                  ) {
                    OpenModalByType("displayWarningChemicalMessage");
                  } else {
                    createRequest();
                  }
                }
              }}
            >
              Submit
            </GlobalButton>
          </Box>
        </Box>
      </Box>
      <Box display="flex" flexDirection="column" marginTop={2}>
        <UXDataTableWithoutBody
          tableWidth="100%"
          cols={itemsSelectedCols}
          blankFirstHeader
          tableId="selectedItemsTable"
          rowLength={selectedItems.length}
          enablePaging
          rowsPerPage={rowsPerPage}
          page={page}
          handleChangePage={handlePageChange}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          noDataFoundMessage={"No items selected"}
          // enableAddIcon
          // addToolTipText="Add item by ID"
          // addFunction={() => {
          //   OpenModalByType("addItemById");
          // }}
        >
          <StyledTableBody>
            {selectedItems.length > 0 &&
              selectedItems
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((item) => {
                  return (
                    <StyledTableRow key={item?.id}>
                      <StyledTableCellSmall>
                        <Box
                          display="flex"
                          alignItems="center"
                          gap={1}
                          justifyContent="center"
                        >
                          <IconButton
                            style={{ padding: 0 }}
                            onClick={() => {
                              setSelectedItem(item);
                              OpenModalByType("deleteItem");
                            }}
                          >
                            <Delete fontSize="small" />
                          </IconButton>
                          {item?.imagePath && (
                            <IconButton
                              style={{ padding: 0 }}
                              onClick={() => {
                                setItemImage("");
                                convertImagePath(item?.imagePath);
                                setItemID(item?.siteAssetNumber);
                                OpenModalByType("displayItemImage");
                              }}
                            >
                              <CameraAltOutlined fontSize="small" />
                            </IconButton>
                          )}

                          <IconButton
                            style={{ padding: 0 }}
                            onClick={() => {
                              setQuantity(item?.quantity);
                              setSelectedItem(item);
                              OpenModalByType("updateItemQuantity");
                            }}
                          >
                            <AddCircleOutline fontSize="small" />
                          </IconButton>
                        </Box>
                      </StyledTableCellSmall>
                      <StyledTableCellMedium>
                        {item?.siteAssetNumber}
                      </StyledTableCellMedium>
                      <StyledTableCellLarge>
                        {item?.description}
                      </StyledTableCellLarge>
                      <StyledTableCellMedium>
                        {item?.quantity}
                      </StyledTableCellMedium>
                      <StyledTableCellMedium>
                        {item?.stockUnitOfMeasure?.toUpperCase()}
                      </StyledTableCellMedium>
                      <StyledTableCellMedium>
                        ${(item?.equipmentPriceUSD * item?.quantity).toFixed(2)}
                      </StyledTableCellMedium>
                    </StyledTableRow>
                  );
                })}
          </StyledTableBody>
        </UXDataTableWithoutBody>
        <Box
          display="flex"
          alignItems="center"
          gap={1}
          justifyContent="flex-end"
          marginRight={2}
          marginTop={2}
        >
          <GlobalSecondaryButton
            onClick={() => setSelectedItems([])}
            variant="contained"
          >
            Clear
          </GlobalSecondaryButton>
          <Typography>Order total: ${totalOrder.toFixed(2)}</Typography>
        </Box>
      </Box>
      <Box display="flex" flexDirection="column" gap={2} marginTop={10}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography component="h2" fontSize={30}>
            Stockroom Inventory
          </Typography>
        </Box>
        <FilterRequest
          inventoryItems={inventoryItems}
          setFilteredItems={setFilteredItems}
          setInventoryPage={setInventoryPage}
          categories={categories}
        />
        <UXDataTableWithoutBody
          tableWidth="100%"
          cols={inventoryCols}
          blankFirstHeader
          tableId="inventoryTable"
          rowLength={filteredItems.length}
          enablePaging
          rowsPerPage={inventoryRowsPerPage}
          page={inventoryPage}
          handleChangePage={handleInventoryPageChange}
          handleChangeRowsPerPage={handleChangeInventoryRowsPerPage}
          noDataFoundMessage={"No items found"}
          isDataLoading={isDataLoading}
        >
          <StyledTableBody>
            {filteredItems.length > 0 &&
              filteredItems
                .sort((a, b) =>
                  a?.siteAssetNumber?.localeCompare(b?.siteAssetNumber)
                )
                .slice(
                  inventoryPage * inventoryRowsPerPage,
                  inventoryPage * inventoryRowsPerPage + inventoryRowsPerPage
                )
                .map((item) => {
                  return (
                    <StyledTableRow key={item?.id}>
                      <StyledTableCellSmall>
                        <Box
                          display="flex"
                          alignItems="center"
                          gap={1}
                          justifyContent="center"
                        >
                          <IconButton
                            style={{ padding: 0 }}
                            onClick={() => {
                              setSelectedItem(item);
                              OpenModalByType("addItem");
                            }}
                          >
                            <AddCircleOutline fontSize="small" />
                          </IconButton>
                          {item?.imagePath && (
                            <IconButton
                              style={{ padding: 0 }}
                              onClick={() => {
                                setItemImage("");
                                convertImagePath(item?.imagePath);
                                setItemID(item?.siteAssetNumber);
                                OpenModalByType("displayItemImage");
                              }}
                            >
                              <CameraAltOutlined fontSize="small" />
                            </IconButton>
                          )}
                        </Box>
                      </StyledTableCellSmall>
                      <StyledTableCellMedium>
                        {item?.siteAssetNumber}
                      </StyledTableCellMedium>
                      <StyledTableCellLarge>
                        {item?.description}
                      </StyledTableCellLarge>
                      <StyledTableCellMedium>
                        {item?.quantity}
                      </StyledTableCellMedium>
                      <StyledTableCellMedium>
                        {item?.stockUnitOfMeasure?.toUpperCase()}
                      </StyledTableCellMedium>
                      <StyledTableCellMedium>
                        ${item?.equipmentPriceUSD?.toFixed(2)}
                      </StyledTableCellMedium>
                    </StyledTableRow>
                  );
                })}
          </StyledTableBody>
        </UXDataTableWithoutBody>
      </Box>
      {isModalOpen && handleSelectModal()}
      <FilterMenu
        open={filteringOpen}
        setOpen={setFilteringOpen}
        applyBtnAction={() => setFilteringOpen(false)}
        cancelButtonAction={() => setFilteringOpen(false)}
        filteringInfo={filterOptions}
        appliedFilters={filters}
        setAppliedFilters={setFilters}
      />
      <ModalSimpleButton
        title={modalErrorMessage.title}
        buttonText="Close"
        buttonAction={() => {
          setModalErrorMessage((prev) => ({ ...prev, isOpen: false }));
        }}
        setOpen={() => {
          setModalErrorMessage((prev) => ({ ...prev, isOpen: false }));
        }}
        open={modalErrorMessage.isOpen}
      >
        <Typography
          style={{ padding: "0 16px", fontSize: 18, textAlign: "center" }}
        >
          {modalErrorMessage.message}
        </Typography>
      </ModalSimpleButton>
    </Box>
  );
};

export default StockroomOrder;
