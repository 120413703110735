import React from "react";

const InventoryMasterDataManagement = ({ ...props }) => {
    return (
      <div>
        <p>
          Coming soon to a browser near you!
        </p>
        <ul>
          <li>Authorization Request Status</li>
          <li>Chem ID Category</li>
          <li>Container Status</li>
          <li>Container Type</li>
          <li>(Experimental) Substance Type
              <ul>
                  <li>(Experimental) Substance Subtype 1</li>
                  <li>(Experimental) Substance Subtype 2</li>
                  <li>(Experimental) Substance Subtype 3</li>
              </ul>
          </li>
          <li>Special Handling Status</li>
          <li>Substance Status</li>
        </ul>
      </div>
    );
  };
  
  export default InventoryMasterDataManagement;