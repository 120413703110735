import { styled } from '@mui/material/styles';
import { StyledTableCell } from '../../components/UXDataTable'

export const StyledTableCellExtraSmall = styled(StyledTableCell)({
  width: 10,
});

export const StyledTableCellSmall = styled(StyledTableCell)({
  width: 30,
});

export const StyledTableClickableCell = styled(StyledTableCell)({
  width: 130,
  cursor: 'pointer',
  textDecoration: 'underline',
  color: 'blue'
});