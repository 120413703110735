import {Box, Typography } from '@mui/material'
import { styled } from '@mui/material/styles';
import { StyledTableCell } from '../../components/UXDataTable'

export const StyledForm = styled('form')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: 16,
});

export const StyledGridForm = styled('form')({
  display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    gridGap: 16,
});

export const StyledTableCellSmall = styled(StyledTableCell)({
  width: 30,
});

export const StyledTableCellSmallHoverable = styled(StyledTableCell)({
  width: 30,
  cursor: 'pointer',

  '&:hover': {
    backgroundColor: '#c1c1ed',
  },
});

export const StyledTableCellMedium = styled(StyledTableCell)({
  width: 130,
});

export const StyledClickableTableCellMedium = styled(StyledTableCell)({
  width: 130,
  cursor: 'pointer',
  transition: 'all 0.2s ease-in-out',

  '&:hover': {
    backgroundColor: '#fafafa',
  },
});

export const StyledTableCellLarge = styled(StyledTableCell)({
  width: 260,
});

export const StyledScrollingBox = styled(Box)({
  height: '80%',
  width: "100%",
  overflowY: 'auto',
  paddingRight: 20,

  '&::-webkit-scrollbar': {
    width: '10px',
    height: '10px',
  },

  '&::-webkit-scrollbar-track': {
    background: 'rgba(0, 0, 0, 0.04)',
  },

  '&::-webkit-scrollbar-thumb': {
    background: '#1976d2',
    borderRadius: '4px',
  },
});

export const StyledWarningBox = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  gap: 8,
  width: '60%',
  borderRadius: 8,
  padding: '8px 16px',
  backgroundColor: 'rgba(219, 218, 18, 0.15)',
  marginBottom: 16,
});

export const StyledErrorMessage = styled(Typography)({
  color: 'red',
  fontSize: 14,
  marginTop: 8,
})