import React from "react";
import { BrowserRouter } from "react-router-dom";
import "./index.css";
import AuthProvider from "./authProvider";
import reportWebVitals from "./reportWebVitals";
import {createRoot} from "react-dom/client";

const container = document.getElementById("root");
const root = createRoot(container);
root.render(<BrowserRouter>
  <AuthProvider></AuthProvider>
</BrowserRouter>);

reportWebVitals();
