import React, { useContext } from "react";
import {
    Box,
    FormControl,
    styled,
    TextField,
    Autocomplete
} from "@mui/material";
import STEngineerComponent from "../../../api/StationaryTesting/STEngineer";
import STEngineerForm from "./STEngineerForm";
import { getMessage, hasRole, Roles } from "../../../global";
import ModalSimpleButton from '../../../components/Modal/ModalSimpleButton';
import UserContext from "../../../context/UserContext";
import { GlobalButton, GlobalSecondaryButton } from "../../styles";

const StyledBox = styled(Box)(() => ({
    display: 'flex',
    marginBottom: "20px"
}));

const StyledFormControl = styled(FormControl)(() => ({
    display: 'flex',
    width: "50rem"
}));

const StyledDiv = styled('div')({
    display: 'flex',
    justifyContent: "space-between"
});


const STEngineer = (props) => {

    const initialStateEngineer = {
        id: undefined,
        fullName: "",
        engineerEmailAddress: ""
    };

    const validationsInitialState =
    {
        engineerEmailAddress: "",
    };

    const [isAdd, setIsAdd] = React.useState(false);
    const [infoData, setInfoData] = React.useState(initialStateEngineer);
    const [engineerList, setEngineerList] = React.useState(null);
    const [invalidField, setInvalidField] = React.useState(validationsInitialState);

    const [modalSimpleButton, setModalSimpleButton] = React.useState(false);
    const [modalSimpleButtonText, setModalSimpleButtonText] = React.useState('');
    const [modalSimpleTitle, setModalSimpleTitle] = React.useState('');
    const [modalSimpleText, setModalSimpleText] = React.useState('');

    const currentUser = useContext(UserContext)
    const roles = currentUser?.idTokenClaims.roles

    const canEdit = hasRole(Roles.Developer, roles) || hasRole(Roles.MethodAdministrator, roles)

    function closeModalSimpleButton() {
        setModalSimpleButton(false);
    }

    function openModalSimpleButton(title, text, buttonText) {
        setModalSimpleButtonText(buttonText)
        setModalSimpleButton(true);
        setModalSimpleTitle(title);
        setModalSimpleText(text);
    }

    function handleSubmit(event) {
        event.preventDefault();

        if (!validateFields()) {
            if (infoData.id !== undefined)
                saveEngineer();
            else {

                STEngineerComponent.get(infoData.engineerEmailAddress).then((engineerExists) => {
                    if ((engineerExists !== undefined && engineerExists !== null))
                        openModalSimpleButton('Fail', 'This ST Engineer name already exist', 'Ok');
                    else saveEngineer();
                });
            }
        }
    }

    function saveEngineer() {

        let engineer = new STEngineerComponent({
            fullName: infoData.fullName,
            engineerEmailAddress: infoData.engineerEmailAddress
        });

        if (infoData.id === undefined) {
            STEngineerComponent.add(engineer).then((res) => {
                if (res) {
                    resetForm();
                    openModalSimpleButton('Success', 'ST Engineer saved with success', 'Ok');
                    loadEngineerFilter();
                }
                else
                    openModalSimpleButton('Fail', 'Something went wrong, please try after sometime', 'Ok');
            });
        } else {
            STEngineerComponent.update(engineer).then((res) => {
                if (res) {
                    resetForm();
                    openModalSimpleButton('Success', 'ST Engineer updated with success', 'Ok');
                    loadEngineerFilter();
                }
                else
                    openModalSimpleButton('Fail', 'Something went wrong, please try after sometime', 'Ok');
            });
        }
    }

    function handleChangeIsAdd() {
        setIsAdd(true);
        resetForm();
    }


    function loadEngineerFilter() {
        STEngineerComponent.getAll().then((res) => {
            if (res.length > 0) {

                let array = [];
                array.push({ id: undefined, engineerEmailAddress: "" });
                array.push(...res);

                setEngineerList(array);
            }
        });
    }

    function validateFields() {
        let requiredEngineerEmailAddress = infoData.engineerEmailAddress === "" ? getMessage('REQUIRED_FIELD') : "";

        setInvalidField({
            ...invalidField,
            engineerEmailAddress: requiredEngineerEmailAddress
        });

        return ((requiredEngineerEmailAddress !== ""));
    }

    function resetForm() {

        setInfoData(initialStateEngineer);
        setInvalidField(validationsInitialState);
    }

    function handleChangeSTEngineerFilter(idSelected) {
        setInfoData(initialStateEngineer);

        setInfoData(() => ({
            ...initialStateEngineer,
            id: idSelected,
            engineerEmailAddress: idSelected
        }));

        setIsAdd(true);

        setInvalidField(validationsInitialState);

        if (idSelected !== "") {
            STEngineerComponent.get(idSelected).then((engineer) => {
                if (engineer !== null) {

                    setInfoData(() => ({
                        ...infoData,
                        id: idSelected,
                        fullName: engineer.fullName,
                        engineerEmailAddress: engineer.engineerEmailAddress
                    }));
                }
            });
        }
    }

    React.useMemo(() => {
        if (engineerList === null) {
            loadEngineerFilter();
        }
    }, [engineerList])

    return (
        <div>
            <form onSubmit={handleSubmit}>
                <StyledDiv>
                    <div>
                        <StyledBox>
                            <StyledFormControl sx={{ m: 1, width: "35rem" }} variant='outlined'>

                                <StyledBox sx={{ justifyContent: "space-between" }}>
                                    <Autocomplete
                                        noOptionsText={"Loading ST Engineer..."}
                                        value={infoData.engineerEmailAddress === '' ? null : infoData.engineerEmailAddress}
                                        onChange={(e, value) => {
                                            handleChangeSTEngineerFilter(value);
                                        }}
                                        disablePortal
                                        options={engineerList ? engineerList.map((engineer) => engineer.engineerEmailAddress) : []}
                                        sx={{ width: "35rem" }}
                                        renderInput={(params) => <TextField {...params} variant="outlined" size="small" margin="none" label="ST Engineer" />}
                                        autoSelect
                                    />

                                    <GlobalSecondaryButton sx={{ width: "8rem", marginLeft:"1rem" }}
                                        variant='contained'
                                        engineer="button"
                                        onClick={handleChangeIsAdd}
                                    >Add New</GlobalSecondaryButton>

                                </StyledBox>

                            </StyledFormControl>

                        </StyledBox>

                        {isAdd === true && <STEngineerForm
                            infoData={infoData} setInfoData={setInfoData}
                            invalidField={invalidField}
                            handleChangeSTEngineerFilter={handleChangeSTEngineerFilter}
                            canEdit={canEdit}
                        >
                        </STEngineerForm>}

                        {
                            isAdd === true &&
                            <div>
                                <StyledBox sx={{ mb: 1, mt: 2, justifyContent: "space-between" }}>
                                    <GlobalButton sx={{ width: "8rem" }}
                                        variant='contained'
                                        type="submit"
                                        disabled={!canEdit}>Save</GlobalButton>
                                </StyledBox>
                            </div>
                        }

                    </div>

                </StyledDiv>

            </form >

            <ModalSimpleButton title={modalSimpleTitle} buttonText={modalSimpleButtonText} buttonAction={closeModalSimpleButton} open={modalSimpleButton} setOpen={setModalSimpleButton}>
                <label>
                    {modalSimpleText}
                </label>
            </ModalSimpleButton>

        </div >
    )
}

export default STEngineer;