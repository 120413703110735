import { useEffect } from "react";
import {Grid, Typography, TextField, FormControlLabel, Checkbox} from "@mui/material";
import { useState } from "react";
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { ChemIDSearchField } from '../../../components/ChemIDSearch/index'
import Substance from './../../../api/LIMS/Substance'
import Request from '../../../api/Inventory/NewChemicalAuthorizationRequest'
import ModalSimpleButton from "../../../components/Modal/ModalSimpleButton";
import FileDragAndDrop from "../../../components/FileDragAndDrop";
import { GlobalButton, GlobalSecondaryButton } from "../../../pages/styles";
import { DatePickerKeyDownEvent } from "../../../global";


const completedStatus = 'Complete';

export function SHEReviewManagement(props) {
  const selectedVendor = props.selectedVendor;
  const canEdit = props.canEdit;
  const currentUser = props.currentUser;
  const [isChecked, setIsChecked] = useState(false);
  const [isCheckedPHS1, setIsCheckedPHS1] = useState(selectedVendor?.isPHSCat1);
  const [isCheckedPHS2, setIsCheckedPHS2] = useState(selectedVendor?.isPHSCat2);
  const [isCheckedPHS3, setIsCheckedPHS3] = useState(selectedVendor?.isPHSCat3);

  const [isChronic, setIsChronic] = useState(selectedVendor?.isChronic);

  const [isPeroxidizable, setIsPeroxidizable] = useState(
    selectedVendor?.isPeroxidizable
  );

  const [modalMessagesOpen, setModalMessagesOpen] = useState(false)
  const [modalMessagesText, setModalMessagesText] = useState('')
  const [modalMessagesTitle, setModalMessagesTitle] = useState('')
  const [modalMessagesButtonText, setModalMessagesButtonText] = useState('Ok')
  const [isReloadList, setIsReloadList] = useState(false)
  const [disabledSubmit, setDisabledSubmit] = useState(false);

  useEffect(() => {
    let chem = ''
    async function getChem() {
      if (props.selectedVendor && props.selectedVendor.midasChemID && props.selectedVendor.midasChemID !== '') {
        chem = await Substance.getByChemID(props.selectedVendor.midasChemID, true, false)
      } else if (props.selectedVendor.productChemID && !props.selectedVendor.isNewToSite) {
        chem = await Substance.getByChemID(props.selectedVendor.productChemID, true, false)
      }

      if (chem && chem.result && chem.message === 'Success') {
        setSubmitInputsValue({
          ...props.selectedVendor,
          midasChemID: chem.result,
          nfpah: chem.result.nfpah,
          nfpaf: chem.result.nfpaf,
          nfpar: chem.result.nfpar,
          hmish: chem.result.hmish,
          hmisf: chem.result.hmisf,
          hmisr: chem.result.hmisr,
          isChronic: chem.result.isChronic,
          isPHSCat1: chem.result.isPHSCat1,
          isPHSCat2: chem.result.isPHSCat2,
          isPHSCat3: chem.result.isPHSCat3,
          isPeroxidizable: chem.result.isPeroxidizable,
        })
      } else {
        setSubmitInputsValue({
          ...props.selectedVendor,
          midasChemID: null
        })
      }  
    }

    getChem()

  }, [props.selectedVendor])

  const [hasChemIDErrors, setHasChemIDErrors] = useState(false)

  const [submitInputsValue, setSubmitInputsValue] = useState({
    id: selectedVendor.id,
    createdByEmail: selectedVendor.createdByEmail,
    createdDate: selectedVendor.createdDate,
    lastModifiedByEmail: selectedVendor.lastModifiedByEmail,
    lastModifiedDate: selectedVendor.lastModifiedDate,
    requestedForEmail: selectedVendor.requestedForEmail,
    requiredDate: selectedVendor.requiredDate,
    employeeSection: selectedVendor.employeeSection,
    requestComments: selectedVendor.requestComments,
    productName: selectedVendor.productName,
    productChemID: selectedVendor.productChemID,
    isEngineeredNanomaterial: selectedVendor.isEngineeredNanomaterial,
    estimatedAnnualPurchaseAmount: selectedVendor.estimatedAnnualPurchaseAmount,
    estimatedAnnualPurchaseAmountUoMName: selectedVendor.estimatedAnnualPurchaseAmountUoMName,
    intendedUse: selectedVendor.intendedUse,
    knownSafetyRequirements: selectedVendor.knownSafetyRequirements,
    isNewToSite: selectedVendor.isNewToSite,
    reviewerNotes: selectedVendor.reviewerNotes,
    ChemicalIdentification: selectedVendor.ChemicalIdentification,
    nfpah: selectedVendor.nfpah,
    nfpaf: selectedVendor.nfpaf,
    nfpar: selectedVendor.nfpar,
    hmish: selectedVendor.hmish,
    hmisf: selectedVendor.hmisf,
    hmisr: selectedVendor.hmisr,
    isChronic: selectedVendor.isChronic,
    isPHSCat1: selectedVendor.isPHSCat1,
    isPHSCat2: selectedVendor.isPHSCat2,
    isPHSCat3: selectedVendor.isPHSCat3,
    isPeroxidizable: selectedVendor.isPeroxidizable,
    reviewedByEmail: selectedVendor.reviewedByEmail,
    approvedDate: selectedVendor.approvedDate,
    midasChemID: selectedVendor.midasChemID,
    statusName: selectedVendor.statusName,
    fileAttachments: selectedVendor.fileAttachments,
  });

  const handleCheck = () => {
    setIsChecked(!isChecked);
  };

  function openModalMessages(title, text) {
    setModalMessagesOpen(true);
    setModalMessagesTitle(title);
    setModalMessagesText(text);
  }

  function closeModalMessages() {
    setModalMessagesOpen(false);
    setModalMessagesText('')
    setModalMessagesButtonText('Ok')
    setModalMessagesTitle('')

    if (isReloadList) {
      setIsReloadList(false)
      props.reloadList()
    }
  }

  const handlePHSCat = (e, id) => {
    if (id === "1") {
      setIsCheckedPHS1(!isCheckedPHS1);
    } else if (id === "2") {
      setIsCheckedPHS2(!isCheckedPHS2);
    } else if (id === "3") {
      setIsCheckedPHS3(!isCheckedPHS3);
    }
  };

  const handleChronic = () => {
    setIsChronic(!isChronic);
  };

  const handlePeroxidizable = () => {
    setIsPeroxidizable(!isPeroxidizable);
  };

  const handleUpdate = async (saveType) => {
    if (saveType === 'approve' && !submitInputsValue.midasChemID) {
      setHasChemIDErrors(true);
      return;
    }

    const payload = {
      id: submitInputsValue.id,
      createdByEmail: submitInputsValue.createdByEmail,
      createdDate: submitInputsValue.createdDate,
      lastModifiedByEmail: submitInputsValue.lastModifiedByEmail,
      lastModifiedDate: submitInputsValue.lastModifiedDate,
      requestedForEmail: submitInputsValue.requestedForEmail,
      requiredDate: submitInputsValue.requiredDate,
      employeeSection: submitInputsValue.employeeSection,
      productName: submitInputsValue.productName,
      productChemID: submitInputsValue.productChemID,
      isEngineeredNanomaterial: submitInputsValue.isEngineeredNanomaterial,
      estimatedAnnualPurchaseAmount: submitInputsValue.estimatedAnnualPurchaseAmount,
      estimatedAnnualPurchaseAmountUoMName: submitInputsValue.estimatedAnnualPurchaseAmountUoMName,
      intendedUse: submitInputsValue.intendedUse,
      knownSafetyRequirements: submitInputsValue.knownSafetyRequirements,
      isNewToSite: submitInputsValue.isNewToSite,
      reviewerNotes: submitInputsValue.reviewerNotes,
      ChemicalIdentification: submitInputsValue.ChemicalIdentification,
      nfpah: submitInputsValue.nfpah,
      nfpaf: submitInputsValue.nfpaf,
      nfpar: submitInputsValue.nfpar,
      hmish: submitInputsValue.hmish,
      hmisf: submitInputsValue.hmisf,
      hmisr: submitInputsValue.hmisr,
      isChronic: submitInputsValue.isChronic,
      isPHSCat1: submitInputsValue.isPHSCat1,
      isPHSCat2: submitInputsValue.isPHSCat2,
      isPHSCat3: submitInputsValue.isPHSCat3,
      isPeroxidizable: submitInputsValue.isPeroxidizable,
      reviewedByEmail: submitInputsValue.reviewedByEmail,
      approvedDate: submitInputsValue.approvedDate,
      midasChemID: submitInputsValue.midasChemID?.chemID ? submitInputsValue.midasChemID.chemID : submitInputsValue.midasChemID,
      requestComments: submitInputsValue.requestComments
    };

    if (saveType === 'progress') {
      payload.statusName = 'InProgress';
    }
    else

      if (saveType === 'approve') {

        payload.statusName = completedStatus;
        payload.approvedDate = new Date();
        payload.reviewedByEmail = currentUser.username;
      }
      else

        if (saveType === 'reject') {

          payload.statusName = 'Rejected';
          payload.reviewedByEmail = currentUser.username;
        }
        else {
          payload.statusName = submitInputsValue.statusName;

        }
        setDisabledSubmit(true)
        var myResult = await Request.UpdateChemicalAuthorizationRequest(payload)
        setDisabledSubmit(false)

        if (myResult.message === 'Success')
        {
          openModalMessages("NCA Saved Successfully", "You request has been saved!")
  
        } else {
          if (myResult.message)
          {
            openModalMessages("NCA Save Failed", `Your request failed to save.  ${myResult.message}. Contact support if you feel this is an error.`);
          } else {
            openModalMessages('NCA Save Failed', `Your request failed to save.  Unspecified Error, Contact support if you feel this is an error.`);
          }
        }

        if (saveType === 'reject' || saveType === 'approve') {
          setIsReloadList(true)
        }
  };

  function handleChangeChemID(chem) {
    if (chem) {
      setSubmitInputsValue({
        ...submitInputsValue,
        midasChemID: chem,
        nfpah: chem.nfpah,
        nfpaf: chem.nfpaf,
        nfpar: chem.nfpar,
        hmish: chem.hmish,
        hmisf: chem.hmisf,
        hmisr: chem.hmisr,
        isChronic: chem.isChronic,
        isPHSCat1: chem.isPHSCat1,
        isPHSCat2: chem.isPHSCat2,
        isPHSCat3: chem.isPHSCat3,
        isPeroxidizable: chem.isPeroxidizable,
      })
    } else {
      setSubmitInputsValue({
        ...submitInputsValue,
        midasChemID: chem
      })
    }
  }

  const gridStyle = {
    fontSize: "10px",
    fontFamily: "EMprint",
    textTransform: "none",
  };

  function handleCreateNewChem() {
    if (submitInputsValue.statusName !== completedStatus && submitInputsValue.statusName !== 'Rejected') {
      handleUpdate('progress');
    }

    //navigate to the substance tab
    props.setTabValue(1)
    //history.push('/inventory/substances')
  }

  return (
    <Grid paddingLeft={2} paddingTop={3} paddingBottom={1} style={gridStyle} ref={props.formSHEReviewRef}>
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <Typography variant="h6" gutterBottom>
            Requestor Information
          </Typography>
          <br />
          <Grid container spacing={2}>
            <Grid item xs={7}>
              <TextField
                fullWidth
                label="Requester Email"
                size="small"
                InputProps={{
                  readOnly: true,
                }}
                value={submitInputsValue?.requestedForEmail}
                onChange={(e) =>
                  setSubmitInputsValue({
                    ...submitInputsValue,
                    requestedForEmail: e.target.value,
                  })
                }
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                fullWidth
                label="Section"
                size="small"
                // InputProps={{
                //   readOnly: true,
                // }}
                value={submitInputsValue?.employeeSection ? submitInputsValue?.employeeSection : ''}
                onChange={(e) =>
                  setSubmitInputsValue({
                    ...submitInputsValue,
                    employeeSection: e.target.value,
                  })
                }
                disabled={!canEdit}
              />
            </Grid>

            <Grid item xs={11}>
              <TextField
                fullWidth
                label="Request comments"
                size="small"
                // InputProps={{
                //   readOnly: true,
                // }}
                value={submitInputsValue.requestComments ? submitInputsValue.requestComments : ''}
                onChange={(e) =>
                  setSubmitInputsValue({
                    ...submitInputsValue,
                    requestComments: e.target.value,
                  })
                }
                disabled={!canEdit}
              />
            </Grid>
            <Grid item xs={5}>
              <LocalizationProvider dateAdapter={AdapterDateFns} >
                <DesktopDatePicker
                  label="Date Requested By"
                  value={submitInputsValue.requiredDate}
                  disabled
                  onChange={(e) => { setSubmitInputsValue({ ...submitInputsValue, requiredDate: e }) }}
                  renderInput={(params) => <TextField onKeyDown={DatePickerKeyDownEvent} size="small" {...params} fullWidth />}
                  fullWidth={true}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label="Last Updated By"
                size="small"
                disabled={true}
                InputProps={{
                  readOnly: true,
                }}
                value={submitInputsValue?.lastModifiedByEmail ? submitInputsValue?.lastModifiedByEmail : ''}
              />
              {/* <StyledInputLabel sx={{ position: "relative" }}>Last Updated By <b>{submitInputsValue.lastModifiedByEmail}</b> on
                <b> {convertToLocalTime(submitInputsValue.lastModifiedDate)}</b>
              </StyledInputLabel> */}
            </Grid>
            <Grid item xs={11}>
              <FileDragAndDrop disabled={true} showFiles={true} files={submitInputsValue.fileAttachments} container={'midasanalyticalfiles'}></FileDragAndDrop>
            </Grid>
          </Grid>
          <Typography variant="h6" gutterBottom paddingTop={3}>
            Chemical Safety Information
          </Typography>
          <br />
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Grid container spacing={2} >
                <Grid item xs={7}>
                  <ChemIDSearchField
                    fontSize={14}
                    fieldWidth="100%"
                    selectedChemIDObject={submitInputsValue.midasChemID}
                    setSelectedChemIDObject={(e) => handleChangeChemID(e)}
                    hasErrors={hasChemIDErrors}
                    setHasErrors={setHasChemIDErrors}
                    isDisabled={!canEdit}
                    allowInactiveChemIDs={true}
                    returnSubstanceStrucureData={false}
                  />
                </Grid>
                <Grid item xs={4} style={{
                  'display': 'flex',
                  'align-items': 'center',
                  'margin-top': '8px',
                  'margin-left': '10px'
                }}>
                  <GlobalSecondaryButton variant="contained" size="medium" onClick={handleCreateNewChem} disabled={!submitInputsValue.isNewToSite}>
                    Create New Chem ID
                  </GlobalSecondaryButton>
                </Grid>
                <Grid item xs={2}>
                  {/* <Button variant="outlined">NFPA H</Button> */}
                  <TextField
                    fullWidth
                    label="NFPA H"
                    size="small"
                    disabled={true}
                    value={submitInputsValue?.nfpah ?? ''}
                    onChange={(e) =>
                      setSubmitInputsValue({
                        ...submitInputsValue,
                        nfpah: e.target.value,
                      })
                    }
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    fullWidth
                    label="HMIS H"
                    size="small"
                    disabled={true}
                    value={submitInputsValue?.hmish ?? ''}
                    onChange={(e) =>
                      setSubmitInputsValue({
                        ...submitInputsValue,
                        hmish: e.target.value,
                      })
                    }
                  />
                </Grid>
                <Grid item xs={4}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={submitInputsValue?.isPHSCat1}
                        onChange={(e) => handlePHSCat(e, "1")}
                        disabled
                      />
                    }
                    label="PHS Category 1"
                  />
                </Grid>
                <Grid item xs={3}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={submitInputsValue?.isChronic}
                        onChange={(e) => handleChronic(e)}
                        disabled
                      />
                    }
                    label="Chronic"
                  />
                </Grid>
                <Grid item xs={2}>
                  <TextField
                    fullWidth
                    label="NFPA F"
                    size="small"
                    disabled={true}
                    value={submitInputsValue?.nfpaf ?? ''}
                    onChange={(e) =>
                      setSubmitInputsValue({
                        ...submitInputsValue,
                        nfpaf: e.target.value,
                      })
                    }
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    fullWidth
                    label="HMIS F"
                    size="small"
                    disabled={true}
                    value={submitInputsValue?.hmisf ?? ''}
                    onChange={(e) =>
                      setSubmitInputsValue({
                        ...submitInputsValue,
                        hmisf: e.target.value,
                      })
                    }
                  />
                </Grid>
                <Grid item xs={4}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={submitInputsValue?.isPHSCat2}
                        onChange={(e) => handlePHSCat(e, "2")}
                        disabled
                      />
                    }
                    label="PHS Category 2"
                  />
                </Grid>
                <Grid item xs={3}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={submitInputsValue?.isPeroxidizable}
                        onChange={(e) => handlePeroxidizable(e)}
                        disabled
                      />
                    }
                    label="Peroxidizable"
                  />
                </Grid>
                <Grid item xs={2}>
                  <TextField
                    fullWidth
                    label="NFPA R"
                    size="small"
                    disabled={true}
                    value={submitInputsValue?.nfpar ?? ''}
                    onChange={(e) =>
                      setSubmitInputsValue({
                        ...submitInputsValue,
                        nfpar: e.target.value,
                      })
                    }
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    fullWidth
                    label="HMIS R"
                    size="small"
                    disabled={true}
                    value={submitInputsValue?.hmisr ?? ''}
                    onChange={(e) =>
                      setSubmitInputsValue({
                        ...submitInputsValue,
                        hmisr: e.target.value,
                      })
                    }
                  />
                </Grid>
                <Grid item xs={4}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={submitInputsValue?.isPHSCat3}
                        onChange={(e) => handlePHSCat(e, "3")}
                        disabled
                      />
                    }
                    label="PHS Category 3"
                  />
                </Grid>
                {/* <Grid>
                  <RequestManageSubstancesModal open={showManageSubstances} setOpen={setShowManageSubstances} />
                </Grid> */}
                <Grid item xs={4}>
                  <GlobalButton
                    disabled={submitInputsValue.statusName === completedStatus || submitInputsValue.statusName === 'Rejected' || !canEdit || disabledSubmit}
                    variant='contained'
                    onClick={() => handleUpdate('approve')}
                  >Approve</GlobalButton>
                </Grid>
                <Grid item xs={4}>
                  <GlobalSecondaryButton
                    variant='contained'
                    disabled={submitInputsValue.statusName === completedStatus || submitInputsValue.statusName === 'Rejected' || !canEdit || disabledSubmit}
                    onClick={() => handleUpdate('reject')}
                  >Reject</GlobalSecondaryButton>
                </Grid>
                <Grid item xs={4}>
                  <GlobalButton
                    disabled={submitInputsValue.statusName === completedStatus || submitInputsValue.statusName === 'Rejected' || !canEdit || disabledSubmit}
                    variant='contained'
                    onClick={() => handleUpdate('progress')}
                  >Save Progress</GlobalButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={6}>
          <Typography variant="h6" gutterBottom>
            Chemical Information
          </Typography>
          <br />
          <Grid container spacing={2}>
            <Grid item xs={5}>
              <TextField
                fullWidth
                label="Product / Chemical Name "
                size="small"
                disabled={true}
                InputProps={{
                  readOnly: true,
                }}
                value={submitInputsValue?.productName}
                onChange={(e) =>
                  setSubmitInputsValue({
                    ...submitInputsValue,
                    productName: e.target.value,
                  })
                }
              />
            </Grid>
            <Grid item xs={5}>
              <TextField
                fullWidth
                label="Chemical Id"
                size="small"
                disabled={true}
                value={submitInputsValue?.productChemID}
                onChange={(e) =>
                  setSubmitInputsValue({
                    ...submitInputsValue,
                    productChemID: e.target.value,
                  })
                }
              />
            </Grid>

            <Grid item xs={3}>
              <TextField
                fullWidth
                label="Estimated Annual Quantity"
                size="small"
                disabled={true}
                value={submitInputsValue && submitInputsValue.estimatedAnnualPurchaseAmount ? submitInputsValue.estimatedAnnualPurchaseAmount : ''}
                onChange={(e) =>
                  setSubmitInputsValue({
                    ...submitInputsValue,
                    estimatedAnnualPurchaseAmount: e.target.value,
                  })
                }
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                fullWidth
                label="UoM"
                size="small"
                disabled={true}
                value={submitInputsValue && submitInputsValue.estimatedAnnualPurchaseAmountUoMName ? submitInputsValue.estimatedAnnualPurchaseAmountUoMName : ''}
                onChange={(e) =>
                  setSubmitInputsValue({
                    ...submitInputsValue,
                    estimatedAnnualPurchaseAmountUoMName: e.target.value,
                  })
                }
              />
            </Grid>
            <Grid item xs={5}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={submitInputsValue?.isEngineeredNanomaterial}
                    value={selectedVendor?.isEngineeredNanomaterial}
                    disabled
                  />
                }
                label="Engineered Nanomaterial"
              />
            </Grid>
            <Grid item xs={10}>
              <TextField
                fullWidth
                label="Intended Use"
                size="small"
                disabled={true}
                value={submitInputsValue?.intendedUse}
                onChange={(e) =>
                  setSubmitInputsValue({
                    ...submitInputsValue,
                    intendedUse: e.target.value,
                  })
                }
              />
            </Grid>
            <Grid item xs={10}>
              <TextField
                fullWidth
                label="Special SHE Requirements"
                size="small"
                disabled={true}
                value={submitInputsValue?.knownSafetyRequirements}
                onChange={(e) =>
                  setSubmitInputsValue({
                    ...submitInputsValue,
                    knownSafetyRequirements: e.target.value,
                  })
                }
              />
            </Grid>
            <Grid item xs={5}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isChecked}
                    value={submitInputsValue.isNewToSite}
                    disabled
                    onChange={(e) => handleCheck()}
                  ></Checkbox>
                }
                label="New To Site"
              />
            </Grid>
            <Grid item xs={5}>
              <FormControlLabel
                control={
                  <Checkbox
                    disabled
                    checked={!isChecked}
                    onChange={(e) => handleCheck()}
                  ></Checkbox>
                }
                label="Not registered in last 3 years"
              />
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item xs={10}>
              <TextField
                fullWidth
                label="Review Notes"
                multiline
                rows={5}
                value={submitInputsValue.reviewerNotes ? submitInputsValue.reviewerNotes : ''}
                onChange={(e) =>
                  setSubmitInputsValue({
                    ...submitInputsValue,
                    reviewerNotes: e.target.value,
                  })
                }
                disabled={!canEdit}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <ModalSimpleButton title={modalMessagesTitle} buttonText={modalMessagesButtonText} buttonAction={closeModalMessages} open={modalMessagesOpen} setOpen={setModalMessagesOpen}>
        <label>
          {modalMessagesText}
        </label>
      </ModalSimpleButton>
    </Grid>
  );
}

