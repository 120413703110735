import API from "..";

export default class ComponentRequest {
  
  constructor({
    id,
    chemID,
    quantityRequested,
    quantityRequestedUoMName,
    submitterComments,
    transactionComments,
    needByDate,
    receivedDate,
    requestStatus,
    requestStatusName,
    requestedForEmail,
    createdDate,
    createdByEmail,
    lastModifiedByEmail,
    lastModifiedDate,
    requestSubstance,
    requestedUoM,
  }) 
    
  {
    this.id = id;
    this.chemID = chemID;
    this.quantityRequested = quantityRequested;
    this.quantityRequestedUoMName = quantityRequestedUoMName;
    this.submitterComments = submitterComments;
    this.transactionComments = transactionComments;
    this.needByDate = needByDate;
    this.receivedDate = receivedDate;
    this.requestStatus = requestStatus;
    this.requestStatusName = requestStatusName;
    this.requestedForEmail = requestedForEmail;
    this.createdDate = createdDate;
    this.createdByEmail = createdByEmail;
    this.lastModifiedByEmail = lastModifiedByEmail;
    this.lastModifiedDate = lastModifiedDate;
    this.requestSubstance = requestSubstance;
    this.requestedUoM = requestedUoM;
  }

  static async getAll() {
    const api = await API();
    const { data } = await api.get(`/componentrequest`);
    return data.result.map((d) => new ComponentRequest(d));
  }

  static async create(componentRequest) {
    const api = await API();
    const { data } = await api.post(`/componentrequest`, componentRequest);
    return data;
    //return new ComponentRequest(data.result);
  }

  static async update(componentRequest) {
    const api = await API();
    const { data } = await api.put(`/componentrequest`, componentRequest);
    return data
    //return new ComponentRequest(data.result);
  }

}
