import {formatMidasNumber, displayHFRValue, displayPHSValue, convertDateFormat} from "../../global";
//import MoreOptions from "@mui/icons-material/MoreVert";
import {useState} from "react";
import { UXDataTableWithoutBody, StyledTableCell, UXDataTableNested, StyledTableBody, StyledTableRow } from "../../components/UXDataTable";
import {IconButton,TableRow, Checkbox} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

const requestCols = ['ID', 'Samples', 'Request Type', 'Business', 'Cost Code', 'Submitted By', 'Requested Completion','Requested By', 'Assigned To', 'Assigned Date']
const sampleCols = ['MIDAS Number', 'Sample Description', 'Additional Info','Chem ID','Parent Sample', 'HFR', 'PHS', 'Perox', 'Sample Owner', 'Project']

const SearchRequests = ({inputSearchCriteria, myWorkRequests, dataIsLoading}) => {
  const [sampleRowOpen, setSampleRowOpen] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  const noDataMessage = `No work request info found matching the quick search for = ${inputSearchCriteria}`

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleSamplesRowClick = (workRequestID) => {
    const sampleRowOpenIndex = sampleRowOpen.indexOf(workRequestID);
    let newSampleRowOpen = [];

    if (sampleRowOpenIndex === -1) {
      newSampleRowOpen = newSampleRowOpen.concat(sampleRowOpen, workRequestID);
    } else if (sampleRowOpenIndex === 0) {
      newSampleRowOpen = newSampleRowOpen.concat(sampleRowOpen.slice(1));
    } else if (sampleRowOpenIndex === sampleRowOpen.length - 1) {
      newSampleRowOpen = newSampleRowOpen.concat(sampleRowOpen.slice(0, -1));
    } else if (sampleRowOpenIndex > 0) {
      newSampleRowOpen = newSampleRowOpen.concat(
        sampleRowOpen.slice(0, sampleRowOpenIndex),
        sampleRowOpen.slice(sampleRowOpenIndex + 1)
      );
    }
    setSampleRowOpen(newSampleRowOpen);
  };

  const determineStartArray = () => {
    return (myWorkRequests.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage))
  }

  const isSampleRowOpen = (workRequestID) => sampleRowOpen.indexOf(workRequestID) !== -1;

  return (
    <>
    <UXDataTableWithoutBody 
      tableWidth='100%' 
      cols={requestCols} 
      blankFirstHeader={false}
      tableId="myWRTable"
      rowLength={myWorkRequests ? myWorkRequests.length : 0}
      enablePaging={true}
      rowsPerPage={rowsPerPage}
      page={page}
      handleChangePage={handleChangePage}
      handleChangeRowsPerPage={handleChangeRowsPerPage}
      noDataFoundMessage={noDataMessage}
      enableAddIcon={false}
      addFunction={null}
      addToolTipText={null}  
      isDataLoading={dataIsLoading}
      >

        {myWorkRequests && myWorkRequests.length > 0 ? (
          determineStartArray().map((workRequest, index) => {
          
            return (
              <StyledTableBody key={"myWRTablebody" + index}> 
                <StyledTableRow>
                  <StyledTableCell>{workRequest.id}</StyledTableCell>

                    <StyledTableCell style={{width:'5rem'}}>

                    {workRequest.workRequestSamples.length}

                      <IconButton
                        aria-label='expand row'
                        size='small'
                        onClick={() => handleSamplesRowClick(workRequest.id)}>
                        {isSampleRowOpen(workRequest.id) ? (
                          <KeyboardArrowUpIcon/>
                        ) : (
                          <KeyboardArrowDownIcon/>
                        )}
                      </IconButton>
                    </StyledTableCell>
                          
                    <StyledTableCell>{workRequest.typeName}</StyledTableCell>
                    <StyledTableCell>{workRequest.businessUnitName}</StyledTableCell>
                    <StyledTableCell>{workRequest.chargeCode}</StyledTableCell>
                    <StyledTableCell>{workRequest.createdByEmail}</StyledTableCell>
                    <StyledTableCell>{convertDateFormat(workRequest.requestedCompletionDate, false)}</StyledTableCell>
                    <StyledTableCell>{workRequest.requestedByEmail}</StyledTableCell>
                    <StyledTableCell>{workRequest.assignedToEmail}</StyledTableCell>
                    <StyledTableCell>{workRequest.assignedDate}</StyledTableCell>
                  </StyledTableRow>

                {isSampleRowOpen(workRequest.id) && (
                  <UXDataTableNested 
                  tableWidth={'100%'} 
                  cols={sampleCols} 
                  blankFirstHeader={false} 
                  colSpan={requestCols.length + 1} 
                  isOpenComparator={workRequest.id} 
                  isOpenArray={sampleRowOpen}>

                    {workRequest.workRequestSamples.map((oSample) => (
                    <StyledTableBody key={`${workRequest.id}${oSample.sample.sampleName}_"testtablebody"`}>
                      <StyledTableRow>
                        <StyledTableCell sx={{minWidth:"8rem"}}>{formatMidasNumber(oSample.sample.sampleName)}</StyledTableCell>
                        <StyledTableCell>{oSample.sample.description}</StyledTableCell>
                        <StyledTableCell>{oSample.sample.additionalSampleInformation}</StyledTableCell>
                        <StyledTableCell>{oSample.sample.chemID}</StyledTableCell>
                        <StyledTableCell>{oSample.sample.parentSampleContainer ? oSample.sample.parentSampleContainer.containerNumber : ''}</StyledTableCell>
                        <StyledTableCell>
                          {oSample.sample.substance ? displayHFRValue(oSample.sample.substance): ('?-?-?')}
                        </StyledTableCell>
                        <StyledTableCell>{oSample.sample.substance ? displayPHSValue(oSample.sample.substance) : ('???')}</StyledTableCell>
                        <StyledTableCell>
                          <Checkbox color='primary' checked={oSample.sample.substance && oSample.sample.substance.isPeroxidizable ? true : false} disabled={true} />
                        </StyledTableCell>
                        <StyledTableCell>{oSample.sample.sampleOwnerEmail}</StyledTableCell>
                        <StyledTableCell>{oSample.sample.project ? oSample.sample.project.name : oSample.sample.projectID}</StyledTableCell>
                      </StyledTableRow>
                      
                      </StyledTableBody>
                      ))}
                      </UXDataTableNested>
                    )}
                
                </StyledTableBody>
            );
          })
        ) : (
          <StyledTableBody key={"no WR found"}>
            <TableRow key={"nothing found wr"}>
              <StyledTableCell colSpan='14' key={"nothing found cells wr"}>
                <p>{noDataMessage}</p>
              </StyledTableCell>
          </TableRow>
          </StyledTableBody>
        )}
  </UXDataTableWithoutBody>
  </>
  );
};

export default SearchRequests;
