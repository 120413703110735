import { useEffect, useState, useRef } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import type { Interval, STRequestProcessResult } from "../../types";
import { useDetailedStRequest } from "../../context/DetailedStRequestContext";
import STRequestProcessResultSection from "./ExperimentProcessDetails/STRequestProcessResultSection";
import STIntervalProcessResultSection from "./ExperimentProcessDetails/STIntervalProcessResultSection";
import STRequestProcessResultApi from "../../../../api/StationaryTesting/STRequestProcessResult";
import STRequestIntervalApi from "../../../../api/StationaryTesting/STRequestInterval";

type ExperimentProcessDetailsViewProps = {
  saveSTRequestProcessResult(values: STRequestProcessResult[]): void;
  saveSTIntervalProcessResult(values: Interval[]): void;
};

export default function ExperimentProcessDetailsView({
  saveSTRequestProcessResult,
  saveSTIntervalProcessResult,
}: ExperimentProcessDetailsViewProps) {
  const { stRequestId } = useDetailedStRequest();
  const initialStRequestProcessResult = useRef<STRequestProcessResult[] | null>(
    null
  );
  const initialIntervals = useRef<Interval[] | null>(null);
  const [loading, setLoading] = useState(true);

  async function getStRequestProcessResults() {
    const stRequestProcessResults =
      await STRequestProcessResultApi.getByStRequestId(stRequestId);
    const intervalsWithIntervalProcessResults =
      await STRequestIntervalApi.getAllSTRequestIntervalsForRequest(
        stRequestId
      );

    initialStRequestProcessResult.current = stRequestProcessResults;
    saveSTRequestProcessResult(stRequestProcessResults);
    initialIntervals.current = intervalsWithIntervalProcessResults;
    saveSTIntervalProcessResult(intervalsWithIntervalProcessResults);
    setLoading(false);
  }

  useEffect(() => {
    getStRequestProcessResults();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stRequestId]);

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <STRequestProcessResultSection
        initialSTRequestProcessResults={
          initialStRequestProcessResult?.current ?? []
        }
        saveSTRequestProcessResult={saveSTRequestProcessResult}
      />
      <STIntervalProcessResultSection
        initialIntervals={initialIntervals?.current ?? []}
        saveSTIntervalProcessResult={saveSTIntervalProcessResult}
      />
    </div>
  );
}
