import React, { useState, useContext } from "react";
import { Box } from "@mui/material";
import SRRequests from "./SRRequests";
import SRReturns from "./SRReturns";
import SRReceiveStock from "./SRReceiveStock";
import SRManageStock from "./SRManageStock";
import SRManageVendor from "./SRManageVendor";
import SRManagePOs from "./SRManagePOs";
import SRReports from "./SRReports";
import { GlobalTabCollection, StyledTab } from "../../pages/styles";
import { hasRole, Roles } from "../../global";
import UserContext from "../../context/UserContext";

const StockroomManagement = () => {
  const [tabValue, setTabValue] = useState(0);
  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const currentUser = useContext(UserContext);
  const roles = currentUser?.idTokenClaims.roles;

  const stockRoomWorker = hasRole(Roles.StockroomWorker, roles);

  const canEdit =
    hasRole(Roles.Developer, roles) ||
    hasRole(Roles.StockroomAdmin, roles);

  const renderSearchTab = () => {
    switch (tabValue) {
      case 0: {
        return <SRRequests canEdit={canEdit || stockRoomWorker}/>
      }
      case 1: {
        return <SRReturns canEdit={canEdit || stockRoomWorker}/>
      }
      case 2: {
        return <SRReceiveStock canEdit={canEdit || stockRoomWorker}/>
      }
      case 3: {
        return <SRManageStock canEdit={canEdit}/>
      }
      case 4: {
        return <SRManageVendor canEdit={canEdit}/>
      }
      case 5: {
        return <SRManagePOs canEdit={canEdit}/>
      }
      case 6: {
        return <SRReports canEdit={canEdit || stockRoomWorker}/>
      }
      default: {
        alert(tabValue);
      }
    }
  };
  return (
    <div>
        <Box sx={{ bgcolor: "#fff"}}>
          <GlobalTabCollection value={tabValue} onChange={handleChange} aria-label='ant example'>
            <StyledTab label='Requests' />
            <StyledTab label='Returns' />
            <StyledTab label='Receive Stock' />
            <StyledTab label='Manage Stock' />
            <StyledTab label='Manage Vendor' />
            <StyledTab label='Manage POs' />
            <StyledTab label='Reports' />
          </GlobalTabCollection>
          <Box sx={{ p: 3 }} />
        </Box>
        {renderSearchTab()}
      </div>
  );
};

export default StockroomManagement;