import React, { useState, useEffect, useContext } from "react";
import UserContext from "../../context/UserContext";
import BlendStudy from "../../api/Formulations/BlendStudy";
import { UXDataTable } from "../../components/UXDataTable";

const myCols = [
  { field: 'studyName', headerName: 'Study Name', type: 'button-blends', path: '/blendstudy'},
  { field: 'createdDate', headerName: 'Date Created', type: 'datetime'},
  { field: 'blendStudyStatusName', headerName: 'Status', type: 'label' }
];


const MyRecentBlends = () => {

  const [studiesIsLoading, setStudiesIsLoading] = useState(true)
  const [myStudyInfo, setMyStudyInfo] = useState([])
  const currentUser = useContext(UserContext)
  
  let inputSearchCriteria = currentUser.username
  const noDataMessage = `No studies found for owner ${inputSearchCriteria}`

  //get all the studies
  useEffect(() => {
    let cancelPromise = false
      
      BlendStudy.getMyBlendStudies(inputSearchCriteria).then((res) => {
        if (cancelPromise) return
        setMyStudyInfo(res)
        setStudiesIsLoading(false)
        });
      
      return () => {
        cancelPromise = true
      }
  }, [inputSearchCriteria])

  
  return (
    <UXDataTable tableWidth='100%' cols={myCols} rows={myStudyInfo === null ? [] : myStudyInfo.sort((a, b) => new Date(b.createdDate) - new Date(a.createdDate))} moreOptionsCell={false} enablePaging={false} 
                    noDataMessage={noDataMessage} menuProps={null} defaultRowsPerPage={10} isDataLoading={studiesIsLoading} tableName={'recentBlendsWidget'} enableSorting={true}></UXDataTable>
  );
};

export default MyRecentBlends;
