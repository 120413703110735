import React, { useState, useEffect, useContext } from "react";
import {useLocation, useHistory} from 'react-router-dom';
import { styled } from "@mui/material/styles";
import { Box, Divider, Toolbar, Drawer, TextField, Checkbox, FormControlLabel, InputLabel, Accordion, AccordionSummary, 
    Typography, AccordionDetails, Button, Autocomplete, CircularProgress, FormGroup, Switch } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Sample from "../../../api/LIMS/Sample";
import SampleAttachment from "../../../api/LIMS/SampleAttachment";
import Container from "../../../api/LIMS/Container";
import Draft from "../../../api/LIMS/Draft";
import UnitOfMeasure from "../../../api/Admin/UnitOfMeasure";
import Location from "../../../api/Admin/Location";
import Project from "../../../api/Admin/Project";
import { convertToLocalTime, isNumber, hasRole, Roles, EvaluateOptionalNumberField, downloadFile, convertToBase64, convertBase64ToFile } from "../../../global";
import UserContext from "../../../context/UserContext";
import DeleteIcon from "@mui/icons-material/HighlightOff";
import ModalSimpleButton from "../../../components/Modal/ModalSimpleButton";
import ModalTwoButtons from "../../../components/Modal/ModalTwoButtons";
import ModalThreeButtons from "../../../components/Modal/ModalThreeButtons";
import { outlinedInputClasses } from "@mui/material/OutlinedInput";
import { inputLabelClasses } from "@mui/material/InputLabel";
import Template from "../../../api/LIMS/Template";
import { GlobalButton, GlobalSecondaryButton } from "../../../pages/styles";
import { ChemIDSearchField } from "../../../components/ChemIDSearch";
import PrintInformation from "../../../components/PrintInformation";
import PrintLabel from "../../../api/LIMS/PrintLabel";
import EmailAddressTextField from "../../../components/EmailAddressField";
import BillingInfoField from "../../../components/BillingInfoField";
import FileDragAndDrop from "../../../components/FileDragAndDrop";
import ContainerTable from "../../../components/ContainerTable";
import STLCSearch from "../../../components/STLCSearch";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import InputAdornment from "@mui/material/InputAdornment";

const drawerWidth = 250

const buttonCancelText = "Cancel"
const buttonUpdateText = "Update Sample Data"
const buttonAddText = "Add Sample to Queue"

const templateModalTitle = "Save New Template"
const templateButton1Text = "Save Template"

const draftButtonUpdateText = "Update Draft"
const draftButtonSaveText = "Save as Draft"
const draftModalTitle = "Save New Draft"
const draftButton1Text = "Save Draft"

const errorsModalTitle = "Errors Detected"

const viewMySamplesText = 'View My Samples'

const ValidatedTextField = styled(TextField)({
    [`& .${outlinedInputClasses.root} .${outlinedInputClasses.notchedOutline}`]: {
      borderColor: "green"
    },
    [`&:hover .${outlinedInputClasses.root} .${outlinedInputClasses.notchedOutline}`]: {
      borderColor: "green"
    },
    [`& .${outlinedInputClasses.root}.${outlinedInputClasses.focused} .${outlinedInputClasses.notchedOutline}`]: {
      borderColor: "green"
    },
    [`& .${outlinedInputClasses.input}`]: {
      color: "green"
    },
    [`&:hover .${outlinedInputClasses.input}`]: {
      color: "green"
    },
    [`& .${outlinedInputClasses.root}.${outlinedInputClasses.focused} .${outlinedInputClasses.input}`]: {
      color: "green"
    },
    [`& .${inputLabelClasses.outlined}`]: {
      color: "green"
    },
    [`&:hover .${inputLabelClasses.outlined}`]: {
      color: "green"
    },
    [`& .${inputLabelClasses.outlined}.${inputLabelClasses.focused}`]: {
      color: "green"
    },

    id:"outlined-normal",
    width:"25%",
    marginRight:"15px"
  });

const StyledShortTextField = styled(TextField)({
    id:"outlined-normal",
    width:"25%",
    marginRight:"15px"
  });

const StyledShortProcessTechnologyField = styled(TextField)({
    id:"outlined-normal",
    width:"31%",
    marginRight:"15px"
  });

const StyledInputLabel = styled(InputLabel)(() => ({
    wordBreak: 'break-all',
    width: "100%",
    wordWrap: 'break-word',
    whiteSpace: 'normal',
    marginTop:"15px"
  }));
 
const NewSample = ({ ...props }) => {
    const currentUser = useContext(UserContext)
    const roles = currentUser?.idTokenClaims.roles;

    const hasEMBSIUser = hasRole(Roles.EMBISUser, roles) || hasRole(Roles.Developer, roles)
    const hasPMUser = hasRole(Roles.PMUser, roles) || hasRole(Roles.Developer, roles)

    const [defaultProject, setDefaultProject] = useState(null)

    let myDefaultSample = new Sample({
        sampleName: "NEW",
        description: '',
        additionalSampleInformation: '',
        chargeCode: null,
        parentSampleReason: '',
        ppmReference: '',
        createdByEmail: '',
        createdDate: null,
        lastModifiedByEmail: '',
        lastModifiedDate: null,
        sampleOwnerEmail: '',
        userDefinedSafetyInformation: '',
        densityAtSTP: '',
        sampleConcentration: null,
        sampleConcentrationUoM: '',
        uom:null,
        isCommodityChemical: false,
        supplierTradenameLotID: '',
        supplierLot: null,
        isSampleDataLocked: false,
        isTestingAllowed: true,
        projectID: '',
        project: defaultProject,
        isRestrictedAccess: false,
        chemID: '',
        substance: null,
        initialLocation: '',
        location: null,
        containers: [],
        parentSampleContainerID: '',
        parentSampleContainer: null,
        forInformaticsUse: false,
        requiresBlindCode: false,
        productUnit: '',
        productRun: '',
        productBalance: '',
        productStream: '',
        productLowCutTemp: '',
        productHighCutTemp: '',
        feedstockCode: '',
        feedstockNumber1: '',
        feedstockInitials: '',
        feedstockNumber2: '',
        feedstockLowCutTemp: '',
        feedstockHighCutTemp: '',
        catalystYear: '',
        catalystGroup: '',
        catalystApp: '',
        catalystNumber: '',
        mrdNumber:'',
        embsiUseOnly: false, 
        isRMCSample: false,
        fileAttachments: [],
        psimsid: null,
        comments: ''
    })

    
 //set inital error value to Null if required field
 //set intial error value to false if optional field but has validation
    let defaultSampleErrorChecks = {
        description:null,
        sampleOwnerEmail:null,
        project:false,
        substance:null,
        parentMidasNumber:false,
        sampleConcentration:false
    }

    let defaultContainerErrorChecks = {
        containerType:null,
        containerStatus:false,
        size:null, 
        currentAmount:null,
        uom:null,
        location:null,
        ownerEmail:null
    }    

    // const getDraftSamples = (draftSamples) => {
    //     return draftSamples.map(draft => {
    //         const fileAttachments = draft.fileAttachments ? JSON.parse(draft.fileAttachments) : [];
    //         return {
    //             ...draft,
    //             fileAttachments: fileAttachments && fileAttachments.length > 0 ? fileAttachments.map(file => {
    //                 return {
    //                   ...file,
    //                   fileObject: convertBase64ToFile(file.fileObject)
    //                 }
    //             }) : []
    //         }
    //     })
    // }

    //load the current sample and drafts that is passed in
    const location = useLocation()
    const mySingleSample = location.state && location.state.sampleObject ? location.state.sampleObject : null
    const myDraftSamples = location.state && location.state.draftSamples ? convertDraftAttachmentsToFiles(location.state.draftSamples) : []
    const [myDraftObj, setMyDraftObj] = useState(location.state && location.state.draftObject ? location.state.draftObject : null)
    const [myTemplateObj, setMyTemplateObj] = useState(location.state && location.state.templateObject ? location.state.templateObject : null)
    //const myTemplateObj = location.state && location.state.templateObject ? location.state.templateObject : null
            
    //current sample and list of samples to register
    const ReorderContainers = (mySample) => {
        const newSample = JSON.parse(JSON.stringify(mySample))
        const copyContainers = [...mySample.containers]
        
        copyContainers.sort((a, b) => a.containerNumber - b.containerNumber)
        newSample.containers = copyContainers

        return(newSample)
    }

    const [currentSample, setCurrentSample] = useState(ReorderContainers(mySingleSample !== null ? mySingleSample : myDefaultSample))
    const [samplesToRegister, setSamplesToRegister] = useState(myDraftSamples)

    //autocomplete lists
    const [availableUOMs, setAvailableUOMs] = useState([])
    const [availableLocations, setAvailableLocations] = useState([])
    const [availableParentContainers, setAvailableParentContainers] = useState([])
    const [availableProjects, setAvailableProjects] = useState([])

    const [parentMidasNumberTextFieldVal, setParentMidasNumberTextFieldVal] = useState("")

    //error checking
    const PopulateContainerErrorChecksDefaults = () => {
        const myDefaults = []

        currentSample.containers.forEach (element => myDefaults.push(defaultContainerErrorChecks))

        return myDefaults
    }

    const [errorChecks, setErrorChecks] = useState(defaultSampleErrorChecks)
    const [containerErrorChecks, setContainerErrorChecks] = useState(PopulateContainerErrorChecksDefaults)

    const [saveButtonText, setSaveButtonText] = useState(buttonAddText)
    const [sampleIndexBeingEdited, setSampleIndexBeingEdited] = useState(-1)
    const [isInitialRender, setIsInitialRender] = useState(true)
    const [isSaving, setIsSaving] = useState(false)

    //Modal stuff
    const [modalMessagesOpen, setModalMessagesOpen] = React.useState(false);
    const modalMessagesButtonText = 'Ok'
    const [modalMessagesTitle, setModalMessagesTitle] = React.useState('');
    const [modalMessagesText, setModalMessagesText] = React.useState('');

    const [modalDraftsOpen, setModalDraftsOpen] = React.useState(false);
    const [modalDraftsButton1Text, setModalDraftsButton1Text] = React.useState('');
    const [modalDraftsButton2Text, setModalDraftsButton2Text] = React.useState('');
    const [modalDraftsTitle, setModalDraftsTitle] = React.useState('');
    const [modalDraftsText, setModalDraftsText] = React.useState('');

    const [modalTemplatesOpen, setModalTemplatesOpen] = React.useState(false);
    const [modalTemplatesButton1Text, setModalTemplatesButton1Text] = React.useState('');
    const [modalTemplatesButton2Text, setModalTemplatesButton2Text] = React.useState('');
    const [modalTemplatesTitle, setModalTemplatesTitle] = React.useState('');
    const [modalTemplatesText, setModalTemplatesText] = React.useState('');

    const [modalThreeBtnOpen, setModalThreeBtnOpen] = React.useState(false);
    const [modalThreeBtnButton1Text, setModalThreeBtnButton1Text] = React.useState('');
    const [modalThreeBtnButton2Text, setModalThreeBtnButton2Text] = React.useState('');
    const [modalThreeBtnButton3Text, setModalThreeBtnButton3Text] = React.useState('');
    const [modalThreeBtnTitle, setModalThreeBtnTitle] = React.useState('');
    const [modalThreeBtnText, setModalThreeBtnText] = React.useState('');

    const [modalDeleteAttachOpen, setModalDeleteAttachOpen] = React.useState(false);
    const [modalDeleteAttachButton1Text, setModalDeleteAttachButton1Text] = React.useState('');
    const [modalDeleteAttachButton2Text, setModalDeleteAttachButton2Text] = React.useState('');
    const [modalDeleteAttachTitle, setModalDeleteAttachTitle] = React.useState('');
    const [modalDeleteAttachText, setModalDeleteAttachText] = React.useState('');

    const [openSTLCSearch, setOpenSTLCSerach] = useState(false);
    const [resetSearchSTLC, setResetSearchSTLC] = useState(false);

    //printing
    const [printLabelInfo, setPrintLabelInfo] = React.useState(null)
    const [printInfoOpen, setPrintInfoOpen] = React.useState(false);

    //Draft stuff
    const [draftSaveError, setDraftSaveError] = React.useState(false)
    const [draftSaveName, setDraftSaveName] = React.useState('')
    const [draftButtonText, setDraftButtonText] = React.useState(myDraftObj === null ? draftButtonSaveText : draftButtonUpdateText)
    const [isDraftSaving, setIsDraftSaving] = useState(false)

    //Template Stuff
    const [templateSaveError, setTemplateSaveError] = React.useState(false)
    const [templateSaveName, setTemplateSaveName] = React.useState(myTemplateObj === null || currentUser.username !== myTemplateObj?.templateOwnerEmail ? '' : myTemplateObj.templateName)
    const [isPublicChecked, setIsPublicChecked] = useState(false)
    const [isTemplateSaving, setIsTemplateSaving] = useState(false)
    const allowTemplateUpdates = myTemplateObj === null || currentUser.username !== myTemplateObj?.templateOwnerEmail ? false : true

    const history = useHistory();

    const [attachmentDeleteOptions, setAttachmentDeleteOptions] = useState(null)

    function closeModalMessages() {
        setModalMessagesOpen(false);
    }

    function openModalMessages(title, text) {
        setModalMessagesOpen(true);
        setModalMessagesTitle(title);
        setModalMessagesText(text);
    }
    
    function closeModalDrafts() {
        setModalDraftsOpen(false);
        setDraftSaveError(false)
    }

    function openModalDrafts(title, text, buttonText, button2Text) {
        setModalDraftsButton1Text(buttonText)
        setModalDraftsButton2Text(button2Text)
        setModalDraftsOpen(true);
        setModalDraftsTitle(title);
        setModalDraftsText(text);
    }

    function closeModalTemplate() {
        setModalTemplatesOpen(false);

        setTemplateSaveError(false)
    }

    function openModalTemplate(title, text, buttonText, button2Text) {
        setModalTemplatesButton1Text(buttonText)
        setModalTemplatesButton2Text(button2Text)
        setModalTemplatesOpen(true);
        setModalTemplatesTitle(title);
        setModalTemplatesText(text);
    }

    function closeModalThreeButtons() {
        setModalThreeBtnOpen(false)
        window.location.href = "/samples"
    }

    function openModalThreeButtons(title, text, buttonText, button2Text, button3Text) {
        setModalThreeBtnButton1Text(buttonText)
        setModalThreeBtnButton2Text(button2Text)
        setModalThreeBtnButton3Text(button3Text)
        setModalThreeBtnOpen(true)
        setModalThreeBtnTitle(title)
        setModalThreeBtnText(text)
    }

    function openPrintInfo() {
        let arrayPrintInfo = []
        let myContainerNumbers = []

        samplesToRegister.forEach((oSample) => {

            myContainerNumbers = []

            oSample.containers.forEach ((oContainer) => {
                if (oContainer.lastModifiedByEmail === null)
                {
                    myContainerNumbers.push(oContainer.containerNumber)    
                }    
            })

            let newPrintInfo = new PrintLabel({
                sampleName: oSample.sampleName,
                containerNumbers: myContainerNumbers,
                includeAdditionalSampleInformation: false,
                chemIDOnly: false,
                printLabelSizeType: null,
                isBlindCoded: false,
                blindDescription: null,
                blindSampleName: null,
                isShelfLabel: false,
                shelfLabelText: null,
                includeShelfBarcode: false
            })

            arrayPrintInfo.push(newPrintInfo)
        })
    
        setPrintLabelInfo(arrayPrintInfo)
        setPrintInfoOpen(true)  
    }

    function closePrintInformation() {
        setPrintInfoOpen(false)
        window.location.href = "/samples"
    }

    function OpenPrintingOptions() {
        setModalThreeBtnOpen(false)

        let newContainerCount = 0

        samplesToRegister.forEach((oSample) => {
            oSample.containers.forEach ((oContainer) => {
                if (oContainer.lastModifiedDate === null) {
                    newContainerCount += 1
                }
            })
        })

        if (newContainerCount > 0) {
            openPrintInfo()
        } else {
            openModalMessages('No New Containers Found', `There where no new containers created, so no labels can be printed`, 'Ok');
            window.location.href = "/samples"
        }
    }

    function closeModalDeleteAttach() {
        setModalDeleteAttachOpen(false);
    }

    function openModalDeleteAttach(title, text, buttonText, button2Text) {
        setModalDeleteAttachButton1Text(buttonText)
        setModalDeleteAttachButton2Text(button2Text)
        setModalDeleteAttachOpen(true);
        setModalDeleteAttachTitle(title);
        setModalDeleteAttachText(text);
    }

    const RouteToTesting = () => {
        //Need to remove any samples that don't have containers
        let mySamples = []
        samplesToRegister.forEach((oSample) => {
            if(oSample.containers && oSample.containers.length > 0)
            {
                mySamples.push(oSample)
            }

        })
        
        history.push('/newTest', {sampleInfo: mySamples});
    }

    //set the available containers
    //start up run
    useEffect(() => {
        if (isInitialRender) {
            setIsInitialRender(false);

            if (currentSample.containers.length > 0) {
                setAvailableParentContainers(currentSample.containers)
            } else {
                setAvailableParentContainers([])
            }

            if (currentSample.sampleName === 'NEW'){
                setSaveButtonText(buttonAddText)
            } else {
                setSaveButtonText(buttonUpdateText)
            }

        }
        
    }, [currentSample, isInitialRender])

    //get all the start up data
    useEffect(() => {
        let cancelPromise = false

            Location.getAll().then((res) => {
                if (cancelPromise) return
                setAvailableLocations(res.filter(result => result.isActive === true).sort((a, b) => a.locationName.localeCompare(b.locationName)))
            });

            UnitOfMeasure.getAll().then((res) => {
                if (cancelPromise) return
                setAvailableUOMs(res.filter(result => (result.metricStandardConversion !== null || result.type === 'concentration') && result.isActive === true).sort((a, b) => a.uoMName.localeCompare(b.uoMName)))
            });
        
            Project.getAll().then((res) => {
                if (cancelPromise) return

                if (currentSample.project === null)
                {
                    setCurrentSample(() => ({
                        ...currentSample,
                        project: res.find(v => v.name === "PUBLIC")
                    }))
                }
            
                setDefaultProject(res.find(v => v.name === "PUBLIC"))
                setAvailableProjects(res.filter(result => result.isActive === true).sort((a, b) => a.name - b.name))
            });
            
        return () => {
            cancelPromise = true
        }
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (mySingleSample !== null)
        {
            //setup the error checking
            const newErrorChecks = 
            {
                description: mySingleSample.description === null ? true : mySingleSample.description.trim() === '',
                sampleOwnerEmail: mySingleSample.sampleOwnerEmail === null ? true :  mySingleSample.sampleOwnerEmail.trim() === '',
                project:false,
                substance: mySingleSample.substance === null,
                parentMidasNumber:false,
                sampleConcentration:false
            };

            const newContainerErrorChecks = []

            mySingleSample.containers.sort((a, b) => a.containerNumber - b.containerNumber).forEach((container, index) => {
                let containerErrorCheck = {
                    containerType: container.type === null,
                    containerStatus: container.status === null,
                    size: container.size || container.size ===  0 ? !(isNumber(container.size)) : true,
                    currentAmount: container.currentAmount || container.currentAmount === 0 ? !(isNumber(container.currentAmount)) : true,
                    uom: container.uom === null,
                    location: container.location === null,
                    ownerEmail: container.ownerEmail.trim() === ''
            }  
            
            newContainerErrorChecks.push(containerErrorCheck)
            })

            setErrorChecks(newErrorChecks)
            setContainerErrorChecks(newContainerErrorChecks)
            }    
    }, [mySingleSample])

    useEffect(() => {
        if (isInitialRender === true)
        {
            return
        }
        
        if(currentSample.substance && currentSample.substance.densityAtSTPgml !== currentSample.densityAtSTP)
        {
            if (currentSample.densityAtSTP === null || currentSample.densityAtSTP === '' || currentSample.densityAtSTP <= 0)
            {
                setCurrentSample((currentSample) => ({
                    ...currentSample,
                    densityAtSTP: currentSample.substance.densityAtSTPgml
                }))
            } else {
                openModalMessages("Density Check", "You have entered a Chem ID and there is an existing Sample Density.  Please confirm your Sample Density is correct.")
            }

        } else {
            setCurrentSample((currentSample) => ({
                ...currentSample,
                densityAtSTP: null
            }))        
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentSample.substance?.densityAtSTPgml])

    useEffect(() => {
        if (isInitialRender === true)
        {
            return
        }
        
        if(currentSample.substance && currentSample.substance.psimsid !== currentSample.psimsid)
        {
            if (currentSample.psimsid === null || currentSample.psimsid === '')
            {
                setCurrentSample((currentSample) => ({
                    ...currentSample,
                    psimsid: currentSample.substance.psimsid
                }))
            } else {
                openModalMessages("PSIMS ID Check", "You have entered a Chem ID and there is an existing Sample PSIMS ID.  Please confirm your Sample PSIMS ID is correct.")
            }

        } else {
            setCurrentSample((currentSample) => ({
                ...currentSample,
                psimsid: null
            }))        
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentSample.substance?.psimsid])

    function UpdateContainerList (containers) 
    {
        const newSample = structuredClone(currentSample)

        newSample.containers = containers

        setCurrentSample(newSample)
    }

    const ValidateSampleAndContainers = () => { 
        let sampleErrors = ValidateSample()
        let containerErrors = ValidateContainers()

        const combindedErrors = sampleErrors.concat(containerErrors)

        //if all checks were passed add it to the collection to register
        if (combindedErrors.length === 0) {

            //decide if an update or adds needs to happen
            if (sampleIndexBeingEdited === -1){
                setSamplesToRegister([...samplesToRegister, currentSample])
                myDefaultSample.project = defaultProject

            } else {
                const newSampleList = JSON.parse(JSON.stringify(samplesToRegister))
                newSampleList.splice(sampleIndexBeingEdited, 1, currentSample)
                setSamplesToRegister(newSampleList)

                myDefaultSample.project = defaultProject
                setSampleIndexBeingEdited(-1)
            } 

            setErrorChecks(defaultSampleErrorChecks)
            setContainerErrorChecks([])

            setCurrentSample(myDefaultSample)
            //setTemplateButtonText(templateButtonSaveText)
            setSaveButtonText(buttonAddText)
            setParentMidasNumberTextFieldVal("")

            //setEmailUserObject(null)
        } else {
            openModalMessages(errorsModalTitle, combindedErrors, "Ok")
            setSaveButtonText(buttonAddText)
        }
    }

    function ValidateContainers () 
    {
        let hasErrors = []

        containerErrorChecks.forEach((oContainerError, index) => {
            if (oContainerError.containerType === true || oContainerError.containerType === null)
            {
                hasErrors.push(`Container ${index} has an invalid container type.`)
            }

            if (oContainerError.containerStatus === true || oContainerError.containerStatus === null)
            {
                hasErrors.push(`Container ${index} has an invalid container status.`)
            }

            if (oContainerError.size === true || oContainerError.size === null)
            {
                hasErrors.push(`Container ${index} has an invalid container size.`)
            }

            if (oContainerError.currentAmount === true || oContainerError.currentAmount === null)
            {
                hasErrors.push(`Container ${index} has an invalid container current amount.`)
            }

            if (oContainerError.uom === true || oContainerError.uom === null)
            {
                hasErrors.push(`Container ${index} has an invalid container UoM.`)
            }

            if (oContainerError.location === true || oContainerError.location === null)
            {
                hasErrors.push(`Container ${index} has an invalid container location.`)
            }

            if (oContainerError.ownerEmail === true || oContainerError.ownerEmail === null)
            {
                hasErrors.push(`Container ${index} has an invalid container owner.`)
            }
        })    

        return hasErrors;
    }

    function ValidateSample () 
    {
        let hasErrors = []

        if (errorChecks.project === true || errorChecks.project === null)
        {
            hasErrors.push("A sample project must be selected.")
        }

        if (errorChecks.substance === true || errorChecks.substance === null)
        {
            hasErrors.push('Sample Chem ID must be entered.')
        }

        if (errorChecks.sampleOwnerEmail === true || errorChecks.sampleOwnerEmail === null)
        {
            hasErrors.push("Sample owner is required or is invalid.") 
        }

        if (errorChecks.description === true || errorChecks.description === null)
        {
            hasErrors.push('Sample description is required.') 
        }

        if (errorChecks.parentMidasNumber === true || errorChecks.parentMidasNumber === null)
        {
            hasErrors.push('The parent MIDAS Number and container have not be validated.')
        }

        if (errorChecks.sampleConcentration === true || errorChecks.sampleConcentration === null)
        {
            hasErrors.push('The sample concentration is invalid.')
        }   

        return hasErrors;
    }

    const DeleteSampleFromList = (index) => {
        const copySample = JSON.parse(JSON.stringify(samplesToRegister))

        copySample.splice(index, 1)

        setSamplesToRegister(copySample)

        if (index === sampleIndexBeingEdited) {
            myDefaultSample.project = defaultProject
            setCurrentSample(myDefaultSample)
            setParentMidasNumberTextFieldVal("")
            setSampleIndexBeingEdited(-1)
            setSaveButtonText(buttonAddText)
        }
    }

    const LoadSampleFromList = (sampleToLoad, index) => {
        setSampleIndexBeingEdited(index)
        setSaveButtonText(buttonUpdateText)
        setCurrentSample(sampleToLoad)
        setErrorChecks({
            description:false,
            sampleOwnerEmail:false,
            project:false,
            substance:false,
            parentMidasNumber:false,
            sampleConcentration:false
        })

        const myDefaults = []

        const newContainerErrorChecks = {
            containerType:false,
            containerStatus:false,
            size:false, 
            currentAmount:false,
            uom:false,
            location:false,
            ownerEmail:false
        }    

        sampleToLoad.containers.forEach (element => myDefaults.push(newContainerErrorChecks))

        setContainerErrorChecks(myDefaults)
    }

    async function convertDraftAttachmentsToStrings () 
    {
        return await Promise.all(
            samplesToRegister.map(async (sample) => {
              return {
                ...sample,
                fileAttachments: JSON.stringify(await Promise.all(
                    sample.fileAttachments.map(async (file) => {
                    return {
                      ...file,
                      fileObject: await convertToBase64(file.fileObject)
                    }
                  })))
              }
            })
        )
    }

    function convertDraftAttachmentsToFiles (draftSamples)
    {
        return draftSamples.map(draft => {
            const fileAttachments = draft.fileAttachments && draft.fileAttachments.length > 0 ? JSON.parse(draft.fileAttachments) : [];
            return {
                ...draft,
                fileAttachments: fileAttachments && fileAttachments.length > 0 ? fileAttachments.map(file => {
                    return {
                      ...file,
                      fileObject: convertBase64ToFile(file.fileObject)
                    }
                }) : []
            }
        })
    }

    const SaveNewDraft = async (e) => {

        //make sure there is a draft name or it is an update
        if (draftSaveName === '' && !(e.target.innerText === draftButtonUpdateText)) 
        {
            setDraftSaveError(true)
            return
        }

        if (isDraftSaving)
        { 
            return
        } else {
            setIsDraftSaving(true)
        }

        const samplesDraft = await convertDraftAttachmentsToStrings();
        
        if (myDraftObj !== null) {
            const copyDraftObj = JSON.parse(JSON.stringify(myDraftObj))

            copyDraftObj.draftJSON = JSON.stringify(samplesDraft)

            Draft.update(copyDraftObj).then((res) => {
                if (res.message === 'Success') 
                {
                    openModalMessages("Update Successful", "Draft Successfully Updated!")
                    closeModalDrafts()
                } else {
                    openModalMessages("Update Failed", `${res.message}. Contact support if you feel this is an error.`)
                }
                setIsDraftSaving(false)
            });

        } else {
            let newDraft = new Draft({
                draftOwnerEmail: null,
                draftName: draftSaveName,
                draftJSON: JSON.stringify(samplesDraft),
                draftType: "sample",
                createdDate: null, 
                lastModifiedDate: null
            });
            
            Draft.add(newDraft).then((res) => {
                if (res.message === 'Success')
                {
                openModalMessages("Save Successful", "Draft Successfully Saved!")
                closeModalDrafts()
                setDraftButtonText(draftButtonUpdateText)
                setMyDraftObj(res.result)
                } else {
                openModalMessages('Draft Save Failed', `${res.message}. Contact support if you feel this is an error.`);
                }
                setIsDraftSaving(false)
            });
        }
        
    };

    const SaveTemplate = (e) => {

        if (templateSaveName.trim() === '') {
            setTemplateSaveError(true)
            return
        }

        // if (templateSaveName.trim() === '' && !(e.target.innerText === templateButtonUpdateText)) {
        //     setTemplateSaveError(true)
        //     return
        // }
        
        // if (myTemplateObj !== null && currentUser.username !== myTemplateObj.templateOwnerEmail && templateButtonText === templateButtonUpdateText)
        // {
        //     openModalMessages("Template Error", "You cannot update a template from another user!")
        //     return
        // }

        let containerErrors = ValidateContainers()

        if (containerErrors.length > 0) 
        {
            openModalMessages(errorsModalTitle, containerErrors, "Ok") 
            return
        }

        if (isTemplateSaving)
        { 
            return
        } else {
            setIsTemplateSaving(true)
        }

        const copyCurrentSample = JSON.parse(JSON.stringify(currentSample))

        let newTemplate = new Template({
            templateName: templateSaveName,
            templateJSON: JSON.stringify(copyCurrentSample),
            templateType: "sample",
            templateOwnerEmail: currentUser.username,
            isPublic: myTemplateObj ? myTemplateObj.isPublic : isPublicChecked,
            createdDate: null, 
            lastModifiedDate: null
        });

        Template.add(newTemplate).then((res) => {
            if (res.message === 'Success') 
            {
                openModalMessages("Save Successful", "Template Successfully Saved!")
                closeModalTemplate()
                setMyTemplateObj(res.result)
                //setTemplateButtonText(templateButtonUpdateText)
            } else {
                openModalMessages("Update Failed", `${res.message}. Contact support if you feel this is an error.`)
            }
            setIsTemplateSaving(false)
        });

        // if (templateButtonText === templateButtonUpdateText)
        // {
        //     Template.update(newTemplate).then((res) => {
        //         if (res.message === 'Success') 
        //         {
        //             openModalMessages("Update Successful", "Template Successfully Updated!")
        //             closeModalTemplate()
        //         } else {
        //             openModalMessages("Update Failed", `${res.message}. Contact support if you feel this is an error.`)
        //         }
        //         setIsTemplateSaving(false)
        //     });

        // } else {

        //     Template.add(newTemplate).then((res) => {
        //         if (res.message === 'Success') 
        //         {
        //             openModalMessages("Save Successful", "Template Successfully Saved!")
        //             closeModalTemplate()
        //             setTemplateButtonText(templateButtonUpdateText)
        //         } else {
        //             openModalMessages("Update Failed", `${res.message}. Contact support if you feel this is an error.`)
        //         }
        //         setIsTemplateSaving(false)
        //     });
        // }
    };

    function UpdateTemplate () {
        if (templateSaveName === '') {
            setTemplateSaveError(true)
            return
        }
        
        if (myTemplateObj !== null && currentUser.username !== myTemplateObj.templateOwnerEmail)
        {
            openModalMessages("Template Error", "You cannot update a template from another user!")
            return
        }

        let containerErrors = ValidateContainers()

        if (containerErrors.length > 0) 
        {
            openModalMessages(errorsModalTitle, containerErrors, "Ok") 
            return
        }

        if (isTemplateSaving)
        { 
            return
        } else {
            setIsTemplateSaving(true)
        }

        const copyCurrentSample = JSON.parse(JSON.stringify(currentSample))

        let newTemplate = new Template({
            templateName: templateSaveName,
            templateJSON: JSON.stringify(copyCurrentSample),
            templateType: "sample",
            templateOwnerEmail: currentUser.username,
            isPublic: myTemplateObj ? myTemplateObj.isPublic : isPublicChecked,
            createdDate: null, 
            lastModifiedDate: null
        });

        Template.update(newTemplate).then((res) => {
            if (res.message === 'Success') 
            {
                openModalMessages("Update Successful", "Template Successfully Updated!")
                closeModalTemplate()
            } else {
                openModalMessages("Update Failed", `${res.message}. Contact support if you feel this is an error.`)
            }
            setIsTemplateSaving(false)
        });
    }

    const RegisterSamplesViaAPI = () => {

        if (samplesToRegister.length < 1){
            openModalMessages('No Samples Found','There are no samples added to the queue for registration, please add samples to the registation queue.', 'Ok');
            return
        }

        setIsSaving(true)

        const formData = new FormData();

        formData.append("samples", JSON.stringify(samplesToRegister))

        samplesToRegister.forEach(oSample => {
            oSample.fileAttachments.forEach(oFile => {
                if (oFile.id === null || oFile.id < 1) {
                    formData.append("files", oFile.fileObject, oFile.fileName)
                }
            })
        })

        Sample.RegisterSampleAndContainers(formData).then((res) => {

            if (res && res.message === 'Success') {
                let containerCount = 0
                let sampleCount = 0

                res.result.forEach((oSample) => {
                    oSample.containers.forEach((oContainer) => 
                    {
                        if (oContainer.lastModifiedByEmail === null)
                        {
                            containerCount += 1;
                        }
                    })           
                    if (oSample.lastModifiedByEmail === null)
                    {
                        sampleCount += 1;
                    }     
                })

                setSamplesToRegister(res.result)

                //delete the draft, if there is one
                if (myDraftObj){
                    Draft.delete(myDraftObj.id).then((res) => {
                    })
                }

                openModalThreeButtons('Registration Complete', `You registered ${sampleCount} new samples with ${containerCount} new containers`, 'Print Labels', viewMySamplesText, 'Request Tests')
            }
            else{
                openModalMessages('Samples and Containers Failed to Save', `Samples failed to save. ${res ? res.message : ''}`, 'Ok');
            }

            setIsSaving(false)
        });
    }

    const handleKeyPressParentSample = (event) => {

        if (event.key === 'Enter' || event.key === 'Tab') {
            ValidateParentSampleEntry()
        } else {

            setCurrentSample(() => ({
                ...currentSample,
                parentSampleContainer: null
            }))
        }
    }

    const ValidateParentSampleEntry = () => {

        if (parentMidasNumberTextFieldVal === '') 
        {
            setErrorChecks(() => ({
                ...errorChecks,
                parentMidasNumber: false
            }))
            return
        }

        const splitString = parentMidasNumberTextFieldVal.split("/")

        if (splitString.length === 2){
        Container.getContainerBySampleAndNumber(splitString[0], splitString[1]).then((result) => {
            if(result.id)
            {
                setCurrentSample(() => ({
                    ...currentSample, 
                    parentSampleContainer: result,
                    parentSampleContainerID: result.id
                }))

                setErrorChecks(() => ({
                    ...errorChecks,
                    parentMidasNumber: false
                }))

            } else {
                setCurrentSample(() => ({
                    ...currentSample,
                    parentSampleContainer: null
                }))

                setErrorChecks(() => ({
                    ...errorChecks,
                    parentMidasNumber: true
                }))
            }

        })
        } else {
            setCurrentSample(() => ({
                ...currentSample,
                parentSampleContainer: null
            }))

            setErrorChecks(() => ({
                ...errorChecks,
                parentMidasNumber: true
            }))

        }
    }  

    function SetSampleProperty (value, property)
    {
        const copyCurrentSample = JSON.parse(JSON.stringify(currentSample))

        copyCurrentSample[property] = value

        setCurrentSample(copyCurrentSample)
    }

    function updateErrorChecks (property, value)
    {
        const copyErrors = JSON.parse(JSON.stringify(errorChecks))

        copyErrors[property] = value

        setErrorChecks(copyErrors)
    }

    function AutoCompleteChangeAndValidate (property, value)
    {
        const copyCurrentSample = JSON.parse(JSON.stringify(currentSample))
        const copyErrors = JSON.parse(JSON.stringify(errorChecks))

        copyCurrentSample[property] = value
        copyErrors[property] = value === null ? true : false 
        
        setCurrentSample(copyCurrentSample)
        setErrorChecks(copyErrors)
    }

    function IsButtonDisabled ()
    {
        if(currentSample.embsiUseOnly)
        {
            if(!(hasEMBSIUser))
            {
                return true
            }
        }

        if (currentSample.isRestrictedAccess)
        {
            if(!(hasPMUser))
            {
                return true
            }
        }

        return false
    }

    function HasMetaDataLock ()
    {
        if(currentSample.sampleName === 'NEW')
        {
            return false
        }

        if(currentSample.isSampleDataLocked)
        {
            if(currentUser.username !== currentSample.sampleOwnerEmail)
            {
                return true
            }
        }

        return false
    }

    function HandleFileAdd (newFiles) {
        const listFiles = newFiles.map(item => {
            return {
            id: 0,
            sampleName: currentSample.sampleName,
            fileName: item.name,
            storagePath: '',
            fileObject: item,
            }
        })

        const copySample = structuredClone(currentSample)

        copySample.fileAttachments = copySample.fileAttachments.concat(listFiles)

        setCurrentSample(copySample)
    }

    const ConfirmRemoveAttachment = (file) => {   
        setAttachmentDeleteOptions({property:'sampleAttachments', file:file})
        openModalDeleteAttach(`Remove Attachment?`, "Are you sure you want to permanently remove the attachment?", "Yes", "No")
    }

    function deleteFile () {

        let newFiles

        closeModalDeleteAttach()

        if (attachmentDeleteOptions === null)
        { 
            return
        }

        const property = attachmentDeleteOptions.property
        const copySample = structuredClone(currentSample)

        if (property === 'sampleAttachments')
        {
            if (attachmentDeleteOptions.file.id > 0)
            {
                SampleAttachment.delete(attachmentDeleteOptions.file.id).then((res) => {
                    if (res && res.message === 'Success') {
                        newFiles = copySample.fileAttachments.filter(f => f !== attachmentDeleteOptions.file)     
                        setAttachmentDeleteOptions(null)

                        copySample.fileAttachments = newFiles
                        setCurrentSample(copySample)   

                        openModalMessages("Attachment Deleted", `Attachment successfully deleted.`)   
                    } else {
                        openModalMessages("Error Removing Attachment", `${res ? res.message : 'Error removing attachment'}.  Please try again or contact support.`)
                        return
                    }
                })
            } else {
                newFiles = copySample.fileAttachments.filter(f => f.fileName !== attachmentDeleteOptions.file.fileName)    
                //newFiles = copySample.fileAttachments.filter(f => f !== attachmentDeleteOptions.file)    
                setAttachmentDeleteOptions(null)

                copySample.fileAttachments = newFiles
                setCurrentSample(copySample) 
            }  
        }
    }

    function HandleFileDownload (file) {
        downloadFile('midassamplefiles', file.fileName, file.storagePath, openModalMessages)
    }

    function handleSTLSearch () {
        setOpenSTLCSerach(true)
    }

    function handleLotSelection (lot) {
        if (lot) {
            setCurrentSample(() => ({
                ...currentSample,
                supplierLot: lot
            }))
        } else {
            setCurrentSample(() => ({
                ...currentSample,
                supplierLot: null
            }))
        }     
    }

  return (
<>
      <span className='pageheader'>Register a Sample</span>
      <Divider className='dividerbar' />
<div>
      <p style={{
              color:"#111112",
              fontWeight:'600',
              fontSize:"16px",
              fontFamily:"EMprint"
          }}>Sample Information (* = required field)</p>

      <Drawer sx={{
          width: 200,
          flexShrink: 0,
           [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' },
          marginTop:"25px",
         }} 
        variant="permanent"
        open
        anchor="right"
      >
        <Box style={{height:"69px"}}>test</Box>
        <Toolbar />
        <Divider />

        <div style={{textAlign:"left"}}>
            <p style={{
               fontFamily:"EMprint",
               fontWeight:"600",
               fontSize:"20px",
               color:"#545459",
               marginBottom:"20px",
               textTransform: 'none',
               paddingLeft:"20px"
              }}>Sample Summary</p>

    {samplesToRegister.length < 1 &&
    
        <Box style={{marginLeft:"20px", width:"80%", borderStyle: 'dotted',borderWidth: 1,borderRadius: 1}}>
             <p style={{
               fontFamily:"EMprint",
               fontWeight:"400",
               fontSize:"14px",
               color:"#545459",
               marginBottom:"20px",
               textTransform: 'none',
               paddingLeft:"20px"
              }}>You can register a list of samples. Your list is going to appear here when added. </p>
        </Box>
    }
              {samplesToRegister && samplesToRegister.map ((sampleInfo, index) => {

                  return(
                    <div key={'myDiv' + index} style={{display:"flex"}}>
                           <Button key={'myButton' + index} style={{
                            color:"#1460A6",
                            fontFamily:"EMprint",
                            fontWeight:"600",
                            fontSize:"14px",
                            textTransform: 'none',
                            paddingLeft:"20px"
                            }}
                            onClick={e => LoadSampleFromList(samplesToRegister[index], index)}
                            >{sampleInfo.description}</Button>

                            <DeleteIcon style={{paddingTop:"8px"}}
                            onClick={e => DeleteSampleFromList(index)}></DeleteIcon>
                    </div>
                    
              )}
                 
              )}
        </div>

        {isSaving === false ? (
            <div style={{bottom:0, position:"absolute", marginBottom:"75px"}}>
            <GlobalButton  
                name="btnRegister"
                disabled={samplesToRegister.length <= 0}
                variant='contained'
                style={{ marginTop:"50px", marginLeft:"15px", marginRight:"10px", width:"90%"}}
                onClick={(e) => RegisterSamplesViaAPI()}
            >Register Sample</GlobalButton>

            <GlobalSecondaryButton  
                name="btnSaveDraft"
                disabled={samplesToRegister.length <= 0}
                variant='contained'
                style={{ marginTop:"15px", marginLeft:"15px", marginRight:"10px", width:"90%"}}
                onClick={(e) => draftButtonText === draftButtonUpdateText ? SaveNewDraft(e) : openModalDrafts(draftModalTitle, "Enter a name for your new draft", draftButton1Text, buttonCancelText)}
            >{draftButtonText}</GlobalSecondaryButton>
            </div>
        ):( <div style={{bottom:0, position:"absolute", marginBottom:"75px"}}>

                <CircularProgress style={{textTransform: "none",  marginTop:"50px", marginLeft:"15px"}}></CircularProgress>

                <GlobalButton  
                    name="btnRegister"
                    disabled={samplesToRegister.length <= 0}
                    variant='contained'
                    style={{ marginTop:"-30px", marginLeft:"10px", marginRight:"10px", width:"60%"}}
                    onClick={(e) => RegisterSamplesViaAPI()}
                >Register Sample</GlobalButton>

                {/* <GlobalButton  
                    name="btnRegisterSMG"
                    disabled={true}
                    variant='contained'
                    style={{ marginTop:"15px", marginLeft:"15px", marginRight:"10px", width:"90%"}}
                    //onClick={(e) => RegisterSamplesViaAPI()}
                >Register Via SMG</GlobalButton> */}

                <GlobalSecondaryButton
                name="btnSaveDraft"
                disabled={samplesToRegister.length <= 0}
                variant='contained'
                style={{ marginTop:"15px", marginLeft:"15px", marginRight:"10px", width:"90%"}}
                onClick={(e) => draftButtonText === draftButtonUpdateText ? SaveNewDraft(e) : openModalDrafts(draftModalTitle, "Enter a name for your new draft", draftButton1Text, buttonCancelText)}
                >{draftButtonText}</GlobalSecondaryButton>
            </div>
        )}

      </Drawer>

    {/* <div> */}
    <Box style={{width:`calc(100% - ${drawerWidth}px)`}}>

{/* Sample Information */}
    <div>
        <div style={{display:"flex"}}>
            <StyledShortTextField size="small" margin="normal" variant="outlined" label="MIDAS Number" InputLabelProps={{shrink: true}}
                disabled
                value={currentSample.sampleName}></StyledShortTextField>

            <TextField style={{ id:"outlined-normal",width:"76.5%",marginRight:"15px"}} size="small" margin="normal" variant="outlined" label="* Sample Description" InputLabelProps={{shrink: true}}
                inputProps={{ maxLength: 700 }}
                multiline
                onChange={e => setCurrentSample(() => ({...currentSample, description: e.target.value}))}
                value={currentSample.description === null ? '' : currentSample.description}
                error = {errorChecks.description === null ? false : errorChecks.description}       
                onBlur={e => {updateErrorChecks('description', currentSample.description === null || currentSample.description.trim() === '')}}
                helperText = {errorChecks.description ? 'Required' : ''}
                disabled={HasMetaDataLock()}
            ></TextField>
        </div>

        <div style={{display:"flex"}}>
             {/* CHEM ID */}
            <ChemIDSearchField 
                fontSize = {16}
                fieldWidth = {"24%"} 
                selectedChemIDObject = {currentSample.substance}
                setSelectedChemIDObject = {(e) => SetSampleProperty(e, "substance")}
                hasErrors = {errorChecks.substance === null ? false : errorChecks.substance}
                setHasErrors = {(e) => updateErrorChecks('substance', e)}
                isDisabled={HasMetaDataLock()}
                labelCustom = '* Chem ID'
                allowInactiveChemIDs={false}
                returnSubstanceStrucureData={false}
            ></ChemIDSearchField>

            <StyledShortTextField style={{marginLeft:"1rem"}} size="small" margin="normal" variant="outlined" label="Additional Sample Info" InputLabelProps={{shrink: true}}
                onChange={e => setCurrentSample(() => ({...currentSample,additionalSampleInformation: e.target.value}))}
                value={currentSample.additionalSampleInformation === null ? '' : currentSample.additionalSampleInformation}
                disabled={HasMetaDataLock()}
                multiline
            ></StyledShortTextField>

            <EmailAddressTextField
                fontSize = {16}
                fieldWidth = {"24%"} 
                validatedUserEmail = {currentSample.sampleOwnerEmail}
                setValidatedUserEmail = {(e) => SetSampleProperty(e, "sampleOwnerEmail")}
                hasErrors = {errorChecks.sampleOwnerEmail === null ? false : errorChecks.sampleOwnerEmail}
                setHasErrors = {(e) => updateErrorChecks('sampleOwnerEmail', e)}
                isDisabled={HasMetaDataLock()}
                labelText="* Owner Email"
                placeholderText="Owner Email"
                showPlusMeButton={true}>
            </EmailAddressTextField>
        </div>
    </div>

{/* Checkboxes */}
    <div style={{display:"flex"}}>
            <FormControlLabel control={<Checkbox checked={currentSample.isSampleDataLocked}
             onChange={e => setCurrentSample(() => ({
                ...currentSample,
                isSampleDataLocked: e.target.checked
            }))}
                disabled={HasMetaDataLock()}
            ></Checkbox>} label="Sample Metadata Locked"/>

            <FormControlLabel control={<Checkbox checked={currentSample.isCommodityChemical}
             onChange={e => setCurrentSample(() => ({
                ...currentSample,
                isCommodityChemical: e.target.checked
            }))}
                disabled={HasMetaDataLock()}
            ></Checkbox>} label="Commodity Chemical"/>

            <FormControlLabel control={<Checkbox checked={currentSample.isTestingAllowed}
             onChange={e => setCurrentSample(() => ({
                ...currentSample,
                isTestingAllowed: e.target.checked
            }))}
                disabled={HasMetaDataLock()}
            ></Checkbox>} label="Testing Allowed"/>

            <FormControlLabel control={<Checkbox checked={currentSample.isRestrictedAccess}
             onChange={e => setCurrentSample(() => ({
                ...currentSample,
                isRestrictedAccess: e.target.checked
            }))}
                disabled={!(hasPMUser) || HasMetaDataLock()}
            ></Checkbox>} label="Controlled Precious Metal"/>

             <FormControlLabel control={<Checkbox checked={currentSample.requiresBlindCode}
             onChange={e => setCurrentSample(() => ({
                ...currentSample,
                requiresBlindCode: e.target.checked
            }))}
                disabled={HasMetaDataLock()}
            ></Checkbox>} label="Requires Blind Code"/>

            <FormControlLabel control={<Checkbox checked={currentSample.embsiUseOnly}
             onChange={e => setCurrentSample(() => ({
                ...currentSample,
                embsiUseOnly: e.target.checked,
                mrdNumber: e.target.checked ? currentSample.mrdNumber : ''
            }))}
                disabled={!(hasEMBSIUser) || HasMetaDataLock()}
            ></Checkbox>} label="EMBSI Use Only"/>

            <FormControlLabel control={<Checkbox checked={currentSample.isRMCSample}
             onChange={e => setCurrentSample(() => ({
                ...currentSample,
                isRMCSample: e.target.checked
            }))}
                disabled={HasMetaDataLock()}
            ></Checkbox>} label="Is RMC Sample"/>
    </div>

{/* Additional Info */}
    <div style={{marginTop:"30px",marginRight:"15px", display:"flex"}}>
        <Accordion style={{width:"100%"}}>
            <AccordionSummary  expandIcon={<ExpandMoreIcon />} aria-controls="panel1bh-content">
                <Typography 
                    style={{
                    color:"#0C69B0",
                    fontWeight:'600',
                    fontSize:"16px",
                    fontFamily:"EMprint"
                }}>Additional Sample Information</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <div style={{display:"flex"}}>

                {currentSample.parentSampleContainer === null || errorChecks.parentMidasNumber ? ( 

                    <StyledShortTextField size="small" margin="normal" variant="outlined" label="Parent MIDAS # / Container #" InputLabelProps={{shrink: true}}
                        onKeyUp={handleKeyPressParentSample}
                        onBlur = {ValidateParentSampleEntry}
                        onChange={e => setParentMidasNumberTextFieldVal(e.target.value)}
                        value={parentMidasNumberTextFieldVal}
                        placeholder="Midas # / Container #"
                        error = {errorChecks.parentMidasNumber}
                        helperText = {errorChecks.parentMidasNumber ? "Invalid parent/container" : ""}
                        disabled={HasMetaDataLock()}
                        >
                    </StyledShortTextField>
            
                ):(
                    <ValidatedTextField size="small" margin="normal" variant="outlined" label="Validated Parent MIDAS # / Container #" InputLabelProps={{shrink: true}}
                        onKeyUp={handleKeyPressParentSample}
                        error = {errorChecks.parentMidasNumber}
                        helperText = {errorChecks.parentMidasNumber ? "Invalid parent/container" : ""}
                        value={currentSample.parentSampleContainer.sampleName + '/' + currentSample.parentSampleContainer.containerNumber}
                        disabled={HasMetaDataLock()}
                    /> 

                )}

                <TextField style={{ id:"outlined-normal",width:"47%",marginRight:"15px"}} size="small" margin="normal" variant="outlined" label="Parent Sample Reason" InputLabelProps={{shrink: true}}
                    inputProps={{ maxLength: 2000 }}
                        multiline
                    onChange={e => setCurrentSample(() => ({
                        ...currentSample,
                        parentSampleReason: e.target.value
                    }))}
                    value={currentSample.parentSampleReason === null ? '' : currentSample.parentSampleReason}
                        disabled={HasMetaDataLock()}
                ></TextField>

                <BillingInfoField
                    billingValue={currentSample.chargeCode}
                    handleChange={e => setCurrentSample(() => ({
                        ...currentSample,
                        chargeCode: e
                    }))}
                    isDisabled={HasMetaDataLock()}
                    margin="normal"
                    marginLeft="0rem"
                    marginRight="0rem"
                    fieldWidth="25%"
                    hasErrors={false}
                    helperText=""               
                >
                </BillingInfoField>
                </div>

                <div style={{display:"flex"}}>
                <StyledShortTextField size="small" margin="normal" variant="outlined" label="Sample Concentration" InputLabelProps={{shrink: true}}
                    onChange={e => setCurrentSample(() => ({
                        ...currentSample,
                        sampleConcentration: e.target.value
                    }))}
                    value={currentSample.sampleConcentration === null ? '' : currentSample.sampleConcentration}
                    error = {errorChecks.sampleConcentration === null ? false : errorChecks.sampleConcentration}       
                    onBlur={e => {updateErrorChecks('sampleConcentration', EvaluateOptionalNumberField(currentSample.sampleConcentration))}}
                    helperText = {errorChecks.sampleConcentration ? 'Invalid Entry, must be a number' : ''}
                    disabled={HasMetaDataLock()}
                >
                </StyledShortTextField> 

                <Autocomplete
                    disabled={HasMetaDataLock()}
                    disablePortal
                    noOptionsText={"Loading UOMs..."}
                    options={availableUOMs.filter(result => result.type === 'concentration')}
                    getOptionLabel={(option) => option.uoMName}
                    onChange={(e, value) => setCurrentSample(() => ({
                        ...currentSample,
                        uom: value
                        }))}
                    autoHighlight
                    autoSelect
                    value={currentSample.uom}
                    isOptionEqualToValue={(option, value) => value.uoMName === option.uoMName}
                    style={{ width:"15%", marginRight:"15px"}}
                    renderInput={(params) => <TextField {...params} variant="outlined" size="small" margin="normal" label="Sample Concentration UoM" error = {false} InputLabelProps={{shrink: true}} InputProps={{ ...params.InputProps }}/>} 
                />

                <StyledShortTextField size="small" margin="normal" variant="outlined" label="User Defined Safety Info" InputLabelProps={{shrink: true}}
                    inputProps={{ maxLength: 200 }}
                    onChange={e => setCurrentSample(() => ({
                        ...currentSample,
                        userDefinedSafetyInformation: e.target.value
                    }))}
                    value={currentSample.userDefinedSafetyInformation === null ? '' : currentSample.userDefinedSafetyInformation}
                    disabled={HasMetaDataLock()}
                ></StyledShortTextField>

                <StyledShortTextField size="small" margin="normal" variant="outlined" label="PSIMS ID" InputLabelProps={{shrink: true}}
                    inputProps={{ maxLength: 4000 }}
                    onChange={e => setCurrentSample(() => ({
                        ...currentSample,
                        psimsid: e.target.value
                    }))}
                    value={currentSample.psimsid === null ? '' : currentSample.psimsid}
                    disabled={HasMetaDataLock()}
                ></StyledShortTextField>
                </div>

                <div style={{display:"flex"}}>
                <StyledShortTextField size="small" margin="normal" variant="outlined" label="Density at STP (g/ml)" InputLabelProps={{shrink: true}}
                    onChange={e => setCurrentSample(() => ({
                        ...currentSample,
                        densityAtSTP: e.target.value
                    }))}
                    value={currentSample.densityAtSTP === null ? '' : currentSample.densityAtSTP}
                    disabled={HasMetaDataLock()}
                ></StyledShortTextField> 

                <Autocomplete
                    disabled={HasMetaDataLock()}
                    disablePortal
                    noOptionsText={"Loading Projects..."}
                    options={availableProjects}
                    getOptionLabel={(option) => option.name}
                    onChange={(e, value) => {AutoCompleteChangeAndValidate('project', value)}}
                    autoHighlight
                    autoSelect
                    value={currentSample.project}
                    isOptionEqualToValue={(option, value) => value.name === option.name}
                    style={{ width:"25%", marginRight:"15px"}}
                    renderInput={(params) => <TextField {...params} variant="outlined" size="small" margin="normal" label="* Project"
                        error={errorChecks.project === null ? false : errorChecks.project}
                        helperText = {errorChecks.project ? 'Project Required' : ''}
                    InputLabelProps={{shrink: true}} InputProps={{ ...params.InputProps }}/>} 
                />

                <StyledShortTextField size="small" margin="normal" variant="outlined" label="PPM Reference" InputLabelProps={{shrink: true}}
                    inputProps={{ maxLength: 10 }}
                    onChange={e => setCurrentSample(() => ({
                        ...currentSample,
                        ppmReference: e.target.value
                    }))}
                    value={currentSample.ppmReference === null ? '' : currentSample.ppmReference}
                    disabled={HasMetaDataLock()}
                ></StyledShortTextField>
                </div>

                
                <div style={{display:"flex"}}>
                <StyledShortTextField size="small" margin="normal" variant="outlined" label="Supplier/Tradename/Lot Name" InputLabelProps={{shrink: true}}
                    inputProps={{ maxLength: 10 }}
                    onKeyDown={e => setCurrentSample(() => ({
                        ...currentSample,
                        supplierLot: null
                    }))}
                    value={currentSample.supplierLot === null ? '' : currentSample.supplierLot.lotName}
                    disabled={HasMetaDataLock()}
                    InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              type="button"
                              aria-label="search"
                              onClick={handleSTLSearch}
                            >
                              <SearchIcon style={{ fill: "blue" }} />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                >
                </StyledShortTextField>

                <Autocomplete
                    disabled={HasMetaDataLock()}
                    disablePortal
                    noOptionsText={"Loading Locations..."}
                    options={availableLocations}
                    getOptionLabel={(option) => option.locationName}
                    onChange={(e, value) => setCurrentSample(() => ({
                        ...currentSample,
                        location: value
                        }))}
                    autoHighlight
                    autoSelect
                    value={currentSample.location}
                    isOptionEqualToValue={(option, value) => value.locationName === option.locationName}
                    style={{ width:"25%", marginRight:"15px"}}
                    renderInput={(params) => <TextField {...params} size="small" margin="normal" variant="outlined" label="Default Initial Location" error = {false} InputLabelProps={{shrink: true}} InputProps={{ ...params.InputProps }}/>} 
                />

                <StyledShortTextField size="small" margin="normal" variant="outlined" label="MRD Number" InputLabelProps={{shrink: true}}
                    inputProps={{ maxLength: 20 }}
                    onChange={e => setCurrentSample(() => ({
                        ...currentSample,
                        mrdNumber: e.target.value
                    }))}
                    value={currentSample.mrdNumber === null ? '' : currentSample.mrdNumber}
                    disabled={!currentSample.embsiUseOnly || HasMetaDataLock()}
                ></StyledShortTextField>

                </div>
                <div>

                <StyledShortTextField size="small" margin="normal" variant="outlined" label="Comments" InputLabelProps={{shrink: true}}
                    onChange={e => setCurrentSample(() => ({
                        ...currentSample,
                        comments: e.target.value
                    }))}
                    value={currentSample.comments === null ? '' : currentSample.comments}
                    disabled={HasMetaDataLock()}
                    multiline
                ></StyledShortTextField>  
                                 
                </div>

                <div style={{display:"flex"}}>
                    {currentSample.createdByEmail == null ? (
                            <StyledInputLabel>Original Submitter <b>N/A</b> on <b>N/A</b>
                        </StyledInputLabel>
                    ):(
                        <StyledInputLabel>Original Submitter <b>{currentSample.createdByEmail}</b> on <b>{convertToLocalTime(currentSample.createdDate)}</b>
                        </StyledInputLabel>
                    )}
                
                </div>
                
                <div style={{display:"flex"}}>
                    {currentSample.lastModifiedByEmail == null ? (
                        <StyledInputLabel>Last Modified By <b>N/A</b> on <b>N/A</b>
                        </StyledInputLabel>
                    ):(
                        <StyledInputLabel>Last Modified By <b>{currentSample.lastModifiedByEmail}</b> on <b>{convertToLocalTime(currentSample.lastModifiedDate)}</b>
                        </StyledInputLabel>
                    )}
                
                </div>
            </AccordionDetails>
        </Accordion>
    </div>

{/* Super Duper Additional Info */}

    <div style={{marginTop:"30px",marginRight:"15px", display:"flex"}}>
                <Accordion style={{width:"100%"}}>
                    <AccordionSummary  expandIcon={<ExpandMoreIcon />} aria-controls="panel1bh-content">
                        <Typography style={{
                                        color:"#0C69B0",
                                        fontWeight:'600',
                                        fontSize:"16px",
                                        fontFamily:"EMprint"
                                    }}>Process Technology Sample Information</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                <div style={{display:"flex"}}>
                        <StyledShortProcessTechnologyField size="small" margin="normal" variant="outlined" label="Product Unit" InputLabelProps={{shrink: true}}
                            onChange={e => setCurrentSample(() => ({
                            ...currentSample,
                            productUnit: e.target.value
                          }))}
                          inputProps={{ tabIndex: "1", maxLength:70 }}
                          value={currentSample.productUnit === null ? '' : currentSample.productUnit}
                          disabled={HasMetaDataLock()}></StyledShortProcessTechnologyField>

                        <StyledShortProcessTechnologyField size="small" margin="normal" variant="outlined" label="Feedstock Code" InputLabelProps={{shrink: true}}
                            onChange={e => setCurrentSample(() => ({
                            ...currentSample,
                            feedstockCode: e.target.value
                          }))}
                          inputProps={{ tabIndex: "7", maxLength:70 }}
                          value={currentSample.feedstockCode === null ? '' : currentSample.feedstockCode}
                          disabled={HasMetaDataLock()}></StyledShortProcessTechnologyField>

                        <StyledShortProcessTechnologyField size="small" margin="normal" variant="outlined" label="Catalyst Year" InputLabelProps={{shrink: true}}
                            onChange={e => setCurrentSample(() => ({
                                ...currentSample,
                                catalystYear: e.target.value
                            }))}
                            inputProps={{ tabIndex: "13", maxLength:70 }}
                          value={currentSample.catalystYear === null ? '' : currentSample.catalystYear}
                          disabled={HasMetaDataLock()}></StyledShortProcessTechnologyField>
                </div>

                <div style={{display:"flex"}}>

                        <StyledShortProcessTechnologyField size="small" margin="normal" variant="outlined" label="Product Run" InputLabelProps={{shrink: true}}

                            onChange={e => setCurrentSample(() => ({
                            ...currentSample,
                            productRun: e.target.value
                          }))}
                          inputProps={{ tabIndex: "2", maxLength:70 }}
                          value={currentSample.productRun === null ? '' : currentSample.productRun}
                          disabled={HasMetaDataLock()}></StyledShortProcessTechnologyField>

                        <StyledShortProcessTechnologyField size="small" margin="normal" variant="outlined" label="Feedstock Number 1" InputLabelProps={{shrink: true}}
                            onChange={e => setCurrentSample(() => ({
                            ...currentSample,
                            feedstockNumber1: e.target.value
                          }))}
                          inputProps={{ tabIndex: "8", maxLength:70 }}
                          value={currentSample.feedstockNumber1 === null ? '' : currentSample.feedstockNumber1}
                          disabled={HasMetaDataLock()}></StyledShortProcessTechnologyField>

                        <StyledShortProcessTechnologyField size="small" margin="normal" variant="outlined" label="Catalyst Group" InputLabelProps={{shrink: true}}
                            onChange={e => setCurrentSample(() => ({
                                ...currentSample,
                                catalystGroup: e.target.value
                            }))}
                            inputProps={{ tabIndex: "14", maxLength:70 }}
                          value={currentSample.catalystGroup === null ? '' : currentSample.catalystGroup}
                          disabled={HasMetaDataLock()}></StyledShortProcessTechnologyField>   
                </div>

                <div style={{display:"flex"}}>
                        
                        <StyledShortProcessTechnologyField size="small" margin="normal" variant="outlined" label="Product Balance" InputLabelProps={{shrink: true}}
                            onChange={e => setCurrentSample(() => ({
                            ...currentSample,
                            productBalance: e.target.value
                          }))}
                          inputProps={{ tabIndex: "3", maxLength:70 }}
                          value={currentSample.productBalance === null ? '' : currentSample.productBalance}
                          disabled={HasMetaDataLock()}></StyledShortProcessTechnologyField>

                        <StyledShortProcessTechnologyField size="small" margin="normal" variant="outlined" label="Feedstock Initials" InputLabelProps={{shrink: true}}
                            onChange={e => setCurrentSample(() => ({
                                ...currentSample,
                                feedstockInitials: e.target.value
                            }))}
                            inputProps={{ tabIndex: "9", maxLength:70 }}
                          value={currentSample.feedstockInitials === null ? '' : currentSample.feedstockInitials}
                          disabled={HasMetaDataLock()}></StyledShortProcessTechnologyField>

                        <StyledShortProcessTechnologyField size="small" margin="normal" variant="outlined" label="Catalyst App" InputLabelProps={{shrink: true}}
                            onChange={e => setCurrentSample(() => ({
                                ...currentSample,
                                catalystApp: e.target.value
                            }))}
                            inputProps={{ tabIndex: "15", maxLength:70 }}
                          value={currentSample.catalystApp === null ? '' : currentSample.catalystApp}
                          disabled={HasMetaDataLock()}></StyledShortProcessTechnologyField>               
                </div>

                <div style={{display:"flex"}}>

                        <StyledShortProcessTechnologyField size="small" margin="normal" variant="outlined" label="Product Stream" InputLabelProps={{shrink: true}}
                            onChange={e => setCurrentSample(() => ({
                            ...currentSample,
                            productStream: e.target.value
                          }))}
                          inputProps={{ tabIndex: "4", maxLength:70 }}
                          value={currentSample.productStream === null ? '' : currentSample.productStream}
                          disabled={HasMetaDataLock()}></StyledShortProcessTechnologyField>

                        <StyledShortProcessTechnologyField size="small" margin="normal" variant="outlined" label="Feedstock Number 2" InputLabelProps={{shrink: true}}
                            onChange={e => setCurrentSample(() => ({
                                ...currentSample,
                                feedstockNumber2: e.target.value
                            }))}
                            inputProps={{ tabIndex: "10", maxLength:70 }}
                          value={currentSample.feedstockNumber2 === null ? '' : currentSample.feedstockNumber2}
                          disabled={HasMetaDataLock()}></StyledShortProcessTechnologyField>

                        <StyledShortProcessTechnologyField size="small" margin="normal" variant="outlined" label="Catalyst Number" InputLabelProps={{shrink: true}}
                            onChange={e => setCurrentSample(() => ({
                                ...currentSample,
                                catalystNumber: e.target.value
                            }))}
                            inputProps={{ tabIndex: "16", maxLength:70 }}
                          value={currentSample.catalystNumber === null ? '' : currentSample.catalystNumber}
                          disabled={HasMetaDataLock()}></StyledShortProcessTechnologyField>
                </div>

                <div style={{display:"flex"}}>
                        <StyledShortProcessTechnologyField size="small" margin="normal" variant="outlined" label="Product Low Cut Temp" InputLabelProps={{shrink: true}}
                            onChange={e => setCurrentSample(() => ({
                            ...currentSample,
                            productLowCutTemp: e.target.value
                          }))}
                          inputProps={{ tabIndex: "5", maxLength:70 }}
                          value={currentSample.productLowCutTemp === null ? '' : currentSample.productLowCutTemp}
                          disabled={HasMetaDataLock()}></StyledShortProcessTechnologyField>

                        <StyledShortProcessTechnologyField size="small" margin="normal" variant="outlined" label="Feedstock Low Cut Temp" InputLabelProps={{shrink: true}}
                            onChange={e => setCurrentSample(() => ({
                                ...currentSample,
                                feedstockLowCutTemp: e.target.value
                            }))}
                        inputProps={{ tabIndex: "11", maxLength:70 }}
                          value={currentSample.feedstockLowCutTemp === null ? '' : currentSample.feedstockLowCutTemp}
                          disabled={HasMetaDataLock()}></StyledShortProcessTechnologyField>
                </div>

                <div style={{display:"flex"}}>
                            <StyledShortProcessTechnologyField size="small" margin="normal" variant="outlined" label="Product High Cut Temp" InputLabelProps={{shrink: true}}
                                onChange={e => setCurrentSample(() => ({
                                ...currentSample,
                                productHighCutTemp: e.target.value
                            }))}
                            inputProps={{ tabIndex: "6", maxLength:70 }}
                            value={currentSample.productHighCutTemp === null ? '' : currentSample.productHighCutTemp}
                            disabled={HasMetaDataLock()}></StyledShortProcessTechnologyField>

                            <StyledShortProcessTechnologyField size="small" margin="normal" variant="outlined" label="Feedstock High Cut Temp" InputLabelProps={{shrink: true}}
                                onChange={e => setCurrentSample(() => ({
                                    ...currentSample,
                                    feedstockHighCutTemp: e.target.value
                                }))}
                            inputProps={{ tabIndex: "12", maxLength:70 }}
                            value={currentSample.feedstockHighCutTemp === null ? '' : currentSample.feedstockHighCutTemp}
                            disabled={HasMetaDataLock()}></StyledShortProcessTechnologyField>
                         
                </div>

                
                    </AccordionDetails>
                </Accordion>
    </div>

{/* File Attachments */}    
    <div style={{marginTop:"30px",marginRight:"15px"}}>
         <p style={{
              color:"#111112",
              fontWeight:'600',
              fontSize:"16px",
              fontFamily:"EMprint"
          }}>File Attachments</p>

        <div style={{ display: "flex", width: "46%", marginTop:"5px" }}>
            <FileDragAndDrop 
                files={currentSample.fileAttachments} 
                disabled={HasMetaDataLock()} 
                showFiles={true} 
                handleFiles={HandleFileAdd} 
                handleDeleteFile={ConfirmRemoveAttachment} 
                handleFileDownload={HandleFileDownload}>
            </FileDragAndDrop>
        </div>
    </div>

{/*Container Grid */}
    <div style={{marginTop:"50px"}}>

        <p style={{
            color:"#111112",
            fontWeight:'600',
            fontSize:"16px",
            fontFamily:"EMprint"
        }}>Manage Containers</p>

            <ContainerTable
                width = {'110%'}
                showParentContainer = {currentSample.containers.filter(c => c.sampleName === currentSample.sampleName).length > 0}
                showTests = {false}
                containers ={currentSample.containers}
                setContainers = {UpdateContainerList} 
                containerErrorChecks = {containerErrorChecks} 
                setContainerErrorChecks = {setContainerErrorChecks}
                enableAdditions = {!(IsButtonDisabled())}
                enableDeletions = {true} 
                enableCopy = {true}
                availableParentContainers = {availableParentContainers}
                sampleObject = {currentSample}
                substanceObject = {currentSample.substance ? currentSample.substance : null}
                lockContainerStatus={false}
                newContainerDefaultOwner = {null}
            >
            </ContainerTable>
    </div>

{/*Buttons at bottom */}
    <div>
            <GlobalButton  
                name="btnAddSample"
                variant='contained'
                style={{ textTransform: "none",  marginTop:"50px", marginBottom:"25px"}}
                onClick={(e) => ValidateSampleAndContainers()}
                disabled={IsButtonDisabled()}
            >{saveButtonText}</GlobalButton>

            <GlobalSecondaryButton  
                disabled={isTemplateSaving}
                name="btnSaveTemplate"
                variant='contained'
                style={{ textTransform: "none",  marginTop:"50px", marginBottom:"25px", marginLeft:"25px"}}
                onClick={(e) => openModalTemplate(templateModalTitle, "Enter a name for your new template", templateButton1Text, buttonCancelText)}
            >{'Save as Template'}</GlobalSecondaryButton>

            <GlobalSecondaryButton  
                disabled={isTemplateSaving || allowTemplateUpdates === false}
                name="btnUpdateTemplate"
                variant='contained'
                style={{ textTransform: "none",  marginTop:"50px", marginBottom:"25px", marginLeft:"25px"}}
                //onClick={(e) => templateButtonText === templateButtonUpdateText ? SaveTemplate(e) : openModalTemplate(templateModalTitle, "Enter a name for your new template", templateButton1Text, buttonCancelText)}
                onClick={(e) => UpdateTemplate()}
            >{'Update Template'}</GlobalSecondaryButton>               
    </div>

    </Box>

</div>  

{/*Modals */}
        <div>
            <PrintInformation 
                open={printInfoOpen} 
                setOpen={setPrintInfoOpen} 
                button2Action={closePrintInformation} 
                button2Text={"View My Samples"}
                printLabelInfo={printLabelInfo}
                isShelfLabel={false}
                > 
            </PrintInformation>

            {/* Saving new drafts */}
            <ModalTwoButtons title={modalDraftsTitle} button1Text={modalDraftsButton1Text} button1Action={SaveNewDraft} button2Text={modalDraftsButton2Text} button2Action={closeModalDrafts} open={modalDraftsOpen} setOpen={setModalDraftsOpen}>
                <label>
                    {modalDraftsText}
                </label>

                <div></div>

                <TextField
                    size="small"
                    label = "Draft Name"
                    value = {draftSaveName}
                    onChange = {e => setDraftSaveName(e.target.value)}
                    error = {draftSaveError}
                    helperText = {draftSaveError ? "Enter a Draft Name" : ""}
                    margin = "normal"
                    style={{width:250, marginTop:40}}
                    inputProps={{ maxLength: 250 }}
                  ></TextField>
             
            </ModalTwoButtons>

            {/* Saving new template */}
            <ModalTwoButtons title={modalTemplatesTitle} button1Text={modalTemplatesButton1Text} button1Action={SaveTemplate} button2Text={modalTemplatesButton2Text} button2Action={closeModalTemplate} open={modalTemplatesOpen} setOpen={setModalTemplatesOpen}>
                <label>
                    {modalTemplatesText}
                </label>

                <div></div>

                <TextField
                    size="small"
                    label = "Template Name"
                    value = {templateSaveName}
                    onChange = {e => setTemplateSaveName(e.target.value)}
                    error = {templateSaveError}
                    helperText = {templateSaveError ? "Enter a Template Name" : ""}
                    margin = "normal"
                    style={{width:250, marginTop:40}}
                    inputProps={{ maxLength: 250 }}
                  ></TextField>

                <div>
                    <FormGroup>
                        <FormControlLabel control={<Switch defaultChecked />} 
                        checked={isPublicChecked}
                        onChange={e => setIsPublicChecked(e.target.checked)}
                        label="Make Template Public" />
                    </FormGroup>
                </div>          
             
            </ModalTwoButtons>

            {/* Attachment Removal */}
            <ModalTwoButtons title={modalDeleteAttachTitle} button1Text={modalDeleteAttachButton1Text} button1Action={deleteFile} button2Text={modalDeleteAttachButton2Text} button2Action={closeModalDeleteAttach} open={modalDeleteAttachOpen} setOpen={setModalDeleteAttachOpen}>
                <label>
                    {modalDeleteAttachText}
                </label>

                <div></div>
            </ModalTwoButtons>

            {/* Final Message Box */}
            <ModalThreeButtons title={modalThreeBtnTitle} button1Text={modalThreeBtnButton1Text} button1Action={OpenPrintingOptions} button2Text={modalThreeBtnButton2Text} 
            button2Action={closeModalThreeButtons} button3Text={modalThreeBtnButton3Text} button3Action={RouteToTesting} open={modalThreeBtnOpen} setOpen={setModalThreeBtnOpen}>

                <label>
                    {modalThreeBtnText}
                </label>
           
            </ModalThreeButtons>

            {/* Informational Messages */}
            <ModalSimpleButton title={modalMessagesTitle} buttonText={modalMessagesButtonText} buttonAction={closeModalMessages} open={modalMessagesOpen} setOpen={setModalMessagesOpen}>
                {modalMessagesTitle === errorsModalTitle ?
                (  modalMessagesText && modalMessagesText.map((text, index) => {
                    return (
                        <div style={{display:"flex"}} key={`mySamples${index}`}>
                            <label>
                            {text}
                            </label>
                        </div>
                    )
                    })
                )
                :
                ( <label>
                    {modalMessagesText}
                  </label>
                )}      
            </ModalSimpleButton>

            <STLCSearch
                open={openSTLCSearch}
                setOpen={setOpenSTLCSerach}
                setLot={(lot, supplier, tradename) => {
                    handleLotSelection(lot, supplier, tradename)
                }}
                setReset={setResetSearchSTLC}
                reset={resetSearchSTLC}
            >   
            </STLCSearch>

        </div>
</>
  );
};

export default NewSample;