import API from "..";

export default class SpecialAnalysisReportFormat {
 
  constructor({
    formatName,
    isActive
  }) {
    this.formatName = formatName;
    this.isActive = isActive;
   
  }

  static async get() {
    const api = await API();
    const { data } = await api.get(`/specialrequestreportformat`);
    return data.result.map((d) => new SpecialAnalysisReportFormat(d));
  }
}