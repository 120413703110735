import React, { useState, useEffect, useContext } from "react";
import {
  Box,
  Typography,
  TextField,
  FormGroup,
  FormControlLabel,
  Checkbox,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { AddCircleOutline, Delete, ClearAll } from "@mui/icons-material";
import {
  StyledTableCellSmall,
  StyledTableCellSmallHoverable,
  StyledTableCellMedium,
  StyledTableCellLarge,
} from "./styles";
import {
  StyledTableBody,
  UXDataTableWithoutBody,
  StyledTableRow,
} from "../../components/UXDataTable";
import ModalTwoButtons from "../../components/Modal/ModalTwoButtons";
import ModalSimpleButton from "../../components/Modal/ModalSimpleButton";
import { StockroomPOContext } from "./context/StockroomPOContext";
import { InventoryItemContext } from "./context/InventoryItemContext";
import EmailAddressTextField from "../../components/EmailAddressField";
import VendorSearchField from "../../components/VendorSearchField/VendorSearchField";
import { status } from "./context/StockroomPOUtils";
import ManagePoTemplate from "./components/ManagePoTemplate";
import { GlobalButton, GlobalSecondaryButton } from "../styles";
import User from "../../api/Admin/User";

const stockToOrderCols = [
  "Stockroom #",
  "Description",
  "Status",
  "Location",
  "Inventory",
  "RO Point",
  "RO Quantity",
  "SUinRU",
  "RO Price",
  "Vendor",
  "RO Type",
  "SigReq",
  "Account",
];
const buildPOCols = [
  "Stockroom #",
  "Description",
  "RO Quantity",
  "SUinRU",
  "RO Price",
  "Vendor",
  "Vendor StkNo",
];

const _emailOrBadge = { email: "email", badgeId: "badgeId" };

const SRManagePOs = ({ canEdit }) => {
  const [emailOrBadge, setEmailOrBadge] = useState(_emailOrBadge.email);
  const [requester, setRequester] = React.useState("");

  const [searchRequesterValid, setSearchRequesterValid] = React.useState();

  const [badgeId, setBadgeId] = useState(null);
  const [isValidBadgeId, setIsValidBadgeId] = useState(false);
  const [reportModal, setReportModal] = useState(false);
  const [showOnlyActive, setShowOnlyActive] = useState(false);
  const [showAllInventory, setShowAllInventory] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [buildPORowsPerPage, setBuildPORowsPerPage] = useState(10);
  const [buildPOPage, setBuildPOPage] = useState(0);
  const [quantity, setQuantity] = useState(1);
  const [filterValue, setFilterValue] = useState("");
  const [selectedItem, setSelectedItem] = useState({});
  const [selectedItems, setSelectedItems] = useState([]);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isMessageModalOpen, setIsMessageModalOpen] = useState(false);
  const [messageContent, setMessageContent] = useState({
    title: "",
    message: "",
  });
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { stockItemsDict, setStockItemsDict, getAllStockItems, isInventoryDataLoading } =
    useContext(InventoryItemContext);
  const { managePO } = useContext(StockroomPOContext);

  const [userInfo, setUserInfo] = useState(null);

  let filteredList = Object.keys(stockItemsDict)
    .map((key) => stockItemsDict[key])
    .filter((item) => item?.siteAssetNumber?.includes(filterValue));

  if (showOnlyActive) {
    filteredList = filteredList.filter((item) => item.isActive === true);
  }

  if (!showAllInventory) {
    filteredList = filteredList.filter((item) => {
      return item.quantity <= item.reorderPoint && item?.openPoItemsCount === 0;
    });
  }

  const handlePageChange = (event, page) => {
    setPage(page);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleBuildPOPageChange = (event, page) => {
    setBuildPOPage(page);
  };

  const handleChangeBuildPORowsPerPage = (event) => {
    setBuildPORowsPerPage(parseInt(event.target.value, 10));
    setBuildPOPage(0);
  };
  const handleChange = (event) => {
    setEmailOrBadge(event.target.value);
  };

  const handleCreatePO = async () => {
    let requestItems = [];
    selectedItems.forEach((item) => {
      let newItem = { ...item };
      newItem.id = 0;
      newItem.equipmentId = item.id;
      newItem.orderStatusName = "Pending";
      newItem.itemQuantity = item.reorderQuantity;

      requestItems.push(newItem);
    });
    const payload = {
      id: 0,
      createdByEmail: null,
      createdDate: undefined,
      lastModifiedByEmail: null,
      lastModifiedDate: null,
      requestedByEmail: emailOrBadge === _emailOrBadge.email ? requester : badgeId.BadgeID,
      completedByEmail: null,
      completeDate: null,
      comments: null,
      vendorID: null,
      vendor: null,
      statusName: status.submitted,
      poItems: requestItems,
    };

    setIsSubmitting(true);
    await managePO(payload)
      .then((response) => {
        setStockItemsDict((prev) => {
          let items = prev;
          let equipmentIds = response.poItems.map((po) => po.equipmentID);

          equipmentIds.forEach((id) => {
            items[id].openPoItemsCount = ++items[id].openPoItemsCount;
          });

          return items;
        });

        setMessageContent({
          title: "Success!",
          message: "Your PO has successfully been created.",
        });
        setIsMessageModalOpen(true);
        setSelectedItems([]);
      })
      .catch((err) => {
        setMessageContent({
          title: "Error!",
          message: "There was an error when creating your PO, please try again later.",
        });
        setIsMessageModalOpen(true);
      })
      .finally(() => setIsSubmitting(false));
  };

  useEffect(() => {
    if (!stockItemsDict.length) {
      getAllStockItems();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const generateReport = () => {
    setReportModal(true);
  };

  useEffect(() => {
    User.validateEmailAddress(requester).then((res) => {
      setUserInfo(res);
    });
  }, [requester]);

  const poFormContainsRequester =
    emailOrBadge === _emailOrBadge.email ? requester?.length > 0 : badgeId?.BadgeID !== undefined;

  return (
    <>
      <Box display="flex" flexDirection="column">
        <Typography component="h2" fontSize={30} marginBottom={2}>
          Find Stock to Order
        </Typography>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="flex-start"
          gap={3}
          marginBottom={2}>
          <Box display="flex" alignItems="center" gap={1}>
            <Typography>(Manual) Stockroom #</Typography>
            <TextField
              type="text"
              size="small"
              placeholder="Type the Stockroom #"
              value={filterValue.toUpperCase()}
              onChange={(e) => setFilterValue(e.target.value)}
            />
          </Box>
          <Box display="flex" alignItems="center" gap={1}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={showOnlyActive}
                    onChange={(event) => {
                      setShowOnlyActive(event.target.checked);
                    }}
                  />
                }
                label="Show only active"
              />
            </FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={showAllInventory}
                  onChange={(event) => {
                    setShowAllInventory(event.target.checked);
                  }}
                />
              }
              label="Show all inventory"
            />
          </Box>
        </Box>
        <UXDataTableWithoutBody
          tableWidth="100%"
          cols={stockToOrderCols}
          blankFirstHeader
          tableId="stockToOrderTable"
          rowLength={filteredList.length}
          enablePaging
          rowsPerPage={rowsPerPage}
          page={page}
          handleChangePage={handlePageChange}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          noDataFoundMessage={"No stock to order found"}
          isDataLoading={isInventoryDataLoading}>
          <StyledTableBody>
            {filteredList.length > 0 &&
              filteredList
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((item) => {
                  return (
                    <StyledTableRow key={item?.id} hover>
                      <StyledTableCellSmall>
                        <Box display="flex" alignItems="center" gap={1} justifyContent="center">
                          <AddCircleOutline
                            fontSize="small"
                            onClick={() => {
                              if (canEdit) {
                                const foundItem = selectedItems.find(
                                  (chosenItem) => chosenItem?.id === item?.id,
                                );
                                if (foundItem) {
                                  setIsMessageModalOpen(true);
                                  setMessageContent({
                                    title: "Error!",
                                    message: "Item(s) already in the list, try another one.",
                                  });
                                } else {
                                  setSelectedItems((prevState) => [...prevState, item]);
                                }
                              }
                            }}
                          />
                        </Box>
                      </StyledTableCellSmall>
                      <StyledTableCellSmall>{item?.siteAssetNumber}</StyledTableCellSmall>
                      <StyledTableCellLarge>{item?.description}</StyledTableCellLarge>
                      <StyledTableCellLarge>
                        {item?.isActive ? "Active" : "Inactive"}
                      </StyledTableCellLarge>
                      <StyledTableCellMedium>{item?.subLocation}</StyledTableCellMedium>
                      <StyledTableCellSmall>{item?.quantity}</StyledTableCellSmall>
                      <StyledTableCellSmall>{item?.reorderPoint}</StyledTableCellSmall>
                      <StyledTableCellSmall>{item?.reorderQuantity}</StyledTableCellSmall>
                      <StyledTableCellSmall>{item?.suinru}</StyledTableCellSmall>
                      <StyledTableCellMedium>
                        ${item?.reorderPriceUSD?.toFixed(2)} /{" "}
                        {item?.stockUnitOfMeasure?.toUpperCase()}
                      </StyledTableCellMedium>
                      <StyledTableCellLarge>{item?.itemVendor?.vendorName}</StyledTableCellLarge>
                      <StyledTableCellMedium>
                        {item?.isAutomaticReorder ? "Automatic" : "Non-automatic"}
                      </StyledTableCellMedium>
                      <StyledTableCellSmall>
                        {item?.isSignatureRequired ? "Yes" : "No"}
                      </StyledTableCellSmall>
                      <StyledTableCellSmall>{item?.costCenter}</StyledTableCellSmall>
                    </StyledTableRow>
                  );
                })}
          </StyledTableBody>
        </UXDataTableWithoutBody>
      </Box>
      <Box display="flex" flexDirection="column">
        <Typography component="h2" fontSize={30} marginBottom={2} marginTop={4}>
          Build POs
        </Typography>

        <Box style={{ display: "flex", flexDirection: "row", marginBottom: ".5rem" }} gap={3}>
          <FormControl
            style={{
              width: "150px",
            }}>
            <InputLabel id="demo-simple-select-label">Input type</InputLabel>
            <Select
              size="small"
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={emailOrBadge}
              label="Input type"
              onChange={handleChange}
              disabled={!canEdit}>
              <MenuItem value={_emailOrBadge.email}>Email</MenuItem>
              <MenuItem value={_emailOrBadge.badgeId}>Badge Id</MenuItem>
            </Select>
          </FormControl>
          {emailOrBadge === _emailOrBadge.email ? (
            <EmailAddressTextField
              fontSize={16}
              fieldWidth={"350px"}
              validatedUserEmail={requester}
              setValidatedUserEmail={setRequester}
              hasErrors={!searchRequesterValid}
              setHasErrors={(value) => setSearchRequesterValid(value)}
              isDisabled={!canEdit}
              labelText="Requester"
              placeholderText="Email"
              showPlusMeButton={true}
              margin={"none"}
            />
          ) : (
            <VendorSearchField
              style={{
                width: "250px",
              }}
              value={badgeId}
              setValue={setBadgeId}
              isValid={isValidBadgeId}
              setIsValid={setIsValidBadgeId}
              isDisabled={!canEdit}
            />
          )}
          <GlobalButton
            variant="contained"
            onClick={handleCreatePO}
            disabled={
              !poFormContainsRequester || !selectedItems.length || isSubmitting || !canEdit
            }>
            Submit POs
          </GlobalButton>
          <GlobalSecondaryButton
            variant="contained"
            onClick={generateReport}
            disabled={!selectedItems.length || isSubmitting || !canEdit}>
            Generate
          </GlobalSecondaryButton>
        </Box>
        <UXDataTableWithoutBody
          tableWidth="100%"
          cols={buildPOCols}
          blankFirstHeader
          tableId="buildPOTable"
          rowLength={selectedItems.length}
          enablePaging
          rowsPerPage={buildPORowsPerPage}
          page={buildPOPage}
          handleChangePage={handleBuildPOPageChange}
          handleChangeRowsPerPage={handleChangeBuildPORowsPerPage}
          noDataFoundMessage={"No built POs found"}>
          <StyledTableBody>
            {selectedItems.length > 0 &&
              selectedItems
                .slice(
                  buildPOPage * buildPORowsPerPage,
                  buildPOPage * buildPORowsPerPage + buildPORowsPerPage,
                )
                .map((item) => {
                  return (
                    <StyledTableRow key={item?.id} hover>
                      <StyledTableCellSmall>
                        <Box display="flex" alignItems="center" gap={1} justifyContent="center">
                          <Delete
                            fontSize="small"
                            onClick={() => {
                              const updatedList = selectedItems.filter(
                                (chosenItem) => chosenItem?.id !== item?.id,
                              );
                              setSelectedItems(updatedList);
                            }}
                          />
                          <ClearAll
                            fontSize="small"
                            onClick={() => {
                              const updatedList = selectedItems.filter(
                                (chosenItem) =>
                                  chosenItem?.itemVendor?.vendorName !==
                                  item?.itemVendor?.vendorName,
                              );
                              setSelectedItems(updatedList);
                            }}
                          />
                        </Box>
                      </StyledTableCellSmall>
                      <StyledTableCellSmall>{item?.siteAssetNumber}</StyledTableCellSmall>
                      <StyledTableCellLarge>{item?.description}</StyledTableCellLarge>
                      <StyledTableCellSmallHoverable
                        onClick={() => {
                          setQuantity(item?.reorderQuantity);
                          setSelectedItem(item);
                          setIsEditModalOpen(true);
                        }}>
                        {item?.reorderQuantity}
                      </StyledTableCellSmallHoverable>
                      <StyledTableCellSmall>{item?.suinru}</StyledTableCellSmall>
                      <StyledTableCellMedium>
                        ${item?.reorderPriceUSD?.toFixed(2)} /{" "}
                        {item?.stockUnitOfMeasure?.toUpperCase()}
                      </StyledTableCellMedium>
                      <StyledTableCellLarge>{item?.itemVendor?.vendorName}</StyledTableCellLarge>
                      <StyledTableCellLarge>{item?.vendorStockNumber}</StyledTableCellLarge>
                    </StyledTableRow>
                  );
                })}
          </StyledTableBody>
        </UXDataTableWithoutBody>
      </Box>
      {isEditModalOpen && (
        <ModalTwoButtons
          title={`Update #${selectedItem?.siteAssetNumber} reorder quantity`}
          button1Text="Cancel"
          button1Action={() => {
            setSelectedItem({});
            setIsEditModalOpen(false);
            setQuantity(1);
          }}
          button2Text="Update"
          button2Action={() => {
            const updatedList = selectedItems.map((item) => {
              if (item?.id === selectedItem?.id) {
                let updatedItem = JSON.parse(JSON.stringify(item));
                updatedItem = {
                  ...updatedItem,
                  reorderQuantity: quantity,
                };
                return updatedItem;
              }
              return item;
            });
            setSelectedItems(updatedList);
            setSelectedItem({});
            setQuantity(1);
            setIsEditModalOpen(false);
          }}
          setOpen={setIsEditModalOpen}
          open={isEditModalOpen}>
          <TextField
            size="small"
            type="number"
            name="quantity"
            label="Quantity"
            min={1}
            value={quantity}
            onChange={(event) => setQuantity(event.target.value)}
          />
        </ModalTwoButtons>
      )}
      {isMessageModalOpen && (
        <ModalSimpleButton
          title={messageContent.title}
          buttonText="Close"
          buttonAction={() => {
            setIsMessageModalOpen(false);
            setMessageContent({ title: "", message: "" });
          }}
          setOpen={setIsMessageModalOpen}
          open={isMessageModalOpen}>
          <Typography style={{ padding: "0 16px", fontSize: 18, textAlign: "center" }}>
            {messageContent.message}
          </Typography>
        </ModalSimpleButton>
      )}

      {selectedItems && reportModal && (
        <ModalSimpleButton
          title={"Report"}
          buttonText={"Close"}
          buttonAction={() => {
            setReportModal(false);
          }}
          open={reportModal}
          setOpen={setReportModal}>
          <ManagePoTemplate reportFile={selectedItems} userInfo={userInfo} />
        </ModalSimpleButton>
      )}
    </>
  );
};

export default SRManagePOs;
