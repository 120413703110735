import React, { useState, useEffect } from "react";
import { UXDataTableWithoutBody, StyledTableBody, StyledTableCell, StyledTableRow } from "../../../components/UXDataTable";
import { Dialog, DialogTitle, DialogContent, Box } from "@mui/material";
import ShippingAddressApi from "../../../api/WorkRequest/ShippingAddress"
import { GlobalButton, GlobalSecondaryButton } from "../../../pages/styles";
import FilterMenu from "../../../components/FilterMenu";
import { applyFiltersToArray } from './../../../global';

const Columns = [
    { id: 'recipientName', label: 'Recipient Name' },
    { id: 'emailAddress', label: 'Recipient\'s E-mail' },
    { id: 'phoneNumber1', label: 'Phone' },
    { id: 'country', label: 'Country' },
    { id: 'postalCode', label: 'Zip Code' },
    { id: 'state', label: 'State' },
    { id: 'city', label: 'City' },
    { id: 'address1', label: 'Street Address' },
    { id: 'company', label: 'Company' },
    { id: 'department', label: 'Department/ Floor No.' },
];

const AddressBook = ({ open, setOpen, handleApply }) => {

    const [listAddressBook, setListAddressBook] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    // const [searchField, setSearchField] = useState('');

    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [page, setPage] = React.useState(0);
    const [selected, setSelected] = React.useState();

    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('');

    const [filteringOpen, setFilteringOpen] = useState(false);
    const [filters, setFilters] = useState([{ name: null, displayName: null, operator: null, enumValues: [], value: '' }])
    const [filteredTableData, setFilteredTableData] = useState([])

    useEffect(() => {
        getAll();
    }, [])

    async function getAll() {
        setIsLoading(true)
        const response = await ShippingAddressApi.getAll();
        if (response && response.length > 0) {
            setListAddressBook(response)
        }
        setIsLoading(false)
    }

    function handleClose() {
        setOpen(false)
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    // const filteredTableData = listAddressBook.filter((data) => {
    //     return data?.recipientName?.toLowerCase().includes(searchField.toLowerCase());
    // })

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    function stableSort(array, comparator) {
        const stabilizedThis = array.map((el, index) => [el, index]);
        stabilizedThis.sort((a, b) => {
            const order = comparator(a[0], b[0]);
            if (order !== 0) return order;
            return a[1] - b[1];
        });
        return stabilizedThis.map((el) => el[0]);
    }

    function getComparator(order, orderBy) {
        return order === 'desc'
            ? (a, b) => descendingComparator(a, b, orderBy)
            : (a, b) => -descendingComparator(a, b, orderBy);
    }

    function descendingComparator(a, b, orderBy) {
        if (b[orderBy] < a[orderBy]) {
            return -1;
        }
        if (b[orderBy] > a[orderBy]) {
            return 1;
        }
        return 0;
    }

    const handleClick = (event, row) => {
        setSelected(row);
    };

    const isSelected = (row) => selected === row;

    const filterOptions = [
        { name: "recipientName", displayName: "Recipient Name", type: "string", enumValues: [] },
        { name: "emailAddress", displayName: "Recipient's E-mail", type: "string", enumValues: [] },
        { name: "phoneNumber1", displayName: "Phone", type: "string", enumValues: [] },
        { name: "country", displayName: "Country", type: "string", enumValues: [] },
        { name: "postalCode", displayName: "Zip Code", type: "string", enumValues: [] },
        { name: "state", displayName: "State", type: "string", enumValues: [] },
        { name: "city", displayName: "City", type: "string", enumValues: [] },
        { name: "address1", displayName: "Street Address", type: "string", enumValues: [] },
        { name: "company", displayName: "Company", type: "string", enumValues: [] },
        { name: "department", displayName: "Department/ Floor No.", type: "string", enumValues: [] },
    ]

    function closeFiltering() {
        setFilteringOpen(false);
    }

    function applyFilters() {
        setFilteringOpen(false);
    }

    const filterClick = (event) => {
        setFilteringOpen(true);
    }

    const clearFiltersClick = (event) => {
        setFilters([{ name: null, displayName: null, operator: null, enumValues: [], value: null }])
    }

    useEffect(() => {
        var filteredArray = applyFiltersToArray(filters, listAddressBook)
        setFilteredTableData(filteredArray)
    }, [filters, listAddressBook])

    return (
        <Dialog
            fullWidth={true}
            maxWidth={'xl'}
            open={open}
            onClose={handleClose}
        >
            <DialogTitle>Saved Address</DialogTitle>
            <DialogContent>
                <div style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
                    <label>
                        To select one of the previous address, just click on the desired row or search by the recipient's name.
                    </label>
                    {/* <div style={{ display: "flex", flexDirection: "row", gap: "20px", alignItems: "center" }}>
                        <label style={{ fontWeight: "bold" }}>Search Recipient Name </label>
                        <TextField
                            variant="outlined"
                            label="Search"
                            value={searchField}
                            onChange={(e) => setSearchField(e.target.value)}
                            size="small"
                        />
                    </div> */}
                    <div style={{ display: "flex", flexDirection: "row", gap: "20px", alignItems: "center" }}>
                        <Box display="flex" alignItems={"center"} marginLeft="auto" marginTop="-.8rem">
                            <GlobalButton style={{ marginRight: "1rem" }} variant="contained"
                                onClick={() => filterClick()}>Filters</GlobalButton>

                            {!(filters[0].name === null) &&
                                <GlobalSecondaryButton variant="contained"
                                    onClick={() => clearFiltersClick()}>Clear Filters</GlobalSecondaryButton>}
                        </Box>
                    </div>
                    <div>
                        <UXDataTableWithoutBody
                            tableWidth='100%'
                            cols={Columns}
                            blankFirstHeader={false}
                            tableId={`addressBookTable`}
                            rowLength={filteredTableData.length}
                            enablePaging={true}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            handleChangePage={handleChangePage}
                            handleChangeRowsPerPage={handleChangeRowsPerPage}
                            noDataFoundMessage={'No Address Book Found'}
                            // enableAddIcon={!myWorkRequest.id}
                            // addFunction={() => AddNewSampleRow()}
                            // addToolTipText="Add new sample"
                            enableSorteable={true}
                            order={order}
                            orderBy={orderBy}
                            handleRequestSort={handleRequestSort}
                            isDataLoading={isLoading}
                        >
                            <StyledTableBody key={`AddressBookTable`}>

                                {stableSort(filteredTableData, getComparator(order, orderBy))
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((addressBook, index) => {
                                        const isItemSelected = isSelected(addressBook);

                                        return (
                                            <StyledTableRow
                                                key={`AddressBookTable~Row${index}`}
                                                hover
                                                onClick={(event) => handleClick(event, addressBook)}
                                                selected={isItemSelected}
                                                style={isItemSelected ? { backgroundColor: 'rgba(25, 118, 210, 0.08)' } : {}}
                                            >
                                                <StyledTableCell component='th' scope='row'>
                                                    {addressBook?.recipientName}
                                                </StyledTableCell>
                                                <StyledTableCell component='th' scope='row'>
                                                    {addressBook?.emailAddress}
                                                </StyledTableCell>
                                                <StyledTableCell component='th' scope='row'>
                                                    {addressBook?.phoneNumber1}
                                                </StyledTableCell>
                                                <StyledTableCell component='th' scope='row'>
                                                    {addressBook?.country}
                                                </StyledTableCell>
                                                <StyledTableCell component='th' scope='row'>
                                                    {addressBook?.postalCode}
                                                </StyledTableCell>
                                                <StyledTableCell component='th' scope='row'>
                                                    {addressBook?.state}
                                                </StyledTableCell>
                                                <StyledTableCell component='th' scope='row'>
                                                    {addressBook?.city}
                                                </StyledTableCell>
                                                <StyledTableCell component='th' scope='row'>
                                                    {addressBook?.address1}
                                                </StyledTableCell>
                                                <StyledTableCell component='th' scope='row'>
                                                    {addressBook?.company}
                                                </StyledTableCell>
                                                <StyledTableCell component='th' scope='row'>
                                                    {addressBook?.department}
                                                </StyledTableCell>
                                            </StyledTableRow>
                                        );
                                    })}
                            </StyledTableBody>
                        </UXDataTableWithoutBody>
                    </div>
                </div>
                <div style={{
                    display: "flex",
                    justifyContent: "center",
                }}>
                    <GlobalButton
                        style={{
                            marginTop: "35px",
                            marginLeft: "6px",
                            // fontSize: "14px",
                            // fontFamily: "EMprint",
                            // textTransform: "none"
                        }}
                        variant='contained'
                        onClick={() => {
                            handleApply(selected);
                            handleClose();
                        }}
                        disabled={!selected}
                    >Apply Address</GlobalButton>

                    <GlobalSecondaryButton
                        style={{
                            marginTop: "35px",
                            marginLeft: "20px",
                            // fontSize: "14px",
                            // fontFamily: "EMprint",
                            // textTransform: "none"
                        }}
                        variant='contained'
                        onClick={handleClose}
                    >Close</GlobalSecondaryButton>
                </div>
            </DialogContent>
            <FilterMenu
                open={filteringOpen}
                setOpen={setFilteringOpen}
                applyBtnAction={applyFilters}
                cancelButtonAction={closeFiltering}
                filteringInfo={filterOptions}
                appliedFilters={filters}
                setAppliedFilters={setFilters}
            />
        </Dialog>
    );
};

export default AddressBook;
