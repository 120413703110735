import { styled } from "@mui/material/styles";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { SortableTable } from "../../components/SortableTable/SortableTable";
import {
  applyFiltersToArray,
  convertDateFormat,
  getGlobalHyperLinkColor,
} from "../../global";
import ProcessMenuItem from "./ProcessMenuItem";



const LinkTo = styled(Link)({
  textAlign: "left",
  fontFamily: "EMprint",
  fontWeight: "600",
  textTransform: "none",
  color: getGlobalHyperLinkColor(),
  textDecoration: "underline",
  cursor: "pointer",
});
const MySortableWorkRequestCols = [
  {
    id: "id",
    label: "ID",
    transform: (value) => (
      <LinkTo to={{pathname: `/viewWorkRequests/${value}`, state: {tab: 'process'}}}>{value}</LinkTo>
    ),
  },
  {
    id: "typeName",
    label: "Category",
  },
  {
    id: "priorityName",
    label: "Priority",
  },

  {
    id: "description",
    label: "Description",
  },
  {
    id: "requestedByEmail",
    label: "Requester",
  },
  {
    id: "assignedToEmail",
    label: "Assigned To",
  },
  // {
  //   id: "createdDate",
  //   label: "Requested Date",
  //   transform: (value) => (value ? convertDateFormat(value) : ""),
  // },
  {
    id: "requestedCompletionDate",
    label: "Requested Completion",
    transform: (value) => {
      return value ? convertDateFormat(value) : "";
    },
  },
  {
    id: "lastAcceptableCompletionDate",
    label: "Last Acceptable Date",
    transform: (value) => (value ? convertDateFormat(value) : ""),
  },
  {
    id: "facilityName",
    label: "Facility",
  },
  {
    id: "businessUnitName",
    label: "Business Unit",
  },
  {
    id: "statusName",
    label: "Status",
  },
  {
    id: "mwrName",
    label: "MWR Name",
  },
];

const TableRequestQueue = ({
  filters,
  myRequests,
  reloadWorRequest,
  isDataLoading,
  billingList,
  isProcessing,
  copyWorkRequest,
}) => {
  const [filteredRequests, setFilteredRequests] = useState([]);
  useEffect(() => {
    var filteredArray = applyFiltersToArray(filters, myRequests);
    setFilteredRequests(filteredArray);
  }, [filters, myRequests]);

  return (
    <div style={{ width: "100%" }}>
      <SortableTable
        columns={MySortableWorkRequestCols.filter(f => (!isProcessing && f.label !== 'Business Unit') || isProcessing)}
        count={filteredRequests.length ?? 0}
        loading={isDataLoading}
        rows={filteredRequests}
        rowsPerPage={10}
        initialOrderBy={"priorityName"}
        children={<ProcessMenuItem myRequests={myRequests} billingList={billingList} reloadWorRequest={reloadWorRequest} copyWorkRequest={copyWorkRequest} />}
        tableSize="small"
      ></SortableTable>
    </div>
  );
};

export default TableRequestQueue;
