import API from '../index'

export default class DistillationCutType {
    constructor(
        distillationCutTypeName,
        isActive,
        isBlend,
        isHighVacuum,
        isAtmospheric, 
        isExtraction,
        isMassBalance, 
        isStartingCut,
        isEndingCut
    ) {
        this.distillationCutTypeName = distillationCutTypeName
        this.isActive = isActive
        this.isBlend = isBlend
        this.isHighVacuum = isHighVacuum
        this.isAtmospheric = isAtmospheric
        this.isExtraction = isExtraction
        this.isMassBalance = isMassBalance
        this.isStartingCut = isStartingCut
        this.isEndingCut = isEndingCut
    }

    static async getAllCutTypes() {
        const api = await API();
        const { data } = await api.get('/distillationcuttype');
        return data.result;
    }
}