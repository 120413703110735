import { useState, useEffect, useMemo } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import FilterMenu from "../../components/FilterMenu";
import { applyFiltersToArray, exportToCsv } from "../../global";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";

import STRequest from "../../api/StationaryTesting/STRequest";
import STStatus from "../../api/StationaryTesting/STStatus";

import { GlobalButton, GlobalSecondaryButton } from "../styles";
import {
  FILTER_INITIAL_STATE,
  filterOptions,
  NOTIFICATION_INITIAL_VALUES,
  notificationSchema,
  ST_STATUS_ENUM,
} from "./shared";
import { useFormik } from "formik";
import API from "../../api";
import { toast } from "react-hot-toast";
import { SortableTable } from "../../components/SortableTable/SortableTable";
import ProcessRequestsAdditionActions from "./components/ProcessRequestsAdditionActions";
import DetailedProcessRequest from "./components/DetailedProcessRequest/DetailedProcessRequest";
import type { AdditionalMenuAction, StationaryTest } from "./types";
import { processRequestsCols } from "./tableColumns";
import STQueue from "../../api/StationaryTesting/STQueue";
import STType from "../../api/StationaryTesting/STType";
import { SideImportantInformationProvider } from "./context/SideImportantInformationContext";

const ProcessRequests = () => {
  const [statuses, setStatuses] = useState<string[]>([]);
  const [queues, setQueues] = useState<string[]>([]);
  const [types, setTypes] = useState<string[]>([]);
  const [requests, setRequests] = useState<StationaryTest[]>([]);
  const [isRequestsLoading, setIsRequestsLoading] = useState(false);
  const [filteringOpen, setFilteringOpen] = useState(false);
  const [filters, setFilters] = useState(FILTER_INITIAL_STATE);
  const [notifyModalOpen, setNotifyModalOpen] = useState(false);
  const [stationaryTestBeingNotified, setStationaryTestBeingNotified] =
    useState<StationaryTest>();
  const [visibleIds, setVisibleIds] = useState<number[]>([]);

  const formik = useFormik({
    initialValues: NOTIFICATION_INITIAL_VALUES,
    validationSchema: notificationSchema,
    onSubmit: async (values) => {
      if (stationaryTestBeingNotified) {
        if (
          stationaryTestBeingNotified.requestedByEmail &&
          stationaryTestBeingNotified.requestedByEmail !== ""
        ) {
          const api = await API();

          if (api) {
            const notification = {
              notificationToEmail: stationaryTestBeingNotified.requestedByEmail,
              notificationContent: values.notificationContent,
              notificationSubject:
                "Stationary Testing: Notification to requester",
            };

            const { data } = await api.post("/notification", notification);

            if (data.message === "Success") {
              toast.success("Notification sent");
            } else {
              toast.error(data.message);
            }
          }
        } else {
          toast.error("Requester e-mail not found");
        }
      } else {
        toast.error("Stationary test to be notified not found");
      }
      setNotifyModalOpen(false);
    },
  });

  const handleClose = () => {
    setNotifyModalOpen(false);
  };

  const getAllRequests = async () => {
    setIsRequestsLoading(true);
    const data = await STRequest.getAllForProcessRequests();
    setRequests(data);
    setIsRequestsLoading(false);
  };

  const getAllStatuses = async () => {
    const data = await STStatus.getAllActiveStatusNamesOrdered();
    setStatuses(data);
  };

  const getAllQueues = async () => {
    const data: string[] = await STQueue.getAllActiveQueueOptions();
    setQueues(data);
  };

  const getAllTypes = async () => {
    const data: string[] = await STType.getAllTypeNames();
    setTypes(data);
  };

  const filteredRequests =
    filters && filters[0] && filters[0].name === ""
      ? requests.filter(
          (r: any) =>
            r.statusName !== ST_STATUS_ENUM.Cancelled &&
            r.statusName !== ST_STATUS_ENUM.Completed
        )
      : applyFiltersToArray(filters, requests);

  useEffect(() => {
    getAllRequests();
    getAllStatuses();
    getAllQueues();
    getAllTypes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleStationaryTestNotification(st: StationaryTest) {
    setStationaryTestBeingNotified(st);
    setNotifyModalOpen(true);
  }

  const additionalMenuActions: AdditionalMenuAction[] = [
    {
      label: "Notify Submitter",
      action: (row: StationaryTest) => handleStationaryTestNotification(row),
    },
  ];

  function handleVisibilityToggle(newIds: number[]) {
    setVisibleIds(newIds);
  }

  const visibleAndOrderedProcessRequests = useMemo(() => {
    const orderedAndFiltered: any = [];

    visibleIds.forEach((vi) => {
      const correspondentProcessRequest = requests.find(
        (fr: any) => fr.id === vi
      );

      if (correspondentProcessRequest) {
        orderedAndFiltered.push(correspondentProcessRequest);
      }
    });

    return orderedAndFiltered;
  }, [requests, visibleIds]);

  async function handleCloseTab(closedTabIndex: number) {
    const newIds = [...visibleIds].filter((vi, i) => i !== closedTabIndex);

    if (!newIds || newIds.length === 0) {
      getAllRequests();
    }

    setVisibleIds(newIds);
  }

  return (
    <div
      style={{
        paddingBottom: visibleIds.length > 0 ? 520 : 0,
      }}
    >
      <Box sx={{ bgcolor: "#fff", pb: 1 }}>
        <Box display="flex" gap={2}>
          <span className="pageheader">Backlogged Requests</span>

          <Box display="flex" marginLeft="auto">
            <GlobalButton
              style={{ marginRight: "1rem" }}
              variant="contained"
              onClick={() => setFilteringOpen(true)}
            >
              Filters
            </GlobalButton>
            {!(filters[0].name === null) && (
              <GlobalSecondaryButton
                style={{ marginRight: "1rem" }}
                variant="contained"
                onClick={() => {
                  setFilters(FILTER_INITIAL_STATE);
                }}
              >
                Clear Filters
              </GlobalSecondaryButton>
            )}
            <GlobalSecondaryButton
              variant="contained"
              onClick={(e) =>
                exportToCsv(
                  filteredRequests.map((item: StationaryTest) => {
                    return {
                      "Card Number": item.cardNumber,
                      "Test Name": item.testDisplayName,
                      "Test Type": item.testTypeName,
                      "Run Number": item.runNumber,
                      "MIDAS Number": item.submittedSampleName,
                      "Container Number": item.submittedContainerNumber,
                      Requester: item.requestedByEmail,
                      "Date Created": item.createdDate,
                      "Test Started On": item.testStartDate,
                      "Test Stopped On": item.testEndDate,
                      "Test Facility": item.stationaryTestFacilityName,
                      "Test Stand": item.testStandName,
                      Status: item.statusName,
                      Queue: item.workGroupName,
                    };
                  }),
                  "Stationary Testing - Backlogged Requests"
                )
              }
            >
              Export CSV
            </GlobalSecondaryButton>
          </Box>
        </Box>
        <Box sx={{ p: 1 }} />
      </Box>
      <SortableTable
        columns={processRequestsCols}
        count={filteredRequests ? filteredRequests.length : 0}
        loading={isRequestsLoading}
        rows={filteredRequests}
        rowsPerPage={10}
        initialOrderBy="createdDate"
        initialAscendingOrder={false}
        children={
          <ProcessRequestsAdditionActions
            additionalMenuActions={additionalMenuActions}
            visibleIds={visibleIds}
            setVisibleIds={handleVisibilityToggle}
            row={null}
          />
        }
      />
      <FilterMenu
        open={filteringOpen}
        setOpen={setFilteringOpen}
        applyBtnAction={() => setFilteringOpen(false)}
        cancelButtonAction={() => setFilteringOpen(false)}
        filteringInfo={[
          ...filterOptions,
          {
            name: "statusName",
            displayName: "Status",
            type: "enum",
            enumValues: statuses,
          },
          {
            name: "workGroupName",
            displayName: "Queue",
            type: "enum",
            enumValues: queues,
          },
          {
            name: "testTypeName",
            displayName: "Test Type",
            type: "enum",
            enumValues: types,
          },
        ]}
        appliedFilters={filters}
        setAppliedFilters={setFilters}
        children={null}
      />

      {visibleIds.length > 0 && (
        <SideImportantInformationProvider>
          <DetailedProcessRequest
            visibleProcessRequests={visibleAndOrderedProcessRequests}
            onCloseTab={handleCloseTab}
          />
        </SideImportantInformationProvider>
      )}

      <Dialog
        fullWidth={true}
        maxWidth="md"
        open={notifyModalOpen}
        onClose={handleClose}
      >
        <DialogTitle>Notify Submitter</DialogTitle>
        <DialogContent>
          <Box
            component="form"
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <TextField
              name="notificationContent"
              id="notificationContent"
              label="Message"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.notificationContent}
              minRows={15}
              multiline
              required
              helperText={formik.errors.notificationContent || ""}
              error={
                formik.touched.notificationContent &&
                Boolean(formik.errors.notificationContent)
              }
              style={{ marginTop: "1rem" }}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            disabled={
              !formik.isValid &&
              (!formik.values.notificationContent ||
                formik.values.notificationContent === "")
            }
            onClick={() => formik.submitForm()}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ProcessRequests;
