import { useState } from "react";
import { TextField, Box } from "@mui/material";
import { formatMidasNumber } from "../../../global";
import { styled } from "@mui/material/styles";
import TrashIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/AddCircleOutline';
import ValidatedMidasNumberTextField from  "../../../components/ValidatedMidasNumberTextField";

const StyledP = styled('p')({
  color:"#000000DE",
  fontFamily:"EMprint",
  fontWeight:"600",
  fontSize: "16px",
  paddingTop:"2rem",
  paddingLeft:".6rem",
  paddingBottom:"2rem"
})

const NewTest = ({mySampleInfo, setMySampleInfo, containerErrorChecks, setContainerErrorChecks, }) => {
  
  const [errors, setErrors] = useState([false])

  const NewFieldKeyPress = (e) =>{
    let copyMySampleInfo = JSON.parse(JSON.stringify(mySampleInfo))
    let masterMethods = JSON.parse(JSON.stringify(mySampleInfo.masterMethods))

    copyMySampleInfo.samples.push({
      sampleName:'', 
      sampleObject:null,
      methods:[masterMethods],
      containerManagementType:"midas", 
      containers:[]
    })

    let copyContainerErrors = JSON.parse(JSON.stringify(containerErrorChecks))
    copyContainerErrors.push({containerErrorInfo:[]})
    setContainerErrorChecks(copyContainerErrors)

    setMySampleInfo(copyMySampleInfo)
  }

  const RemoveSampleFields = (index) => {
    if(mySampleInfo.length < 2)
    {
      return
    }
    
    let copyMySampleInfo = JSON.parse(JSON.stringify(mySampleInfo))
    let copyContainerErrors = JSON.parse(JSON.stringify(containerErrorChecks))

    copyMySampleInfo.samples.splice(index, 1) 
    copyContainerErrors.splice(index, 1)

    setContainerErrorChecks(copyContainerErrors)
    setMySampleInfo(copyMySampleInfo)
  } 

  function SetSampleObject(e, index)
  {
    let copyMySampleInfo = structuredClone(mySampleInfo)

    copyMySampleInfo.samples[index].sampleObject = e
    copyMySampleInfo.samples[index].sampleName = e != null ? formatMidasNumber(e.sampleName) : ''

    setMySampleInfo(copyMySampleInfo)
  }

  function UpdateErrorChecks(e, index)
  {
    const copyErrors = structuredClone(errors)

    copyErrors[index] = e

    setErrors(copyErrors)
  }
  
  return (

  <div>
      <Box component="form"
      sx={{
        '& .MuiTextField-root': { m: 1, width: '25ch' },
      }}
        style={{
          backgroundColor: "white"
        }}
      >

        <StyledP>Add Samples to Assign Testing</StyledP>

        {mySampleInfo.samples.map((oSample, index) => {

        return (
          <div key={index}>
          
          {oSample.sampleObject &&
            <AddIcon style={{marginTop:"1rem", marginRight:".5rem"}}
              onClick={(e) => NewFieldKeyPress(e)}>
            </AddIcon>
          }

          {index > 0 &&
            <TrashIcon
              onClick={(e) => RemoveSampleFields(index)}
            ></TrashIcon>
          }

          <ValidatedMidasNumberTextField
            isDisabled = {false}
            margin = {"none"}
            showLabel = {true}
            fontSize = {14}
            fieldWidth = {"12%"}
            midasNumberObject = {oSample.sampleObject}
            setMidasNumberObject = {(e) => SetSampleObject(e, index)}
            hasErrors = {errors[index] === null ? false : errors[index]}
            setHasErrors = {(e) => UpdateErrorChecks(e, index)}
            chemIDToMatch = {null}
          ></ValidatedMidasNumberTextField>   

          <TextField size="small"
            style={{
              id: "outlined-normal",
              width: "25%",
              fontSize: 14
            }}
              disabled
              label = "Sample Description"
              variant="outlined"
              id="SampleDescription"
              margin="normal"
              value={oSample.sampleObject ? oSample.sampleObject.description : ''}
              InputLabelProps={{shrink: true}}
              ></TextField>

          <TextField size="small"
            style={{
              id: "outlined-normal",
              width: "20%",
              fontSize: 14
            }}
              disabled
              label = "Chem ID"
              variant="outlined"
              id="ChemID"
              margin="normal"
              value={oSample.sampleObject ? oSample.sampleObject.chemID : ''}
              InputLabelProps={{shrink: true}}
              ></TextField>

          <TextField size="small"
            style={{
              id: "outlined-normal",
              width: "20%",
              fontSize: 14
            }}
              disabled
              label = "Sample Owner"
              variant="outlined"
              id="SampleOwner"
              margin="normal"
              value={oSample.sampleObject ? oSample.sampleObject.sampleOwnerEmail : ''}
              InputLabelProps={{shrink: true}}
              ></TextField>
          </div>
          )
        })}

        <p></p>
        <div style={{
            paddingTop:"20px"
        }}>
         </div>
  </Box>
  </div>
  );
};

export default NewTest;
