import React from "react";
import { Divider } from "@mui/material";

const PageNotFound = ({ ...props }) => {
  return (
    <div>
      <span className='pageheader'>PageNotFound</span>
      <Divider className='dividerbar' />
      <p>The page you are looking for cannot be found.</p>
    </div>
  );
};

export default PageNotFound;
