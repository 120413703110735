import React, { useState, useEffect } from "react";
import { Typography, Box } from "@mui/material";
import { UXDataTable } from "../../../components/UXDataTable";
import { applyFiltersToArray } from "../../../global";
import ModalSimpleButton from '../../../components/Modal/ModalSimpleButton'

const myExperimentCols = [
  { field: 'id', headerName: 'Experiment ID', type: 'button-distillation', path: '/newExperiment' },
  { field: 'mnemonic', headerName: 'Mnemonic', type: 'label' },
  { field: 'experimentDescription', headerName: 'Experiment Description', type: 'label' },
  { field: 'requestedByEmail', headerName: 'Requester Email', type: 'label' },
  { field: 'createdDate', headerName: 'Requested Date', type: 'datetime' },
  { field: 'distillationStatusName', headerName: 'Status', type: 'label' },
];

const TableMyExperiments = ({ filters, myExperiments, isDataLoading, userEmail, updateExperiment }) => {
  const [filteredExperiments, setFilteredExperiments] = useState([])
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [modalTitle, setModalTitle] = useState('')
  const [modalMessage, setModalMessage] = useState('')
  
  const handleCancelExperiment = async (experiment) => {
    if (
      experiment?.distillationStatusName === 'Complete' || 
      experiment?.experimentStartDate === 'InProgress' ||
      experiment?.distillationStatusName === 'Cancelled'
    ) {
      setIsCancelModalOpen(true)
      return 
    }

    const payload = {
      ...experiment,
      distillationStatusName: 'Cancelled'
    }

    const result = await updateExperiment(payload)
    if (result) {
      setIsModalOpen(true)
      setModalTitle('Success')
      setModalMessage('Experiment cancelled with success')
    } else {
      setIsModalOpen(true)
      setModalTitle('Fail')
      setModalMessage('Something went wrong, please try after sometime')
    }
  }

  const menuItems = [
    {
      menuType: 'text',
      redirectPath: '',
      text: 'Cancel Experiment',
      onClickFunction: handleCancelExperiment,
    },
  ]
 
  useEffect(() => {
      const filteredArray = applyFiltersToArray(filters, myExperiments)
      setFilteredExperiments(filteredArray)
  }, [filters, myExperiments])

  return (
    <Box display="flex" flexDirection="column" gap={2}>

      <UXDataTable 
        tableWidth='100%' 
        cols={myExperimentCols} 
        rows={filteredExperiments.sort((a, b) => new Date(b.createdDate) - new Date(a.createdDate))} 
        moreOptionsCell
        enablePaging
        menuProps={menuItems}
        noDataMessage={`No Experiments found for owner = ${userEmail}`}
        defaultRowsPerPage={10} 
        isDataLoading={isDataLoading} 
        tableName={'myExperiments'}
        enableSorting={true}
      />

      {isCancelModalOpen && (
        <ModalSimpleButton
          title="Warning!"
          buttonText="Close"
          buttonAction={() => {
            setIsCancelModalOpen(false)
          }}
          setOpen={setIsCancelModalOpen}
          open={isCancelModalOpen}
        >
          <Typography style={{ padding: '0 16px', fontSize: 18, textAlign: 'center' }}>
            You cannot cancel a cancelled experiment, completed experiment or an experiment that is already in progress.
          </Typography>
        </ModalSimpleButton>
      )}
      {isModalOpen && (
        <ModalSimpleButton
          title={modalTitle}
          buttonText="Ok"
          buttonAction={() => {
            setIsModalOpen(false)
            setModalTitle('')
            setModalMessage('')
          }}
          setOpen={setIsModalOpen}
          open={isModalOpen}
        >
          <Typography style={{ padding: '0 16px', fontSize: 18, textAlign: 'center' }}>
            {modalMessage}
          </Typography>
        </ModalSimpleButton>
      )}
    </Box>
  );
};

export default TableMyExperiments;