import { createContext, useContext, useState } from "react";

export type SideInformationProps = {
  label: string;
  value: string | number | boolean | null | undefined;
};

let INITIAL_SIDE_INFORMATION: SideInformationProps[] = [];

export const SideImportantInformationContext = createContext({
  sideImportantInformation: INITIAL_SIDE_INFORMATION,
  setSideImportantInformation: (info: SideInformationProps[]) => {},
});

export const SideImportantInformationProvider = ({ children }: any) => {
  const [sideImportantInformation, setSideImportantInformation] = useState(
    INITIAL_SIDE_INFORMATION
  );
  return (
    <SideImportantInformationContext.Provider
      value={{ sideImportantInformation, setSideImportantInformation }}
    >
      {children}
    </SideImportantInformationContext.Provider>
  );
};

export const useSideImportantInformation = () => {
  const { sideImportantInformation, setSideImportantInformation } = useContext(
    SideImportantInformationContext
  );
  return { sideImportantInformation, setSideImportantInformation };
};
