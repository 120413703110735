import Autocomplete from "@mui/material/Autocomplete";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { PackageTestCriteria } from "../../../api/Admin/PackageTestCriteria";
import UnitOfMeasure from "../../../api/Admin/UnitOfMeasure";
import Substance from "../../../api/LIMS/Substance";
import { ChemIDSearchField } from "../../../components/ChemIDSearch";
import MethodSelection, {
  defaultMethodObj,
} from "../../../components/MethodSelection";
import CustomModal from "../../../components/Modal";
import { GlobalButton, GlobalSecondaryButton } from "../../styles";
import { packageTestCriteriaSchema } from "./utilities";

type PackageTestCriteriaFormProps = {
  initialValue: PackageTestCriteria;
  onClose(): void;
  onUpdate(packageTestCriterion: PackageTestCriteria): void;
  methods: any[];
  unitsOfMeasure: UnitOfMeasure[];
};

export function PackageTestCriteriaForm({
  initialValue,
  onClose,
  onUpdate,
  methods,
  unitsOfMeasure,
}: PackageTestCriteriaFormProps) {
  const [selectedMethods, setSelectedMethods] = useState<any[]>([]);

  const formik = useFormik<PackageTestCriteria>({
    initialValues: initialValue,
    onSubmit: (values) => {
      onUpdate(values);
      onClose();
    },
    validationSchema: packageTestCriteriaSchema,
    validateOnMount: true,
  });

  useEffect(() => {
    const method = structuredClone(defaultMethodObj);

    if (formik.values.criteriaMethodFacility) {
      method.method = methods.find(
        (m) => m.name === formik.values.criteriaMethodFacility.method.name
      );
      method.methodFacility = (method.method as any).methodFacilities.find(
        (m: any) => m.id === formik.values.criteriaMethodFacility.id
      );
      method.conditionOffering = (
        method.methodFacility as any
      ).testConditionOfferings.find(
        (t: any) => t.id === formik.values.testConditionOffering.id
      );
      setSelectedMethods([method]);
    } else if (initialValue.criteriaMethodFacility) {
      method.method = methods.find(
        (m) => m.name === formik.values.criteriaMethodFacility.method.name
      );
      method.methodFacility = (method.method as any).methodFacilities.find(
        (m: any) => m.id === initialValue.criteriaMethodFacility.id
      );
      method.conditionOffering = (
        method.methodFacility as any
      ).testConditionOfferings.find(
        (t: any) => t.id === initialValue.testConditionOffering
      );
      setSelectedMethods([method]);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValue]);

  const saveDisabled = !formik.isValid || 
    (!formik.values.stepDescription || formik.values.stepDescription === "") || 
    (!formik.values.generatesNewSample && (!selectedMethods || selectedMethods.length === 0));

  return (
    <CustomModal
      title="Test Step Configuration"
      open={true}
      setOpen={() => {}}
      setClose={onClose}
    >
      <Stack sx={{ marginBlockStart: "0.5rem" }}>
        <TextField
          name="stepDescription"
          label="Description"
          value={formik.values.stepDescription ?? ""}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={
            formik.touched.stepDescription &&
            Boolean(formik.errors.stepDescription)
          }
          size="small"
          sx={{ width: "26rem", marginBottom: 2 }}
        />

        <MethodSelection
          width="80rem"
          selectedMethods={selectedMethods}
          setSelectedMethods={(v: any) => {
            const value = v?.[0];

            if (value?.methodFacility)
              value.methodFacility.method = value.method;

            formik.setFieldValue(
              "criteriaMethodFacility",
              value?.methodFacility
            );
            formik.setFieldValue("methodFacilityID", value?.methodFacility?.id);
            formik.setFieldValue(
              "testConditionOffering",
              value?.conditionOffering
            );
            formik.setFieldValue(
              "testConditionOfferingID",
              value?.conditionOffering?.id ?? null
            );

            setSelectedMethods(v);
          }}
          showCompletionDate={false}
          showContainerGrouping={false}
          incomingTemplate={null}
          isReadOnly={false}
          showSearchTemplateName={false}
          showEstimateAndPriority={false}
          maxMethodsAllowed={1}
          disabledUserDefinedConditions={true}
        />

        <FormControlLabel
          control={
            <Checkbox
              name="generatesNewSample"
              checked={formik.values.generatesNewSample}
              onChange={(event) => {
                if (!event.target.checked) {
                  formik.setFieldValue("defaultNewSampleChemID", null);
                  formik.setFieldValue("defaultNewSampleSubstance", null);
                  formik.setFieldValue("postTestSampleYield", null);
                  formik.setFieldValue("postTestSampleYieldUoMName", null);
                }

                formik.handleChange(event);
              }}
              onBlur={formik.handleBlur}
            />
          }
          label="Generates new sample"
        />
      </Stack>

      <Stack direction="row" gap="1rem">
        <TextField
          select
          name="conditionalTrigger"
          label="Conditional Trigger"
          value={formik.values.conditionalTrigger ?? ""}
          onChange={(e) =>
            formik.setFieldValue("conditionalTrigger", e.target.value || null)
          }
          onBlur={formik.handleBlur}
          error={
            formik.touched.conditionalTrigger &&
            Boolean(formik.errors.conditionalTrigger)
          }
          size="small"
          sx={{ width: "26rem", marginTop: "1rem" }}
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          <MenuItem value="Completion">Completion</MenuItem>
          {/* <MenuItem value="Time">Time</MenuItem> */}
        </TextField>

        <TextField
          disabled
          name="scheduledIntervalHours"
          label="Scheduled Interval Hours"
          value={formik.values.scheduledIntervalHours ?? ""}
          onChange={(e) => {
            const { value } = e.target;
            if (value.length === 0)
              formik.setFieldValue("scheduledIntervalHours", null);
            const parsed = Number.parseInt(value);
            if (Number.isNaN(parsed)) return;
            formik.setFieldValue("scheduledIntervalHours", parsed);
          }}
          onBlur={formik.handleBlur}
          error={
            formik.touched.scheduledIntervalHours &&
            Boolean(formik.errors.scheduledIntervalHours)
          }
          size="small"
          margin="normal"
          sx={{ width: "26rem", marginBottom: 2 }}
        />
      </Stack>

      <Stack direction="row" gap="1rem">
        <ChemIDSearchField
          helperText=""
          margin="normal"
          fontSize={16}
          fieldWidth="26rem"
          selectedChemIDObject={formik.values.defaultNewSampleSubstance ?? null}
          setSelectedChemIDObject={(e: Substance | null) => {
            formik.setFieldValue("defaultNewSampleChemID", e?.chemID);
            formik.setFieldValue("defaultNewSampleSubstance", e);

            setTimeout(() => formik.validateForm());
          }}
          hasErrors={
            formik.touched.defaultNewSampleChemID &&
            Boolean(formik.errors.defaultNewSampleChemID)
          }
          setHasErrors={(e: boolean) => {}}
          isDisabled={!formik.values.generatesNewSample}
          labelCustom="New Sample Chem ID"
          allowInactiveChemIDs={false}
          returnSubstanceStrucureData={false}
        />

        <Stack direction="row" gap="1rem" sx={{ marginBlockStart: "1rem" }}>
          <TextField
            name="postTestSampleYield"
            label="Yield Amount"
            value={formik.values.postTestSampleYield ?? ""}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.postTestSampleYield &&
              Boolean(formik.errors.postTestSampleYield)
            }
            helperText={
              formik.touched.postTestSampleYield
                ? formik.errors.postTestSampleYield
                : " "
            }
            inputProps={{ maxLength: 200 }}
            size="small"
            sx={{ width: "11rem" }}
            disabled={!formik.values.generatesNewSample}
          />

          <Autocomplete
            autoSelect
            autoHighlight
            options={unitsOfMeasure ?? []}
            value={
              formik.values.postTestSampleYieldUoMName
                ? ({
                    uoMName: formik.values.postTestSampleYieldUoMName,
                  } as UnitOfMeasure)
                : null
            }
            isOptionEqualToValue={(option, value) =>
              option.uoMName === value.uoMName
            }
            getOptionLabel={(option) => option.uoMName}
            renderOption={(props, option) => (
              <li {...props} key={option.uoMName}>
                {option.uoMName}
              </li>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                name="postTestSampleYieldUoMName"
                label="UoM"
                error={
                  formik.touched.postTestSampleYieldUoMName &&
                  Boolean(formik.errors.postTestSampleYieldUoMName)
                }
                size="small"
              />
            )}
            onChange={(event, option) =>
              formik.setFieldValue(
                "postTestSampleYieldUoMName",
                option?.uoMName ?? null
              )
            }
            onBlur={formik.handleBlur}
            sx={{ width: "14rem" }}
            disabled={!formik.values.generatesNewSample}
          />
        </Stack>
      </Stack>

      <Stack
        direction="row"
        alignItems="center"
        justifyContent="center"
        gap={2}
        marginTop="2rem"
      >
        <GlobalButton
          type="submit"
          variant="contained"
          onClick={() => formik.handleSubmit()}
          disabled={saveDisabled}
        >
          Save
        </GlobalButton>

        <GlobalSecondaryButton
          type="button"
          variant="outlined"
          onClick={onClose}
        >
          Cancel
        </GlobalSecondaryButton>
      </Stack>
    </CustomModal>
  );
}
