import { Accordion, AccordionDetails, AccordionSummary, Autocomplete, Box, Checkbox, FormControlLabel, FormGroup, InputLabel, TextField, Typography, InputAdornment } from "@mui/material";
import { styled } from "@mui/system";
import React, { useEffect, useState } from "react";
import ExperimentalSubstanceType from "../../../api/LIMS/ExperimentalSubstanceType";
import ExperimentalSubstanceSubtype1 from "../../../api/LIMS/ExperimentalSubstanceSubtype1";
import ExperimentalSubstanceSubtype2 from "../../../api/LIMS/ExperimentalSubstanceSubtype2";
import ExperimentalSubstanceSubtype3 from "../../../api/LIMS/ExperimentalSubstanceSubtype3";
import { convertToLocalTime } from "../../../global";
import ChemIDCategory from "../../../api/LIMS/ChemIDCategory";
import { ChemIDSearchField } from '../../../components/ChemIDSearch/index';
import EmailAddressTextField from "../../../components/EmailAddressField";

const StyledDiv = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'start',
  width: '100%',
  gap: '40px'
}));

const StyledDivInputs = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  flexDirection: 'column',
  width: '70%',
  gap: '20px'
}));

const StyledBoxInputs = styled(Box)(() => ({
  display: 'flex',
  gap: '20px',
  width: '90%',
  alignItems: 'start'
}));

const StyledAccordionDetails = styled(AccordionDetails)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '20px'
}));

const StyledInputLabel = styled(InputLabel)(() => ({
  wordBreak: 'break-all',
  wordWrap: 'break-word',
  whiteSpace: 'normal;'
}));

const StyledDivInformation = styled('div')({
  display: 'flex',
  gap: '20px'
});

const GeneralInformation = ({
  currentSubstance,
  setCurrentSubstance,
  substanceInitial,
  validateFields,
  fieldsCheck,
  isNewSubstance,
  canEdit,
  canEditMaintainer,
  setErrorChecks,
}) => {
  const [listSubstanceCategory, setListSubstanceCategory] = useState([])
  const [listSubstanceType, setListSubstanceType] = useState([])
  const [listSubstanceSubType1, setListSubstanceSubType1] = useState([])
  const [listSubstanceSubType2, setListSubstanceSubType2] = useState([])
  const [listSubstanceSubType3, setListSubstanceSubType3] = useState([])

  useEffect(() => {
    ChemIDCategory.get().then((resp) => {
      const allCategoriesAllow = ['RCC', 'EC', 'CSS'];
      const categories = canEditMaintainer ? resp : resp.filter(f => allCategoriesAllow.includes(f.chemIDCategoryName))
      setListSubstanceCategory(categories)
    })
    ExperimentalSubstanceType.getAll().then((resp) => {
      setListSubstanceType(resp)
    })
  }, [canEditMaintainer])

  useEffect(() => {
    if (currentSubstance.substanceTypeName !== null && currentSubstance.substanceTypeName !== '') {
      ExperimentalSubstanceSubtype1.get(-1, "", currentSubstance.substanceTypeName).then((resp) => {
        setListSubstanceSubType1(resp)
      })
      ExperimentalSubstanceSubtype3.get(-1, "").then((resp) => {
        setListSubstanceSubType3(resp)
      })
    }
  }, [currentSubstance.substanceTypeName])

  useEffect(() => {
    if (currentSubstance.substanceSubtype1ID !== null && currentSubstance.substanceSubtype1ID !== '') {
      ExperimentalSubstanceSubtype2.get(-1, "", currentSubstance.substanceSubtype1ID).then((resp) => {
        setListSubstanceSubType2(resp)
      })
    }
  }, [currentSubstance.substanceSubtype1ID])

  function handleChangeType(value) {
    if (value !== null && value !== '') {
      setCurrentSubstance(() => ({
        ...currentSubstance,
        substanceTypeName: value,
        substanceSubtype1ID: substanceInitial.substanceSubtype1ID,
        substanceSubtype2ID: substanceInitial.substanceSubtype2ID,
        substanceSubtype3ID: substanceInitial.substanceSubtype3ID
      }))
    } else {
      setCurrentSubstance(() => ({
        ...currentSubstance,
        substanceTypeName: substanceInitial.substanceTypeName,
        substanceSubtype1ID: substanceInitial.substanceSubtype1ID,
        substanceSubtype2ID: substanceInitial.substanceSubtype2ID,
        substanceSubtype3ID: substanceInitial.substanceSubtype3ID
      }))
    }
  }

  function handleChangeSubtype1(value) {
    if (value !== null && value !== '') {
      setCurrentSubstance(() => ({
        ...currentSubstance,
        substanceSubtype1ID: value.sT1ID,
        substanceSubtype2ID: substanceInitial.substanceSubtype2ID,
      }))
    } else {
      setCurrentSubstance(() => ({
        ...currentSubstance,
        substanceSubtype1ID: substanceInitial.substanceSubtype1ID,
        substanceSubtype2ID: substanceInitial.substanceSubtype2ID,
      }))
    }
  }

  function handleChangeSubtype2(value) {
    if (value !== null && value !== '') {
      setCurrentSubstance(() => ({
        ...currentSubstance,
        substanceSubtype2ID: value.sT2ID,
      }))
    } else {
      setCurrentSubstance(() => ({
        ...currentSubstance,
        substanceSubtype2ID: substanceInitial.substanceSubtype2ID,
      }))
    }
  }

  return (
    <Accordion expanded={true}>
      <AccordionSummary
        aria-controls="panel1bh-content"
        id="panel1bh-header"
      >
        <Typography variant="h5" sx={{ width: '33%', flexShrink: 0 }} >
          General Information
        </Typography>
      </AccordionSummary>
      <StyledAccordionDetails>
        <StyledDiv>
          <StyledDivInputs>
            <StyledBoxInputs
              sx={{
                '& > :not(style)': { width: '33.3%' },
              }}
            >
              <TextField size="small" id="chem-id" label="ChemID" variant="outlined" required
                onChange={e => {
                  const current = {
                    ...currentSubstance,
                    chemID: e.target.value
                  }
                  setCurrentSubstance(() => (current))
                  validateFields(current, 'chemID')
                }}
                value={currentSubstance.chemID}
                error={fieldsCheck.chemID}
                helperText={fieldsCheck.chemID ? 'Required' : ''}
                inputProps={{ maxLength: 50 }}
                disabled={isNewSubstance ? false : !canEdit}
                InputProps={{
                  startAdornment: isNewSubstance && (currentSubstance.chemIDCategoryName === 'RCC' || currentSubstance.chemIDCategoryName === 'EC') && <InputAdornment position="start">{currentSubstance.chemIDCategoryName === 'RCC' ? 'RC' : currentSubstance.chemIDCategoryName}-</InputAdornment>,
                }}
              />
              <Autocomplete
                size="small"
                id="substance-category-select"
                disablePortal
                disabled={isNewSubstance ? false : !canEdit}                
                noOptionsText={"Loading Substance Category..."}
                options={listSubstanceCategory.map((substanceCategory) => substanceCategory.chemIDCategoryName)}
                getOptionLabel={(option) => {
                  const category = listSubstanceCategory.find(f => f.chemIDCategoryName === option)
                  if (category) return `${category.chemIDCategoryName} - ${category.categoryDescription}`
                  else return option
                }}
                onChange={(e, value) => {
                  const current = {
                    ...currentSubstance,
                    chemIDCategoryName: value
                  }
                  validateFields(current, 'chemIDCategoryName')
                  setCurrentSubstance(() => (current))
                }}
                autoHighlight
                autoSelect
                value={currentSubstance.chemIDCategoryName === '' ? null : currentSubstance.chemIDCategoryName}
                renderInput={(params) => <TextField {...params} variant="outlined" size="small" label="Substance Category" error={fieldsCheck.chemIDCategoryName}
                helperText={fieldsCheck.chemIDCategoryName ? 'Required' : ''} InputProps={{ ...params.InputProps }} required/>}
              />
              <ChemIDSearchField
                fontSize={16}
                isDisabled={isNewSubstance ? false : !canEdit}  
                fieldWidth="100%"
                selectedChemIDObject={currentSubstance.promotedChemID}
                setSelectedChemIDObject={(value) => {
                  const current = {
                    ...currentSubstance,
                    promotedChemID: value
                  }
                  setCurrentSubstance(() => (current))
                }}
                margin="none"
                labelCustom="Promoted Chem ID"
                allowInactiveChemIDs={true}
                returnSubstanceStrucureData={true}
                hasErrors={false}
                setHasErrors={(e) => {}}
              />
            </StyledBoxInputs>
            <StyledBoxInputs
              sx={{
                '& > :not(style)': { width: '50%' },
              }}
            >
              <TextField size="small" id="substance-name" label="Substance Name *" variant="outlined"
                onChange={e => setCurrentSubstance(() => ({
                  ...currentSubstance,
                  substanceName: e.target.value
                }))}
                value={currentSubstance.substanceName === null ? '' : currentSubstance.substanceName}
                inputProps={{ maxLength: 200 }}
                disabled={isNewSubstance ? false : !canEdit}  
                error={fieldsCheck.substanceName}
                helperText={fieldsCheck.substanceName ? 'Required' : ''}
              />
              <ChemIDSearchField
                fontSize={16}
                fieldWidth="100%"
                selectedChemIDObject={currentSubstance.parentChemID}
                setSelectedChemIDObject={(value) => {
                  const current = {
                    ...currentSubstance,
                    parentChemID: value
                  }
                  setCurrentSubstance(() => (current))
                }}
                margin="none"
                labelCustom="Parent Chem ID Search"
                allowInactiveChemIDs={true}
                returnSubstanceStrucureData={true}
                isDisabled={isNewSubstance ? false : !canEdit}  
                hasErrors={false}
                setHasErrors={(e) => {}}
              />
            </StyledBoxInputs>
            <StyledBoxInputs
              sx={{
                '& > :not(style)': { width: '100%' },
              }}
            >
              <TextField size="small" id="description" label="Description" variant="outlined"
                onChange={e => setCurrentSubstance(() => ({
                  ...currentSubstance,
                  description: e.target.value
                }))}
                value={currentSubstance.description === null ? '' : currentSubstance.description}
                disabled={isNewSubstance ? false : !canEdit}  
              />
            </StyledBoxInputs>
            <StyledBoxInputs>
              <EmailAddressTextField
                id="owner-email"
                fontSize={16}
                fieldWidth={"50%"}
                validatedUserEmail={currentSubstance.ownerEmail}
                setValidatedUserEmail={value => {
                  const current = {
                    ...currentSubstance,
                    ownerEmail: value
                  }
                  setCurrentSubstance(() => (current))
                }}
                setHasErrors={(value) => setErrorChecks({...fieldsCheck, ownerEmail: value})}
                hasErrors={fieldsCheck.ownerEmail !== null && fieldsCheck.ownerEmail !== '' ? true : false}
                isDisabled={isNewSubstance ? false : !canEdit}  
                labelText="Owner Email *"
                placeholderText="Owner Email"
                size={"small"}
                margin={"none"}
                showPlusMeButton={true}>
              </EmailAddressTextField>
            </StyledBoxInputs>
          </StyledDivInputs>
          <FormGroup>
            <FormControlLabel control={
              <Checkbox sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }} />} label="Active"
              onClick={e => setCurrentSubstance(() => ({
                ...currentSubstance,
                isActive: !currentSubstance.isActive
              }))}
              checked={currentSubstance.isActive}
              disabled={isNewSubstance ? false : !canEdit}  
            />
          </FormGroup>
        </StyledDiv>
        <StyledBoxInputs
          sx={{
            '& > :not(style)': { width: '33.3%' },
          }}
        >
          <Autocomplete
            id="substance-type-select"
            disablePortal
            noOptionsText={"Loading Substance Type..."}
            options={listSubstanceType.map((substanceType) => substanceType.ecTypeName)}
            getOptionLabel={(option) => option}
            onChange={(e, value) => handleChangeType(value)}
            autoHighlight
            disabled={isNewSubstance ? false : !canEdit}  
            value={currentSubstance.substanceTypeName === '' ? null : currentSubstance.substanceTypeName}
            renderInput={(params) => <TextField {...params} variant="outlined" size="small" label="Substance Type" error={false} InputProps={{ ...params.InputProps }} />}
          />
          <Autocomplete
            id="substance-subtype1-select"
            disablePortal
            noOptionsText={"Loading Substance Subtype 1..."}
            options={listSubstanceSubType1}
            getOptionLabel={(option) => option?.subtype1Name ? option.subtype1Name : listSubstanceSubType1.length > 0 ? listSubstanceSubType1.find(f => f.sT1ID === option).subtype1Name : ``}
            onChange={(e, value) => handleChangeSubtype1(value)}
            autoHighlight
            value={currentSubstance.substanceSubtype1ID === '' ? null : currentSubstance.substanceSubtype1ID}
            disabled={(!listSubstanceSubType1 || listSubstanceSubType1.length < 1 || !currentSubstance.substanceTypeName) || (isNewSubstance ? false : !canEdit)}
            renderInput={(params) => <TextField {...params} variant="outlined" size="small" label="Substance Subtype 1" error={false} InputProps={{ ...params.InputProps }} />}
          />
          <Autocomplete
            id="substance-subtype2-select"
            disablePortal
            noOptionsText={"Loading Substance Subtype 2..."}
            options={listSubstanceSubType2}
            getOptionLabel={(option) => option?.subtype2Name ? option.subtype2Name : listSubstanceSubType2.length > 0 ? listSubstanceSubType2.find(f => f.sT2ID === option).subtype2Name : ``}
            onChange={(e, value) => handleChangeSubtype2(value)}
            autoHighlight
            value={currentSubstance.substanceSubtype2ID === '' ? null : currentSubstance.substanceSubtype2ID}
            disabled={(!listSubstanceSubType2 || listSubstanceSubType2.length < 1 || !currentSubstance.substanceSubtype1ID) || (isNewSubstance ? false : !canEdit)}
            renderInput={(params) => <TextField {...params} variant="outlined" size="small" label="Substance Subtype 2" error={false} InputProps={{ ...params.InputProps }} />}
          />
          <Autocomplete
            id="substance-subtype3-select"
            disablePortal
            noOptionsText={"Loading Tags..."}
            options={listSubstanceSubType3}
            getOptionLabel={(option) => option?.subtype3Name ? option.subtype3Name : listSubstanceSubType3.length > 0 ? listSubstanceSubType3.find(f => f.sT3ID === option).subtype3Name : ``}
            onChange={(e, value) => setCurrentSubstance(() => ({
              ...currentSubstance,
              substanceSubtype3ID: value.sT3ID
            }))}
            autoHighlight
            value={currentSubstance.substanceSubtype3ID === '' ? null : currentSubstance.substanceSubtype3ID}
            disabled={(!listSubstanceSubType3 || listSubstanceSubType3.length < 1 || !currentSubstance.substanceTypeName) || (isNewSubstance ? false : !canEdit)}
            renderInput={(params) => <TextField {...params} variant="outlined" size="small" label="Tags" error={false} InputProps={{ ...params.InputProps }} />}
          />
        </StyledBoxInputs>
        <StyledBoxInputs
          sx={{
            '& > :not(style)': { width: '33.3%' },
          }}
        >
          <TextField size="small" id="commercial-notes" label="Commercial Notes" variant="outlined"
            onChange={e => setCurrentSubstance(() => ({
              ...currentSubstance,
              commericallyAvailableNotes: e.target.value
            }))}
            value={currentSubstance.commericallyAvailableNotes}
            disabled={isNewSubstance ? false : !canEdit}  
          />
          <TextField size="small" id="psimsid" label="PSIMS ID" variant="outlined"
            onChange={e => setCurrentSubstance(() => ({
              ...currentSubstance,
              psimsid: e.target.value
            }))}
            value={currentSubstance.psimsid}
            disabled={isNewSubstance ? false : !canEdit}  
          />
        </StyledBoxInputs>
        <StyledBoxInputs>
          <FormGroup>
            <FormControlLabel control={
              <Checkbox sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }} />} label="Commercially Available"
              onClick={e => setCurrentSubstance(() => ({
                ...currentSubstance,
                isCommerciallyAvailable: !currentSubstance.isCommerciallyAvailable
              }))}
              checked={currentSubstance.isCommerciallyAvailable}
              disabled={isNewSubstance ? false : !canEdit}  
            />
          </FormGroup>
          <FormGroup>
            <FormControlLabel control={
              <Checkbox sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }} />} label="Was Purchased"
              onClick={e => setCurrentSubstance(() => ({
                ...currentSubstance,
                wasPurchased: !currentSubstance.wasPurchased
              }))}
              checked={currentSubstance.wasPurchased}
              disabled={isNewSubstance ? false : !canEdit}  
            />
          </FormGroup>
        </StyledBoxInputs>
        {(currentSubstance.createdByEmail || currentSubstance.lastModifiedByEmail) && (
          <StyledDivInformation>
            {currentSubstance.createdByEmail && <StyledInputLabel sx={{ position: "relative" }}>Created By <b> {currentSubstance.createdByEmail}</b> on <b>{convertToLocalTime(new Date(currentSubstance.createdDate))}</b></StyledInputLabel>}
            {currentSubstance.lastModifiedByEmail && <StyledInputLabel sx={{ position: "relative" }}>Last Modified By <b> {currentSubstance.lastModifiedByEmail}</b> on <b>{convertToLocalTime(new Date(currentSubstance.lastModifiedDate))}</b></StyledInputLabel>}
          </StyledDivInformation>)
        }
      </StyledAccordionDetails>
    </Accordion >
  );
};

export default GeneralInformation;