import API from '../index'

export default class DistillationCut {
    // constructor(
    //     distillationCutTypeName,
    //     isActive,
    //     isBlend,
    //     isHighVacuum,
    //     isAtmospheric, 
    //     isExtraction,
    //     isMassBalance, 
    //     isStartingCut,
    //     isEndingCut
    // ) {
    //     this.distillationCutTypeName = distillationCutTypeName
    //     this.isActive = isActive
    //     this.isBlend = isBlend
    //     this.isHighVacuum = isHighVacuum
    //     this.isAtmospheric = isAtmospheric
    //     this.isExtraction = isExtraction
    //     this.isMassBalance = isMassBalance
    //     this.isStartingCut = isStartingCut
    //     this.isEndingCut = isEndingCut
    // }

    static async changeStatusToFailed(cutID) {
        const api = await API();
        const { data } = await api.put(`distillationCut/UpdateStatusToFailed?id=${cutID}`);
        return data;
    }
}