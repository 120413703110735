import API from "..";

export default class JapanFireSafetyCode {
  /**
   * The JapanFireSafetyCode dimension table for M3
   * @param TODO
   */
  constructor({
    fireSafetyCodeName,
    description,
    isActive
  }) {
    this.fireSafetyCodeName = fireSafetyCodeName;
    this.description = description;
    this.isActive = isActive;
  }

  static async getAll() {
    const api = await API();
    const { data } = await api.get(`/japanfiresafetycode`);
    return data.result.map((d) => new JapanFireSafetyCode(d));
  }
}