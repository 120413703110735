import Autocomplete from "@mui/material/Autocomplete";
import Checkbox from "@mui/material/Checkbox";
import Divider from "@mui/material/Divider";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import FormHelperText from "@mui/material/FormHelperText";
import FormLabel from "@mui/material/FormLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Switch from "@mui/material/Switch";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/material/styles";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { GlobalSecondaryButton } from "../../../pages/styles";
import WorkRequestShippingSafetyTable from "./components/WorkRequestShippingSafetyTable";
import { defaultShippingAddress } from "./constants";
import { DatePickerKeyDownEvent } from "../../../global";

const StyledSuperShortTextField = styled(TextField)({
  id: "outlined-normal",
  width: `calc(9.5% - 7.5px)`,
  marginRight: "15px",
  marginBottom: "20px",
});

const StyledShortTextField = styled(TextField)({
  id: "outlined-normal",
  width: "18%",
  marginRight: "15px",
  marginBottom: "20px",
});

const StyledAutocomplete = styled(Autocomplete)({
  "& .MuiAutocomplete-input, & .MuiInputLabel-root": {
    fontSize: 16,
  },
  width: "15%",
  marginRight: "15px",
  marginBottom: "20px",
});

const StyledDivider = styled(Divider)({
  marginTop: "10px",
});

const StyledDiv = styled("div")({
  display: "flex",
  marginTop: "5px",
});

export default function ShippingForm({
  setAddressBookOpen,
  setShippingAddress,
  shippingAddress,
  errorChecksShipping,
  setShippingRequest,
  shippingRequest,
  shippingCarriersLoading,
  shippingCarriers,
  shippingReasonsLoading,
  shippingReasons,
  myWorkRequest,
  isTSCA,
  setIsTSCA,
}) {
  function handlePrint() {
    window.print();
  }
  function updateData_ShippingItem(property, value, sampleName, containerID) {
    let itemsCopy = structuredClone(shippingRequest.shippingItems);
    itemsCopy.find((x) => x.sampleName === sampleName && x.containerID === containerID)[property] = value;

    setShippingRequest({
      ...shippingRequest,
      shippingItems: itemsCopy,
    });
  }

  function clearAddress(){
    setShippingAddress(defaultShippingAddress)
  }

  return (
    <>
      <StyledDivider />

      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <StyledDiv>
          <p
            style={{
              fontFamily: "EMprint",
              fontWeight: "600",
              fontSize: "16px",
              color: "#545459",
              textTransform: "none",
              textAlign: "left",
              paddingTop: "20px",
            }}
          >
            Shipping Form
          </p>
        </StyledDiv>
        <div>
          <GlobalSecondaryButton
            style={{
              marginTop: "1.1rem",
              width: "12rem",
              marginRight: "15px",
            }}
            onClick={handlePrint}
          >
            Print
          </GlobalSecondaryButton>
          <GlobalSecondaryButton
            style={{
              marginTop: "1.1rem",
              width: "12rem",
              marginRight: "15px",
            }}
            onClick={() => setAddressBookOpen(true)}
          >
            Add from Address Book
          </GlobalSecondaryButton>
          <GlobalSecondaryButton
            style={{
              marginTop: "1.1rem",
              width: "12rem",
              marginRight: "15px",
            }}
            onClick={clearAddress}
          >
            Clear Address
          </GlobalSecondaryButton>
        </div>
      </div>

      {/* <Box style={{width:'100%', marginTop:'1rem'}}> */}
      <div style={{ display: "flex" }}>
        <StyledShortTextField
          size="small"
          margin="normal"
          variant="outlined"
          label="* Recipient's Name"
          InputLabelProps={{ shrink: true }}
          inputProps={{ maxLength: 200 }}
          onChange={(e) =>
            setShippingAddress(() => ({
              ...shippingAddress,
              recipientName: e.target.value,
            }))
          }
          value={
            shippingAddress.recipientName === null
              ? ""
              : shippingAddress.recipientName
          }
          error={
            errorChecksShipping.recipientName === null
              ? false
              : errorChecksShipping.recipientName &&
                !shippingAddress.recipientName
          }
          helperText={
            errorChecksShipping.recipientName && !shippingAddress.recipientName
              ? errorChecksShipping.recipientName
              : ""
          }
        />

        <StyledShortTextField
          size="small"
          margin="normal"
          variant="outlined"
          label="Recipient's Email"
          InputLabelProps={{ shrink: true }}
          inputProps={{ maxLength: 60 }}
          onChange={(e) =>
            setShippingAddress(() => ({
              ...shippingAddress,
              emailAddress: e.target.value,
            }))
          }
          value={
            shippingAddress.emailAddress === null
              ? ""
              : shippingAddress.emailAddress
          }
        />

        <StyledShortTextField
          size="small"
          margin="normal"
          variant="outlined"
          label="* Phone #"
          InputLabelProps={{ shrink: true }}
          inputProps={{ maxLength: 30 }}
          onChange={(e) =>
            setShippingAddress(() => ({
              ...shippingAddress,
              phoneNumber1: e.target.value,
            }))
          }
          value={
            shippingAddress.phoneNumber1 === null
              ? ""
              : shippingAddress.phoneNumber1
          }
          error={
            errorChecksShipping.phoneNumber1 === null
              ? false
              : errorChecksShipping.phoneNumber1 &&
                !shippingAddress.phoneNumber1
          }
          helperText={
            errorChecksShipping.phoneNumber1 && !shippingAddress.phoneNumber1
              ? errorChecksShipping.phoneNumber1
              : ""
          }
        />

        <StyledShortTextField
          size="small"
          margin="normal"
          variant="outlined"
          label="* Country"
          InputLabelProps={{ shrink: true }}
          inputProps={{ maxLength: 50 }}
          onChange={(e) =>
            setShippingAddress(() => ({
              ...shippingAddress,
              country: e.target.value,
            }))
          }
          value={
            shippingAddress.country === null ? "" : shippingAddress.country
          }
          error={
            errorChecksShipping.country === null
              ? false
              : errorChecksShipping.country && !shippingAddress.country
          }
          helperText={
            errorChecksShipping.country && !shippingAddress.country
              ? errorChecksShipping.country
              : ""
          }
        />

        <StyledSuperShortTextField
          size="small"
          margin="normal"
          variant="outlined"
          label="* Zip"
          InputLabelProps={{ shrink: true }}
          inputProps={{ maxLength: 20 }}
          onChange={(e) =>
            setShippingAddress(() => ({
              ...shippingAddress,
              postalCode: e.target.value,
            }))
          }
          value={
            shippingAddress.postalCode === null
              ? ""
              : shippingAddress.postalCode
          }
          error={
            errorChecksShipping.postalCode === null
              ? false
              : errorChecksShipping.postalCode && !shippingAddress.postalCode
          }
          helperText={
            errorChecksShipping.postalCode && !shippingAddress.postalCode
              ? errorChecksShipping.postalCode
              : ""
          }
        />

        <StyledSuperShortTextField
          size="small"
          margin="normal"
          variant="outlined"
          label="State"
          InputLabelProps={{ shrink: true }}
          inputProps={{ maxLength: 20 }}
          onChange={(e) =>
            setShippingAddress(() => ({
              ...shippingAddress,
              state: e.target.value,
            }))
          }
          value={shippingAddress.state === null ? "" : shippingAddress.state}
        />
        <StyledShortTextField
          size="small"
          margin="normal"
          variant="outlined"
          label="* City"
          InputLabelProps={{ shrink: true }}
          inputProps={{ maxLength: 60 }}
          onChange={(e) =>
            setShippingAddress(() => ({
              ...shippingAddress,
              city: e.target.value,
            }))
          }
          value={shippingAddress.city === null ? "" : shippingAddress.city}
          error={
            errorChecksShipping.city === null
              ? false
              : errorChecksShipping.city && !shippingAddress.city
          }
          helperText={
            errorChecksShipping.city && !shippingAddress.city
              ? errorChecksShipping.city
              : ""
          }
        />
      </div>

      <div style={{ display: "flex" }}>
        <StyledShortTextField
          size="small"
          margin="normal"
          variant="outlined"
          label="* Street Address"
          InputLabelProps={{ shrink: true }}
          inputProps={{ maxLength: 255 }}
          onChange={(e) =>
            setShippingAddress(() => ({
              ...shippingAddress,
              address1: e.target.value,
            }))
          }
          value={
            shippingAddress.address1 === null ? "" : shippingAddress.address1
          }
          error={
            errorChecksShipping.address1 === null
              ? false
              : errorChecksShipping.address1 && !shippingAddress.address1
          }
          helperText={
            errorChecksShipping.address1 && !shippingAddress.address1
              ? errorChecksShipping.address1
              : ""
          }
        />

        <StyledShortTextField
          size="small"
          margin="normal"
          variant="outlined"
          label="Company"
          InputLabelProps={{ shrink: true }}
          inputProps={{ maxLength: 255 }}
          onChange={(e) =>
            setShippingAddress(() => ({
              ...shippingAddress,
              company: e.target.value,
            }))
          }
          value={
            shippingAddress.company === null ? "" : shippingAddress.company
          }
        />

        <StyledShortTextField
          size="small"
          margin="normal"
          variant="outlined"
          label="Department/Floor No."
          InputLabelProps={{ shrink: true }}
          inputProps={{ maxLength: 255 }}
          onChange={(e) =>
            setShippingAddress(() => ({
              ...shippingAddress,
              department: e.target.value,
            }))
          }
          value={
            shippingAddress.department === null
              ? ""
              : shippingAddress.department
          }
        />

        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DesktopDatePicker
            size="small"
            inputFormat="MM/dd/yyyy"
            label="* Delivery Required By"
            onChange={(e) =>
              setShippingRequest(() => ({
                ...shippingRequest,
                requestedDeliveryDate: e,
              }))
            }
            value={
              shippingRequest.requestedDeliveryDate === null
                ? ""
                : shippingRequest.requestedDeliveryDate
            }
            minDate={new Date()}
            renderInput={(params) => (
              <StyledShortTextField
                onKeyDown={DatePickerKeyDownEvent}
                {...params}
                margin="normal"
                variant="outlined"
                size="small"
                InputLabelProps={{ shrink: true }}
                inputProps={{ ...params.inputProps }}
                InputProps={{ ...params.InputProps }}
                error={
                  errorChecksShipping.requestedDeliveryDate === null
                    ? false
                    : errorChecksShipping.requestedDeliveryDate &&
                      !shippingRequest.requestedDeliveryDate
                }
                helperText={
                  errorChecksShipping.requestedDeliveryDate &&
                  !shippingRequest.requestedDeliveryDate
                    ? errorChecksShipping.requestedDeliveryDate
                    : ""
                }
              />
            )}
          />
        </LocalizationProvider>

        <StyledAutocomplete
          disablePortal
          noOptionsText={
            shippingCarriersLoading
              ? "Loading Carriers..."
              : "No Carriers Found"
          }
          options={shippingCarriers}
          getOptionLabel={(option) =>
            option?.carrierName
              ? option.carrierName
              : shippingCarriers.length > 0
              ? shippingCarriers.find((f) => f.id === option)?.carrierName
              : ""
          }
          autoHighlight
          autoSelect
          onChange={(e, value) =>
            setShippingRequest(() => ({
              ...shippingRequest,
              shippingCarrierID: value?.id,
            }))
          }
          value={shippingRequest.shippingCarrierID}
          isOptionEqualToValue={(option, value) => value?.id === option?.id}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              size="small"
              margin="normal"
              label="* Ship Via"
              error={
                errorChecksShipping.shippingCarrierID === null
                  ? false
                  : errorChecksShipping.shippingCarrierID &&
                    !shippingRequest.shippingCarrierID
              }
              helperText={
                errorChecksShipping.shippingCarrierID &&
                !shippingRequest.shippingCarrierID
                  ? errorChecksShipping.shippingCarrierID
                  : ""
              }
              InputLabelProps={{ shrink: true }}
              InputProps={{ ...params.InputProps }}
            />
          )}
        />

        <StyledAutocomplete
          disablePortal
          noOptionsText={
            shippingReasonsLoading ? "Loading Reasons..." : "No Reasons Found"
          }
          options={shippingReasons}
          getOptionLabel={(option) =>
            option?.shippingReasonName
              ? option.shippingReasonName
              : shippingReasons.length > 0
              ? shippingReasons.find((f) => f.shippingReasonName === option)
                  ?.shippingReasonName
              : ``
          }
          autoHighlight
          autoSelect
          onChange={(e, value) =>
            setShippingRequest(() => ({
              ...shippingRequest,
              shippingReasonName: value?.shippingReasonName,
            }))
          }
          value={shippingRequest.shippingReasonName}
          isOptionEqualToValue={(option, value) =>
            value?.shippingReasonName === option?.shippingReasonName
          }
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              size="small"
              margin="normal"
              label="* Reason for Shipment"
              error={
                errorChecksShipping.shippingReasonName === null
                  ? false
                  : errorChecksShipping.shippingReasonName &&
                    !shippingRequest.shippingReasonName
              }
              helperText={
                errorChecksShipping.shippingReasonName &&
                !shippingRequest.shippingReasonName
                  ? errorChecksShipping.shippingReasonName
                  : ""
              }
              InputLabelProps={{ shrink: true }}
              InputProps={{ ...params.InputProps }}
            />
          )}
        />
      </div>
      {/* </Box> */}

      <div style={{ display: "flex" }}>
        <FormControl
          error={
            errorChecksShipping.isPrepaid === null
              ? false
              : errorChecksShipping.isPrepaid && !shippingRequest.isPrepaid
          }
        >
          <FormLabel id="demo-controlled-radio-buttons-group">
            * Shipment Payment:
          </FormLabel>
          <RadioGroup
            row
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="controlled-radio-buttons-group"
            onChange={(e) =>
              setShippingRequest(() => ({
                ...shippingRequest,
                isPrepaid: e.target.value === "true" ? true : false,
              }))
            }
            value={shippingRequest.isPrepaid}
          >
            <FormControlLabel
              value={true}
              control={<Radio />}
              label="Prepaid"
            />
            <FormControlLabel
              value={false}
              control={<Radio />}
              label="Collect"
            />
          </RadioGroup>
          <FormHelperText>
            {errorChecksShipping.isPrepaid && !shippingRequest.isPrepaid
              ? errorChecksShipping.isPrepaid
              : ""}
          </FormHelperText>
        </FormControl>
      </div>

      <div>
        <StyledDivider />
        <StyledDiv>
          <p
            style={{
              fontFamily: "EMprint",
              fontWeight: "600",
              fontSize: "16px",
              color: "#545459",
              textTransform: "none",
              textAlign: "left",
              paddingTop: "20px",
            }}
          >
            ETQ Info
          </p>
        </StyledDiv>

        <FormGroup>
          <FormControlLabel
            control={<Switch />}
            onChange={(e) =>
              setShippingRequest(() => ({
                ...shippingRequest,
                is3PTesting: e.target.checked,
              }))
            }
            checked={
              shippingRequest?.is3PTesting === null
                ? false
                : shippingRequest?.is3PTesting
            }
            label="Request is for 3rd party testing?"
          />
        </FormGroup>
      </div>

      <div>
        <StyledDivider />
        <StyledDiv>
          <p
            style={{
              fontFamily: "EMprint",
              fontWeight: "600",
              fontSize: "16px",
              color: "#545459",
              textTransform: "none",
              textAlign: "left",
              paddingTop: "20px",
            }}
          >
            Sample Safety Info
          </p>
        </StyledDiv>
        <WorkRequestShippingSafetyTable
          updateData_ShippingItem={updateData_ShippingItem}
          myWorkRequest={myWorkRequest}
          setShippingRequest={setShippingRequest}
          shippingRequest={shippingRequest}
        />
      </div>

      <StyledDivider />
      <StyledDiv>
        <p
          style={{
            fontFamily: "EMprint",
            fontWeight: "600",
            fontSize: "16px",
            color: "#545459",
            textTransform: "none",
            textAlign: "left",
            paddingTop: "20px",
          }}
        >
          TSCA Review
        </p>
      </StyledDiv>

      <FormGroup>
        <FormControlLabel
          control={<Checkbox />}
          checked={isTSCA}
          onChange={(e) => setIsTSCA(e.target.checked)}
          label={<>TSCA Review is Required</>}
        />
      </FormGroup>

      <StyledDivider />
      <StyledDiv>
        <p
          style={{
            fontFamily: "EMprint",
            fontWeight: "600",
            fontSize: "16px",
            color: "#545459",
            textTransform: "none",
            textAlign: "left",
            paddingTop: "20px",
          }}
        >
          Terms
        </p>
      </StyledDiv>

      <FormGroup>
        <FormControlLabel
          control={
            <Checkbox
              checked={shippingRequest.iAcknowledge}
              onChange={(e) =>
                setShippingRequest(() => ({
                  ...shippingRequest,
                  iAcknowledge: e.target.checked,
                }))
              }
            />
          }
          label="I acknowledge that the descriptions on this form will be printed on MIDAS labels and are deemed acceptable for external use"
        />

        <FormControlLabel
          control={
            <Checkbox
              checked={shippingRequest.containsSamples}
              onChange={(e) =>
                setShippingRequest(() => ({
                  ...shippingRequest,
                  containsSamples: e.target.checked,
                }))
              }
            />
          }
          label="This shipment contains chemicals"
        />
      </FormGroup>
    </>
  );
}
