import AddCircleIcon from "@mui/icons-material/AddCircle";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import Stack from "@mui/material/Stack";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { useFormikContext } from "formik";
import { SQCASTM } from "../../../lib/types/sqc";
import { SqcMethodManagementForm } from "./form";
import { useState } from "react";
import { useSqcMethodManagement } from "./SqcMethodManagementContext";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";

type SqcMethodManagementAstmSetsProps = {
  sets: SQCASTM[];
};

export function SqcMethodManagementAstmSets({
  sets,
}: SqcMethodManagementAstmSetsProps) {
  const { activeAstmIndex, setActiveAstmIndex } = useSqcMethodManagement();
  const formik = useFormikContext<SqcMethodManagementForm>();
  const [selectedId, setSelectedId] = useState<number>();
  const [selectedParameter, setSelectedParameter] = useState<string | null>(
    null
  );
  const [page, setPage] = useState(0);

  const ROWS_PER_PAGE = 5;

  const handleCancel = () => {
    formik.setFieldValue("sqcastm", formik.values.sqcastm.slice(0, -1));
    setActiveAstmIndex(null);
  };

  const handleCreate = () => {
    const previousSet = formik.values.sqcastm.find(
      (s) => s.isActive && s.resultComponentName === selectedParameter
    );

    if (previousSet) {
      previousSet.isActive = false;
      previousSet.deactivationDate = new Date().toJSON();
    }

    const newSets = formik.values.sqcastm.concat([
      {
        resultComponentName: selectedParameter!,
        revisionNumber: null,
        littleR: null,
        bigR: null,
        createdByEmail: undefined!,
        createdDate: undefined!,
        isActive: true,
        equationForm: null!,
        a: null,
        c: null,
        x: null,
        y: null,
        deactivationDate: null,
      },
    ]);

    formik.setFieldValue("sqcastm", newSets);
    setActiveAstmIndex(newSets.length - 1);
    setSelectedId(undefined);
  };

  const handleEquationChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    formik.setFieldValue(
      `sqcastm.${activeAstmIndex}.equationForm`,
      value ? +value : null
    );

    if (!value) {
      formik.setFieldValue(`sqcastm.${activeAstmIndex}.a`, null);
      formik.setFieldValue(`sqcastm.${activeAstmIndex}.c`, null);
      formik.setFieldValue(`sqcastm.${activeAstmIndex}.x`, null);
      formik.setFieldValue(`sqcastm.${activeAstmIndex}.y`, null);
    } else {
      formik.setFieldValue(`sqcastm.${activeAstmIndex}.littleR`, null);
      formik.setFieldValue(`sqcastm.${activeAstmIndex}.bigR`, null);

      switch (value) {
        case "1":
          formik.setFieldValue(`sqcastm.${activeAstmIndex}.c`, null);
          formik.setFieldValue(`sqcastm.${activeAstmIndex}.x`, null);
          formik.setFieldValue(`sqcastm.${activeAstmIndex}.y`, null);
          break;
        case "2":
        case "3":
          formik.setFieldValue(`sqcastm.${activeAstmIndex}.x`, null);
          formik.setFieldValue(`sqcastm.${activeAstmIndex}.y`, null);
          break;
        case "4":
          formik.setFieldValue(`sqcastm.${activeAstmIndex}.c`, null);
          formik.setFieldValue(`sqcastm.${activeAstmIndex}.y`, null);
          break;
        case "5":
        case "8":
          formik.setFieldValue(`sqcastm.${activeAstmIndex}.y`, null);
          break;
        case "6":
          formik.setFieldValue(`sqcastm.${activeAstmIndex}.c`, null);
          break;
        default:
          break;
      }
    }

    setTimeout(() => formik.validateForm(), 0);
  };

  const filteredSets = selectedParameter
    ? sets.filter((s) => s.resultComponentName === selectedParameter)
    : [];

  return (
    <Stack sx={{ gap: "2rem", paddingBlockStart: "1rem" }}>
      <Stack direction="row" gap="1rem" alignItems="center">
        <Typography
          component="h3"
          sx={{ fontSize: "1.25rem", fontWeight: 600 }}
        >
          ASTM Values
        </Typography>

        <Button
          variant="text"
          startIcon={<AddCircleIcon />}
          onClick={handleCreate}
          disabled={
            selectedParameter === null ||
            (activeAstmIndex != null && selectedId == null)
          }
        >
          New ASTM Set
        </Button>

        {activeAstmIndex != null && selectedId == null && (
          <Button variant="text" onClick={handleCancel}>
            Cancel
          </Button>
        )}
      </Stack>

      <Autocomplete
        size="small"
        options={formik.values.trackedComponents
          .filter((t) => !t.isInformational)
          .map((t) => t.resultComponentName)}
        renderInput={(params) => (
          <TextField {...params} label="Analysis Parameter" />
        )}
        value={selectedParameter}
        onChange={(event, value) => {
          setActiveAstmIndex(null);
          setSelectedId(undefined);
          setSelectedParameter(value);
        }}
        disabled={activeAstmIndex != null && selectedId == null}
        sx={{ width: "24rem" }}
      />

      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell sx={{ width: "4rem" }} />

              <TableCell>Status</TableCell>

              <TableCell>Analysis Parameter</TableCell>

              <TableCell>Revision</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {Array.from(filteredSets)
              .reverse()
              .map((s) => (
                <TableRow key={s.id}>
                  <TableCell>
                    <Radio
                      checked={s.id === selectedId}
                      onChange={() => {
                        setSelectedId(s.id);

                        const index = formik.values.sqcastm.findIndex(
                          (ss) => ss.id === s.id
                        );

                        setActiveAstmIndex(index);
                      }}
                      disabled={activeAstmIndex != null && selectedId == null}
                    />
                  </TableCell>

                  <TableCell>{s.isActive ? "Active" : "Inactive"}</TableCell>

                  <TableCell>{s.resultComponentName}</TableCell>

                  <TableCell>{s.revisionNumber}</TableCell>
                </TableRow>
              ))}

            {filteredSets.length === 0 && (
              <TableRow>
                <TableCell colSpan={4}>
                  No limits that match the filter are available
                </TableCell>
              </TableRow>
            )}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[]}
                colSpan={4}
                count={filteredSets.length}
                rowsPerPage={ROWS_PER_PAGE}
                page={page}
                onPageChange={(event, value) => setPage(value)}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>

      {activeAstmIndex !== null && (
        <Stack sx={{ gap: "1rem" }}>
          <TextField
            size="small"
            label="ASTM Revision"
            value={formik.values.sqcastm[activeAstmIndex].revisionNumber ?? ""}
            onChange={(e) => {
              formik.setFieldValue(
                `sqcastm.${activeAstmIndex}.revisionNumber`,
                e.target.value || null
              );
            }}
            disabled={selectedId != null}
            sx={{ width: "12rem" }}
          />

          <Typography component="h4" sx={{ fontSize: "1.25rem" }}>
            ASTM Repeatability and Reproducibility
          </Typography>

          <Box
            sx={{
              display: "grid",
              gap: "0.5rem 0",
              gridTemplateColumns: "repeat(2, 1fr)",
              gridTemplateRows: "repeat(2, 1fr)",
              maxWidth: "40rem",
            }}
          >
            <FormControlLabel
              control={
                <Radio
                  checked={
                    formik.values.sqcastm[activeAstmIndex].equationForm === null
                  }
                  value=""
                  onChange={handleEquationChange}
                  disabled={selectedId != null}
                />
              }
              label="No equation, use specified R values"
              sx={{ gridRow: "span 2", alignSelf: "start" }}
            />

            <TextField
              type="number"
              size="small"
              label="r Value"
              value={formik.values.sqcastm[activeAstmIndex].littleR ?? ""}
              onChange={(e) => {
                formik.setFieldValue(
                  `sqcastm.${activeAstmIndex}.littleR`,
                  e.target.value !== "" ? +e.target.value : null
                );
              }}
              disabled={
                formik.values.sqcastm[activeAstmIndex].equationForm !== null ||
                selectedId != null
              }
              sx={{ width: "16rem" }}
            />

            <TextField
              type="number"
              size="small"
              label="R Value"
              value={formik.values.sqcastm[activeAstmIndex].bigR ?? ""}
              onChange={(e) => {
                formik.setFieldValue(
                  `sqcastm.${activeAstmIndex}.bigR`,
                  e.target.value !== "" ? +e.target.value : null
                );
              }}
              disabled={
                formik.values.sqcastm[activeAstmIndex].equationForm !== null ||
                selectedId != null
              }
              sx={{ width: "16rem" }}
            />
          </Box>

          <Box
            sx={{
              display: "grid",
              gap: "0.5rem 0",
              gridTemplateColumns: "repeat(2, 1fr)",
              gridTemplateRows: "repeat(8, 1fr)",
              gridAutoFlow: "column",
              maxWidth: "40rem",
            }}
          >
            <FormControlLabel
              control={
                <Radio
                  checked={
                    formik.values.sqcastm[activeAstmIndex].equationForm === 1
                  }
                  value={1}
                  onChange={handleEquationChange}
                  disabled={selectedId != null}
                />
              }
              label="R = A * mean"
              sx={{ width: "fit-content" }}
            />

            <FormControlLabel
              control={
                <Radio
                  checked={
                    formik.values.sqcastm[activeAstmIndex].equationForm === 2
                  }
                  value={2}
                  onChange={handleEquationChange}
                  disabled={selectedId != null}
                />
              }
              label="R = A * mean + C"
              sx={{ width: "fit-content" }}
            />

            <FormControlLabel
              control={
                <Radio
                  checked={
                    formik.values.sqcastm[activeAstmIndex].equationForm === 3
                  }
                  value={3}
                  onChange={handleEquationChange}
                  disabled={selectedId != null}
                />
              }
              label="R = A * (mean + C)"
              sx={{ width: "fit-content" }}
            />

            <FormControlLabel
              control={
                <Radio
                  checked={
                    formik.values.sqcastm[activeAstmIndex].equationForm === 4
                  }
                  value={4}
                  onChange={handleEquationChange}
                />
              }
              label="R = A * mean^X"
              disabled={selectedId != null}
              sx={{ width: "fit-content" }}
            />

            <FormControlLabel
              control={
                <Radio
                  checked={
                    formik.values.sqcastm[activeAstmIndex].equationForm === 5
                  }
                  value={5}
                  onChange={handleEquationChange}
                  disabled={selectedId != null}
                />
              }
              label="R = A * mean^X + C"
              sx={{ width: "fit-content" }}
            />

            <FormControlLabel
              control={
                <Radio
                  checked={
                    formik.values.sqcastm[activeAstmIndex].equationForm === 6
                  }
                  value={6}
                  onChange={handleEquationChange}
                  disabled={selectedId != null}
                />
              }
              label="R = A * (mean * Y)^X"
              sx={{ width: "fit-content" }}
            />

            <FormControlLabel
              control={
                <Radio
                  checked={
                    formik.values.sqcastm[activeAstmIndex].equationForm === 7
                  }
                  value={7}
                  onChange={handleEquationChange}
                  disabled={selectedId != null}
                />
              }
              label="R = A * (mean * Y)^X + C"
              sx={{ width: "fit-content" }}
            />

            <FormControlLabel
              control={
                <Radio
                  checked={
                    formik.values.sqcastm[activeAstmIndex].equationForm === 8
                  }
                  value={8}
                  onChange={handleEquationChange}
                  disabled={selectedId != null}
                />
              }
              label="R = A * (mean + C)^X"
              sx={{ width: "fit-content" }}
            />

            <TextField
              type="number"
              size="small"
              label="A"
              value={formik.values.sqcastm[activeAstmIndex].a ?? ""}
              onChange={(e) => {
                formik.setFieldValue(
                  `sqcastm.${activeAstmIndex}.a`,
                  e.target.value !== "" ? +e.target.value : null
                );
              }}
              disabled={
                selectedId != null ||
                formik.values.sqcastm[activeAstmIndex].equationForm == null
              }
              sx={{ width: "16rem" }}
            />

            <TextField
              type="number"
              size="small"
              label="C"
              value={formik.values.sqcastm[activeAstmIndex].c ?? ""}
              onChange={(e) => {
                formik.setFieldValue(
                  `sqcastm.${activeAstmIndex}.c`,
                  e.target.value !== "" ? +e.target.value : null
                );
              }}
              disabled={
                selectedId != null ||
                (formik.values.sqcastm[activeAstmIndex].equationForm !== 2 &&
                  formik.values.sqcastm[activeAstmIndex].equationForm !== 3 &&
                  formik.values.sqcastm[activeAstmIndex].equationForm !== 5 &&
                  formik.values.sqcastm[activeAstmIndex].equationForm !== 7 &&
                  formik.values.sqcastm[activeAstmIndex].equationForm !== 8)
              }
              sx={{ width: "16rem" }}
            />

            <TextField
              type="number"
              size="small"
              label="X"
              value={formik.values.sqcastm[activeAstmIndex].x ?? ""}
              onChange={(e) => {
                formik.setFieldValue(
                  `sqcastm.${activeAstmIndex}.x`,
                  e.target.value !== "" ? +e.target.value : null
                );
              }}
              disabled={
                selectedId != null ||
                (formik.values.sqcastm[activeAstmIndex].equationForm !== 4 &&
                  formik.values.sqcastm[activeAstmIndex].equationForm !== 5 &&
                  formik.values.sqcastm[activeAstmIndex].equationForm !== 6 &&
                  formik.values.sqcastm[activeAstmIndex].equationForm !== 7 &&
                  formik.values.sqcastm[activeAstmIndex].equationForm !== 8)
              }
              sx={{ width: "16rem" }}
            />

            <TextField
              type="number"
              size="small"
              label="Y"
              value={formik.values.sqcastm[activeAstmIndex].y ?? ""}
              onChange={(e) => {
                formik.setFieldValue(
                  `sqcastm.${activeAstmIndex}.y`,
                  e.target.value !== "" ? +e.target.value : null
                );
              }}
              disabled={
                selectedId != null ||
                (formik.values.sqcastm[activeAstmIndex].equationForm !== 6 &&
                  formik.values.sqcastm[activeAstmIndex].equationForm !== 7)
              }
              sx={{ width: "16rem" }}
            />
          </Box>
        </Stack>
      )}
    </Stack>
  );
}
