import { styled } from "@mui/system";
import React, { useState } from "react";
import ModalSimpleButton from '../../../components/Modal/ModalSimpleButton';
import { Box, Button, FormControl } from "@mui/material";
import { ChemIDSearchField } from '../../../components/ChemIDSearch/index';
import { formatMidasNumber, convertDateFormat } from "../../../global";
import SampleApi from "../../../api/LIMS/Sample";
import SubstanceApi from "../../../api/LIMS/Substance";

import { StyledTableRow, StyledTableBody, StyledTableCell, UXDataTableWithoutBody } from "../../../components/UXDataTable";

const StyledContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  paddingBottom: '20px'
}));

const StyledFormControl = styled(FormControl)(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  marginBottom: '30px'
}));

const StyledBox = styled(Box)(() => ({
  display: 'inline-box',
  marginRight: "25px"
}));

const StyledButton = styled(Button)(() => ({
  textTransform: 'none',
  height: '2.2rem',
  marginRight: '20px'
}));

const PromoteSubstance = ({ ...props }) => {

  const [chemIDEcCode, setChemIDEcCode] = useState(null);
  const [chemIDEcCodeError, setChemIDEcCodeError] = useState("");
  const [newChemID, setNewChemID] = useState(null);
  const [newChemIDError, setNewChemIDError] = useState("");
  const [listSample, setListSample] = React.useState([]);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [page, setPage] = React.useState(0);
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('');
  const [dataIsLoading, setDataIsLoading] = React.useState(false);

  const [modalSimpleButton, setModalSimpleButton] = useState(false);
  const [modalSimpleButtonText, setModalSimpleButtonText] = useState('');
  const [modalSimpleTitle, setModalSimpleTitle] = useState('');
  const [modalSimpleText, setModalSimpleText] = useState('');
  const [disabledSubmit, setDisabledSubmit] = useState(false);

  async function handleSearch() {
    if (chemIDEcCode && chemIDEcCode.chemID) {
      setListSample([]);
      setDataIsLoading(true);
      const response = (await SampleApi.searchByChemID(chemIDEcCode.chemID, ""))?.result;

      setListSample(response ?? []);
      setDataIsLoading(false);
    }
  }

  async function handlePromote() {
    if  (chemIDEcCode && chemIDEcCode.chemID && newChemID && newChemID.chemID && chemIDEcCode.chemID === newChemID.chemID) {
      openModalSimpleButton('Fail', 'EC-Code and New Chem Id cannot be the same', 'Ok');
    } else if (chemIDEcCode && chemIDEcCode.chemID && newChemID && newChemID.chemID) {
      setDisabledSubmit(true);
      const response = await SubstanceApi.updatePartial(chemIDEcCode.chemID, {
        PromotedChemID: newChemID.chemID
      });
      setDisabledSubmit(false);

      if (response) {
        openModalSimpleButton('Success', 'Promoted with success', 'Ok');
        setChemIDEcCode(null);
        setNewChemID(null);
        setListSample([]);
      } else {
        openModalSimpleButton('Fail', 'Something went wrong, please try after sometime', 'Ok');
      }
    }
  }

  const colHeaders = [
    { id: 'sampleName', label: 'MIDAS Number' },
    { id: 'description', label: 'Description' },
    { id: 'chemID', label: 'Chem ID' },
    { id: 'createdDate', label: 'Date Created' },
  ];

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  function getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  function openModalSimpleButton(title, text, buttonText) {
    setModalSimpleButtonText(buttonText)
    setModalSimpleButton(true);
    setModalSimpleTitle(title);
    setModalSimpleText(text);
  }

  function closeModalSimpleButton() {
    setModalSimpleButton(false);
  }



  return (
    <StyledContainer>
      <h4>Promote EC-Code</h4>

      <StyledFormControl fullWidth>
        <StyledBox>
          <ChemIDSearchField
            fontSize={16}
            fieldWidth="100%"
            selectedChemIDObject={chemIDEcCode}
            setSelectedChemIDObject={(value) => {
              if (value?.chemIDCategoryName === "EC")
                setChemIDEcCode(value);
              else {
                setChemIDEcCode(null);
                setChemIDEcCodeError(true);
              }
              
            }}
            hasErrors={chemIDEcCodeError}
            setHasErrors={(e) => setChemIDEcCodeError(e)}
            labelCustom="Existing EC-Code"
            allowInactiveChemIDs={false}
            returnSubstanceStrucureData={false}
          />
        </StyledBox>
        <StyledBox>
          <ChemIDSearchField
            fontSize={16}
            fieldWidth="100%"
            selectedChemIDObject={newChemID}
            setSelectedChemIDObject={(value) => {
              if (value?.chemIDCategoryName !== "EC" && value?.chemIDCategoryName !== "RCC")
                setNewChemID(value);
              else {
                setNewChemID(null);
                setNewChemIDError(true);
              }
              
            }}
            hasErrors={newChemIDError}
            setHasErrors={(e) => setNewChemIDError(e)}
            labelCustom="New ChemID"
            allowInactiveChemIDs={false}
            returnSubstanceStrucureData={false}
          />
        </StyledBox>
        <StyledButton sx={{ width: "14rem" }}
          variant='contained'
          type="submit"
          onClick={handleSearch}
          disabled={!chemIDEcCode}
        >Show Potential Changes</StyledButton>
        <StyledButton sx={{ width: "10rem" }}
          variant='contained'
          type="submit"
          onClick={handlePromote}
          disabled={!chemIDEcCode || !newChemID || disabledSubmit}
        >Promote EC-Code</StyledButton>
      </StyledFormControl>
      <div>
        <UXDataTableWithoutBody
          tableWidth='100%'
          cols={colHeaders}
          blankFirstHeader={false}
          tableId="simpleResultTable"
          rowLength={listSample.length}
          enablePaging={true}
          rowsPerPage={rowsPerPage}
          page={page}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          noDataFoundMessage={'There is no container data available'}
          enableAddIcon={false}
          addFunction={null}
          addToolTipText={null}
          enableSorteable={true}
          order={order}
          orderBy={orderBy}
          handleRequestSort={handleRequestSort}
          isDataLoading={dataIsLoading}
        >

          <StyledTableBody key={"myTablebody"}>
            {stableSort(listSample, getComparator(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((sample, rowIndex) => {
                return (
                  [
                    <StyledTableRow
                      hover
                      key={`custom-row-${rowIndex}`}>

                      <StyledTableCell>{formatMidasNumber(sample.sampleName)}</StyledTableCell>
                      <StyledTableCell>{sample.description}</StyledTableCell>
                      <StyledTableCell>{sample.chemID}</StyledTableCell>
                      <StyledTableCell>{convertDateFormat(sample.createdDate)}</StyledTableCell>
                    </StyledTableRow>
                  ]
                );
              })}
          </StyledTableBody>
        </UXDataTableWithoutBody>
      </div>

      <ModalSimpleButton title={modalSimpleTitle} buttonText={modalSimpleButtonText} buttonAction={closeModalSimpleButton} open={modalSimpleButton} setOpen={setModalSimpleButton}>
        <label>
          {modalSimpleText}
        </label>
      </ModalSimpleButton>
    </StyledContainer>
  );
};

export default PromoteSubstance;