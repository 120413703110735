import { useState } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { useFormik } from "formik";

import { ChemIDSearchField } from "../../../../components/ChemIDSearch";
import { areTwoIntervalsTheSame } from "../../shared";
import type { ChemId, Interval } from "../../types";
import { StyledTableCellMedium } from "../../styles";
import { AddIntervalRowSchema } from "../../validation";

export enum Mode {
  Read,
  Write,
}

enum IntervalRowFormField {
  IntervalName = "intervalName",
  ChemID = "chemID",
  PsimsId = "psimsId",
  InitialLocationName = "initialLocationName",
  ReturnLocationName = "returnLocationName",
}

type IntervalRowFormCriteria = {
  id: string;
  intervalName: string;
  chemID: string;
  psimsId?: string;
  initialLocationName: string;
  returnLocationName: string;
};

type IntervalTableRowProps = {
  intervals: Interval[];
  interval: Interval;
  setIntervals(newIntervals: Interval[]): void;
  selectedChemID: ChemId | null;
  setSelectedChemID(chemId: ChemId | null): void;
  disabled?: boolean;
  mode?: Mode;
  locationOptions: string[];
};

const IntervalRowForm = ({
  intervals,
  interval,
  setIntervals,
  selectedChemID,
  setSelectedChemID,
  disabled = false,
  mode = Mode.Read,
  locationOptions,
}: IntervalTableRowProps) => {
  const [hasChemIDErrors, setHasChemIDErrors] = useState(false);

  const psimsIdToDisplay = interval.intervalSample?.psimsid ?? interval.psimsId;

  const psimsIdDisabled =
    disabled || Boolean(psimsIdToDisplay && psimsIdToDisplay !== "" && !interval._updatedPsimsId);

  const initialValues = {
    id: interval?.id ?? "",
    intervalName: interval?.intervalName ?? "",
    chemID: interval?.chemID ?? "",
    psimsId: psimsIdToDisplay,
    initialLocationName: interval?.initialLocationName ?? "",
    returnLocationName: interval?.returnLocationName ?? "",
  };

  const referenceIntervals = intervals?.filter((i) => i.isReference);
  const candidateIntervals = intervals?.filter((i) => !i.isReference);

  const formik = useFormik<IntervalRowFormCriteria>({
    initialValues: initialValues,
    validationSchema: AddIntervalRowSchema(
      interval.isReference ? referenceIntervals : candidateIntervals,
    ),
    onSubmit: () => {},
  });

  function handleUpdateInterval(
    updatedInterval: Interval,
    propertyName: keyof Interval,
    newValue: any,
  ) {
    const newList = intervals.map((val) => {
      if (areTwoIntervalsTheSame(val, updatedInterval)) {
        const updatedInterval = {
          ...val,
          [propertyName]: newValue,
        };

        if (propertyName === "psimsId") {
          updatedInterval._updatedPsimsId = true;
        }

        return updatedInterval;
      }
      return val;
    });

    setIntervals(newList);
  }

  const fieldErrorMessage = (fieldName: IntervalRowFormField) => {
    const { error, touched } = formik.getFieldMeta(fieldName);

    return touched && Boolean(error) ? error : undefined;
  };

  return (
    <>
      {mode === Mode.Read ? (
        [
          interval?.intervalName,
          interval?.chemID,
          psimsIdToDisplay,
          interval?.initialLocationName,
          interval?.returnLocationName,
        ].map((value, i) => <StyledTableCellMedium key={i}>{value}</StyledTableCellMedium>)
      ) : (
        <>
          <StyledTableCellMedium>
            <TextField
              id={IntervalRowFormField.IntervalName}
              name={IntervalRowFormField.IntervalName}
              value={formik.values.intervalName}
              onChange={(e) => {
                handleUpdateInterval(interval, "intervalName", e.target.value);
                formik.handleChange(e);
              }}
              onBlur={formik.handleBlur}
              disabled={disabled}
              size="small"
              fullWidth
              error={Boolean(fieldErrorMessage(IntervalRowFormField.IntervalName))}
              helperText={fieldErrorMessage(IntervalRowFormField.IntervalName) ?? " "}
            />
          </StyledTableCellMedium>
          <StyledTableCellMedium>
            <ChemIDSearchField
              fontSize={14}
              fieldWidth="100%"
              selectedChemIDObject={selectedChemID}
              setSelectedChemIDObject={(e: ChemId) => {
                setSelectedChemID(e);
                if (e.psimsid) {
                  handleUpdateInterval(interval, "psimsId", e.psimsid);
                }
              }}
              hasErrors={hasChemIDErrors || Boolean(fieldErrorMessage(IntervalRowFormField.ChemID))}
              setHasErrors={setHasChemIDErrors}
              isDisabled={disabled}
              helperText={
                hasChemIDErrors || Boolean(fieldErrorMessage(IntervalRowFormField.ChemID))
                  ? "Enter a valid Chem ID"
                  : " "
              }
              labelCustom={null}
              margin={null}
              allowInactiveChemIDs={false}
              returnSubstanceStrucureData={false}
            />
          </StyledTableCellMedium>
          <StyledTableCellMedium>
            <TextField
              id={IntervalRowFormField.PsimsId}
              name={IntervalRowFormField.PsimsId}
              onChange={(e) => handleUpdateInterval(interval, "psimsId", e.target.value)}
              disabled={psimsIdDisabled}
              value={psimsIdToDisplay}
              size="small"
              fullWidth
              helperText=" "
            />
          </StyledTableCellMedium>
          <StyledTableCellMedium>
            <Autocomplete
              noOptionsText="Loading Locations..."
              value={interval?.initialLocationName}
              onChange={(_, value) => {
                handleUpdateInterval(interval, "initialLocationName", value ?? "");
                formik.setFieldValue("initialLocationName", value);
              }}
              disabled={disabled}
              isOptionEqualToValue={(option, value) => value === option || value === ""}
              disablePortal
              options={locationOptions.filter(
                (location) => location.toLocaleLowerCase() !== "DISCARD".toLocaleLowerCase(),
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  id={IntervalRowFormField.InitialLocationName}
                  name={IntervalRowFormField.InitialLocationName}
                  size="small"
                  onBlur={formik.handleBlur}
                  error={Boolean(fieldErrorMessage(IntervalRowFormField.InitialLocationName))}
                  helperText={fieldErrorMessage(IntervalRowFormField.InitialLocationName) ?? " "}
                />
              )}
              autoSelect
              fullWidth
            />
          </StyledTableCellMedium>
          <StyledTableCellMedium>
            <Autocomplete
              noOptionsText="Loading Locations..."
              value={interval?.returnLocationName}
              onChange={(_, value) =>
                handleUpdateInterval(interval, "returnLocationName", value ?? "")
              }
              disabled={disabled}
              isOptionEqualToValue={(option, value) => value === option || value === ""}
              disablePortal
              options={locationOptions}
              renderInput={(params) => (
                <TextField
                  {...params}
                  id={IntervalRowFormField.ReturnLocationName}
                  name={IntervalRowFormField.ReturnLocationName}
                  size="small"
                  helperText=" "
                />
              )}
              autoSelect
              fullWidth
            />
          </StyledTableCellMedium>
        </>
      )}
    </>
  );
};

export default IntervalRowForm;
