import API from '../index'

export default class DistillationExperimentPriority {
    constructor(
        distillationPriorityName,
        isActive,
        priorityLevel,
        displayColor
    ) {
        this.distillationPriorityName = distillationPriorityName
        this.isActive = isActive
        this.priorityLevel = priorityLevel
        this.displayColor = displayColor
    }

    static async getAllDistillationExperimentPriorities() {
        const api = await API();
        const { data } = await api.get('/distillationexperimentpriority');
        return data.result;
    }
}