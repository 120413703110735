import API from "..";

export default class NCAFileAttachment {
  
  constructor({
    id,
    NCAID,
    workRequest,
    fileName,
    filePath,
  }) 
    
  {
    this.id = id;
    this.NCAID = NCAID;
    this.workRequest = workRequest;
    this.fileName = fileName;
    this.filePath = filePath;
  }

  static async postAttachs(info) {
    const api = await API('multipart/form-data');
    const { data } = await api.post('/ncaFileAttachment', info);
    return new NCAFileAttachment(data.result);
  }

}
