import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { REQUEST_INFORMATION_ENUM } from "../../shared";
import Table from "@mui/material/Table";
import { StyledScrollableBox, StyledTableCellLarge } from "../../styles";
import {
  StyledTableBody,
  StyledTableCell,
  StyledTableRow,
} from "../../../../components/UXDataTable";
import type { FuelSamples, StationaryTest } from "../../types";
import type { FormikProps } from "formik";
import { columnsAdditionalFuelSamplesTable } from "../../tableColumns";
import { useDetailedStRequest } from "../../context/DetailedStRequestContext";

type StationaryTestFuelSampleViewProps = {
  fuelSamples: FuelSamples[];
  formik: FormikProps<Partial<StationaryTest>>;
};

export default function StationaryTestFuelSampleView({
  fuelSamples,
  formik,
}: StationaryTestFuelSampleViewProps) {
  const { canManageFuelSamples } = useDetailedStRequest();

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <span
        style={{
          textAlign: "left",
          font: "normal normal 600 20px/24px EMprint",
          letterSpacing: 0,
        }}
      >
        {REQUEST_INFORMATION_ENUM.STATIONARY_TEST_FUEL_SAMPLES}
      </span>
      <Box display="grid" gridTemplateColumns="1fr" gap={2} mt={1}>
        <StyledScrollableBox>
          <Table>
            <StyledTableBody>
              <StyledTableRow>
                {columnsAdditionalFuelSamplesTable.map((col) => (
                  <StyledTableCell key={`custom-header-${col.headerName}`}>
                    {col.headerName}
                  </StyledTableCell>
                ))}
              </StyledTableRow>
              {fuelSamples.map((fuelSample) => {
                return (
                  <StyledTableRow
                    key={`${fuelSample.containerId}${fuelSample.sampleName}`}
                  >
                    <StyledTableCellLarge>
                      {fuelSample.sampleName}
                    </StyledTableCellLarge>
                    <StyledTableCellLarge>
                      {fuelSample.containerNumber}
                    </StyledTableCellLarge>
                    <StyledTableCellLarge>
                      {fuelSample.returnLocationName}
                    </StyledTableCellLarge>
                    <StyledTableCellLarge>
                      {fuelSample.amountUsed}
                    </StyledTableCellLarge>
                    <StyledTableCellLarge>
                      {fuelSample.amountUsedUoMName}
                    </StyledTableCellLarge>
                  </StyledTableRow>
                );
              })}
            </StyledTableBody>
          </Table>
        </StyledScrollableBox>
      </Box>
      <Box display="grid" gridTemplateColumns="1fr 1fr" gap={2} mt={3}>
        <TextField
          label="Fuel Type"
          size="small"
          value={formik.values.fuelType}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          id="fuelType"
          disabled={!canManageFuelSamples}
        />
        <TextField
          label="Fuel Batch"
          size="small"
          value={formik.values.fuelBatch}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          id="fuelBatch"
          disabled={!canManageFuelSamples}
        />
      </Box>
      <Box display="grid" gridTemplateColumns="1fr 1fr" gap={2} mt={3}>
        <TextField
          label="Fuel Tank/Line"
          size="small"
          value={formik.values.fuelTankLine}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          id="fuelTankLine"
          disabled={!canManageFuelSamples}
        />
        <TextField
          label="ION #"
          size="small"
          value={formik.values.ionNumber}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          id="ionNumber"
          disabled={!canManageFuelSamples}
          type="number"
        />
      </Box>
    </div>
  );
}
