import { useState } from "react";
import ContainerTable from "../../../../components/ContainerTable";
import { INITIAL_ERROR_CHECKS_FOR_CONTAINER } from "../../shared";

type TestContaineringTableProps = {
  testContainersForSample: any[];
  updateSampleContainers: any;
  selectedInterval: any | undefined;
  canUpdateFields: boolean;
  initialSize: number;
};

export function TestContaineringTable({
  selectedInterval,
  updateSampleContainers,
  testContainersForSample,
  canUpdateFields,
  initialSize,
}: TestContaineringTableProps) {
  const [testContainerErrorChecks, setTestContainerErrorChecks] = useState<any[]>(() => {
    const initialErrors: any[] = [];

    Array.apply(null, Array(initialSize)).forEach(() => {
      initialErrors.push(structuredClone(INITIAL_ERROR_CHECKS_FOR_CONTAINER));
    });

    return initialErrors;
  });

  return (
    <>
      {selectedInterval &&
        selectedInterval.intervalSample &&
        selectedInterval.intervalSample.containers && (
          <ContainerTable
            width="100%"
            showParentContainer={false}
            showTests={true}
            containers={testContainersForSample}
            setContainers={(newContainers: any[]) => updateSampleContainers(newContainers, true)}
            containerErrorChecks={testContainerErrorChecks}
            setContainerErrorChecks={setTestContainerErrorChecks}
            enableAdditions={false}
            enableDeletions={canUpdateFields && !selectedInterval.intervalSampleName}
            enableCopy={false}
            availableParentContainers={null}
            sampleObject={null}
            substanceObject={selectedInterval.intervalSubstance ?? null} 
            lockContainerStatus={false}
            newContainerDefaultOwner = {null}
          />
        )}
    </>
  );
}
