import API from "../../api";

export default class Site {
  /**
   * The Site dimension table for M3
   * @param {String} siteName The full name of the Site
   * @param {String} abreviation The shorthand abbreviation for the Site
   * @param {bool} isActive The shorthand abbreviation for the Site
   */
  constructor({ siteName, abreviation, isActive }) {
    this.siteName = siteName;
    this.abreviation = abreviation;
    this.isActive = isActive;
  }

  static async getAll() {
    const api = await API();
    const { data } = await api.get(`/site`);
    return data.result.map((d) => new Site(d));
  }

  static async get(id) {
    const api = await API();
    const { data } = await api.get(`site/${id}`);
    return new Site(data.result);
  }

  static async update(data) {
    const api = await API();
    return await api.put(`/site`, data);
  }
}
