import { useState } from "react";
import { Box, Divider } from "@mui/material";
import CreateRequest from "./CreateRequest";
import ProcessRequests from "./ProcessRequests";
import ProcedureManagement from "./ProcedureManagement";
import TestStandManagement from "./TestStandManagement";
import { GlobalTabCollection, StyledTab } from "../../pages/styles";


const StationaryTesting = () => {
  const [tabValue, setTabValue] = useState(0);

  function handleChange(_: any, newValue: number) {
    setTabValue(newValue);
  };

  const renderSearchTab = () => {
    switch (tabValue) {
      case 0: {
        return <CreateRequest />
      }
      case 1: {
        return <ProcessRequests />;
      }
      case 2: {
        return <ProcedureManagement />;
      }
      case 3: {
        return <TestStandManagement />;
      }
      default: {
        alert(tabValue);
      }
    }
  };
  return (
    <Box paddingBottom={4}>
      <span className='pageheader'>Stationary Testing</span>
      <Divider className='dividerbar' />
      <div>
        <Box sx={{ bgcolor: "#fff", pt:3, pb:1 }}>
          <GlobalTabCollection value={tabValue} onChange={handleChange} aria-label='ant example'>
            <StyledTab label='Create Request' />
            <StyledTab label='Process Requests' />
            <StyledTab label='Procedure Management' />
            <StyledTab label='Test Stand Management' />
          </GlobalTabCollection>
          <Box sx={{ p: 3, paddingBottom: 0 }} />
        </Box>
        {renderSearchTab()}
      </div>
    </Box>
  );
};

export default StationaryTesting
