import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import Autocomplete from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TextField from "@mui/material/TextField";
import { useFormikContext } from "formik";
import { useEffect, useMemo, useState } from "react";
import { SqcApi } from "../../../../../api/Admin/SQC";
import { SqcSample } from "../../../../../lib/types/sqc";
import { SqcMethodManagementForm } from "../../form";

type QcIdModalProps = {
  onClose(): void;
};

export function QcIdModal({ onClose }: QcIdModalProps) {
  const [loading, setLoading] = useState(false);
  const [samples, setSamples] = useState<SqcSample[]>([]);

  const [selectedSample, setSelectedSample] = useState<SqcSample | null>(null);

  useEffect(() => {
    setLoading(true);

    SqcApi.getAllSamples()
      .then(setSamples)
      .finally(() => setLoading(false));
  }, []);

  const formik = useFormikContext<SqcMethodManagementForm>();

  const handleAdd = () => {
    if (!selectedSample) return;

    formik.setFieldValue(
      "qcids",
      formik.values.qcids.concat({
        sqcSampleID: selectedSample.sqcid,
      })
    );

    setSelectedSample(null);
  };

  const handleDelete = (index: number) => {
    formik.setFieldValue(
      "qcids",
      formik.values.qcids.filter((t, i) => i !== index)
    );
  };

  const activeSamples = useMemo(
    () => samples.filter((i) => i.isActive),
    [samples]
  );

  return (
    <Dialog open={true} onClose={onClose}>
      <DialogTitle>Method's QCIDs</DialogTitle>

      <DialogContent dividers>
        <Stack gap="1rem">
          <Stack direction="row" gap="0.5rem" sx={{ alignItems: "center" }}>
            <Autocomplete
              size="small"
              loading={loading}
              options={activeSamples}
              getOptionLabel={(option) => option.sqcid}
              getOptionDisabled={(option) =>
                formik.values.qcids.some((a) => a.sqcSampleID === option.sqcid)
              }
              renderOption={(props, option) => (
                <li {...props}>{option.sqcid}</li>
              )}
              isOptionEqualToValue={(option, value) =>
                option.sqcid === value.sqcid
              }
              renderInput={(params) => <TextField {...params} label="QCID" />}
              value={selectedSample}
              onChange={(event, value) => setSelectedSample(value)}
              sx={{ width: "16rem" }}
            />

            <Button
              type="button"
              disabled={!selectedSample}
              onClick={handleAdd}
            >
              Add
            </Button>
          </Stack>

          <TableContainer>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell sx={{ width: "4rem" }} />
                  <TableCell>Name</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {formik.values.qcids.map((a, index) => (
                  <TableRow key={a.sqcSampleID}>
                    <TableCell>
                      <IconButton onClick={() => handleDelete(index)}>
                        <DeleteForeverIcon />
                      </IconButton>
                    </TableCell>
                    <TableCell>{a.sqcSampleID}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Stack>
      </DialogContent>

      <DialogActions>
        <Button type="button" onClick={onClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
