import React, { useState, useEffect } from "react";
import { UXDataTable } from "../../components/UXDataTable";
import { applyFiltersToArray } from "../../global";

const MyDistillationCols = [
  { field: 'id', headerName: 'ID', type: 'number'},
  { field: 'distillationTypeName', headerName: 'Type', type: 'label'},
  { field: 'initialContainer.sampleName', headerName: 'Feed MIDAS #', type: 'object-midasnumber' },
  { field: 'requestedByEmail', headerName: 'Requester', type: 'label' },
  { field: 'mnemonic', headerName: 'Mnemonic', type: 'label' },
  { field: 'comments', headerName: 'Comments', type: 'label' },
  { field: 'experimentStartDate', headerName: 'Start Date', type: 'date' },
  { field: 'createdDate', headerName: 'Submitted On', type: 'date' },
  { field: 'requestedCompletionDate', headerName: 'Req. Completion Date', type: 'date' },
  { field: 'distillationStatusName', headerName: 'Status', type: 'label' },
  ];

const TableCompletedExperiments = ({filters, myExperiments, isDataLoading}) => {
  const [filteredExperiments, setFilteredExperiments] = useState([])
  const noDataMessage = `No experiments found in an state < Complete`

  const menuItems = [{
    menuType: 'link-processDistillation',
    redirectPath: '/processDistillation',
    text: 'View Experiment',
    onClickFunction: null,
    isDisabled: false
  }]

  useEffect(() => {
    var filteredArray = applyFiltersToArray(filters, myExperiments)
    setFilteredExperiments(filteredArray)
  }, [filters, myExperiments])
 
  return (
       <div>
         <UXDataTable tableWidth='100%' cols={MyDistillationCols} rows={filteredExperiments} moreOptionsCell={true} enablePaging={true} 
                      noDataMessage={noDataMessage} menuProps={menuItems} defaultRowsPerPage={10} isDataLoading={isDataLoading} tableName={'DistillationExperiments_Complete'} enableSorting={true}>
         </UXDataTable>
       </div>
  );
};

export default TableCompletedExperiments;