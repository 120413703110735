import React from "react";

const UOMManagement = ({ ...props }) => {
    return (
      <div>
        <p>
          Coming soon to a browser near you!
        </p>
  
          <ul>
            <li>Units of Measure</li>
            <li>GRDB Interaction</li>
          </ul>
      </div>
    );
  };
  
  export default UOMManagement;