import React from "react";
import { Box, Divider} from "@mui/material";
import CreateDistillation from "./CreateDistillation";
import ManageDistillationTemplates from "./ManageDistillationTemplates";
import ManageDistillations from "./ManageDistillations";
import { GlobalTabCollection, StyledTab } from "../../pages/styles";


const Distillations = ({ ...props }) => {
  const [tabValue, setTabValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

    const renderSearchTab = () => {
      switch (tabValue) {
        case 0: {
          return <CreateDistillation></CreateDistillation>
        }
        case 1: {
          return <ManageDistillations></ManageDistillations>;
        }
        case 2: {
          return <ManageDistillationTemplates></ManageDistillationTemplates>;
        }
        default: {
          alert(tabValue);
        }
      }
    };
  return (
    <div>
      <span className='pageheader'>Distillations</span>
      <Divider className='dividerbar' />
      <div>
        <Box sx={{ bgcolor: "#fff", pt:3, pb:1 }}>
          <GlobalTabCollection value={tabValue} onChange={handleChange} aria-label='ant example'>
            <StyledTab label='Create Distillation Request' />
            <StyledTab label='Manage Distillations' />
            <StyledTab label='Manage Distillation Templates' />
          </GlobalTabCollection>
          <Box sx={{ p: 1 }} />
        </Box>
        {renderSearchTab()}
      </div>
    </div>
  );
};

export default Distillations;
