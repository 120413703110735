import React from "react";

const Tutorials = ({ ...props }) => {
  return (
    <div>
      <p>
        <br />
        The code for this page can be found at <code>src/pages/Tutorials/index.js</code>
      </p>
    </div>
  );
};

export default Tutorials;
