import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { useEffect, useState } from "react";
import Site from "../../../api/Admin/Site";
import { SqcSampleForm } from "./form";

type SqcIdSitePickerProps = {
  disabled: boolean;
  value: SqcSampleForm["siteName"] | null;
  onChange(site: Site): void;
};

export function SqcIdSitePicker({
  value: siteName,
  disabled,
  onChange,
}: SqcIdSitePickerProps) {
  const [siteOptions, setSiteOptions] = useState<Site[]>();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);

    Site.getAll()
      .then(setSiteOptions)
      .finally(() => setLoading(false));
  }, []);

  const selectedSite = siteName ? ({ siteName } as Site) : null;

  return (
    <Autocomplete
      disableClearable
      loading={loading}
      options={siteOptions ?? []}
      getOptionLabel={(option) => option.siteName}
      renderOption={(props, option) => (
        <li {...props} key={option.siteName}>
          {option.siteName}
        </li>
      )}
      isOptionEqualToValue={(option, value) =>
        option.siteName === value.siteName
      }
      renderInput={(params) => <TextField {...params} label="Site" />}
      getOptionDisabled={(option) => !option.isActive}
      value={selectedSite as Site}
      onChange={(event, value) => onChange(value)}
      disabled={disabled}
      sx={{ width: "16rem" }}
    />
  );
}
