import { useEffect, useState } from "react";
import ContainerStatus from "../../../api/LIMS/ContainerStatus";

export default function useContainerStatus() {
  const [containerStatus, setContainerStatus] = useState(null);
  useEffect(() => {
    if (containerStatus) return;
    ContainerStatus.getAll().then(
      (res) => {
        setContainerStatus(
          res
            .filter((result) => result.isActive === true)
            .sort((a, b) => a.name.localeCompare(b.name))
        );
      },
      [containerStatus]
    );
  });

  return {containerStatus};
}
