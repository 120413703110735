import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Autocomplete from "@mui/material/Autocomplete";
import { useDetailedStRequest } from "../../../context/DetailedStRequestContext";
import { useFormik } from "formik";
import type { Interval, STIntervalProcessResult } from "../../../types";
import {
  INTERVAL_PROCESS_RESULT_INITIAL_VALUES,
  stIntervalProcessResultSchema,
} from "../../../shared";

type EditStIntervalProcessResultProps = {
  open: boolean;
  close(): void;
  initialValues: STIntervalProcessResult | null;
  onSave(
    stIntervalProcessResult: STIntervalProcessResult,
    previousStIntervalProcessResult: STIntervalProcessResult | null,
    interval: Interval
  ): void;
  intervalThatContainsNewValue: Interval | null;
};

export function EditStIntervalProcessResult({
  open,
  close,
  initialValues,
  onSave,
  intervalThatContainsNewValue,
}: EditStIntervalProcessResultProps) {
  const { uoms, processComponentOptions } = useDetailedStRequest();

  const requestProcessResultFormik = useFormik({
    initialValues: initialValues ?? INTERVAL_PROCESS_RESULT_INITIAL_VALUES,
    onSubmit: (value, { setValues, setFieldTouched }) => {
      if (intervalThatContainsNewValue) {
        onSave(value, initialValues, intervalThatContainsNewValue);
        setValues(INTERVAL_PROCESS_RESULT_INITIAL_VALUES);
        setFieldTouched("resultComponentName", false);
        setFieldTouched("resultValue", false);
        close();
      }
    },
    validationSchema: stIntervalProcessResultSchema,
    validateOnMount: false,
    enableReinitialize: true,
  });

  return (
    <Dialog fullWidth={true} maxWidth="md" open={open} onClose={close}>
      <DialogTitle>ST Interval Process Result</DialogTitle>
      <DialogContent>
        <Box
          component="form"
          display="grid"
          gridTemplateColumns="1fr 1fr 1fr"
          gap={1}
          style={{ paddingTop: "1rem" }}
        >
          <Autocomplete
            id="resultComponentName"
            value={requestProcessResultFormik.values.processComponentName}
            options={processComponentOptions}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Result Component Name"
                size="small"
                helperText={requestProcessResultFormik.errors.processComponentName || " "}
                error={
                  requestProcessResultFormik.touched.processComponentName &&
                  Boolean(requestProcessResultFormik.errors.processComponentName)
                }
                required
              />
            )}
            autoSelect
            onChange={(_, v) => {
              requestProcessResultFormik.setFieldValue("processComponentName", v);
            }}
            onBlur={requestProcessResultFormik.handleBlur}
            disabled={!!initialValues}
          />
          <TextField
            name="resultValue"
            id="resultValue"
            label="Value"
            size="small"
            onChange={requestProcessResultFormik.handleChange}
            onBlur={requestProcessResultFormik.handleBlur}
            value={requestProcessResultFormik.values.resultValue}
            helperText={requestProcessResultFormik.errors.resultValue || ""}
            error={
              requestProcessResultFormik.touched.resultValue &&
              Boolean(requestProcessResultFormik.errors.resultValue)
            }
            required
          />
          <Autocomplete
            id="uom"
            value={requestProcessResultFormik.values.resultUoMName}
            options={uoms.map((uom) => uom.uoMName)}
            renderInput={(params) => <TextField {...params} label="UoM" size="small" />}
            autoSelect
            onChange={(_, v) => {
              requestProcessResultFormik.setFieldValue("resultUoMName", v);
            }}
            onBlur={requestProcessResultFormik.handleBlur}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={close}>Close</Button>
        <Button onClick={requestProcessResultFormik.submitForm}>Save</Button>
      </DialogActions>
    </Dialog>
  );
}
