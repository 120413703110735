import React from "react";
import { styled } from "@mui/material/styles";
import { Box, Tab, Tabs } from "@mui/material";
import AnalyticalMetrics from "./AnalyticalMetrics";
import FormulationsMetrics from "./FormulationsMetrics";
import WorkRequestMetrics from "./WorkRequestMetrics";

const StyledTestTabs = styled(Tabs)({
  marginTop: "-20px",
  borderBottom: "1px solid #e8e8e8",
});

const StyledTestTab = styled((props) => <Tab disableRipple {...props} />)(({ theme }) => ({
  textTransform: "none",
  minWidth: 100,
  [theme.breakpoints.up("sm")]: {
    minWidth: 0,
  },
  fontWeight: theme.typography.fontWeightRegular,
  marginRight: theme.spacing(1),
  color: "rgba(0, 0, 0, 0.85)",
  fontFamily: ["EMPrint"].join(","),
  "&:hover": {
    color: "#40a9ff",
    opacity: 1,
  },
  "&.Mui-selected": {
    color: "#1890ff",
    fontWeight: theme.typography.fontWeightMedium,
  },
}));

const Reporting = ({ ...props }) => {
  const [tabValue, setTabValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const renderSearchTab = () => {
    switch (tabValue) {
      case 0: {
        return <AnalyticalMetrics />;
      }
      case 1: {
        return <FormulationsMetrics />;
      }
      case 2: {
        return <WorkRequestMetrics />;
      }

      default: {
        alert(tabValue);
      }
    }
  };

  return (
    <>
      <div>
        <Box sx={{ bgcolor: "#fff" }}>
          <StyledTestTabs value={tabValue} onChange={handleChange} aria-label='ant example'>
            <StyledTestTab label='Analytical Metrics' />
            <StyledTestTab label='Blends Metrics' />
            <StyledTestTab label='Work Request Metrics' />
          </StyledTestTabs>
          <Box sx={{ p: 3 }} />
        </Box>
        {renderSearchTab()}
      </div>
    </>
  );
};

export default Reporting;