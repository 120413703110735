import ModalTwoButtons from "../../../components/Modal/ModalTwoButtons";

export default function DeleteItem({
  selectedItems,
  selectedItem,
  setSelectedItems,
  setIsModalOpen,
  setSelectedItem,
  setModalType,
  setQuantity,
  isModalOpen,
}) {
  return (
    <ModalTwoButtons
      title="Delete item from list"
      button1Text="Delete"
      button1Action={() => {
        const updatedList = selectedItems.filter((item) => {
          return item?.id !== selectedItem?.id;
        });
        setSelectedItems(updatedList);
        setIsModalOpen(false);
        setSelectedItem({});
        setModalType("");
      }}
      button2Text="Cancel"
      button2Action={() => {
        setSelectedItem({});
        setIsModalOpen(false);
        setQuantity(1);
        setModalType("");
      }}
      setOpen={setIsModalOpen}
      open={isModalOpen}
    />
  );
}
