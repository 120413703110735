import API from '../index'

export default class Return {
    constructor(
        id,
        createdByEmail,
        createdDate,
        lastModifiedByEmail,
        lastModifiedDate,
        returnedByEmail,
        comments,
        orderStatusName,
        returnItems,
        workOrderName,
        completedDate
    ) {
        this.id = id
        this.createdByEmail = createdByEmail
        this.createdDate = createdDate
        this.lastModifiedByEmail = lastModifiedByEmail
        this.lastModifiedDate = lastModifiedDate
        this.returnedByEmail = returnedByEmail
        this.comments = comments
        this.orderStatusName = orderStatusName
        this.returnItems = returnItems
        this.workOrderName = workOrderName
        this.completedDate = completedDate
    }

    static async createReturn(info) {
        const api = await API();
        const { data } = await api.post(`/stockroomreturn?requestId=${info.requestId}&requestItemId=${info.requestItemId}&returnedQuantity=${info.returnedQuantity}`, info);
        return data.result;
    }
}