import API from '../index'

export default class Category {
    constructor(
        StockroomCategoryName,
        Description,
        isActive,
    ) {
        this.StockroomCategoryName = StockroomCategoryName
        this.Description = Description
        this.isActive = isActive
    }

    static async getAll() {
        const api = await API()
        const { data } = await api.get('/stockroomcategory')
        return data.result.map(category => new Category(category))
    }

    static async getCategoryByName(categoryName) {
        const api = await API();
        const { data } = await api.get(`/stockroomcategory?categoryName=${categoryName}`);
        return data.result.map((category) => new Category(category));
    }
}