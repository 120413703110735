import { useState, useContext, useEffect } from "react";
import { Box, TextField, Autocomplete, Typography, CircularProgress } from "@mui/material";
import { StyledForm, StyledButton } from "./styles";
import ProcedureManagementForm from "./components/ProcedureManagementForm";
import ModalSimpleButton from "../../components/Modal/ModalSimpleButton";
import UserContext from "../../context/UserContext";
import { generateUniqueID, hasRole, Roles } from "../../global";

import STProcedure from "../../api/StationaryTesting/STProcedure";
import STParameter from "../../api/StationaryTesting/STParameter";
import { PROCEDURE_INITIAL_STATE } from "./shared";
import { validateIntervals } from "./validators";

const ProcedureManagement = () => {
  const [proceduresList, setProceduresList] = useState([]);
  const [testParameters, setTestParameters] = useState([]);
  const [rowsEngineer, setRowsEngineer] = useState([]);
  const [rowsTest, setRowsTest] = useState([]);
  const [procedureNameValue, setProcedureNameValue] = useState("");
  const [procedureData, setProcedureData] = useState(PROCEDURE_INITIAL_STATE);
  const [showProcedure, setShowProcedure] = useState(false);
  const [isSavingProcedure, setIsSavingProcedure] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState({ title: "", message: "" });
  const [isLoading, setIsLoading] = useState(false);

  const currentUser = useContext(UserContext);
  const roles = currentUser?.idTokenClaims?.roles;

  const canManageProcedure =
    hasRole(Roles.Developer, roles) || hasRole(Roles.ProcedureAdministrator, roles);

  const canManageSchedule =
    hasRole(Roles.Developer, roles) ||
    (hasRole(Roles.STScheduleAdministrator, roles) && hasRole(Roles.ProcedureAdministrator, roles));

  const requiredFieldsAreFilled = !!(
    procedureData?.procedureNumber &&
    procedureData?.procedureName &&
    procedureData?.classificationName
  );
  const listOfProceduresNumbers = proceduresList.map((procedure) => procedure?.procedureNumber);
  const procedureNumberIsAlreadyUsed = !!(
    procedureData?.procedureNumber &&
    listOfProceduresNumbers.includes(procedureData?.procedureNumber) &&
    !procedureNameValue
  );

  const getAllProcedures = async () => {
    const response = await STProcedure.getAll();
    setProceduresList(response);
  };

  async function getAllParameters() {
    const response = await STParameter.getAllActive();
    setTestParameters(response);
  }

  const getAllSelectOptions = async () => {
    setIsLoading(true);

    await getAllProcedures();

    await getAllParameters();

    setIsLoading(false);
  };

  const handleSelectProcedure = async (value) => {
    setIsLoading(true);
    if (value) {
      const stringArray = value.trim().replace(" ", "").split(":");
      const response = await STProcedure.getProcedureByNumberRaw(stringArray[0], true);
      const adaptedProcedure = handleAddRowIDs(response);
      setProcedureData(adaptedProcedure);
      setRowsEngineer(adaptedProcedure?.availableEngineers);
      setRowsTest(adaptedProcedure?.procedureTests);
      setProcedureNameValue(value);
      setShowProcedure(true);
    } else {
      setProcedureData(PROCEDURE_INITIAL_STATE);
      setRowsEngineer([]);
      setRowsTest([]);
      setProcedureNameValue("");
      setShowProcedure(false);
    }
    setIsLoading(false);
  };

  const handleRemoveRowIDs = (procedure) => {
    let newObj = structuredClone(procedure);
    if (newObj?.availableEngineers?.length) {
      const newEngineersArray = newObj?.availableEngineers.map((engineer) => {
        const { rowId, fullName, ...engineerObj } = engineer;
        return engineerObj;
      });
      newObj.availableEngineers = newEngineersArray;
    }

    if (newObj?.procedureTests?.length) {
      const newTestsArray = newObj?.procedureTests.map((test) => {
        const newTestIntervalsArray = test?.testIntervals?.map((testInterval) => {
          if (typeof testInterval.id === "string") {
            delete testInterval.id;
          }
          if (testInterval.intervalSubstance) {
            delete testInterval.intervalSubstance;
          }
          return testInterval;
        });

        const newAvailableFacilitiesArray = [];

        if (test && test.availableFacilities && test.availableFacilities.length > 0) {
          test.availableFacilities.forEach((f) => {
            newAvailableFacilitiesArray.push({
              facilityName: f.stationaryTestFacilityName,
              testDisplayName: test.testDisplayName,
            });
          });
        }

        const newAvailableTestStandsArray = test?.availableTestStands?.map((testStand) => {
          const { rowId, ...testStandObj } = testStand;
          testStandObj.testDisplayName = test?.testDisplayName;
          return testStandObj;
        });

        const newAvailableParametersArray = test?.availableParameters?.map((parameter) => {
          const { rowId, rowSelectOptions, ...parameterObj } = parameter;
          parameterObj.testDisplayName = test?.testDisplayName;
          return parameterObj;
        });

        const { rowId, rowSelected, canEditValue, isOldTest, ...procedureTestObj } = test;
        return {
          ...procedureTestObj,
          derivativeNumber: +procedureTestObj?.derivativeNumber,
          modificationNumber: +procedureTestObj?.modificationNumber,
          testIntervals: newTestIntervalsArray,
          availableFacilities: newAvailableFacilitiesArray,
          availableTestStands: newAvailableTestStandsArray,
          availableParameters: newAvailableParametersArray,
        };
      });
      newObj.procedureTests = newTestsArray;
    }

    return newObj;
  };

  const handleAddRowIDs = (procedure) => {
    let newObj = { ...procedure };
    if (newObj?.availableEngineers?.length) {
      const newEngineersArray = newObj?.availableEngineers.map((engineer) => {
        return {
          ...engineer,
          rowId: generateUniqueID(),
        };
      });
      newObj.availableEngineers = newEngineersArray;
    }

    if (newObj?.procedureTests?.length) {
      const newTestsArray = newObj?.procedureTests.map((test) => {
        const newAvailableFacilitiessArray = test?.availableFacilities?.map((facility) => {
          return {
            ...facility,
            rowId: generateUniqueID(),
            externalCoordinatorEmail: facility?.thisFacility?.externalCoordinatorEmail,
            isActive: facility?.thisFacility?.isActive,
            isExternal: facility?.thisFacility?.isExternal ? "Yes" : "No",
            stationaryTestFacilityName: facility?.thisFacility?.stationaryTestFacilityName,
          };
        });

        const newAvailableTestStandsArray = test?.availableTestStands?.map((testStand) => {
          return {
            ...testStand,
            rowId: generateUniqueID(),
          };
        });

        const newAvailableParametersArray = test?.availableParameters?.map((parameter) => {
          return {
            ...parameter,
            rowId: generateUniqueID(),
            rowSelectOptions: testParameters.map((parameter) => parameter?.testParameterName),
          };
        });

        const adaptedDerivativeNumber =
          test?.derivativeNumber > 0
            ? test?.derivativeNumber?.toString()?.padStart(2, "0")
            : test?.derivativeNumber?.toString();
        const adaptedModificationNumber =
          test?.modificationNumber > 0
            ? test?.modificationNumber?.toString()?.padStart(2, "0")
            : test?.modificationNumber?.toString();

        return {
          ...test,
          rowId: generateUniqueID(),
          isOldTest: true,
          rowSelected: false,
          canEditValue: true,
          derivativeNumber: adaptedDerivativeNumber,
          modificationNumber: adaptedModificationNumber,
          testIntervals: test?.testIntervals,
          availableFacilities: newAvailableFacilitiessArray,
          availableTestStands: newAvailableTestStandsArray,
          availableParameters: newAvailableParametersArray,
        };
      });
      newObj.procedureTests = newTestsArray;
    }

    return newObj;
  };

  const isValidSetOfIntervals = (intervals) => {
    const { isValid, error: errorMessage } = validateIntervals(intervals);

    if (!isValid) {
      setIsModalOpen(true);
      setModalContent({
        title: "Error",
        message: errorMessage,
      });
    }
    return isValid;
  };

  const handleSubmit = async () => {
    try {
      const adaptedProcedure = handleRemoveRowIDs(procedureData);

      const referenceIntervals = adaptedProcedure.procedureTests[0].testIntervals.filter(
        (interval) => interval.isReference,
      );

      const candidateIntervals = adaptedProcedure.procedureTests[0].testIntervals.filter(
        (interval) => !interval.isReference,
      );

      if (
        isValidSetOfIntervals(referenceIntervals) === false ||
        isValidSetOfIntervals(candidateIntervals) === false
      ) {
        return;
      }

      setIsSavingProcedure(true);

      if (procedureNameValue) {
        const response = await STProcedure.updateProcedure(adaptedProcedure);
        if (response && response.result) {
          setProcedureNameValue("");
          setModalContent({
            title: "Success!",
            message: "Your Procedure has successfully been updated.",
          });
          setIsModalOpen(true);
          setShowProcedure(false);
          setProcedureData(PROCEDURE_INITIAL_STATE);
          getAllProcedures();
        } else {
          setIsModalOpen(true);
          setModalContent({
            title: "Error",
            message: response.message,
          });
        }
      } else {
        const payload = {
          ...adaptedProcedure,
          createdDate: new Date(),
          createdByEmail: currentUser?.username,
        };
        const response = await STProcedure.createProcedure(payload);
        if (response && response.result) {
          setModalContent({
            title: "Success!",
            message: "Your Procedure has successfully been created.",
          });
          setIsModalOpen(true);
          setShowProcedure(false);
          setProcedureData(PROCEDURE_INITIAL_STATE);
          getAllProcedures();
        } else {
          setIsModalOpen(true);
          setModalContent({
            title: "Error",
            message: response.message,
          });
        }
      }
    } catch (err) {
      setIsModalOpen(true);
      setModalContent({
        title: "Error!",
        message: "An error occurred. Try again later.",
      });
    }

    setIsSavingProcedure(false);
  };

  useEffect(() => {
    setProcedureData((prevState) => ({
      ...prevState,
      availableEngineers: rowsEngineer,
      procedureTests: rowsTest,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowsTest, rowsEngineer]);

  useEffect(() => {
    getAllSelectOptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <StyledForm>
        <Box display="flex" flexDirection="column" gap={3}>
          <Box display="flex" gap={2}>
            <Autocomplete
              noOptionsText="Loading Procedure Names..."
              value={procedureNameValue}
              name="procedureName"
              onChange={(_, value) => handleSelectProcedure(value)}
              disablePortal
              options={proceduresList.map(
                (procedure) => `${procedure?.procedureNumber}: ${procedure?.procedureName}`,
              )}
              sx={{ width: "35rem" }}
              renderInput={(params) => <TextField {...params} label="Procedure Name" />}
              autoSelect
              size="small"
            />
            <StyledButton
              variant="contained"
              type="button"
              onClick={() => setShowProcedure(true)}
              disabled={!canManageProcedure}>
              Add New
            </StyledButton>
          </Box>

          {isLoading && <CircularProgress style={{}} />}

          {showProcedure && !isLoading && (
            <ProcedureManagementForm
              procedureData={procedureData}
              setProcedureData={setProcedureData}
              rowsEngineer={rowsEngineer}
              setRowsEngineer={setRowsEngineer}
              rowsTest={rowsTest}
              setRowsTest={setRowsTest}
              testParameters={testParameters.map((parameter) => parameter?.testParameterName)}
              doesProcedureExist={!!procedureNameValue}
              procedureNumberIsAlreadyUsed={procedureNumberIsAlreadyUsed}
              canManageProcedure={canManageProcedure}
              canManageSchedule={canManageSchedule}
            />
          )}
        </Box>
        {!isLoading && (
          <Box marginTop={2}>
            <StyledButton
              variant="contained"
              type="button"
              disabled={
                !showProcedure ||
                isSavingProcedure ||
                !requiredFieldsAreFilled ||
                procedureNumberIsAlreadyUsed ||
                !canManageProcedure
              }
              onClick={handleSubmit}>
              Save
            </StyledButton>
          </Box>
        )}
      </StyledForm>
      {isModalOpen && (
        <ModalSimpleButton
          title={modalContent.title}
          buttonText="Close"
          buttonAction={() => {
            setIsModalOpen(false);
            setModalContent({ title: "", message: "" });
          }}
          setOpen={setIsModalOpen}
          open={isModalOpen}>
          <Typography style={{ padding: "0 16px", fontSize: 18, textAlign: "center" }}>
            {modalContent.message}
          </Typography>
        </ModalSimpleButton>
      )}
    </>
  );
};

export default ProcedureManagement;
