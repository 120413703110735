import API from "..";

export default class SpecialAnalysisDataClassification {
 
  constructor({
    classificationName,
    isActive,
    displayColor,
    displayOrder
  }) {
    this.classificationName = classificationName;
    this.isActive = isActive;
    this.displayColor = displayColor;
    this.displayOrder = displayOrder;
  }

  static async get() {
    const api = await API();
    const { data } = await api.get(`/specialrequestdataclassification`);
    return data.result.map((d) => new SpecialAnalysisDataClassification(d));
  }
}