import React from "react";
import RightToKnow from "./RightToKnow";
import { styled } from "@mui/material/styles";
import { Box, Tab, Tabs, tableBodyClasses } from "@mui/material";

const StyledTestTabs = styled(Tabs)({
  marginTop: "-20px",
  borderBottom: "1px solid #e8e8e8",
  // width: "750px",
});

const StyledTestTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    textTransform: "none",
    minWidth: 100,
    [theme.breakpoints.up("sm")]: {
      minWidth: 0,
    },
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(1),
    color: "rgba(0, 0, 0, 0.85)",
    fontFamily: ["EMPrint"].join(","),
    "&:hover": {
      color: "#40a9ff",
      opacity: 1,
    },
    "&.Mui-selected": {
      color: "#1890ff",
      fontWeight: theme.typography.fontWeightMedium,
    },
  })
);

export default function SiteSafety({ setTabValue }) {
  const [tab, setTab] = React.useState(0);
  const handleChange = (event, newValue) => {
    setTab(newValue);
  };

  const renderSearchTab = () => {
    switch (tab) {
      case 0: {
        return <RightToKnow setTabValue={setTabValue} />;
      }

      default: {
        alert(tableBodyClasses);
      }
    }
  };
  return (
    <div>
      <Box sx={{ bgcolor: "#fff" }}>
        <StyledTestTabs
          value={tab}
          onChange={handleChange}
          aria-label="ant example"
        >
          <StyledTestTab label="Right To Know" />
        </StyledTestTabs>
        <Box sx={{ p: 3 }} />
      </Box>
      {renderSearchTab()}
    </div>
  );
}
