import Box from "@mui/material/Box";
import { REQUEST_INFORMATION_ENUM } from "../../shared";
import Table from "@mui/material/Table";
import { StyledScrollableBox, StyledTableCellLarge } from "../../styles";
import {
  StyledTableBody,
  StyledTableCell,
  StyledTableRow,
} from "../../../../components/UXDataTable";
import type { GeneralInformation, TestInformation } from "../../types";
import { columnsFuelSampleTable } from "../../tableColumns";
import { formatMidasNumber } from "../../../../global";

type StationaryTestSampleViewProps = {
  testInformation?: TestInformation;
  generalInformation?: GeneralInformation;
};

export default function StationaryTestSampleView({
  generalInformation,
  testInformation,
}: StationaryTestSampleViewProps) {
  const tableColumns = generalInformation && generalInformation.sampleViscosityGrade 
    ? [...columnsFuelSampleTable, "Viscosity Grade"] 
    : columnsFuelSampleTable;

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <span
        style={{
          textAlign: "left",
          font: "normal normal 600 20px/24px EMprint",
          letterSpacing: 0,
        }}
      >
        {REQUEST_INFORMATION_ENUM.STATIONARY_TEST_SAMPLE}
      </span>
      <Box display="grid" gridTemplateColumns="1fr" gap={2} mt={1}>
        <StyledScrollableBox>
          <Table>
            <StyledTableBody>
              <StyledTableRow>
                {tableColumns.map((col) => (
                  <StyledTableCell key={`header-${col}`}>
                    {col}
                  </StyledTableCell>
                ))}
              </StyledTableRow>
              <StyledTableRow>
                <StyledTableCellLarge>{testInformation?.submittedSample?.sampleName ? formatMidasNumber(testInformation?.submittedSample?.sampleName) : ""}</StyledTableCellLarge>
                <StyledTableCellLarge>{generalInformation?.submittedContainerNumber}</StyledTableCellLarge>
                <StyledTableCellLarge>{testInformation?.submittedSample?.description}</StyledTableCellLarge>
                <StyledTableCellLarge>{generalInformation?.sampleExpectedUse}</StyledTableCellLarge>
                <StyledTableCellLarge>{generalInformation?.sampleExpectedUseUoMName}</StyledTableCellLarge>
                <StyledTableCellLarge>{generalInformation?.sampleUsedReturnLocationName}</StyledTableCellLarge>
                <StyledTableCellLarge>{generalInformation?.sampleUnusedReturnLocationName}</StyledTableCellLarge>
                {generalInformation?.sampleViscosityGrade && (
                  <StyledTableCellLarge>{generalInformation?.sampleViscosityGrade}</StyledTableCellLarge>
                )}
              </StyledTableRow>
            </StyledTableBody>
          </Table>
        </StyledScrollableBox>
      </Box>
    </div>
  );
}
