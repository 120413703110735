import API from "..";

export default class ComponentProcurementThreshold {

  constructor({
    chemID,
    substance,
    thresholdValueKG
  }) {
    this.chemID = chemID;
    this.substance = substance;
    this.thresholdValueKG = thresholdValueKG;
  }

  static async getAll() {
    const api = await API();
    const { data } = await api.get(`/componentprocurementthreshold`);
    return data.result.map((d) => new ComponentProcurementThreshold(d));
  }

  static async create(info) {
    const api = await API();
    const { data } = await api.post(`/componentprocurementthreshold`, info);

    if (data && data.result)
      return new ComponentProcurementThreshold(data.result);
    else return null;
  }

  static async update(info) {
    const api = await API();
    const { data } = await api.put(`/componentprocurementthreshold`, info);

    if (data && data.result)
      return new ComponentProcurementThreshold(data.result);
    else return null;
  }

  static async delete(id) {
    const api = await API();
    const { data } = await api.delete(`/componentprocurementthreshold/${id}`);
    return data;
  }
}
