import React, { useContext, createContext, useCallback, useState } from 'react'
import UserContext from "./UserContext";

import Notification from '../api/Admin/Notification'

export const NotificationContext = createContext(null)

export const NotificationContextProvider = ({ children }) => {
    const [notifications, setNotifications] = useState([])
    const [isNotificationDataLoading, setIsNotificationDataLoading] = useState(false)

    const currentUser = useContext(UserContext)

    const unreadNotificationsCount = notifications?.filter(notification => !notification?.isAcknowledged).length

    const getNotificationsForUser = useCallback(async () => {
        setIsNotificationDataLoading(true)

        const response = await Notification.getByEmail(-1, currentUser?.username)

        setIsNotificationDataLoading(false)

        const adaptedResponse = response?.map(notification => {
            return { ...notification }
        })

        setNotifications(adaptedResponse)

    }, [currentUser?.username])

    return (
        <NotificationContext.Provider 
            value={{ 
                unreadNotificationsCount, 
                notifications, 
                setNotifications, 
                getNotificationsForUser,
                isNotificationDataLoading
            }}
        >
            {children}
        </NotificationContext.Provider>
    )
}