import API from "../../api";

export default class ResultComponent {
  /**
   * The ResultComponent dimension table for M3
   * @param {int} id The id of the ResultComponent
   * @param {String} name The full name of the ResultComponent
   * @param {int} legacyIDNUM The legacyIDNUM for the ResultComponent
   * @param {String} description The Description for the ResultComponent
   * @param {bool} isActive The IsActive for the ResultComponent
   * @param {String} createdByEmail The CreatedByEmail for the ResultComponent
   * @param {Datetime} createdDate The CreatedDate for the ResultComponent
   * @param {string} lastModifiedByEmail The LastModifiedByEmail for the ResultComponent
   * @param {string} lastModifiedDate The LastModifiedDate for the ResultComponent
   * @param {int} researchComponentID The ResearchComponentID for the ResultComponent
   * @param {string} GRDBProperty The GRDBProperty for the ResultComponent
   */

  constructor({
    name,
    legacyIDNUM,
    description,
    isActive,
    createdByEmail,
    createdDate,
    lastModifiedByEmail,
    lastModifiedDate,
    researchComponentID,
    GRDBProperty }) {
    this.name = name;
    this.legacyIDNUM = legacyIDNUM;
    this.description = description;
    this.isActive = isActive;
    this.createdByEmail = createdByEmail;
    this.createdDate = createdDate;
    this.lastModifiedByEmail = lastModifiedByEmail;
    this.lastModifiedDate = lastModifiedDate;
    this.researchComponentID = researchComponentID;
    this.GRDBProperty = GRDBProperty;
  }

  static async getAll() {
    const api = await API();
    const { data } = await api.get(`/resultComponent`);
    return data.result.map((d) => new ResultComponent(d));
  }

  static async getGRDB() {
    const api = await API();
    const { data } = await api.get(`/resultComponent/grdb`);
    return data;
  }

  static async get(id) {
    const api = await API();
    const { data } = await api.get(`resultComponent/getByName?name=${id}`);
    if (data.result) {
      return new ResultComponent(data.result);
    }
    else {
      return null;
    }

  }

  static async add(info) {
    const api = await API();
    const { data } = await api.post(`/resultComponent`, info);
    return new ResultComponent(data.result);
  }

  static async update(id, info) {
    const api = await API();
    const { data } = await api.put(`/resultComponent/${id}`, info);
    return new ResultComponent(data.result);
  }
}
