import API from "..";

export default class SubstanceStructureLookup {
  /**
   * The SubstanceStructureLookup dimension table for M3
   * @param TODO
   */
  constructor({
    chemID,
    referenceSource,
    structureID,
    lowerPercentage,
    upperPercentage,
    substanceStructure
  }) {
    this.chemID = chemID;
    this.referenceSource = referenceSource;
    this.structureID = structureID;
    this.lowerPercentage = lowerPercentage;
    this.upperPercentage = upperPercentage;
    this.substanceStructure = substanceStructure;
  }

  static async get(structureID = "", chemID = "") {
    const api = await API();
    const { data } = await api.get(`/substancestructurelookup?structureID=${structureID}&chemID=${chemID}`);
    return data.result.map((d) => new SubstanceStructureLookup(d));
  }

  static async create(substancestructurelookup) {
    const api = await API();
    const { data } = await api.post(`/substancestructurelookup`, substancestructurelookup);
    return (data);
  }

  static async delete(substancestructurelookup) {
    const api = await API();
    const { data } = await api.delete(`/substancestructurelookup/${substancestructurelookup.structureID}`);
    return data;
  }
}