import { createContext, useState } from "react";
import Request from "../../../api/Stockroom/Request";
import ModalSimpleButton from "../../../components/Modal/ModalSimpleButton";
import { Typography } from "@mui/material";
import { useEffect } from "react";

export const StockroomRequestContext = createContext(null);

export const StockroomRequestContextProvider = ({ children }) => {
  const [requests, setRequests] = useState([]);
  const [requestsForItem, setRequestsForItem] = useState([]);
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [hasError, setHasError] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    if (hasError) setIsModalOpen(true);
  }, [hasError]);

  const getAll = async () => {
    const data = await Request.getAllRequests();
    setRequests(data);
  };

  const getFilteredRequests = async (params) => {
    setIsDataLoading(true);
    const { statuses, startDate, endDate, requester = null } = params;
    await Request.getFilteredRequests(statuses, startDate, endDate, requester)
      .then((data) => {
        setIsDataLoading(false);
        setRequests(data);
      })
      .catch((err) => {
        setHasError(err);
      });
  };

  const getRequestsForItem = async (itemId, searchFrom) => {
    setIsDataLoading(true);
    const data = await Request.getRequestsForItem(itemId, searchFrom);
    setIsDataLoading(false);
    setRequestsForItem(data);
  };

  const getRequests = async (id, requester, orderStatus) => {
    const data = await Request.getStockroomRequests(id, requester, orderStatus);
    setRequests(data);
  };

  const createStockRequest = async (payload) => {
    return await Request.createRequest(payload)
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return setHasError(err);
      });
  };

  const cancelStockRequest = async (id) => {
    await Request.cancelRequest(id)
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return setHasError(err);
      });
  };

  const getStockroomReports = async (payload) => {
    const data = await Request.getStockroomReports(payload);

    return data;
  };

  const refreshRequests = () => {
    setRequests([]);
  };

  const refreshRequestsForItem = () => {
    setRequestsForItem([]);
  };

  return (
    <StockroomRequestContext.Provider
      value={{
        requests,
        isDataLoading,
        getRequests,
        getAll,
        getFilteredRequests,
        createStockRequest,
        cancelStockRequest,
        refreshRequests,
        getRequestsForItem,
        requestsForItem,
        refreshRequestsForItem,
        getStockroomReports,
      }}>
      {children}
      <ModalSimpleButton
        title={"Error"}
        buttonText={"Close"}
        buttonAction={() => {
          setIsModalOpen(false);
          setHasError("");
        }}
        setOpen={() => {
          setIsModalOpen(false);
          setHasError("");
        }}
        open={isModalOpen}>
        <Typography style={{ padding: "0 16px", fontSize: 18, textAlign: "center" }}>
          {hasError}
        </Typography>
      </ModalSimpleButton>
    </StockroomRequestContext.Provider>
  );
};
