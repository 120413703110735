import { React, useState, useEffect } from "react";
import { Box, Autocomplete, Typography, TextField, styled, Modal, TableRow, Checkbox } from "@mui/material";
import { UXDataTableWithoutBody, StyledTableBody, StyledTableCell } from "../../../components/UXDataTable";
import Site from "../../../api/Admin/Site";
import Container from "../../../api/LIMS/Container";
import CloseIcon from '@mui/icons-material/Close';
import { formatMidasNumber, ConvertUOMs, RoundNumber } from "../../../global";
import { GlobalButton } from "../../../pages/styles";
import UnitOfMeasure from "../../../api/Admin/UnitOfMeasure";

const StyledModalBox = styled(Box)(() => ({
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: 'white',
    borderRadius: '10px',
    border: '1px solid rgba(0, 0, 0, 0.3)',
    boxShadow: '0 3px 7px rgba(0, 0, 0, 0.3)',
    backgroundClip: 'padding-box'
}));

const StyledModalHeader = styled('div')({
    marginLeft: '15px',
    marginTop:"20px",
    display: 'flex',
    justifyContent: 'left'
});

const StyledModalBody = styled('div')({
    margin: '1rem'
});

const myContainerCols = ['MIDAS Number', 'Container', 'Sample Description', 'Location', 'Sublocation', 'Amount Available']
const gramUoMObject = {uoMName: 'g', type: 'weight', metricStandardUoMName: 'g', metricStandardConversion: 1}
const allSitesName = "All Sites"

const LocateContainer = ({ open, setOpen, incomingUoMObject, midasNumber, setSelectedContainer, updateAllMethods, methodInfo, allowAllSites = false, onlySearch = false }) => {

    const handleClose = () => setOpen(false)

    const [availableSites, setAvailableSites] = useState([])
    const [availableUOMs, setAvailableUOMs] = useState([])

    const [isDataLoading, setIsDataLoading] = useState(false)

    const [availableContainers, setAvailableContainers] = useState([])
    const [checkedRows, setCheckedRows] = useState([])
    const [selectedSite, setSelectedSite] = useState(null)
    const [selectedUoM, setSelectedUoM] = useState(incomingUoMObject ? incomingUoMObject : gramUoMObject)

    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [page, setPage] = useState(0);
  
   
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handelCheckChange = (isChecked, index) => {
        const copyChecks = JSON.parse(JSON.stringify(checkedRows))

        copyChecks[index] = isChecked

        setCheckedRows(copyChecks)
    }

    function AddMidasNumbers() {
        const firstIndex = checkedRows.findIndex((x) => x === true)

        setSelectedContainer(availableContainers[firstIndex], methodInfo, 'createFromContainer', updateAllMethods)
        //setSelectedContainer(availableContainers[firstIndex], midasNumber, methodInfo, 'createFromContainer', updateAllMethods)
      
        setOpen(false)
    }

    function SearchForContainers() {
        setIsDataLoading(true)
        setAvailableContainers([])

        let cancelPromise = false

        let searchPayload = {
            site: selectedSite.siteName === allSitesName ? null : selectedSite.siteName,
            containerTypes:null,
            includeDiscards: false,
            listCriteria:[
            {
                property: "SampleName",
                operator: "==",
                join: null,
                value: midasNumber.replace("-", "")
            }
            ]
        }          

        Container.getAdvancedLocateContainers(searchPayload).then((res) => 
        {
            if (cancelPromise) return

            setAvailableContainers(res.sort((a, b) => new Date(b.createdDate) - new Date(a.createdDate)))

            setCheckedRows(Array(res.length).fill(false))
            setIsDataLoading(false)
        })
           
        return () => {
            cancelPromise = true
          }
    }

    useEffect(() => {
        let cancelPromise = false

        Site.getAll().then((res) => {
            if (cancelPromise) return

            let emptyOption = {siteName: allSitesName, abreviation:"", isActive:true}

            if(allowAllSites){
                setAvailableSites([emptyOption, ...res.filter(result => result.isActive === true && result.siteName !== 'Global Usage - No Site').sort((a, b) => a.siteName < b.siteName ? -1 : 1)])
            }else{
                setAvailableSites(res.filter(result => result.isActive === true && result.siteName !== 'Global Usage - No Site').sort((a, b) => a.siteName < b.siteName ? -1 : 1))
            }
            
        });

        UnitOfMeasure.getAll().then((res) => {
            if (cancelPromise) return
            setAvailableUOMs(res.filter(result => (result.type === 'weight' || result.type ==='volume') && result.metricStandardConversion !== null && result.isActive === true).sort((a, b) => a.uoMName.localeCompare(b.uoMName)))
        })
        
        return () => {
            cancelPromise = true
        }
     // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (open === false)
        {
            setAvailableContainers([])
            setCheckedRows([])
            setSelectedUoM((incomingUoMObject ? incomingUoMObject : gramUoMObject))
            setSelectedSite(null)
        }
    }, [incomingUoMObject, open])

    useEffect(() => {
        if (selectedUoM === null)
        {
            setSelectedUoM(gramUoMObject)
        }
    }, [selectedUoM])

    return (
        <>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-header"
                aria-describedby="modal-modal-body"
            >
                <StyledModalBox style={{width:"60%", minHeight:"60%", maxHeight:"100%", overflow:"auto"}}>
                    <StyledModalHeader id="modal-modal-header" >
                        <Typography variant="h6" component="h6">{`Locate Container for MIDAS Number ${formatMidasNumber(midasNumber)}`}</Typography>
                        
                        <Box display="flex" alignItems={"center"} marginLeft="auto" marginRight="20px">
                            <CloseIcon onClick={() => setOpen(false)}></CloseIcon>
                        </Box>
                    </StyledModalHeader>
                    <StyledModalBody id="modal-modal-body">

                <label>
                    {`Select the container that you would like to use.`}
                </label>

                <div style={{
                    display:"flex",
                    marginBottom:"20px",
                    marginTop:"20px"}}>

                    {/* <TextField size="small" margin="dense" label="ChemID" style={{width:"250px", marginTop:"15px"}}
                    inputProps={{ style: { fontSize: 14 }}} 
                    disabled={true}
                    value={SetChemIDTextFieldValue()}
                    >
                    </TextField> */}

                    <Autocomplete
                        disablePortal
                        options={ availableSites}
                        onChange={(e, value) => { setSelectedSite(value)}}
                        getOptionLabel={(option) => option.siteName}
                        value={selectedSite}
                        isOptionEqualToValue={(option, value) => value.siteName === option.siteName}
                        autoHighlight
                        style={{marginTop:"3px"}}
                        renderInput={(params) => <TextField {...params} style={{width:"300px", marginTop:"13px", marginLeft:"0px"}} variant="outlined" size="small" label="Select Site" error = {false}  
                        inputProps={{ ...params.inputProps,  style: { fontSize: 14 }}} InputProps={{ ...params.InputProps }}/>} 
                    />

                    <Autocomplete
                        disablePortal
                        noOptionsText={"Loading UoMs..."}
                        options={availableUOMs}
                        getOptionLabel={(option) => option.uoMName}
                        onChange={(e, value) => setSelectedUoM(value)}
                        autoHighlight
                        autoSelect
                        value={selectedUoM}
                        isOptionEqualToValue={(option, value) => value.uoMName === option.uoMName}
                        style={{marginTop:"3px"}}
                        renderInput={(params) => <TextField {...params} style={{width:"150px", marginTop:"13px", marginLeft:"25px"}} variant="outlined" size="small" label="Unit of Measure" error={false}
                        inputProps={{ ...params.inputProps,  style: { fontSize: 14 }}} InputProps={{ ...params.InputProps }}/>}  
                    />

                    <GlobalButton
                        disabled={selectedSite == null}
                        variant="contained" 
                        style={{ textTransform: "none", marginTop:"17px", marginLeft:"25px", height:"30px"}}
                        onClick={() => SearchForContainers()}
                    >Search</GlobalButton>
                </div>

                <div>

                    <UXDataTableWithoutBody
                        tableWidth='95%' 
                        cols={myContainerCols} 
                        blankFirstHeader = {onlySearch ? false : true}
                        tableId= {`locateMIDASNumber`}
                        rowLength={availableContainers.length}
                        enablePaging = {true}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        handleChangePage={handleChangePage}
                        handleChangeRowsPerPage={handleChangeRowsPerPage}
                        noDataFoundMessage={'No Containers Found'}
                        enableAddIcon = {false}
                        addFunction={null}
                        addToolTipText="Add new component"
                        isDataLoading={isDataLoading}
                    >
                    {availableContainers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((oContainer, index) => {
                        return(
                        <StyledTableBody key={`SearchedContainers~${index}`}>
                            <TableRow> 
                                {!onlySearch && <StyledTableCell >
                                    <Checkbox 
                                        disabled = {checkedRows.filter(result => result === true).length >= 1 && checkedRows[index + (page * rowsPerPage)] === false}
                                        checked = {checkedRows[index + (page * rowsPerPage)] === true}
                                        onChange = {e => handelCheckChange(e.target.checked, index + (page * rowsPerPage))}>
                                    </Checkbox>
                                </StyledTableCell>}


                                <StyledTableCell component='th' scope='row'>
                                    {formatMidasNumber(oContainer.sampleName)}
                                </StyledTableCell>

                                <StyledTableCell component='th' scope='row'>
                                    {oContainer.containerNumber}
                                </StyledTableCell>

                                <StyledTableCell >
                                    {oContainer.sample?.description}
                                </StyledTableCell>

                                <StyledTableCell >
                                    {oContainer.locationName}
                                </StyledTableCell>

                                <StyledTableCell >
                                    {oContainer.subLocation}
                                </StyledTableCell>

                                <StyledTableCell >
                                    {RoundNumber(ConvertUOMs(oContainer.sample, oContainer.sample?.substance, oContainer.currentAmount, oContainer.uom, selectedUoM), 3)}
                                </StyledTableCell>
                            
                            </TableRow>
                        </StyledTableBody>
                        )
                    })}

                    </UXDataTableWithoutBody>

                </div>    

                    {!onlySearch && <Box display="flex" alignItems={"center"} marginLeft="auto">
                        <GlobalButton 
                            variant="contained" 
                            style={{ textTransform: "none", marginTop:"17px", marginLeft:"auto", marginRight:"5%", height:"30px"}}
                            disabled = {checkedRows.filter(result => result === true).length < 1}
                            onClick={() => AddMidasNumbers()}
                        >{'Select Container'}</GlobalButton>
                    </Box>}

                    </StyledModalBody>
                </StyledModalBox>
            </Modal>
        </>
    );
};

export default LocateContainer;