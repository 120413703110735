import API from "..";

export default class blendComponent {

  constructor({
    id, 
    blendID, 
    targetAmountUpper,
    targetAmountLower, 
    addOrder,
    isBalance,
    preferredSampleName, 
    secondaryPreferredSampleName,
    substanceName,
    componentInstructions,
    componentUoMName,
    sortOrder
}) 
    
  {
    this.id = id;
    this.blendID = blendID;
    this.batargetAmountUppertch = targetAmountUpper;
    this.targetAmountLower = targetAmountLower; 
    this.addOrder = addOrder;
    this.isBalance = isBalance;
    this.preferredSampleName = preferredSampleName;
    this.secondaryPreferredSampleName = secondaryPreferredSampleName;
    this.substanceName = substanceName;
    this.componentInstructions = componentInstructions;
    this.componentUoMName = componentUoMName;
    this.sortOrder = sortOrder;
  }

  static async delete(componentID) {
    const api = await API();
    const { data } = await api.delete(`/blendComponent/${componentID}`);
    return (data);
  }
}
