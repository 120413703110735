import React from "react";

const About = ({ ...props }) => {
  return (
    <div>
      <div>
        <h2>About MIDAS 3</h2>
        <h4>Mobil Integrated Data Aquisition System</h4>
        <h4>Copyright EMTEC IT 2023</h4>
      </div>
      <div>
        MIDAS is a custom developed Laboratory Information Management System
      </div>
      <div>
        <h3>Development Team</h3>
        <h4>System Architects</h4>
        <p>Eric Delich<br/>
        </p>
        <h4>Product Owner</h4>
        <p>Matthew McKinney</p>
        <h4>Developers</h4>
        <p>Leonardo Oliveira<br />
          Basit Mohammed<br />
          Irvin Diaz<br />
          Kourtnie Henault<br />
          Saul Hernandez<br />
          Emilio Weba Filho<br/>
          Gary Lyons</p>

      </div>
    </div>
  );
};

export default About;