import * as yup from "yup";
import { PackageTestCriteria } from "../../../api/Admin/PackageTestCriteria";
import Method from "../../../api/LIMS/Method";

export type PackageTestForm = {
  name: string;
  description: string | null;
  isActive: boolean;
  isPackageTest: true;
  packageTestOwnerEmail: string;
  packageTestRequiredAmount: number | null;
  packageTestRequiredAmountUoMName: string | null;
  packageTestCriterias: PackageTestCriteria[];
};

export const packageTestCriteriaSchema = yup.object({
  // packageTestName: yup.string().required(),
  // methodFacilityID: yup.number().required(),
  generatesNewSample: yup.boolean().required(),
  stepDescription: yup.string().nullable(),
  conditionalTrigger: yup.string().nullable(),
  scheduledIntervalHours: yup.number().nullable(),
  defaultNewSampleChemID: yup
    .string()
    .nullable()
    .when("generatesNewSample", {
      is: true,
      then: () => yup.string().required(),
      otherwise: () => yup.string().nullable(),
    }),
  postTestSampleYield: yup
    .number()
    .nullable()
    .when("generatesNewSample", {
      is: true,
      then: () =>
        yup
          .number()
          .transform((value) => (isNaN(value) ? null : value))
          .required("Required"),
      otherwise: () =>
        yup
          .number()
          .transform((value) => (isNaN(value) ? null : value))
          .nullable(),
    }),
  postTestSampleYieldUoMName: yup
    .string()
    .nullable()
    .when("generatesNewSample", {
      is: true,
      then: () => yup.string().required(),
      otherwise: () => yup.string().nullable(),
    }),
  // testConditionOfferingID: yup.string().required(),
});

export const packageTestSchema = yup.object({
  name: yup
    .string()
    .min(1)
    .max(50)
    .required()
    .test(
      "package_test_name_async_validation",
      "Package name already in use. Please enter a new name.",
      async function (value) {
        try {
          if (value && value !== "") {
            const response = await Method.get(`PKG-${value}`);
            if (response) {
              return this.createError({
                message:
                  "Package name already in use. Please enter a new name.",
              });
            }
          }
        } catch (e) {
          console.log(e);
        }
        return true;
      }
    ),
  description: yup.string().min(1).max(200).nullable(),
  isActive: yup.boolean(),
  packageTestCriterias: yup.array().of(packageTestCriteriaSchema),
  packageTestOwnerEmail: yup.string().email(),
  packageTestRequiredAmount: yup.number().min(0),
  packageTestRequiredAmountUoMName: yup.string(),
});

function prepareCriteriaForSave(
  criterion: PackageTestCriteria,
  packageTestName: string
): PackageTestCriteria {
  return {
    id: criterion.id,
    packageTestName: criterion.packageTestName ?? packageTestName,
    stepDescription: criterion.stepDescription,
    methodFacilityID: criterion.methodFacilityID,
    parentTestCriteriaID: criterion.parentTestCriteriaID,
    generatesNewSample: criterion.generatesNewSample,
    conditionalTrigger: criterion.conditionalTrigger,
    scheduledIntervalHours: criterion.scheduledIntervalHours,
    defaultNewSampleChemID: criterion.defaultNewSampleChemID,
    testConditionOfferingID: criterion.testConditionOfferingID,
    postTestSampleYield: criterion.postTestSampleYield,
    postTestSampleYieldUoMName: criterion.postTestSampleYieldUoMName,
    childPackageTestCriteria: criterion.childPackageTestCriteria.map(
      (criteria) => prepareCriteriaForSave(criteria, packageTestName)
    ),
  } as PackageTestCriteria;
}

/** Prepares the form to be sent to the API, avoiding ORM errors */
export function prepareFormForSave(form: PackageTestForm): PackageTestForm {
  form.packageTestCriterias = form.packageTestCriterias.map((criteria) =>
    prepareCriteriaForSave(criteria, form.name)
  );

  return form;
}

export function addCriterion(
  items: PackageTestCriteria[],
  packageTestCriterion: PackageTestCriteria,
  parent?: PackageTestCriteria
): PackageTestCriteria[] {
  const newItems: PackageTestCriteria[] = [];

  if (!parent) {
    return [...items, packageTestCriterion];
  }

  for (const item of items) {
    if (item === parent) {
      item.childPackageTestCriteria.push(packageTestCriterion);
    }

    if (item.childPackageTestCriteria.length) {
      item.childPackageTestCriteria = addCriterion(
        item.childPackageTestCriteria,
        packageTestCriterion,
        parent
      );
    }

    newItems.push(item);
  }

  return newItems;
}

export function removeCriterion(
  items: PackageTestCriteria[],
  packageTestCriterion: PackageTestCriteria
): PackageTestCriteria[] {
  const newItems: PackageTestCriteria[] = [];

  for (const item of items) {
    if (item === packageTestCriterion) {
      continue;
    }

    if (item.childPackageTestCriteria.length) {
      item.childPackageTestCriteria = removeCriterion(
        item.childPackageTestCriteria,
        packageTestCriterion
      );
    }

    newItems.push(item);
  }

  return newItems;
}

export function updateCriteria(
  items: PackageTestCriteria[],
  previousValue: PackageTestCriteria,
  newValue: PackageTestCriteria
): PackageTestCriteria[] {
  const newItems: PackageTestCriteria[] = [];

  for (const item of items) {
    if (item.childPackageTestCriteria.length) {
      item.childPackageTestCriteria = updateCriteria(
        item.childPackageTestCriteria,
        previousValue,
        newValue
      );
    }

    newItems.push(item === previousValue ? newValue : item);
  }

  return newItems;
}

export function findCriterionInTree(
  items: PackageTestCriteria[],
  id: number
): PackageTestCriteria | undefined {
  return items.find((i) => i.id === id);
}

export function buildCriteriaTree(
  packageTestCriteria: PackageTestCriteria[]
): PackageTestCriteria[] {
  const root = {
    id: 0,
    childPackageTestCriteria: [] as PackageTestCriteria[],
  } as PackageTestCriteria;

  const nodes: Record<number, PackageTestCriteria> = { [root.id]: root };

  const items = packageTestCriteria.map<PackageTestCriteria>((item) => ({
    ...item,
    childPackageTestCriteria: [],
  }));

  for (const item of items) {
    const parentId = item.parentTestCriteriaID ?? root.id;
    const parent = nodes[parentId] ?? findCriterionInTree(items, parentId);

    nodes[item.id] = item;
    parent.childPackageTestCriteria.push(item);
  }

  return root.childPackageTestCriteria;
}

export function flattenTree(
  tree: PackageTestCriteria[]
): PackageTestCriteria[] {
  const flattenedTree: PackageTestCriteria[] = [];

  for (const child of tree) {
    flattenedTree.push(child);

    if (child.childPackageTestCriteria.length > 0) {
      flattenedTree.push(...flattenTree(child.childPackageTestCriteria));
    }
  }

  return flattenedTree;
}
