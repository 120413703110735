import { useState, useEffect } from "react";
import { TextField, Autocomplete } from "@mui/material";
import BillingInfo from "../../api/Admin/BillingInfo";
import ModalSimpleButton from "../../components/Modal/ModalSimpleButton";

const BillingInfoField = ({billingValue, handleChange, isDisabled, margin, marginLeft, marginRight, fieldWidth, hasErrors, helperText, required = false}) => { 

  const [billingLoading, setBillingLoading] = useState(false);
  const [billingInfo, setBillingInfo] = useState([])

  //Modal stuff
  const [modalMessagesOpen, setModalMessagesOpen] = useState(false);
  const modalMessagesButtonText = 'Ok'
  const [modalMessagesTitle, setModalMessagesTitle] = useState('');
  const [modalMessagesText, setModalMessagesText] = useState('');

  useEffect(() => {
    let cancelPromise = false
    setBillingLoading(true)

    BillingInfo.getAll().then((res) => {
        if (cancelPromise) return

        setBillingLoading(false)

        if (res.message === 'Success')
        {
            //setBillingInfo(res.result.filter(result => result.isActive === true).sort((a, b) => a.costCode.localeCompare(b.costCode))) 
            setBillingInfo(res.result.sort((a, b) => a.costCode.localeCompare(b.costCode)))               
        } else {
            setBillingInfo([])
            openModalMessages("Billing Info Error", "Failed to retrieve the billing info from MIDAS, contact support if this continues.")
        }

    });

    return () => {
      cancelPromise = true
    }
  }, [])

  function closeModalMessages() {
    setModalMessagesOpen(false);
  }

  function openModalMessages(title, text) {
    setModalMessagesOpen(true);
    setModalMessagesTitle(title);
    setModalMessagesText(text);
  }

  function returnValue (value) {
    if (value === null)
    {
      return null;
    } else {
      if (value.isActive)
      {
        return value.costCode
      } else {
        openModalMessages("Inactive JADE Number", `The JADE number ${value.costCode} is not active, please select a different number.`)
        return null;
      }
    }
  }

  return (
    <>
        <Autocomplete
            size="small"
            disablePortal
            noOptionsText={billingLoading ? "Loading Billing Codes..." : "No Billing Codes Found"}
            options={billingInfo}
            getOptionLabel={(option) => `${option.costCode} ~ ${option.description} - ${option.isActive ? "Active" : "Inactive"}`}
            onChange={(e, value) => {
              //value = value === null ? null : value.costCode;
                value = returnValue(value);
                handleChange(value);
            }}
            isOptionEqualToValue={(option, value) => option === value}
            autoHighlight
            autoSelect
            value={(billingValue === undefined || billingValue === '' || billingValue === null || billingInfo.length === 0) ? null : billingInfo.find(oInfo => oInfo.costCode === billingValue)}
            style={{ width: fieldWidth ? fieldWidth : "20rem", marginLeft: marginLeft ? marginLeft : "0rem", marginRight: marginRight ? marginRight : "0rem"  }}
            disabled={isDisabled}
            renderInput={(params) => (<TextField {...params}
                variant="outlined"
                size="small"
                label={`${required ? '*' : ''} JADE Number`}
                margin={margin ? margin : "none"}
                error={Boolean(hasErrors)}
                helperText={Boolean(hasErrors) ? helperText : ""}
                InputLabelProps={{shrink: true}} 
                InputProps={{ ...params.InputProps }}
                />
            )}
        />

        {/* Informational Messages */}
        <ModalSimpleButton title={modalMessagesTitle} buttonText={modalMessagesButtonText} buttonAction={closeModalMessages} open={modalMessagesOpen} setOpen={setModalMessagesOpen}>
            {modalMessagesText}
        </ModalSimpleButton>
    </>
  );
};
export default BillingInfoField;
