import ShowChartIcon from "@mui/icons-material/ShowChart";
import LoadingButton from "@mui/lab/LoadingButton";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
//import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CircularProgress from "@mui/material/CircularProgress";
import Checkbox from "@mui/material/Checkbox";
import Divider from "@mui/material/Divider";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";
import Switch from "@mui/material/Switch";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import format from "date-fns/format";
import { useFormik } from "formik";
import { useContext, useEffect, useState } from "react";
import * as yup from "yup";
import { SqcApi } from "../../api/Admin/SQC";
import { SqcStatusApi } from "../../api/Admin/SQCStatus";
import UserContext from "../../context/UserContext";
import {
  Roles,
  convertDateFormat,
  convertToLocalTime,
  exportToCsv,
  hasRole,
  DatePickerKeyDownEvent
} from "../../global";
import {
  HISTOGRAM_CATEGORIES,
  SimplifiedSqcResultValue,
  SqcMethodSetup,
  SqcStatus,
} from "../../lib/types/sqc";
import { GlobalButton, GlobalSecondaryButton } from "../styles";
import { Chart } from "./chart";
import toast from "react-hot-toast";

const RANGE = {
  Date: "date",
  NumberOfPoints: "numberOfPoints",
} as const;

type Range = (typeof RANGE)[keyof typeof RANGE];

type FilterForm = {
  method: string | null;
  methodFacilityId: number | null;
  qcid: string | null;
  resultComponent: string | null;
  temperature: number | null;
  instrumentIds: number[];
  range: Range;
  startDate: Date | null;
  endDate: Date | null;
  numberOfPoints: number | null;
  /** Unavailable when temperature dependent */
  deviationLimit: number | null;
  ewmaLambda: number;
  callOutsVisible: boolean;
  ruleViolationsVisible: boolean;
  excludedPointsVisible: boolean;
};

const initialValidationSchema = yup.object({
  // method: yup.string(),
  methodFacilityId: yup.number(),
  qcid: yup.string().required(),
  resultComponent: yup.string(),
  temperature: yup.number().nullable(),
  instrumentIds: yup.array().of(yup.number()),
  range: yup.string(),
  startDate: yup
    .date()
    .nullable()
    .when("range", {
      is: RANGE.Date,
      then: () => yup.date(),
    }),
  endDate: yup
    .date()
    .nullable()
    .when("range", {
      is: RANGE.Date,
      then: () => yup.date(),
    }),
  numberOfPoints: yup
    .number()
    .positive()
    .nullable()
    .when("range", {
      is: RANGE.NumberOfPoints,
      then: () => yup.number().positive(),
    }),
  deviationLimit: yup.number().positive().max(100),
  ewmaLambda: yup.number().positive().max(1).required(),
  callOutsVisible: yup.boolean(),
  ruleViolationsVisible: yup.boolean(),
  excludedPointsVisible: yup.boolean(),
});

type MethodFacilityOption = {
  id: number;
  testFacilityAbv: string;
};

function getHoverTemplate(
  resultValues: SimplifiedSqcResultValue[],
  callOutsVisible: boolean,
  ruleViolationsVisible: boolean
): string[] {
  return resultValues.map(
    (r) =>
      `<b>Value</b>: ${r.value}<br>` +
      `<b>Date</b>: ${convertToLocalTime(r.analysisDate)}<br>` +
      `<b>ID</b>: ${r.number}<br>` +
      `<b>Status</b>: ${r.status}<br>` +
      `<b>Instrument Name</b>: ${r.instrumentName}<br>` +
      (callOutsVisible ? `<b>Remarks</b>: ${r.comments}<br>` : "") +
      (ruleViolationsVisible
        ? `<b>Failures</b>: ${r.failures.replaceAll("\n", "<br>")}`
        : "")
  );
}

export function SqcGraphs() {
  const [isLoadingSqcMethodSetups, setIsLoadingSqcMethodSetups] =
    useState(false);
  const [sqcMethodSetups, setSqcMethodSetups] = useState<SqcMethodSetup[]>([]);
  const [isLoadingResults, setIsLoadingResults] = useState(false);
  const [validationSchema, setValidationSchema] = useState(initialValidationSchema);
  const [resultValues, setResultValues] = useState<SimplifiedSqcResultValue[]>(
    []
    );
  const [sqcStatuses, setSqcStatuses] = useState<SqcStatus[]>([]);
  const [sqcResultsToChange, setSqcResultsToChange] = useState<any[]>([]);
  const [isLoadingResultsChanges, setIsLoadingResultsChanges] = useState(false);

  const formik = useFormik<FilterForm>({
    initialValues: {
      method: null,
      methodFacilityId: null,
      qcid: null,
      resultComponent: null,
      temperature: null,
      instrumentIds: [],
      range: RANGE.Date,
      startDate: null,
      endDate: null,
      numberOfPoints: null,
      deviationLimit: 5,
      ewmaLambda: 0.25,
      callOutsVisible: true,
      ruleViolationsVisible: true,
      excludedPointsVisible: false,
    },
    onSubmit: () => {},
    validationSchema,
    validateOnMount: true,
  });


  async function loadSqcMethods() {
    setIsLoadingSqcMethodSetups(true);

    const response = await SqcApi.getAllMethodSetups();

    if (response && response.result) {
      setSqcMethodSetups(response.result);
    } else if (response && response.message) {
      toast.error(response.message);
    } else {
      toast.error("Failed while loading sqc methods");
    }
    
    setIsLoadingSqcMethodSetups(false)
  }

  async function loadSqcStatuses() {
    const response = await SqcStatusApi.getAllStatuses();

    if (response) {
      setSqcStatuses(response);
    } else {
      toast.error("Failed while loading sqc statuses");
    }
  }

  useEffect(() => {
    loadSqcMethods();
    loadSqcStatuses();
  }, []);

  const onChangeResult = (id: number | null, field: string, value: any) => {
    let results = resultValues.map(result => {
      if (result.resultId === id) {
        return {
          ...result,
          [field]: value
        }
      }

      return result;
    });

    setResultValues(results);
    if (!sqcResultsToChange.some(s => s === id)) setSqcResultsToChange([...sqcResultsToChange, id]);
  };

  function handleSaveResult() {
    setIsLoadingResultsChanges(true);
    sqcResultsToChange.forEach(async (id, index) => {
      const resultValue = resultValues.find(r => r.resultId === id);
      let errors = [];
      if (resultValue) {
        const response = await SqcApi.updatePartialResult(id, resultValue.status, resultValue.comments ?? '');
        if (!response) {
          errors.push(id);
        }
      }

      if (sqcResultsToChange.length === index + 1) {
        if (errors.length < 1) {
          toast.success("Points changed with success");
        } else {
          toast.error("Failed to change points from result ids " + errors.join(', '));
        }
        handleSearch();
        setIsLoadingResultsChanges(false);
      }
    })
  }

  const resetFilter = (includeMethodFacility = false, includeRange = false) => {
    if (includeMethodFacility) {
      formik.setFieldValue("method", null);
      formik.setFieldValue("methodFacilityId", null);
    }

    if (includeRange) {
      formik.setFieldValue("startDate", null);
      formik.setFieldValue("endDate", null);
      formik.setFieldValue("numberOfPoints", null);
    }

    formik.setFieldValue("qcid", null);
    formik.setFieldValue("resultComponent", null);
    formik.setFieldValue("temperature", null);
    formik.setFieldValue("instrumentIds", []);
  };

  const onSelectMethod = (method: string | null) => {
    const sqcMethodSetup = sqcMethodSetups.find(
      (s) => s.methodFacility?.methodName === method
    );

    const newValidationSchema: any = yup.object().shape({
      ...validationSchema.fields,
      temperature: sqcMethodSetup?.isTemperatureDependent ? yup.number().required() : yup.number().notRequired(),
    })
    
    setValidationSchema(newValidationSchema)
    resetFilter(true);
    setResultValues([]);
    formik.setFieldValue("method", method);
  };

  const onSelectMethodFacility = (
    methodFacility: MethodFacilityOption | null
  ) => {
    resetFilter();
    setResultValues([]);
    formik.setFieldValue("methodFacilityId", methodFacility?.id);

    const sqcMethodSetup = sqcMethodSetups.find(
      (s) => s.methodFacilityID === methodFacility?.id
    );

    formik.setFieldValue(
      "instrumentIds",
      sqcMethodSetup?.methodFacility?.availableInstruments
        .filter((a) => a.isActive)
        .map((a) => a.instrumentID) ?? []
    );
  };

  const onSelectRange = (type: Range) => {
    if (type === RANGE.Date) {
      formik.setFieldValue("numberOfPoints", null);
    } else {
      formik.setFieldValue("startDate", null);
      formik.setFieldValue("endDate", null);
    }

    formik.setFieldValue("range", type);
  };

  const handleSearch = () => {
    setSqcResultsToChange([]);
    if (!selectedMethodFacility || !formik.values.resultComponent) return;

    setResultValues([]);
    setIsLoadingResults(true);

    SqcApi.getResultValues({
      methodFacilityId: formik.values.methodFacilityId!,
      qcid: formik.values.qcid!,
      parameter: formik.values.resultComponent!,
      temperature: formik.values.temperature ?? undefined,
      instrumentIds: formik.values.instrumentIds,
      startDate: formik.values.startDate
        ? format(formik.values.startDate, "MM/dd/yyyy")
        : undefined,
      endDate: formik.values.endDate
        ? format(formik.values.endDate, "MM/dd/yyyy")
        : undefined,
      numberOfPoints: formik.values.numberOfPoints ?? undefined,
      lambda: formik.values.ewmaLambda,
      excludedPointsVisible: formik.values.excludedPointsVisible,
    })
      .then((result) => {
        if (result) setResultValues(result);
      })
      .finally(() => setIsLoadingResults(false));
  };

  const methods = Array.from(
    new Set(sqcMethodSetups.map((s) => s.methodFacility.methodName))
  );

  const facilities = sqcMethodSetups
    .filter((s) => s.methodFacility.methodName === formik.values.method)
    .map((s) => ({
      id: s.methodFacilityID,
      testFacilityAbv: s.methodFacility.testFacilityAbv,
    }));

  const selectedSqcMethodSetup = sqcMethodSetups.find(
    (s) => s.methodFacilityID === formik.values.methodFacilityId
  );

  const qcids = Array.from(
    selectedSqcMethodSetup?.qcids?.map((q) => q.sqcSampleID) ?? []
  ).sort();

  const currentLimit = selectedSqcMethodSetup?.limits.find(
    (l) =>
      l.isActive &&
      l.sqcSampleID === formik.values.qcid &&
      l.resultComponentName === formik.values.resultComponent &&
      (!selectedSqcMethodSetup.isTemperatureDependent ||
        l.applicableTemperatureValue === formik.values.temperature)
  );

  const instruments =
    selectedSqcMethodSetup?.methodFacility?.availableInstruments ?? [];

  const selectedMethodFacility =
    facilities.find((f) => f.id === formik.values.methodFacilityId) ?? null;

  const selectedInstruments = instruments.filter((a) =>
    formik.values.instrumentIds.includes(a.instrumentID)
  );

  const currentUser = useContext(UserContext);
  const roles = (currentUser.idTokenClaims as unknown as { roles: string[] })
    .roles;

  const canViewData =
    hasRole(Roles.Developer, roles) ||
    hasRole(Roles.SQCAdministrator, roles) ||
    hasRole(Roles.SQCViewer, roles);

  const canFilterInstruments: boolean =
    hasRole(Roles.Developer, roles) || hasRole(Roles.SQCAdministrator, roles);

  if (!canViewData) {
    return (
      <Typography>
        You don't have permission to view SQC data. Make sure you're assigned
        the QC.VIEWER role.
      </Typography>
    );
  }

  return (
    <Stack sx={{ gap: "1rem" }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
          paddingBlock: "0.5rem",
        }}
      >
        <Stack direction="row" gap="1rem" flexWrap="wrap" alignItems="center">
          <Autocomplete
            loading={isLoadingSqcMethodSetups}
            options={methods}
            renderInput={(params) => (
              <TextField {...params} label="Method" size="small" />
            )}
            value={formik.values.method}
            onChange={(event, value) => onSelectMethod(value)}
            sx={{ display: "inline-flex", width: "16rem" }}
          />

          <Autocomplete
            options={facilities}
            getOptionLabel={(option) => option.testFacilityAbv}
            renderOption={(props, option) => (
              <li {...props} key={option.id}>
                {option.testFacilityAbv}
              </li>
            )}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            renderInput={(params) => (
              <TextField {...params} label="Facility" size="small" />
            )}
            value={selectedMethodFacility}
            onChange={(event, value) => onSelectMethodFacility(value)}
            disabled={!formik.values.method}
            sx={{ display: "inline-flex", width: "8rem" }}
          />

          <Autocomplete
            options={qcids}
            renderInput={(params) => (
              <TextField {...params} label="QCID" size="small" />
            )}
            value={formik.values.qcid}
            onChange={(event, value) => {
              setResultValues([]);
              formik.setFieldValue("qcid", value);
            }}
            disabled={!selectedSqcMethodSetup}
            sx={{ display: "inline-flex", width: "16rem" }}
          />

          <Autocomplete
            options={
              selectedSqcMethodSetup?.trackedComponents
                .filter((t) => !t.isInformational)
                .map((t) => t.resultComponentName) ?? []
            }
            renderInput={(params) => (
              <TextField {...params} label="Parameter" size="small" />
            )}
            value={formik.values.resultComponent}
            onChange={(event, value) => {
              setResultValues([]);
              formik.setFieldValue("resultComponent", value);
            }}
            disabled={!selectedSqcMethodSetup}
            sx={{ display: "inline-flex", width: "16rem" }}
          />

          <Autocomplete
            options={
              selectedSqcMethodSetup?.allowedTemperatures.map(
                (t) => t.temperature
              ) ?? []
            }
            getOptionLabel={(option) => option.toString()}
            renderInput={(params) => (
              <TextField {...params} label="Temperature (°C)" size="small" />
            )}
            value={formik.values.temperature}
            onChange={(event, value) => {
              setResultValues([]);
              formik.setFieldValue("temperature", value);
            }}
            disabled={
              !selectedSqcMethodSetup ||
              !selectedSqcMethodSetup.isTemperatureDependent
            }
            sx={{ display: "inline-flex", width: "12rem" }}
          />

          <Autocomplete
            multiple
            disableCloseOnSelect
            options={instruments}
            getOptionLabel={(option) => option.thisInstrument.name}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox style={{ marginRight: 8 }} checked={selected} />
                {option.thisInstrument.name}
              </li>
            )}
            isOptionEqualToValue={(option, value) =>
              option.instrumentID === value.instrumentID
            }
            renderInput={(params) => (
              <TextField
                {...params}
                label="Instruments"
                size="small"
                sx={{
                  "& .MuiInputBase-root": {
                    paddingLeft: "1rem !important",
                  },
                }}
              />
            )}
            renderTags={(selected) => {
              return selected.length === instruments.length
                ? "All"
                : selected.length > 1
                ? "Multiple"
                : selected[0].thisInstrument.name;
            }}
            value={selectedInstruments}
            onChange={(event, value) =>
              formik.setFieldValue(
                "instrumentIds",
                value.map((v) => v.instrumentID)
              )
            }
            disabled={!selectedSqcMethodSetup || !canFilterInstruments}
            sx={{
              display: "inline-flex",
              width: "14rem",
            }}
          />

          <TextField
            select
            size="small"
            label="Range"
            value={formik.values.range}
            onChange={(event) => onSelectRange(event.target.value as Range)}
            sx={{ width: "12rem" }}
          >
            <MenuItem value={RANGE.Date}>Date</MenuItem>
            <MenuItem value={RANGE.NumberOfPoints}>Number of Points</MenuItem>
          </TextField>

          {RANGE.Date === formik.values.range ? (
            <>
              <DesktopDatePicker
                value={formik.values.startDate}
                maxDate={formik.values.endDate}
                onChange={(value) => {
                  formik.setFieldValue("startDate", value);
                }}
                inputFormat="MM/dd/yyyy"
                label="Start Date"
                renderInput={(params) => (
                  <TextField onKeyDown={DatePickerKeyDownEvent} {...params} size="small" sx={{ width: "10rem" }} />
                )}
              />

              <DesktopDatePicker
                value={formik.values.endDate}
                minDate={formik.values.startDate}
                onChange={(value) => {
                  formik.setFieldValue("endDate", value);
                }}
                inputFormat="MM/dd/yyyy"
                label="End Date"
                renderInput={(params) => (
                  <TextField onKeyDown={DatePickerKeyDownEvent} {...params} size="small" sx={{ width: "10rem" }} />
                )}
              />
            </>
          ) : (
            <TextField
              type="number"
              name="numberOfPoints"
              label="Number of Points"
              size="small"
              value={formik.values.numberOfPoints ?? ""}
              onChange={formik.handleChange}
              sx={{ width: "10rem" }}
            />
          )}

          <LoadingButton
            type="button"
            variant="contained"
            size="small"
            onClick={handleSearch}
            startIcon={<ShowChartIcon />}
            loading={isLoadingResults}
            disabled={!formik.isValid}
          >
            Show QC Data
          </LoadingButton>

          <GlobalSecondaryButton
            variant='contained'
            size="small"
            onClick={() => resetFilter(true, true)}
          >
            Clear
          </GlobalSecondaryButton>
        </Stack>

        <Divider />

        <Stack direction="row" gap="1rem" flexWrap="wrap" alignItems="center">
          {/* <TextField
            type="number"
            name="deviationLimit"
            label="% Deviation Limit"
            size="small"
            value={formik.values.deviationLimit}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.deviationLimit &&
              Boolean(formik.errors.deviationLimit)
            }
            sx={{ width: "10rem" }}
            disabled
          /> */}

          <TextField
            type="number"
            name="ewmaLambda"
            label="EWMA Lambda"
            size="small"
            value={formik.values.ewmaLambda}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.ewmaLambda && Boolean(formik.errors.ewmaLambda)
            }
            inputProps={{
              step: 0.01,
            }}
            sx={{ width: "10rem" }}
          />

          <FormGroup row>
            <FormControlLabel
              control={<Switch />}
              label="Display Call-Outs"
              checked={formik.values.callOutsVisible}
              onChange={(e, value) =>
                formik.setFieldValue("callOutsVisible", value)
              }
            />

            <FormControlLabel
              control={<Switch />}
              label="Display Rule Violation Descriptions"
              checked={formik.values.ruleViolationsVisible}
              onChange={(e, value) =>
                formik.setFieldValue("ruleViolationsVisible", value)
              }
            />

            <FormControlLabel
              control={<Switch />}
              label="Include Excluded Points"
              checked={formik.values.excludedPointsVisible}
              onChange={(e, value) =>
                formik.setFieldValue("excludedPointsVisible", value)
              }
            />
          </FormGroup>
        </Stack>
      </Box>

      <Box
        sx={{
          height: "50rem",
          display: "grid",
          gridTemplateColumns: "2fr 1fr",
          gridTemplateRows: "2fr 1fr",
          gap: "0.5rem",
          "> *": {
            borderWidth: "1px",
            borderStyle: "solid",
            borderColor: (theme) => theme.palette.grey[300],
          },
        }}
      >
        <Card
          variant="outlined"
          sx={{
            display: "flex",
            gridColumn: "span 2",
          }}
        >
          <Chart
            title="Values"
            data={[
              {
                x: resultValues
                  .filter((r) => !r.ruleResult)
                  .map((r) => r.number),
                y: resultValues
                  .filter((r) => !r.ruleResult)
                  .map((r) => r.value),
                type: "scatter",
                mode: "markers",
                marker: { color: "blue" },
                name: "Data Points",
                hovertemplate: getHoverTemplate(
                  resultValues.filter((r) => !r.ruleResult),
                  formik.values.callOutsVisible,
                  formik.values.ruleViolationsVisible
                ),
              },
              {
                x: resultValues
                  .filter((r) => r.ruleResult === "warning")
                  .map((r) => r.number),
                y: resultValues
                  .filter((r) => r.ruleResult === "warning")
                  .map((r) => r.value),
                type: "scatter",
                mode: "markers",
                marker: { color: "purple" },
                name: "Warnings",
                hovertemplate: getHoverTemplate(
                  resultValues.filter((r) => r.ruleResult === "warning"),
                  formik.values.callOutsVisible,
                  formik.values.ruleViolationsVisible
                ),
              },
              {
                x: resultValues
                  .filter((r) => r.ruleResult === "violation")
                  .map((r) => r.number),
                y: resultValues
                  .filter((r) => r.ruleResult === "violation")
                  .map((r) => r.value),
                type: "scatter",
                mode: "markers",
                marker: { color: "red" },
                name: "Violations",
                hovertemplate: getHoverTemplate(
                  resultValues.filter((r) => r.ruleResult === "violation"),
                  formik.values.callOutsVisible,
                  formik.values.ruleViolationsVisible
                ),
              },
              currentLimit?.meanValue && currentLimit.sigmaValue
                ? {
                    x: resultValues.map((r) => r.number),
                    y: resultValues.map((r) => r.ewma),
                    mode: "lines",
                    marker: { color: "purple" },
                    name: "EWMA",
                    line: {
                      width: 0.5,
                    },
                  }
                : {},
            ]}
            shapes={
              currentLimit?.meanValue && currentLimit.sigmaValue
                ? [
                    {
                      type: "line",
                      x0: 0,
                      y0: currentLimit.meanValue,
                      x1: resultValues.length,
                      y1: currentLimit.meanValue,
                      line: {
                        color: "rgb(50, 171, 96)",
                        width: 2,
                        dash: "dash",
                      },
                      layer: "below",
                    },
                    {
                      type: "line",
                      x0: 0,
                      y0: currentLimit.meanValue + 2 * currentLimit.sigmaValue,
                      x1: resultValues.length,
                      y1: currentLimit.meanValue + 2 * currentLimit.sigmaValue,
                      line: {
                        color: "rgb(230, 235, 35)",
                        width: 2,
                        dash: "dash",
                      },
                      layer: "below",
                    },
                    {
                      type: "line",
                      x0: 0,
                      y0: currentLimit.meanValue - 2 * currentLimit.sigmaValue,
                      x1: resultValues.length,
                      y1: currentLimit.meanValue - 2 * currentLimit.sigmaValue,
                      line: {
                        color: "rgb(230, 235, 35)",
                        width: 2,
                        dash: "dash",
                      },
                      layer: "below",
                    },
                    {
                      type: "line",
                      x0: 0,
                      y0: currentLimit.meanValue + 3 * currentLimit.sigmaValue,
                      x1: resultValues.length,
                      y1: currentLimit.meanValue + 3 * currentLimit.sigmaValue,
                      line: {
                        color: "rgb(235, 165, 35)",

                        width: 2,
                        dash: "dash",
                      },
                      layer: "below",
                    },
                    {
                      type: "line",
                      x0: 0,
                      y0: currentLimit.meanValue - 3 * currentLimit.sigmaValue,
                      x1: resultValues.length,
                      y1: currentLimit.meanValue - 3 * currentLimit.sigmaValue,
                      line: {
                        color: "rgb(235, 165, 35)",
                        width: 2,
                        dash: "dash",
                      },
                      layer: "below",
                    },
                  ]
                : currentLimit?.upperControlLimitValue &&
                  currentLimit?.lowerControlLimitValue
                ? [
                    {
                      type: "line",
                      x0: 0,
                      y0: currentLimit.upperControlLimitValue,
                      x1: resultValues.length,
                      y1: currentLimit.upperControlLimitValue,
                      line: {
                        color: "rgb(235, 165, 35)",

                        width: 2,
                        dash: "dash",
                      },
                      layer: "below",
                    },
                    {
                      type: "line",
                      x0: 0,
                      y0: currentLimit.lowerControlLimitValue,
                      x1: resultValues.length,
                      y1: currentLimit.lowerControlLimitValue,
                      line: {
                        color: "rgb(235, 165, 35)",
                        width: 2,
                        dash: "dash",
                      },
                      layer: "below",
                    },
                  ]
                : []
            }
          />
        </Card>

        <Card variant="outlined">
          <Chart
            title="Percent Difference"
            data={[
              {
                x: resultValues.map((r) => r.number),
                y: resultValues.map((r) => r.percentageDifference),
                mode: "lines",
                marker: { color: "blue" },
                name: "% Diff",
              },
            ]}
          />
        </Card>

        <Card variant="outlined">
          <Chart
            title="Histogram"
            data={[
              {
                x: HISTOGRAM_CATEGORIES as unknown as string[],
                y: HISTOGRAM_CATEGORIES.map(
                  (h) =>
                    resultValues.filter((r) => r.histogramCategory === h).length
                ),
                type: "bar",
              },
            ]}
          />
        </Card>
      </Box>

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
        }}
      >
        <GlobalButton
          disabled={resultValues.length === 0}
          style={{ marginRight: "1rem" }}
          variant="contained"
          onClick={(e) =>
            exportToCsv(
              resultValues.map((item) => {
                return {
                  Num: item.number,
                  QCID: item.qcid,
                  Method: item.methodName,
                  "Temp (C)": item.temperature,
                  Date: convertDateFormat(item.analysisDate),
                  "Run Number": item.runNumber,
                  Analyst: item.analystEmail,
                  "Instrument ID": item.instrumentId,
                  Status: item.status,
                  Value: item.value,
                  "% Difference": item.percentageDifference,
                  EWMA: item.ewma,
                  Failures: item.failures,
                  Comments: item.comments,
                };
              }),
              "SQC Data"
            )
          }
        >
          Export CSV
        </GlobalButton>
      </Box>

      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Num</TableCell>
              <TableCell>QCID</TableCell>
              <TableCell>Method</TableCell>
              <TableCell>Temp (°C)</TableCell>
              <TableCell>Date</TableCell>
              <TableCell>Run Number</TableCell>
              <TableCell>Analyst</TableCell>
              <TableCell>Instrument ID</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Value</TableCell>
              <TableCell>% Difference</TableCell>
              <TableCell>EWMA</TableCell>
              <TableCell>Failures</TableCell>
              <TableCell>Comments</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Array.from(resultValues)
              .reverse()
              .map((resultValue, index) => {
                const optionStatus = sqcStatuses.find(s => s.statusName === resultValue.status);
                return (
                  <TableRow key={index}>
                    <TableCell>{resultValue.number}</TableCell>
                    <TableCell>{resultValue.qcid}</TableCell>
                    <TableCell>
                      {resultValue.methodName}/{resultValue.siteAbbreviation}
                    </TableCell>
                    <TableCell>{resultValue.temperature}</TableCell>
                    <TableCell>
                      {convertToLocalTime(resultValue.analysisDate)}
                    </TableCell>
                    <TableCell>{resultValue.runNumber}</TableCell>
                    <TableCell>{resultValue.analystEmail}</TableCell>
                    <TableCell>{resultValue.instrumentName}</TableCell>
                    <TableCell>{!canFilterInstruments ? resultValue.status : (
                      <Autocomplete
                        options={sqcStatuses}
                        getOptionLabel={(option) => option?.statusName}
                        renderInput={(params) => (
                          <TextField {...params} size="small" />
                        )}
                        value={optionStatus}
                        onChange={(event, value) => {
                          if (value) {
                            onChangeResult(resultValue.resultId, 'status', value?.statusName);
                          }
                        }}
                        sx={{ display: "inline-flex", width: "11.5rem" }}
                    />
                    )}</TableCell>
                    <TableCell>{resultValue.value}</TableCell>
                    <TableCell>{resultValue.percentageDifference}</TableCell>
                    <TableCell>{resultValue.ewma}</TableCell>
                    <TableCell>{resultValue.failures}</TableCell>
                    <TableCell>{!canFilterInstruments ? resultValue.comments : (
                          <TextField 
                            size="small" 
                            value={resultValue.comments} 
                            onChange={(event) => {
                              if (event.target.value) {
                                onChangeResult(resultValue.resultId, 'comments', event.target.value);
                              }
                            }}
                            sx={{ display: "inline-flex", width: "12rem" }}
                            inputProps={{ maxLength: 300 }}
                          />
                        )}</TableCell>
                  </TableRow>
                )
              } )}

            {resultValues.length === 0 && (
              <TableRow>
                <TableCell colSpan={14}>No data available</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {canFilterInstruments && <div style={{
        display: 'flex',
        alignItems: 'center',
        gap: '10px'
      }}>
        <GlobalButton
          disabled={sqcResultsToChange.length < 1 || isLoadingResultsChanges}
          style={{ width: "10rem" }}
          variant="contained"
          onClick={handleSaveResult}
        >
          Save points
        </GlobalButton>
        {isLoadingResultsChanges && <CircularProgress size={30} style={{textTransform: "none"}}></CircularProgress>}
      </div>}
      
    </Stack>
  );
}
