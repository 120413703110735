import API from "..";

export default class Project {
  /**
   * The Sample dimension table for M3
   * @param {String} SampleName The MIDAS of the Sample
   */
  constructor({
    id,
    name,
    description,
    ownerEmail,
    lastVerifiedDate,
    restrictedAccess,
    projectEndDate,
    isActive,
    restrictAtEndOfLife
  }) {
    this.id = id;
    this.name = name;
    this.description = description;
    this.ownerEmail = ownerEmail;
    this.lastVerifiedDate = lastVerifiedDate;
    this.restrictedAccess = restrictedAccess;
    this.projectEndDate = projectEndDate;
    this.isActive = isActive;
    this.restrictAtEndOfLife = restrictAtEndOfLife;
  }

  static async getAll() {
    const api = await API();
    const { data } = await api.get(`/project`);
    return data.result.map((d) => new Project(d));
  }
}
