import API from '../index'

export default class DistillationExperiment {
    constructor(
        id,
        mnemonic,
        chargeCode,
        comments,
        createdByEmail,
        createdDate,
        lastModifiedByEmail,
        lastModifiedDate,
        requestedByEmail,
        requestedCompletionDate,
        distillationStatusName,
        status,
        distillationTypeName,
        thisDistillationType,
        initialContainerID,
        initialContainer,
        initialSampleAmount,
        initialSampleAmountUoM,
        iSampleAmountUoM,
        distillationPriorityName,
        priority,
        startingAPI,
        experimentCuts,
        isRMCRequest,
        keywords,
        experimentDescription,
        atmosphericStillName, 
        vacuumStillName, 
        waterWeight,
        atmosphericWettage,
        vacuumWettage,
        correctionReason,
        atmosphericPotSizeL,
        vacuumPotSizeL,
        actualInitialSampleAmount,
        atmosphericStartDate, 
        atmosphericEndDate, 
        vacuumStartDate, 
        vacuumEndDate,
        extractionStartDate, 
        extractionEndDate,
        extrationUnit,
        assignedToEmail, 
        experimentLocationName, 
        experimentLocation

    ) {
        this.id = id
        this.mnemonic = mnemonic
        this.chargeCode = chargeCode
        this.comments = comments
        this.createdByEmail = createdByEmail
        this.createdDate = createdDate
        this.lastModifiedByEmail = lastModifiedByEmail
        this.lastModifiedDate = lastModifiedDate
        this.requestedByEmail = requestedByEmail
        this.requestedCompletionDate = requestedCompletionDate
        this.distillationStatusName = distillationStatusName
        this.status = status
        this.distillationTypeName = distillationTypeName
        this.thisDistillationType = thisDistillationType
        this.initialContainerID = initialContainerID
        this.initialContainer = initialContainer
        this.initialSampleAmount = initialSampleAmount
        this.initialSampleAmountUoM = initialSampleAmountUoM
        this.iSampleAmountUoM = iSampleAmountUoM
        this.distillationPriorityName = distillationPriorityName
        this.priority = priority
        this.experimentCuts = experimentCuts
        this.startingAPI = startingAPI
        this.isRMCRequest = isRMCRequest
        this.keywords = keywords
        this.experimentDescription = experimentDescription
        this.atmosphericStillName = atmosphericStillName
        this.vacuumStillName = vacuumStillName
        this.waterWeight = waterWeight
        this.atmosphericWettage = atmosphericWettage
        this.vacuumWettage = vacuumWettage
        this.correctionReason = correctionReason
        this.atmosphericPotSizeL = atmosphericPotSizeL
        this.vacuumPotSizeL = vacuumPotSizeL
        this.actualInitialSampleAmount = actualInitialSampleAmount
        this.atmosphericStartDate = atmosphericStartDate
        this.atmosphericEndDate = atmosphericEndDate
        this.vacuumStartDate = vacuumStartDate
        this.vacuumCompleteDate = vacuumEndDate
        this.extractionStartDate = extractionStartDate
        this.extractionEndDate = extractionEndDate
        this.extrationUnit = extrationUnit
        this.assignedToEmail = assignedToEmail
        this.experimentLocationName = experimentLocationName
        this.experimentLocation = experimentLocation
    }

    static async getAllExperiments() {
        const api = await API();
        const { data } = await api.get('/distillationexperiment');
        return data.result;
    }

    static async getOpenExperiments() {
        const api = await API();
        const { data } = await api.get('/distillationexperiment/openExperiments');
        return data;
    }

    static async getCompletedExperiments() {
        const api = await API();
        const { data } = await api.get('/distillationexperiment/completedExperiments');
        return data;
    }
    
    static async getExperimentById(experimentID) {
        const api = await API();
        const { data } = await api.get(`/distillationexperiment/${experimentID}`);
        return data
    }

    static async quickSearch(searchCriteria) {
        const api = await API();
        const { data } = await api.get(`distillationexperiment/quickSearch?searchCriteria=${searchCriteria}`);
        return data;
      }

    static async createExperiment(info) {
        const api = await API();
        const { data } = await api.post('/distillationexperiment', info);
        return data;
        // if (data.message === 'Success') {
        //     return new DistillationExperiment(data.result);
        // } else {
        //     return null
        // }
    }

    static async updateExperiment(experiment) {
        const api = await API();
        const { data } = await api.put('/distillationexperiment', experiment);
        return data
        // if (data.message === 'Success') {
        //     return new DistillationExperiment(data.result);
        // } else {
        //     return null
        // }
    }

    static async StartExperiment(experiment) {
        const api = await API();
        const { data } = await api.put('/distillationexperiment/startexperiment', experiment);
        return data;        
    }

    static async SubmitExperimentResults(experiment) {
        const api = await API();
        const { data } = await api.put('/distillationexperiment/submitExperimentResults', experiment);
        return data;        
    }

    static async SubmitExperimentContainersAndTests(distillationSubmissionDTO) {
        const api = await API();
        const { data } = await api.post('/distillationexperiment/submitTestingForExperiment', distillationSubmissionDTO);
        return data;        
    }

    static async UpdateStatus(experimentID, newStatus) {
        const api = await API();
        const { data } = await api.put(`/distillationexperiment/updateExperimentStatus?experimentID=${experimentID}&newStatus=${newStatus}`);
        return data;        
    }
}