import API from "..";

export default class BatchStatus {

  constructor({
    batchStatusName,
    isActive,
    displayOrder
}) 
    
  {
    this.batchStatusName = batchStatusName;
    this.isActive = isActive;
    this.displayOrder = displayOrder;
  }

  static async getAllBatchStatuses() {
    const api = await API();
    const { data } = await api.get(`/batchStatus`);
    if (data.result) {
        return data.result.map((d) => new BatchStatus(d));
    }
    else {
        return [];
    }
  }
}
