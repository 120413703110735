import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { useFormik } from "formik";
import { testParameterSchema } from "../shared";
import type { TestParameter } from "../types";

type TestParameterModalProps = {
  initialValues: TestParameter;
  isModalOpen: boolean;
  setModalOpen(newValue: boolean): void;
  parameterList: string[];
  add(newValue: TestParameter): void;
};

export default function TestParameterModal({
  initialValues,
  isModalOpen,
  setModalOpen,
  parameterList,
  add,
}: TestParameterModalProps) {
  const formik = useFormik({
    initialValues,
    validationSchema: testParameterSchema,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: async (values) => add(values),
  });

  return (
    <Dialog
      fullWidth={true}
      maxWidth="sm"
      open={isModalOpen}
      onClose={() => setModalOpen(false)}
    >
      <DialogTitle>Test Parameter</DialogTitle>
      <DialogContent>
        <Box
          display="grid"
          gap={2}
          gridTemplateColumns="1fr 1fr"
          style={{ marginTop: "1rem" }}
        >
          <Autocomplete
            id="testParameterName"
            options={parameterList}
            renderInput={(params) => (
              <TextField {...params} label="Parameter" size="small" />
            )}
            onChange={(_, v) => {
              formik.setFieldValue("testParameterName", v);
            }}
            value={formik.values.testParameterName}
            size="small"
          />

          <TextField
            name="parameterValue"
            id="parameterValue"
            label="Value"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.parameterValue}
            size="small"
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button disabled={!formik.isValid} onClick={() => formik.submitForm()}>
          add
        </Button>
      </DialogActions>
    </Dialog>
  );
}
