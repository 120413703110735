import { TextField, Box, TableRow, RadioGroup, Radio, FormControlLabel, FormControl, IconButton, Autocomplete } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import React, { useState, useEffect } from "react";
import { UXDataTableWithoutBody, UXDataTable, StyledTableBody, StyledTableCell, UXDataTableNested } from "../../../components/UXDataTable";
import { formatMidasNumber, ConvertUOMs, RoundNumber, globalMessages, GetRequiredSampleSize, GetRequiredSampleSizeUoM } from "../../../global";
import { styled } from "@mui/material/styles";
import { GlobalTabCollection,  GlobalSecondaryButton, StyledTab } from "../../../pages/styles";
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import EmailAddressTextField from "../../../components/EmailAddressField";
import LocateContainer from "./LocateContainer";
import BillingInfoField from "../../../components/BillingInfoField";
import ContainerTable from "../../../components/ContainerTable";
import HelpIcon from '@mui/icons-material/Help';
import ModalMessages from "../../../components/Modal/ModalSimpleButton";

const midasColHeaders = ['MIDAS Number', 'Locate Container', 'Req. Size', 'Parent Container', 'Container Amt.', 'Sample Description']
const midasColHeaders_Existing = ['MIDAS Number', 'Options', 'Sample Description']
const existingColHeaders = ['Method', 'Container Options','Locate Container', 'Container', 'Req. Size', 'Container Amt.']

const methodSummaryColHeaders = [
  { field: 'method.name', headerName: 'Method', type: 'object' },
  { field: 'method.description', headerName: 'Method Description', type: 'object' },
  { field: 'methodFacility.testFacilityAbv', headerName: 'Facility', type: 'object' },
  { field: 'methodFacility.requiredSampleSize', headerName: 'Test Size', type: 'object' },
  { field: 'methodFacility.requiredSampleSizeUoM', headerName: 'UoM', type: 'object' },
  { field: 'method.packageTestRequiredAmount', headerName: 'Package Size', type: 'object' },
  { field: 'method.packageTestRequiredAmountUoMName', headerName: 'UoM', type: 'object' },
  { field: 'conditionOffering', headerName: 'Condition', type: 'object-condition' },
  { field: 'estimate', headerName: 'Estimate', type: 'label' },
  { field: 'testPriority.testPriorityName', headerName: 'Priority', type: 'object' },
  { field: 'requestedCompletionDate', headerName: 'Req. Completion Date', type: 'date' }
];

const StyledP = styled('p')({
  color:"#000000DE",
  fontFamily:"EMprint",
  fontWeight:"600",
  fontSize: "16px",
  paddingTop:"1rem",
  paddingLeft:".6rem",
  paddingBottom:"1rem"
})

const StyledDiv = styled('div')({
  display:"flex", 
  marginTop:"5px"
});

const TestSummary = ({mySampleInfo, setMySampleInfo, errorChecks, setErrorChecks, availableLocations, availableUOMs, containerTypes, 
                      containerErrorChecks, setContainerErrorChecks, tabValue, setTabValue, setTriggerContainerReload}) => {

  const [sampleRowOpen, setSampleRowOpen] = useState([])

  const [minSampleSize, setMinSampleSize] = useState(0)
  const [masterUoM, setMasterUoM]  = useState()

  const [modalLocateContainerOpen, setModalLocateContainerOpen] = useState(false)
  const [updateAllMethods, setUpdateAllMethods] = useState(false)
  const [methodToUpdate, setMethodToUpdate] = useState({methodName:'', facility:''})

  const [sampleNameToLocateContainers, setSampleNameToLocateContainers] = useState(null)

  const [modalMessagesOpen, setModalMessagesOpen] = useState(false);
  const modalMessagesButtonText = 'Ok'
  const [modalMessagesTitle, setModalMessagesTitle] = useState('');
  const [modalMessagesText, setModalMessagesText] = useState('');

  function closeModalMessages() {
    setModalMessagesOpen(false);
  }

  function openModalMessages(title, text) {
    setModalMessagesOpen(true);
    setModalMessagesTitle(title);
    setModalMessagesText(text);
  }

  //get all the locations
  useEffect(() => {
    if (availableUOMs.length === 0)
     return

    let tempMasterUoM =  availableUOMs.find(e => e.uoMName === GetRequiredSampleSizeUoM(mySampleInfo.masterMethods[0]))
    let currentUOM
    let tempSampleSize = 0

    mySampleInfo.masterMethods.forEach(oMethod => {
      currentUOM = availableUOMs.find(e => e.uoMName === GetRequiredSampleSizeUoM(oMethod))
      tempSampleSize = tempSampleSize + ConvertUOMs(null, null, GetRequiredSampleSize(oMethod), currentUOM, tempMasterUoM)
    });

    setMinSampleSize(RoundNumber(tempSampleSize, 2))
    setMasterUoM(tempMasterUoM)

  }, [mySampleInfo.masterMethods, availableUOMs])

  const UpdateContainerManagementType = (e) => {
    let copyMySampleInfo = JSON.parse(JSON.stringify(mySampleInfo))

    for(var x = 0; x < copyMySampleInfo.samples[tabValue].methods.length; x++)
    {
      copyMySampleInfo.samples[tabValue].methods[x].createFromContainer = null
      copyMySampleInfo.samples[tabValue].methods[x].containerSelectionType = null
    }

    copyMySampleInfo.samples[tabValue].containerManagementType = e.target.value
    setMySampleInfo(copyMySampleInfo)

    setTriggerContainerReload(true)
  };

  const handleSampleRowClick = (midasNumber) => {
    const sampleRowOpenIndex = sampleRowOpen.indexOf(midasNumber);
    let newSampleRowOpen = [];

    if (sampleRowOpenIndex === -1) {
      newSampleRowOpen = newSampleRowOpen.concat(sampleRowOpen, midasNumber);
    } else if (sampleRowOpenIndex === 0) {
      newSampleRowOpen = newSampleRowOpen.concat(sampleRowOpen.slice(1));
    } else if (sampleRowOpenIndex === sampleRowOpen.length - 1) {
      newSampleRowOpen = newSampleRowOpen.concat(sampleRowOpen.slice(0, -1));
    } else if (sampleRowOpenIndex > 0) {
      newSampleRowOpen = newSampleRowOpen.concat(
        sampleRowOpen.slice(0, sampleRowOpenIndex),
        sampleRowOpen.slice(sampleRowOpenIndex + 1)
      );
    }
    setSampleRowOpen(newSampleRowOpen);
  };
 
  const isSampleRowOpen = (midasNumber) => sampleRowOpen.indexOf(midasNumber) !== -1;

  const UpdateMethodContainering = (value, methodInfo, property, updateAllMethods) => {
    let copyMySampleInfo = JSON.parse(JSON.stringify(mySampleInfo))
   
    if(updateAllMethods)
    {
      for(var i = 0; i < copyMySampleInfo.samples[tabValue].methods.length; i++)
      {
        copyMySampleInfo.samples[tabValue].methods[i].createFromContainer = value
      }
    } else {
      let methodIndex = copyMySampleInfo.samples[tabValue].methods.findIndex(s => s.method.name === methodInfo.methodName && s.methodFacility.testFacilityAbv === methodInfo.facility && (s.conditionOffering ? s.conditionOffering.conditionOfferingName === methodInfo.conditionOfferingName : true))

      copyMySampleInfo.samples[tabValue].methods[methodIndex][property] = value
    }

    setMySampleInfo(copyMySampleInfo)

    setTriggerContainerReload(true)
  }

  function UpdateContainerList (containers) 
  {
    const newSample = structuredClone(mySampleInfo)

    newSample.samples[tabValue].containers = containers

    setMySampleInfo(newSample)
  }

  const AutoCompletePropertyUpdate = (value, property) => {
    const copyMySampleInfo = JSON.parse(JSON.stringify(mySampleInfo))
    const copyErrors = JSON.parse(JSON.stringify(errorChecks))

    copyMySampleInfo[property] = value
    copyErrors[property] = value === null ? true : false 

    //set all the container return locations
    if (property === 'returnLocation')
    {
      for (let x = 0; x < copyMySampleInfo.samples.length; x++) {
        for (let index = 0; index < copyMySampleInfo.samples[x].containers.length; index++) {
          copyMySampleInfo.samples[x].containers[index].returnLocation = value
        }
      }   
    }

    setMySampleInfo(copyMySampleInfo)
    setErrorChecks(copyErrors)
  }; 

  function SetSampleProperty (value, property)
  {
      const copyMySampleInfo = JSON.parse(JSON.stringify(mySampleInfo))

      copyMySampleInfo[property] = value

      if (property === 'requesterEmail' || property === 'chargeCode')
      {
        //set all the containers that share these properties
        for (let x = 0; x < copyMySampleInfo.samples.length; x++) {
          for (let index = 0; index < copyMySampleInfo.samples[x].containers.length; index++) {
            for (let z = 0; z < copyMySampleInfo.samples[x].containers[index].tests.length; z++) {
              copyMySampleInfo.samples[x].containers[index].tests[z][property] = value
            }
          }
        }        
      }

      setMySampleInfo(copyMySampleInfo)
  }

  function updateErrorChecks (property, value)
  {
      const copyErrors = JSON.parse(JSON.stringify(errorChecks))

      copyErrors[property] = value

      setErrorChecks(copyErrors)
  }

  function updateContainerErrorChecks(oErrors)
  {
    const copyContainerErrors = JSON.parse(JSON.stringify(containerErrorChecks))

    copyContainerErrors[tabValue].containerErrorInfo = oErrors
    setContainerErrorChecks(copyContainerErrors)
  }

  function PrepareToLocateContainers(isOpen, sampleName, updateAllMethods, methodInfo)
  {
    setModalLocateContainerOpen(isOpen)
    setSampleNameToLocateContainers(sampleName)
    setUpdateAllMethods(updateAllMethods)
    setMethodToUpdate(methodInfo)
  }

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  function GetFilteredContainers (containers) {

    return containers.filter(myContainer => myContainer.containerStatusName === 'Confirmed').map(filteredContainer => filteredContainer)


    //check if everything is EXT test, if so we show Shipped containers
    // let onlyEXTFacilities = true
    // let iTest = 0

    // while (onlyEXTFacilities === true && iTest < mySampleInfo.masterMethods.length) {

    //   if (mySampleInfo.masterMethods[iTest].methodFacility.testFacilityAbv !== 'EXT')
    //   {
    //     onlyEXTFacilities = false;
    //   }

    //   iTest += 1
    // }

    // if (onlyEXTFacilities === true)
    // {
    //   return containers.filter(myContainer => myContainer.containerStatusName === 'Confirmed' || myContainer.containerStatusName === 'Shipped').map(filteredContainer => filteredContainer)
    // } else {
    //   return containers.filter(myContainer => myContainer.containerStatusName === 'Confirmed').map(filteredContainer => filteredContainer)
    // }
  }

  return (
<>
  <Box component="form"
        sx={{
          '& .MuiTextField-root': { m: 1 }, //width: '25ch'
        }}
          style={{
            backgroundColor: "white",
          }}
        >

          <StyledP>Sample Information</StyledP>

          {mySampleInfo.samples.map((oSample, index) => {
            return (
              <div key={"SampleInfo" + index}>

              <TextField size="small"
                style={{
                  id: "outlined-normal",
                  width: "12%",
                }}
                disabled
                label = "MIDAS Number"
                variant="outlined"
                id="MIDASNumber"
                margin="normal"
                value={formatMidasNumber(oSample.sampleObject.sampleName)}
                ></TextField>

              <TextField size="small"
                style={{
                  id: "outlined-normal",
                  width: "25%",
                }}
                  disabled
                  label = "Sample Description"
                  variant="outlined"
                  id="SampleDescription"
                  margin="normal"
                  value={oSample.sampleObject.description}
                  ></TextField>

              <TextField size="small"
                style={{
                  id: "outlined-normal",
                  width: "20%",
                }}
                  disabled
                  label = "Chem ID"
                  variant="outlined"
                  id="ChemID"
                  margin="normal"
                  value={oSample.sampleObject.chemID}
                  ></TextField>

              <TextField size="small"
                style={{
                  id: "outlined-normal",
                  width: "20%",
                }}
                  disabled
                  label = "Sample Owner"
                  variant="outlined"
                  id="SampleOwner"
                  margin="normal"
                  value={oSample.sampleObject.sampleOwnerEmail}
                  ></TextField>
              </div>
              )
            })}

          <StyledP>Method Information</StyledP>

            <UXDataTable tableWidth='80%' cols={methodSummaryColHeaders} rows={mySampleInfo.masterMethods} moreOptionsCell={false} enablePaging={false} 
                        noDataMessage={"No Methods Found"} menuProps={null} defaultRowsPerPage={100} isDataLoading={false} tableName={'testSummary'} enableSorting={false}></UXDataTable>

          <StyledP>Owner, Return and Billing</StyledP>
          <div style={{display:"flex"}}>
            <BillingInfoField
              billingValue={mySampleInfo.chargeCode}
              handleChange={value => {
                AutoCompletePropertyUpdate(value, 'chargeCode');
              }}
              isDisabled={false}
              margin="normal"
              marginLeft="0px"
              marginRight="1rem"
              fieldWidth="25rem"
              hasErrors={errorChecks.chargeCode === null ? false : errorChecks.chargeCode}
              helperText="JADE is Required"       
              required        
            >
            </BillingInfoField>

            <Autocomplete
              disablePortal
              options={availableLocations}
              noOptionsText={"Loading Locations..."}
              onChange={(event, value) => {
                AutoCompletePropertyUpdate(value, 'returnLocation');
              }}
              getOptionLabel={(option) => option.locationName}
              value={mySampleInfo.returnLocation}
              isOptionEqualToValue={(option, value) => value.locationName === option.locationName}
              autoHighlight
              autoSelect
              style={{ fontFamily:"EMprint", fontSize:"16px"}}
              renderInput={(params) => <TextField {...params}  label='* Choose a Return Location' style={{width:"25rem"}} margin="normal" variant="outlined" size="small" 
                error = {errorChecks.returnLocation === null ? false : errorChecks.returnLocation}  
                helperText = {errorChecks.returnLocation ? 'Location Required' : ''}
                InputLabelProps={{shrink: true}} InputProps={{ ...params.InputProps }}/>}
              /> 

            <EmailAddressTextField
              fontSize = {16}
              fieldWidth = {"25rem"} 
              validatedUserEmail = {mySampleInfo.requesterEmail}
              setValidatedUserEmail = {(e) => SetSampleProperty(e, "requesterEmail")}
              hasErrors = {errorChecks.requesterEmail === null ? false : errorChecks.requesterEmail}
              setHasErrors = {(e) => updateErrorChecks('requesterEmail', e)}
              isDisabled={false}
              labelText="Requester Email"
              placeholderText="Requester Email"
              showPlusMeButton={true}
              >
            </EmailAddressTextField>

            <GlobalSecondaryButton  name="btnMe"
              style={{ marginTop:"15px", marginLeft:"1rem"}}
              onClick={e => {setMySampleInfo(() => ({
                ...mySampleInfo,
                requesterEmail: mySampleInfo.samples[0].sampleObject.sampleOwnerEmail
              })); updateErrorChecks('requesterEmail', false)}}
            >+Sample Owner</GlobalSecondaryButton>
          </div>      

          {/* onClick={() => {handleCloseMenu(); SetBatchStatus(myStudy.blendRequests[tabValue].batches[tabValueBatch].id, DetermineNewStateToSet())}} */}

          <StyledP>Container Management</StyledP>

          <StyledDiv>
            <GlobalTabCollection value={tabValue} onChange={handleTabChange} variant={"scrollable"} scrollButtons="auto" aria-label='ant example'>
              {mySampleInfo.samples.map((oSample, index) => {
                  return(
                      <StyledTab key={index}
                      label={`${oSample.sampleName}`} />
                  )
              })}
                
            </GlobalTabCollection>
          </StyledDiv>          

          <p style={{
            paddingTop:"16px"
          }}></p>

          <FormControl>
            <RadioGroup
              rows="true"
              aria-labelledby="row-radio-buttons-group-label"
              name="container-radio-buttons-group"
            >
              <div style={{display:"flex"}}>
                <FormControlLabel value="nonsplit" control={<Radio checked={mySampleInfo.samples[tabValue].containerManagementType === 'nonsplit'} onChange={UpdateContainerManagementType}/>} label="Make NonSplit" />
                <HelpIcon style={{marginTop:".5rem", color:"blue"}} onClick={(e) => openModalMessages("Container Info", globalMessages.NonSplitMsg)}></HelpIcon>
              </div>

              <div style={{display:"flex"}}>
                <FormControlLabel value="midas" control={<Radio checked={mySampleInfo.samples[tabValue].containerManagementType === 'midas'} onChange={UpdateContainerManagementType}/>} label="MIDAS Recommendation" />
                <HelpIcon style={{marginTop:".5rem", color:"blue"}} onClick={(e) => openModalMessages("Container Info", globalMessages.MidasMsg)}></HelpIcon>
              </div>
              <div style={{display:"flex"}}>
                <FormControlLabel value="existing" control={<Radio checked={mySampleInfo.samples[tabValue].containerManagementType === 'existing'}  onChange={UpdateContainerManagementType} />} label="Existing Container" />
                <HelpIcon style={{marginTop:".5rem", color:"blue"}} onClick={(e) => openModalMessages("Container Info", globalMessages.existingMsg)}></HelpIcon>
              </div>
            </RadioGroup>
          </FormControl> 

          <p style={{
            paddingTop:"16px"
          }}></p>

          {mySampleInfo.samples[tabValue].containerManagementType === 'existing' ?
            <UXDataTableWithoutBody 
            tableWidth='60%' 
            cols={midasColHeaders_Existing} 
            blankFirstHeader={false}
            tableId="containerTable"
            rowLength={mySampleInfo.samples ? mySampleInfo.samples.length : 0}
            enablePaging={false}
            rowsPerPage={0}
            page={0}
            handleChangePage={null}
            handleChangeRowsPerPage={null}
            noDataFoundMessage={'No samples found'}
            enableAddIcon={false}
            addFunction={null}
            addToolTipText={null}
            isDataLoading={false}  
            >

              {/* {mySampleInfo.samples.map((oSample, sampleIndex) => {
                  return( */}
                    <StyledTableBody key={"SamplesTable" + mySampleInfo.samples[tabValue].sampleName}>
                        <TableRow> 
                            <StyledTableCell>{formatMidasNumber(mySampleInfo.samples[tabValue].sampleName)}</StyledTableCell>
                            <StyledTableCell>View
                                <IconButton
                                aria-label='expand row'
                                size='small'
                                onClick={() => handleSampleRowClick(mySampleInfo.samples[tabValue].sampleName)}>
                                {isSampleRowOpen(mySampleInfo.samples[tabValue].sampleName) ? (
                                  <KeyboardArrowUpIcon/>
                                ) : (
                                  <KeyboardArrowDownIcon/>
                                )}
                                </IconButton>
                            </StyledTableCell>
                            <StyledTableCell>{mySampleInfo.samples[tabValue].sampleObject.description}</StyledTableCell>
                        </TableRow>

                      <UXDataTableNested 
                        tableWidth={'100%'} 
                        cols={existingColHeaders} 
                        blankFirstHeader={false} 
                        colSpan={midasColHeaders_Existing.length + 1} 
                        isOpenComparator={mySampleInfo.samples[tabValue].sampleName} 
                        isOpenArray={sampleRowOpen}>

                                <StyledTableBody>
                                  {mySampleInfo.samples[tabValue].methods !== null ? mySampleInfo.samples[tabValue].methods.map((methodObj, methodIndex) => (
                                      
                                    <TableRow key={mySampleInfo.samples[tabValue].sampleName + methodIndex}>
                                      <StyledTableCell>{methodObj.method.name}</StyledTableCell>

                                      <StyledTableCell align="left" style={{verticalAlign:'top'}}>   
                                        <Autocomplete
                                            disablePortal
                                            options={["Create From Parent", "Existing Container"]}
                                            onChange = {(e, value) => UpdateMethodContainering(value, {methodName: methodObj.method.name, facility: methodObj.methodFacility.testFacilityAbv, conditionOfferingName: methodObj.conditionOffering?.conditionOfferingName}, 'containerSelectionType', false)}
                                            getOptionLabel={(option) => option}
                                            value={methodObj.containerSelectionType}
                                            isOptionEqualToValue={(option, value) => value === option}
                                            style={{ fontFamily:"EMprint", fontSize:"16px", verticalAlign:'top'}}
                                            renderInput={(params) => <TextField {...params} style={{width:"230px"}} variant="outlined" size="small" error = {methodObj.containerSelectionError} InputProps={{ ...params.InputProps }}/>}/> 
                                        </StyledTableCell>

                                          {/* Locate Container */}
                                          <StyledTableCell align='center' style={{width:"150px"}}>
                                            <ManageSearchIcon 
                                              onClick={() => PrepareToLocateContainers(true, mySampleInfo.samples[tabValue].sampleName, false, {methodName: methodObj.method.name, facility: methodObj.methodFacility.testFacilityAbv})}
                                            >
                                            </ManageSearchIcon>
                                          </StyledTableCell>

                                        <StyledTableCell align="left" style={{verticalAlign:'top'}}>   
                                        <Autocomplete
                                            disablePortal
                                            options={GetFilteredContainers(mySampleInfo.samples[tabValue].sampleObject.containers)}
                                            //options={mySampleInfo.samples[tabValue].sampleObject.containers.filter(myContainer => myContainer.containerStatusName === 'Confirmed').map(filteredContainer => filteredContainer)}
                                            onChange = {(e, value) => UpdateMethodContainering(value, {methodName: methodObj.method.name, facility: methodObj.methodFacility.testFacilityAbv, conditionOfferingName: methodObj.conditionOffering?.conditionOfferingName}, 'createFromContainer', false)}
                                            getOptionLabel={(option) => `${option.containerNumber.toString()} - ${option.containerStatusName}`}
                                            //getOptionLabel={(option) => option.containerNumber.toString()}
                                            value={methodObj.createFromContainer}
                                            isOptionEqualToValue={(option, value) => value.containerNumber === option.containerNumber}
                                            style={{ fontFamily:"EMprint", fontSize:"16px", verticalAlign:'top'}}
                                            renderInput={(params) => <TextField {...params} style={{width:"200px"}} variant="outlined" size="small" error = {methodObj.containerError} InputProps={{ ...params.InputProps }}/>}/> 
                                        </StyledTableCell>

                                        <StyledTableCell>
                                          {RoundNumber(GetRequiredSampleSize(methodObj), 2)} {GetRequiredSampleSizeUoM(methodObj)}                           
                                        </StyledTableCell>

                                        <StyledTableCell>
                                          {methodObj.createFromContainer ? methodObj.createFromContainer.currentAmount : 'N/A'} {methodObj.createFromContainer ? methodObj.createFromContainer.containerSizeUoM : ''}
                                        </StyledTableCell> 

                                    </TableRow>  

                                )):(  <TableRow>
                                  <StyledTableCell colSpan='8'>
                                    <p>No Methods Found</p>
                                  </StyledTableCell>
                                </TableRow>)}
                    </StyledTableBody>
                  </UXDataTableNested>
                  </StyledTableBody>
                                {/* )
                                })} */}

            </UXDataTableWithoutBody>
            : 
            <UXDataTableWithoutBody 
              tableWidth='70%' 
              cols={midasColHeaders} 
              blankFirstHeader={false}
              tableId="containerTableNew"
              rowLength={mySampleInfo.samples ? 1 : 0}
              enablePaging={false}
              rowsPerPage={0}
              page={0}
              handleChangePage={null}
              handleChangeRowsPerPage={null}
              noDataFoundMessage={'No containers found'}
              enableAddIcon={false}
              addFunction={null}
              addToolTipText={null} 
              isDataLoading={false}  
            >

              <StyledTableBody key={"myTablebody"}>
                <TableRow
                  hover
                  key={`custom-row-${mySampleInfo.samples[tabValue].sampleName}`}>

                  <StyledTableCell>{formatMidasNumber(mySampleInfo.samples[tabValue].sampleName)}</StyledTableCell>

                  {/* Locate Container */}
                  <StyledTableCell align='center' style={{width:"150px"}}>
                    <ManageSearchIcon 
                      onClick={() => PrepareToLocateContainers(true, mySampleInfo.samples[tabValue].sampleName, true, null)}
                      >
                    </ManageSearchIcon>
                  </StyledTableCell>

                  <StyledTableCell>
                    {`${minSampleSize} ${masterUoM ? masterUoM.uoMName : '???'}`}
                  </StyledTableCell>

                  <StyledTableCell>
                    <Autocomplete
                      disablePortal
                      noOptionsText={"Loading Containers..."}
                      options={GetFilteredContainers(mySampleInfo.samples[tabValue].sampleObject.containers)}
                      onChange = {(e, value) => UpdateMethodContainering(value, null, 'createFromContainer', true)}
                      getOptionLabel={(option) => `${option.containerNumber.toString()} - ${option.containerStatusName}`}
                      value={mySampleInfo.samples[tabValue].methods[0].createFromContainer}
                      isOptionEqualToValue={(option, value) => value.containerNumber === option.containerNumber}
                      style={{ fontFamily:"EMprint", fontSize:"16px", verticalAlign:'top'}}
                      renderInput={(params) => <TextField {...params} style={{width:"200px"}} variant="outlined" size="small" InputProps={{ ...params.InputProps }}/>}/> 
                  </StyledTableCell>

                  <StyledTableCell>{mySampleInfo.samples[tabValue].methods[0].createFromContainer ? `${mySampleInfo.samples[tabValue].methods[0].createFromContainer?.currentAmount} ${mySampleInfo.samples[tabValue].methods[0].createFromContainer?.containerSizeUoM}` : ''}</StyledTableCell>
                  <StyledTableCell>{mySampleInfo.samples[tabValue].sampleObject.description}</StyledTableCell>

                </TableRow> 
              </StyledTableBody>
            </UXDataTableWithoutBody>
          }
  </Box>

    <LocateContainer
        open={modalLocateContainerOpen} 
        setOpen={setModalLocateContainerOpen} 
        incomingUoMObject={masterUoM}
        midasNumber={sampleNameToLocateContainers}
        setSelectedContainer={UpdateMethodContainering}
        updateAllMethods={updateAllMethods}
        methodInfo={methodToUpdate}
      >
      </LocateContainer>

  <StyledP>Containers to Create</StyledP>

  <div style={{paddingBottom: "2rem"}}>
    <ContainerTable
      width = {'95%'}
      showParentContainer = {false}
      showTests = {true}
      containers ={mySampleInfo.samples[tabValue].containers}
      setContainers = {UpdateContainerList} 
      containerErrorChecks = {containerErrorChecks[tabValue].containerErrorInfo}
      setContainerErrorChecks = {updateContainerErrorChecks}
      enabledAdditions = {false} 
      enableDeletions = {false}
      enableCopy = {false}
      availableParentContainers = {null}
      sampleObject = {mySampleInfo.samples[tabValue].sampleObject}
      substanceObject = {mySampleInfo.samples[tabValue].sampleObject.substance ? mySampleInfo.samples[tabValue].sampleObject.substance : null}
      lockContainerStatus={true}
      newContainerDefaultOwner = {null}
    >
    </ContainerTable>
  </div>

    <ModalMessages title={modalMessagesTitle} buttonText={modalMessagesButtonText} buttonAction={closeModalMessages} open={modalMessagesOpen} setOpen={setModalMessagesOpen}>
      <label>
        {modalMessagesText}
      </label>
    </ModalMessages>
</>

  );
};
export default TestSummary;
