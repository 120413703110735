import React, { useState, useContext, useEffect } from "react";
import { Box } from "@mui/material";
import { RequestsReport } from "./components/RequestsReport";
import { TwoTwoFiveReport } from "./components/TwoTwoFiveReport";

import { ChargesReport } from "./components/ChargesReport";
import { StkTrackerReport } from "./components/StkTrackerReport";
import { OrderFreqReport } from "./components/OrderFreqReport";
import { InventoryItemContext } from "./context/InventoryItemContext";
import { POSearchReport } from "./components/POSearchReport";
import { GlobalTabCollection, StyledTab } from "../../pages/styles";


const SRReports = ({canEdit}) => {
  const [tabValue, setTabValue] = useState(0);

  const {
    getAllStockItems,
    stockItems,

  } = useContext(InventoryItemContext);

  useEffect(() => {
    if (!stockItems.length) getAllStockItems();
  }, [stockItems, getAllStockItems]);

  return (
    <Box>
      <Box>
        <Box sx={{ bgcolor: "#fff", pt:3, pb:1 }}>
          <GlobalTabCollection
            value={tabValue}
            onChange={(_, newValue) => setTabValue(newValue)}
          >
            <StyledTab label="Requests" />
            <StyledTab label="Inventory Reports" />
            <StyledTab label="Charges" />
            <StyledTab label='Stk Tracker' />
            <StyledTab label='Order Freq' />
            <StyledTab label='PO Search' />
            {/* <StyledTab label='Inv Adj' />
            <StyledTab label='JV Upload' />
            <StyledTab label='JV Details' /> */}
          </GlobalTabCollection>
          <Box sx={{ p: 3 }} />
        </Box>
        {tabValue === 0 && <RequestsReport canEdit={canEdit} />}
        {tabValue === 1 && !!stockItems.length && (
          <TwoTwoFiveReport stockItems={stockItems} canEdit={canEdit}/>
        )}
        {tabValue === 2 && <ChargesReport canEdit={canEdit}/>}
        {tabValue === 3 && <StkTrackerReport canEdit={canEdit}/>}
        {tabValue === 4 && <OrderFreqReport canEdit={canEdit}/>}
        {tabValue === 5 && <POSearchReport canEdit={canEdit}/>}
      </Box>
    </Box>
  );
};

export default SRReports;
