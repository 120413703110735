import Box from "@mui/material/Box";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import { visuallyHidden } from "@mui/utils";
import type { TableColumns } from "../type";

type SortableTableHeadProps<T, K extends keyof T> = {
  columns: TableColumns<T, K>;
  onRequestSort: (event: React.MouseEvent<unknown>, property: K) => void;
  order: "desc" | "asc";
  orderBy?: K;
  selectable?: boolean;
  selectAll?(event: React.ChangeEvent<HTMLInputElement>): void;
  isAnySelected?: boolean;
  areAllSelected?: boolean;
  additionalMenuActions: any;
};

export function SortableTableHead<T, K extends keyof T>({
  columns,
  onRequestSort,
  order,
  orderBy,
  additionalMenuActions,
}: SortableTableHeadProps<T, K>) {
  const createSortHandler = (property: K) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {additionalMenuActions && <TableCell />}
        {columns.map((column) => (
          <TableCell
            key={column.id as string}
            sortDirection={orderBy === column.id ? order : false}
            style={{
              verticalAlign: "middle",
              border: "1px solid #F4F4F4",
              textAlign: "left",
              borderBottomColor: "#D2D2D2",
              fontWeight: 600,
              color: "rgba(0, 0, 0, 0.8)",
            }}
          >
            <TableSortLabel
              disabled={column.sortable === false}
              active={orderBy === column.id}
              direction={orderBy === column.id ? order : "asc"}
              onClick={createSortHandler(column.id)}
            >
              {column.label}
              {orderBy === column.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
