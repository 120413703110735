import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import IconButton from "@mui/material/IconButton";
import InputBase from "@mui/material/InputBase";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { useFormikContext } from "formik";
import { useState } from "react";
import { SqcIdContainerPicker } from "./SqcIdContainerPicker";
import { SqcSampleContainerHistoryForm, SqcSampleForm } from "./form";
import { convertToLocalTime } from "../../../global";

type SqcQcIdSampleRowProps = {
  index: number;
  containerId: number | null;
  removable: boolean;
  disabled: boolean;
};

export function SqcQcIdSampleRow({
  index,
  containerId,
  removable,
  disabled,
}: SqcQcIdSampleRowProps) {
  const [sampleDescription, setSampleDescription] = useState("");
  const formik = useFormikContext<SqcSampleForm>();

  const onRemove = () => {
    let newValues: SqcSampleContainerHistoryForm[] = [];

    const { containerHistory } = formik.values;

    if (index === 0) {
      newValues = newValues.concat(containerHistory.slice(1));
    } else if (index === containerHistory.length - 1) {
      newValues = newValues.concat(containerHistory.slice(0, -1));
    } else if (index > 0) {
      newValues = newValues.concat(
        containerHistory.slice(0, index),
        containerHistory.slice(index + 1)
      );
    }

    const lastContainer = newValues.at(-1);

    if (lastContainer?.deactivatedDate) {
      lastContainer.deactivatedDate = null;
    }

    formik.setFieldValue("containerHistory", newValues);
  };

  return (
    <TableRow>
      <TableCell sx={{ padding: 0, textAlign: "center" }}>
        {removable && (
          <IconButton onClick={onRemove}>
            <DeleteForeverIcon />
          </IconButton>
        )}
      </TableCell>

      <TableCell sx={{ paddingLeft: 0 }}>
        <SqcIdContainerPicker
          containerId={containerId}
          onChange={(sample, containerId) => {
            setSampleDescription(sample?.description ?? "");
            formik.setFieldValue(
              `containerHistory.${index}.containerID`,
              containerId
            );
          }}
          containerError={
            (formik.errors.containerHistory?.[index] as any)?.containerID
          }
          disabled={disabled}
        />
      </TableCell>
      <TableCell>{sampleDescription}</TableCell>
      <TableCell sx={{ paddingInline: "0.5rem", paddingBlock: 0 }}>
        <InputBase
          name={`containerHistory.${index}.manufacturer`}
          value={formik.values.containerHistory[index].manufacturer ?? ""}
          onChange={formik.handleChange}
          inputProps={{
            maxLength: 200,
          }}
          sx={{
            width: "100%",
            backgroundColor: ({ palette }) => palette.common.white,
            borderWidth: "1px",
            borderStyle: "solid",
            borderColor: ({ palette }) => palette.grey[300],
          }}
        />
      </TableCell>
      <TableCell sx={{ paddingInline: "0.5rem", paddingBlock: 0 }}>
        <InputBase
          name={`containerHistory.${index}.lotNumber`}
          value={formik.values.containerHistory[index].lotNumber ?? ""}
          onChange={formik.handleChange}
          inputProps={{
            maxLength: 100,
          }}
          sx={{
            width: "100%",
            backgroundColor: ({ palette }) => palette.common.white,
            borderWidth: "1px",
            borderStyle: "solid",
            borderColor: ({ palette }) => palette.grey[300],
          }}
        />
      </TableCell>
      <TableCell>
        {convertToLocalTime(formik.values.containerHistory[index].createdDate)}
      </TableCell>
      <TableCell>
        {convertToLocalTime(
          formik.values.containerHistory[index].deactivatedDate
        )}
      </TableCell>
    </TableRow>
  );
}
