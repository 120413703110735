import API from "..";
export default class SampleAttachment {
  constructor({ id, sampleName, fileName, storagePath, fileObject }) {
    this.id = id;
    this.sampleName = sampleName;
    this.fileName = fileName;
    this.storagePath = storagePath;
    this.fileObject = fileObject;
  }

  static async delete(id) {
    const api = await API();
    const { data } = await api.delete(`/sampleAttachment/${id}`);
    return data;
  }

  static async getFileAttachment(containerName, filePath) {
    const api = await API();
    const { data } = await api.get(
      `/fileDownload/?containerName=${containerName}&filePath=${filePath}`
    );
    return data.result;
  }
}
