import API from "../../api";

export default class User {
    /**
     * The IGrapherUser properties
     * @param {string} businessPhones
     * @param {string} displayName
     * @param {string} givenName
     * @param {string} jobTitle
     * @param {string} mail
     * @param {string} mobilePhones
     * @param {string} surname
     * @param {string} officeLocation
     * @param {string} preferredLanguage
     * @param {String} id

     * need to get supervisor
     */
     constructor({
      businessPhones,
      displayName,
      givenName,
      jobTitle,
      mail,
      mobilePhones,
      surname,
      officeLocation,
      preferredLanguage,
      id
    }) 
        
    {
      this.businessPhones = businessPhones;
      this.displayName = displayName;
      this.givenName = givenName;
      this.jobTitle = jobTitle;
      this.mail = mail;
      this.mobilePhones = mobilePhones
      this.surname = surname;
      this.officeLocation = officeLocation;
      this.preferredLanguage = preferredLanguage
      this.id = id;
  }
  
    static async searchByLastName(lastName) {
      const api = await API();
      const { data } = await api.get(`/user/searchByLastName?lastName=${lastName}`);
      if(data.result)
      {
        return data.result.map((d) => new User(d));
      } else {
        return []
      }
    }

    static async searchByFirstName(firstName) {
      const api = await API();
      const { data } = await api.get(`/user/searchByFirstName?firstName=${firstName}`);
      if(data.result)
      {
        return data.result.map((d) => new User(d));
      } else {
        return []
      }
    }

    static async searchByFirstAndLastName(firstName, lastName) {
      const api = await API();
      const { data } = await api.get(`/user/searchByFirstAndLastName?lastName=${lastName}&firstName=${firstName}`);
      if(data.result)
      {
        return data.result.map((d) => new User(d));
      } else {
        return []
      }
    }
    
    static async validateEmailAddress(emailAddress) {
      const api = await API();
      const { data } = await api.get(`/user?userEmail=${emailAddress}`);
      if (data.result) {
        return new User(data.result);
      }
      else {
        return null;
      }
    }
}