import API from "..";

export default class CertificationMethod {
  /**
   * The Sample dimension table for M3
   * @param {String} methodName The MIDAS of the Sample
   */
  constructor({
    methodName,
    description,
    createdByEmail,
    createdDate,
    lastModifiedByEmail,
    lastModifiedDate,
    midasMethodName,
    midasMethod,
    methodLimits,
  }) {
    this.methodName = methodName;
    this.description = description;
    this.createdByEmail = createdByEmail;
    this.createdDate = createdDate;
    this.lastModifiedByEmail = lastModifiedByEmail;
    this.lastModifiedDate = lastModifiedDate;
    this.midasMethodName = midasMethodName;
    this.midasMethod = midasMethod;
    this.methodLimits = methodLimits;
  }

  static async create(certificationmethod) {
    const api = await API();
    const { data } = await api.post(`/certificationmethod`, certificationmethod);
    return data;
  }
}
