import React, { useState, useEffect } from 'react';
import { useTheme } from "@mui/material/styles";
import { TextField } from "@mui/material";
import { outlinedInputClasses } from "@mui/material/OutlinedInput";
import { inputLabelClasses } from "@mui/material/InputLabel";
import Sample from "../../api/LIMS/Sample"
import { formatMidasNumber, isNumber } from "../../global";
import ModalMessages from "../../components/Modal/ModalSimpleButton";

//const ValidatedMidasNumberTextField = ({ isDisabled, margin, showLabel, fontSize, fieldWidth, midasNumberObject, setMidasNumberObject, hasErrors, setHasErrors, chemIDToMatch, setScannedContainer, onlyISOSamples = false }) => {
const ValidatedMidasNumberTextField = ({ isDisabled, margin, showLabel, fontSize, fieldWidth, midasNumberObject, setMidasNumberObject, hasErrors, setHasErrors, chemIDToMatch, setScannedContainer, autoSearch = false }) => {

    const theme = useTheme();
    const [midasNumberSearchValue, setMidasNumberSearchValue] = useState("")
    const [coloring, setColoring] = useState(theme.palette.primary)
    const [label, setLabel] = useState("MIDAS #")

    const [modalMessagesOpen, setModalMessagesOpen] = useState(false);
    const modalMessagesButtonText = 'Ok'
    const [modalMessagesTitle, setModalMessagesTitle] = useState('');
    const [modalMessagesText, setModalMessagesText] = useState('');

    function closeModalMessages() {
        setModalMessagesOpen(false);
    }
    
    function openModalMessages(title, text) {
        setModalMessagesOpen(true);
        setModalMessagesTitle(title);
        setModalMessagesText(text);
    }

    const handleKeyPress = (event) => {
        if (event.key === 'Enter' || event.key === 'Tab') {
            setMidasNumberSearchValue(event.target.value)
            fieldLeaveAndValidate()
        }
    }

    const fieldChange = (value) => {
        if (midasNumberObject === null) {
            setMidasNumberSearchValue(value)

            if (autoSearch && value?.length > 3)
            {
                fieldLeaveAndValidate(value);
            }

        } else if (autoSearch && value?.length > 3) 
        {
            fieldLeaveAndValidate(value);
        } else {
            setMidasNumberSearchValue(value)
            setMidasNumberObject(null)
            setLabel("MIDAS #")
            setColoring(theme.palette.primary)
        }
    } 

    const fieldLeaveAndValidate = (searchValue) => {
        searchValue = searchValue ?? midasNumberSearchValue;
        setHasErrors(midasNumberObject === null)
        let cancelPromise = false
    
        if (searchValue && searchValue !== "") {
            Sample.getSamplesWithContainers(searchValue).then((res) => {
                if (cancelPromise) return

                if (res) {
                    if (res.message === 'Success')
                    {
                        if (res.result.substance.chemID === chemIDToMatch || chemIDToMatch === null)
                        {
                        const containerNumber = scannerContainer(false);

                        setMidasNumberObject(res.result, containerNumber)
                        setLabel("Valid MIDAS #")
                        setColoring("green")
                        setHasErrors(false)
                           
                        } else {
                            setMidasNumberObject(null)
                            setHasErrors(true)
                            setLabel("ChemID Mismatch")
                            setColoring(theme.palette.primary)
                        }
                    } else {
                        setMidasNumberObject(null)
                        setHasErrors(true)
                        setLabel("Invalid MIDAS #")
                        setColoring(theme.palette.primary)
                        openModalMessages("Sample Error", res.message)
                    }
                   
                } else {
                    setMidasNumberObject(null)
                    setHasErrors(true)
                    setLabel("Invalid MIDAS #")
                    setColoring(theme.palette.primary)
                }
            });
        } else {
            setHasErrors(false)
            setMidasNumberObject(null)
            setLabel("MIDAS #")
            setColoring(theme.palette.primary)
        }
        
        return () => {
            cancelPromise = true
        }
    }

    useEffect(() => {
        if (midasNumberObject && midasNumberObject !== null)
        {
            setLabel("Valid MIDAS #")
            setColoring("green")
            setMidasNumberSearchValue(midasNumberObject.sampleName)
            //setHasErrors(false)   this might need to be added back in if there are problems

            scannerContainer()

        } else {
            setLabel("MIDAS #")
            setColoring(theme.palette.primary)
            setMidasNumberSearchValue('')
            setHasErrors(false)
            if (!(setScannedContainer === undefined))
            {
                setScannedContainer(null)
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [midasNumberObject])

    const scannerContainer = (setScanned = true) => {
        let containerNumber = null
        let shortHandTokens = midasNumberSearchValue ? midasNumberSearchValue.split("-") : null

        if (midasNumberSearchValue.length === 13)
        {
            if (isNumber(midasNumberSearchValue)) 
            {
                containerNumber = parseInt(midasNumberSearchValue.substring(10, 13))
            } else {
                containerNumber = parseInt(midasNumberSearchValue.substring(8, 11))
            }
            if (!(setScannedContainer === undefined) && setScanned)
            {
                setScannedContainer(midasNumberObject.containers.find(obj => obj.containerNumber === containerNumber))
            }
        } else if (shortHandTokens && shortHandTokens.length === 3)
        {
            containerNumber = Number(shortHandTokens[2])
            
            if (!(setScannedContainer === undefined) && setScanned)
            {
                setScannedContainer(midasNumberObject.containers.find(obj => obj.containerNumber === shortHandTokens[2]))
            }
        }

        return containerNumber;
    }

    useEffect(() => {
        if (midasNumberSearchValue !== '') {
            fieldLeaveAndValidate()
        }
    // This should run only once
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
  
  return (
    <>
        <TextField sx={{width:fieldWidth,
                [`& .${outlinedInputClasses.root} .${outlinedInputClasses.notchedOutline}`]: {
                    borderColor: coloring
                },

                [`&:hover .${outlinedInputClasses.root} .${outlinedInputClasses.notchedOutline}`]: {
                    borderColor: coloring
                },
                [`& .${outlinedInputClasses.root}.${outlinedInputClasses.focused} .${outlinedInputClasses.notchedOutline}`]: {
                    borderColor: coloring
                },
                [`& .${outlinedInputClasses.input}`]: {
                    color: coloring
                },

                [`&:hover .${outlinedInputClasses.input}`]: {
                    color: coloring
                },
                [`& .${outlinedInputClasses.root}.${outlinedInputClasses.focused} .${outlinedInputClasses.input}`]: {
                    color: coloring
                },
                [`& .${inputLabelClasses.outlined}`]: {
                    color: coloring
                },

                [`&:hover .${inputLabelClasses.outlined}`]: {
                    color: coloring
                },
                [`& .${inputLabelClasses.outlined}.${inputLabelClasses.focused}`]: {
                    color: coloring
                }
                }} 

                disabled = {isDisabled}
                size="small" margin={margin} variant="outlined" label= {showLabel ? label : null} InputLabelProps={{shrink: true}}
                onKeyDown={e => handleKeyPress(e)}
                onChange={e => fieldChange(e.target.value)}
                value={midasNumberObject !== null ? formatMidasNumber(midasNumberObject.sampleName) : midasNumberSearchValue}
                placeholder="MIDAS #"
                error = {hasErrors}
                onBlur = {() => autoSearch ? {} : (midasNumberObject ? {} : fieldLeaveAndValidate())}
                //onBlur = {() => autoSearch ? {} : fieldLeaveAndValidate()}
                inputProps={{ style: { fontSize: fontSize} }}
                >
        </TextField>

            {/* Informational Messages */}
            <ModalMessages title={modalMessagesTitle} buttonText={modalMessagesButtonText} buttonAction={closeModalMessages} open={modalMessagesOpen} setOpen={setModalMessagesOpen}>
                <label>
                    {modalMessagesText}
                </label> 
            </ModalMessages>
    </>
  );
}
export default ValidatedMidasNumberTextField;


