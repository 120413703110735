import React from "react";
import CustomModal from './index';
import { GlobalButton, GlobalSecondaryButton } from "../../pages/styles";

const ModalThreeButtons = ({ children, title, button1Text, button1Action, button2Text, button2Action, button3Text, button3Action, open, setOpen }) => {

    return (
        <>
            <CustomModal title={title} open={open} setOpen={setOpen}>
                {children ? children : <></>}

                <div style={{
                    display: "flex",
                    justifyContent: "center",
                }}>
                    <GlobalButton
                        style={{
                            marginTop: "35px",
                            marginLeft: "6px",
                        }}
                        variant='contained'
                        onClick={button1Action}
                    >{button1Text}</GlobalButton>

                    <GlobalSecondaryButton
                        style={{
                            marginTop: "35px",
                            marginLeft: "20px",
                        }}
                        variant='contained'
                        onClick={button2Action}
                    >{button2Text}</GlobalSecondaryButton>

                    <GlobalSecondaryButton
                        style={{
                            marginTop: "35px",
                            marginLeft: "20px",
                        }}
                        variant='contained'
                        onClick={button3Action}
                    >{button3Text}</GlobalSecondaryButton>
                </div>


            </CustomModal>
        </>
    );
};

export default ModalThreeButtons;
