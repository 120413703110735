import LinearProgress from "@mui/material/LinearProgress";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { useState, cloneElement, useEffect } from "react";
import { SortableTableHead } from "./SortableTableHead/SortableTableHead";
import type { TableColumns } from "./type";

const style: React.CSSProperties = {
  verticalAlign: "middle",
  border: "1px solid #F4F4F4",
  textAlign: "left",
  fontSize: 14,
};

type SortableTableProps<T, K extends keyof T> = {
  columns: TableColumns<T, K>;
  count?: number;
  loading?: boolean;
  onPageChange?(page: number): void;
  rows: T[];
  rowsPerPage?: number;
  selectable?: boolean;
  isSelected?(row: T): boolean;
  select?(row: T): void;
  selectAll?(event: React.ChangeEvent<HTMLInputElement>): void;
  isAnySelected?: boolean;
  areAllSelected?: boolean;
  handleClickOnRow?(row: T): void;
  className?: string;
  style?: React.CSSProperties;
  onEvent?(column: K, context: T, event: any): void;
  children: any;
  initialOrderBy: keyof T;
  initialAscendingOrder: boolean;
  tableSize?: "small" | "medium";
};

export function descendingComparator<T, K extends keyof T>(
  a: T,
  b: T,
  orderBy: K
) {
  if (!a[orderBy]) return 1;
  if (!b[orderBy]) return -1;
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }

  if (b[orderBy] > a[orderBy]) {
    return 1;
  }

  return 0;
}

export function getComparator<T, K extends keyof T>(
  order: "asc" | "desc",
  orderBy?: K
): (a: T, b: T) => number {
  if (!orderBy) return () => 0;

  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

export function SortableTable<T, K extends keyof T>({
  columns,
  count,
  loading,
  rows,
  rowsPerPage = 11,
  onEvent,
  initialOrderBy,
  initialAscendingOrder,
  children,
  tableSize = "medium",
}: SortableTableProps<T, K>) {
  const [order, setOrder] = useState<"asc" | "desc">(
    initialAscendingOrder ? "asc" : "desc"
  );
  const [orderBy, setOrderBy] = useState<keyof T>(initialOrderBy ?? null);
  const [page, setPage] = useState(0);

  const smallScreen = window.innerWidth < 1601 && columns.length > 12;
  const colSpan = columns.length + 1;
  const sortedRows = rows.slice().sort(getComparator(order, orderBy));
  const resultRows = count
    ? sortedRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
    : sortedRows;

  const handleRequestSort = (_: any, property: keyof T) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  useEffect(() => {
    setPage(0);
  }, [count]);

  const handlePageChange = (_: any, newPage: number) => {
    setPage(newPage);
  };

  return (
    <TableContainer>
      <Table size={tableSize}>
        <SortableTableHead
          columns={columns}
          onRequestSort={handleRequestSort}
          order={order}
          orderBy={orderBy}
          additionalMenuActions={!!children}
        />
        <TableBody>
          {loading && (
            <TableRow>
              <TableCell colSpan={colSpan}>
                <LinearProgress />
              </TableCell>
            </TableRow>
          )}
          {!loading && (
            <>
              {resultRows.map((row, index) => {
                return (
                  <TableRow key={index} hover>
                    {!!children && (
                      <TableCell
                        sx={{ paddingLeft: "0", paddingRight: "0.5rem" }}
                      >
                        {cloneElement(children, { row })}
                      </TableCell>
                    )}
                    {columns.map((headCell, index) => (
                      <TableCell key={index} style={{ ...style }}>
                        {/* @ts-ignore */}
                        {headCell.transform?.(row[headCell.id], row, (e) =>
                          onEvent?.(headCell.id, row, e)
                        ) ?? row[headCell.id]}
                      </TableCell>
                    ))}
                  </TableRow>
                );
              })}
              {resultRows.length === 0 && (
                <TableRow>
                  <TableCell colSpan={colSpan} align="center">
                    Sorry, we couldn&apos;t find any results.
                  </TableCell>
                </TableRow>
              )}
            </>
          )}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[]}
              colSpan={colSpan}
              count={count ? Math.floor(count) : rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handlePageChange}
              sx={{
                "& .MuiTablePagination-spacer": {
                  display: smallScreen && colSpan > 8 ? "none" : "block",
                },
              }}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
}
