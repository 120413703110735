import API from "..";
import {
  SimplifiedSqcResultValue,
  SqcMethodSetup,
  SqcSample,
} from "../../lib/types/sqc";
import { SqcMethodManagementForm } from "../../pages/SQC/MethodManagement/form";
import { SqcSampleForm } from "../../pages/SQC/QCID/form";

export class SqcApi {
  static async getAllSamples(): Promise<SqcSample[]> {
    const api = await API();

    if (!api) {
      throw new Error("Failed to create an API instance");
    }

    const { data } = await api.get("/sqcsample");

    return data.result;
  }

  static async createSample(sample: SqcSampleForm): Promise<SqcSample> {
    const api = await API();

    if (!api) {
      throw new Error("Failed to create an API instance");
    }

    const { data } = await api.post("/sqcsample", sample);

    return data.result;
  }

  static async updateSample(
    id: string,
    sample: SqcSampleForm
  ): Promise<SqcSample> {
    const api = await API();

    if (!api) {
      throw new Error("Failed to create an API instance");
    }

    const { data } = await api.put(`/sqcsample/${id}`, sample);

    return data.result;
  }

  static async getAllMethodSetups(): Promise<{ result: SqcMethodSetup[], message?: string }> {
    const api = await API();

    if (!api) {
      throw new Error("Failed to create an API instance");
    }

    const { data } = await api.get("/sqcmethodsetup");

    return data;
  }

  static async createMethodSetup(
    methodSetup: SqcMethodManagementForm
  ): Promise<SqcMethodSetup> {
    const api = await API();

    if (!api) {
      throw new Error("Failed to create an API instance");
    }

    const { data } = await api.post("/sqcmethodsetup", methodSetup);

    return data.result;
  }

  static async updateMethodSetup(
    methodSetup: SqcMethodManagementForm
  ): Promise<SqcMethodSetup> {
    const api = await API();

    if (!api) {
      throw new Error("Failed to create an API instance");
    }

    const { data } = await api.put("/sqcmethodsetup", methodSetup);

    return data.result;
  }

  static async getResultValues(filter: {
    methodFacilityId: number;
    qcid: string;
    parameter: string;
    temperature?: number;
    instrumentIds?: number[];
    startDate?: string;
    endDate?: string;
    numberOfPoints?: number;
    lambda: number;
    excludedPointsVisible: boolean;
  }): Promise<SimplifiedSqcResultValue[]> {
    const api = await API();

    if (!api) {
      throw new Error("Failed to create an API instance");
    }

    const { data } = await api.get("/sqcresultvalue", {
      params: { ...filter },
    });

    return data.result;
  }

  static async updatePartialResult(
    id: number,
    status: string,
    comments: string
  ): Promise<SimplifiedSqcResultValue> {
    const api = await API();

    if (!api) {
      throw new Error("Failed to create an API instance");
    }

    const { data } = await api.patch(`/sqcresult/${id}?status=${status}&comments=${comments}`);

    return data?.result;
  }
}
