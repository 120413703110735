import API from '../index'

export default class DistillationExperimentTemplate {
    constructor(
        distillationTemplateName,
        createdByEmail,
        createdDate,
        lastModifiedByEmail,
        lastModifiedDate,
        distillationTypeName,
        thisDistillationType,
        templateCuts,
        isActive,
    ) {
        this.distillationTemplateName = distillationTemplateName
        this.createdByEmail = createdByEmail
        this.createdDate = createdDate
        this.lastModifiedByEmail = lastModifiedByEmail
        this.lastModifiedDate = lastModifiedDate
        this.distillationTypeName = distillationTypeName
        this.thisDistillationType = thisDistillationType
        this.templateCuts = templateCuts
        this.isActive = isActive
    }

    // static async getAllExperimentTemplates() {
    //     const api = await API();
    //     const { data } = await api.get('/distillationexperimenttemplate');
    //     return data.result;
    // }

    static async searchTemplates(userEmail, includePublic, includeInactives) {
        const api = await API();
        const { data } = await api.get(`/distillationexperimenttemplate/search?userEmail=${userEmail}&includePublic=${includePublic}&includeInactives${includeInactives}`);
        return data.result;
    }
 
    static async createTemplate(payload) {
        const api = await API();
        const { data } = await api.post('/distillationexperimenttemplate', payload);
        return data
    }

    static async updateTemplate(payload) {
        const api = await API();
        const { data } = await api.put(`/distillationexperimenttemplate`, payload);
        return data
    }

    static async deleteTemplate(name) {
        const api = await API();
        const { data } = await api.delete(`/distillationexperimenttemplate/${name}`);
        return data;
    }
}