import React from "react";
import { Divider } from "@mui/material";

const UnauthorizedPage = ({ ...props }) => {
  return (
    <div>
      <span className='pageheader'>Unauthorized Page</span>
      <Divider className='dividerbar' />
      <p>You are not authorized to perform this operation, contact support if you feel this is an error</p>
    </div>
  );
};

export default UnauthorizedPage;