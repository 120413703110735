import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import {
  REQUEST_INFORMATION_ENUM,
  TEST_CLASSIFICATION_ENUM,
} from "../../shared";
import Checkbox from "@mui/material/Checkbox";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Table from "@mui/material/Table";
import { StyledScrollableBox, StyledTableCellLarge } from "../../styles";
import {
  StyledTableBody,
  StyledTableCell,
  StyledTableRow,
  UXDataTableWithoutBody,
} from "../../../../components/UXDataTable";
import type { StationaryTest, TestSpecification } from "../../types";
import type { FormikProps } from "formik";
import { useDetailedStRequest } from "../../context/DetailedStRequestContext";

const labelStyle = {
  font: "normal normal normal 12px/15px EMprint",
  color: "#021834",
  marginTop: "1rem",
};

type TestSpecificationViewProps = {
  testSpecification: TestSpecification;
  formik: FormikProps<Partial<StationaryTest>>;
};

export default function TestSpecificationView({
  testSpecification,
  formik,
}: TestSpecificationViewProps) {
  const { canManageGeneralAndTesting } = useDetailedStRequest();

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <span
        style={{
          textAlign: "left",
          font: "normal normal 600 20px/24px EMprint",
          letterSpacing: 0,
        }}
      >
        {REQUEST_INFORMATION_ENUM.TEST_SPECIFICATIONS}
      </span>
      <span style={labelStyle}>Classification</span>
      <Box
        display="grid"
        gridTemplateColumns="2fr 1fr 2fr"
        gap={2}
        style={{ marginTop: "1rem" }}
      >
        <RadioGroup style={{ flexDirection: "row" }}>
          <FormControlLabel
            value={TEST_CLASSIFICATION_ENUM.Lubricant}
            key="lubricantTesting"
            label="Lubricant Testing"
            control={
              <Radio
                checked={
                  TEST_CLASSIFICATION_ENUM.Lubricant ===
                  testSpecification.testTypeName
                }
              />
            }
            disabled
          />
          <FormControlLabel
            value={TEST_CLASSIFICATION_ENUM.Fuel}
            key="fuelTesting"
            label="Fuel Testing"
            control={
              <Radio
                checked={
                  TEST_CLASSIFICATION_ENUM.Fuel ===
                  testSpecification.testTypeName
                }
              />
            }
            disabled
          />
        </RadioGroup>

        <Box style={{ display: "flex", alignItems: "center" }}>
          <Checkbox
            id="isISO9001"
            name="isISO9001"
            size="small"
            checked={formik.values.isISO9001}
            style={{ paddingLeft: 0 }}
            disabled={!canManageGeneralAndTesting}
            onClick={() => {
              formik.setFieldValue("isISO9001", !formik.values.isISO9001);
            }}
          />

          <label
            htmlFor="isISO9001"
            style={{ font: "normal normal 400 14px/16px EMprint" }}
          >
            ISO 9001
          </label>
        </Box>

        <Box style={{ display: "flex", alignItems: "center" }}>
          <Checkbox
            id="safetyAlert"
            name="safetyAlert"
            size="small"
            checked={formik.values.safetyAlert}
            style={{ paddingLeft: 0 }}
            disabled={!canManageGeneralAndTesting}
            onClick={() => {
              formik.setFieldValue("safetyAlert", !formik.values.safetyAlert);
            }}
          />

          <label
            htmlFor="safetyAlert"
            style={{ font: "normal normal 400 14px/16px EMprint" }}
          >
            Safety Alert! Test of material may result in a test that falls
            outside the scope of normal operation.
          </label>
        </Box>
      </Box>
      <span style={labelStyle}>Official Registered</span>
      <Box display="grid" gridTemplateColumns="2fr 1fr 2fr" gap={2} mt={1}>
        <RadioGroup style={{ flexDirection: "row" }}>
          <FormControlLabel
            value="Yes"
            label="Yes"
            control={
              <Radio checked={formik.values.officialRegistered === true} />
            }
            disabled={!canManageGeneralAndTesting}
            onClick={() => {
              formik.setFieldValue("officialRegistered", true);
            }}
          />
          <FormControlLabel
            value="No"
            label="No"
            control={
              <Radio checked={formik.values.officialRegistered === false} />
            }
            disabled={!canManageGeneralAndTesting}
            onClick={() => {
              formik.setFieldValue("officialRegistered", false);
            }}
          />
          <FormControlLabel
            value="N/A"
            label="N/A"
            control={
              <Radio checked={formik.values.officialRegistered === null} />
            }
            disabled={!canManageGeneralAndTesting}
            onClick={() => {
              formik.setFieldValue("officialRegistered", null);
            }}
          />
        </RadioGroup>
      </Box>
      <Box display="grid" gridTemplateColumns="1fr 1fr 1fr" gap={2} mt={3}>
        <TextField
          value={formik.values.reportAlias}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          id="reportAlias"
          label="Report Alias"
          size="small"
          disabled={!canManageGeneralAndTesting}
        />
        <TextField
          value={formik.values.accOilCode}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          id="accOilCode"
          label="ACC Oil Code"
          size="small"
          disabled={!canManageGeneralAndTesting}
        />
        <TextField
          value={formik.values.formulationCode}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          id="formulationCode"
          label="Formulation/Stand Code"
          size="small"
          disabled={!canManageGeneralAndTesting}
        />
      </Box>
      <span style={labelStyle}>Parameters</span>
      <Box display="grid" gridTemplateColumns="1fr" gap={2} mt={1}>
        <StyledScrollableBox>
          <Table>
            <StyledTableBody>
              <StyledTableRow>
                {testSpecification.parameterColumns.map((col) => (
                  <StyledTableCell key={`custom-header-${col}`}>
                    {col}
                  </StyledTableCell>
                ))}
              </StyledTableRow>
              <StyledTableRow>
                <StyledTableCellLarge>
                  {testSpecification.testDisplayName}
                </StyledTableCellLarge>
                {testSpecification.parameterColumns.slice(1).map((col) => {
                  const valueToDisplay =
                    testSpecification.requestParameters.find(
                      (rp) => rp.testParameterName === col
                    );

                  return valueToDisplay ? (
                    <StyledTableCellLarge key={`parameter-${col}`}>
                      {valueToDisplay.parameterValue}
                    </StyledTableCellLarge>
                  ) : (
                    <StyledTableCellLarge
                      key={`parameter-${col}`}
                    ></StyledTableCellLarge>
                  );
                })}
              </StyledTableRow>
            </StyledTableBody>
          </Table>
        </StyledScrollableBox>
      </Box>
      <Box display="grid" gridTemplateColumns="1fr 1fr" gap={2} mt={3}>
        <TextField
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          id="submitterComments"
          value={formik.values.submitterComments ?? ""}
          label="Submitter Comments"
          size="small"
          multiline
          rows={4}
          disabled={!canManageGeneralAndTesting}
        />
        <TextField
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          id="technicianComments"
          value={formik.values.technicianComments ?? ""}
          label="Additional Instructions"
          size="small"
          multiline
          rows={4}
          disabled={!canManageGeneralAndTesting}
        />
      </Box>
      <span style={labelStyle}>File Attachments</span>
      <Box display="grid" gridTemplateColumns="1fr 1fr" gap={2} mt={1}>
        <UXDataTableWithoutBody
          cols={["File Name"]}
          tableWidth="100%"
          blankFirstHeader={false}
          tableId={`midasFileAttachments`}
          rowLength={["files.length"].length}
          enablePaging={false}
          rowsPerPage={["files.length"].length}
          page={0}
          handleChangePage={null}
          handleChangeRowsPerPage={null}
          noDataFoundMessage={"No Files Added"}
          enableAddIcon={false}
          addFunction={() => null}
          addToolTipText=""
          isDataLoading={false}
          enableCheckbox={false}
          handleRequestSort={null}
          handleSelectAllClick={null}
          order={null}
          orderBy={null}
        >
          <StyledTableBody key={"myTablebody"}>
            {testSpecification.fileAttachments.map((file, rowIndex) => {
              return (
                <StyledTableRow hover key={`custom-row-${rowIndex}`}>
                  <StyledTableCell>
                    {file.file.uri ? (
                      <a href={file.file.uri}>{file.file.name}</a>
                    ) : (
                      <span>{file.file.name}</span>
                    )}
                  </StyledTableCell>
                </StyledTableRow>
              );
            })}
          </StyledTableBody>
        </UXDataTableWithoutBody>
      </Box>
    </div>
  );
}
