import React from "react";
//import {TextField} from "@mui/material";


const SiteLocationAdminMenu = ({ ...props }) => {
  return (
    <div>
      <p>
        Coming soon to a browser near you!
      </p>

        <li>Locations</li>
        <li>Sites</li>
    </div>
  );
};

export default SiteLocationAdminMenu;