import  React, { useEffect, useState }  from "react";
import { Box, Autocomplete, TextField, Typography, styled, Modal, FormControlLabel, Checkbox, Switch } from "@mui/material";
import { GlobalButton, GlobalSecondaryButton } from "../../pages/styles";
import { printZpl } from "../../global";
import ModalSimpleButton from "../../components/Modal/ModalSimpleButton";
import PrintMidasLabel from "../../api/LIMS/PrintLabel";
import PrintLabelOptions from "../../api/LIMS/PrintLabelOptions";


const StyledModalBox = styled(Box)(() => ({
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: 'white',
    borderRadius: '10px',
    border: '1px solid rgba(0, 0, 0, 0.3)',
    boxShadow: '0 3px 7px rgba(0, 0, 0, 0.3)',
    backgroundClip: 'padding-box'
}));

const StyledModalHeader = styled('div')({
    marginLeft: '15px',
    marginTop:"20px",
    display: 'flex',
    justifyContent: 'left'
});

const StyledModalBody = styled('div')({
    margin: '1rem'
});

const PrintInformation = ({ open, setOpen, button2Action, button2Text, printLabelInfo, isShelfLabel, useFieldsBlindCode = true }) => {

    const [zplToPrint, setZplToPrint] = useState([])

    //label options
    const [printLabelOptions, setPrintLabelOptions] = useState({
        includeAdditionalSampleInformation: false,
        chemIDOnly: false,
        printLabelSizeType: null,
        isBlindCoded: false,
        blindDescription: null,
        blindSampleName: null,
        isShelfLabel: false,
        shelfLabelText: null,
        includeShelfBarcode: false
    })

    //size options
    const [printLabelSizes, setPrintLabelSizes] = useState([])

    //Modal stuff
    const [modalMessagesOpen, setModalMessagesOpen] = useState(false);
    const modalMessagesButtonText = 'Ok'
    const [modalMessagesTitle, setModalMessagesTitle] = useState('');
    const [modalMessagesText, setModalMessagesText] = useState('');

    const isSampleLableTypeSelected = printLabelOptions.printLabelSizeType?.includes("3x4 Sample Label") || printLabelOptions.printLabelSizeType?.includes("3x4 Japan Label")
    const isDrumLableTypeSelected = printLabelOptions.printLabelSizeType?.includes("5x8 Drum Label")

    function closeModalMessages() {
        setModalMessagesOpen(false);
    }
    
    function openModalMessages(title, text) {
        setModalMessagesOpen(true);
        setModalMessagesTitle(title);
        setModalMessagesText(text);
    }

    useEffect(() => {
        let cancelPromise = false

        PrintLabelOptions.getAll().then((res) => {
        if (cancelPromise) return

        if (res.message === 'Success')
        {
            setPrintLabelSizes(res.result.sort((a, b) => a.localeCompare(b)))
        }

        });
        return () => {
        cancelPromise = true
        }
    }, [])

    useEffect(() => {   
        let cancelPromise = false
    
        setZplToPrint(null)
        let newPrintInfo = []

        if ((printLabelOptions.printLabelSizeType === null && isShelfLabel === false) || (printLabelOptions.shelfLabelText === null && isShelfLabel === true) || printLabelInfo === null)
        {
            return
        } else {
            //populate the label size and type
            const copyPrintInfo = JSON.parse(JSON.stringify(printLabelInfo))

            copyPrintInfo.forEach(oPrintInfo => {
                oPrintInfo.includeAdditionalSampleInformation = printLabelOptions.includeAdditionalSampleInformation
                oPrintInfo.chemIDOnly = printLabelOptions.chemIDOnly
                oPrintInfo.printLabelSizeType = printLabelOptions.printLabelSizeType
                oPrintInfo.isBlindCoded = useFieldsBlindCode ? printLabelOptions.isBlindCoded : oPrintInfo.isBlindCoded
                oPrintInfo.blindDescription = useFieldsBlindCode ? printLabelOptions.blindDescription : oPrintInfo.blindDescription
                oPrintInfo.blindSampleName = printLabelOptions.blindSampleName
                oPrintInfo.isShelfLabel = isShelfLabel
                oPrintInfo.shelfLabelText = printLabelOptions.shelfLabelText
                oPrintInfo.includeShelfBarcode= printLabelOptions.includeShelfBarcode 

                newPrintInfo.push(oPrintInfo)
            });
        }

        PrintMidasLabel.getZPL(newPrintInfo).then((res) => {
            if (cancelPromise) return

            if (res && res.message === 'Success') {
                setZplToPrint(res.result.join(''))
            } else {
                openModalMessages("Error Getting Label Info", `${res ? res.message : 'Nasty error getting printing info'}.  Please try again or contact support.`)
            }
        });
        
        return () => {
            cancelPromise = true
          }
        
    }, [printLabelOptions, printLabelInfo, isShelfLabel, useFieldsBlindCode])

    // useEffect(() => {
    //     if (printLabelOptions.chemIDOnly === true) {
    //         setPrintLabelOptions(() => ({
    //             ...printLabelOptions,
    //             includeAdditionalSampleInformation: false
    //         }))
    //     } else if (printLabelOptions.includeAdditionalSampleInformation === true) {
    //         setPrintLabelOptions(() => ({
    //             ...printLabelOptions,
    //             chemIDOnly: false
    //         }))
    //     }

    // // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [printLabelOptions.chemIDOnly, printLabelOptions.includeAdditionalSampleInformation])

    useEffect(() => {
       Clear()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [printLabelOptions.printLabelSizeType])

    function PrintLabel()
    {
        if (zplToPrint === null)
        {
            openModalMessages("ZPLLabel Info Error", `The ZPL string is null and cannot be printed.  Please try again or contact support.`)
            return
        }

        printZpl(zplToPrint)
    }

    function Clear() {
        setPrintLabelOptions({
            includeAdditionalSampleInformation: false,
            chemIDOnly: false,
            printLabelSizeType: printLabelOptions.printLabelSizeType,
            isBlindCoded: false,
            blindDescription: null,
            blindSampleName: null,
            isShelfLabel: false,
            shelfLabelText: null,
            includeShelfBarcode: false
        })
    }

    return (
        <>
            <Modal
                open={open}
                aria-labelledby="modal-modal-header"
                aria-describedby="modal-modal-body"
            >
                <StyledModalBox style={{width:"30rem", maxHeight:"50%", overflow:"auto"}}>
                    <StyledModalHeader id="modal-modal-header" >
                        <Typography variant="h6" component="h6">Print Registration Label</Typography>
                    </StyledModalHeader>
                    <StyledModalBody id="modal-modal-body">

                        <label>
                            {'Please select the label information'}
                        </label>

                        <div style={{
                            marginTop:"20px"
                        }}>
                    {isShelfLabel === false ? 
                    (
                        <>
                        <Autocomplete
                            noOptionsText={"Loading Label Size/Types..."}
                            options={printLabelSizes}
                            getOptionLabel={(option) => option}
                            onChange={(e, value) => setPrintLabelOptions(() => ({
                                ...printLabelOptions,
                                printLabelSizeType: value
                            }))}
                            autoHighlight
                            autoSelect
                            value={printLabelOptions.printLabelSizeType}
                            isOptionEqualToValue={(option, value) => value === option}
                            style={{ width:"100%", marginRight:"15px", marginTop:"15px"}}
                            renderInput={(params) => <TextField {...params} variant="outlined" size="small" margin="normal" label="Select Label Stock"  InputLabelProps={{shrink: true}} InputProps={{ ...params.InputProps }}/>} 
                         />

                        <div style={{display:"flex"}}>
                            <FormControlLabel control={<Checkbox checked={printLabelOptions.chemIDOnly}
                                onChange={e => setPrintLabelOptions(() => ({
                                    ...printLabelOptions,
                                    chemIDOnly: e.target.checked
                                }))}
                                disabled= {(isSampleLableTypeSelected === false && isDrumLableTypeSelected === false) || printLabelOptions.printLabelSizeType === null}
                                // disabled= {isSampleLableTypeSelected === false || printLabelOptions.includeAdditionalSampleInformation === true || printLabelOptions.printLabelSizeType === null}
                            ></Checkbox>} label={printLabelOptions.isBlindCoded ? "Show Chem ID" : "Print Chem ID Only"}/>

                            <FormControlLabel control={<Checkbox checked={printLabelOptions.includeAdditionalSampleInformation}
                                onChange={e => setPrintLabelOptions(() => ({
                                    ...printLabelOptions,
                                    includeAdditionalSampleInformation: e.target.checked
                                }))}
                                disabled={isSampleLableTypeSelected === false || printLabelOptions.printLabelSizeType === null}
                                //disabled={isSampleLableTypeSelected === false || printLabelOptions.chemIDOnly === true || printLabelOptions.printLabelSizeType === null}
                            ></Checkbox>} label="Include Additional Sample Info"/>

                        </div>

                        {useFieldsBlindCode && <div>
                            
                            <FormControlLabel control={<Switch />} 
                                checked={printLabelOptions.isBlindCoded}
                                onChange={e => setPrintLabelOptions(() => ({
                                    ...printLabelOptions,
                                    isBlindCoded: e.target.checked
                                }))}
                                label = {"Use Blind Coding"}
                                disabled={(isSampleLableTypeSelected === false && isDrumLableTypeSelected === false) || printLabelOptions.printLabelSizeType === null}
                            />
                        </div>}

                        {useFieldsBlindCode && <div>           
                            <TextField style={{width: "100%"}} size="small" margin="normal" variant="outlined" label="Blind Code Sample Name" InputLabelProps={{shrink: true}}
                                onChange={e => setPrintLabelOptions(() => ({
                                    ...printLabelOptions,
                                    blindSampleName: e.target.value
                                }))}
                                value={printLabelOptions.blindSampleName === null ? '' : printLabelOptions.blindSampleName}
                                disabled={printLabelOptions.isBlindCoded === false}
                            ></TextField>
                        </div>}

                        {useFieldsBlindCode && <div>           
                            <TextField style={{width:"100%"}} size="small" margin="normal" variant="outlined" label="Blind Code Description" InputLabelProps={{shrink: true}}
                                onChange={e => setPrintLabelOptions(() => ({
                                    ...printLabelOptions,
                                    blindDescription: e.target.value
                                }))}
                                value={printLabelOptions.blindDescription === null ? '' : printLabelOptions.blindDescription}
                                disabled={printLabelOptions.isBlindCoded === false}
                            ></TextField>
                        </div>}
                        </>
                        ) : (
                        <div>
                            <TextField style={{width:"100%"}} size="small" margin="normal" variant="outlined" label="Shelf Label Text" InputLabelProps={{shrink: true}}
                                onChange={e => setPrintLabelOptions(() => ({
                                    ...printLabelOptions,
                                    shelfLabelText: e.target.value
                                }))}
                                value={printLabelOptions.shelfLabelText === null ? '' : printLabelOptions.shelfLabelText}
                            ></TextField>
                            
                            <FormControlLabel control={<Checkbox />} 
                                checked={printLabelOptions.includeShelfBarcode}
                                onChange={e => setPrintLabelOptions(() => ({
                                    ...printLabelOptions,
                                    includeShelfBarcode: e.target.checked
                                }))}
                                label = {"Include Barcode"}
                            />
                        </div>

                        )}




                    <div style={{
                        display: "flex",
                        justifyContent: "center",
                    }}>
                        <GlobalButton
                            style={{
                                marginTop: "35px",
                                marginLeft: "6px",
                            }}
                            disabled={zplToPrint === null}
                            variant='contained'
                            onClick={PrintLabel}
                        >{"Print"}</GlobalButton>
                        
                        <GlobalSecondaryButton
                            style={{
                                marginTop: "35px",
                                marginLeft: "20px",
                            }}
                            variant='contained'
                            onClick={button2Action}
                        >{button2Text}</GlobalSecondaryButton>
                    </div>
              
                        </div>
                    </StyledModalBody>
                </StyledModalBox>
            </Modal>

            <ModalSimpleButton title={modalMessagesTitle} buttonText={modalMessagesButtonText} buttonAction={closeModalMessages} open={modalMessagesOpen} setOpen={setModalMessagesOpen}>
                <label>
                    {modalMessagesText}
                </label>       
            </ModalSimpleButton>
        </>
    );
};

export default PrintInformation;