import React from "react";
import { Box, Divider} from "@mui/material";
import Roles from "./Roles";
import About from "./About";
import { GlobalTabCollection, StyledTab } from "../../pages/styles";

const Help = ({ ...props }) => {
  const [tabValue, setTabValue] = React.useState(0);
    const handleChange = (event, newValue) => {
      setTabValue(newValue);
    };
  
    const renderSearchTab = () => {
      switch (tabValue) {
        case 0: {
          return <Roles></Roles>
        }
        case 1: {
          return <About></About>
        }
        default: {
          alert(tabValue);
        }
      }
    };
  return (
    <div>
      <span className='pageheader'>MIDAS 3 Help</span>
      <Divider className='dividerbar' />
      <div>
        <Box sx={{ bgcolor: "#fff", pt:3, pb:1 }}>          
          <GlobalTabCollection value={tabValue} onChange={handleChange} aria-label='ant example'>
            <StyledTab label='Personas and Roles' />
            <StyledTab label='About MIDAS 3' />
          </GlobalTabCollection>
          <Box sx={{ p: 3 }} />
        </Box>
        {renderSearchTab()}
      </div>
    </div>
  );
};

export default Help;