import { createContext, useState } from "react";
import OrderStatus from "../../../api/Stockroom/OrderStatus";
import { useEffect } from "react";
import ModalSimpleButton from "../../../components/Modal/ModalSimpleButton";
import { Typography } from "@mui/material";

export const OrderStatusContext = createContext(null);

export const OrderStatusContextProvider = ({ children }) => {
  const [orderStatuses, setOrderStatuses] = useState([]);
  const [hasError, setHasError] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    if (hasError) setIsModalOpen(true);
  }, [hasError]);

  const getAllOrderStatuses = async () => {
    await OrderStatus.getAll()
      .then((data) => {
        const formattedData = data.filter((item) => item.isActive);
        setOrderStatuses(formattedData);
      })
      .catch((err) => {
        setHasError(err);
        setIsModalOpen(true);
      });
  };

  return (
    <OrderStatusContext.Provider value={{ orderStatuses, getAllOrderStatuses }}>
      {children}

      <ModalSimpleButton
        title={"Error"}
        buttonText={"Close"}
        buttonAction={() => {
          setIsModalOpen(false);
          setHasError("");
        }}
        setOpen={() => {
          setIsModalOpen(false);
          setHasError("");
        }}
        open={isModalOpen}
      >
        <Typography
          style={{ padding: "0 16px", fontSize: 18, textAlign: "center" }}
        >
          {hasError}
        </Typography>
      </ModalSimpleButton>
    </OrderStatusContext.Provider>
  );
};
