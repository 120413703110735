import API from "..";

export default class Test {
    /**
     * The Container dimension table for M3
     * @param TODO
     */
    constructor({
      id,
      containerID,
      container,
      containerSelectionType,
      methodFacility,
      methodFacilityID,
      testSequenceNumber,
      chargeCode,
      costCenter,
      testInstanceCostUSD,
      lastModifiedByEmail,
      lastModifiedDate,
      testStatusName,
      submitterEmail,
      submittedDate,
      receivedDate,
      inProgressByEmail,
      inProgressDate,
      testedDate,
      reportedDate,
      analystEmail,
      inRetest,
      requestedCompletionDate,
      testPackageID,
      testComment,
      isFitForModeling,
      testEstimate,
      testPriorityName,
      testPriority,
      requesterEmail,
      age,
      testConditionInstanceValue,
      testResults,
      externalLaboratory,
      externalLaboratoryName,
      testConditionOfferingID,
      testConditionOffering,
      userDefinedTestGrouping,
      parentTestID,
      countChildTest
    }) {
      this.id = id;
      this.containerID = containerID;
      this.container = container;
      this.containerSelectionType = containerSelectionType;
      this.methodFacility = methodFacility;
      this.methodFacilityID = methodFacilityID;
      this.testSequenceNumber = testSequenceNumber;
      this.chargeCode = chargeCode;
      this.costCenter = costCenter;
      this.testInstanceCostUSD = testInstanceCostUSD;
      this.lastModifiedByEmail = lastModifiedByEmail;
      this.lastModifiedDate = lastModifiedDate;
      this.testStatusName = testStatusName;
      this.submitterEmail = submitterEmail;
      this.submittedDate = submittedDate;
      this.receivedDate = receivedDate;
      this.inProgressByEmail = inProgressByEmail;
      this.inProgressDate = inProgressDate;
      this.testedDate = testedDate;
      this.reportedDate = reportedDate;
      this.analystEmail = analystEmail;
      this.inRetest = inRetest;
      this.requestedCompletionDate = requestedCompletionDate;
      this.testPackageID = testPackageID;
      this.testComment = testComment;
      this.isFitForModeling = isFitForModeling;
      this.testEstimate = testEstimate;
      this.testPriorityName = testPriorityName;
      this.testPriority = testPriority;
      this.requesterEmail = requesterEmail;
      this.age = age;
      this.testConditionInstanceValue = testConditionInstanceValue;
      this.testResults = testResults;
      this.externalLaboratory = externalLaboratory;
      this.externalLaboratoryName = externalLaboratoryName;
      this.testConditionOfferingID = testConditionOfferingID;
      this.testConditionOffering = testConditionOffering;
      this.userDefinedTestGrouping = userDefinedTestGrouping;
      this.parentTestID = parentTestID;
      this.countChildTest = countChildTest;
    }

    static async cancelTests(ids, message) {
      const api = await API();
      const { data } = await api.put(`/test/cancelTests?cancelMessage=${message}`, ids);
      return (data);
    }

    static async submitTestForReview(id, message, requesterEmail) {
      const api = await API();
      const { data } = await api.patch(`/test/${id}/submitReview?message=${message}&requesterEmail=${requesterEmail}`);
      return data;
    }

    static async getSimpleResult(methodName, facility, sampleName, condition) {
      const api = await API();
      const { data } = await api.get(`/test/simpleResult?methodName=${methodName}&facility=${facility}&sampleName=${sampleName}&condition=${condition}`);
      return data.result.map((d) => new Test(d));
    }

    static async getReviewCorrectResult(methodName, facility, sampleName, submitterEmail, requesterEmail, ownerEmail, testOwnerEmail, condition, status) {
      const api = await API();
      const { data } = await api.get(`/test/reviewCorrectResult/?methodName=${methodName}&facility=${facility}&sampleName=${sampleName}&submitterEmail=${submitterEmail}&requesterEmail=${requesterEmail}&ownerEmail=${ownerEmail}&testOwnerEmail=${testOwnerEmail}&condition=${condition}&${status}`);
      return data.result.map((d) => new Test(d));
    }

    static async updateStatus(ids, status) {
      const api = await API();
      const { data } = await api.put(`/test/status?status=${status}`, ids);
      return data;
    }

    static async update(test) {
      const api = await API();
      const { data } = await api.put(`/test/saveTest`, test);
      return data;
    }

    //this isnt used in the UI, only used external and by MRR
    // static async completeTestQuick(testID, analystEmail = "", testComments = "", testResults) {
    //   let filterOptional = analystEmail !== "" ? `&analystEmail=${analystEmail}` : ""
    //   filterOptional = testComments !== "" ? `&testComments=${testComments}` : ""

    //   const api = await API();
    //   const { data } = await api.put(`/test/completetestquick?testID=${testID}${filterOptional}`, testResults);
    //   return data;
    // }

    static async sendEmail(info) {
      const api = await API();
      const { data } = await api.post(`/test/emailSubmitter`, info);
      return data;
    }

    static async updatePriority(id, priority) {
      const api = await API();
      const { data } = await api.patch(`/test/${id}/priority?priority=${priority}`);
      return data;
    }

    static async addTestToContainer(test, containerId) {
      const api = await API();
      const { data } = await api.post(`/test/addSingleTest?containerID=${containerId}`, test);
      return data;
    }

    static async addTestToContainerWithoutVolumeCheck(test, containerId) {
      const api = await API();
      const { data } = await api.post(`/test/addSingleTestWithoutVolumeCheck?containerID=${containerId}`, test);
      return data;
    }

    static async getCompleteByID(id, removeCorrectedResults) {
      const api = await API();
      const { data } = await api.get(`/test/wholeTest?id=${id}&removeCorrectedResults=${removeCorrectedResults}`);
      let returnValue = [];
      if (data.result && data.message === "Success") {
        returnValue = new Test(data.result);
      } else {
        returnValue = []
      }
      return returnValue;
    }

    static async parentTest(parentTestId) {
      const api = await API();
      const { data } = await api.get(`/test/parentTest/${parentTestId}`);
      return data;
    }
  }
  