import { StyleSheet } from "@react-pdf/renderer";

export const styles = StyleSheet.create({
    page: {
      fontSize: "10px",
      display: "flex",
      padding: 15,
    },
  
    row1: {
      marginTop: "15px",
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
    },
    item10: {
      width: "10%",
    },
  
    item20: {
      width: "20%",
    },
    item25: {
      width: "25%",
    },
  
    item33: {
      width: "33%",
    },
    item30: {
      width: "30%",
    },
    item40: {
      width: "40%",
    },
    item50: {
      width: "50%",
    },
    item60: {
      width: "60%",
    },
    item100: {
      width: "100%",
    },
    title: {
      textAlign: "center",
      fontSize: "16pt",
    },
    subtitle: {
      textAlign: "left",
      fontSize: "12pt",
      marginTop: "10px",
    },
    textHeader: {
      textAlign: "left",
      fontSize: "6pt",
      fontWeight: "bold",
      marginBottom: "3px",
    },
    text: {
      textAlign: "left",
      fontSize: "9pt",
    },
    tableRow: {
      display: "flex",
      flexDirection: "row",
      paddingTop: 4,
      paddingBottom: 4,
      justifyContent: "space-between",
      borderBottom: "1px dotted gray",
    },
    tableDivider: {
      borderTop: "1px solid gray",
      width: "100%",
      height: "2px",
    },
    tableText: {
      textAlign: "left",
      fontSize: "7pt",
    },
    table: { marginBottom: "15px" },
  });