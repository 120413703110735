import {formatMidasNumber, convertDateFormat} from "../../global";
//import MoreOptions from "@mui/icons-material/MoreVert";
import {useState} from "react";
import { UXDataTableWithoutBody, StyledTableCell, UXDataTableNested, StyledTableBody, StyledTableRow } from "../../components/UXDataTable";
import {IconButton,TableRow} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

const experimentCols = ['ID', 'Cuts', 'MIDAS # / Container', 'Mnemonic', 'Description', 'Requester Email', 'Requested Completion Date', 'Status']
const cutCols = ['MIDAS #', 'Chem ID', 'Type','Comments','Requsted Start Temp (C)', 'Requsted End Temp (C)', 'Actual Start Temp (C)', 'Actual End Temp (C)']

const SearchDistillations = ({inputSearchCriteria, myExperiments, dataIsLoading}) => {

  const [cutRowOpen, setCutRowOpen] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  const noDataMessage = `No distillation info found matching the quick search for = ${inputSearchCriteria}`

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleCutsRowClick = (experimentID) => {
    const cutRowOpenIndex = cutRowOpen.indexOf(experimentID);
    let newCutRowOpen = [];

    if (cutRowOpenIndex === -1) {
      newCutRowOpen = newCutRowOpen.concat(cutRowOpen, experimentID);
    } else if (cutRowOpenIndex === 0) {
      newCutRowOpen = newCutRowOpen.concat(cutRowOpen.slice(1));
    } else if (cutRowOpenIndex === cutRowOpen.length - 1) {
      newCutRowOpen = newCutRowOpen.concat(cutRowOpen.slice(0, -1));
    } else if (cutRowOpenIndex > 0) {
      newCutRowOpen = newCutRowOpen.concat(
        cutRowOpen.slice(0, cutRowOpenIndex),
        cutRowOpen.slice(cutRowOpenIndex + 1)
      );
    }
    setCutRowOpen(newCutRowOpen);
  };

  const determineStartArray = () => {
    return (myExperiments.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage))
  }

  const isCutRowOpen = (experimentID) => cutRowOpen.indexOf(experimentID) !== -1;
  return (
    <>
    <UXDataTableWithoutBody 
      tableWidth='100%' 
      cols={experimentCols} 
      blankFirstHeader={false}
      tableId="myWRTable"
      rowLength={myExperiments ? myExperiments.length : 0}
      enablePaging={true}
      rowsPerPage={rowsPerPage}
      page={page}
      handleChangePage={handleChangePage}
      handleChangeRowsPerPage={handleChangeRowsPerPage}
      noDataFoundMessage={noDataMessage}
      enableAddIcon={false}
      addFunction={null}
      addToolTipText={null}  
      isDataLoading={dataIsLoading}
      >

        {myExperiments && myExperiments.length > 0 ? (
          determineStartArray().map((experiment, index) => {
          
            return (
              <StyledTableBody key={"myExperimentTablebody" + index}> 
                <StyledTableRow>

                  <StyledTableCell component='th' scope='row'>{experiment.id}</StyledTableCell>

                    <StyledTableCell align='right' style={{width:'5rem'}}>

                    {experiment.experimentCuts.length}

                      <IconButton
                        aria-label='expand row'
                        size='small'
                        onClick={() => handleCutsRowClick(experiment.id)}>
                        {isCutRowOpen(experiment.id) ? (
                          <KeyboardArrowUpIcon/>
                        ) : (
                          <KeyboardArrowDownIcon/>
                        )}
                      </IconButton>
                    </StyledTableCell>
                          
                    <StyledTableCell>{experiment.initialContainer ? `${formatMidasNumber(experiment.initialContainer.sample.sampleName)}/${experiment.initialContainer.containerNumber}` : ''}</StyledTableCell>
                    <StyledTableCell>{experiment.mnemonic}</StyledTableCell>
                    <StyledTableCell>{experiment.experimentDescription}</StyledTableCell>
                    <StyledTableCell>{experiment.requestedByEmail}</StyledTableCell>
                    <StyledTableCell>{convertDateFormat(experiment.requestedCompletionDate, false)}</StyledTableCell>
                    <StyledTableCell>{experiment.distillationStatusName}</StyledTableCell>
                  </StyledTableRow>

                {isCutRowOpen(experiment.id) && (
                  <UXDataTableNested 
                  tableWidth={'100%'} 
                  cols={cutCols} 
                  blankFirstHeader={false} 
                  colSpan={experimentCols.length + 1} 
                  isOpenComparator={experiment.id} 
                  isOpenArray={cutRowOpen}>

                    {experiment.experimentCuts.map((cuts) => {

                      return (
                    <StyledTableBody key={`${experiment.id}${cuts.id}_"cutstablebody"`}>
                      <StyledTableRow>
                        <StyledTableCell sx={{minWidth:"8rem"}}>{formatMidasNumber(cuts.sampleName)}</StyledTableCell>
                        <StyledTableCell>{cuts.chemID}</StyledTableCell>
                        <StyledTableCell>{cuts.distillationCutTypeName}</StyledTableCell>
                        <StyledTableCell>{cuts.comments}</StyledTableCell>
                        <StyledTableCell>{cuts.requestedStartingTemperatureC}</StyledTableCell>
                        <StyledTableCell>{cuts.requestedEndTemperatureC}</StyledTableCell>
                        <StyledTableCell>{cuts.actualStartingTemperatureC === null ? '' : cuts.actualStartingTemperatureC}</StyledTableCell>
                        <StyledTableCell>{cuts.actualEndTemperatureC == null ? '' : cuts.actualEndTemperatureC}</StyledTableCell>
                      </StyledTableRow>
                      
                      </StyledTableBody>
                      )
                      })}
                      </UXDataTableNested>
                    )}
                
                </StyledTableBody>
            );
          })
        ) : (
          <StyledTableBody key={"no Distillations found"}>
            <TableRow key={"nothing found dist"}>
            <StyledTableCell colSpan='14' key={"nothing found cells dist"}>
              <p>{noDataMessage}</p>
            </StyledTableCell>
          </TableRow>
          </StyledTableBody>
        )}
  </UXDataTableWithoutBody>
  </>
  );
};

export default SearchDistillations;
