//import { LogLevel } from "@azure/msal-browser";

// Browser check variables
// If you support IE, our recommendation is that you sign-in using Redirect APIs
// If you as a developer are testing using Edge InPrivate mode, please add "isEdge" to the if check
const ua = window.navigator.userAgent;
const msie = ua.indexOf("MSIE ");
const msie11 = ua.indexOf("Trident/");
const msedge = ua.indexOf("Edge/");
const isIE = msie > 0 || msie11 > 0;
const isEdge = msedge > 0;

// Config object to be passed to Msal on creation
export const msalConfigAdfs = {
  auth: {
    clientId: "38abe0f9-222c-4b4e-adb7-3592af700a26", // PLACE YOUR CLIENT ID HERE
    authority: "https://adfs.emrelab.na.xom.com/adfs/",
    redirectUri: window.location.origin,
    knownAuthorities: ["https://adfs.emrelab.na.xom.com/adfs/"]
  },
  cache: {
    storeAuthStateInCookie: isIE || isEdge,
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
      },
    }
  }
};

// Example request configuration for accessing MS Graph.
export const graphLoginRequest = {
  scopes: ["https://graph.microsoft.com/User.Read"],
  domainHint: "exxonmobil.com",
};

// Place any scopes required by your app here
export const appLoginRequestAdfs = {
  scopes: [],
  domainHint: "exxonmobil.com",
};

// Add here the endpoints for MS Graph API services you would like to use.
export const graphConfig = {
  graphMeEndpoint: "https://graph.microsoft.com/v1.0/me",
};

/**
 * Note: Configuration for scopes, api endpoints and client ids can be
 * configured in whichever way feels the most natural and accessible way
 *  for you and your applications use-case. Please feel free to abstract them
 *  away from this file in whichever way you see fit.
 */
