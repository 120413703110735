import API from "..";

export default class WorkRequestFileAttachment {
  
  constructor({
    id,
    workRequestID,
    workRequest,
    fileName,
    filePath,
    sendWithShipment,
    isSDS
  }) 
    
  {
    this.id = id;
    this.workRequestID = workRequestID;
    this.workRequest = workRequest;
    this.fileName = fileName;
    this.filePath = filePath;
    this.sendWithShipment = sendWithShipment;
    this.isSDS = isSDS;
  }

  static async getAll(workRequestId) {
    const api = await API();
    const { data } = await api.get(`/workRequestFileAttachment/?workRequestId=${workRequestId}`);
    if (data.result) {
        return data.result.map((d) => new WorkRequestFileAttachment(d));
    }
    else {
        return [];
    }
  }

  static async postAttachs(info) {
    const api = await API('multipart/form-data');
    const { data } = await api.post('/workRequestFileAttachment', info);
    return new WorkRequestFileAttachment(data.result);
  }

  static async updateWorkRequest(info) {
    const api = await API();
    const { data } = await api.put('/workRequestFileAttachment', info);
    return data;
  }
}
