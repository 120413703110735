export default class POSearchReport {
  constructor({
    report,
    pOno,
    vendor,
    location,
    stockroomNo,
    description,
    bckOrder,
    qtyOrdered,
    poStatus,
    recvd,
  }) {
    this.report = report;
    this.pOno = pOno;
    this.vendor = vendor;
    this.location = location;
    this.stockroomNo = stockroomNo;
    this.description = description;
    this.qtyOrdered = qtyOrdered;
    this.bckOrder = bckOrder;
    this.poStatus = poStatus;
    this.recvd = recvd;
  }
}
