import { createContext, useState, useEffect } from "react";
import Item from "../../../api/Stockroom/Item";
import ModalSimpleButton from "../../../components/Modal/ModalSimpleButton";
import { Typography } from "@mui/material";

export const InventoryItemContext = createContext(null);

export const InventoryItemContextProvider = ({ children }) => {
  const [stockItems, setStockItems] = useState([]);
  const [stockItemsDict, setStockItemsDict] = useState({});
  const [isInventoryDataLoading, setIsInventoryDataLoading] = useState(false);
  const [hasError, setHasError] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [postResponse, setPostStockResponse] = useState([]);

  useEffect(() => {
    if (hasError) setIsModalOpen(true);
  }, [hasError]);

  const getAllStockItems = async () => {
    setIsInventoryDataLoading(true);
    await Item.getAll()
      .then((data) => {
        setIsInventoryDataLoading(false);
        const formattedData = data.map((item) => item?.ID);
        setStockItems(formattedData);

        const formattedStockItemsDict = Object.assign(
          {},
          ...formattedData.map((i) => ({ [i.id]: i })),
        );

        setStockItemsDict(formattedStockItemsDict);
      })
      .catch((err) => {
        setHasError(err);
        setIsModalOpen(true);
      });
  };

  const getStockItem = async (assetNumber) => {
    await Item.getItemByAssetNumber(assetNumber)
      .then((data) => {
        return data;
      })
      .catch((err) => {
        setHasError(err);
        setIsModalOpen(true);
      });
  };

  const manageStockItem = async (payload) => {
    return await Item.manageStockItem(payload).then((data) => {
      setPostStockResponse(data.ID);
    });
  };

  const clearInventoryStockList = () => {
    setStockItems([]);
  };

  return (
    <InventoryItemContext.Provider
      value={{
        getStockItem,
        getAllStockItems,
        manageStockItem,
        stockItems,
        stockItemsDict,
        setStockItemsDict,
        isInventoryDataLoading,
        clearInventoryStockList,
        postResponse,
      }}>
      {children}
      <ModalSimpleButton
        title={"Error"}
        buttonText={"Close"}
        buttonAction={() => {
          setIsModalOpen(false);
          setHasError("");
        }}
        setOpen={() => {
          setIsModalOpen(false);
          setHasError("");
        }}
        open={isModalOpen}>
        <Typography style={{ padding: "0 16px", fontSize: 18, textAlign: "center" }}>
          {hasError}
        </Typography>
      </ModalSimpleButton>
    </InventoryItemContext.Provider>
  );
};
