
import React, { useEffect, useState } from "react";
import { Dialog, Box, FormControl, Select, MenuItem, InputLabel, IconButton, Autocomplete, TextField, Collapse } from '@mui/material';
import "./index.css";
import { GlobalSecondaryButton } from "../../pages/styles";
import { StyledTableRow, StyledTableBody, StyledTableCell, UXDataTableWithoutBody } from "../../components/UXDataTable";
import { styled } from "@mui/material/styles";
import SearchIcon from '@mui/icons-material/Search';
import Supplier from "../../api/ChemInv/Supplier";
// import Tradename from "../../api/ChemInv/Tradename";
import SupplierTradename from "../../api/ChemInv/SupplierTradename";
import { convertDateFormat } from "../../global";
import { ChemIDSearchField } from '../../components/ChemIDSearch/index';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const StyledTableCellClickable = styled(StyledTableCell)(() => ({
    cursor: 'pointer'
}));

const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
})(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
    }),
}));

const STLCSearch = ({ open, setOpen, setClose, setLot, setReset, reset, showExternalSearch }) => {
    const [listSupplier, setListSupplier] = useState([]);
    const [listTradename, setListTradename] = useState([]);
    // const [listTradenameDropdown, setListTradenameDropdown] = useState([]);
    const [listLot, setListLot] = useState([]);
    const [supplierIsLoading, setSupplierIsLoading] = useState(false);
    // const [tradenameIsLoading, setTradenameIsLoading] = useState(false);
    const [supplierSelected, setSupplierSelected] = useState(null);
    const [tradenameSelected, setTradenameSelected] = useState(null);
    const [searchByField, setSearchByField] = useState('Supplier');
    const [searchField, setSearchField] = useState(null);
    const [errorChemID, setErrorChemID] = useState(false);

    const [supplierOpen, setSupplierOpen] = useState(true);
    const [tradenameOpen, setTradenameOpen] = useState(true);
    const [lotOpen, setLotOpen] = useState(true);

    const [rowsPerPageSupplier, setRowsPerPageSupplier] = useState(10);
    const [pageSupplier, setPageSupplier] = useState(0);
    const [rowsPerPageTradename, setRowsPerPageTradename] = useState(10);
    const [pageTradename, setPageTradename] = useState(0);
    const [rowsPerPageLot, setRowsPerPageLot] = useState(10);
    const [pageLot, setPageLot] = useState(0);

    const handleChangePageSupplier = (event, newPage) => {
        setPageSupplier(newPage);
    };

    const handleChangeRowsPerPageSupplier = (event) => {
        setRowsPerPageSupplier(parseInt(event.target.value, 10));
        setPageSupplier(0);
    };

    const handleChangePageTradename = (event, newPage) => {
        setPageTradename(newPage);
    };

    const handleChangeRowsPerPageTradename = (event) => {
        setRowsPerPageTradename(parseInt(event.target.value, 10));
        setPageTradename(0);
    };

    const handleChangePageLot = (event, newPage) => {
        setPageLot(newPage);
    };

    const handleChangeRowsPerPageLot = (event) => {
        setRowsPerPageLot(parseInt(event.target.value, 10));
        setPageLot(0);
    };

    useEffect(() => {
        async function getSuppliers() {

            setSupplierIsLoading(true)

            const response = await Supplier.getAll();
            if (response) {
                setListSupplier(response);
            } else {
                setListSupplier([]);
            }

            setSupplierIsLoading(false)
        }
        getSuppliers();
    }, [])

    async function getSuppliers() {

        setSupplierIsLoading(true)

        const response = await Supplier.getAll();
        if (response) {
            setListSupplier(response);
        } else {
            setListSupplier([]);
        }

        setSupplierIsLoading(false)
    }
    
    async function getTradenamesByChemID(chemID) {
        const response = await SupplierTradename.getByChemID(chemID, false, false);
        if (response) {
            setListTradename(response);
        } else {
            setListTradename([]);
        }
    }

    const colHeadersSupplier = [
        'Supplier Name',
        'Description'
    ]

    const colHeadersTradename = searchByField === 'ChemID' ? [
        'Supplier',
        'Tradename',
        'Default ChemID',
        'Shelf Life'
    ] : [
        'Tradename',
        'Default ChemID',
        'Shelf Life'
    ];

    const colHeadersLot = [
        'Lot Name',
        'Comment',
        'Certification Status',
        'Last Certified Date',
    ]

    const handleClose = () => {
        setSupplierSelected(null)
        setTradenameSelected(null)
        return setClose ? setClose() : setOpen(false)
    };

    function handleSelectedSupplier(supplier) {
        setSupplierSelected(supplier)
        setListTradename(supplier.tradenames)
        setListLot([])
    }

    function handleSelectedTradename(tradename) {
        setTradenameSelected(tradename)
        setListLot(tradename.lots)
    }

    function handleSelectedLot(lot) {
        let supplier = supplierSelected;
        if (!supplier) {
            supplier = listSupplier.find(f => f.tradenames.some(s => s.tradenameID === tradenameSelected.tradenameID));
        }
        setLot(lot, supplier, tradenameSelected)
        handleClose()
    }

    function handleClickTradename(tradename, supplier, close = true) {
        if (!supplier && !supplierSelected) {
            supplier = listSupplier.find(f => f.tradenames.some(s => s.tradenameID === tradename.tradenameID));
        }
        setLot(null, supplier ?? supplierSelected, tradename)
        if (supplier) {
            setSupplierSelected(supplier)
            setListTradename(supplier.tradenames)
            handleSelectedTradename(tradename)
        }
        if (close) handleClose()
    }

    const searchComponent = () => {
        switch (searchByField) {
            case 'ChemID':
                return <ChemIDSearchField
                    margin='none'
                    fontSize={16}
                    fieldWidth="400px"
                    selectedChemIDObject={searchField}
                    setSelectedChemIDObject={(value) => {
                        setSearchField(value)
                        if (value && value.chemID)
                            getTradenamesByChemID(value.chemID)
                    }}
                    hasErrors={errorChemID}
                    setHasErrors={(e) => setErrorChemID(e)}
                    allowInactiveChemIDs={false}
                    returnSubstanceStrucureData={false}
                />
            default:
                return <Autocomplete
                    size="small"
                    autoHighlight
                    autoSelect
                    noOptionsText={supplierIsLoading ? "Loading Suppliers..." : "No Suppliers Found"}
                    value={searchField}
                    onChange={(e, value) => {
                        setSearchField(value)
                        handleSelectedSupplier(value)
                    }}
                    disablePortal
                    options={listSupplier}
                    getOptionLabel={(option) => option?.supplierName}
                    sx={{ width: 400 }}
                    renderInput={(params) => <TextField size="small" {...params}
                        label="Supplier"
                    />}
                />
        }
    }

    const searchByComponent = () => {
        return <FormControl size="small" sx={{ m: 1, width: 200 }}>
            <InputLabel id="search-select-label">Find Lots by</InputLabel>
            <Select
                labelId="search"
                id="search-select"
                value={searchByField}
                label="Find Lots by"
                onChange={e => {
                    setSearchByField(e.target.value)
                    setSearchField(null)
                    setSupplierSelected(null)
                    setTradenameSelected(null)
                    setListTradename([])
                    setListLot([])
                }}
            >
                <MenuItem value={'Supplier'}>Supplier</MenuItem>
                {/* <MenuItem value={'Tradename'}>Tradename</MenuItem> */}
                <MenuItem value={'ChemID'}>ChemID</MenuItem>

            </Select>
        </FormControl>
    }

    useEffect(() => {
        const resetPage = () => {
            setListSupplier([]);
            setListTradename([]);
            // setListTradenameDropdown([]);
            setListLot([]);
            setSupplierSelected(null);
            setTradenameSelected(null);
            setSearchByField('Supplier');
            setSearchField(null);
            setErrorChemID(false);
            getSuppliers();
            setSupplierOpen(true);
            setTradenameOpen(true);
            setLotOpen(true);
        }
        if (reset) {
            resetPage()
            setReset(false)
        };
    }, [reset, setReset])

    // const heightTables = supplierSelected ?
    //     tradenameSelected ? '712px' : '566px' : '160px'

    let heightTables = tradenameSelected ?
        supplierSelected ? 722 : 584 : supplierSelected ? 576 : 170

    heightTables = tradenameSelected && !lotOpen ? heightTables - 200 : heightTables;
    heightTables = (supplierSelected || searchByField === 'ChemID' || searchByField === 'Tradename') && !tradenameOpen ? tradenameSelected ? heightTables - 148 : heightTables - 200 : heightTables;

    return (
        <>
            {showExternalSearch && <div className='stlc-search-box'>
                {searchByComponent()}
                {searchComponent()}
                <IconButton
                    aria-label="search-text"
                    onClick={() => setOpen(true)}
                    // onMouseDown={handleMouseDownPassword}
                    edge="end"
                >
                    <SearchIcon />
                </IconButton>
                {/* <FormControl sx={{ m: 1, minWidth: 400 }} variant="outlined" size='small'>
                    <InputLabel htmlFor="outlined-adornment-password">Search</InputLabel>
                    <OutlinedInput
                        label="Search"
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="search-text"
                                    onClick={() => setOpen(true)}
                                    // onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                >
                                    <SearchIcon />
                                </IconButton>
                            </InputAdornment>
                        }
                    />
                </FormControl> */}
            </div>}
            <Dialog
                fullWidth={true}
                maxWidth="xl"
                open={open}
                onClose={handleClose}
            >
                <Box className='container-stlc'>
                    <div className='header'>
                        <div className='header-box'>
                            <p>Search</p>
                            {searchByComponent()}
                            {searchComponent()}
                            {/* <FormControl sx={{ m: 1, minWidth: 400 }} variant="outlined" size='small'>
                                <InputLabel htmlFor="outlined-adornment-password">Search</InputLabel>
                                <OutlinedInput
                                    label="Search"
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="search-text"
                                                // onClick={handleClickShowPassword}
                                                // onMouseDown={handleMouseDownPassword}
                                                edge="end"
                                            >
                                                <SearchIcon />
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                />
                            </FormControl> */}

                        </div>
                        <GlobalSecondaryButton
                            variant='contained'
                            onClick={handleClose}
                        >Close</GlobalSecondaryButton>
                    </div>

                    <div className='content'>
                        {searchByField !== 'Tradename' && searchByField !== 'ChemID' && <div style={{ display: 'flex' }}>
                            <p>Suppliers</p>
                            <ExpandMore
                                expand={supplierOpen}
                                onClick={() => setSupplierOpen(!supplierOpen)}
                                aria-expanded={supplierOpen}
                                aria-label="show supplier"
                            >
                                <ExpandMoreIcon />
                            </ExpandMore>
                        </div>}
                        <Collapse in={supplierOpen} timeout="auto" unmountOnExit>
                            {searchByField !== 'Tradename' && searchByField !== 'ChemID' && <span>Select a supplier to view tradenames</span>}
                            {searchByField !== 'Tradename' && searchByField !== 'ChemID' && <div>
                                <UXDataTableWithoutBody
                                    tableWidth='100%'
                                    cols={colHeadersSupplier}
                                    blankFirstHeader={false}
                                    tableId="simpleResultTable"
                                    rowLength={listSupplier.length}
                                    noDataFoundMessage={'There is no supplier data available'}
                                    enableAddIcon={false}
                                    addFunction={null}
                                    addToolTipText={null}
                                    enableSorteable={false}
                                    isDataLoading={supplierIsLoading}
                                    stickyHeader={true}
                                    maxHeight={`calc(100vh - ${heightTables}px)`}
                                    enablePaging={true}
                                    rowsPerPage={rowsPerPageSupplier}
                                    page={pageSupplier}
                                    handleChangePage={handleChangePageSupplier}
                                    handleChangeRowsPerPage={handleChangeRowsPerPageSupplier}
                                >

                                    <StyledTableBody key={"myTablebody"}>
                                        {listSupplier.slice(pageSupplier * rowsPerPageSupplier, pageSupplier * rowsPerPageSupplier + rowsPerPageSupplier).map((supplier, rowIndex) => {
                                            const isSelected = supplierSelected === supplier;
                                            return (
                                                [
                                                    <StyledTableRow
                                                        hover
                                                        key={`custom-row-${rowIndex}`}
                                                        selected={isSelected}
                                                        style={{ background: isSelected ? 'rgba(25, 118, 210, 0.08)' : '' }}
                                                    >
                                                        <StyledTableCellClickable onClick={() => { handleSelectedSupplier(supplier) }}>{supplier.supplierName}</StyledTableCellClickable>
                                                        <StyledTableCellClickable onClick={() => { handleSelectedSupplier(supplier) }}>{supplier.description}</StyledTableCellClickable>
                                                    </StyledTableRow>
                                                ]
                                            );
                                        })}
                                    </StyledTableBody>
                                </UXDataTableWithoutBody>
                            </div>}
                        </Collapse>

                        {(supplierSelected || searchByField === 'ChemID' || searchByField === 'Tradename') && <div className="tradename">
                            {/* {searchByField !== 'Tradename' && searchByField !== 'ChemID' && tradenameOpen && <div className={`link-tables ${listSupplier.length > 0 ? 'link-with-itens' : ''}`} />} */}
                            <div style={{ display: 'flex' }}>
                                <p>Tradenames</p>
                                <ExpandMore
                                    expand={tradenameOpen}
                                    onClick={() => setTradenameOpen(!tradenameOpen)}
                                    aria-expanded={tradenameOpen}
                                    aria-label="show supplier"
                                >
                                    <ExpandMoreIcon />
                                </ExpandMore>
                            </div>
                            <Collapse in={tradenameOpen} timeout="auto" unmountOnExit>
                                <span>Select a tradename to view lots</span>
                                <div>
                                    <UXDataTableWithoutBody
                                        tableWidth='100%'
                                        cols={colHeadersTradename}
                                        blankFirstHeader={false}
                                        tableId="simpleResultTable"
                                        rowLength={listTradename.length}
                                        noDataFoundMessage={'There is no tradename data available'}
                                        enableAddIcon={false}
                                        addFunction={null}
                                        addToolTipText={null}
                                        enableSorteable={false}
                                        stickyHeader={true}
                                        maxHeight={`calc(100vh - ${heightTables}px)`}
                                        enablePaging={true}
                                        rowsPerPage={rowsPerPageTradename}
                                        page={pageTradename}
                                        handleChangePage={handleChangePageTradename}
                                        handleChangeRowsPerPage={handleChangeRowsPerPageTradename}
                                    >

                                        <StyledTableBody key={"myTablebody"}>
                                            {listTradename.slice(pageTradename * rowsPerPageTradename, pageTradename * rowsPerPageTradename + rowsPerPageTradename).map((tradename, rowIndex) => {
                                                const isSelected = tradenameSelected === tradename;
                                                return (
                                                    [
                                                        <StyledTableRow
                                                            hover
                                                            key={`custom-row-${rowIndex}`}
                                                            selected={isSelected}
                                                            style={{ background: isSelected ? 'rgba(25, 118, 210, 0.08)' : '' }}
                                                        >
                                                            {searchByField === 'ChemID' && <StyledTableCellClickable onDoubleClick={() => { handleClickTradename(tradename) }} onClick={() => { handleSelectedTradename(tradename) }}>{tradename.supplierName}</StyledTableCellClickable>}
                                                            <StyledTableCellClickable onDoubleClick={() => { handleClickTradename(tradename) }} onClick={() => { handleSelectedTradename(tradename) }}>{tradename.tradename.tradenameName}</StyledTableCellClickable>
                                                            <StyledTableCellClickable onDoubleClick={() => { handleClickTradename(tradename) }} onClick={() => { handleSelectedTradename(tradename) }}>{tradename.tradename.chemID}</StyledTableCellClickable>
                                                            <StyledTableCellClickable onDoubleClick={() => { handleClickTradename(tradename) }} onClick={() => { handleSelectedTradename(tradename) }}>{tradename.tradename.standardShelfLifeMonths}</StyledTableCellClickable>
                                                        </StyledTableRow>
                                                    ]
                                                );
                                            })}
                                        </StyledTableBody>
                                    </UXDataTableWithoutBody>
                                </div>
                            </Collapse>
                        </div>
                        }
                        {tradenameSelected && <div className="lot">
                            {/* {lotOpen && <div className={`link-tables ${listTradename.length > 0 ? 'link-with-itens' : ''}`} />} */}
                            <div style={{ display: 'flex' }}>
                                <p>Lots</p>
                                <ExpandMore
                                    expand={lotOpen}
                                    onClick={() => setLotOpen(!lotOpen)}
                                    aria-expanded={lotOpen}
                                    aria-label="show supplier"
                                >
                                    <ExpandMoreIcon />
                                </ExpandMore>
                            </div>
                            <Collapse in={lotOpen} timeout="auto" unmountOnExit>
                                <span>Double-click a lot to open</span>
                                <div>
                                    <UXDataTableWithoutBody
                                        tableWidth='100%'
                                        cols={colHeadersLot}
                                        blankFirstHeader={false}
                                        tableId="simpleResultTable"
                                        rowLength={listLot.length}
                                        noDataFoundMessage={'There is no lot data available'}
                                        enableAddIcon={false}
                                        addFunction={null}
                                        addToolTipText={null}
                                        enableSorteable={false}
                                        stickyHeader={true}
                                        maxHeight={`calc(100vh - ${heightTables}px)`}
                                        enablePaging={true}
                                        rowsPerPage={rowsPerPageLot}
                                        page={pageLot}
                                        handleChangePage={handleChangePageLot}
                                        handleChangeRowsPerPage={handleChangeRowsPerPageLot}
                                    >

                                        <StyledTableBody key={"myTablebody"}>
                                            {listLot.slice(pageLot * rowsPerPageLot, pageLot * rowsPerPageLot + rowsPerPageLot).map((lot, rowIndex) => {
                                                return (
                                                    [
                                                        <StyledTableRow
                                                            hover
                                                            key={`custom-row-${rowIndex}`}>
                                                            <StyledTableCellClickable onDoubleClick={() => { handleSelectedLot(lot) }}>{lot.lotName}</StyledTableCellClickable>
                                                            <StyledTableCellClickable onDoubleClick={() => { handleSelectedLot(lot) }}>{lot.comments}</StyledTableCellClickable>
                                                            <StyledTableCellClickable onDoubleClick={() => { handleSelectedLot(lot) }}>{lot.certificationStatusName}</StyledTableCellClickable>
                                                            <StyledTableCellClickable onDoubleClick={() => { handleSelectedLot(lot) }}>{convertDateFormat(lot.lastCertifiedDate)}</StyledTableCellClickable>
                                                        </StyledTableRow>
                                                    ]
                                                );
                                            })}
                                        </StyledTableBody>
                                    </UXDataTableWithoutBody>
                                </div>
                            </Collapse>
                        </div>
                        }
                    </div>
                </Box>
            </Dialog>
        </>
    );
}
export default STLCSearch;


