import API from '../index'

export default class EquipmentStatus {
    constructor(
        EquipmentStatusName,
        IsActive,
        DisplayColor,
        DisplayOrder
    ) {
        this.EquipmentStatusName = EquipmentStatusName
        this.IsActive = IsActive
        this.DisplayColor = DisplayColor
        this.DisplayOrder = DisplayOrder
    }

    static async getAll() {
        const api = await API()
        const { data } = await api.get('/equipmentstatus')
        return data.result.map(equipmentStatus => new EquipmentStatus(equipmentStatus))
    }

    static async getEquipmentStatusByName(statusName) {
        const api = await API();
        const { data } = await api.get(`/equipmentstatus?statusName=${statusName}`);
        return data.result.map((equipmentStatus) => new EquipmentStatus(equipmentStatus));      
    }
}