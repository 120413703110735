import API from "../index";
import StkReport from "./StkReport";
import OrderFreqReport from "./OrderFreqReport";
import POSearchReport from "./POSearchReport";

export default class Request {
  constructor({
    id,
    createdByEmail,
    createdDate,
    lastModifiedByEmail,
    lastModifiedDate,
    requestedForEmail,
    chargeCode,
    costCenter,
    comments,
    deliveryLocationName,
    completedDate,
    workOrderID,
    ignoreAlert,
    emergeRequest,
    orderStatusName,
    isSignatureRequired,
    requestItems,
    workOrderName,
    finalDestinationName,
    locationName,
    subLocation,
  }) {
    this.id = id;
    this.createdByEmail = createdByEmail;
    this.createdDate = createdDate;
    this.lastModifiedByEmail = lastModifiedByEmail;
    this.lastModifiedDate = lastModifiedDate;
    this.requestedForEmail = requestedForEmail;
    this.chargeCode = chargeCode;
    this.costCenter = costCenter;
    this.comments = comments;
    this.deliveryLocationName = deliveryLocationName;
    this.completedDate = completedDate;
    this.workOrderID = workOrderID;
    this.ignoreAlert = ignoreAlert;
    this.emergeRequest = emergeRequest;
    this.orderStatusName = orderStatusName;
    this.isSignatureRequired = isSignatureRequired;
    this.requestItems = requestItems;
    this.workOrderName = workOrderName;
    this.finalDestinationName = finalDestinationName;
    this.locationName = locationName;
    this.subLocation = subLocation;
  }

  static async getAllRequests() {
    const api = await API();
    const { data } = await api.get("/stockroomrequest");
    return data.result.map((request) => new Request(request));
  }

  static async getFilteredRequests(statuses, startDate, endDate, requester) {
    const api = await API();
    if (endDate) {
      const { data } = await api.get(
        `/stockroomrequest/search?statuses=${statuses}&startDate=${startDate}&endDate=${endDate}${
          requester ? "&requester=" + requester : ""
        } `,
      );
      return data.result.map((request) => new Request(request));
    } else {
      const { data } = await api.get(
        `/stockroomrequest/search?statuses=${statuses}&startDate=${startDate}`,
      );
      return data.result.map((request) => new Request(request));
    }
  }

  static async getRequestsForItem(itemID, searchFrom) {
    const api = await API();
    const { data } = await api.get(
      `/stockroomrequest/getRequestsByEquipmentID?equipmentID=${itemID}&minRequestDate=${searchFrom}`,
    );
    return data.result.map((request) => new Request(request));
  }

  static async getStockroomRequests(id, requester, orderStatus) {
    const api = await API();
    const { data } = await api.get(
      `/stockroomrequest?id=${id}&requester=${requester}&orderStatus=${orderStatus}`,
    );
    return data.result.map((request) => new Request(request));
  }

  static async createRequest(info) {
    const api = await API();
    let response = await api.post("/stockroomrequest", info);
    let { status, data } = response;
    return { status: status, data: data };
  }

  static async cancelRequest(id) {
    const api = await API();
    const { data } = await api.delete(`/stockroomrequest/${id}`);
    if (data.message === "Success") {
      return new Request(data.result);
    } else {
      return null;
    }
  }

  static async getFilteredRequestReturns(requester, id, siteAssetNumber) {
    let requestString = [];
    if (requester) requestString.push(`requester=${requester}`);
    if (id) requestString.push(`id=${id}`);
    if (siteAssetNumber) requestString.push(`siteAssetNumber=${siteAssetNumber}`);
    const api = await API();
    const { data } = await api.get(`/stockroomrequest/returns?${requestString.join("&")}`);
    return data.result.map((request) => new Request(request));
  }

  static async getStockroomReports(report) {
    const api = await API();
    const { data } = await api.post("/stockroomrequest/getReports", report);
    if (data.message === "Success" && data.result.length > 0) {
      if (data.result[0]?.report === "J") {
        return data.result.map((request) => new StkReport(request));
      } else if (data.result[0]?.report === "I") {
        return data.result.map((request) => new Request(request));
      } else if (data.result[0]?.report === "K") {
        return data.result.map((request) => new OrderFreqReport(request));
      } else if (data.result[0]?.report === "M") {
        return data.result.map((request) => new POSearchReport(request));
      }
    } else {
      return null;
    }
  }
}
