export default class OrderFreqReport {
  constructor({
    report,
    orders,
    issued,
    stockroomItem
  }) {
    this.report = report;
    this.orders = orders;
    this.issued = issued;
    this.stockroomItem = stockroomItem;
  }
}
