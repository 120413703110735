import React, { useState, useRef } from 'react';
import { GlobalSecondaryButton } from '../../pages/styles';
import "./index.css";
import { UXDataTableWithoutBody, StyledTableBody, StyledTableCell, StyledTableRow } from "../../components/UXDataTable";
import TrashIcon from '@mui/icons-material/Delete';
import { Tooltip } from "@mui/material";
import DownloadIcon from '@mui/icons-material/FileDownload';
import { downloadFile } from "../../global";
import ModalSimpleButton from "../../components/Modal/ModalSimpleButton";

//const FilterMenu = ({ cols, setFilters, open, setOpen, applyBtnAction, cancelButtonAction, setClose}) => {
const FileDragAndDrop = ({ handleFiles, files, disabled = false, showFiles = false, handleDeleteFile, container, handleFileDownload, message = 'Drag and drop your file here or' }) => {
  const [dragActive, setDragActive] = useState(false);
  const inputRef = useRef(null);

  const [modalMessagesOpen, setModalMessagesOpen] = useState(false)
  const [modalMessagesText, setModalMessagesText] = useState('')
  const [modalMessagesTitle, setModalMessagesTitle] = useState('')
  const [modalMessagesButtonText, setModalMessagesButtonText] = useState('Ok')

  // triggers the input when the button is clicked
  const onButtonClick = () => {
    inputRef.current.click();
  };

  // handle drag events
  const handleDrag = function (e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  const checkFiles = function (newFiles) {
    let filesChecked = [];

    for (let i = 0; i < newFiles.length; i++) {
      let file = newFiles[i];
      if (!files.find(a => a.name === file.name)) {
        filesChecked.push(file);
      }
    }
    return filesChecked
  }

  // triggers when file is dropped
  const handleDrop = function (e) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      const filesChecked = checkFiles(e.dataTransfer.files);
      if (filesChecked.length > 0) {
        handleFiles(filesChecked);
      }
    }
  };

  // triggers when file is selected with click
  const handleChange = function (e) {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      const filesChecked = checkFiles(e.target.files);
      if (filesChecked.length > 0) {
        handleFiles(filesChecked);
      }
    }
  };

  function downloadFileBlob(file) {
    if (file) {
      const a = document.createElement("a"); //Create <a>
      a.href = URL.createObjectURL(file);
      a.download = file.name; //File name Here
      a.click(); //Downloaded file
    }
  }

  function closeModalMessages() {
    setModalMessagesOpen(false);
    setModalMessagesText('')
    setModalMessagesButtonText('Ok')
    setModalMessagesTitle('')
  }

  function openModalMessage(title, message) {
    setModalMessagesOpen(true);
    setModalMessagesText(message)
    setModalMessagesTitle(title)
  }


  return (
    <div style={{ width: '100%' }}>
      {!disabled && (
        <form id="form-file-upload" onDragEnter={handleDrag} onSubmit={(e) => e.preventDefault()}>
          <input ref={inputRef} type="file" id="input-file-upload" multiple={true} onChange={handleChange} />
          <label id="label-file-upload" htmlFor="input-file-upload" className={dragActive ? "drag-active" : ""}>
            <div>
              <p style={{ paddingBottom: "1rem" }}>{message}</p>
              <GlobalSecondaryButton onClick={onButtonClick}>Upload a file</GlobalSecondaryButton>
            </div>
          </label>
          {dragActive && <div id="drag-file-element" onDragEnter={handleDrag} onDragLeave={handleDrag} onDragOver={handleDrag} onDrop={handleDrop}></div>}
        </form>
      )}
      {showFiles && <div style={{ marginTop: '20px' }}>
        <UXDataTableWithoutBody
          tableWidth='100%'
          cols={['File Name']}
          blankFirstHeader={true}
          tableId={`midasFileAttachments`}
          rowLength={files?.length ? files.length : 0}
          enablePaging={false}
          rowsPerPage={files?.length ? files.length : 0}
          page={0}
          handleChangePage={null}
          handleChangeRowsPerPage={null}
          noDataFoundMessage={'No Files Added'}
          enableAddIcon={false}
          addFunction={() => null}
          addToolTipText=""
          isDataLoading={false}
        >
          <StyledTableBody key={"myTablebody"}>
            {files && files.map((file, rowIndex) => {
              return (<StyledTableRow
                hover
                key={`custom-row-${rowIndex}`} >
                <StyledTableCell component='th' scope='row' style={{ width: '4em' }} align='center'>
                  <div style={{display: 'flex', justifyContent: 'space-between'}}>
                  {handleDeleteFile && (file.canDelete || file.canDelete === undefined) && <Tooltip title="Delete File" placement="right">
                    <TrashIcon onClick={() => handleDeleteFile(file)}>
                    </TrashIcon>
                  </Tooltip>}
                  {file.id > 0 &&
                    <Tooltip title="Download File" placement="right">
                      <DownloadIcon onClick={() => handleFileDownload ? handleFileDownload(file) : ((file.filePath || file.storagePath) && container ? 
                        downloadFile(container, file.fileName,  file.filePath ?? file.storagePath, openModalMessage) : 
                        downloadFileBlob(file))}>
                      </DownloadIcon>
                    </Tooltip>
                  }
                  </div>
                </StyledTableCell>
                <StyledTableCell>{file.name ?? file.fileName}</StyledTableCell>
              </StyledTableRow>
              );
            })}
          </StyledTableBody>
        </UXDataTableWithoutBody>
      </div>}
      <ModalSimpleButton title={modalMessagesTitle} buttonText={modalMessagesButtonText} buttonAction={closeModalMessages} open={modalMessagesOpen} setOpen={setModalMessagesOpen}>
        <label>
          {modalMessagesText}
        </label>
      </ModalSimpleButton>
    </div>
  );
}
export default FileDragAndDrop;


