import type { ReactNode } from "react";
import { createContext, useContext, useEffect, useRef, useState, useMemo } from "react";
import UnitOfMeasureApi from "../../../api/Admin/UnitOfMeasure";
import LocationApi from "../../../api/Admin/Location";
import ContainerTypeApi from "../../../api/LIMS/ContainerType";
import STStatusApi from "../../../api/StationaryTesting/STStatus";
import STQueueApi from "../../../api/StationaryTesting/STQueue";
import STRequestProcessResultApi from "../../../api/StationaryTesting/STRequestProcessResult";
import type { UoM, ContainerType, Location } from "../types";

export type DetailedStRequestContextProps = {
  stRequestId: number;
  actions: string[];
  canUpdateFields: boolean;
  uoms: UoM[];
  locations: Location[];
  containerTypes: ContainerType[];
  locationNames: string[];
  statusOptions: string[];
  queueOptions: string[];
  processComponentOptions: string[];
  canManageSampleSchedule: boolean;
  canManageAuthorizationValidation: boolean;
  canManageProcessResultDetails: boolean;
  canManageIntervalResultDetails: boolean;
  canManageFuelSamples: boolean;
  canManageGeneralAndTesting: boolean;
};

export const DetailedStRequestContext = createContext<DetailedStRequestContextProps>(
  {} as DetailedStRequestContextProps
);

type DetailedStRequestProviderProps = {
  children: ReactNode;
  stRequestId: number;
  actions: string[];
  canManageSampleSchedule: boolean;
  canManageAuthorizationValidation: boolean;
  canManageProcessResultDetails: boolean;
  canManageIntervalResultDetails: boolean;
  canManageFuelSamples: boolean;
  canManageGeneralAndTesting: boolean;
};

export const DetailedStRequestProvider = ({
  children,
  stRequestId,
  actions,
  canManageSampleSchedule,
  canManageAuthorizationValidation,
  canManageProcessResultDetails,
  canManageIntervalResultDetails,
  canManageFuelSamples,
  canManageGeneralAndTesting,
}: DetailedStRequestProviderProps) => {
  const mounted = useRef(false);
  const [uoms, setUoms] = useState<UoM[]>([]);
  const [locations, setLocations] = useState<Location[]>([]);
  const [containerTypes, setContainerTypes] = useState<ContainerType[]>([]);
  const [statusOptions, setStatusOptions] = useState<string[]>([]);
  const [queueOptions, setQueueOptions] = useState<string[]>([]);
  const [processComponentOptions, setProcessComponentOptions] = useState<string[]>([]);

  const canUpdateFields = useMemo(() => {
    if (actions && actions.length !== 0) {
      return actions.some((actionName) => actionName === "Save Updates");
    }

    return false;
  }, [actions]);

  async function fetchSelectOptions() {

    UnitOfMeasureApi.getAll().then((uoms) => {
      if (uoms) {
        setUoms(uoms);
      }
    });

    LocationApi.getAll().then((rawLocations) => {
      if (rawLocations) {
        const activeLocations = rawLocations.filter((location: Location) => location?.isActive);
        setLocations(activeLocations);
      }
    });

    ContainerTypeApi.getAll().then((rawContainerTypes) => {
      if (rawContainerTypes && rawContainerTypes.length > 0) {
        const filteredData = rawContainerTypes.filter(
          (containerType: ContainerType) => containerType?.isActive
        );
        setContainerTypes(filteredData);
      }
    });

    STStatusApi.getAllActiveStatusNamesExceptCancelled().then((status) => {
      if (status.length > 0) {
        setStatusOptions(status);
      }
    });

    STQueueApi.getAllActiveQueueOptions().then((queueOptions) => {
      if (queueOptions.length > 0) {
        setQueueOptions(queueOptions);
      }
    });

    STRequestProcessResultApi.getActiveStProcessComponentNames().then((names) => {
      if (names.length > 0) {
        setProcessComponentOptions(names);
      }
    });
  }

  useEffect(() => {
    mounted.current = true;
    const abortController = new AbortController();

    fetchSelectOptions();

    return () => {
      mounted.current = false;
      abortController.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const locationNames =
    locations && locations.length > 0 ? locations.map((location) => location?.locationName) : [];

  return (
    <DetailedStRequestContext.Provider
      value={{
        stRequestId,
        actions,
        canUpdateFields,
        containerTypes,
        locations,
        uoms,
        locationNames,
        queueOptions,
        statusOptions,
        processComponentOptions,
        canManageSampleSchedule: canUpdateFields && canManageSampleSchedule,
        canManageAuthorizationValidation: canUpdateFields && canManageAuthorizationValidation,
        canManageProcessResultDetails: canUpdateFields && canManageProcessResultDetails,
        canManageIntervalResultDetails: canUpdateFields && canManageIntervalResultDetails,
        canManageFuelSamples: canUpdateFields && canManageFuelSamples,
        canManageGeneralAndTesting: canUpdateFields && canManageGeneralAndTesting,
      }}
    >
      {children}
    </DetailedStRequestContext.Provider>
  );
};

export function useDetailedStRequest(): DetailedStRequestContextProps {
  const context = useContext(DetailedStRequestContext);

  return context;
}
