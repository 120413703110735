import API from "../index";

export default class StockroomPOItem {
  constructor({
    id,
    poid,
    thisPO,
    equipmentID,
    equipmentItem,
    lastModifiedByEmail,
    lastModifiedDate,
    itemQuantity,
    unitPriceUSD,
    receivedDate,
    receivedQuantity,
    orderStatusName,
    backorderedQuantity,
    backorderedDate,
    accountCode,
  }) {
    this.id = id;
    this.poid = poid;
    this.thisPO = thisPO;
    this.equipmentID = equipmentID;
    this.equipmentItem = equipmentItem;
    this.lastModifiedByEmail = lastModifiedByEmail;
    this.lastModifiedDate = lastModifiedDate;
    this.itemQuantity = itemQuantity;
    this.unitPriceUSD = unitPriceUSD;
    this.receivedDate = receivedDate;
    this.receivedQuantity = receivedQuantity;
    this.orderStatusName = orderStatusName;
    this.backorderedQuantity = backorderedQuantity;
    this.backorderedDate = backorderedDate;
    this.accountCode = accountCode;
  }

  static async getAll() {
    const api = await API();
    const { data } = await api.get("/stockroompoitem");
    return data.result.map((poItem) => new StockroomPOItem(poItem));
  }

  static async getStockroomPOItemById(itemID, poID) {
    const api = await API();
    if (itemID) {
      const { data } = await api.get(`/stockroompoitem?itemID=${itemID}`);
      return data.result.map((poItem) => new StockroomPOItem(poItem));
    } else if (poID) {
      const { data } = await api.get(`/stockroompoitem?poID=${poID}`);
      return data.result.map((poItem) => new StockroomPOItem(poItem));
    }
  }
}
