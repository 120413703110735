import { Box, Button, RadioGroup, TextareaAutosize, Typography, TextField, FormControl, OutlinedInput } from '@mui/material'
import Delete from "@mui/icons-material/Delete";
import { StyledTableCell } from '../../components/UXDataTable'
import { styled } from '@mui/material/styles';

export const StyledForm = styled('form')({
  display: 'flex',
  flexDirection: 'column',
});

export const StyledGridForm = styled('form')({
  display: 'grid',
  gridTemplateColumns: 'repeat(3, 1fr)',
  gridGap: 16,
});

export const StyledFormInRow = styled('form')({
  display: 'flex',
  alignItems: 'center',
  gap: 16,
});

export const StyledRadioGroup = styled(RadioGroup)({
  display: "grid",
  gridTemplateColumns: "1fr 1fr 1fr",
});

export const StyledCheckboxGroup = styled(Box)({
  display: "grid",
  gridTemplateColumns: "1fr 1fr",
  width: '30%',
});

export const StyledScrollableBox = styled(Box)({
  width: '100%',
  overflow: 'scroll',
  maxHeight: 300,
  paddingRight: 16,

  '&::-webkit-scrollbar': {
    width: 10,
    height: 10,
  },
  
  '&::-webkit-scrollbar-thumb': {
    background: '#3190D9',
    borderRadius: 4
  },
  
  '&::-webkit-scrollbar-track': {
    background: '#f1eded',
  }
});

export const StyledButton = styled(Button)({
  backgroundColor: "white",
  color: "#3190D9",
  fontSize: "13px",
  fontFamily: "EMprint",
  textTransform: "none",
  "&:hover": {
    color: "white",
  },
});

export const StyledTableCellSmall = styled(StyledTableCell)({
  width: 30,
});

export const StyledTableCellSmallHoverable = styled(StyledTableCell)({
  width: 30,
  cursor: 'pointer',

  '&:hover': {
    backgroundColor: '#c1c1ed',
  },
});

export const StyledTableCellMedium = styled(StyledTableCell)({
  width: 130,
});

export const StyledClickableTableCellMedium = styled(StyledTableCell)({
  width: 130,
  cursor: 'pointer',

  '&:hover': {
    backgroundColor: '#c1c1ed',
  },
});

export const StyledTableCellLarge = styled(StyledTableCell)({
  width: 260,
});


export const StyledErrorMessage = styled(Typography)({
  color: 'red',
  fontSize: 14,
  marginTop: 8
});

export const StyledTextareaAutosize = styled(TextareaAutosize)({
  resize: 'none',
  height: '200px !important',
  width: '450px !important',
  padding: 12,
});

export const StyledInput = styled(TextField)({
  '& .MuiInputBase-input': {
    padding: '8.5px 14px',
  },
});

export const StyledOutlinedInput = styled(OutlinedInput)({
  '& .MuiInputBase-input': {
    padding: '8.5px 14px',
  },
});

export const SmallImageBox = styled(Box)({
  display: "flex" ,
    alignItems: "center" ,
    justifyContent: "center",
    width: 200,
    height: 200,
    border: '1px solid black',
    borderRadius: 8,
    padding: 4,
    boxShadow: 'inset 2px 2px 6px 0 black',

    '& img': {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
    },
});

export const ImageBox = styled(Box)({
  display: "flex" ,
    alignItems: "center" ,
    justifyContent: "center",
    width: 800,
    height: 600,
    border: '1px solid black',
    borderRadius: 8,
    padding: 4,
    boxShadow: 'inset 2px 2px 6px 0 black',

    '& img': {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
    },
});

export const StyledFormControl = styled(FormControl)({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'row',
  gap: 8,
});

export const StyledRowForm = styled(Box)({
  display: 'flex',
    alignItems: 'center',
    marginBottom: 16,
    gap: 24,
});

export const StyledWarningBox = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  gap: 8,
  width: '60%',
  borderRadius: 8,
  padding: '8px 16px',
  backgroundColor: 'rgba(219, 218, 18, 0.15)',
  marginBottom: 16,
});

export const useStyles = styled(({ theme }) => ({
  icon: {
    margin: '0 auto',
    display: 'block',
  },
  closeIcon: {
    position: 'absolute',
    width: 30,
    height: 30,
    top: -10,
    right: -10,
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: '2px solid black',
    cursor: 'pointer',
    background: 'white',
  },
  actionCell: {
    display: 'flex',
    alignItems: 'center',
    gap: 8,
  },
}))

export const StyledDelete = styled(Delete)({
  '&:hover': {
    color: 'red',
  },
});
