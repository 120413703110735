import { createContext, useContext, useState } from "react";

type SqcMethodManagementContextProps = {
  activeLimitIndex: number | null;
  setActiveLimitIndex(value: number | null): void;
  activeAstmIndex: number | null;
  setActiveAstmIndex(value: number | null): void;
};

const SqcMethodManagementContext =
  createContext<SqcMethodManagementContextProps | null>(null);

type SqcMethodManagementProviderProps = {
  children: React.ReactNode;
};

export function SqcMethodManagementProvider({
  children,
}: SqcMethodManagementProviderProps) {
  const [activeLimitIndex, setActiveLimitIndex] = useState<number | null>(null);
  const [activeAstmIndex, setActiveAstmIndex] = useState<number | null>(null);

  return (
    <SqcMethodManagementContext.Provider
      value={{
        activeLimitIndex,
        setActiveLimitIndex,
        activeAstmIndex,
        setActiveAstmIndex,
      }}
    >
      {children}
    </SqcMethodManagementContext.Provider>
  );
}

export function useSqcMethodManagement() {
  const context = useContext(SqcMethodManagementContext);

  if (!context)
    throw Error(`There must be a ${SqcMethodManagementProvider.name} declared`);

  return context;
}
