import { createContext, useState } from "react";
import EquipmentType from "../../../api/Stockroom/EquipmentType";
import { useEffect } from "react";
import ModalSimpleButton from "../../../components/Modal/ModalSimpleButton";
import { Typography } from "@mui/material";

export const EquipmentTypeContext = createContext(null);

export const EquipmentTypeContextProvider = ({ children }) => {
  const [equipmentTypes, setEquipmentTypes] = useState([]);
  const [hasError, setHasError] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    if (hasError) setIsModalOpen(true);
  }, [hasError]);

  const getAllEquipmentTypes = async () => {
    await EquipmentType.getAll()
      .then((data) => {
        setEquipmentTypes(data);
      })
      .catch((err) => {
        setHasError(err);
        setIsModalOpen(true);
      });
  };

  const getEquipmentTypesByName = async (equipmentTypeName) => {
    await EquipmentType.getEquipmentStatusByName(equipmentTypeName)
      .then((data) => {
        setEquipmentTypes(data);
      })
      .catch((err) => {
        setHasError(err);
        setIsModalOpen(true);
      });
  };

  return (
    <EquipmentTypeContext.Provider
      value={{ equipmentTypes, getEquipmentTypesByName, getAllEquipmentTypes }}
    >
      {children}
      <ModalSimpleButton
        title={"Error"}
        buttonText={"Close"}
        buttonAction={() => {
          setIsModalOpen(false);
          setHasError("");
        }}
        setOpen={() => {
          setIsModalOpen(false);
          setHasError("");
        }}
        open={isModalOpen}
      >
        <Typography
          style={{ padding: "0 16px", fontSize: 18, textAlign: "center" }}
        >
          {hasError}
        </Typography>
      </ModalSimpleButton>
    </EquipmentTypeContext.Provider>
  );
};
