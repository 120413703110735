import React, { useState, useEffect, useContext } from "react";
import {Box, Typography, Checkbox, FormControlLabel, TextField, Autocomplete} from '@mui/material'
import ModalTwoButtons from '../../components/Modal/ModalTwoButtons'
import ModalSimpleButton from '../../components/Modal/ModalSimpleButton'
import TemplateTables from '../../components/TemplateTables'
import { OrderTemplateCutList, hasRole, Roles } from '../../global'
import UserContext from "../../context/UserContext";
import DistillationExperimentTemplate from '../../api/Distillation/DistillationExperimentTemplate'
import DistillationType from '../../api/Distillation/DistillationType'
import DistillationCutType from "../../api/Distillation/DistillationCutType";
import { GlobalButton, GlobalSecondaryButton } from "../styles";

const ManageDistillationTemplates = () => {
  const [experimentTemplates, setExperimentTemplates] = useState([])
  const [templateCuts, setTemplateCuts] = useState([])
  const [methods, setMethods] = useState([])
  const [selectedChemID, setSelectedChemID] = useState(null)
  const [distillationTypes, setDistillationTypes] = useState([])
  const [cutTypesList, setCutTypesList] = useState([])
  const [distillationType, setDistillationType] = useState('')
  const [templateName, setTemplateName] = useState('')
  const [nameInputValue, setNameInputValue] = useState('')
  const [isTemplateActive, setIsTemplateActive] = useState(true)
  const [isTemplatePublic, setIsTemplatePublic] = useState(false)
  const [isCloneModalOpen, setIsCloneModalOpen] = useState(false)
  // const [isCloneSuccessModalOpen, setIsCloneSuccessModalOpen] = useState(false)
  // const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false)
  // const [isSaveModalOpen, setIsSaveModalOpen] = useState(false)
  const [selectedTemplate, setSelectedTemplate] = useState({})
  const [selectedTemplateCut, setSelectedTemplateCut] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const [clonedTemplateName, setClonedTemplateName] = useState(null);

  const [modalMessagesNewOpen, setModalMessagesNewOpen] = useState(false);
  const modalMessagesNewButtonText = 'Ok'
  const [modalMessagesNewTitle, setModalMessagesNewTitle] = useState('');
  const [modalMessagesNewText, setModalMessagesNewText] = useState('');

  const [reloadData, setReloadData] = useState(false)

  const currentUser = useContext(UserContext)
  const roles = currentUser?.idTokenClaims?.roles
  const canManageTemplate = hasRole(Roles.Developer, roles)  || selectedTemplate.templateOwnerEmail === currentUser.username //|| hasRole(Roles.DistillationTemplateMaintainer, roles)

  const templateHasRequiredCutTypes = () => {
    if(distillationType === 'Vacuum'){
      return templateCuts.findIndex(x => x.distillationCutTypeName === 'Vacuum Charge') !== -1 &&
      templateCuts.findIndex(x => x.distillationCutTypeName === 'Vacuum Cut') !== -1 &&
      templateCuts.findIndex(x => x.distillationCutTypeName === 'Vacuum Resid') !== -1
    }
    if(distillationType === 'Atmospheric'){
      return templateCuts.findIndex(x => x.distillationCutTypeName === 'Atmospheric Charge') !== -1 &&
      templateCuts.findIndex(x => x.distillationCutTypeName === 'Atmospheric Cut') !== -1 &&
      templateCuts.findIndex(x => x.distillationCutTypeName === 'Atmospheric Resid') !== -1
    }
  } 

  function closeModalMessagesNew() {
    setModalMessagesNewOpen(false);
  }

  function openModalMessagesNew(title, text) {
    setModalMessagesNewOpen(true);
    setModalMessagesNewTitle(title);
    setModalMessagesNewText(text);
  }

  const handleTemplateCutsList = (cutList, templateType) => {
    const filteredCutTypesList = cutTypesList.filter(cutType => {
      if (templateType === 'Extractions') {
        return (
            cutType?.isExtraction
        )
      }
      else if (templateType === 'Atmospheric') {
        return (
            cutType?.isAtmospheric
        )
      }
      else if (templateType === 'Vacuum') {
        return (
            cutType?.isHighVacuum
        )
      } else {
        return cutType
      }
    })

    const listArray = OrderTemplateCutList(cutList, filteredCutTypesList)
    setTemplateCuts(listArray)
    //setTemplateCuts(Object.values(listArray).flatMap(item => item))
  }

  const handleCreateTemplate = async () => {
    setIsLoading(true)
    const formattedCutList = templateCuts.map((cut) => {
      if (cut?.id?.toString()?.includes('test-')) {
        const newCut = {
          ...cut,
          id: 0,
        }
        return newCut
      }
      return cut
    })

    const payload = {
      distillationTemplateName: templateName,
      templateOwnerEmail: null, 
      distillationTypeName: distillationType,
      thisDistillationType: null,
      templateCuts: formattedCutList,
      isActive: isTemplateActive,
      isPublic: isTemplatePublic, 
      createdDate: null,
      lastModifiedDate: null
    }


      DistillationExperimentTemplate.createTemplate(payload).then((res) =>  {

        setIsLoading(false)

        if (res.message === 'Success')
        {
          setTemplateCuts([])
          setMethods([])
          setSelectedTemplateCut({})
          setTemplateName('')
          setIsTemplateActive(true)
          setIsTemplatePublic(false)
          setDistillationType('')
          setSelectedChemID(null)
          // setIsSuccessModalOpen(true)
          setReloadData(!reloadData)

          openModalMessagesNew("Tempate Created", "Your template has been created successfully.")

        } else {
          openModalMessagesNew("Error Creating Template", `There was an error creating your template. ${res.message ? res.message : ''}`)
        }
      })



  }

  const handleSaveTemplate = async () => {
    setIsLoading(true)

    const formattedCutList = templateCuts.map((cut) => {
      if (cut?.id?.toString()?.includes('test-')) {
        const newCut = {
          ...cut,
          id: 0,
        }
        return newCut
      }
      return cut
    })
    const payload = {
      ...selectedTemplate,
      distillationTemplateName: templateName,
      isActive: isTemplateActive,
      isPublic: isTemplatePublic, 
      distillationTypeName: distillationType,
      templateCuts: formattedCutList,
    }

      DistillationExperimentTemplate.updateTemplate(payload).then((res) =>  {

        setIsLoading(false)

        if (res.message === 'Success')
        {
          setSelectedTemplate({})
          setSelectedTemplateCut({})
          setTemplateCuts([])
          setMethods([])
          setTemplateName('')
          setIsTemplateActive(true)
          setIsTemplatePublic(false)
          setDistillationType('')
          setNameInputValue('')
          setSelectedChemID(null)
          setReloadData(!reloadData)

          openModalMessagesNew("Template Updated", "Your template has been saved successfully.")
        } else {
          openModalMessagesNew("Error Updating Template", `There was an error creating your template. ${res.message ? res.message : ''}`)
        }
      })
  }

  function cloneTemplate () 
  {
    const updatedList = templateCuts.map(cut => {
      let newItem = {
        ...cut,
        id: 0,
        distillationTemplateName: clonedTemplateName,
      }
      return newItem
    })

    const payload = {
      distillationTemplateName: clonedTemplateName,
      templateOwnerEmail: null, 
      distillationTypeName: distillationType,
      thisDistillationType: null,
      templateCuts: updatedList,
      isActive: isTemplateActive,
      isPublic: isTemplatePublic, 
      createdDate: null,
      lastModifiedDate: null
    }


    DistillationExperimentTemplate.createTemplate(payload).then((res) =>  {

      setIsLoading(false)

      if (res.message === 'Success')
      {
        setTemplateCuts([])
        setMethods([])
        setSelectedTemplateCut({})
        setTemplateName('')
        setIsTemplateActive(true)
        setIsTemplatePublic(false)
        setDistillationType('')
        setSelectedChemID(null)
        // setIsSuccessModalOpen(true)
        setReloadData(!reloadData)

        openModalMessagesNew("Tempate Created", "Your template has been created successfully.")

      } else {
        openModalMessagesNew("Error Creating Template", `There was an error creating your template. ${res.message ? res.message : ''}`)
      }

      setIsCloneModalOpen(false)  
    })

    // setDistillationType(selectedTemplate?.distillationTypeName)
    // handleTemplateCutsList(updatedList, selectedTemplate?.distillationTypeName)
    // setTemplateName(clonedTemplateName)
    // setIsTemplateActive(selectedTemplate?.isActive)
    // setNameInputValue('')
    // setSelectedTemplate({})
    // setIsCloneModalOpen(false)  
    
    // handleCreateTemplate()
  }

  useEffect(() => {
    let cancelPromise = false

    setExperimentTemplates([])

    DistillationExperimentTemplate.searchTemplates(currentUser.username, true, true).then((res) => {
      if (cancelPromise) return
      setExperimentTemplates(res)
    });

    DistillationType.getAllDistillationTypes().then((res) => {
      if (cancelPromise) return
      setDistillationTypes(res.filter(result => result.isActive === true))
    });

    DistillationCutType.getAllCutTypes().then((res) => {
      setCutTypesList(res.filter(cutType => cutType.isActive === true))            
    })

    return () => {
      cancelPromise = true
    }
  }, [reloadData, currentUser.username])

  return (
    <Box>
      <Box display="flex" flexDirection="column" gap={2}>
        <Box display="flex" gap={2} alignItems="center">
          <Autocomplete
            disablePortal
            noOptionsText={"Loading Distillation Template Names..."}
            options={experimentTemplates.map((option) => option?.distillationTemplateName)}
            value={nameInputValue}
            onChange={(_, value) => {
              if (value) {
                const foundTemplate = experimentTemplates.find(experimentTemplate => experimentTemplate.distillationTemplateName === value)
                setSelectedTemplate(foundTemplate)
                setDistillationType(foundTemplate?.distillationTypeName)
                handleTemplateCutsList(foundTemplate?.templateCuts, foundTemplate?.distillationTypeName)
                setTemplateName(foundTemplate?.distillationTemplateName)
                setIsTemplateActive(foundTemplate?.isActive)
                setIsTemplatePublic(foundTemplate?.isPublic)
                setMethods([])
              } else {
                setSelectedTemplate({})
                setTemplateCuts([])
                setMethods([])
                setSelectedTemplateCut({})
                setDistillationType('')
                setTemplateName('')
              }
              setNameInputValue(value)
            }}
            isOptionEqualToValue={(option, value) => value === option}
            renderInput={(params) => <TextField {...params} label="Select Distillation Template" size="small" />}
            autoSelect
            style={{ width: '60%' }} 
          />

          <TextField size="small" variant="outlined" label="Template Owner" InputLabelProps={{shrink: true}}
            style={{ width: '20%' }} 
            value={selectedTemplate.templateOwnerEmail}
            disabled={true}
          />
        </Box>
        <Box display="flex" gap={2} alignItems="center">
          <TextField 
            type="text" 
            size="small" 
            label="Template Name*" 
            style={{ width: '60%' }} 
            value={templateName}
            //disabled={!canManageTemplate}
            onChange={(e) => setTemplateName(e.target.value)}
            inputProps={{
              maxLength: 60,
            }}
          />

          <Autocomplete
            disablePortal           
            noOptionsText={"Loading Distillation Types..."}
            options={distillationTypes.map(option => option?.distillationTypeName)}
            value={distillationType}
            onChange={(_, value) => {
                if (value) {
                    setDistillationType(value)
                } else {
                    setDistillationType('')
                }
            }}
            //disabled={!canManageTemplate}
            isOptionEqualToValue={(option, value) => value === option}
            renderInput={(params) => <TextField {...params} label="Distillation Type" size="small" />}
            autoSelect
            style={{ width: '20%' }}
          />

          <FormControlLabel
            label="Active"
            control={
              <Checkbox 
                name="isActive" 
                checked={isTemplateActive} 
                onChange={e => setIsTemplateActive(e.target.checked)} 
              />
            }
          />

          <FormControlLabel
            label="Public"
            control={
              <Checkbox 
                name="isPublic" 
                checked={isTemplatePublic} 
                onChange={e => setIsTemplatePublic(e.target.checked)} 
              />
            }
          />
        </Box>
      </Box>
      <Box marginTop={3} marginBottom={3} display="flex" flexDirection="column">
        <TemplateTables
          templateName={templateName}
          methods={methods}
          setMethods={setMethods}
          templateCuts={templateCuts}
          setTemplateCuts={setTemplateCuts}
          selectedTemplateCut={selectedTemplateCut}
          setSelectedTemplateCut={setSelectedTemplateCut}
          selectedChemID={selectedChemID}
          setSelectedChemID={setSelectedChemID}
          distillationType={distillationType}
          isReadOnly={!distillationType} //|| !canManageTemplate}
          showMethodSearchTemplateName={!!distillationType}
        />
      </Box>
      <Box display="flex" gap={2} paddingBottom={3}>
        <GlobalButton 
          variant="contained"
          disabled={
            !templateName || 
            isLoading || 
            Object.entries(selectedTemplate).length ||
            !templateHasRequiredCutTypes()
          }
          onClick={handleCreateTemplate}
        >
          Create New Template
        </GlobalButton>
        <GlobalButton 
          variant="contained" 
          disabled={
            !Object.entries(selectedTemplate).length || 
            isLoading ||
            !canManageTemplate ||            
            !templateHasRequiredCutTypes()
          }
          onClick={handleSaveTemplate}
        >
          Save Template
        </GlobalButton>
        <GlobalSecondaryButton 
          variant="contained" 
          disabled={!Object.entries(selectedTemplate).length }
          onClick={() => setIsCloneModalOpen(true)}
        >
          Clone Template
        </GlobalSecondaryButton>
      </Box>

        <ModalTwoButtons 
          title={`Cloning ${templateName}`}
          button1Text="Clone"
          button1Action={() => {cloneTemplate()}}
          isButton1Disabled={clonedTemplateName === null || clonedTemplateName === ""}
          button2Text="Cancel"
          button2Action={() => {setIsCloneModalOpen(false)}}
          setOpen={setIsCloneModalOpen}
          open={isCloneModalOpen}
        >
          <Box display="flex" flexDirection="column" gap={2}>
            <Typography>Type the new name of the Cloned Template:</Typography>
            <TextField 
               onChange={(e) => setClonedTemplateName(e.target.value)}
              size="small" 
              type="text" 
              name="newName" 
              label="New Name" 
            />
          </Box>
        </ModalTwoButtons>

      {/* Informational Messages */}
     <ModalSimpleButton title={modalMessagesNewTitle} buttonText={modalMessagesNewButtonText} buttonAction={closeModalMessagesNew} open={modalMessagesNewOpen} setOpen={setModalMessagesNewOpen}>
          {Array.isArray(modalMessagesNewText) ?
          (modalMessagesNewText && modalMessagesNewText.map((text, index) => {
          return (
               <div style={{display:"flex"}} key={`myChecks${index}`}>
                    <label>
                    {text}
                    </label>
               </div>
          )
          }))
          :
          ( <label>
          {modalMessagesNewText}
          </label>
          )}      
     </ModalSimpleButton>
    </Box>
  );
};

export default ManageDistillationTemplates;