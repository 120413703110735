import React from "react";

const ProjectManagement = ({ ...props }) => {
    return (
      <div>
        <p>
          Coming soon to a browser near you!
        </p>
          <ul>
            <li>Projects</li>
          </ul>
      </div>
    );
  };
  
  export default ProjectManagement;