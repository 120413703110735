import API from "..";

export default class WorkRequestFacility {

  constructor({
    facilityName,
    isActive,
    siteName
  }) 
    
  {
    this.facilityName = facilityName;
    this.isActive = isActive;
    this.siteName = siteName
  }

  static async getAll() {
    const api = await API();
    const { data } = await api.get(`/workRequestFacility`);
    if (data.result) {
        return data.result.map((d) => new WorkRequestFacility(d));
    }
    else {
        return [];
    }
  }

  static async getAllActive() {
    const api = await API();
    const { data } = await api.get(`/workRequestFacility`);
    if (data && data.result && data.result.length > 0) {
        return data.result.filter((d) => d.isActive);
    }
    else {
        return [];
    }
  }
}
