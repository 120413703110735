import {
  NonChecmicalItemCols,
  Option,
  StyledAutocomplete,
  StyledDiv,
  StyledDivider,
  defaultShippingItem,
  sampleGridFontSize,
  subsectionTitle,
} from "../constants";
import { TextField, Tooltip } from "@mui/material";
import {
  StyledTableBody,
  StyledTableCell,
  StyledTableRow,
  UXDataTableWithoutBody,
} from "../../../../components/UXDataTable";
import TrashIcon from "@mui/icons-material/Delete";

export default function WorkRequestNonChemicalTable({
  myWorkRequest,
  unitOfMeasureLoading,
  unitOfMeasure,
  nonChemicalShipping,
  setNonChemicalShipping,
}) {
  function UpdateNonChemicalTable(property, value, index) {
    let itemsCopy = structuredClone(nonChemicalShipping);
    itemsCopy[index][property] = value;

    setNonChemicalShipping(itemsCopy);
  }
  function DeleteNonChemicalRow(index) {
    let copyShippingItems = structuredClone(nonChemicalShipping);
    copyShippingItems.splice(index, 1);
    setNonChemicalShipping(copyShippingItems);
  }

  function AddNewNonChemicalItemsRow() {
    const copyDefaultShippingItem = structuredClone(defaultShippingItem);
    setNonChemicalShipping((prev) => [...prev, copyDefaultShippingItem]);
  }
  return (
    <div>
      <StyledDivider />
      <StyledDiv>
        <p style={subsectionTitle}>Shipping Non-chemical Items</p>
      </StyledDiv>

      <StyledDiv>
        <UXDataTableWithoutBody
          tableWidth="100%"
          cols={NonChecmicalItemCols}
          blankFirstHeader={true}
          tableId="ShippingItems"
          rowLength={nonChemicalShipping.length}
          enablePaging={false}
          rowsPerPage={0}
          page={0}
          handleChangePage={null}
          handleChangeRowsPerPage={null}
          noDataFoundMessage="No items added"
          enableAddIcon={!myWorkRequest.id}
          addFunction={() => AddNewNonChemicalItemsRow()}
          addToolTipText="Add new item"
          isDataLoading={false}
        >
          <StyledTableBody key="WRNonChemicalTable">
            {nonChemicalShipping.map((item, index) => (
              <StyledTableRow key={`WRNonChemicalTable~Row${index}`}>
                <StyledTableCell component="th" scope="row">
                  {index > 0 && (
                    <Tooltip title="Delete Sample" placement="right">
                      <TrashIcon onClick={() => DeleteNonChemicalRow(index)} />
                    </Tooltip>
                  )}
                </StyledTableCell>
                <StyledTableCell component="th" scope="row">
                  <TextField
                    sx={{ width: "800px" }}
                    size="small"
                    value={item.description ?? ""}
                    onChange={(e) => {
                      UpdateNonChemicalTable(
                        "description",
                        e.target.value,
                        index
                      );
                    }}
                  />
                </StyledTableCell>

                <StyledTableCell align="left">
                  <TextField
                    style={{ width: "200px", marginTop: "5px" }}
                    disabled={false}
                    size="small"
                    margin="dense"
                    variant="outlined"
                    type="number"
                    inputProps={{ style: { fontSize: sampleGridFontSize } }}
                    InputProps={{
                      inputProps: {
                        min: 0,
                      },
                    }}
                    InputLabelProps={{ shrink: true }}
                    value={item.quantity ? item.quantity < 0 ? 0 : item.quantity : ""}
                    onChange={(e) => {
                      UpdateNonChemicalTable("quantity", e.target.value, index);
                    }}
                  />
                </StyledTableCell>

                {/* UoM */}
                <StyledTableCell align="right">
                  <StyledAutocomplete
                    renderOption={(props2, option) => (
                      <Option {...props2}>{option}</Option>
                    )}
                    disabled={false}
                    disablePortal
                    noOptionsText={
                      unitOfMeasureLoading ? "Loading UoMs..." : "No UoMs Found"
                    }
                    options={unitOfMeasure
                      .map((x) => x.uoMName)
                      .sort((a, b) =>
                        a.toLowerCase().localeCompare(b.toLowerCase())
                      )}
                    onChange={(e, value) => {
                      UpdateNonChemicalTable("quantityUoMName", value, index);
                    }}
                    value={item.quantityUoMName ?? null}
                    autoHighlight
                    style={{ marginBottom: "5px" }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        style={{ width: "200px" }}
                        variant="outlined"
                        margin="dense"
                        size="small"
                        error={false}
                        inputProps={{
                          ...params.inputProps,
                          style: { fontSize: sampleGridFontSize },
                        }}
                        InputProps={{ ...params.InputProps }}
                      />
                    )}
                  />
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </StyledTableBody>
        </UXDataTableWithoutBody>
      </StyledDiv>
    </div>
  );
}
