import React, { useContext, useState } from "react";
import {
    Box,
    TextField
} from '@mui/material'
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { StockroomRequestContext } from "../context/StockroomRequestContext";
import {
    StyledTableBody,
    StyledTableRow,
    UXDataTableWithoutBody,
} from "../../../components/UXDataTable";
import { StyledTableCellSmall} from "../styles";
import { DatePickerKeyDownEvent } from "../../../global";
import {GlobalButton} from "../../styles"

const buildPOCols = [
    "Stock #",
    "Description",
    "Acct",
    "InStock",
    "Price",
    "ROQuantity",
    "ROPoint",
    "ROType",
    "Issued",
    "NoOrders"
];

export const OrderFreqReport = ({ canEdit }) => {
    const day = new Date().getDate()
    const month = new Date().getMonth()
    const year = new Date().getFullYear()

    const [dateFrom, setDateFrom] = useState(null)
    const [dateTo, setDateTo] = useState(new Date(year, month, day))
    const [chargesReportRowsPerPage, setChargesReportRowsPerPage] = useState(10);
    const [chargesReportPage, setChargesReportPage] = useState(0);
    const [chargesReport, setChargesReport] = useState([])
    const { getStockroomReports } = useContext(StockroomRequestContext);

    const handleCreateReport = async () => {
        const payload = {
            Report: "K",
            StartDate: dateFrom,
            EndDate: dateTo
        };

        let report = await getStockroomReports(payload);
        setChargesReport(report);
    };

    const handleChangeReportRowsPerPage = (event) => {
        setChargesReportRowsPerPage(parseInt(event.target.value, 10));
        setChargesReportPage(0);
    };

    const handleBuildReportPageChange = (event, page) => {
        setChargesReportPage(page);
    };

    return (
        <Box>
            <Box display="flex" gap={4} marginBottom={4}>
                <Box display="flex" alignItems="center" gap={2}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DesktopDatePicker
                            disabled={!canEdit}
                            label="Search From"
                            maxDate={new Date()}
                            value={dateFrom}
                            onChange={e => setDateFrom(e)}
                            renderInput={(params) => <TextField onKeyDown={DatePickerKeyDownEvent} size="small" {...params} />}
                        />

                        <DesktopDatePicker
                            disabled={!canEdit}
                            label="To"
                            minDate={dateFrom}
                            value={dateTo}
                            maxDate={new Date()}
                            onChange={e => setDateTo(e)}
                            renderInput={(params) => <TextField onKeyDown={DatePickerKeyDownEvent} size="small" {...params} />}
                        />
                    </LocalizationProvider>
                </Box>

                <Box display="flex" alignItems="center">
                    <GlobalButton
                        disabled={!canEdit}
                        variant="contained"
                        onClick={handleCreateReport}
                    >
                        Create report
                    </GlobalButton>
                </Box>
            </Box>
            <Box>
                <UXDataTableWithoutBody
                    tableWidth="100%"
                    cols={buildPOCols}
                    tableId="chargesTable"
                    rowLength={chargesReport?.length}
                    enablePaging={true}
                    rowsPerPage={chargesReportRowsPerPage}
                    page={chargesReportPage}
                    handleChangePage={handleBuildReportPageChange}
                    handleChangeRowsPerPage={handleChangeReportRowsPerPage}
                    noDataFoundMessage={"No charges found"}
                >
                    <StyledTableBody>
                        {chargesReport?.length > 0 &&
                            chargesReport.slice(
                                chargesReportPage * chargesReportRowsPerPage,
                                chargesReportPage * chargesReportRowsPerPage +
                                chargesReportRowsPerPage
                            )
                                .map((item) => {
                                    return (
                                        <StyledTableRow>
                                            <StyledTableCellSmall>
                                                {item?.stockroomItem.siteAssetNumber}
                                            </StyledTableCellSmall>
                                            <StyledTableCellSmall>
                                                {item?.stockroomItem.description}
                                            </StyledTableCellSmall>
                                            <StyledTableCellSmall>
                                                {item?.stockroomItem.costCenter}
                                            </StyledTableCellSmall>
                                            <StyledTableCellSmall>{item?.stockroomItem.quantity}</StyledTableCellSmall>
                                            <StyledTableCellSmall>{item?.stockroomItem.equipmentPriceUSD}</StyledTableCellSmall>
                                            <StyledTableCellSmall>{item?.stockroomItem.reorderQuantity}</StyledTableCellSmall>
                                            <StyledTableCellSmall>{item?.stockroomItem.reorderPoint}</StyledTableCellSmall>
                                            <StyledTableCellSmall>{item?.stockroomItem.isAutomaticReorder ? 'Automatic' : 'Non-automatic'}</StyledTableCellSmall>
                                            <StyledTableCellSmall>
                                                {item?.issued}
                                            </StyledTableCellSmall>
                                            <StyledTableCellSmall>
                                                {item?.orders}
                                            </StyledTableCellSmall>
                                        </StyledTableRow>
                                    )
                                })}
                    </StyledTableBody>
                </UXDataTableWithoutBody>
            </Box>
        </Box>
    )
}