import API from '../index'

export default class Image {
    constructor(
        ID,
        FileName,
        StoragePath,
    ) {
        this.ID = ID
        this.FileName = FileName
        this.StoragePath = StoragePath
    }

    static async getAll() {
        const api = await API()
        const { data } = await api.get('/stockroomimage')
        return data.result.map(image => new Image(image))
    }

    static async getImageById(id) {
        const api = await API();
        const { data } = await api.get(`/stockroomimage?id=${id}`);
        return data.result.map((image) => new Image(image));
    }
}