import { PublicClientApplication, EventType } from "@azure/msal-browser";
import { msalConfigAdfs } from "./msalConfigAdfs";
import { msalConfigAzureAd } from "./msalConfigAzureAd";
import { isAdfs } from "./isAdfs";

/**
 * Main MSAL setup file
 *
 * For documentation on msal-browser, please see:
 *   https://github.com/AzureAD/microsoft-authentication-library-for-js/tree/dev/lib/msal-browser
 *
 */

// create our apps instance of MSAL using our configuration settings
let msalInstance = null;
let accounts = null;

function setMsalEvents() {
  accounts = msalInstance.getAllAccounts();
  if (accounts.length > 0) {
    msalInstance.setActiveAccount(accounts[0]);
    window.localStorage.setItem('HOME_ACCOUNT_ID', accounts[0].homeAccountId);
    window.localStorage.removeItem("SHOULD_LOGIN");
  }

  msalInstance.addEventCallback((event) => {
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload?.account) {
      const account = event.payload.account;
      msalInstance.setActiveAccount(account);
      window.localStorage.removeItem("SHOULD_LOGIN");
    }
  });

}

function configureInstance() {
  try {

    //alert(window.location.href);
    if (window.localStorage["AUTH_PROVIDER"] === null || window.localStorage["AUTH_PROVIDER"] === undefined) {
        if (isAdfs()) {
          msalInstance = new PublicClientApplication(msalConfigAdfs);
          window.localStorage.setItem("AUTH_PROVIDER", "Adfs");
          setMsalEvents();
        }
        else {
          msalInstance = new PublicClientApplication(msalConfigAzureAd);
          window.localStorage.setItem("AUTH_PROVIDER", "AzureAd");
          setMsalEvents();
        }
    }
    else {
      if (window.localStorage.getItem("AUTH_PROVIDER") === "Adfs") {
        msalInstance = new PublicClientApplication(msalConfigAdfs);
        setMsalEvents();
      }
      else {
        msalInstance = new PublicClientApplication(msalConfigAzureAd);
        window.localStorage.setItem("AUTH_PROVIDER", "AzureAd");
        setMsalEvents();
      }
    }
  } catch (error) {
    console.log(`msalError-${error}`);
    msalInstance = new PublicClientApplication(msalConfigAzureAd);
    window.localStorage.setItem("AUTH_PROVIDER", "AzureAd");
    setMsalEvents();
  }
}

configureInstance();

export default msalInstance;
