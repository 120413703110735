import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { useFormik } from "formik";
import { testStandSchema } from "../shared";
import type { TestStand } from "../types";

type TestStandModalProps = {
  initialValues: TestStand;
  isModalOpen: boolean;
  setModalOpen(newValue: boolean): void;
  standList: string[];
  add(newValue: TestStand): void;
};

export default function TestStandModal({
  initialValues,
  isModalOpen,
  setModalOpen,
  standList,
  add,
}: TestStandModalProps) {
  const formik = useFormik({
    initialValues,
    validationSchema: testStandSchema,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: async (values) => add(values),
  });

  return (
    <Dialog
      fullWidth={true}
      maxWidth="sm"
      open={isModalOpen}
      onClose={() => setModalOpen(false)}
    >
      <DialogTitle>Add Test Stand</DialogTitle>
      <DialogContent>
        <Box
          display="grid"
          gap={2}
          gridTemplateColumns="1fr"
          style={{ marginTop: "1rem" }}
        >
          <Autocomplete
            id="standName"
            options={standList}
            renderInput={(params) => (
              <TextField {...params} label="Stand Name" size="small" />
            )}
            onChange={(_, v) => {
              formik.setFieldValue("standName", v);
            }}
            value={formik.values.standName}
            size="small"
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button disabled={!formik.isValid} onClick={() => formik.submitForm()}>
          add
        </Button>
      </DialogActions>
    </Dialog>
  );
}
