import { FormControl } from "@mui/material";
import React from "react";
import { StyledTextareaAutosize } from "../styles";
import { Box } from "@mui/system";
import ModalTwoButtons from "../../../components/Modal/ModalTwoButtons";

export default function UpdateItemComment({
  selectedItems,
  selectedItem,
  comment,
  setSelectedItems,
  setSelectedItem,
  setIsModalOpen,
  setComment,
  setModalType,
  isModalOpen,
}) {
  return (
    <ModalTwoButtons
      title="Add/Edit Item's comment"
      button1Text="Update/Add"
      button1Action={() => {
        const updatedList = selectedItems.map((item) => {
          if (item?.id === selectedItem?.id) {
            let updatedItem = JSON.parse(JSON.stringify(item));
            updatedItem = {
              ...updatedItem,
              comments: comment,
            };
            return updatedItem;
          }
          return item;
        });
        setSelectedItems(updatedList);
        setSelectedItem({});
        setIsModalOpen(false);
        setComment("");
        setModalType("");
      }}
      button2Text="Cancel"
      button2Action={() => {
        setIsModalOpen(false);
        setSelectedItem({});
        setComment("");
        setModalType("");
      }}
      setOpen={setIsModalOpen}
      open={isModalOpen}
    >
      <Box display="flex" flexDirection="column" alignItems="center" gap={2}>
        <FormControl>
          <StyledTextareaAutosize
            name="textarea"
            value={comment}
            onChange={(event) => setComment(event.target.value)}
          />
        </FormControl>
      </Box>
    </ModalTwoButtons>
  );
}
