import Box from "@mui/material/Box";
import { REQUEST_INFORMATION_ENUM } from "../../shared";
import Table from "@mui/material/Table";
import { StyledScrollableBox, StyledTableCellLarge } from "../../styles";
import {
  StyledTableBody,
  StyledTableCell,
  StyledTableRow,
} from "../../../../components/UXDataTable";
import type { LubricantSamples } from "../../types";
import { columnsAdditionalLubricantSamplesTable } from "../../tableColumns";

type StationaryTestLubeSampleViewProps = {
  lubricantSamples: LubricantSamples[];
};

export default function StationaryTestLubeSampleView({
  lubricantSamples,
}: StationaryTestLubeSampleViewProps) {

  const tableColumns = lubricantSamples.some(ls => Boolean(ls.viscosityGrade)) 
    ? [...columnsAdditionalLubricantSamplesTable, {headerName: "Viscosity Grade", field: "viscosityGrade"}] 
    : columnsAdditionalLubricantSamplesTable;

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <span
        style={{
          textAlign: "left",
          font: "normal normal 600 20px/24px EMprint",
          letterSpacing: 0,
        }}
      >
        {REQUEST_INFORMATION_ENUM.STATIONARY_TEST_LUBE_SAMPLES}
      </span>
      <Box display="grid" gridTemplateColumns="1fr" gap={2} mt={1}>
        <StyledScrollableBox>
          <Table>
            <StyledTableBody>
              <StyledTableRow>
                {tableColumns.map((col) => (
                  <StyledTableCell key={`custom-header-${col.headerName}`}>
                    {col.headerName}
                  </StyledTableCell>
                ))}
              </StyledTableRow>
              {lubricantSamples.map((lubricantSample) => {
                return (
                  <StyledTableRow
                    key={`${lubricantSample.containerId}${lubricantSample.sampleName}`}
                  >
                    <StyledTableCellLarge>
                      {lubricantSample.sampleName}
                    </StyledTableCellLarge>
                    <StyledTableCellLarge>
                      {lubricantSample.containerNumber}
                    </StyledTableCellLarge>
                    <StyledTableCellLarge>
                      {lubricantSample.amountUsed}
                    </StyledTableCellLarge>
                    <StyledTableCellLarge>
                      {lubricantSample.amountUsedUoMName}
                    </StyledTableCellLarge>
                    <StyledTableCellLarge>
                      {lubricantSample.usedReturnLocation}
                    </StyledTableCellLarge>
                    <StyledTableCellLarge>
                      {lubricantSample.unusedReturnLocation}
                    </StyledTableCellLarge>
                    {lubricantSample.viscosityGrade && (
                      <StyledTableCellLarge>
                        {lubricantSample.viscosityGrade}
                      </StyledTableCellLarge>
                    )}
                  </StyledTableRow>
                );
              })}
            </StyledTableBody>
          </Table>
        </StyledScrollableBox>
      </Box>
    </div>
  );
}
