import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TextField from "@mui/material/TextField";
import { useFormikContext } from "formik";
import { SqcMethodManagementForm } from "../../form";
import Stack from "@mui/material/Stack";
import { useState } from "react";

type TemperaturesModalProps = {
  onClose(): void;
};

export function TemperaturesModal({ onClose }: TemperaturesModalProps) {
  const formik = useFormikContext<SqcMethodManagementForm>();
  const [temperature, setTemperature] = useState<string | null>(null);

  const handleAdd = () => {
    if (!temperature) return;

    formik.setFieldValue(
      "allowedTemperatures",
      formik.values.allowedTemperatures.concat({ temperature: +temperature })
    );

    setTemperature(null);
  };

  const handleDelete = (index: number) => {
    formik.setFieldValue(
      "allowedTemperatures",
      formik.values.allowedTemperatures.filter((t, i) => i !== index)
    );
  };

  const isInputValid = Boolean(
    temperature && /^\d{1,4}(?:\.\d)?$/.test(temperature)
  );

  const alreadyExists = Boolean(
    temperature &&
      formik.values.allowedTemperatures.some(
        (a) => a.temperature === +temperature
      )
  );

  return (
    <Dialog open={true} onClose={onClose}>
      <DialogTitle>Temperatures</DialogTitle>

      <DialogContent dividers>
        <Stack gap="1rem">
          <Stack direction="row" gap="0.5rem" sx={{ alignItems: "center" }}>
            <TextField
              label="Temperature"
              size="small"
              value={temperature ?? ""}
              onChange={(event) => setTemperature(event.target.value)}
              required
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">°C</InputAdornment>
                ),
              }}
              inputProps={{
                step: 0.1,
                style: {
                  textAlign: "right",
                },
              }}
            />

            <Button
              type="button"
              disabled={!isInputValid || alreadyExists}
              onClick={handleAdd}
            >
              Add
            </Button>
          </Stack>

          <TableContainer>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell sx={{ width: "4rem" }} />
                  <TableCell>Temperature</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {formik.values.allowedTemperatures.map((a, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      <IconButton onClick={() => handleDelete(index)}>
                        <DeleteForeverIcon />
                      </IconButton>
                    </TableCell>
                    <TableCell>{a.temperature}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Stack>
      </DialogContent>

      <DialogActions>
        <Button type="button" onClick={onClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
