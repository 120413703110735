import { useState, useEffect, useMemo } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Autocomplete from "@mui/material/Autocomplete";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";
import {
  StyledTableCellMedium,
  StyledTableCellLarge,
  StyledScrollableBox,
} from "../styles";
import ModalTwoButtons from "../../../components/Modal/ModalTwoButtons";
import {
  StyledTableBody,
  StyledTableRow,
} from "../../../components/UXDataTable";
import STMethodClassification from "../../../api/StationaryTesting/STMethodClassification";
import STProcedure from "../../../api/StationaryTesting/STProcedure";
import { useFormik } from "formik";
import {
  TEST_SELECTION_INITIAL_VALUES,
  getLabelForProcedureByNumberAndName,
  getParameterColumnsByProcedure,
  stRequestSelectTestSchema,
} from "../shared";
import { toast } from "react-hot-toast";
import  Table  from "@mui/material/Table";
import  TableCell  from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";
import { CircularProgress } from "@mui/material";

const StyledTableCell = styled(TableCell)(() => ({
  verticalAlign: 'middle',
  textAlign: "left",
  borderBottomColor: "#D2D2D2",
  fontWeight: 600,
  color: "rgba(0, 0, 0, 0.8)",
  zIndex: 3,
    position: 'sticky',
    top: '0px',
    background: "#fff"
}));

export const SelectTestModal = ({
  setIsSelectTestModalOpen,
  save,
  initialValues,
  setPredefinedSampleExpectedUseUoMName,
  setPrefinedSampleExpectedUse,
  isCandidate,
  setIsCandidate,
  testStandName,
  setTestStandName,
  isLocked,
}) => {
  const [procedureTypes, setProcedureTypes] = useState([]);
  const [proceduresList, setProceduresList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  async function getAllProcedures() {
    const allProcedures = await STProcedure.getAll();
    const activeProcedures = allProcedures.filter((p) => p.isActive);
    setProceduresList(activeProcedures);
  }

  async function getAllTypes() {
    const activeTypes = await STMethodClassification.getAllActiveForStRequestCreation();
    const classificationNames = activeTypes.map((at) => at.classificationName);
    setProcedureTypes(classificationNames);
  }

  async function getAllSelectOptions() {
    setIsLoading(true);

    await getAllTypes();
    await getAllProcedures();

    setIsLoading(false);
  }

  useEffect(() => {
    getAllSelectOptions();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: stRequestSelectTestSchema,
    onSubmit: () => {},
  });

  const filteredProcedureList = useMemo(() => {
    const filtered = proceduresList
      ? proceduresList.filter((procedure) => {
          let filtered = false;

          if (
            procedure.classificationName &&
            formik.values.testClassificationType
          ) {
            filtered =
              procedure.classificationName ===
              formik.values.testClassificationType;
          }

          return filtered;
        })
      : [];

    return filtered.length > 0
      ? filtered.map((fp) => {
          return {
            label: getLabelForProcedureByNumberAndName(
              fp.procedureNumber,
              fp.procedureName
            ),
            value: fp.procedureNumber,
          };
        })
      : [];
  }, [formik.values.testClassificationType, proceduresList]);

  async function handleGetProcedureByProcedureOption(procedureOption) {
    setIsLoading(true);
    if (procedureOption && procedureOption.value && procedureOption.label) {
      formik.setFieldValue("procedureNumber", procedureOption.value);
      formik.setFieldValue("procedureLabel", procedureOption.label);

      const procedure = await STProcedure.getProcedureByNumberRaw(
        procedureOption.value
      );

      const leadEngineer =
        procedure.availableEngineers && procedure.availableEngineers.length > 0
          ? procedure.availableEngineers.find(
              (engineer) => engineer.isPrimaryEngineer
            )
          : null;

      if (leadEngineer) {
        formik.setFieldValue(
          "leadEngineerEmail",
          leadEngineer.engineerEmailAddress
        );
      }

      const backupEngineers =
        procedure.availableEngineers && procedure.availableEngineers.length > 0
          ? procedure.availableEngineers.filter(
              (engineer) => engineer.isPrimaryEngineer === false
            )
          : null;

      if (backupEngineers) {
        const backupEngineerEmails = backupEngineers
          .map((engineer) => engineer.engineerEmailAddress)
          .join(", ");

        formik.setFieldValue("backupEngineerEmails", backupEngineerEmails);
      }

      if (procedure.workInstructionName) {
        formik.setFieldValue(
          "workInstructionName",
          procedure.workInstructionName
        );
      }

      const parameterColumns = getParameterColumnsByProcedure(procedure);

      if (parameterColumns) {
        formik.setFieldValue("parameterColumns", parameterColumns);
      }

      if (procedure && procedure.procedureTests) {
        formik.setFieldValue("procedureTests", procedure.procedureTests);
      }

      if (procedure) {
        formik.setFieldValue("showViscosity", procedure.showViscosity);
      }
    }
    setIsLoading(false);
  }

  function handleUpdateValue(
    updatedValue,
    updatedProcedureTest,
    parameterColumn
  ) {
    const procedureTestIndex = formik.values.procedureTests.findIndex(
      (pt) => pt.testDisplayName === updatedProcedureTest.testDisplayName
    );

    if (procedureTestIndex !== -1) {
      const auxiliarProcedureTests = [...formik.values.procedureTests];

      const availableParametersIndex = auxiliarProcedureTests[
        procedureTestIndex
      ].availableParameters.findIndex(
        (ap) => ap.testParameterName === parameterColumn
      );

      if (availableParametersIndex !== -1) {
        auxiliarProcedureTests[procedureTestIndex].availableParameters[
          availableParametersIndex
        ] = {
          ...auxiliarProcedureTests[procedureTestIndex].availableParameters[
            availableParametersIndex
          ],
          parameterValue: updatedValue,
        };

        formik.setFieldValue("procedureTests", auxiliarProcedureTests);
      }
    }
  }

  function handleSubmitForm() {
    const procedureTestTestDisplayNameValid =
      formik.values.procedureTestTestDisplayName &&
      formik.values.procedureTestTestDisplayName !== "";

    const minimumDataValid = procedureTestTestDisplayNameValid;

    if (!minimumDataValid) {
      return toast.error("You need to select a Procedure Name and Test");
    }

    const selectedTest = formik.values.procedureTests.find(
      (pt) => pt.testDisplayName === formik.values.procedureTestTestDisplayName
    );

    if (!selectedTest) {
      return toast.error("Test selection is required");
    }

    const parametersValuesWithSelect = selectedTest.availableParameters.some(
      (ap) => ap.parameterValue === "SELECT"
    );

    if (parametersValuesWithSelect) {
      return toast.error(
        "You need to select a valid option to one of the test parameters"
      );
    }

    save({
      ...formik.values,
      requestParameters: selectedTest.availableParameters,
    });

    setIsSelectTestModalOpen(false);
  }

  function handleResetForm(resetAll) {
    if (resetAll) {
      formik.setValues(TEST_SELECTION_INITIAL_VALUES);
    } else {
      formik.setFieldValue(
        "requestParameters",
        TEST_SELECTION_INITIAL_VALUES.requestParameters
      );
      formik.setFieldValue(
        "procedureTestTestDisplayName",
        TEST_SELECTION_INITIAL_VALUES.procedureTestTestDisplayName
      );
      formik.setFieldValue(
        "procedureLabel",
        TEST_SELECTION_INITIAL_VALUES.procedureLabel
      );
      formik.setFieldValue(
        "leadEngineerEmail",
        TEST_SELECTION_INITIAL_VALUES.leadEngineerEmail
      );
      formik.setFieldValue(
        "backupEngineerEmails",
        TEST_SELECTION_INITIAL_VALUES.backupEngineerEmails
      );
      formik.setFieldValue(
        "workInstructionName",
        TEST_SELECTION_INITIAL_VALUES.workInstructionName
      );
      formik.setFieldValue(
        "parameterColumns",
        TEST_SELECTION_INITIAL_VALUES.parameterColumns
      );
      formik.setFieldValue(
        "procedureTests",
        TEST_SELECTION_INITIAL_VALUES.procedureTests
      );
      formik.setFieldValue(
        "testDescription",
        TEST_SELECTION_INITIAL_VALUES.testDescription
      );
      formik.setFieldValue(
        "minimumSampleSizeUoMName",
        TEST_SELECTION_INITIAL_VALUES.minimumSampleSizeUoMName
      );
      formik.setFieldValue(
        "minimumSampleSize",
        TEST_SELECTION_INITIAL_VALUES.minimumSampleSize
      );
      formik.setFieldValue(
        "showViscosity",
        TEST_SELECTION_INITIAL_VALUES.showViscosity
      );
      formik.setFieldValue("availableTestStands", []);
    }
    setTestStandName("");
  }

  function handleProcedureTestClick(procedureTest) {
    formik.setFieldValue(
      "procedureTestTestDisplayName",
      procedureTest.testDisplayName
    );
    formik.setFieldValue(
      "testDescription",
      procedureTest.testDescription
    );
    formik.setFieldValue(
      "minimumSampleSizeUoMName",
      procedureTest.minimumSampleSizeUoMName
    );
    setPredefinedSampleExpectedUseUoMName(
      procedureTest.minimumSampleSizeUoMName
    );
    formik.setFieldValue(
      "minimumSampleSize",
      procedureTest.minimumSampleSize
    );
    formik.setFieldValue(
      "testLabel",
      `${procedureTest.testDisplayName}: ${procedureTest.testName}`
    );
    if (procedureTest.minimumSampleSize) {
      setPrefinedSampleExpectedUse(
        +procedureTest.minimumSampleSize
      );
    }

    setTestStandName("");

    if (
      procedureTest &&
      procedureTest.availableTestStands &&
      procedureTest.availableTestStands.length > 0
    ) {
      const options =
        procedureTest.availableTestStands.map(
          (ats) => ats.standName
        );
      formik.setFieldValue(
        "availableTestStands",
        options
      );
    } else {
      formik.setFieldValue(
        "availableTestStands",
        []
      );
    }
  }

  return (
    <ModalTwoButtons
      title="Select Stationary Test"
      button1Text="Ok"
      button1Action={handleSubmitForm}
      button2Text="Cancel"
      button2Action={() => {
        setIsSelectTestModalOpen(false);
      }}
      setOpen={setIsSelectTestModalOpen}
      open={true}
    >
      <Box display="flex" flexDirection="column" gap={2}>
        <Typography fontSize={20}>
          Choose from the available Test Procedure Names:
        </Typography>
        <Box display="grid" gridTemplateColumns="1fr 3fr" gap={2}>
          <Autocomplete
            noOptionsText="Loading Test Types..."
            value={formik.values.testClassificationType}
            onChange={(_, value) => {
              handleResetForm(true);
              formik.setFieldValue("testClassificationType", value ?? "");
            }}
            isOptionEqualToValue={(option, value) => value === option}
            disablePortal
            options={procedureTypes}
            renderInput={(params) => (
              <TextField {...params} label="Test Type" size="small" />
            )}
            autoSelect
            disabled={isLocked}
          />

          <Autocomplete
            noOptionsText="Loading Procedure Names..."
            value={formik.values.procedureLabel}
            isOptionEqualToValue={(option) =>
              option.label === formik.values.procedureLabel
            }
            name="procedureName"
            onChange={(_, procedureOption) => {
              handleResetForm(false);
              handleGetProcedureByProcedureOption(procedureOption);
            }}
            options={filteredProcedureList}
            renderInput={(params) => (
              <TextField {...params} label="Procedure Name" />
            )}
            autoSelect
            size="small"
            disabled={isLocked}
          />
        </Box>
        {isLoading ? (
          <CircularProgress />
        ) : (
          <>
            <Box display="grid" gridTemplateColumns="1fr 1fr" gap={2}>
              <TextField
                value={formik.values.leadEngineerEmail}
                label="Test Engineer"
                InputProps={{ readOnly: true }}
                size="small"
                disabled
              />
              <TextField
                value={formik.values.backupEngineerEmails}
                label="Backup Engineer(s)"
                InputProps={{ readOnly: true }}
                size="small"
                disabled
              />
            </Box>
            <Box display="flex" alignItems="center">
              <Checkbox
                id="isCandidate"
                size="small"
                onChange={setIsCandidate}
                checked={isCandidate}
                disabled={isLocked}
              />

              <label
                htmlFor="isCandidate"
                style={{ font: "normal normal 400 14px/16px EMprint" }}
              >
                Is Candidate Test?
              </label>
            </Box>
            <Box display="flex" flexDirection="column" width="1200px">
              <Typography fontSize={18} marginBottom={2}>
                Select from the following available tests (and enter parameter value
                if necessary):
              </Typography>
              <StyledScrollableBox>
                <Table>
                  {formik.values.parameterColumns ? (
                    formik.values.parameterColumns.map((col) => (
                      <StyledTableCell key={`custom-header-${col}`}>
                        {col}
                      </StyledTableCell>
                    ))
                  ) : (
                    <StyledTableCell></StyledTableCell>
                  )}
                  <StyledTableBody>
                    {formik.values.procedureTests.map((procedureTest) => {
                      const isRowSelected =
                        formik.values.procedureTestTestDisplayName ===
                        procedureTest.testDisplayName;

                      return (
                        <StyledTableRow
                          key={procedureTest.testDisplayName}
                          hover
                          style={{
                            border: isRowSelected && "3px solid #1976D2",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            if (!isRowSelected && !isLocked) {
                              handleProcedureTestClick(procedureTest);
                            }
                          }}
                        >
                          <StyledTableCellLarge>
                            {procedureTest.testDisplayName} -{" "}
                            {procedureTest.testName}
                          </StyledTableCellLarge>
                          {[...formik.values.parameterColumns]
                            .slice(1)
                            .map((parameterColumn, parameterColumnIndex) => {
                              const parameter =
                                procedureTest.availableParameters?.find(
                                  (avProcedure) =>
                                    avProcedure.testParameterName ===
                                    parameterColumn
                                );

                              return !parameter ? (
                                <StyledTableCellMedium
                                  key={parameterColumnIndex}
                                  style={{ backgroundColor: "#d0d0d0" }}
                                />
                              ) : isRowSelected && parameter.canUpdate ? (
                                <StyledTableCellMedium key={parameterColumnIndex}>
                                  {parameter.selectOptions.length ? (
                                    <Autocomplete
                                      noOptionsText="Loading Options..."
                                      value={parameter.parameterValue}
                                      onChange={(_, value) => {
                                        handleUpdateValue(
                                          value ?? "",
                                          procedureTest,
                                          parameterColumn
                                        );
                                      }}
                                      disablePortal
                                      options={parameter.selectOptions}
                                      fullWidth
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          value={parameter.parameterValue}
                                          onChange={(e) => {
                                            if (e.target) {
                                              handleUpdateValue(
                                                e.target.value ?? "",
                                                procedureTest,
                                                parameterColumn
                                              );
                                            }
                                          }}
                                          />
                                      )}
                                      autoSelect
                                      size="small"
                                      disabled={isLocked}
                                    />
                                  ) : (
                                    <TextField
                                      onChange={(e) => {
                                        if (e && e.target) {
                                          handleUpdateValue(
                                            e.target.value ?? "",
                                            procedureTest,
                                            parameterColumn
                                          );
                                        }
                                      }}
                                      placeholder="Enter value"
                                      value={parameter?.parameterValue}
                                      label="Value"
                                      size="small"
                                      fullWidth
                                      disabled={isLocked}
                                    />
                                  )}
                                </StyledTableCellMedium>
                              ) : (
                                <StyledTableCellMedium key={parameterColumnIndex}>
                                  {parameter?.parameterValue}
                                </StyledTableCellMedium>
                              );
                            })}
                        </StyledTableRow>
                      );
                    })}
                  </StyledTableBody>
                </Table>
              </StyledScrollableBox>
            </Box>

            <Box display="grid" gridTemplateColumns="1fr 3fr">
              <Autocomplete
                noOptionsText="No options found"
                value={testStandName}
                onChange={(_, value) => {
                  setTestStandName(value ?? "");
                }}
                isOptionEqualToValue={(option, value) => value === option}
                disablePortal
                options={formik.values.availableTestStands}
                renderInput={(params) => (
                  <TextField {...params} label="Test Stand Name" size="small" />
                )}
                autoSelect
                disabled={
                  !formik.values.availableTestStands ||
                  formik.values.availableTestStands.length === 0 ||
                  isLocked
                }
              />
            </Box>

            <Box display="grid" gridTemplateColumns="1fr 1fr 1fr">
              <Box display="flex" flexDirection="column">
                <Typography fontSize={16}>Work Instruction #:</Typography>
                <Typography fontSize={16}>
                  {formik.values.workInstructionName}
                </Typography>
              </Box>
              <Box display="flex" flexDirection="column">
                <Typography fontSize={16}>Minimum Sample Size:</Typography>
                <Typography fontSize={16}>
                  {formik.values.minimumSampleSize}
                </Typography>
              </Box>
              <Box display="flex" flexDirection="column">
                <Typography fontSize={16}>Minimum Sample UoM:</Typography>
                <Typography fontSize={16}>
                  {formik.values.minimumSampleSizeUoMName}
                </Typography>
              </Box>
            </Box>

            <Box display="flex">
              <Box display="flex" flexDirection="column" width="100%">
                <Typography fontSize={16}>Test Procedure Description:</Typography>
                <TextareaAutosize
                  style={{
                    width: "100%",
                    resize: "none",
                    paddingTop: "1rem",
                    paddingLeft: "1rem",
                    fontSize: 16,
                  }}
                  minRows={5}
                  readOnly
                  value={formik.values.testDescription}
                  disabled
                />
              </Box>
            </Box>
          </>
        )}
      </Box>      
    </ModalTwoButtons>
  );
};
