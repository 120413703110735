import API from "../../api";

export default class MethodCategory {
  /**
   * The MethodCategory dimension table for M3
   * @param {String} methodCategoryName The name of the MethodCategory
   * @param {String} description The full name of the MethodCategory
   */

  constructor({
    methodCategoryName,
    description }) {
    this.methodCategoryName = methodCategoryName;
    this.description = description;
  }

  static async getAll() {
    const api = await API();
    const { data } = await api.get(`/methodCategory`);
    return data.result.map((d) => new MethodCategory(d));
  }

  static async get(id) {
    const api = await API();
    const { data } = await api.get(`methodCategory/${id}`);
    if (data.result) {
      return new MethodCategory(data.result);
    }
    else {
      return null;
    }

  }

  static async add(info) {
    const api = await API();
    const { data } = await api.post(`/methodCategory`, info);
    return new MethodCategory(data.result);
  }

  static async update(id, info) {
    const api = await API();
    const { data } = await api.put(`/methodCategory/${id}`, info);
    return new MethodCategory(data.result);
  }
}
