import React, { useEffect, useState } from "react";
import { styled, useTheme } from "@mui/material/styles";
import { Autocomplete, TextField } from "@mui/material";
import { createFilterOptions } from "@mui/material/Autocomplete";
import { outlinedInputClasses } from "@mui/material/OutlinedInput";
import { inputLabelClasses } from "@mui/material/InputLabel";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import InputAdornment from "@mui/material/InputAdornment";
import ModalTwoButtons from "../../components/Modal/ModalTwoButtons";
import Substance from "../../api/LIMS/Substance";
import ModalMessages from "../../components/Modal/ModalSimpleButton"

const autoCompleteFontSize = 14;

const StyledAutocomplete = styled(Autocomplete)({
  "& .MuiAutocomplete-input, & .MuiInputLabel-root": {
    fontSize: autoCompleteFontSize,
  },
});

const Option = styled("li")({
  fontSize: autoCompleteFontSize,
});

const filterOptions = createFilterOptions({
  matchFrom: "any",
  limit: 100,
});

const ChemIDSearchField = ({fontSize, fieldWidth, selectedChemIDObject, setSelectedChemIDObject, hasErrors, setHasErrors, isDisabled, helperText, margin, labelCustom, allowInactiveChemIDs, returnSubstanceStrucureData}) => {

  const theme = useTheme();

  const [modalChemIDOpen, setModalChemIDOpen] = React.useState(false);
  const [modalChemIDButton1Text, setModalChemIDButton1Text] = React.useState("");
  const [modalChemIDButton2Text, setModalChemIDButton2Text] = React.useState("");
  const [modalChemIDTitle, setModalChemIDTitle] = React.useState("");
  const [modalChemIDText, setModalChemIDText] = React.useState("");

  const [modalMessagesOpen, setModalMessagesOpen] = useState(false);
  const modalMessagesButtonText = 'Ok'
  const [modalMessagesTitle, setModalMessagesTitle] = useState('');
  const [modalMessagesText, setModalMessagesText] = useState('');

  const [availableSubstances, setAvailableSubstances] = useState([]);
  const [substanceSearchCriteria, setSubstanceSearchCriteria] = useState("");
  const [chemIDSearchValue, setChemIDSearchValue] = useState("");

  const [coloring, setColoring] = useState(theme.palette.primary);
  const [label, setLabel] = useState("Chem ID Search"); 
  
  function closeModalMessages() {
    setModalMessagesOpen(false);
  }

  function openModalMessages(title, text) {
    setModalMessagesOpen(true);
    setModalMessagesTitle(title);
    setModalMessagesText(text);
  }

  const handleChemIDSearchClick = () => 
  {
    setSubstanceSearchCriteria(chemIDSearchValue);
  };

  function closeModalChemIDs() 
  {
    if (selectedChemIDObject === null) {
      setLabel("Chem ID Search");
      setColoring(theme.palette.primary);
    }
    setModalChemIDOpen(false);
  }

  function openModalChemIDs(title, text, buttonText, button2Text) 
  {
    setModalChemIDButton1Text(buttonText);
    setModalChemIDButton2Text(button2Text);
    setModalChemIDOpen(true);
    setModalChemIDTitle(title);
    setModalChemIDText(text);
  }

  const confirmChemIDSelection = () => {
    setLabel("Valid Chem ID");
    setColoring("green");
    setHasErrors(false);
    closeModalChemIDs();
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter" || event.key === "Tab") 
    {
      setSubstanceSearchCriteria(event.target.value);
    }
  };

  const fieldChange = (value) => {
    if (selectedChemIDObject === null) {
      setChemIDSearchValue(value);
    } else {
      setChemIDSearchValue(value);
      setSelectedChemIDObject(null);
      setLabel("Invalid Chem ID");
      setColoring(theme.palette.primary);
    }
  };

  //get all the Substances
  useEffect(() => {
    let cancelPromise = false;

    if (substanceSearchCriteria && substanceSearchCriteria !== "") 
    {
      Substance.getByChemID(substanceSearchCriteria, true, returnSubstanceStrucureData).then((res) => {
        if (cancelPromise) return;

        if (res && res.message === 'Success' && res.result) 
        {
          if (res.result.isActive === false && allowInactiveChemIDs === false)
          {
            setSelectedChemIDObject(null);
            setLabel("Inactive Chem ID");
            setColoring(theme.palette.primary);
            setHasErrors(true)
          } else {
            setLabel("Valid Chem ID");
            setColoring("green");
            setHasErrors(false);
            setSelectedChemIDObject(res.result);
            setModalChemIDOpen(false);
          }
        } else {
          openModalChemIDs(`Chem ID Search for ${chemIDSearchValue}`,"Please select your Chem ID from the list below","OK","Cancel");

          Substance.search(substanceSearchCriteria, allowInactiveChemIDs ? allowInactiveChemIDs : false).then((res) => {
            if (cancelPromise) return;
            
            if (res && res.message === 'Success') 
            {
              setAvailableSubstances(res.result);
            } else {
              openModalMessages("Error Searching Chem IDs", `There was an error getting Chem IDs.  ${res ? res.message : ''}`)
            }
          });
        }
      });
    }

    return () => {
      cancelPromise = true;
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [substanceSearchCriteria]);

  useEffect(() => {
    if (selectedChemIDObject && selectedChemIDObject !== null) 
    {
      setLabel("Valid Chem ID");
      setColoring("green");
    } else {
      setLabel("Chem ID Search");
      setColoring(theme.palette.primary);
      setChemIDSearchValue("");
      setSubstanceSearchCriteria("");
    }
  }, [selectedChemIDObject, theme.palette.primary]);

  const getLabel = () => { 
    if (labelCustom) {
      if (label.includes('Invalid')) return `Invalid ${labelCustom.replace('*', '')}`
      else if (label.includes('Valid')) return `Valid ${labelCustom.replace('*', '')}`
      else if (label.includes('Inactive')) return label
      else return labelCustom;
    }

    return label;
  }

  return (
    <>
      <TextField
        sx={{
          width: fieldWidth,
          [`& .${outlinedInputClasses.root} .${outlinedInputClasses.notchedOutline}`]:
            {
              borderColor: coloring,
            },

          [`&:hover .${outlinedInputClasses.root} .${outlinedInputClasses.notchedOutline}`]:
            {
              borderColor: coloring,
            },
          [`& .${outlinedInputClasses.root}.${outlinedInputClasses.focused} .${outlinedInputClasses.notchedOutline}`]:
            {
              borderColor: coloring,
            },
          [`& .${outlinedInputClasses.input}`]: {
            color: coloring,
          },

          [`&:hover .${outlinedInputClasses.input}`]: {
            color: coloring,
          },
          [`& .${outlinedInputClasses.root}.${outlinedInputClasses.focused} .${outlinedInputClasses.input}`]:
            {
              color: coloring,
            },
          [`& .${inputLabelClasses.outlined}`]: {
            color: coloring,
          },

          [`&:hover .${inputLabelClasses.outlined}`]: {
            color: coloring,
          },
          [`& .${inputLabelClasses.outlined}.${inputLabelClasses.focused}`]: {
            color: coloring,
          },
        }}
        disabled={isDisabled}
        size="small"
        margin={margin ? margin : "normal"}
        variant="outlined"
        label={getLabel()}
        InputLabelProps={{ shrink: true }}
        onKeyDown={handleKeyPress}
        onChange={(e) => fieldChange(e.target.value)}
        value={(selectedChemIDObject !== null && selectedChemIDObject !== undefined) ? `${selectedChemIDObject?.chemID} - ${selectedChemIDObject?.substanceName}` : chemIDSearchValue
        }
        placeholder="Search Chem IDs..."
        error={hasErrors}
        onBlur={() => setHasErrors(selectedChemIDObject === null)}
        inputProps={{ style: { fontSize: fontSize }, maxLength: 50 }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                type="button"
                aria-label="search"
                onClick={handleChemIDSearchClick}
              >
                <SearchIcon style={{ fill: "blue" }} />
              </IconButton>
            </InputAdornment>
          ),
        }}
        helperText={helperText}
      ></TextField>

      <ModalTwoButtons
        title={modalChemIDTitle}
        button1Text={modalChemIDButton1Text}
        button1Action={confirmChemIDSelection}
        button2Text={modalChemIDButton2Text}
        button2Action={closeModalChemIDs}
        open={modalChemIDOpen}
        setOpen={setModalChemIDOpen}
      >
        <label>{modalChemIDText}</label>

        <div></div>

        <StyledAutocomplete
          renderOption={(props2, option) => (
            <Option {...props2}>{`${option.chemID} ~ ${option.substanceName}`}</Option>
          )}
          noOptionsText={"No Chem IDs Found..."}
          options={availableSubstances}
          getOptionLabel={(option) =>`${option.chemID} ~ ${option.substanceName}`}
          onChange={(e, value) => setSelectedChemIDObject(value)}
          autoHighlight
          filterOptions={filterOptions}
          value={selectedChemIDObject}
          isOptionEqualToValue={(option, value) => value.chemID === option.chemID}
          style={{ width: "450px", marginRight: "15px", marginTop: "15px" }}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              size="small"
              margin="normal"
              label="Select Chem ID"
              InputLabelProps={{ shrink: true }}
              InputProps={{ ...params.InputProps }}
            />
          )}
        />
      </ModalTwoButtons>

      <ModalMessages title={modalMessagesTitle} buttonText={modalMessagesButtonText} buttonAction={closeModalMessages} open={modalMessagesOpen} setOpen={setModalMessagesOpen}>
        <label>
          {modalMessagesText}
        </label>
      </ModalMessages>
    </>
  );
};
export { ChemIDSearchField };
