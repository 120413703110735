import Autocomplete from "@mui/material/Autocomplete";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { useEffect, useState } from "react";
import ValidatedMidasNumberTextField from "../../../components/ValidatedMidasNumberTextField";
import Sample from "../../../api/LIMS/Sample";

type SqcIdContainerPickerProps = {
  containerId?: number | null;
  onChange(sample: Sample | null, containerId: number | null): void;
  containerError?: string;
  disabled: boolean;
};

export function SqcIdContainerPicker({
  containerId,
  onChange,
  containerError,
  disabled,
}: SqcIdContainerPickerProps) {
  const [sampleObject, setSampleObject] = useState<Sample | null>(null);
  const [searchMidasError, setSearchMidasError] = useState(false);
  const [container, setContainer] = useState<any | null>(null);

  useEffect(() => {
    if (containerId) {
      Sample.getByContainerID(containerId).then((sample) => {
        const container = sample?.containers?.find(
          (c: any) => c.id === containerId
        );

        setSampleObject(sample);
        setContainer(container ?? null);
        onChange(sample, container?.id ?? null);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [containerId]);

  return (
    <Stack direction="row" sx={{ gap: "0.5rem" }}>
      <ValidatedMidasNumberTextField
        isDisabled={disabled}
        setScannedContainer={() => {}}
        margin="none"
        showLabel
        fontSize={16}
        fieldWidth="20rem"
        midasNumberObject={sampleObject}
        setMidasNumberObject={(e: Sample) => {
          setSampleObject(e);
          if (e?.sampleName !== sampleObject?.sampleName) setContainer(null);
          onChange(e, e ? container?.id ?? null : null);
        }}
        hasErrors={searchMidasError}
        setHasErrors={setSearchMidasError}
        chemIDToMatch={null}
      />

      <Autocomplete
        disableClearable
        options={
          (sampleObject?.containers.filter(
            (c: any) => c.containerStatusName !== "Discarded"
          ) ?? []) as any[]
        }
        getOptionLabel={(option) => `${option.containerNumber}`}
        renderOption={(props, option) => (
          <li {...props} key={option.id}>
            {option.containerNumber}
          </li>
        )}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Container"
            size="small"
            error={Boolean(containerError)}
          />
        )}
        value={container}
        onChange={(e, value) => {
          setContainer(value);
          onChange(sampleObject, value?.id ?? null);
        }}
        disabled={!sampleObject || disabled}
        sx={{ width: "8rem" }}
      />
    </Stack>
  );
}
