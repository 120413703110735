import { TextField, Box, Typography, TableRow, Autocomplete, CircularProgress, FormGroup, FormControlLabel, Switch, IconButton, Menu, MenuItem,} from "@mui/material";
import { UXDataTableWithoutBody, StyledTableCell, StyledTableBody } from "../../../components/UXDataTable";
import { formatMidasNumber, EvaluateOptionalIntegerField, EvaluateOptionalNumberField, EvaluateRequiredNumberField, RoundNumber, OrderTemplateCutList } from "../../../global";
import { GlobalButton, GlobalTabCollection, StyledTab } from "../../styles";
import MethodSelection from "../../../components/MethodSelection";
import { useState } from "react";
import ContainerTable from "../../../components/ContainerTable";
import { styled } from "@mui/material/styles";
import MoreOptions from "@mui/icons-material/MoreVert";
import ModalTwoButtons from "../../../components/Modal/ModalTwoButtons";

const wholeNumberMsg = "Only Whole Numbers"
const numberMsg = "Only Numbers"

const gridFontSize = 12
const cols = ['ID', 'Status', 'Cut Name', 'MIDAS #', 'Type', 'Start Temp (C)', 'End Temp (C)', 'Actual Start Temp (C)', 'Actual End Temp (C)', 'Pressure (torr)', 'Pot Liquid Temp (C)', 'Net Wt. (g)', 'Yield (wt%)']

const Option = styled('li')({
     fontSize: 12,
 });

const AtmosphericDistillation = ({myDistillationExp, cutErrors, handleCutRowChange, updateCutErrors, handleExperimentChange, 
     experimentErrors, updateExperimentErrors, atmosphericMassBalance, calculateYieldPercentage, experimentHasStarted,  
     StartExperiment, experimentIsStarting, allLocations, ValidateInitialAmount, actualAmountMessage,testingContainers, 
     setTestingContainers, testingContainerErrors, setTestingContainerErrors, experimentResultsEntered, fnSaveResults, 
     resultsAreSaving, fnSubmitTesting, testsAreSaving, experimentIsClosed, containerTypes, updateCutStatus, cutTypes}) => {
    
     const [switchShowTests, setSwitchShowTests] = useState(false)

     const lockResults = experimentResultsEntered || !experimentHasStarted
     const allowContainering = (experimentResultsEntered === true && experimentHasStarted === true && experimentIsClosed === false)

     const [tabValue, setTabValue] = useState(myDistillationExp.experimentCuts.find(cut => cut.testTemplateJSON !== null && cut.thisDistillationCutType.isAtmospheric === true && cut.distillationCutStatusName !== 'Failed')  
     && cutTypes ? OrderTemplateCutList( myDistillationExp.experimentCuts, cutTypes).find(cut => cut.testTemplateJSON !== null && cut.thisDistillationCutType.isAtmospheric === true && cut.distillationCutStatusName !== 'Failed' ).id : null)

     const [anchorEl, setAnchorEl] = useState(null)
     const [workingCutID, setWorkingCutID] = useState(null)

     const [modalCancelCutOpen, setModalCancelCutOpen] = useState(false);
     const [modalCancelCutTitle, setModalCancelCutTitle] = useState("");
     const [modalCancelCutText, setModalCancelCutText] = useState("");
     const [modalCancelCutButton1Text, setModalCancelCutButton1Text] = useState("");
     const [modalCancelCutButton2Text, setModalCancelCutButton2Text] = useState("");

     const handleTabChange = (event, newValue) => {
          setTabValue(newValue)
     };

     const openMenu = () => {
          return anchorEl != null
     }

     const handleOpenMoreOptions = (event) => {
          setAnchorEl(event.currentTarget)
     }
      
     const handleCloseMenu = () => {
          setAnchorEl(null)
     }

     const ConfirmRemoveItem = () => {
          if (workingCutID) {
            openModalCancelCut(`Cancel Cut `, "Are you sure you want to cancel the cut?", "Yes", "No");
          }
     };

     function closeModalCancelCut() {
          setModalCancelCutOpen(false);
     }
      
     function openModalCancelCut(title, text, button1, button2, Cut) {
          setModalCancelCutOpen(true);
          setModalCancelCutTitle(title);
          setModalCancelCutText(text);
          setModalCancelCutButton1Text(button1);
          setModalCancelCutButton2Text(button2);     
     }

     const GetLabelDisplay = () => {
          if (lockResults && tabValue) {
               if (myDistillationExp?.experimentCuts?.find(obj => obj.id === tabValue)?.testTemplateJSON ? JSON.parse(myDistillationExp?.experimentCuts?.find(obj => obj.id === tabValue).testTemplateJSON).methods : [] > 0)
               {
                    return "Show Tests"
               } else {
                    return "No Methods"
               }
     
          } else {
               return ("Enter and submit results to generate containering")
          }
     }

     const updateContainers = (newContainer) =>  {
          const index = testingContainers.findIndex(obj => obj.cutID === tabValue)
          const copyContainerInfo = structuredClone(testingContainers)
     
          if (index >= 0)
          {
               copyContainerInfo[index].containers = structuredClone(newContainer)
               setTestingContainers(copyContainerInfo)
          }   
     }
     
     const updateContainersErrors = (newErrors) =>  {
          const index = testingContainerErrors.findIndex(obj => obj.cutID === tabValue)
          const copyContainerErrorInfo = structuredClone(testingContainerErrors)
     
          if (index >= 0)
          {
               copyContainerErrorInfo[index].errors = structuredClone(newErrors)
               setTestingContainerErrors(copyContainerErrorInfo)
          }
     }

     function ChangeStatus () {
          updateCutStatus(workingCutID)
          closeModalCancelCut()
          setWorkingCutID(null)
     }

     return (
     <div>
          <div style={{marginTop:"2rem"}}>
          {experimentIsStarting ?
          (
          <>
               <CircularProgress style={{textTransform: "none"}}></CircularProgress>
               <GlobalButton  
                    disabled
                    name="btnStartExp"
                    variant='contained'
                    style={{ textTransform: "none",  marginBottom:"25px", marginLeft:"10px"}}
               >{"Start Experiment"}</GlobalButton>
          </>
          ):(
               <GlobalButton  
                    disabled={experimentHasStarted}
                    name="btnStartExp"
                    variant='contained'
                    style={{ textTransform: "none", marginBottom:"25px"}}
                    onClick={(e) => StartExperiment()}
               >{"Start Experiment"}</GlobalButton>
          )
          }

          {/* <GlobalSecondaryButton  
               disabled
               name="btnSaveTemplate"
               variant='contained'
               style={{ textTransform: "none", marginBottom:"25px", marginLeft:"25px"}}
               //onClick={(e) => templateButtonText === templateButtonUpdateText ? SaveTemplate(e) : openModalTemplate(templateModalTitle, "Enter a name for your new template", templateButton1Text, buttonCancelText)}
          >{"Print Run Sheet"}</GlobalSecondaryButton> */}
     </div>

     <Box display="flex" gap={3} alignItems="center" marginTop={"2rem"} width="50%">
          <Typography fontSize={16}>
               Requested Initial Amount: {myDistillationExp.initialSampleAmount?.toLocaleString()} {myDistillationExp.initialSampleAmountUoM}
          </Typography>

          <TextField size="small" margin="normal" variant="outlined" label="Actual Initial Amount (g)" InputLabelProps={{shrink: true}}
               disabled = {experimentHasStarted}   
               onChange={(e) => handleExperimentChange('actualInitialSampleAmount', e.target.value)}
               value={myDistillationExp.actualInitialSampleAmount === null ? '' : myDistillationExp.actualInitialSampleAmount.toLocaleString()}
               error={experimentErrors.actualInitialSampleAmount}
               onBlur={e => {updateExperimentErrors('actualInitialSampleAmount', ValidateInitialAmount())}}
               helperText={experimentErrors.actualInitialSampleAmount ? actualAmountMessage : ""}
          ></TextField> 

          <Autocomplete
               disabled = {experimentHasStarted}                              
               disablePortal
               noOptionsText={"Loading Locations..."}
               options={allLocations ? allLocations : []}
               getOptionLabel={(option) => option.locationName}
               onChange={(e, value) => handleExperimentChange('experimentLocation', value)}
               isOptionEqualToValue={(option, value) => value.locationName === option.locationName}
               autoHighlight
               autoSelect
               value={myDistillationExp.experimentLocation}
               renderInput={(params) => <TextField {...params} style={{width:"200px"}} variant="outlined" size="small" margin="normal" label="Experiment Location"
               error = {experimentErrors.experimentLocation} InputLabelProps={{shrink: true}} inputProps={{ ...params.inputProps,  style: { fontSize: 16 } }} InputProps={{ ...params.InputProps }}/>}        
          />    
     </Box>

     <div style={{display:"flex", marginTop:"5px"}}>
          <p style={{
          fontFamily:"EMprint",
          fontWeight:"600",
          fontSize:"18px",
          color:"#545459",
          textTransform: 'none',
          textAlign:"left",
          paddingTop:"20px",
          marginBottom:"1rem"
          }}>Atmospheric Cuts</p>
     </div>

          <>
          <UXDataTableWithoutBody 
          tableWidth={'100%'} 
          cols={cols} 
          blankFirstHeader={true}
          tableId="atmosphericDistillation"
          rowLength={myDistillationExp.experimentCuts ? myDistillationExp.experimentCuts.length : 0}
          enablePaging={false}
          rowsPerPage={0}
          page={0}
          handleChangePage={null}
          handleChangeRowsPerPage={null}
          noDataFoundMessage={'No cuts found'}
          enableAddIcon={false}
          addFunction={null}
          addToolTipText={'Add Cuts'}  
          isDataLoading={false}
          >
               <StyledTableBody>
               {cutTypes && myDistillationExp?.experimentCuts?.length && OrderTemplateCutList(myDistillationExp?.experimentCuts?.filter(cut => cut.thisDistillationCutType.isAtmospheric), cutTypes).map((oCut, index) => {

                    const cutIsFailed = oCut.distillationCutStatusName === 'Failed'
                    const isStartingCut = oCut.thisDistillationCutType.isStartingCut === true
                    const isEndingCut = oCut.thisDistillationCutType.isEndingCut === true

                    return (
                         <TableRow key={`atmospphericCutRow${index}`}>
                              {/* {lockResults === false && */}
                                   <StyledTableCell style={{width:"30px"}}>
                                        {/* {(!isStartingCut && !isEndingCut && oCut.distillationCutStatusName === 'InProgress') && */}
                                             <IconButton aria-label="more options" 
                                                  disabled = {(isStartingCut || isEndingCut || oCut.distillationCutStatusName !== 'InProgress')}
                                                  onClick={(e) => {handleOpenMoreOptions(e); setWorkingCutID(oCut.id)}}
                                                  >
                                                  <MoreOptions color='black'/>
                                             </IconButton>
                                        {/* } */}
                                   </StyledTableCell>
                              {/* } */}
                              <StyledTableCell>{oCut.id}</StyledTableCell>
                              <StyledTableCell>{oCut.distillationCutStatusName}</StyledTableCell>
                              <StyledTableCell>{oCut.distillationCutTypeName}</StyledTableCell>
                              <StyledTableCell>{oCut.thisContainer ? `${formatMidasNumber(oCut.thisContainer?.sampleName)}/${oCut.thisContainer?.containerNumber}` : ''}</StyledTableCell>
                              <StyledTableCell>
                                   <Autocomplete
                                        renderOption={(props2, option) => (
                                             <Option {...props2}>{option.name}</Option>
                                        )} 
                                        disabled = {lockResults || cutIsFailed || isStartingCut}
                                        disablePortal
                                        noOptionsText={"Loading..."}
                                        options={containerTypes}
                                        getOptionLabel={(option) => option.name}
                                        onChange={(e, value) => handleCutRowChange('containerType', value, oCut.id)}
                                        autoHighlight
                                        autoSelect
                                        value={oCut.thisContainer ? oCut.thisContainer.containerType : null}
                                        isOptionEqualToValue={(option, value) => value.name === option.name}
                                        renderInput={(params) => <TextField {...params} style={{width:"170px"}} variant="outlined" size="small" inputProps={{ ...params.inputProps,  style: { fontSize: 12}}} InputProps={{ ...params.InputProps }}/>} 
                                   />
                              </StyledTableCell>
                              <StyledTableCell style={{width:"100px"}}>{oCut.requestedStartingTemperatureC}</StyledTableCell>
                              <StyledTableCell style={{width:"100px"}}>{oCut.requestedEndTemperatureC}</StyledTableCell>

                              <StyledTableCell style={{width:"100px"}}>
                                   {!isStartingCut && !cutIsFailed &&
                                   <TextField style={{width:"80px", marginTop:"5px"}}
                                        disabled={lockResults}
                                        size="small" 
                                        margin="dense" 
                                        variant="outlined"
                                        inputProps={{ style: { fontSize: gridFontSize} }}
                                        InputLabelProps={{shrink: true}}
                                        value = {oCut.actualStartingTemperatureC}
                                        error = {cutErrors[cutErrors.findIndex(cut => cut.id === oCut.id)].actualStartingTemperatureC} 
                                        onChange={(e) => handleCutRowChange('actualStartingTemperatureC', (e.target.value), oCut.id)}
                                        onBlur={e => {updateCutErrors('actualStartingTemperatureC', EvaluateOptionalIntegerField(oCut.actualStartingTemperatureC), oCut.id)}}
                                        helperText={cutErrors[cutErrors.findIndex(cut => cut.id === oCut.id)].actualStartingTemperatureC ? wholeNumberMsg : ""}
                                   />
                                   }
                              </StyledTableCell>
                              <StyledTableCell style={{width:"100px"}}>
                                   {!isStartingCut && !cutIsFailed &&
                                   <TextField style={{width:"80px", marginTop:"5px"}}
                                        disabled={lockResults}
                                        size="small" 
                                        margin="dense" 
                                        variant="outlined"
                                        inputProps={{ style: { fontSize: gridFontSize} }}
                                        InputLabelProps={{shrink: true}}
                                        value = {oCut.actualEndTemperatureC}
                                        error = {cutErrors[cutErrors.findIndex((cut => cut.id === oCut.id))].actualEndTemperatureC}  
                                        onChange={(e) => handleCutRowChange('actualEndTemperatureC', (e.target.value), oCut.id)}
                                        onBlur={e => {updateCutErrors('actualEndTemperatureC', EvaluateOptionalIntegerField(oCut.actualEndTemperatureC), oCut.id)}}
                                        helperText={cutErrors[cutErrors.findIndex(cut => cut.id === oCut.id)].actualEndTemperatureC ? wholeNumberMsg : ""}
                                   />
                                   }
                              </StyledTableCell>
                              <StyledTableCell style={{width:"100px"}}>
                                   {!isStartingCut && !cutIsFailed &&
                                   <TextField style={{width:"80px", marginTop:"5px"}}
                                        disabled={lockResults}
                                        size="small" 
                                        margin="dense" 
                                        variant="outlined"
                                        inputProps={{ style: { fontSize: gridFontSize} }}
                                        InputLabelProps={{shrink: true}}
                                        value = {oCut.pressure}
                                        error = {cutErrors[cutErrors.findIndex((cut => cut.id === oCut.id))].pressure} 
                                        onChange={(e) => handleCutRowChange('pressure', (e.target.value), oCut.id)}
                                        onBlur={e => {updateCutErrors('pressure', EvaluateOptionalNumberField(oCut.pressure), oCut.id)}}
                                        helperText={cutErrors[cutErrors.findIndex(cut => cut.id === oCut.id)].pressure ? numberMsg : ""}
                                   />
                                   }
                              </StyledTableCell>
                              <StyledTableCell style={{width:"100px"}}>
                                   {!isStartingCut && !cutIsFailed &&
                                   <TextField style={{width:"80px", marginTop:"5px"}}
                                        disabled={lockResults}
                                        size="small" 
                                        margin="dense" 
                                        variant="outlined"
                                        inputProps={{ style: { fontSize: gridFontSize} }}
                                        InputLabelProps={{shrink: true}}
                                        value = {oCut.potLiquidTemperatureC}
                                        error = {cutErrors[cutErrors.findIndex((cut => cut.id === oCut.id))].potLiquidTemperatureC} 
                                        onChange={(e) => handleCutRowChange('potLiquidTemperatureC', (e.target.value), oCut.id)}
                                        onBlur={e => {updateCutErrors('potLiquidTemperatureC', EvaluateOptionalNumberField(oCut.potLiquidTemperatureC), oCut.id)}}
                                        helperText={cutErrors[cutErrors.findIndex(cut => cut.id === oCut.id)].potLiquidTemperatureC ? numberMsg : ""}
                                   />
                                   }    
                              </StyledTableCell>
                              <StyledTableCell style={{width:"100px"}}>
                                   {!isStartingCut && !cutIsFailed ? (
                                   <TextField style={{width:"80px", marginTop:"5px"}}
                                        disabled={lockResults}
                                        size="small" 
                                        margin="dense" 
                                        variant="outlined"
                                        inputProps={{ style: { fontSize: gridFontSize} }}
                                        InputLabelProps={{shrink: true}}
                                        value = {oCut.distillationCutYieldInG ? oCut.distillationCutYieldInG.toLocaleString() : ''}
                                        error = {cutErrors[cutErrors.findIndex((cut => cut.id === oCut.id))].distillationCutYieldInG} 
                                        onChange={(e) => handleCutRowChange('distillationCutYieldInG', (e.target.value), oCut.id)}
                                        onBlur={e => {updateCutErrors('distillationCutYieldInG', EvaluateRequiredNumberField(oCut.distillationCutYieldInG), oCut.id)}}
                                        helperText={cutErrors[cutErrors.findIndex(cut => cut.id === oCut.id)].distillationCutYieldInG ? numberMsg : ""}
                                        />
                                   ) : (
                                        oCut.distillationCutYieldInG === null ? '' : oCut.distillationCutYieldInG.toLocaleString()
                                   )
                                   }
                              </StyledTableCell>
                              <StyledTableCell>{isNaN(calculateYieldPercentage(oCut)) || oCut.thisDistillationCutType.isBlend === true || cutIsFailed ? '' : `${RoundNumber(calculateYieldPercentage(oCut), 3)}%`}</StyledTableCell>
                         </TableRow>
                    )
               })}

               </StyledTableBody>
     
          </UXDataTableWithoutBody>   
          
          <Box display="flex" gap={3} alignItems="center" marginTop={2}>
               <Typography fontSize={16}>
                    Atmospheric Mass Balance: {isNaN(atmosphericMassBalance) ? 0 : `${RoundNumber(atmosphericMassBalance, 3)}%`}
               </Typography>
        </Box>
        </>


     <Box display="flex" gap={3} alignItems="center" marginTop={"2rem"} width="50%">
          <TextField size="small" margin="normal" variant="outlined" label="Still Name*" InputLabelProps={{shrink: true}}
               disabled={lockResults}
               inputProps={{ maxLength: 20 }}
               onChange={(e) => handleExperimentChange('atmosphericStillName', e.target.value)}
               value={myDistillationExp.atmosphericStillName === null ? '' : myDistillationExp.atmosphericStillName}
               error={experimentErrors.atmosphericStillName}
               onBlur={e => {updateExperimentErrors('atmosphericStillName',  myDistillationExp.atmosphericStillName === null || myDistillationExp.atmosphericStillName === '')}}
               helperText={experimentErrors.atmosphericStillName ? 'Required' : ""}
          ></TextField> 

          <TextField size="small" margin="normal" variant="outlined" label="Water (g)*" InputLabelProps={{shrink: true}}
               disabled={lockResults}
               onChange={(e) => handleExperimentChange('waterWeight', e.target.value)}
               value={myDistillationExp.waterWeight === null ? '' : myDistillationExp.waterWeight.toLocaleString()}
               error={experimentErrors.waterWeight}
               onBlur={e => {updateExperimentErrors('waterWeight', EvaluateRequiredNumberField(myDistillationExp.waterWeight))}}
               helperText={experimentErrors.waterWeight ? numberMsg : ""}
          ></TextField> 

          <TextField size="small" margin="normal" variant="outlined" label="Atmospheric Wettage (g)*" InputLabelProps={{shrink: true}}
               disabled={lockResults}
               onChange={(e) => handleExperimentChange('atmosphericWettage', e.target.value)}
               value={myDistillationExp.atmosphericWettage === null ? '' : myDistillationExp.atmosphericWettage.toLocaleString()}
               error={experimentErrors.atmosphericWettage}
               onBlur={e => {updateExperimentErrors('atmosphericWettage', EvaluateRequiredNumberField(myDistillationExp.atmosphericWettage))}}
               helperText={experimentErrors.atmosphericWettage ? numberMsg : ""}
          ></TextField> 

          <TextField size="small" margin="normal" variant="outlined" label="Pot Size (L)*" InputLabelProps={{shrink: true}}
               disabled={lockResults}
               onChange={(e) => handleExperimentChange('atmosphericPotSizeL', e.target.value)}
               value={myDistillationExp.atmosphericPotSizeL === null ? '' : myDistillationExp.atmosphericPotSizeL.toLocaleString()}
               error={experimentErrors.atmosphericPotSizeL}
               onBlur={e => {updateExperimentErrors('atmosphericPotSizeL', EvaluateRequiredNumberField(myDistillationExp.atmosphericPotSizeL))}}
               helperText={experimentErrors.atmosphericPotSizeL ? numberMsg : ""}
          ></TextField> 
     </Box>

     <Box width="50%">
          <TextField multiline style={{width:"100%"}} size="small" margin="normal" variant="outlined" label="Comments" InputLabelProps={{shrink: true}}
               disabled={lockResults}
               onChange={(e) => handleExperimentChange('comments', e.target.value)}
               value={myDistillationExp.comments === null ? '' : myDistillationExp.comments}
          ></TextField>
     </Box>


     {resultsAreSaving ?
          ( 
               <div style={{marginTop:"2rem"}}>
                    <CircularProgress style={{textTransform: "none"}}></CircularProgress>
                    <GlobalButton
                         disabled
                         name="btnSubmit"
                         variant='contained'
                         style={{marginBottom:"3rem", marginLeft:"10px"}}
                    >
                         {'Submit Results'}
                    </GlobalButton>
               </div>
          ) : (
               <div style={{marginTop:"2rem"}}>
                    <GlobalButton
                         disabled={lockResults}
                         name="btnSubmit"
                         variant='contained'
                         style={{marginBottom:"3rem"}}
                         onClick={(e) => fnSaveResults()}
                    >
                         {'Submit Results'}
                    </GlobalButton>
               </div>          
          )
     }
     {experimentIsClosed === false &&
     <>
          <div>
               <p style={{
               fontFamily:"EMprint",
               fontWeight:"600",
               fontSize:"16px",
               color:"#545459",
               textTransform: 'none',
               textAlign:"left",
               paddingTop:"10px"
               }}>{`Testing/Containering`}</p>

          </div>


          <div style={{marginTop:"1.5rem"}}>
               <GlobalTabCollection style={{width: myDistillationExp?.experimentCuts?.length > 10 ? "60%" :  `${myDistillationExp?.experimentCuts?.length * 6}%` }} 
                    value={tabValue} onChange={handleTabChange} variant={"scrollable"} scrollButtons="auto" aria-label='ant example'>
                    {myDistillationExp?.experimentCuts?.filter(cut => cut.thisDistillationCutType.isAtmospheric === true && cut.testTemplateJSON !== null && cut.distillationCutStatusName !== 'Failed').map((oCut, index) => {
                         return(

                              <StyledTab 
                                   disabled={lockResults === false}
                                   key={index}
                                   value={oCut.id}
                                   label={`Cut ${oCut.id}`} />
                         )
                    })}
                    
               </GlobalTabCollection>

               {allowContainering &&
                    <div style={{marginTop:"1rem"}}>

                         <FormGroup style={{marginLeft: "15px"}}>
                              <FormControlLabel control={<Switch />} 
                                   checked={switchShowTests}
                                   onChange={e => setSwitchShowTests(e.target.checked)}
                                   label = {GetLabelDisplay()}
                                   disabled = {allowContainering === false}
                              />
                         </FormGroup>

                    {switchShowTests &&
                         <MethodSelection 
                              width={'100%'} 
                              selectedMethods={myDistillationExp?.experimentCuts?.find(obj => obj.id === tabValue).testTemplateJSON ? JSON.parse(myDistillationExp?.experimentCuts?.find(obj => obj.id === tabValue).testTemplateJSON).methods : []} 
                              setSelectedMethods={null} 
                              showCompletionDate={true} 
                              showContainerGrouping={false}
                              incomingTemplate={null}
                              isReadOnly={true}
                              showSearchTemplateName={false}
                              showEstimateAndPriority={true}
                              maxMethodsAllowed={999}
                         >
                         </MethodSelection>
                    }

                         <div style={{marginTop: "1rem"}}>
                              {tabValue && myDistillationExp.experimentCuts?.find(obj => obj.id === tabValue).testTemplateJSON !== null && testingContainers.find(obj => obj.cutID === tabValue) === undefined &&
                                   <div style={{display:"flex"}}>
                                        <p style={{
                                             fontFamily:"EMprint",
                                             fontWeight:"600",
                                             fontSize:"16px",
                                             color:"#545459",
                                             textTransform: 'none',
                                             textAlign:"left",
                                             paddingTop:"10px"
                                             }}>{'Generating Testing Containers'}</p>
                                        <CircularProgress style={{textTransform: "none"}}></CircularProgress>
                                   </div>
                              }

                         <ContainerTable
                              width = {'100%'}
                              showParentContainer = {false}
                              showTests = {true}
                              containers={testingContainers.find(obj => obj.cutID === tabValue) ? testingContainers.find(obj => obj.cutID === tabValue).containers : []} 
                              setContainers = {updateContainers}
                              containerErrorChecks={testingContainerErrors.find(obj => obj.cutID === tabValue) ? testingContainerErrors.find(obj => obj.cutID === tabValue).errors : []} 
                              setContainerErrorChecks = {updateContainersErrors}
                              enableAdditions = {false}
                              enableDeletions = {false} 
                              enableCopy = {false}
                              availableParentContainers = {null}
                              sampleObject = {myDistillationExp.experimentCuts.find(cut => cut.id === tabValue) ? myDistillationExp.experimentCuts.find(cut => cut.id === tabValue).thisContainer.sample : null}
                              substanceObject = {myDistillationExp.experimentCuts.find(cut => cut.id === tabValue) ? myDistillationExp.experimentCuts.find(cut => cut.id === tabValue).thisContainer.sample.substance : null}
                              lockContainerStatus={true}
                              newContainerDefaultOwner = {null}
                         >
                         </ContainerTable>
                         </div> 
                    </div>   
               } 

          {testsAreSaving ?
               ( 
                    <div style={{marginTop:"2rem"}}>
                         <CircularProgress style={{textTransform: "none"}}></CircularProgress>
                         <GlobalButton
                              disabled
                              name="btnSubmit"
                              variant='contained'
                              style={{marginBottom:"3rem"}}
                         >
                              {'Submit Testing'}
                         </GlobalButton>
                    </div>
               ) : (
                    <div style={{marginTop:"2rem"}}>
                         <GlobalButton
                              disabled={!(allowContainering)}
                              name="btnSubmit"
                              variant='contained'
                              style={{marginBottom:"3rem"}}
                              onClick={(e) => fnSubmitTesting()}
                         >
                              {'Submit Testing'}
                         </GlobalButton>
                    </div>       
               )
          }

          <Menu
              anchorEl={anchorEl}
              open={openMenu()}
              onClose={(e) => handleCloseMenu(e)}>
              <MenuItem 
                onClick={() => {handleCloseMenu(); ConfirmRemoveItem()}}
              >Cancel Cut</MenuItem>
          </Menu>

          <ModalTwoButtons
               title={modalCancelCutTitle}
               button1Text={modalCancelCutButton1Text}
               button1Action={ChangeStatus}
               button2Text={modalCancelCutButton2Text}
               button2Action={closeModalCancelCut}
               open={modalCancelCutOpen}
               setOpen={setModalCancelCutOpen}
          >
          <div style={{ textAlign: "center" }}>
               <label>{modalCancelCutText}</label>
          </div>
          </ModalTwoButtons>

          </div>     
     </>  
     }
    
   
     </div>
);
};

  export default AtmosphericDistillation;