import API from "..";

export default class ComponentProcurementReport {

  constructor({
    containers,
    chemID,
    description,
    timesUsed,
    amountUsed,
    quantityAvailable,
    isoAvailable,
    expiringInOneMonth,
    expiringInTwoMonth,
    expiringInSixMonths,
    substanceDensity,
    sampleDensity
  }) {
    this.containers = containers;
    this.chemID = chemID;
    this.description = description;
    this.timesUsed = timesUsed;
    this.amountUsed = amountUsed;
    this.quantityAvailable = quantityAvailable;
    this.isoAvailable = isoAvailable;
    this.expiringInOneMonth = expiringInOneMonth;
    this.expiringInTwoMonth = expiringInTwoMonth;
    this.expiringInSixMonths = expiringInSixMonths;
    this.substanceDensity = substanceDensity;
    this.sampleDensity = sampleDensity;
  }

  static async get(startDate, endDate, siteName, includeDefault, listChemID, returnUoM, page, resultsPerPage) {
    const api = await API();
    const { data } = await api.post(`/componentprocurementreport?startDate=${startDate}&endDate=${endDate}&siteName=${siteName}&includeDefault=${includeDefault}&returnUoM=${returnUoM}&page=${page}&resultsPerPage=${resultsPerPage}`, listChemID);
    return data
  }
}
