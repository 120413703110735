import { useState } from "react";
import { EditStIntervalProcessResult } from "./EditStIntervalProcessResult";
import { STIntervalProcessResultTable } from "./STIntervalProcessResultTable";
import type { Interval, STIntervalProcessResult } from "../../../types";

type STIntervalProcessResultSectionProps = {
  initialIntervals: Interval[];
  saveSTIntervalProcessResult(values: Interval[]): void;
};

export default function STIntervalProcessResultSection({
  initialIntervals,
  saveSTIntervalProcessResult,
}: STIntervalProcessResultSectionProps) {
  const [intervals, setIntervals] = useState<Interval[]>(structuredClone(initialIntervals) ?? []);
  const [editSTIntervalProcessResultModalOpen, setEditSTIntervalProcessResultModalOpen] = useState(false);
  const [stIntervalProcessResultBeingEdited, setStIntervalProcessResultBeingEdited] =
    useState<STIntervalProcessResult | null>(null);
  const [intervalBeingEdited, setIntervalBeingEdited] = useState<Interval | null>(null);

  function handleSaveSTIntervalProcessResult(
    newValue: STIntervalProcessResult,
    previousStIntervalProcessResult: STIntervalProcessResult | null,
    intervalThatContainsNewValue: Interval
  ) {
    let newValues: Interval[] = [];

    if (previousStIntervalProcessResult !== null) {
      intervals.forEach((i) => {
        const sameIntervalName = i.intervalName === intervalThatContainsNewValue.intervalName;

        if (sameIntervalName) {
          const newIntervalProcessResults: STIntervalProcessResult[] = [];

          i.intervalProcessResults?.forEach((ipr) => {
            const isSameIntervalProcessResult = ipr.id === newValue.id;

            if (isSameIntervalProcessResult) {
              ipr.resultUoMName = newValue.resultUoMName;
              ipr.resultValue = newValue.resultValue;
            }

            newIntervalProcessResults.push(ipr);
          });

          i.intervalProcessResults = newIntervalProcessResults;
        }

        newValues.push(i);
      });
    } else {
      intervals.forEach((i) => {
        const sameIntervalName = i.intervalName === intervalThatContainsNewValue.intervalName;

        if (sameIntervalName) {
          i.intervalProcessResults?.push(newValue);
        }

        newValues.push(i);
      });
    }

    setIntervals(newValues);
    saveSTIntervalProcessResult(newValues);
    setIntervalBeingEdited(null);
    setStIntervalProcessResultBeingEdited(null);
  }

  function handleEdit(intervalBeingEdited: Interval, intervalProcessResult: STIntervalProcessResult) {
    setIntervalBeingEdited(intervalBeingEdited);
    setStIntervalProcessResultBeingEdited(intervalProcessResult);
    setEditSTIntervalProcessResultModalOpen(true);
  }

  function handleAdd(intervalThatContainsNewValue: Interval) {
    setStIntervalProcessResultBeingEdited(null);
    setIntervalBeingEdited(intervalThatContainsNewValue);
    setEditSTIntervalProcessResultModalOpen(true);
  }

  function handleDelete(interval: Interval, stIntervalProcessResult: STIntervalProcessResult) {
    const newValues: Interval[] = [];

    intervals.forEach((i) => {
      const sameIntervalName = i.intervalName === interval.intervalName;

      if (sameIntervalName) {
        const filteredIntervalProcessResults = i.intervalProcessResults
          ? i.intervalProcessResults.filter(
              (ipr) => ipr.id !== stIntervalProcessResult.id
            )
          : [];

        i.intervalProcessResults = filteredIntervalProcessResults;
      }

      newValues.push(i);
    });

    setIntervals(newValues);
    saveSTIntervalProcessResult(newValues);
    setIntervalBeingEdited(null);
    setStIntervalProcessResultBeingEdited(null);
  }

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "start",
          marginTop: "2rem",
        }}
      >
        <span
          style={{
            textAlign: "left",
            font: "normal normal 600 20px/24px EMprint",
            letterSpacing: 0,
          }}
        >
          ST Interval Process Result
        </span>
      </div>
      <STIntervalProcessResultTable
        intervals={intervals}
        openAddStIntervalProcessResultModal={(i) => handleAdd(i)}
        handleEdit={handleEdit}
        handleDelete={handleDelete}
      />
      <EditStIntervalProcessResult
        close={() => {
          setEditSTIntervalProcessResultModalOpen(false);
          setIntervalBeingEdited(null);
          setStIntervalProcessResultBeingEdited(null);
        }}
        open={editSTIntervalProcessResultModalOpen}
        initialValues={structuredClone(stIntervalProcessResultBeingEdited)}
        onSave={handleSaveSTIntervalProcessResult}
        intervalThatContainsNewValue={intervalBeingEdited}
      />
    </>
  );
}
