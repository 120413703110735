import { useState, useEffect, useContext } from "react";
import { styled } from "@mui/material/styles";
import {TextField,TableRow, Autocomplete, Tooltip, TableCell } from "@mui/material";
import { UXDataTableWithoutBody, StyledTableCell, StyledTableBody } from "../UXDataTable";
import ContainerType from "../../api/LIMS/ContainerType";
import ContainerStatus from "../../api/LIMS/ContainerStatus";
import UnitOfMeasure from "../../api/Admin/UnitOfMeasure";
import Location from "../../api/Admin/Location";
import {RoundNumber, RecalculateCurrentAmountOnUoMChange, RecalculateInitialSizeOnUoMChange, CheckContainerCurrentAmount, CheckContainerSize, isNumber } from "../../global";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import TrashIcon from '@mui/icons-material/Delete';
import EmailAddressTextField from "../EmailAddressField";
import UserContext from "../../context/UserContext";
import IntegrationCommentsIcon from '@mui/icons-material/IntegrationInstructions';
import ModalTwoButtons from "../../components/Modal/ModalTwoButtons";

const containerGridFontSize = 12

const StyledAutocomplete = styled(Autocomplete)({
    '& .MuiAutocomplete-input, & .MuiInputLabel-root': {
      fontSize: containerGridFontSize,
    },
});

const Option = styled('li')({
    fontSize: containerGridFontSize,
});

const ContainerTable = ({ width, showParentContainer, showTests, containers, setContainers, 
    containerErrorChecks, setContainerErrorChecks, enableAdditions, enableDeletions, enableCopy, 
    availableParentContainers, sampleObject, substanceObject, lockContainerStatus, newContainerDefaultOwner}) => {
    
    const [containerTypes, setContainerTypes] = useState([])
    const [containerStatuses, setContainerStatuses] = useState([])
    const [availableUOMs, setAvailableUOMs] = useState([])
    const [availableLocations, setAvailableLocations] = useState([])

    const [modalCommentsOpen, setModalCommentsOpen] = useState(false)
    const [modalCommentsButton1Text, setModalCommentsButton1Text] = useState(null)
    const [modalCommentsButton2Text, setModalCommentsButton2Text] = useState(null)
    const [modalCommentsTitle, setModalCommentsTitle] = useState(null)
    const [modalCommentsText, setModalCommentsText] = useState(null)
    const [tempContainerInstruction, setTempContainerInstruction] = useState(null)
    const [containerWorkingIndex, setContainerWorkingIndex] = useState(null)

    const currentUser = useContext(UserContext)

    const methodCols = [
        '#', 
        showParentContainer && 'Parent Container', 
        'Type', 
        'Status', 
        'Container Size',
        'Current Amt', 
        'UoM',
        '% Full',
        'Location', 
        'Sublocation',
        'Owner',
        'Comment',
        showTests && 'Tests'
    ].filter(Boolean)
    
    function closeModalComments() {
        setModalCommentsOpen(false);
        setTempContainerInstruction('')
        setContainerWorkingIndex(null)
    }
    
    function openModalComments(title, text, buttonText, button2Text, containerIndex) {
        setModalCommentsButton1Text(buttonText)
        setModalCommentsButton2Text(button2Text)
        setModalCommentsOpen(true);
        setModalCommentsTitle(title);
        setModalCommentsText(text);

        setContainerWorkingIndex(containerIndex)
        setTempContainerInstruction(containers[containerIndex].comments)
    }

    useEffect(() => {
        let cancelPromise = false
    
            Location.getAll().then((res) => {
                if (cancelPromise) return
                setAvailableLocations(res.filter(result => result.isActive === true).sort((a, b) => a.locationName.localeCompare(b.locationName)))
            });
    
            UnitOfMeasure.getAll().then((res) => {
                if (cancelPromise) return
                setAvailableUOMs(res.filter(result => (result.metricStandardConversion !== null || result.type === 'concentration') && result.isActive === true).sort((a, b) => a.uoMName.localeCompare(b.uoMName)))
            });
    
            ContainerType.getAll().then((res) => {
                if (cancelPromise) return
                setContainerTypes(res.filter(result => result.isActive === true).sort((a, b) => a.name.localeCompare(b.name)))
            });
    
            ContainerStatus.getAll().then((res) => {
                if (cancelPromise) return
                setContainerStatuses(res.filter(result => result.isActive === true).sort((a, b) => a.name.localeCompare(b.name)))
            });            
        return () => {
            cancelPromise = true
          }
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const AddNewBlankContainer = () => {
        const copyContainers = structuredClone(containers)
        const copyContainerErrorChecks = structuredClone(containerErrorChecks)
        
        const container = 
            {sampleName: null,
            tests: null,
            containerNumber: copyContainers.length,
            containerTypeName: '',
            containerType: null,
            containerStatusName: '',
            containerStatus: containerStatuses.find(status => status.name === "Confirmed"),
            cylinderSerialNumber: null,
            size: '',
            containerSizeUoM: '',
            uom: null,
            tareWeight: null,
            tareWeightUoM: null,
            currentAmount: '',
            ownerEmail: sampleObject ? sampleObject.sampleOwnerEmail : (newContainerDefaultOwner ? newContainerDefaultOwner : currentUser.username),
            createdDate: null,
            createdByEmail: null,
            lastModifiedDate: null,
            lastModifiedByEmail: null,
            subLocation: null,
            expirationDate: null,
            nextDispositionDate: null, 
            discardOnDisposition: false,
            locationName: '',
            location: sampleObject ? (sampleObject.location === null ? null : sampleObject.location) : null,
            parentID: null, 
            parentContainer: null,
            returnLocationName: '', 
            returnLocation: null,
            comments: null
        }
    
        copyContainers.push(container)

        copyContainerErrorChecks.push({
            containerType: null,
            containerStatus: false,
            size: null, 
            currentAmount: null,
            uom: null,
            location: container.location === null ? null : false,
            ownerEmail: container.ownerEmail === null || container.ownerEmail === '' ? null : false
        })
       
        setContainers(copyContainers)
        setContainerErrorChecks(copyContainerErrorChecks)
      }
    
    const CopyContainerRowToEnd = (copiedIndex) => {
        const newContainers = structuredClone(containers)
        const newErrors  = structuredClone(containerErrorChecks)

        const copiedContainer = Object.assign({}, containers[copiedIndex])
        const copiedError = Object.assign({}, containerErrorChecks[copiedIndex])
    
        copiedContainer.containerNumber = containers.length
        delete copiedContainer.id
    
        newContainers.push(copiedContainer)
        newErrors.push(copiedError)

          
        setContainers(newContainers)
        setContainerErrorChecks(newErrors)
      }
    
    const DeleteContainerRow = (index) => {
        const copyContainers = structuredClone(containers)
        const copyContainerErrorChecks = structuredClone(containerErrorChecks)
    
        copyContainerErrorChecks.splice(index, 1)  
        copyContainers.splice(index, 1)
    
        //redo the container numbering
        //check to make sure that all the containers weren't removed
        if (copyContainers && copyContainers.length > 0){
        let i = 0
        do {
            copyContainers[i].containerNumber = i
            i += 1
        } while (i < copyContainers.length)
        }
            
        setContainers(copyContainers)
        setContainerErrorChecks(copyContainerErrorChecks)
    } 

    const handleContainerRowChange = (property, value, index) => {
        const newContainer = structuredClone(containers)
        const copyContainerErrorChecks = structuredClone(containerErrorChecks)

        newContainer[index][property] = value
    
        if (property === 'containerType')
        {
            copyContainerErrorChecks[index].containerType = CheckAutoCompleteField(newContainer[index], property)

            if (value)
            {
                newContainer[index].size = value.defaultContainerSize
    
                if (value.defaultContainerUoMName)
                {
                    const newUoM = availableUOMs.find(result => result.uoMName === value.defaultContainerUoMName) 
    
                    if (newUoM)
                    {
                        newContainer[index].currentAmount = RecalculateCurrentAmountOnUoMChange(sampleObject, substanceObject, newContainer[index].currentAmount,  newContainer[index].uom, newUoM) 
                        newContainer[index].uom = newUoM
                        newContainer[index].containerSizeUoM = ''
                        newContainer[index].containerTypeName = ''
    
                        copyContainerErrorChecks[index].uom = false
                    }
    
                    copyContainerErrorChecks[index].size = CheckContainerSize(newContainer[index])
    
                } else {
                    containers[index].size = ''
                    copyContainerErrorChecks[index].size = null                
                }
            } else {
                newContainer[index].size = ''
                newContainer[index].uom = null 
                newContainer[index].containerSizeUoM = ''   
                newContainer[index].currentAmount = ''   
                newContainer[index].containerTypeName = ''
    
                copyContainerErrorChecks[index].currentAmount = null 
                copyContainerErrorChecks[index].uom = null
                copyContainerErrorChecks[index].size = null
            }

            setContainerErrorChecks(copyContainerErrorChecks)

        } else if (property === 'uom')
        {
          if (value && containers[index].uom)
          {
            const oldUoM = containers[index].uom
    
            newContainer[index].currentAmount = RecalculateCurrentAmountOnUoMChange(sampleObject, substanceObject, newContainer[index].currentAmount,  oldUoM, newContainer[index].uom) 
            newContainer[index].size = RecalculateInitialSizeOnUoMChange(sampleObject, substanceObject, newContainer[index].size,  oldUoM, newContainer[index].uom) 
          }

          updateErrorChecksForContainers(property, CheckAutoCompleteField(newContainer[index], property), index)

        } else if (property === 'containerStatus') {
            if (value && value.name === "Shipped")
            {
                const locationObject = availableLocations.find(obj => obj.locationName === 'XSHIP')    

                if (locationObject)
                {
                    newContainer[index].location = locationObject     
                    copyContainerErrorChecks[index].location = false
                    
                    setContainerErrorChecks(copyContainerErrorChecks)  
                }
            }

            updateErrorChecksForContainers(property, CheckAutoCompleteField(newContainer[index], property), index)

        } else if (property === 'location')
        {
           updateErrorChecksForContainers(property, CheckAutoCompleteField(newContainer[index], property), index)
        }

        setContainers(newContainer)
    }

    function updateErrorChecksForContainers (property, value, index)
    {
        const copyContainerErrorChecks = structuredClone(containerErrorChecks)

        copyContainerErrorChecks[index][property] = value

        setContainerErrorChecks(copyContainerErrorChecks)  
    }

    function CheckAutoCompleteField (oContainer, property)
    {
        if (oContainer[property] === null)
        {
            return true
        } else {
            return false
        }
    }

    function GetContainerStatusValue (oContainer)
    {
        if (oContainer.containerStatus === null && oContainer.containerStatusName !== null && oContainer.containerStatusName.trim() !== '')
        {
            return containerStatuses.find(status => status.name === oContainer.containerStatusName) 
        } else if (oContainer.containerStatus)
        {
            return oContainer.containerStatus
        } else {
            return null
        }       
    }

    function RoundAmounts (value, index, property)
    {
      if(isNumber(value))
      {
        const newContainers = structuredClone(containers)
  
        newContainers[index][property] = RoundNumber(value, 2, true)
        setContainers(newContainers)
      }
    }
   
    function PopulateTests (tests)
    {
        let returnValue = null

        if (tests === null )
        {
            return returnValue
        }

        tests.forEach(oTest => {
            if (returnValue === null)
            {
                returnValue = `${oTest.methodFacility?.methodName}/${oTest.methodFacility?.testFacilityAbv}`
            } else {
                returnValue += `, ${oTest.methodFacility?.methodName}/${oTest.methodFacility?.testFacilityAbv}`
            }         
        });

        return returnValue
    }

    function SaveContainerComment () 
    {
        if (containerWorkingIndex !== null)
        {
            handleContainerRowChange('comments', tempContainerInstruction, containerWorkingIndex)
            setTempContainerInstruction('')
        }
        closeModalComments()
    }

    return (
        <>
            <UXDataTableWithoutBody 
                tableWidth={width} 
                cols={methodCols} 
                blankFirstHeader={(enableAdditions || enableDeletions || enableCopy)}
                tableId="containerTable"
                rowLength={containers ? containers.length : 0}
                enablePaging={false}
                rowsPerPage={0}
                page={0}
                handleChangePage={null}
                handleChangeRowsPerPage={null}
                noDataFoundMessage={'No containers found'}
                enableAddIcon={enableAdditions}
                addFunction={AddNewBlankContainer}
                addToolTipText={'Add Container'}  
                isDataLoading={false}
            >

                <StyledTableBody>
                    {containers && containers.sort((a,b) => a.containerNumber - b.containerNumber).map((containerInfo, index) => {
                        
                    return(
                    <TableRow key={`ContainerRow${index}`}>

                        {/* containerInfo._originalUoM is used to block certain deletions in blending */}
                        {(enableAdditions || enableDeletions || enableCopy) &&
                        <StyledTableCell style={{width:"90px"}} component='th' scope='row'>

                            {!(containerInfo.createdDate) && enableDeletions && !(containerInfo._originalUoM) &&
                                <Tooltip title="Delete Container" placement="right">
                                    <TrashIcon onClick ={() => DeleteContainerRow(index)}>

                                    </TrashIcon>
                                </Tooltip>
                            }

                            {enableCopy && 
                                <Tooltip title="Copy Row" placement="right">
                                    <ContentCopyIcon style={{marginLeft:"10px"}}
                                        value={index}
                                        onClick={() => CopyContainerRowToEnd(index)}>
                                    </ContentCopyIcon>
                                </Tooltip>
                            }

                        </StyledTableCell>
                        }

                        <StyledTableCell style={{width:"15px"}}><b>{containerInfo.containerNumber}</b></StyledTableCell>
                        
                        {showParentContainer &&  
                        <StyledTableCell>                      
                            <StyledAutocomplete
                                renderOption={(props2, option) => (
                                    <Option {...props2}>{option.containerNumber}</Option>
                                )} 
                                disabled = {!(containerInfo.createdDate === null)}
                                disablePortal
                                noOptionsText={"Loading..."}
                                options={availableParentContainers? availableParentContainers.sort((a, b) => a.containerNumber - b.containerNumber).filter((c => c.containerNumber !== containerInfo.containerNumber && c.containerStatusName !== 'Discarded')):[]}
                                getOptionLabel={(option) => option.containerNumber.toString()}
                                onChange={(e, value) => handleContainerRowChange('parentContainer', value, index)}
                                autoHighlight
                                autoSelect
                                value={containerInfo.parentContainer}
                                isOptionEqualToValue={(option, value) => value.containerNumber === option.containerNumber}
                                renderInput={(params) => <TextField {...params} style={{width:"70px"}} variant="outlined" size="small" inputProps={{ ...params.inputProps,  style: { fontSize: containerGridFontSize}}} InputProps={{ ...params.InputProps }}/>} 
                            />
                        </StyledTableCell>
                        }

                        <StyledTableCell>                        
                            <StyledAutocomplete
                                renderOption={(props2, option) => (
                                    <Option {...props2}>{option.name}</Option>
                                )}
                                disabled = {containerInfo.containerStatus ? containerInfo.containerStatus.name.includes('Discard') : false} 
                                disablePortal
                                noOptionsText={"Loading Types..."}
                                options={containerTypes}
                                getOptionLabel={(option) => option.name}
                                onChange={(e, value) => handleContainerRowChange('containerType', value, index)}
                                value={containerInfo.containerType}
                                isOptionEqualToValue={(option, value) => value.name === option.name}
                                autoHighlight
                                autoSelect
                                renderInput={(params) => <TextField {...params} style={{width:"160px"}} variant="outlined" size="small" error = {containerErrorChecks[index].containerType}  inputProps={{ ...params.inputProps,  style: { fontSize: containerGridFontSize}}} InputProps={{ ...params.InputProps }}/>} 
                            />
                        </StyledTableCell>


                        <StyledTableCell>
                            <StyledAutocomplete
                                renderOption={(props2, option) => (
                                    <Option {...props2}>{option.name}</Option>
                                )}
                                disabled={lockContainerStatus}
                                disablePortal
                                noOptionsText={"Loading Statuses..."}
                                options={containerStatuses}
                                getOptionLabel={(option) => option.name}
                                onChange={(e, value) => handleContainerRowChange('containerStatus', value, index)}
                                value={GetContainerStatusValue(containerInfo)}
                                isOptionEqualToValue={(option, value) => value.name === option.name}
                                autoHighlight
                                autoSelect
                                renderInput={(params) => <TextField {...params} style={{width:"145px"}} variant="outlined" size="small" error = {containerErrorChecks[index].containerStatus}  inputProps={{ ...params.inputProps,  style: { fontSize: containerGridFontSize}}}  InputProps={{ ...params.InputProps }}/>} 
                            />
                        </StyledTableCell>

                        <StyledTableCell style={{width:"80px"}}>
                            <TextField style={{width:"70px", marginTop:"5px"}}
                                disabled = {containerInfo.containerStatus ? containerInfo.containerStatus.name.includes('Discard') : false} 
                                size="small" 
                                margin="dense" 
                                variant="outlined"
                                inputProps={{ style: { fontSize: containerGridFontSize} }}
                                InputLabelProps={{shrink: true}}
                                value = {containerInfo.size}
                                error = {containerErrorChecks[index].size} 
                                onChange={(e) => handleContainerRowChange('size', (e.target.value), index)}
                                onBlur={e => {updateErrorChecksForContainers('size', CheckContainerSize(containerInfo), index); RoundAmounts(containerInfo.size, index, 'size')}}
                            />
                        </StyledTableCell>
                        <StyledTableCell style={{width:"80px"}}>
                            <TextField style={{width:"70px", marginTop:"5px"}}
                                disabled = {containerInfo.containerStatus ? containerInfo.containerStatus.name.includes('Discard') : false} 
                                size="small" 
                                margin="dense" 
                                variant="outlined"
                                inputProps={{ style: { fontSize: containerGridFontSize} }}
                                InputLabelProps={{shrink: true}}
                                value = {containerInfo.currentAmount}
                                error = {containerErrorChecks[index].currentAmount} 
                                onChange={(e) => handleContainerRowChange('currentAmount', (e.target.value), index)}
                                onBlur={e => {updateErrorChecksForContainers('currentAmount', CheckContainerCurrentAmount(containerInfo), index); RoundAmounts(containerInfo.currentAmount, index, 'currentAmount')}}     
                            />
                        </StyledTableCell>
                        <StyledTableCell style={{width:"80px"}}>
                            <StyledAutocomplete
                                renderOption={(props2, option) => (
                                    <Option {...props2}>{option.uoMName}</Option>
                                )}
                                disabled = {containerInfo.containerStatus ? containerInfo.containerStatus.name.includes('Discard') : false} 
                                disablePortal
                                noOptionsText={"Loading UOMs..."}
                                options={availableUOMs.filter(result => (result.type === 'weight' || result.type ==='volume' || result.type === 'unit'))}
                                getOptionLabel={(option) => option.uoMName}
                                onChange={(e, value) => handleContainerRowChange('uom', value, index)}
                                value={containerInfo.uom}
                                isOptionEqualToValue={(option, value) => value.uoMName === option.uoMName}
                                autoHighlight
                                autoSelect
                                renderInput={(params) => <TextField {...params} style={{width:"80px"}} variant="outlined" size="small" error = {containerErrorChecks[index].uom}  inputProps={{ ...params.inputProps,  style: { fontSize: containerGridFontSize}}} InputProps={{ ...params.InputProps }}/>} 
                            />
                        </StyledTableCell>

                        <TableCell align="center" style={{width:"75px"}}>{containerInfo.size > 0 ? ((containerInfo.currentAmount / containerInfo.size) * 100).toFixed(2): 0}%</TableCell>

                        <StyledTableCell>
                            <StyledAutocomplete
                                renderOption={(props2, option) => (
                                    <Option {...props2}>{option.locationName}</Option>
                                )}
                                disabled = {containerInfo.containerStatus ? containerInfo.containerStatus.name.includes('Discard') : false}                              
                                disablePortal
                                noOptionsText={"Loading Locations..."}
                                options={availableLocations}
                                getOptionLabel={(option) => option.locationName}
                                onChange={(e, value) => handleContainerRowChange('location', value, index)}
                                isOptionEqualToValue={(option, value) => value.locationName === option.locationName}
                                autoHighlight
                                autoSelect
                                value={containerInfo.location}
                                renderInput={(params) => <TextField {...params} style={{width:"160px"}} variant="outlined" size="small" 
                                    error = {containerErrorChecks[index].location}  inputProps={{ ...params.inputProps,  style: { fontSize: containerGridFontSize } }} InputProps={{ ...params.InputProps }}/>} 
                            />
                        </StyledTableCell>

                        <StyledTableCell>
                            <TextField style={{width:"160px", marginTop:"5px"}}
                                disabled = {containerInfo.containerStatus ? containerInfo.containerStatus.name.includes('Discard') : false} 
                                multiline
                                size="small" 
                                margin="dense" 
                                variant="outlined"
                                InputProps={{ style: { fontSize: containerGridFontSize }, maxLength: 4000 }}
                                InputLabelProps={{shrink: true}}
                                value = {containerInfo.subLocation ?? ""}
                                onChange={(e) => handleContainerRowChange('subLocation', (e.target.value), index)}
                            />
                        </StyledTableCell>

                        <StyledTableCell>
                            <EmailAddressTextField
                                fontSize = {12}
                                fieldWidth = {"225px"} 
                                validatedUserEmail = {containerInfo.ownerEmail}
                                setValidatedUserEmail = {(e) => handleContainerRowChange("ownerEmail", e, index )}
                                hasErrors = {containerErrorChecks[index].ownerEmail === null ? false : containerErrorChecks[index].ownerEmail}
                                setHasErrors = {(e) => updateErrorChecksForContainers('ownerEmail', e, index)}
                                isDisabled={containerInfo.containerStatus ? containerInfo.containerStatus.name.includes('Discard') : false} 
                                labelText="Owner Email"
                                placeholderText="Owner Email"
                                showPlusMeButton={false}>
                            </EmailAddressTextField>
                        </StyledTableCell>

                        {/* Comments */}
                        <TableCell align='center' style={{width:"80px"}}>
                            <IntegrationCommentsIcon 
                                onClick={() => openModalComments("View/Edit Container Comments", "You can add/edit an existing comment from here.", "Save", "Cancel", index)}>
                            </IntegrationCommentsIcon>
                        </TableCell>


                        {showTests &&
                        <StyledTableCell>
                            {PopulateTests(containerInfo.tests)}
                        </StyledTableCell>
                        }   
                    </TableRow>
                )})}
                </StyledTableBody>
            </UXDataTableWithoutBody>

        <ModalTwoButtons title={modalCommentsTitle} button1Text={modalCommentsButton1Text} button1Action={SaveContainerComment} button2Text={modalCommentsButton2Text} button2Action={closeModalComments} open={modalCommentsOpen} setOpen={setModalCommentsOpen}>
            <label>
                {modalCommentsText}
            </label>

            <div></div>
    
            <TextField
                size="small"
                multiline
                label = "Comments"
                value = {tempContainerInstruction === null ? '' : tempContainerInstruction}
                onChange = {e => {setTempContainerInstruction(e.target.value)}}
                margin = "normal"
                style={{width:500, marginTop:40}}
                inputProps={{ maxLength: 250 }}
            ></TextField>  
        </ModalTwoButtons>
        </>
    );
};

export default ContainerTable;