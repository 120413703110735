import React, { useState, useEffect, useContext } from "react";
import UserContext from "../../context/UserContext";
import WorkRequest from "../../api/WorkRequest/WorkRequest";
import { UXDataTable } from "../../components/UXDataTable";

const myCols = [
  { field: 'id', headerName: 'Request ID', type: 'number', path: null},
  { field: 'typeName', headerName: 'Type', type: 'label'},
  { field: 'createdDate', headerName: 'Request Date', type: 'datetime' },
  { field: 'completedDate', headerName: 'Completion Date', type: 'datetime'},
  { field: 'requestedByEmail', headerName: 'Requester', type: 'label'},
  { field: 'statusName', headerName: 'Status', type: 'label'}
];


const WorkRequestsTable = () => {
  const [workRequestIsLoading, setWorkRequestIsLoading] = useState(true)
  const [myWorkRequestInfo, setMyWorkRequestInfo] = useState([])
  const currentUser = useContext(UserContext)
  
  let inputSearchCriteria = currentUser.username
  const noDataMessage = `No work requests found for owner/assigned to ${inputSearchCriteria}`

  //get all the WR
  useEffect(() => {
    let cancelPromise = false
      
    WorkRequest.getMyWorkRequests(inputSearchCriteria).then((res) => {
      if (cancelPromise) return
      setMyWorkRequestInfo(res)
      setWorkRequestIsLoading(false)
      });
    
    return () => {
      cancelPromise = true
    }
  }, [inputSearchCriteria])

  
  return (
    <UXDataTable tableWidth='100%' cols={myCols} rows={myWorkRequestInfo === null ? [] : myWorkRequestInfo.sort((a, b) => new Date(b.createdDate) - new Date(a.createdDate))} moreOptionsCell={false} enablePaging={false} 
                    noDataMessage={noDataMessage} menuProps={null} defaultRowsPerPage={10} isDataLoading={workRequestIsLoading} tableName={'recentWorkRequestsWidget'} enableSorting={true}></UXDataTable>
  );
};

export default WorkRequestsTable;
