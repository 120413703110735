export type SqcSampleContainer = {
  id: number;
  sampleName: string;
  sample: {
    sampleName: string;
    description: string;
  };
};

export type SqcSampleContainerHistory = {
  sqcid: string;
  containerID: number;
  usedContainer: SqcSampleContainer;
  createdByEmail: string;
  createdDate: string;
  lastModifiedByEmail: string | null;
  lastModifiedDate: string | null;
  isActiveContainer: boolean;
  deactivatedDate: string | null;
  manufacturer: string | null;
  lotNumber: string | null;
};

export type SqcSample = {
  sqcid: string;
  lastModifiedDate: string | null;
  lastModifiedByEmail: string | null;
  isActive: boolean;
  description: string | null;
  containerHistory: SqcSampleContainerHistory[];
  siteName: string;
  thisSite: any;
};

export type SQCMethodFacility = {
  availableInstruments: {
    methodFacilityID: number;
    instrumentID: number;
    thisInstrument: {
      name: string;
    };
    isActive: boolean;
  }[];
  methodName: string;
  testFacilityAbv: string;
};

export type SQCMethodQCID = {
  sqcSampleID: string;
  sqcSample: any | null;
  methodFacilityID: number;
};

export type SQCMethodTrackedComponent = {
  methodFacilityID: number;
  resultComponentName: string;
  isInformational: boolean;
};

export type SQCMethodAllowedTemperature = {
  methodFacilityID: number;
  temperature: number;
};

export type SQCMethodLimit = {
  id: number;
  methodFacilityID: number;
  sqcSampleID: string;
  sqcid: SqcSample;
  resultComponentName: string;
  thisResultComponent: any;
  isActive: boolean;
  createdDate: string;
  createdByEmail: string;
  lastModifiedByEmail: string | null;
  lastModifiedDate: string | null;
  retiredDate: string | null;
  sigmaValue: number | null;
  meanValue: number | null;
  upperControlLimitValue: number | null;
  lowerControlLimitValue: number | null;
  applicableTemperatureValue: number | null;
  rule1ViolationBehavior: 1 | 2 | 3;
  rule2ViolationBehavior: 1 | 2 | 3;
  rule3ViolationBehavior: 1 | 2 | 3;
  rule4ViolationBehavior: 1 | 2 | 3;
  failureWindowBefore: number | null;
  failureWindowAfter: number | null;
};

export type SQCASTM = {
  id: number;
  methodFacilityID: number;
  resultComponentName: string;
  sqcResultComponent: any;
  revisionNumber: string;
  littleR: number | null;
  bigR: number | null;
  createdByEmail: string;
  createdDate: string;
  isActive: boolean;
  equationForm: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8;
  a: number | null;
  c: number | null;
  x: number | null;
  y: number | null;
  deactivationDate: string | null;
};

export type SqcMethodSetup = {
  methodFacilityID: number;
  methodFacility: SQCMethodFacility;
  isActive: boolean;
  lastModifiedByEmail: string;
  lastModifiedDate: string;
  isTemperatureDependent: boolean;
  qcids: SQCMethodQCID[];
  trackedComponents: SQCMethodTrackedComponent[];
  allowedTemperatures: SQCMethodAllowedTemperature[];
  limits: SQCMethodLimit[];
  sqcastm: SQCASTM[];
};

export type SqcResult = {
  id: number;
  createdByEmail: string;
  createdDate: string;
  lastModifiedByEmail: string | null;
  lastModifiedDate: string | null;
  analystEmail: string | null;
  analysisDate: string | null;
  runNumber: number;
  comments: string | null;
  sqcid: string;
  qcSample: any;
  sqcStatusName: string;
  sqcStatus: any;
  instrumentID: number;
  testInstrument: any;
  methodFacilityID: number;
  thisMethodFacility: any;
  temperature: number;
  testValues: SqcResultValue[];
};

export type SqcResultValue = {
  sqcResultID: number;
  resultSet: SqcResult;
  resultComponentName: string;
  thisResultComponent: any;
  resultUoMName: string | null;
  resultUoM: any;
  /** Most of the time this is a numerical value. Being read as a string since data quality issues */
  resultValue: string;
  // TODO: Is this even being used?
  analysisTemperature: number | null;
  comments: string | null;
  runViolations: string | null;
  violationReviewed: boolean;
  reviewedByEmail: string | null;
  reviewedDate: string | null;
};

export const HISTOGRAM_CATEGORIES = [
  "> -3s",
  "-3s",
  "-2s",
  "-1s",
  "1s",
  "2s",
  "3s",
  "3s <",
] as const;

export type SimplifiedSqcResultValue = {
  number: number;
  qcid: string;
  methodName: string;
  siteAbbreviation: string;
  temperature: number | null;
  analysisDate: string | null;
  runNumber: number | null;
  analystEmail: string | null;
  instrumentId: number | null;
  instrumentName: string | null;
  status: string;
  value: number;
  percentageDifference: number;
  ewma: number;
  failures: string;
  comments: string | null;
  histogramCategory: (typeof HISTOGRAM_CATEGORIES)[number];
  ruleResult: "warning" | "violation" | null;
  resultId: number | null;
};

export type SqcStatus = {
  statusName: string;
  isActive: boolean;
  displayOrder: number;
  displayColor: string;
};
