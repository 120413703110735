import React, { useState } from "react";
import { Box, Divider} from "@mui/material";
import { ReprintLabel } from "./components/ReprintLabel";
import Reporting from "./components/Reporting/";
import { GlobalTabCollection, StyledTab } from "../../pages/styles";

const Utilities = () => {
  const [tabValue, setTabValue] = useState(0);
    const handleChange = (event, newValue) => {
      setTabValue(newValue);
    };
  
    const renderSearchTab = () => {
      switch (tabValue) {
        case 0: {
          return <ReprintLabel />
        }
        case 1: {
          return <Reporting />;
        }
        default: {
          alert(tabValue);
        }
      }
    };

  return (
    <>
      <span className='pageheader'>Utilities</span>
      <Divider className='dividerbar' />
      <div>
        <Box sx={{ bgcolor: "#fff", pt:3, pb:1 }}>
          <GlobalTabCollection value={tabValue} onChange={handleChange} aria-label='ant example'>
            <StyledTab label='Reprint Label' />
            <StyledTab label='Reporting' />
          </GlobalTabCollection>
          <Box sx={{ p: 3 }} />
        </Box>
        {renderSearchTab()}
      </div>
    </>
  );
};

export default Utilities;
